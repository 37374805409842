import axios from "axios";
import instance from "../../store/AxiosInterceptor";
import { EnumGAEvent, sendGAEvent } from "../../utils/GAEvent";

/**
 * 일반 사용자 계정 로그인 API
 *
 * @param {*} id
 * @param {*} password
 *
 * @returns
 */
export async function ReqSignIn(id, password) {
    const requestBody = {
        id: id,
        password: password,
    };

    // [A] 20240425 YiSuHwan Send 'Login event' to GoogleAnalystics.
    sendGAEvent(EnumGAEvent.event_login, EnumGAEvent.cat_user_accout, id);

    const response = await axios
        .post("/api/v1/auth/signIn", requestBody)
        .then((response) => {
            // console.log(response);
            return response;
        })
        .catch((error) => {
            // console.log(error);
            if (error.response) {
                // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
                return {
                    status: error.response.status,
                    error: "",
                };
            } else if (error.request) {
                // 요청이 이루어 졌으나 응답을 받지 못했습니다.
                return {
                    status: 600,
                    error: "요청에 대한 응답 없음",
                };
            } else {
                // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
                return {
                    status: 600,
                    error: "요청설정 에러",
                };
            }
        });
    return response;
}

/**
 * admin 계정 로그인 API
 *
 * @param {*} id
 * @param {*} password
 *
 * @returns
 */
export async function ReqAdminSignIn(id, password) {
    const requestBody = {
        id ,password
    };

    // [A] 20240425 YiSuHwan Send 'Login event' to GoogleAnalystics.
    sendGAEvent(EnumGAEvent.event_login_admin, EnumGAEvent.cat_user_accout, id);

    const response = await axios
        .post("/api/v1/auth/admin/signIn", requestBody)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log('error:',error);
            if (error.response) {
                // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
                return {
                    status: error.response.status,
                    error: "",
                };
            } else if (error.request) {
                // 요청이 이루어 졌으나 응답을 받지 못했습니다.
                return {
                    status: 600,
                    error: "요청에 대한 응답 없음",
                };
            } else {
                // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
                return {
                    status: 600,
                    error: "요청설정 에러",
                };
            }
        });
    return response;
}

/**
 * logout을 해주는 API
 * token을 보고 admin인지 user인지 판단하여 uri를 다르게 생성하여 로그아웃을 해준다.
 *
 * @param {*} loginCtx
 * @param {*} resolveCB
 * @param {*} rejectCB
 */
export async function ReqSignOut(loginCtx, resolveCB, rejectCB) {
    const uri = `/api/v1/auth/${
        loginCtx.isAdmin() ? "admin/signOut" : "signOut"
    }`;

    // [A] 20240425 YiSuHwan Send 'Login event' to GoogleAnalystics.
    sendGAEvent(EnumGAEvent.event_logout, EnumGAEvent.cat_user_accout, loginCtx.userType);

    const response = await instance
        .post(uri)
        .then((response) => {
            //console.log(response);
            return response;
        })
        .catch((error) => {
            console.log(error);
            if (error.response) {
                // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
                return {
                    status: error.response.status,
                    error: "",
                };
            } else if (error.request) {
                // 요청이 이루어 졌으나 응답을 받지 못했습니다.
                return {
                    status: 600,
                    error: "요청에 대한 응답 없음",
                };
            } else {
                // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
                return {
                    status: 600,
                    error: "요청설정 에러",
                };
            }
        });

    if (
        response.status === 200 &&
        (response.data.status === 200 || response.data.status === 0)
    ) {
        if (resolveCB) resolveCB();
    } else {
        if (rejectCB) rejectCB();
    }
}

/**
 * 서면 회원 가입을 위한 API 요청
 * @param {신분증 및 회원가입 정보} formData
 * @param {*} resolveCB
 * @param {*} rejectCB
 */
export async function ReqSignUpDocMember(formData, resolveCB, rejectCB) {

    const response = await instance({
        method: "POST",
        url: "/api/v1/auth/signUpDoc",
        data: formData,
    })
        .then((response) => {
            resolveCB(response.data);
        })
        .catch((error) => {
            rejectCB(error);
            if (error.response) {
                // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
                return {
                    status: error.response.status,
                    error: "",
                };
            } else if (error.request) {
                // 요청이 이루어 졌으나 응답을 받지 못했습니다.
                return {
                    status: 600,
                    error: "요청에 대한 응답 없음",
                };
            } else {
                // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
                return {
                    status: 600,
                    error: "요청설정 에러",
                };
            }
        });
}

/**
 * 서면 회원가입 SMS 인증 요청
 * @param resolveCB
 * @returns
 */
export function ReqSMSAuthCode(memberPhone, resolveCB, rejectCB) {
    if (
        memberPhone.trim() === "010" ||
        memberPhone.trim() === "011" ||
        memberPhone.trim() === "018" ||
        memberPhone.trim() === "019" ||
        memberPhone.trim() === "016"
    ) {
        alert("휴대폰 번호를 입력해주세요");
        return;
    }

    instance({
        url: `/api/v1/auth/reqAuthNumber`,
        method: "POST",
        data: {
            phone: memberPhone,
        },
    })
        .then((response) => {
            // List [ { id, name } ...]
            if (resolveCB) {
                resolveCB(response.data);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/**
 * 서면 회원가입 SMS 인증번호 체크 API
 * @param resolveCB
 * @returns
 */
export function ReqSMSAuthCodeValid(
    serverAuthCode,
    inputAuthCode,
    resolveCB,
    rejectCB
) {
    instance({
        url: `/api/v1/auth/confirmAuthNumber`,
        method: "POST",
        data: {
            auth_tid: serverAuthCode,
            auth_number: inputAuthCode,
        },
    })
        .then((response) => {
            // List [ { id, name } ...]
            if (resolveCB) {
                resolveCB(response.data);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/**
 * 회원가입 중 회원 아이디 중복 확인 API
 * @param resolveCB
 * @returns
 */
export function ReqCheckDuplicateMemId(memberId, resolveCB, rejectCB) {
    instance({
        url: `/api/v1/auth/idCheck?memberID=${memberId}`,
        method: "POST",
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.status);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/**
 * 휴대폰 인증 후 회원가입 API
 * @param {신분증 및 회원가입 정보} formData
 * @param {*} resolveCB
 * @param {*} rejectCB
 */
export async function ReqSignUpPhoneMember(
    memberInfoObject,
    resolveCB,
    rejectCB
) {
    const response = await instance({
        method: "POST",
        url: "/api/v1/auth/signUp",
        data: memberInfoObject,
    })
        .then((response) => {
            resolveCB(response.data);
        })
        .catch((error) => {
            rejectCB(error);
            if (error.response) {
                // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
                return {
                    status: error.response.status,
                    error: "",
                };
            } else if (error.request) {
                // 요청이 이루어 졌으나 응답을 받지 못했습니다.
                return {
                    status: 600,
                    error: "요청에 대한 응답 없음",
                };
            } else {
                // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
                return {
                    status: 600,
                    error: "요청설정 에러",
                };
            }
        });
}

/**
 * 마이페이지 > 내정보 변경 > 비밀번호 체크
 * @param resolveCB
 * @returns
 */
export function ReqCheckPassword(password, resolveCB, rejectCB) {
    instance({
        url: `/api/v1/auth/passwordCheck`,
        method: "POST",
        data: {
            password: password,
        },
    })
    .then((response) => {
        if (resolveCB) {
            resolveCB(response.data);
        }
    })
    .catch((error) => {
        if (rejectCB) {
            rejectCB([]);
        }
    });
}

/**
 * 아이디/비밀번호 찾기에서 이름, 휴대폰번호, 이메일로 아이디찾기
 * @param resolveCB
 * @returns
 */
export function ReqFindId(findIdObject, resolveCB, rejectCB) {
    instance({
        url: `/api/v1/auth/findId`,
        method: "POST",
        data: findIdObject,
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/**
 * 아이디/비밀번호 찾기에서 이름, 아이디, 휴대폰번호, 이메일로 비밀번호 찾기
 * 사실 상 비밀번호 변경 페이지로 넘어간다.
 * @param resolveCB
 * @returns
 */
export function ReqFindPassword(findPasswodObject, resolveCB, rejectCB) {
    instance({
        url: `/api/v1/auth/findPassword`,
        method: "POST",
        data: findPasswodObject,
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/**
 * 아이디/비밀번호 찾기에서 비밀번호 변경
 * @param resolveCB
 * @returns
 */
export function ReqChangePassword(passwordObject, resolveCB, rejectCB) {
    instance({
        url: `/api/v1/auth/changePassword`,
        method: "PUT",
        data: passwordObject,
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

export const reqTimeAdd = async ({token, refreshToken }) => {
    
    const response = await axios({
        method: "post",
        url: "/api/v1/auth/refresh",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${refreshToken}`,
        },
    }).catch((error) => {
        return error.response;
    });
    
    return response;
}