import { useContext, useState } from "react";
import Button from "../../../../components/UI/Button/Button";
import Input from "../../../../components/UI/Input/Input";
import Radio from "../../../../components/UI/Input/Radio";
import SelectBoxNew from "../../../../components/UI/SelectBox/SelectBoxNew";
import classes from "../../MngCommon.module.css";
import DaumPostcode from "react-daum-postcode";
import { DefaultAll, regionType } from "../../../../components/definedefault/DefSelectBoxValues";
import { ReqRegionData } from "../../../../api/common/ConditionSelectBoxApi";
import { useLocation, useNavigate } from "react-router-dom";
import Modal from "../../../../components/UI/Modal/Modal";
import Moment from 'moment';
import { ReqCenterCreate } from "../../../../api/company/ApiForCenter";
import { JwtTokenContext } from "../../../../store/JwtTokenProvider";

function MngCenterCreate() {

    const loginCtx = useContext(JwtTokenContext);
    const location = useLocation();
    const navigate = useNavigate();

    const backHandler = () => {
        navigate("/admin/company/center", {state: location.state});
    };

    const [exposeYn, setExposeYn ] = useState("Y")
    const [regionData, setRegionData] = useState([ DefaultAll ]);
    const [region, setRegionType] = useState ("");
    const [centerName, setCenterName] = useState();
    const [phoneNum1, setPhoneNum1] = useState("");
    const [phoneNum2, setPhoneNum2] = useState("");
    const [phoneNum3, setPphoneNum3] = useState("");

    const exposeYnRadioButton = (e) => {
        setExposeYn(e.target.value)
    }

    const regionDataHandler = (key) => {
        setRegionType(key);
    };

    const changeCenterNameHandler = (value) => {
        setCenterName(value);
    };

    const phoneNum1ChangeHandler = (value) => {
        setPhoneNum1(value);
    };
    const phoneNum2ChangeHandler = (value) => {
        setPhoneNum2(value);
    };
    const phoneNum3ChangeHandler = (value) => {
        setPphoneNum3(value);
    };

    const addressHandler = (address) => {
        setEnteredAddress(address);
    };

    const regionNameMouseOverHandler = (key) => {
        if (regionData.length === 1) {
            ReqRegionData (
                (result) => {
                    const success = result.map((data) => { return { key: String(data.name), value: data.name }; });
                    setRegionData((prev) => [DefaultAll, ...success]);
                },
                (excption_result) => {
                    setRegionData((prev) => [DefaultAll]);
                }
            );
        }
    }

    // [START] 다음 주소 API =================================================================

    const [isDaumPostcodeOpen, setIsDaumPostcodeOpen] = useState(false);
    const [selectedAddress, setSelectedAddress] = useState(""); // 선택한 주소를 저장할 상태
    const [enteredAddress, setEnteredAddress] = useState(""); // 선택한 주소를 저장할 상태

    const openDaumPostcodeHandler = (e) => {
        e.stopPropagation();
        setIsDaumPostcodeOpen(true);
    };

    const handleAddressSelect = (data) => {
        const fullAddress = data.address; // 전체 주소
        const extraAddress = data.addressType === "R" ? "" : data.bname; // 도로명일 경우 법정동 추가
        const address = `${fullAddress} ${extraAddress}`; // 최종 주소
        setSelectedAddress(address); // 주소를 선택하면 상태 업데이트
        setIsDaumPostcodeOpen(false); // 주소를 선택하면 모달 창 닫기
    };

    const closeDaumPostcodeHandler = () => {
        setIsDaumPostcodeOpen(false);
    };

    // [END] 다음 주소 API =================================================================

    const createHandler = async (e) => {
        e.preventDefault();
        
        if(!region) {
            alert("지역을 선택해주세요.");
            return
        }

        if(!centerName) {
            alert("볼링장명을 입력해주세요.");
            return
        }

        if (
            /^(\d{2,3})-(\d{3,4})-(\d{4})$/.test(
                `${phoneNum1}-${phoneNum2}-${phoneNum3}`
            ) === false
        ) {
            alert("전화번호 형식에 맞지 않습니다.");
            return;
        }

        if(!selectedAddress || !enteredAddress) {
            alert("주소을 입력해주세요.");
            return
        }

        const isTrue = window.confirm('볼링장를 등록하시겠습니까?');
        if(isTrue) {
            try {
                const jsonData = {
                    expose_yn: exposeYn === "Y" ? true : false,
                    region: region,
                    name: centerName,
                    address: selectedAddress,
                    sub_address: enteredAddress,
                    phone: `${phoneNum1}-${phoneNum2}-${phoneNum3}`,
                };
                console.log(jsonData);
                const response = await ReqCenterCreate(jsonData);
                if (response.status === 200) {
                    alert("볼링장이 등록되었습니다.");
                    backHandler();
                } else {
                    alert("볼링장 등록 실패");
                }
            } catch (error) {
                console.error("Error:", error);
            }
        } else {
          return
        }

        
    };

    return (
        <section>
            <div className={classes.admin_contents}>
                <h3>전국볼링장 - 등록</h3>
                <div
                    className={`${classes.contents_wrap} ${classes.search_wrap} `}
                >
                    <div className={classes.admin_table_wrap}>
                        <table>
                            <colgroup>
                                <col style={{ width: "159px" }} />
                                <col style={{ width: "620px" }} />
                                <col style={{ width: "159px" }} />
                                <col style={{ width: "620px" }} />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>등록일</th>
                                    <td>{Moment(new Date()).format('YYYY-MM-DD')}</td>
                                    <th>등록자</th>
                                    <td>{loginCtx.decodedToken.jti}</td>
                                </tr>
                                <tr>
                                    <th>노출여부</th>
                                    <td colSpan={3}>
                                        <div className={classes.radio_wrap}>
                                            <Radio
                                                value="Y"
                                                title="노출"
                                                name="rad_01"
                                                id="rad_01_01"
                                                checked={exposeYn === "Y"} 
                                                onChange={exposeYnRadioButton}
                                            />
                                            <Radio
                                                value="N"
                                                title="미노출"
                                                name="rad_01"
                                                id="rad_01_02"
                                                checked={exposeYn === "N"} 
                                                onChange={exposeYnRadioButton}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>지역</th>
                                    <td colSpan={3}>
                                        <SelectBoxNew
                                            className="admin_st"
                                            setSelectItemCB={regionDataHandler}
                                            optionDatas={regionType}
                                            showDefaultKey={region}
                                            //mouseOverCB={regionNameMouseOverHandler}
                                        />        
                                    </td>
                                </tr>
                                <tr>
                                    <th>볼링장명</th>
                                    <td colSpan={3}>
                                        <Input onChange={changeCenterNameHandler} value={centerName}/>
                                    </td>
                                </tr>
                                <tr>
                                    <th>연락처</th>
                                    <td colSpan={3}>
                                        <div
                                            className={`${classes.flex_row} ${classes.multi}`}
                                        >
                                            <Input
                                                type="number"
                                                className="sm"
                                                value={phoneNum1}
                                                onChange={phoneNum1ChangeHandler}
                                            />
                                            <span>-</span>
                                            <Input
                                                type="number"
                                                className="sm"
                                                value={phoneNum2}
                                                onChange={phoneNum2ChangeHandler}
                                            />
                                            <span>-</span>
                                            <Input
                                                type="number"
                                                className="sm"
                                                value={phoneNum3}
                                                onChange={phoneNum3ChangeHandler}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th rowSpan={2}>주소</th>
                                    <td colSpan={3}>
                                        <div className={classes.flex_row}>
                                            <Input className="admin_st w_50" value={selectedAddress} readOnly/>
                                            <Button className="border sm" onClick={openDaumPostcodeHandler}>
                                                주소찾기
                                            </Button>
                                        </div>
                                    </td>
                                </tr>
                                {isDaumPostcodeOpen && (
                                    <Modal
                                        className="admin_st"
                                        close={closeDaumPostcodeHandler}
                                        header="주소 검색"
                                    >
                                        <DaumPostcode onComplete={handleAddressSelect} />
                                    </Modal>
                                )}
                                <tr>
                                    <td colSpan={3}>
                                        <div
                                            className={`${classes.flex_row} ${classes.multi}`}
                                        >
                                            <Input
                                                className="admin_st w_50"
                                                placeholder="나머지 주소 입력"
                                                onChange={addressHandler}
                                                value={enteredAddress}
                                            />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <div className={classes.bottom_btn_wrap}>
                            <Button className="secondary sm" onClick={createHandler}>등록</Button>
                            <Button
                                className="border sm" onClick={backHandler}
                            >
                                목록
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default MngCenterCreate;

