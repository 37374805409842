import { NavLink } from "react-router-dom";
import classes from "../MngCommon.module.css";

function MngCommunityNavigation(props) {
    return (
        <div className={classes.admin_sub_nav}>
            <nav>
                <ul className={classes.depth_1}>
                    <li className={classes.depth_1_link}>
                        <NavLink to="notice">공지사항</NavLink>
                    </li>
                    <li className={classes.depth_1_link}>
                        <NavLink to="faq">FAQ</NavLink>
                    </li>
                    <li className={classes.depth_1_link}>
                        <NavLink to="cs">1:1 문의</NavLink>
                    </li>
                    <li className={classes.depth_1_link}>
                        <NavLink to="as">A/S 신청</NavLink>
                    </li>
                </ul>
            </nav>
        </div>
    );
}

export default MngCommunityNavigation;
