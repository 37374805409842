import { useNavigate } from "react-router-dom";
import classes from "./MyPage.module.css";
import { useContext } from "react";
import { ReqSignOut } from "../../api/auth/AuthApi";
import { JwtTokenContext } from "../../store/JwtTokenProvider";



function MyPageSubHeader() {
    const navigate = useNavigate();
    const loginCtx = useContext(JwtTokenContext);
    const userName = loginCtx.decodedToken ? loginCtx.decodedToken.mname ? loginCtx.decodedToken.mname : loginCtx.decodedToken.jti : "";
    
    const onLogoutHandler = async (e) => {
        const isAdmin = loginCtx.isAdmin();
        await ReqSignOut (loginCtx, () => {
            loginCtx.removeToken();
            if (isAdmin)
                navigate("/adminlogin");
            else
                navigate("/");
        }, () => {
            // alert("로그아웃 실패");
            loginCtx.removeToken();
            if (isAdmin)
                navigate("/adminlogin");
            else
                navigate("/");
        });
    };

    const moveOneInquiryHandler = () => {
        navigate("/myPage/contact/help");
    }

    const moveASHandler = () => {
        navigate("/myPage/contact/as");
    }

    return (
        <div className={classes.mypage_sub_header}>
            <div className={classes.inner}>
                <div className={classes.user}>
                    <p>{userName}님 환영합니다.</p>
                    {/*  2차 오픈 시 이용 
                    <p className={classes.j_id}>
                        025648001
                        <span className={classes.tooltip}>툴팁 열기</span>
                        <span className={classes.tooltip_content}>
                            <b>JID란?</b>
                            <span>
                                진승 라이선스 번호로 진승 회원님들께 제공되는
                                특별한 번호입니다.
                            </span>
                        </span>
                    </p> */}
                </div>
                <ul className={classes.shortcut}>
                    <li className={classes.cs} onClick={moveOneInquiryHandler}>1:1문의</li>
                    <li className={classes.as} onClick={moveASHandler}>AS 신청</li>
                    <li className={classes.logout} onClick={onLogoutHandler}>로그아웃</li>
                </ul>
            </div>
        </div>
    );
}
export default MyPageSubHeader;
