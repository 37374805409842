import React, { useState } from "react";
import classes from "./Accordion.module.css";

function AccordionFaq({ contents, className }) {
    // 본 컴포넌트는 커뮤니티 > FAQ 페이지를 작업하며, 기존 Accordion 컴포넌트 내용의 충돌을 막기 위해 퍼블단에서 임의로 추가 하였습니다.
    // className 을 props로 가져옴, data에 title 추가
    // ↓ 퍼블단에서 화면 확인만을 위해 작성한 코드 입니다.
    const [accordionItems, setAccordionItems] = useState([null]);

    const toggleAccordion = (index) => {
        setAccordionItems((prevState) => {
            const newAccordionItems = [...prevState];
            newAccordionItems[index] = !newAccordionItems[index];
            return newAccordionItems;
        });
    };

    return (
        <div className={`${classes.accordion_warp} ${classes[className]}`}>
            {contents.map((item, index) => (
                <div
                    className={`${classes.accordion} ${
                        accordionItems[index] ? classes.expanded : ""
                    }`}
                    key={index}
                >
                    <h3 onClick={() => toggleAccordion(index)}>
                        <span>[{item.title}]</span>
                        {item.question}
                    </h3>
                    <p
                        className={`${classes.answer} ${
                            accordionItems[index] ? classes.show : ""
                        }`}
                    >
                        <div dangerouslySetInnerHTML={{
                            __html: item.answer,
                        }} >
                        </div>
                    </p>
                </div>
            ))}
        </div>
    );
}
export default AccordionFaq;
