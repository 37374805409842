import { useNavigate } from "react-router";
import Button from "../../../../components/UI/Button/Button";
import DateRangePicker from "../../../../components/UI/DatePicker/DateRangePicker";
import Input from "../../../../components/UI/Input/Input";
import Radio from "../../../../components/UI/Input/Radio";
import classes from "../../MngCommon.module.css";
import Moment from 'moment';
import { useContext, useState } from "react";
import QuillEditor from "../../../../components/UI/Editer/QuillEditor";
import { ReqPopupCreate } from "../../../../api/site/ApiForPopup";
import DatePickerBox from "../../../../components/UI/DatePicker/DatePickerBox";
import DateTimePicker from "../../../../components/UI/DatePicker/DateTimePicker";
import { JwtTokenContext } from "../../../../store/JwtTokenProvider";

function MngPopupCreate(props) {

    const loginCtx = useContext(JwtTokenContext);
    const navigate = useNavigate();

    const backHandler = () => {
        navigate("/admin/site/popup");
    };

    const [title, setTitle] = useState();
    const [url, setUrl] = useState();
    const [width, setWidth] = useState();
    const [height, setHeight] = useState();
    const [x, setX] = useState();
    const [y, setY] = useState();
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [exposeYnStatus, setExposeYnStatus ] = useState("Y")
    const [cookieYn, setCookieYn ] = useState("Y")
    const [content, setContent] = useState();

    const changeTitleHandler = (value) => {
        setTitle(value);
    };

    const changeUrlHandler = (value) => {
        setUrl(value);
    };

    const changeWidthHandler = (value) => {
        setWidth(value);
    };

    const changeHeightHandler = (value) => {
        setHeight(value);
    };

    const changeXHandler = (value) => {
        setX(value);
    };

    const changeYHandler = (value) => {
        setY(value);
    };

    const startDateHandler = (date) => {
        setStartDate(date);
    };

    const endDateHandler = (date) => {
        setEndDate(date);
    };

    const exposeYnRadioButton = (e) => {
        setExposeYnStatus(e.target.value)
    }

    const cookieYnRadioButton = (e) => {
        setCookieYn(e.target.value)
    }

    const contentHandler = (value) => {
        setContent(value);
    };

    const createHandler = async (e) => {
        e.preventDefault();
        
        if(!title) {
            alert("제목을 입력해주세요.");
            return
        }

        if(!url) {
            alert("URL을 입력해주세요.");
            return
        }

        if(!width || !height) {
            alert("사이즈을 입력해주세요.");
            return
        }

        if(!x || !y) {
            alert("위치을 입력해주세요.");
            return
        }

        if(endDate < startDate) {
            alert("게시기간 종료일보다 게시기간 시작일이 클 수 없습니다.");
            return
        }

        if(!content) {
            alert("내용을 입력해주세요.");
            return
        }

        const isTrue = window.confirm('팝업을 등록하시겠습니까?');
        if(isTrue) {
            try {
                const jsonData = {
                    expose_yn: exposeYnStatus === "Y" ? true : false,
                    title: title,
                    content: content,
                    url: url,
                    x: x,
                    y: y,
                    width : width,
                    height : height,
                    expose_s_date : Moment(startDate).format('YYYYMMDDHHmm'),
                    expose_e_date : Moment(endDate).format('YYYYMMDDHHmm'),
                    cookie_yn : cookieYn === "Y" ? true : false,
                };

                const response = await ReqPopupCreate(jsonData);
                if (response.status === 200) {
                    alert("팝업이 등록되었습니다.");
                    backHandler();
                } else {
                    alert("팝업 등록 실패");
                }
            } catch (error) {
                console.error("Error:", error);
            }
        } else {
          return
        }

        
    };

    return (
        <section>
            <div className={classes.admin_contents}>
                <h3>팝업관리 - 등록</h3>
                <div
                    className={`${classes.contents_wrap} ${classes.search_wrap} `}
                >
                    <div className={classes.admin_table_wrap}>
                        <table>
                            <colgroup>
                                <col style={{ width: "10%" }} />
                                <col style={{ width: "40%" }} />
                                <col style={{ width: "10%" }} />
                                <col style={{ width: "40%" }} />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>등록일</th>
                                    <td>{Moment(new Date()).format('YYYY-MM-DD')}</td>
                                    <th>등록자</th>
                                    <td>{loginCtx.decodedToken.jti}</td>
                                </tr>
                                <tr>
                                    <th>노출여부</th>
                                    <td colSpan={3}>
                                        <div className={classes.radio_wrap}>
                                            <Radio
                                                value="Y"
                                                title="노출"
                                                name="rad_01"
                                                id="rad_01_01"
                                                checked={exposeYnStatus === "Y"} 
                                                onChange={exposeYnRadioButton}
                                            />
                                            <Radio
                                                value="N"
                                                title="미노출"
                                                name="rad_01"
                                                id="rad_01_02"
                                                checked={exposeYnStatus === "N"} 
                                                onChange={exposeYnRadioButton}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>제목</th>
                                    <td colSpan={3}>
                                        <Input className="admin_st" onChange={changeTitleHandler}/>
                                    </td>
                                </tr>
                                <tr>
                                    <th>팝업창 URL</th>
                                    <td colSpan={3}>
                                        <Input
                                            className="admin_st" onChange={changeUrlHandler}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>팝업창 사이즈</th>
                                    <td colSpan={3}>
                                        <div className={classes.flex_row}>
                                            <Input
                                                className="admin_st"
                                                onChange={changeWidthHandler}
                                            />
                                            <span>px</span>
                                            <span>*</span>
                                            <Input
                                                className="admin_st"
                                                onChange={changeHeightHandler}
                                            />
                                            <span>px</span>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>팝업창 위치</th>
                                    <td colSpan={3}>
                                        <div className={classes.flex_row}>
                                            <Input
                                                className="admin_st"
                                                onChange={changeXHandler}
                                            />
                                            <span>px</span>
                                            <span>*</span>
                                            <Input
                                                className="admin_st"
                                                onChange={changeYHandler}
                                            />
                                            <span>px</span>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>게시기간</th>
                                    <td colSpan={3}>
                                    <div className={classes.date_pick_row}>
                                        <DateTimePicker id="start_date" onChange={startDateHandler} value={startDate}/>
                                        <em>~</em>
                                        <DateTimePicker id="end_date" onChange={endDateHandler}  value={endDate}/>
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>쿠키설정여부</th>
                                    <td colSpan={3}>
                                        <div className={classes.radio_wrap}>
                                            <Radio
                                                value="Y"
                                                title="Y"
                                                name="rad_02"
                                                id="rad_02_01"
                                                checked={cookieYn === "Y"} 
                                                onChange={cookieYnRadioButton}
                                            />
                                            <Radio
                                                value="N"
                                                title="N"
                                                name="rad_02"
                                                id="rad_02_02"
                                                checked={cookieYn === "N"} 
                                                onChange={cookieYnRadioButton}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>내용</th>
                                    <td colSpan={3}>                                    
                                    <div
                                        style={{ border: "1px solid #dedede", height: "292px" }}
                                    >
                                        <QuillEditor
                                        height='248' width='auto'
                                        onValueChangeCB={contentHandler}
                                        value={content}
                                        />
                                    </div> </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className={classes.bottom_btn_wrap}>
                            <Button className="secondary sm" onClick={createHandler}>등록</Button>
                            <Button
                                className="border sm" onClick={backHandler}
                            >
                                목록
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default MngPopupCreate;
