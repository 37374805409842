import { useContext, useEffect, useState } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { JwtTokenContext } from "../store/JwtTokenProvider";
import classes from "./MainNavigationApp.module.css";
import Button from "./UI/Button/Button";
import { ReqSignOut } from "../api/auth/AuthApi";
import { useMemStore } from "../store/memstore/MemStore";

function MainNavigationApp(props) {
    const setMemStore = useMemStore(false)[1];
    const location = useLocation();
    const loginCtx = useContext(JwtTokenContext);
    const navigate = useNavigate();

    const isAdminUri = location.pathname.startsWith("/admin");
    const isTournamentUri = location.pathname.startsWith("/tournament");
    const isHome = location.pathname.endsWith("/");
    const isAdmin = isAdminUri ? loginCtx.isAdmin() : false;

    const isUserLogin = loginCtx.isLogin()
        ? loginCtx.isAdmin()
            ? false
            : true
        : false;

    const isTournamentHome = location.pathname.endsWith("/tournament");
    const [navToggle, setNavToggle] = useState(false);
    const navOpenHandler = () => {
        setNavToggle(true);
    };

    const [headerClass, setHeaderClass] = useState("");
    const navCloseHandler = () => {
        setNavToggle(false);
    };

    useEffect(() => {
        const pathname = location.pathname;
        navCloseHandler();

        return () => {};
    }, [location.pathname]);

    useEffect(() => {
        // Check if either isHome or isTornamentHome is true
        if (isHome || isTournamentHome) {
            const handleScroll = () => {
                const scrollPosition = window.scrollY;
                if (scrollPosition > 530) {
                    setHeaderClass("on");
                } else {
                    setHeaderClass("");
                }
            };

            window.addEventListener("scroll", handleScroll);
            return () => {
                window.removeEventListener("scroll", handleScroll);
            };
        } else {
            setHeaderClass("on");
        }
    }, [isHome, isTournamentHome]);
    const tokenCtx = useContext(JwtTokenContext);

    // lnb slider menu 여닫기
    const [lnbToggle, setLnbToggle] = useState(false);
    const LnbToggleHandler = () => {
        setLnbToggle((prev) => !prev);
    };
    // lnb 메뉴 열린 상태에서 스크롤 제거
    useEffect(() => {
        const body = document.getElementById("bodyWrap");
        if (!body) return;
        body.style.overflow = lnbToggle ? "hidden" : "auto";
        body.style.height = lnbToggle ? "100vh" : "auto";
    }, [lnbToggle]);

    // lnb slider 대회 메뉴 여닫기
    const [lnbSubToggle, setLnbSubToggle] = useState(false);
    const lnbSubToggleHandler = () => {
        setLnbSubToggle((prev) => !prev);
    };
    const userMenu = (
        <ul>
            <li>
                <NavLink to="/balls" onClick={LnbToggleHandler}>
                    볼링볼
                </NavLink>
            </li>
            <li>
                <NavLink to="/bags" onClick={LnbToggleHandler}>
                    볼링가방
                </NavLink>
            </li>
            <li>
                <NavLink to="/shoes" onClick={LnbToggleHandler}>
                    볼링화
                </NavLink>
            </li>
            <li>
                <NavLink to="/clothes" onClick={LnbToggleHandler}>
                    볼링의류
                </NavLink>
            </li>
            <li>
                <NavLink to="/products" onClick={LnbToggleHandler}>
                    볼링용품
                </NavLink>
            </li>
            <li>
                <NavLink to="/guard" onClick={LnbToggleHandler}>
                    볼링아대
                </NavLink>
            </li>
            <li>
                <NavLink to="/kegel" onClick={LnbToggleHandler}>
                    케겔
                </NavLink>
            </li>
            <li>
                <NavLink to="/media" onClick={LnbToggleHandler}>
                    커뮤니티
                </NavLink>
            </li>
            <li>
                <NavLink to="/intro" onClick={LnbToggleHandler}>
                    회사소개
                </NavLink>
            </li>
            <li>
                <NavLink to="/event" onClick={LnbToggleHandler}>
                    이벤트
                </NavLink>
            </li>
            <li
                className={`${classes.tournament_link} ${
                    lnbSubToggle ? classes.on : null
                }`}
            >
                <NavLink to="/tournament" onClick={LnbToggleHandler}>
                    진승 볼링대회
                </NavLink>
                <span onClick={lnbSubToggleHandler}></span>
                <ul>
                    <li>
                        <NavLink
                            to="/tournament/notice"
                            onClick={LnbToggleHandler}
                        >
                            대회공지
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to="/tournament/apply"
                            onClick={LnbToggleHandler}
                        >
                            대회접수
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to="/tournament/list"
                            onClick={LnbToggleHandler}
                        >
                            대회목록
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to="/tournament/result"
                            onClick={LnbToggleHandler}
                        >
                            대회결과
                        </NavLink>
                    </li>
                </ul>
            </li>
        </ul>
    );

    const tournamentMenu = (
        <ul>
            <li>
                <NavLink to="/tournament/notice" onClick={LnbToggleHandler}>
                    대회공지
                </NavLink>
            </li>
            <li>
                <NavLink to="/tournament/apply" onClick={LnbToggleHandler}>
                    대회접수
                </NavLink>
            </li>
            <li>
                <NavLink to="/tournament/list" onClick={LnbToggleHandler}>
                    대회목록
                </NavLink>
            </li>
            <li>
                <NavLink to="/tournament/result" onClick={LnbToggleHandler}>
                    대회결과
                </NavLink>
            </li>
        </ul>
    );

    const signOutHandler = async () => {
        await ReqSignOut(
            loginCtx,
            () => {
                loginCtx.removeToken();
                if (isAdmin) navigate("/adminlogin");
                else if (isTournamentUri) navigate("/tournament");
                else navigate("/");
            },
            () => {
                loginCtx.removeToken();
                if (isAdmin) navigate("/adminlogin");
                else if (isTournamentUri) navigate("/tournament");
                else navigate("/");
            }
        );
    };

    const mobile_menu_wrap = (
        <div className={classes.mobile_menu_warp}>
            <ul>
                <li className={classes.menu} onClick={LnbToggleHandler}>
                    menu 열기
                </li>
                <li className={classes.mypage}>
                    <NavLink to="/myPage">MYPAGE</NavLink>
                </li>
            </ul>
        </div>
    );

    const slide_lnb = (
        <div
            className={`${classes.slide_lnb_wrap} ${
                lnbToggle ? classes.on : null
            }`}
        >
            <div className={classes.slide_lnb_box}>
                <div className={classes.slide_lnb_header}>
                    <div className={classes.logo}>(주)진승</div>
                    <Button className="btn_x" onClick={LnbToggleHandler} />
                </div>
                <div className={classes.mypage_wrap}>
                    <ul>
                        <li>
                            <NavLink to="/login" onClick={LnbToggleHandler}>
                                로그인
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/signUp" onClick={LnbToggleHandler}>
                                회원가입
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/myPage" onClick={LnbToggleHandler}>
                                마이페이지
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );

    return (
        <>
            <header
                className={
                    isTournamentUri
                        ? `${classes[headerClass]} ${classes.header} ${classes.main_header} ${classes.tournament_header_wrap}`
                        : `${classes[headerClass]} ${classes.header} ${classes.main_header}`
                }
            >
                <div className={classes.header_wrap}>
                    {isTournamentUri ? (
                        <>
                            <h1 className={classes.header_tournament_logo}>
                                <NavLink to="/tournament">
                                    <span>진</span>승볼링대회
                                </NavLink>
                            </h1>
                            <div className={classes.go_home}>
                                <NavLink to="/">go_home</NavLink>
                            </div>
                            {mobile_menu_wrap}
                        </>
                    ) : (
                        <>
                            <h1 className={classes.header_logo}>
                                <NavLink to="/">(주)진승</NavLink>
                            </h1>
                            <div className={classes.go_tournament_home}>
                                <NavLink to="/tournament">대회</NavLink>
                            </div>
                            {mobile_menu_wrap}
                        </>
                    )}
                </div>

                <div
                    className={`${classes.slide_lnb_wrap} ${
                        lnbToggle ? classes.on : null
                    }`}
                >
                    <div className={classes.slide_lnb_box}>
                        <div className={classes.slide_lnb_header}>
                            {isTournamentUri ? (
                                <div className={classes.header_tournament_logo}>
                                    <NavLink
                                        to="/tournament"
                                        onClick={LnbToggleHandler}
                                    >
                                        <span>진</span>승볼링대회
                                    </NavLink>
                                </div>
                            ) : (
                                <div className={classes.logo}>
                                    <NavLink to="/" onClick={LnbToggleHandler}>
                                        (주)진승
                                    </NavLink>
                                </div>
                            )}
                            <Button
                                className="btn_x"
                                onClick={LnbToggleHandler}
                            />
                        </div>
                        <div className={classes.mypage_wrap}>
                            <ul>
                                {!isUserLogin && (
                                    <li className={classes.login}>
                                        <NavLink
                                            to="/login"
                                            onClick={LnbToggleHandler}
                                        >
                                            로그인
                                        </NavLink>
                                    </li>
                                )}
                                {!isUserLogin && (
                                    <li className={classes.sign}>
                                        <NavLink
                                            to="/signUp"
                                            onClick={LnbToggleHandler}
                                        >
                                            회원가입
                                        </NavLink>
                                    </li>
                                )}
                                {isUserLogin && (
                                    <li className={classes.logout}>
                                        <a
                                            to="/signUp"
                                            onClick={signOutHandler}
                                        >
                                            로그아웃
                                        </a>
                                    </li>
                                )}
                                <li className={classes.my}>
                                    <NavLink
                                        to="/myPage"
                                        onClick={LnbToggleHandler}
                                    >
                                        마이페이지
                                    </NavLink>
                                </li>
                                {isUserLogin && (
                                    <li className={classes.cs}>
                                        <NavLink
                                            to="/myPage/contact/help"
                                            onClick={LnbToggleHandler}
                                        >
                                            1:1 문의
                                        </NavLink>
                                    </li>
                                )}
                            </ul>
                        </div>
                        <div className={classes.lnb_nav}>
                            {isTournamentUri ? tournamentMenu : userMenu}
                        </div>
                        <div className={classes.sns_nav}>
                            {isTournamentUri ? (
                                <div className={classes.to_home}>
                                    <Link to="/" onClick={LnbToggleHandler}>
                                        진승 공식사이트
                                    </Link>
                                </div>
                            ) : null}
                            <ul>
                                <li className={classes.mall}>
                                    <a
                                        href="https://bowlingkoreamall.com"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        진승MALL
                                    </a>
                                </li>
                                <li className={classes.insta}>
                                    <a
                                        className={classes.insta}
                                        href="https://www.instagram.com/stormrotokorea"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        인스타그램
                                    </a>
                                </li>
                                <li className={classes.youtube}>
                                    <a
                                        className={classes.youtube}
                                        href="https://www.youtube.com/@stormrotokorea"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        유튜브
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                {lnbToggle ? <div className={classes.dim}></div> : null}
            </header>
        </>
    );
}

export default MainNavigationApp;
