import { useContext, useEffect, useState } from "react";
import {
    ReqApplyDrawTournament,
    ReqApplyOofATournament,
    ReqFindGroupName,
    ReqFindReasearchList,
    ReqMemberPersonalInfo,
    ReqRemainTicketsForDraw,
    ReqRemainTicketsForOofa,
} from "../../../api/home/TournamentHomeApplyApi";
import Button from "../../../components/UI/Button/Button";
import Checkbox from "../../../components/UI/Input/Checkbox";
import Input from "../../../components/UI/Input/Input";
import Alert from "../../../components/UI/Modal/Alert";
import Modal from "../../../components/UI/Modal/Modal";
import SelectBoxNew from "../../../components/UI/SelectBox/SelectBoxNew";
import {
    DefaultDisableSelectBox,
    DefaultGroupNameSelectBox,
} from "../../../components/definedefault/DefSelectBoxValues";
import { JwtTokenContext } from "../../../store/JwtTokenProvider";
import classes from "./../TournamentContainerApp.module.css";
import ApplyTournamentCompleteApp from "./ApplyTournamentCompleteApp";
import TermsModalContentsApp from "./TermsModalContentsApp";
import SelectBoxTicket from "../../../components/UI/SelectBox/SelectBoxTicket";

function ApplyTournamentFormApp(props) {
    const loginCtx = useContext(JwtTokenContext);
    const token = loginCtx.getToken().token;
    const memberId = loginCtx.decodedToken ? loginCtx.decodedToken.jti : null;
    const { contestId, placeId, onApplyModalHandler } = props;
    const [groupOptionDatas, setGoroupOptionDatas] = useState([DefaultGroupNameSelectBox]);
    const [researchList, setResearchList] = useState([]);
    const [memberInfo, setMemberInfo] = useState({});
    const [isAgreePersonalInfo, setIsAgreePersonalInfo] = useState(false);
    const disableDatas = DefaultDisableSelectBox;
    const [showModal, setShowModal] = useState(false);
    const [modalText, setModalText] = useState("");
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [showApplyModal, setShowApplylModal] = useState(false);

    const viewBirthDate = memberInfo.birthDate
        ? `${memberInfo.birthDate.slice(0, 4)}년 ${memberInfo.birthDate.slice(4, 6)}월 ${memberInfo.birthDate.slice(
              6,
              8
          )}일`
        : "";
    const birthDateRegex = /^\d{4}(0[1-9]|1[0-2])(0[1-9]|[1-2][0-9]|3[01])$/;
    const birthDateIsValid = birthDateRegex.test(memberInfo.birthDate);
    const viewPhoneNumber = memberInfo.memberPhoneNumber;

    //Alert창 상태, 텍스트 관리
    const onShowModalHandler = (state, text) => {
        setShowModal(state);
        setModalText(text);
    };

    //그룹(조) 선택
    const [selectedGroup, setSelectedGroup] = useState();
    const onSelectedGroupHandler = (key) => {
        setSelectedGroup(key);
    };

    //장애여부 선택
    const [disableStatus, setDisableStatus] = useState("");
    const onDisableStatusHandler = (key) => {
        setDisableStatus(key);
    };

    //현재시간 구하기
    const moment = require("moment");
    function getCurrentDateTime() {
        const now = moment();
        return now.format("YYYYMMDDHHmmss");
    }

    //개인정보 이용 동의서 상태관리
    const personalInfoHandler = () => {
        setIsAgreePersonalInfo((state) => !state);
    };

    // 대회신청완료 모달 관리
    const [showCompleteApply, setShowCompleteApply] = useState(false);
    const onShowCompleteApplyHandler = (state) => {
        setShowCompleteApply(state);
    };

    //대회 신청 완료 후 신청모달, 완료모달 닫기
    const closeAllModalHandler = () => {
        onApplyModalHandler(false);
        onShowCompleteApplyHandler(false);
    };

    const ticketData = {
        cmd: "remain_tickets",
        token: token,
        contest_id: contestId,
        place_id: placeId,
    };

    const findGroupNameHandler = async (contestId, placeId) => {
        const groupResponse = await ReqFindGroupName(contestId, placeId);
        const groupData = await groupResponse.map((data) => {
            return {
                key: String(data.id),
                value: data.name ? data.name + " 조" : data.seq + " 조",
                disabled: false,
            };
        });

        if (props.ctAppType === "OofA") {
            const ticketResponse = await ReqRemainTicketsForOofa(ticketData);
            const resData = await ticketResponse.data;
            if (resData.count.length > 0) {
                const curData = resData.count.map((data) => {
                    return {
                        count: data,
                    };
                });

                const combinedArray = groupData.map((group, index) => {
                    return {
                        ...group,
                        count: curData[index].count,
                        disabled: curData[index].count === "0",
                        type: "OofA",
                    };
                });
                setGoroupOptionDatas([DefaultGroupNameSelectBox, ...combinedArray]);
            }
        }

        if (props.ctAppType === "DRAW") {
            const ticketResponse = await ReqRemainTicketsForDraw(contestId, placeId);
            const resData = await ticketResponse.data;
            if (resData.remains) {
                const data = resData.remains.split(",");
                const curData = data.map((data) => {
                    return {
                        count: data,
                    };
                });

                const combinedArray = groupData.map((group, index) => {
                    return {
                        ...group,
                        count: curData[index].count,
                        disabled: false,
                        type: "DRAW",
                    };
                });

                setGoroupOptionDatas([DefaultGroupNameSelectBox, ...combinedArray]);
            }
        }
    };

    //개인정보 수집, 이용 동의 모달 열기
    const [showPersonalInfo, setShowPersonalInfo] = useState(false);
    const onShowPersonalInfoHandler = (state) => {
        setShowPersonalInfo(state);
    };

    //접수페이지, 조사 항목 Input 상태를 관리하기 위한 함수
    //InputBox 또는 SelectBox 상태가 변경되면 해당 함수가 실행된다.
    //서버에서 오는 데이터 상태에 따라 Input 인지 SelectBox인지 결정
    const inputBoxChangeHandler = (value, boxName) => {
        const copyList = [];
        researchList.forEach((v) => {
            const copy = { ...v };
            copy.values = [...v.values];
            copyList.push(copy);
        });
        const selectedBox = copyList.filter((v) => v.title === boxName);
        selectedBox[0].default_value = value;
        setResearchList((prev) => copyList);
    };

    //접수페이지, 조사항목 SelectBox 상태를 관리하기 위한 함수
    //InputBox 또는 SelectBox 상태가 변경되면 해당 함수가 실행된다.
    //서버에서 오는 데이터 상태에 따라 Input 인지 SelectBox인지 결정
    const selectBoxNewCallback = function (key, boxName) {
        const copyList = [];
        researchList.forEach((v) => {
            const copy = { ...v };
            copy.values = [...v.values];
            copyList.push(copy);
        });
        const selectedBox = copyList.filter((v) => v.title === boxName);
        selectedBox[0].default_value = key;
        setResearchList((prev) => copyList);
    };

    const findResearchListHandler = (contestId) => {
        ReqFindReasearchList(
            contestId,
            (result) => {
                const curData = result.map((data, index) => {
                    const array = [];
                    const default_value = data.default_value !== "" ? data.default_value.split(",") : [];
                    default_value.forEach((value, index) => {
                        array.push({ key: value, value: value });
                    });
                    return {
                        seq: data.seq,
                        title: data.title,
                        default_value: array.length === 0 ? "" : array[0].key,
                        values: array,
                    };
                });
                setResearchList(curData);
            },
            (error_result) => {
                setResearchList((prev) => prev);
            }
        );
    };

    const findMemberInfoHandler = (memberId) => {
        ReqMemberPersonalInfo(
            memberId,
            (result) => {
                const curData = {
                    memberName: result.member_name,
                    memberPhoneNumber:
                        result.member_phone_number.indexOf("-") === -1
                            ? `${result.member_phone_number.slice(0, 3)}-${result.member_phone_number.slice(
                                  3,
                                  7
                              )}-${result.member_phone_number.slice(7, 11)}`
                            : result.member_phone_number,
                    gender: result.gender,
                    birthDate: result.birth_date,
                };
                setMemberInfo(curData);
            },
            (error_result) => {
                setMemberInfo((prev) => prev);
            }
        );
    };

    //신청 로직 데이터
    const processingData = researchList.map((data) => data.default_value);
    const drawData = {
        member_id: memberId,
        contest_id: contestId,
        place_id: placeId,
        group_id: selectedGroup,
        disabled: disableStatus,
        female: memberInfo.gender === "F" ? "Y" : "N",
        member_name: memberInfo.memberName,
        phone_number: memberInfo.memberPhoneNumber?.replace(/-/g, ""),
        birth_date: memberInfo.birthDate,
        apply_date: getCurrentDateTime(),
        search_info_list: processingData,
    };

    // 대회 신청하기 함수 및 접수완료 모달 보여주기
    const applyIsValidHandler = () => {
        if (!birthDateIsValid) {
            alert("생년월일이 정확하지 않습니다. 개인정보 수정 신청을 해주세요.");
            return;
        }
        if (!selectedGroup) {
            onShowModalHandler(true, "조를 선택해주세요.");
            return;
        }
        if (disableStatus === "") {
            onShowModalHandler(true, "장애여부를 선택해주세요.");
            return;
        }
        if (!isAgreePersonalInfo) {
            onShowModalHandler(true, "개인정보 수집, 이용 동의에 동의해주세요.");
            return;
        }
        const filterArray = researchList.filter((data) => {
            return data.default_value.trim() === "";
        });
        //비어있는 배열의 크기가 0보다 크면 입력안한것.
        if (filterArray.length > 0) {
            onShowModalHandler(true, "필수 추가 입력 항목을 작성해주세요.");
            return;
        }
        setShowApplylModal(true);
    };

    //추첨식 신청 핸들러
    const applyDrawContestHandler = () => {
        ReqApplyDrawTournament(
            drawData,
            (result) => {
                onShowCompleteApplyHandler(true);
                return;
            },
            (error_result) => {
                alert(error_result.error);
                return;
            }
        );
    };

    const oofaData = {
        member_id: memberId,
        contest_id: contestId,
        place_id: placeId,
        group_id: selectedGroup,
        phone_number: memberInfo.memberPhoneNumber,
        female: memberInfo.gender === "F" ? "Y" : "N",
        disabled: disableStatus,
        member_name: memberInfo.memberName,
        birth_date: memberInfo.birthDate,
        search_info_list: processingData,
    };

    //선착순 신청 핸들러
    const applyOofAContestHandler = async () => {
        if (!birthDateIsValid) {
            alert("생년월일이 정확하지 않습니다. 개인정보 수정 신청을 해주세요.");
            return;
        }
        const response = await ReqApplyOofATournament(oofaData, token);
        const resData = await response.data;
        if (resData.status === 200 && resData.ticket) {
            onShowCompleteApplyHandler(true);
            return;
        }
        if (!resData.ticket || resData.status === 400 || resData.status === 500 || resData.status === 403) {
            if (resData.reason) {
                onShowModalHandler(true, `${resData.description} 사유:${resData.reason}`);
                return;
            } else {
                onShowModalHandler(true, `${resData.description}`);
                return;
            }
        }
    };

    useEffect(() => {
        findGroupNameHandler(contestId, placeId); //그룹가져오기
        findResearchListHandler(contestId); //추가조사항목가져오기
        findMemberInfoHandler(memberId); //토큰에담긴 memberId로 서버에서 개인정보 가져오기
    }, [contestId, placeId, memberId]);

    const researchView = (
        <>
            {researchList.map((data, index) => (
                <tr key={index}>
                    <th>{data.title}</th>
                    <td>
                        {data.values.length === 0 && (
                            <div className="flex_row">
                                <Input className="admin_st" onChange={inputBoxChangeHandler} boxName={data.title} />
                                <span className="red_c">
                                    {data.default_value === "" ? `${data.title} 항목을 입력해주세요` : ""}
                                </span>
                            </div>
                        )}
                        {data.values.length > 0 && (
                            <SelectBoxNew
                                className="default"
                                key={index}
                                setSelectItemCB={selectBoxNewCallback}
                                optionDatas={data.values}
                                showDefaultKey={data.default_value}
                                boxName={data.title}
                            />
                        )}
                    </td>
                </tr>
            ))}
        </>
    );

    return (
        <>
            <main className={classes.applyForm}>
                {/* 모바일에서 div.mobile_modal_table 추가 */}
                <div className={classes.mobile_modal_table}>
                    <table>
                        <colgroup>
                            <col style={{ width: "160px" }} />
                            <col style={{ width: "460px" }} />
                        </colgroup>
                        <tbody>
                            <tr>
                                <th>대회</th>
                                <td>{props.ctTitle}</td>
                            </tr>
                            <tr>
                                <th>경기장</th>
                                <td>
                                    {props.region}({props.centerName})
                                </td>
                            </tr>
                            <tr>
                                <th>조</th>
                                <td>
                                    <SelectBoxTicket
                                        className="default"
                                        optionDatas={groupOptionDatas}
                                        setSelectItemCB={onSelectedGroupHandler}
                                        showDefaultKey={selectedGroup}
                                    />
                                    {selectedGroup ? "" : <p className={classes.error_text}>조를 선택해주세요.</p>}
                                </td>
                            </tr>
                            <tr>
                                <th>이름</th>
                                <td>{memberInfo.memberName}</td>
                            </tr>
                            <tr>
                                <th>연락처</th>
                                <td>
                                    <div className={classes.input_group}>{viewPhoneNumber}</div>
                                </td>
                            </tr>
                            <tr>
                                <th>성별</th>
                                <td>{memberInfo.gender === "M" ? "남자" : "여자"}</td>
                            </tr>
                            <tr>
                                <th>장애여부</th>
                                <td>
                                    <SelectBoxNew
                                        className="default"
                                        optionDatas={disableDatas}
                                        setSelectItemCB={onDisableStatusHandler}
                                        showDefaultKey={disableStatus}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>생년월일</th>
                                <td>{viewBirthDate}</td>
                            </tr>
                            {researchView}
                        </tbody>
                    </table>
                </div>
                <div className={classes.terms_agree_wrap}>
                    <p className={classes.terms_agree}>
                        <span>개인정보 수집, 이용 동의 : </span>
                        <Button
                            className="btn_text"
                            ctAgreementInfo={props.ctAgreementInfo}
                            onClick={() => onShowPersonalInfoHandler(true)}
                        >
                            상세보기
                        </Button>
                    </p>
                    <Checkbox
                        id="agree_check"
                        title="참가 신청정보를 확인하였으며, 개인정보 수집, 이용에 동의합니다."
                        onChange={personalInfoHandler}
                    />
                    {showPersonalInfo && (
                        <Modal close={() => onShowPersonalInfoHandler(false)} header="개인정보 수집, 이용 동의 ">
                            <TermsModalContentsApp
                                ctAgreementInfo={props.ctAgreementInfo}
                                close={() => onShowPersonalInfoHandler(false)}
                            />
                        </Modal>
                    )}
                    {showCompleteApply && (
                        <Modal close={() => closeAllModalHandler()} header="대회접수완료">
                            <ApplyTournamentCompleteApp
                                placeId={placeId}
                                ctTitle={props.ctTitle}
                                centerName={props.centerName}
                                region={props.region}
                                groupOptionDatas={groupOptionDatas}
                                selectedGroup={selectedGroup}
                                memberName={memberInfo.memberName}
                                viewPhoneNumber={viewPhoneNumber}
                                gender={memberInfo.gender}
                                viewBirthDate={viewBirthDate}
                                disabled={disableStatus}
                                researchList={researchList}
                                centerDefaultAddress={props.centerDefaultAddress}
                                centerDetailedAddress={props.centerDetailedAddress}
                                close={() => closeAllModalHandler()}
                            />
                        </Modal>
                    )}
                    {showModal && (
                        <Alert type="alert" confirm="확인" close={() => onShowModalHandler(false)}>
                            {modalText}
                        </Alert>
                    )}
                </div>
            </main>
            <footer>
                <Button className="sm" onClick={() => setShowCancelModal(true)}>
                    취소
                </Button>
                <Button className="secondary sm" onClick={applyIsValidHandler}>
                    접수
                </Button>
                {showCancelModal && (
                    <Alert
                        type="confirm"
                        cancel="돌아가기"
                        confirm="접수취소"
                        close={() => setShowCancelModal(false)} // 모달 닫기
                        confirmHandler={() => onApplyModalHandler(false)} // 확인 버튼 눌렀을 때 실행되는 Handler
                    >
                        {"취소 하시겠습니까?"}
                    </Alert>
                )}
                {showApplyModal && props.ctAppType === "DRAW" && (
                    <Alert
                        type="confirm"
                        cancel="취소"
                        confirm="접수완료"
                        close={() => setShowApplylModal(false)} // 모달 닫기, 추첨식 접수
                        confirmHandler={() => applyDrawContestHandler()} // 확인 버튼 눌렀을 때 실행되는 Handler
                    >
                        {"접수 하시겠습니까?"}
                    </Alert>
                )}
                {showApplyModal && props.ctAppType === "OofA" && (
                    <Alert
                        type="confirm"
                        cancel="취소"
                        confirm="접수완료"
                        close={() => setShowApplylModal(false)} // 모달 닫기, 선착순 접수 (.온점있으면 선착순)
                        confirmHandler={() => applyOofAContestHandler()} // 확인 버튼 눌렀을 때 실행되는 Handler
                    >
                        {"접수 하시겠습니까?"}
                    </Alert>
                )}
            </footer>
        </>
    );
}
export default ApplyTournamentFormApp;
