import { useRef } from "react";
import Button from "../Button/Button";
import classes from "./FileBox.module.css";

function FileBoxMj({ onFileChange, selectedFileName }) {
    const fileInputRef = useRef(null);

    const handleFileInputChange = (event) => {
        const file = event.target.files[0];
        onFileChange(file);
    };

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    return (
        <div className={classes.file_box}>
            <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileInputChange}
            />
            <Button className="border sm" onClick={handleButtonClick}>
                파일 선택
            </Button>
            {/* {selectedFileName ? ( */}
            <input
                className={classes.file_name}
                value={selectedFileName}
                placeholder="선택된 파일 없음"
                readOnly
            />
            {/* ) : (
                <p>Current File: {selectedFileName}</p>
            )} */}
        </div>
    );
}

export default FileBoxMj;
