import instance from "../../store/AxiosInterceptor";

/**
 * 유저 마이페이지 >> 이벤트 접수내역
 * @returns
 */
export function ReqFindMyEvent(queryText, resolveCB, rejectCB) {
    instance({
        url: `/api/v1/home/mypage/event?queryText=${queryText}&page=0&size=10`,
        method: "GET",
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data.datas);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/**
 * 유저 마이페이지 >> 이벤트 접수내역
 * @returns
 */
export function ReqPagingFindMyEvent(queryText, page, resolveCB, rejectCB) {
    instance({
        url: `/api/v1/home/mypage/event?queryText=${queryText}&page=${page}&size=10`,
        method: "GET",
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data.datas);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}