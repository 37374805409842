import React from "react";
import Button from "../../../../components/UI/Button/Button";
import Input from "../../../../components/UI/Input/Input";
import classes from "../../MngCommon.module.css";
import Radio from "../../../../components/UI/Input/Radio";
import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import { ReqContestType } from "../../../../api/common/ConditionSelectBoxApi";
import { DefaultAll } from "../../../../components/definedefault/DefSelectBoxValues";
import SelectBoxNew from "../../../../components/UI/SelectBox/SelectBoxNew";
import { ReqCreateNoticesForAdmin } from "../../../../api/board/BoardApi";
import QuillEditor from "../../../../components/UI/Editer/QuillEditor";
import { JwtTokenContext } from "../../../../store/JwtTokenProvider";
import FileBoxMj from "../../../../components/UI/Input/FileBoxMj";

const MngTournamentNoticeUpload = () => {
    const navigate = useNavigate();
    const loginCtx = useContext(JwtTokenContext);
    const [enteredTitle, setEnteredTitle] = useState("");
    const [content, setContent] = useState("");
    const [topNotice, setTopNotice] = useState("N");
    const [useNotice, setUseNotice] = useState("N");
    const [fileBoxes, setFileBoxes] = useState([]);
    const [files, setFiles] = useState([]); // 배열로 파일 관리
    const [fileNames, setFileNames] = useState([]); // 파일 이름을 관리하는 배열

    // <<< 대회 타입 가져오기(Select Box) */
    const [categoryData, setCategoryData] = useState([{}]);
    const [type, setType] = useState("K");

    const titleChangeHandler = (e) => {
        setEnteredTitle(e);
    };
    const contentChangeHandler = (content) => {
        setContent(content);
    };

    // 상단공지여부 라디오버튼
    const topNoticesHandler = (e) => {
        setTopNotice(e.target.value);
    };
    // 사용여부 라디오버튼
    const useNoticesHandler = (e) => {
        setUseNotice(e.target.value);
    };

    // 새 항목을 추가
    const handleAddFileBox = () => {
        setFileBoxes([...fileBoxes, null]);
        setFiles([...files, null]);
        setFileNames([...fileNames, ""]);
    };

    // 맨 마지막 항목을 삭제
    const handleDelFileBox = (index) => {
        if (fileBoxes.length === 0) {
            return; // 항목이 없을 경우 종료
        }
        
        const newFileBoxes = [...fileBoxes];
        newFileBoxes.pop(); // 맨 마지막 항목 삭제
        setFileBoxes(newFileBoxes);

        const newFiles = [...files];
        newFiles.pop();
        setFiles(newFiles);

        const newFileNames = [...fileNames];
        newFileNames.pop();
        setFileNames(newFileNames);
    };

    // 파일 변경 이벤트 핸들러
    const handleFileChange = (file, index) => {
        const newFiles = [...files];
        newFiles[index] = file;
        setFiles(newFiles);

        const newFileNames = [...fileNames];
        newFileNames[index] = file ? file.name : "";
        setFileNames(newFileNames);
    };

    // 등록일 (오늘 날짜 표시)
    const today = () => {
        const now = new Date();

        return (
            now.getFullYear() +
            "-" +
            ("0" + (now.getMonth() + 1)).slice(-2) +
            "-" +
            now.getDate()
        );
    };

    // 대회 유형 select box
    const typeHandler = (key) => {
        setType(key);
    };

    useEffect(() => {
        // 컴포넌트 첫 로드시에만 카테고리 목록, 지역 목록을 가져온다.
        ReqContestType(
            (result) => {
                const category = result.map((data) => {
                    return {
                        key: data.type,
                        value: data.name,
                        disabled: false,
                    };
                });
                setCategoryData((prev) => [...category]);
            },
            (result) => {
                setCategoryData((prev) => [DefaultAll]);
            }
        );
    }, []);

    const submitHandler = async (e) => {
        e.preventDefault();

        const jsonData = JSON.stringify({
            title: enteredTitle,
            content: content,
            exposeYn: useNotice,
            openOtherYn: useNotice,
            regID: "admin",
            ct_type: type,
            noticeYn: topNotice,
            boardWriteType: "NOMAL",
        });

        // alert(jsonData);
        const blob = new Blob([jsonData], { type: "application/json" });

        const formData = new FormData();
        files.forEach((image, index) => {
            formData.append("image", image);
        });
        formData.append("body", blob);

        const response = await ReqCreateNoticesForAdmin("contestNotice", formData);
        if (response.status === 200) {
            navigate("/admin/tournament/notice");
        } else {
            alert(
                `[${response.status}] ${
                    response.data ? response.data.error : "등록 실패"
                } `
            );
        }
    };

    return (
        <section>
            <div className={classes.admin_contents}>
                {/* ↓ 상태에 따라 등록 / 수정 */}
                <h3>대회공지 - 등록</h3>
                <div
                    className={`${classes.contents_wrap} ${classes.search_wrap}`}
                >
                    <div className={classes.admin_table_wrap}>
                        <table>
                            <colgroup>
                                <col style={{ width: "10%" }} />
                                <col style={{ width: "40%" }} />
                                <col style={{ width: "10%" }} />
                                <col style={{ width: "40%" }} />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>등록일</th>
                                    <td>{today()}</td>
                                    <th>등록자</th>
                                    <td>{loginCtx.decodedToken.jti}</td>
                                </tr>
                                <tr>
                                    <th>상단공지여부</th>
                                    <td>
                                        <div className={classes.radio_wrap}>
                                            <Radio
                                                value="Y"
                                                title="적용"
                                                name="rad_01"
                                                id="rad_01_01"
                                                checked={topNotice === "Y"}
                                                onChange={topNoticesHandler}
                                            />
                                            <Radio
                                                value="N"
                                                title="미적용"
                                                name="rad_01"
                                                id="rad_01_02"
                                                checked={topNotice === "N"}
                                                onChange={topNoticesHandler}
                                            />
                                        </div>
                                    </td>
                                    <th>사용여부</th>
                                    <td>
                                        <div className={classes.radio_wrap}>
                                            <Radio
                                                value="Y"
                                                title="사용"
                                                name="rad_02"
                                                id="rad_02_01"
                                                checked={useNotice === "Y"}
                                                onChange={useNoticesHandler}
                                            />
                                            <Radio
                                                value="N"
                                                title="미사용"
                                                name="rad_02"
                                                id="rad_02_02"
                                                checked={useNotice === "N"}
                                                onChange={useNoticesHandler}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>유형</th>
                                    <td colSpan={3}>
                                        <div className={classes.flex_row}>
                                            <SelectBoxNew
                                                className="admin_st"
                                                setSelectItemCB={typeHandler}
                                                optionDatas={categoryData}
                                                showDefaultKey={type}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>제목</th>
                                    <td colSpan={3}>
                                        <Input
                                            type="text"
                                            name="title"
                                            id="title"
                                            className="admin_st"
                                            placeholder="제목 입력"
                                            value={enteredTitle}
                                            onChange={titleChangeHandler}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={4}>
                                        <div style={{ height: "500px" }}>
                                            <QuillEditor
                                                height="460"
                                                width="auto"
                                                value={content}
                                                onValueChangeCB={
                                                    contentChangeHandler
                                                }
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>첨부파일</th>
                                    <td colSpan={3}>
                                        {/* <FileBoxMj
                                            onFileChange={handleFileChange}
                                            selectedFileName={fileName}
                                        /> */}
                                        <Button className="border sm" onClick={handleAddFileBox}>항목추가하기</Button>
                                        <Button className="secondary sm" onClick={handleDelFileBox}>항목삭제하기</Button>
                                        <div>
                                            {fileBoxes.map((item, index) => (
                                                <div key={index}>
                                                    <FileBoxMj
                                                        onFileChange={(file) => handleFileChange(file, index)}
                                                        selectedFileName={fileNames[index]}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className={classes.bottom_btn_wrap}>
                            <Button
                                className="border sm"
                                onClick={submitHandler}
                            >
                                등록
                            </Button>
                            <Link to="/admin/tournament/notice">
                                <Button className="secondary sm">목록</Button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default MngTournamentNoticeUpload;
