import { useEffect, useState } from "react";
import classes from "./Terms.module.css";
import SelectBoxSearch from "../../components/UI/SelectBox/SelectBoxNew";
import PrivacyPolicyDetail from "./PrivacyPolicyDetail";
import instance from "../../store/AxiosInterceptor";
import Moment from "moment";
import { useLoaderData } from "react-router";
import SelectBoxNew from "../../components/UI/SelectBox/SelectBoxNew";

function PrivacyPolicyPage(props) {

    const term = useLoaderData();

    const [termsId, setTermsId] = useState(term.data.id);
    const [termsContent, setTermsContent] = useState(term.data.content);
    const [initTermsDate, setInitTermsDate] = useState(term.initTermsDate);
     
    const [categoryKey, setCategoryKey] = useState();
    const setSelectItemCB = async (key) => {
        setTermsId(key)
        setCategoryKey(key);
        const response = await instance.get(`/api/v1/home/terms/detail/${key}`);
        if (response.status === 200) {
            const resData = await response.data;
            setTermsContent(resData.content)
        } else {
            setTermsContent([]);
        }
    };

    return (
        <div className={`container ${classes.terms_wrap}`}>
            <div className={classes.sub_header}>
                <h2>개인정보취급방침</h2>
            </div>
            <div className={classes.inner}>
                <div className={classes.revision_date}>
                    <p>개정일</p>
                    <SelectBoxNew
                        optionDatas={initTermsDate}
                        setSelectItemCB={setSelectItemCB}
                        showDefaultKey={categoryKey}
                    />
                </div>
                <PrivacyPolicyDetail contentCB={termsContent}/>
            </div>
        </div>
    );
}

export default PrivacyPolicyPage;

export const loader = async () => {
    const response = await instance.get(`/api/v1/home/terms/Terms`)

    if (response.data.status === 200) {
        const resData = await response.data;

        const responseDate = await instance.get(`/api/v1/home/terms/${resData.type}/${resData.id}`);
        const resDataDate = await responseDate.data.datas;
        const initTermsDate = resDataDate.map((data) => ({
            value: Moment(data.regDate).format('YYYY-MM-DD'),
            key: String(data.id),
        }));
        return { data: resData, initTermsDate: initTermsDate };
    } else {
        return [];
    }
};
