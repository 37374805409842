import BreadCrumb from "../../components/UI/BreadCrumb/BreadCrumb";
import Input from "../../components/UI/Input/Input";
import classes from "./Kegel.module.css";

import React, { useEffect, useState } from "react";
import Button from "../../components/UI/Button/Button";
import instance from "../../store/AxiosInterceptor";
import { Link, useLoaderData } from "react-router-dom";
import { ReqHomeKegel } from "../../api/kegel/KegelAPi";
import Alert from "../../components/UI/Modal/Alert";
import KegelCard from "./KegelCard";

function LaneMachinesPage(props) {
    const [currentPage, setCurrentPage] = useState(0);
    const [KegelList, setKegelList] = useState([]);
    const [searchText, setSearchText] = useState("");
    const resData = useLoaderData().data;
    const menuName = useLoaderData().params;

    const kegelTypeMapping = {
        machine: "레인정비기계",
        consumable: "레인정비소모품",
        training_materials: "훈련 도구",
    };

    const mappedKegelType = kegelTypeMapping[menuName] || menuName;

    console.log("resData", resData);

    // //모달 상태 관리
    const [showConfirmation, setShowConfirmation] = useState(false);
    const onChangeShowConfirmation = (state) => {
        setShowConfirmation(state);
    };

    const onChangePageHandler = () => {
        setCurrentPage((prev) => prev + 1);
    };

    const onSearchTextHandler = (value) => {
        setSearchText(value);
    };

    const findKegelListSearchHandler = () => {
        setCurrentPage(0);
        ReqHomeKegel(
            menuName,
            0,
            searchText,
            (result) => {
                if (result.length === 0) {
                    onChangeShowConfirmation(true);
                    return;
                }
                const curData = result.map((data) => {
                    return {
                        id: data.id,
                        kegelType: data.kegelType,
                        title: data.title,
                        subDescription: data.subDescription,
                        file_uri: data.file_uri,
                    };
                });
                setKegelList(curData);
            },
            (error_result) => {
                setKegelList((prev) => prev);
            }
        );
    };

    const findKegelListPageHandler = () => {
        onChangePageHandler();
        ReqHomeKegel(
            menuName,
            currentPage + 1,
            searchText,
            (result) => {
                const curData = result.map((data) => {
                    return {
                        id: data.id,
                        kegelType: data.kegelType,
                        title: data.title,
                        subDescription: data.subDescription,
                        file_uri: data.file_uri,
                    };
                });
                setKegelList((prev) => [...prev, ...curData]);
            },
            (error_result) => {
                setKegelList((prev) => prev);
            }
        );
    };

    useEffect(() => {
        const curData = resData.map((data) => {
            return {
                id: data.id,
                kegelType: data.kegelType,
                title: data.title,
                subDescription: data.subDescription,
                file_uri: data.file_uri,
            };
        });
        setKegelList(curData);
    }, [resData]);

    //  breadCrumb sample data
    const breadCrumbList = ["케겔", mappedKegelType];

    return (
        <>
            <BreadCrumb breadCrumbList={breadCrumbList} />
            <div className={classes.contents_wrap}>
                <div className={classes.contents_title}>
                    <h3>{mappedKegelType}</h3>
                    <div className={classes.search_wrap}>
                        <Input
                            placeholder="검색어를 입력해주세요."
                            onChange={onSearchTextHandler}
                        />
                        <Button
                            className="search_sm"
                            onClick={findKegelListSearchHandler}
                        />
                    </div>
                </div>
                <div className={classes.card_board}>
                    {KegelList.map((data) => (
                        <KegelCard key={data.id} data={data} />
                    ))}
                </div>

                <div className={classes.bottom_center_btn}>
                    <Button
                        className="text_icon"
                        onClick={findKegelListPageHandler}
                    >
                        더보기
                    </Button>
                </div>
                {showConfirmation && (
                    <Alert
                        type="alert"
                        confirm="확인"
                        close={() => onChangeShowConfirmation(false)}
                    >
                        {"일치하는 결과가 없습니다."}
                    </Alert>
                )}
            </div>
        </>
    );
}

export default LaneMachinesPage;

export const loader = async (param) => {
    let params = param.params.machine;
    // await 사용으로 수정
    const response = await instance.get(
        `/api/v1/home/kegel/${params}?page=0&size=5`
    );

    const resData = response.data.datas.content;

    if (response.data.status === 200) {
        return {
            data: resData,
            params: params,
        };
    } else {
        return { data: [], params: params };
    }
};
