import { Link } from "react-router-dom";

import classes from "../TournamentApp.module.css";

const HomeTournamentNoticeApp = (props) => {
    let newType = classes.type_a;
    switch (props.type) {
        case "K": //왕중왕전
            newType = classes.type_a;
            break;
        case "L": //이벤트 대회
            newType = classes.type_b;
            break;
        case "A": //고객감사
            newType = classes.type_c;
            break;
        case "B": //챔피언십
            newType = classes.type_d;
            break;
        case "S":
            newType = classes.type_e;
            break;
    }

    return (
        <li>
            <Link to={`/tournament/notice/${props.id}`}>
                <span className={newType}>{props.typeTitle}</span>
                {props.title}
            </Link>
        </li>
    );
};

export default HomeTournamentNoticeApp;
