import React from "react";
import classes from "../../UserHomeApp.module.css";

const OngoingTournamentUserApp = (props) => {
    let newType = classes.type_a;
    switch (props.type) {
        case "K": //왕중왕전
            newType = classes.type_a;
            break;
        case "L": //이벤트 대회
            newType = classes.type_b;
            break;
        case "A": //고객감사
            newType = classes.type_c;
            break;
        case "B": //챔피언십
            newType = classes.type_d;
            break;
        case "S":
            newType = classes.type_e;
            break;
    }
    return (
        <li className={newType} onClick={props.onClick}>
            <span className={classes.title}>{props.title}</span>
            <span className={classes.name}>{props.name}</span>
            <span className={classes.location}>
                <i>{props.regionName}</i>
                {props.centerName}
            </span>
        </li>
    );
};

export default OngoingTournamentUserApp;
