import React from "react";
import { Link } from "react-router-dom";
import classes from "./Kegel.module.css";
import instance from "../../store/AxiosInterceptor";

const KegelCard = ({ data }) => {
    const increaseViews = (id) => {
        instance.put(`/api/v1/home/kegel/${id}/views`).catch((error) => {
            console.error("Error increasing views:", error);
        });
    };

    return (
        <Link to={`${data.id}`}>
            <div
                className={classes.card}
                onClick={() => increaseViews(data.id)}
            >
                <div className={classes.img_box}>
                    <img src={data.file_uri} alt="케겔 이미지" />
                </div>
                <div className={classes.text_box}>
                    <h4>{data.title}</h4>
                    <p>{data.subDescription}</p>
                </div>
            </div>
        </Link>
    );
};

export default KegelCard;
