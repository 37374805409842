import instance from "../../store/AxiosInterceptor";

/**
 * BO - 회원관리 - 회원정보 등록/변경 접수
 * 모든 신청 리스트를 가져온다.
 * @returns
 */
export function ReqFindMemberAll(resolveCB, rejectCB) {
    instance({
        url: `/api/v1/admin/member/list?page=0&size=10`,
        method: "GET",
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data.data);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/**
 * BO - 회원관리 - 회원정보 등록/변경 접수
 * 검색 조건에 해당하는 목록 리스트를 가져온다.
 * @returns
 */
export function ReqFindMemberSearch(searchObject, resolveCB, rejectCB) {
    instance({
        url: `/api/v1/admin/member/list?sDate=${searchObject.sDate}&eDate=${searchObject.eDate}&state=${searchObject.state}&query_type=${searchObject.query_type}&query_text=${searchObject.query_text}&page=0&size=10`,
        method: "GET",
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data.data);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/**
 * BO - 회원관리 - 회원정보 등록/변경 접수
 * 검색 조건에 해당하는 목록 리스트를 가져온다. 페이징
 * @returns
 */
export function ReqFindMemberPaging(searchObject, page, resolveCB, rejectCB) {
    instance({
        url: `/api/v1/admin/member/list?sDate=${searchObject.sDate}&eDate=${searchObject.eDate}&state=${searchObject.state}&query_type=${searchObject.query_type}&query_text=${searchObject.query_text}&page=${page}&size=10`,
        method: "GET",
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data.data);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}