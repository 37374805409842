import Button from "../../../../components/UI/Button/Button";
import Modal from "../../../../components/UI/Modal/Modal";
import classes from "../../MngCommon.module.css";
import ReactDOMServer from 'react-dom/server';

import { useEffect, useRef, useState } from "react";
import { ReqGroupData } from "../../../../api/common/ConditionSelectBoxApi";
import SelectBoxNew from "../../../../components/UI/SelectBox/SelectBoxNew";
import { DefaultSelect } from "../../../../components/definedefault/DefSelectBoxValues";
import instance from "../../../../store/AxiosInterceptor";
import Moment from "moment";
import PaginationNew from "../../../../components/UI/Table/PaginationNew";

const MngTournamentDetailPrintAttendModal = (props) => {
  const { isAttendModalOpenCB, contestCB } = props;

  const [numData, setNumData] = useState([DefaultSelect]);
  const [numDataType, setNumDataType] = useState("");
  const [applyMemberList, setApplyMemberList] = useState([]);

  const [currentPage, setCurrentPage] = useState(0);
  const [totalPage, setTotalPage] = useState(0); //총 페이지 갯수
  const [totalCount, setTotalCount] = useState(0); //총 페이지 갯수

  const numDataTypeHandler = (key) => {
    if (key === null) {
      setApplyMemberList([]);
    }
    setNumDataType(key);
  };

  const groupMouseOverHandler = () => {
    if (numData.length === 1) {
      ReqGroupData(
        contestCB.contest_id,
        (result) => {
          const success = result.map((data) => {
            let groupNames =
              data.name === null ? data.seq : data.name;
            return { key: String(data.id), value: groupNames + "조"};
          });
          setNumData([DefaultSelect, ...success]);
        },
        (exception_result) => {
          setNumData([DefaultSelect]);
        }
      );
    }
  };

  useEffect(() => {
    groupMouseOverHandler();
  }, []);

  const fetchData = async () => {
    try {
      const searchParam = {
        contestID: contestCB.contest_id,
        placeId: contestCB.place_id,
        regionName: contestCB.region,
        groupId: numDataType,
      };
      const response = await instance({
        method: "GET",
        url: `/api/v1/admin/contest/printing/attend?page=0`,
        params: searchParam,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });

      if (response.status === 200) {
        const resData = await response.data.datas;
        setApplyMemberList(resData.content);
        setTotalPage(resData.totalPages);
        setTotalCount(resData.totalElements);
      } else {
        setApplyMemberList([]);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  
  const handlePageChange = async (page) => {
    setCurrentPage(page);

    const searchParam = {
      contestID: contestCB.contest_id,
      placeId: contestCB.place_id,
      regionName: contestCB.region,
      groupId: numDataType,
      page : page,
    };

    const response = await instance({
      method: "GET",
      url: `/api/v1/admin/contest/printing/attend`,
      params: searchParam,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });

    if (response.status === 200) {
      const resData = await response.data.datas;
      setApplyMemberList(resData.content);
      setTotalPage(resData.totalPages);
      setTotalCount(resData.totalElements);
    } else {
      setApplyMemberList([]);
    }
  };

  useEffect(() => {
    if (numDataType) {
      fetchData();
    }
  }, [numDataType]);

  const closeAttendModalHandler = (e) => {
    e.stopPropagation();
    isAttendModalOpenCB(false);
  };

  const formatPhoneNumber = (phoneNumber) => {
    // 정규 표현식을 사용하여 전화번호 형식 변경
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{4})(\d{4})$/);
  
    if (match) {
      return match[1] + '-' + match[2] + '-' + match[3];
    }
  
    return phoneNumber;
  };

  const onPrintHandler = async () => {
    try {
      const searchParam = {
        contestID: contestCB.contest_id,
        placeId: contestCB.place_id,
        regionName: contestCB.region,
        groupId: numDataType,
      };

      const response = await instance({
        method: "GET",
        url: `/api/v1/admin/contest/printing/attendAll`,
        params: searchParam,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });

      if (response.status === 200) {
        const resData = await response.data.datas;
        const windowObject = window.open('', 'PrintWindow', 'width=1200, height=800, top=100, left=0, toolbars=no, scrollbars=no, status=no, resizable=no');

        const applyMemberListHTML = `${resData.content.length !== 0 ? `${generateTables(resData.content, 27)}` : ''}`;

        windowObject.document.writeln(applyMemberListHTML);
        windowObject.document.close();
        windowObject.focus();
        windowObject.print();
        windowObject.close();
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  function generateTables(dataList, itemsPerPage) {
    const numTables = Math.ceil(dataList.length / itemsPerPage); // 108/36 = 3
    let tablesHTML = '';
  
    for (let tableIndex = 0; tableIndex < numTables; tableIndex += 2) {
      const startIndex1 = tableIndex * itemsPerPage;
      const endIndex1 = startIndex1 + itemsPerPage;
      const tableData1 = dataList.slice(startIndex1, endIndex1);
  
      const startIndex2 = (tableIndex + 1) * itemsPerPage;
      const endIndex2 = startIndex2 + itemsPerPage;
      const tableData2 = dataList.slice(startIndex2, endIndex2);
  
      if (tableIndex === numTables - 1 && tableData1.length === 0 && tableData2.length === 0) {
        break;
      }
  
      const groupName = numData?.find(data => data.key === numDataType)['value'];
      tablesHTML += `
          <div style="width: 1140px; height: 1520px;">
            <h3 style="width: 100%; font-size: 26px; text-align: center">${groupName} ${contestCB.center_name} / ${contestCB.region}(출석체크)</h3>
            <div style="width: 92%; display: flex; justify-content: center; align-items: flex-start; gap: 30px;">

                <table style="border-top: 3px solid #000; flex: 1; border-collapse: collapse; border-spacing: 0;">
                    <colgroup>
                      <col style="width: 10%;" />
                      <col style="width: 16%;" />
                      <col style="width: 12%;" />
                      <col style="width: 10%;" />
                      <col style="width: 22%;" />
                      <col style="width: 10%;" />
                      <col style="width: 21%;" />
                    </colgroup>

                    <thead>
                        <tr>
                            <th style="font-size: 22px; height:53px; text-align: center; border-bottom: 1px solid #dedede; line-height: 17px; font-weight: 300; background: #f7f9fa;">번호</th>
                            <th style="font-size: 22px; height:53px; text-align: center; border-bottom: 1px solid #dedede; line-height: 17px; font-weight: 300; background: #f7f9fa;">이름</th>
                            <th style="font-size: 22px; height:53px; text-align: center; border-bottom: 1px solid #dedede; line-height: 17px; font-weight: 300; background: #f7f9fa;">레인</th>
                            <th style="font-size: 22px; height:53px; text-align: center; border-bottom: 1px solid #dedede; line-height: 17px; font-weight: 300; background: #f7f9fa;">핸디</th>
                            <th style="font-size: 22px; height:53px; text-align: center; border-bottom: 1px solid #dedede; line-height: 17px; font-weight: 300; background: #f7f9fa;">비고</th>
                            <th style="font-size: 22px; height:53px; text-align: center; border-bottom: 1px solid #dedede; line-height: 17px; font-weight: 300; background: #f7f9fa;">성별</th>
                            <th style="font-size: 22px; height:53px; text-align: center; border-bottom: 1px solid #dedede; line-height: 17px; font-weight: 300; background: #f7f9fa;">연락처</th>
                        </tr>
                    </thead>
                    <tbody>

                      ${tableData1.map((data, index) => `
                      <tr key=${index}>
                      <td style="font-size: 23px; height:53px; text-align: center; border-bottom: 1px solid #dedede; line-height: 15px;">${data.num}</td>
                      <td style="font-size: 23px; height:53px; text-align: center; border-bottom: 1px solid #dedede; line-height: 15px;">${data.memberName}</td>
                      <td style="font-size: 23px; height:53px; text-align: center; border-bottom: 1px solid #dedede; line-height: 15px;">${data.lane === null ? "" : data.lane}-${data.seqOfLane === 0 ? "" : data.seqOfLane}</td>
                      <td style="font-size: 23px; height:53px; text-align: center; border-bottom: 1px solid #dedede; line-height: 15px;">${data.handicap}</td>
                      <td style="font-size: 23px; height:53px; text-align: center; border-bottom: 1px solid #dedede; line-height: 15px;">${Moment(data.birthDate).format("YYYY-MM-DD").substring(0,5)}</br>${Moment(data.birthDate).format("YYYY-MM-DD").substring(5)}</td>
                      <td style="font-size: 23px; height:53px; text-align: center; border-bottom: 1px solid #dedede; line-height: 15px;">${data.gender === "M" ? '남' + (data.disabled === "Y" ? "+" : "") : '여' + (data.disabled === "Y" ? "+" : "")}</td>
                      <td style="font-size: 23px; height:53px; text-align: center; border-bottom: 1px solid #dedede; line-height: 15px;">${formatPhoneNumber(data.phoneNumber).substring(0,4)}</br>${formatPhoneNumber(data.phoneNumber).substring(4,9)}</br>${formatPhoneNumber(data.phoneNumber).substring(9)}</td>
                    </tr>
                      `).join('')}

                    </tbody>
                </table>

                <table style="border-top: 3px solid #000; flex: 1; border-collapse: collapse; border-spacing: 0;">
                    <colgroup>
                      <col style="width: 10%;" />
                      <col style="width: 16%;" />
                      <col style="width: 12%;" />
                      <col style="width: 10%;" />
                      <col style="width: 22%;" />
                      <col style="width: 10%;" />
                      <col style="width: 21%;" />
                    </colgroup>
                    <thead>
                        <tr>
                        <th style="font-size: 22px; height:53px; text-align: center; border-bottom: 1px solid #dedede; line-height: 17px; font-weight: 300; background: #f7f9fa;">번호</th>
                        <th style="font-size: 22px; height:53px; text-align: center; border-bottom: 1px solid #dedede; line-height: 17px; font-weight: 300; background: #f7f9fa;">이름</th>
                        <th style="font-size: 22px; height:53px; text-align: center; border-bottom: 1px solid #dedede; line-height: 17px; font-weight: 300; background: #f7f9fa;">레인</th>
                        <th style="font-size: 22px; height:53px; text-align: center; border-bottom: 1px solid #dedede; line-height: 17px; font-weight: 300; background: #f7f9fa;">핸디</th>
                        <th style="font-size: 22px; height:53px; text-align: center; border-bottom: 1px solid #dedede; line-height: 17px; font-weight: 300; background: #f7f9fa;">비고</th>
                        <th style="font-size: 22px; height:53px; text-align: center; border-bottom: 1px solid #dedede; line-height: 17px; font-weight: 300; background: #f7f9fa;">성별</th>
                        <th style="font-size: 22px; height:53px; text-align: center; border-bottom: 1px solid #dedede; line-height: 17px; font-weight: 300; background: #f7f9fa;">연락처</th>
                        </tr>
                    </thead>

                    <tbody>

                      ${tableData2.map((data, index) => `
                        <tr key=${index}>
                          <td style="font-size: 23px; height:53px; text-align: center; border-bottom: 1px solid #dedede; line-height: 15px;">${data.num}</td>
                          <td style="font-size: 23px; height:53px; text-align: center; border-bottom: 1px solid #dedede; line-height: 15px;">${data.memberName}</td>
                          <td style="font-size: 23px; height:53px; text-align: center; border-bottom: 1px solid #dedede; line-height: 15px;">${data.lane === null ? "" : data.lane}-${data.seqOfLane === 0 ? "" : data.seqOfLane}</td>
                          <td style="font-size: 23px; height:53px; text-align: center; border-bottom: 1px solid #dedede; line-height: 15px;">${data.handicap}</td>
                          <td style="font-size: 23px; height:53px; text-align: center; border-bottom: 1px solid #dedede; line-height: 15px;">${Moment(data.birthDate).format("YYYY-MM-DD").substring(0,5)}</br>${Moment(data.birthDate).format("YYYY-MM-DD").slice(5)}</td>
                          <td style="font-size: 23px; height:53px; text-align: center; border-bottom: 1px solid #dedede; line-height: 15px;">${data.gender === "M" ? '남' + (data.disabled === "Y" ? "+" : "") : '여' + (data.disabled === "Y" ? "+" : "")}</td>
                          <td style="font-size: 23px; height:53px; text-align: center; border-bottom: 1px solid #dedede; line-height: 15px;">${formatPhoneNumber(data.phoneNumber).substring(0,4)}</br>${formatPhoneNumber(data.phoneNumber).substring(4,9)}</br>${formatPhoneNumber(data.phoneNumber).substring(9)}</td>
                        </tr>
                        `).join('')}

                    </tbody>
                </table>
            </div>
        </div>
      `;
    }
  
    return tablesHTML;
  }
  const printList = () => {
    return (
      <>
        {applyMemberList.length !== 0 && (
          <>
            <h3>{numData ? numData.find(data => data.key === numDataType)['value'] : ""} {contestCB.center_name} / {contestCB.region}(출석체크)</h3>
            <table className="align_center">
              {/* 첫 번째 테이블 */}
              <colgroup>
                <col style={{ width: "10%" }} />
                <col style={{ width: "16%" }} />
                <col style={{ width: "12%" }} />
                <col style={{ width: "7%" }} />
                <col style={{ width: "22%" }} />
                <col style={{ width: "10%" }} />
                <col style={{ width: "23%" }} />
              </colgroup>
              <thead>
                <tr>
                  <th>번호</th>
                  <th>이름</th>
                  <th>레인</th>
                  <th>핸디</th>
                  <th>비고</th>
                  <th>성별</th>
                  <th>연락처</th>
                </tr>
              </thead>
              <tbody>
                {applyMemberList.length !== 0 &&
                  applyMemberList.slice(0, 36).map((data, index) => (
                    <tr key={index}>
                      <td>{data.num}</td>
                      <td>{data.memberName}</td>
                      <td>
                        {data.lane}-{data.seqOfLane === 0 ? "" : data.seqOfLane}
                      </td>
                      <td>{data.handicap}</td>
                      <td>{Moment(data.birthDate).format("YYYY-MM-DD")}</td>
                      <td>{data.gender === "M" ? `남자${data.disabled === "Y" ? "+" : ""}` : `여자${data.disabled === "Y" ? "+" : ""}`}</td>
                      <td>{formatPhoneNumber(data.phoneNumber)}</td>
                    </tr>
                  ))}
              </tbody>
            </table>

            <table className="align_center">
              {/* 두 번째 테이블 */}
              <colgroup>
                <col style={{ width: "10%" }} />
                <col style={{ width: "16%" }} />
                <col style={{ width: "12%" }} />
                <col style={{ width: "7%" }} />
                <col style={{ width: "22%" }} />
                <col style={{ width: "10%" }} />
                <col style={{ width: "23%" }} />
              </colgroup>
              <thead>
                <tr>
                  <th>번호</th>
                  <th>이름</th>
                  <th>레인</th>
                  <th>핸디</th>
                  <th>비고</th>
                  <th>성별</th>
                  <th>연락처</th>
                </tr>
              </thead>
              <tbody>
                {applyMemberList.length !== 0 &&
                  applyMemberList.slice(36).map((data, index) => (
                    <tr key={index}>
                      <td>{data.num}</td>
                      <td>{data.memberName}</td>
                      <td>
                        {data.lane}-{data.seqOfLane === 0 ? "" : data.seqOfLane}
                      </td>
                      <td>{data.handicap}</td>
                      <td>{Moment(data.birthDate).format("YYYY-MM-DD")}</td>
                      <td>{data.gender === "M" ? `남자${data.disabled === "Y" ? "+" : ""}` : `여자${data.disabled === "Y" ? "+" : ""}`}</td>
                      <td>{formatPhoneNumber(data.phoneNumber)}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </>
        )}
      </>
    );
  };

  return (
    <section>
      <Modal
        className="admin_st w_1200"
        close={closeAttendModalHandler}
        header="출석확인표 인쇄 미리보기"
      >
        <main>
          <div className={classes.print_wrap}>
            <div className={classes.info_box}>
              <SelectBoxNew
                className="admin_st"
                setSelectItemCB={numDataTypeHandler}
                optionDatas={numData}
                showDefaultKey={numDataType}
              />
              <ul className={classes.page_box}>
                  <PaginationNew
                    className="admin_st"
                    totalPage={totalPage}
                    currentPage={currentPage}
                    defaultShowPage={1}
                    changePageCB={handlePageChange}
                  />
              </ul>
            </div>
            <div className={classes.print_table_box}>
              {printList()}
            </div>
          </div>
        </main>
        <footer>
          <Button className="secondary sm" onClick={onPrintHandler}>인쇄하기</Button>
        </footer>
      </Modal>
    </section>
  );
};

export default MngTournamentDetailPrintAttendModal;