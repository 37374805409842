import { useState, useEffect } from "react";
import { NavLink, useLoaderData, useNavigate } from "react-router-dom";
import axios from "axios";

import Moment from "moment";
import classes from "../../MngCommon.module.css";
import Button from "../../../../components/UI/Button/Button";
import Checkbox from "../../../../components/UI/Input/Checkbox";
import Modal from "../../../../components/UI/Modal/Modal";
import Input from "../../../../components/UI/Input/Input";
import Radio from "../../../../components/UI/Input/Radio";
import DatePickerBox from "../../../../components/UI/DatePicker/DatePickerBox";
import DateRangePicker from "../../../../components/UI/DatePicker/DateRangePicker";
//import ReactFileReader from "react-csv-reader";
import ReactFileReader from "react-file-reader";
import { CSVLink, CSVDownload } from "react-csv";
import AddingApplyIDModal from "../applyConditionComponents/AddingApplyIDModal";
import { ReqApplyConditionAll } from "../../../../api/tournament/ApiForTournamentApplyCondition";
import instance from "../../../../store/AxiosInterceptor";

const MngTournamentReceiptModal = (props) => {
    const tilte = props.title;
    const id = props.id;

    const [navIndex, setNavIndex] = useState(0);
    const navToggle = (index) => {
        setNavIndex(index);
    };
    const navArr = [
        { id: 0, title: "ID 통제" },
        { id: 1, title: "연령통제" },
    ];

    const [controlIdList, setControlIdList] = useState([]);
    const [controlAgeList, setControlAgeList] = useState([]);

    /// ID 통제 및 연령 통제 리스트
    const fetchData = async () => {
        try {
            const response = await ReqApplyConditionAll(props.id);
            if (response.status === 200) {
                // console.log("response.data  ;:: ", response.data);
                const resData = await response.data;
                if (navIndex === 0) {
                    if (resData.datas.length > 0) {
                        const dataList = resData.datas
                            .filter((data) => data.condition_type === "ID")
                            .map((data) => ({
                                ...data,
                                title: tilte, // Add your desired title value here
                            }));
                        const uniqueEnables = [
                            ...new Set(dataList.map((data) => data.enable)),
                        ];
                        setIdEnableYnStatic(
                            uniqueEnables.length === 0
                                ? "Y"
                                : String(uniqueEnables)
                        );
                        setIdEnableYn(
                            uniqueEnables.length === 0
                                ? "Y"
                                : String(uniqueEnables)
                        );
                        setControlIdList(dataList);
                    } else {
                        setControlIdList([]);
                    }
                } else if (navIndex === 1) {
                    if (resData.datas.length > 0) {
                        const dataList = resData.datas
                            .filter((data) => data.condition_type === "AGE")
                            .map((data) => ({ ...data }));
                        setControlAgeList(dataList);
                    } else {
                        setControlAgeList([]);
                    }
                }
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    useEffect(() => {
        fetchData();
    }, [navIndex]);

    const [isModalOpen4, setIsModalOpen4] = useState(false);

    const closeModalHandler3 = (e) => {
        e.stopPropagation();
        props.isModalOpen3(false);
    };
    const openModalHandler4 = (e) => {
        e.stopPropagation();

        if (!idEnableYn) {
            alert("통제유형을 선택해주세요.");
            return;
        }
        setIsModalOpen4(true);
    };
    const closeModalHandler4 = (e) => {
        e.stopPropagation();
        setControlIdAddList([]);
        setIsModalOpen4(false);
    };

    ///>>> ID 통제
    const [idEnableYn, setIdEnableYn] = useState();
    const [idEnableYnStatic, setIdEnableYnStatic] = useState();

    const idEnableYnRadioButton = (e) => {
        if (controlIdList.length === 0) {
            setIdEnableYnStatic(e.target.value);
            setIdEnableYn(e.target.value);
        } else {
            setIdEnableYn(e.target.value);
        }
    };

    const [controlIdAddList, setControlIdAddList] = useState([]);

    const controlIdAddHandler = () => {
        const newItem = {
            member_id: "",
            description: "",
        };

        setControlIdAddList((prevList) => [...prevList, newItem]);
    };

    // id
    const handleControlIdChange = (e, index) => {
        const updatedList = [...controlIdAddList];
        updatedList[index].member_id = e;
        setControlIdAddList(updatedList);
    };

    // 통제사유
    const handleControlDescriptionChange = (e, index) => {
        const updatedList = [...controlIdAddList];
        updatedList[index].description = e;
        setControlIdAddList(updatedList);
    };

    // 통제유형 변경시 리스트 삭제
    const controlIdEnableYnHandler = async () => {
        let flagCommnet = "";
        if (idEnableYn === "Y") {
            flagCommnet =
                "특정 ID만 접수 가능하게 통제 유형을 변경하시겠습니까?? \n통제 유형 변경시 하단의 통제 ID리스트는 삭제됩니다.";
        } else {
            flagCommnet =
                "특정 ID만 접수 불가하게 통제 유형을 변경하시겠습니까?? \n통제 유형 변경시 하단의 통제 ID리스트는 삭제됩니다.";
        }
        const isTrue = window.confirm(flagCommnet);

        const idList = controlIdList.map((item) => item.id);
        if (controlIdList.length !== 0) {
            if (isTrue) {
                controlDelete(idList, "ID");
            } else {
                return;
            }
        }
        setIdEnableYnStatic(idEnableYn);
    };

    const [selectedIds, setSelectedIds] = useState([]);

    const handleCheckboxIdChange = (e, id) => {
        if (e.target.checked) {
            setSelectedIds((prevSelectedIds) => [...prevSelectedIds, id]);
        } else {
            setSelectedIds((prevSelectedIds) =>
                prevSelectedIds.filter((selectedId) => selectedId !== id)
            );
        }
    };

    // 체크박스 선택 후 선택삭제 버튼 클릭시 선택한 Id 삭제
    const controlIdSelectHandler = async () => {
        const isTrue = window.confirm("선택하신 ID를 삭제하시겠습니까?");

        if (selectedIds.length === 0) {
            alert("삭제하실 ID를 체크해주세요");
            return;
        }

        if (isTrue) {
            controlDelete(selectedIds, "ID");
        } else {
            return;
        }
    };

    const createIdHandler = async (e) => {
        e.preventDefault();

        const jsonData = JSON.stringify({
            contest_id: id,
            condition_type: "ID",
            enable: idEnableYnStatic,
            id_conditions: controlIdAddList,
        });

        // console.log(jsonData);
        const formData = new FormData();
        formData.append(
            "body",
            new Blob([jsonData], { type: "application/json" })
        );

        const response = await instance.post(
            `/api/v1/admin/contest/condition/${props.id}`,
            formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }
        );

        if (response.status === 200) {
            alert("통제 ID가 등록되었습니다.");
            setControlIdAddList([]);
            setIsModalOpen4(false);
            fetchData();
        } else {
            alert("error");
        }
    };

    /// 엑셀파일 업로드
    const uploadFile = (files) => {
        if (!idEnableYn) {
            alert("통제유형을 선택해주세요.");
            return;
        }

        var read = new FileReader();
        read.onload = function (e) {
            const csvData = read.result;
            const parsedData = parseCSV(csvData);
            const extractedData = parsedData.map((row) => ({
                member_id: row.member_id,
                description: row.description,
            }));

            const jsonData = JSON.stringify({
                contest_id: id,
                condition_type: "ID",
                enable: idEnableYnStatic,
                id_conditions: extractedData,
            });

            // console.log(jsonData);
            const formData = new FormData();
            formData.append(
                "body",
                new Blob([jsonData], { type: "application/json" })
            );

            instance
                .post(`/api/v1/admin/contest/condition/${props.id}`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((response) => {
                    if (response.status === 200) {
                        alert("통제 ID가 등록되었습니다.");
                        setControlIdAddList([]);
                        setIsModalOpen4(false);
                        fetchData();
                    } else {
                        throw new Error("Error");
                    }
                })
                .catch((error) => {
                    console.error("Error:", error);
                    alert("Error occurred while uploading the file.");
                });
        };
        read.readAsText(files[0]);
    };

    /// 파일내용을 읽는
    const parseCSV = (csvData) => {
        const lines = csvData.split("\n");
        const headers = lines[0].split(",").map((header) => header.trim());
        const parsedData = [];

        for (let i = 1; i < lines.length; i++) {
            const values = lines[i].split(",").map((value) => value.trim());

            if (values.some((value) => value === "")) {
                continue;
            }

            const row = {};

            for (let j = 0; j < headers.length; j++) {
                const header = headers[j];
                const value = values[j];
                row[header] = value;
            }

            parsedData.push(row);
        }

        return parsedData;
    };

    /// 엑셀 업로드 양식때 필요한 데이터
    const headers = [{ label: "member_id" }, { label: "description" }];
    const data = [];
    ///<<< ID 통제

    ///>>> 연령 통제
    const [ageEnableYn, setageEnableYn] = useState("Y");
    const [ageEnd, setAgeEnd] = useState(new Date());
    const [ageStartDate, setAgeStartDate] = useState(new Date());
    const [ageEndDate, setAgeEndDate] = useState(new Date());
    const [ageStart, setAgeStart] = useState(new Date());
    const [description, setDescription] = useState();

    // 접수 가능여부 라디오 버튼
    const ageEnableYnRadioButton = (e) => {
        setageEnableYn(e.target.value);
    };

    // 이전 출생
    const ageEndHandler = (date) => {
        setAgeEnd(date);
    };

    // 사이 출생(시작)
    const ageStartDateHandler = (date) => {
        setAgeStartDate(date);
    };

    // 사이 출생(종료)
    const ageEndDateHandler = (date) => {
        setAgeEndDate(date);
    };

    // 이후 출생
    const ageStartHandler = (date) => {
        setAgeStart(date);
    };

    // 통제 사유
    const descriptionHandler = (value) => {
        setDescription(value);
    };

    const [selectedDate, setSelectedDate] = useState(null);

    // 체크박스 선택 핸들러
    const handleCheckboxChange = (e, id) => {
        setSelectedDate({ id });
    };

    const createAgeHandler = async (e) => {
        e.preventDefault();

        if (controlAgeList.length === 1) {
            alert(
                "연령통제 등록 사유가 한개만 가능합니다. \n삭제 후 등록바랍니다."
            );
            return;
        }

        let controlAgeAddList = [];

        if (selectedDate !== null) {
            if (selectedDate.id === "start") {
                controlAgeAddList = [
                    {
                        description: description,
                        start_age: Moment(ageStart).format("YYYYMMDD"),
                    },
                ];
            } else if (selectedDate.id === "end") {
                controlAgeAddList = [
                    {
                        description: description,
                        end_age: Moment(ageEnd).format("YYYYMMDD"),
                    },
                ];
            } else {
                controlAgeAddList = [
                    {
                        description: description,
                        start_age: Moment(ageStartDate).format("YYYYMMDD"),
                        end_age: Moment(ageEndDate).format("YYYYMMDD"),
                    },
                ];
            }
        } else {
            alert("통제 유형을 선택해주세요");
            return;
        }

        const jsonData = JSON.stringify({
            contest_id: id,
            condition_type: "AGE",
            enable: ageEnableYn,
            age_conditions: controlAgeAddList,
        });

        // console.log(jsonData);
        const formData = new FormData();
        formData.append(
            "body",
            new Blob([jsonData], { type: "application/json" })
        );

        const response = await instance.post(
            `/api/v1/admin/contest/condition/${props.id}`,
            formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }
        );

        // console.log(response);
        if (response.status === 200) {
            alert("연령 통제가 등록되었습니다.");
            fetchData();
        } else {
            alert("error");
        }
    };

    const [selectedAges, setSelectedAges] = useState([]);

    const handleCheckboxAgeChange = (e, id) => {
        if (e.target.checked) {
            setSelectedAges((prevSelectedAges) => [...prevSelectedAges, id]);
        } else {
            setSelectedAges((prevSelectedAges) =>
                prevSelectedAges.filter((selectedAges) => selectedAges !== id)
            );
        }
    };

    const controlAgeSelectHandler = async () => {
        const isTrue = window.confirm("선택하신 ID를 삭제하시겠습니까?");

        if (selectedAges.length === 0) {
            alert("삭제하실 ID를 체크해주세요");
            return;
        }

        if (isTrue) {
            controlDelete(selectedAges, "AGE");
        } else {
            return;
        }
    };
    ///<<< 연령 통제

    //삭제 api호출 분리
    const controlDelete = async (ids, type) => {
        const response = await instance.delete(
            `/api/v1/admin/contest/condition/${props.id}`,
            {
                headers: {
                    "Content-Type": "application/json",
                },
                data: JSON.stringify({ ids: ids }),
            }
        );

        if (response.status === 200) {
            if (type === "ID") {
                alert("ID 통제 리스트가 삭제되었습니다.");
            } else {
                alert("연령 통제 리스트가 삭제되었습니다.");
            }
            fetchData();
        } else {
            if (type === "ID") {
                alert("ID 통제 리스트 삭제 실패");
            } else {
                alert("연령 통제 리스트 삭제 실패");
            }
            return;
        }
    };

    const tilteDesc = "[" + props.id + "] " + tilte;

    const idConditionViewForModal3 = () => {
        return (
            <>
                {/* 분리하기 어려워 화면에 필요한 요소들을 모두 넣었습니다. 필요한 요소를 취사 선택해주세요 */}
                <table>
                    <colgroup>
                        <col style={{ width: "110px" }} />
                        <col style={{ width: "310px" }} />
                        <col style={{ width: "110px" }} />
                        <col style={{ width: "310px" }} />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>등록일</th>
                            <td>{Moment(new Date()).format("YYYY-MM-DD")}</td>
                            <th>등록자</th>
                            <td></td>
                        </tr>
                        <tr>
                            <th>통제유형</th>
                            <td colSpan={3}>
                                <div className={classes.radio_wrap}>
                                    <Radio
                                        title="특정 ID만 접수 가능"
                                        value="Y"
                                        name="rad_10"
                                        id="rad_10_01"
                                        checked={idEnableYn === "Y"}
                                        onChange={idEnableYnRadioButton}
                                    />
                                    <Radio
                                        title="특정 ID만 접수 불가"
                                        value="N"
                                        name="rad_10"
                                        id="rad_10_02"
                                        checked={idEnableYn === "N"}
                                        onChange={idEnableYnRadioButton}
                                    />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>통제유형상태</th>
                            <td colSpan={3}>
                                {idEnableYnStatic === undefined
                                    ? ""
                                    : idEnableYnStatic === "Y"
                                    ? "특정 ID만 접수 가능"
                                    : "특정 ID만 접수 불가"}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className={classes.btn_center}>
                    <Button className="sm" onClick={controlIdEnableYnHandler}>
                        통제유형 변경
                    </Button>
                </div>
                <div className={classes.position_left_btn}>
                    <Button
                        className="sm border"
                        onClick={controlIdSelectHandler}
                    >
                        선택 삭제
                    </Button>
                </div>
                <p className={classes.counter}>
                    총 <b>{controlIdList.length}</b>건
                </p>
                <div
                    className="fixed_scroll_table"
                    style={{ maxHeight: "400px" }}
                >
                    <table className="align_center ">
                        <colgroup>
                            <col style={{ width: "7%" }} />
                            <col style={{ width: "8.5%" }} />
                            <col style={{ width: "8.5%" }} />
                            <col style={{ width: "29%" }} />
                            <col style={{ width: "10%" }} />
                            <col style={{ width: "12%" }} />
                            <col style={{ width: "12%" }} />
                            <col style={{ width: "12%" }} />
                        </colgroup>
                        <thead>
                            <tr>
                                <th></th>
                                <th>No</th>
                                <th>대회코드</th>
                                <th>대회명</th>
                                <th>ID</th>
                                <th>접수가능 여부</th>
                                <th>통제사유</th>
                                <th>등록일</th>
                            </tr>
                        </thead>
                        <tbody>
                            {controlIdList.map((data, index) => (
                                <>
                                    <tr>
                                        <td>
                                            <Checkbox
                                                className="admin_st"
                                                id={`chk_100_${data.id}`}
                                                value={data.id}
                                                checked={selectedIds.includes(
                                                    data.id
                                                )}
                                                onChange={(e) =>
                                                    handleCheckboxIdChange(
                                                        e,
                                                        data.id
                                                    )
                                                }
                                            />
                                        </td>
                                        <td>{index + 1}</td>
                                        <td>{data.ct_id}</td>
                                        <td>{data.title}</td>
                                        <td>{data.member_id}</td>
                                        <td>
                                            {data.enable === "Y"
                                                ? "접수 가능"
                                                : "접수 불가"}
                                        </td>
                                        <td>{data.description}</td>
                                        <td>
                                            {Moment(data.reg_date).format(
                                                "YYYY-MM-DD"
                                            )}
                                        </td>
                                    </tr>
                                </>
                            ))}
                        </tbody>
                    </table>
                </div>
                <footer>
                    <Button
                        className="secondary sm"
                        onClick={openModalHandler4}
                    >
                        등록
                    </Button>
                    <CSVLink
                        data={data}
                        headers={headers}
                        filename={"접수 제한 관리 ID통제"}
                    >
                        <Button className="primary sm download">
                            엑셀업로드 양식 다운로드
                        </Button>
                    </CSVLink>
                    <ReactFileReader
                        handleFiles={uploadFile}
                        fileTypes={".csv"}
                    >
                        <Button className="sm">엑셀파일 일괄 등록</Button>
                    </ReactFileReader>
                </footer>
            </>
        );
    };

    const ageConditionForModal3 = () => {
        return (
            <>
                <table>
                    <colgroup>
                        <col style={{ width: "110px" }} />
                        <col style={{ width: "310px" }} />
                        <col style={{ width: "110px" }} />
                        <col style={{ width: "132px" }} />
                        <col style={{ width: "178px" }} />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>등록일</th>
                            <td>{Moment(new Date()).format("YYYY-MM-DD")}</td>
                            <th>등록자</th>
                            <td colSpan={2}>admin</td>
                        </tr>
                        <tr>
                            <th rowSpan={4}>통제유형</th>
                            <th colSpan={3} className={classes.text_center}>
                                연령대(생년월일)
                            </th>
                            <th className={classes.text_center}>
                                접수가능 여부
                            </th>
                        </tr>
                        <tr>
                            <td colSpan={3}>
                                <div className={classes.flex_row}>
                                    <Checkbox
                                        className="admin_st"
                                        id="end"
                                        value="end"
                                        checked={
                                            selectedDate &&
                                            selectedDate.id === "end"
                                        }
                                        onChange={(e) =>
                                            handleCheckboxChange(e, "end")
                                        }
                                    />
                                    <DatePickerBox
                                        className="sm"
                                        onChange={ageEndHandler}
                                        value={ageEnd}
                                        showMonthDropdown={true}
                                        showYearDropdown={true}
                                    />
                                    <span>이전 출생</span>
                                </div>
                            </td>
                            <td rowSpan={3} className={classes.border_left}>
                                <Radio
                                    title="접수 가능"
                                    value="Y"
                                    name="rad_11"
                                    id="rad_11_01"
                                    checked={ageEnableYn === "Y"}
                                    onChange={ageEnableYnRadioButton}
                                />
                                <Radio
                                    title="접수 불가"
                                    value="N"
                                    name="rad_11"
                                    id="rad_11_02"
                                    checked={ageEnableYn === "N"}
                                    onChange={ageEnableYnRadioButton}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={3}>
                                <div className={classes.flex_row}>
                                    <Checkbox
                                        className="admin_st"
                                        id="startEndRange"
                                        value="startEndRange"
                                        checked={
                                            selectedDate &&
                                            selectedDate.id === "startEndRange"
                                        }
                                        onChange={(e) =>
                                            handleCheckboxChange(
                                                e,
                                                "startEndRange"
                                            )
                                        }
                                    />
                                    <DateRangePicker
                                        className="sm"
                                        startDate={ageStartDate}
                                        endDate={ageEndDate}
                                        onStartDateChange={ageStartDateHandler}
                                        onEndDateChange={ageEndDateHandler}
                                        showMonthDropdown={true}
                                        showYearDropdown={true}
                                    />
                                    <span>사이 출생</span>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={3}>
                                <div className={classes.flex_row}>
                                    <Checkbox
                                        className="admin_st"
                                        id="start"
                                        value="start"
                                        checked={
                                            selectedDate &&
                                            selectedDate.id === "start"
                                        }
                                        onChange={(e) =>
                                            handleCheckboxChange(e, "start")
                                        }
                                    />
                                    <DatePickerBox
                                        className="sm"
                                        onChange={ageStartHandler}
                                        value={ageStart}
                                        showMonthDropdown={true}
                                        showYearDropdown={true}
                                    />
                                    <span>이후 출생</span>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>통제사유</th>
                            <td colSpan={4}>
                                <Input
                                    onChange={descriptionHandler}
                                    value={description}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className={classes.btn_center}>
                    <Button className="secondary sm" onClick={createAgeHandler}>
                        등록
                    </Button>
                </div>
                <h4>등록현황</h4>
                <table className="align_center">
                    <colgroup>
                        <col style={{ width: "60px" }} />
                        <col style={{ width: "300px" }} />
                        <col style={{ width: "100px" }} />
                        <col style={{ width: "100px" }} />
                        <col style={{ width: "195px" }} />
                    </colgroup>
                    <thead>
                        <tr>
                            <th></th>
                            <th>연령대</th>
                            <th>접수가능 여부</th>
                            <th>통제사유</th>
                            <th>등록일</th>
                        </tr>
                    </thead>
                    <tbody>
                        {controlAgeList.map((data, index) => (
                            <>
                                <tr>
                                    <td>
                                        <Checkbox
                                            className="admin_st"
                                            id={`chk_100_${data.id}`}
                                            value={data.id}
                                            checked={selectedAges.includes(
                                                data.id
                                            )}
                                            onChange={(e) =>
                                                handleCheckboxAgeChange(
                                                    e,
                                                    data.id
                                                )
                                            }
                                        />
                                    </td>
                                    <td>
                                        {data.start_age && !data.end_age ? (
                                            <>
                                                {Moment(data.start_age).format(
                                                    "YYYY-MM-DD"
                                                )}{" "}
                                                이후 출생
                                            </>
                                        ) : !data.start_age && data.end_age ? (
                                            <>
                                                {Moment(data.end_age).format(
                                                    "YYYY-MM-DD"
                                                )}{" "}
                                                이전 출생
                                            </>
                                        ) : data.start_age && data.end_age ? (
                                            <>
                                                {Moment(data.start_age).format(
                                                    "YYYY-MM-DD"
                                                )}{" "}
                                                ~{" "}
                                                {Moment(data.end_age).format(
                                                    "YYYY-MM-DD"
                                                )}{" "}
                                                사이 출생
                                            </>
                                        ) : null}
                                    </td>
                                    <td>
                                        {data.enable === "Y"
                                            ? "접수 가능"
                                            : "접수 불가"}
                                    </td>
                                    <td>{data.description}</td>
                                    <td>
                                        {Moment(data.reg_date).format(
                                            "YYYY-MM-DD"
                                        )}
                                    </td>
                                </tr>
                            </>
                        ))}
                    </tbody>
                </table>
                <div className={classes.btn_center}>
                    <Button
                        className="border sm"
                        onClick={controlAgeSelectHandler}
                    >
                        선택 삭제
                    </Button>
                </div>
            </>
        );
    };

    const modalFor3 = () => {
        return (
            <Modal
                className="admin_st"
                close={closeModalHandler3}
                header="접수 제한 관리"
                desc={tilteDesc}
            >
                <main>
                    <h5>ID 또는 연령 통제로 대회접수를 제한할 수 있습니다.</h5>
                    <ul className={classes.modal_nav}>
                        {navArr.map((item) => (
                            <li
                                key={item.id}
                                className={navIndex === item.id && classes.on}
                                onClick={() => navToggle(item.id)}
                            >
                                {item.title}
                            </li>
                        ))}
                    </ul>
                    {navIndex === 0 && idConditionViewForModal3()}
                    {navIndex === 1 && ageConditionForModal3()}
                </main>
            </Modal>
        );
    };

    return (
        <section>
            {props.isModalOpen3 && modalFor3()}
            {isModalOpen4 &&
                AddingApplyIDModal({
                    title: tilteDesc,
                    controlIdAddList: controlIdAddList,
                    onControlIdChangeCB: handleControlIdChange,
                    onControlDescriptionChangeCB:
                        handleControlDescriptionChange,
                    onControlIdAddCB: controlIdAddHandler,
                    onCloseModalCB: closeModalHandler4,
                    onCreateIdCB: createIdHandler,
                })}
        </section>
    );
};

export default MngTournamentReceiptModal;
