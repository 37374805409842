import { useState } from "react";
import Button from "../../../components/UI/Button/Button";
import FileBox from "../../../components/UI/Input/FileBox";
import Input from "../../../components/UI/Input/Input";
import Radio from "../../../components/UI/Input/Radio";
import SelectBox from "../../../components/UI/SelectBox/SelectBox";
import classes from "../MngCommon.module.css";
import Pagination from "../../../components/UI/Table/Pagination";
import instance from "../../../store/AxiosInterceptor";
import { useLoaderData, useLocation, useNavigate } from "react-router-dom";
import { ReqDeleteKegelInfo } from "../../../api/kegel/KegelAPi";

function MngKegelInfoDetail() {
    const location = useLocation();
    const navigate = useNavigate();
    const kegelData = useLoaderData();

    const toList = () => {
        navigate("/admin/kegel/info", {state: location.state});
    };

    const updateHandler = () => {
        navigate(`/admin/kegel/info/update/${kegelData.id}`, {state: location.state});
    };

    const deleteKegel = async () => {
        const response = await ReqDeleteKegelInfo(kegelData.id);
        if (response.status === 200) {
            navigate("/admin/kegel/info", {state: location.state});
        } else {
            alert(
                `[${response.status}] ${
                    response.data ? response.data.error : "삭제 실패"
                } `
            );
        }
    };

    // 매핑시켜주기
    const kegelTypeMapping = {
        USEFUL_INFO: "유용한 정보",
        HELP: "문제해결 도우미",
    };

    const urlMapping = {
        USEFUL_INFO: "usefulInfo",
        HELP: "help",
    };

    const bodyData = (
        <>
            <tr>
                <th>등록일</th>
                <td>{kegelData.regDate}</td>
                <th>등록자</th>
                <td>{kegelData.regID}</td>
            </tr>
            <tr>
                <th>노출여부</th>
                <td colSpan={3}>
                    {kegelData.exposeYn === "Y" ? "노출" : "미노출"}
                </td>
            </tr>
            <tr>
                <th>구분</th>
                {/* <td colSpan={3}>{kegelData.kegelType}</td> */}
                <td colSpan={3}>
                    {kegelTypeMapping[kegelData.kegelGuideType]}
                </td>
            </tr>
            <tr>
                <th>제목</th>
                <td colSpan={3}>{kegelData.title}</td>
            </tr>
            <tr>
                <th>파일첨부</th>
                <td colSpan={3}>{kegelData.attach_uri}</td>
            </tr>

            <tr>
                <th>사용자페이지 URL</th>
                <td colSpan={3}>
                    {window.location.protocol +
                        "//" +
                        window.location.host +
                        "/kegel/" +
                        urlMapping[kegelData.kegelGuideType] +
                        "/" +
                        kegelData.id}
                </td>
            </tr>
            <tr>
                <th>상세정보</th>
                <td colSpan={3}>
                    <div>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: kegelData.content,
                            }}
                        />
                    </div>
                </td>
            </tr>
        </>
    );
    return (
        <section>
            <div className={classes.admin_contents}>
                <h3>유용한 정보 / 문제해결 도우미 - 상세</h3>
                <div
                    className={`${classes.contents_wrap} ${classes.search_wrap} `}
                >
                    <div className={classes.admin_table_wrap}>
                        <table>
                            <colgroup>
                                <col style={{ width: "159px" }} />
                                <col style={{ width: "620px" }} />
                                <col style={{ width: "159px" }} />
                                <col style={{ width: "620px" }} />
                            </colgroup>
                            <tbody>{bodyData}</tbody>
                        </table>

                        <div className={classes.bottom_btn_wrap}>
                            <Button
                                className="border sm"
                                onClick={updateHandler}
                            >
                                수정
                            </Button>
                            <Button className="sm" onClick={deleteKegel}>
                                삭제
                            </Button>
                            <Button className="secondary sm" onClick={toList}>
                                목록
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default MngKegelInfoDetail;

export const loader = async ({ params }) => {
    try {
        const response = await instance.get(
            `/api/v1/admin/kegel/guide/${params.kegelId}`,
            {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            }
        );

        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error("Failed to fetch data");
        }
    } catch (error) {
        console.error("Error fetching data:", error);
        return null;
    }
};
