import { Outlet } from "react-router-dom";
import classes from "./Kegel.module.css";
import KegelNavigation from "./KegelNavigation";

function KegelRootLayout() {
    return (
        <div className={`container ${classes.kegel_container}`}>
            <KegelNavigation />
            <section>
                <div className={classes.kegel_contents}>
                    <Outlet />
                </div>
            </section>
        </div>
    );
}

export default KegelRootLayout;
