import classes from "./Kegel.module.css";
import BreadCrumb from "../../components/UI/BreadCrumb/BreadCrumb";

import Button from "../../components/UI/Button/Button";
import { useEffect, useState } from "react";
import Modal from "../../components/UI/Modal/Modal";
import instance from "../../store/AxiosInterceptor";
import { useLoaderData } from "react-router-dom";
import { ReqHomeKegelVideo } from "../../api/kegel/KegelAPi";
function LaneUsefulVideo(props) {
    const resData = useLoaderData();

    const [KegelList, setKegelList] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    //  breadCrumb sample data
    const breadCrumbList = ["케겔", "레인정비기계 서비스", "유용한 동영상"];
    // modal
    const [isModalOpen1, setIsModalOpen1] = useState(false);
    const [modalContent, setModalContent] = useState("");

    const openModalHandler1 = (id, content) => {
        // 조회수 증가
        instance.put(`/api/v1/home/kegel/video/${id}/views`).catch((error) => {
            console.error("Error increasing views:", error);
        });
        setModalContent(content);
        setIsModalOpen1(true);
    };

    const closeModalHandler1 = (e) => {
        e.stopPropagation();
        setIsModalOpen1(false);
    };

    const onChangePageHandler = () => {
        setCurrentPage((prev) => prev + 1);
    };

    const findKegelListPageHandler = () => {
        onChangePageHandler();
        ReqHomeKegelVideo(
            currentPage + 1,
            (result) => {
                const curData = result.map((data) => {
                    return {
                        id: data.id,
                        title: data.title,
                        file_uri: data.file_uri,
                        content: data.content,
                    };
                });
                setKegelList((prev) => [...prev, ...curData]);
            },
            (error_result) => {
                setKegelList((prev) => prev);
            }
        );
    };

    useEffect(() => {
        // console.log(resData);
        const curData = resData.map((data) => {
            return {
                id: data.id,
                title: data.title,
                file_uri: data.file_uri,
                content: data.content,
            };
        });
        setKegelList(curData);
    }, [resData]);

    const bodyValues = KegelList.map(
        ({ id, title, file_uri, content }, index) => {
            return (
                <div
                    className={classes.box}
                    onClick={() => openModalHandler1(id, content)}
                >
                    <div className={classes.thumbnail}>
                        <img src={file_uri} alt="샘플이미지" />
                    </div>
                    <p>{title}</p>
                </div>
            );
        }
    );

    return (
        <>
            <BreadCrumb breadCrumbList={breadCrumbList} />

            <div className={classes.contents_wrap}>
                <div className={classes.contents_title}>
                    <h3>유용한 동영상</h3>
                </div>
                <div className={classes.gallery_board}>{bodyValues}</div>
                <div className={classes.bottom_center_btn}>
                    <Button
                        className="text_icon"
                        onClick={findKegelListPageHandler}
                    >
                        더보기
                    </Button>
                </div>
            </div>
            {isModalOpen1 && (
                <Modal
                    close={closeModalHandler1}
                    className="video_modal none_title"
                >
                    <div dangerouslySetInnerHTML={{ __html: modalContent }} />
                </Modal>
            )}
        </>
    );
}

export default LaneUsefulVideo;
export const loader = async () => {
    return instance
        .get(`/api/v1/home/kegel/video?page=0&size=6`)
        .then((response) => {
            const resData = response.data.datas.content;
            if (response.data.status === 200) {
                return resData;
            } else {
                return [];
            }
        })
        .catch((error) => {
            return [];
        });
};
