import { NavLink } from "react-router-dom";
import classes from "../MngCommon.module.css";

function MngKegelNavigation(props) {
    return (
        <div className={classes.admin_sub_nav}>
            <nav>
                <ul className={classes.depth_1}>
                    <li className={classes.depth_1_link}>
                        <NavLink to="">상품목록</NavLink>
                    </li>

                    <li className={classes.depth_1}>
                        <NavLink to="info">레인정비기계서비스</NavLink>
                        <ul className={classes.depth_2}>
                            <li>
                                <NavLink to="info">
                                    유용한 정보 / 문제해결 도우미
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="video">유용한 동영상</NavLink>
                            </li>
                        </ul>
                    </li>
                </ul>
            </nav>
        </div>
    );
}

export default MngKegelNavigation;
