import { useState } from "react";
import classes from "../Sign.module.css";
import Button from "../../../components/UI/Button/Button";
import Input, { MultiInput } from "../../../components/UI/Input/Input";
import Radio from "../../../components/UI/Input/Radio";
import Checkbox from "../../../components/UI/Input/Checkbox";
import { Link, useLocation } from "react-router-dom";
import Modal from "../../../components/UI/Modal/Modal";
import SignTermsModal from "../SignTermsModal";
import { useNavigate } from "react-router-dom";
import SelectBoxNew from "../../../components/UI/SelectBox/SelectBoxNew";
import DaumPostcode from "react-daum-postcode";
import { ReqCheckDuplicateMemId, ReqSignUpPhoneMember } from "../../../api/auth/AuthApi";
import { defaultEmailHostList } from "../../../components/definedefault/DefSelectBoxValues";

function SignUpPhone() {
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const paramGender = searchParams.get("gender");
    const paramName = searchParams.get("name");
    const paramYear = searchParams.get("year");
    const paramMonth = searchParams.get("month");
    const paramDay = searchParams.get("day");
    const paramCell = searchParams.get("cell");
    const birthDate = `${paramYear}-${paramMonth}-${paramDay}`;

    const [successSignUp, setSuccessSignUp] = useState(false);
    const [validCheck, setValidCheck] = useState(false);
    const topHandler = () => {
        window.scrollTo({ top: 0, behavior: "smooth" }); //상단 smooth 이동
    };

    const cancelHandler = () => {
        navigate("/");
    };

    const [input, setInput] = useState({
        memberPhoneFirst: paramCell?.split("-")[0],
        memberPhoneMid: paramCell?.split("-")[1],
        memberPhoneLast: paramCell?.split("-")[2],
        emailId: "",
        emailHost: "",
        password: "",
        passwordCheck: "",
    });

    const { memberPhoneFirst, memberPhoneMid, memberPhoneLast, emailId, password, passwordCheck } = input;
    const [memberId, setMemberId] = useState("");
    const memberIdHandler = (value) => {
        setMemberIdIsValid("");
        setMemberId(value);
    };
    const onChangeInput = (e) => {
        const { name, value } = e.target;
        setInput({
            ...input,
            [name]: value,
        });
    };

    const [memberAgree, setMemberAgree] = useState(false);
    const [signUpAgree, setSignUpAgree] = useState(false);
    const [smsYn, setSmsYn] = useState(true);
    const [emailYn, setEmailYn] = useState(true);

    const memberAgreeHandler = () => {
        setMemberAgree((prev) => !prev);
    };
    const signUpAgreeHandler = () => {
        setSignUpAgree((prev) => !prev);
    };
    const smsYnHandler = () => {
        setSmsYn((prev) => !prev);
    };
    const emailYnHandler = () => {
        setEmailYn((prev) => !prev);
    };

    const [emailHost, setEmailHost] = useState("");
    const emailHostHandler = (value) => {
        setEmailHost(value);
    };

    const memberIdRegex = /^(?=.*[a-zA-Z\d])(?=.*)[A-Za-z\d!@#$%^&*]{6,20}$/;
    const emailDomainRegex = /^[a-zA-Z0-9.!@#$%^&*()_+{}\[\]:;<>,.?~=-]*$/;
    const passwordRegex = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{6,20}$/;

    const memberIdRegexCheck = memberIdRegex.test(memberId);
    const emailDomainRegexCheck = emailDomainRegex.test(emailHost);
    const passwordRegexCheck = passwordRegex.test(password);

    const [memberIdIsValid, setMemberIdIsValid] = useState("");
    const memberIdDuplicateHandler = () => {
        if (memberId.trim() === "") {
            alert("회원 아이디를 입력해주세요.");
            return;
        }
        if (!memberIdRegexCheck) {
            alert("회원 아이디 양식에 적합하지 않습니다.");
            return;
        }
        ReqCheckDuplicateMemId(
            memberId,
            (status) => {
                if (status === 204) {
                    setMemberIdIsValid("Y");
                } else {
                    setMemberIdIsValid("N");
                }
            },
            (error_result) => {
                alert("서버 에러");
                setMemberIdIsValid("");
            }
        );
    };

    const [isPasswordCheck, setIsPasswordCheck] = useState(false);

    const passwordCheckHandler = () => {
        setIsPasswordCheck(true);
    };

    const emailHostList = defaultEmailHostList;

    // modal
    const [isModalOpen1, setIsModalOpen1] = useState(false);
    const [isModalOpen2, setIsModalOpen2] = useState(false);
    const openModalHandler1 = (e) => {
        e.stopPropagation();
        setIsModalOpen1(true);
    };
    const closeModalHandler1 = (e) => {
        e.stopPropagation();
        setIsModalOpen1(false);
    };
    const openModalHandler2 = (e) => {
        e.stopPropagation();
        setIsModalOpen2(true);
    };
    const closeModalHandler2 = (e) => {
        e.stopPropagation();
        setIsModalOpen2(false);
    };
    // [START] 다음 주소 API =================================================================
    const [isDaumPostcodeOpen, setIsDaumPostcodeOpen] = useState(false);
    const [zipCode, setZipCode] = useState(""); //우편번호
    const [selectedAddress, setSelectedAddress] = useState(""); // 선택한 주소를 저장할 상태
    const [enteredAddress, setEnteredAddress] = useState(""); // 입력한 주소를 저장할 상태

    const openDaumPostcodeHandler = (e) => {
        e.stopPropagation();
        setIsDaumPostcodeOpen(true);
    };

    const handleAddressSelect = (data) => {
        const fullAddress = data.address; // 전체 주소
        const extraAddress = data.addressType === "R" ? "" : data.bname; // 도로명일 경우 법정동 추가
        const address = `${fullAddress} ${extraAddress}`; // 최종 주소
        setZipCode(data.zonecode);
        setSelectedAddress(address); // 주소를 선택하면 상태 업데이트
        setIsDaumPostcodeOpen(false); // 주소를 선택하면 모달 창 닫기
    };

    const closeDaumPostcodeHandler = () => {
        setIsDaumPostcodeOpen(false);
    };

    const addressIsNotValid = zipCode.trim() === "" || selectedAddress.trim() === "" || enteredAddress.trim() === "";
    // [END] 다음 주소 API =================================================================

    const memberInfoObject = {
        id: memberId,
        name: paramName,
        email: emailId + "@" + emailHost,
        accept_mail: emailYn ? "Y" : "N",
        accept_sms: smsYn ? "Y" : "N",
        password: password,
        phone: paramCell,
        cell: paramCell,
        birth: birthDate,
        address: selectedAddress,
        address_sub: enteredAddress,
        gender: paramGender,
        zip: zipCode,
        member_agree: memberAgree ? "Y" : "N",
        sign_up_agree: signUpAgree ? "Y" : "N",
        auth_type: "1",
    };

    const signUpHandler = () => {
        setValidCheck(true);
        topHandler();

        if (memberIdIsValid === "N" || memberIdIsValid === "") {
            return;
        }

        if (!passwordRegexCheck) {
            return;
        }

        if (password.trim() === "" || passwordCheck.trim() === "") {
            return;
        }

        if (password.trim() !== passwordCheck.trim()) {
            return;
        }

        if (!memberIdRegexCheck) {
            return;
        }

        if (!emailDomainRegexCheck) {
            return;
        }

        if (addressIsNotValid) {
            return;
        }

        if (paramGender === "") {
            return;
        }

        if (!memberAgree) {
            return;
        }

        if (!signUpAgree) {
            return;
        }

        if (paramName.trim() === "") {
            return;
        }

        if (birthDate.trim() === "") {
            return;
        }

        if (memberId.trim() === "") {
            return;
        }

        if (memberPhoneFirst.trim() === "") {
            return;
        }
        if (memberPhoneMid.trim() === "") {
            return;
        }

        if (memberPhoneLast.trim() === "") {
            return;
        }

        if (emailId.trim() === "") {
            return;
        }

        ReqSignUpPhoneMember(
            memberInfoObject,
            (result) => {
                if (result.status === 200) {
                    setSuccessSignUp(true);
                    setValidCheck(false);
                } else {
                    setSuccessSignUp(false);
                    alert("회원 가입 실패");
                }
            },
            (error_result) => {
                setSuccessSignUp(false);
            }
        );
    };

    return (
        <div className={`container ${classes.sign_container}`}>
            <div className={classes.official_sub_header}>
                <h2>회원가입</h2>
            </div>
            <div className={classes.sign_up_wrap}>
                {!successSignUp && (
                    <div className={classes.sign_up_box}>
                        <p className={classes.desc}>
                            필수항목<span className="red_c">*</span>
                        </p>
                        <div className={classes.detail_table}>
                            <table>
                                <colgroup>
                                    <col style={{ width: "17%" }} />
                                    <col style={{ width: "83%" }} />
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <th>이름</th>
                                        <td>
                                            <Input className="admin_st_sm" value={paramName} readOnly />
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>생년월일</th>
                                        <td>
                                            <div className={classes.flex_row}>
                                                <Input
                                                    className="admin_st_sm"
                                                    value={paramYear}
                                                    name="paramYear"
                                                    disabled
                                                />
                                                <span>년</span>
                                                <Input
                                                    className="admin_st_sm"
                                                    value={paramMonth}
                                                    name="paramMonth"
                                                    disabled
                                                />
                                                <span>월</span>
                                                <Input
                                                    className="admin_st_sm"
                                                    value={paramDay}
                                                    name="paramDay"
                                                    disabled
                                                />
                                                <span>일</span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>성별</th>
                                        <td>
                                            <div className={classes.radio_wrap}>
                                                <Radio
                                                    className="official_st"
                                                    name="gender"
                                                    id="rad_02_1"
                                                    value="M"
                                                    title="남"
                                                    checked={paramGender === "M"}
                                                    disabled
                                                />
                                                <Radio
                                                    className="official_st"
                                                    name="gender"
                                                    id="rad_02_2"
                                                    value="F"
                                                    title="여"
                                                    checked={paramGender === "F"}
                                                    disabled
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            아이디
                                            <span className="red_c">*</span>
                                        </th>
                                        <td>
                                            <div className={classes.flex_row}>
                                                <Input
                                                    className="admin_st_sm"
                                                    value={memberId}
                                                    name="memberId"
                                                    onChange={memberIdHandler}
                                                />
                                                <Button className="border sm" onClick={memberIdDuplicateHandler}>
                                                    아이디 중복 확인
                                                </Button>
                                                {/**중복됐을경우 N으로 변경 */}
                                                {memberIdIsValid === "Y" &&
                                                    memberIdRegexCheck &&
                                                    memberId.trim() !== "" && <p>사용가능한 아이디입니다.</p>}
                                                {!validCheck && !memberIdRegexCheck && (
                                                    <p className={classes.required}>
                                                        영 대소문자를 포함하여 6~20자 이내
                                                    </p>
                                                )}
                                                {memberId.trim() !== "" && memberIdIsValid === "N" && (
                                                    <p className={classes.required}>
                                                        이미 사용중인 아이디입니다. 다시 확인해주세요.
                                                    </p>
                                                )}
                                                {validCheck && memberIdIsValid === "" && memberId.trim() !== "" && memberIdRegexCheck && (
                                                    <p className={classes.required}>아이디 중복확인을 해주세요.</p>
                                                )}
                                                {memberId.trim() === "" && validCheck && (
                                                    <p className={classes.required}>아이디를 입력해주세요.</p>
                                                )}
                                                {validCheck && memberId.trim() !== "" && !memberIdRegexCheck && (
                                                    <p className={classes.required}>
                                                        영 대소문자를 포함하여 6~20자 이내로 입력해주세요.
                                                    </p>
                                                )}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            비밀번호
                                            <span className="red_c">*</span>
                                        </th>
                                        <td>
                                            <div className={classes.flex_row}>
                                                <MultiInput
                                                    className="admin_st_sm"
                                                    value={password}
                                                    name="password"
                                                    onChange={onChangeInput}
                                                    type="password"
                                                />
                                                {!validCheck && !passwordRegexCheck && (
                                                    <p className={classes.required}>
                                                        영 대소문자, 숫자, 특수문자를 포함하여 6~20자 이내
                                                    </p>
                                                )}
                                                {validCheck && !passwordRegexCheck && password.trim() !== "" && (
                                                    <p className={classes.required}>
                                                        영 대소문자, 숫자, 특수문자를 포함하여 6~20자 이내로
                                                        입력해주세요.
                                                    </p>
                                                )}
                                                {validCheck && password.trim() === "" && (
                                                    <p className={classes.required}>비밀번호를 입력해주세요.</p>
                                                )}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            비밀번호 확인
                                            <span className="red_c">*</span>
                                        </th>
                                        <td>
                                            <div className={classes.flex_row}>
                                                <MultiInput
                                                    className="admin_st_sm"
                                                    value={passwordCheck}
                                                    name="passwordCheck"
                                                    onChange={onChangeInput}
                                                    type="password"
                                                />
                                                {validCheck && passwordCheck.trim() === "" && (
                                                    <p className={classes.required}>
                                                        비밀번호를 다시 한번 입력해주세요.
                                                    </p>
                                                )}
                                                {validCheck &&
                                                    passwordRegexCheck &&
                                                    password.trim() !== passwordCheck.trim() &&
                                                    password.trim() !== "" &&
                                                    passwordCheck.trim() !== "" && (
                                                        <p className={classes.required}>
                                                            입력하신 비밀번호와 동일하지 않습니다.
                                                        </p>
                                                    )}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            주소
                                            <span className="red_c">*</span>
                                        </th>
                                        <td>
                                            <div className={classes.address_wrap}>
                                                <div className={classes.flex_row}>
                                                    <Input
                                                        className="h45 "
                                                        value={zipCode}
                                                        id="zipCode"
                                                        onChange={setZipCode}
                                                    />
                                                    <Button className="border sm" onClick={openDaumPostcodeHandler}>
                                                        우편번호 검색
                                                    </Button>
                                                    {validCheck && addressIsNotValid && (
                                                        <p className={classes.required}>주소를 입력해주세요.</p>
                                                    )}
                                                </div>
                                                <div className={classes.flex_row}>
                                                    <Input
                                                        className="h45 w_50"
                                                        value={selectedAddress}
                                                        id="address"
                                                        onChange={setSelectedAddress}
                                                    />
                                                    <Input
                                                        className="h45 w_50"
                                                        value={enteredAddress}
                                                        id="detailAddress"
                                                        onChange={setEnteredAddress}
                                                    />
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            휴대폰
                                            <span className="red_c">*</span>
                                        </th>
                                        <td>
                                            <div className={classes.flex_row}>
                                                <MultiInput
                                                    className="h45 sm"
                                                    name="memberPhoneFirst"
                                                    value={memberPhoneFirst}
                                                    disabled={true}
                                                />
                                                <span>-</span>
                                                <MultiInput
                                                    className="h45 sm"
                                                    value={memberPhoneMid}
                                                    name="memberPhoneMid"
                                                    disabled={true}
                                                />
                                                <span>-</span>
                                                <MultiInput
                                                    className="h45 sm"
                                                    value={memberPhoneLast}
                                                    name="memberPhoneLast"
                                                    disabled={true}
                                                />
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>
                                            이메일
                                            <span className="red_c">*</span>
                                        </th>
                                        <td>
                                            <div className={classes.flex_row}>
                                                <MultiInput
                                                    className="admin_st_sm"
                                                    value={emailId}
                                                    name="emailId"
                                                    onChange={onChangeInput}
                                                />
                                                <span>@</span>
                                                <Input
                                                    className="admin_st_sm"
                                                    value={emailHost}
                                                    name="emailHost"
                                                    onChange={emailHostHandler}
                                                />
                                                <SelectBoxNew
                                                    className="default "
                                                    optionDatas={emailHostList}
                                                    setSelectItemCB={emailHostHandler}
                                                    showDefaultKey={emailHost}
                                                />
                                            </div>
                                            {validCheck && (emailId.trim() === "" || emailHost.trim() === "") && (
                                                <p className={classes.required}>이메일을 입력해주세요.</p>
                                            )}
                                            {emailId.trim() !== "" && !emailDomainRegexCheck && (
                                                <p className={classes.required}>올바른 이메일 주소를 입력해주세요.</p>
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            약관동의
                                            <span className="red_c">*</span>
                                        </th>
                                        <td>
                                            <div className={classes.checkbox_wrap}>
                                                <Checkbox
                                                    className="official_st"
                                                    title="회원약관동의"
                                                    id="signUpAgree"
                                                    onChange={signUpAgreeHandler}
                                                    checked={signUpAgree === true}
                                                />
                                                <span className="red_c">(필수)</span>
                                                <Button className="text_underline" onClick={openModalHandler1}>
                                                    전문보기
                                                </Button>
                                                {validCheck && !signUpAgree && (
                                                    <p className={classes.required}>회원약관에 동의를 해주세요.</p>
                                                )}
                                            </div>
                                            <div className={classes.checkbox_wrap}>
                                                <Checkbox
                                                    className="official_st"
                                                    title="개인정보 수집에 대한 동의"
                                                    id="memberAgree"
                                                    onChange={memberAgreeHandler}
                                                    checked={memberAgree === true}
                                                />
                                                <span className="red_c">(필수)</span>
                                                <Button className="text_underline" onClick={openModalHandler2}>
                                                    전문보기
                                                </Button>
                                                {validCheck && !memberAgree && (
                                                    <p className={classes.required}>
                                                        개인정보 제공 및 이용에 동의 해주세요.
                                                    </p>
                                                )}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>마케팅 정보수신 동의</th>
                                        <td>
                                            <div className={classes.radio_wrap}>
                                                <Checkbox
                                                    className="official_st"
                                                    title="SMS"
                                                    id="smsYn"
                                                    onChange={smsYnHandler}
                                                    checked={smsYn === true}
                                                />
                                                <Checkbox
                                                    className="official_st"
                                                    title="이메일"
                                                    id="emailYn"
                                                    onChange={emailYnHandler}
                                                    checked={emailYn === true}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className={classes.btn_bottom_wrap}>
                                <Button onClick={cancelHandler}>취소</Button>
                                <Button className="secondary" onClick={signUpHandler}>
                                    가입하기
                                </Button>
                            </div>
                            {isModalOpen1 && (
                                <Modal close={closeModalHandler1} header="회원약관 동의">
                                    <SignTermsModal
                                        className="terms_personal_info"
                                        close={closeModalHandler1}
                                        ctAgreementInfo="ConsentTerms"
                                    />
                                </Modal>
                            )}
                            {isModalOpen2 && (
                                <Modal close={closeModalHandler2} header="개인정보 수집에 대한 동의">
                                    <SignTermsModal
                                        className="terms_personal_info"
                                        close={closeModalHandler2}
                                        ctAgreementInfo="ConsentCollectPrivacy"
                                    />
                                </Modal>
                            )}
                            {isDaumPostcodeOpen && (
                                <Modal className="admin_st" close={closeDaumPostcodeHandler} header="우편번호 검색">
                                    <DaumPostcode onComplete={handleAddressSelect} />
                                </Modal>
                            )}
                        </div>
                    </div>
                )}
                {successSignUp && (
                    <div className={classes.welcome_wrap}>
                        <h2>
                            {paramName}님 <span className="red_c">환영합니다.</span>
                        </h2>
                        <p className={classes.desc}>
                            회원가입이 완료되었습니다.
                            <br />
                            동일한 아이디와 비밀번호로 진승의 모든 회원제 사이트의 서비스를 안전하게 이용하실 수
                            있습니다.
                        </p>
                        <Link to="/">
                            <Button className="secondary">홈으로</Button>
                        </Link>
                    </div>
                )}
            </div>
        </div>
    );
}

export default SignUpPhone;
