import { useState } from "react";
import classes from "./MediaApp.module.css";
import Input from "../../components/UI/Input/Input";
import Button from "../../components/UI/Button/Button";
import Pagination from "../../components/UI/Table/Pagination";
import instance from "../../store/AxiosInterceptor";
import { NavLink, useLoaderData } from "react-router-dom";

function MainNoticeAppPage(props) {
    const notice = useLoaderData();
    const [text, setText] = useState(notice.data);
    const [topData, setTopData] = useState(notice.topData); // 상단 공지 데이터
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPage, setTotalPage] = useState(notice.totalPage); //총 페이지 갯수
    const [totalCount, setTotalCount] = useState(notice.totalCount); //총 페이지 갯수

    const handlePageChange = async (page) => {
        const pages = currentPage + 1;
        const searchParam = {
            page: pages,
            searchText: searchText,
        };
        setCurrentPage(pages);

        const response = await instance.get("/api/v1/home/contest/notice", {
            params: searchParam,
        });
        if (response.status === 200) {
            const resData = await response.data.datas;
            setTotalPage(resData.totalPages);
            setTotalCount(resData.totalElements);
            setText((prev) => [...prev, ...resData.content]);
        } else {
            setText([]);
        }
    };

    const [searchText, setSearchText] = useState("");

    const onSearchTextHandler = (value) => {
        setSearchText(value);
    };

    const findSearchHandler = async () => {
        setCurrentPage(0);

        const searchParam = {
            page: currentPage,
            searchText: searchText,
        };

        const response = await instance.get("/api/v1/home/contest/notice", {
            params: searchParam,
        });
        if (response.status === 200) {
            const resData = await response.data.datas;
            setTotalPage(resData.totalPages);
            setTotalCount(resData.totalElements);
            setText(resData.content);
        } else {
            setText([]);
        }
    };

    const increaseViews = (id) => {
        instance.put(`/api/v1/home/contest/${id}/views`).catch((error) => {
            console.error("Error increasing views:", error);
        });
    };
    return (
        <>
            <div className={classes.contents_wrap}>
                <div className={classes.contents_title}>
                    <div className={classes.search_wrap}>
                        <Input onChange={onSearchTextHandler} />
                        <Button
                            className="search_sm"
                            onClick={findSearchHandler}
                        />
                    </div>
                </div>
                <div className={classes.mobile_table_wrap}>
                    <table>
                        <colgroup>
                            <col style={{ width: "10%" }} />
                            <col style={{ width: "70%" }} />
                            <col style={{ width: "20%" }} />
                        </colgroup>
                        <thead>
                            <tr>
                                <th>번호</th>
                                <th>대회</th>
                                <th>날짜</th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* 상단 공지 데이터 표시 */}
                            {topData.map((data) => (
                                <NavLink to={`${data.id}`}>
                                    <tr onClick={() => increaseViews(data.id)}>
                                        <td>공지</td>
                                        <td>{data.title}</td>
                                        <td>{data.regDate}</td>
                                    </tr>
                                </NavLink>
                            ))}
                            {text.length !== 0 ? (
                                text.map((data, index) => {
                                    return (
                                        <NavLink to={`${data.id}`}>
                                            <tr
                                                onClick={() =>
                                                    increaseViews(data.id)
                                                }
                                            >
                                                <td>{totalCount - index}</td>
                                                <td>{data.title}</td>
                                                <td>{data.regDate}</td>
                                            </tr>
                                        </NavLink>
                                    );
                                })
                            ) : (
                                <div className={classes.empty_data}>
                                    <p>검색결과가 없습니다.</p>
                                </div>
                            )}
                        </tbody>
                    </table>
                    {/* 모바일에서 페이지 -> 더보기 */}
                    {totalCount !== text.length && (
                        <div className={classes.bottom_center_btn}>
                            <Button
                                className="text_icon"
                                onClick={handlePageChange}
                            >
                                더보기
                            </Button>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

export default MainNoticeAppPage;

export const loader = async () => {
    return instance
        .get("/api/v1/home/contest/notice?page=0")
        .then((response) => {
            const resData = response.data.datas;
            // console.log("resData ", resData);
            const curData = resData.content.map((data) => {
                return {
                    id: data.id,
                    title: data.title,
                    openOtherYn: data.openOtherYn,
                    readCnt: data.readCnt,
                    regID: data.regID,
                    regDate: data.regDate,
                };
            });
            return {
                data: curData,
                topData: response.data.topNoticeList,
                totalPage: resData.totalPages,
                totalCount: resData.totalElements,
            };
        })
        .catch((error) => {
            return [];
        });
};
