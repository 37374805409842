import { useState } from "react";
import classes from "../../MngCommon.module.css";
import Button from "../../../../components/UI/Button/Button";
import Input from "../../../../components/UI/Input/Input";
import FileBox from "../../../../components/UI/Input/FileBox";
import Radio from "../../../../components/UI/Input/Radio";
import DateTimePicker from "../../../../components/UI/DatePicker/DateTimePicker";
import { useLocation, useNavigate } from "react-router-dom";
import { useContext } from "react";
import { JwtTokenContext } from "../../../../store/JwtTokenProvider";
import moment from "moment";
import QuillEditor from "../../../../components/UI/Editer/QuillEditor";
import { useEffect } from "react";
import Alert from "../../../../components/UI/Modal/Alert";
import { ReqSaveEvent } from "../../../../api/event/MngEventApi";

function MngEventRegister() {
    const loginCtx = useContext(JwtTokenContext);
    const regId = loginCtx.decodedToken ? loginCtx.decodedToken.jti : "";
    const now = moment(new Date()).format("YYYY-MM-DD");

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const [eventTitle, setEventTitle] = useState("");
    const [exposeYn, setExposeYn] = useState("N");
    const [chosenExistYn, setChosenExistYn] = useState("N");

    const [content, setContent] = useState("");
    const contentChangeHandler = (value) => {
        setContent(value);
    };

    const onExposeHandler = (e) => {
        setExposeYn(e.target.value);
    };

    const onChosenExistHandler = (e) => {
        setChosenExistYn(e.target.value);
    };

    const onEventTitleHandler = (value) => {
        setEventTitle(value);
    };

    const startDateHandler = (value) => {
        setStartDate(value);
    };

    const endDateHandler = (value) => {
        setEndDate(value);
    };
    const location = useLocation();
    const navigate = useNavigate();

    const backHandler = () => {
        navigate(-1);
    };

    // >> 이미지 미리 보기 및 업로드
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [selectedFile, setSelectedFile] = useState();
    const [newFileUri, setNewFileUri] = useState();

    const confirmChangeHandler = () => {
        setSelectedFile(null);
        setNewFileUri(null);
        setShowConfirmation(false);
    };

    useEffect(() => {
        if (!selectedFile) {
            setNewFileUri(null);
            return;
        }

        const objectUrl = URL.createObjectURL(selectedFile);
        setNewFileUri(objectUrl);

        return () => URL.revokeObjectURL(objectUrl);
    }, [selectedFile]);

    const onSelectFile = (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            setSelectedFile(undefined);
            return;
        }

        setSelectedFile(e.target.files[0]);
    };
    //<<< 이미지 미리보기

    const [successModal, setSuccessModal] = useState(false);
    const modalHandler = () => {
        setSuccessModal(false);
        navigate("/admin/event/list", {state: location.state});
    };

    const saveHandler = () => {
        const jsonData = JSON.stringify({
            title: eventTitle,
            expose_yn: exposeYn,
            chosen_exist_yn: chosenExistYn,
            start_date: moment(startDate).format("YYYYMMDDHHmm"),
            end_date: moment(endDate).format("YYYYMMDDHHmm"),
            content: content,
        });

        if (eventTitle.trim() === "") {
            alert("필수항목을 입력해주세요.");
            return;
        }

        if (exposeYn === "") {
            alert("필수항목을 입력해주세요.");
            return;
        }

        if (chosenExistYn === "") {
            alert("필수항목을 입력해주세요.");
            return;
        }

        if (startDate == null) {
            alert("필수항목을 입력해주세요.");
            return;
        }

        if (endDate == null) {
            alert("필수항목을 입력해주세요.");
            return;
        }

        if (content.trim() === "") {
            alert("필수항목을 입력해주세요.");
            return;
        }

        const formData = new FormData();

        if (selectedFile) {
            formData.append("image", selectedFile);
        }

        formData.append("body", new Blob([jsonData], { type: "application/json" }));

        ReqSaveEvent(
            formData,
            (result) => {
                if (result.status === 200) {
                    setSuccessModal(true);
                    return;
                }
            },
            (error_result) => {
                alert("등록 에러");
            }
        );
    };
    return (
        <section>
            <div className={classes.admin_contents}>
                <h3>이벤트관리 - 등록</h3>
                <div className={`${classes.contents_wrap} ${classes.search_wrap} `}>
                    <div className={classes.admin_table_wrap}>
                        <table>
                            <colgroup>
                                <col style={{ width: "159px" }} />
                                <col style={{ width: "620px" }} />
                                <col style={{ width: "159px" }} />
                                <col style={{ width: "620px" }} />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>등록일</th>
                                    <td>{now}</td>
                                    <th>등록자</th>
                                    <td>{regId}</td>
                                </tr>
                                <tr>
                                    <th>노출여부</th>
                                    <td>
                                        <div className={classes.radio_wrap}>
                                            <Radio
                                                value="Y"
                                                title="노출"
                                                name="rad_01"
                                                id="rad_01_01"
                                                checked={exposeYn === "Y"}
                                                onClick={onExposeHandler}
                                            />
                                            <Radio
                                                value="N"
                                                title="미노출"
                                                name="rad_01"
                                                id="rad_01_02"
                                                checked={exposeYn === "N"}
                                                onClick={onExposeHandler}
                                            />
                                        </div>
                                    </td>
                                    <th>당첨자 발표 여부</th>
                                    <td>
                                        <div className={classes.radio_wrap}>
                                            <Radio
                                                value="Y"
                                                title="있음"
                                                name="rad_02"
                                                id="rad_02_01"
                                                checked={chosenExistYn === "Y"}
                                                onClick={onChosenExistHandler}
                                            />
                                            <Radio
                                                value="N"
                                                title="없음"
                                                name="rad_02"
                                                id="rad_02_02"
                                                checked={chosenExistYn === "N"}
                                                onClick={onChosenExistHandler}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>이벤트 기간</th>
                                    <td colSpan={3}>
                                        <div className={classes.flex_row}>
                                            <DateTimePicker
                                                id="start_date"
                                                onChange={startDateHandler}
                                                value={startDate}
                                            />
                                            ~
                                            <DateTimePicker id="end_date" onChange={endDateHandler} value={endDate} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>제목</th>
                                    <td colSpan={3}>
                                        <Input value={eventTitle} onChange={onEventTitleHandler} />
                                    </td>
                                </tr>
                                <tr>
                                    <th>내용</th>
                                    <td colSpan={3}>
                                        <div style={{ height: "400px" }}>
                                            <QuillEditor
                                                height="360"
                                                width="auto"
                                                onValueChangeCB={contentChangeHandler}
                                                value={content}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>이벤트 배너</th>
                                    <td colSpan={3}>
                                        <div className={classes.flex_row}>
                                            {!newFileUri && (
                                                <FileBox
                                                    type="file"
                                                    id="file"
                                                    accept="image/*"
                                                    onChange={onSelectFile}
                                                />
                                            )}
                                            {newFileUri && (
                                                <img
                                                    src={newFileUri}
                                                    alt="선택한 배너 미리보기"
                                                    style={{ width: "350px", height: "300px" }}
                                                />
                                            )}
                                            <p className={classes.desc}>* 이미지 사이즈 000*000px</p>
                                        </div>
                                        {newFileUri ? (
                                            <Button className="border sm" onClick={() => setShowConfirmation(true)}>
                                                파일삭제
                                            </Button>
                                        ) : null}
                                    </td>
                                </tr>
                                {showConfirmation && (
                                    <Alert
                                        type="confirm"
                                        cancel="취소"
                                        confirm="확인"
                                        close={() => setShowConfirmation(false)}
                                        confirmHandler={confirmChangeHandler}
                                    >
                                        {"파일을 삭제 하시겠습니까?"}
                                    </Alert>
                                )}
                                {successModal && (
                                    <Alert type="alert" confirm="확인" close={modalHandler}>
                                        {"이벤트가 등록되었습니다."}
                                    </Alert>
                                )}
                            </tbody>
                        </table>

                        <div className={classes.bottom_btn_wrap}>
                            <Button className="border sm" onClick={saveHandler}>
                                등록
                            </Button>
                            <Button className="secondary sm" onClick={backHandler}>
                                목록
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default MngEventRegister;
