import { useState } from "react";
import Button from "../../../components/UI/Button/Button";
import Modal from "../../../components/UI/Modal/Modal";
import classes from "../EventApp.module.css";
import { Link } from "react-router-dom";

function EventWinnerListComponentApp(props) {
    const [isModalOpen1, setIsModalOpen1] = useState(false);

    const openModalHandler1 = (e) => {
        e.stopPropagation();
        setIsModalOpen1(true);
    };

    const closeModalHandler1 = (e) => {
        e.stopPropagation();
        setIsModalOpen1(false);
    };

    return (
        <>
            <tr key={props.index}>
                <td>{props.seq}</td>
                <td>
                    {props.title}
                </td>
                <td>
                    {props.startDate} ~ {props.endDate}
                </td>
                <td>
                    {props.exposeChosenYn === "Y" ? (
                        <Button className="secondary sm" onClick={openModalHandler1}>
                            당첨자보기
                        </Button>
                    ) : (
                        "-"
                    )}
                </td>
            </tr>
            {isModalOpen1 && (
                <Modal close={closeModalHandler1} header="당첨자 발표 보기" key={`modal${props.index}`}>
                    <main>
                        <div className={classes.modal_title}>
                            <h4>{props.title}</h4>
                            <p>
                                {props.startDate} ~ {props.endDate}
                            </p>
                        </div>
                        <div className={classes.event_winner_content}>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: props.description,
                                }}
                            />
                        </div>
                    </main>
                    <footer>
                        <div className={classes.btn_bottom_wrap}>
                            <Button className="secondary" onClick={closeModalHandler1}>
                                확인
                            </Button>
                        </div>
                    </footer>
                </Modal>
            )}
        </>
    );
}

export default EventWinnerListComponentApp;
