import axios from "axios";
import { useContext, useEffect } from "react";
import { JwtTokenContext } from "./JwtTokenProvider";

const instance = axios.create({
    baseURL: "",
    headers: {
        Authorization: `${localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : null}`,
    },    
});

function AxiosInterceptor({ children }) {
    const tokenCtx = useContext(JwtTokenContext);
    //console.log('AxiosInterceptor:: call :: ');

    useEffect(() => {
        const errInterceptor = (error) => Promise.reject(error);
        const reqInterceptor = async (request) => {
            let tokenInfo = null;
            let token = tokenCtx.token;
            let refreshToken = tokenCtx.refreshToken;

            if (!tokenCtx.token) {
                tokenInfo = tokenCtx.getToken();
                if (!tokenInfo) {
                    return request;
                }
                token = tokenInfo.token;
                refreshToken = tokenInfo.refreshToken;
            }

            if (tokenCtx.isAccessExpired()) {
                // console.log('isRefreshExpired token', tokenCtx.decodedToken);
                tokenCtx.removeToken();
                return request;
            }
            //console.log('AxiosInterceptor:: reqInterceptor :: ', request.url, tokenCtx.isAccessExpired(), token);
            /* 자동으로 토큰 Refresh를 위한 작업 수행 일부러 지원하지 않도록 막아둠
            if (tokenCtx.isAccessExpired()) {
                if (tokenCtx.isRefreshExpired(refreshToken)) {
                    // console.log('isRefreshExpired token', tokenCtx.decodedToken);
                    tokenCtx.removeToken();
                    return request;
                } else {
                    // 재요청
                    const response = await axios({
                        method: "post",
                        url: "/api/v1/auth/refresh",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${refreshToken}`,
                        },
                    });

                    //console.log('/api/v1/auth/refresh token', tokenCtx.decodedToken);
                    const { status, access_token, refresh_token } =
                        await response.data;
                    if (status === 200) {
                        tokenCtx.addToken({
                            token: access_token,
                            refreshToken: refresh_token,
                        });
                        token = access_token;
                        refreshToken = refresh_token;
                    } else {
                        token = null;
                        refreshToken = null;
                    }
                }
            }*/

            request.headers["Authorization"] = `Bearer ${token}`;

            // console.log('AxiosInterceptor:: Authorization :: ', request.headers['Authorization']);

            return request;
        };

        const useReqinterceptor = instance.interceptors.request.use(
            reqInterceptor,
            errInterceptor
        );
        const resInterceptor = async (response) => {
            // [A] 20240503 YiSuHwan 데이터는 정상이나 서버응답값만 403으로 채워서 내려올때 처리. 서버도 동일하게 해주어야함
            if (403 === response?.data?.status) {
                console.log("remove token due to data.status == 403");
                tokenCtx.removeToken();
            }

            return response;
        };
        const resErrInterceptor = (error) => {
            console.log("resErrInterceptor response.status === ", error.response.status);
            if (error.response.status === 401) {
                tokenCtx.removeToken();
            }
            // [A] 20240503 YiSuHwan 마이페이지에서 튕기지 않는 현상관련
            if (error.response.status === 403) {
                tokenCtx.removeToken();
            }
            return Promise.reject(error);
        }
        const useResinterceptor = instance.interceptors.response.use(
            resInterceptor,
            resErrInterceptor
        );

        return () => {
            instance.interceptors.request.eject(useReqinterceptor);
            instance.interceptors.response.eject(useResinterceptor);
        };
    }, [tokenCtx]);

    return children;
}

export default instance;
export { AxiosInterceptor };
