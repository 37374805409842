import { useContext, useState } from "react";
import Button from "../../../../components/UI/Button/Button";
import DateTimePicker from "../../../../components/UI/DatePicker/DateTimePicker";
import Input from "../../../../components/UI/Input/Input";
import Radio from "../../../../components/UI/Input/Radio";
import classes from "../../MngCommon.module.css";
import { useNavigate } from "react-router-dom";
import Moment from 'moment';
import { ReqSystemPopupCreate } from "../../../../api/site/ApiForSystemPopup";
import { JwtTokenContext } from "../../../../store/JwtTokenProvider";

function MngSystemCreate(props) {

    const loginCtx = useContext(JwtTokenContext);

    const navigate = useNavigate();

    const backHandler = () => {
        navigate("/admin/site/system");
    };

    const [title, setTitle] = useState();
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [exposeYnStatus, setExposeYnStatus ] = useState("Y")
    const [notiStartDate, setNotiStartDate] = useState(new Date());
    const [notiEndDate, setNotiEndDate] = useState(new Date());
    const [notiExposeYnStatus, setNotiExposeYnStatus ] = useState("Y")

    const changeTitleHandler = (value) => {
        setTitle(value);
    };

    const exposeYnRadioButton = (e) => {
        setExposeYnStatus(e.target.value)
    }

    const startDateHandler = (date) => {
        setStartDate(date);
    };

    const endDateHandler = (date) => {
        setEndDate(date);
    };

    const notiExposeYnRadioButton = (e) => {
        setNotiExposeYnStatus(e.target.value)
    }

    const notiStartDateHandler = (date) => {
        setNotiStartDate(date);
    };

    const notiEndDateHandler = (date) => {
        setNotiEndDate(date);
    };

    const createHandler = async (e) => {
        e.preventDefault();
        
        if(!title) {
          alert("제목을 입력해주세요.");
          return
        }

        if(endDate < startDate) {
            alert("시스템점검일시 종료일보다 시작일이 클 수 없습니다.");
            return
        }

        if(notiEndDate < notiStartDate) {
            alert("시스템 점검 사전 안내 팝업 종료일보다 시작일이 클 수 없습니다.");
            return
        }

        const isTrue = window.confirm('내용을 등록하시겠습니까?');
        if(isTrue) {
            try {
                const jsonData = {
                    title: title,
                    exposeYn: exposeYnStatus === "Y" ? true : false,
                    startDate : Moment(startDate).format('YYYYMMDDHHmm'),
                    endDate : Moment(endDate).format('YYYYMMDDHHmm'),
                    notiExposeYn: notiExposeYnStatus === "Y" ? true : false,
                    notiStartDate : Moment(notiStartDate).format('YYYYMMDDHHmm'),
                    notiEndDate : Moment(notiEndDate).format('YYYYMMDDHHmm'),
                };

                const response = await ReqSystemPopupCreate(jsonData);
                if (response.status === 200) {
                    alert("내용이 등록되었습니다.");
                    backHandler();
                } else {
                    alert("내용 등록 실패");
                }
            } catch (error) {
                console.error("Error:", error);
            }
        } else {
          return
        }

        
    };

    return (
        <section>
            <div className={classes.admin_contents}>
                <h3>시스템 점검 안내 - 등록</h3>
                <div
                    className={`${classes.contents_wrap} ${classes.search_wrap} `}
                >
                    <div className={classes.admin_table_wrap}>
                        <table>
                            <colgroup>
                                <col style={{ width: "10%" }} />
                                <col style={{ width: "10%" }} />
                                <col style={{ width: "30%" }} />
                                <col style={{ width: "10%" }} />
                                <col style={{ width: "40%" }} />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>등록일</th>
                                    <td colSpan={2}>{Moment(new Date()).format('YYYY-MM-DD')}</td>
                                    <th>등록자</th>
                                    <td>{loginCtx.decodedToken.jti}</td>
                                </tr>
                                <tr>
                                    <th>사용여부</th>
                                    <td colSpan={4}>
                                        <div className={classes.radio_wrap}>
                                            <Radio
                                                value="Y"
                                                title="사용"
                                                name="rad_01"
                                                id="rad_01_01"
                                                checked={exposeYnStatus === "Y"} 
                                                onChange={exposeYnRadioButton}
                                            />
                                            <Radio
                                                value="N"
                                                title="미사용"
                                                name="rad_01"
                                                id="rad_01_02"
                                                checked={exposeYnStatus === "N"} 
                                                onChange={exposeYnRadioButton}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>시스템 점검 일시</th>
                                    <td colSpan={4}>
                                        <div className={classes.flex_row}>
                                            <DateTimePicker id="start_date" onChange={startDateHandler} value={startDate}/>
                                            <em>~</em>
                                            <DateTimePicker id="end_date" onChange={endDateHandler}  value={endDate}/>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th rowSpan={2}>
                                        시스템 점검
                                        <br />
                                        사전 안내 팝업
                                    </th>
                                    <th>노출여부</th>
                                    <td colSpan={3}>
                                        <div className={classes.radio_wrap}>
                                            <Radio
                                                value="Y"
                                                title="노출"
                                                name="rad_02"
                                                id="rad_02_01"
                                                checked={notiExposeYnStatus === "Y"} 
                                                onChange={notiExposeYnRadioButton}
                                            />
                                            <Radio
                                                value="N"
                                                title="미노출"
                                                name="rad_02"
                                                id="rad_02_02"
                                                checked={notiExposeYnStatus === "N"} 
                                                onChange={notiExposeYnRadioButton}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>노출기간</th>
                                    <td colSpan={3}>
                                        <div className={classes.flex_row}>
                                            <DateTimePicker id="start_date" onChange={notiStartDateHandler} value={notiStartDate}/>
                                            <em>~</em>
                                            <DateTimePicker id="end_date" onChange={notiEndDateHandler}  value={notiEndDate}/>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>점검내용</th>
                                    <td colSpan={4}>
                                        <Input
                                            className="admin_st"
                                            value={title}
                                            onChange={changeTitleHandler}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className={classes.bottom_btn_wrap}>
                            <Button className="secondary sm" onClick={createHandler}>등록</Button>
                            <Button className="border sm" onClick={() => backHandler(0)}>목록</Button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default MngSystemCreate;
