import React, { useState } from "react";
import Alert from "../UI/Modal/Alert";
import { useNavigate } from "react-router-dom";

const NiceDuplicate = () => {
    const navigate = useNavigate();
    const [showCancelModal, setShowCancelModal] = useState(true);

    const modalHandler = (state) => {
        window.close();
        setShowCancelModal(state);
        navigate("/login");
    };

    return (
        <>
            {showCancelModal && (
                <Alert type="alert" confirm="확인" close={() => modalHandler(false)}>
                    {"고객님은 이미 가입하신 아이디가 있습니다."}
                </Alert>
            )}
        </>
    );
};

export default NiceDuplicate;
