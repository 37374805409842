import { useState } from "react";
import classes from "../../MngCommon.module.css";
import Button from "../../../../components/UI/Button/Button";
import Radio from "../../../../components/UI/Input/Radio";
import Modal from "../../../../components/UI/Modal/Modal";
import instance from "../../../../store/AxiosInterceptor";
import { useLoaderData, useLocation, useNavigate, useParams } from "react-router-dom";
import {
    ReqDeleteHelpComment,
    ReqFindAlimTalkHistoryForHelp,
    ReqHelpAddComment,
    ReqModifyHelpComment,
    ReqSendAlimTalkForHelp,
} from "../../../../api/community/ASAPi";
import Alert from "../../../../components/UI/Modal/Alert";
import QuillEditor from "../../../../components/UI/Editer/QuillEditor";
import { useRef } from "react";
import { useEffect } from "react";

function MngCsDetail() {
    const resData = useLoaderData();
    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const [comment, setComment] = useState("");
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [deletedId, setDeletedId] = useState();
    const [editedId, setEditedId] = useState();
    const [images, setImages] = useState(resData.images);
    const formattedNumber =
        resData.phone?.indexOf("-") === -1
            ? resData.phone.replace(/(\d{2,3})(\d{3,4})(\d{4})/, "$1-$2-$3")
            : resData.phone;

    const commentHandler = (value) => {
        setComment(value);
    };

    const [process, setProcess] = useState(resData.process);
    const processHandler = (e) => {
        setProcess(e.target.value);
    };

    const postObject = {
        id: params.helpId,
        comment: comment,
        answer_complete: "Y",
    };

    const postHandler = () => {
        ReqHelpAddComment(
            postObject,
            (result) => {
                if (result.status === 200) {
                    openAlertHandler();
                }
            },
            (error_result) => {
                alert("통신 에러");
            }
        );
    };

    const [deleteComplete, setDeleteComplete] = useState(false);

    const deleteHandler = (commentId) => {
        setDeletedId(commentId);
        setShowDeleteConfirmation(true);
    };

    const cancelDeleteHandler = () => {
        setDeletedId(null);
        setShowDeleteConfirmation(false);
    };

    const serverDeleteHandler = () => {
        ReqDeleteHelpComment(
            params.helpId,
            deletedId,
            (result) => {
                if (result.status === 200) {
                    setDeleteComplete(true);
                }
            },
            (error_result) => {
                alert("통신 에러");
            }
        );
    };
    useEffect(() => {
        const toList = () => { navigate("/admin/community/cs", { state: location.state }); }
        window.addEventListener('popstate', toList);
        return () => {window.removeEventListener('popstate', toList);};
    }, []);
    const deleteCompleteNavigation = () => {
        navigate("/admin/community/cs", {state: location.state});
    };

    const [selectedComment, setSelectedComment] = useState("");
    const [number, setNumber] = useState();
    const modifyCommentHandler = (value) => {
        setSelectedComment(value);
    };

    const [inputDisabled, setInputDisabled] = useState(true);
    const [commentDate, setCommentDate] = useState("");
    const [commentRegId, setCommentRegId] = useState("");

    const editHandler = (commentId, no, comment, commentRegDate, commentRegId) => {
        setInputDisabled(false);
        setEditedId(commentId);
        setNumber(no);
        setSelectedComment(comment);
        setCommentDate(commentRegDate);
        setCommentRegId(commentRegId);
    };

    const modifiedProcess = process === "Close" ? "Y" : "N";
    const [modifyConfirm, setModifyConfirm] = useState(false);
    const reqModifyCommentHandler = () => {
        setModifyConfirm(false);
        ReqModifyHelpComment(
            params.helpId,
            editedId,
            selectedComment,
            modifiedProcess,
            (result) => {
                if (result.status === 200) {
                    alert("수정되었습니다.");
                    navigate("/admin/community/cs", {state: location.state});
                }
            },
            (error_result) => {
                alert("서버 통신 에러");
            }
        );
    };

    const navigateHandler = (link) => {
        navigate(link, {state: location.state});
        setShowConfirmation(false);
    };

    const editModeCancelHandler = () => {
        setInputDisabled(true);
        setNumber(null);
        setSelectedComment("");
        setCommentDate(null);
        setCommentRegId(null);
    };

    const [isModalOpen1, setIsModalOpen1] = useState(false);
    const openModalHandler1 = (e) => {
        e.stopPropagation();
        setIsModalOpen1(true);
    };
    const closeModalHandler1 = (e) => {
        e.stopPropagation();
        setIsModalOpen1(false);
    };

    const [isModalOpen2, setIsModalOpen2] = useState(false);
    const openModalHandler2 = () => {
        setAlimTalkSuccess(false);
        setIsModalOpen2(true);
    };

    const closeModalHandler2 = () => {
        setIsModalOpen2(false);
    };

    const [isModalOpen3, setIsModalOpen3] = useState(false);
    const openModalHandler3 = () => {
        setIsModalOpen3(true);
    };

    const closeModalHandler3 = () => {
        setIsModalOpen3(false);
    };

    const [showConfirmation, setShowConfirmation] = useState(false);
    const openAlertHandler = () => {
        setShowConfirmation(true);
    };

    const closeAlertHandler = () => {
        setShowConfirmation(false);
    };

    const alertRef = useRef(null);
    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            closeAlertHandler();
            navigateHandler("/admin/community/cs");
        }
    };

    useEffect(() => {
        if (showConfirmation && alertRef.current) {
            alertRef.current.focus();
        }
    }, [showConfirmation]);

    const [alimTalkConfirm, setAlimTalkConfirm] = useState(false);
    const alimTalkConfirmHandler = (state) => {
        closeModalHandler3();
        setAlimTalkConfirm(state);
    };

    const [alimTalkSuccess, setAlimTalkSuccess] = useState(false);
    const [alimTalkFail, setAlimTalkFail] = useState(false);

    const alimTalkHandler = () => {
        ReqSendAlimTalkForHelp(
            params.helpId,
            (result) => {
                if (result.status === 200) {
                    setAlimTalkSuccess(true);
                } else {
                    setAlimTalkFail(true);
                }
            },
            (error_result) => {
                setAlimTalkFail(true);
            }
        );
    };

    const [alimTalkHistory, setAlimTalkHistory] = useState([]);
    const alimtalkHistoryHandler = () => {
        ReqFindAlimTalkHistoryForHelp(
            resData.request_id,
            (result) => {
                if (result.status === 200) {
                    setAlimTalkHistory(result.datas);
                } else {
                    setAlimTalkHistory([]);
                }
            },
            (error_result) => {
                setAlimTalkHistory([]);
            }
        );
    };

    useEffect(() => {
        alimtalkHistoryHandler();
    }, [isModalOpen2]);

    return (
        <section>
            <div className={classes.admin_contents}>
                <h3>1:1 문의 - 상세</h3>
                <div className={`${classes.contents_wrap} ${classes.search_wrap} `}>
                    <div className={classes.admin_table_wrap}>
                        <table>
                            <colgroup>
                                <col style={{ width: "159px" }} />
                                <col style={{ width: "620px" }} />
                                <col style={{ width: "159px" }} />
                                <col style={{ width: "620px" }} />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>처리일</th>
                                    <td>{resData.adm_upd_date ? resData.adm_upd_date : ""}</td>
                                    <th>처리자</th>
                                    <td>{resData.handling_member ? resData.handling_member : ""}</td>
                                </tr>
                                <tr>
                                    <th>처리상태</th>
                                    <td colSpan={3}>
                                        <div className={classes.radio_wrap}>
                                            <Radio
                                                value="Request"
                                                checked={process === "Request"}
                                                title="접수"
                                                name="rad_01"
                                                id="rad_01_01"
                                                onChange={processHandler}
                                            />
                                            <Radio
                                                value="Close"
                                                checked={process === "Close"}
                                                title="답변 완료"
                                                name="rad_01"
                                                id="rad_01_02"
                                                onChange={processHandler}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>문의일자</th>
                                    <td colSpan={4}>{resData.reqDate}</td>
                                </tr>
                                <tr>
                                    <th>문의항목</th>
                                    <td colSpan={3}>{resData.as_support_type}</td>
                                </tr>
                                <tr>
                                    <th>작성자</th>
                                    <td>{resData.request_name}</td>
                                    <th>아이디</th>
                                    <td>{resData.request_id}</td>
                                </tr>
                                <tr>
                                    <th>휴대폰</th>
                                    <td colSpan={4}>{formattedNumber}</td>
                                </tr>
                                <tr>
                                    <th>문의제목</th>
                                    <td colSpan={4}>{resData.title}</td>
                                </tr>
                                <tr>
                                    <th>문의내용</th>
                                    <td colSpan={4}>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: resData.content,
                                            }}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>첨부파일</th>
                                    <td colSpan={3}>
                                        {images?.length > 0 &&
                                            images.map((data) => (
                                                <img
                                                    src={data.uri}
                                                    alt={data.origin_name}
                                                    style={{ width: "265px", height: "265px" }}
                                                />
                                            ))}
                                        {images?.length === 0 && (
                                            <div className={classes.product_slider}>파일 없음</div>
                                        )}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table className={classes.table_in_table}>
                            <colgroup>
                                <col style={{ width: "159px" }} />
                                <col style={{ width: "620px" }} />
                                <col style={{ width: "159px" }} />
                                <col style={{ width: "620px" }} />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>답변입력</th>
                                    <td colSpan={3}>
                                        <div style={{ height: "300px" }}>
                                            <QuillEditor
                                                height="260"
                                                width="auto"
                                                onValueChangeCB={commentHandler}
                                                value={comment}
                                            />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className={`${classes.bottom_btn_wrap} ${classes.multi}`}>
                            <div className={classes.left}>
                                <Button className="secondary sm" onClick={openModalHandler3}>
                                    문자발송
                                </Button>
                                <Button className=" border sm" onClick={openModalHandler2}>
                                    문자발송 내역
                                </Button>
                            </div>
                            <div className={classes.right}>
                                <Button className="border sm" onClick={postHandler}>
                                    저장
                                </Button>
                                <Button className="secondary sm" onClick={() => navigateHandler("/admin/community/cs")}>
                                    목록
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`${classes.contents_wrap}`}>
                    <h4>답변이력</h4>
                    <div className={`${classes.admin_table_wrap} ${classes.align_center}`}>
                        <table className={classes.left_col_2}>
                            <colgroup>
                                <col style={{ width: "5%" }} />
                                <col style={{ width: "65%" }} />
                                <col style={{ width: "10%" }} />
                                <col style={{ width: "10%" }} />
                                <col style={{ width: "10%" }} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th>답변내역</th>
                                    <th>관리</th>
                                    <th>답변일</th>
                                    <th>담당자</th>
                                </tr>
                            </thead>
                            <tbody>
                                {resData.comments.length > 0 &&
                                    resData.comments.map((data, index) => (
                                        <tr key={data.id}>
                                            <td>{index + 1}</td>
                                            <td>
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: data.comment,
                                                    }}
                                                />
                                            </td>
                                            <td>
                                                <div className={classes.flex_col}>
                                                    <Button
                                                        className="border xs"
                                                        onClick={() =>
                                                            editHandler(
                                                                data.id,
                                                                index + 1,
                                                                data.comment,
                                                                data.reg_date,
                                                                data.reg_id
                                                            )
                                                        }
                                                    >
                                                        수정
                                                    </Button>
                                                    <Button
                                                        className="secondary xs"
                                                        onClick={() => deleteHandler(data.id)}
                                                    >
                                                        삭제
                                                    </Button>
                                                </div>
                                            </td>
                                            <td>{data.reg_date}</td>
                                            <td>{data.reg_id}</td>
                                        </tr>
                                    ))}
                                <tr>
                                    <td>{number}</td>
                                    <td>
                                        <div style={{ height: "200px" }}>
                                            {!inputDisabled && (
                                                <QuillEditor
                                                    height="160"
                                                    width="auto"
                                                    onValueChangeCB={modifyCommentHandler}
                                                    value={selectedComment}
                                                />
                                            )}
                                        </div>
                                    </td>
                                    <td>
                                        <div className={classes.flex_col}>
                                            {!inputDisabled && (
                                                <Button className="border xs" onClick={() => setModifyConfirm(true)}>
                                                    저장
                                                </Button>
                                            )}
                                            {!inputDisabled && (
                                                <Button className="secondary xs" onClick={editModeCancelHandler}>
                                                    취소
                                                </Button>
                                            )}
                                        </div>
                                    </td>
                                    <td>{commentDate}</td>
                                    <td>{commentRegId}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {isModalOpen1 && (
                <Modal className="admin_st w_360" close={closeModalHandler1} header="고객 첨부 이미지">
                    <main>
                        <div className={classes.pop_img_preview}>
                            <img src={`${resData.attach_uri}`} alt="고객 첨부 이미지" />
                        </div>
                    </main>
                </Modal>
            )}
            {showConfirmation && (
                <Alert type="alert" confirm="확인" close={() => navigateHandler("/admin/community/cs")}>
                    <div tabIndex={0} ref={alertRef} onKeyDown={handleKeyPress}>
                        답변이 등록되었습니다.
                    </div>
                </Alert>
            )}
            {deleteComplete && (
                <Alert type="alert" confirm="확인" close={deleteCompleteNavigation}>
                    답변이 삭제되었습니다.
                </Alert>
            )}
            {showDeleteConfirmation && (
                <Alert
                    type="confirm"
                    cancel="취소"
                    confirm="확인"
                    close={cancelDeleteHandler}
                    confirmHandler={serverDeleteHandler}
                >
                    {"답변을 삭제하시겠습니까?"}
                </Alert>
            )}
            {modifyConfirm && (
                <Alert
                    type="confirm"
                    cancel="취소"
                    confirm="확인"
                    close={() => setModifyConfirm(false)} // 모달 닫기
                    confirmHandler={reqModifyCommentHandler} // 확인 버튼 눌렀을 때 실행되는 Handler
                >
                    {"수정된 내용을 저장하시겠습니까?"}
                </Alert>
            )}
            {isModalOpen2 && (
                <Modal className="admin_st w_1200" close={closeModalHandler2} header="문자발송 내역">
                    <main>
                        <div>
                            {alimTalkHistory.length > 0 && (
                                <table className="align_center align_left_col3">
                                    <colgroup>
                                        <col style={{ width: "10%" }} />
                                        <col style={{ width: "13%" }} />
                                        <col style={{ width: "32%" }} />
                                        <col style={{ width: "10%" }} />
                                        <col style={{ width: "10%" }} />
                                        <col style={{ width: "8%" }} />
                                        <col style={{ width: "13%" }} />
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th>아이디</th>
                                            <th>수신번호</th>
                                            <th>내용</th>
                                            <th>발송상태</th>
                                            <th>발송결과</th>
                                            <th>발송일자</th>
                                            <th>발송자</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {alimTalkHistory.map((data, index) => (
                                            <tr key={index}>
                                                <td>{data.member_id}</td>
                                                <td>{data.tel?.replace(/(\d{2,3})(\d{3,4})(\d{4})/, "$1-$2-$3")}</td>
                                                <td>
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: data.message.replaceAll("\n", "<br/>"),
                                                        }}
                                                    />
                                                </td>
                                                <td>{data.sent_type === "KAKAO" ? "알림톡" : "SMS"}</td>
                                                <td>
                                                    {data.success === true ? "발송 성공" : "발송 실패"}
                                                    <p className="red_c">{data.fail_state ? data.fail_state : ""}</p>
                                                </td>
                                                <td>{data.reg_date}</td>
                                                <td>{data.reg_id}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            )}
                            {alimTalkHistory.length === 0 && (
                                <div className={`${classes.empty_data} ${classes.sm}`}>
                                    <p>문자발송 내역이 없습니다.</p>
                                </div>
                            )}
                        </div>
                    </main>
                    <footer>
                        <Button className="secondary sm" onClick={closeModalHandler2}>
                            확인
                        </Button>
                    </footer>
                </Modal>
            )}
            {isModalOpen3 && (
                <Modal className="admin_st w_360" close={closeModalHandler3} header="미리보기">
                    <main>
                        <div className={classes.text_bx}>
                            {/* {messageCB.message} */}
                            <pre
                                style={{
                                    "text-wrap": "wrap",
                                    "font-family": "pretendard",
                                }}
                            >
                                [1:1문의 답변안내]
                                <br />
                                {resData.request_name}님, 1:1 문의글에 답변 하였습니다.
                            </pre>
                        </div>
                        <div className={classes.send_test_num}>
                            <b>수신번호</b>
                            {formattedNumber}
                        </div>
                    </main>
                    <footer>
                        <Button className="secondary sm" onClick={() => alimTalkConfirmHandler(true)}>
                            문자발송
                        </Button>
                        <Button className="sm" onClick={closeModalHandler3}>
                            취소
                        </Button>
                    </footer>
                </Modal>
            )}
            {alimTalkConfirm && (
                <Alert
                    type="confirm"
                    cancel="취소"
                    confirm="확인"
                    close={() => alimTalkConfirmHandler(false)}
                    confirmHandler={alimTalkHandler}
                >
                    {"문자를 발송하시겠습니까?"}
                </Alert>
            )}
            {alimTalkSuccess && (
                <Alert type="alert" confirm="발송결과 확인" close={openModalHandler2}>
                    문자 발송이 완료되었습니다.
                </Alert>
            )}
            {alimTalkFail && (
                <Alert type="alert" confirm="확인" close={() => setAlimTalkFail(false)}>
                    문자 발송 실패하였습니다.
                </Alert>
            )}
        </section>
    );
}
export default MngCsDetail;

//>>> Loader
export const loader = async ({ params }) => {
    const response = await instance({
        url: `/api/v1/admin/board/help/${params.helpId}`,
        method: "GET",
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    }).catch(function (error) {
        if (error.response) {
            return error.response;
        } else if (error.request) {
            return null;
        } else {
            return null;
        }
    });

    if (response == null || response.status !== 200) {
        return [];
    }
    if (response.data.status === 200) {
        return response.data.data;
    } else {
        return [];
    }
};
//<<< Loader
