import classes from "../../MngCommon.module.css";
import Button from "../../../../components/UI/Button/Button";
import Input from "../../../../components/UI/Input/Input";
import Radio from "../../../../components/UI/Input/Radio";
import FileBoxMj from "../../../../components/UI/Input/FileBoxMj";
import { Link, useLoaderData, useNavigate } from "react-router-dom";
import { React, useState, useEffect, useContext } from "react";
import { DefaultAll } from "../../../../components/definedefault/DefSelectBoxValues";
import { ReqContestType } from "../../../../api/common/ConditionSelectBoxApi";
import SelectBoxNew from "../../../../components/UI/SelectBox/SelectBoxNew";
import {
    ReqCTNoticeForAdmin,
    ReqUpdateCTNoticeForAdmin,
} from "../../../../api/board/BoardApi";
import QuillEditor from "../../../../components/UI/Editer/QuillEditor";
import { JwtTokenContext } from "../../../../store/JwtTokenProvider";
import FileBoxUpdateMj from "../../../../components/UI/Input/FileBoxUpdateMj";
const MngTournamentNoticeUpdate = () => {
    const loginCtx = useContext(JwtTokenContext);
    const resData = useLoaderData();
    const navigate = useNavigate();

    if (!resData) {
        navigate(-1); // 뒤로가기
    }

    const [isUse, setIsUse] = useState(resData.exposeYn);
    // const [isUse, setIsUse] = useState(resData.openOtherYn);
    const [isTop, setIsTop] = useState(resData.noticeYn);
    const [fileBoxes, setFileBoxes] = useState([...resData.attach_uri_list]);
    const [files, setFiles] = useState([]); // 파일 배열
    const [fileNames, setFileNames] = useState([...resData.attach_file_name_list]); // 파일 이름 배열
    const [enteredTitle, setEnteredTitle] = useState(resData.title);
    const [enteredContent, setEnteredContent] = useState(resData.content);

    // <<< 대회 타입 가져오기(Select Box) */
    const [categoryData, setCategoryData] = useState([DefaultAll]);
    const [type, setType] = useState(resData.ct_type);

    // 사용 여부
    const isUseHandler = (e) => {
        setIsUse(e.target.value);
    };

    // 상단 여부
    const isTopHandler = (e) => {
        setIsTop(e.target.value);
    };

    const changeTitleHandler = (value) => {
        setEnteredTitle(value);
    };

    const contentChangeHandler = (value) => {
        setEnteredContent(value);
    };

    // 새 항목을 추가
    const handleAddFileBox = () => {
        setFileBoxes([...fileBoxes, null]);
        setFiles([...files, null]);
        setFileNames([...fileNames, ""]);
    };

    // 맨 마지막 항목을 삭제
    const handleDelFileBox = () => {
        if (fileBoxes.length === 0) {
            return; // 항목이 없을 경우 종료
        }

        const newFileBoxes = [...fileBoxes];
        newFileBoxes.pop(); // 맨 마지막 항목 삭제
        setFileBoxes(newFileBoxes);

        const newFiles = [...files];
        newFiles.pop();
        setFiles(newFiles);

        const newFileNames = [...fileNames];
        newFileNames.pop();
        setFileNames(newFileNames);
    };

    // 파일 변경 이벤트 핸들러
    const handleFileChange = (file, index) => {
        const newFiles = [...files];
        newFiles[index] = file;
        setFiles(newFiles);

        const newFileNames = [...fileNames];
        newFileNames[index] = file ? file.name : "";
        setFileNames(newFileNames);
    };

    // 등록일 (오늘 날짜 표시)
    const today = () => {
        const now = new Date();

        return (
            now.getFullYear() +
            "-" +
            ("0" + (now.getMonth() + 1)).slice(-2) +
            "-" +
            // now.getDate()
            ("0" + now.getDate()).slice(-2)
        );
    };

    const editHandler = async (e) => {
        e.preventDefault();

        const jsonData = JSON.stringify({
            title: enteredTitle,
            content: enteredContent,
            exposeYn: isUse,
            openOtherYn: isUse,
            updID: "admin",
            ct_type: type,
            noticeYn: isTop, // 공지
            board_write_type: "NOMAL",
            attach_uri_list: fileNames,
        });
        const blob = new Blob([jsonData], { type: "application/json" });

        const formData = new FormData();
        files.forEach((image, index) => {
            formData.append("image", image);
        });
        formData.append("body", blob);
        const response = await ReqUpdateCTNoticeForAdmin(resData.id, formData);
        if (response.status === 200) {
            navigate("/admin/tournament/notice");
        } else {
            console.log(response);
        }
    };

    // 대회 유형 select box
    const typeHandler = (key) => {
        setType(key);
    };

    useEffect(() => {
        // 컴포넌트 첫 로드시에만 카테고리 목록, 지역 목록을 가져온다.
        ReqContestType(
            (result) => {
                const category = result.map((data) => {
                    return {
                        key: data.type,
                        value: data.name,
                        disabled: false,
                    };
                });
                setCategoryData((prev) => [DefaultAll, ...category]);
            },
            (result) => {
                setCategoryData((prev) => [DefaultAll]);
            }
        );
    }, []);

    return (
        <section>
            <div className={classes.admin_contents}>
                <h3>대회공지 - 수정</h3>
                <div className={`${classes.contents_wrap} ${classes.search_wrap}`}>
                    <div className={classes.admin_table_wrap}>
                        <table>
                            <colgroup>
                                <col style={{ width: "10%" }} />
                                <col style={{ width: "40%" }} />
                                <col style={{ width: "10%" }} />
                                <col style={{ width: "40%" }} />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>등록일</th>
                                    <td>{today()}</td>
                                    <th>등록자</th>
                                    <td>{loginCtx.decodedToken.jti}</td>
                                </tr>
                                <tr>
                                    <th>상단공지여부</th>
                                    <td>
                                        <div className={classes.radio_wrap}>
                                            <Radio
                                                value="Y"
                                                title="적용"
                                                name="rad_01"
                                                id="rad_01_01"
                                                checked={isTop === "Y"}
                                                onChange={isTopHandler}
                                            />
                                            <Radio
                                                value="N"
                                                title="미적용"
                                                name="rad_01"
                                                id="rad_01_02"
                                                checked={isTop === "N"}
                                                onChange={isTopHandler}
                                            />
                                        </div>
                                    </td>
                                    <th>사용여부</th>
                                    <td>
                                        <div className={classes.radio_wrap}>
                                            <Radio
                                                value="Y"
                                                title="사용"
                                                name="rad_02"
                                                id="rad_02_01"
                                                checked={isUse === "Y"}
                                                onChange={isUseHandler}
                                            />
                                            <Radio
                                                value="N"
                                                title="미사용"
                                                name="rad_02"
                                                id="rad_02_02"
                                                checked={isUse === "N"}
                                                onChange={isUseHandler}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>유형</th>
                                    <td colSpan={3}>
                                        <div className={classes.flex_row}>
                                            <SelectBoxNew
                                                className="admin_st"
                                                setSelectItemCB={typeHandler}
                                                optionDatas={categoryData}
                                                showDefaultKey={type}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>제목</th>
                                    <td colSpan={3}>
                                        <Input
                                            type="text"
                                            name="title"
                                            className="admin_st"
                                            placeholder="제목 입력"
                                            value={enteredTitle}
                                            onChange={changeTitleHandler}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={4}>
                                        <div style={{ height: "500px" }}>
                                            <QuillEditor
                                                height="460"
                                                width="auto"
                                                onValueChangeCB={contentChangeHandler}
                                                value={enteredContent}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>첨부파일</th>
                                    <td colSpan={3}>
                                        <Button
                                            className="border sm"
                                            onClick={handleAddFileBox}
                                        >
                                            항목추가하기
                                        </Button>
                                        <Button
                                            className="secondary sm"
                                            onClick={handleDelFileBox}
                                        >
                                            항목삭제하기
                                        </Button>
                                        <div>
                                            {fileBoxes.map((item, index) => (
                                                <div key={index}>
                                                    <FileBoxUpdateMj
                                                        onFileChange={(file) =>
                                                            handleFileChange(file, index)
                                                        }
                                                        selectedFileName={
                                                            fileNames[index]
                                                        }
                                                        file={files[index]}
                                                        setFile={(newFile) => {
                                                            const newFiles = [...files];
                                                            newFiles[index] = newFile;
                                                            setFiles(newFiles);
                                                        }}
                                                        setFileName={(newFileName) => {
                                                            const newFileNames = [
                                                                ...fileNames,
                                                            ];
                                                            newFileNames[index] =
                                                                newFileName;
                                                            setFileNames(newFileNames);
                                                        }}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className={classes.bottom_btn_wrap}>
                            <Button
                                className="border sm"
                                type="submit"
                                onClick={editHandler}
                            >
                                수정
                            </Button>
                            <Link to="/admin/tournament/notice">
                                <Button className="secondary sm">목록</Button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default MngTournamentNoticeUpdate;

export const loader = async ({ params }) => {
    const response = await ReqCTNoticeForAdmin(params.ctNoticeId);
    if (response.status === 200) {
        return response.data;
    }
    return null;
};
