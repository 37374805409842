import Button from "../../../../components/UI/Button/Button";
import Modal from "../../../../components/UI/Modal/Modal";
import classes from "../../MngCommon.module.css";
import { DefaultNonePrintType } from "../../../../components/definedefault/DefSelectBoxValues";
import { useEffect, useState } from "react";
import SelectBoxNew from "../../../../components/UI/SelectBox/SelectBoxNew";
import instance from "../../../../store/AxiosInterceptor";
import PaginationNew from "../../../../components/UI/Table/PaginationNew";

function MngTournamentDetailPrintLaneModal(props) {

  const { isLaneModalOpenCB, contestCB, printTypeCB } = props;

  const [printType, setPrintType] = useState(printTypeCB === "name" ? printTypeCB : "lane");
  const [applyMemberList, setApplyMemberList] = useState([]);

  const [currentPage, setCurrentPage] = useState(0);
  const [totalPage, setTotalPage] = useState(0); //총 페이지 갯수
  const [totalCount, setTotalCount] = useState(0); //총 페이지 갯수

  const printTypeHandler = (key) => {
    setPrintType(key);
  };

  const closeLaneModalHandler = (e) => {
    e.stopPropagation();
    isLaneModalOpenCB(false);
  };

  const fetchData = async () => {
    try {
      const searchParam = {
        contestID: contestCB.contest_id,
        placeId: contestCB.place_id,
        regionName: contestCB.region,
        type: printType,
      };
      const response = await instance({
        method: "GET",
        url: `/api/v1/admin/contest/printing/lane?page=0`,
        params: searchParam,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });

      if (response.status === 200) {
        const resData = await response.data.datas;
        setApplyMemberList(resData);
      } else {
        setApplyMemberList([]);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [printType]);

  const onPrintHandler = async () => {
      const windowObject = window.open('', 'PrintWindow', 'width=1200px, height=800px, top=100, left=0, toolbars=no, scrollbars=no, status=no, resizable=no');

      const applyMemberListHTML = `
      <div style="width: 1140px; height: 770px">
        <h3 style="width: 100%; font-size: 30px; text-align: center">
          ${contestCB.center_name}/${contestCB.region}대회 레인배정표 (${printType === "name" ? "이름순" : "레인순"})
        </h3>

        <div style="width: 92%; display: flex; justify-content: center; align-items: flex-start; gap: 30px;">
        ${applyMemberList.length !== 0 && Object.keys(applyMemberList).map((groupKey) => (
          `<table style="border-top: 3px solid #000; flex: 1; border-collapse: collapse; border-spacing: 0;">

            <thead>
              <tr>
                <th colSpan="3" style="font-size: 30px; height:40px; text-align: center; font-weight: 300; border-bottom: 1px solid #dedede; line-height: 17px; background: #f7f9fa;">${groupKey}조</th>
              </tr>
              <tr>
                <th style="font-size: 20px; height:40px; text-align: center; font-weight: 300; border-bottom: 1px solid #dedede; line-height: 17px; background: #f7f9fa;">레인</th>
                <th style="font-size: 20px; height:40px; text-align: center; font-weight: 300; border-bottom: 1px solid #dedede; line-height: 17px; background: #f7f9fa;">이름</th>
                <th style="font-size: 20px; height:40px; text-align: center; font-weight: 300; border-bottom: 1px solid #dedede; line-height: 17px; background: #f7f9fa;">핸디</th>
              </tr>
            </thead>

            <tbody>
              ${applyMemberList[groupKey].map((item, index) => (
                `<tr key=${index}>
                  <td style="font-size: 30px; height:40px; text-align: center; border-bottom: 1px solid #dedede; line-height: 15px;">${item.lane === null ? "" : item.lane}-${item.seqOfLane === 0 ? "" : item.seqOfLane}</td>
                  <td style="font-size: 30px; height:40px; text-align: center; border-bottom: 1px solid #dedede; line-height: 15px;">${item.memberName}</td>
                  <td style="font-size: 30px; height:40px; text-align: center; border-bottom: 1px solid #dedede; line-height: 15px;">${item.handicap}</td>
                </tr>`
              )).join('')}
            </tbody>

          </table>`
        )).join('')}

        </div>
      </div>
    `;

    windowObject.document.writeln(applyMemberListHTML);
    windowObject.document.close();
    windowObject.focus();
    windowObject.print();
    windowObject.close();
  };

  return (
    <section>
        <Modal
          className="admin_st"
          close={closeLaneModalHandler}
          header="레인배정표 인쇄 미리보기"
        >
          <main>
            <div className={classes.print_wrap}>
              <div className={classes.info_box}>
                <SelectBoxNew
                  className="admin_st"
                  setSelectItemCB={printTypeHandler}
                  optionDatas={DefaultNonePrintType}
                  showDefaultKey={printType}
                />
                <ul className={classes.page_box}>
                  <PaginationNew
                    className="admin_st"
                    totalPage={totalPage}
                    currentPage={currentPage}
                    defaultShowPage={1}
                  />
                </ul>
              </div>
              <div className={classes.print_table_box}>
                <h3>
                  {contestCB.center_name}/{contestCB.region}대회 레인배정표 ({printType === "name" ? "이름순" : "레인순"})
                </h3>
                {applyMemberList.length !== 0 && Object.keys(applyMemberList).map((groupKey) => (
                    <table className="align_center">
                      <thead>
                        <tr>
                            <th colSpan={3}>{groupKey}조</th>
                        </tr>
                        <tr>
                          <th>레인</th>
                          <th>이름</th>
                          <th>핸디</th>
                        </tr>
                      </thead>
                      <tbody>
                        {applyMemberList[groupKey].map((item, index) => (
                          <tr key={index}>
                            <td>{item.lane}-{item.seqOfLane === 0 ? "" : item.seqOfLane}</td>
                            <td>{item.memberName}</td>
                            <td>{item.handicap}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                ))}
              </div>
            </div>
          </main>
          <footer>
            <Button className="secondary sm" onClick={onPrintHandler}>인쇄하기</Button>
          </footer>
        </Modal>
    </section>
  );
}
export default MngTournamentDetailPrintLaneModal;