import { useState, useEffect } from "react";
import classes from "./Pagination.module.css";

function Pagination ({
    className,
    totalPage =  1,
    currentPage = 1,
    changeCurrentPage,
}) {
    // 부모 컴포넌트에서 줘야하는값
    // totalPage : 총 페이지 갯수, currentPage : 현재 페이지(스프링기준0이 1페이지) changeCurrentPage : 페이지 변경함수
    const [pageList, setPageList] = useState([]);

    const startPage = Math.floor(currentPage / 10) * 10 + 1;
    let endPage = startPage + 9; //

    if (endPage > totalPage) {
        endPage = totalPage;
    }

    useEffect(() => {
        if (totalPage) {
            let copy = [];
            for (let i = startPage; i <= endPage; i++) {
                copy.push(i);
            }
            setPageList(copy);
        }
        selectMenuHandler(startPage - 1);
    }, [startPage, endPage]);

    const selectMenuHandler = (index) => {
        if (changeCurrentPage)
            changeCurrentPage(index);
    };

    const back = () => {
        if (currentPage < 10) {
            return;
        }
        if (changeCurrentPage)
            changeCurrentPage(currentPage - 10);
    };

    const go = () => {
        if (endPage >= totalPage) {
            return;
        }
        if (changeCurrentPage)
            changeCurrentPage(currentPage + 10);
    };

    const moveTheFirstPage = () => {
        if (currentPage <= 0) {
            return;
        }
        if (changeCurrentPage)
            changeCurrentPage(0);
    };

    const moveTheLastPage = () => {
        if (currentPage + 1 >= totalPage) {
            return;
        }
        if (changeCurrentPage)
            changeCurrentPage(totalPage - 1);
    };

    return (
        <nav className={`${classes.pagination} ${classes[className]}`}>
            <span
                className={`${classes.first} ${
                    currentPage === 0 ? classes.disable : ""
                }`}
                onClick={moveTheFirstPage}
            ></span>
            <span
                className={`${classes.prev} ${
                    currentPage < 10 ? classes.disable : ""
                }`}
                onClick={back}
            ></span>
            <ul>
                {pageList.map((item, index) => (
                    <li
                        key={index}
                        className={currentPage === item - 1 && classes.on}
                        onClick={() => selectMenuHandler(item - 1)}
                    >
                        {item}
                    </li>
                ))}
            </ul>
            <span
                className={`${classes.next}  ${
                    endPage >= totalPage ? classes.disable : ""
                }`}
                onClick={go}
            ></span>
            <span
                className={`${classes.last} ${
                    currentPage + 1 >= totalPage ? classes.disable : ""
                }`}
                onClick={moveTheLastPage}
            ></span>
        </nav>
    );
}

export default Pagination;
