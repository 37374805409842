import Input from "../../../components/UI/Input/Input";
import classes from "../TournamentContainerApp.module.css";
import Button from "../../../components/UI/Button/Button";
import { useState } from "react";
import { NavLink, useLoaderData } from "react-router-dom";
import instance from "../../../store/AxiosInterceptor";
function TournamentNoticeViewApp() {
    const ctNotice = useLoaderData();

    const [text, setText] = useState(ctNotice.data);
    const [topData, setTopData] = useState(ctNotice.topData); // 상단 공지 데이터

    const [currentPage, setCurrentPage] = useState(0);
    const [totalPage, setTotalPage] = useState(ctNotice.totalPage); //총 페이지 갯수
    const [totalCount, setTotalCount] = useState(ctNotice.totalCount); //총 페이지 갯수

    const handlePageChange = async () => {
        const page = currentPage + 1;
        const searchParam = {
            page: page,
        };
        setCurrentPage(page);
        const response = await instance.get(
            "/api/v1/home/contest/contestNotice",
            {
                params: searchParam,
            }
        );
        if (response.status === 200) {
            const resData = await response.data.datas;
            setTotalPage(resData.totalPages);
            setTotalCount(resData.totalElements);
            setText((prev) => [...prev, ...resData.content]);
        } else {
            setText((prev) => prev);
        }
    };

    function resCtTypeClass(ctType) {
        switch (ctType) {
            case "K":
                return classes.type_a;
            case "L":
                return classes.type_b;
            case "A":
                return classes.type_c;
            case "B":
                return classes.type_d;
            case "S":
                return classes.type_d;
            default:
                return "";
        }
    }

    const [searchText, setSearchText] = useState("");

    const onSearchTextHandler = (value) => {
        setSearchText(value);
    };

    const findSearchHandler = async () => {
        setCurrentPage(0);

        const searchParam = {
            page: currentPage,
            searchText: searchText,
        };

        const response = await instance.get(
            "/api/v1/home/contest/contestNotice",
            {
                params: searchParam,
            }
        );
        if (response.status === 200) {
            const resData = await response.data.datas;
            setTotalPage(resData.totalPages);
            setTotalCount(resData.totalElements);
            setText(resData.content);
        } else {
            setText([]);
        }
    };
    const increaseViews = (id) => {
        instance.put(`/api/v1/home/contest/${id}/views`).catch((error) => {
            console.error("Error increasing views:", error);
        });
    };
    return (
        <>
            {/* 모바일에서는 TournamentAppRoot 사용으로, 구조 변경합니다. section 아래에서 부터 페이지 컴포넌트 시작 */}
            <div className={classes.search_wrap}>
                <Input onChange={onSearchTextHandler} />
                <Button className="search_sm" onClick={findSearchHandler} />
            </div>
            <div
                className={`${classes.mobile_board_table} ${classes.notice_type}`}
            >
                {/* mobile-table class del */}
                {text.length !== 0 ? (
                    <>
                        <table>
                            <colgroup>
                                <col style={{ width: "8%" }} />
                                <col style={{ width: "18%" }} />
                                <col style={{ width: "55%" }} />
                                <col style={{ width: "19%" }} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>번호</th>
                                    <th>유형</th>
                                    <th>제목</th>
                                    <th>등록일자</th>
                                </tr>
                            </thead>
                            <tbody>
                                {/* 상단 공지 데이터 표시 */}
                                {topData.map((data, index) => {
                                    return (
                                        <NavLink to={`${data.id}`}>
                                            <tr
                                                onClick={() =>
                                                    increaseViews(data.id)
                                                }
                                            >
                                                <td>공지</td>

                                                <td>
                                                    <b
                                                        className={resCtTypeClass(
                                                            data.ct_type
                                                        )}
                                                    >
                                                        {data.type_title}
                                                    </b>
                                                </td>
                                                <td>{data.title}</td>
                                                <td>{data.regDate}</td>
                                            </tr>
                                        </NavLink>
                                    );
                                })}

                                {text.map((data, index) => {
                                    return (
                                        <NavLink to={`${data.id}`}>
                                            <tr
                                                onClick={() =>
                                                    increaseViews(data.id)
                                                }
                                            >
                                                {/* <td>{totalCount - (10 * currentPage + index)}</td> */}
                                                <td>{totalCount - index}</td>
                                                <td>
                                                    <b
                                                        className={resCtTypeClass(
                                                            data.ct_type
                                                        )}
                                                    >
                                                        {data.type_title}
                                                    </b>
                                                </td>
                                                <td>{data.title}</td>
                                                <td>{data.regDate}</td>
                                            </tr>
                                        </NavLink>
                                    );
                                })}
                            </tbody>
                        </table>
                        {/* 모바일버전 - 페이징 삭제 / 더보기 추가 */}
                        {totalCount !== text.length && (
                            <div className={classes.more_btn}>
                                <Button
                                    className="text_icon"
                                    onClick={handlePageChange}
                                >
                                    더보기
                                </Button>
                            </div>
                        )}
                    </>
                ) : (
                    <>
                        {/* 검색결과 없는 경우 */}
                        <div className={classes.no_data}>
                            <p>검색결과가 없습니다.</p>
                        </div>
                    </>
                )}
            </div>
        </>
    );
}
export default TournamentNoticeViewApp;

export const loader = async () => {
    return instance
        .get("/api/v1/home/contest/contestNotice?page=0")
        .then((response) => {
            const resData = response.data.datas;
            const curData = resData.content.map((data) => {
                return {
                    id: data.id,
                    title: data.title,
                    exposeYn: data.exposeYn,
                    readCnt: data.readCnt,
                    regID: data.regID,
                    regDate: data.regDate,
                    ct_type: data.ct_type,
                    type_title: data.type_title,
                };
            });
            return {
                data: curData,
                topData: response.data.topNoticeList,
                totalPage: resData.totalPages,
                totalCount: resData.totalElements,
            };
        })
        .catch((error) => {
            return [];
        });
};
