import instance from "../../store/AxiosInterceptor";

/**
 * BO - 대회 사용구 조회
 * 전체보기 API
 */
export function ReqFindAllUserBallList(resolveCB, rejectCB) {
    instance({
        url: `/api/v1/admin/contest/userBall?page=0&size=10&contestId=&contestType=&regionName=&centerId=&isRegister=`,
        method: "GET",
    })
        .then((response) => {
            resolveCB(response.data.datas);
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/**
 * BO - 대회 사용구 조회
 * 검색 API
 */
export function ReqSearchUserBallList(props, resolveCB, rejectCB) {
    instance({
        url: `/api/v1/admin/contest/userBall?contestId=${props.contestId}&contestType=${props.contestType}&regionName=${props.regionName}&centerId=${props.centerId}&isRegister=${props.isRegister}&${props.queryType}=${props.queryText}&page=0&size=10`,
        method: "GET",
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data.datas);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/**
 * BO - 대회 사용구 조회
 * 페이지 핸들러
 */
export function ReqPagingUserBallList(props, page, resolveCB, rejectCB) {
    instance({
        url: `/api/v1/admin/contest/userBall?contestId=${props.contestId}&contestType=${props.contestType}&regionName=${props.regionName}&centerId=${props.centerId}&isRegister=${props.isRegister}&${props.queryType}=${props.queryText}&page=${page}&size=10`,
        method: "GET",
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data.datas);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}
