import { useContext, useEffect, useMemo, useRef, useState } from "react";
import Button from "../../../../components/UI/Button/Button";
import Checkbox from "../../../../components/UI/Input/Checkbox";
import Input from "../../../../components/UI/Input/Input";
import Modal from "../../../../components/UI/Modal/Modal";
import SelectBoxNew from "../../../../components/UI/SelectBox/SelectBoxNew";
import classes from "../../MngCommon.module.css";
import { JwtTokenContext } from "../../../../store/JwtTokenProvider";
import { useNavigate } from "react-router";
import { ProductCategory } from "../../../../api/product/CategoryApi";
import { ProductSelectedCategoryListAll } from "../../../../api/product/CategorySelectApi";

const initProductSelectedListData = {
    content: [],
    pageable: {
        sort: {
            empty: true,
            unsorted: true,
            sorted: false,
        },
        pageNumber: 0,
        offset: 0,
        pageSize: 20,
        unpaged: false,
        paged: true,
    },
    last: true,
    totalElements: 1,
    totalPages: 1,
    size: 10,
    number: 0,
    sort: {
        empty: true,
        unsorted: true,
        sorted: false,
    },
    numberOfElements: 1,
    first: true,
    empty: false,
};

function MngRecommendProductModal(props) {
    
    const { isModalOpenCB } = props;

    const closeModalHandler = (e) => {
        e.stopPropagation();
        isModalOpenCB(false, []);
    };

    const closeModalHandler1 = (e) => {
        e.stopPropagation();
        // if(selectedUpdateParam.length < 4 || selectedUpdateParam.length > 8) {
        //     alert("상품을 최소 4개~ 최대 8개 선택해주세요.")
        //     return;
        // }
        isModalOpenCB(false, selectedUpdateParam);
    };

    const navigate = useNavigate();
    const [nowCurrentPage, setNowCurrentPage] = useState(0);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [categoryMap, setCategoryMap] = useState({});
    const [loadCategory, setLoadCategory] = useState(true);
    const searchFormRef = useRef();
    const [searchKeyword, setSearchKeyword] = useState("");
    const [expose, setExpose] = useState(-1);

    const [selectedInfoAll, setSelectedInfoAll] = useState(
        initProductSelectedListData
    );
    const [selectedInfo, setSelectedInfo] = useState(
        initProductSelectedListData
    );
    const [adderCategoryPath, setAdderCategory] = useState([-1, -1, -1]);

    const toMap = (data = []) => {
        let rs = {};
        data.forEach((item) => {
            if (item?.id) {
                rs[item.id] = item;
                if (_.isArray(item?.children) && item.children.length > 0) {
                    rs = { ...rs, ...toMap(item.children) };
                }
            }
        });
        return rs;
    };
    const selectedValues = useMemo(() => {
        const MAX_DEPTH = 3;
        let rs = [];
        if (!_.isObject(categoryMap) || Object.keys(categoryMap).length === 0) {
            return [];
        }
        if (selectedCategory === null) {
            return [
                {
                    list: Object.values(categoryMap).filter(
                        ({ depth }) => depth === 1
                    ),
                    selected: null,
                },
            ];
        }

        let pId = selectedCategory["parent_id"];
        for (let i = 0; i < selectedCategory.depth; i++) {
            const list =
                pId === null || pId < 0
                    ? Object.values(categoryMap).filter(
                          ({ depth }) => depth === 1
                      )
                    : categoryMap[pId].children;
            console.log({ pId });
            rs.unshift({
                list,
                selected: i === 0 ? selectedCategory.id : pId,
            });
            if (pId === null || pId < 0) break;
            pId = categoryMap[pId]["parent_id"];
        }
        if (
            selectedCategory.depth < MAX_DEPTH &&
            selectedCategory.children &&
            selectedCategory.children.length > 0
        ) {
            rs.push({ list: selectedCategory.children, selected: null });
        }
        return rs;
    }, [selectedCategory, categoryMap]);

    const selectedIdxs = useMemo(() => {
        if (selectedCategory === null) return [null];
        let nowIdx = selectedCategory.id;
        const rs = [selectedCategory.id];
        while (
            !(
                !categoryMap[nowIdx] ||
                categoryMap[nowIdx]["parent_id"] === null ||
                categoryMap[nowIdx]["parent_id"] < 0
            )
        ) {
            nowIdx = categoryMap[nowIdx]["parent_id"];
            rs.unshift(nowIdx);
        }
        return rs;
    }, [selectedCategory]);

    useEffect(() => {
        if (!loadCategory) return;
        ProductCategory().then((res) => {
            if (_.isArray(res.data?.datas) && res.status === 200) {
                const map = toMap(res.data.datas);
                setCategoryMap(map);
                setLoadCategory(false);
            }
        });
    }, [loadCategory]);
    useEffect(() => {
        try {
            ProductSelectedCategoryListAll({
                page: nowCurrentPage,
                size: 10000,
            }).then((res) => {
                if (res.status === 200 && _.isObject(res.data.datas)) {
                    setSelectedInfo(res.data.datas);
                    setSelectedInfoAll(res.data.datas)
                    setAdderCategory([-1, -1, -1]);
                }
            });
        } catch (error) {
            alert(error);
        }
    }, []);

    useEffect(() => {
        console.log("searchFormRef:::", searchFormRef);
    }, [searchFormRef]);

    const [selectedIds, setSelectedIds] = useState([]);
    const [selectedUpdateParam, setSelectedUpdateParam] = useState([]);
    const [selectAll, setSelectAll] = useState(false);

    const handleCheckboxIdChange = (e, id, name) => {
        const { checked } = e.target;

        if (id === "chk_all") {
            // Select All checkbox clicked
            setSelectAll(checked);
            if (checked) {
                const allIds = selectedInfo.content.map((item) => item.id);
                setSelectedIds(allIds);
                setSelectedUpdateParam(
                    allIds.map((id) => {
                        const {
                            name,
                        } = selectedInfoAll.content.find((item) => item.id === id);
                        return {
                            id,
                            name: name || "",
                        };
                    })
                );
            } else {
                setSelectedIds([]);
                setSelectedUpdateParam([]);
            }
        } else {
            // Individual checkbox clicked
            setSelectedIds((prevSelectedIds) => {
                const updatedSelectedIds = checked
                    ? [...prevSelectedIds, id]
                    : prevSelectedIds.filter((selectedId) => selectedId !== id);
                console.log(updatedSelectedIds);
                const updatedSelectedParams = updatedSelectedIds.map((id) => {
                    const {
                        name
                    } = selectedInfoAll.content.find((item) => item.id === id);
                    return {
                        id,
                        name: name || "",
                    };
                });

                setSelectAll(
                    updatedSelectedIds.length === selectedInfo.content.length
                );
                setSelectedUpdateParam(updatedSelectedParams);

                return updatedSelectedIds;
            });
        }
    };

    return (
        <section>
            <Modal
                className="admin_st"
                close={closeModalHandler}
                header="상품선택"
            >
                <main>
                    <div className={classes.admin_table_wrap}>
                        <table>
                            <colgroup>
                                <col style={{ width: "139px" }} />
                                <col style={{ width: "*" }} />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>카테고리</th>
                                    <td>
                                    <div
                                            className={`${classes.flex_row} ${classes.multi}`}
                                        >
                                            {selectedValues.map(
                                                ({ list }, idx) => {
                                                    return (
                                                        // eslint-disable-next-line react/jsx-key
                                                        <SelectBoxNew
                                                            className="admin_st"
                                                            optionDatas={[
                                                                {
                                                                    id: -1,
                                                                    name: "전체",
                                                                    depth: 1,
                                                                },
                                                                ...(list ??
                                                                    []),
                                                            ].map(
                                                                (item) => {
                                                                    return {
                                                                        ...item,
                                                                        key: item?.id,
                                                                        value: item?.name,
                                                                        disabled: false,
                                                                    };
                                                                }
                                                            )}
                                                            setSelectItemCB={(
                                                                key
                                                            ) => {
                                                                if (
                                                                    Number(
                                                                        key
                                                                    ) === -1
                                                                ) {
                                                                    if (
                                                                        idx ===
                                                                        0
                                                                    ) {
                                                                        setSelectedCategory(
                                                                            null
                                                                        );
                                                                    } else if (
                                                                        selectedCategory
                                                                    ) {
                                                                        let sId =
                                                                            selectedCategory.id;
                                                                        let cdepth = 3;
                                                                        while (
                                                                            idx !==
                                                                            cdepth
                                                                        ) {
                                                                            if (
                                                                                // _.isUndefined(
                                                                                //     categoryMap[
                                                                                //         sId
                                                                                //     ]
                                                                                //         .parent_id
                                                                                // ) ||
                                                                                categoryMap[
                                                                                    sId
                                                                                ]
                                                                                    ?.parent_id ===
                                                                                    -1
                                                                            ) {
                                                                                break;
                                                                            }
                                                                            sId =
                                                                                categoryMap[
                                                                                    sId
                                                                                ]
                                                                                    ?.parent_id;
                                                                            cdepth--;
                                                                        }
                                                                        setSelectedCategory(
                                                                            categoryMap[
                                                                                sId
                                                                            ]
                                                                        );
                                                                    }

                                                                    return;
                                                                }
                                                                setSelectedCategory(
                                                                    categoryMap[
                                                                        key
                                                                    ]
                                                                );
                                                            }}
                                                            showDefaultKey={selectedIdxs.at(
                                                                idx
                                                            )}
                                                        />
                                                    );
                                                }
                                            )}
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>상품명</th>
                                    <td>
                                        <Input
                                            className="admin_st"
                                            placeholder="상품명 입력"
                                            ref={(sref) => {
                                                searchFormRef.current =
                                                    sref;
                                            }}
                                            value={searchKeyword}
                                            onChange={setSearchKeyword}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className={classes.bottom_btn_wrap}>
                        <Button
                                    className="sm secondary"
                                    onClick={() => {
                                        let url = "";
                                        url += `?searchKeyword=${searchKeyword}`;
                                        console.log({ selectedValues });
                                        const selectedIds = selectedValues
                                            .map(({ selected }) => selected)
                                            .filter(
                                                (s) => s !== null && s > -1
                                            );
                                        if (selectedIds.length > 0) {
                                            url += `&selectedId=${selectedIds.at(
                                                -1
                                            )}&depth=${
                                                categoryMap[selectedIds.at(-1)]
                                                    .depth
                                            }`;
                                        }
                                        if (expose !== -1)
                                            url +=
                                                expose === 1
                                                    ? "&exposeYn=Y"
                                                    : "&exposeYn=N";
                                        const urlSearchParams =
                                            new URLSearchParams(url);
                                        const searchParams = {};
                                        [
                                            "searchKeyword",
                                            "selectedId",
                                            "depth",
                                            "exposeYn",
                                        ].forEach((key) => {
                                            if (urlSearchParams.get(key)) {
                                                searchParams[key] =
                                                    urlSearchParams.get(key);
                                            }
                                        });
                                        navigate(url);
                                        console.log({ searchParams });
                                        ProductSelectedCategoryListAll(
                                            {
                                                page: nowCurrentPage,
                                                size: 10000,
                                            },
                                            searchParams
                                        ).then((res) => {
                                            if (
                                                res.status === 200 &&
                                                _.isObject(res.data.datas)
                                            ) {
                                                setSelectedInfo(res.data.datas);
                                                // setDetailData(
                                                //     initProductSelectedDetailData
                                                // );
                                                // setInsertData(
                                                //     (prev) =>
                                                //         initInsertSelectedData
                                                // );
                                                // console.log(
                                                //     "setInsertData(initInsertSelectedData) call after ProductSelectedCategoryListAll 1"
                                                // );
                                                setAdderCategory([-1, -1, -1]);
                                                // if (viewMode !== 0)
                                                //     setViewMode(0);
                                            }
                                        });
                                    }}
                                >
                                    검색
                                </Button>
                                <Button
                                    className="sm"
                                    onClick={() => {
                                        ProductSelectedCategoryListAll({
                                            page: 0,
                                            size: 10000,
                                        }).then((res) => {
                                            if (
                                                res.status === 200 &&
                                                _.isObject(res.data.datas)
                                            ) {
                                                setSelectedInfo(res.data.datas);
                                                // setDetailData(
                                                //     initProductSelectedDetailData
                                                // );
                                                // setInsertData(
                                                //     (prev) =>
                                                //         initInsertSelectedData
                                                // );
                                                // console.log(
                                                //     "setInsertData(initInsertSelectedData) call after ProductSelectedCategoryListAll 2"
                                                // );
                                                setAdderCategory([-1, -1, -1]);
                                                // if (viewMode !== 0)
                                                //     setViewMode(0);
                                            }
                                        });
                                    }}
                                >
                                    전체보기
                                </Button>

{/*                             <Button className="sm secondary">검색</Button>
                            <Button className="sm">전체보기</Button> */}
                        </div>
                        <div
                            className={`${classes.admin_table_wrap} ${classes.fixed_st}`}
                        >
                            <p className={classes.counter}>
                                총 <b>{selectedInfo.totalElements}</b>건
                            </p>
                            <div className={classes.scroll_box}>
                                <table className="align_center">
                                    <colgroup>
                                        <col style={{ width: "5%" }} />
                                        <col style={{ width: "45%" }} />
                                        <col style={{ width: "50%" }} />
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th>
                                                <Checkbox
                                                    className="admin_st"
                                                    id="chk_all"
                                                    checked={selectAll}
                                                    onChange={(e) =>
                                                        handleCheckboxIdChange(
                                                            e,
                                                            "chk_all"
                                                        )
                                                    }
                                                />
                                            </th>
                                            <th>카테고리</th>
                                            <th>상품명</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {(selectedInfo.content ?? []).map(
                                            (item, index) => (
                                                // eslint-disable-next-line react/jsx-key
                                                <tr>
                                                    <td>
                                                        <Checkbox
                                                            key={item.id}
                                                            className="admin_st"
                                                            id={`chk_1_${item.id}`}
                                                            value={item.id}
                                                            checked={selectedIds.includes(
                                                                item.id
                                                            )}
                                                            onChange={(e) =>
                                                                handleCheckboxIdChange(
                                                                    e,
                                                                    item.id,
                                                                    item.name,
                                                                )
                                                            }
                                                        />
                                                    </td>
                                                    <td>
                                                        {item?.hierarchyCategoryStr}
                                                    </td>
                                                    <td>
                                                        {item?.name} &nbsp;{" "}
                                                        {item?.subName}
                                                    </td>
                                                </tr>
                                            )
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </main>
                <footer>
                    <Button
                        className="secondary sm"
                        onClick={closeModalHandler1}
                    >
                        선택완료
                    </Button>
                </footer>
            </Modal>
        </section>
    );
}

export default MngRecommendProductModal;
