import { useNavigate } from "react-router-dom";
import Button from "../../../components/UI/Button/Button";
import DateRangePicker from "../../../components/UI/DatePicker/DateRangePicker";
import Checkbox, { CheckboxB } from "../../../components/UI/Input/Checkbox";
import Input from "../../../components/UI/Input/Input";
import Modal from "../../../components/UI/Modal/Modal";
import SelectBoxNew from "../../../components/UI/SelectBox/SelectBoxNew";
import PaginationNew from "../../../components/UI/Table/PaginationNew";
import {
    DefaultAll,
    DefaultBallBrandList,
    DefaultSerialQueryType,
} from "../../../components/definedefault/DefSelectBoxValues";
import classes from "../MngCommon.module.css";
import moment from "moment";
import { useState, useEffect } from "react";
import { CSVLink } from "react-csv";
import {
    ReqDeleteBallSerial,
    ReqFileNameList,
    ReqOfficialBallList,
    ReqPagingAPI,
    ReqSearchHandler,
    ReqUploadFile,
} from "../../../api/tournament/OfficialBallApi";
import instance from "../../../store/AxiosInterceptor";
import Alert from "../../../components/UI/Modal/Alert";

function MngTournamentRegisterSerial(props) {
    const navigate = useNavigate();
    const ballBrandList = DefaultBallBrandList;
    const queryTypeList = DefaultSerialQueryType;
    const [officialBallList, setOfficialBallList] = useState([]);
    const [queryValue, setQueryValue] = useState("");
    const [selectedBrand, setSelectedBrand] = useState("");
    const [queryType, setQueryType] = useState("");
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [fileName, setFileName] = useState("");
    const [fileNameList, setFileNameList] = useState([DefaultAll]);

    // >>> 페이징을 위한 값 세팅
    const [totalCount, setTotalCount] = useState(0);
    const [totalPage, setTotalPage] = useState();
    const [pageNumber, setPageNumber] = useState(0);
    // <<< 페이징을 위한 값 세팅

    const searchStartDate = startDate ? moment(startDate).format("YYYYMMDD") : "";
    const searchEndDate = endDate ? moment(endDate).format("YYYYMMDD") : "";

    const [duplicateModal, setDuplicateModal] = useState(false);
    const [successModal, setSuccessModal] = useState(false);

    const duplicateModalHandler = (state) => {
        setDuplicateModal(state);
    };

    const successModalHandler = (state) => {
        setSuccessModal(state);
    };

    const onBrandHandler = (value) => {
        setSelectedBrand(value);
    };

    const onQueryTypeHandler = (value) => {
        setQueryType(value);
    };

    const onQueryValueHandler = (value) => {
        setQueryValue(value);
    };

    const startDateHandler = (value) => {
        setStartDate(value);
    };

    const endDateHandler = (value) => {
        setEndDate(value);
    };

    const onFileNameHandler = (value) => {
        setFileName(value);
    };

    const [manageBallCheckBoxs, setManageBallCheckBoxs] = useState({});

    const onBallCheckBoxChangeHandler = (ballID) => {
        let temp = { ...manageBallCheckBoxs };
        if (temp[ballID]) {
            temp[ballID] = false;
        } else {
            temp[ballID] = true;
        }
        setManageBallCheckBoxs((prev) => temp);
    };

    const [selectAll, setSelectAll] = useState(false);
    const [currentIds, setCurrentIds] = useState([]);

    const ballIdsAllSelectHandler = () => {
        setSelectAll((prev) => !prev);
        let temp = { ...manageBallCheckBoxs };

        currentIds.forEach((data) => {
            if (temp[data.ballId]) {
                temp[data.ballId] = false;
            } else {
                temp[data.ballId] = true;
            }
        });
        setManageBallCheckBoxs((prev) => temp);
    };

    const [deleteConfirm, setDeleteConfirm] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);

    const deleteModalHandler = () => {
        setDeleteModal(false);
        navigate("/admin/tournament/registerSerial");
    };

    const deleteHandler = () => {
        let combined = [];

        const listLength = Object.keys(manageBallCheckBoxs).length;
        if (listLength <= 0) {
            return;
        }

        for (const key in manageBallCheckBoxs) {
            if (manageBallCheckBoxs[key] === true) {
                combined.push({
                    ball_id: key,
                    is_delete: manageBallCheckBoxs[key],
                });
            }
        }

        if (combined?.length <= 0) {
            return;
        }

        ReqDeleteBallSerial(
            combined,
            (result) => {
                if (result.status === 200) {
                    setDeleteModal(true);
                    return;
                }
            },
            (error_result) => {
                alert("삭제 에러");
            }
        );
    };

    const findFileNameListHandler = () => {
        if (fileNameList.length === 1) {
            ReqFileNameList(
                (result) => {
                    const success = result.map((data) => {
                        return { key: data.ball_file_name, value: data.ball_file_name };
                    });
                    setFileNameList((prev) => [DefaultAll, ...success]);
                },
                (excption_result) => {
                    setFileNameList((prev) => [DefaultAll]);
                }
            );
        }
    };

    const searchObejct = {
        start_reg_date: searchStartDate,
        end_reg_date: searchEndDate,
        brand: selectedBrand,
        query_type: queryType,
        query_value: queryValue,
        file_name: fileName,
    };

    const searchHandler = () => {
        setPageNumber(0);
        ReqSearchHandler(
            searchObejct,
            (result) => {
                if (result.content) {
                    const curData = result.content.map((data) => {
                        return {
                            ballId: data.ball_id,
                            regDate: data.reg_date,
                            fileName: data.file_name,
                            brandName: data.brand_name,
                            ballName: data.ball_name,
                            serialNo: data.serial_no,
                            seq: data.seq,
                        };
                    });

                    const ids = result.content.map((data) => {
                        return {
                            ballId: data.ball_id,
                        };
                    });

                    setCurrentIds(ids);
                    setOfficialBallList(curData);
                    setTotalCount(result.totalElements);
                    setTotalPage(result.totalPages);
                    setPageNumber(result.pageable.pageNumber);
                } else {
                    setOfficialBallList((prev) => prev);
                    setTotalCount((prev) => prev);
                    setTotalPage((prev) => prev);
                    setPageNumber((prev) => prev);
                    setCurrentIds((prev) => prev);
                }
            },
            (error_result) => {
                setOfficialBallList((prev) => prev);
                setTotalCount((prev) => prev);
                setTotalPage((prev) => prev);
                setPageNumber((prev) => prev);
                setCurrentIds((prev) => prev);
            }
        );
    };

    const findAllHandler = () => {
        setQueryType("");
        setQueryValue("");
        setSelectedBrand("");
        setStartDate("");
        setEndDate("");
        setFileName("");
        setPageNumber(0);
        ReqOfficialBallList(
            (result) => {
                if (result.content) {
                    const curData = result.content.map((data) => {
                        return {
                            ballId: data.ball_id,
                            regDate: data.reg_date,
                            fileName: data.file_name,
                            brandName: data.brand_name,
                            ballName: data.ball_name,
                            serialNo: data.serial_no,
                            seq: data.seq,
                        };
                    });

                    const ids = result.content.map((data) => {
                        return {
                            ballId: data.ball_id,
                        };
                    });

                    setCurrentIds(ids);
                    setOfficialBallList(curData);
                    setTotalCount(result.totalElements);
                    setTotalPage(result.totalPages);
                    setPageNumber(result.pageable.pageNumber);
                } else {
                    setOfficialBallList((prev) => prev);
                    setTotalCount((prev) => prev);
                    setTotalPage((prev) => prev);
                    setPageNumber((prev) => prev);
                    setCurrentIds((prev) => prev);
                }
            },
            (error_result) => {
                setCurrentIds((prev) => prev);
                setOfficialBallList((prev) => prev);
                setTotalCount((prev) => prev);
                setTotalPage((prev) => prev);
                setPageNumber((prev) => prev);
            }
        );
    };

    const pagingHandler = (value) => {
        setPageNumber(value);
        ReqPagingAPI(
            searchObejct,
            value,
            (result) => {
                if (result.content) {
                    const curData = result.content.map((data) => {
                        return {
                            ballId: data.ball_id,
                            regDate: data.reg_date,
                            fileName: data.file_name,
                            brandName: data.brand_name,
                            ballName: data.ball_name,
                            serialNo: data.serial_no,
                            seq: data.seq,
                        };
                    });

                    const ids = result.content.map((data) => {
                        return {
                            ballId: data.ball_id,
                        };
                    });

                    setCurrentIds(ids);

                    setOfficialBallList(curData);
                    setTotalCount(result.totalElements);
                    setTotalPage(result.totalPages);
                    setPageNumber(result.pageable.pageNumber);
                } else {
                    setOfficialBallList((prev) => prev);
                    setTotalCount((prev) => prev);
                    setTotalPage((prev) => prev);
                    setPageNumber((prev) => prev);
                    setCurrentIds((prev) => prev);
                }
            },
            (error_result) => {
                setCurrentIds((prev) => prev);
                setOfficialBallList((prev) => prev);
                setTotalCount((prev) => prev);
                setTotalPage((prev) => prev);
                setPageNumber((prev) => prev);
            }
        );
    };
    useEffect(() => {
        pagingHandler(pageNumber)
    }, []);
    ///>>> 파일업로드
    const [selectedFile, setSelectedFile] = useState();
    const [savedSize, setSavedSize] = useState(0);
    const [duplicateSize, setDuplicateSize] = useState(0);
    const [duplicateList, setDuplicateList] = useState([]);
    const navigateHandler = (value) => {
        navigate(value);
        duplicateModalHandler(false);
        successModalHandler(false);
    };
    const uploadCsvHandler = (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            setSelectedFile(undefined);
            return;
        }

        // 파일업로드를 하면 바로 서버로 보내기 위함
        const formData = new FormData();
        setSelectedFile(e.target.files[0]);

        if (e.target.files[0]) {
            formData.append("file", e.target.files[0]);
        }

        ReqUploadFile(
            formData,
            (result) => {
                if (result.status === 200) {
                    if (result.duplicate_size > 0) {
                        setDuplicateSize(result.duplicate_size);
                        setDuplicateList(result.duplicate_list);
                        setSavedSize(result.save_size);
                        duplicateModalHandler(true);
                        return;
                    } else {
                        setSavedSize(result.save_size);
                        successModalHandler(true);
                        return;
                    }
                }
            },
            (error_result) => {
                alert("업로드 에러");
            }
        );
    };
    ///<<< 파일 업로드

    const data = [];
    const headers = ["NO", "브랜드", "모델명", "시리얼넘버"];


    const downLoadheaders = [
        { label: "No", key: "index" },
        { label: "등록일자", key: "regDate" },
        { label: "파일명", key: "fileName" },
        { label: "브랜드", key: "brandName" },
        { label: "모델명", key: "ballName" },
        { label: "시리얼넘버", key: "serialNo" },
    ];

    // 엑셀 다운로드
    const dataHandler = async (e, object) => {
        try {
            const response = await instance({
                url: `/api/v1/admin/contest/officialBall/excel?start_reg_date=${object.start_reg_date}&end_reg_date=${object.end_reg_date}&brand=${object.brand}&${object.query_type}=${object.query_value}&file_name=${object.file_name}`,
                method: "GET",
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            });

            if (response.status === 200) {
                const resData = await response.data.datas;
                const curData = resData.map((data, index) => {
                    return {
                        index: index + 1,
                        regDate: data.reg_date,
                        fileName: data.file_name,
                        brandName: data.brand_name,
                        ballName: data.ball_name,
                        serialNo: data.serial_no,
                    };
                });

                if (curData.length > 0) {
                    // CSV 데이터 생성
                    let csvContent = "\uFEFF"; // BOM 문자로 UTF-8 인코딩 설정 (한글 깨짐 방지)

                    // 그룹 헤더 추가
                    curData.forEach((data, index) => {
                        if (index === 0) {
                            // 헤더 추가
                            csvContent += downLoadheaders.map((header) => header.label).join(",") + "\n";
                        }

                        if (index !== 0) {
                            csvContent += `\n`;
                        }

                        // 데이터 추가
                        csvContent += `"${data.index}","${data.regDate}","${data.fileName}","${data.brandName}","${data.ballName}","${data.serialNo}"`;
                    });

                    // Blob 생성
                    const blob = new Blob([csvContent], {
                        type: "text/csv;charset=utf-8;",
                    });

                    // Blob을 URL로 변환하여 다운로드 링크 생성
                    const url = URL.createObjectURL(blob);
                    const a = document.createElement("a");
                    a.style.display = "none";
                    a.href = url;
                    a.download = `대회 사용구 시리얼 넘버.csv`;
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                }
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    return (
        <section>
            <div className={classes.admin_contents}>
                <h3>대회 사용구 시리얼넘버 등록</h3>
                <div className={`${classes.contents_wrap} ${classes.search_wrap}`}>
                    <div className={classes.admin_table_wrap}>
                        <table>
                            <colgroup>
                                <col style={{ width: "11%" }} />
                                <col style={{ width: "39%" }} />
                                <col style={{ width: "11%" }} />
                                <col style={{ width: "39%" }} />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>등록일</th>
                                    <td>
                                        <DateRangePicker
                                            startDate={startDate}
                                            endDate={endDate}
                                            onStartDateChange={startDateHandler}
                                            onEndDateChange={endDateHandler}
                                        />
                                    </td>
                                    <th>파일명</th>
                                    <td>
                                        <SelectBoxNew
                                            className="admin_st w100"
                                            optionDatas={fileNameList}
                                            setSelectItemCB={onFileNameHandler}
                                            showDefaultKey={fileName}
                                            mouseOverCB={findFileNameListHandler}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>브랜드</th>
                                    <td colSpan={3}>
                                        <SelectBoxNew
                                            className="admin_st"
                                            setSelectItemCB={onBrandHandler}
                                            optionDatas={ballBrandList}
                                            showDefaultKey={selectedBrand}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>검색구분</th>
                                    <td colSpan={3}>
                                        <div className={classes.flex_row}>
                                            <SelectBoxNew
                                                className="admin_st"
                                                setSelectItemCB={onQueryTypeHandler}
                                                optionDatas={queryTypeList}
                                                showDefaultKey={queryType}
                                            />
                                            <Input
                                                placeholder="검색어입력"
                                                onChange={onQueryValueHandler}
                                                value={queryValue}
                                            />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className={classes.bottom_btn_wrap}>
                            <Button className="border sm" onClick={searchHandler}>
                                검색
                            </Button>
                            <Button className="secondary sm" onClick={findAllHandler}>
                                전체보기
                            </Button>
                        </div>
                    </div>
                </div>
                <div className={classes.contents_wrap}>
                    <div className={`${classes.admin_table_wrap} ${classes.align_center}`}>
                        <p className={classes.counter}>
                            총 <b>{totalCount}</b>건
                        </p>
                        <table>
                            <colgroup>
                                <col style={{ width: "3%" }} />
                                <col style={{ width: "10%" }} />
                                <col style={{ width: "12%" }} />
                                <col style={{ width: "21%" }} />
                                <col style={{ width: "17%" }} />
                                <col style={{ width: "17%" }} />
                                <col style={{ width: "20%" }} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>
                                        <Checkbox
                                            id="chk01"
                                            onChange={ballIdsAllSelectHandler}
                                            checked={selectAll === true}
                                        />
                                    </th>
                                    <th>No</th>
                                    <th>등록일자</th>
                                    <th>파일명</th>
                                    <th>브랜드</th>
                                    <th>모델명</th>
                                    <th>시리얼넘버</th>
                                </tr>
                            </thead>
                            <tbody>
                                {officialBallList.map((data, index) => (
                                    <tr key={data.ballId}>
                                        <td>
                                            <CheckboxB
                                                className="admin_st"
                                                id={Math.floor(Math.random() * 1000)}
                                                onChange={() => onBallCheckBoxChangeHandler(data.ballId)}
                                                checked={manageBallCheckBoxs[data.ballId]}
                                                value={data.ballId}
                                            />
                                        </td>
                                        <td>{data.seq}</td>
                                        <td>{data.regDate}</td>
                                        <td>{data.fileName}</td>
                                        <td>{data.brandName}</td>
                                        <td>{data.ballName}</td>
                                        <td>{data.serialNo}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <PaginationNew
                            className="admin_st"
                            totalPage={totalPage}
                            currentPage={pageNumber}
                            defaultShowPage={5}
                            changePageCB={pagingHandler}
                        />
                        <div className={classes.position_left_btn}>
                            <Button className="border sm" onClick={() => setDeleteConfirm(true)}>
                                선택 삭제
                            </Button>
                            <CSVLink data={data} headers={headers} filename={"시리얼 넘버 등록 양식"} forceText={true}>
                                <Button className="primary download sm">엑셀업로드 양식 다운로드</Button>
                            </CSVLink>
                        </div>
                        <div className={`${classes.bottom_btn_wrap} ${classes.position_st}`}>
                            <Button className="primary upload sm">
                                <input id="file" type="file" accept=".csv" onChange={uploadCsvHandler} />
                                <label htmlFor="file">파일 업로드</label>
                            </Button>
                            <Button className="primary download sm" onClick={(e) => dataHandler(e, searchObejct)}>
                                엑셀 다운로드
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            {deleteConfirm && (
                <Alert
                    type="confirm"
                    cancel="취소"
                    confirm="확인"
                    close={() => setDeleteConfirm(false)}
                    confirmHandler={deleteHandler}
                >
                    {"삭제 하시겠습니까?"}
                </Alert>
            )}
            {deleteModal && (
                <Alert type="alert" confirm="확인" close={deleteModalHandler}>
                    {"정상적으로 삭제되었습니다."}
                </Alert>
            )}
            {successModal && (
                <Modal
                    className="admin_st w_360"
                    close={() => navigateHandler("/admin/tournament/registerSerial")}
                    header=""
                >
                    <main>
                        <h5>
                            시리얼넘버 <b className="primary_c">{savedSize}</b>건이 등록 되었습니다.
                        </h5>
                    </main>
                    <footer>
                        <Button
                            className="secondary sm"
                            onClick={() => navigateHandler("/admin/tournament/registerSerial")}
                        >
                            확인
                        </Button>
                    </footer>
                </Modal>
            )}
            {duplicateModal && (
                <Modal
                    className="admin_st w_360"
                    close={() => navigateHandler("/admin/tournament/registerSerial")}
                    header=""
                >
                    <main>
                        <h5>
                            시리얼넘버 <b className="primary_c">{savedSize}</b>건이 등록 되었습니다.
                        </h5>
                        <h5>
                            (중복 시리얼넘버 <b className="primary_c">{duplicateSize}</b>건 제외)
                        </h5>
                        <div className="fixed_scroll_table" style={{ maxHeight: "300px" }}>
                            <table className="align_center">
                                <thead>
                                    <tr>
                                        <th>중복 시리얼넘버</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {duplicateList.map((data, index) => (
                                        <tr key={index}>
                                            <td>{data}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </main>
                    <footer>
                        <Button
                            className="secondary sm"
                            onClick={() => navigateHandler("/admin/tournament/registerSerial")}
                        >
                            확인
                        </Button>
                    </footer>
                </Modal>
            )}
        </section>
    );
}

export default MngTournamentRegisterSerial;

//등록된 사용구 시리얼 넘버 리스트 가져오기
export const loader = async () => {
    const response = await instance({
        method: "GET",
        url: "/api/v1/admin/contest/officialBall?start_reg_date=&end_reg_date=&brand=&ball_name=&serial_number=&file_name=&page=0&size=10",
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    }).catch(function (error) {
        if (error.response) {
            return error.response;
        } else if (error.request) {
            return null;
        } else {
            return null;
        }
    });
    if (response == null || response.status !== 200) {
        return [];
    }
    if (response.data.status === 200) {
        return response.data.datas;
    } else {
        return [];
    }
};
//등록된 사용구 시리얼 넘버 리스트 가져오기
