import { useEffect, useState } from "react";
import classes from "./Kegel.module.css";
import BreadCrumb from "../../components/UI/BreadCrumb/BreadCrumb";
import Pagination from "../../components/UI/Table/Pagination";
import Button from "../../components/UI/Button/Button";
import {
    ReqKegelHomeServiceList,
    ReqKegelServiceList,
} from "../../api/kegel/KegelAPi";
import PaginationNew from "../../components/UI/Table/PaginationNew";
import instance from "../../store/AxiosInterceptor";
import { useLocation, useNavigate } from "react-router-dom";

function LaneServiceHelp(props) {
    const location = useLocation();
    const navigate = useNavigate();

    const [kegelList, setKegelList] = useState([]);
    //  breadCrumb sample data
    const breadCrumbList = ["케겔", "레인정보기계 서비스", "문제해결 도우미"];

    // >>> 페이징을 위한 값 세팅
    const [totalPage, setTotalPage] = useState(0); //총 페이지 갯수
    const [pageNumber, setPageNumber] = useState(location.state?.pageNumber ?? 0); //글목록 No. 값을세팅하기 위한 서버에서 들어오는값
    const [totalCount, setTotalCount] = useState(0);
    // <<< 페이징을 위한 값 세팅

    const kegelGuideTypeMapping = {
        USEFUL_INFO: "유용한 정보",
        HELP: "문제해결 도우미",
    };

    const pageHandler = (value) => {
        setPageNumber(value);
        instance
            .get("/api/v1/home/kegel/guide", {
                params: {
                    category: "HELP",
                    exposeYn: "Y",
                    title: "",
                    page: value,
                    size: 10,
                },
                withCredentials: true,
            })
            .then((res) => {
                const curData = res.data.datas.content.map((data) => ({
                    kegelId: data.id,
                    content: data.content,
                    exposeYn: data.exposeYn,
                    kegelType: data.kegelGuideType,
                    readCnt: data.readCnt,
                    regDate: data.regDate,
                    regID: data.regID,
                    title: data.title,
                }));
                setKegelList(curData);
                setTotalPage(res.data.datas.totalPages);
                setTotalCount(res.data.datas.totalElements);
                setPageNumber(res.data.datas.pageable.pageNumber);
            })
            .catch((error) => {
                console.error(error);
                setKegelList((prev) => prev);
                setTotalPage((prev) => prev);
                setTotalCount((prev) => prev);
                setPageNumber((prev) => prev);
            });
    };
    useEffect(() => {
            pageHandler(location.state?.pageNumber ?? 0);
    }, [])
    const increaseViews = (id) => {
        navigate(`/kegel/help/${id}`, { state: { pageNumber } });
        instance.put(`/api/v1/home/kegel/guide/${id}/views`).catch((error) => {
            console.error("Error increasing views:", error);
        });
    };
    const bodyValues = kegelList.map(({ kegelId, title, regDate }, index) => {
        const number = totalCount - (10 * pageNumber + index);
        return (
            <tr key={kegelId}>
                <td>{number}</td>
                <td onClick={() => increaseViews(kegelId)}>{title}</td>
                <td>{regDate}</td>
            </tr>
        );
    });

    return (
        <>
            <BreadCrumb breadCrumbList={breadCrumbList} />

            <div className={classes.contents_wrap}>
                <div className={classes.contents_title}>
                    <h3>문제해결 도우미</h3>
                </div>
                <div className={classes.table_wrap}>
                    <table className="align_center align_left_col2">
                        <colgroup>
                            <col style={{ width: "8%" }} />
                            <col style={{ width: "43%" }} />
                            <col style={{ width: "11%" }} />
                        </colgroup>
                        <thead>
                            <tr>
                                <th>번호</th>
                                <th>제목</th>
                                <th>등록일자</th>
                            </tr>
                        </thead>
                        <tbody>{bodyValues}</tbody>
                    </table>
                    <PaginationNew
                        className="admin_st"
                        totalPage={totalPage}
                        currentPage={pageNumber}
                        changePageCB={pageHandler}
                        defaultShowPage={5}
                    />
                </div>
            </div>
        </>
    );
}

export default LaneServiceHelp;
export const loader = async () => {
    const type = "HELP";
    const response = await ReqKegelHomeServiceList(type);
    if (response.status === 200) {
        const resData = response.data.datas;

        return resData;
    } else {
        return [];
    }
};
