import BreadCrumb from "../../components/UI/BreadCrumb/BreadCrumb";
import Input from "../../components/UI/Input/Input";
import classes from "./KegelApp.module.css";
import laneMachine from "../../assets/images/lane_machine_assist_mo.png";
import laneMachine_lift from "../../assets/images/laneMachine_lift.png";
import React, { useState } from "react";
import Button from "../../components/UI/Button/Button";
import instance from "../../store/AxiosInterceptor";
import { useLoaderData, useNavigate } from "react-router-dom";

function LaneMachinesAppDetail(props) {
    const resData = useLoaderData();
    // const menuName = useLoaderData();

    const navigate = useNavigate();
    const kegelData = useLoaderData();

    const kegelTypeMapping = {
        MACHINE: "machine",
        CONSUMABLE: "consumable",
        TOOL: "training_materials",
    };

    const mappedKegelType =
        kegelTypeMapping[kegelData.kegelType] || kegelData.kegelType;

    const toList = () => {
        navigate(`/kegel/${mappedKegelType}`);
    };
    return (
        <>
            <div className={classes.contents_wrap}>
                <div className={classes.contents_detail}>
                    <div className={classes.contents_title}>
                        <div className={classes.img_box}>
                            <img
                                src={kegelData.attach_uri}
                                alt="laneMachine 이미지"
                            />
                        </div>
                        <div className={classes.contents_info}>
                            <div className={classes.title}>
                                <h3>{kegelData.title}</h3>
                            </div>
                            <div className={classes.contents}>
                                <ul>
                                    <li>{kegelData.firstSignature}</li>
                                    <li>{kegelData.secondSignature}</li>
                                    <li>{kegelData.thirdSignature}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className={classes.detail_box}>
                        <div>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: kegelData.content,
                                }}
                            />
                        </div>
                    </div>
                    <div className={classes.btn_wrap}>
                        <Button onClick={toList}>목록</Button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default LaneMachinesAppDetail;

export const loader = async ({ params }) => {
    try {
        const response = await instance.get(
            `/api/v1/home/kegel/${params.kegelId}/info`
        );

        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error("Failed to fetch data");
        }
    } catch (error) {
        console.error("Error fetching data:", error);
        return null;
    }
};
