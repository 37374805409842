import Button from "../../../../components/UI/Button/Button";
import Input from "../../../../components/UI/Input/Input";
import Modal from "../../../../components/UI/Modal/Modal";

/**
 * ID 접수제한 화면 중 ID 등록 모달화면
 * @param {props.controlIdAddList} 접근 통제 ID LIST
 * @returns 
 */
//isModalOpen4
export default function AddingApplyIDModal (props) {
    const { title, controlIdAddList, onControlIdChangeCB, onControlDescriptionChangeCB, onControlIdAddCB, onCloseModalCB, onCreateIdCB } = props;
    return (
        <Modal
            className="admin_st"
            close={onCloseModalCB}
            header="통제 ID 등록"
            desc={title}
        >
            <main>
                <table className="align_center">
                    <colgroup>
                        <col style={{ width: "50%" }} />
                        <col style={{ width: "50%" }} />
                    </colgroup>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>통제사유</th>
                        </tr>
                    </thead>
                    <tbody>
                        {controlIdAddList.map((data, index) => (
                            <tr key={data.index}>
                                <td>
                                    <Input
                                        value={data.member_id}
                                        onChange={(e) => onControlIdChangeCB(e, index)}
                                    />
                                </td>
                                <td>
                                    <Input
                                        value={data.description}
                                        onChange={(e) => onControlDescriptionChangeCB(e, index)}
                                    />
                                </td>
                            </tr>
                        ))}
                        <tr>
                            <td colSpan={2}>
                                <Button className="circle_add sm" onClick={onControlIdAddCB} />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </main>
            <footer>
                <Button className="sm" onClick={onCloseModalCB}>
                    취소
                </Button>
                <Button className="secondary sm" onClick={onCreateIdCB}>
                    등록
                </Button>
            </footer>
        </Modal>
    );
};
