import instance from "../../store/AxiosInterceptor";

/**
 * BO - 이벤트 결과 등록 및 조회
 * 이벤트명 마우스 오버 시 이벤트 PK, 이벤트 명을 가져온다.
 */
export function ReqEventName(resolveCB, rejectCB) {
    instance({
        url: `/api/v1/admin/eventApplier/eventName`,
        method: "GET",
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data.datas);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/**
 * BO - 이벤트 결과 등록 및 조회
 * 이벤트 결과 업로드
 */
export function ReqEventResultUploadFile(formData, resolveCB, rejectCB) {
    instance({
        url: `/api/v1/admin/eventApplier`,
        method: "POST",
        data: formData,
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/**
 * BO - 이벤트 결과 등록 및 조회
 * 검색 기능
 */
export function ReqEventResultSearch(props, resolveCB, rejectCB) {
    instance({
        url: `/api/v1/admin/eventApplier?${props.queryType}=${props.queryText}&eventID=${props.eventID}&chosen=${props.chosen}&page=0&size=10`,
        method: "GET",
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data.data);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/**
 * BO - 이벤트 결과 등록 및 조회
 * 검색 기능
 */
export function ReqEventResultFindAll(resolveCB, rejectCB) {
    instance({
        url: `/api/v1/admin/eventApplier?page=0&size=10`,
        method: "GET",
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data.data);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/**
 * BO - 이벤트 결과 등록 및 조회
 * 검색 기능 후 페이징 기능
 */
export function ReqEventResultPaging(props, page, resolveCB, rejectCB) {
    instance({
        url: `/api/v1/admin/eventApplier?${props.queryType}=${props.queryText}&eventID=${props.eventID}&chosen=${props.chosen}&page=${page}&size=10`,
        method: "GET",
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data.data);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}
