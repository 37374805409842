import { useState } from "react";
import classes from "../MngCommon.module.css";
import Button from "../../../components/UI/Button/Button";
import Input from "../../../components/UI/Input/Input";
import DateRangePicker from "../../../components/UI/DatePicker/DateRangePicker";
import SelectBoxNew from "../../../components/UI/SelectBox/SelectBoxNew";
import moment from "moment/moment";
import {
    DefaultMemberInfoQueryType,
    DefaultMemberStatusSearchSelectBox,
} from "../../../components/definedefault/DefSelectBoxValues";
import { useLocation, useNavigate } from "react-router-dom";
import PaginationNew from "../../../components/UI/Table/PaginationNew";
import { useEffect } from "react";
import instance from "../../../store/AxiosInterceptor";
import { ReqFindMemberAll, ReqFindMemberPaging, ReqFindMemberSearch } from "../../../api/member/MemberListApi";

function MngUserListPage() {
    const navigate = useNavigate();
    const location = useLocation();
    const navigateHandler = () => {
        navigate("register");
    };
    const detailPageHandler = (link) => {
        navigate(link, { state: { pageNumber } }); // 페이지 정보 넣어서 보냄
    };

    const [memberList, setMemberList] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [pageNumber, setPageNumber] = useState(location.state?.pageNumber ?? 0); // 목록버튼이나 뒤로가기시 받은 페이지 넘버로 감
    const [totalPages, setTotalPages] = useState(0);

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const searchStartDate = startDate ? moment(startDate).format("YYYYMMDD") : "";
    const searchEndDate = endDate ? moment(endDate).format("YYYYMMDD") : "";

    const startDateHandler = (value) => {
        setStartDate(value);
    };

    const endDateHandler = (value) => {
        setEndDate(value);
    };

    const stateList = DefaultMemberStatusSearchSelectBox;
    const [state, setState] = useState(null);
    const stateHandler = (value) => {
        setState(value);
    };

    const queryTypeList = DefaultMemberInfoQueryType;
    const [queryType, setQueryType] = useState(null);
    const queryTypeHandler = (value) => {
        setQueryType(value);
    };

    const [queryText, setQueryText] = useState("");
    const queryTextHandler = (value) => {
        setQueryText(value);
    };

    const searchObject = {
        sDate: searchStartDate,
        eDate: searchEndDate,
        state: state,
        query_type: queryType,
        query_text: queryText,
    };

    const pagingHandler = (value) => {
        setPageNumber(value);
        ReqFindMemberPaging(
            searchObject,
            value,
            (result) => {
                const curData = result.data.map((data) => {
                    return {
                        memberId: data.member_id,
                        memberName: data.name,
                        gender: data.gender,
                        phone: data.phone,
                        email: data.email,
                        birthDate: data.birth_date,
                        regDate: data.reg_date,
                        lastLogin: data.last_login,
                        state: data.state,
                        seq: data.seq,
                    };
                });
                setMemberList(curData);
                setTotalCount(result.totalElements);
                setPageNumber(result.page);
                setTotalPages(result.totalPages);
            },
            (error_result) => {
                setMemberList((prev) => prev);
                setTotalCount((prev) => prev);
                setPageNumber((prev) => prev);
                setTotalPages((prev) => prev);
            }
        );
    };

    const searchHandler = () => {
        ReqFindMemberSearch(
            searchObject,
            (result) => {
                const curData = result.data.map((data) => {
                    return {
                        memberId: data.member_id,
                        memberName: data.name,
                        gender: data.gender,
                        phone: data.phone,
                        email: data.email,
                        birthDate: data.birth_date,
                        regDate: data.reg_date,
                        lastLogin: data.last_login,
                        state: data.state,
                        seq: data.seq,
                    };
                });
                setMemberList(curData);
                setTotalCount(result.totalElements);
                setPageNumber(result.page);
                setTotalPages(result.totalPages);
            },
            (error_result) => {
                setMemberList((prev) => prev);
                setTotalCount((prev) => prev);
                setPageNumber((prev) => prev);
                setTotalPages((prev) => prev);
            }
        );
    };

    const findAllHandler = () => {
        setQueryText("");
        setQueryType(null);
        setState(null);
        setStartDate(null);
        setEndDate(null);
        setPageNumber(0);
        ReqFindMemberAll(
            (result) => {
                const curData = result.data.map((data) => {
                    return {
                        memberId: data.member_id,
                        memberName: data.name,
                        gender: data.gender,
                        phone: data.phone,
                        email: data.email,
                        birthDate: data.birth_date,
                        regDate: data.reg_date,
                        lastLogin: data.last_login,
                        state: data.state,
                        seq: data.seq,
                    };
                });
                setMemberList(curData);
                setTotalCount(result.totalElements);
                setPageNumber(result.page);
                setTotalPages(result.totalPages);
            },
            (error_result) => {
                setMemberList((prev) => prev);
                setTotalCount((prev) => prev);
                setPageNumber((prev) => prev);
                setTotalPages((prev) => prev);
            }
        );
    };


    const noDataLoader = async () => {
        const response = await instance({
            method: "GET",
            url: `/api/v1/admin/member/list?page=${pageNumber}&size=10`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        }).catch(function (error) {
            if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
                return error.response;
            } else if (error.request) {
                console.log(error.request);
                return null;
            } else {
                console.log("Error", error.message);
                return null;
            }
        });
        if (response == null || response.status !== 200) {
            return [];
        }
        if (response.data.status === 200) {
            return response.data.data;
        } else {
            return [];
        }
    };

    useEffect(() => {
        const fetchData = async () => {
             const response = await noDataLoader();
             if (response.length === 0) return;
             const curData = response.data.map((data) => {
                 return {
                     memberId: data.member_id,
                     memberName: data.name,
                     gender: data.gender,
                     phone: data.phone,
                     email: data.email,
                     birthDate: data.birth_date,
                     regDate: data.reg_date,
                     lastLogin: data.last_login,
                     state: data.state,
                     seq: data.seq,
                 };
             });
             setMemberList(curData);
             setTotalCount(response.totalElements);
             setPageNumber(response.page);
             setTotalPages(response.totalPages);
        };
        fetchData();
    }, []);

    const downLoadheaders = [
        { label: "No", key: "index" },
        { label: "아이디", key: "memberId" },
        { label: "이름", key: "memberName" },
        { label: "성별", key: "gender" },
        { label: "휴대폰", key: "phone" },
        { label: "이메일", key: "email" },
        { label: "생년월일", key: "birthDate" },
        { label: "가입일", key: "regDate" },
        { label: "최근접속일", key: "lastLogin" },
        { label: "상태", key: "status" },
    ];

    // 엑셀 다운로드
    const dataHandler = async (e, props) => {
        try {
            const response = await instance({
                url: `/api/v1/admin/member/list/excel?sDate=${props.sDate}&eDate=${props.eDate}&state=${props.state}&query_type=${props.query_type}&query_text=${props.query_text}`,
                method: "GET",
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            });

            if (response.status === 200) {
                const resData = await response.data.datas;
                const curData = resData.map((data, index) => {
                    return {
                        index: index + 1,
                        memberId: data.member_id,
                        memberName: data.name,
                        gender: data.gender,
                        phone: data.phone,
                        email: data.email,
                        birthDate: data.birth_date,
                        regDate: data.reg_date,
                        lastLogin: data.last_login,
                        state: data.state === "N" ? "활동" : "탈퇴",
                    };
                });

                if (curData.length > 0) {
                    // CSV 데이터 생성
                    let csvContent = "\uFEFF"; // BOM 문자로 UTF-8 인코딩 설정 (한글 깨짐 방지)

                    // 그룹 헤더 추가
                    curData.forEach((data, index) => {
                        if (index === 0) {
                            // 헤더 추가
                            csvContent += downLoadheaders.map((header) => header.label).join(",") + "\n";
                        }

                        if (index !== 0) {
                            csvContent += `\n`;
                        }

                        // 데이터 추가
                        csvContent += `"${data.index}","${data.memberId}","${data.memberName}","${data.gender}","${data.phone}","${data.email}","${data.birthDate}","${data.regDate}","${data.lastLogin}","${data.state}"`;
                    });

                    // Blob 생성
                    const blob = new Blob([csvContent], {
                        type: "text/csv;charset=utf-8;",
                    });

                    // Blob을 URL로 변환하여 다운로드 링크 생성
                    const url = URL.createObjectURL(blob);
                    const a = document.createElement("a");
                    a.style.display = "none";
                    a.href = url;
                    a.download = `회원관리-회원목록.csv`;
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                }
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    return (
        <section>
            <div className={classes.admin_contents}>
                <h3>회원목록</h3>
                <div className={`${classes.contents_wrap} ${classes.search_wrap}`}>
                    <div className={classes.admin_table_wrap}>
                        <table>
                            <colgroup>
                                <col style={{ width: "11%" }} />
                                <col style={{ width: "39%" }} />
                                <col style={{ width: "11%" }} />
                                <col style={{ width: "39%" }} />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>가입일자</th>
                                    <td>
                                        <DateRangePicker
                                            startDate={startDate}
                                            endDate={endDate}
                                            onStartDateChange={startDateHandler}
                                            onEndDateChange={endDateHandler}
                                        />
                                    </td>
                                    <th>상태</th>
                                    <td>
                                        <SelectBoxNew
                                            className="admin_st"
                                            optionDatas={stateList}
                                            showDefaultKey={state}
                                            setSelectItemCB={stateHandler}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>검색구분</th>
                                    <td colSpan={3}>
                                        <div className={classes.flex_row}>
                                            <SelectBoxNew
                                                className="admin_st"
                                                optionDatas={queryTypeList}
                                                showDefaultKey={queryType}
                                                setSelectItemCB={queryTypeHandler}
                                            />
                                            <Input
                                                className="admin_st"
                                                value={queryText}
                                                onChange={queryTextHandler}
                                                placeholder="검색어 입력"
                                            />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className={classes.bottom_btn_wrap}>
                            <Button className="border sm" onClick={searchHandler}>
                                검색
                            </Button>
                            <Button className="secondary sm" onClick={findAllHandler}>
                                전체보기
                            </Button>
                        </div>
                    </div>
                </div>
                <div className={classes.contents_wrap}>
                    <div className={`${classes.admin_table_wrap} ${classes.align_center}  ${classes.row_select}`}>
                        <p className={classes.counter}>
                            총 <b>{totalCount}</b>건
                        </p>
                        <table>
                            <colgroup>
                                <col style={{ width: "5%" }} />
                                <col style={{ width: "10%" }} />
                                <col style={{ width: "10%" }} />
                                <col style={{ width: "10%" }} />
                                <col style={{ width: "10%" }} />
                                <col style={{ width: "15%" }} />
                                <col style={{ width: "10%" }} />
                                <col style={{ width: "10%" }} />
                                <col style={{ width: "15%" }} />
                                <col style={{ width: "5%" }} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th>아이디</th>
                                    <th>이름</th>
                                    <th>성별</th>
                                    <th>휴대폰</th>
                                    <th>이메일</th>
                                    <th>생년월일</th>
                                    <th>가입일</th>
                                    <th>최근접속일</th>
                                    <th>상태</th>
                                </tr>
                            </thead>
                            <tbody>
                                {memberList &&
                                    memberList.map((data, index) => (
                                        <tr
                                            key={index}
                                            onClick={() => detailPageHandler(`${data.memberId}/${data.state}`)}
                                        >
                                            <td>{data.seq}</td>
                                            <td>{data.memberId}</td>
                                            <td>{data.state !== "Y" ? data.memberName : ""}</td>
                                            <td>{data.state === "N" ? data.gender : ""}</td>
                                            <td>{data.state === "N" ? data.phone : ""}</td>
                                            <td>{data.state === "N" ? data.email : ""}</td>
                                            <td>{data.state === "N" ? data.birthDate : ""}</td>
                                            <td>{data.state === "N" ? data.regDate : ""}</td>
                                            <td>{data.state === "N" ? data.lastLogin : ""}</td>
                                            {data.state === "D" && <td>휴면</td>}
                                            {data.state === "N" && <td className="primary_c">활동</td>}
                                            {data.state === "Y" && <td className="red_c">탈퇴</td>}
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                        <PaginationNew
                            className="admin_st"
                            totalPage={totalPages}
                            currentPage={pageNumber}
                            defaultShowPage={5}
                            changePageCB={pagingHandler}
                        />
                        <div className={`${classes.bottom_btn_wrap} ${classes.position_st}`}>
                            <Button className="primary download sm" onClick={(e) => dataHandler(e, searchObject)}>
                                엑셀 다운로드
                            </Button>
                            <Button className="secondary sm" onClick={navigateHandler}>
                                등록
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default MngUserListPage;

//회원 목록 리스트 가져오기
export const loader = async () => {
    const response = await instance({
        method: "GET",
        url: `/api/v1/admin/member/list?page=0&size=10`,
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    }).catch(function (error) {
        if (error.response) {
            // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            return error.response;
        } else if (error.request) {
            // 요청이 이루어 졌으나 응답을 받지 못했습니다.
            console.log(error.request);
            return null;
        } else {
            // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
            console.log("Error", error.message);
            return null;
        }
    });
    if (response == null || response.status !== 200) {
        return [];
    }
    if (response.data.status === 200) {
        return response.data.data;
    } else {
        return [];
    }
};

//회원 목록 리스트 가져오기
