// import { json } from "react-router-dom";
import instance from "../../store/AxiosInterceptor";



export async function ReqAutoSendList () {
    const response = await instance.get(
        `/api/v1/admin/contest/sms`
    )
    .then((response) => {
        return response;
    })
    .catch((error) => {
        if (error.response) { // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
            return {
                status: error.response.status,
                error: ''
            }
        }
        else if (error.request) { // 요청이 이루어 졌으나 응답을 받지 못했습니다.
            return {
                status: 600,
                error: '요청에 대한 응답 없음'
            }            
        }
        else { // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
            return {
                status: 600,
                error: '요청설정 에러'
            }                            
        }
    });
    return response;
};


export async function ReqUpdateCheckbox (id, checked) {
    const response = await instance.put(
        `/api/v1/admin/contest/sms/${id}`,
        { auto: checked ? "AUTO" : "OFF" },
    )
    .then((response) => {
        return response;
    })
    .catch((error) => {
        if (error.response) { // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
            return {
                status: error.response.status,
                error: ''
            }
        }
        else if (error.request) { // 요청이 이루어 졌으나 응답을 받지 못했습니다.
            return {
                status: 600,
                error: '요청에 대한 응답 없음'
            }            
        }
        else { // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
            return {
                status: 600,
                error: '요청설정 에러'
            }                            
        }
    });
    return response;
};