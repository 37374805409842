import { React, useEffect, useState } from "react";
import classes from "./Media.module.css";
import BreadCrumb from "../../components/UI/BreadCrumb/BreadCrumb";
import Input from "../../components/UI/Input/Input";
import Button from "../../components/UI/Button/Button";
import instance from "../../store/AxiosInterceptor";
import PaginationNew from "../../components/UI/Table/PaginationNew";
import { NavLink, useLoaderData, useLocation } from "react-router-dom";

function MainNoticePage() {
    //  breadCrumb sample data
    const breadCrumbList = ["커뮤니티", "공지사항"];

    const location = useLocation();

    const notice = useLoaderData();
    const [text, setText] = useState([]);
    const [topData, setTopData] = useState(notice.topData); // 상단 공지 데이터
    const [currentPage, setCurrentPage] = useState(location.state?.currentPage ?? 0);
    const [totalPage, setTotalPage] = useState(0); //총 페이지 갯수
    const [totalCount, setTotalCount] = useState(0); //총 페이지 갯수

    const handlePageChange = async (page) => {
        setCurrentPage(page);

        const searchParam = {
            page: page,
            searchText: searchText,
        };

        const response = await instance.get("/api/v1/home/contest/notice", {
            params: searchParam,
        });
        if (response.status === 200) {
            const resData = await response.data.datas;
            setTotalPage(resData.totalPages);
            setTotalCount(resData.totalElements);
            setText(resData.content);
        } else {
            setText([]);
        }
    };
    useEffect(() => {
        handlePageChange(currentPage)
    }, [])
    const [searchText, setSearchText] = useState("");

    const onSearchTextHandler = (value) => {
        setSearchText(value);
    };

    const findSearchHandler = async () => {
        setCurrentPage(0);

        const searchParam = {
            page: currentPage,
            searchText: searchText,
        };

        const response = await instance.get("/api/v1/home/contest/notice", {
            params: searchParam,
        });
        if (response.status === 200) {
            const resData = await response.data.datas;
            setTotalPage(resData.totalPages);
            setTotalCount(resData.totalElements);
            setText(resData.content);
        } else {
            setText([]);
        }
    };
    const increaseViews = (id) => {
        instance.put(`/api/v1/home/contest/${id}/views`).catch((error) => {
            console.error("Error increasing views:", error);
        });
    };
    return (
        <>
            <BreadCrumb breadCrumbList={breadCrumbList} />
            <div className={classes.contents_wrap}>
                <div className={classes.contents_title}>
                    <h3>공지사항</h3>
                    <div className={classes.search_wrap}>
                        <Input onChange={onSearchTextHandler} />
                        <Button
                            className="search_sm"
                            onClick={findSearchHandler}
                        />
                    </div>
                </div>
                <div className={classes.table_wrap}>
                    <table className="align_center align_left_col2">
                        <colgroup>
                            <col style={{ width: "10%" }} />
                            <col style={{ width: "70%" }} />
                            <col style={{ width: "20%" }} />
                        </colgroup>
                        <thead>
                            <tr>
                                <th>번호</th>
                                <th>제목</th>
                                <th>날짜</th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* 상단 공지 데이터 표시 */}
                            {topData.map((data) => (
                                <tr key={data.id}>
                                    <td>
                                        <b>공지</b>
                                    </td>
                                    <td onClick={() => increaseViews(data.id)}>
                                        <NavLink to={`${data.id}`}>
                                            <b>{data.title}</b>
                                        </NavLink>
                                    </td>
                                    <td>
                                        <b>{data.regDate}</b>
                                    </td>
                                </tr>
                            ))}
                            {text.map((data, index) => {
                                return (
                                    <tr key={data.id}>
                                        <td>
                                            {totalCount -
                                                (10 * currentPage + index)}
                                        </td>
                                        <td
                                            onClick={() =>
                                                increaseViews(data.id)
                                            }
                                        >
                                            <NavLink to={`${data.id}`} state={{ currentPage }}>
                                                {data.title}
                                            </NavLink>
                                        </td>
                                        <td>{data.regDate}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    <PaginationNew
                        className="admin_st"
                        totalPage={totalPage}
                        currentPage={currentPage}
                        defaultShowPage={5}
                        changePageCB={handlePageChange}
                    />
                </div>
            </div>
        </>
    );
}

export default MainNoticePage;

export const loader = async () => {
    return instance
        .get("/api/v1/home/contest/notice?page=0")
        .then((response) => {
            const resData = response.data.datas;
            // console.log("resData ", resData);
            const curData = resData.content.map((data) => {
                return {
                    id: data.id,
                    title: data.title,
                    exposeYn: data.exposeYn,
                    readCnt: data.readCnt,
                    regID: data.regID,
                    regDate: data.regDate,
                };
            });
            return {
                data: curData,
                topData: response.data.topNoticeList,
                totalPage: resData.totalPages,
                totalCount: resData.totalElements,
            };
        })
        .catch((error) => {
            return [];
        });
};
