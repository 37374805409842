import Moment from "moment";
import Checkbox from "../../components/UI/Input/Checkbox";
import classes from "./SystemCheckInfo.module.css";
function SystemCheckInfo(props) {

    const {systemPopupCB, typeCB} = props;

    return (
        <main className={classes.system_check_wrap}>
            <h3>시스템 점검 안내</h3>
            <div>
                {systemPopupCB.title}
            </div>
            <div className={classes.box}>
                <b>점검일시</b>{Moment(systemPopupCB.startDate, "YYYYMMDDHHmm").format("YYYY-MM-DD HH:mm")} ~ {Moment(systemPopupCB.endDate, "YYYYMMDDHHmm").format("YYYY-MM-DD HH:mm")}
            </div>
            {!typeCB && (
                {/* <p className={classes.today_close}>
                <Checkbox
                    className="official_st"
                    id="chk_02"
                    value="오늘 하루 보지 않기"
                    title="오늘 하루 보지 않기"
                />
                </p> */}
            )}
            
        </main>
    );
}
export default SystemCheckInfo;
