import instance from "../../store/AxiosInterceptor";

/**
 * FO - 진행중인 이벤트, 타입별로 가져오기
 * 최신순 OR 마감임박순
 * 전체보기
 */
export function ReqFindAllEventList(type, resolveCB, rejectCB) {
    instance({
        url: `/api/v1/home/event?type=${type}&page=0&size=10`,
        method: "GET",
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data.datas);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/**
 * FO - 진행중인 이벤트, 페이징
 */
export function ReqPageingEventList(type, page, resolveCB, rejectCB) {
    instance({
        url: `/api/v1/home/event?type=${type}&page=${page}&size=10`,
        method: "GET",
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data.datas);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/**
 * FO - 진행중인 이벤트, 페이징
 */
export function ReqSearchEventChosenList(queryText, resolveCB, rejectCB) {
    instance({
        url: `/api/v1/home/event/chosen?queryText=${queryText}&page=0&size=10`,
        method: "GET",
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data.datas);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/**
 * FO - 진행중인 이벤트, 페이징
 */
export function ReqPageingEventChosenList(queryText, page, resolveCB, rejectCB) {
    instance({
        url: `/api/v1/home/event/chosen?queryText=${queryText}&page=${page}&size=10`,
        method: "GET",
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data.datas);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}