import { initMemStore } from "./MemStore"

/**
 * useMemStore 값을 사용하기 위한 초기화 정의
 */
const configureMemStore = () => {
    const defaultActions = {
        /**
         * SAVE_LAST_PAGE 마지막 페이지를 저장한다 
         * {
         *   comment: '링크생성시 로그로 남기고 싶은 말'
         *   uri: '마지막 링크의 uri'
         * }
         * ACTION은 함수가 들어가야 하며, 자신의 새로운 State를 Return 해야 한다.
         * @returns 
         */
        LOGIN_MOVE_PAGE: (curState, newPage) => {
            // console.log(curState.lastPage, " to ", newPage);
            return { lastPage: newPage };
        }
    }

    // Default 값이 있으면 넣어준다.
    initMemStore (defaultActions, {
        lastPage: {
        }
    });
}

export default configureMemStore;