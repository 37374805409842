import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

const pageNumberSlice = createSlice({
  name: 'pageInfo',
  initialState: {
    number: {}
  },
  reducers: {
    setNowPageNumber: (state, action) => {
        if(!_.isObject(action.payload)) return;
        state.number = { ...state.number, ...action.payload };
  },
}
})

export const { setNowPageNumber } = pageNumberSlice.actions;
export default pageNumberSlice.reducer;