import { useContext, useState } from "react";
import Button from "../../../../components/UI/Button/Button";
import Input from "../../../../components/UI/Input/Input";
import Radio from "../../../../components/UI/Input/Radio";
import classes from "../../MngCommon.module.css";
import Moment from 'moment';
import MngTermsHistoryList from "./MngTermsHistoryList";
import { ReqTermsDetail, ReqTermsUpdate } from "../../../../api/site/ApiForTerms";
import { useLoaderData, useLocation, useNavigate } from "react-router-dom";
import QuillEditor from "../../../../components/UI/Editer/QuillEditor";
import { JwtTokenContext } from "../../../../store/JwtTokenProvider";
function MngTermsModify(props) {
    
    const loginCtx = useContext(JwtTokenContext);

    const term = useLoaderData();

    const location = useLocation();
    const navigate = useNavigate();

    const backHandler = () => {
        navigate("/admin/site/terms", { state: location.state });
    };

    const [title, setTitle] = useState(term.title);
    const [revisionHist, setRevisionHist] = useState(term.modify);
    const [description, setDescription] = useState(term.content);
    const [exposeYnStatus, setExposeYnStatus ] = useState(term.exposeYn)

    const changeTitleHandler = (value) => {
        setTitle(value);
    };

    const changeRevisionHistHandler = (value) => {
        setRevisionHist(value);
    };

    const descriptionHandler = (value) => {
        setDescription(value);
    };

    const exposeYnRadioButton = (e) => {
        setExposeYnStatus(e.target.value)
    }

    const updateHandler = async (e) => {
        e.preventDefault();
        
        if(!title) {
          alert("제목을 입력해주세요.");
          return
        }

        if(!description) {
          alert("전문을 입력해주세요.");
          return
        }

        if(!revisionHist) {
            alert("개정내용을 입력해주세요.");
            return
          }

        const isTrue = window.confirm('내용을 수정하시겠습니까?');
        if(isTrue) {
            try {
                const jsonData = {
                    title: title,
                    exposeYn: exposeYnStatus,
                    modify: revisionHist,
                    content: description,
                };

                const response = await ReqTermsUpdate(term.id, jsonData);
                if (response.status === 200) {
                    alert("내용이 수정되었습니다.");
                    backHandler();
                } else {
                    alert("내용 수정 실패");
                }
            } catch (error) {
                console.error("Error:", error);
            }
        } else {
          return
        }

        
    };

    return (
        <section>
                <div className={classes.admin_contents}>
                    <h3>이용약관/개인정보취급방침 - 수정</h3>
                    <div
                        className={`${classes.contents_wrap} ${classes.search_wrap} `}
                    >
                        <div className={classes.admin_table_wrap}>
                            <table>
                                <colgroup>
                                    <col style={{ width: "10%" }} />
                                    <col style={{ width: "40%" }} />
                                    <col style={{ width: "10%" }} />
                                    <col style={{ width: "40%" }} />
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <th>등록일</th>
                                        <td>{Moment(new Date()).format('YYYY-MM-DD')}</td>
                                        <th>등록자</th>
                                        <td>{loginCtx.decodedToken.jti}</td>
                                    </tr>
                                    <tr>
                                        <th>사용여부</th>
                                        <td colSpan={3}>
                                            <div className={classes.radio_wrap}>
                                                <Radio
                                                    value="Y"
                                                    title="사용"
                                                    name="rad_01"
                                                    id="rad_01_01"
                                                    checked={exposeYnStatus === "Y"} 
                                                    onChange={exposeYnRadioButton}
                                                />
                                                <Radio
                                                    value="N"
                                                    title="미사용"
                                                    name="rad_01"
                                                    id="rad_01_02"
                                                    checked={exposeYnStatus === "N"} 
                                                    onChange={exposeYnRadioButton}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>제목</th>
                                        <td colSpan={3}>
                                        <Input className="admin_st" onChange={changeTitleHandler} value={title}/>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>전문</th>
                                        <td colSpan={3}>
                                        <div
                                            style={{ border: "1px solid #dedede", height: "292px" }}
                                        >
                                            <QuillEditor
                                            height='248' width='auto'
                                            onValueChangeCB={descriptionHandler}
                                            value={description}
                                            />
                                        </div>    
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>개정내용</th>
                                        <td colSpan={3}>
                                            <Input className="admin_st" onChange={changeRevisionHistHandler} value={revisionHist}/>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className={classes.bottom_btn_wrap}>
                                <Button className="secondary sm" onClick={updateHandler}>수정</Button>
                                <Button
                                    className="border sm"
                                    onClick={backHandler}
                                >
                                    목록
                                </Button>
                            </div>
                        </div>
                    </div>
                    <MngTermsHistoryList idCB={term.id} typeCB={term.type}/>
                </div>
        </section>
    );
}

export default MngTermsModify;

export const loader = async ({ params }) => {
    const response = await ReqTermsDetail(params.termsId);
    
    if (response.status === 200) {
        const resData = await response.data;
        return resData;
    } else {
        return [];
    }
};