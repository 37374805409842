import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import classes from "../TournamentContainer.module.css";

function ResultContent(content) {
    const { content: actualContent } = content;

    return (
        <div className={`${classes.content_wrap} ${classes.table_st}`}>
            <h4> </h4>
            <div>
                <div
                    dangerouslySetInnerHTML={{
                        __html: actualContent,
                    }}
                />
            </div>
        </div>
    );
}

export default ResultContent;
