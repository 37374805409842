import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import configureMemStore from "./store/memstore/configureMemStore";
import ReactGA from "react-ga4"

// [A] YiSuHwan 전역으로 사용하는 부분이라 여기에 정의 
configureMemStore();
// [A] YiSuHwan Google Analistics initialize. first tracker
ReactGA.initialize("G-8WCDCK6V4V");
// [A] 20240620 YiSuHwan 진승에서 전달받은 tracker id. second tracker
ReactGA.initialize("G-N7FL89VZYG");

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
 
        <App />

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
