import instance from "../../store/AxiosInterceptor";

/// ID 통제 및 연령 통제 리스트
export async function ReqApplyConditionAll(contestID) {
    const result = await instance
        .get(`/api/v1/admin/contest/condition/${contestID}`)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            if (error.response) {
                // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
                return {
                    status: error.response.status,
                    error: error.response.error,
                };
            } else if (error.request) {
                // 요청이 이루어 졌으나 응답을 받지 못했습니다.
                return { status: 600, error: "not requested" };
            } else {
                // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
                return { status: 400, error: "local setting error" };
            }
        });
    return result;
}

/// ID 통제 및 연령 통제 등록
export async function ReqApplyConditionCreate(contestID, formData) {
    const result = await instance
        .post(`/api/v1/admin/contest/condition/${contestID}`, formData)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            if (error.response) {
                // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
                return {
                    status: error.response.status,
                    error: error.response.error,
                };
            } else if (error.request) {
                // 요청이 이루어 졌으나 응답을 받지 못했습니다.
                return { status: 600, error: "not requested" };
            } else {
                // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
                return { status: 400, error: "local setting error" };
            }
        });
    return result;
}

/// ID 통제 및 연령 통제 삭제
export async function ReqApplyConditionDelete(contestID, formData) {
    const result = await instance
        .delete(`/api/v1/admin/contest/condition/${contestID}`, formData)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            if (error.response) {
                // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
                return {
                    status: error.response.status,
                    error: error.response.error,
                };
            } else if (error.request) {
                // 요청이 이루어 졌으나 응답을 받지 못했습니다.
                return { status: 600, error: "not requested" };
            } else {
                // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
                return { status: 400, error: "local setting error" };
            }
        });
    return result;
}

/// 핸디 리스트
export async function ReqContestHandicapList(page) {
    const result = await instance
        .get(`/api/v1/admin/contest/handicap?page=${page}`)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            if (error.response) {
                // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
                return {
                    status: error.response.status,
                    error: error.response.error,
                };
            } else if (error.request) {
                // 요청이 이루어 졌으나 응답을 받지 못했습니다.
                return { status: 600, error: "not requested" };
            } else {
                // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
                return { status: 400, error: "local setting error" };
            }
        });
    return result;
}

/// 핸디 생성
export async function ReqContestHandicapCreate(formData) {
    const result = await instance
        .post("/api/v1/admin/contest/handicap", formData)
        .then((response) => {
            // console.log (response);

            return response;
        })
        .catch((error) => {
            if (error.response) {
                // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
                return {
                    status: error.response.status,
                    error: error.response.error,
                };
            } else if (error.request) {
                // 요청이 이루어 졌으나 응답을 받지 못했습니다.
                return { status: 600, error: "not requested" };
            } else {
                // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
                return { status: 400, error: "local setting error" };
            }
        });
    return result;
}

/// 핸디 수정
export async function ReqContestHandicapModify(id, formData) {
    const result = await instance
        .put(`/api/v1/admin/contest/handicap/${id}`, formData)
        .then((response) => {
            // console.log(response);

            return response;
        })
        .catch((error) => {
            if (error.response) {
                // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
                return {
                    status: error.response.status,
                    error: error.response.error,
                };
            } else if (error.request) {
                // 요청이 이루어 졌으나 응답을 받지 못했습니다.
                return { status: 600, error: "not requested" };
            } else {
                // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
                return { status: 400, error: "local setting error" };
            }
        });
    return result;
}

/// 핸디 삭제
export async function ReqContestHandicapDelete(id) {
    const result = await instance
        .delete(`/api/v1/admin/contest/handicap/${id}`)
        .then((response) => {
            // console.log(response);

            return response;
        })
        .catch((error) => {
            if (error.response) {
                // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
                return {
                    status: error.response.status,
                    error: error.response.error,
                };
            } else if (error.request) {
                // 요청이 이루어 졌으나 응답을 받지 못했습니다.
                return { status: 600, error: "not requested" };
            } else {
                // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
                return { status: 400, error: "local setting error" };
            }
        });
    return result;
}
