import React, { useRef, useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import classes from "./TournamentApp.module.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Accordion from "../../components/UI/Accordion/Accordion";
import FormSignIn from "../../components/auth/FormSignIn";
import Button from "../../components/UI/Button/Button";
import {
    ReqBannerList,
    ReqFAQList,
    ReqOngoingTournamentList,
    ReqResultList,
    ReqTournamentList,
    ReqTournamentNoticeList,
} from "../../api/home/TournamentHomepageApi";
import { JwtTokenContext } from "../../store/JwtTokenProvider";
import HomeTournamentBannerApp from "./components/HomeTournamentBannerApp";
import OngoingTournamentApp from "./components/OngoingTournamentApp";
import HomeTournamentNoticeApp from "./components/HomeTournamentNoticeApp";

function TournamentAppPage(props) {
    const loginCtx = useContext(JwtTokenContext);
    const isUserLogin = loginCtx.isLogin() ? (loginCtx.isAdmin() ? false : true) : false;
    const [resultList, setResultList] = useState([]);

    // 메인 슬라이드
    const [slideIndex, setSlideIndex] = useState(0);
    const [updateCount, setUpdateCount] = useState(0);
    const sliderRef = useRef(null);
    const sliderSettings = {
        dots: true,
        lazyLoad: true,
        infinite: true,
        speed: 300,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        afterChange: () => setUpdateCount(updateCount + 1),
        beforeChange: (current, next) => setSlideIndex(next),
    };

    // 대회결과 슬라이드
    const [arr, setArr] = useState();
    const [slideIndex_2, setSlideIndex_2] = useState(0);
    const [updateCount_2, setUpdateCount_2] = useState(0);
    const sliderRef_2 = useRef(null);
    const sliderSettings_2 = {
        lazyLoad: true,
        infinite: arr > 4,
        speed: 300,
        slidesToShow: 4,
        // slidesToScroll: 1,
        arrows: true,
        autoplay: false,
        afterChange: () => setUpdateCount_2(updateCount_2 + 1),
        beforeChange: (current, next) => setSlideIndex_2(next),
        responsive: [
            {
                breakpoint: 1360,
                settings: {
                    arrows: false,
                },
            },
        ],
    };

    // >>> 진행중인 대회
    const [ongoingTournament, setOngoingTournament] = useState([]);

    const ongoingTournamentHandler = () => {
        ReqOngoingTournamentList(
            (result) => {
                const curData = result.map((data) => {
                    return {
                        contestId: data.contest_id,
                        placeId: data.place_id,
                        name: data.name,
                        type: data.type,
                        centerName: data.center_name,
                        regionName: data.region_name,
                        title: data.type_title,
                    };
                });
                setOngoingTournament(curData);
            },
            (eroor_result) => {
                setOngoingTournament((prev) => prev);
            }
        );
    };
    // <<< 진행중인 대회

    // >>> 대회 공지
    const [tournamentNotice, setTournamentNotice] = useState([]);

    const tournamentNoticeHandler = async () => {
        ReqTournamentNoticeList(
            (result) => {
                const curData = result.map((data) => {
                    return {
                        id: data.id,
                        boardType: data.boardType,
                        title: data.title,
                        type: data.ct_type,
                        typeTitle: data.type_title,
                    };
                });
                setTournamentNotice(curData);
            },
            (error_result) => {
                setTournamentNotice((prev) => prev);
            }
        );
    };

    const navigate = useNavigate();
    const moveNoticeHandler = (e) => {
        e.preventDefault();
        navigate("/tournament/notice");
    };
    // <<< 대회 공지

    // >>> 대회 안내
    const [tournamentList, setTournamentList] = useState([]);

    const tournamentInfoHandler = () => {
        ReqTournamentList(
            (result) => {
                const curData = result.map((data) => {
                    return {
                        contestId: data.contest_id,
                        contestTitle: data.contest_title,
                    };
                });
                setTournamentList(curData);
            },
            (error_result) => {
                setTournamentList((prev) => prev);
            }
        );
    };

    const moveInfoHandler = (e) => {
        e.preventDefault();
        navigate("/tournament/list");
    };
    // <<< 대회 안내, 공지

    /// >>> FAQ
    const [faqData, setFaqData] = useState([]);

    const faqHandler = () => {
        ReqFAQList(
            (result) => {
                const curData = result.map((data) => {
                    return {
                        id: data.id,
                        boardType: data.boardType,
                        question: data.title,
                        answer: data.content,
                    };
                });
                setFaqData(curData);
            },
            (error_result) => {
                setFaqData((prev) => prev);
            }
        );
    };
    /// <<< FAQ

    /// >>> 노출로 설정된 대회 메인 배너만 출력
    const [banners, setBanners] = useState([]);

    const bannerHandler = () => {
        ReqBannerList(
            "contest",
            (result) => {
                const curData = result.map((data) => {
                    return {
                        id: data.id,
                        title: data.title,
                        priority: data.priority,
                        exposeYn: data.expose_yn,
                        launchNewPage: data.launch_new_page,
                        linkUrl: data.link_url,
                        mobileLinkUrl: data.link_url_mobile,
                        fileUri: data.file_uri,
                        mobileFileUri: data.mobile_file_uri,
                    };
                });
                setBanners(curData);
            },
            (error_result) => {
                setBanners((prev) => prev);
            }
        );
    };
    /// <<< 노출로 설정된 대회 메인 배너만 출력

    const resultListHandler = () => {
        ReqResultList(
            (result) => {
                const curData = result.datas?.map((data) => {
                    return {
                        contestId: data.contest_id,
                        contestName: data.contest_name,
                        centerName: data.center_name,
                        region: data.region,
                        resultId: data.result_id,
                        fileUri: data.file_uri,
                    };
                });
                setResultList(curData);
                setArr(curData.length);
            },
            (error_result) => {
                setResultList((prev) => prev);
            }
        );
    };

    useEffect(() => {
        bannerHandler();
        ongoingTournamentHandler();
        tournamentNoticeHandler();
        tournamentInfoHandler();
        faqHandler();
        resultListHandler();
    }, []);

    const contestResultPageHandler = (contestId) => {
        navigate(`/tournament/result/${contestId}`);
    };

    const moveToContestDetail = (link) => {
        navigate(link);
    }

    return (
        <section className={`container ${classes.tournament_main_wrap}`}>
            <div className={classes.tournament_main}>
                <article className={classes.visual_wrap}>
                    <div className={classes.tournament_main_slider}>
                        <Slider ref={sliderRef} {...sliderSettings}>
                            {banners.map((banner) => (
                                <HomeTournamentBannerApp
                                    key={banner.id}
                                    mobileFileUri={banner.mobileFileUri}
                                    title={banner.title}
                                    launchNewPage={banner.launchNewPage}
                                    // linkUrl={banner.mobileLinkUrl}
                                    linkUrl={banner.linkUrl}
                                />
                            ))}
                        </Slider>
                    </div>
                </article>
                {/* mobile - login_wrap del */}
            </div>
            {ongoingTournament.length !== 0 && (
                <article>
                    <div className={classes.contents_wrap}>
                        <h2>진행중 대회</h2>
                        <ul className={classes.tournament_list}>
                            {ongoingTournament.map((data, index) => (
                                <OngoingTournamentApp
                                    onClick={()=>moveToContestDetail(`/tournament/apply/${data.contestId}/${data.placeId}`)}
                                    key={index}
                                    contestId={data.contestId}
                                    placeId={data.placeId}
                                    name={data.name}
                                    type={data.type}
                                    title={data.title}
                                    centerName={data.centerName}
                                    regionName={data.regionName}
                                />
                            ))}
                        </ul>
                    </div>
                </article>
            )}
            {/* 모바일에서 대회안내 / 대회 공지 별도의 article 로 구조 변경 */}
            <article className={classes.dark}>
                <div className={`flex_row ${classes.contents_wrap}`}>
                    <div className={classes.tournament_main_table_wrap}>
                        <h2>
                            대회안내
                            <Button className="circle_add" onClick={moveInfoHandler}></Button>
                        </h2>
                        <ul className={classes.list}>
                            {tournamentList.map((data, index) => (
                                <Link to={`/tournament/list/info/${data.contestId}`} key={index}>
                                    <li key={index}>{data.contestTitle}</li>
                                </Link>
                            ))}
                        </ul>
                    </div>
                </div>
            </article>
            <article className={classes.blue}>
                <div className={`flex_row ${classes.contents_wrap}`}>
                    <div className={classes.tournament_main_table_wrap}>
                        <h2>
                            대회공지
                            <Button className="circle_add" onClick={moveNoticeHandler}></Button>
                        </h2>
                        <ul className={classes.list}>
                            {tournamentNotice.map((data) => (
                                <HomeTournamentNoticeApp
                                    key={data.id}
                                    id={data.id}
                                    title={data.title}
                                    type={data.type}
                                    typeTitle={data.typeTitle}
                                />
                            ))}
                        </ul>
                    </div>
                </div>
            </article>
            {/* 모바일에서 blue 제거 */}
            {/**진승 측 요청으로 데이터 마이그레이션 전까지 대회 결과는 주석처리 */}
            <article>
                <div className={classes.contents_wrap}>
                    <h2>대회결과</h2>
                    <div className={classes.contents_slider}>
                        <Slider ref={sliderRef_2} {...sliderSettings_2}>
                            {resultList.map((data, index) => (
                                <div className={classes.content} key={index}>
                                    <img src={`${data.fileUri}`} alt="대회 대표 이미지" onClick={()=>contestResultPageHandler(data.contestId)}/>
                                    <span>
                                        {data.region}({data.centerName})
                                    </span>
                                    <p>{data.contestName}</p>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </article>
            <article>
                {/* mobile .faq_wrap 추가 */}
                <div className={`${classes.contents_wrap} ${classes.faq_wrap}`}>
                    <h2>FAQ</h2>
                    <Accordion contents={faqData} />
                </div>
            </article>
        </section>
    );
}

export default TournamentAppPage;
