import { Outlet } from "react-router-dom";
import MngCompanyNavigation from "./MngCompanyNavigation";
import classes from "../MngCommon.module.css";

function MngCompanyRootLayout() {
    return (
        <>
            <div className={classes.admin_container}>
                <MngCompanyNavigation />
                <Outlet />
            </div>
        </>
    );
}

export default MngCompanyRootLayout;
