import Input from "../../components/UI/Input/Input";
import classes from "./KegelApp.module.css";

import React, { useEffect, useState } from "react";
import Button from "../../components/UI/Button/Button";
import { Link, useLoaderData } from "react-router-dom";
import { ReqHomeKegel } from "../../api/kegel/KegelAPi";
import instance from "../../store/AxiosInterceptor";

function LaneMachinesAppPage(props) {
    const [currentPage, setCurrentPage] = useState(0);
    const [KegelList, setKegelList] = useState([]);
    const [searchText, setSearchText] = useState("");
    const resData = useLoaderData().data;
    const menuName = useLoaderData().params;

    const kegelTypeMapping = {
        machine: "레인정비기계",
        consumable: "레인정비소모품",
        training_materials: "훈련 도구",
    };

    // //모달 상태 관리
    const [showConfirmation, setShowConfirmation] = useState(false);
    const onChangeShowConfirmation = (state) => {
        setShowConfirmation(state);
    };

    const onChangePageHandler = () => {
        setCurrentPage((prev) => prev + 1);
    };

    const onSearchTextHandler = (value) => {
        setSearchText(value);
    };

    const findKegelListSearchHandler = () => {
        setCurrentPage(0);
        ReqHomeKegel(
            menuName,
            0,
            searchText,
            (result) => {
                if (result.length === 0) {
                    // onChangeShowConfirmation(true);
                    // return;
                    setKegelList([]);
                }
                const curData = result.map((data) => {
                    return {
                        id: data.id,
                        kegelType: data.kegelType,
                        title: data.title,
                        subDescription: data.subDescription,
                        file_uri: data.file_uri,
                    };
                });
                setKegelList(curData);
            },
            (error_result) => {
                setKegelList((prev) => prev);
            }
        );
    };

    const findKegelListPageHandler = () => {
        onChangePageHandler();
        ReqHomeKegel(
            menuName,
            currentPage + 1,
            searchText,
            (result) => {
                const curData = result.map((data) => {
                    return {
                        id: data.id,
                        kegelType: data.kegelType,
                        title: data.title,
                        subDescription: data.subDescription,
                        file_uri: data.file_uri,
                    };
                });
                setKegelList((prev) => [...prev, ...curData]);
            },
            (error_result) => {
                setKegelList((prev) => prev);
            }
        );
    };

    useEffect(() => {
        const curData = resData.map((data) => {
            return {
                id: data.id,
                kegelType: data.kegelType,
                title: data.title,
                subDescription: data.subDescription,
                file_uri: data.file_uri,
            };
        });
        setKegelList(curData);
    }, [resData]);

    const increaseViews = (id) => {
        instance.put(`/api/v1/home/kegel/${id}/views`).catch((error) => {
            console.error("Error increasing views:", error);
        });
    };
    return (
        <>
            <div className={classes.contents_wrap}>
                <div className={classes.contents_title}>
                    <div className={classes.search_wrap}>
                        <Input
                            placeholder="검색어를 입력해주세요."
                            onChange={onSearchTextHandler}
                        />
                        <Button
                            className="search_sm"
                            onClick={findKegelListSearchHandler}
                        />
                    </div>
                </div>
                <div className={classes.card_board}>
                    {KegelList.map((data) => (
                        <Link to={`${data.id}`} key={data.id}>
                            <div
                                className={classes.card}
                                onClick={() => increaseViews(data.id)}
                            >
                                <div className={classes.img_box}>
                                    <img
                                        src={data.file_uri}
                                        alt="케겔 이미지"
                                    />
                                </div>

                                <div className={classes.text_box}>
                                    <h4>{data.title}</h4>
                                    <p>{data.subDescription}</p>
                                </div>
                            </div>
                        </Link>
                    ))}
                </div>
                {KegelList.length == 0 && (
                    <div className={classes.empty_data}>
                        <p>검색결과가 없습니다.</p>
                        {/* <Button className="secondary">볼링볼 전체보기</Button> */}
                    </div>
                )}

                <div className={classes.bottom_center_btn}>
                    {KegelList.length > 0 && (
                        <Button
                            className="text_icon"
                            onClick={findKegelListPageHandler}
                        >
                            더보기
                        </Button>
                    )}
                </div>
            </div>
        </>
    );
}

export default LaneMachinesAppPage;

export const loader = async (param) => {
    let params = param.params.machine;
    const response = await instance.get(
        `/api/v1/home/kegel/${params}?page=0&size=5`
    );

    const resData = response.data.datas.content;

    if (response.data.status === 200) {
        return {
            data: resData,
            params: params,
        };
    } else {
        return { data: [], params: params };
    }
};
