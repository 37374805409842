import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../../../components/UI/Button/Button";
import Alert from "../../../../components/UI/Modal/Alert";
import Modal from "../../../../components/UI/Modal/Modal";
import { JwtTokenContext } from "../../../../store/JwtTokenProvider";
import ApplyTournamentFormApp from "../ApplyTournamentFormApp";
import ReceptionStatusApp from "../ReceptionStatusApp";
import WaitingService from "../WaitingService";
import { useMemStore } from "../../../../store/memstore/MemStore";
import { ReqCheckContestCondition } from "../../../../api/tournament/ApiForContestApply";

/**
 * https://bowlingkorea.com/tournament/apply
 * 대회 접수 화면의 개별 목록
 *
 * @param {} props
 * @returns
 */
const ApplyTournamentListApp = (props) => {
    const loginCtx = useContext(JwtTokenContext);
    const isLogin = loginCtx.isLogin();
    const navigate = useNavigate();
    const setMemStore = useMemStore(false)[1];

    let currentStatus = "";
    let newCtType = "";

    const now = new Date();
    const contestAppStartTime = new Date(props.appStartTime);
    const contestAppEndTime = new Date(props.appEndTime);

    // [M] 20230905 YiSuHwan 대회상태관련
    const ctProcessType = props?.ctProcessType;
    const placeCloseApply = props?.placeCloseApply;
    let isApply = false;
    if (ctProcessType) {
        if (ctProcessType === "GENERATED") {
            currentStatus = "접수 전";
        } else if (ctProcessType === "APPLY") {
            if (placeCloseApply && placeCloseApply === "Y") currentStatus = "접수 마감";
            else {
                currentStatus = "접수 중";
                isApply = true;
            }
        } else {
            currentStatus = "접수 마감";
        }
    } else {
        // 절대 타지 않아야 하지만 혹시나 예외가 있을 수 있어 추가됨 backend 적용 후 삭제 무방
        if (now < contestAppStartTime) {
            currentStatus = "접수 전";
        } else if (now < contestAppEndTime) {
            currentStatus = "접수 중";
            isApply = true;
        } else {
            currentStatus = "접수 마감";
        }
    }

    switch (props.ctType) {
        case "K":
            newCtType = "type_a";
            break;
        case "L":
            newCtType = "type_b";
            break;
        case "A":
            newCtType = "type_c";
            break;
        case "B":
            newCtType = "type_d";
            break;
        case "S":
            newCtType = "type_d";
            break;
    }

    //접수하기 모달 상태 관리
    const [showApplyModal, setShowApplyModal] = useState(false);
    const [loginAlert, setLoginAlert] = useState(false);
    const [retryLogin, setRetryLogin] = useState(false);
    const [conditionAlert, setConditionAlert] = useState(false);
    const [conditionText, setConditionText] = useState("");

    const reLoginModalHandler = (state) => {
        setRetryLogin(state);
    };

    const onShowApplyModalHandler = (state) => {
        //여기에 서버갔다와서 참가제한 확인하기
        if (!isLogin) {
            onShowLoginModalHandler(true);
            return;
        }

        ReqCheckContestCondition(
            props.ctId,
            (result) => {
                if (result.status === 200) {
                    setShowApplyModal(state);
                }
            },
            (error_result) => {
                if (error_result.status === 403) {
                    loginCtx.removeToken();
                    reLoginModalHandler(true);
                    return;
                }

                if (error_result.status === 400) {
                    setConditionAlert(true);
                    setConditionText(`아래사유로 접수 하실 수 없습니다 : ${error_result.error}`);
                    return;
                }
            }
        );
    };

    const onShowLoginModalHandler = (state) => {
        setLoginAlert(state);
    };

    const navigateHandler = () => {
        if (loginAlert) onShowLoginModalHandler(false);
        if (retryLogin) reLoginModalHandler(false);
        // configureMemStore에 정의된 ACTION
        setMemStore("LOGIN_MOVE_PAGE", {
            uri: "/tournament/apply",
        });

        navigate("/login");
    };

    //접수결과 레인 배치
    const [showApplyResultAndLane, setShowApplyResultAndLane] = useState(false);
    const onShowApplyResultLaneModalHandler = (state) => {
        setShowApplyResultAndLane(state);
    };

    //TODO : 접속 대기 조건
    const [showWaitingModal, setShowWaitingModal] = useState(false);
    const onShowWaitingModalHandler = (state) => {
        setShowWaitingModal(state);
    };

    return (
        <tr>
            <td>
                <b className={`${newCtType}`}>{props.ctTypeName}</b>
            </td>
            <td>
                <Link to={`${props.ctId}/${props.placeId}`}>{props.ctTitle}</Link>
            </td>
            <td></td>
            <td>{props.centerRegion}</td>
            <td>{props.centerName}</td>
            <td>{currentStatus}</td>
            <td>
                {isApply && (
                    <Button className="secondary xs" onClick={() => onShowApplyModalHandler(true)}>
                        접수하기
                    </Button>
                )}
                {!isApply && (
                    <Button className="secondary xs" disabled>
                        접수하기
                    </Button>
                )}
            </td>
            <td>
                <Button className="border xs" onClick={() => onShowApplyResultLaneModalHandler(true)}>
                    접수결과 및 레인
                </Button>
            </td>
            {showApplyModal && (
                <Modal close={() => onShowApplyModalHandler(false)} header="대회 접수">
                    <ApplyTournamentFormApp
                        close={() => onShowApplyModalHandler(false)}
                        contestId={props.ctId}
                        placeId={props.placeId}
                        onApplyModalHandler={onShowApplyModalHandler}
                        region={props.centerRegion}
                        centerName={props.centerName}
                        ctTitle={props.ctTitle}
                        ctAgreementInfo={props.ctAgreementInfo}
                        centerDefaultAddress={props.centerDefaultAddress}
                        centerDetailedAddress={props.centerDetailedAddress}
                        ctAppType={props.ctAppType}
                    />
                </Modal>
            )}
            {showApplyResultAndLane && props.exposeDraw === "N" && (
                <Alert type="alert" confirm="확인" close={() => onShowApplyResultLaneModalHandler(false)}>
                    {"선정여부를 게재하기 전입니다."}
                </Alert>
            )}
            {showApplyResultAndLane && props.exposeDraw === "Y" && (
                <Modal close={() => onShowApplyResultLaneModalHandler(false)} header="접수결과 및 레인">
                    <ReceptionStatusApp
                        contestId={props.ctId}
                        placeId={props.placeId}
                        ctTitle={props.ctTitle}
                        region={props.centerRegion}
                        centerName={props.centerName}
                        completePosition={props.completePosition}
                        exposePosition={props.exposePosition}
                    />
                </Modal>
            )}
            {showWaitingModal && (
                <Modal close={() => onShowWaitingModalHandler(false)} header="접속 대기">
                    <WaitingService close={() => onShowWaitingModalHandler(false)} />
                </Modal>
            )}
            {loginAlert && (
                <Alert type="alert" confirm="확인" close={() => navigateHandler()}>
                    {"로그인이 필요한 서비스입니다."}
                </Alert>
            )}
            {retryLogin && (
                <Alert type="alert" confirm="확인" close={() => navigateHandler()}>
                    {"다시 로그인 해주세요."}
                </Alert>
            )}
            {conditionAlert && (
                <Alert type="alert" confirm="확인" close={() => setConditionAlert(false)}>
                    {conditionText}
                </Alert>
            )}
        </tr>
    );
};

export default ApplyTournamentListApp;
