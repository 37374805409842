import instance from "../../store/AxiosInterceptor";

/**
 * BO - 사용구 시리얼 넘버 등록
 * 전체 조회
 * @param {*} resolveCB
 * @param {*} rejectCB
 */
export function ReqOfficialBallList(resolveCB, rejectCB) {
    instance({
        url: `/api/v1/admin/contest/officialBall?start_reg_date=&end_reg_date=&brand=&ball_name=&serial_number=&file_name=&page=0&size=10`,
        method: "GET",
    })
        .then((response) => {
            // List [ { id, name } ...]
            if (resolveCB) {
                resolveCB(response.data.datas);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/**
 * BO - 사용구 시리얼 넘버 등록
 * 파일명 리스트 가져오기
 * @param {*} resolveCB
 * @param {*} rejectCB
 */
export function ReqFileNameList(resolveCB, rejectCB) {
    instance({
        url: `/api/v1/admin/contest/officialBall/file`,
        method: "GET",
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data.datas);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/**
 * BO - 사용구 시리얼 넘버 등록
 *
 * @param {*} resolveCB
 * @param {*} rejectCB
 */
export function ReqUploadFile(file, resolveCB, rejectCB) {
    instance({
        url: `/api/v1/admin/contest/officialBall/file`,
        method: "POST",
        data: file,
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/**
 * BO - 사용구 시리얼 넘버 등록
 * 페이지 API
 *
 * @param {*} resolveCB
 * @param {*} rejectCB
 */
export function ReqPagingAPI(object, page, resolveCB, rejectCB) {
    instance({
        url: `/api/v1/admin/contest/officialBall?start_reg_date=${object.start_reg_date}&end_reg_date=${object.end_reg_date}&brand=${object.brand}&${object.query_type}=${object.query_value}&file_name=${object.file_name}&page=${page}&size=10`,
        method: "GET",
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data.datas);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/**
 * BO - 사용구 시리얼 넘버 등록
 * 페이지 API
 *
 * @param {*} resolveCB
 * @param {*} rejectCB
 */
export function ReqSearchHandler(object, resolveCB, rejectCB) {
    instance({
        url: `/api/v1/admin/contest/officialBall?start_reg_date=${object.start_reg_date}&end_reg_date=${object.end_reg_date}&brand=${object.brand}&${object.query_type}=${object.query_value}&file_name=${object.file_name}&page=0&size=10`,
        method: "GET",
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data.datas);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/**
 * BO - 사용구 시리얼 넘버 등록
 *
 * @param {*} resolveCB
 * @param {*} rejectCB
 */
export function ReqDeleteBallSerial(serials, resolveCB, rejectCB) {
    instance({
        url: `/api/v1/admin/contest/officialBall/delete`,
        method: "POST",
        data: {
            datas: serials,
        },
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}