import React, { useRef, useMemo } from "react";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

/**
 * https://codesandbox.io/s/react-quill-with-functional-component-68zcnt?file=/src/index.js
 */
const QuillEditorForText = React.forwardRef(({value, width, height, minHeight, onValueChangeCB}, ref) => {
    const quillRef = useRef();

    const modules = useMemo(() => {
        return {
            toolbar: {
                container: [
                    [{ header: [1, 2, 3, 4, 5, 6, false] }],
                    ["bold", "italic", "underline", "strike", "blockquote"],
                ],
            },
        };
    }, []);

    const formats = [
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "link",
        "color",
        "image",
        "background",
        "align",
        "size",
        "font",
    ];

    const handleProcedureContentChange = (content, delta, source, editor) => {
        // setValue(content); 상위 컴포넌트에서 처리하도록 한다.
        if (onValueChangeCB)
            onValueChangeCB(content);
    };

    return (
        <>
            <ReactQuill
                style={{ height: `${height}px`, width: `${width}px`}}
                ref={quillRef}
                theme="snow"
                modules={modules}
                formats={formats}
                value={value}
                onChange={handleProcedureContentChange}
            />
        </>
    );
});

export default QuillEditorForText;
