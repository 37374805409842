import { useState } from "react";
import SelectBoxSearch from "../../components/UI/SelectBox/SelectBoxNew";
import classes from "./Terms.module.css";
function TermsPersonalInformationDetail(props) {
    return (
        <div className={classes.terms_box}>
            <div dangerouslySetInnerHTML={{
                __html: props.contentCB,
                }} >
            </div>
            {/* <p className={classes.info}>
                ㈜진승(이하 “진승”이라고 함)은 별도의 회원가입 절차 없이
                대부분의 컨텐츠에 자유롭게 접근할 수 있습니다. 진승은의 회원제
                서비스를 이용하시고자 할 경우다음의 정보를 입력해주셔야 하며
                선택항목을 입력하시지 않았다 하여 서비스 이용에 제한은 없습니다.
            </p>
            <article>
                <h3>개인정보의 수집 항목 및 수집 방법</h3>
                <div className={classes.box}>
                    <ol>
                        <li>
                            진승은 회원 가입 전에 진승의 개인정보 보호 방침 또는
                            이용 약관의 내용에 대해 회원 가입전에 동의할 수 있는
                            절차를 마련하여 진승의 개인정보 수집에 대해 회원
                            가입을 하는 것은 개인 정보 수집에 대하여 동의하는
                            것으로 간주합니다.
                            <ul>
                                <li>
                                    필수항목 : 이름, 성별, 생년월일, 아이디,
                                    비밀번호, 주소, 전화번호, 휴대전화번호,
                                    이메일
                                </li>
                                <li>
                                    성명, 아이디, 비밀번호, 생년월일 : 회원제
                                    서비스 이용에 따른 본인 식별 절차에 이용
                                </li>
                                <li>
                                    이메일주소, 전화번호, 휴대전화번호 :
                                    고지사항 전달, 본인 의사 확인, 불만 처리 등
                                    원활한 의사소통 경로의 확보, 새로운
                                    서비스/신상품이나 이벤트 정보의 안내
                                </li>
                                <li>
                                    은행계좌정보, 신용카드정보 : 유료정보 이용에
                                    대한 요금 결제
                                </li>
                                <li>
                                    주소, 휴대전화번호 : 청구서, 경품과 쇼핑
                                    물품 배송에 대한 정확한 배송지의 확보
                                </li>
                                <li>
                                    생년월일 : 인구통계학적 분석 자료(이용자의
                                    연령별, 성별, 지역별 통계분석)
                                </li>
                                <li>
                                    그 외 선택항목 : 개인맞춤 서비스를 제공하기
                                    위한 자료
                                </li>
                            </ul>
                        </li>
                        <li>
                            개인정보의 수집 방법
                            <ul>
                                <li>
                                    홈페이지, 서면양식, 팩스, 전화, 이메일,
                                    배송요청
                                </li>
                            </ul>
                        </li>
                        <li>
                            개인정보 수집에 대한 동의
                            <ul>
                                <li>
                                    진승은 회원이 개인정보취급방침 및 이용약관의
                                    내용에 대해 「동의한다」버튼 또는 「동의하지
                                    않는다」버튼을 클릭할 수 있는 절차를
                                    마련하여, 「동의한다」버튼을 클릭하면
                                    개인정보 수집에 대해 동의한 것으로 봅니다.
                                </li>
                            </ul>
                        </li>
                    </ol>
                </div>
            </article>
            <article>
                <h3>개인정보의 보유기간 및 이용시간</h3>
                <div className={classes.box}>
                    <ol>
                        <li>
                            회원의 개인정보는 회사가 신청인에게 서비스를
                            제공하는 기간 동안에 한하여 보유하고 이를
                            활용합니다. 다만 다른 법률에 특별한 규정이 있는
                            경우에는 관계법령에 따라 보관합니다.
                            <ul>
                                <li>
                                    회원가입정보 : 회원가입을 탈퇴하거나 회원에
                                    제명된 때
                                </li>
                                <li>
                                    대금지급정보 : 대금의 완제일 또는
                                    채권소명시효기간이 만료된 때
                                </li>
                                <li>
                                    배송정보 : 물품 또는 서비스가 인도되거나
                                    제공된 때
                                </li>
                                <li>
                                    설문조사, 이벤트 등 일시적 목적을 위하여
                                    수집한 경우 : 당해 설문조사, 이벤트 등이
                                    종료한 때
                                </li>
                            </ul>
                        </li>
                        <li>
                            위 개인정보 수집목적 달성시 즉시파기 원칙에도
                            불구하고 다음과 같이 거래 관련 권리 의무 관계의 확인
                            등을 이유로 일정기간 보유하여야 할 필요가 있을
                            경우에는 전자상거래 등에서의 소비자보호에 관한 법률
                            등에 근거하여 일정기간 보유합니다.
                            <ul>
                                <li>계약 또는 청약철회 등에 관한 기록 : 5년</li>
                                <li>
                                    대금결제 및 재화 등의 공급에 관한 기록 : 5년
                                </li>
                                <li>
                                    소비자의 불만 또는 분쟁처리에 관한 기록 :
                                    3년
                                </li>
                                <li>
                                    설문조사, 이벤트 등 일시적 목적을 위하여
                                    수집한 경우 : 당해 설문조사, 이벤트 등의
                                    종료 시점
                                </li>
                            </ul>
                        </li>
                        <li>
                            회원의 동의를 받아 보유하고 있는 거래정보 등을
                            회원이 열람을 요구하는 경우 진승은 지체 없이 열람,
                            확인 할 수 있도록 조치합니다.
                        </li>
                    </ol>
                </div>
            </article> */}
        </div>
    );
}

export default TermsPersonalInformationDetail;
