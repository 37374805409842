import { useState, useEffect } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import Moment from "moment";
import Button from "../../../components/UI/Button/Button";
import SelectBoxNew from "../../../components/UI/SelectBox/SelectBoxNew";
import PaginationNew from "../../../components/UI/Table/PaginationNew";
import classes from "../MngCommon.module.css";
import Checkbox from "../../../components/UI/Input/Checkbox";
import DateRangePicker from "../../../components/UI/DatePicker/DateRangePicker";
import MngTournamentReceiptModal from "./components/MngTournamentReceiptModal";
import MngTournamentStadiumModal from "./components/MngTournamentStadiumModal";
import { ReqContestType } from "../../../api/common/ConditionSelectBoxApi";
import {
    DefaultAll,
    progressData,
} from "../../../components/definedefault/DefSelectBoxValues";
import {
    ReqContestMainInfoList,
    ReqContestMainInfoSearchList,
    ReqContestMainPlacement,
} from "../../../api/tournament/ApiForContestMain";

function MngTournamentDetailPage(props) {

    const location = useLocation();
    const navigate = useNavigate();

    const createHandler = () => {
        navigate("./create");
    };

    // <<< 대회 타입 가져오기(Select Box) */
    const [contestTypeData, setContestTypeData] = useState([DefaultAll]);
    const [type, setType] = useState("");

    // let tournamentName = [];
    // if (mains.length > 0) {
    //     tournamentName = mains.map((data) => ({
    //         value: data.name ? data.name : "전체",
    //         key: data.name ? data.name : null,
    //         disabled: false,
    //     }));
    // }

    // tournamentName.unshift({
    //   value: "전체",
    //   key: null,
    //   disabled: false,
    // });

    const [text, setText] = useState([]);
    const [tournament, setTournament] = useState("");
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [searchStartDate, setSearchStartDate] = useState("");
    const [searchEndDate, setSearchEndDate] = useState("");

    const handleStartDateChange = (date) => {
        setStartDate(date);
        setSearchStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
        setSearchEndDate(date);
    };

    // 대회 유형 select box
    const titleHandler = (key) => {
        setTournament(key);
    };

    // 대회 유형 select box
    const typeHandler = (key) => {
        setType(key);
    };

    // 모달창
    const [isModalOpen3, setIsModalOpen3] = useState(false);
    const [isModalOpen5, setIsModalOpen5] = useState(false);

    const [title, setTitle] = useState();
    const [id, setId] = useState();

    const openModalHandler3 = (e, id, title) => {
        e.stopPropagation();
        setIsModalOpen3(true);
        setTitle(title);
        setId(id);
    };
    const openModalHandler5 = (e, id, title) => {
        e.stopPropagation();
        setIsModalOpen5(true);
        setTitle(title);
        setId(id);
    };

    const contestTypeMouseOverHandler = (key) => {
        if (contestTypeData.length === 1) {
            ReqContestType(
                (result) => {
                    const contestTypeDatas = result.map((data) => {
                        return { key: data.type, value: data.name };
                    });
                    setContestTypeData((prev) => [
                        DefaultAll,
                        ...contestTypeDatas,
                    ]);
                },
                (excption_result) => {
                    setContestTypeData((prev) => [DefaultAll]);
                }
            );
        }
    };

    // const setProcessTypeCheck = (processType) => {
    //   const process_type = progressData.find((items) => items.key === processType)
    //   return process_type === undefined ? "" : process_type.value;
    // };

    // Pagination state
    const [currentPage, setCurrentPage] = useState(location.state?.currentPage ?? 0);
    const [totalPage, setTotalPage] = useState(0); //총 페이지 갯수
    const [totalCount, setTotalCount] = useState(0);

    const viewAll = async () => {
        setCurrentPage(0);
        setType("");
        setStartDate(new Date());
        setEndDate(new Date());
        setSearchStartDate("");
        setSearchEndDate("");
        const response = await ReqContestMainInfoList(0);
        if (response.status === 200) {
            const resData = await response.data.datas;
            setTotalPage(resData.totalPages);
            setTotalCount(resData.totalElements);
            setText(resData.content);
        } else {
            setText([]);
        }
    };

    const handlePageChange = async (page) => {
        setCurrentPage(page);

        const searchParam = {
            type: type === "" ? null : type,
            sDate:
                searchStartDate === ""
                    ? null
                    : Moment(searchStartDate).format("YYYYMMDD"),
            eDate:
                searchEndDate === ""
                    ? null
                    : Moment(searchEndDate).format("YYYYMMDD"),
            page: page,
            //title: tournament === "" ? null : tournament,
        };

        const response = await ReqContestMainInfoSearchList(searchParam);
        if (response.status === 200) {
            const resData = await response.data.datas;
            setTotalPage(resData.totalPages);
            setTotalCount(resData.totalElements);
            setText(resData.content);
        } else {
            setText([]);
        }
    };
    useEffect(() => {
        handlePageChange(currentPage)
    }, [])
    // 검색 버튼 클릭
    const search = async () => {
        setCurrentPage(0);

        const searchParam = {
            type: type === "" ? null : type,
            sDate:
                searchStartDate === ""
                    ? null
                    : Moment(searchStartDate).format("YYYYMMDD"),
            eDate:
                searchEndDate === ""
                    ? null
                    : Moment(searchEndDate).format("YYYYMMDD"),
            page: 0,
            //title: tournament === "" ? null : tournament,
        };

        const response = await ReqContestMainInfoSearchList(searchParam); // Use loader with pagination parameters
        if (response.status === 200) {
            const resData = await response.data.datas;
            setTotalPage(resData.totalPages);
            setTotalCount(resData.totalElements);
            setText(resData.content);
        } else {
            setText([]);
        }
    };

    const [checkedIds, setCheckedIds] = useState([]);

    const checkboxChangeHandler = (e) => {
        const id = e.target.value;
        // 다중체크인경우
        if (checkedIds.includes(id)) {
            setCheckedIds(checkedIds.filter((checkedId) => checkedId !== id));
        } else {
            setCheckedIds([...checkedIds, id]);
        }
    };

    const handleButtonClick = async () => {
        if (checkedIds.length === 0) {
            alert("자리배치를 선택해주세요.");
        }

        if (checkedIds.length > 1) {
            alert("자리배치는 여러번이 불가능합니다.");
        }

        const searchParam = {
            contest_id: checkedIds[0],
        };

        const response = await ReqContestMainPlacement(searchParam); // Use loader with pagination parameters
        if (response.status === 200) {
            const resData = await response.data.datas;
            // console.log(resData);
            alert("자리배치가 완료되었습니다.");
        } else {
            alert(
                "자리배치가 실패하였습니다. 확인 후 다시실행해주시기 바랍니다."
            );
        }
    };

    const mainList = () => {
        return (
            <>
                {text.length !== 0 &&
                    text.map((data, index) => (
                        <tr key={data.id}>
                            <td>{totalCount - (10 * currentPage + index)}</td>
                            <td>{data.id}</td>
                            <td>
                                <NavLink to={`${data.id}`} state={{currentPage}}>{data.name}</NavLink>
                            </td>
                            <td>{data.regionNames}</td>
                            <td>
                                {Moment(data.start_date).format("YYYY-MM-DD")} ~{" "}
                                {Moment(data.end_date).format("YYYY-MM-DD")}
                            </td>
                            <td>
                                {/* 대회 상태 및 체크박스 값 설정 */}
                                {data.process_type_value}
                            </td>
                            <td>
                                {data.process_type === "DRAW" && (
                                    <Checkbox
                                        className="admin_st"
                                        id={`chk_${data.id}`}
                                        value={data.id}
                                        onChange={checkboxChangeHandler}
                                    />
                                )}
                            </td>
                            <td>
                                {Moment(data.reg_date).format("YYYY-MM-DD")}
                            </td>
                            <td>
                                <Button
                                    className="xxxs border"
                                    onClick={(e) =>
                                        openModalHandler3(e, data.id, data.name)
                                    }
                                >
                                    관리
                                </Button>
                            </td>
                            <td>
                                <Button
                                    className="xxxs border"
                                    onClick={(e) =>
                                        openModalHandler5(e, data.id, data.name)
                                    }
                                >
                                    등록
                                </Button>
                            </td>
                        </tr>
                    ))}
            </>
        );
    };

    return (
        <section>
            <div className={classes.admin_contents}>
                <h3>대회등록</h3>
                <div
                    className={`${classes.contents_wrap} ${classes.search_wrap}`}
                >
                    <div className={classes.admin_table_wrap}>
                        <table>
                            <colgroup>
                                <col style={{ width: "11%" }} />
                                <col style={{ width: "89%" }} />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>등록일</th>
                                    <td>
                                        <DateRangePicker
                                            startDate={startDate}
                                            endDate={endDate}
                                            onStartDateChange={
                                                handleStartDateChange
                                            }
                                            onEndDateChange={
                                                handleEndDateChange
                                            }
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>대회조회</th>
                                    <td>
                                        <div className={classes.flex_row}>
                                            <SelectBoxNew
                                                className="admin_st"
                                                setSelectItemCB={typeHandler}
                                                optionDatas={contestTypeData}
                                                showDefaultKey={type}
                                                mouseOverCB={
                                                    contestTypeMouseOverHandler
                                                }
                                            />
                                            {/* <SelectBoxNew
                          className="admin_st"
                          setSelectItemCB={titleHandler}
                          optionDatas={tournamentName}
                          showDefaultKey={tournament}
                        /> */}
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className={classes.bottom_btn_wrap}>
                            <Button className="border sm" onClick={search}>
                                검색
                            </Button>
                            <Button className="secondary sm" onClick={viewAll}>
                                전체보기
                            </Button>
                        </div>
                    </div>
                </div>
                <div className={classes.contents_wrap}>
                    <div
                        className={`${classes.admin_table_wrap} ${classes.align_center} ${classes.left_col_3} `}
                    >
                        <p className={classes.counter}>
                            총 <b>{totalCount}</b>건
                        </p>
                        <table>
                            <colgroup>
                                <col style={{ width: "5%" }} />
                                <col style={{ width: "5%" }} />
                                <col style={{ width: "22%" }} />
                                <col style={{ width: "20%" }} />
                                <col style={{ width: "14%" }} />
                                <col style={{ width: "6.5%" }} />
                                <col style={{ width: "5%" }} />
                                <col style={{ width: "10%" }} />
                                <col style={{ width: "6%" }} />
                                <col style={{ width: "6%" }} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th>대회코드</th>
                                    <th>대회명</th>
                                    <th>지역</th>
                                    <th>대회기간</th>
                                    <th>진행상태</th>
                                    <th>자리배치</th>
                                    <th>등록일</th>
                                    <th>접수제한</th>
                                    <th>경기장등록</th>
                                </tr>
                            </thead>
                            <tbody>{mainList()}</tbody>
                        </table>
                        <PaginationNew
                            className="admin_st"
                            totalPage={totalPage}
                            currentPage={currentPage}
                            defaultShowPage={5}
                            changePageCB={handlePageChange}
                        />
                        <div
                            className={`${classes.bottom_btn_wrap} ${classes.position_st}`}
                        >
                            <Button
                                className="border sm"
                                onClick={handleButtonClick}
                            >
                                자리배치
                            </Button>
                            <Button
                                className="secondary sm"
                                onClick={createHandler}
                            >
                                대회등록
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            {isModalOpen3 && (
                <MngTournamentReceiptModal
                    isModalOpen3={setIsModalOpen3}
                    title={title}
                    id={id}
                />
            )}
            {isModalOpen5 && (
                <MngTournamentStadiumModal
                    isModalOpen5={setIsModalOpen5}
                    title={title}
                    id={id}
                />
            )}
        </section>
    );
}

export default MngTournamentDetailPage;

//>>>대회등록 리스트 조회 loader
export const loader = async () => {
    const response = await ReqContestMainInfoList(0);

    if (response.status === 200) {
        const resData = await response.data.datas;
        const curData = resData.content.map((data) => {
            return {
                id: data.id,
                name: data.name,
                number: data.number,
                type: data.type,
                start_date: data.start_date,
                end_date: data.end_date,
                process_type: data.process_type,
                process_type_value: data.process_type_value,
                apply_type: data.apply_type,
                apply_start_time: data.apply_start_time,
                apply_end_time: data.apply_end_time,
                entry_fee: data.entry_fee,
                payment_type: data.payment_type,
                deposit_start: data.deposit_start,
                deposit_1st_end: data.deposit_1st_end,
                deposit_2nd_end: data.deposit_2nd_end,
                deposit_3th_end: data.deposit_3th_end,
                auto_succession_yn: data.auto_succession_yn,
                expose_yn: data.expose_yn,
                cancel_apply_date: data.cancel_apply_date,
                modify_apply_info: data.modify_apply_info,
                cancel_apply_yn: data.cancel_apply_yn,
                description: data.description,
                agreement_info: data.agreement_info,
                odd_lane_limit: data.odd_lane_limit,
                even_lane_limit: data.even_lane_limit,
                wait_limit: data.wait_limit,
                handicap_id: data.handicap_id,
                handicap_title: data.handicap_title,
                group_start: data.group_start,
                group_end: data.group_end,
                group_names: data.group_names,
                bg_img_uri: data.bg_img_uri,
                bg_img_name: data.bg_img_name,
                prevent_duplicates: data.prevent_duplicates,
                contest_researchs: data.contest_researchs,
                reg_date: data.reg_date,
                regionNames: data.regionNames,
            };
        });
        return {
            data: curData,
            totalPage: resData.totalPages,
            totalCount: resData.totalElements,
        };
    } else {
        //console.log(resData);
        return { data: [], totalPage: 0, totalCount: 0 };
    }
};
//<<대회등록 리스트 조회
