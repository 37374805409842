import classes from "./BreadCrumb.module.css";
function BreadCrumb({ breadCrumbList }) {
    //  sample data
    //  const breadCrumbList = ["케겔", "레인정비기계"];
    return (
        <div className={classes.breadcrumb}>
            <div className={classes.inner}>
                <p className={classes.home}>홈</p>
                <ul>
                    {breadCrumbList.map((item, index) => (
                        <li key={index}>{item}</li>
                    ))}
                </ul>
            </div>
        </div>
    );
}

export default BreadCrumb;
