import React, { useState } from "react";

/**
 * 대회 > 대회상세 > 접수결과 및 레인 > 접수결과 상세 : 데이터 표출
 * @param {} props 
 * @returns 
 */
const ApplyTournamentReceptionStatus = (props) => {

    function maskString(str) {
        if (str.length <= 2) {
            return str.substring(0, 1) + "*"; // 첫 번째 글자 + '*'
        }

        const firstChar = str.charAt(0);
        const lastChar = str.charAt(str.length - 1);
        const middleMask = "*".repeat(str.length - 2);

        return firstChar + middleMask + lastChar;
    }

    const originalName = props.memberName; // 원래의 이름
    const maskedName = maskString(originalName); // 가려진 이름
    const newMemberId = props.memberId.substring(0, 3) + "*".repeat(props.memberId.length - 3);
    let newStatus = "";

    switch (props.applyStatus) {
        case "applied":
            newStatus = "접수완료";
            break;
        case "waiting":
            newStatus = "대기";
            break;
        case "chosen":
        case "chosen_1":
        case "chosen_9":
        case "complete":
            newStatus = "선정";
            break;
        case "not_chosen":
            newStatus = "비선정";
            break;            
        case "cancel":
            newStatus = "취소";
            break;
    }

    return (
        <tr>
            <td>{props.index+1}</td>
            <td>
                {maskedName}({newMemberId})
            </td>
            <td>{props.gender === "M" ? "남" : "여"}</td>
            <td>{props.handicap}</td>
            <td>{newStatus}</td>
        </tr>
    );
};

export default ApplyTournamentReceptionStatus;
