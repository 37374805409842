import _ from "lodash";
import instance from "../../store/AxiosInterceptor";

export async function ProductCategory() {
    try {
        const response = await instance({
            method: "GET",
            url: `/api/v1/admin/category/PRODUCT/all`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        });
        return response;
    } catch (error) {
        return { error };
    }
}

export async function ProductCategoryDetail(categoryId = 1) {
    try {
        const response = await instance.get(`/api/v1/admin/category/PRODUCT/${categoryId}`);
        return response;
    } catch (error) {
        return { error };
    }
}

export async function ProductCategoryAdd({ body, image }) {
    try {
        const formData = new FormData();
        formData.append("body", new Blob([JSON.stringify(body)], { type: "application/json" }));
        if (image) {
            formData.append("image", image);
        }
        const response = await instance.post("/api/v1/admin/category/PRODUCT", formData);
        return response;
    } catch (error) {
        return { error };
    }
}

export async function ProductCategoryEdit({ id, body, image }) {
    try {
        if (_.isNaN(Number(id))) throw new Error(`${id} is not number`);
        const formData = new FormData();
        formData.append("body", new Blob([JSON.stringify(body)], { type: "application/json" }));
        if (image) {
            formData.append("image", image);
        }
        const response = await instance.put(`/api/v1/admin/category/PRODUCT/${id}`, formData);
        return response;
    } catch (error) {
        return { error };
    }
}

export async function ProductCategoryDelete(id) {
    try {
        if (_.isNaN(Number(id))) throw new Error(`${id} is not number`);
        const url = `/api/v1/admin/category/PRODUCT/${id}`;
        const response = await instance.delete(url);
        console.log({ response });
        return response;
    } catch (error) {
        console.error(error);
        return { error };
    }
}

export async function ProductSelectedCategory(id) {
    try {
        if (_.isNaN(Number(id))) throw new Error(`${id} is not number`);
        const url = `/api/v1/admin/product/select/${id}`;
        const response = await instance.get(url);
        return response;
    } catch (error) {
        return { error };
    }
}

// 스마트필터 가져오기
export async function ProductSmartFilter(id) {
    try {
        const url = `/api/v1/admin/smartfilter/product/${id}`;
        const response = await instance.get(url);
        return response;
    } catch (error) {
        return { error };
    }
}

export function ReqUpdateCategorySeq(updateData, resolveCB, rejectCB) {
    instance({
        url: `/api/v1/admin/category/update/sequence`,
        method: "PUT",
        data: {
            datas: updateData,
        },
    })
        .then((response) => {
            // List [ { id, name } ...]
            if (resolveCB) {
                resolveCB(response.data);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}
