import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import "react-datepicker/dist/react-datepicker.css";
import classes from "./DatePicker.module.css";

// 사용 한 라이브러리
// https://reactdatepicker.com/#example-year-picker
// https://www.npmjs.com/package/react-datepicker

function DateTimePicker(props) {
  const [startDate, setStartDate] = useState(new Date());
  return (
    <div className={`${classes.date_picker_box} ${classes[props.className]}`}>
      <DatePicker
        selected={props.value === undefined ? new Date() : props.value}
        onChange={props.onChange}
        timeInputLabel="Time:"
        dateFormat="yyyy-MM-dd aa h:mm"
        locale={ko}
        showTimeInput
      />
    </div>
  );
}
export default DateTimePicker;
