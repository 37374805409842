import { useEffect, useState } from "react";
import Button from "../../../../components/UI/Button/Button";
import classes from "../../MngCommon.module.css";
import { useNavigate, useLoaderData, useLocation } from "react-router-dom";
import instance from "../../../../store/AxiosInterceptor";
import MngUserWithTournamentDetailList from "./MngUserWithTournamentDetailList";

function MngUserWithTournamentDetail(props) {

    const location = useLocation();
    const navigate = useNavigate();
    const resData = useLoaderData();

    const backHandler = () => {
        navigate("/admin/users/withTournament", { state: location.state });
    };
    useEffect(() => {
        window.addEventListener('popstate', backHandler);
        return () => { window.removeEventListener('popstate', backHandler) };
    }, [])
    return (
        <section>
            <div className={classes.admin_contents}>
                <h3>대회접수이력 - 상세</h3>
                <div className={`${classes.contents_wrap} `}>
                    <h4>{resData?.member_name}님 대회접수이력</h4>
                    <div className={`${classes.admin_table_wrap} ${classes.align_center} ${classes.row_select}`}>
                        <table>
                            <colgroup>
                                <col style={{ width: "40%" }} />
                                <col style={{ width: "10%" }} />
                                <col style={{ width: "20%" }} />
                                <col style={{ width: "10%" }} />
                                <col style={{ width: "20%" }} />
                                <col style={{ width: "10%" }} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>대회명</th>
                                    <th>지역</th>
                                    <th>경기장</th>
                                    <th>조</th>
                                    <th>대회기간</th>
                                    <th>
                                        고객상태
                                        <br />
                                        결제상태
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {resData.datas &&
                                    resData.datas.map((data) => <MngUserWithTournamentDetailList info={data}/>)}
                                {!resData.datas &&
                                    <div></div>}
                            </tbody>
                        </table>
                        <div className={classes.bottom_btn_wrap}>
                            <Button className="secondary sm" onClick={backHandler}>
                                목록
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default MngUserWithTournamentDetail;

//해당 회원의 참가 목록 가져오기
export const loader = async ({ params }) => {
    const response = await instance({
        method: "get",
        url: `/api/v1/admin/contest/competitor/contestList/${params.memberId}`,
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    }).catch(function (error) {
        if (error.response) {
            // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            return error.response;
        } else if (error.request) {
            // 요청이 이루어 졌으나 응답을 받지 못했습니다.
            console.log(error.request);
            return null;
        } else {
            // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
            console.log("Error", error.message);
            return null;
        }
    });
    if (response == null || response.status !== 200) {
        return [];
    }
    if (response.data.status === 200) {
        return response.data;
    } else {
        return [];
    }
};

//해당 회원의 참가 목록 가져오기
