import classes from "./MyPage.module.css";
import Button from "../../components/UI/Button/Button";
import Input from "../../components/UI/Input/Input";
import Pagination from "../../components/UI/Table/Pagination";
import { JwtTokenContext } from "../../store/JwtTokenProvider";
import { useContext, useEffect } from "react";
import { useMemStore } from "../../store/memstore/MemStore";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import PaginationNew from "../../components/UI/Table/PaginationNew";
import instance from "../../store/AxiosInterceptor";
import { ReqFindMyEvent, ReqPagingFindMyEvent } from "../../api/mypage/MyPageEventApi";

function MyEventHistory() {
    const setMemStore = useMemStore(false)[1];
    const loginCtx = useContext(JwtTokenContext);
    const token = loginCtx.getToken();
    const location = useLocation();
    const navigate = useNavigate();

    const [queryText, setQueryText] = useState(null);
    const changeQueryTextHandler = (key) => {
        setQueryText(key);
    };

    const [eventList, setEventList] = useState([]);

    const [totalPages, setTotalPages] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [pageNumber, setPageNumber] = useState(location.state?.pageNumber ?? 0);

    const navigateHandler = (value) => {
        navigate(value, { pageNumber });
    };

    const searchHandler = () => {
        setPageNumber(0);
        ReqFindMyEvent(
            queryText,
            (result) => {
                if (result.content) {
                    const curData = result.content.map((data) => {
                        return {
                            id: data.apply_id,
                            title: data.event_title,
                            applyDate: data.apply_date,
                            startDate: data.event_start_date,
                            endDate: data.event_end_date,
                            exposeChosenYn: data.expose_chosen_yn,
                            chosenYn: data.chosen_yn,
                            seq: data.seq,
                            eventId: data.event_id,
                        };
                    });

                    setEventList(curData);
                    setTotalCount(result.totalElements);
                    setPageNumber(result.pageable.pageNumber);
                    setTotalPages(result.totalPages);
                }
            },
            (error_result) => {
                setEventList((prev) => prev);
                setTotalCount((prev) => prev);
                setPageNumber((prev) => prev);
                setTotalPages((prev) => prev);
            }
        );
    };

    const pagingHandler = (value) => {
        setPageNumber(value);
        ReqPagingFindMyEvent(
            queryText,
            value,
            (result) => {
                if (result.content) {
                    const curData = result.content.map((data) => {
                        return {
                            id: data.apply_id,
                            title: data.event_title,
                            applyDate: data.apply_date,
                            startDate: data.event_start_date,
                            endDate: data.event_end_date,
                            exposeChosenYn: data.expose_chosen_yn,
                            chosenYn: data.chosen_yn,
                            seq: data.seq,
                            eventId: data.event_id,
                        };
                    });

                    setEventList(curData);
                    setTotalCount(result.totalElements);
                    setPageNumber(result.pageable.pageNumber);
                    setTotalPages(result.totalPages);
                }
            },
            (error_result) => {
                setEventList((prev) => prev);
                setTotalCount((prev) => prev);
                setPageNumber((prev) => prev);
                setTotalPages((prev) => prev);
            }
        );
    };
    useEffect(() => {
        pagingHandler(pageNumber);
    }, [])


    useEffect(() => {
        if (token == null) {
            setMemStore("LOGIN_MOVE_PAGE", { uri: "/myPage/event" });
            navigate("/login");
        }
    }, []);

    return (
        <div className={classes.contents_wrap}>
            <div className={classes.contents_title}>
                <h3>이벤트 접수내역</h3>
                <div className={classes.search_wrap}>
                    <Input onChange={changeQueryTextHandler} value={queryText} />
                    <Button className="search_sm" onClick={searchHandler} />
                </div>
            </div>
            {eventList.length === 0 && (
                <div className={`${classes.empty_data} ${classes.event_st}`}>
                    <p>이벤트 접수내역이 없습니다.</p>
                    <Button className="secondary" onClick={() => navigateHandler("/myPage/event")}>
                        이벤트 접수내역 보기
                    </Button>
                </div>
            )}
            {eventList.length > 0 && (
                <>
                    <div>
                        <table className="align_center align_left_col2 ">
                            <colgroup>
                                <col style={{ width: "7%" }} />
                                <col style={{ width: "47%" }} />
                                <col style={{ width: "22%" }} />
                                <col style={{ width: "12%" }} />
                                <col style={{ width: "12%" }} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>번호</th>
                                    <th>이벤트명</th>
                                    <th>이벤트 기간</th>
                                    <th>접수일자</th>
                                    <th>결과</th>
                                </tr>
                            </thead>
                            <tbody>
                                {eventList.length > 0 &&
                                    eventList.map((data, index) => (
                                        <tr key={index} onClick={()=>navigateHandler(`/event/ongoing/${data.eventId}`)}>
                                            <td>{data.seq}</td>
                                            <td>{data.title}</td>
                                            <td>
                                                {data.startDate} ~ {data.endDate}
                                            </td>
                                            <td>{data.applyDate}</td>
                                            {data.exposeChosenYn === "N" && <td>발표예정</td>}
                                            {data.exposeChosenYn === "Y" && data.chosenYn === "Y" && (
                                                <td className="red_c">당첨</td>
                                            )}
                                            {data.exposeChosenYn === "Y" && data.chosenYn === "N" && (
                                                <td className="gray_c">미당첨</td>
                                            )}
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                    <PaginationNew
                        totalPage={totalPages}
                        currentPage={pageNumber}
                        changePageCB={pagingHandler}
                        defaultShowPage={5}
                    />
                </>
            )}
        </div>
    );
}
export default MyEventHistory;

//이벤트 목록 리스트 가져오기
export const loader = async () => {
    const response = await instance({
        method: "GET",
        url: `/api/v1/home/mypage/event?page=0&size=10`,
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    }).catch(function (error) {
        if (error.response) {
            return error.response;
        } else if (error.request) {
            return null;
        } else {
            return null;
        }
    });
    if (response == null || response.status !== 200) {
        return [];
    }
    if (response.data.status === 200) {
        return response.data.datas;
    } else {
        return [];
    }
};
//이벤트 목록 리스트 가져오기
