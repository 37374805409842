import classes from "./EventApp.module.css";
import { useState } from "react";
import Button from "../../components/UI/Button/Button";
import instance from "../../store/AxiosInterceptor";
import { ReqFindAllEventList, ReqPageingEventList } from "../../api/event/EventHomeApi";
import { useLoaderData, useNavigate } from "react-router-dom";
import { useEffect } from "react";
function EventAppPage() {
    const resData = useLoaderData();
    const navigate = useNavigate();

    //  breadCrumb sample data
    const breadCrumbList = ["이벤트", "진행중인 이벤트"];

    const [eventList, setEventList] = useState([]);
    const [type, setType] = useState("asc");

    const [totalPages, setTotalPages] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [pageNumber, setPageNumber] = useState(0);

    // filter toggle
    const [filterToggle, setFilterToggle] = useState(0);
    const filterToggleHandler = (id, type) => {
        setFilterToggle(id);
        setType(type);
    };

    const navigateHandler = (key) => {
        navigate(key);
    };

    const pagingHandler = () => {
        setPageNumber((prev) => prev + 1);
        ReqPageingEventList(
            type,
            pageNumber + 1,
            (result) => {
                const curData = result.content.map((data) => {
                    return {
                        id: data.event_id,
                        title: data.event_title,
                        regDate: data.event_reg_date,
                        startDate: data.event_start_date,
                        endDate: data.event_end_date,
                        fileUri: data.file_uri,
                    };
                });
                setEventList(prev=>[...prev, ...curData]);
                setTotalCount(result.totalElements);
                setPageNumber(result.pageable.pageNumber);
                setTotalPages(result.totalPages);
            },
            (error_result) => {
                setEventList((prev) => prev);
            }
        );
    };

    useEffect(() => {
        ReqFindAllEventList(
            type,
            (result) => {
                const curData = result.content.map((data) => {
                    return {
                        id: data.event_id,
                        title: data.event_title,
                        regDate: data.event_reg_date,
                        startDate: data.event_start_date,
                        endDate: data.event_end_date,
                        fileUri: data.file_uri,
                    };
                });
                setEventList(curData);
                setTotalCount(result.totalElements);
                setPageNumber(result.pageable.pageNumber);
                setTotalPages(result.totalPages);
            },
            (error_result) => {
                setEventList((prev) => prev);
            }
        );
    }, [type]);

    useEffect(() => {
        if (resData.content) {
            const curData = resData.content.map((data) => {
                return {
                    id: data.event_id,
                    title: data.event_title,
                    regDate: data.event_reg_date,
                    startDate: data.event_start_date,
                    endDate: data.event_end_date,
                    fileUri: data.file_uri,
                };
            });

            setEventList(curData);
            setTotalCount(resData.totalElements);
            setPageNumber(resData.pageable.pageNumber);
            setTotalPages(resData.totalPages);
        }
    }, [resData]);

    return (
        <>
            <div className={classes.contents_wrap}>
                <div className={classes.contents_title}>
                    <ul className={classes.filter}>
                        <li
                            className={filterToggle === 0 ? classes.on : null}
                            onClick={() => filterToggleHandler(0, "asc")}
                        >
                            최신순
                        </li>
                        <li
                            className={filterToggle === 1 ? classes.on : null}
                            onClick={() => filterToggleHandler(1, "desc")}
                        >
                            마감임박순
                        </li>
                    </ul>
                </div>
                <div className={classes.event_board}>
                    {eventList.length === 0 && (
                        <div className={classes.empty_data}>
                            <p>진행중인 이벤트가 없습니다.</p>
                        </div>
                    )}
                    {eventList.length > 0 &&
                        eventList.map((data, index) => (
                            <div className={classes.board} key={index} onClick={() => navigateHandler(`${data.id}`)}>
                                <div className={classes.img_box}>
                                    <img src={data.fileUri} alt={data.title} />
                                </div>
                                <h4>{data.title}</h4>
                                <p>
                                    {data.startDate} ~ {data.endDate}
                                </p>
                            </div>
                        ))}
                </div>
                <div className={classes.bottom_btn_wrap}>
                    {eventList.length > 10 && totalPages > pageNumber + 1 && (
                        <Button className="text_icon" onClick={pagingHandler}>
                            더보기
                        </Button>
                    )}
                </div>
            </div>
        </>
    );
}
export default EventAppPage;

//이벤트 목록 리스트 가져오기
export const loader = async () => {
    const response = await instance({
        method: "GET",
        url: `/api/v1/home/event?type=asc&page=0&size=10`,
    }).catch(function (error) {
        if (error.response) {
            return error.response;
        } else if (error.request) {
            return null;
        } else {
            return null;
        }
    });
    if (response == null || response.status !== 200) {
        return [];
    }
    if (response.data.status === 200) {
        return response.data.datas;
    } else {
        return [];
    }
};
//이벤트 목록 리스트 가져오기
