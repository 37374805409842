import { useState } from "react";
import Button from "../../../../components/UI/Button/Button";
import { CheckboxA } from "../../../../components/UI/Input/Checkbox";
import Input, { MultiInput } from "../../../../components/UI/Input/Input";
import classes from "../../MngCommon.module.css";
import { adminStatusType, defaultEmailHostList } from "../../../../components/definedefault/DefSelectBoxValues";
import SelectBoxNew from "../../../../components/UI/SelectBox/SelectBoxNew";
import { useNavigate } from "react-router-dom";
import Alert from "../../../../components/UI/Modal/Alert";
import { ReqCheckDuplicateAdminId, ReqCreateAdmin } from "../../../../api/member/AdminMemberApi";

function MngAdminAccountCreate() {
    const navigate = useNavigate();
    // 대회 운영 / 대회등록
    const [ctMain, setCtMain] = useState(false);
    const [ctMain_1, setCtMain_1] = useState(false);

    // 대회 운영 / 경기장 등록
    const [ctPlace, setCtPlace] = useState(false);
    const [ctPlace_1, setCtPlace_1] = useState(false);

    // 대회 운영 / 볼링장 등록
    const [ctCenter, setCtCenter] = useState(false);
    const [ctCenter_1, setCtCenter_1] = useState(false);

    // 대회 운영 / 게재 관리
    const [ctMngExpose, setCtMngExpose] = useState(false);
    const [ctMngExpose_1, setCtMngExpose_1] = useState(false);

    // 대회 운영 / 접수/참가관리
    const [ctMngApplier, setCtMngApplier] = useState(false);
    const [ctMngApplier_1, setCtMngApplier_1] = useState(false);

    // 대회 운영 / 문자발송 관리
    const [ctConfigSms, setCtConfigSms] = useState(false);
    const [ctConfigSms_1, setCtConfigSms_1] = useState(false);

    // 상품관리
    const [product, setProduct] = useState(false);

    // 케겔관리
    const [kegel, setKegel] = useState(false);

    // 커뮤니티
    const [community, setCommunity] = useState(false);

    // 회사소개
    const [intro, setIntro] = useState(false);

    // 이벤트
    const [event, setEvent] = useState(false);

    // 회원관리
    const [member, setMember] = useState(false);

    // 웹사이트관리
    const [website, setWebsite] = useState(false);

    // 통계
    const [statistics, setStatistics] = useState(false);

    const emailHostList = defaultEmailHostList;
    const [registerEmailHost, setRegisterEmailHost] = useState("");
    const emailHostHandler = (key) => {
        setRegisterEmailHost(key);
    };

    const [adminStatus, setAdminStatus] = useState("");
    const adminStatusHandler = (value) => {
        setAdminStatus(value);
    };

    // 아이디 중복확인
    const [memberIdIsValid, setMemberIdIsValid] = useState("");

    const adminIdDuplicateHandler = () => {
        if (adminMemberId.trim() === "") {
            alert("아이디를 입력해주세요.");
            return;
        }
        if (!memberIdRegexCheck) {
            alert("회원 아이디 양식에 적합하지 않습니다.");
            return;
        }
        ReqCheckDuplicateAdminId(
            adminMemberId,
            (result) => {
                if (result === 204) {
                    setMemberIdIsValid("Y");
                } else if (result === 200) {
                    setMemberIdIsValid("N");
                } else {
                    alert("서버 에러");
                }
            },
            (error_result) => {
                alert("서버 에러");
                setMemberIdIsValid("");
            }
        );
    };
    const [inputs, setInputs] = useState({
        adminMemberId: "",
        password: "",
        passwordCheck: "",
        adminName: "",
        assignedTask: "",
        phoneNum1: "",
        phoneNum2: "",
        phoneNum3: "",
        firstEmail: "",
    });

    const {
        adminMemberId,
        password,
        passwordCheck,
        adminName,
        assignedTask,
        phoneNum1,
        phoneNum2,
        phoneNum3,
        firstEmail,
    } = inputs; // 비구조화 할당

    function onChange(e) {
        const { value, name } = e.target;
        setInputs({
            ...inputs,
            [name]: value,
        });
    }

    //영소문자 포함하여 6~20자 이내 입력
    const memberIdRegex = /^(?=.*[a-zA-Z\d])(?=.*)[A-Za-z\d!@#$%^&*]{6,20}$/;
    const memberIdRegexCheck = memberIdRegex.test(adminMemberId);

    // 6~20자 영문, 숫자로만 공백 없이 입력하세요.
    const passwordRegex = /^[a-zA-Z0-9]{6,20}$/;
    const passwordRegexCheck = passwordRegex.test(password);

    const toList = () => {
        navigate("/admin/users/adminAccount");
    };

    const [bowlingCenter, setBowlingCenter] = useState(false);
    const [bowlingCenterModal, setBowlingCenterModal] = useState(false);
    const [successModal, setSuccessModal] = useState(false);

    const updateObject = {
        // 체크박스 상태값 시작
        ct_main: !ctMain && !ctMain_1 ? 0 : ctMain ? 2 : 1,
        ct_place: !ctPlace && !ctPlace_1 ? 0 : ctPlace ? 2 : 1,
        ct_center: !ctCenter && !ctCenter_1 ? 0 : ctCenter ? 2 : 1,
        ct_mng_expose: !ctMngExpose && !ctMngExpose_1 ? 0 : ctMngExpose ? 2 : 1,
        ct_mng_applier: !ctMngApplier && !ctMngApplier_1 ? 0 : ctMngApplier ? 2 : 1,
        ct_config_sms: !ctConfigSms && !ctConfigSms_1 ? 0 : ctConfigSms ? 2 : 1,
        product: product ? 2 : 0,
        kegel: kegel ? 2 : 0,
        community: community ? 2 : 0,
        intro: intro ? 2 : 0,
        event: event ? 2 : 0,
        member: member ? 2 : 0,
        website: website ? 2 : 0,
        statistics: statistics ? 2 : 0,
        bowling_center: bowlingCenter ? 1 : 0,
        // 체크박스 상태값 끝
        adminMemberId: adminMemberId,
        password: password,
        admin_name: adminName,
        assignedTask: assignedTask,
        phone: `${String(phoneNum1)}${String(phoneNum2)}${String(phoneNum3)}`,
        email: `${firstEmail}@${registerEmailHost}`,
        expired: adminStatus,
    };

    const confirmHandler = () => {
        if (memberIdIsValid === "N" || memberIdIsValid === "") {
            alert("아이디 중복확인을 해주세요.");
            return;
        }

        if (!passwordRegexCheck) {
            alert("비밀번호 양식에 맞게 입력해주세요.");
            return;
        }

        if (password.trim() !== passwordCheck.trim()) {
            alert("패스워드가 서로 다릅니다.");
            return;
        }
        if (phoneNum1.trim() === "") {
            alert("연락처를 확인해주세요");
            return;
        }
        if (phoneNum2.trim() === "") {
            alert("연락처를 확인해주세요");
            return;
        }
        if (phoneNum3.trim() === "") {
            alert("연락처를 확인해주세요");
            return;
        }

        if (bowlingCenter) {
            setBowlingCenterModal(true);
            return;
        } else {
            updateHandler();
        }
    };

    const updateHandler = () => {
        setBowlingCenterModal(false);
        ReqCreateAdmin(
            updateObject,
            (result) => {
                if (result.status === 200) {
                    setSuccessModal(true);
                    return;
                }
            },
            (error_result) => {
                alert("에러");
            }
        );
    };

    return (
        <section>
            <div className={classes.admin_contents}>
                <h3>관리자 계정관리 - 등록</h3>
                <div className={`${classes.contents_wrap} ${classes.search_wrap} `}>
                    <div className={classes.admin_table_wrap}>
                        <table>
                            <colgroup>
                                <col style={{ width: "159px" }} />
                                <col style={{ width: "*" }} />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>아이디</th>
                                    <td>
                                        <div className={classes.flex_row}>
                                            <MultiInput
                                                className="admin_st"
                                                value={adminMemberId}
                                                onChange={onChange}
                                                id="adminMemberId"
                                                name="adminMemberId"
                                            />
                                            <Button className="border sm" onClick={adminIdDuplicateHandler}>
                                                아이디 중복확인
                                            </Button>
                                            {memberIdIsValid === "" && (
                                                <p className={classes.desc_gray}>영 대소문자를 포함하여 6~20자 이내</p>
                                            )}
                                            {adminMemberId.trim() !== "" && memberIdIsValid === "N" && (
                                                <p className={classes.desc_gray}>
                                                    이미 사용중인 아이디입니다. 다시 확인해주세요.
                                                </p>
                                            )}
                                            {memberIdIsValid === "Y" &&
                                                memberIdRegexCheck &&
                                                adminMemberId.trim() !== "" && (
                                                    <p className={classes.desc_gray}>사용가능한 아이디입니다.</p>
                                                )}
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        비밀번호
                                        <span className="require_red">*</span>
                                    </th>
                                    <td>
                                        <div className={classes.flex_row}>
                                            <MultiInput
                                                className="admin_st"
                                                value={password}
                                                onChange={onChange}
                                                type="password"
                                                id="password"
                                                name="password"
                                            />
                                            <p className={classes.desc_gray}>
                                                6~20자 영문, 숫자로만 공백 없이 입력하세요.
                                            </p>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        비밀번호 확인
                                        <span className="require_red">*</span>
                                    </th>
                                    <td>
                                        <div className={classes.flex_row}>
                                            <MultiInput
                                                className="admin_st"
                                                value={passwordCheck}
                                                onChange={onChange}
                                                type="password"
                                                id="passwordCheck"
                                                name="passwordCheck"
                                            />
                                            <p className={classes.desc_gray}>비밀번호를 다시 한번 입력해 주세요.</p>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        관리자명
                                        <span className="require_red">*</span>
                                    </th>
                                    <td>
                                        <div className={classes.flex_row}>
                                            <MultiInput
                                                className="admin_st"
                                                value={adminName}
                                                onChange={onChange}
                                                id="adminName"
                                                name="adminName"
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        담당업무
                                        <span className="require_red">*</span>
                                    </th>
                                    <td>
                                        <div className={classes.flex_row}>
                                            <MultiInput
                                                className="admin_st"
                                                value={assignedTask}
                                                onChange={onChange}
                                                id="assignedTask"
                                                name="assignedTask"
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        연락처
                                        <span className="require_red">*</span>
                                    </th>
                                    <td>
                                        <div className={`${classes.flex_row} ${classes.multi}`}>
                                            <MultiInput
                                                className="admin_st sm"
                                                value={phoneNum1}
                                                onChange={onChange}
                                                id="phoneNum1"
                                                name="phoneNum1"
                                            />
                                            <span>-</span>
                                            <MultiInput
                                                className="admin_st sm"
                                                value={phoneNum2}
                                                onChange={onChange}
                                                id="phoneNum2"
                                                name="phoneNum2"
                                            />
                                            <span>-</span>
                                            <MultiInput
                                                className="admin_st sm"
                                                value={phoneNum3}
                                                onChange={onChange}
                                                id="phoneNum3"
                                                name="phoneNum3"
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        이메일
                                        <span className="require_red">*</span>
                                    </th>
                                    <td>
                                        <div className={`${classes.flex_row} ${classes.multi}`}>
                                            <MultiInput
                                                className="admin_st"
                                                value={firstEmail}
                                                onChange={onChange}
                                                id="firstEmail"
                                                name="firstEmail"
                                            />
                                            <span>@</span>
                                            <Input
                                                className="admin_st_sm"
                                                value={registerEmailHost}
                                                onChange={emailHostHandler}
                                                name="registerEmailHost"
                                            />
                                            <SelectBoxNew
                                                className="admin_st "
                                                optionDatas={emailHostList}
                                                setSelectItemCB={emailHostHandler}
                                                showDefaultKey={registerEmailHost}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>상태</th>
                                    <td>
                                        <SelectBoxNew
                                            className="admin_st"
                                            optionDatas={adminStatusType}
                                            setSelectItemCB={adminStatusHandler}
                                            showDefaultKey={adminStatus}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className={`${classes.contents_wrap} ${classes.search_wrap}`}>
                    <div className={`${classes.admin_table_wrap} ${classes.align_center} `}>
                        <h5>업무권한</h5>
                        <table>
                            <tbody>
                                <tr>
                                    <th rowSpan={2}></th>
                                    <th colSpan={6}>대회운영</th>
                                    <th rowSpan={2}>
                                        상품
                                        <br />
                                        관리
                                    </th>
                                    <th rowSpan={2}>케겔</th>
                                    <th rowSpan={2}>
                                        커뮤
                                        <br />
                                        니티
                                    </th>
                                    <th rowSpan={2}>
                                        회사
                                        <br />
                                        소개
                                    </th>
                                    <th rowSpan={2}>이벤트</th>
                                    <th rowSpan={2}>
                                        회원
                                        <br />
                                        관리
                                    </th>
                                    <th rowSpan={2}>
                                        웹사이트 <br /> 관리
                                    </th>
                                    <th rowSpan={2}>통계</th>
                                    <th rowSpan={2}>
                                        <span className="require_red">*</span>
                                        <br />
                                        볼링장
                                    </th>
                                </tr>
                                <tr>
                                    <th>
                                        대회
                                        <br />
                                        등록
                                    </th>
                                    <th>
                                        경기장
                                        <br />
                                        등록
                                    </th>
                                    <th>
                                        볼링장
                                        <br />
                                        등록
                                    </th>
                                    <th>
                                        게재
                                        <br />
                                        관리
                                    </th>
                                    <th>
                                        접수/
                                        <br />
                                        참가관리
                                    </th>
                                    <th>
                                        문자발송
                                        <br />
                                        관리
                                    </th>
                                </tr>

                                <tr>
                                    <th>
                                        등록
                                        <br />
                                        /수정
                                    </th>
                                    <td>
                                        <CheckboxA id="ct_main_1" checked={ctMain} onChange={setCtMain} />
                                    </td>
                                    <td>
                                        <CheckboxA id="ct_place_1" checked={ctPlace} onChange={setCtPlace} />
                                    </td>
                                    <td>
                                        <CheckboxA id="ct_center_1" checked={ctCenter} onChange={setCtCenter} />
                                    </td>
                                    <td>
                                        <CheckboxA
                                            id="ct_mng_expose_1"
                                            checked={ctMngExpose}
                                            onChange={setCtMngExpose}
                                        />
                                    </td>
                                    <td>
                                        <CheckboxA
                                            id="ct_mng_applier_1"
                                            checked={ctMngApplier}
                                            onChange={setCtMngApplier}
                                        />
                                    </td>
                                    <td>
                                        <CheckboxA
                                            id="ct_config_sms_1"
                                            checked={ctConfigSms}
                                            onChange={setCtConfigSms}
                                        />
                                    </td>
                                    <td rowSpan={2}>
                                        <CheckboxA id="product" checked={product} onChange={setProduct} />
                                    </td>
                                    <td rowSpan={2}>
                                        <CheckboxA id="kegel" checked={kegel} onChange={setKegel} />
                                    </td>
                                    <td rowSpan={2}>
                                        <CheckboxA id="community" checked={community} onChange={setCommunity} />
                                    </td>
                                    <td rowSpan={2}>
                                        <CheckboxA id="intro" checked={intro} onChange={setIntro} />
                                    </td>
                                    <td rowSpan={2}>
                                        <CheckboxA id="event" checked={event} onChange={setEvent} />
                                    </td>
                                    <td rowSpan={2}>
                                        <CheckboxA id="member" checked={member} onChange={setMember} />
                                    </td>
                                    <td rowSpan={2}>
                                        <CheckboxA id="website" checked={website} onChange={setWebsite} />
                                    </td>
                                    <td rowSpan={2}>
                                        <CheckboxA id="statistics" checked={statistics} onChange={setStatistics} />
                                    </td>
                                    <td rowSpan={2}>
                                        <CheckboxA
                                            id="bowling_center"
                                            checked={bowlingCenter}
                                            onChange={setBowlingCenter}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>조회</th>
                                    <td>
                                        <CheckboxA id="ct_main_2" checked={ctMain_1} onChange={setCtMain_1} />
                                    </td>
                                    <td>
                                        <CheckboxA id="ct_place_2" checked={ctPlace_1} onChange={setCtPlace_1} />
                                    </td>
                                    <td>
                                        <CheckboxA id="ct_center_2" checked={ctCenter_1} onChange={setCtCenter_1} />
                                    </td>
                                    <td>
                                        <CheckboxA
                                            id="ct_mng_expose_2"
                                            checked={ctMngExpose_1}
                                            onChange={setCtMngExpose_1}
                                        />
                                    </td>
                                    <td>
                                        <CheckboxA
                                            id="ct_mng_applier_2"
                                            checked={ctMngApplier_1}
                                            onChange={setCtMngApplier_1}
                                        />
                                    </td>
                                    <td>
                                        <CheckboxA
                                            id="ct_config_sms_2"
                                            checked={ctConfigSms_1}
                                            onChange={setCtConfigSms_1}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className={classes.bottom_btn_wrap}>
                            <Button className="secondary sm" onClick={confirmHandler}>
                                등록
                            </Button>
                            <Button className="border sm" onClick={toList}>
                                목록
                            </Button>
                        </div>
                        {bowlingCenterModal && (
                            <Alert
                                type="confirm"
                                cancel="취소"
                                confirm="확인"
                                close={() => setBowlingCenterModal(false)}
                                confirmHandler={updateHandler}
                            >
                                {"모든 권한은 사용이 불가능하고, 볼링장만 적용됩니다."}
                            </Alert>
                        )}
                        {successModal && (
                            <Alert type="alert" confirm="확인" close={toList}>
                                {"등록되었습니다."}
                            </Alert>
                        )}
                    </div>
                </div>
                <div className={classes.info_box}>
                    <p>* 볼링장 관리자의 경우 아래메뉴에서 자신의 볼링장에 대한 데이터만 조회/등록 가능</p>
                    <ul>
                        <li>
                            - 대회운영 {">"} 대회관리 {">"} 대회진행 인쇄물
                        </li>
                        <li>
                            - 대회운영 {">"} 대회결과 {">"} 대회 진행결과 업로드
                        </li>
                    </ul>
                    <p>
                        * 관리자 계정관리에서 볼링장관리자로 아이디 생성 후 ‘대회운영
                        {">"} 대회관리 {">"} 볼링장 등록‘에 해당 아이디 등록 시 자신의 볼링장에 대한 권한만 부여
                    </p>
                </div>
            </div>
        </section>
    );
}
export default MngAdminAccountCreate;
