import { useState, useEffect } from "react";
import classes from "../../MngCommon.module.css";
import Button from "../../../../components/UI/Button/Button";
import Radio from "../../../../components/UI/Input/Radio";
import Input from "../../../../components/UI/Input/Input";
import Modal from "../../../../components/UI/Modal/Modal";
import { Link, useLoaderData, useLocation, useNavigate } from "react-router-dom";
import DaumPostcode from "react-daum-postcode";
import SelectBoxNew from "../../../../components/UI/SelectBox/SelectBoxNew";
import { ReqRegionData } from "../../../../api/common/ConditionSelectBoxApi";
import { DefaultAll } from "../../../../components/definedefault/DefSelectBoxValues";
import Alert from "../../../../components/UI/Modal/Alert";
import classes2 from "../../../../components/UI/Input/SearchBox.module.css";
import {
    ReqAddRegion,
    ReqRegionSearch,
    ReqRegionList,
    ReqUpdateBowlingAlley,
} from "../../../../api/tournament/BowlingAlleyApi";
import instance from "../../../../store/AxiosInterceptor";

function MngTournamentBowlingAlleyUpdate() {
    const bowlingAlleyData = useLoaderData();

    const location = useLocation();
    const navigate = useNavigate();

    // [START] 지역 팝업    =================================

    const [regionList, setRegionList] = useState([]);

    const [regionName, setRegionName] = useState("");

    const regionNameChangeHandler = (e) => {
        setRegionName(e);
    };

    const [showConfirm, setShowConfirm] = useState(false);

    // 지역 추가
    const addRegion = async () => {
        setShowConfirm(true);
    };
    const handleConfirm = async () => {
        setShowConfirm(false);

        const requestData = {
            name: regionName,
        };
        const response = await ReqAddRegion(requestData);
        if (response.status === 200) {
            setRegionList((prevRegionList) => [
                ...prevRegionList,
                response.data,
            ]);
            setRegionName(""); // 지역 추가 후 입력 필드 초기화
            reqRegionNameHandler(false);
        } else {
            alert(`지역 추가 요청 실패 (${response.status})`);
        }
    };

    // 지역 검색어
    const [keyword, setKeyword] = useState("");

    const keywordHandler = (keyword) => {
        setKeyword(keyword);
    };

    // 지역 검색 버튼
    const regionSearch = async () => {
        let requestData = null;
        if (keyword === "") {
            requestData = null;
        } else {
            requestData = keyword;
        }
        const response = await ReqRegionSearch(requestData);

        if (response.status === 200) {
            setRegionList(response.data.datas);
        } else {
            alert(`지역 추가 요청 실패 (${response.status})`);
        }
    };

    // [END] --지역 팝업

    // [START] 다음 주소 API

    useEffect(() => {
        setSelectedAddress(bowlingAlleyData.default_address);
    }, [bowlingAlleyData.default_address]);

    const [isDaumPostcodeOpen, setIsDaumPostcodeOpen] = useState(false);

    const openDaumPostcodeHandler = (e) => {
        e.stopPropagation();
        setIsDaumPostcodeOpen(true);
    };

    const [selectedAddress, setSelectedAddress] = useState(
        bowlingAlleyData.default_address
    ); // 선택한 주소를 저장할 상태

    const handleAddressSelect = (data) => {
        const fullAddress = data.address; // 전체 주소
        const extraAddress = data.addressType === "R" ? "" : data.bname; // 도로명일 경우 법정동 추가
        const address = `${fullAddress} ${extraAddress}`; // 최종 주소

        setSelectedAddress((prev) => address);
        setIsDaumPostcodeOpen(false);
    };
    const closeDaumPostcodeHandler = () => {
        setIsDaumPostcodeOpen(false);
    };

    const [enteredAddress, setEnteredAddress] = useState(
        bowlingAlleyData.detailed_address
    ); // 상세 주소
    const addressHandler = (data) => {
        setEnteredAddress(data);
    };

    // [END] 다음 주소 API =================================================================

    // [START] 전화번호 정규식 =================================================================
    // 전화번호 GROUP1
    const formatPhoneNumber1 = (str) => {
        const regex = /(^02|^0504|^0505|^0\d{2})(\d+)?(\d{4})$/;
        const match = str.match(regex);
        if (match) {
            const number = match[1];
            return number;
        }
        return str;
    };

    // 전화번호 GROUP2
    const formatPhoneNumber2 = (str) => {
        const regex = /(^02|^0504|^0505|^0\d{2})(\d+)?(\d{4})$/;
        const match = str.match(regex);
        if (match) {
            const number = match[2];
            return number;
        }
        return str;
    };

    // 전화번호 GROUP3
    const formatPhoneNumber3 = (str) => {
        const regex = /(^02|^0504|^0505|^0\d{2})(\d+)?(\d{4})$/;
        const match = str.match(regex);
        if (match) {
            const number = match[3];
            return number;
        }
        return str;
    };
    // [END] 전화번호 정규식 =================================================================

    // [Start] 지역 선택 SELECTBOX =================================================

    const [regionData, setRegionData] = useState([DefaultAll]);
    const [region, setRegionType] = useState("");
    const [regionValue, setRegionValue] = useState(bowlingAlleyData.region);

    const regionDataHandler = (key) => {
        // setRegionType(key);
        const selectedOption = regionData.find((data) => data.key === key);
        if (selectedOption) {
            setRegionType(key);
            setRegionValue(selectedOption.value);
        }
    };
    const reqRegionNameHandler = (setDefault) => {
        //if (regionData.length === 1) {
        ReqRegionData(
            (result) => {
                const success = result.map((data) => {
                    return { key: String(data.id), value: data.name };
                });
                setRegionData((prev) => [DefaultAll, ...success]);
                if (success.length > 0 && setDefault)
                    // setRegionType(success[0].key);
                    setRegionType(String(bowlingAlleyData.regionId)); // 로더 값
            },
            (excption_result) => {
                setRegionData((prev) => [DefaultAll]);
            }
        );
        //}
    };

    useEffect(() => {
        reqRegionNameHandler(true);
    }, []);
    // [End] 지역 선택 SELECTBOX =================================================

    const [isModalOpen1, setIsModalOpen1] = useState(false);
    const openModalHandler1 = async (e) => {
        e.stopPropagation();
        setIsModalOpen1(true);
        const response = await ReqRegionList();

        if (response.status === 200) {
            setRegionList(response.data.datas);
        } else {
            alert(`지역 리스트 요청 실패 (${response.status})`);
        }
    };
    const closeModalHandler1 = (e) => {
        e.stopPropagation();
        setIsModalOpen1(false);
    };

    // -- INPUT --------------------------------
    const [name, setName] = useState(bowlingAlleyData.name);
    const nameChangeHandler = (value) => {
        setName(value);
    };

    const [laneCnt, setLaneCnt] = useState(bowlingAlleyData.use_lane_count);
    const laneCntChangeHandler = (value) => {
        setLaneCnt(value);
    };

    const [startLane, setStartLane] = useState(
        bowlingAlleyData.start_lane_number
    );
    const startLaneChangeHandler = (value) => {
        setStartLane(value);
    };

    const [endLane, setEndLane] = useState(bowlingAlleyData.end_lane_number);
    const endLaneChangeHandler = (value) => {
        setEndLane(value);
    };
    const [phoneNum1, setPhoneNum1] = useState(
        formatPhoneNumber1(bowlingAlleyData.phoneNo)
    );
    const phoneNum1ChangeHandler = (value) => {
        setPhoneNum1(value);
    };
    const [phoneNum2, setPhoneNum2] = useState(
        formatPhoneNumber2(bowlingAlleyData.phoneNo)
    );
    const phoneNum2ChangeHandler = (value) => {
        setPhoneNum2(value);
    };
    const [phoneNum3, setPphoneNum3] = useState(
        formatPhoneNumber3(bowlingAlleyData.phoneNo)
    );
    const phoneNum3ChangeHandler = (value) => {
        setPphoneNum3(value);
    };

    const [isUse, setIsUse] = useState(bowlingAlleyData.use_reserv_lane);
    const isUseChangeHandler = (e) => {
        setIsUse(e.target.value);
    };

    // --------------------------------

    // --- 필수항목 넣었는지? Alert 띄우기  --------------------------------

    const [showConfirmation, setShowConfirmation] = useState(false);
    const [showConfirmation2, setShowConfirmation2] = useState(false);
    const [showConfirmation3, setShowConfirmation3] = useState(false);
    const [showConfirmation4, setShowConfirmation4] = useState(false);

    //[START] 수정 =================================
    const editHandler = async () => {
        // 필수 항목 체크
        if (
            region === "" ||
            name === "" ||
            laneCnt === "" ||
            startLane === "" ||
            endLane === "" ||
            phoneNum1 === "" ||
            phoneNum2 === "" ||
            phoneNum3 === "" ||
            selectedAddress === ""
        ) {
            setShowConfirmation(true);
            return;
        }

        // 끝 레인이 시작 레인보다 작을 때
        if (Number(startLane) > Number(endLane)) {
            setShowConfirmation2(true);
            return;
        }

        // 사용레인 개수가 맞지 않을 때

        if (endLane - startLane + 1 != laneCnt) {
            setShowConfirmation4(true);
            return;
        }

        // 전화번호 형식
        if (
            /^(\d{2,3})-(\d{3,4})-(\d{4})$/.test(
                `${phoneNum1}-${phoneNum2}-${phoneNum3}`
            ) === false
        ) {
            setShowConfirmation3(true);
            return;
        }

        const requestData = {
            region: regionValue,
            name: name,
            use_lane_count: parseInt(laneCnt),
            start_lane_number: parseInt(startLane),
            end_lane_number: parseInt(endLane),
            use_reserv_lane: isUse,
            // phoneNo: `${phoneNum1}-${phoneNum2}-${phoneNum3}`,
            phoneNo: `${phoneNum1}${phoneNum2}${phoneNum3}`,
            default_address: selectedAddress,
            detailed_address: enteredAddress,
            regDate: new Date().toISOString(),
        };

        const response = await ReqUpdateBowlingAlley(
            bowlingAlleyData.center_id,
            requestData
        );

        if (response.status === 200) {
            navigate('/admin/tournament/bowlingAlley', { state: location.state });
        } else {
            alert(`수정 요청 실패 (${response.status})`);
        }
    };

    const bodyData = (
        <>
            <tr>
                <th>등록일</th>
                <td colSpan={2}>{bowlingAlleyData.regDate}</td>
                <th>등록자</th>
                <td>{bowlingAlleyData.regID}</td>
            </tr>
            <tr>
                <th>지역</th>
                <td colSpan={4}>
                    <div className={classes.flex_row}>
                        <SelectBoxNew
                            className="admin_st"
                            setSelectItemCB={regionDataHandler}
                            optionDatas={regionData}
                            showDefaultKey={region}
                        />
                        <Button
                            className="sm border"
                            onClick={openModalHandler1}
                        >
                            지역 추가
                        </Button>
                    </div>
                </td>
            </tr>
            <tr>
                <th>볼링장명</th>
                <td colSpan={2}>
                    <Input
                        className="admin_st_sm"
                        value={name}
                        onChange={nameChangeHandler}
                    />
                </td>
                <th>볼링장ID</th>
                <td>{bowlingAlleyData.center_id}</td>
            </tr>
            <tr>
                <th rowSpan={3}>레인</th>
                <th>레인 수</th>
                <td colSpan={3}>
                    <Input
                        className="admin_st_sm"
                        value={laneCnt}
                        onChange={laneCntChangeHandler}
                    />
                </td>
            </tr>
            <tr>
                <th>사용레인</th>
                <td colSpan={3}>
                    <div className={classes.flex_row}>
                        <Input
                            className="admin_st_sm"
                            label="레인"
                            value={startLane}
                            onChange={startLaneChangeHandler}
                        />
                        <span>~</span>
                        <Input
                            className="admin_st_sm"
                            label="레인"
                            value={endLane}
                            onChange={endLaneChangeHandler}
                        />
                    </div>
                </td>
            </tr>
            <tr>
                <th>예비레인 사용유무</th>
                <td colSpan={3}>
                    <div className={classes.radio_wrap}>
                        <Radio
                            value="Y"
                            title="사용"
                            name="rad_01"
                            id="rad_01_01"
                            checked={isUse === "Y"}
                            onChange={isUseChangeHandler}
                        />
                        <Radio
                            value="N"
                            title="미사용"
                            name="rad_01"
                            id="rad_01_02"
                            checked={isUse === "N"}
                            onChange={isUseChangeHandler}
                        />
                    </div>
                </td>
            </tr>
            {isDaumPostcodeOpen && (
                <Modal
                    className="admin_st"
                    close={closeDaumPostcodeHandler}
                    header="주소 검색"
                >
                    <DaumPostcode onComplete={handleAddressSelect} />
                </Modal>
            )}
            <tr>
                <th>주소</th>
                <td colSpan={4}>
                    <div className={classes.flex_row}>
                        <Input
                            className="admin_st_mid"
                            value={selectedAddress}
                            readOnly
                        />
                        <Button
                            className="border sm"
                            onClick={openDaumPostcodeHandler}
                        >
                            주소찾기
                        </Button>
                    </div>
                </td>
            </tr>

            <tr>
                <td colSpan={4}>
                    <Input
                        className="admin_st_mid"
                        placeholder="나머지 주소 입력"
                        value={enteredAddress}
                        onChange={addressHandler}
                    />
                </td>
            </tr>
            <tr>
                <th>전화번호</th>
                <td colSpan={4}>
                    <div className={classes.flex_row}>
                        <Input
                            className="sm"
                            value={phoneNum1}
                            onChange={phoneNum1ChangeHandler}
                        />
                        <span>-</span>
                        <Input
                            className="sm"
                            value={phoneNum2}
                            onChange={phoneNum2ChangeHandler}
                        />
                        <span>-</span>
                        <Input
                            className="sm"
                            value={phoneNum3}
                            onChange={phoneNum3ChangeHandler}
                        />
                    </div>
                </td>
            </tr>
        </>
    );

    return (
        <section>
            <div className={classes.admin_contents}>
                <h3>볼링장 수정</h3>
                <div
                    className={`${classes.contents_wrap} ${classes.search_wrap}`}
                >
                    <div className={classes.admin_table_wrap}>
                        <table>
                            <colgroup>
                                <col style={{ width: "159px" }} />
                                <col style={{ width: "159px" }} />
                                <col style={{ width: "460px" }} />
                                <col style={{ width: "159px" }} />
                                <col style={{ width: "620px" }} />
                            </colgroup>
                            <tbody>{bodyData}</tbody>
                        </table>
                        <div className={classes.bottom_btn_wrap}>
                            <Button className="border sm" onClick={editHandler}>
                                수정
                            </Button>
                            <Link to="/admin/tournament/bowlingAlley" state={location.state}>
                                <Button className="secondary sm">목록</Button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            {showConfirmation && (
                <Alert
                    type="alert"
                    confirm="확인"
                    close={() => setShowConfirmation(false)} // 알림창 닫기
                >
                    필수항목을 입력해주세요.
                </Alert>
            )}
            {showConfirmation2 && (
                <Alert
                    type="alert"
                    confirm="확인"
                    close={() => setShowConfirmation2(false)}
                >
                    시작레인보다 작을 수 없습니다.
                </Alert>
            )}
            {showConfirmation3 && (
                <Alert
                    type="alert"
                    confirm="확인"
                    close={() => setShowConfirmation3(false)}
                >
                    전화번호 형식에 맞지 않습니다.
                </Alert>
            )}
            {showConfirmation4 && (
                <Alert
                    type="alert"
                    confirm="확인"
                    close={() => setShowConfirmation4(false)}
                >
                    레인 수는 사용레인의 개수입니다.
                </Alert>
            )}
            {isModalOpen1 && (
                <Modal
                    className="admin_st"
                    close={closeModalHandler1}
                    header="지역 추가"
                >
                    <main>
                        <div className={classes.search_bx_wrap}>
                            <div className={classes2.search_box}>
                                <Input
                                    className="search_input"
                                    placeholder="지역검색"
                                    value={keyword}
                                    onChange={keywordHandler}
                                />
                                <button
                                    className={classes2.btn_search}
                                    onClick={regionSearch}
                                >
                                    검색
                                </button>
                                <p className={classes2.error_txt}>
                                    검색하실 내용을 입력해주세요.
                                </p>
                            </div>
                        </div>
                        <table>
                            <colgroup>
                                <col style={{ width: "110px" }} />
                                <col style={{ width: "739px" }} />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>지역 추가</th>
                                    <td>
                                        <div className={classes.flex_row}>
                                            <Input
                                                value={regionName}
                                                className="h35"
                                                onChange={
                                                    regionNameChangeHandler
                                                }
                                            />
                                            <Button
                                                className="border xxxs"
                                                onClick={addRegion}
                                            >
                                                등록
                                            </Button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div
                            className={`${classes.table_st_wrap} ${classes.st_3}`}
                        >
                            <div className={classes.header}>
                                <div>No</div>
                                <div>지역명</div>
                            </div>
                            <div className={classes.scroll_box}>
                                <div className={`${classes.body} `}>
                                    <div className={classes.col}>
                                        {regionList.map((item, index) => {
                                            return (
                                                <div className={classes.list}>
                                                    <b>{index + 1}</b>
                                                    <p>{item.name}</p>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </Modal>
            )}
            {showConfirm && (
                <Alert
                    type="confirm"
                    confirm="확인"
                    cancel="취소"
                    close={() => setShowConfirm(false)}
                    confirmHandler={handleConfirm}
                >
                    지역을 추가하시겠습니까?
                </Alert>
            )}
        </section>
    );
}
export default MngTournamentBowlingAlleyUpdate;

export const loader = async ({ params }) => {
    try {
        const response = await instance.get(
            `/api/v1/admin/contest/center/${params.center_id}`,
            {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            }
        );

        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error("Failed to fetch data");
        }
    } catch (error) {
        console.error("Error fetching data:", error);
        return null;
    }
};
