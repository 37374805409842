import instance from "../../store/AxiosInterceptor";

/**
 * 유저 마이페이지 >> 대회 접수 리스트, 검색
 * @param resolveCB 응답이 성공일 경우 데이터를 넣어 호출하는 callback method
 * @returns
 */
export function ReqApplyListWithSearch(searchText, page, resolveCB, rejectCB) {
    instance({
        url: `/api/v1/home/mypage/contest?searchText=${searchText}&page=${page}&size=10`,
        method: "GET",
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data.datas);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/**
 * 유저 마이페이지 >> 대회 접수 리스트, 검색
 * @param resolveCB 응답이 성공일 경우 데이터를 넣어 호출하는 callback method
 * @returns
 */
export function ReqApplyListWithSearchApp(searchText, page, resolveCB, rejectCB) {
    instance({
        url: `/api/v1/home/mypage/contest?searchText=${searchText}&page=${page}&size=5`,
        method: "GET",
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data.datas);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/**
 * 유저 마이페이지 >> 대회 접수 리스트, 검색
 * @param resolveCB 응답이 성공일 경우 데이터를 넣어 호출하는 callback method
 * @returns
 */
export function ReqUpdateResearchResult(competitorId, updateList, selectDisabled, resolveCB, rejectCB) {
    instance({
        url: `/api/v1/home/mypage/contest/${competitorId}/edit`,
        method: "PUT",
        data: {
            datas: updateList,
            disabled: selectDisabled,
        },
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/**
 * 유저 마이페이지 >> AS신청 글쓰기 API
 * @param resolveCB 응답이 성공일 경우 데이터를 넣어 호출하는 callback method
 * @returns
 */
export function ReqBoardAsListPage(page, resolveCB, rejectCB) {
    instance({
        url: `/api/v1/home/board/as?page=${page}&size=10`,
        method: "GET",
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

export function ReqBoardAsListPageApp(page, resolveCB, rejectCB) {
    instance({
        url: `/api/v1/home/board/as?page=${page}&size=5`,
        method: "GET",
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/**
 * 유저 마이페이지 >> AS신청 글쓰기 API
 * @param resolveCB 응답이 성공일 경우 데이터를 넣어 호출하는 callback method
 * @returns
 */
export function ReqBoardHelpListPage(page, resolveCB, rejectCB) {
    instance({
        url: `/api/v1/home/board/help?page=${page}&size=10`,
        method: "GET",
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

export function ReqBoardHelpListPageApp(page, resolveCB, rejectCB) {
    instance({
        url: `/api/v1/home/board/help?page=${page}&size=5`,
        method: "GET",
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/**
 * 유저 마이페이지 >> AS신청 글쓰기 API
 * @param resolveCB 응답이 성공일 경우 데이터를 넣어 호출하는 callback method
 * @returns
 */
export function ReqAddBoardAs(boardObject, resolveCB, rejectCB) {
    instance({
        url: `/api/v1/home/board/as`,
        method: "POST",
        data: boardObject,
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/**
 * 유저 마이페이지 >> 핸드폰번호, 주소, 비밀번호 변경
 * @param resolveCB 응답이 성공일 경우 데이터를 넣어 호출하는 callback method
 * @returns
 */
export function ReqModifyMemberInfo(object, resolveCB, rejectCB) {
    instance({
        url: `/api/v1/home/mypage/memberInfo`,
        method: "PUT",
        data: object,
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/**
 * 유저 마이페이지 >> 비밀번호 변경
 * @param resolveCB 응답이 성공일 경우 데이터를 넣어 호출하는 callback method
 * @returns
 */
export function ReqModifyMemberPassword(passwordObject, resolveCB, rejectCB) {
    instance({
        url: `/api/v1/home/mypage/memberInfo/password`,
        method: "POST",
        data: passwordObject,
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/**
 * 유저 마이페이지 >> 성별, 이름, 생년월일 변경
 * @param resolveCB 응답이 성공일 경우 데이터를 넣어 호출하는 callback method
 * @returns
 */
export function ReqModifyPersonalInfo(formData, resolveCB, rejectCB) {
    instance({
        url: `/api/v1/home/member/change`,
        method: "POST",
        data: formData,
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/**
 * 유저 마이페이지 >> 1:1문의 글쓰기 API
 * @param resolveCB 응답이 성공일 경우 데이터를 넣어 호출하는 callback method
 * @returns
 */
export function ReqAddBoardHelp(formData, resolveCB, rejectCB) {
    instance({
        url: `/api/v1/home/board/help`,
        method: "POST",
        data: formData,
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/**
 * 유저 마이페이지 >> 1:1문의 삭제 API
 * @param resolveCB 응답이 성공일 경우 데이터를 넣어 호출하는 callback method
 * @returns
 */
export function ReqDeleteBoardHelp(helpId, resolveCB, rejectCB) {
    instance({
        url: `/api/v1/home/board/help/${helpId}`,
        method: "DELETE",
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/**
 * 유저 마이페이지 >> 1:1문의 삭제 API
 * @param resolveCB 응답이 성공일 경우 데이터를 넣어 호출하는 callback method
 * @returns
 */
export function ReqDeleteBoardAS(helpId, resolveCB, rejectCB) {
    instance({
        url: `/api/v1/home/board/as/${helpId}`,
        method: "DELETE",
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/**
 * 유저 마이페이지 >> 1:1문의 글쓰기 API
 * @param resolveCB 응답이 성공일 경우 데이터를 넣어 호출하는 callback method
 * @returns
 */
export function ReqUpdateBoardHelp(helpId, formData, resolveCB, rejectCB) {
    instance({
        url: `/api/v1/home/board/help/${helpId}`,
        method: "PUT",
        data: formData,
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/**
 * 유저 마이페이지 >> AS신청 글쓰기 API
 * @param resolveCB 응답이 성공일 경우 데이터를 넣어 호출하는 callback method
 * @returns
 */
export function ReqUpdateBoardAs(asId, boardObject, resolveCB, rejectCB) {
    instance({
        url: `/api/v1/home/board/as/${asId}`,
        method: "PUT",
        data: boardObject,
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/**
 * 유저 - 마이페이지 - 회원정보수정 - 회원탈퇴
 */
export function ReqDeleteMember(quitObject, resolveCB, rejectCB) {
    instance({
        url: `/api/v1/home/mypage/memberInfo/delete`,
        method: "POST",
        data: quitObject,
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/**
 * 유저 마이페이지 >> 성별, 생년월일, 이름 변경 신청 내역 조회
 * 가장 최근에 신청했으며 REQUEST인 신청내역 1개만 가져온다.
 * REQUEST인 신청내역이 없으면 null리턴
 *
 */
export function ReqChangeInfoDetail(resolveCB, rejectCB) {
    instance({
        url: `/api/v1/home/mypage/memberInfo/changeInfo`,
        method: "GET",
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB(error);
            }
        });
}


/**
 * 유저 - 마이페이지 - 내정보가져오기
 */
export function ReqMemberInfo(resolveCB, rejectCB) {
    instance({
        url: `/api/v1/home/mypage/memberInfo`,
        method: "GET",
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data.data);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/**
 * 유저 마이페이지 - 대회 접수 내역 - 사용구 등록
 */
export function ReqAddUserBowlingBall(items, resolveCB, rejectCB) {
    instance({
        url: `/api/v1/home/mypage/memberInfo/ball`,
        method: "POST",
        data: {
            data : items
        }
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/**
 * 유저 마이페이지 - 대회 접수 내역 - 사용구 - 등록한 사용구 조회
 */
export function ReqFindUserBowlingBall(competitorId, resolveCB, rejectCB) {
    instance({
        url: `/api/v1/home/mypage/memberInfo/ball/${competitorId}`,
        method: "GET",
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/**
 * 유저 마이페이지 - 대회 접수 내역 - 사용구 - 등록한 사용구 삭제
 */
export function ReqUpdateUserBowlingBall(ballId, updateObject, resolveCB, rejectCB) {
    instance({
        url: `/api/v1/home/mypage/memberInfo/ball/${ballId}`,
        method: "PUT",
        data : updateObject
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/**
 * 유저 마이페이지 - 대회 접수 내역 - 사용구 - 등록한 사용구 삭제
 */
export function ReqDeleteUserBowlingBall(competitorId, ballId, resolveCB, rejectCB) {
    instance({
        url: `/api/v1/home/mypage/memberInfo/ball/${competitorId}/${ballId}`,
        method: "DELETE",
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}