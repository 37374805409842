import React, { useEffect, useState } from "react";
import classes from "./components/UI/Modal/Modal.module.css";
import Button from "./components/UI/Button/Button";
import instance from "./store/AxiosInterceptor";
import Checkbox from "./components/UI/Input/Checkbox";
import Cookies from "js-cookie";

export const PopupModal = (props) => {
    const { isMobileCB } = props;
    const [popupList, setPopupList] = useState([]);

    useEffect(() => {
        const fetchPopups = async () => {
            try {
                const response = await instance.get(
                    `/api/v1/home/homeview/mainpopup/top`
                );
                if (response.status === 200 && response.data.status === 200) {
                    const resData = response.data.data;
                    setPopupList(resData);
                } else {
                    setPopupList([]);
                }
            } catch (error) {
                console.error("Error fetching popups:", error);
                setPopupList([]);
            }
        };
        fetchPopups();
    }, []);

    const shouldShowPopup = (popup) => {
        const hideToday = Cookies.get(`popup_${popup.id}_hide_today`);
        
        if (hideToday) {
            return false;
        }
    
        if (!popup.closedTime) {
            return true;
        }
    
        const currentTime = new Date().getTime();
        const timeSinceClosed = currentTime - popup.closedTime;
    
        return timeSinceClosed >= 24 * 60 * 60 * 1000;
    };
    
    const handleClosePopup = (popupId) => {
        const hideToday = document.getElementById(`chk_${popupId}`).checked;
    
        if (hideToday) {
            Cookies.set(`popup_${popupId}_hide_today`, "true", { expires: 1 });
        }
    
        const updatedPopups = popupList.map((popup) =>
            popup.id === popupId
                ? { ...popup, closedTime: new Date().getTime() }
                : popup
        );
        setPopupList(updatedPopups);
    };

    return (
        <div>
            {popupList.length !== 0 &&
                popupList.map(
                    (data, index) =>
                        shouldShowPopup(data) && (
                            <div
                                className={`${classes.modal} ${classes.popupModal}`}
                                key={index}
                            >
                                <section
                                    style={{
                                        height: isMobileCB
                                            ? "auto"
                                            : `${data.height}px`,
                                        width: isMobileCB
                                            ? `94vw`
                                            : `${data.width}px`,
                                        top: `${data.y}px`,
                                        left: isMobileCB
                                            ? "2vw"
                                            : `${data.x}px`,
                                    }}
                                >
                                    <header>
                                        <Button
                                            className="close_x"
                                            onClick={() =>
                                                handleClosePopup(data.id)
                                            }
                                        >
                                            close
                                        </Button>
                                    </header>
                                    <div
                                        onClick={() =>
                                            (window.location.href = data.url)
                                        }
                                    >
                                        <div className={classes.modal_body}>
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: data.content,
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <p className={classes.today_close}>
                                        <Checkbox
                                            className="official_st"
                                            id={`chk_${data.id}`} // Unique ID for each checkbox
                                            value="오늘 하루 보지 않기"
                                            title="오늘 하루 보지 않기"
                                        />
                                    </p>
                                </section>
                            </div>
                        )
                )}
        </div>
    );
};
