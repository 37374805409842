import { useContext, useState } from "react";
import Button from "../../../../components/UI/Button/Button";
import FileBox, { FileBoxForImage } from "../../../../components/UI/Input/FileBox";
import Input from "../../../../components/UI/Input/Input";
import Radio from "../../../../components/UI/Input/Radio";
import classes from "../../MngCommon.module.css";
import { useNavigate } from "react-router";
import Moment from 'moment';
import { ReqInsertKegel } from "../../../../api/kegel/KegelAPi";
import { ReqKegelBoardCreate } from "../../../../api/site/ApiForKegel";
import { JwtTokenContext } from "../../../../store/JwtTokenProvider";

function MngKegelContentsCreate() {

    const loginCtx = useContext(JwtTokenContext);
    const navigate = useNavigate();

    const backHandler = () => {
        navigate("/admin/site/kegel");
    };

    const [exposeYn, setExposeYn] = useState("Y");
    const [title, setTitle] = useState();
    const [content, setContent] = useState();
    const [url, setUrl] = useState();

    const changeTitleHandler = (value) => {
        setTitle(value);
    };

    const changeUrlHandler = (value) => {
        setUrl(value);
    };

    const exposeYnRadioButton = (e) => {
        setExposeYn(e.target.value)
    }

    const contentHandler = (value) => {
        setContent(value);
    };

    const [imageSrc, setImageSrc] = useState('');
    const [selectedFile, setSelectedFile] = useState();

    const encodeFileToBase64New = (fileBlob, fileUrl) => {
      setImageSrc(fileUrl);
      setSelectedFile(fileBlob);
    };

    const encodeFileRemove = () => {
      setImageSrc('');
      setSelectedFile(null)
    };

    const createHandler = async (e) => {
        e.preventDefault();
        
        if(!title) {
            alert("제목을 입력해주세요.");
            return
        }

        if(!url) {
            alert("URL을 입력해주세요.");
            return
        }

        if(!content) {
            alert("내용을 입력해주세요.");
            return
        }

        const isTrue = window.confirm('케겔를 등록하시겠습니까?');
        if(isTrue) {
            try {
                const jsonData = JSON.stringify({
                    exposeYn: exposeYn,
                    title: title,
                    content: content,
                    landingUrl: url,
                });

                const formData = new FormData();
      
                if (selectedFile) {
                    formData.append("image", selectedFile);
                }
            
                formData.append("body", new Blob([jsonData], { type: "application/json" }));

                const response = await ReqKegelBoardCreate(formData);
                if (response.status === 200) {
                    alert("케겔이 등록되었습니다.");
                    backHandler();
                } else {
                    alert("케겔 등록 실패");
                }
            } catch (error) {
                console.error("Error:", error);
            }
        } else {
          return
        }

        
    };

    return (
        <section>
            <div className={classes.admin_contents}>
                <h3>케겔 - 등록</h3>
                <div
                    className={`${classes.contents_wrap} ${classes.search_wrap} `}
                >
                    <div className={classes.admin_table_wrap}>
                        <table>
                            <colgroup>
                                <col style={{ width: "10%" }} />
                                <col style={{ width: "40%" }} />
                                <col style={{ width: "10%" }} />
                                <col style={{ width: "40%" }} />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>등록일</th>
                                    <td>{Moment(new Date()).format('YYYY-MM-DD')}</td>
                                    <th>등록자</th>
                                    <td>{loginCtx.decodedToken.jti}</td>
                                </tr>
                                <tr>
                                    <th>노출여부</th>
                                    <td colSpan={3}>
                                    <div className={classes.radio_wrap}>
                                            <Radio
                                                value="Y"
                                                title="노출"
                                                name="rad_01"
                                                id="rad_01_01"
                                                checked={exposeYn === "Y"} 
                                                onChange={exposeYnRadioButton}
                                            />
                                            <Radio
                                                value="N"
                                                title="미노출"
                                                name="rad_01"
                                                id="rad_01_02"
                                                checked={exposeYn === "N"} 
                                                onChange={exposeYnRadioButton}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>메인카피</th>
                                    <td colSpan={3}>
                                        <Input
                                            className="admin_st"
                                            onChange={changeTitleHandler}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>서브카피</th>
                                    <td colSpan={3}>
                                        <Input
                                            className="admin_st"
                                            onChange={contentHandler}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>썸네일 이미지</th>
                                    <td colSpan={3}>
                                        {FileBoxForImage (imageSrc, encodeFileToBase64New, encodeFileRemove)}
                                        {/* <div className={classes.flex_row}>
                                            <FileBox />
                                            <p className={classes.desc}>
                                                * 이미지 사이즈 000*000px
                                            </p>
                                        </div> */}
                                    </td>
                                </tr>
                                <tr>
                                    <th>랜딩 URL</th>
                                    <td colSpan={3}>
                                        <Input
                                            className="admin_st"
                                            onChange={changeUrlHandler}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className={classes.bottom_btn_wrap}>
                            <Button className="secondary sm" onClick={createHandler}>등록</Button>
                            <Button
                                className="border sm" onClick={backHandler}
                            >
                                목록
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default MngKegelContentsCreate;
