import { useState } from "react";
import classes from "./SelectBox.module.css";

function SelectBox(props) {
    // ↓ 퍼블단에서 화면 확인만을 위해 작성한 코드 입니다.
    const { optionData, className = "", readonly } = props;

    //optionData 에서 selected 값 확인하여 표출
    const selected = optionData.filter((data) => {
        return data.selected === "true";
    });
    //select 값
    const [showOptions, setShowOptions] = useState(false);
    const HandleShowOption = () => {
        //readonly 가 아닐때만 작동
        if (readonly !== true) {
            setShowOptions((current) => !current);
        }
    };
    //클래스명 조합
    const classNames = Array.isArray(className)
        ? className
        : className.split(" ");

    const combinedClasses = classNames
        .map((cn) => classes[cn])
        .filter((cn) => cn)
        .join(" ");

  return (
    <div
      className={`${classes.selectBox} ${combinedClasses} ${
        showOptions ? classes.on : ""
      }`}
      onClick={HandleShowOption}
    >
      <p>{props.value}</p>
      <ul>
        {optionData.map((data) => (
          <>
            <li
              key={data.key}
              id={data.key}
              value={data.value}
              //onClick={handleOnChangeValue}
              onClick={props.onClick}
              className={data.disabled}
              >
              {data.value}
              {data.state && (
                <span
                  onClick={(e) => {
                    e.stopPropagation();
                    e.currentTarget.parentNode.click();
                  }}
                >
                  - {data.state}
                </span>
              )}
            </li>
          </>
        ))}
      </ul>
    </div>
  );
}
export default SelectBox;
