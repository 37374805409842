import { useState } from "react";
import BreadCrumb from "../../components/UI/BreadCrumb/BreadCrumb";
import Button from "../../components/UI/Button/Button";
import PaginationNew from "../../components/UI/Table/PaginationNew";
import classes from "./Event.module.css";
import Input from "../../components/UI/Input/Input";
import instance from "../../store/AxiosInterceptor";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import EventWinnerListComponent from "./components/EventWinnerListComponent";
import { ReqPageingEventChosenList, ReqSearchEventChosenList } from "../../api/event/EventHomeApi";

function WinnersList() {
    const location = useLocation();
    //  breadCrumb sample data
    const breadCrumbList = ["이벤트", "진행중인 이벤트"];
    const [queryText, setQueryText] = useState(null);
    const changeQueryTextHandler = (key) => {
        setQueryText(key);
    };

    const [eventList, setEventList] = useState([]);

    const [totalPages, setTotalPages] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [pageNumber, setPageNumber] = useState(location.state?.pageNumber ?? 0);

    const pagingHandler = (value) => {
        setPageNumber(value);
        ReqPageingEventChosenList(
            queryText,
            value,
            (result) => {
                if (result.content) {
                    const curData = result.content.map((data) => {
                        return {
                            id: data.event_id,
                            title: data.event_title,
                            regDate: data.event_reg_date,
                            startDate: data.event_start_date,
                            endDate: data.event_end_date,
                            exposeChosenYn: data.expose_chosen_yn,
                            description: data.chosen_description,
                            seq: data.seq,
                        };
                    });

                    setEventList(curData);
                    setTotalCount(result.totalElements);
                    setPageNumber(result.pageable.pageNumber);
                    setTotalPages(result.totalPages);
                }
            },
            (error_result) => {
                setEventList((prev) => prev);
                setTotalCount((prev) => prev);
                setPageNumber((prev) => prev);
                setTotalPages((prev) => prev);
            }
        );
    };
    useEffect(() => {
        pagingHandler(location.state?.pageNumber ?? 0)
    }, [])
    const searchHandler = () => {
        setPageNumber(0);
        ReqSearchEventChosenList(
            queryText,
            (result) => {
                if (result.content) {
                    const curData = result.content.map((data) => {
                        return {
                            id: data.event_id,
                            title: data.event_title,
                            regDate: data.event_reg_date,
                            startDate: data.event_start_date,
                            endDate: data.event_end_date,
                            exposeChosenYn: data.expose_chosen_yn,
                            description: data.chosen_description,
                            seq: data.seq,
                        };
                    });

                    setEventList(curData);
                    setTotalCount(result.totalElements);
                    setPageNumber(result.pageable.pageNumber);
                    setTotalPages(result.totalPages);
                }
            },
            (error_result) => {
                setEventList((prev) => prev);
                setTotalCount((prev) => prev);
                setPageNumber((prev) => prev);
                setTotalPages((prev) => prev);
            }
        );
    };



    return (
        <>
            <BreadCrumb breadCrumbList={breadCrumbList} />
            <div className={classes.contents_wrap}>
                <div className={classes.contents_title}>
                    <h3>당첨자 발표</h3>
                    <div className={classes.search_wrap}>
                        <Input onChange={changeQueryTextHandler} value={queryText} />
                        <Button className="search_sm" onClick={searchHandler} />
                    </div>
                </div>
                <div className={classes.table_wrap}>
                    {eventList.length === 0 && (
                        <div className={classes.empty_data}>
                            <p>게시글이 없습니다.</p>
                        </div>
                    )}
                    {eventList.length > 0 && (
                        <>
                            <table className="cursor_default align_center align_left_col2">
                                <colgroup>
                                    <col style={{ width: "10%" }} />
                                    <col style={{ width: "51%" }} />
                                    <col style={{ width: "22%" }} />
                                    <col style={{ width: "17%" }} />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th>번호</th>
                                        <th>이벤트 제목</th>
                                        <th>이벤트 기간</th>
                                        <th>당첨자 발표</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {eventList.map((data, index) => (
                                        <EventWinnerListComponent
                                            index={index}
                                            id={data.id}
                                            title={data.title}
                                            seq={data.seq}
                                            startDate={data.startDate}
                                            endDate={data.endDate}
                                            exposeChosenYn={data.exposeChosenYn}
                                            description={data.description}
                                        />
                                    ))}
                                </tbody>
                            </table>
                            <PaginationNew
                                totalPage={totalPages}
                                currentPage={pageNumber}
                                changePageCB={pagingHandler}
                                defaultShowPage={5}
                            />
                        </>
                    )}
                </div>
            </div>
        </>
    );
}

export default WinnersList;

//이벤트 목록 리스트 가져오기
export const loader = async () => {
    const response = await instance({
        method: "GET",
        url: `/api/v1/home/event/chosen?page=0&size=10`,
    }).catch(function (error) {
        if (error.response) {
            return error.response;
        } else if (error.request) {
            return null;
        } else {
            return null;
        }
    });
    if (response == null || response.status !== 200) {
        return [];
    }
    if (response.data.status === 200) {
        return response.data.datas;
    } else {
        return [];
    }
};
//이벤트 목록 리스트 가져오기
