import React from "react";
import classes from "../../TournamentContainerApp.module.css";

const TournamentComponentApp = (props) => {
    let currentStatus = "";
    let currentStatusCss = "";
    let newCtType = "";

    if (props.ctProcessType) {
        if (props.ctProcessType === "GENERATED") {
            currentStatus = "진행예정";
            currentStatusCss = classes.to_be;
        } else if (props.ctProcessType === "CONTEST_END") {
            currentStatus = "종료";
            currentStatusCss = classes.end;
        } else {
            currentStatus = "진행중";
            currentStatusCss = classes.ing;
        }
    }

    switch (props.ctType) {
        case "K":
            newCtType = classes.type_a;
            break;
        case "L":
            newCtType = classes.type_b;
            break;
        case "A":
            newCtType = classes.type_c;
            break;
        case "B":
            newCtType = classes.type_d;
            break;
        case "S":
            newCtType = classes.type_e;
            break;
    }

    return (
        <div className={classes.card}>
            <div className={classes.title}>
                <b className={newCtType}>{props.ctTypeName}</b>
                <h3>{props.ctTitle}</h3>
                <p className={`${classes.state} ${currentStatusCss}`}>{currentStatus}</p>
            </div>
            <ul className={classes.info}>
                <li>
                    대회기간 : {props.ctStartDate} ~ {props.ctEndDate}
                </li>
                <li>
                    대회접수 : {props.ctAppStartTime} ~ {props.ctAppEndTime}
                </li>
                <li>대회지역 : {props.regionList.join("/")}</li>
            </ul>
        </div>
    );
};

export default TournamentComponentApp;
