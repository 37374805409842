import React, { useState } from "react";
import Alert from "../UI/Modal/Alert";
import { useNavigate } from "react-router-dom";

const CloseInicis = () => {
    const [showCancelModal, setShowCancelModal] = useState(true);
    const navigate = useNavigate();

    const modalHandler = (state) => {
        window.parent.INIStdPay.viewOff();
        setShowCancelModal(()=>state);
        navigate("/myPage/tournamentHistory");
    };

    return (
        <>
            {showCancelModal && (
                <Alert type="alert" confirm="확인" close={() => modalHandler(false)}>
                    {"결제가 취소되었습니다."}
                </Alert>
            )}
        </>
    );
};

export default CloseInicis;
