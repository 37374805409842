/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useMemo  } from 'react'
import classes from "./../Products.module.css";
import Button from '../../../components/UI/Button/Button';
import { ProductItem } from './ProductItem';
import Input from '../../../components/UI/Input/Input';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRef } from 'react';

export const ProductItemList = ({ 
    content = [], pagable = {}, last = true, first=true, totalElements = 0, categoryIdISValid = true, totalPages = 0, number = 0, title='', selectedInfo = [-1,-1],
    sort = {empty: true, unsorted: true, sorted: false}, numberOfElements = 0, empty = false, onItemClick = () => {}, nextPageHandler=(pg) => {},
    searchKeyword='', setSearchKeyword, searchHandler=() => {},
}) => {
          const emptyDiv = useMemo(() => {
            if(categoryIdISValid){
                return (<div className={classes.empty_data}>
                        <p>상품이 존재하지 않습니다.</p>
                        </div>);
            }
            return(<div style={{ padding: '20rem 0rem 4rem', textAlign: 'center', fontSize: '2.5rem', fontWeight: 'bold' }}>
                    <p>카테고리를 선택해 주세요</p> 
                    </div>);
          }, [selectedInfo]);
          const navigate = useNavigate();
          const location = useLocation();
          const searchBtnRef = useRef();

          return <>
          <div className={classes.contents_title}>
                    <h3>
                        {title}
                        <span className={classes.count}>
                            {totalElements}
                        </span>
                    </h3>
                    <div className={classes.search_wrap}>
                        <Input
                            type='search' 
                            onChange={setSearchKeyword} 
                            value={searchKeyword}
                            onKeyPress={(e) => {
                                if(e.key === 'Enter' && searchBtnRef.current) searchBtnRef.current?.click() }}
                         />
                        <Button 
                            forwardedRef={searchBtnRef} 
                            className="search_sm" 
                            onClick={()=>{
                                    navigate( searchKeyword.length > 0 ? `?searchKeyword=${searchKeyword}` : '');
                                    searchHandler()
                            }}
                        />
                    </div>
                </div>
          {content.length > 0 ?
            (<>
                
                <div className={classes.display_wrap}>
                    {content.map((item) => (
                    <ProductItem
                        key={item?.id}
                        onClick={() => onItemClick(item?.id)}
                        imgSrc={item['thumbnail_url']}
                        alt={item?.attachedFile?.originalFileName}
                        title={item.name}
                    />))}
                </div>
                <div className={classes.bottom_center_btn}>
                {!last && (<Button className="text_icon" onClick={nextPageHandler}>
                    더보기
                </Button>)}
            </div>
            </>):emptyDiv}
            </>
            ;
}