import React, { useState } from "react";
import Button from "../../../components/UI/Button/Button";
import Input from "../../../components/UI/Input/Input";
import Radio from "../../../components/UI/Input/Radio";
import classes from "../MngCommon.module.css";
import instance from "../../../store/AxiosInterceptor";
import { useLoaderData, useLocation, useNavigate } from "react-router-dom";
import { ReqUpdateKegelInfo } from "../../../api/kegel/KegelAPi";
import FileBoxMj from "../../../components/UI/Input/FileBoxMj";
import SelectBoxKegel from "../../../components/UI/SelectBox/SelectBoxKegel";
import QuillEditor from "../../../components/UI/Editer/QuillEditor";

function MngKegelInfoUpdate() {
    const location = useLocation();
    const navigate = useNavigate();
    const kegelData = useLoaderData();

    const toList = () => {
        navigate("/admin/kegel/info", {state: location.state});
    };
    const sampleData = [
        {
            value: "유용한 정보",
            data: "USEFUL_INFO",
            disabled: "false",
            selected: "false",
        },
        {
            value: "문제해결 도우미",
            data: "HELP",
            disabled: "false",
            selected: "false",
        },
    ];

    //[S] 수정 데이터 --------------------
    const [category, setCategody] = useState(kegelData.kegelGuideType);
    const [title, setTitle] = useState(kegelData.title);
    const [content, setContent] = useState(kegelData.content);
    const [exposeYn, setExposeYn] = useState(kegelData.exposeYn);
    const isExposeChangeHandler = (e) => {
        setExposeYn(e.target.value);
    };
    const [firstSignature, setFirstSignature] = useState(
        kegelData.firstSignature
    );
    const [secondSignature, setSecondSignature] = useState(
        kegelData.secondSignature
    );
    const [thirdSignature, setThirdSignature] = useState(
        kegelData.thirdSignature
    );
    const [subDescription, setSubDescription] = useState(kegelData.title);
    const [file, setFile] = useState();
    const [fileName, setFileName] = useState(kegelData.attach_uri || "");
    const categoryHandler = (value) => {
        setCategody(value);
    };
    const titleHandler = (value) => {
        setTitle(value);
    };
    const contentHandler = (value) => {
        setContent(value);
    };

    const subDescriptionHandler = (value) => {
        setSubDescription(value);
    };
    const handleFileChange = (file) => {
        setFile(file);
        setFileName(file ? file.name : "");
    };

    //[E] 수정 데이터 --------------------

    const selectedCategoryValue =
        sampleData.find((item) => item.data === kegelData.kegelGuideType)
            ?.value || "";

    const [selectedCategory, setSelectedCategory] = useState(
        selectedCategoryValue
    );
    const [selectedCategoryData, setSelectedCategoryData] = useState(
        kegelData.kegelGuideType
    );

    const editHandler = async (e) => {
        e.preventDefault();

        const jsonData = JSON.stringify({
            title: title,
            content: content,
            exposeYn: exposeYn,
            subDescription: subDescription,
            kegelGuideType: selectedCategoryData,
        });

        const formData = new FormData();
        formData.append("image", file);
        formData.append(
            "body",
            new Blob([jsonData], { type: "application/json" })
        );

        const response = await ReqUpdateKegelInfo(kegelData.id, formData);
        if (response.status === 200) {
            navigate("/admin/kegel/info", {state: location.state});
        } else {
            alert(
                `[${response.status}] ${
                    response.data ? response.data.error : "수정 실패"
                } `
            );
        }
    };
    return (
        <section>
            <div className={classes.admin_contents}>
                <h3>유용한 정보 / 문제해결 도우미 - 수정</h3>
                <div
                    className={`${classes.contents_wrap} ${classes.search_wrap} `}
                >
                    <div className={classes.admin_table_wrap}>
                        <table>
                            <colgroup>
                                <col style={{ width: "159px" }} />
                                <col style={{ width: "620px" }} />
                                <col style={{ width: "159px" }} />
                                <col style={{ width: "620px" }} />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>등록일</th>
                                    <td>{kegelData.regDate}</td>
                                    <th>등록자</th>
                                    <td>{kegelData.regID}</td>
                                </tr>
                                <tr>
                                    <th>노출여부</th>
                                    <td colSpan={3}>
                                        <div className={classes.radio_wrap}>
                                            <Radio
                                                value="Y"
                                                title="노출"
                                                name="rad_01"
                                                id="rad_01_01"
                                                checked={exposeYn === "Y"}
                                                onChange={isExposeChangeHandler}
                                            />
                                            <Radio
                                                value="N"
                                                title="미노출"
                                                name="rad_01"
                                                id="rad_01_02"
                                                checked={exposeYn === "N"}
                                                onChange={isExposeChangeHandler}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>구분</th>
                                    <td colSpan={3}>
                                        <SelectBoxKegel
                                            className="admin_st"
                                            optionData={sampleData}
                                            selectedValue={selectedCategory}
                                            setSelectedValue={
                                                setSelectedCategory
                                            }
                                            selectedData={selectedCategoryData}
                                            setSelectedData={
                                                setSelectedCategoryData
                                            }
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>제목</th>
                                    <td colSpan={3}>
                                        <Input
                                            value={title}
                                            onChange={titleHandler}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>파일첨부</th>
                                    <td colSpan={3}>
                                        <FileBoxMj
                                            onFileChange={handleFileChange}
                                            selectedFileName={fileName}
                                        />
                                    </td>
                                </tr>

                                <tr>
                                    <th>사용자페이지 URL</th>
                                    <td colSpan={3}>
                                        https://bowlingkorea.com/kegel/laneMachinesView.do?ntt_id=43202&currRow=1
                                    </td>
                                </tr>
                                <tr>
                                    <th>상세정보</th>
                                    <td colSpan={3}>
                                        <div style={{ height: "500px" }}>
                                            <QuillEditor
                                                height="400"
                                                width="auto"
                                                onValueChangeCB={contentHandler}
                                                value={content}
                                            />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <div className={classes.bottom_btn_wrap}>
                            <Button className="border sm" onClick={editHandler}>
                                수정
                            </Button>
                            <Button className="secondary sm" onClick={toList}>
                                목록
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default MngKegelInfoUpdate;
export const loader = async ({ params }) => {
    try {
        const response = await instance.get(
            `/api/v1/admin/kegel/guide/${params.kegelId}`,
            {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            }
        );

        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error("Failed to fetch data");
        }
    } catch (error) {
        console.error("Error fetching data:", error);
        return null;
    }
};
