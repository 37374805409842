import { useState } from "react";
import FormSignIn from "../../components/auth/FormSignIn";
import classes from "./SignApp.module.css";
import Button from "../../components/UI/Button/Button";
import Checkbox from "../../components/UI/Input/Checkbox";
import { Link, useNavigate } from "react-router-dom";

function SignInAppPage() {
    const navigate = useNavigate();
    const navigateHandler = () => {
        navigate("/find");
    };

    return (
        <div className={`container ${classes.sign_container}`}>
            <div className={classes.sign_in_wrap}>
                <div className={classes.title}>
                    <h2>로그인</h2>
                </div>
                <div className={classes.sign_in_box}>
                    <FormSignIn className="official_st"/>
                    {/* <div className={classes.save_id}>
                        <Checkbox
                            className="official_st"
                            id="chk_11"
                            title="아이디 저장"
                            checked={saveId === true}
                            onChange={saveIdHandler}
                        />
                    </div> */}
                    <div className={classes.find_btn_wrap}>
                        <Link to="/signUp">
                            <Button className="text_underline">회원가입</Button>
                        </Link>
                        <Button className="text_underline" onClick={navigateHandler}>
                            아이디/비밀번호 찾기
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SignInAppPage;
