import { useEffect, useState } from "react";
import BreadCrumb from "../../components/UI/BreadCrumb/BreadCrumb";
import classes from "./Introduce.module.css";
import Input from "../../components/UI/Input/Input";
import Button from "../../components/UI/Button/Button";
import staff_1 from "../../assets/images/staff_1.png";
import staff_2 from "../../assets/images/staff_2.png";
import staff_3 from "../../assets/images/staff_3.png";
import staff_4 from "../../assets/images/staff_4.png";
import Modal from "../../components/UI/Modal/Modal";
import instance from "../../store/AxiosInterceptor";
import { useLoaderData } from "react-router";
import IntroStaffsModal from "./IntroStaffsModal";
function IntroStaffsPage(props) {
    const staff = useLoaderData();

    //  breadCrumb sample data
    const breadCrumbList = ["회사소개", "STAFF"];

    const tabArr = [
        { id: 0, title: "프로스텝", key: "professional" },
        { id: 1, title: "아마추어스텝", key: "amateur" },
    ];

    const [tabIndex, setTabIndex] = useState(0);
    const [tabKey, setTabKey] = useState(tabArr[0].key);
    const tabToggle = (index, key) => {
        // console.log("", index, key);
        setTabIndex(index);
        setTabKey(key);
        setSearchStaffs([]);
    };

    // modal
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [staffData, setStaffData] = useState(false);

    const openModalHandler1 = (e, data) => {
        e.stopPropagation();
        setStaffData(data);
        setIsModalOpen(true);
    };

    const [currentPage, setCurrentPage] = useState(0);
    const [totalPage, setTotalPage] = useState(staff.totalPage); //총 페이지 갯수
    const [totalCount, setTotalCount] = useState(staff.totalCount); //총 페이지 갯수

    const [staffs, setStaffs] = useState(staff.data);
    const [searchStaffs, setSearchStaffs] = useState([]);

    const [searchText, setSearchText] = useState();

    const searchHandler = (key) => {
        setSearchText(key);
    };

    const handleTapChange = async () => {
        // console.log(tabKey);
        const searchParam = {
            page: 0,
            query_type: tabKey,
        };
        setSearchText("");
        setCurrentPage(0);
        setSearchStaffs([]);

        const response = await instance.get("/api/v1/home/member/staff", {
            params: searchParam,
        });
        // console.log(response);
        if (response.status === 200) {
            const resData = await response.data.data;
            setTotalPage(resData.totalPages);
            setTotalCount(resData.totalElements);
            setStaffs(resData.content);
        } else {
            setStaffs([]);
        }
    };

    const handlePageChange = async () => {
        const page = currentPage + 1;
        const searchParam = {
            page: page,
            query_type: tabKey,
            query_text: searchText,
        };
        setCurrentPage(page);
        const response = await instance.get("/api/v1/home/member/staff", {
            params: searchParam,
        });
        if (response.status === 200) {
            const resData = await response.data.data;
            setTotalPage(resData.totalPages);
            setTotalCount(resData.totalElements);
            setStaffs((prev) => [...prev, ...resData.content]);
        } else {
            setStaffs((prev) => prev);
        }
    };

    const handleSearchPageChange = async () => {
        const page = currentPage + 1;
        const searchParam = {
            page: page,
            query_type: tabKey,
            query_text: searchText,
        };
        setCurrentPage(page);
        const response = await instance.get("/api/v1/home/member/staff", {
            params: searchParam,
        });
        if (response.status === 200) {
            const resData = await response.data.data;
            setTotalPage(resData.totalPages);
            setTotalCount(resData.totalElements);
            setSearchStaffs((prev) => [...prev, ...resData.content]);
            setStaffs([]);
        } else {
            setSearchStaffs((prev) => prev);
        }
    };

    const findSearchHandler = async () => {
        setCurrentPage(0);
        setSearchStaffs([]);
        const searchParam = {
            page: currentPage,
            query_type: tabKey,
            query_text: searchText,
        };

        const response = await instance.get("/api/v1/home/member/staff", {
            params: searchParam,
        });
        if (response.status === 200) {
            const resData = await response.data.data;
            setTotalPage(resData.totalPages);
            setTotalCount(resData.totalElements);
            setSearchStaffs(resData.content);
            setStaffs([]);
        } else {
            setSearchStaffs([]);
        }
    };

    useEffect(() => {
        handleTapChange();
    }, [tabKey]);

    const transformedStaff = () => {
        return (
            <>
                <div className={classes.inner}>
                    {staffs.length !== 0 &&
                        staffs.map((data, index) => (
                            <div
                                key={`staff_${index}`}
                                className={classes.staff_box}
                                onClick={(e) => openModalHandler1(e, data)}
                            >
                                <img
                                    src={data.thumbnail_url}
                                    alt={data.original_name}
                                />
                                <div className={classes.info_txt}>
                                    <b>{data.staff_name}</b> {data.team}
                                </div>
                            </div>
                        ))}
                </div>
                {totalCount !== staffs.length && (
                    <div className={classes.bottom_btn_wrap}>
                        <Button
                            className="text_icon"
                            onClick={handlePageChange}
                        >
                            더보기
                        </Button>
                    </div>
                )}
            </>
        );
    };

    return (
        <>
            <BreadCrumb breadCrumbList={breadCrumbList} />
            <div className={classes.contents_wrap}>
                <div className={classes.contents_title}>
                    <h3>STAFF</h3>
                    <div className={classes.search_wrap}>
                        <Input onChange={searchHandler} value={searchText} />
                        <Button
                            className="search_sm"
                            onClick={findSearchHandler}
                        />
                    </div>
                </div>
                <ul className={classes.tab_menu}>
                    {tabArr.map((item) => (
                        <li
                            key={item.id}
                            className={tabIndex === item.id && classes.on}
                            onClick={() => tabToggle(item.id, item.key)}
                        >
                            {item.title}
                        </li>
                    ))}
                </ul>
                <div className={classes.staff_box_wrap}>
                    {staffs.length === 0 ? (
                        <>
                            {/* 검색결과 화면 */}
                            <div className={classes.inner}>
                                {searchStaffs.map((data, index) => (
                                    <div
                                        key={`staff_${index}`}
                                        className={classes.staff_box}
                                        onClick={(e) =>
                                            openModalHandler1(e, data)
                                        }
                                    >
                                        <img
                                            src={data.thumbnail_url}
                                            alt={data.original_name}
                                        />
                                        <div className={classes.info_txt}>
                                            <b>{data.staff_name}</b> {data.team}
                                        </div>
                                    </div>
                                ))}
                            </div>
                            {searchStaffs.length === 0 && (
                                <div className={classes.empty_data}>
                                    <p>검색결과가 없습니다.</p>
                                </div>
                            )}
                            {totalCount !== searchStaffs.length && (
                                <div className={classes.bottom_btn_wrap}>
                                    <Button
                                        className="text_icon"
                                        onClick={handleSearchPageChange}
                                    >
                                        더보기
                                    </Button>
                                </div>
                            )}
                        </>
                    ) : (
                        <>
                            {tabIndex === 0 && transformedStaff()}
                            {tabIndex === 1 && transformedStaff()}
                        </>
                    )}
                </div>
            </div>
            {isModalOpen && (
                <IntroStaffsModal
                    isModalOpen={setIsModalOpen}
                    staffDataCB={staffData}
                />
            )}
        </>
    );
}

export default IntroStaffsPage;

export const loader = async () => {
    const response = await instance.get(
        `/api/v1/home/member/staff?page=0&query_type=professional`
    );

    if (response.data.status === 200) {
        const resData = await response.data.data;
        return {
            data: resData.content,
            totalPage: resData.totalPages,
            totalCount: resData.totalElements,
        };
    } else {
        return [];
    }
};
