import React from "react";
import classes from "../../TournamentContainerApp.module.css";

/**
 * 대회 > 대회상세 > 접수결과 및 레인 > 레인 배치
 * @param {*} param0
 * @returns
 */
const ApplyTournamentLaneResultApp = ({ datas }) => {
    function maskString(str) {
        if (str.length <= 2) {
            return str.substring(0, 1) + "*"; // 첫 번째 글자 + '*'
        }

        const firstChar = str.charAt(0);
        const lastChar = str.charAt(str.length - 1);
        const middleMask = "*".repeat(str.length - 2);

        return firstChar + middleMask + lastChar;
    }

    // map <String(lane), [object,...]> 래인단위로 묶어준다. 필요없는 데이터 필터링 추가
    const mapByLane = new Map();
    datas.forEach((element) => {
        if (!element.lane) return;
        if (!element.applyStatus) return;
        if (
            element.applyStatus === "chosen_1" ||
            element.applyStatus === "chosen_9" ||
            element.applyStatus === "chosen" ||
            element.applyStatus === "complete"
        ) {
            let list = mapByLane.get(element.lane);
            if (!list) {
                list = [];
                mapByLane.set(element.lane, list);
            }
            list.push(element);
        }
    });
    // map <String(lane), [object,...]> 래인단위로 묶은 데이터를 Sorting
    mapByLane.forEach((values, keys) => {
        values.sort((a, b) => a.laneSeq - b.laneSeq); // seq 낮은 순대로
    });

    const piece = function (leftData) {
        const leftNewMemberName = leftData ? maskString(leftData.memberName) : "";
        const leftNewMemberId = leftData
            ? leftData.memberId.substring(0, 3) + "*".repeat(leftData.memberId.length - 3)
            : "";
        return (
            <tr key={leftData ? `${leftData.lane}-${leftData.laneSeq}` : ""}>
                <td>{leftData ? `${leftData.lane}-${leftData.laneSeq}` : ""}</td>
                <td>{leftData ? `${leftNewMemberName}(${leftNewMemberId})` : ""}</td>
                <td>{leftData ? (leftData.gender === "M" ? "남" : "여") : ""}</td>
                <td>{leftData ? leftData.handicap : ""}</td>
            </tr>
        );
    };

    const viewOfLaneGroup = function (group1Num, group2Num, group1, group2) {
        if (!group1 && !group2) return null;
        const size1 = group1 ? group1.length : 0;
        const size2 = group2 ? group2.length : 0;
        const maxIdx = size1 - size2 > 0 ? size1 : size2;
        let groupView = [];
        for (let i = 0; i < maxIdx; ++i) {
            groupView.push(piece(i >= size1 ? null : group1[i]));
        }
        for (let i = 0; i < maxIdx; ++i) {
            groupView.push(piece(i >= size2 ? null : group2[i]));
        }
        return (
            <>
                <h4>{`${group1Num}/${group2Num}`} 레인</h4>
                <div className={`table_wrap ${classes.mobile_table_2}`}>
                    <table className="align_center">
                        <colgroup>
                            <col style={{ width: "19%" }} />
                            <col style={{ width: "43%" }} />
                            <col style={{ width: "19%" }} />
                            <col style={{ width: "19%" }} />
                        </colgroup>
                        <thead>
                            <tr>
                                <th>레인</th>
                                <th>이름(아이디)</th>
                                <th>성별</th>
                                <th>핸디</th>
                            </tr>
                        </thead>
                        <tbody>{groupView}</tbody>
                    </table>
                </div>
            </>
        );
    };

    const viewDatas = [];
    for (let i = 1; i < 100; i += 2) {
        const view = viewOfLaneGroup(i, i + 1, mapByLane.get(String(i)), mapByLane.get(String(i + 1)));
        if (view) viewDatas.push(view);
    }

    return (
        <>
            {viewDatas.length > 0 ? (
                viewDatas
            ) : (
                <div className={`table_wrap ${classes.mobile_table_2}`}>
                    <table className="align_center">
                        <colgroup>
                            <col style={{ width: "19%" }} />
                            <col style={{ width: "43%" }} />
                            <col style={{ width: "19%" }} />
                            <col style={{ width: "19%" }} />
                        </colgroup>
                        <thead>
                            <tr>
                                <th>레인</th>
                                <th>이름(아이디)</th>
                                <th>성별</th>
                                <th>핸디</th>
                            </tr>
                        </thead>
                        <tbody></tbody>
                    </table>
                </div>
            )}
        </>
    );
};

export default ApplyTournamentLaneResultApp;
