import { useEffect, useState } from "react";
import Button from "../../../../components/UI/Button/Button";
import classes from "../../MngCommon.module.css";
import Radio from "../../../../components/UI/Input/Radio";
import Input from "../../../../components/UI/Input/Input";
import Modal from "../../../../components/UI/Modal/Modal";
import Checkbox from "../../../../components/UI/Input/Checkbox";
import { ReqSmartFilterDetail, ReqSmartFilterItemUpdate, ReqSmartFiltermenuUpload, ReqqSmartFilterMenuItem } from "../../../../api/product/SmartFilterApi";
import SelectBoxNew from "../../../../components/UI/SelectBox/SelectBoxNew";
import { useLoaderData, useLocation, useNavigate } from "react-router-dom";
import { dropDownType, itemFilterType, productCategoryTypes } from "../../../../components/definedefault/DefSelectBoxValues";

function MngProductSmartFilterUpdate(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const smartFilter = useLoaderData();
    const [smartFilterData, setSmartFilterData] = useState(smartFilter);

    const [smartFilterMenuItemData, setSmartFilterMenuItemData] = useState(null);

    const toList = () => {
        navigate("/admin/product/smartFilter", { state: location.state });
    };
    const [newItemTitle, setNewItemTitle] = useState(""); // 새로운 항목의 제목 상태
    const [newItemSeq, setNewItemSeq] = useState(0); // 새로운 항목의 순서 상태

    const [isModalOpen1, setIsModalOpen1] = useState(false);

    const closeModalHandler1 = (e) => {
        e.stopPropagation();
        setIsModalOpen1(false);
    };

    const [navArr, setNavArr] = useState([]);

    const [title, setTitle] = useState(smartFilterData.title);
    const titleHandler = (value) => {
        setTitle(value);
    };

    const [dropDownFilterType, setDropDownFilterType] = useState();

    const [dropAndRadioValue, setDropAndRadioValue] = useState({ dropYn: false, dropDownFilterType: null });

    const dropDownFilterTypeHandler = (key) => {

        setDropDownFilterType(key);
        const navCount = parseInt(key); // 선택한 "1항목", "2항목", "3항목"에 따른 항목 개수

        // 항목 개수에 따라 navArr 배열을 생성
        const newNavArr = Array.from({ length: navCount }, (_, index) => ({
            id: index,
            title: `항목${index + 1}`,
        }));

        setNavArr(newNavArr);
    };

    const [itemType, setItemType] = useState();
    const itemTypeHandler = (key) => {
        setItemType(key);
    };

    const [categoryType, setCategoryType] = useState(smartFilter.categoryId.toString());

    const categoryTypeHandler = (key) => {
        setCategoryType(key);
    };

    const expose_yn = smartFilter.exposeYn === true ? "Y" : "N";

    const [exposeYn, setExposeYn] = useState(expose_yn);
    const isExposeChangeHandler = (e) => {
        setExposeYn(e.target.value);
    };
    // id와 seq를 관리할 상태 변수 추가
    const [nextId, setNextId] = useState(1);
    const [navIndex, setNavIndex] = useState(0);

    

    // 초기값 설정
    const [filteredData, setFilteredData] = useState([]);

    const navToggle = (index) => {
        setNavIndex(index); // 선택한 탭의 인덱스를 상태에 저장
    
        const filteredData = smartFilterMenuItemData.data.filter((item) => {
            return item.tab === index + 1;
        });
        setFilteredData(filteredData);
    };
    

    const editHandler = async (e) => {
        e.preventDefault();

        const smartFilterMenuData = smartFilterData.smartFilterMenus.map((menu) => ({
            id: menu.id,
            title: menu.title,
        }));

        const editSmartFilterMenuData = Object.entries(editedSmartFilterTitles).map(([id, title]) => ({
            id: parseInt(id),
            title,
        }));

        const lastSmartFilterMenuData = [
            ...editSmartFilterMenuData.map((editItem) => ({
                title: editItem.title,
            })),

            ...smartFilterMenuData
                .filter((menu) => {
                    const isEdited = editSmartFilterMenuData.some((editItem) => editItem.id === menu.id);
                    return !isEdited;
                })
                .map((menu) => ({
                    id: menu.id,
                    title: menu.title,
                })),
        ];

        const extInfoMenuData = smartFilterData.extInformationMenus.map((item) => ({
            id: item.id,
            title: item.title,
        }));

        const editProdcutMenuData = Object.entries(editedProductInfoTitles).map(([id, title]) => ({
            id: parseInt(id),
            title,
        }));

        const lastProductMenuData = [
            ...editProdcutMenuData.map((editItem) => ({
                title: editItem.title,
            })),
            ...extInfoMenuData
                .filter((menu) => {
                    return !editProdcutMenuData.find((editItem) => editItem.id === menu.id);
                })
                .map((menu) => ({
                    title: menu.title,
                })),
        ];

        const jsonData = {
            category_id: parseInt(categoryType),
            id: smartFilterData.id,
            title: title,
            expose_yn: exposeYn,
            smart_filter_menu: lastSmartFilterMenuData,
            ext_info_menu: lastProductMenuData,
        };

        const response = await ReqSmartFiltermenuUpload(jsonData);

        if (response.status === 200) {
            navigate("/admin/product/smartFilter", { state: location.state });
        } else {
            alert(`[${response.status}] ${response.data ? response.data.error : "등록 실패"} `);
        }
    };

    const deleteExtInformationItem = (id) => {
        const updatedExtInformationMenus = smartFilterData.extInformationMenus.filter((menu) => menu.id !== id);
        setSmartFilterData({
            ...smartFilterData,
            extInformationMenus: updatedExtInformationMenus,
        });
    };

    const deleteSmartFilterItem = (id) => {
        const updatedItems = smartFilterData.smartFilterMenus.filter((menu) => menu.id !== id);
        setSmartFilterData({
            ...smartFilterData,
            smartFilterMenus: updatedItems,
        });
    };

    // 항목 추가 함수
    const addItem = () => {
        if (!itemType) {
            alert("항목을 선택하세요.");
            return;
        }

        const newItem = {
            id: nextId,
            title: newItemTitle,
            seq: newItemSeq,
        };

        // id와 seq 업데이트
        setNextId(nextId + 1);
        setNewItemSeq(newItemSeq + 1);

        // 항목 추가
        let updatedItems;
        if (itemType === "smartFilter") {
            updatedItems = {
                ...smartFilterData,
                smartFilterMenus: [...smartFilterData.smartFilterMenus, newItem],
            };
        } else if (itemType === "extInformation") {
            updatedItems = {
                ...smartFilterData,
                extInformationMenus: [...smartFilterData.extInformationMenus, newItem],
            };
        }
        setSmartFilterData(updatedItems);
        // 추가 후 초기화
        setNewItemTitle("");
    };

    const [currentFilterMenuId, setCurrentFilterMenuId] = useState(null);

    // 필터 값 설정 버튼 클릭
    const smartFilterModalHandler = async (id) => {
        try {
            if (parseInt(id) < nextId) {
                alert("스마트필터는 항목을 저장한 후에 필터값 설정 가능합니다.\n먼저 수정 버튼을 눌러주세요.");
                return;
            }
            const response = await ReqqSmartFilterMenuItem(id);

            if (response.status === 200) {

                setSmartFilterMenuItemData(response);

                const extractedId = id;
                setCurrentFilterMenuId(extractedId);

                setIsModalOpen1(true);
            } else {
                console.error("필터 값 설정 데이터를 불러오는 데 실패했습니다.");
            }
        } catch (error) {
            console.error("필터 값 설정 데이터를 불러오는 데 오류가 발생했습니다.", error);
        }
    };

    const [smartFilterMenuItemDataTitle, setSmartFilterMenuItemDataTitle] = useState("");
    const [dropYn, setDropYn] = useState("");

    
    // ----------------------------------------------------------------
    // [S] useEffect
    // ----------------------------------------------------------------

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetchDropYnFromDb();
                const data = await response.json();
                setDropYn(data.dropYn);
            } catch (error) {
                console.error("Failed to fetch dropYn data from db", error);
            }
        };
        fetchData();
    }, []);


    useEffect(() => {
        if (isModalOpen1) {
            setDropYn(smartFilterMenuItemData.drop === true ? "Y" : "N");
            setNavIndex(0);
            setDropDownFilterType(smartFilterMenuItemData.tab_size.toString());
            setSmartFilterMenuItemDataTitle(smartFilterMenuItemData.title);

            const navCount = parseInt(smartFilterMenuItemData.tab_size);
            const newNavArr = Array.from({ length: navCount }, (_, index) => ({
                id: index,
                title: `항목${index + 1}`,
            }));
            setNavArr(newNavArr);
        }
    }, [isModalOpen1]);

    useEffect(() => {
        if (smartFilterMenuItemData) {
            setDropYn(smartFilterMenuItemData.drop === true ? "Y" : "N");
            // setNavIndex(0);
            setDropDownFilterType(smartFilterMenuItemData.tab_size.toString());
            setSmartFilterMenuItemDataTitle(smartFilterMenuItemData.title);

            const navCount = parseInt(smartFilterMenuItemData.tab_size);
            const newNavArr = Array.from({ length: navCount }, (_, index) => ({
                id: index,
                title: `항목${index + 1}`,
            }));
            setNavArr(newNavArr);
        }
    }, [smartFilterMenuItemData]);

    // ----------------------------------------------------------------
    // [E] useEffect
    // ----------------------------------------------------------------

    const dropChangeHandler = (e) => {
        setDropYn(e.target.value);
        // 라디오 버튼 값이 변경될 때 navArr를 업데이트
        if (e.target.value === "Y") {
            const newNavArr = Array.from({ length: parseInt(dropDownFilterType) }, (_, index) => ({
                id: index,
                title: `항목${index + 1}`,
            }));
            setNavArr(newNavArr);
        } else {
            setNavArr([]); // '선택'이면 navArr을 빈 배열로 초기화
        }
        // 라디오 버튼 값이 "N"이면 selectbox 값을 "선택"으로 변경
        if (e.target.value === "N") {
            setDropDownFilterType(null);
        }
    };

    const [addFilter, setAddFilter] = useState([]);

    const [editedSmartFilterTitles, setEditedSmartFilterTitles] = useState({});
    const [editedProductInfoTitles, setEditedProductInfoTitles] = useState({});

    // 추가 필터 값 입력 이벤트 핸들러
    const addFilterHandler = (e) => {
        const lines = e.target.value.split("\n");
        // 각 줄을 항목으로 변환
        const filters = lines.map((line, index) => ({
            id: index,
            tab: navIndex + 1,
            name: line.trim(),
            isUse: null,
        }));
        setAddFilter(filters);
    };

    // =================================================================
    // 추가하기 버튼
    // =================================================================
    const addFilterOnClickHandler = () => {
        const newFilterValue = document.getElementById("filterTextarea").value.trim(); 

        if (!newFilterValue) {
            alert("추가할 필터 값을 입력하세요.");
            return;
        }
    
        // 현재 선택한 탭에 맞춰서 tab 값을 설정, 공백만 입력 시 취급X
        const tabValue = navIndex + 1;

        const lines = newFilterValue.split("\n");
        const filters = lines.map((line, index) => ({
            tab: tabValue,
            name: line.trim(),
            isUse: null,
        }));

        const existingFilterNames = new Set(smartFilterMenuItemData.data.map((item) => item.name));
        const uniqueFilters = filters.filter((filter) => !existingFilterNames.has(filter.name));
    
        if (uniqueFilters.length === 0) {
            alert("중복된 필터 값입니다.");
            return;
        }

        const updatedData = {
            ...smartFilterMenuItemData,
            tab_size: navArr.length,
            drop: dropYn == 'Y' ? true : false,
            data: [
                ...smartFilterMenuItemData.data,
                ...uniqueFilters,
            ],
        };
    
        setSmartFilterMenuItemData(updatedData);
        document.getElementById("filterTextarea").value = "";
    
        const duplicatesExcluded = filters.length - uniqueFilters.length;
        if (duplicatesExcluded > 0) {
            alert(`필터값이 추가되었습니다. (중복 값 ${duplicatesExcluded}건 제외)`);
        } else {
            alert("필터값이 추가되었습니다.");
        }
    };
    
    const toggleCheckbox = (id) => {
        const updatedData = smartFilterMenuItemData.data.map((item) => {
            if (item.id === id) {
                return {
                    ...item,
                    checked: !item.checked,
                };
            }
            return item;
        });
    
        setSmartFilterMenuItemData((prevState) => ({
            ...prevState,
            data: updatedData,
        }));
    };
    
    const deleteSelectedData = () => {
        const updatedData = smartFilterMenuItemData.data.filter((item) => !item.checked);
        setSmartFilterMenuItemData({
            ...smartFilterMenuItemData,
            data: updatedData,
        });
    };

    const showDeleteAlert = () => {
        if (window.confirm("필터 값을 삭제하시겠습니까?")) {
            deleteSelectedData();
        }
    };
    const smartFilterItemUpdateHandler = async (e) => {
        
        try {
            if (dropYn === 'Y' && dropDownFilterType === '0'){
                alert('항목 개수를 선택해주세요.')
            }
            const updatedData = {
                ...smartFilterMenuItemData,
                drop: dropYn,
                tab_size: dropDownFilterType
            };

            const response = await ReqSmartFilterItemUpdate(currentFilterMenuId, updatedData);

            if (response.status === 200) {
                setIsModalOpen1(false);
            } else {
                console.error("필터 값 설정 데이터를 불러오는 데 실패했습니다.");
            }
        } catch (error) {
            console.error("필터 값 설정 데이터를 불러오는 데 오류가 발생했습니다.", error);
        }
    };

    const handleSmartFilterTitleChange = (e, id) => {
        setEditedSmartFilterTitles((prevEditedTitles) => ({
            ...prevEditedTitles,
            [id]: e,
        }));
    };
    const handleEditedProductInfoTitleChange = (e, id) => {
        setEditedProductInfoTitles((prevEditedTitles) => ({
            ...prevEditedTitles,
            [id]: e,
        }));
    };

    return (
        <section>
            <div className={classes.admin_contents}>
                <h3>상품정보/스마트필터관리 - 수정</h3>
                <div className={`${classes.contents_wrap} ${classes.search_wrap} `}>
                    <div className={classes.admin_table_wrap}>
                        <table>
                            <colgroup>
                                <col style={{ width: "159px" }} />
                                <col style={{ width: "620px" }} />
                                <col style={{ width: "159px" }} />
                                <col style={{ width: "482px" }} />
                                <col style={{ width: "138px" }} />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>등록일</th>
                                    <td>{smartFilter.regDate}</td>
                                    <th>등록자</th>
                                    <td colSpan={2}>{smartFilter.regID}</td>
                                </tr>
                                <tr>
                                    <th>사용여부</th>
                                    <td colSpan={4}>
                                        <div className={classes.radio_wrap}>
                                            <Radio
                                                value="Y"
                                                title="사용"
                                                name="rad_01"
                                                id="rad_01_01"
                                                checked={exposeYn === "Y"}
                                                onChange={isExposeChangeHandler}
                                            />
                                            <Radio
                                                value="N"
                                                title="미사용"
                                                name="rad_01"
                                                id="rad_01_02"
                                                checked={exposeYn === "N"}
                                                onChange={isExposeChangeHandler}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>적용 카테고리</th>
                                    <td colSpan={4}>
                                        <SelectBoxNew
                                            className="admin_st"
                                            setSelectItemCB={categoryTypeHandler}
                                            optionDatas={productCategoryTypes}
                                            showDefaultKey={categoryType}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>제목</th>
                                    <td colSpan={4}>
                                        <Input value={title} onChange={titleHandler} />
                                    </td>
                                </tr>
                                <tr className={classes.th_st}>
                                    <th>구분</th>
                                    <th>항목</th>
                                    <th colSpan={2}>유형</th>
                                    <th>삭제</th>
                                </tr>
                                {smartFilterData.smartFilterMenus.map((menu, index) => (
                                    <tr key={menu.id}>
                                        {index === 0 && <th rowSpan={smartFilterData.smartFilterMenus.length}>스마트필터</th>}
                                        <td>
                                            <Input
                                                className="admin_st w_100"
                                                value={editedSmartFilterTitles[menu.id] !== undefined ? editedSmartFilterTitles[menu.id] : menu.title}
                                                onChange={(e) => handleSmartFilterTitleChange(e, menu.id)}
                                            />
                                        </td>
                                        <td colSpan={2} className={classes.align_center}>
                                            <Button className="border sm" onClick={() => smartFilterModalHandler(menu.id)}>
                                                필터 값 설정
                                            </Button>
                                        </td>
                                        <td className={classes.align_center}>
                                            <Button className="sm" onClick={() => deleteSmartFilterItem(menu.id, "smartFilter")}>
                                                삭제
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                                {smartFilterData.extInformationMenus.map((menu, index) => (
                                    <tr key={menu.id}>
                                        {index === 0 && <th rowSpan={smartFilterData.extInformationMenus.length}>상품정보</th>}
                                        <td>
                                            <Input
                                                className="admin_st w_100"
                                                value={editedProductInfoTitles[menu.id] !== undefined ? editedProductInfoTitles[menu.id] : menu.title}
                                                onChange={(e) => handleEditedProductInfoTitleChange(e, menu.id)}
                                            />
                                        </td>
                                        <td colSpan={2} className={classes.align_center}>
                                            입력형
                                        </td>
                                        <td className={classes.align_center}>
                                            <Button className="sm" onClick={() => deleteExtInformationItem(menu.id, "extInformation")}>
                                                삭제
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div className={classes.position_left_btn}>
                            <SelectBoxNew className="admin_st" setSelectItemCB={itemTypeHandler} optionDatas={itemFilterType} showDefaultKey={itemType} />
                            <Button className="primary plus sm" onClick={addItem}>
                                항목추가
                            </Button>
                        </div>
                        <div className={classes.bottom_btn_wrap}>
                            <Button className="border sm" onClick={editHandler}>
                                수정
                            </Button>
                            <Button className="secondary sm" onClick={toList}>
                                목록
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            {isModalOpen1 && (
                <Modal className="admin_st" close={closeModalHandler1} header="스마트필터 값 설정">
                    <main>
                        <div className={classes.admin_table_wrap}>
                            <table>
                                <colgroup>
                                    <col style={{ width: "159px" }} />
                                    <col style={{ width: "620px" }} />
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <th>항목명</th>
                                        <td>{smartFilterMenuItemData.title}</td>
                                    </tr>
                                    <tr>
                                        <th>타입</th>
                                        <td>
                                            <div className={classes.radio_wrap}>
                                                <Radio
                                                    value="N"
                                                    title="라디오 버튼"
                                                    name="rad_02"
                                                    id="rad_02_01"
                                                    checked={dropYn === "N"} 
                                                    onChange={dropChangeHandler}
                                                />
                                                <Radio
                                                    value="Y"
                                                    title="드롭다운"
                                                    name="rad_02"
                                                    id="rad_02_02"
                                                    checked={dropYn === "Y"} 
                                                    onChange={dropChangeHandler}
                                                />
                                                {dropYn === "Y" && (
                                                    <SelectBoxNew
                                                        className="admin_st"
                                                        setSelectItemCB={dropDownFilterTypeHandler}
                                                        optionDatas={dropDownType}
                                                        showDefaultKey={dropDownFilterType}
                                                    />
                                                )}
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <ul className={classes.modal_nav}>
                            {navArr.map((item) => (
                                <li key={item.id} className={navIndex === item.id && classes.on} onClick={() => navToggle(item.id)}>
                                    {item.title}
                                </li>
                            ))}
                        </ul>
                        <p className={classes.counter}>
                            총 <b>{smartFilterMenuItemData.data.length}</b>건
                        </p>
                        <div className={classes.filter_box_wrap}>
                            <div className={classes.filter_box}>
                                <h5>현재 필터 값</h5>
                                <div className={classes.scroll_box}>
                                    {smartFilterMenuItemData.data.length > 0 ? (
                                        <ul>
                                            {smartFilterMenuItemData.data
                                                .filter((item) => item.tab === navIndex + 1) // 현재 선택한 탭의 데이터만 필터링
                                                .map((item) => (
                                                    <li key={item.id}>
                                                        <Checkbox
                                                            className="admin_label"
                                                            id={item.id}
                                                            checked={item.checked}
                                                            onChange={() => toggleCheckbox(item.id)}
                                                            title={item.name}
                                                        />
                                                    </li>
                                                ))}
                                        </ul>
                                    ) : (
                                        <p>선택된 탭에 대한 필터 값이 없습니다.</p>
                                    )}
                                </div>
                            </div>
                            <div className={`${classes.filter_box} ${classes.st_2}`}>
                                <h5>추가 필터 값</h5>
                                <div className={classes.scroll_box}>
                                    <textarea id="filterTextarea" onChange={addFilterHandler} rows="7"></textarea>
                                </div>
                                <Button className="border xxxs" onClick={addFilterOnClickHandler}>
                                    추가하기
                                </Button>
                            </div>
                        </div>
                    </main>
                    <footer>
                        <Button className="border sm" onClick={showDeleteAlert}>
                            선택 삭제
                        </Button>
                        <Button className=" sm" onClick={closeModalHandler1}>
                            취소
                        </Button>
                        <Button className="secondary sm" onClick={smartFilterItemUpdateHandler}>
                            등록
                        </Button>
                    </footer>
                </Modal>
            )}
        </section>
    );
}

export default MngProductSmartFilterUpdate;

export const loader = async ({ params }) => {
    const response = await ReqSmartFilterDetail(params.productId);
    if (response.status === 200) {
        return response.data;
    }
    return null;
};
