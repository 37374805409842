import React from "react";
import classes from "./../TournamentContainerApp.module.css";
import ApplyTournamentLaneResultApp from "./components/ApplyTournamentLaneResultApp";

function PlacementResultsApp({ datas }) {
    return (
        <div className={classes.placement_results_table}>
            <ApplyTournamentLaneResultApp datas={datas} />
        </div>
    );
}

export default PlacementResultsApp;
