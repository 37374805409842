import instance from "../../store/AxiosInterceptor";

/**
 * 대회 공지 상세내역 조회
 *
 * @param {} noticeID 공지의 고유 ID
 * @returns
 */
export async function ReqContestNoticeForAdmin(noticeID) {
    const response = await instance
        .get(`/api/v1/admin/board/contestNotice/${noticeID}`, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            // console.log(error);
            if (error.response) {
                // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
                return {
                    status: error.response.status,
                    error: "",
                };
            } else if (error.request) {
                // 요청이 이루어 졌으나 응답을 받지 못했습니다.
                return {
                    status: 600,
                    error: "요청에 대한 응답 없음",
                };
            } else {
                // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
                return {
                    status: 600,
                    error: "요청설정 에러",
                };
            }
        });
    return response;
}

/**
 * 게시판 데이터 삭제요청
 * @param boardType ex) contestNotice
 * @param noticeID
 * @returns
 */
export async function ReqCreateForAdmin(boardType, formData) {
    const response = await instance
        .post(`/api/v1/admin/board/${boardType}`, formData)
        .then((response) => {s
            return response;
        })
        .catch((error) => {
            console.log(error);
            if (error.response) {
                // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
                return {
                    status: error.response.status,
                    error: "",
                };
            } else if (error.request) {
                // 요청이 이루어 졌으나 응답을 받지 못했습니다.
                return {
                    status: 600,
                    error: "요청에 대한 응답 없음",
                };
            } else {
                // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
                return {
                    status: 600,
                    error: "요청설정 에러",
                };
            }
        });
    return response;
}

export async function ReqCreateNoticesForAdmin(boardType, formData) {
    const response = await instance
        .post(`/api/v1/admin/board/notices/${boardType}`, formData)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            if (error.response) {
                // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
                return {
                    status: error.response.status,
                    error: "",
                };
            } else if (error.request) {
                // 요청이 이루어 졌으나 응답을 받지 못했습니다.
                return {
                    status: 600,
                    error: "요청에 대한 응답 없음",
                };
            } else {
                // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
                return {
                    status: 600,
                    error: "요청설정 에러",
                };
            }
        });
    return response;
}

export async function ReqUpdateContestNoticeForAdmin(noticeID, formData) {
    const response = await instance
        .put(`/api/v1/admin/board/contestNotice/${noticeID}`, formData)
        .then((response) => {
            // console.log(response);
            return response;
        })
        .catch((error) => {
            console.log(error);
            if (error.response) {
                // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
                return {
                    status: error.response.status,
                    error: "",
                };
            } else if (error.request) {
                // 요청이 이루어 졌으나 응답을 받지 못했습니다.
                return {
                    status: 600,
                    error: "요청에 대한 응답 없음",
                };
            } else {
                // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
                return {
                    status: 600,
                    error: "요청설정 에러",
                };
            }
        });
    return response;
}

/**
 * 게시판 데이터 삭제요청
 * @param boardType ex) contestNotice
 * @param noticeID
 * @returns
 */
export async function ReqDeleteForAdmin(boardType, noticeID) {
    const response = await instance
        .delete(`/api/v1/admin/board/${boardType}/${noticeID}`)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            if (error.response) {
                // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
                return {
                    status: error.response.status,
                    error: "",
                };
            } else if (error.request) {
                // 요청이 이루어 졌으나 응답을 받지 못했습니다.
                return {
                    status: 600,
                    error: "요청에 대한 응답 없음",
                };
            } else {
                // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
                return {
                    status: 600,
                    error: "요청설정 에러",
                };
            }
        });
    return response;
}

/**
 * 게시판 데이터 삭제요청
 * @param boardType ex) contestNotice
 * @param noticeID
 * @returns
 */
export async function ReqDeleteNoticeForAdmin(boardType, noticeID) {
    const response = await instance
        .delete(`/api/v1/admin/board/notices/${boardType}/${noticeID}`)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            if (error.response) {
                // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
                return {
                    status: error.response.status,
                    error: "",
                };
            } else if (error.request) {
                // 요청이 이루어 졌으나 응답을 받지 못했습니다.
                return {
                    status: 600,
                    error: "요청에 대한 응답 없음",
                };
            } else {
                // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
                return {
                    status: 600,
                    error: "요청설정 에러",
                };
            }
        });
    return response;
}

/**
 * 공지 상세내역 조회
 *
 * @param {} noticeID 공지의 고유 ID
 * @returns
 */
export async function ReqNoticeForAdmin(noticeID) {
    const response = await instance
        .get(`/api/v1/admin/board/notices/notice/${noticeID}`, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        })
        .then((response) => {
            // console.log(response);
            return response;
        })
        .catch((error) => {
            // console.log(error);
            if (error.response) {
                // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
                return {
                    status: error.response.status,
                    error: "",
                };
            } else if (error.request) {
                // 요청이 이루어 졌으나 응답을 받지 못했습니다.
                return {
                    status: 600,
                    error: "요청에 대한 응답 없음",
                };
            } else {
                // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
                return {
                    status: 600,
                    error: "요청설정 에러",
                };
            }
        });
    return response;
}

/**
 * 대회 공지 상세내역 조회
 *
 * @param {} noticeID 공지의 고유 ID
 * @returns
 */
export async function ReqCTNoticeForAdmin(noticeID) {
    const response = await instance
        .get(`/api/v1/admin/board/notices/contestNotice/${noticeID}`, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        })
        .then((response) => {
            // console.log(response);
            return response;
        })
        .catch((error) => {
            // console.log(error);
            if (error.response) {
                // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
                return {
                    status: error.response.status,
                    error: "",
                };
            } else if (error.request) {
                // 요청이 이루어 졌으나 응답을 받지 못했습니다.
                return {
                    status: 600,
                    error: "요청에 대한 응답 없음",
                };
            } else {
                // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
                return {
                    status: 600,
                    error: "요청설정 에러",
                };
            }
        });
    return response;
}

export async function ReqUpdateNoticeForAdmin(noticeID, formData) {
    const response = await instance
        .put(`/api/v1/admin/board/notices/notice/${noticeID}`, formData)
        .then((response) => {
            // console.log(response);
            return response;
        })
        .catch((error) => {
            console.log(error);
            if (error.response) {
                // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
                return {
                    status: error.response.status,
                    error: "",
                };
            } else if (error.request) {
                // 요청이 이루어 졌으나 응답을 받지 못했습니다.
                return {
                    status: 600,
                    error: "요청에 대한 응답 없음",
                };
            } else {
                // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
                return {
                    status: 600,
                    error: "요청설정 에러",
                };
            }
        });
    return response;
}

export async function ReqUpdateCTNoticeForAdmin(noticeID, formData) {
    const response = await instance
        .put(`/api/v1/admin/board/notices/contestNotice/${noticeID}`, formData)
        .then((response) => {
            // console.log(response);
            return response;
        })
        .catch((error) => {
            console.log(error);
            if (error.response) {
                // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
                return {
                    status: error.response.status,
                    error: "",
                };
            } else if (error.request) {
                // 요청이 이루어 졌으나 응답을 받지 못했습니다.
                return {
                    status: 600,
                    error: "요청에 대한 응답 없음",
                };
            } else {
                // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
                return {
                    status: 600,
                    error: "요청설정 에러",
                };
            }
        });
    return response;
}