import Input from "../../../components/UI/Input/Input";
import classes from "../TournamentContainerApp.module.css";
import Button from "../../../components/UI/Button/Button";
import { useEffect, useState } from "react";
import instance from "../../../store/AxiosInterceptor";
import { useLoaderData } from "react-router";
import { ReqTournamentSearchList } from "../../../api/home/TournamentHomeApi";
import { Link } from "react-router-dom";
import Alert from "../../../components/UI/Modal/Alert";
import TournamentComponentApp from "./components/TournamentComponentApp";

function TournamentListApp() {
    //대회목록
    const resData = useLoaderData();
    const [tournamentList, setTournamentList] = useState([]);

    //모달 상태 관리
    const [showConfirmation, setShowConfirmation] = useState(false);
    const onChangeShowConfirmation = (state) => {
        setShowConfirmation(state);
    };

    //페이징
    const [currentPage, setCurrentPage] = useState(0);
    const onCurrentPageChangeHandler = () => {
        setCurrentPage((page) => page + 1);
    };

    //검색기능
    const [searchText, setSearchText] = useState("");
    const onSearchTextChangeHandler = (value) => {
        setSearchText(value);
    };

    const findTournamentSearchListHandler = () => {
        setCurrentPage(0);
        ReqTournamentSearchList(
            0,
            searchText,
            (result) => {
                if (result.length === 0) {
                    onChangeShowConfirmation(true);
                    return;
                }
                const resData = result.map((data) => {
                    return {
                        ctId: data.ct_id,
                        ctType: data.ct_type,
                        ctTitle: data.ct_title,
                        ctStartDate: data.ct_start_date,
                        ctEndDate: data.ct_end_date,
                        ctAppStartTime: data.ct_app_start_time,
                        ctAppEndTime: data.ct_app_end_time,
                        regionList: data.region_list,
                        ctTypeName: data.ct_type_name,
                        ctProcessType: data.ct_process_type,
                    };
                });

                setTournamentList(resData);
            },
            (error_result) => {
                setTournamentList((prev) => prev);
            }
        );
    };

    const findTournamentPagingHandler = () => {
        onCurrentPageChangeHandler();
        ReqTournamentSearchList(
            currentPage + 1,
            searchText,
            (result) => {
                const resData = result.map((data) => {
                    return {
                        ctId: data.ct_id,
                        ctType: data.ct_type,
                        ctTitle: data.ct_title,
                        ctStartDate: data.ct_start_date,
                        ctEndDate: data.ct_end_date,
                        ctAppStartTime: data.ct_app_start_time,
                        ctAppEndTime: data.ct_app_end_time,
                        regionList: data.region_list,
                        ctTypeName: data.ct_type_name,
                        ctProcessType: data.ct_process_type,
                    };
                });
                setTournamentList((prev) => [...prev, ...resData]);
            },
            (error_result) => {
                setTournamentList((prev) => prev);
            }
        );
    };

    useEffect(() => {
        const curData = resData.map((data) => {
            return {
                ctId: data.ct_id,
                ctType: data.ct_type,
                ctTitle: data.ct_title,
                ctStartDate: data.ct_start_date,
                ctEndDate: data.ct_end_date,
                ctAppStartTime: data.ct_app_start_time,
                ctAppEndTime: data.ct_app_end_time,
                regionList: data.region_list,
                ctTypeName: data.ct_type_name,
                ctProcessType: data.ct_process_type,
            };
        });
        setTournamentList(curData);
    }, [resData]);

    return (
        <>
            {/* 모바일에서 tournament_sub_headert삭제 */}
            <div className={classes.search_wrap}>
                <Input value={searchText} onChange={onSearchTextChangeHandler} />
                <Button className="search_sm" onClick={findTournamentSearchListHandler} />
            </div>

            <div className={classes.card_list_wrap}>
                {tournamentList.map((data, index) => (
                    <Link to={`info/${data.ctId}`} key={index}>
                        <TournamentComponentApp
                            key={index}
                            ctId={data.ctId}
                            ctType={data.ctType}
                            ctTitle={data.ctTitle}
                            ctStartDate={data.ctStartDate}
                            ctEndDate={data.ctEndDate}
                            ctAppStartTime={data.ctAppStartTime}
                            ctAppEndTime={data.ctAppEndTime}
                            regionList={data.regionList}
                            ctTypeName={data.ctTypeName}
                            ctProcessType={data.ctProcessType}
                        />
                    </Link>
                ))}
                <div className={classes.more_btn}>
                    <Button className="text_icon" onClick={findTournamentPagingHandler}>
                        더보기
                    </Button>
                </div>
                {showConfirmation && (
                    <Alert type="alert" confirm="확인" close={() => onChangeShowConfirmation(false)}>
                        {"일치하는 결과가 없습니다."}
                    </Alert>
                )}
            </div>
        </>
    );
}
export default TournamentListApp;

//>>>대회 목록 뿌려주기 Loader
export const loader = async () => {
    const response = await instance({
        method: "GET",
        url: `/api/v1/home/contest/list?page=0&size=5`,
    }).catch(function (error) {
        if (error.response) {
            // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            return error.response;
        } else if (error.request) {
            // 요청이 이루어 졌으나 응답을 받지 못했습니다.
            console.log(error.request);
            return null;
        } else {
            // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
            console.log("Error", error.message);
            return null;
        }
    });
    if (response == null || response.status !== 200) {
        return [];
    }
    if (response.data.status === 200) {
        return response.data.datas.content;
    } else {
        return [];
    }
};
//<<<대회 목록 뿌려주기 Loader
