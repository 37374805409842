import { useEffect, useState } from "react";
import Button from "../../../components/UI/Button/Button";
import Input from "../../../components/UI/Input/Input";
import instance from "../../../store/AxiosInterceptor";
import classes from "../TournamentContainerApp.module.css";
import ResultDetailApp from "./ResultDetailApp";
import { useLoaderData } from "react-router";
import { ReqResultListWithSearch } from "../../../api/home/TournamentHomeResultApi";
import Alert from "../../../components/UI/Modal/Alert";
import TournamentResultAppComponent from "./components/TournamentResultAppComponent";
import { Link } from "react-router-dom";

function TournamentresultApp(props) {
    const [currentPage, setCurrentPage] = useState(0);
    const [resultList, setResultList] = useState([]);
    const [searchText, setSearchText] = useState("");
    const resData = useLoaderData();

    //모달 상태 관리
    const [showConfirmation, setShowConfirmation] = useState(false);
    const onChangeShowConfirmation = (state) => {
        setShowConfirmation(state);
    };

    const onChangePageHandler = () => {
        setCurrentPage((prev) => prev + 1);
    };

    const onSearchTextHandler = (value) => {
        setSearchText(value);
    };

    const findResultListSearchHandler = () => {
        setCurrentPage(0);
        ReqResultListWithSearch(
            0,
            searchText,
            (result) => {
                if (result.length === 0) {
                    onChangeShowConfirmation(true);
                    return;
                }
                const curData = result.map((data) => {
                    return {
                        ctId: data.ct_id,
                        ctTitle: data.ct_title,
                        ctType: data.ct_type,
                        ctTypeTitle: data.ct_type_title,
                        ctStartDate: data.ct_start_date,
                        ctEndDate: data.ct_end_date,
                        ctRegDate: data.reg_date,
                        regionList: data.region_list,
                    };
                });
                setResultList(curData);
            },
            (error_result) => {
                setResultList((prev) => prev);
            }
        );
    };

    const findResultListPageHandler = () => {
        onChangePageHandler();
        ReqResultListWithSearch(
            currentPage + 1,
            searchText,
            (result) => {
                const curData = result.map((data) => {
                    return {
                        ctId: data.ct_id,
                        ctTitle: data.ct_title,
                        ctType: data.ct_type,
                        ctTypeTitle: data.ct_type_title,
                        ctStartDate: data.ct_start_date,
                        ctEndDate: data.ct_end_date,
                        ctRegDate: data.reg_date,
                        regionList: data.region_list,
                    };
                });
                setResultList((prev) => [...prev, ...curData]);
            },
            (error_result) => {
                setResultList((prev) => prev);
            }
        );
    };

    useEffect(() => {
        const curData = resData.map((data) => {
            return {
                ctId: data.ct_id,
                ctTitle: data.ct_title,
                ctType: data.ct_type,
                ctTypeTitle: data.ct_type_title,
                ctStartDate: data.ct_start_date,
                ctEndDate: data.ct_end_date,
                ctRegDate: data.reg_date,
                regionList: data.region_list,
            };
        });
        setResultList(curData);
    }, [resData]);

    return (
        <>
            <div className={classes.search_wrap}>
                <Input onChange={onSearchTextHandler} />
                <Button className="search_sm" onClick={findResultListSearchHandler} />
            </div>

            <div className={classes.card_list_wrap}>
                {resultList.map((data, index) => (
                    <Link to={`${data.ctId}`}>
                        <TournamentResultAppComponent
                            key={index}
                            ctId={data.ctId}
                            ctTitle={data.ctTitle}
                            ctType={data.ctType}
                            ctTypeTitle={data.ctTypeTitle}
                            ctStartDate={data.ctStartDate}
                            ctEndDate={data.ctEndDate}
                            ctRegDate={data.ctRegDate}
                            regionList={data.regionList}
                        />
                    </Link>
                ))}
                <div className={classes.more_btn}>
                    <Button className="text_icon" onClick={findResultListPageHandler}>
                        더보기
                    </Button>
                </div>
            </div>
            {/* {viewMode && <ResultDetailApp view_board={view_board} />} */}
            {showConfirmation && (
                <Alert type="alert" confirm="확인" close={() => onChangeShowConfirmation(false)}>
                    {"일치하는 결과가 없습니다."}
                </Alert>
            )}
        </>
    );
}

export default TournamentresultApp;

//>>>대회 목록(결과) 뿌려주기 Loader
export const loader = async () => {
    const response = await instance({
        method: "GET",
        url: `/api/v1/home/contest/result?page=0&size=5`,
    }).catch(function (error) {
        if (error.response) {
            // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            return error.response;
        } else if (error.request) {
            // 요청이 이루어 졌으나 응답을 받지 못했습니다.
            console.log(error.request);
            return null;
        } else {
            // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
            console.log("Error", error.message);
            return null;
        }
    });
    if (response == null || response.status !== 200) {
        return [];
    }
    if (response.data.status === 200) {
        return response.data.datas.content;
    } else {
        return [];
    }
};
//<<<대회 목록(결과) 뿌려주기 Loader
