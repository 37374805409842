import React, { useState } from "react";
import WaitingService from "../../pages/tournament/applyTournament/WaitingService";
import Button from "./Button/Button";
import Checkbox from "./Input/Checkbox";
import Input from "./Input/Input";
import SearchBox from "./Input/SearchBox";
import Modal from "./Modal/Modal";
import SelectBox from "./SelectBox/SelectBox";
import Alert from "./Modal/Alert";
import SystemCheckInfo from "../../pages/other/SystemCheckInfo";
import CustomTree from "./Tree";
import sampleImg from "../../assets/images/event_img.jpg";
import { useEffect } from "react";
import { useRef } from "react";
const treeData = [
    {
        id: 100,
        name: "볼링화",
        seq: 1,
        depth: 1,
    },
    {
        id: 1,
        name: "볼링볼",
        seq: 1,
        depth: 1,
        children: [
            {
                id: 2,
                name: "STORM",
                seq: 1,
                depth: 2,
                children: [
                    {
                        id: 4,
                        name: "PREMIER",
                        seq: 1,
                        depth: 3,
                        children: null,
                    },
                    {
                        id: 5,
                        name: "SIGNATURE",
                        seq: 2,
                        depth: 3,
                        children: null,
                    },
                    {
                        id: 6,
                        name: "MASTER",
                        seq: 3,
                        depth: 3,
                        children: null,
                    },
                    {
                        id: 7,
                        name: "THUNDER",
                        seq: 4,
                        depth: 3,
                        children: null,
                    },
                    {
                        id: 8,
                        name: "HOT",
                        seq: 5,
                        depth: 3,
                        children: null,
                    },
                    {
                        id: 9,
                        name: "TROPICAL",
                        seq: 6,
                        depth: 3,
                        children: null,
                    },
                    {
                        id: 10,
                        name: "ICE",
                        seq: 7,
                        depth: 3,
                        children: null,
                    },
                ],
            },
            {
                id: 3,
                name: "ROTO GRIP",
                seq: 2,
                depth: 2,
                children: [
                    {
                        id: 11,
                        name: "HP4",
                        seq: 1,
                        depth: 3,
                        children: null,
                    },
                    {
                        id: 12,
                        name: "HP3",
                        seq: 2,
                        depth: 3,
                        children: null,
                    },
                    {
                        id: 13,
                        name: "HP2",
                        seq: 3,
                        depth: 3,
                        children: null,
                    },
                    {
                        id: 14,
                        name: "HP1",
                        seq: 4,
                        depth: 3,
                        children: null,
                    },
                ],
            },
        ],
    },
    {
        id: 101,
        name: "볼링가방",
        seq: 1,
        depth: 1,
        children: [
            {
                id: 102,
                name: "STORM",
                seq: 1,
                depth: 2,
            },
        ],
    },
];
// select box 샘플 데이터
const groupOptionData = [
    {
        value: "선택",
        disabled: "false",
        selected: "true",
    },
    {
        value: "1조",
        state: "잔여: 1명",
        disabled: "false",
        selected: "false",
    },
    {
        value: "2조 ",
        state: "마감",
        disabled: "true",
        selected: "false",
    },
    {
        value: "3조",
        state: "잔여: 8명",
        disabled: "false",
        selected: "false",
    },
    {
        value: "4조",
        state: "잔여: 10명",
        disabled: "false",
        selected: "false",
    },
];

// 모달 여닫기
function UIComponents() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpen2, setIsModalOpen2] = useState(false);
    const [isModalOpen3, setIsModalOpen3] = useState(false);
    const [isModalOpen4, setIsModalOpen4] = useState(false);
    const [isModalOpen5, setIsModalOpen5] = useState(false);
    const [isModalOpen6, setIsModalOpen6] = useState(false);
    const openModalHandler = () => {
        setIsModalOpen(true);
    };
    const closeModalHandler = () => {
        setIsModalOpen(false);
    };
    const openModalHandler2 = () => {
        setIsModalOpen2(true);
    };
    const closeModalHandler2 = () => {
        setIsModalOpen2(false);
    };
    const openModalHandler3 = () => {
        setIsModalOpen3(true);
    };
    const closeModalHandler3 = () => {
        setIsModalOpen3(false);
    };
    const openModalHandler4 = () => {
        setIsModalOpen4(true);
    };
    const closeModalHandler4 = () => {
        setIsModalOpen4(false);
    };
    const openModalHandler5 = () => {
        setIsModalOpen5(true);
    };
    const closeModalHandler5 = () => {
        setIsModalOpen5(false);
    };
    const openModalHandler6 = () => {
        setIsModalOpen6(true);
    };
    const closeModalHandler6 = () => {
        setIsModalOpen6(false);
    };

    //alert 여닫기
    const [isAlertOpen, setIsAlertOpen] = useState(false);
    const [isAlertOpen2, setIsAlertOpen2] = useState(false);
    const [isAlertOpen3, setIsAlertOpen3] = useState(false);
    const [isAlertOpen4, setIsAlertOpen4] = useState(false);
    const [isAlertOpen5, setIsAlertOpen5] = useState(false);
    const [isAlertOpen6, setIsAlertOpen6] = useState(false);
    const [isAlertOpen7, setIsAlertOpen7] = useState(false);

    const openAlertHandler = () => {
        setIsAlertOpen(true);
    };
    const closeAlertHandler = () => {
        setIsAlertOpen(false);
    };
    const openAlertHandler2 = () => {
        setIsAlertOpen2(true);
    };
    const closeAlertHandler2 = () => {
        setIsAlertOpen2(false);
    };
    const openAlertHandler3 = () => {
        setIsAlertOpen3(true);
    };
    const closeAlertHandler3 = () => {
        setIsAlertOpen3(false);
    };
    const openAlertHandler4 = () => {
        setIsAlertOpen4(true);
    };
    const openAlertHandler5 = () => {
        setIsAlertOpen5(true);
    };
    const openAlertHandler6 = () => {
        setIsAlertOpen6(true);
    };
    const closeAlertHandler6 = () => {
        setIsAlertOpen6(false);
    };
    const openAlertHandler7 = () => {
        setIsAlertOpen7(true);
    };
    const closeAlertHandler7 = () => {
        setIsAlertOpen7(false);
    };
    // Event handler to open the alert
    const alertRef = useRef(null);
    // Event handler to close the alert when Enter key is pressed
    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            setIsAlertOpen(false);
        }
    };

    useEffect(() => {
        if (isAlertOpen && alertRef.current) {
            // Check if alertRef is not null before focusing
            alertRef.current.focus();
        }
    }, [isAlertOpen]);

    return (
        <div style={{ padding: "100px 20px 150px " }}>
            <div>
                <h1>UI Components</h1>
                <h2>button</h2>
                <div
                    style={{ display: "flex", gap: "10px", flexWrap: "nowrap" }}
                >
                    <Button>default</Button>
                    <Button className="secondary">secondary</Button>
                    <Button className="sm">sm-default</Button>
                    <Button className="secondary sm">sm-secondary</Button>
                    <div
                        style={{ background: "#000", display: "inline-block" }}
                    >
                        <Button className="close_x">close_x</Button>
                    </div>
                    <Button className="btn_text">btn_text</Button>
                </div>

                <hr />
                <h2>input</h2>
                <div
                    style={{
                        display: "flex",
                        gap: "10px",
                    }}
                >
                    <Input type="text" placeholder="입력하세요" />
                    <Checkbox value="checkbox" id="chk_01" />
                    <Checkbox
                        value="official_checkbox"
                        className="official_st"
                        id="chk_03"
                    />
                    {/* <SelectBox optionData={groupOptionData} />
                    <SearchBox /> */}
                </div>
                <hr />
                <h2>modal</h2>
                <div
                    style={{ display: "flex", gap: "10px", flexWrap: "nowrap" }}
                >
                    <Button className="sm" onClick={openModalHandler6}>
                        사용구등록
                    </Button>
                    {isModalOpen6 && (
                        <Modal
                            close={closeModalHandler6}
                            header="사용구 등록/수정"
                        >
                            <main>
                                <div>
                                    <h4>[안내]</h4>
                                    <ul>
                                        <li>
                                            대회사용구 등록은 STORM, Roto Grip,
                                            900 Global만 가능합니다.
                                        </li>
                                        <li>
                                            볼링공 시리얼넘버는 최대 12자리이고
                                            12자리 보다 적은 경우도 있습니다.
                                        </li>
                                        <li>
                                            사용하시는 볼링공의 시리얼넘버가
                                            ‘미확인‘ 인 경우에는 ‘1:1문의‘로
                                            접수하시기 바랍니다.
                                        </li>
                                    </ul>
                                </div>
                                <div>
                                    <table>
                                        <colgroup>
                                            <col style={{ width: "%" }} />
                                        </colgroup>
                                        <thead>
                                            <tr>
                                                <th>선택</th>
                                                <th>브랜드</th>
                                                <th>모델명</th>
                                                <th>시리얼넘버</th>
                                                <th>등록확인</th>
                                                <th>수정/삭제</th>
                                            </tr>
                                        </thead>
                                        <tbody></tbody>
                                    </table>
                                </div>
                            </main>
                        </Modal>
                    )}
                    <Button className="sm" onClick={openModalHandler}>
                        접속 대기
                    </Button>
                    {/* ↓ WaitingService 에 className="type_2" 빨간색 로딩, 클레스네임 없으면 보라색 */}
                    {isModalOpen && (
                        <Modal close={closeModalHandler} header="접속 대기">
                            <WaitingService
                                className="type_2"
                                close={closeModalHandler}
                            />
                        </Modal>
                    )}

                    <Button className="sm" onClick={openModalHandler3}>
                        시스템 점검 안내 + 오늘 하루 보지 않기
                    </Button>
                    {isModalOpen3 && (
                        <Modal
                            className="none_title"
                            close={closeModalHandler3}
                        >
                            <SystemCheckInfo close={closeModalHandler3} />
                        </Modal>
                    )}
                    <Button className="sm" onClick={openModalHandler5}>
                        popup modal
                    </Button>
                    {isModalOpen5 && (
                        <Modal
                            className="popupModal"
                            close={closeModalHandler5}
                        >
                            <img src={sampleImg} alt="" />
                        </Modal>
                    )}
                    <Button className="sm" onClick={openModalHandler2}>
                        admin modal
                    </Button>
                    {isModalOpen2 && (
                        <Modal
                            className="admin_st w_1200"
                            close={closeModalHandler2}
                            header="문자발송 정보 확인"
                        >
                            <main>
                                <h4>문자 발송정보 확인</h4>
                                <div>
                                    <table>
                                        <colgroup>
                                            <col style={{ width: "12%" }} />
                                            <col style={{ width: "8%" }} />
                                            <col style={{ width: "10%" }} />
                                            <col style={{ width: "10%" }} />
                                            <col style={{ width: "10%" }} />
                                            <col style={{ width: "10%" }} />
                                            <col style={{ width: "10%" }} />
                                            <col style={{ width: "20%" }} />
                                            <col style={{ width: "10%" }} />
                                        </colgroup>
                                        <thead>
                                            <tr>
                                                <th>대회명</th>
                                                <th>지역</th>
                                                <th>경기장</th>
                                                <th>대상건수</th>
                                                <th>고객상태</th>
                                                <th>템플릿명</th>
                                                <th>템플릿유형</th>
                                                <th>템플릿 문구</th>
                                                <th>미리보기</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    2023년 제62회 진승 고객 감사
                                                    페스티벌
                                                </td>
                                                <td>일산일산</td>
                                                <td>우리볼링센타</td>
                                                <td>17건</td>
                                                <td>선정</td>
                                                <td>선정</td>
                                                <td>기본형</td>
                                                <td>
                                                    [고객명]님
                                                    <br />
                                                    [대회명] / [지역] / [경기장]
                                                    / [조]조 / [레인번호]레인 /
                                                    참가확정
                                                    <br />
                                                    <br />
                                                    -결제정보-
                                                    <br />
                                                    [1차 결제 마감]까지 결제
                                                    요망 (마이페이지{">"}
                                                    대회신청내역 에서 결제
                                                    가능합니다.)
                                                </td>
                                                <td>
                                                    <Button className="border xxxs">
                                                        미리보기
                                                    </Button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    2023년 제62회 진승 고객 감사
                                                    페스티벌
                                                </td>
                                                <td>일산</td>
                                                <td>우리볼링센타</td>
                                                <td>17건</td>
                                                <td>선정</td>
                                                <td>선정</td>
                                                <td>기본형</td>
                                                <td>
                                                    [고객명]님
                                                    <br />
                                                    [대회명] / [지역] / [경기장]
                                                    / [조]조 / [레인번호]레인 /
                                                    참가확정
                                                    <br />
                                                    <br />
                                                    -결제정보-
                                                    <br />
                                                    [1차 결제 마감]까지 결제
                                                    요망 (마이페이지{">"}
                                                    대회신청내역 에서 결제
                                                    가능합니다.)
                                                </td>
                                                <td>
                                                    <Button className="border xxxs">
                                                        미리보기
                                                    </Button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </main>
                            <footer>
                                <Button
                                    className=" sm"
                                    onClick={closeModalHandler2}
                                >
                                    취소
                                </Button>
                                <Button className="secondary sm">
                                    문자발송
                                </Button>
                            </footer>
                        </Modal>
                    )}
                    <Button className="sm" onClick={openModalHandler4}>
                        admin modal
                    </Button>
                    {isModalOpen4 && (
                        <Modal
                            className="admin_st w_360"
                            close={closeModalHandler4}
                            header="변경관리"
                        >
                            <main>
                                <h5>
                                    <b>변경 대상 : </b> 김진승 (kjs1234)
                                </h5>
                                <div>
                                    <h4>변경 전</h4>
                                    <table className="align_center">
                                        <colgroup>
                                            <col style={{ width: "33%" }} />
                                            <col style={{ width: "33%" }} />
                                            <col style={{ width: "33%" }} />
                                        </colgroup>
                                        <thead>
                                            <tr>
                                                <th>고객상태</th>
                                                <th>결제방식</th>
                                                <th>결제유무</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>선정</td>
                                                <td>PG결제</td>
                                                <td>결제대기</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <h4>변경 후</h4>
                                    <table className="align_center">
                                        <colgroup>
                                            <col style={{ width: "33%" }} />
                                            <col style={{ width: "33%" }} />
                                            <col style={{ width: "33%" }} />
                                        </colgroup>
                                        <thead>
                                            <tr>
                                                <th>고객상태</th>
                                                <th>결제방식</th>
                                                <th>결제유무</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>선정</td>
                                                <td className="red_c">
                                                    무통장입금
                                                </td>
                                                <td className="red_c">
                                                    결제완료
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <h4>변경사유</h4>
                                    <textarea
                                        name=""
                                        id=""
                                        cols="30"
                                        rows="5"
                                    ></textarea>
                                </div>
                            </main>
                            <footer>
                                <Button className="secondary sm">
                                    변경적용
                                </Button>
                            </footer>
                        </Modal>
                    )}
                </div>
                <hr />
                <h2>alert</h2>
                <div
                    style={{ display: "flex", gap: "10px", flexWrap: "nowrap" }}
                >
                    <Button className="sm" onClick={openAlertHandler}>
                        신청취소
                    </Button>
                    {isAlertOpen && (
                        <Alert
                            type="confirm"
                            cancel="돌아가기"
                            confirm="접수취소"
                            close={closeAlertHandler}
                        >
                            <div
                                tabIndex={0}
                                ref={alertRef}
                                onKeyDown={handleKeyPress}
                            >
                                취소 하시겠습니까?
                            </div>
                        </Alert>
                    )}

                    <Button className="sm" onClick={openAlertHandler2}>
                        신청
                    </Button>
                    {isAlertOpen2 && (
                        <Alert
                            type="confirm"
                            cancel="신청취소"
                            confirm="접수완료"
                            close={closeAlertHandler2}
                        >
                            신청 하시겠습니까?
                        </Alert>
                    )}

                    <Button className="sm" onClick={openAlertHandler2}>
                        신청
                    </Button>
                    {isAlertOpen2 && (
                        <Alert
                            type="confirm"
                            cancel="신청취소"
                            confirm="접수완료"
                            close={closeAlertHandler2}
                        >
                            신청 하시겠습니까?
                        </Alert>
                    )}

                    <Button className="sm" onClick={openAlertHandler3}>
                        참가비결제취소
                    </Button>
                    {isAlertOpen3 && (
                        <Alert
                            type="confirm"
                            cancel="취소"
                            confirm="확인"
                            close={closeAlertHandler3}
                        >
                            참가비 결제를
                            <br />
                            취소하시겠습니까?
                        </Alert>
                    )}

                    <Button className="sm" onClick={openAlertHandler4}>
                        결제실패
                    </Button>
                    {isAlertOpen4 && (
                        <Alert>
                            결제가 되지 않았습니다.
                            <br />
                            다시 신청해주세요.
                        </Alert>
                    )}

                    <Button className="sm" onClick={openAlertHandler5}>
                        결제완료
                    </Button>
                    {isAlertOpen5 && (
                        <Alert>
                            참가비 결제가
                            <br />
                            완료되었습니다.
                        </Alert>
                    )}
                </div>
                <hr />

                {/* <h2></h2>
        <hr /> */}
            </div>
            <div style={{ width: "50%" }}>
                <h1>pages</h1>
            </div>
            <hr />
            <div style={{ width: "50%" }}>
                <h1>admin_alert</h1>
                <Button className="sm" onClick={openAlertHandler6}>
                    문자발송
                </Button>
                <Button className="sm" onClick={openAlertHandler7}>
                    문자발송성공
                </Button>
                {isAlertOpen6 && (
                    <Alert
                        type="confirm"
                        cancel="확인"
                        confirm="취소"
                        className="admin_st msg_send"
                        close={closeAlertHandler6}
                    >
                        문자 30건을 발송하시겠습니까?
                    </Alert>
                )}
                {isAlertOpen7 && (
                    <Alert
                        type="alert"
                        confirm="발송결과 확인"
                        className="admin_st msg_complete"
                        close={closeAlertHandler7}
                    >
                        문자 발송이 완료되었습니다.
                    </Alert>
                )}
            </div>
            <hr />
            <CustomTree
                data={treeData}
                keyName="id"
                titleName="name"
                selectable
            />
        </div>
    );
}
export default UIComponents;
