import { useContext, useEffect, useState } from "react";
import Button from "../../../../components/UI/Button/Button";
import classes from "../../MngCommon.module.css";
import Radio from "../../../../components/UI/Input/Radio";
import Input, { MultiInput } from "../../../../components/UI/Input/Input";
import { ReqSmartFilterDetail, ReqSmartFiltermenuUpload, ReqqSmartFilterMenuItem } from "../../../../api/product/SmartFilterApi";
import SelectBoxNew from "../../../../components/UI/SelectBox/SelectBoxNew";
import { useLoaderData, useNavigate } from "react-router-dom";
import { JwtTokenContext } from "../../../../store/JwtTokenProvider";
import { itemFilterType, productCategoryType } from "../../../../components/definedefault/DefSelectBoxValues";

function MngProductSmartFilterUpload(props) {
    const [exposeYn, setExposeYn] = useState("N");
    const isExposeChangeHandler = (e) => {
        setExposeYn(e.target.value);
    };

    // MULTI INPUT
    const [input, setInput] = useState({
        title: "",
    });

    const { title } = input;

    const onChangeInput = (e) => {
        const { name, value } = e.target;
        setInput({
            ...input,
            [name]: value,
        });
    };

    const [itemType, setItemType] = useState();

    const itemTypeHandler = (key) => {
        setItemType(key);
    };

    const [categoryType, setCategoryType] = useState();

    const categoryTypeHandler = (key) => {
        setCategoryType(key);
    };

    const loginCtx = useContext(JwtTokenContext);
    const today = () => {
        const now = new Date();

        return now.getFullYear() + "-" + ("0" + (now.getMonth() + 1)).slice(-2) + "-" + ("0" + now.getDate()).slice(-2);
    };
    const navigate = useNavigate();
    const toList = () => {
        navigate("/admin/product/smartFilter");
    };

    // 스마트필터 5행 기본으로 깔고 가
    const [smartFilterRows, setSmartFilterRows] = useState([
        { id: 1, value: "" },
        { id: 2, value: "" },
        { id: 3, value: "" },
        { id: 4, value: "" },
        { id: 5, value: "" },
    ]);

    // 상품정보 2행 기본으로 깔고 가
    const [productInfoRows, setProductInfoRows] = useState([
        { id: 1, value: "" },
        { id: 2, value: "" },
    ]);

    const handleAddItem = () => {
        if (itemType === "smartFilter") {
            // 스마트필터 항목 추가
            const newRow = {
                id: smartFilterRows.length + 1, // 새 항목의 고유 ID
                value: "", // 스마트필터 값
            };

            setSmartFilterRows([...smartFilterRows, newRow]);

            setInput({
                ...input,
                [`smartFilter${newRow.id}`]: newRow.value,
            });
        } else if (itemType === "extInformation") {
            // 상품정보 항목 추가
            const newRow = {
                id: productInfoRows.length + 1, // 새 항목의 고유 ID
                value: "", // 상품정보 값
            };
            setProductInfoRows([...productInfoRows, newRow]);

            setInput({
                ...input,
                [`product${newRow.id}`]: newRow.value,
            });
        }
    };

    const [editedSmartFilterTitles, setEditedSmartFilterTitles] = useState({});
    const [editedProductInfoTitles, setEditedProductInfoTitles] = useState({});

    const handleSmartFilterTitleChange = (e, id) => {
        setEditedSmartFilterTitles((prevEditedTitles) => ({
            ...prevEditedTitles,
            [id]: e,
        }));
    };
    const handleEditedProductInfoTitleChange = (e, id) => {
        setEditedProductInfoTitles((prevEditedTitles) => ({
            ...prevEditedTitles,
            [id]: e,
        }));
    };

    const deleteExtInformationItem = (id) => {
        // 상품정보 항목 삭제
        const updatedProductInfoRows = productInfoRows.filter((item) => item.id !== id);
        setProductInfoRows(updatedProductInfoRows);

        // Input 삭제
        setInput((prevInput) => {
            const updatedInput = { ...prevInput };
            delete updatedInput[`product${id}`];
            return updatedInput;
        });

        // editedProductInfoTitles에서도 삭제
        setEditedProductInfoTitles((prevEditedTitles) => {
            const updatedTitles = { ...prevEditedTitles };
            delete updatedTitles[id];
            return updatedTitles;
        });
    };

    const deleteSmartFilterItem = (id) => {
        // 스마트필터 항목 삭제
        const updatedSmartFilterRows = smartFilterRows.filter((item) => item.id !== id);
        setSmartFilterRows(updatedSmartFilterRows);

        // Input 삭제
        setInput((prevInput) => {
            const updatedInput = { ...prevInput };
            delete updatedInput[`smartFilter${id}`];
            return updatedInput;
        });

        // editedSmartFilterTitles에서도 삭제
        setEditedSmartFilterTitles((prevEditedTitles) => {
            const updatedTitles = { ...prevEditedTitles };
            delete updatedTitles[id];
            return updatedTitles;
        });
    };

    const submitHandler = async (e) => {
        e.preventDefault();

        const transSmartFilterTitles = Object.keys(editedSmartFilterTitles).map((key) => {
            const value = editedSmartFilterTitles[key];
            return {
                title: value,
                tabSize: 1,
            };
        });
        const transProductTitles = Object.keys(editedProductInfoTitles).map((key) => {
            const value = editedProductInfoTitles[key];
            return {
                title: value,
            };
        });

        if (categoryType === undefined) {
            alert("적용 카테고리를 선택해주세요");
            return;
        } else if (title === "") {
            alert("제목을 입력해주세요");
            return;
        }

        console.log(categoryType);

        const submitData = {
            category_id: categoryType,
            title: title,
            expose_yn: exposeYn,
            smart_filter_menu: transSmartFilterTitles,
            ext_info_menu: transProductTitles,
        };

        const response = await ReqSmartFiltermenuUpload(submitData);

        if (response.status === 200) {
            navigate("/admin/product/smartFilter");
        } else {
            alert(`[${response.status}] ${response.data ? response.data.error : "등록 실패"} `);
        }
    };

    return (
        <section>
            <div className={classes.admin_contents}>
                <h3>상품정보/스마트필터관리 - 등록</h3>
                <div className={`${classes.contents_wrap} ${classes.search_wrap} `}>
                    <div className={classes.admin_table_wrap}>
                        <table>
                            <colgroup>
                                <col style={{ width: "159px" }} />
                                <col style={{ width: "620px" }} />
                                <col style={{ width: "159px" }} />
                                <col style={{ width: "482px" }} />
                                <col style={{ width: "138px" }} />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>등록일</th>
                                    <td>{today()}</td>
                                    <th>등록자</th>
                                    <td>{loginCtx.decodedToken.jti}</td>
                                </tr>
                                <tr>
                                    <th>사용여부</th>
                                    <td colSpan={4}>
                                        <div className={classes.radio_wrap}>
                                            <Radio
                                                value="Y"
                                                title="사용"
                                                name="rad_01"
                                                id="rad_01_01"
                                                checked={exposeYn === "Y"}
                                                onChange={isExposeChangeHandler}
                                            />
                                            <Radio
                                                value="N"
                                                title="미사용"
                                                name="rad_01"
                                                id="rad_01_02"
                                                checked={exposeYn === "N"}
                                                onChange={isExposeChangeHandler}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>적용 카테고리</th>
                                    <td colSpan={4}>
                                        <SelectBoxNew
                                            className="admin_st"
                                            setSelectItemCB={categoryTypeHandler}
                                            optionDatas={productCategoryType}
                                            showDefaultKey={categoryType}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>제목</th>
                                    <td colSpan={4}>
                                        <MultiInput className="admin_st w_100" value={title} name="title" onChange={onChangeInput} />
                                    </td>
                                </tr>
                                <tr className={classes.th_st}>
                                    <th>구분</th>
                                    <th>항목</th>
                                    <th colSpan={2}>유형</th>
                                    <th>삭제</th>
                                </tr>

                                {smartFilterRows.map((item, index) => (
                                    <tr key={item.id}>
                                        {index === 0 && <th rowSpan={smartFilterRows.length}>스마트필터</th>}
                                        <td>
                                            <Input
                                                className="admin_st w_100"
                                                value={editedSmartFilterTitles[item.id]}
                                                onChange={(e) => handleSmartFilterTitleChange(e, item.id)}
                                            />
                                        </td>
                                        <td colSpan={2} className={classes.align_center}>
                                            필터 값 설정
                                        </td>
                                        <td className={classes.align_center}>
                                            <Button className="sm" onClick={() => deleteSmartFilterItem(item.id, "smartFilter")}>
                                                삭제
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                                {productInfoRows.map((item, index) => (
                                    <tr key={item.id}>
                                        {index === 0 && <th rowSpan={productInfoRows.length}>상품정보</th>}
                                        <td>
                                            <Input
                                                className="admin_st w_100"
                                                value={editedProductInfoTitles[item.id]}
                                                onChange={(e) => handleEditedProductInfoTitleChange(e, item.id)}
                                            />
                                        </td>
                                        <td colSpan={2} className={classes.align_center}>
                                            입력형
                                        </td>
                                        <td className={classes.align_center}>
                                            <Button className="sm" onClick={() => deleteExtInformationItem(item.id, "extInformation")}>
                                                삭제
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div className={classes.position_left_btn}>
                            <SelectBoxNew className="admin_st" setSelectItemCB={itemTypeHandler} optionDatas={itemFilterType} showDefaultKey={itemType} />
                            <Button className="primary plus sm" onClick={handleAddItem}>
                                항목추가
                            </Button>
                        </div>
                        <div className={classes.bottom_btn_wrap}>
                            <Button className="border sm" onClick={submitHandler}>
                                등록
                            </Button>
                            <Button className="secondary sm" onClick={toList}>
                                목록
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default MngProductSmartFilterUpload;
