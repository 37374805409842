import React, { useEffect, useState } from "react";
import classes from "./KegelApp.module.css";
import BreadCrumb from "../../components/UI/BreadCrumb/BreadCrumb";
import Pagination from "../../components/UI/Table/Pagination";
import Button from "../../components/UI/Button/Button";
import SelectBoxSearch from "../../components/UI/SelectBox/SelectBoxNew";
import { NavLink, useLoaderData, useNavigate } from "react-router-dom";
import {
    ReqKegelHomeServiceList,
    ReqKegelHomeServiceListApp,
    ReqKegelHomeServiceListMobile,
} from "../../api/kegel/KegelAPi";
import instance from "../../store/AxiosInterceptor";

function LaneServiceHelpAppPage(props) {
    const kegel = useLoaderData();
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(0);
    const [kegelList, setKegelList] = useState([]);
    //  breadCrumb sample data
    const breadCrumbList = ["케겔", "레인정비기계 서비스", "유용한 정보"];

    // >>> 페이징을 위한 값 세팅
    const [totalPage, setTotalPage] = useState(); //총 페이지 갯수
    const [page, setPage] = useState(0); // 현재 페이지 >> 서버로 보낼 값
    const [pageNumber, setPageNumber] = useState(0); //글목록 No. 값을세팅하기 위한 서버에서 들어오는값
    const [totalCount, setTotalCount] = useState(0);
    // <<< 페이징을 위한 값 세팅

    useEffect(() => {
        if (kegel.content) {
            const curData = kegel.content.map((data) => ({
                kegelId: data.id,
                content: data.content,
                exposeYn: data.exposeYn,
                kegelType: data.kegelGuideType,
                readCnt: data.readCnt,
                regDate: data.regDate,
                regID: data.regID,
                title: data.title,
            }));
            setKegelList(curData);
            setTotalCount(kegel.totalElements);
        }
    }, [kegel]);

    const onChangePageHandler = () => {
        setCurrentPage((prev) => prev + 1);
    };

    const pageHandler = (value) => {
        onChangePageHandler();

        ReqKegelHomeServiceListApp(
            "HELP",
            currentPage + 1,
            (result) => {
                const curData = result.map((data) => {
                    return {
                        kegelId: data.id,
                        content: data.content,
                        exposeYn: data.exposeYn,
                        kegelType: data.kegelGuideType,
                        regDate: data.regDate,
                        regID: data.regID,
                        title: data.title,
                    };
                });
                setKegelList((prev) => [...prev, ...curData]);
                setTotalCount(result.data.datas.totalElements);
            },
            (error_result) => {
                setKegelList((prev) => prev);
            }
        );
    };

    const increaseViews = (id) => {
        navigate(`/kegel/help/${id}`);
        instance.put(`/api/v1/home/kegel/guide/${id}/views`).catch((error) => {
            console.error("Error increasing views:", error);
        });
    };
    const bodyValues = kegelList.map(({ kegelId, title, regDate }, index) => {
        const number = totalCount - (10 * pageNumber + index);
        return (
            <tr key={kegelId}>
                <td>{number}</td>
                <td onClick={() => increaseViews(kegelId)}>{title}</td>
                <td>{regDate}</td>
            </tr>
        );
    });

    return (
        <>
            <div className={`${classes.contents_wrap} ${classes.depth_2_st}`}>
                <div className={classes.mobile_table_wrap}>
                    <table>
                        <colgroup>
                            <col style={{ width: "8%" }} />
                            <col style={{ width: "43%" }} />
                            <col style={{ width: "11%" }} />
                        </colgroup>
                        <thead>
                            <tr>
                                <th>번호</th>
                                <th>제목</th>
                                <th>등록일자</th>
                            </tr>
                        </thead>
                        <tbody>{bodyValues}</tbody>
                    </table>
                    {/* 모바일에서 페이징 -> 더보기 버튼으로 변경 */}
                    <div className={classes.bottom_center_btn}>
                        <Button className="text_icon" onClick={pageHandler}>
                            더보기
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default LaneServiceHelpAppPage;

export const loader = async () => {
    const type = "HELP";
    const response = await ReqKegelHomeServiceList(type);
    if (response.status === 200) {
        const resData = response.data.datas;

        return resData;
    } else {
        return [];
    }
};
