import instance from "../../store/AxiosInterceptor";

/**
 * BO - 회원관리 - 관리자 계정 관리
 * @returns
 */
export function ReqAdminMemberList(resolveCB, rejectCB) {
    instance({
        url: `/api/v1/admin/member/admin?page=0&size=10`,
        method: "GET",
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data.datas);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/**
 * BO - 회원관리 - 관리자 계정 관리 페이징
 * @returns
 */
export function ReqAdminMemberListPage(object, page, resolveCB, rejectCB) {
    instance({
        url: `/api/v1/admin/member/admin?start_reg_date=${object.start_reg_date}&end_reg_date=${object.end_reg_date}&process=${object.process}&${object.queryType}=${object.queryText}&page=${page}&size=10`,
        method: "GET",
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data.datas);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/**
 * BO - 회원관리 - 관리자 계정 관리 페이징
 * @returns
 */
export function ReqAdminMemberListSearch(object, resolveCB, rejectCB) {
    instance({
        url: `/api/v1/admin/member/admin?start_reg_date=${object.start_reg_date}&end_reg_date=${object.end_reg_date}&process=${object.process}&${object.queryType}=${object.queryText}&page=0&size=10`,
        method: "GET",
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data.datas);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/**
 * BO - 회원관리 - 관리자 계정 관리
 * 관리자 계정관리 - 수정
 * @returns
 */
export function ReqUpdateAdmin(adminId, object, resolveCB, rejectCB) {
    instance({
        url: `/api/v1/admin/member/admin/${adminId}`,
        method: "POST",
        data: object,
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/**
 * BO - 회원관리 - 관리자 계정 관리
 * 관리자 계정관리 - 저장, 생성
 * @returns
 */
export function ReqCreateAdmin(object, resolveCB, rejectCB) {
    instance({
        url: `/api/v1/admin/member/admin`,
        method: "POST",
        data: object,
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/**
 * 회원가입 중 회원 아이디 중복 확인 API
 * @param resolveCB
 * @returns
 */
export function ReqCheckDuplicateAdminId(adminID, resolveCB, rejectCB) {
    instance({
        url: `/api/v1/admin/member/admin/idCheck?adminID=${adminID}`,
        method: "POST",
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.status);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}