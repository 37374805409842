import React, { useEffect, useState } from "react";
import classes from "../ProductsApp.module.css";
import Button from "../../../components/UI/Button/Button";
import Checkbox from "../../../components/UI/Input/Checkbox";
import { getHomeProduct } from "../../../api/home/ProductCategoryApi";

export const SmartFilterApp = ({
    smartFilterDatas = [],
    searchParams,
    searchKeyword = {},
    setSearchResults,
    setSelectedValues,
    resetPage,
    setFilterBoxToggle,
    selectedValues,
}) => {
    const [selectedCheckboxes, setSelectedCheckboxes] = useState({});

    useEffect(() => {
        // selectedValues가 배열인 경우에만 처리한다......
        if (Array.isArray(selectedValues)) {
            // selectedValues가 변경될 때마다! selectedCheckboxes 업데이트
            const updatedCheckboxes = {};
            selectedValues.forEach((value) => {
                updatedCheckboxes[value] = true;
            });
            setSelectedCheckboxes(updatedCheckboxes);
        }
    }, [selectedValues]);

    const handleSearchClick = () => {
        const selectedValues = Object.keys(selectedCheckboxes).filter(
            (checkboxId) => selectedCheckboxes[checkboxId]
        );

        setSelectedValues(selectedValues);

        resetPage(); // 페이지 초기화

        getHomeProduct(
            searchParams,
            (searchKeyword = { searchKeyword }),
            selectedValues
        )
            .then(({ data, status }) => {
                if (status === 200 && _.isObject(data.data)) {
                    const pData = data.data;

                    // console.log(
                    //     "ProductNavigation getHomeProduct Mobile:::",
                    //     searchParams,
                    //     searchKeyword,
                    //     selectedValues,
                    //     pData
                    // );
                    setSearchResults(pData);
                    setFilterBoxToggle(false); // 검색 시, 스마트필터 창 닫기
                } else {
                    console.error("검색에 실패했습니다.");
                    setSearchResults([]);
                }
            })
            .catch((error) => {
                console.error("API 요청 중 오류가 발생했습니다:", error);
                setSearchResults([]);
            });
    };

    // 모든 선택된 체크박스를 해제
    const handleResetClick = () => {
        const updatedCheckboxes = {};

        Object.keys(selectedCheckboxes).forEach((checkboxId) => {
            updatedCheckboxes[checkboxId] = false;
        });

        setSelectedCheckboxes(updatedCheckboxes);
    };
    // filter box 여닫기
    const [filterToggle, setFilterToggle] = useState("");
    const filterToggleHandler = (id) => {
        if (id === filterToggle) {
            setFilterToggle("");
        } else {
            setFilterToggle(id);
        }
    };

    return (
        <div>
            {smartFilterDatas.length > 0 && (
                <>
                    <div className={classes.smart_filter_wrap}>
                        <h4>Smart Filter</h4>
                        <ul className={classes.depth_1}>
                            {smartFilterDatas.map((filter, index) => (
                                <li
                                    key={index}
                                    id={`filter_${index + 1}`}
                                    className={
                                        filterToggle === `filter_${index + 1}`
                                            ? classes.on
                                            : ""
                                    }
                                    onClick={() =>
                                        filterToggleHandler(
                                            `filter_${index + 1}`
                                        )
                                    }
                                >
                                    <span>{filter.title}</span>
                                    <div className={classes.depth_2_wrap}>
                                        {filter.smartFilterItems.map(
                                            (item, itemIndex) => (
                                                <div
                                                    className={classes.depth_2}
                                                    key={itemIndex}
                                                >
                                                    <p>
                                                        <Checkbox
                                                            className="official_st"
                                                            id={`chk_${
                                                                index + 1
                                                            }_${itemIndex + 1}`}
                                                            value={item.id}
                                                            // title={item.name}
                                                            title={
                                                                item.name +
                                                                " (" +
                                                                item.itemCount +
                                                                ")"
                                                            }
                                                            checked={
                                                                selectedCheckboxes[
                                                                    item.id
                                                                ] || false
                                                            } // 체크박스의 초기 상태 설정
                                                            onChange={(
                                                                event
                                                            ) => {
                                                                const checked =
                                                                    event.target
                                                                        .checked;
                                                                if (
                                                                    item.itemCount >
                                                                    0
                                                                ) {
                                                                    console.log(
                                                                        "체크여부 : ",
                                                                        checked,
                                                                        "item.id : ",
                                                                        item.id
                                                                    );
                                                                    setSelectedCheckboxes(
                                                                        (
                                                                            prevCheckboxes
                                                                        ) => ({
                                                                            ...prevCheckboxes,
                                                                            [item.id]:
                                                                                checked,
                                                                        })
                                                                    );
                                                                }
                                                            }}
                                                            disabled={
                                                                item.itemCount ===
                                                                0
                                                            } // itemCount가 0이면 체크박스 비활성화
                                                        />
                                                    </p>
                                                    {itemIndex + 1 <
                                                        filter.smartFilterItems
                                                            .length &&
                                                        item.tab !==
                                                            filter
                                                                .smartFilterItems[
                                                                itemIndex + 1
                                                            ].tab && <hr />}
                                                </div>
                                            )
                                        )}
                                    </div>
                                </li>
                            ))}
                        </ul>
                        <div className={classes.btn_wrap}>
                            <Button onClick={handleResetClick}>초기화</Button>
                            <Button
                                className="secondary"
                                onClick={handleSearchClick}
                            >
                                검색
                            </Button>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};
