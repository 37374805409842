import { Outlet } from "react-router-dom";
import MngCommunityNavigation from "./MngCommunityNavigation";
import classes from "../MngCommon.module.css";

function MngCommunityRootLayout() {
    return (
        <>
            <div className={classes.admin_container}>
                <MngCommunityNavigation />
                <Outlet />
            </div>
        </>
    );
}

export default MngCommunityRootLayout;
