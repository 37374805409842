import { useContext, useState } from "react";
import Button from "../../../../components/UI/Button/Button";
import Checkbox from "../../../../components/UI/Input/Checkbox";
import Input from "../../../../components/UI/Input/Input";
import Radio from "../../../../components/UI/Input/Radio";
import Modal from "../../../../components/UI/Modal/Modal";
import SelectBoxNew from "../../../../components/UI/SelectBox/SelectBoxNew";
import classes from "../../MngCommon.module.css";
import { JwtTokenContext } from "../../../../store/JwtTokenProvider";
import { useNavigate } from "react-router";
import { tabType } from "../../../../components/definedefault/DefSelectBoxValues";
import Moment from 'moment';
import MngRecommendProductModal from "./MngRecommendProductModal";
import { ReqRecommendProductCreate } from "../../../../api/site/ApiForRecommendProduct";

function MngRecommendProductCreate(props) {
    
    const loginCtx = useContext(JwtTokenContext);
    const navigate = useNavigate();

    const backHandler = () => {
        navigate("/admin/site/recommend");
    };

    const [title, setTitle] = useState();
    const [type, setType] = useState(tabType[0].key);
    const [exposeYnStatus, setExposeYnStatus ] = useState("Y")

    const changeTitleHandler = (value) => {
        setTitle(value);
    };

    const exposeYnRadioButton = (e) => {
        setExposeYnStatus(e.target.value)
    }

    const typeHandler = (key) => {
        setType(key)
    };

    const [selectedProduct, setSelectedProduct] = useState([]);

    //modal
    const [isModalOpen, setIsModalOpen] = useState(false);
    const openModalHandler = (e) => {
        e.stopPropagation();
        setIsModalOpen(true);
    };
    const handleModalOpen = (isOpen, selectedUpdateParam) => {
        setIsModalOpen(isOpen);
        setSelectedProduct(prevArray => {
            const updatedArray = [...prevArray];
    
            for (const item of selectedUpdateParam) {
                // 중복 확인
                if (!updatedArray.some(existingItem => existingItem.id === item.id)) {
                    updatedArray.push(item); // 중복되지 않은 경우에만 추가
                }
            }
    
            return updatedArray;
        });
    };

    const handleRemoveItem = (itemIdToRemove) => {
        setSelectedProduct((prevSelectedHandi) =>
          prevSelectedHandi.filter((item) => item.id !== itemIdToRemove)
        );
    };

    const createHandler = async (e) => {
        e.preventDefault();
        
        if(!title) {
          alert("탭명을 입력해주세요.");
          return
        }

        if(selectedProduct.length < 4 || selectedProduct.length > 8) {
            alert("상품을 최소 4개~ 최대 8개 선택해주세요.")
            return;
        }

        const isTrue = window.confirm('내용을 등록하시겠습니까?');
        if(isTrue) {
            try {
                const jsonData = {
                    tabSeq: type,
                    title: title,
                    exposeYn: exposeYnStatus,
                    itemList: selectedProduct.map((product) => ({
                        productDetail: {
                            id: product.id, // product.id 값을 productDetail.id로 설정
                        },
                    })),
                };

                const response = await ReqRecommendProductCreate(jsonData);
                if (response.status === 200) {
                    alert("내용이 등록되었습니다.");
                    backHandler();
                } else {
                    alert("내용 등록 실패");
                }
            } catch (error) {
                console.error("Error:", error);
            }
        } else {
          return
        }
    };

    return (
        <section>
                <div className={classes.admin_contents}>
                    <h3>추천상품 - 등록</h3>
                    <div
                        className={`${classes.contents_wrap} ${classes.search_wrap} `}
                    >
                        <div className={classes.admin_table_wrap}>
                            <table>
                                <colgroup>
                                    <col style={{ width: "10%" }} />
                                    <col style={{ width: "40%" }} />
                                    <col style={{ width: "10%" }} />
                                    <col style={{ width: "40%" }} />
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <th>등록일</th>
                                        <td>{Moment(new Date()).format('YYYY-MM-DD')}</td>
                                        <th>등록자</th>
                                        <td>{loginCtx.decodedToken.jti}</td>
                                    </tr>
                                    <tr>
                                        <th>노출여부</th>
                                        <td colSpan={3}>
                                            <div className={classes.radio_wrap}>
                                                <Radio
                                                    value="Y"
                                                    title="노출"
                                                    name="rad_01"
                                                    id="rad_01_01"
                                                    checked={exposeYnStatus === "Y"} 
                                                    onChange={exposeYnRadioButton}
                                                />
                                                <Radio
                                                    value="N"
                                                    title="미노출"
                                                    name="rad_01"
                                                    id="rad_01_02"
                                                    checked={exposeYnStatus === "N"} 
                                                    onChange={exposeYnRadioButton}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>노출탭</th>
                                        <td colSpan={3}>
                                            <SelectBoxNew
                                                className="admin_st"
                                                setSelectItemCB={typeHandler}
                                                optionDatas={tabType}
                                                showDefaultKey={type}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>탭명</th>
                                        <td colSpan={3}>
                                            <Input
                                                className="admin_st"
                                                value={title}
                                                onChange={changeTitleHandler}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>상품매핑</th>
                                        <td colSpan={3}>
                                            <ul
                                                className={
                                                    classes.product_select_box
                                                }
                                            >
                                                <li>
                                                    <Button
                                                        className="border sm"
                                                        onClick={
                                                            openModalHandler
                                                        }
                                                    >
                                                        상품선택
                                                    </Button>
                                                    <p className={classes.desc}>
                                                        * 상품은 최소 4개 ~ 최대
                                                        8개 까지 선택
                                                        가능합니다.
                                                    </p>
                                                </li>
                                                {selectedProduct.length !== 0 && selectedProduct.map((data, index) => (
                                                    <li>
                                                        <span>
                                                            {data.name}
                                                        </span>
                                                        <Button className="border xxs" onClick={() => handleRemoveItem(data.id)}>
                                                            상품삭제
                                                        </Button>
                                                    </li>
                                                ))}
                                            </ul>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className={classes.bottom_btn_wrap}>
                                <Button className="secondary sm" onClick={createHandler}>등록</Button>
                                <Button className="border sm" onClick={() => backHandler(0)}>목록</Button>
                            </div>
                        </div>
                    </div>
                </div>
            {isModalOpen && (
                <MngRecommendProductModal isModalOpenCB={handleModalOpen}/>
            )}
        </section>
    );
}

export default MngRecommendProductCreate;
