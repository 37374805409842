import { useEffect, useState } from "react";
import Moment from "moment";
import Button from "../../../components/UI/Button/Button";
import Input from "../../../components/UI/Input/Input";
import SelectBoxNew from "../../../components/UI/SelectBox/SelectBoxNew";
import Pagination from "../../../components/UI/Table/Pagination";
import classes from "../MngCommon.module.css";
import { ReqCenterList, ReqCenterSearchList } from "../../../api/company/ApiForCenter";
import { useLocation, useNavigate } from "react-router-dom";
import PaginationNew from "../../../components/UI/Table/PaginationNew";
import { DefaultAll, regionType } from "../../../components/definedefault/DefSelectBoxValues";
import { ReqRegionData } from "../../../api/common/ConditionSelectBoxApi";
import { CSVLink } from "react-csv";
import ReactFileReader from "react-file-reader";
import instance from "../../../store/AxiosInterceptor";

function MngCenter() {

    const location = useLocation();
    const navigate = useNavigate();

    

    const [centers, setCenters] = useState([]);

    const [currentPage, setCurrentPage] = useState(location.state?.currentPage ?? 0);
    const [totalPage, setTotalPage] = useState(0); //총 페이지 갯수
    const [totalCount, setTotalCount] = useState(0); //총 페이지 갯수
    const createHandler = () => {
        navigate("./create", {state: { currentPage }});
    };

    const detailHandler = (e, id) => {
        e.preventDefault();

        navigate(`./${id}`, {state: { currentPage }});
    };
    const viewAll = async () => {
        setCurrentPage(0)
        setSearch("")
        setRegionType("")

        const response = await ReqCenterList(0);
        if (response.status === 200) {
            const resData = await response.data.data;
            setTotalPage(resData.totalPages);
            setTotalCount(resData.totalElements);
            setCenters(resData.content);
        } else {
            setCenters([]);
        }
    };

    const handlePageChange = async (page) => {
        setCurrentPage(page);
    
        const searchParam = {
            region : region === "" ? null : region,
            query_text: search === "" ? null : search,
            page : page,
        }

        const response = await ReqCenterSearchList(searchParam);
        if (response.status === 200) {
            const resData = await response.data.data;
            setTotalPage(resData.totalPages);
            setTotalCount(resData.totalElements);
            setCenters(resData.content);
        } else {
            setCenters([]);
        }
    };
    useEffect(() => {
        handlePageChange(currentPage);
    }, []);
    // 검색 버튼 클릭
    const searchClick = async () => {
        setCurrentPage(0)
        
        const searchParam = {
            region : region === "" ? null : region,
            query_text: search === "" ? null : search,
            page : 0,
        }

        console.log(region)

        const response = await ReqCenterSearchList(searchParam);
        if (response.status === 200) {
            const resData = await response.data.data;
            setTotalPage(resData.totalPages);
            setTotalCount(resData.totalElements);
            setCenters(resData.content);
        } else {
            setCenters([]);
        }
    };

    const [search, setSearch] = useState();
    const [regionData, setRegionData] = useState();
    const [region, setRegionType] = useState ("");

    const searchHandler = (e) => {
        setSearch(e)
    };

    const regionDataHandler = (key) => {
        setRegionType(key);
    };

    const regionNameMouseOverHandler = (key) => {
        if (regionData.length === 1) {
            ReqRegionData (
                (result) => {
                    const success = result.map((data) => { return { key: String(data.name), value: data.name }; });
                    setRegionData((prev) => [DefaultAll, ...success]);
                },
                (excption_result) => {
                    setRegionData((prev) => [DefaultAll]);
                }
            );
        }
    }

    /// 엑셀 업로드 양식때 필요한 데이터
    const headers = [
        { label: "이름" }
        , { label: "주소" }
        , { label: "상세주소" }
        , { label: "노출여부(Y/N)" }
        , { label: "전화번호" }
        , { label: "지역명(ex.서울)" }
    ];
    const data = [];

    /// 엑셀파일 업로드
    const uploadFile = async (files) => {
        const isTrue = window.confirm('볼링장을 등록하시겠습니까?');
        if (isTrue) {
            const formData = new FormData();
            formData.append("file", files[0]); // Assuming files is an array and we take the first file

            try {
                const response = await instance.post("/api/v1/admin/center/address/excel", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        "Authorization": "Bearer " + localStorage.getItem("token"),
                    },
                });

                if (response.status === 200) {
                    alert("볼링장 등록이 완료되었습니다.");
                    viewAll();
                } else {
                    alert("볼링장 등록 실패");
                }
            } catch (error) {
                console.error("Error uploading file: ", error);
                alert("볼링장 등록 실패");
            }
        } else {
            return;
        }
    };

    return (
        <section>
            <div className={classes.admin_contents}>
                <h3>전국볼링장 - 목록</h3>
                <div
                    className={`${classes.contents_wrap} ${classes.search_wrap}`}
                >
                    <div className={classes.admin_table_wrap}>
                        <table>
                            <colgroup>
                                <col style={{ width: "11%" }} />
                                <col style={{ width: "19%" }} />
                                <col style={{ width: "11%" }} />
                                <col style={{ width: "59%" }} />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>지역</th>
                                    <td>
                                        <SelectBoxNew
                                            className="admin_st"
                                            setSelectItemCB={regionDataHandler}
                                            optionDatas={regionType}
                                            showDefaultKey={region}
                                            //mouseOverCB={regionNameMouseOverHandler}
                                        />        
                                    </td>
                                    <th>검색어</th>
                                    <td>
                                        <Input
                                            className="admin_st" placeholder="검색어 입력" onChange={searchHandler} value={search}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className={classes.bottom_btn_wrap}>
                            <Button className="border sm" onClick={searchClick}>검색</Button>
                            <Button className="secondary sm" onClick={viewAll}>
                                전체보기
                            </Button>
                        </div>
                    </div>
                </div>
                <div className={classes.contents_wrap}>
                    <div
                        className={`${classes.admin_table_wrap} ${classes.align_center}  ${classes.row_select}`}
                    >
                        <p className={classes.counter}>
                            총 <b>{totalCount}</b> 건
                        </p>
                        <table>
                            <colgroup>
                                <col style={{ width: "5%" }} />
                                <col style={{ width: "8%" }} />
                                <col style={{ width: "20%" }} />
                                <col style={{ width: "10%" }} />
                                <col style={{ width: "30%" }} />
                                <col style={{ width: "10%" }} />
                                <col style={{ width: "10%" }} />
                                <col style={{ width: "8%" }} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th>지역</th>
                                    <th>볼링장명</th>
                                    <th>연락처</th>
                                    <th>주소</th>
                                    <th>등록일</th>
                                    <th>등록자</th>
                                    <th>사용여부</th>
                                </tr>
                            </thead>
                            <tbody>
                                {centers.length !== 0 && centers.map((data, index) => (
                                    <tr onClick={(e) => detailHandler(e, data.id)}>
                                        <td>{data.seq}</td>
                                        <td>{data.region}</td>
                                        <td>{data.name}</td>
                                        <td>{data.phone}</td>
                                        <td>{data.address}</td>
                                        <td>{Moment(data.reg_date).format('YYYY-MM-DD')}</td>
                                        <td>{data.reg_id}</td>
                                        <td>{data.expose_yn ? "노출" : "미노출"}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <PaginationNew className="admin_st" totalPage={totalPage} currentPage={currentPage} defaultShowPage={5} changePageCB={handlePageChange} />
                        <div
                            className={`${classes.bottom_btn_wrap} ${classes.position_st}`}
                        >
                            <CSVLink
                                data={data}
                                headers={headers}
                                filename={"전국 볼링장"}
                            >
                                <Button className="primary upload sm">
                                    엑셀 업로드 양식 다운로드
                                </Button>
                            </CSVLink>
                            <ReactFileReader
                                handleFiles={uploadFile}
                                fileTypes={".csv"}
                            >
                            <Button className="sm">
                                엑셀 업로드
                            </Button>
                            </ReactFileReader>
                            <Button
                                className="secondary sm" onClick={createHandler}
                            >
                                등록
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default MngCenter;

export const loader = async () => {
    const response = await ReqCenterList(0);
    
    if (response.status === 200) {
        const resData = await response.data.data;
        return { data: resData.content, totalPage: resData.totalPages, totalCount : resData.totalElements };
    } else {
        return { data: [], totalPage: 0, totalCount : 0 };
    }
};