import { useState } from "react";
import classes from "./Media.module.css";
import BreadCrumb from "../../components/UI/BreadCrumb/BreadCrumb";
import Input from "../../components/UI/Input/Input";
import Button from "../../components/UI/Button/Button";
import instance from "../../store/AxiosInterceptor";
import { useLoaderData, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

function MainNoticeDetail() {
    //  breadCrumb sample data
    const location = useLocation();
    const breadCrumbList = ["커뮤니티", "공지사항"];

    const navigate = useNavigate();
    const resData = useLoaderData();
    const [noticeData, setNoticeData] = useState(resData.data);
    const [prevNotice, setPrevNotice] = useState(resData.prev_data); // 이전글 정보
    const [nextNotice, setNextNotice] = useState(resData.next_data); // 다음글 정보

    const combinedList = noticeData.attach_uri_list != null ? noticeData.attach_uri_list.map((attach_uri, index) => ({
        attach_uri,
        originalFileName: noticeData.originalFileNameList[index],
    })) : [];

    const downloadFile = async (attachUri, originalFileName) => {
        try {
            const response = await axios.get(`${attachUri}`, {
                responseType: "blob", // 파일을 Blob 형식으로 받기 위해 설정
            });

            const blob = new Blob([response.data], {
                type: response.headers["content-type"],
            });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.download = originalFileName;
            link.click();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error("파일 다운로드 에러:", error);
        }
    };

    const toList = () => {
        navigate("/media/notice", { state: location.state });
    };

    const handlePreviousClick = async (e, prevId) => {
        const response = await instance.get(
            `/api/v1/home/contest/notices/notice/${prevId}`
        );
        if (response.status === 200) {
            setNoticeData(response.data.data);
            setPrevNotice(response.data.prev_data);
            setNextNotice(response.data.next_data);
        } else {
            return [];
        }
    };

    const handleNextClick = async (e, nextId) => {
        const response = await instance.get(
            `/api/v1/home/contest/notices/notice/${nextId}`
        );
        if (response.status === 200) {
            setNoticeData(response.data.data);
            setPrevNotice(response.data.prev_data);
            setNextNotice(response.data.next_data);
        } else {
            return [];
        }
    };
    return (
        <>
            <BreadCrumb breadCrumbList={breadCrumbList} />
            <div className={classes.contents_wrap}>
                <div className={classes.view_content}>
                    <div className={classes.title}>
                        <h4>{noticeData.title}</h4>
                        <p className={classes.date}>{noticeData.regDate}</p>
                    </div>
                    <div className={classes.content}>
                        {combinedList.length != 0 && combinedList.map((item, index) => (
                            <div>
                                <Button
                                className="btn_text official_st download"
                                onClick={() => downloadFile(item.attach_uri, item.originalFileName)}
                                >
                                    {item.originalFileName}
                                </Button>
                            </div>
                        ))}
                        <div className={classes.text_box}>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: noticeData.content,
                                }}
                            />
                        </div>
                    </div>
                    <ul className={classes.list_wrap}>
                        {prevNotice != null && (
                            <li
                                className={classes.prev}
                                onClick={(e) =>
                                    handlePreviousClick(e, prevNotice.id)
                                }
                            >
                                <i>이전글</i>
                                <span>{prevNotice.title}</span>
                                <em>{prevNotice.regDate}</em>
                            </li>
                        )}
                        {nextNotice != null && (
                            <li
                                className={classes.next}
                                onClick={(e) =>
                                    handleNextClick(e, nextNotice.id)
                                }
                            >
                                <i>다음글</i>

                                <span>{nextNotice.title}</span>
                                <em>{nextNotice.regDate}</em>
                            </li>
                        )}
                    </ul>
                    <div className={classes.btn_wrap}>
                        <Button onClick={toList}>목록으로</Button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default MainNoticeDetail;

export const loader = async ({ params }) => {
    const response = await instance.get(
        `/api/v1/home/contest/notices/notice/${params.noticeId}`
    );
    if (response.status === 200) {
        return response.data;
    } else {
        return [];
    }
};
