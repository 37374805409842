import { NavLink, useLocation } from "react-router-dom";
import classes from "./KegelApp.module.css";
import { useRef, useState } from "react";
import Slider from "react-slick";

function KegelAppNavigation(props) {
    // service / video; 일치하면, depth_2 열기
    const location = useLocation();
    const isDepth1Active =
        location.pathname.includes("/usefulInfo") ||
        location.pathname.includes("/help") ||
        location.pathname.includes("/video");
    // usefulInfo 가 포함된 경우
    const isUsefulInfo = location.pathname.includes("/usefulInfo");
    // help 가 포함된 경우
    const isHelp = location.pathname.includes("/help");
    // video 가 포함된 경우
    const isVideo = location.pathname.includes("/video");
    // sub nav 슬라이더
    const sliderRef = useRef(null);
    const sliderSettings = {
        className: "header_sub_nav",
        dots: false,
        arrows: false,
        infinite: false,
        speed: 300,
        slidesToShow: 5,
        slidesToScroll: 1,
        initialSlide: 0,
        variableWidth: true,
        responsive: [
            {
                breakpoint: 425,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    const [openDepth, setOpenDepth] = useState(false);
    // const toggleDepth = (prev) => {
    //     setOpenDepth((prev) => !prev);
    // };
    const closeDepth = (prev) => {
        setOpenDepth(false);
    };
    const [open2Depth, setOpen2Depth] = useState(false);
    const toggle2Depth = (prev) => {
        setOpen2Depth((prev) => !prev);
    };
    const handleTouchStart = () => {
        setOpen2Depth(true);
    };
    const handleMouseLeave = () => {
        setOpen2Depth(false);
    };
    return (
        <div className={classes.kegel_sub_nav}>
            <h3>케겔</h3>
            <Slider ref={sliderRef} {...sliderSettings}>
                <div className={classes.depth_1_link} onClick={closeDepth}>
                    <NavLink to="machine">레인정비기계</NavLink>
                </div>
                <div className={classes.depth_1_link} onClick={closeDepth}>
                    <NavLink to="consumable">레인정비소모품</NavLink>
                </div>
                <div className={classes.depth_1_link} onClick={closeDepth}>
                    <NavLink to="training_materials">훈련도구</NavLink>
                </div>
                <div className={classes.depth_1}>
                    <NavLink
                        to="usefulInfo"
                        className={`${isDepth1Active ? classes.on : null} `}
                        // onClick={toggleDepth}
                    >
                        레인정비기계 서비스
                    </NavLink>
                </div>
            </Slider>
            <ul
                className={`${classes.depth_2} ${
                    isDepth1Active ? classes.on : null
                } ${open2Depth ? classes.open : null}`}
                onClick={toggle2Depth}
            >
                {isUsefulInfo ? (
                    <>
                        <li>
                            <NavLink to="usefulInfo">유용한 정보</NavLink>
                        </li>
                        <li>
                            <NavLink to="help">문제해결도우미</NavLink>
                        </li>
                        <li>
                            <NavLink to="video">유용한 동영상</NavLink>
                        </li>
                    </>
                ) : isHelp ? (
                    <>
                        <li>
                            <NavLink to="help">문제해결도우미</NavLink>
                        </li>
                        <li>
                            <NavLink to="usefulInfo">유용한 정보</NavLink>
                        </li>

                        <li>
                            <NavLink to="video">유용한 동영상</NavLink>
                        </li>
                    </>
                ) : (
                    <>
                        <li>
                            <NavLink to="video">유용한 동영상</NavLink>
                        </li>
                        <li>
                            <NavLink to="usefulInfo">유용한 정보</NavLink>
                        </li>
                        <li>
                            <NavLink to="help">문제해결도우미</NavLink>
                        </li>
                    </>
                )}
            </ul>
        </div>
    );
}

export default KegelAppNavigation;
