import instance from "../../store/AxiosInterceptor";

export default function UploadAttachImage(imgFile, resolveCB, rejectCB) {
    const formData = new FormData();
    formData.append("image", imgFile);

    instance
        .post("/api/v1/attach/editor", formData)
        .then((response) => {
            // console.log(response.data.url);
            resolveCB(response.data.url);
        })
        .catch((error) => {
            console.log(error);
            rejectCB(error);
        });

    // const resData = await response.json();
    // //const result = await axios.post('/api/v1/attach/editor', formData);
    // // console.log('성공 시, 백엔드가 보내주는 데이터', result.url);
    // //Image Upload 수행 및 응답 URL 획득
    // //result.url
    // const IMG_URL = `${resData.url}`;
}
