import Button from "../../components/UI/Button/Button";
import classes from "../UserHome.module.css";
function PageError() {
    // page errer 종류별로 모두 넣었습니다.
    return (
        <div className={`container ${classes.page_error_container}`}>
            {/* error 1 */}
            <div className={classes.page_error}>
                <h2>시스템 점검 중입니다.</h2>
                <div className={classes.info}>
                    <b>서비스 이용에 불편을 드려 죄송합니다.</b>
                    <p>
                        안정적인 서비스 제공을 위해 시스템 점검을 진행중입니다.
                    </p>
                    <p>
                        점검시간 동안 서비스 사용이 제한되니 양해 부탁드립니다.
                    </p>
                </div>
                <div className={classes.box}>
                    <b>점검일시</b>2023.05.17 22:00 ~ 2023.05.17 23:00
                </div>
            </div>
            {/* error 2 */}
            <div className={classes.page_error}>
                <h2 className={classes.error_2}>페이지를 찾을 수 없습니다.</h2>
                <div className={classes.info}>
                    <p>
                        방문하시려는 페이지의 주소가 잘못 입력 되었거나, 변경
                        혹은 삭제되어 페이지를 찾을 수 없습니다.
                    </p>
                    <p>
                        입력하신 주소가 정확한지 다시 한번 확인 해 주시기
                        바랍니다.
                    </p>
                </div>
                <div className={classes.btn_wrap}>
                    <Button>이전 페이지</Button>
                    <Button className="secondary">메인화면</Button>
                </div>
            </div>
            {/* error 3 */}
            <div className={classes.page_error}>
                <h2 className={classes.error_3}>서버에 오류가 발생했어요!</h2>
                <div className={classes.info}>
                    <p>
                        일시적으로 서버에 문제가 발생하여 서비스 이용이 원활하지
                        않습니다. 잠시 후, 다시 이용해주세요.
                    </p>
                    <p>
                        입력하신 주소가 정확한지 다시 한번 확인 해 주시기
                        바랍니다.
                    </p>
                </div>
            </div>
        </div>
    );
}
export default PageError;
