import axios from "axios";
import instance from "../../store/AxiosInterceptor";

/**
 * 유저 대회 목록 페이지, 대회 클릭 후 대회 상세 정보
 * @param resolveCB 응답이 성공일 경우 데이터를 넣어 호출하는 callback method
 * @returns
 */
export function ReqApplyTournamentList(
    page,
    size,
    selectedRegion,
    resolveCB,
    rejectCB
) {
    instance({
        url: `/api/v1/home/contest/apply?regionName=${selectedRegion}&page=${page}&size=${size}`,
        method: "GET",
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data.datas);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/**
 * 유저 대회 목록 페이지, 대회 클릭 후 대회 상세 정보
 */
export function ReqApplyTournamentInfo(
    contestId,
    placeId,
    resolveCB,
    rejectCB
) {
    instance({
        url: `/api/v1/home/contest/apply/${contestId}/${placeId}`,
        method: "GET",
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/**
 * 해당 대회, 해당 경기장의 몇조까지 존재하는지 가져오기 위한 API
 */
export async function ReqFindGroupName(contestId, placeId) {
    const response = await instance({
        url: `/api/v1/home/contest/apply/groupName?contestId=${contestId}&placeId=${placeId}`,
        method: "GET",
    });

    if (response.status === 200) {
        return response.data.datas;
    } else {
        return [];
    }
}

/**
 * 해당 대회, 해당 경기장의 추가 조사 항목을 검색한다.
 */
export function ReqFindReasearchList(contestId, resolveCB, rejectCB) {
    instance({
        url: `/api/v1/home/contest/apply/research?contestId=${contestId}`,
        method: "GET",
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data.datas);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/**
 * 대회 접수 페이지
 * 해당 대회, 해당 경기장의 접수 결과, 레인 배치 결과를 확인한다.
 */
export function ReqReceptionStatus(
    contestId,
    placeId,
    groupId,
    resolveCB,
    rejectCB
) {
    instance({
        url: `/api/v1/home/contest/apply/reception?contestId=${contestId}&placeId=${placeId}&groupId=${groupId}`,
        method: "GET",
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data.datas);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/**
 * 대회 접수 페이지
 * 해당 대회, 해당 경기장의 접수 결과, 레인 배치 결과를 확인한다.
 * 검색 기능
 */
export function ReqReceptionStatusWithSearch(
    contestId,
    placeId,
    text,
    resolveCB,
    rejectCB
) {
    instance({
        url: `/api/v1/home/contest/apply/reception/search?contestId=${contestId}&placeId=${placeId}&text=${text}`,
        method: "GET",
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data.datas);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/**
 * 대회 접수 페이지
 * 토큰에 담긴 memberId를 이용하여 서버측으로 보내고
 * 서버에서 개인정보를 가져온다.
 */
export function ReqMemberPersonalInfo(memberId, resolveCB, rejectCB) {
    instance({
        url: `/api/v1/home/contest/apply/userinfo`,
        method: "POST",
        data: {
            member_id: memberId,
        },
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/**
 * 사용자가 대회 신청할 경우 신청 정보를 저장 API
 * @returns
 */
export async function ReqApplyDrawTournament(bodyData, resolveCB, rejectCB) {
    instance({
        url: `/api/v1/home/contest/apply`,
        method: "POST",
        data: bodyData,
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB(error.response.data);
            }
        });
}

export async function ReqApplyOofATournament(oofaData, token) {
    const response = axios({
        headers: {
            "Content-Type": "application/json",
        },
        url: `https://t5nutx2xvv.apigw.ntruss.com/apply-contest/2023/apply/json`, // PRD
        // url: `https://t5nutx2xvv.apigw.ntruss.com/apply-contest/2023/A1LieFc4Pr/json`, // DEV
        method: "POST",
        data: {
            cmd: "apply",
            token: token,
            apply_info: oofaData,
        },
    });
    return response;
}

export async function ReqRemainTicketsForOofa(ticketData) {
    const response = axios({
        headers: {
            "Content-Type": "application/json",
        },
        url: `https://t5nutx2xvv.apigw.ntruss.com/apply-contest/2023/apply/json`,
        // url: `https://t5nutx2xvv.apigw.ntruss.com/apply-contest/2023/A1LieFc4Pr/json`,
        method: "POST",
        data: ticketData,
    });
    return response;
}

export async function ReqRemainTicketsForDraw(contestId, placeId) {
    const response = instance({
        url: `/api/v1/home/contest/apply/remainSeats/draw/${contestId}/${placeId}`,
        method: "GET",
    });
    return response;
}

/**
 * 해당 대회, 해당 경기장의 추가 조사 항목을 검색한다.
 */
export function ReqFindGroupNameWithOutTicket(
    contestId,
    placeId,
    resolveCB,
    rejectCB
) {
    instance({
        url: `/api/v1/home/contest/apply/groupName?contestId=${contestId}&placeId=${placeId}`,
        method: "GET",
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data.datas);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB(error);
            }
        });
}
