import staff_4 from "../../assets/images/staff_4.png";
import Modal from "../../components/UI/Modal/Modal";
import classes from "./IntroduceApp.module.css";
function IntroStaffsAppModal(props) {
    const { isModalOpen, staffDataCB } = props;

    const closeModalHandler1 = (e) => {
        e.stopPropagation();
        isModalOpen(false);
    };

    return (
        <Modal close={closeModalHandler1} header="STAFF 프로필">
            <main>
                <div className={classes.staff_profile_wrap}>
                    <div className={classes.profile_box}>
                        <div className={classes.img_box}>
                            <img src={staffDataCB.thumbnail_url} alt="staff" />
                        </div>
                        <div className={classes.profile}>
                            <h4>{staffDataCB.staff_name}</h4>
                            <ul>
                                <li>소속 : {staffDataCB.team}</li>
                                <li>프로기수 : {staffDataCB.pro_no}</li>
                                <li>구력 : {staffDataCB.career}</li>
                                <li>사용손 : {staffDataCB.use_hand}</li>
                                <li>포인트 랭킹 : {staffDataCB.point_rank}</li>
                                <li>경력</li>
                            </ul>
                        </div>
                    </div>
                    <div className={classes.award_box}>
                        <div className={classes.inner}>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: staffDataCB.staff_info,
                                }}
                            ></div>
                        </div>
                    </div>
                </div>
            </main>
        </Modal>
    );
}

export default IntroStaffsAppModal;
