import { useState, useEffect, useContext } from "react";
import classes from "../../MngCommon.module.css";
import Button from "../../../../components/UI/Button/Button";
import Radio from "../../../../components/UI/Input/Radio";
import { useLoaderData, useLocation, useNavigate, useParams } from "react-router-dom";
import instance from "../../../../store/AxiosInterceptor";
import moment from "moment/moment";
import QuillEditor from "../../../../components/UI/Editer/QuillEditor";
import DatePickerBox from "../../../../components/UI/DatePicker/DatePickerBox";
import { ReqUpdateNotifyDate } from "../../../../api/event/MngEventApi";
import Alert from "../../../../components/UI/Modal/Alert";

function MngWinnerDetail() {
    const resData = useLoaderData();
    const params = useParams();
    const eventId = params.eventId;

    const [notifyDate, setNotifyDate] = useState(
        resData.notify_date ? moment(resData.notify_date, "YYYY-MM-DD").toDate() : null
    );

    const onNotifyDateHandler = (value) => {
        setNotifyDate(value);
    };

    const [isEditMode, setIsEditMode] = useState(false);

    const editModeHandler = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
        setIsEditMode(true);
    };

    const location = useLocation();
    const navigate = useNavigate();
    const backHandler = () => {
        navigate("/admin/event/winner", {state: location.state});
    };
    useEffect(() => {
        window.addEventListener('popstate', backHandler);
        return () => { window.removeEventListener('popstate', backHandler); };
    }, []);
    const [exposeYn, setExposeYn] = useState(resData.chosen_expose_yn);
    const onExposeHandler = (e) => {
        setExposeYn(e.target.value);
    };

    const [content, setContent] = useState(resData.chosen_content);
    const contentChangeHandler = (value) => {
        setContent(value);
    };

    const [successModal, setSuccessModal] = useState(false);
    const successModalHandler = () => {
        setSuccessModal(false);
        navigate("/admin/event/winner", { state: location.state });
    };

    const editHandler = () => {
        const bodyData = {
            notify_date: moment(notifyDate).format("YYYYMMDDHHmm"),
            chosen_expose_yn: exposeYn,
            content: content,
        };

        if (notifyDate == null) {
            alert("필수항목을 입력해주세요.");
            return;
        }

        if (content.trim() === "") {
            alert("필수항목을 입력해주세요.");
            return;
        }

        ReqUpdateNotifyDate(
            eventId,
            bodyData,
            (result) => {
                if (result.status === 200) {
                    setSuccessModal(true);
                    return;
                } else {
                    alert("수정에러");
                }
            },
            (error_result) => {
                alert("수정에러");
            }
        );
    };

    return (
        <section>
            <div className={classes.admin_contents}>
                {isEditMode && <h3>당첨자발표 - 수정</h3>}
                {!isEditMode && <h3>당첨자발표 - 상세</h3>}
                <div className={`${classes.contents_wrap} ${classes.search_wrap} `}>
                    <div className={classes.admin_table_wrap}>
                        <table>
                            <colgroup>
                                <col style={{ width: "159px" }} />
                                <col style={{ width: "620px" }} />
                                <col style={{ width: "159px" }} />
                                <col style={{ width: "620px" }} />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>등록일</th>
                                    <td>{resData.upd_date ? resData.upd_date : resData.reg_date}</td>
                                    <th>등록자</th>
                                    <td>{resData.upd_id ? resData.upd_id : resData.reg_id}</td>
                                </tr>
                                <tr>
                                    <th>노출여부</th>
                                    <td>
                                        {isEditMode && (
                                            <div className={classes.radio_wrap}>
                                                <Radio
                                                    value="Y"
                                                    title="노출"
                                                    name="rad_01"
                                                    id="rad_01_01"
                                                    checked={exposeYn === "Y"}
                                                    onClick={onExposeHandler}
                                                />
                                                <Radio
                                                    value="N"
                                                    title="미노출"
                                                    name="rad_01"
                                                    id="rad_01_02"
                                                    checked={exposeYn === "N"}
                                                    onClick={onExposeHandler}
                                                />
                                            </div>
                                        )}
                                        {!isEditMode && resData.chosen_expose_yn === "Y" && "노출"}
                                        {!isEditMode && resData.chosen_expose_yn === "N" && "미노출"}
                                    </td>
                                    <th>당첨자 발표일</th>
                                    <td>
                                        {isEditMode && (
                                            <DatePickerBox
                                                id="notify_date"
                                                onChange={onNotifyDateHandler}
                                                value={notifyDate}
                                            />
                                        )}
                                        {!isEditMode && resData.notify_date}
                                    </td>
                                </tr>
                                <tr>
                                    <th>이벤트 기간</th>
                                    <td colSpan={3}>
                                        {moment(resData.event_start_date, "YYYYMMDDHHmm").format(
                                            "YYYY-MM-DD [HH[시] mm[분]]"
                                        )}{" "}
                                        ~{" "}
                                        {moment(resData.event_end_date, "YYYYMMDDHHmm").format(
                                            "YYYY-MM-DD [HH[시] mm[분]]"
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <th>제목</th>
                                    <td colSpan={3}>{resData.event_title}</td>
                                </tr>
                                <tr>
                                    <th>내용</th>
                                    <td colSpan={3}>
                                        {isEditMode && (
                                            <div style={{ height: "400px" }}>
                                                <QuillEditor
                                                    height="360"
                                                    width="auto"
                                                    onValueChangeCB={contentChangeHandler}
                                                    value={content}
                                                />
                                            </div>
                                        )}
                                        {!isEditMode && (
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: resData.chosen_content,
                                                }}
                                            />
                                        )}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className={classes.bottom_btn_wrap}>
                            {!isEditMode && (
                                <Button className="border sm" onClick={editModeHandler}>
                                    수정
                                </Button>
                            )}
                            {isEditMode && (
                                <Button className="border sm" onClick={editHandler}>
                                    수정
                                </Button>
                            )}
                            <Button className="secondary sm" onClick={backHandler}>
                                목록
                            </Button>
                        </div>
                        {successModal && (
                            <Alert type="alert" confirm="확인" close={successModalHandler}>
                                {"이벤트가 수정되었습니다."}
                            </Alert>
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
}
export default MngWinnerDetail;

//이벤트 상세내용 가져오기
export const loader = async ({ params }) => {
    const response = await instance({
        method: "GET",
        url: `/api/v1/admin/event/${params.eventId}`,
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    }).catch(function (error) {
        if (error.response) {
            return error.response;
        } else if (error.request) {
            return null;
        } else {
            return null;
        }
    });
    if (response == null || response.status !== 200) {
        return [];
    }
    if (response.data.status === 200) {
        console.log(response.data);
        return response.data;
    } else {
        return [];
    }
};

//이벤트 상세내용 가져오기
