import Moment from "moment";
import { useState } from "react";
import { useLoaderData } from "react-router-dom";
import instance from "../../../store/AxiosInterceptor";
import classes from "../TournamentContainerApp.module.css";
import axios from "axios";

function TournamentNoticeViewDetailApp() {
    const resData = useLoaderData();

    const [notice, setNotice] = useState(resData.data);
    const [prevNotice, setPrevNotice] = useState(resData.prev_data); // 이전글 정보
    const [nextNotice, setNextNotice] = useState(resData.next_data); // 다음글 정보

    const combinedList =
        notice.attach_uri_list != null
            ? notice.attach_uri_list.map((attach_uri, index) => ({
                  attach_uri,
                  originalFileName: notice.originalFileNameList[index],
              }))
            : [];

    const downloadFile = async (attachUri, originalFileName) => {
        try {
            const response = await axios.get(`${attachUri}`, {
                responseType: "blob", // 파일을 Blob 형식으로 받기 위해 설정
            });

            const blob = new Blob([response.data], {
                type: response.headers["content-type"],
            });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.download = originalFileName;
            link.click();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error("파일 다운로드 에러:", error);
        }
    };

    function resCtType(ct_type) {
        switch (ct_type) {
            case "K":
                return "왕중왕전";
            case "L":
                return "이벤트";
            case "A":
                return "고객감사 페스티벌";
            case "B":
                return "챔피언십";
            case "G":
                return "공통";
            default:
                return "";
        }
    }

    function resCtTypeClass(ctType) {
        switch (ctType) {
            case "T":
                return classes.type_a;
            case "C":
                return classes.type_b;
            case "K":
                return classes.type_c;
            case "L":
                return classes.type_d;
            case "A":
                return classes.type_e;
            default:
                return "";
        }
    }

    const handlePreviousClick = async (e, prevId) => {
        const response = await instance.get(
            `/api/v1/home/contest/contestNotice/${prevId}`
        );
        if (response.status === 200) {
            setNotice(response.data.data);
            setPrevNotice(response.data.prev_data);
            setNextNotice(response.data.next_data);
        } else {
            return [];
        }
    };

    const handleNextClick = async (e, nextId) => {
        const response = await instance.get(
            `/api/v1/home/contest/contestNotice/${nextId}`
        );
        if (response.status === 200) {
            setNotice(response.data.data);
            setPrevNotice(response.data.prev_data);
            setNextNotice(response.data.next_data);
        } else {
            return [];
        }
    };

    return (
        <>
            {/* 모바일에서는 TournamentAppRoot 사용으로, 구조 변경합니다. section 아래에서 부터 페이지 컴포넌트 시작 */}
            <div className={classes.search_wrap}>
                {/* <Input />
                <Button className="search_sm" /> */}
            </div>
            <div className={classes.view_content}>
                <div className={classes.title}>
                    <b className={resCtTypeClass(notice.ct_type)}>
                        {resCtType(notice.ct_type)}
                    </b>
                    <h3>{notice.title}</h3>
                    <em>{notice.regDate}</em>
                </div>

                <div className={classes.content_wrap}>
                    {combinedList.length != 0 &&
                        combinedList.map((item, index) => (
                            <div>
                                <p
                                    className={classes.download}
                                    onClick={() =>
                                        downloadFile(
                                            item.attach_uri,
                                            item.originalFileName
                                        )
                                    }
                                >
                                    {item.originalFileName}
                                </p>
                            </div>
                        ))}

                    <div className={classes.content}>
                        {" "}
                        <div
                            dangerouslySetInnerHTML={{
                                __html: notice.content,
                            }}
                        />
                    </div>
                </div>

                <ul className={classes.list_wrap}>
                    {prevNotice != null && (
                        <li
                            className={classes.prev}
                            onClick={(e) => handlePreviousClick(e, prevNotice.id)}
                        >
                            <i>이전글</i>
                            <b className={resCtTypeClass(prevNotice.ct_type)}>
                                {resCtType(prevNotice.ct_type)}
                            </b>
                            <span>{prevNotice.title}</span>
                            <em>{Moment(prevNotice.regDate).format("YYYY-MM-DD")}</em>
                        </li>
                    )}
                    {nextNotice != null && (
                        <li
                            className={classes.next}
                            onClick={(e) => handleNextClick(e, nextNotice.id)}
                        >
                            <i>다음글</i>
                            <b className={resCtTypeClass(nextNotice.ct_type)}>
                                {resCtType(nextNotice.ct_type)}
                            </b>
                            <span>{nextNotice.title}</span>
                            <em>{Moment(nextNotice.regDate).format("YYYY-MM-DD")}</em>
                        </li>
                    )}
                </ul>
            </div>
        </>
    );
}
export default TournamentNoticeViewDetailApp;

export const loader = async ({ params }) => {
    const response = await instance.get(
        `/api/v1/home/contest/notices/contestNotice/${params.ctNoticeId}`
    );
    if (response.status === 200) {
        return response.data;
    } else {
        return [];
    }
};
