import instance from "../../store/AxiosInterceptor";

// 참가자 정보 리스트
export async function ReqContestApplyList(searchParm) {
    const response = await instance
        .post("/api/v1/admin/contest/competitor/apply/contest", searchParm)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            if (error.response) {
                // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
                return {
                    status: error.response.status,
                    error: "",
                };
            } else if (error.request) {
                // 요청이 이루어 졌으나 응답을 받지 못했습니다.
                return {
                    status: 600,
                    error: "요청에 대한 응답 없음",
                };
            } else {
                // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
                return {
                    status: 600,
                    error: "요청설정 에러",
                };
            }
        });
    return response;
}

// 참가자 정보 리스트
export async function ReqContestApplyMessageList(searchParm) {
    const response = await instance
        .post("/api/v1/admin/contest/competitor/apply/contest/message", searchParm)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            if (error.response) {
                // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
                return {
                    status: error.response.status,
                    error: "",
                };
            } else if (error.request) {
                // 요청이 이루어 졌으나 응답을 받지 못했습니다.
                return {
                    status: 600,
                    error: "요청에 대한 응답 없음",
                };
            } else {
                // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
                return {
                    status: 600,
                    error: "요청설정 에러",
                };
            }
        });
    return response;
}

// 참가자 정보 리스트(변경이력)
export async function ReqContestApplyStatusList(searchParm) {
    const response = await instance
        .post("/api/v1/admin/contest/competitor/apply/contest/status", searchParm)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            if (error.response) {
                // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
                return {
                    status: error.response.status,
                    error: "",
                };
            } else if (error.request) {
                // 요청이 이루어 졌으나 응답을 받지 못했습니다.
                return {
                    status: 600,
                    error: "요청에 대한 응답 없음",
                };
            } else {
                // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
                return {
                    status: 600,
                    error: "요청설정 에러",
                };
            }
        });
    return response;
}

// 참가자 정보 수정
export async function ReqContestApplyUpdate(updateData) {
    const response = await instance
        .put("/api/v1/admin/contest/competitor/apply/contest", updateData)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            if (error.response) {
                // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
                return {
                    status: error.response.status,
                    error: "",
                };
            } else if (error.request) {
                // 요청이 이루어 졌으나 응답을 받지 못했습니다.
                return {
                    status: 600,
                    error: "요청에 대한 응답 없음",
                };
            } else {
                // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
                return {
                    status: 600,
                    error: "요청설정 에러",
                };
            }
        });
    return response;
}

// 참가자 자리 변경 목록
export async function ReqContestApplyChangeSeatList(id) {
    const response = await instance
        .get(`/api/v1/admin/contest/competitor/apply/changeSeat/${id}`)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            if (error.response) {
                // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
                return {
                    status: error.response.status,
                    error: "",
                };
            } else if (error.request) {
                // 요청이 이루어 졌으나 응답을 받지 못했습니다.
                return {
                    status: 600,
                    error: "요청에 대한 응답 없음",
                };
            } else {
                // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
                return {
                    status: 600,
                    error: "요청설정 에러",
                };
            }
        });
    return response;
}

// 조
export async function ReqContestApplyGroup(contestID) {
    const response = await instance
        .get(`/api/v1/admin/contest/group/${contestID}`)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            if (error.response) {
                // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
                return {
                    status: error.response.status,
                    error: "",
                };
            } else if (error.request) {
                // 요청이 이루어 졌으나 응답을 받지 못했습니다.
                return {
                    status: 600,
                    error: "요청에 대한 응답 없음",
                };
            } else {
                // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
                return {
                    status: 600,
                    error: "요청설정 에러",
                };
            }
        });
    return response;
}

// 레인
export async function ReqContestApplyPlace(placeID) {
    const response = await instance
        .get(`/api/v1/admin/contest/place/${placeID}`)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            if (error.response) {
                // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
                return {
                    status: error.response.status,
                    error: "",
                };
            } else if (error.request) {
                // 요청이 이루어 졌으나 응답을 받지 못했습니다.
                return {
                    status: 600,
                    error: "요청에 대한 응답 없음",
                };
            } else {
                // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
                return {
                    status: 600,
                    error: "요청설정 에러",
                };
            }
        });
    return response;
}

//자리 변경 수정
export async function ReqContestApplyChangeSeatUpdate(updateData) {
    const response = await instance
        .put("/api/v1/admin/contest/competitor/apply/changeSeat", updateData)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            if (error.response) {
                // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
                return {
                    status: error.response.status,
                    error: "",
                };
            } else if (error.request) {
                // 요청이 이루어 졌으나 응답을 받지 못했습니다.
                return {
                    status: 600,
                    error: "요청에 대한 응답 없음",
                };
            } else {
                // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
                return {
                    status: 600,
                    error: "요청설정 에러",
                };
            }
        });
    return response;
}

// 참가자 정보 변경 조회
export async function ReqContestApplyStatusInfo(id) {
    const response = await instance
        .get(`/api/v1/admin/contest/competitor/apply/status/${id}`)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            if (error.response) {
                // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
                return {
                    status: error.response.status,
                    error: "",
                };
            } else if (error.request) {
                // 요청이 이루어 졌으나 응답을 받지 못했습니다.
                return {
                    status: 600,
                    error: "요청에 대한 응답 없음",
                };
            } else {
                // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
                return {
                    status: 600,
                    error: "요청설정 에러",
                };
            }
        });
    return response;
}

// 참가자 정보 사용 현황 접수 수정
export async function ReqContestApplyResearchResultUpdate(updateData) {
    const response = await instance
        .put("/api/v1/admin/contest/competitor/apply/researchResult", updateData)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            if (error.response) {
                // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
                return {
                    status: error.response.status,
                    error: "",
                };
            } else if (error.request) {
                // 요청이 이루어 졌으나 응답을 받지 못했습니다.
                return {
                    status: 600,
                    error: "요청에 대한 응답 없음",
                };
            } else {
                // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
                return {
                    status: 600,
                    error: "요청설정 에러",
                };
            }
        });
    return response;
}

// 참가자 정보 환불 정보 수정
export async function ReqContestApplyRefundUpdate(updateData) {
    const response = await instance
        .put("/api/v1/admin/contest/competitor/apply/refund", updateData)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            if (error.response) {
                // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
                return {
                    status: error.response.status,
                    error: "",
                };
            } else if (error.request) {
                // 요청이 이루어 졌으나 응답을 받지 못했습니다.
                return {
                    status: 600,
                    error: "요청에 대한 응답 없음",
                };
            } else {
                // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
                return {
                    status: 600,
                    error: "요청설정 에러",
                };
            }
        });
    return response;
}

// 참가자 취소
export async function ReqContestApplyCancelUpdate(data) {
    const response = await instance
        .put(`/api/v1/admin/contest/competitor/apply/cancel`, data)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            if (error.response) {
                // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
                return {
                    status: error.response.status,
                    error: "",
                };
            } else if (error.request) {
                // 요청이 이루어 졌으나 응답을 받지 못했습니다.
                return {
                    status: 600,
                    error: "요청에 대한 응답 없음",
                };
            } else {
                // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
                return {
                    status: 600,
                    error: "요청설정 에러",
                };
            }
        });
    return response;
}

//문자 미리 보기
export async function ReqContestApplySendMessage(data) {
    const response = await instance
        .post("/api/v1/admin/contest/competitor/apply/message", data)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            if (error.response) {
                // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
                return {
                    status: error.response.status,
                    error: "",
                };
            } else if (error.request) {
                // 요청이 이루어 졌으나 응답을 받지 못했습니다.
                return {
                    status: 600,
                    error: "요청에 대한 응답 없음",
                };
            } else {
                // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
                return {
                    status: 600,
                    error: "요청설정 에러",
                };
            }
        });
    return response;
}

//문자 미리 보기
export async function ReqContestApplySendMessagePreviewText(data) {
    const response = await instance
        .post("/api/v1/admin/contest/competitor/apply/message/preview", data)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            if (error.response) {
                // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
                return {
                    status: error.response.status,
                    error: "",
                };
            } else if (error.request) {
                // 요청이 이루어 졌으나 응답을 받지 못했습니다.
                return {
                    status: 600,
                    error: "요청에 대한 응답 없음",
                };
            } else {
                // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
                return {
                    status: 600,
                    error: "요청설정 에러",
                };
            }
        });
    return response;
}

//문자 미리 보기 발송
export async function ReqContestApplySendMessagePreview(data) {
    const response = await instance
        .post("/api/v1/admin/contest/competitor/apply/message/preview/send", data)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            if (error.response) {
                // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
                return {
                    status: error.response.status,
                    error: "",
                };
            } else if (error.request) {
                // 요청이 이루어 졌으나 응답을 받지 못했습니다.
                return {
                    status: 600,
                    error: "요청에 대한 응답 없음",
                };
            } else {
                // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
                return {
                    status: 600,
                    error: "요청설정 에러",
                };
            }
        });
    return response;
}

export async function ReqCheckContestCondition(contestID, resolveCB, rejectCB) {
    instance({
        url: `/api/v1/home/contest/apply/checkApplyCondition/${contestID}`,
        method: "GET",
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB(error.response.data);
            }
        });
}
