import { useState } from "react";
import Button from "../../../../components/UI/Button/Button";
import Input from "../../../../components/UI/Input/Input";
import Radio from "../../../../components/UI/Input/Radio";
import SelectBoxNew from "../../../../components/UI/SelectBox/SelectBoxNew";
import classes from "../../MngCommon.module.css";
import Moment from 'moment';
import { useLoaderData, useLocation, useNavigate } from "react-router-dom";
import { faqType } from "../../../../components/definedefault/DefSelectBoxValues";
import { ReqFaqCreate, ReqFaqDetail, ReqFaqUpdate } from "../../../../api/community/ApiForFaq";
import QuillEditor from "../../../../components/UI/Editer/QuillEditor";

function MngFaqModify() {

    const faq = useLoaderData();

    const location = useLocation();
    const navigate = useNavigate();

    const backHandler = () => {
        navigate("/admin/community/faq", {state: location.state});
    };

    const [title, setTitle] = useState(faq.title);
    const [content, setContent] = useState(faq.content);
    const [exposeYn, setExposeYn ] = useState(faq.exposeYn)
    const [faqDataType, setFaqDataType] = useState(faq.boardTypeValue);

    const changeTitleHandler = (value) => {
        setTitle(value);
    };

    const contentHandler = (value) => {
        setContent(value);
    };

    const exposeYnRadioButton = (e) => {
        setExposeYn(e.target.value)
    }

    const faqTypeHandler = (key) => {
        setFaqDataType(key)
    };

    const updateHandler = async (e) => {
        e.preventDefault();
        
        if(faqDataType === null) {
            alert("유형을 선택해주세요.");
            return
        }

        if(!title) {
            alert("질문을 입력해주세요.");
            return
        }

        if(!content) {
            alert("답변을 입력해주세요.");
            return
        }

        const isTrue = window.confirm('내용을 수정하시겠습니까?');
        if(isTrue) {
            try {
                const jsonData = {
                    boardType: faqDataType,
                    title: title,
                    exposeYn: exposeYn,
                    content : content
                };
                
                const response = await ReqFaqUpdate(faq.id, jsonData);
                if (response.status === 200) {
                    alert("내용이 수정되었습니다.");
                    backHandler();
                } else {
                    alert("내용 수정 실패");
                }
            } catch (error) {
                console.error("Error:", error);
            }
        } else {
          return
        }
    };

    return (
        <section>
            <div className={classes.admin_contents}>
                <h3>FAQ - 등록/수정</h3>
                <div
                    className={`${classes.contents_wrap} ${classes.search_wrap} `}
                >
                    <div className={classes.admin_table_wrap}>
                        <table>
                            <colgroup>
                                <col style={{ width: "159px" }} />
                                <col style={{ width: "620px" }} />
                                <col style={{ width: "159px" }} />
                                <col style={{ width: "620px" }} />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>등록일</th>
                                    <td>{Moment(faq.regDate).format('YYYY-MM-DD')}</td>
                                    <th>등록자</th>
                                    <td>{faq.regID}</td>
                                </tr>
                                <tr>
                                    <th>사용여부</th>
                                    <td colSpan={3}>
                                    <div className={classes.radio_wrap}>
                                            <Radio
                                                value="Y"
                                                title="사용"
                                                name="rad_01"
                                                id="rad_01_01"
                                                checked={exposeYn === "Y"} 
                                                onChange={exposeYnRadioButton}
                                            />
                                            <Radio
                                                value="N"
                                                title="미사용"
                                                name="rad_01"
                                                id="rad_01_02"
                                                checked={exposeYn === "N"} 
                                                onChange={exposeYnRadioButton}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>유형</th>
                                    <td colSpan={3}>
                                        <SelectBoxNew
                                            className="admin_st"
                                            setSelectItemCB={faqTypeHandler}
                                            optionDatas={faqType}
                                            showDefaultKey={faqDataType}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>질문</th>
                                    <td colSpan={3}>
                                        <Input className="admin_st" onChange={changeTitleHandler} value={title}/>
                                    </td>
                                </tr>
                                <tr>
                                    <th>답변</th>
                                    <td colSpan={3}>
                                    <div
                                        style={{ border: "1px solid #dedede", height: "292px" }}
                                    >
                                        <QuillEditor
                                        height='248' width='auto'
                                        onValueChangeCB={contentHandler}
                                        value={content}
                                        />
                                    </div>    
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <div className={classes.bottom_btn_wrap}>
                            <Button className="secondary sm" onClick={updateHandler}>수정</Button>
                            <Button className="border sm" onClick={() => backHandler(0)}>목록</Button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default MngFaqModify;

export const loader = async ({ params }) => {
    console.log(params);
    const response = await ReqFaqDetail(params.faqID);
    
    if (response.status === 200) {
        const resData = await response.data;
        return resData;
    } else {
        return [];
    }
};