import classes from "../ReceptionStatus.module.css";

const LaneSearchResult = (props) => {
    return (
        <div className={classes.search_result}>
            <h4>{props.groupSeq}조</h4>
            <table className="align_center">
                <colgroup>
                    <col style={{ width: "15%" }} />
                    <col style={{ width: "40%" }} />
                    <col style={{ width: "15%" }} />
                    <col style={{ width: "15%" }} />
                </colgroup>
                <thead>
                    <tr>
                        <th>레인</th>
                        <th>이름(아이디)</th>
                        <th>성별</th>
                        <th>핸디</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            {props.lane}-{props.laneSeq}
                        </td>
                        <td>
                            {props.memberName}({props.memberId})
                        </td>
                        <td>{props.gender === "M" ? "남" : "여"}</td>
                        <td>{props.handicap}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default LaneSearchResult;
