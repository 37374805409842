import instance from "../../store/AxiosInterceptor";

export async function ReqBowlingAlleyList() {
    try {
        const response = await instance.get(
            `/api/v1/admin/contest/center/page?page=0&size=10`,
            {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            }
        );
        return response;
    } catch (error) {
        if (error.response) {
            return {
                status: error.response.status,
                error: "",
            };
        } else if (error.request) {
            return {
                status: 600,
                error: "요청에 대한 응답 없음",
            };
        } else {
            return {
                status: 600,
                error: "요청설정 에러",
            };
        }
    }
}

// 지역 추가 팝업 > 지역 추가
export async function ReqAddRegion(requestData) {
    const response = await instance
        .post("/api/v1/admin/contest/region", requestData)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            if (error.response) {
                // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
                return {
                    status: error.response.status,
                    error: "",
                };
            } else if (error.request) {
                // 요청이 이루어 졌으나 응답을 받지 못했습니다.
                return {
                    status: 600,
                    error: "요청에 대한 응답 없음",
                };
            } else {
                // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
                return {
                    status: 600,
                    error: "요청설정 에러",
                };
            }
        });
    return response;
}

// 지역 추가 팝업 > 지역 검색
export async function ReqRegionSearch(requestData) {
    const response = await instance
        .get(
            "/api/v1/admin/contest/region",
            {
                params: {
                    region: requestData,
                },
            },
            { withCredentials: true }
        )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            if (error.response) {
                // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
                return {
                    status: error.response.status,
                    error: "",
                };
            } else if (error.request) {
                // 요청이 이루어 졌으나 응답을 받지 못했습니다.
                return {
                    status: 600,
                    error: "요청에 대한 응답 없음",
                };
            } else {
                // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
                return {
                    status: 600,
                    error: "요청설정 에러",
                };
            }
        });
    return response;
}

// 지역 추가 팝업 > 지역 리스트
export async function ReqRegionList() {
    try {
        const response = await instance.get("/api/v1/admin/contest/region", {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        });
        return response;
    } catch (error) {
        if (error.response) {
            return {
                status: error.response.status,
                error: "",
            };
        } else if (error.request) {
            return {
                status: 600,
                error: "요청에 대한 응답 없음",
            };
        } else {
            return {
                status: 600,
                error: "요청설정 에러",
            };
        }
    }
}

// 볼링장 수정
export async function ReqUpdateBowlingAlley(id, requestData) {
    const response = await instance
        .put(`/api/v1/admin/contest/center/${id}`, requestData)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            if (error.response) {
                // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
                return {
                    status: error.response.status,
                    error: "",
                };
            } else if (error.request) {
                // 요청이 이루어 졌으나 응답을 받지 못했습니다.
                return {
                    status: 600,
                    error: "요청에 대한 응답 없음",
                };
            } else {
                // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
                return {
                    status: 600,
                    error: "요청설정 에러",
                };
            }
        });
    return response;
}

// 볼링장 등록
export async function ReqInsertBowlingAlley(requestData) {
    const response = await instance
        .post("/api/v1/admin/contest/center", requestData)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            if (error.response) {
                // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
                return {
                    status: error.response.status,
                    error: "",
                };
            } else if (error.request) {
                // 요청이 이루어 졌으나 응답을 받지 못했습니다.
                return {
                    status: 600,
                    error: "요청에 대한 응답 없음",
                };
            } else if (response.status === 409) {
                alert("이미 존재하는 볼링장 ID입니다.");
            } else {
                // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
                return {
                    status: 600,
                    error: "요청설정 에러",
                };
            }
        });
    return response;
}

// 볼링장 삭제
export async function ReqDeleteBowlingAlley(center_id) {
    const response = await instance
        .delete(`/api/v1/admin/contest/center/${center_id}`)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            if (error.response) {
                // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
                return {
                    status: error.response.status,
                    error: "",
                };
            } else if (error.request) {
                // 요청이 이루어 졌으나 응답을 받지 못했습니다.
                return {
                    status: 600,
                    error: "요청에 대한 응답 없음",
                };
            } else {
                // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
                return {
                    status: 600,
                    error: "요청설정 에러",
                };
            }
        });
    return response;
}
