import { useLoaderData, useLocation, useNavigate, useParams } from "react-router-dom";
import Button from "../../../../components/UI/Button/Button";

import classes from "../../MngCommon.module.css";
import instance from "../../../../store/AxiosInterceptor";
import { useEffect } from "react";
function MngAdminAccountDetail() {
    const resData = useLoaderData();

    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    const adminId = params.adminId;

    const toList = () => {
        navigate("/admin/users/adminAccount", { state: location.state });
    };
    useEffect(() => {
        window.addEventListener('popstate', toList);
        return () => { window.removeEventListener('popstate', toList) };
    }, [])
    const toUpdate = () => {
        navigate(`/admin/users/adminAccount/update/${adminId}`);
    };

    return (
        <section>
            <div className={classes.admin_contents}>
                <h3>관리자 계정관리 - 상세</h3>
                <div className={`${classes.contents_wrap} ${classes.search_wrap} `}>
                    <div className={classes.admin_table_wrap}>
                        <table>
                            <colgroup>
                                <col style={{ width: "159px" }} />
                                <col style={{ width: "*" }} />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>아이디</th>
                                    <td>{resData.admin_member_id}</td>
                                </tr>
                                <tr>
                                    <th>관리자명</th>
                                    <td>{resData.admin_name}</td>
                                </tr>
                                <tr>
                                    <th>담당업무</th>
                                    <td>{resData.assigned_task}</td>
                                </tr>
                                <tr>
                                    <th>연락처</th>
                                    <td>{resData.admin_phone}</td>
                                </tr>
                                <tr>
                                    <th>이메일</th>
                                    <td>{resData.admin_email}</td>
                                </tr>
                                <tr>
                                    <th>상태</th>
                                    <td>{resData.expired === "N" ? "사용" : "사용중지"}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className={`${classes.admin_table_wrap} ${classes.align_center} `}>
                        <h4>* 업무권한</h4>
                        <table>
                            <tbody>
                                <tr>
                                    <th rowSpan={2}></th>
                                    <th colSpan={6}>대회운영</th>
                                    <th rowSpan={2}>
                                        상품
                                        <br />
                                        관리
                                    </th>
                                    <th rowSpan={2}>케겔</th>
                                    <th rowSpan={2}>
                                        커뮤
                                        <br />
                                        니티
                                    </th>
                                    <th rowSpan={2}>
                                        회사
                                        <br />
                                        소개
                                    </th>
                                    <th rowSpan={2}>이벤트</th>
                                    <th rowSpan={2}>
                                        회원
                                        <br />
                                        관리
                                    </th>
                                    <th rowSpan={2}>
                                        웹사이트
                                        <br />
                                        관리
                                    </th>
                                    <th rowSpan={2}>통계</th>
                                    <th rowSpan={2}>
                                        <span className="require_red">*</span>
                                        <br />
                                        볼링장
                                    </th>
                                </tr>
                                <tr>
                                    <th>
                                        대회
                                        <br />
                                        등록
                                    </th>
                                    <th>
                                        경기장
                                        <br />
                                        등록
                                    </th>
                                    <th>
                                        볼링장
                                        <br />
                                        등록
                                    </th>
                                    <th>
                                        게재
                                        <br />
                                        관리
                                    </th>
                                    <th>
                                        접수/
                                        <br />
                                        참가관리
                                    </th>
                                    <th>
                                        문자발송
                                        <br />
                                        관리
                                    </th>
                                </tr>

                                <tr>
                                    <th>
                                        등록
                                        <br />
                                        /수정
                                    </th>
                                    <td>
                                        <span className={resData.ct_main === 2 ? classes.icon_o : ""}></span>
                                    </td>
                                    <td>
                                        <span className={resData.ct_place === 2 ? classes.icon_o : ""}></span>
                                    </td>
                                    <td>
                                        <span className={resData.ct_center === 2 ? classes.icon_o : ""}></span>
                                    </td>
                                    <td>
                                        <span className={resData.ct_mng_expose === 2 ? classes.icon_o : ""}></span>
                                    </td>
                                    <td>
                                        <span className={resData.ct_mng_applier === 2 ? classes.icon_o : ""}></span>
                                    </td>
                                    <td>
                                        <span className={resData.ct_config_sms === 2 ? classes.icon_o : ""}></span>
                                    </td>
                                    <td rowSpan={2}>
                                        <span className={resData.product === 2 ? classes.icon_o : ""}></span>
                                    </td>
                                    <td rowSpan={2}>
                                        <span className={resData.kegel === 2 ? classes.icon_o : ""}></span>
                                    </td>
                                    <td rowSpan={2}>
                                        <span className={resData.community === 2 ? classes.icon_o : ""}></span>
                                    </td>
                                    <td rowSpan={2}>
                                        <span className={resData.intro === 2 ? classes.icon_o : ""}></span>
                                    </td>
                                    <td rowSpan={2}>
                                        <span className={resData.event === 2 ? classes.icon_o : ""}></span>
                                    </td>
                                    <td rowSpan={2}>
                                        <span className={resData.member === 2 ? classes.icon_o : ""}></span>
                                    </td>
                                    <td rowSpan={2}>
                                        <span className={resData.website === 2 ? classes.icon_o : ""}></span>
                                    </td>
                                    <td rowSpan={2}>
                                        <span className={resData.statistics === 2 ? classes.icon_o : ""}></span>
                                    </td>
                                    <td rowSpan={2}>
                                        <span className={resData.bowling_center === 1 ? classes.icon_o : ""}></span>
                                    </td>
                                </tr>
                                <tr>
                                    <th>조회</th>
                                    <td><span className={resData.ct_main === 1 ? classes.icon_o : ""}></span></td>
                                    <td><span className={resData.ct_place === 1 ? classes.icon_o : ""}></span></td>
                                    <td><span className={resData.ct_center === 1 ? classes.icon_o : ""}></span></td>
                                    <td><span className={resData.ct_mng_expose === 1 ? classes.icon_o : ""}></span></td>
                                    <td><span className={resData.ct_mng_applier === 1 ? classes.icon_o : ""}></span></td>
                                    <td><span className={resData.ct_config_sms === 1 ? classes.icon_o : ""}></span></td>
                                </tr>
                            </tbody>
                        </table>
                        <div className={classes.bottom_btn_wrap}>
                            <Button className="secondary sm" onClick={toUpdate}>
                                수정
                            </Button>
                            <Button className=" border sm" onClick={toList}>
                                목록
                            </Button>
                        </div>
                        <div className={classes.info_box}>
                            <ul>
                                <li>
                                    * 볼링장 관리자의 경우 아래메뉴에서 자신의 볼링장에 대한 데이터만 조회/등록 가능
                                </li>
                                <li>
                                    - 대회운영 {">"} 대회관리 {">"} 대회진행 인쇄물
                                </li>
                                <li>
                                    - 대회운영 {">"} 대회결과 {">"} 대회 진행결과 업로드
                                </li>
                                <li>
                                    * 관리자 계정관리에서 볼링장관리자로 아이디 생성 후 ‘대회운영{">"} 대회관리 {">"}{" "}
                                    볼링장 등록‘에 해당 아이디 등록 시 자신의 볼링장에 대한 권한만 부여
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default MngAdminAccountDetail;

//관리자 계정 상세 가져오기
export const loader = async ({ params }) => {
    const response = await instance({
        method: "GET",
        url: `/api/v1/admin/member/admin/${params.adminId}`,
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    }).catch(function (error) {
        if (error.response) {
            // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            return error.response;
        } else if (error.request) {
            // 요청이 이루어 졌으나 응답을 받지 못했습니다.
            console.log(error.request);
            return null;
        } else {
            // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
            console.log("Error", error.message);
            return null;
        }
    });
    if (response == null || response.status !== 200) {
        return [];
    }
    if (response.data.status === 200) {
        console.log(response.data);
        return response.data;
    } else {
        return [];
    }
};
//관리자 계정 목록 리스트 가져오기
