import instance from "../../store/AxiosInterceptor";

/**
 * BO - 페이지 - 커뮤니티 - A/S 신청 - 모든 리스트
 * 모든 AS 신청 리스트를 가져온다.
 * @returns
 */
export function ReqUserAsAllList(resolveCB, rejectCB) {
    instance({
        url: `/api/v1/admin/board/as?page=0&size=10`,
        method: "GET",
    })
        .then((response) => {
            // List [ { id, name } ...]
            if (resolveCB) {
                resolveCB(response.data.data);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/**
 * BO - 페이지 - 커뮤니티 - A/S 신청 - 리스트 검색
 * 검색 AS 신청 리스트를 가져온다.
 * @returns
 */
export function ReqUserAsSearchList(params, resolveCB, rejectCB) {
    instance({
        url: `/api/v1/admin/board/as?process=${params.process}&support_type=${params.supportType}&query_type=${params.queryType}&query_text=${params.queryText}&page=0&size=10`,
        method: "GET",
    })
        .then((response) => {
            // List [ { id, name } ...]
            if (resolveCB) {
                resolveCB(response.data.data);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/**
 * BO - 페이지 - 커뮤니티 - A/S 신청 - 페이징
 * 검색 AS 신청 리스트를 가져온다.
 * @returns
 */
export function ReqUserAsPageList(params, page, resolveCB, rejectCB) {
    instance({
        url: `/api/v1/admin/board/as?process=${params.process}&support_type=${params.supportType}&query_type=${params.queryType}&query_text=${params.queryText}&page=${page}&size=10`,
        method: "GET",
    })
        .then((response) => {
            // List [ { id, name } ...]
            if (resolveCB) {
                resolveCB(response.data.data);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/**
 * BO - 페이지 - 커뮤니티 - A/S 신청 - 답변 등록
 * @returns
 */
export function ReqAddComment(params, resolveCB, rejectCB) {
    instance({
        url: `/api/v1/admin/board/as/${params.id}/comment`,
        method: "POST",
        data: {
            comment: params.comment,
            answer_complete: params.answer_complete,
        },
    })
        .then((response) => {
            // List [ { id, name } ...]
            if (resolveCB) {
                resolveCB(response.data);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/**
 * BO - 페이지 - 커뮤니티 - A/S 신청 - 답변 삭제
 * @returns
 */
export function ReqDeleteComment(asId, commentId, resolveCB, rejectCB) {
    instance({
        url: `/api/v1/admin/board/as/${asId}/comment/${commentId}`,
        method: "DELETE",
    })
        .then((response) => {
            // List [ { id, name } ...]
            if (resolveCB) {
                resolveCB(response.data);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/**
 * BO - 페이지 - 커뮤니티 - A/S 신청 - 답변 수정
 * 검색 AS 신청 리스트를 가져온다.
 * @returns
 */
export function ReqModifyComment(asId, commentId, comment, value, resolveCB, rejectCB) {
    instance({
        url: `/api/v1/admin/board/as/${asId}/comment/${commentId}`,
        method: "PUT",
        data: {
            comment: comment,
            answer_complete: value,
        },
    })
        .then((response) => {
            // List [ { id, name } ...]
            if (resolveCB) {
                resolveCB(response.data);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/**
 * BO - 페이지 - 커뮤니티 - 1:1 문의 - 모든 리스트
 * 모든 1:1 문의 리스트를 가져온다.
 * @returns
 */
export function ReqUserHelpAllList(resolveCB, rejectCB) {
    instance({
        url: `/api/v1/admin/board/help`,
        method: "GET",
        params: {
            page: 0,
            size: 10,
        },
    })
        .then((response) => {
            // List [ { id, name } ...]
            if (resolveCB) {
                resolveCB(response.data.data);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/**
 * BO - 페이지 - 커뮤니티 - 1:1 문의 - 리스트 검색
 * 검색 1:1 문의 리스트를 가져온다.
 * @returns
 */
export function ReqUserHelpSearchList(params, resolveCB, rejectCB) {
    console.log(params);
    instance({
        url: `/api/v1/admin/board/help`,
        method: "GET",
        params: {
            process: params.process,
            support_type: params.supportType,
            query_type: params.queryType,
            query_text: params.queryText,
            page: 0,
        },
    })
        .then((response) => {
            // List [ { id, name } ...]
            if (resolveCB) {
                resolveCB(response.data.data);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/**
 * BO - 페이지 - 커뮤니티 - 1:1문의 - 페이징
 * @returns
 */
export function ReqUserHelpPageList(params, page, resolveCB, rejectCB) {
    console.log(params);
    instance({
        url: `/api/v1/admin/board/help`,
        method: "GET",
        params: {
            process: params.process,
            support_type: params.supportType,
            query_type: params.queryType,
            query_text: params.queryText,
            page: page,
        },
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data.data);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/**
 * BO - 페이지 - 커뮤니티 - 1:1문의 - 답변 등록
 * @returns
 */
export function ReqHelpAddComment(params, resolveCB, rejectCB) {
    instance({
        url: `/api/v1/admin/board/help/${params.id}/comment`,
        method: "POST",
        data: {
            comment: params.comment,
            answer_complete: params.answer_complete,
        },
    })
        .then((response) => {
            // List [ { id, name } ...]
            if (resolveCB) {
                resolveCB(response.data);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/**
 * BO - 페이지 - 커뮤니티 - 1:1 문의 - 답변 삭제
 * @returns
 */
export function ReqDeleteHelpComment(helpId, commentId, resolveCB, rejectCB) {
    instance({
        url: `/api/v1/admin/board/help/${helpId}/comment/${commentId}`,
        method: "DELETE",
    })
        .then((response) => {
            // List [ { id, name } ...]
            if (resolveCB) {
                resolveCB(response.data);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/**
 * BO - 페이지 - 커뮤니티 - 1:1 문의 - 답변 수정
 * @returns
 */
export function ReqModifyHelpComment(helpId, commentId, comment, value, resolveCB, rejectCB) {
    instance({
        url: `/api/v1/admin/board/help/${helpId}/comment/${commentId}`,
        method: "PUT",
        data: {
            comment: comment,
            answer_complete: value,
        },
    })
        .then((response) => {
            // List [ { id, name } ...]
            if (resolveCB) {
                resolveCB(response.data);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/**
 * BO - 페이지 - 커뮤니티 - 1:1 문의 - 알림톡 요청
 * @returns
 */
export function ReqSendAlimTalkForHelp(helpId, resolveCB, rejectCB) {
    instance({
        url: `/api/v1/admin/board/help/${helpId}/alimtalk`,
        method: "POST",
        data: {},
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB(error);
            }
        });
}

/**
 * BO - 페이지 - 커뮤니티 - A/S 문의 - 알림톡 요청
 * @returns
 */
export function ReqSendAlimTalkForAS(asId, resolveCB, rejectCB) {
    instance({
        url: `/api/v1/admin/board/as/${asId}/alimtalk`,
        method: "POST",
        data: {},
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB(error);
            }
        });
}

/**
 * BO - 페이지 - 커뮤니티 - 1:1 문의 - 해당 유저의 알림톡 히스토리
 * @returns
 */
export function ReqFindAlimTalkHistoryForHelp(memberID, resolveCB, rejectCB) {
    instance({
        url: `/api/v1/admin/board/help/${memberID}/alimtalk`,
        method: "GET",
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB(error);
            }
        });
}

/**
 * BO - 페이지 - 커뮤니티 - A/S 신청 - 해당 유저의 알림톡 히스토리
 * @returns
 */
export function ReqFindAlimTalkHistoryForAS(memberID, resolveCB, rejectCB) {
    instance({
        url: `/api/v1/admin/board/as/${memberID}/alimtalk`,
        method: "GET",
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB(error);
            }
        });
}
