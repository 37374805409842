import { React, useState } from "react";
import classes from "../MngCommon.module.css";
import Button from "../../../components/UI/Button/Button";
import Input from "../../../components/UI/Input/Input";
import Radio from "../../../components/UI/Input/Radio";

import { useLoaderData, useLocation, useNavigate } from "react-router-dom";
import QuillEditor from "../../../components/UI/Editer/QuillEditor";
import FileBoxMj from "../../../components/UI/Input/FileBoxMj";
import SelectBoxKegel from "../../../components/UI/SelectBox/SelectBoxKegel";
import { ReqUpdateKegel } from "../../../api/kegel/KegelAPi";
import instance from "../../../store/AxiosInterceptor";

function MngKegelUpdate(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const kegelData = useLoaderData();

    const toList = () => {
        navigate("/admin/kegel", { state: location.state });
    };

    //[S] 수정 데이터 --------------------
    const [category, setCategody] = useState(kegelData.kegelType);
    const [title, setTitle] = useState(kegelData.title);
    const [content, setContent] = useState(kegelData.content);
    const [exposeYn, setExposeYn] = useState(kegelData.exposeYn);
    const isExposeChangeHandler = (e) => {
        setExposeYn(e.target.value);
    };
    const [firstSignature, setFirstSignature] = useState(
        kegelData.firstSignature
    );
    const [secondSignature, setSecondSignature] = useState(
        kegelData.secondSignature
    );
    const [thirdSignature, setThirdSignature] = useState(
        kegelData.thirdSignature
    );
    const [subDescription, setSubDescription] = useState(kegelData.title);
    const [file, setFile] = useState();
    const [fileName, setFileName] = useState(kegelData.attach_uri || "");

    const titleHandler = (value) => {
        setTitle(value);
    };
    const contentHandler = (value) => {
        setContent(value);
    };
    const firstSignatureHandler = (value) => {
        setFirstSignature(value);
    };
    const secondSignatureHandler = (value) => {
        setSecondSignature(value);
    };
    const thirdSignatureHandler = (value) => {
        setThirdSignature(value);
    };
    const subDescriptionHandler = (value) => {
        setSubDescription(value);
    };
    const handleFileChange = (file) => {
        setFile(file);
        setFileName(file ? file.name : "");
    };

    //[E] 수정 데이터 --------------------

    // const [selectedCategory, setSelectedCategory] = useState("");
    // const [selectedCategoryData, setSelectedCategoryData] = useState(
    //     kegelData.kegelType
    // );
    const sampleData = [
        {
            value: "레인정비기계",
            data: "MACHINE",
            disabled: "false",
            selected: "false",
        },
        {
            value: "레인정비소모품",
            data: "CONSUMABLE",
            disabled: "false",
            selected: "false",
        },
        {
            value: "훈련 도구",
            data: "TOOL",
            disabled: "false",
            selected: "false",
        },
    ];

    const selectedCategoryValue =
        sampleData.find((item) => item.data === kegelData.kegelType)?.value ||
        "";

    const [selectedCategory, setSelectedCategory] = useState(
        selectedCategoryValue
    );
    const [selectedCategoryData, setSelectedCategoryData] = useState(
        kegelData.kegelType
    );

    const editHandler = async (e) => {
        e.preventDefault();

        const jsonData = JSON.stringify({
            title: title,
            content: content,
            exposeYn: exposeYn,
            firstSignature: firstSignature,
            secondSignature: secondSignature,
            thirdSignature: thirdSignature,
            subDescription: subDescription,
            kegelType: selectedCategoryData,
        });

        const formData = new FormData();

        formData.append("image", file);
        formData.append(
            "body",
            new Blob([jsonData], { type: "application/json" })
        );

        const response = await ReqUpdateKegel(kegelData.id, formData);
        if (response.status === 200) {
            navigate("/admin/kegel", {state: location.state});
        } else {
            alert(
                `[${response.status}] ${
                    response.data ? response.data.error : "수정 실패"
                } `
            );
        }
    };
    return (
        <section>
            <div className={classes.admin_contents}>
                <h3>상품상세 - 수정</h3>
                <div
                    className={`${classes.contents_wrap} ${classes.search_wrap} `}
                >
                    <div className={classes.admin_table_wrap}>
                        <table>
                            <colgroup>
                                <col style={{ width: "159px" }} />
                                <col style={{ width: "620px" }} />
                                <col style={{ width: "159px" }} />
                                <col style={{ width: "620px" }} />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>등록일</th>
                                    <td>{kegelData.regDate}</td>
                                    <th>등록자</th>
                                    <td>{kegelData.regID}</td>
                                </tr>
                                <tr>
                                    <th>노출여부</th>
                                    <td colSpan={3}>
                                        <div className={classes.radio_wrap}>
                                            <Radio
                                                value="Y"
                                                title="노출"
                                                name="rad_01"
                                                id="rad_01_01"
                                                checked={exposeYn === "Y"}
                                                onChange={isExposeChangeHandler}
                                            />
                                            <Radio
                                                value="N"
                                                title="미노출"
                                                name="rad_01"
                                                id="rad_01_02"
                                                checked={exposeYn === "N"}
                                                onChange={isExposeChangeHandler}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>카테고리</th>
                                    <td colSpan={3}>
                                        <SelectBoxKegel
                                            className="admin_st"
                                            optionData={sampleData}
                                            selectedValue={selectedCategory}
                                            setSelectedValue={
                                                setSelectedCategory
                                            }
                                            selectedData={selectedCategoryData}
                                            setSelectedData={
                                                setSelectedCategoryData
                                            }
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>상품명</th>
                                    <td colSpan={3}>
                                        <Input
                                            className="admin_st w_100"
                                            placeholder="상품명 입력"
                                            value={title}
                                            onChange={titleHandler}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>설명</th>
                                    <td colSpan={3}>
                                        <Input
                                            className="admin_st w_100"
                                            value={subDescription}
                                            onChange={subDescriptionHandler}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>대표이미지</th>
                                    <td colSpan={3}>
                                        <div className={classes.flex_row}>
                                            <FileBoxMj
                                                onFileChange={handleFileChange}
                                                selectedFileName={fileName}
                                            />
                                            <p className={classes.desc}>
                                                * 이미지 사이즈 000*000px
                                            </p>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th rowSpan={3}>특징</th>
                                    <td colSpan={3}>
                                        <Input
                                            className="admin_st"
                                            value={firstSignature}
                                            onChange={firstSignatureHandler}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={3}>
                                        <Input
                                            className="admin_st"
                                            value={secondSignature}
                                            onChange={secondSignatureHandler}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={3}>
                                        <Input
                                            className="admin_st"
                                            value={thirdSignature}
                                            onChange={thirdSignatureHandler}
                                        />
                                    </td>
                                </tr>
                                {/* <tr>
                                    <th>사용자페이지 URL</th>
                                    <td colSpan={3}>
                                        <Input
                                            className="admin_st"
                                            value="https://bowlingkorea.com/kegel/laneMachinesView.do?ntt_id=43202&currRow=1"
                                        />
                                    </td>
                                </tr> */}
                                <tr>
                                    <th>상세정보</th>
                                    <td colSpan={3}>
                                        <div style={{ height: "500px" }}>
                                            <QuillEditor
                                                height="460"
                                                width="auto"
                                                onValueChangeCB={contentHandler}
                                                value={content}
                                            />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <div className={classes.bottom_btn_wrap}>
                            <Button className="border sm" onClick={editHandler}>
                                수정
                            </Button>
                            <Button className="secondary sm" onClick={toList}>
                                목록
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default MngKegelUpdate;

export const loader = async ({ params }) => {
    try {
        const response = await instance.get(
            `/api/v1/admin/kegel/${params.kegelId}`,
            {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            }
        );

        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error("Failed to fetch data");
        }
    } catch (error) {
        console.error("Error fetching data:", error);
        return null;
    }
};
