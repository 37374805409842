import React from "react";
import { Link } from "react-router-dom";
import Button from "../../../components/UI/Button/Button";

const UserApplyContestListApp = (props) => {
    const applied = props.applyStatus === "applied";
    const waiting = props.applyStatus === "waiting";
    const chosen = props.applyStatus === "chosen";
    const chosen_1 = props.applyStatus === "chosen_1";
    const chosen_9 = props.applyStatus === "chosen_9";
    const not_chosen = props.applyStatus === "not_chosen";
    const cancel = props.applyStatus === "cancel";
    const cancel_not_pay = props.applyStatus === "cancel_not_pay";
    const cancel_apply = props.applyStatus === "cancel_apply";
    const cancel_waiting = props.applyStatus === "cancel_waiting";
    const complete = props.applyStatus === "complete";
    const not_attend_a = props.applyStatus === "not_attend_a";
    const not_attend_n = props.applyStatus === "not_attend_n";

    const exposeYn = props.drawExposeYn === "Y";
    const entryFee = props.entryFee !== "0";
    const isPay = props.payStatus === "Y";

    const { competitorId } = props;
    let statusCss = "";
    switch (props.applyStatus) {
        case "비선정":
            statusCss = "gray_c";
            break;
        case "선정(결제완료)":
            statusCss = "red_c";
            break;
    }

    return (
        <tr>
            <td>{props.totalElements - (10 * props.pageNumber + props.index)}</td>
            <td>{props.contestTitle}</td>
            <td>
                {props.region} ({props.centerName})
            </td>
            <td>
                {props.ctStartDate} ~ {props.ctEndDate}
            </td>
            <td>{props.ctApplyDate}</td>
            {exposeYn && waiting && <td>대기 {props.seq}번</td>}

            {exposeYn && not_chosen && <td className="gray_c">비선정</td>}

            {applied && <td>접수</td>}

            {cancel_apply && <td>접수취소</td>}

            {cancel && <td>참가취소</td>}

            {cancel_waiting && <td>대기취소</td>}

            {cancel_not_pay && <td>참가취소(미결제)</td>}

            {!exposeYn && !applied && !cancel_apply && !cancel && !cancel_not_pay && !cancel_waiting && <td>-</td>}

            {exposeYn && (chosen || chosen_1 || chosen_9) && isPay && <td className="red_c">선정(결제완료)</td>}
            {exposeYn && (chosen || chosen_1 || chosen_9) && !isPay && entryFee && <td>선정(미결제)</td>}
            {exposeYn && (chosen || chosen_1 || chosen_9) && !isPay && !entryFee && <td>선정</td>}

            {exposeYn && complete && <td>참가등록</td>}

            {(not_attend_a || not_attend_n) && <td>불참</td>}
            <td>
                <span>사용구</span>
                {props.ballUseYn === "Y" && props?.ballSize > 0 && "등록 완료"}
                {props.ballUseYn === "Y" && props?.ballSize === 0 && "미등록"}
                {props.ballUseYn === "N" && "-"}
            </td>
            <td>
                {/* 모바일버전에서 추가된 버튼 */}
                <Button className="secondary sm">
                    <Link to={`${competitorId}`}>내역 상세</Link>
                </Button>
            </td>
        </tr>
    );
};

export default UserApplyContestListApp;
