import React, { useEffect, useState } from "react";
import Button from "../../../components/UI/Button/Button";
import classes from "../MngCommon.module.css";
import CustomTree from "../../../components/UI/Tree";
import {
    ProductCategory,
    ProductCategoryAdd,
    ProductCategoryDelete,
    ProductCategoryEdit,
    ReqUpdateCategorySeq,
} from "../../../api/product/CategoryApi";
import _ from "lodash";
import ProductCategoryInfo from "./productCategoryComponent/ProductCategoryInfo";
import { useNavigate } from "react-router";
import Alert from "../../../components/UI/Modal/Alert";

function MngProductCategory() {
    const [viewMode, setViewMode] = useState(0);
    const [isNew, setIsNew] = useState(false);
    const [treeData, setTreeData] = useState([]);
    const [dataMap, setDataMap] = useState({});
    const [selectedData, setSelectedData] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const [nSeq, setNextSeq] = useState(0);
    const navigate = useNavigate();
    // viewMode :
    // 0 - 대 카테고리 추가, 1- 대 카테고리 정보, 2- 중 카테고리 추가, 3- 중 카테고리 정보, 4- 소 카테고리 추가, 5- 소 카테고리 정보

    const convertJsonToCustomTreeData = (jsonData) => {
        const convertNode = (node) => {
            const convertedNode = {
                key: node.id.toString(),
                title: node.name,
                depth: node.depth,
            };

            if (node.children && node.children.length > 0) {
                convertedNode.children = node.children.map((child) => convertNode(child));
                convertNode.expand = true;
            }

            return convertedNode;
        };

        return jsonData.map((rootNode) => convertNode(rootNode));
    };

    const toMap = (data = []) => {
        let rs = {};
        data.forEach((item) => {
            if (item?.id) {
                rs[item.id] = item;
                if (_.isArray(item?.children) && item.children.length > 0) {
                    rs = { ...rs, ...toMap(item.children) };
                }
            }
        });
        return rs;
    };

    useEffect(() => {
        if (loaded) return;

        ProductCategory().then((res) => {
            if (_.isArray(res.data?.datas) && res.status === 200) {
                const tData = convertJsonToCustomTreeData(res.data.datas);
                //서버에서 가져온 값을 트리 형태로 보여준다.
                setTreeData(tData);

                const map = toMap(res.data.datas);
                setDataMap(map);
                setLoaded(true);
            }
        });
    }, [loaded]);

    useEffect(() => {
        if (!selectedData) setNextSeq(treeData.length + 1);
    }, [selectedData]);

    const onSelect = (id, info) => {
        if (!info.selected) {
            setSelectedData(null);
            return;
        }

        setSelectedData(dataMap[info.node.key.split("_").at(0)]);
        setIsNew(false);

        console.log("dataMap", dataMap[info.node.key.split("_").at(0)]);
    };

    const getDatas = () => {
        if (!selectedData) return [];
        const rs = [{ ...selectedData }];
        let nowData = { ...rs[0] };

        while (nowData && nowData["parent_id"] !== null && nowData["parent_id"] >= 0) {
            console.log({ nowData });
            nowData = dataMap[nowData["parent_id"]];
            rs.unshift(nowData);
        }

        const nextSeq = rs?.at(-1)?.children?.length + 1;
        console.log("setViewMode:::", 2 * rs.length - 1, { nextSeq });

        if (nSeq !== nextSeq) setNextSeq(nextSeq);

        const vMode = isNew ? 2 * rs.length : 2 * rs.length - 1;

        if (vMode !== viewMode) {
            setViewMode(vMode);
        }

        return rs;
    };

    const onDrop = (e) => {
        const dragNodePosList = e.dragNode.pos?.split("-");
        const dropNodePosList = e.node.pos?.split("-");

        if (dragNodePosList.length != dropNodePosList.length) {
            //drage한 노드와 drop한 노드의 depth가 다른경우
            alert("카테고리 변경은 불가능합니다.");
            return;
        }

        const dragNodeSeq = dragNodePosList?.at(-1);
        const dropNodeSeq = dropNodePosList?.at(-1);

        // drop한 경우 drop 전후의 부모의 값을 확인
        // 길이에 따라 조상, 부모의 값을 확인하는 곳이 다르다.
        if (dragNodePosList.length === 2) {
            const copy = [...treeData];

            const temp = copy[dragNodeSeq];
            copy[dragNodeSeq] = copy[dropNodeSeq];
            copy[dropNodeSeq] = temp;

            setTreeData(copy);
        } else if (dragNodePosList.length === 3) {
            const dragParentNodeSeq = dragNodePosList.at(1);
            const dropParentNodeSeq = dropNodePosList.at(1);

            if (dragParentNodeSeq != dropParentNodeSeq) {
                alert("카테고리 변경은 불가능합니다.");
                return;
            }

            const copy = [...treeData];
            const temp = copy[dragParentNodeSeq].children[dragNodeSeq];
            copy[dragParentNodeSeq].children[dragNodeSeq] = copy[dragParentNodeSeq].children[dropNodeSeq];
            copy[dragParentNodeSeq].children[dropNodeSeq] = temp;

            setTreeData(copy);
        } else if (dragNodePosList.length === 4) {
            const dragAncNodeSeq = dragNodePosList.at(1);
            const dropAncNodeSeq = dropNodePosList.at(1);

            const dragParentNodeSeq = dragNodePosList.at(2);
            const dropParentNodeSeq = dropNodePosList.at(2);

            if (dragAncNodeSeq != dropAncNodeSeq) {
                alert("카테고리 변경은 불가능합니다.");
                return;
            }

            if (dragParentNodeSeq != dropParentNodeSeq) {
                alert("카테고리 변경은 불가능합니다.");
                return;
            }

            const copy = [...treeData];

            const temp = copy[dragAncNodeSeq].children[dragParentNodeSeq].children[dragNodeSeq];
            copy[dragAncNodeSeq].children[dragParentNodeSeq].children[dragNodeSeq] =
                copy[dragAncNodeSeq].children[dragParentNodeSeq].children[dropNodeSeq];
            copy[dragAncNodeSeq].children[dragParentNodeSeq].children[dropNodeSeq] = temp;

            setTreeData(copy);
        }
    };

    let list = [];

    const addSeq = (treeParent) => {
        if (!treeParent) return;

        treeParent.forEach((data, index) => {
            data["seq"] = index + 1;
            if (data.children) {
                addSeq(data.children);
            }
            list.push({ key: data["key"], seq: data["seq"] });
        });
    };

    const [updateSuccessModal, setUpdateSuccessModal] = useState(false);
    const modalHandler = () => {
        setUpdateSuccessModal(false);
        navigate("/admin/product/category");
    };
    const updateCategorySeqHandler = () => {
        addSeq(treeData);

        list.sort(function (a, b) {
            return a.key - b.key;
        });

        //console.log("초기화 전", list);

        ReqUpdateCategorySeq(
            list,
            (result) => {
                if (result.status === 200) {
                    setUpdateSuccessModal(true);
                    return;
                }
            },
            (error_result) => {
                alert("에러");
            }
        );

        list = [];
        //console.log("초기화 후", list);
    };

    return (
        <>
            <section>
                <div className={classes.admin_contents}>
                    <h3>카테고리관리</h3>
                    <div className={classes.main_col2}>
                        <div className={classes.category_box_wrap}>
                            <div className={classes.btn_wrap}>
                                <Button
                                    className="xxxs primary plus"
                                    onClick={() => {
                                        setSelectedData(null);
                                        setViewMode(0);
                                    }}
                                >
                                    대 카테고리 추가
                                </Button>
                                <Button
                                    className="xxxs primary plus "
                                    onClick={() => {
                                        if (!selectedData) {
                                            alert("상위 카테고리를 선택해주세요");
                                            return;
                                        }
                                        if (selectedData.depth >= 3) {
                                            alert("최하위 카테고리는 하위 카테고리를 생성할 수 없습니다");
                                            return;
                                        }
                                        setIsNew(true);
                                    }}
                                >
                                    하위 카테고리 추가
                                </Button>
                            </div>
                            <div className={`${classes.contents_wrap} ${classes.category_box}`}>
                                <h4>전체카테고리</h4>
                                <div className={classes.category}>
                                    <CustomTree
                                        data={treeData}
                                        defaultExpanedKeys={["1"]}
                                        selectable
                                        selectedDataKey={selectedData?.id}
                                        onSelect={onSelect}
                                        expandAll
                                        draggable={true}
                                        onDrop={onDrop}
                                    />
                                </div>
                                <div className={`${classes.flex_row} ${classes.center}`}>
                                    <Button className="secondary sm" onClick={updateCategorySeqHandler}>
                                        순서 변경 적용
                                    </Button>
                                </div>
                                <p className={classes.desc_gray}>
                                    드래그앤 드롭으로 <br />
                                    카테고리 순서 변경 가능
                                </p>
                            </div>
                        </div>
                        <div className={`${classes.contents_wrap} ${classes.search_wrap} `}>
                            <ProductCategoryInfo
                                title={`${["대", "중", "소"][Math.floor(viewMode / 2)]} 카테고리`}
                                isNew={viewMode % 2 === 0}
                                depth={Math.floor(viewMode / 2) + 1}
                                datas={getDatas()}
                                nextSeq={nSeq}
                                addHandler={(params) => {
                                    ProductCategoryAdd(params).then((res) => {
                                        //console.log({ res });
                                        setLoaded(false);
                                    });
                                }}
                                editHandler={(params) => {
                                    ProductCategoryEdit(params).then((res) => {
                                        //console.log({ res });
                                        setLoaded(false);
                                        const selectedKey = selectedData.id;
                                        const selectedElement = document
                                            .querySelector(`.rc-tree-node-${selectedKey}`)
                                            .querySelector(".rc-tree-node-content-wrapper")
                                            .querySelector(".rc-tree-title");
                                        //console.log(selectedElement);
                                        selectedElement.click();
                                        setTimeout(() => {
                                            selectedElement.click();
                                            //console.log(selectedElement);
                                        }, 200);
                                    });
                                }}
                                deleteHandler={() => {
                                    if (!selectedData || _.isEmpty(selectedData) || _.isNaN(Number(selectedData?.id))) {
                                        alert("삭제할 카테고리를 선택해 주세요");
                                        return;
                                    }
                                    const reallyDelete = window.confirm("정말 삭제 하시겠습니까?");
                                    if (reallyDelete) {
                                        ProductCategoryDelete(selectedData.id)
                                            .then((res) => {
                                                if (res.data?.status === 200) {
                                                    setLoaded(false);
                                                }
                                                return res;
                                            })
                                            .catch((reason) => {
                                                //console.log({ reason });
                                                return reason;
                                            });
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>
                {updateSuccessModal && (
                    <Alert type="alert" confirm="확인" close={() => modalHandler(false)}>
                        {"정상적으로 변경되었습니다."}
                    </Alert>
                )}
            </section>
        </>
    );
}

export default MngProductCategory;
