import classes from "../MyPageApp.module.css";
import Button from "../../../components/UI/Button/Button";
import { useEffect, useRef, useState } from "react";
import { useLoaderData, useNavigate, useParams } from "react-router-dom";
import instance from "../../../store/AxiosInterceptor";
import Alert from "../../../components/UI/Modal/Alert";
import { ReqDeleteBoardHelp, ReqUpdateBoardHelp } from "../../../api/mypage/UserMyPageApi";
import { phoneNumberDefaultBox } from "../../../components/definedefault/DefSelectBoxValues";
import SelectBoxNew from "../../../components/UI/SelectBox/SelectBoxNew";
import Radio from "../../../components/UI/Input/Radio";
import { MultiInput } from "../../../components/UI/Input/Input";
import QuillEditorForText from "../../../components/UI/Editer/QuillEditorForText";
import DropZoneForAS from "../../../components/UI/DropZone/DropZoneForAS";

function MyCSHistoryDetailApp() {
    const resData = useLoaderData();
    const params = useParams();
    const helpId = params.helpId;

    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const commentList = resData.comments;
    const [tabIndex, setTabIndex] = useState(0);

    const navigate = useNavigate();

    const [content, setContent] = useState(resData.content);
    const contentHandler = (value) => {
        setContent(value);
    };

    const tabArr = [
        { id: 0, title: "1:1 문의내역", link: "/myPage/cs" },
        { id: 1, title: "A/S 신청내역", link: "/myPage/cs/as" },
    ];

    const navigateHandler = (link) => {
        navigate(link);
    };

    const [isEdit, setIsEdit] = useState(false);
    const editModeHandler = () => {
        setIsEdit(true);
    };

    const deleteHandler = () => {
        ReqDeleteBoardHelp(
            helpId,
            (result) => {
                if (result.status === 200) {
                    navigate("/myPage/cs");
                }
            },
            (error_result) => {
                alert("서버 통신 에러");
            }
        );
    };

    const [helpSupportType, setHelpSupportType] = useState(resData.support_type);

    const supportTypeHandler = (e) => {
        setHelpSupportType(e.target.value);
    };

    const topHandler = () => {
        window.scrollTo({ top: 0, behavior: "smooth" }); //상단 smooth 이동
    };

    const [input, setInput] = useState({
        memberPhoneMid: resData.phone?.substring(3, 7),
        memberPhoneLast: resData.phone?.substring(7),
        title: resData.title,
    });

    const { memberPhoneMid, memberPhoneLast, title } = input;

    const onChangeInput = (e) => {
        const { name, value } = e.target;
        setInput({
            ...input,
            [name]: value,
        });
    };

    const [showConfirmation, setShowConfirmation] = useState(false); //Img 삭제 모달 상태관리

    const numberRegex = /^\d+$/;
    const memberPhoneRegexCheck = numberRegex.test(memberPhoneMid) && numberRegex.test(memberPhoneLast);

    const phoneNumberDatas = phoneNumberDefaultBox;
    const [selectedPhoneNumber, setSelectedPhoneNumber] = useState(resData.phone?.substring(0, 3));
    const selectedPhoneNumberHandler = (key) => {
        setSelectedPhoneNumber(key);
    };

    // >>> 사진
    const [images, setImages] = useState(resData.images);
    const [ImagesDropDown, setImagesDropDown] = useState([]);

    const onHandleImageUpload = (acceptedFiles) => {
        setImages([...acceptedFiles]);
    };

    const onHandleImageUploadL = (acceptedFiles) => {
        setImagesDropDown(acceptedFiles);
    };
    //<<< 사진

    const confirmChangeHandler = () => {
        setNewFileUri(null);
        setOldFileUri(null);
        setShowConfirmation(false);
    };

    const boardObject = {
        process: "Request",
        support_type: helpSupportType,
        phone: selectedPhoneNumber + memberPhoneMid + memberPhoneLast,
        title: title,
        content: content,
        images: images,
    };
    ///>>> 1:1신청

    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [validCheck, setValidCheck] = useState(false);

    const editHandler = () => {
        setValidCheck(true);
        topHandler();

        if (!memberPhoneRegexCheck) {
            return;
        }

        if (memberPhoneMid?.trim() === "") {
            return;
        }

        if (memberPhoneLast?.trim() === "") {
            return;
        }

        if (title?.trim() === "") {
            return;
        }

        if (content?.trim() === "") {
            return;
        }

        const jsonData = JSON.stringify(boardObject);

        const formData = new FormData();

        ImagesDropDown.forEach((image, index) => {
            formData.append("image", image);
        });

        formData.append("body", new Blob([jsonData], { type: "application/json" }));

        ReqUpdateBoardHelp(
            helpId,
            formData,
            (result) => {
                if (result.status === 200) {
                    setShowSuccessModal(true);
                }
            },
            (error_result) => {
                alert("수정 실패.");
            }
        );
    };
    ///<<< 1:1신청

    return (
        <>
            {!isEdit && (
                <div className={classes.contents_wrap}>
                    <div className={`${classes.detail_table_wrap} ${classes.mobile_type}`}>
                        <h3>나의 문의</h3>
                        <table>
                            <colgroup>
                                <col style={{ width: "15%" }} />
                                <col style={{ width: "85%" }} />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>제목</th>
                                    <td>{resData.title}</td>
                                </tr>
                                <tr>
                                    <th>문의일자</th>
                                    <td>{resData.reqDate}</td>
                                </tr>
                                <tr>
                                    <th>문의항목</th>
                                    <td>{resData.as_support_type}</td>
                                </tr>
                                <tr>
                                    <th>문의내용</th>
                                    <td>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: resData.content,
                                            }}
                                        />
                                        {images?.length > 0 &&
                                            images.map((data) => (
                                                <img
                                                    src={data.uri}
                                                    alt={data.origin_name}
                                                    style={{ width: "200px", height: "200px" }}
                                                />
                                            ))}
                                    </td>
                                </tr>
                                {/* 모바일에서 클래스 추가 */}
                                {commentList?.length === 0 && (
                                    <tr className={classes.answer_box}>
                                        <th>답변</th>
                                        <td>-</td>
                                    </tr>
                                )}
                                {commentList?.map((data) => (
                                    <tr className={classes.answer_box}>
                                        <th>답변</th>
                                        <td>
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: data.comment,
                                                }}
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        {/* 모바일에서 right 클래스 삭제 */}
                        <div className={classes.btn_bottom_wrap}>
                            <Button onClick={() => navigateHandler("/myPage/cs")}>목록</Button>
                            {resData.process !== "Close" && (
                                <Button className="secondary" onClick={editModeHandler}>
                                    수정
                                </Button>
                            )}
                            <Button className="border" onClick={() => setShowDeleteConfirm(true)}>
                                삭제
                            </Button>
                        </div>
                    </div>
                    {showDeleteConfirm && (
                        <Alert
                            type="confirm"
                            cancel="취소"
                            confirm="확인"
                            close={() => setShowDeleteConfirm(false)}
                            confirmHandler={deleteHandler}
                        >
                            {"삭제하시겠습니까?"}
                        </Alert>
                    )}
                </div>
            )}
            {isEdit && (
                <div className={classes.contents_wrap}>
                    <div className={classes.contents_title}>
                        <p className={classes.info}>이용 중 불편하신 사항이나 문의하실 내용을 알려주세요.</p>
                    </div>
                    <div className={`${classes.detail_table_wrap} ${classes.mobile_type} ${classes.edit_st}`}>
                        <table>
                            <colgroup>
                                <col style={{ width: "15%" }} />
                                <col style={{ width: "85%" }} />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>문의유형</th>
                                    <td>1:1 문의</td>
                                </tr>
                                <tr>
                                    <th>문의항목</th>
                                    <td>
                                        <div className={classes.radio_wrap}>
                                            <Radio
                                                className="official_st"
                                                name="rad_01"
                                                id="rad_01_1"
                                                value="Member"
                                                title="회원정보 문의"
                                                checked={helpSupportType === "Member"}
                                                onChange={supportTypeHandler}
                                            />
                                            <Radio
                                                className="official_st"
                                                name="rad_01"
                                                id="rad_01_2"
                                                value="Contest"
                                                title="대회관련"
                                                checked={helpSupportType === "Contest"}
                                                onChange={supportTypeHandler}
                                            />
                                            <Radio
                                                className="official_st"
                                                name="rad_01"
                                                id="rad_01_3"
                                                value="Product"
                                                title="상품관련"
                                                checked={helpSupportType === "Product"}
                                                onChange={supportTypeHandler}
                                            />
                                            <Radio
                                                className="official_st"
                                                name="rad_01"
                                                id="rad_01_4"
                                                value="Etc"
                                                title="기타문의"
                                                checked={helpSupportType === "Etc"}
                                                onChange={supportTypeHandler}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        휴대폰<span className="red_c">*</span>
                                    </th>
                                    <td>
                                        <div className={classes.phone_wrap}>
                                            <SelectBoxNew
                                                className="default sm"
                                                optionDatas={phoneNumberDatas}
                                                setSelectItemCB={selectedPhoneNumberHandler}
                                                showDefaultKey={selectedPhoneNumber}
                                            />
                                            <span>-</span>
                                            <MultiInput
                                                className="h45 sm"
                                                onChange={onChangeInput}
                                                name="memberPhoneMid"
                                                value={memberPhoneMid}
                                            />
                                            <span>-</span>
                                            <MultiInput
                                                className="h45 sm"
                                                onChange={onChangeInput}
                                                name="memberPhoneLast"
                                                value={memberPhoneLast}
                                            />
                                        </div>
                                        {validCheck &&
                                            !memberPhoneRegexCheck &&
                                            (memberPhoneMid === "" || memberPhoneLast === "") && (
                                                <p className="require_red f_14">휴대폰을 입력해주세요</p>
                                            )}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        문의제목
                                        <span className="red_c">*</span>
                                    </th>
                                    <td>
                                        <MultiInput
                                            className="h45 w_100"
                                            onChange={onChangeInput}
                                            name="title"
                                            value={title}
                                        />
                                        {validCheck && title?.trim() === "" && (
                                            <p className="require_red f_14">제목 입력해주세요.</p>
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        문의내용<span className="red_c">*</span>
                                    </th>
                                    <td>
                                        <div style={{ height: "200px" }}>
                                            <QuillEditorForText
                                                height="160"
                                                width="auto"
                                                onValueChangeCB={contentHandler}
                                                value={content}
                                            />
                                        </div>
                                        {validCheck && content?.trim() === "" && (
                                            <p className="require_red f_14">문의내용을 입력해주세요.</p>
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <th>첨부 파일</th>
                                    <td>
                                        <DropZoneForAS
                                            images={images}
                                            onImageUpload={onHandleImageUpload}
                                            onImageUploadL={onHandleImageUploadL}
                                        />
                                        <div className={classes.desc_s}>
                                            * 이미지 파일 첨부 가능 갯수는 최대 5개 입니다.
                                            <br />
                                            사진은 5MB 미만의 JPG, PNG, GIF 형식으로 업로드 가능합니다.
                                            <br />
                                            파일명에 ‘_’이외의 특수문자는 사용이 불가능합니다.
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        {showConfirmModal && (
                            <Alert
                                type="confirm"
                                cancel="취소"
                                confirm="확인"
                                close={() => setShowConfirmModal(false)}
                                confirmHandler={editHandler}
                            >
                                {"수정 하시겠습니까?"}
                            </Alert>
                        )}
                        {showSuccessModal && (
                            <Alert type="alert" confirm="확인" close={() => navigateHandler("/myPage/cs")}>
                                {"수정이 완료되었습니다."}
                            </Alert>
                        )}
                        <div className={classes.btn_bottom_wrap}>
                            <Button onClick={() => navigate("/myPage/cs")}>취소</Button>
                            <Button className="secondary" onClick={() => setShowConfirmModal(true)}>
                                수정
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
export default MyCSHistoryDetailApp;

//>>>Loader
export const loader = async ({ params }) => {
    const response = await instance.get(`/api/v1/home/board/help/${params.helpId}`);
    if (response.status === 200) {
        return response.data.data;
    }
};
//<<<Loader
