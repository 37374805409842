import classes from "../MyPageApp.module.css";
import Button from "../../../components/UI/Button/Button";
import { useEffect, useRef, useState } from "react";
import { useLoaderData, useNavigate, useParams } from "react-router-dom";
import instance from "../../../store/AxiosInterceptor";
import Alert from "../../../components/UI/Modal/Alert";
import { ReqDeleteBoardAS, ReqUpdateBoardAs } from "../../../api/mypage/UserMyPageApi";
import { phoneNumberDefaultBox } from "../../../components/definedefault/DefSelectBoxValues";
import SelectBoxNew from "../../../components/UI/SelectBox/SelectBoxNew";
import Radio from "../../../components/UI/Input/Radio";
import Input, { MultiInput } from "../../../components/UI/Input/Input";
import DaumPostcode from "react-daum-postcode";
import Modal from "../../../components/UI/Modal/Modal";
import QuillEditorForText from "../../../components/UI/Editer/QuillEditorForText";
import DropZoneForAS from "../../../components/UI/DropZone/DropZoneForAS";

function MyASHistoryDetailApp() {
    const resData = useLoaderData();
    const params = useParams();
    const asId = params.asId;
    const asSupportType = resData.support_type;
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const commentList = resData.comments;

    const [content, setContent] = useState(resData.content);
    const contentHandler = (value) => {
        setContent(value);
    };

    const [input, setInput] = useState({
        title: resData.title,
        as_detail_1: resData.as_detail_1,
        as_detail_2: resData.as_detail_2,
        as_detail_3: resData.as_detail_3,
        as_detail_4: resData.as_detail_4,
        as_detail_5: resData.as_detail_5,
        as_support_type: resData.as_support_type,
        memberPhoneMid: resData.phone?.substring(3, 7),
        memberPhoneLast: resData.phone?.substring(7),
    });

    const {
        title,
        as_detail_1,
        as_detail_2,
        as_detail_3,
        as_detail_4,
        as_detail_5,
        as_support_type,
        memberPhoneMid,
        memberPhoneLast,
    } = input;

    const onChangeInput = (e) => {
        const { name, value } = e.target;
        setInput({
            ...input,
            [name]: value,
        });
    };

    const [tabIndex, setTabIndex] = useState(1);

    const navigate = useNavigate();

    const tabArr = [
        { id: 0, title: "1:1 문의내역", link: "/myPage/cs" },
        { id: 1, title: "A/S 신청내역", link: "/myPage/cs/as" },
    ];

    const navigateHandler = (link) => {
        navigate(link);
    };

    const [isEdit, setIsEdit] = useState(false);
    const editModeHandler = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
        setIsEdit(true);
    };

    const topHandler = () => {
        window.scrollTo({ top: 0, behavior: "smooth" }); //상단 smooth 이동
    };

    const numberRegex = /^\d+$/;
    const memberPhoneRegexCheck = numberRegex.test(memberPhoneMid) && numberRegex.test(memberPhoneLast);

    const phoneNumberDatas = phoneNumberDefaultBox;
    const [selectedPhoneNumber, setSelectedPhoneNumber] = useState(resData.phone?.substring(0, 3));
    const selectedPhoneNumberHandler = (key) => {
        setSelectedPhoneNumber(key);
    };

    // >>> 사진
    const [images, setImages] = useState(resData.images);
    const [ImagesDropDown, setImagesDropDown] = useState([]);

    const onHandleImageUpload = (acceptedFiles) => {
        setImages([...acceptedFiles]);
    };

    const onHandleImageUploadL = (acceptedFiles) => {
        setImagesDropDown(acceptedFiles);
    };
    //<<< 사진

    // [START] 다음 주소 API =================================================================
    const [isDaumPostcodeOpen, setIsDaumPostcodeOpen] = useState(false);
    const [zipCode, setZipCode] = useState(resData.zip); //우편번호
    const [selectedAddress, setSelectedAddress] = useState(resData.address); // 선택한 주소를 저장할 상태
    const [enteredAddress, setEnteredAddress] = useState(resData.address_sub); // 입력한 주소를 저장할 상태

    const openDaumPostcodeHandler = (e) => {
        e.stopPropagation();
        setIsDaumPostcodeOpen(true);
    };

    const handleAddressSelect = (data) => {
        const fullAddress = data.address; // 전체 주소
        const extraAddress = data.addressType === "R" ? "" : data.bname; // 도로명일 경우 법정동 추가
        const address = `${fullAddress} ${extraAddress}`; // 최종 주소
        setZipCode(data.zonecode);
        setSelectedAddress(address); // 주소를 선택하면 상태 업데이트
        setIsDaumPostcodeOpen(false); // 주소를 선택하면 모달 창 닫기
    };

    const closeDaumPostcodeHandler = () => {
        setIsDaumPostcodeOpen(false);
    };

    //우편번호 검색을 했는데, 나머지 주소를 입력하지 않은경우
    //주소입력을 아에 안하고 가입 가능 but, 하나라도 입력하면 나머지 주소를 입력해야한다.
    const addressIsNotValid = zipCode?.trim() === "" || selectedAddress?.trim() === "" || enteredAddress?.trim() === "";
    // [END] 다음 주소 API =================================================================

    const deleteHandler = () => {
        ReqDeleteBoardAS(
            asId,
            (result) => {
                if (result.status === 200) {
                    navigateHandler("/myPage/cs/as");
                } else {
                    alert("삭제 실패 값 불일치");
                }
            },
            (error_result) => {
                alert("삭제 실패 서버에러");
            }
        );
    };

    const boardObject = {
        process: "Request",
        support_type: asSupportType,
        phone: selectedPhoneNumber + memberPhoneMid + memberPhoneLast,
        zip: zipCode,
        address: selectedAddress,
        address_sub: enteredAddress,
        title: title,
        content: content,
        as_detail_1: as_detail_1,
        as_detail_2: as_detail_2,
        as_detail_3: as_detail_3,
        as_detail_4: as_detail_4,
        as_detail_5: as_detail_5,
        images: images,
    };

    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [validCheck, setValidCheck] = useState(false);
    const editHandler = () => {
        setValidCheck(true);
        topHandler();

        if (!memberPhoneRegexCheck) {
            alert("휴대폰 번호를 확인해주세요.");
            return;
        }

        if (addressIsNotValid) {
            return;
        }

        if (memberPhoneMid?.trim() === "") {
            return;
        }

        if (memberPhoneLast?.trim() === "") {
            return;
        }

        if (title?.trim() === "") {
            return;
        }

        if (content?.trim() === "") {
            return;
        }

        if (as_detail_1?.trim() === "") {
            return;
        }

        if (as_detail_2?.trim() === "") {
            return;
        }

        if (as_detail_3?.trim() === "") {
            return;
        }

        if (as_detail_4?.trim() === "") {
            return;
        }

        if (
            (asSupportType === "Ball" || asSupportType === "Shoes" || asSupportType === "Cloth") &&
            as_detail_5?.trim() === ""
        ) {
            return;
        }

        const jsonData = JSON.stringify(boardObject);

        const formData = new FormData();

        ImagesDropDown.forEach((image, index) => {
            formData.append("image", image);
        });

        formData.append("body", new Blob([jsonData], { type: "application/json" }));

        ReqUpdateBoardAs(
            asId,
            formData,
            (result) => {
                if (result.status === 200) {
                    setShowSuccessModal(true);
                }
            },
            (error_result) => {
                alert("수정에 실패하셨습니다.");
            }
        );
    };
    ///<<< AS신청

    return (
        <>
            {!isEdit && (
                <div className={classes.contents_wrap}>
                    <div className={`${classes.detail_table_wrap} ${classes.mobile_type}`}>
                        <h3>나의 문의</h3>
                        <table>
                            <colgroup>
                                <col style={{ width: "15%" }} />
                                <col style={{ width: "85%" }} />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>신청제목</th>
                                    <td>{title}</td>
                                </tr>
                                <tr>
                                    <th>신청일자</th>
                                    <td>{resData.reqDate}</td>
                                </tr>
                                <tr>
                                    <th>주소</th>
                                    <td>
                                        {resData.address} {resData.address_sub}
                                    </td>
                                </tr>
                                <tr>
                                    <th>분류</th>
                                    <td>{as_support_type}</td>
                                </tr>
                                {resData.support_type === "Ball" && (
                                    <>
                                        <tr>
                                            <th>볼명</th>
                                            <td>{as_detail_1}</td>
                                        </tr>
                                        <tr>
                                            <th>파운드</th>
                                            <td>{as_detail_2}</td>
                                        </tr>
                                        <tr>
                                            <th>시리얼 넘버</th>
                                            <td>{as_detail_3}</td>
                                        </tr>
                                        <tr>
                                            <th>구매처</th>
                                            <td>{as_detail_4}</td>
                                        </tr>
                                        <tr>
                                            <th>구매 일자</th>
                                            <td>{as_detail_5}</td>
                                        </tr>
                                    </>
                                )}
                                {resData.support_type === "Shoes" && (
                                    <>
                                        <tr>
                                            <th>상품명</th>
                                            <td>{as_detail_1}</td>
                                        </tr>
                                        <tr>
                                            <th>색상</th>
                                            <td>{as_detail_2}</td>
                                        </tr>
                                        <tr>
                                            <th>사이즈</th>
                                            <td>{as_detail_3}</td>
                                        </tr>
                                        <tr>
                                            <th>구매처</th>
                                            <td>{as_detail_4}</td>
                                        </tr>
                                        <tr>
                                            <th>구매 일자</th>
                                            <td>{as_detail_5}</td>
                                        </tr>
                                    </>
                                )}
                                {resData.support_type === "Bag" && (
                                    <>
                                        <tr>
                                            <th>상품명</th>
                                            <td>{as_detail_1}</td>
                                        </tr>
                                        <tr>
                                            <th>색상</th>
                                            <td>{as_detail_2}</td>
                                        </tr>
                                        <tr>
                                            <th>구매처</th>
                                            <td>{as_detail_3}</td>
                                        </tr>
                                        <tr>
                                            <th>구매 일자</th>
                                            <td>{as_detail_4}</td>
                                        </tr>
                                    </>
                                )}
                                {resData.support_type === "Cloth" && (
                                    <>
                                        <tr>
                                            <th>상품명</th>
                                            <td>{as_detail_1}</td>
                                        </tr>
                                        <tr>
                                            <th>색상</th>
                                            <td>{as_detail_2}</td>
                                        </tr>
                                        <tr>
                                            <th>사이즈</th>
                                            <td>{as_detail_3}</td>
                                        </tr>
                                        <tr>
                                            <th>구매처</th>
                                            <td>{as_detail_4}</td>
                                        </tr>
                                        <tr>
                                            <th>구매 일자</th>
                                            <td>{resData.as_detail_5}</td>
                                        </tr>
                                    </>
                                )}
                                {resData.support_type === "Other" && (
                                    <>
                                        <tr>
                                            <th>상품명</th>
                                            <td>{as_detail_1}</td>
                                        </tr>
                                        <tr>
                                            <th>옵션</th>
                                            <td>{as_detail_2}</td>
                                        </tr>
                                        <tr>
                                            <th>구매처</th>
                                            <td>{as_detail_3}</td>
                                        </tr>
                                        <tr>
                                            <th>구매 일자</th>
                                            <td>{as_detail_4}</td>
                                        </tr>
                                    </>
                                )}

                                <tr>
                                    <th>신청내용</th>
                                    <td>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: content,
                                            }}
                                        />
                                        {images?.length > 0 &&
                                            images.map((data) => (
                                                <img
                                                    src={data.uri}
                                                    alt={data.origin_name}
                                                    style={{ width: "200px", height: "200px" }}
                                                />
                                            ))}
                                    </td>
                                </tr>
                                {commentList?.length === 0 && (
                                    <tr className={classes.answer_box}>
                                        <th>답변</th>
                                        <td>-</td>
                                    </tr>
                                )}
                                {commentList?.map((data) => (
                                    <tr className={classes.answer_box}>
                                        <th>답변</th>
                                        <td>
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: data.comment,
                                                }}
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div className={classes.btn_bottom_wrap}>
                            <Button onClick={() => navigateHandler("/myPage/cs/as")}>목록</Button>
                            {resData.process === "Request" && (
                                <>
                                    <Button className="secondary" onClick={editModeHandler}>
                                        수정
                                    </Button>
                                    <Button className="border" onClick={() => setShowDeleteConfirm(true)}>
                                        삭제
                                    </Button>
                                </>
                            )}
                        </div>
                    </div>
                    {showDeleteConfirm && (
                        <Alert
                            type="confirm"
                            cancel="취소"
                            confirm="확인"
                            close={() => setShowDeleteConfirm(false)}
                            confirmHandler={deleteHandler}
                        >
                            {"삭제하시겠습니까?"}
                        </Alert>
                    )}
                </div>
            )}
            {isEdit && (
                <div className={classes.contents_wrap}>
                    <div className={classes.contents_title}>
                        <p className={classes.info}>A/S 신청 사항이나 문의하실 내용을 알려주세요.</p>
                    </div>
                    <div className={`${classes.detail_table_wrap} ${classes.mobile_type} ${classes.edit_st}`}>
                        <table>
                            <colgroup>
                                <col style={{ width: "15%" }} />
                                <col style={{ width: "85%" }} />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>문의유형</th>
                                    <td>A/S 신청</td>
                                </tr>
                                <tr>
                                    <th>
                                        휴대폰<span className="red_c">*</span>
                                    </th>
                                    <td>
                                        <div className={classes.phone_wrap}>
                                            <SelectBoxNew
                                                className="default sm"
                                                optionDatas={phoneNumberDatas}
                                                setSelectItemCB={selectedPhoneNumberHandler}
                                                showDefaultKey={selectedPhoneNumber}
                                            />
                                            <span>-</span>
                                            <MultiInput
                                                className="h45 sm"
                                                onChange={onChangeInput}
                                                name="memberPhoneMid"
                                                value={memberPhoneMid}
                                            />
                                            <span>-</span>
                                            <MultiInput
                                                className="h45 sm"
                                                onChange={onChangeInput}
                                                name="memberPhoneLast"
                                                value={memberPhoneLast}
                                            />
                                        </div>
                                        {validCheck &&
                                            !memberPhoneRegexCheck &&
                                            (memberPhoneMid === "" || memberPhoneLast === "") && (
                                                <p className="require_red f_14">휴대폰을 입력해주세요</p>
                                            )}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        주소<span className="red_c">*</span>
                                    </th>
                                    <td>
                                        <div className={classes.address_wrap}>
                                            <div className={classes.input_btn_wrap}>
                                                <Input
                                                    className="admin_st_sm"
                                                    value={zipCode}
                                                    id="zipCode"
                                                    onChange={setZipCode}
                                                />
                                                <Button className="border sm" onClick={openDaumPostcodeHandler}>
                                                    우편번호 검색
                                                </Button>
                                            </div>
                                            {/* mobile 구조변경 */}
                                            <Input
                                                className="h45 w_100"
                                                value={selectedAddress}
                                                id="address"
                                                onChange={setSelectedAddress}
                                            />
                                            <Input
                                                className="h45 w_100"
                                                value={enteredAddress}
                                                id="detailAddress"
                                                onChange={setEnteredAddress}
                                            />
                                            {validCheck && addressIsNotValid && (
                                                <p className="require_red f_14">주소를 입력해주세요.</p>
                                            )}
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        신청제목
                                        <span className="red_c">*</span>
                                    </th>
                                    <td>
                                        <MultiInput
                                            className="h45 w_100"
                                            onChange={onChangeInput}
                                            name="title"
                                            value={title}
                                        />
                                        {validCheck && title?.trim() === "" && (
                                            <p className="require_red f_14">제목 입력해주세요.</p>
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <th>분류</th>
                                    <td>
                                        <div className={classes.radio_wrap}>
                                            <Radio
                                                className="official_st"
                                                name="rad_02"
                                                id="rad_02_1"
                                                value="Ball"
                                                title="볼링공"
                                                checked={asSupportType === "Ball"}
                                                disabled={true}
                                            />
                                            <Radio
                                                className="official_st"
                                                name="rad_02"
                                                id="rad_02_2"
                                                value="Shoes"
                                                title="볼링신발"
                                                checked={asSupportType === "Shoes"}
                                                disabled={true}
                                            />
                                            <Radio
                                                className="official_st"
                                                name="rad_02"
                                                id="rad_02_3"
                                                value="Bag"
                                                title="볼링가방"
                                                checked={asSupportType === "Bag"}
                                                disabled={true}
                                            />
                                            <Radio
                                                className="official_st"
                                                name="rad_02"
                                                id="rad_02_4"
                                                value="Cloth"
                                                title="의류"
                                                checked={asSupportType === "Cloth"}
                                                disabled={true}
                                            />
                                            <Radio
                                                className="official_st"
                                                name="rad_02"
                                                id="rad_02_5"
                                                value="Other"
                                                title="기타 용품"
                                                checked={asSupportType === "Other"}
                                                disabled={true}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                {asSupportType === "Ball" && (
                                    <tr>
                                        <th>
                                            볼명
                                            <span className="red_c">*</span>
                                        </th>
                                        <td>
                                            <MultiInput
                                                onChange={onChangeInput}
                                                name="as_detail_1"
                                                className="h45 w_100"
                                                value={as_detail_1}
                                            />
                                            {validCheck && as_detail_1?.trim() === "" && (
                                                <p className="require_red f_14">볼명을 입력해주세요.</p>
                                            )}
                                        </td>
                                    </tr>
                                )}
                                {asSupportType !== "Ball" && (
                                    <tr>
                                        <th>
                                            상품명
                                            <span className="red_c">*</span>
                                        </th>
                                        <td>
                                            <MultiInput
                                                onChange={onChangeInput}
                                                name="as_detail_1"
                                                className="h45 w_100"
                                                value={as_detail_1}
                                            />
                                            {validCheck && as_detail_1?.trim() === "" && (
                                                <p className="require_red f_14">상품명을 입력해주세요.</p>
                                            )}
                                        </td>
                                    </tr>
                                )}
                                {asSupportType === "Ball" && (
                                    <tr>
                                        <th>
                                            파운드
                                            <span className="red_c">*</span>
                                        </th>
                                        <td>
                                            <MultiInput
                                                onChange={onChangeInput}
                                                name="as_detail_2"
                                                className="h45 w_100"
                                                value={as_detail_2}
                                            />
                                            {validCheck && as_detail_2?.trim() === "" && (
                                                <p className="require_red f_14">파운드를 입력해주세요.</p>
                                            )}
                                        </td>
                                    </tr>
                                )}
                                {(asSupportType === "Shoes" ||
                                    asSupportType === "Bag" ||
                                    asSupportType === "Cloth") && (
                                    <tr>
                                        <th>
                                            색상
                                            <span className="red_c">*</span>
                                        </th>
                                        <td>
                                            <MultiInput
                                                onChange={onChangeInput}
                                                name="as_detail_2"
                                                className="h45 w_100"
                                                value={as_detail_2}
                                            />
                                            {validCheck && as_detail_2?.trim() === "" && (
                                                <p className="require_red f_14">색상을 입력해주세요.</p>
                                            )}
                                        </td>
                                    </tr>
                                )}
                                {asSupportType === "Other" && (
                                    <tr>
                                        <th>
                                            옵션
                                            <span className="red_c">*</span>
                                        </th>
                                        <td>
                                            <MultiInput
                                                onChange={onChangeInput}
                                                name="as_detail_2"
                                                className="h45 w_100"
                                                value={as_detail_2}
                                            />
                                            {validCheck && as_detail_2?.trim() === "" && (
                                                <p className="require_red f_14">옵션 입력해주세요.</p>
                                            )}
                                        </td>
                                    </tr>
                                )}
                                {asSupportType === "Ball" && (
                                    <tr>
                                        <th>
                                            시리얼넘버
                                            <span className="red_c">*</span>
                                        </th>
                                        <td>
                                            <MultiInput
                                                onChange={onChangeInput}
                                                name="as_detail_3"
                                                className="h45 w_100"
                                                value={as_detail_3}
                                            />
                                            {validCheck && as_detail_3?.trim() === "" && (
                                                <p className="require_red f_14">시리얼 넘버를 입력해주세요.</p>
                                            )}
                                        </td>
                                    </tr>
                                )}
                                {(asSupportType === "Shoes" || asSupportType === "Cloth") && (
                                    <tr>
                                        <th>
                                            사이즈
                                            <span className="red_c">*</span>
                                        </th>
                                        <td>
                                            <MultiInput
                                                onChange={onChangeInput}
                                                name="as_detail_3"
                                                className="h45 w_100"
                                                value={as_detail_3}
                                            />
                                            {validCheck && as_detail_3?.trim() === "" && (
                                                <p className="require_red f_14">사이즈를 입력해주세요.</p>
                                            )}
                                        </td>
                                    </tr>
                                )}
                                {(asSupportType === "Bag" || asSupportType === "Other") && (
                                    <>
                                        <tr>
                                            <th>
                                                구매처
                                                <span className="red_c">*</span>
                                            </th>
                                            <td>
                                                <MultiInput
                                                    onChange={onChangeInput}
                                                    name="as_detail_3"
                                                    className="h45 w_100"
                                                    value={as_detail_3}
                                                />
                                                {validCheck && as_detail_3?.trim() === "" && (
                                                    <p className="require_red f_14">구매처를 입력해주세요.</p>
                                                )}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                구매일자
                                                <span className="red_c">*</span>
                                            </th>
                                            <td>
                                                <MultiInput
                                                    onChange={onChangeInput}
                                                    name="as_detail_4"
                                                    className="h45 w_100"
                                                    value={as_detail_4}
                                                />
                                                {validCheck && as_detail_4?.trim() === "" && (
                                                    <p className="require_red f_14">구매일자를 입력해주세요.</p>
                                                )}
                                            </td>
                                        </tr>
                                    </>
                                )}
                                {(asSupportType === "Ball" ||
                                    asSupportType === "Shoes" ||
                                    asSupportType === "Cloth") && (
                                    <>
                                        <tr>
                                            <th>
                                                구매처
                                                <span className="red_c">*</span>
                                            </th>
                                            <td>
                                                <MultiInput
                                                    onChange={onChangeInput}
                                                    name="as_detail_4"
                                                    className="h45 w_100"
                                                    value={as_detail_4}
                                                />
                                                {validCheck && as_detail_4?.trim() === "" && (
                                                    <p className="require_red f_14">구매처를 입력해주세요.</p>
                                                )}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                구매일자
                                                <span className="red_c">*</span>
                                            </th>
                                            <td>
                                                <MultiInput
                                                    onChange={onChangeInput}
                                                    name="as_detail_5"
                                                    className="h45 w_100"
                                                    value={as_detail_5}
                                                />
                                                {validCheck && as_detail_5?.trim() === "" && (
                                                    <p className="require_red f_14">구매일자를 입력해주세요.</p>
                                                )}
                                            </td>
                                        </tr>
                                    </>
                                )}
                                <tr>
                                    <th>
                                        A/S 신청내용
                                        <span className="red_c">*</span>
                                    </th>
                                    <td>
                                        <div style={{ height: "200px" }}>
                                            <QuillEditorForText
                                                height="160"
                                                width="auto"
                                                onValueChangeCB={contentHandler}
                                                value={content}
                                            />
                                        </div>
                                        {validCheck && content?.trim() === "" && (
                                            <p className="require_red f_14">A/S 신청내용을 입력해주세요.</p>
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <th>첨부 파일</th>
                                    <td>
                                        <DropZoneForAS
                                            images={images}
                                            onImageUpload={onHandleImageUpload}
                                            onImageUploadL={onHandleImageUploadL}
                                        />
                                        <div className={classes.desc_s}>
                                            * 이미지 파일 첨부 가능 갯수는 최대 5개 입니다.
                                            <br />
                                            사진은 5MB 미만의 JPG, PNG, GIF 형식으로 업로드 가능합니다.
                                            <br />
                                            파일명에 ‘_’이외의 특수문자는 사용이 불가능합니다.
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        {isDaumPostcodeOpen && (
                            <Modal className="admin_st" close={closeDaumPostcodeHandler} header="우편번호 검색">
                                <DaumPostcode onComplete={handleAddressSelect} />
                            </Modal>
                        )}
                        {showConfirmModal && (
                            <Alert
                                type="confirm"
                                cancel="취소"
                                confirm="확인"
                                close={() => setShowConfirmModal(false)}
                                confirmHandler={editHandler}
                            >
                                {"수정 하시겠습니까?"}
                            </Alert>
                        )}
                        {showSuccessModal && (
                            <Alert type="alert" confirm="확인" close={() => navigateHandler("/myPage/cs/as")}>
                                {"수정이 완료되었습니다."}
                            </Alert>
                        )}
                        <div className={classes.btn_bottom_wrap}>
                            <Button onClick={() => navigateHandler("/myPage/cs/as")}>취소</Button>
                            <Button className="secondary" onClick={() => setShowConfirmModal(true)}>
                                등록
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default MyASHistoryDetailApp;

//>>> Loader
export const loader = async ({ params }) => {
    const response = await instance.get(`/api/v1/home/board/as/${params.asId}`).catch(function (error) {
        if (error.response) {
            // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
            // console.log(error.response.data);
            // console.log(error.response.status);
            // console.log(error.response.headers);
            return error.response;
        } else if (error.request) {
            // 요청이 이루어 졌으나 응답을 받지 못했습니다.
            // console.log(error.request);
            return null;
        } else {
            // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
            // console.log("Error", error.message);
            return null;
        }
    });

    if (response == null || response.status !== 200) {
        return [];
    }
    if (response.data.status === 200) {
        return response.data.data;
    } else {
        return [];
    }
};
//<<< Loader
