/* eslint-disable react/jsx-key */
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import Button from "../../../components/UI/Button/Button";
import Input from "../../../components/UI/Input/Input";
import classes from "../MngCommon.module.css";
import Modal from "../../../components/UI/Modal/Modal";
import Radio from "../../../components/UI/Input/Radio";
import DatePickerBox from "../../../components/UI/DatePicker/DatePickerBox";

import _, { set } from "lodash";
import {
    ProductCategory,
    ProductSmartFilter,
} from "../../../api/product/CategoryApi";
import SelectBoxNew from "../../../components/UI/SelectBox/SelectBoxNew";
import {
    ProductSelectedCategoryDetail,
    ProductSelectedCategoryInsert,
    ProductSelectedCategoryList,
} from "../../../api/product/CategorySelectApi";
import PaginationNew from "../../../components/UI/Table/PaginationNew";
import QuillEditor from "../../../components/UI/Editer/QuillEditor";
import { JwtTokenContext } from "../../../store/JwtTokenProvider";
import MyDropzoneMj from "../../../components/UI/DropZone/DropZone copy";
import MngProductDetailPage from "./MngProductDetail";
import MngProductCRUDPage from "./MngProductCRUD";
import { useLocation, useNavigate, useNavigation, useParams } from "react-router-dom";

const PAGE_SIZE = 10;

const initProductSelectedListData = {
    content: [],
    pageable: {
        sort: {
            empty: true,
            unsorted: true,
            sorted: false,
        },
        pageNumber: 0,
        offset: 0,
        pageSize: 20,
        unpaged: false,
        paged: true,
    },
    last: true,
    totalElements: 1,
    totalPages: 1,
    size: 10,
    number: 0,
    sort: {
        empty: true,
        unsorted: true,
        sorted: false,
    },
    numberOfElements: 1,
    first: true,
    empty: false,
};

const initProductSelectedDetailData = {
    hierarchyCategoryStr: "",
    categoryId: 0,
    id: 0,
    name: "",
    subName: "",
    seq: 1,
    exposeYn: false,
    catalogue_ball: false,
    releaseDate: null,
    purchaseUrl: "",
    pageUri: "",
    description: "",
    readCnt: 0,
    regID: "admin",
    regDate: null,
    productDetailImagesSelecteds: [],
    smartFilterMenuSelecteds: [],
    extInformationMenuSelecteds: [],
};
const initInsertSelectedData = {
    name: "",
    subName: "",
    categoryId: -1,
    seq: 1,
    exposeYn: "Y",
    catalogue_ball: "N",
    releaseDate: new Date(Date.now()),
    purchaseUrl: "",
    pageUri: "",
    description: "",
    readCnt: 0,
    // "reqAddProductDetailSFilterList" : [],
    // "reqAddProductDetailExtInformationList" : []
};

function MngProductListPage({ nowMode = 0 }) {
    const location = useLocation();
    const navigate = useNavigate();
    const [viewMode, setViewMode] = useState(nowMode);
    const [nowCurrentPage, setNowCurrentPage] = useState(0);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [categoryMap, setCategoryMap] = useState({});
    const [loadCategory, setLoadCategory] = useState(true);
    const searchFormRef = useRef();
    const [searchKeyword, setSearchKeyword] = useState("");
    const [expose, setExpose] = useState(-1);

    const [selectedInfo, setSelectedInfo] = useState(
        initProductSelectedListData
    );
    const [detailId, setDetailId] = useState(0);
    const [detailData, setDetailData] = useState(initProductSelectedDetailData);
    const [insertData, setInsertData] = useState(initInsertSelectedData);
    const [isEdit, setIsEdit] = useState(false);
    const [adderCategoryPath, setAdderCategory] = useState([-1, -1, -1]);
    const [smartFilterMenus, setSmartFilterMenus] = useState([]);
    const [extInformationMenus, setExtInformationMenus] = useState([]);

    const adderCategoryPathHandler = (id, depth = 0) => {
        if (_.isNaN(Number(id))) return;
        const _id = Number(id);
        const pathTemp = [...adderCategoryPath];
        const othersTemp = Array(2 - depth).fill(-1);
        othersTemp.unshift(_id);
        pathTemp.splice(depth, 3 - depth, ...othersTemp);
        setAdderCategory(pathTemp);
    };

    const [images, setImages] = useState([]);
    const [thumbnailImage, setThumbnailImage] = useState(null);

    const getCategoryPath = (categoryId) => {
        let nowData = categoryMap[Number(categoryId)];
        const rs = [nowData];
        do {
            try {
                nowData = categoryMap[nowData["parent_id"]];
                rs.unshift(nowData);
            } catch (error) {
                break;
            }
        } while (
            nowData &&
            _.isNumber(nowData["parent_id"]) &&
            nowData["parent_id"] >= 0
        );

        return rs;
    };
    const insertDataHandler = (keyName = "name", value = null) => {
        setInsertData((prev) => {
            return { ...prev, [keyName]: value };
        });
    };
    const imageUpload = (addedImages = []) => {
        setImages([...addedImages]);
    };
    const toMap = (data = []) => {
        let rs = {};
        data.forEach((item) => {
            if (item?.id) {
                rs[item.id] = item;
                if (_.isArray(item?.children) && item.children.length > 0) {
                    rs = { ...rs, ...toMap(item.children) };
                }
            }
        });
        return rs;
    };
    const selectedValues = useMemo(() => {
        const MAX_DEPTH = 3;
        let rs = [];
        if (!_.isObject(categoryMap) || Object.keys(categoryMap).length === 0) {
            return [];
        }
        if (selectedCategory === null) {
            return [
                {
                    list: Object.values(categoryMap).filter(
                        ({ depth }) => depth === 1
                    ),
                    selected: null,
                },
            ];
        }

        let pId = selectedCategory["parent_id"];
        for (let i = 0; i < selectedCategory.depth; i++) {
            const list =
                pId === null || pId < 0
                    ? Object.values(categoryMap).filter(
                          ({ depth }) => depth === 1
                      )
                    : categoryMap[pId].children;
            //console.log({ pId });
            rs.unshift({
                list,
                selected: i === 0 ? selectedCategory.id : pId,
            });
            if (pId === null || pId < 0) break;
            pId = categoryMap[pId]["parent_id"];
        }
        if (
            selectedCategory.depth < MAX_DEPTH &&
            selectedCategory.children &&
            selectedCategory.children.length > 0
        ) {
            rs.push({ list: selectedCategory.children, selected: null });
        }
        return rs;
    }, [selectedCategory, categoryMap]);

    const selectedAdderCategoryHasChildren = useMemo(() => {
        const nowPath = adderCategoryPath.filter((path) => path >= 0);
        if (nowPath.length === 0 || Object.keys(categoryMap).length === 0)
            return false;
        const lastPath = nowPath.at(-1);
        return ((categoryMap[lastPath] ?? {}).children ?? []).length > 0;
    }, [adderCategoryPath, categoryMap]);

    const selectedIdxs = useMemo(() => {
        if (selectedCategory === null) return [null];
        let nowIdx = selectedCategory.id;
        const rs = [selectedCategory.id];
        while (
            !(
                !categoryMap[nowIdx] ||
                categoryMap[nowIdx]["parent_id"] === null ||
                categoryMap[nowIdx]["parent_id"] < 0
            )
        ) {
            nowIdx = categoryMap[nowIdx]["parent_id"];
            rs.unshift(nowIdx);
        }
        return rs;
    }, [selectedCategory]);


    useEffect(() => {
        if (!loadCategory) return;
        ProductCategory().then((res) => {
            if (_.isArray(res.data?.datas) && res.status === 200) {
                //console.log(res.data?.datas)
                const map = toMap(res.data.datas);
                setCategoryMap(map);
                setLoadCategory(false);
            }
        });
    }, [loadCategory]);
    useEffect(() => {
        try {
            switch (viewMode) {
                case 0:
                    ProductSelectedCategoryList({
                        page: nowCurrentPage,
                        size: PAGE_SIZE,
                    }).then((res) => {
                        if (res.status === 200 && _.isObject(res.data.datas)) {
                            //(res.data.datas)
                            setSelectedInfo(res.data.datas);
                            setDetailData(initProductSelectedDetailData);
                            setInsertData((prev) => initInsertSelectedData);
                            setAdderCategory([-1, -1, -1]);
                        }
                    });
                    break;

                case 1:
                    if (isEdit) {
                        //console.log("=====================", { detailData });
                        setImages(
                            detailData?.productDetailImagesSelecteds ?? []
                        );
                        const newInsertData = {
                            ...detailData,
                            exposeYn: detailData.exposeYn ? "Y" : "N",
                        };
                        if (
                            _.isString(detailData?.releaseDate) &&
                            detailData.releaseDate.length > 0
                        ) {
                            const releaseDate = new Date();
                            const times = detailData?.releaseDate
                                .split(".")
                                .map((val, idx) =>
                                    idx === 1 ? Number(val) - 1 : Number(val)
                                );
                            releaseDate.setUTCFullYear(...times);
                            newInsertData.releaseDate = releaseDate;
                        }
                        setInsertData((prev) => newInsertData);
                    } else {
                        const cId = adderCategoryPath
                            .filter((val) => !_.isNaN(Number(val)) && val > 0)
                            .at(-1);
                        ProductSmartFilter(cId).then((res) => {
                            if (
                                res.status === 200 &&
                                _.isObject(res.data?.data)
                            ) {
                                // console.log('smartFilterMenus:::', res.data?.data?.smartFilterMenus);
                                // console.log('extInformationMenus:::', res.data?.data?.extInformationMenus);
                                setSmartFilterMenus(
                                    res.data?.data?.smartFilterMenus ?? []
                                );
                                setExtInformationMenus(
                                    res.data?.data?.extInformationMenus ?? []
                                );
                            }
                            return res;
                        });
                    }

                    break;

                case 2:
                    if (detailId <= 0) break;
                    //console.log({ detailId });
                    ProductSelectedCategoryDetail(detailId).then((res) => {
                        //console.log("selected detail::", res.data);
                        if (res.status === 200) {
                            setDetailData(
                                res.data?.data ?? initProductSelectedDetailData
                            );
                            setInsertData((prev) => initInsertSelectedData);
                        }
                        ProductSmartFilter(res.data?.data?.categoryId ?? -1)
                            .then((res) => {
                                if (
                                    res.status === 200 &&
                                    _.isObject(res.data?.data)
                                ) {
                                    // console.log('smartFilterMenus:::', res.data?.data?.smartFilterMenus);
                                    // console.log('extInformationMenus:::', res.data?.data?.extInformationMenus);
                                    setSmartFilterMenus(
                                        res.data?.data?.smartFilterMenus ?? []
                                    );
                                    setExtInformationMenus(
                                        res.data?.data?.extInformationMenus ??
                                            []
                                    );
                                }
                                return res;
                            })
                            .catch((res) => {
                                //console.log(res);
                            });
                    });

                    break;

                default:
                    break;
            }
        } catch (error) {
            alert(error);
        }
    }, [viewMode, detailId, nowCurrentPage, isEdit]);
    useEffect(() => {
        if (isEdit) handleModeChange(1);
    }, [isEdit]);
    // viewMode : 0 - 리스트, 1- 작성, 2- 상세
    const handleModeChange = (mode) => {
        setViewMode(mode);
        window.scrollTo(0, 0);
        setIsModalOpen1(false);
        const _type = location.pathname.split('/').at(-1);
        switch (mode) {
            case 0:
                if(_type !== 'list') navigate('../list');
                break;
            case 1:
                /* if(_type === 'list') {
                  
                } */
            default:
                break;
        }
    };

    const [isModalOpen1, setIsModalOpen1] = useState(false);
    const openModalHandler1 = (e) => {
        e.stopPropagation();
        if (isEdit) setIsEdit(false);
        setIsModalOpen1(true);
    };
    const closeModalHandler1 = (e) => {
        e.stopPropagation();
        if(location.pathname.split('/').at(0) !== 'list' ){
            navigate('../list');
        }
        setIsModalOpen1(false);
        setAdderCategory([-1, -1, -1]);
    };
    useEffect(() => {
        if(location.pathname.split('/').at(-1) === 'list' && viewMode !== 0) {
            handleModeChange(0);
        }
    }, [location.pathname])
    useEffect(() => {
        //console.log("searchFormRef:::", searchFormRef);
    }, [searchFormRef]);
    const tokenContext = useContext(JwtTokenContext);
    useEffect(() => {
        //console.log(tokenContext);
    }, [tokenContext]);

    useEffect(() => {
        if (viewMode === 1 && !["Y", "N"].includes(detailData.catalogue_ball)) {
            insertDataHandler("catalogue_ball", "N");
        }
    }, [detailData.catalogue_ball]);

    return (
        <section>
            {viewMode === 0 && (
                <div className={classes.admin_contents}>
                    <h3>상품목록</h3>
                    <div
                        className={`${classes.contents_wrap} ${classes.search_wrap}`}
                    >
                        <div className={classes.admin_table_wrap}>
                            <table>
                                <colgroup>
                                    <col style={{ width: "11%" }} />
                                    <col style={{ width: "59%" }} />
                                    <col style={{ width: "11%" }} />
                                    <col style={{ width: "19%" }} />
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <th>카테고리</th>
                                        <td colSpan={3}>
                                            <div
                                                className={`${classes.flex_row} ${classes.multi}`}
                                            >
                                                {selectedValues.map(
                                                    ({ list }, idx) => {
                                                        return (
                                                            // eslint-disable-next-line react/jsx-key
                                                            <SelectBoxNew
                                                                className="admin_st"
                                                                optionDatas={[
                                                                    {
                                                                        id: -1,
                                                                        name: "전체보기",
                                                                        depth: 1,
                                                                    },
                                                                    ...(list ??
                                                                        []),
                                                                ].map(
                                                                    (item) => {
                                                                        return {
                                                                            ...item,
                                                                            key: item?.id,
                                                                            value: item?.name,
                                                                            disabled: false,
                                                                        };
                                                                    }
                                                                )}
                                                                setSelectItemCB={(
                                                                    key
                                                                ) => {
                                                                    if (
                                                                        Number(
                                                                            key
                                                                        ) === -1
                                                                    ) {
                                                                        if (
                                                                            idx ===
                                                                            0
                                                                        ) {
                                                                            setSelectedCategory(
                                                                                null
                                                                            );
                                                                        } else if (
                                                                            selectedCategory
                                                                        ) {
                                                                            let sId =
                                                                                selectedCategory.id;
                                                                            let cdepth = 3;
                                                                            while (
                                                                                idx !==
                                                                                cdepth
                                                                            ) {
                                                                                if (
                                                                                    _.isUndefined(
                                                                                        categoryMap[
                                                                                            sId
                                                                                        ]
                                                                                            .parent_id
                                                                                    ) ||
                                                                                    categoryMap[
                                                                                        sId
                                                                                    ]
                                                                                        ?.parent_id ===
                                                                                        -1
                                                                                ) {
                                                                                    break;
                                                                                }
                                                                                sId =
                                                                                    categoryMap[
                                                                                        sId
                                                                                    ]
                                                                                        ?.parent_id;
                                                                                cdepth--;
                                                                            }
                                                                            setSelectedCategory(
                                                                                categoryMap[
                                                                                    sId
                                                                                ]
                                                                            );
                                                                        }

                                                                        // setSelectedCategory(categoryMap[selectedCategory.id]);
                                                                        return;
                                                                    }
                                                                    setSelectedCategory(
                                                                        categoryMap[
                                                                            key
                                                                        ]
                                                                    );
                                                                }}
                                                                showDefaultKey={selectedIdxs.at(
                                                                    idx
                                                                )}
                                                            />
                                                        );
                                                    }
                                                )}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>검색어</th>
                                        <td>
                                            <Input
                                                className="admin_st"
                                                placeholder="검색어 입력"
                                                ref={(sref) => {
                                                    searchFormRef.current =
                                                        sref;
                                                }}
                                                value={searchKeyword}
                                                onChange={setSearchKeyword}
                                            />
                                        </td>
                                        <th>노출여부</th>
                                        <td>
                                            <SelectBoxNew
                                                className="admin_st"
                                                optionDatas={[
                                                    {
                                                        key: -1,
                                                        value: "전체보기",
                                                    },
                                                    { key: 0, value: "미노출" },
                                                    { key: 1, value: "노출" },
                                                ]}
                                                setSelectItemCB={(
                                                    key,
                                                    boxName
                                                ) => {
                                                    setExpose(Number(key));
                                                }}
                                                showDefaultKey={expose}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className={classes.bottom_btn_wrap}>
                                <Button
                                    className="border sm"
                                    onClick={() => {
                                        let url = "";
                                        url += `?searchKeyword=${searchKeyword}`;
                                        //console.log({ selectedValues });
                                        const selectedIds = selectedValues
                                            .map(({ selected }) => selected)
                                            .filter(
                                                (s) => s !== null && s > -1
                                            );
                                        if (selectedIds.length > 0) {
                                            url += `&selectedId=${selectedIds.at(
                                                -1
                                            )}&depth=${
                                                categoryMap[selectedIds.at(-1)]
                                                    .depth
                                            }`;
                                        }
                                        if (expose !== -1)
                                            url +=
                                                expose === 1
                                                    ? "&exposeYn=Y"
                                                    : "&exposeYn=N";
                                        const urlSearchParams =
                                            new URLSearchParams(url);
                                        const searchParams = {};
                                        [
                                            "searchKeyword",
                                            "selectedId",
                                            "depth",
                                            "exposeYn",
                                        ].forEach((key) => {
                                            if (urlSearchParams.get(key)) {
                                                searchParams[key] =
                                                    urlSearchParams.get(key);
                                            }
                                        });
                                        navigate(url);
                                        //console.log({ searchParams });
                                        ProductSelectedCategoryList(
                                            {
                                                page: nowCurrentPage,
                                                size: PAGE_SIZE,
                                            },
                                            searchParams
                                        ).then((res) => {
                                            if (
                                                res.status === 200 &&
                                                _.isObject(res.data.datas)
                                            ) {
                                                setSelectedInfo(res.data.datas);
                                                setDetailData(
                                                    initProductSelectedDetailData
                                                );
                                                setInsertData(
                                                    (prev) =>
                                                        initInsertSelectedData
                                                );
                                                setAdderCategory([-1, -1, -1]);
                                                if (viewMode !== 0)
                                                    setViewMode(0);
                                            }
                                        });
                                    }}
                                >
                                    검색
                                </Button>
                                <Button
                                    className="secondary sm"
                                    onClick={() => {
                                        ProductSelectedCategoryList({
                                            page: 0,
                                            size: PAGE_SIZE,
                                        }).then((res) => {
                                            if (
                                                res.status === 200 &&
                                                _.isObject(res.data.datas)
                                            ) {
                                                setSelectedInfo(res.data.datas);
                                                setDetailData(
                                                    initProductSelectedDetailData
                                                );
                                                setInsertData(
                                                    (prev) =>
                                                        initInsertSelectedData
                                                );
                                                setAdderCategory([-1, -1, -1]);
                                                if (viewMode !== 0)
                                                    setViewMode(0);
                                            }
                                        });
                                    }}
                                >
                                    전체보기
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className={classes.contents_wrap}>
                        <div
                            className={`${classes.admin_table_wrap} ${classes.align_center}  ${classes.row_select}`}
                        >
                            <p className={classes.counter}>
                                총 <b> {selectedInfo.totalElements} </b>건
                            </p>
                            <table>
                                <colgroup>
                                    <col style={{ width: "5%" }} />
                                    <col style={{ width: "25%" }} />
                                    <col style={{ width: "25%" }} />
                                    <col style={{ width: "15%" }} />
                                    <col style={{ width: "15%" }} />
                                    <col style={{ width: "15%" }} />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th>No</th>
                                        <th>카테고리</th>
                                        <th>상품명</th>
                                        <th>등록일</th>
                                        <th>등록자</th>
                                        <th>노출여부</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(selectedInfo.content ?? []).map(
                                        (item) => (
                                            // eslint-disable-next-line react/jsx-key
                                            <tr
                                                onClick={() => {
                                                    setDetailId(item?.id ?? 0);
                                                    navigate(`../${item?.id}`);
                                                    handleModeChange(2);
                                                }}
                                            >
                                                <td>{item?.pageSeq}</td>
                                                <td>
                                                    {item?.hierarchyCategoryStr}
                                                </td>
                                                <td>
                                                    {item?.name} &nbsp;{" "}
                                                    {item?.subName}
                                                </td>
                                                <td>{item?.regDate}</td>
                                                <td>{item?.regID}</td>
                                                <td>
                                                    {!item?.exposeYn && "미"}
                                                    노출
                                                </td>
                                            </tr>
                                        )
                                    )}
                                </tbody>
                            </table>
                            {selectedInfo.pageable.paged && (
                                <PaginationNew
                                    className="admin_st"
                                    totalPage={selectedInfo.totalPages}
                                    currentPage={nowCurrentPage}
                                    changePageCB={setNowCurrentPage}
                                />
                            )}
                            <div
                                className={`${classes.bottom_btn_wrap} ${classes.position_st}`}
                            >
                                <Button
                                    className="secondary sm"
                                    onClick={(e) => {
                                        navigate('../add');
                                        openModalHandler1(e);
                                    }}
                                >
                                    상품등록
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {viewMode === 1 && (
                <MngProductCRUDPage
                    detailData={detailData}
                    getCategoryPath={getCategoryPath}
                    handleModeChange={handleModeChange}
                    imageUpload={imageUpload}
                    images={images}
                    insertData={insertData}
                    insertDataHandler={insertDataHandler}
                    isEdit={isEdit}
                    thumbnailImage={thumbnailImage}
                    setThumbnailImage={setThumbnailImage}
                    smartFilterMenus={smartFilterMenus}
                    extInformationMenus={extInformationMenus}
                />
            )}
            {viewMode === 2 && (
                <MngProductDetailPage
                    tokenContext={tokenContext}
                    detailData={detailData}
                    setIsEdit={setIsEdit}
                    setViewMode={setViewMode}
                    handleModeChange={handleModeChange}
                    smartFilterMenus={smartFilterMenus}
                    extInformationMenus={extInformationMenus}
                />
            )}
            {isModalOpen1 && (
                <Modal
                    className="admin_st"
                    close={closeModalHandler1}
                    header="상품등록"
                >
                    <main>
                        {/* 화면설계서 상 기능 삭제
            <h5>등록 대상 상품 (2건)</h5>
            <div className={classes.product_scroll_box}>
              <div>
                <ul>
                  <li>REAL ABSOLUTE</li>
                  <li>DARK PHYSIX</li>
                </ul>
              </div>
            </div> */}
                        <div className={classes.category_select_wrap}>
                            <h5>상품등록 카테고리를 선택해 주세요.</h5>
                            <div className={classes.category_wrap}>
                                <div className={classes.category_box}>
                                    <h6>대 카테고리</h6>
                                    <div className={classes.scroll_box}>
                                        <ul>
                                            {Object.values(categoryMap)
                                                .filter(
                                                    ({ depth }) => depth === 1
                                                )
                                                .map((item) => (
                                                    <li
                                                        id={item.id}
                                                        className={
                                                            adderCategoryPath[0] ===
                                                            item.id
                                                                ? classes.on
                                                                : undefined
                                                        }
                                                        onClick={({
                                                            target: { id },
                                                        }) => {
                                                            adderCategoryPathHandler(
                                                                id,
                                                                0
                                                            );
                                                        }}
                                                    >
                                                        {" "}
                                                        {item.name}{" "}
                                                    </li>
                                                ))}
                                        </ul>
                                    </div>
                                </div>
                                <div className={classes.category_box}>
                                    <h6>중 카테고리</h6>
                                    <div className={classes.scroll_box}>
                                        <ul>
                                            <ul>
                                                {Object.values(categoryMap)
                                                    .filter(
                                                        ({ parent_id }) =>
                                                            parent_id >= 0 &&
                                                            parent_id ===
                                                                adderCategoryPath[0]
                                                    )
                                                    .map((item) => (
                                                        <li
                                                            id={item.id}
                                                            className={
                                                                adderCategoryPath[1] ===
                                                                item.id
                                                                    ? classes.on
                                                                    : undefined
                                                            }
                                                            onClick={({
                                                                target: { id },
                                                            }) => {
                                                                adderCategoryPathHandler(
                                                                    id,
                                                                    1
                                                                );
                                                            }}
                                                        >
                                                            {" "}
                                                            {item.name}{" "}
                                                        </li>
                                                    ))}
                                            </ul>
                                        </ul>
                                    </div>
                                </div>
                                <div
                                    className={`${classes.category_box} ${classes.dep3}`}
                                >
                                    <h6>소 카테고리</h6>
                                    <div className={classes.scroll_box}>
                                        <ul>
                                            {Object.values(categoryMap)
                                                .filter(
                                                    ({ parent_id }) =>
                                                        parent_id >= 0 &&
                                                        parent_id ===
                                                            adderCategoryPath[1]
                                                )
                                                .map((item) => (
                                                    <li
                                                        id={item.id}
                                                        className={
                                                            adderCategoryPath[2] ===
                                                            item.id
                                                                ? classes.on
                                                                : undefined
                                                        }
                                                        onClick={({
                                                            target: { id },
                                                        }) => {
                                                            adderCategoryPathHandler(
                                                                id,
                                                                2
                                                            );
                                                        }}
                                                    >
                                                        {" "}
                                                        {item.name}{" "}
                                                    </li>
                                                ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                    <footer>
                        <Button className=" sm" onClick={closeModalHandler1}>
                            취소
                        </Button>
                        <Button
                            className={`secondary sm`}
                            onClick={() => {
                                setInsertData((prev) => {
                                    return {
                                        ...prev,
                                        categoryId: adderCategoryPath
                                            .filter((val) => val >= 0)
                                            .at(-1),
                                    };
                                });
                                setIsModalOpen1(false);
                                handleModeChange(1);                                
                            }}
                            disabled={
                                adderCategoryPath.filter((item) => item >= 0)
                                    .length === 0 ||
                                selectedAdderCategoryHasChildren
                            }
                        >
                            등록
                        </Button>
                    </footer>
                </Modal>
            )}
        </section>
    );
}

export default MngProductListPage;
