import { useState } from "react";
import classes from "../MngCommon.module.css";
import Button from "../../../components/UI/Button/Button";

import { useLoaderData, useLocation, useNavigate } from "react-router-dom";
import instance from "../../../store/AxiosInterceptor";
import { ReqDeleteKegel } from "../../../api/kegel/KegelAPi";

function MngKegelDetail(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const kegelData = useLoaderData();

    const toList = () => {
        navigate("/admin/kegel", {state: location.state});
    };

    const updateHandler = () => {
        navigate(`/admin/kegel/update/${kegelData.id}`, {state: location.state});
    };

    const deleteKegel = async () => {
        const response = await ReqDeleteKegel(kegelData.id);
        if (response.status === 200) {
            navigate("/admin/kegel", {state: location.state});
        } else {
            alert(
                `[${response.status}] ${
                    response.data ? response.data.error : "삭제 실패"
                } `
            );
        }
    };

    // 매핑시켜주기
    const kegelTypeMapping = {
        MACHINE: "레인정비기계",
        CONSUMABLE: "레인정비소모품",
        TOOL: "훈련 도구",
    };

    const urlMapping = {
        MACHINE: "machine",
        CONSUMABLE: "consumable",
        TOOL: "training_materials",
    };

    const bodyData = (
        <>
            <tr>
                <th>등록일</th>
                <td>{kegelData.regDate}</td>
                <th>등록자</th>
                <td>{kegelData.regID}</td>
            </tr>
            <tr>
                <th>노출여부</th>
                <td colSpan={3}>
                    {kegelData.exposeYn === "Y" ? "노출" : "미노출"}
                </td>
            </tr>
            <tr>
                <th>카테고리</th>
                {/* <td colSpan={3}>{kegelData.kegelType}</td> */}
                <td colSpan={3}>{kegelTypeMapping[kegelData.kegelType]}</td>
            </tr>
            <tr>
                <th>상품명</th>
                <td colSpan={3}>{kegelData.title}</td>
            </tr>
            <tr>
                <th>설명</th>
                <td colSpan={3}>{kegelData.subDescription}</td>
            </tr>

            <tr>
                <th>대표이미지</th>
                <td colSpan={3}>
                    {kegelData.attach_uri && (
                        <div className={classes.thumbnail_box}>
                            <img src={kegelData.attach_uri} alt="상품이미지" />
                        </div>
                    )}
                </td>
            </tr>
            <tr>
                <th rowSpan={3}>특징</th>
                <td colSpan={3}>{kegelData.firstSignature}</td>
            </tr>
            <tr>
                <td colSpan={3}>{kegelData.secondSignature}</td>
            </tr>
            <tr>
                <td colSpan={3}>{kegelData.thirdSignature}</td>
            </tr>
            <tr>
                <th>사용자페이지 URL</th>
                <td colSpan={3}>
                    {window.location.protocol +
                        "//" +
                        window.location.host +
                        "/kegel/" +
                        urlMapping[kegelData.kegelType] +
                        "/" +
                        kegelData.id}
                </td>
            </tr>
            <tr>
                <th>상세정보</th>
                <td colSpan={3}>
                    <div>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: kegelData.content,
                            }}
                        />
                    </div>
                </td>
            </tr>
        </>
    );

    return (
        <section>
            <div className={classes.admin_contents}>
                <h3>상품상세</h3>
                <div
                    className={`${classes.contents_wrap} ${classes.search_wrap} `}
                >
                    <div className={classes.admin_table_wrap}>
                        <table>
                            <colgroup>
                                <col style={{ width: "159px" }} />
                                <col style={{ width: "620px" }} />
                                <col style={{ width: "159px" }} />
                                <col style={{ width: "620px" }} />
                            </colgroup>
                            <tbody>{bodyData}</tbody>
                        </table>

                        <div className={classes.bottom_btn_wrap}>
                            <Button
                                className="border sm"
                                onClick={updateHandler}
                            >
                                수정
                            </Button>
                            <Button className="sm" onClick={deleteKegel}>
                                삭제
                            </Button>
                            <Button className="secondary sm" onClick={toList}>
                                목록
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default MngKegelDetail;

export const loader = async ({ params }) => {
    try {
        const response = await instance.get(
            `/api/v1/admin/kegel/${params.kegelId}`,
            {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            }
        );

        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error("Failed to fetch data");
        }
    } catch (error) {
        console.error("Error fetching data:", error);
        return null;
    }
};
