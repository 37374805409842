/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import BreadCrumb from "../../../components/UI/BreadCrumb/BreadCrumb";
import Button from "../../../components/UI/Button/Button";
/* import Checkbox from "../../../components/UI/Input/Checkbox";
import Input from "../../../components/UI/Input/Input";
import SearchBox from "../../../components/UI/Input/SearchBox"; */
import _classes from "./../Products.module.css";

import React, { useEffect, useMemo, useState } from "react";
import {
    getHomeProduct,
    getHomeProductDetail,
    getHomeProductDetailInfo,
    getHomeSmartFilter,
} from "../../../api/home/ProductCategoryApi";
import _ from "lodash";
import { ProductNavigation } from "./ProductNavigation";
import { ProductItemList } from "./ProductItemList";
import { ProductItemDetail } from "./ProductItemDetail";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ProductAppPage from "../moblie/ProductApp";

const productPageMap = {
    balls: 100,
    bags: 200,
    clothes: 300,
    shoes: 400,
    products: 500,
    guard: 600,
};
const productNameMap = {
    balls: "볼링볼",
    bags: "볼링가방",
    clothes: "볼링의류",
    shoes: "볼링화",
    products: "볼링용품",
    guard: "볼링아대",
};

function ProductPage({ isDetail = false, isMobile = false }) {
    const resetPage = () => {
        // 페이지 초기화 로직을 구현
        setShowPage(0);
    };

    if (isMobile) return <ProductAppPage isDetail={isDetail} />;
    //  breadCrumb sample data
    const [selectedItem, setSelectedItem] = useState(-1);
    const [subSelectedItem, setSubSelectedItem] = useState(-1);
    const [searchKeyword, setSearchKeyword] = useState("");
    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    // 스마트필터
    const [smartFilterData, setSmartFilterData] = useState([]);

    const [datas, setDatas] = useState([]);
    const [productDatas, setProductDatas] = useState({
        content: [],
        pagable: {},
    });
    const [detailData, setDetailData] = useState({});

    const [showPage, setShowPage] = useState(0);
    const [detailTitle, setDetailTitle] = useState("");

    const [categoryMap, setCategoryMap] = useState({});

    // 스마트필터에 넘기는 searchParams 값 새로 정의
    const [searchParams, setSearchParams] = useState({
        page: 0,
        category: 100,
        depth: 1,
    });

    // 공유 팝업 여닫기
    const [sharePopToggle, setSharePopToggle] = useState(false);
    // lnb filter toggle
    const [filterToggle, setFilterToggle] = useState(""); // Default active item ID is 'filter_1'

    // 스마트필터 검색 값
    const [searchResults, setSearchResults] = useState([]);

    const [selectedValues, setSelectedValues] = useState({});

    const urlSearchParams = useMemo(
        () => new URLSearchParams(location.search),
        [location]
    );
    const searchParamsMap = useMemo(() => {
        const rs = {};
        urlSearchParams.forEach((v, key) => {
            rs[key] = v;
            if (key === "searchKeyword") setSearchKeyword(v);
        });
        return rs;
    }, [urlSearchParams]);
    const categoryId = useMemo(() => {
        //console.log(productPageMap[location.pathname.split("/").at(-1)]);
        if (isDetail) return 0;
        return productPageMap[location.pathname.split("/").at(-1)];
    }, [location?.pathname]);

    const toMap = (_data = {}, map = {}) => {
        map[_data.id] = _data;
        if (_.isArray(_data?.children) && _data.children.length > 0) {
            _data.children.forEach((item) => {
                toMap(item, map);
            });
        }
    };

    const getProductDetailList = () => {
        getHomeProductDetail(
            categoryId === 0 ? productPageMap[params.category] : categoryId
        ).then(({ data, status }) => {
            if (
                status === 200 &&
                _.isArray(data?.datas) &&
                data?.datas?.length > 0
            ) {
                setDatas(data.datas[0]);
                setSmartFilterData([]);
                const map = {};
                toMap(data.datas[0], map);
                setCategoryMap(map);
            }
        });
    };
    const getHomeSmartFilterList = () => {
        getHomeSmartFilter(
            categoryId === 0 ? productPageMap[params.category] : categoryId,
            searchParams.category,
            searchParams.depth
        ).then(({ data, status }) => {
            if (
                status === 200 &&
                _.isArray(data?.data.smartFilterMenus) &&
                data?.data.smartFilterMenus?.length > 0
            ) {
                setSmartFilterData(data.data.smartFilterMenus);
            }
        });
    };

    useEffect(() => {
        // searchParams.category가 변경될 때마다 getHomeSmartFilterList 호출
        getHomeSmartFilterList();
    }, [searchParams.category, searchParams.depth]);

    useEffect(() => {
        if (isDetail) {
            getHomeProductDetailInfo(Number(params.id)).then((res) => {
                if (res.status === 200 && _.isObject(res.data?.data)) {
                    setDetailData(res.data.data);
                    // [A] 20230825 직접 접근시 카테고리 정보 없을 경우 한번 더 호출
                    if (datas.length === 0) {
                        getProductDetailList();
                        getHomeSmartFilterList();
                    }
                }
                return res;
            });
        } else {
            // [A] 20230826 YiSuHwan 카테고리 이동시 검색 결과 초기화
            setSearchKeyword("");
            getProductDetailList();
            getHomeSmartFilterList();
        }
    }, [categoryId, params?.id]);

    useEffect(() => {
        if (Object.keys(categoryMap).length === 0) return;
        if (isDetail && Object.keys(detailData).length !== 0) {
            //detail data 설정
            const { secondCategory } = categoryMap[detailData.categoryId] ?? {};
            setSelectedItem(secondCategory ?? -1);
            setSubSelectedItem(detailData?.categoryId ?? -1);
        } else if (
            !isDetail &&
            location.state !== null &&
            _.isNumber(Number(location.state?.item))
        ) {
            // nav 설정
            const selectMenu = categoryMap[Number(location.state?.item)];
            if (!selectMenu) return;
            if (selectMenu.depth <= 2) {
                setSelectedItem(selectMenu.id);
                setSubSelectedItem(-1);
            } else {
                setSelectedItem(selectMenu["parent_id"]);
                setSubSelectedItem(selectMenu.id);
            }
        }
    }, [location.state, categoryMap, detailData, isDetail]);

    const handleItemClick = (item) => {
        if (isDetail) {
            const pathArr = location.pathname.split("/");
            pathArr.pop();
            navigate(pathArr.join("/"), { state: { item, toList: false } });
        }
        if (item === selectedItem) {
            setSelectedItem(-1);
        } else {
            setSelectedItem(item);
        }
        setSubSelectedItem(-1);
    };
    const subHandleItemClick = (item) => {
        if (isDetail) {
            const pathArr = location.pathname.split("/");
            pathArr.pop();
            navigate(pathArr.join("/"), { state: { item, toList: false } });
        }
        if (item === subSelectedItem) {
            setSubSelectedItem(-1);
        } else {
            setSubSelectedItem(item);
        }
    };

    useEffect(() => {
        setSharePopToggle(false);
    }, [isDetail]);
    const onSelectHandler = (isNextPage = false) => {
        const productParams = {
            // page: isNextPage ? showPage + 1 : 0,
            // page: selectedValues.length > 0 ? 0 : (isNextPage ? showPage + 1 : 0),
            page:
                selectedValues.length > 0 && !isNextPage
                    ? 0
                    : isNextPage
                    ? showPage + 1
                    : 0,
            // page: isNextPage ? 1:0,
            category: 0,
            depth: 1,
        };

        let temp = {};
        if (categoryMap[subSelectedItem] && categoryMap[selectedItem]) {
            productParams.category = subSelectedItem;
            temp = categoryMap[subSelectedItem];
        } else if (!categoryMap[subSelectedItem] && categoryMap[selectedItem]) {
            productParams.category = selectedItem;
            temp = categoryMap[selectedItem];
        } else if ([100, 200, 300, 400, 500, 600].includes(categoryId)) {
            productParams.category = categoryId;
            temp = categoryMap[categoryId];
            if (!temp) {
                setProductDatas({ content: [], pagable: {} });
                setDatas({ name: productNameMap[params.category] });
                setDetailTitle(productNameMap[params.category]);
                return;
            }
        } else {
            return;
        }
        productParams.depth = temp.depth;
        setDetailTitle(temp?.name ?? "");

        setSearchParams(() => ({
            page: productParams.page,
            category: productParams.category,
            depth: productParams.depth,
        }));

        if (location.state?.toList && !isNextPage) {
            setProductDatas(location.state?.productDatas ?? {});
            return;
        }
        const queryParams = { ...searchParamsMap };
        if (searchKeyword !== "") {
            queryParams.searchKeyword = searchKeyword;
        } else {
            delete queryParams.searchKeyword;
        }

        // selectedValues가 객체인 경우(O), 객체를 배열로 변환
        const selectedValuesArray = Object.values(selectedValues);

        // selectedValuesArray에서 객체를 필터링
        const selectedValuesWithoutObjects = selectedValuesArray.filter(
            (value) => typeof value !== "object"
        );

        getHomeProduct(
            {
                page: productParams.page,
                category: productParams.category,
                depth: productParams.depth,
            },
            queryParams,
            selectedValuesWithoutObjects
        ).then(({ data, status }) => {
            if (status === 200 && _.isObject(data.data)) {
                const pData = isNextPage
                    ? {
                          ...data.data,
                          content: [
                              ...(productDatas.content ?? []),
                              ...(data.data?.content ?? []),
                          ],
                      }
                    : data.data;

                setProductDatas(pData);

                if (isNextPage) setShowPage(productParams.page);
                // if (isNextPage) setShowPage((prevPage) => prevPage + 1); // 페이징 증가
            }
        });
    };

    useEffect(() => {
        if (showPage > 0) setShowPage(0);
        onSelectHandler();
    }, [selectedItem, subSelectedItem, categoryId, categoryMap]);

    useEffect(() => {
        if (searchResults) {
            // 검색 결과 데이터를 이용하여 화면에 표시
            // console.log("검색 결과 데이터: ", searchResults);
            setProductDatas(searchResults);
        }
    }, [searchResults]);

    const filterToggleHandler = (id) => {
        // setFilterToggle(id);
        if (id === filterToggle) {
            setFilterToggle("");
        } else {
            setFilterToggle(id);
        }
    };

    const sharePopHandler = () => {
        setSharePopToggle((prev) => !prev);
    };
    const breadCrumbList = useMemo(() => {
        try {
            const keys = Object.keys(categoryMap ?? {});
            if (keys.length === 0 || categoryId === -1) return [];
            const rs = [
                isDetail
                    ? productNameMap[location.pathname.split("/").at(-2)]
                    : categoryMap[categoryId]?.name,
            ];
            if (keys.includes(selectedItem.toString())) {
                rs.push(categoryMap[selectedItem]?.name);
            }
            if (keys.includes(subSelectedItem.toString())) {
                rs.push(categoryMap[subSelectedItem]?.name);
            }
            return rs;
        } catch (error) {
            console.log({ error });
            return [];
        }
    }, [categoryId, categoryMap, selectedItem, subSelectedItem]);

    return (
        <div className={`container ${_classes.products_container}`}>
            <div className={_classes.products_sub_nav}>
                <h3
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                        setSelectedItem(-1);
                        setSubSelectedItem(-1);
                    }}
                >
                    {datas.name}
                </h3>
                <ProductNavigation
                    selectedItem={selectedItem}
                    datas={datas}
                    subSelectedItem={subSelectedItem}
                    handleItemClick={handleItemClick}
                    subHandleItemClick={subHandleItemClick}
                    isDetail={isDetail}
                    smartFilterDatas={smartFilterData} // 스마트필터데이터 추가
                    setSearchResults={setSearchResults}
                    searchParams={searchParams}
                    searchKeyword={searchKeyword}
                    setSearchKeyword={setSearchKeyword}
                    setSelectedValues={setSelectedValues} // 검색된 스마트필터 선택값
                    resetPage={resetPage} // resetPage 함수를 ProductNavigation으로 전달
                />
            </div>
            <section>
                <div className={_classes.products_contents}>
                    <BreadCrumb breadCrumbList={breadCrumbList} />
                    <div className={_classes.contents_wrap}>
                        {/* <div className={_classes.tag_wrap}>
                            <ul>
                                <li>
                                    Centripetal™ Core
                                    <Button className="btn_x" />
                                </li>
                                <li>
                                    Inverted Fe² Technology™
                                    <Button className="btn_x" />
                                </li>
                            </ul>
                        </div> */}
                        {!isDetail ? (
                            <ProductItemList
                                {...productDatas}
                                nextPageHandler={() => {
                                    if (productDatas?.last) return;
                                    onSelectHandler(true);
                                }}
                                categoryIdISValid={Object.values(
                                    productPageMap
                                ).includes(categoryId)}
                                selectedInfo={[selectedItem, subSelectedItem]}
                                onItemClick={(key) => {
                                    navigate(key.toString(), {
                                        state: {
                                            productDatas,
                                            search: location.search,
                                        },
                                    });
                                }}
                                title={detailTitle}
                                searchKeyword={searchKeyword}
                                setSearchKeyword={setSearchKeyword}
                                searchHandler={() => onSelectHandler()}
                            />
                        ) : (
                            <>
                                <ProductItemDetail
                                    {...detailData}
                                    sharePopHandler={sharePopHandler}
                                    sharePopToggle={sharePopToggle}
                                />
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                    }}
                                    className={_classes.right}
                                >
                                    <Button
                                        style={{ margin: "5px" }}
                                        className={_classes.button}
                                        onClick={() => {
                                            const pathArr =
                                                location.pathname.split("/");
                                            pathArr.pop();
                                            //    navigate(`${pathArr.join('/')}${location.state?.search ?? ''}`, { state: { item: detailData?.categoryId, toList: true, ...(location.state ?? {}) } })

                                            navigate(
                                                `${pathArr.join("/")}${
                                                    location.state?.search ?? ""
                                                }`,
                                                {
                                                    state: {
                                                        item: detailData?.categoryId,
                                                        toList:
                                                            location.state
                                                                ?.productData
                                                                ?.content
                                                                .length > 0,
                                                        ...(location.state ??
                                                            {}),
                                                    },
                                                }
                                            );
                                        }}
                                    >
                                        목록으로
                                    </Button>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </section>
        </div>
    );
}

export default ProductPage;
