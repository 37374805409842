import instance from "../../store/AxiosInterceptor";

// [START] 상품
export async function ReqKegelList() {
    const response = await instance
        .get("/api/v1/admin/kegel?page=0&size=10", {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            if (error.response) {
                return {
                    status: error.response.status,
                    error: "",
                };
            } else if (error.request) {
                return {
                    status: 600,
                    error: "요청에 대한 응답 없음",
                };
            } else {
                return {
                    status: 600,
                    error: "요청설정 에러",
                };
            }
        });
    return response;
}

export async function ReqInsertKegel(requestData) {
    // console.log("requestData : ", requestData);

    const response = await instance
        .post("/api/v1/admin/kegel", requestData)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            if (error.response) {
                return {
                    status: error.response.status,
                    error: "",
                };
            } else if (error.request) {
                return {
                    status: 600,
                    error: "요청에 대한 응답 없음",
                };
            } else {
                return {
                    status: 600,
                    error: "요청설정 에러",
                };
            }
        });
    return response;
}
export async function ReqDeleteKegel(kegelID) {
    const response = await instance
        .delete(`/api/v1/admin/kegel/${kegelID}`)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            if (error.response) {
                return {
                    status: error.response.status,
                    error: "",
                };
            } else if (error.request) {
                return {
                    status: 600,
                    error: "요청에 대한 응답 없음",
                };
            } else {
                return {
                    status: 600,
                    error: "요청설정 에러",
                };
            }
        });
    return response;
}
export async function ReqUpdateKegel(kegelId, formData) {
    // console.log("kegelId: " + kegelId, "formData: " + formData);

    const response = await instance
        .put(`/api/v1/admin/kegel/${kegelId}`, formData)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            if (error.response) {
                return {
                    status: error.response.status,
                    error: "",
                };
            } else if (error.request) {
                return {
                    status: 600,
                    error: "요청에 대한 응답 없음",
                };
            } else {
                return {
                    status: 600,
                    error: "요청설정 에러",
                };
            }
        });
    return response;
}

// [END] 상품

// [START] 유용한 정보 / 문제해결 도우미
export async function ReqKegelInfoList() {
    const response = await instance
        .get("/api/v1/admin/kegel/guide?page=0&size=10", {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            if (error.response) {
                return {
                    status: error.response.status,
                    error: "",
                };
            } else if (error.request) {
                return {
                    status: 600,
                    error: "요청에 대한 응답 없음",
                };
            } else {
                return {
                    status: 600,
                    error: "요청설정 에러",
                };
            }
        });
    return response;
}

export async function ReqInsertKegelInfo(requestData) {
    const response = await instance
        .post("/api/v1/admin/kegel/guide", requestData)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            if (error.response) {
                return {
                    status: error.response.status,
                    error: "",
                };
            } else if (error.request) {
                return {
                    status: 600,
                    error: "요청에 대한 응답 없음",
                };
            } else {
                return {
                    status: 600,
                    error: "요청설정 에러",
                };
            }
        });
    return response;
}
export async function ReqDeleteKegelInfo(kegelID) {
    const response = await instance
        .delete(`/api/v1/admin/kegel/guide/${kegelID}`)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            if (error.response) {
                return {
                    status: error.response.status,
                    error: "",
                };
            } else if (error.request) {
                return {
                    status: 600,
                    error: "요청에 대한 응답 없음",
                };
            } else {
                return {
                    status: 600,
                    error: "요청설정 에러",
                };
            }
        });
    return response;
}
export async function ReqUpdateKegelInfo(kegelId, formData) {
    // console.log("kegelId: " + kegelId, "formData: " + formData);

    const response = await instance
        .put(`/api/v1/admin/kegel/guide/${kegelId}`, formData)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            if (error.response) {
                return {
                    status: error.response.status,
                    error: "",
                };
            } else if (error.request) {
                return {
                    status: 600,
                    error: "요청에 대한 응답 없음",
                };
            } else {
                return {
                    status: 600,
                    error: "요청설정 에러",
                };
            }
        });
    return response;
}
// [END] 유용한 정보 / 문제해결 도우미

// [START] 유용한 동영상
export async function ReqKegelVideoList(page, size) {
    const response = await instance
        .get("/api/v1/admin/kegel/videoGuide?page=0&size=10", {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            if (error.response) {
                return {
                    status: error.response.status,
                    error: "",
                };
            } else if (error.request) {
                return {
                    status: 600,
                    error: "요청에 대한 응답 없음",
                };
            } else {
                return {
                    status: 600,
                    error: "요청설정 에러",
                };
            }
        });
    return response;
}

export async function ReqInsertKegelVideo(requestData) {
    // console.log("requestData : ", requestData);

    const response = await instance
        .post("/api/v1/admin/kegel/videoGuide", requestData)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            if (error.response) {
                return {
                    status: error.response.status,
                    error: "",
                };
            } else if (error.request) {
                return {
                    status: 600,
                    error: "요청에 대한 응답 없음",
                };
            } else {
                return {
                    status: 600,
                    error: "요청설정 에러",
                };
            }
        });
    return response;
}
export async function ReqDeleteKegelVideo(kegelID) {
    const response = await instance
        .delete(`/api/v1/admin/kegel/videoGuide/${kegelID}`)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            if (error.response) {
                return {
                    status: error.response.status,
                    error: "",
                };
            } else if (error.request) {
                return {
                    status: 600,
                    error: "요청에 대한 응답 없음",
                };
            } else {
                return {
                    status: 600,
                    error: "요청설정 에러",
                };
            }
        });
    return response;
}
export async function ReqUpdateKegelVideo(kegelId, formData) {
    // console.log("kegelId: " + kegelId, "formData: " + formData);

    const response = await instance
        .put(`/api/v1/admin/kegel/videoGuide/${kegelId}`, formData)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            if (error.response) {
                return {
                    status: error.response.status,
                    error: "",
                };
            } else if (error.request) {
                return {
                    status: 600,
                    error: "요청에 대한 응답 없음",
                };
            } else {
                return {
                    status: 600,
                    error: "요청설정 에러",
                };
            }
        });
    return response;
}

export async function ReqContestMainInfoSearchList(searchParam) {
    const response = await instance
        .get(`/api/v1/admin/kegel/videoGuide`, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
            params: searchParam,
        })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            if (error.response) {
                // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
                return {
                    status: error.response.status,
                    error: "",
                };
            } else if (error.request) {
                // 요청이 이루어 졌으나 응답을 받지 못했습니다.
                return {
                    status: 600,
                    error: "요청에 대한 응답 없음",
                };
            } else {
                // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
                return {
                    status: 600,
                    error: "요청설정 에러",
                };
            }
        });
    return response;
}
// [END] 유용한 동영상

export async function ReqKegelServiceList(type) {
    const response = await instance
        .get(
            `/api/v1/admin/kegel/guide?category=${type}&exposeYn=Y&page=0&size=5`,
            {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            }
        )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            if (error.response) {
                return {
                    status: error.response.status,
                    error: "",
                };
            } else if (error.request) {
                return {
                    status: 600,
                    error: "요청에 대한 응답 없음",
                };
            } else {
                return {
                    status: 600,
                    error: "요청설정 에러",
                };
            }
        });
    return response;
}

export async function ReqKegelHomeServiceList(type) {
    const response = await instance
        .get(
            `/api/v1/home/kegel/guide?category=${type}&exposeYn=Y&page=0&size=10`,
            {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            }
        )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            if (error.response) {
                return {
                    status: error.response.status,
                    error: "",
                };
            } else if (error.request) {
                return {
                    status: 600,
                    error: "요청에 대한 응답 없음",
                };
            } else {
                return {
                    status: 600,
                    error: "요청설정 에러",
                };
            }
        });
    return response;
}
export async function ReqKegelHomeServiceListApp(
    type,
    page,
    resolveCB,
    rejectCB
) {
    instance(
        {
            url: `/api/v1/home/kegel/guide?category=${type}&exposeYn=Y&page=${page}&size=10`,
            method: "GET",
        },
        {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        }
    )
        .then((response) => {
            // console.log(response);
            if (resolveCB) {
                resolveCB(response.data.datas.content);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}
export function ReqHomeKegel(menuName, page, keyword, resolveCB, rejectCB) {
    instance(
        {
            url: `/api/v1/home/kegel/${menuName}?page=${page}&size=5&keyword=${keyword}`,
            method: "GET",
        },
        {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        }
    )
        .then((response) => {
            // console.log(response);
            if (resolveCB) {
                resolveCB(response.data.datas.content);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}
export function ReqHomeKegelVideo(page, resolveCB, rejectCB) {
    instance(
        {
            url: `/api/v1/home/kegel/video?page=${page}&size=6`,
            method: "GET",
        },
        {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        }
    )
        .then((response) => {
            // console.log(response);
            if (resolveCB) {
                resolveCB(response.data.datas.content);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}
