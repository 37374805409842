import { useLoaderData, useNavigate } from "react-router-dom";
import {
    ReqSystemPopupDelete,
    ReqSystemPopupDetail,
} from "../../../../api/site/ApiForSystemPopup";
import Button from "../../../../components/UI/Button/Button";
import classes from "../../MngCommon.module.css";
import Moment from "moment";

function MngSystemDetail(props) {
    const systemPopup = useLoaderData();

    const navigate = useNavigate();

    const backHandler = () => {
        navigate("/admin/site/system");
    };

    const deleteHandler = async (e) => {
        e.preventDefault();

        const isTrue = window.confirm("내용을 삭제하시겠습니까?");
        if (isTrue) {
            try {
                const response = await ReqSystemPopupDelete(systemPopup.id);
                if (response.status === 200) {
                    alert("내용이 삭제되었습니다.");
                    backHandler();
                } else {
                    alert("내용 삭제 실패");
                }
            } catch (error) {
                console.error("Error:", error);
            }
        } else {
            return;
        }
    };

    const updateHandler = () => {
        navigate(`../update/${systemPopup.id}`);
    };

    return (
        <section>
            <div className={classes.admin_contents}>
                <h3>시스템 점검 안내 - 상세</h3>
                <div
                    className={`${classes.contents_wrap} ${classes.search_wrap} `}
                >
                    <div className={classes.admin_table_wrap}>
                        <table>
                            <colgroup>
                                <col style={{ width: "10%" }} />
                                <col style={{ width: "10%" }} />
                                <col style={{ width: "30%" }} />
                                <col style={{ width: "10%" }} />
                                <col style={{ width: "40%" }} />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>등록일</th>
                                    <td colSpan={2}>
                                        {Moment(systemPopup.regDate).format(
                                            "YYYY-MM-DD"
                                        )}
                                    </td>
                                    <th>등록자</th>
                                    <td>{systemPopup.regID}</td>
                                </tr>
                                <tr>
                                    <th>사용여부</th>
                                    <td colSpan={4}>
                                        {systemPopup.expose_yn
                                            ? "사용"
                                            : "미사용"}
                                    </td>
                                </tr>
                                <tr>
                                    <th>시스템 점검 일시</th>
                                    <td colSpan={4}>
                                        {Moment(
                                            systemPopup.startDate,
                                            "YYYYMMDDHHmm"
                                        ).format("YYYY-MM-DD HH:mm")}{" "}
                                        ~{" "}
                                        {Moment(
                                            systemPopup.endDate,
                                            "YYYYMMDDHHmm"
                                        ).format("YYYY-MM-DD HH:mm")}
                                    </td>
                                </tr>
                                <tr>
                                    <th rowSpan={2}>
                                        시스템 점검
                                        <br />
                                        사전 안내 팝업
                                    </th>
                                    <th>노출여부</th>
                                    <td colSpan={3}>
                                        {systemPopup.notiExposeYn
                                            ? "노출"
                                            : "미노출"}
                                    </td>
                                </tr>
                                <tr>
                                    <th>노출기간</th>
                                    <td colSpan={3}>
                                        {Moment(
                                            systemPopup.notiStartDate,
                                            "YYYYMMDDHHmm"
                                        ).format("YYYY-MM-DD HH:mm")}{" "}
                                        ~{" "}
                                        {Moment(
                                            systemPopup.notiEndDate,
                                            "YYYYMMDDHHmm"
                                        ).format("YYYY-MM-DD HH:mm")}
                                    </td>
                                </tr>
                                <tr>
                                    <th>점검내용</th>
                                    <td colSpan={4}>{systemPopup.title}</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className={classes.bottom_btn_wrap}>
                            <Button
                                className="secondary sm"
                                onClick={updateHandler}
                            >
                                수정
                            </Button>
                            <Button className="sm" onClick={deleteHandler}>
                                삭제
                            </Button>
                            <Button className="border sm" onClick={backHandler}>
                                목록
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default MngSystemDetail;

export const loader = async ({ params }) => {
    const response = await ReqSystemPopupDetail(params.systemId);

    if (response.status === 200) {
        const resData = await response.data.data;
        // console.log("", resData);
        return resData;
    } else {
        return [];
    }
};
