import { useEffect, useState } from "react";
import Button from "../../../../components/UI/Button/Button";
import DateRangePicker from "../../../../components/UI/DatePicker/DateRangePicker";
import Input from "../../../../components/UI/Input/Input";
import classes from "../../MngCommon.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import PaginationNew from "../../../../components/UI/Table/PaginationNew";
import SelectBoxNew from "../../../../components/UI/SelectBox/SelectBoxNew";
import { adminAccountSearchTypes, adminStatusTypes } from "../../../../components/definedefault/DefSelectBoxValues";
import moment from "moment";
import instance from "../../../../store/AxiosInterceptor";
import {
    ReqAdminMemberList,
    ReqAdminMemberListPage,
    ReqAdminMemberListSearch,
} from "../../../../api/member/AdminMemberApi";

function MngAdminAccountView() {
    
    const location = useLocation();
    const navigate = useNavigate();
    
    const [adminList, setAdminList] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const searchStartDate = startDate ? moment(startDate).format("YYYYMMDD") : "";
    const searchEndDate = endDate ? moment(endDate).format("YYYYMMDD") : "";

    const startDateHandler = (value) => {
        setStartDate(value);
    };

    const endDateHandler = (value) => {
        setEndDate(value);
    };

    // 검색조건[START]
    // N : 사용, Y : 사용중지 >> expired랑 통일 >> expired 가  Y이면 사용중지 N이면 사용
    const adminStatusType = adminStatusTypes;
    const adminAccountSearchType = adminAccountSearchTypes;

    const [adminStatus, setAdminStatus] = useState("");
    const adminStatusHandler = (value) => {
        setAdminStatus(value);
    };

    const [adminAccountSearch, setAdminAccountSearch] = useState("");
    const adminAccountSearchHandler = (value) => {
        setAdminAccountSearch(value);
    };

    const [searchText, setSearchText] = useState("");
    const searchTextChangeHandler = (value) => {
        setSearchText(value);
    };

    // 검색조건[END]

    // >>> 페이징을 위한 값 세팅
    const [totalPage, setTotalPage] = useState(0); //총 페이지 갯수
    const [pageNumber, setPageNumber] = useState(location.state?.pageNumber ?? 0); //글목록 No. 값을세팅하기 위한 서버에서 들어오는값
    const [totalCount, setTotalCount] = useState(0);
    // <<< 페이징을 위한 값 세팅

    const uploadHandler = () => {
        navigate("/admin/users/adminAccount/upload");
    };

    const detailHandler = (adminId) => {
        navigate(adminId, { state: { pageNumber } });
    };

    const searchObject = {
        start_reg_date: searchStartDate,
        end_reg_date: searchEndDate,
        process: adminStatus,
        queryType: adminAccountSearch,
        queryText: searchText,
    };

    const searchHandler = () => {
        setPageNumber(0);
        ReqAdminMemberListSearch(
            searchObject,
            (result) => {
                if (result.content) {
                    const curData = result.content.map((data) => {
                        return {
                            seq: data.seq,
                            adminId: data.admin_id, //고유값 (PK)
                            adminMemberId: data.admin_member_id,
                            adminName: data.admin_name,
                            siteRole: data.site_role === "A" ? "관리자" : "볼링장",
                            adminEmail: data.admin_email,
                            regDate: data.reg_date,
                            lastLogin: data.last_login,
                            expired: data.expired,
                            adminPhone: data.admin_phone,
                            assignedTask: data.assigned_task,
                        };
                    });

                    setAdminList(curData);
                    setTotalPage(result.totalPages);
                    setPageNumber(result.pageable.pageNumber);
                    setTotalCount(result.totalElements);
                }
            },
            (error_result) => {
                setAdminList((prev) => prev);
                setTotalPage((prev) => prev);
                setPageNumber((prev) => prev);
                setTotalCount((prev) => prev);
            }
        );
    };

    const pageHandler = (value) => {
        setPageNumber(value);
        ReqAdminMemberListPage(
            searchObject,
            value,
            (result) => {
                if (result.content) {
                    const curData = result.content.map((data) => {
                        return {
                            seq: data.seq,
                            adminId: data.admin_id, //고유값 (PK)
                            adminMemberId: data.admin_member_id,
                            adminName: data.admin_name,
                            siteRole: data.site_role === "A" ? "관리자" : "볼링장",
                            adminEmail: data.admin_email,
                            regDate: data.reg_date,
                            lastLogin: data.last_login,
                            expired: data.expired,
                            adminPhone: data.admin_phone,
                            assignedTask: data.assigned_task,
                        };
                    });

                    setAdminList(curData);
                    setTotalPage(result.totalPages);
                    setPageNumber(result.pageable.pageNumber);
                    setTotalCount(result.totalElements);
                }
            },
            (error_result) => {
                setAdminList((prev) => prev);
                setTotalPage((prev) => prev);
                setPageNumber((prev) => prev);
                setTotalCount((prev) => prev);
            }
        );
    };
    useEffect(() => {
        pageHandler(pageNumber);
    }, [])
    const findAllHandler = () => {
        setSearchText("");
        setAdminAccountSearch("");
        setAdminStatus("");
        setStartDate(null);
        setEndDate(null);
        ReqAdminMemberList(
            (result) => {
                if (result.content) {
                    const curData = result.content.map((data) => {
                        return {
                            seq: data.seq,
                            adminId: data.admin_id, //고유값 (PK)
                            adminMemberId: data.admin_member_id,
                            adminName: data.admin_name,
                            siteRole: data.site_role === "A" ? "관리자" : "볼링장",
                            adminEmail: data.admin_email,
                            regDate: data.reg_date,
                            lastLogin: data.last_login,
                            expired: data.expired,
                            adminPhone: data.admin_phone,
                            assignedTask: data.assigned_task,
                        };
                    });

                    setAdminList(curData);
                    setTotalPage(result.totalPages);
                    setPageNumber(result.pageable.pageNumber);
                    setTotalCount(result.totalElements);
                }
            },
            (error_result) => {
                setAdminList((prev) => prev);
                setTotalPage((prev) => prev);
                setPageNumber((prev) => prev);
                setTotalCount((prev) => prev);
            }
        );
    };

    const downLoadheaders = [
        { label: "No", key: "index" },
        { label: "아이디", key: "memberID" },
        { label: "관리자명", key: "memberName" },
        { label: "담당업무", key: "assignedTask" },
        { label: "연락처", key: "memberPhone" },
        { label: "이메일", key: "memberEmail" },
        { label: "등록일", key: "regDate" },
        { label: "최근접속일", key: "lastLoginDate" },
        { label: "상태", key: "status" },
    ];

    // 엑셀 다운로드
    const dataHandler = async (e, object) => {
        try {
            const response = await instance({
                url: `/api/v1/admin/member/admin/excel?start_reg_date=${object.start_reg_date}&end_reg_date=${object.end_reg_date}&process=${object.process}&${object.queryType}=${object.queryText}`,
                method: "GET",
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            });

            if (response.status === 200) {
                const resData = await response.data.datas;
                const curData = resData.map((data, index) => {
                    return {
                        index: index + 1,
                        adminMemberId: data.admin_member_id,
                        adminName: data.admin_name,
                        adminEmail: data.admin_email,
                        regDate: data.reg_date,
                        lastLogin: data.last_login,
                        expired: data.expired === "Y" ? "사용중지" : "사용",
                        adminPhone: data.admin_phone?.replace(/(\d{2,3})(\d{3,4})(\d{4})/, "$1-$2-$3"),
                        assignedTask: data.assigned_task,
                    };
                });

                if (curData.length > 0) {
                    // CSV 데이터 생성
                    let csvContent = "\uFEFF"; // BOM 문자로 UTF-8 인코딩 설정 (한글 깨짐 방지)

                    // 그룹 헤더 추가
                    curData.forEach((data, index) => {
                        if (index === 0) {
                            // 헤더 추가
                            csvContent += downLoadheaders.map((header) => header.label).join(",") + "\n";
                        }

                        if (index !== 0) {
                            csvContent += `\n`;
                        }

                        // 데이터 추가
                        csvContent += `"${data.index}","${data.adminMemberId}","${data.adminName}","${
                            data.assignedTask
                        }","${String(data.adminPhone)}","${data.adminEmail}","${data.regDate}","${data.lastLogin}","${data.expired}"`;
                    });

                    // Blob 생성
                    const blob = new Blob([csvContent], {
                        type: "text/csv;charset=utf-8;",
                    });

                    // Blob을 URL로 변환하여 다운로드 링크 생성
                    const url = URL.createObjectURL(blob);
                    const a = document.createElement("a");
                    a.style.display = "none";
                    a.href = url;
                    a.download = `관리자 계정 관리.csv`;
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                }
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };



    return (
        <section>
            <div className={classes.admin_contents}>
                <h3>관리자계정관리</h3>
                <div className={`${classes.contents_wrap} ${classes.search_wrap}`}>
                    <div className={classes.admin_table_wrap}>
                        <table>
                            <colgroup>
                                <col style={{ width: "11%" }} />
                                <col style={{ width: "39%" }} />
                                <col style={{ width: "11%" }} />
                                <col style={{ width: "39%" }} />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>가입일자</th>
                                    <td>
                                        <DateRangePicker
                                            startDate={startDate}
                                            endDate={endDate}
                                            onStartDateChange={startDateHandler}
                                            onEndDateChange={endDateHandler}
                                        />
                                    </td>
                                    <th>상태</th>
                                    <td>
                                        <SelectBoxNew
                                            className="admin_st"
                                            optionDatas={adminStatusType}
                                            setSelectItemCB={adminStatusHandler}
                                            showDefaultKey={adminStatus}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>검색구분</th>
                                    <td colSpan={3}>
                                        <div className={classes.flex_row}>
                                            <SelectBoxNew
                                                className="admin_st"
                                                optionDatas={adminAccountSearchType}
                                                setSelectItemCB={adminAccountSearchHandler}
                                                showDefaultKey={adminAccountSearch}
                                            />
                                            <Input
                                                className="admin_st"
                                                placeholder="검색어 입력"
                                                onChange={searchTextChangeHandler}
                                                value={searchText}
                                            />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className={classes.bottom_btn_wrap}>
                            <Button className="border sm" onClick={searchHandler}>
                                검색
                            </Button>
                            <Button className="secondary sm" onClick={findAllHandler}>
                                전체보기
                            </Button>
                        </div>
                    </div>
                </div>
                <div className={classes.contents_wrap}>
                    <div className={`${classes.admin_table_wrap} ${classes.align_center}  ${classes.row_select}`}>
                        <p className={classes.counter}>
                            총 <b>{totalCount}</b>건
                        </p>
                        <table>
                            <colgroup>
                                <col style={{ width: "5%" }} />
                                <col style={{ width: "11%" }} />
                                <col style={{ width: "11%" }} />
                                <col style={{ width: "11%" }} />
                                <col style={{ width: "11%" }} />
                                <col style={{ width: "11%" }} />
                                <col style={{ width: "11%" }} />
                                <col style={{ width: "18%" }} />
                                <col style={{ width: "11%" }} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th>아이디</th>
                                    <th>관리자명</th>
                                    <th>담당업무</th>
                                    <th>연락처</th>
                                    <th>이메일</th>
                                    <th>등록일</th>
                                    <th>최근접속일</th>
                                    <th>상태</th>
                                </tr>
                            </thead>
                            <tbody>
                                {adminList.length > 0 &&
                                    adminList.map((data, index) => (
                                        <tr key={index} onClick={() => detailHandler(`${data.adminId}`)}>
                                            <td>{data.seq}</td>
                                            <td>{data.adminMemberId}</td>
                                            <td>{data.adminName}</td>
                                            <td>{data.assignedTask}</td>
                                            <td>{data.adminPhone}</td>
                                            <td>{data.adminEmail}</td>
                                            <td>{data.regDate}</td>
                                            <td>{data.lastLogin}</td>
                                            {data.expired === "N" && <td className="primary_c">사용</td>}
                                            {data.expired === "Y" && <td>사용 중지</td>}
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                        <PaginationNew
                            className="admin_st"
                            totalPage={totalPage}
                            currentPage={pageNumber}
                            changePageCB={pageHandler}
                            defaultShowPage={5}
                        />
                        <div className={`${classes.bottom_btn_wrap} ${classes.position_st}`}>
                            <Button className="primary download sm" onClick={(e) => dataHandler(e, searchObject)}>
                                엑셀 다운로드
                            </Button>
                            <Button className="secondary sm" onClick={uploadHandler}>
                                등록
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default MngAdminAccountView;

//관리자 계정 목록 리스트 가져오기
export const loader = async () => {
    const response = await instance({
        method: "GET",
        url: `/api/v1/admin/member/admin?page=0&size=10`,
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    }).catch(function (error) {
        if (error.response) {
            // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            return error.response;
        } else if (error.request) {
            // 요청이 이루어 졌으나 응답을 받지 못했습니다.
            console.log(error.request);
            return null;
        } else {
            // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
            console.log("Error", error.message);
            return null;
        }
    });
    if (response == null || response.status !== 200) {
        return [];
    }
    if (response.data.status === 200) {
        return response.data.datas;
    } else {
        return [];
    }
};
//관리자 계정 목록 리스트 가져오기
