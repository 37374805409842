import { useState } from "react";
import classes from "./SelectBox.module.css";

/**
 * select box 가이드 코드
 *
 * 부모에서 선택된 데이터를 관리하고 다시 넣어주어 호출하도록 함 (부모 컴포넌트에 useState 가 존재)
 * 2023-07-14 handleOnSelectItem bind부분을 제거 변경
 *
 * @param props.optionDatas {key, value, disable} ex) { key: 'id_0', value: "전체", disabled: false}
 * @param props.className (admin_st or admin_st.w_50 or admin_st.w_sm or w_50 or right ... 1)
 * @param props.setSelectItemCB (selectbox 에서 선택된 데이터에 대한 callback 함수)
 * @param props.showDefaultKey (optionDatas 중 default 로 선택되어야 하는 값의 key)
 * @param props.mouseOverCB (mouseOver Event에 대한 처리)
 * @param props.boxName Callback함수를 한개 사용시 어떠한 SelectBox에서 호출했는지 확인하기 위함
 *
 */
function SelectBoxTicket(props) {
    const {
        optionDatas,
        className = "",
        setSelectItemCB,
        showDefaultKey,
        mouseOverCB,
        boxName,
    } = props;
    const [showOptions, setShowOptions] = useState(false);

    if (!optionDatas) {
        return <></>;
    }

    let currentOption = optionDatas.find((v) => v.key === showDefaultKey);

    if (!currentOption) currentOption = optionDatas[0];

    const handleSelectBoxHideAndShowToggle = () => {
        setShowOptions((current) => !current);
    };

    const onMouseOutHandler = () => {
        setShowOptions((current) => false);
    };

    const handleOnSelectItem = (key) => (e) => {
        const key = e.target.getAttribute("keyValue");
        const selectedOptionData = optionDatas.find((v) => v.key === key);

        if (!selectedOptionData.disabled) {
            setSelectItemCB(key, boxName);
        } else {
            e.stopPropagation();
        }
    };

    //클래스명 조합
    const classNames = Array.isArray(className)
        ? className
        : className.split(" ");
    const combinedClasses = classNames
        .map((cn) => classes[cn])
        .filter((cn) => cn)
        .join(" ");

    return (
        <div
            className={`${classes.selectBox} ${combinedClasses} ${
                showOptions ? classes.on : ""
            }`}
            onClick={handleSelectBoxHideAndShowToggle}
            onMouseOver={mouseOverCB}
        >
            <p>{currentOption.value}</p>
            <ul>
                {optionDatas.map((data, index) => {
                    // const textColor =
                    //     data.key === ""
                    //         ? ""
                    //         : data.count.count_tickets > 0
                    //         ? "green"
                    //         : data.count.count_w_tickets > 0
                    //         ? "yellow"
                    //         : "red";

                    let textColor = "";
                    if (data.key === "") {
                        textColor = "";
                    } else if (
                        data.count.count_tickets > 0 ||
                        data.type === "DRAW"
                    ) {
                        textColor = "rgb(69, 183, 8)"; // Green
                    } else if (data.count.count_w_tickets > 0) {
                        textColor = "rgb(203, 179, 0)"; // Yellow
                    } else {
                        textColor = "rgb(211, 122, 57)"; // Red
                    }

                    return (
                        <li
                            key={data.key}
                            value={data.value}
                            keyValue={data.key}
                            onClick={handleOnSelectItem(data.key)}
                            className={
                                data.disabled
                                    ? `${classes.readonly} ${classes.disabled}`
                                    : ""
                            }
                            style={{ fontWeight: "bold", color: textColor }}
                        >
                            {data.key === "" && `${data.value}`}
                            {data.key !== "" &&
                                data.count.count_tickets > 0 &&
                                `${data.value} : 접수가능 (${data.count.group_capacity - data.count.count_tickets}/${data.count.group_capacity})`}
                            {data.key !== "" &&
                                data.count.count_tickets === 0 &&
                                data.count.count_w_tickets > 0 &&
                                `${data.value} : 대기접수 (${data.count.w_group_capacity - data.count.count_w_tickets}/${data.count.w_group_capacity})`}
                            {data.key !== "" &&
                                data.count.count_tickets === 0 &&
                                data.count.count_w_tickets === 0 &&
                                data.type === "OofA" &&
                                `${data.value} : 접수마감 (${data.count.group_capacity}/${data.count.group_capacity})`}
                            {data.type === "DRAW" && `${data.value} : 접수가능`}
                            {/* {data.key === "" && `${data.value}`}
                            {data.key !== "" && data.count > 0 && `${data.value}:접수가능(대기조포함${data.count}명미달)`}
                            {data.key !== "" && data.count === "0" && data.type === "DRAW" && `${data.value}:접수가능`}
                            {data.key !== "" && data.count === "0" && data.type === "OofA" && `${data.value}:접수마감`} */}
                            {data.subValue && (
                                <span
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        e.currentTarget.parentNode.click();
                                    }}
                                >
                                    - {data.subValue}
                                </span>
                            )}
                        </li>
                    );
                    // }
                })}
            </ul>
        </div>
    );
}
export default SelectBoxTicket;
