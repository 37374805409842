import instance from "../../store/AxiosInterceptor";

export async function ReqContestMainInfo(mainId) {
    const response = await instance({
        method: "GET",
        url: `/api/v1/admin/contest/main/${mainId}`,
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    })
        //const response = await instance.get(`/api/v1/admin/contest/main/${mainId}`)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            if (error.response) {
                // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
                return {
                    status: error.response.status,
                    error: "",
                };
            } else if (error.request) {
                // 요청이 이루어 졌으나 응답을 받지 못했습니다.
                return {
                    status: 600,
                    error: "요청에 대한 응답 없음",
                };
            } else {
                // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
                return {
                    status: 600,
                    error: "요청설정 에러",
                };
            }
        });
    return response;
}

export async function ReqContestMainInfoList(page) {
    const response = await instance({
        method: "GET",
        url: `/api/v1/admin/contest/main?page=${page}`,
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    })
        //const response = await instance.get(`/api/v1/admin/contest/main?page=${page}`)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            if (error.response) {
                // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
                return {
                    status: error.response.status,
                    error: "",
                };
            } else if (error.request) {
                // 요청이 이루어 졌으나 응답을 받지 못했습니다.
                return {
                    status: 600,
                    error: "요청에 대한 응답 없음",
                };
            } else {
                // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
                return {
                    status: 600,
                    error: "요청설정 에러",
                };
            }
        });
    return response;
}

export async function ReqContestMainInfoSearchList(searchParam) {
    if (searchParam.type === null) {
        searchParam.type = null;
    }

    if (searchParam.sDate === null) {
        searchParam.sDate = null;
    }

    if (searchParam.eDate === null) {
        searchParam.eDate = null;
    }

    const response = await instance
        .get(`/api/v1/admin/contest/main`, {
            params: searchParam,
        })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            if (error.response) {
                // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
                return {
                    status: error.response.status,
                    error: "",
                };
            } else if (error.request) {
                // 요청이 이루어 졌으나 응답을 받지 못했습니다.
                return {
                    status: 600,
                    error: "요청에 대한 응답 없음",
                };
            } else {
                // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
                return {
                    status: 600,
                    error: "요청설정 에러",
                };
            }
        });
    return response;
}

export async function ReqContestMainCreate(formData) {
    const response = await instance
        .post(`/api/v1/admin/contest/main`, formData)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            if (error.response) {
                // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
                return {
                    status: error.response.status,
                    error: "",
                };
            } else if (error.request) {
                // 요청이 이루어 졌으나 응답을 받지 못했습니다.
                return {
                    status: 600,
                    error: "요청에 대한 응답 없음",
                };
            } else {
                // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
                return {
                    status: 600,
                    error: "요청설정 에러",
                };
            }
        });
    return response;
}

export async function ReqContestMainDelete(mainId) {
    const response = await instance
        .delete(`/api/v1/admin/contest/main/${mainId}`)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            if (error.response) {
                // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
                return {
                    status: error.response.status,
                    error: "",
                };
            } else if (error.request) {
                // 요청이 이루어 졌으나 응답을 받지 못했습니다.
                return {
                    status: 600,
                    error: "요청에 대한 응답 없음",
                };
            } else {
                // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
                return {
                    status: 600,
                    error: "요청설정 에러",
                };
            }
        });
    return response;
}

export async function ReqContestMainModify(mainId, formData) {
    for (let key of formData.keys()) {
        console.log(key, ":", formData.get(key));
    }

    const response = await instance
        .put(`/api/v1/admin/contest/main/${mainId}`, formData)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            if (error.response) {
                // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
                return {
                    status: error.response.status,
                    error: "",
                };
            } else if (error.request) {
                // 요청이 이루어 졌으나 응답을 받지 못했습니다.
                return {
                    status: 600,
                    error: "요청에 대한 응답 없음",
                };
            } else {
                // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
                return {
                    status: 600,
                    error: "요청설정 에러",
                };
            }
        });
    return response;
}

export async function ReqContestMainPlacement(formData) {
    const response = await instance
        .post(`/api/v1/admin/contest/main/placement`, formData, { timeout : 10000})
        .then((response) => {
            return response;
        })
        .catch((error) => {
            if (error.response) {
                // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
                return {
                    status: error.response.status,
                    error: "",
                };
            } else if (error.request) {
                // 요청이 이루어 졌으나 응답을 받지 못했습니다.
                return {
                    status: 600,
                    error: "요청에 대한 응답 없음",
                };
            } else {
                // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
                return {
                    status: 600,
                    error: "요청설정 에러",
                };
            }
        });
    return response;
}

export async function ReqRegionInfoList() {
    const response = await instance
        .get(`/api/v1/admin/contest/region`)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            if (error.response) {
                // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
                return {
                    status: error.response.status,
                    error: "",
                };
            } else if (error.request) {
                // 요청이 이루어 졌으나 응답을 받지 못했습니다.
                return {
                    status: 600,
                    error: "요청에 대한 응답 없음",
                };
            } else {
                // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
                return {
                    status: 600,
                    error: "요청설정 에러",
                };
            }
        });
    return response;
}

export async function ReqCenterNameList(region) {
    const response = await instance
        .get(`/api/v1/admin/contest/center?region=${region}`)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            if (error.response) {
                // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
                return {
                    status: error.response.status,
                    error: "",
                };
            } else if (error.request) {
                // 요청이 이루어 졌으나 응답을 받지 못했습니다.
                return {
                    status: 600,
                    error: "요청에 대한 응답 없음",
                };
            } else {
                // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
                return {
                    status: 600,
                    error: "요청설정 에러",
                };
            }
        });
    return response;
}

/**
 * 존재하는 대회장소 호출
 * @param {*} contest_id
 * @returns
 */
export async function ReqExistedContestPlace(contest_id) {
    const response = await instance
        .get(`/api/v1/admin/contest/center/contest/${contest_id}`)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            if (error.response) {
                // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
                return {
                    status: error.response.status,
                    error: "",
                };
            } else if (error.request) {
                // 요청이 이루어 졌으나 응답을 받지 못했습니다.
                return {
                    status: 600,
                    error: "요청에 대한 응답 없음",
                };
            } else {
                // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
                return {
                    status: 600,
                    error: "요청설정 에러",
                };
            }
        });
    return response;
}

/**
 * 대회장소 생성
 * @param {*} contest_id
 * @returns
 */
export async function ReqCreateContestPlace(jsonBody) {
    const response = await instance
        .post(`/api/v1/admin/contest/place`, jsonBody)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            if (error.response) {
                // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
                return {
                    status: error.response.status,
                    error: "",
                };
            } else if (error.request) {
                // 요청이 이루어 졌으나 응답을 받지 못했습니다.
                return {
                    status: 600,
                    error: "요청에 대한 응답 없음",
                };
            } else {
                // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
                return {
                    status: 600,
                    error: "요청설정 에러",
                };
            }
        });
    return response;
}
