import classes from "../../MngCommon.module.css";
import Button from "../../../../components/UI/Button/Button";
import { Link, useLoaderData, useLocation, useNavigate } from "react-router-dom";
import { ReqCTNoticeForAdmin, ReqDeleteForAdmin } from "../../../../api/board/BoardApi";
import { useEffect } from "react";
import axios from "axios";

function MngTournamentNoticeDetail() {
    const resData = useLoaderData();
    const location = useLocation();
    const navigate = useNavigate();

    const combinedList =
        resData.attach_uri_list?.length > 0
            ? resData.attach_uri_list.map((attach_uri, index) => ({
                  attach_uri,
                  originalFileName: resData.attach_file_name_list.at(index),
              }))
            : [];

    const deleteCtNotice = async () => {
        // 임시 confirm
        if (window.confirm("삭제 하시겠습니까?")) {
            const response = await ReqDeleteForAdmin("contestNotice", resData.id);
            if (response.status === 200) {
                navigate("/admin/tournament/notice", { state: location.state });
            } else {
                alert(
                    `[${response.status}] ${
                        response.data ? response.data.error : "삭제 실패"
                    } `
                );
            }
        }
    };

    const downloadFile = async (attachUri, originalFileName) => {
        try {
            const response = await axios.get(`${attachUri}`, {
                responseType: "blob", // 파일을 Blob 형식으로 받기 위해 설정
            });

            const blob = new Blob([response.data], {
                type: response.headers["content-type"],
            });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.download = originalFileName;
            link.click();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error("파일 다운로드 에러:", error);
        }
    };

    useEffect(() => {
        const backHandler = () => {
            navigate("/admin/tournament/notice", { state: location.state });
        };
        window.addEventListener("popstate", backHandler);
        return () => {
            window.removeEventListener("popstate", backHandler);
        };
    }, []);

    function resCtType() {
        switch (resData.ct_type) {
            case "K":
                return "왕중왕전";
            case "L":
                return "이벤트";
            case "A":
                return "고객감사 페스티벌";
            case "B":
                return "챔피언십";
            case "G":
                return "공통";
            default:
                return "";
        }
    }

    return (
        <section>
            <div className={classes.admin_contents}>
                <h3>대회공지 - 상세</h3>
                <div className={`${classes.contents_wrap} ${classes.search_wrap}`}>
                    <div className={classes.admin_table_wrap}>
                        <table>
                            <colgroup>
                                <col style={{ width: "11%" }} />
                                <col style={{ width: "39%" }} />
                                <col style={{ width: "10%" }} />
                                <col style={{ width: "40%" }} />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>등록일</th>
                                    <td>{resData.regDate}</td>
                                    <th>등록자</th>
                                    <td>{resData.regID}</td>
                                </tr>
                                <tr>
                                    <th>상단공지여부</th>
                                    <td>
                                        {resData.noticeYn === "Y" ? "사용" : "미사용"}
                                    </td>
                                    <th>사용여부</th>
                                    <td>
                                        {resData.exposeYn === "Y" ? "사용" : "미사용"}
                                    </td>
                                </tr>
                                <tr>
                                    <th>유형</th>
                                    <td colSpan={3}>{resCtType()}</td>
                                </tr>
                                <tr>
                                    <th>제목</th>
                                    <td colSpan={3}>{resData.title}</td>
                                </tr>
                                <tr>
                                    <th>사용자페이지 URL</th>
                                    <td colSpan={3}>
                                        {window.location.protocol +
                                            "//" +
                                            window.location.host +
                                            "/tournament/notice/" +
                                            resData.id}
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={4}>
                                        <div>
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: resData.content,
                                                }}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>첨부파일</th>
                                    <td colSpan={3}>
                                        {combinedList.length === 0 && (
                                            <div>첨부파일이 없습니다.</div>
                                        )}
                                        {combinedList.length !== 0 &&
                                            combinedList.map((data, index) => (
                                                <div key={index}>
                                                    <p
                                                        onClick={() =>
                                                            downloadFile(
                                                                data.attach_uri,
                                                                data.originalFileName
                                                            )
                                                        }
                                                        style={{color : 'blue', cursor : 'pointer'}}
                                                    >
                                                        {data.originalFileName}
                                                    </p>
                                                </div>
                                            ))}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className={classes.bottom_btn_wrap}>
                            <Link to={`/admin/tournament/notice/update/${resData.id}`}>
                                <Button className="border sm">수정</Button>
                            </Link>
                            <Button className="border sm" onClick={deleteCtNotice}>
                                삭제
                            </Button>
                            <Link to="/admin/tournament/notice" state={location.state}>
                                <Button className="secondary sm">목록</Button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default MngTournamentNoticeDetail;

export const loader = async ({ params }) => {
    const response = await ReqCTNoticeForAdmin(params.ctNoticeId);
    if (response.status === 200) {
        return response.data;
    }
    return null;
};
