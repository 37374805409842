import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import "react-datepicker/dist/react-datepicker.css";
import classes from "./DatePicker.module.css";

// 사용 한 라이브러리
// https://reactdatepicker.com/#example-year-picker
// https://www.npmjs.com/package/react-datepicker

function DateRangePicker({
    startDate,
    endDate,
    onStartDateChange,
    onEndDateChange,
    className,
    showMonthDropdown,
    showYearDropdown
}) {
    return (
        <div className={`${classes.date_picker_box} ${classes[className]}`}>
            <DatePicker
                selected={startDate}
                onChange={onStartDateChange}
                locale={ko}
                dateFormat="yyyy-MM-dd"
                selectsStart
                startDate={startDate}
                endDate={endDate}
                showMonthDropdown={showMonthDropdown}
                showYearDropdown={showYearDropdown}
            />
            <span>~</span>
            <DatePicker
                selected={endDate}
                onChange={onEndDateChange}
                locale={ko}
                dateFormat="yyyy-MM-dd"
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                showMonthDropdown={showMonthDropdown}
                showYearDropdown={showYearDropdown}
            />
        </div>
    );
}
export default DateRangePicker;
