import { Outlet } from "react-router-dom";
import classes from "../MngCommon.module.css";
import MngStatisticsNavigation from "./MngStatisticsNavigation";

function MngStatisticsRootLayout() {
    return (
        <>
            <div className={classes.admin_container}>
                <MngStatisticsNavigation />
                <Outlet />
            </div>
        </>
    );
}

export default MngStatisticsRootLayout;
