import { Outlet } from "react-router-dom";
import MngTournamentNavigation from "./MngTournamentNavigation";
import classes from "../MngCommon.module.css";

function MngTournamentRootLayout() {
  return (
    <>
      <div className={classes.admin_container}>
        <MngTournamentNavigation />
        <Outlet />
      </div>
    </>
  );
}

export default MngTournamentRootLayout;
