// src/store.js
import { configureStore } from '@reduxjs/toolkit';
import pageInfo from './pagination/pageInfo';

const store = configureStore({
  reducer: {
    pageInfo: pageInfo
  },
});

export default store;
