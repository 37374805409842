import classes from "./Checkbox.module.css";

export function CheckboxA({ id, checked, className, onChange }) {
    // checkbox toggle 시
    const changeHandler = (event) => {
        const { checked } = event.target;
        onChange(checked);
    };

    return (
        <div className={`${classes.checkbox} ${classes[className]}`}>
            <input type="checkbox" id={id} checked={checked} onChange={changeHandler} />
            <label htmlFor={id}></label>
        </div>
    );
}

//@Depericated
function Checkbox({ title, id, value, checked, onChange, className }) {
    return (
        <div className={`${classes.checkbox} ${classes[className]}`}>
            <input type="checkbox" id={id} checked={checked} onChange={onChange} value={value} />
            <label htmlFor={id}>{title}</label>
        </div>
    );
}

/**
 *
 * @param props.title 화면에 표출하는
 * @param props.id for react
 * @param props.value value..
 * @param props.checked box 체크 여부
 * @param props.onChange 클릭시에 반응하는 Callback Function (callback parameter : id)
 * @param props.className
 * @returns
 */
export function CheckboxB({ title, id, value = "default", checked, onChange, className }) {
    // checkbox toggle 시
    const changeHandler = () => {
        onChange(value);
    };

    return (
        <div className={`${classes.checkbox} ${classes[className]}`}>
            <input type="checkbox" id={id} checked={checked} onChange={changeHandler} value={value} />
            <label htmlFor={id}>{title}</label>
        </div>
    );
}

export default Checkbox;
