import { useLocation, useNavigate } from "react-router-dom";
import { ReqTopLineList } from "../../../api/site/ApiForTopLine";
import Button from "../../../components/UI/Button/Button";
import Pagination from "../../../components/UI/Table/Pagination";
import classes from "../MngCommon.module.css";
import { useEffect, useState } from "react";
import Moment from "moment";
import PaginationNew from "../../../components/UI/Table/PaginationNew";
function MngSiteTopLine(props) {

    const location = useLocation();
    const navigate = useNavigate();



    const [topLines, setTopLines] = useState([]);

    const [currentPage, setCurrentPage] = useState(location.state?.currentPage ?? 0);
    const [totalPage, setTotalPage] = useState(0); //총 페이지 갯수
    const [totalCount, setTotalCount] = useState(0); //총 페이지 갯수
    const createHandler = () => {
        navigate("./create");
    };

    const detailHandler = (e, id) => {
        e.preventDefault();

        navigate(`./${id}`, {state: {currentPage}});
    };
    const handlePageChange = async (page) => {
        setCurrentPage(page);

        const response = await ReqTopLineList(page);
        if (response.status === 200) {
            const resData = await response.data.data;
            setTotalPage(resData.totalPages);
            setTotalCount(resData.totalElements);
            setTopLines(resData.content);
        } else {
            setTopLines([]);
        }
    };
    useEffect(() => {
        handlePageChange(currentPage);
    }, [])
    return (
        <section>
            <div className={classes.admin_contents}>
                <h3>메인 띠배너 관리 - 목록</h3>
                <p className={classes.desc}>
                    * 메인 띠배너는 1개만 노출 가능합니다.
                </p>
                <div className={classes.contents_wrap}>
                    <div
                        className={`${classes.admin_table_wrap} ${classes.align_center}  ${classes.row_select} ${classes.left_col_2}`}
                    >
                        <table>
                            <colgroup>
                                <col style={{ width: "5%" }} />
                                <col style={{ width: "30%" }} />
                                <col style={{ width: "15%" }} />
                                <col style={{ width: "10%" }} />
                                <col style={{ width: "15%" }} />
                                <col style={{ width: "10%" }} />
                                <col style={{ width: "10%" }} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th>배너타이틀</th>
                                    <th>노출기간</th>
                                    <th>등록일</th>
                                    <th>등록자</th>
                                    <th>노출여부</th>
                                    <th>조회수</th>
                                </tr>
                            </thead>
                            <tbody>
                                {topLines.length !== 0 &&
                                    topLines.map((data, index) => (
                                        <tr
                                            onClick={(e) =>
                                                detailHandler(e, data.id)
                                            }
                                        >
                                            <td>{data.seq}</td>
                                            <td>{data.title}</td>
                                            <td>
                                                {Moment(
                                                    data.expose_s_date
                                                ).format("YYYY-MM-DD")}{" "}
                                                ~{" "}
                                                {Moment(
                                                    data.expose_e_date
                                                ).format("YYYY-MM-DD")}
                                            </td>
                                            <td>
                                                {Moment(data.reg_date).format(
                                                    "YYYY-MM-DD"
                                                )}
                                            </td>
                                            <td>{data.reg_id}</td>
                                            <td>
                                                {data.exposeYn
                                                    ? "노출"
                                                    : "미노출"}
                                            </td>
                                            <td>{data.read_ctn}</td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                        <PaginationNew
                            className="admin_st"
                            totalPage={totalPage}
                            currentPage={currentPage}
                            defaultShowPage={5}
                            changePageCB={handlePageChange}
                        />
                        <div
                            className={`${classes.bottom_btn_wrap} ${classes.position_st}`}
                        >
                            <Button
                                className="secondary sm"
                                onClick={createHandler}
                            >
                                등록
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default MngSiteTopLine;

export const loader = async () => {
    const response = await ReqTopLineList(0);

    if (response.status === 200) {
        const resData = await response.data.data;
        return {
            data: resData.content,
            totalPage: resData.totalPages,
            totalCount: resData.totalElements,
        };
    } else {
        return { data: [], totalPage: 0, totalCount: 0 };
    }
};
