import { Outlet } from "react-router-dom";
import classes from "../MngCommon.module.css";
import MngEventNavigation from "./MngEventNavigation";

function MngEventRootLayout() {
    return (
        <>
            <div className={classes.admin_container}>
                <MngEventNavigation />
                <Outlet />
            </div>
        </>
    );
}

export default MngEventRootLayout;
