import instance from "../../store/AxiosInterceptor";

export async function ReqSmartFilterList(page) {
    const response = await instance
        .get(`/api/v1/admin/smartfilter/select/menu?page=${page}&size=10`, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            if (error.response) {
                return {
                    status: error.response.status,
                    error: "",
                };
            } else if (error.request) {
                return {
                    status: 600,
                    error: "요청에 대한 응답 없음",
                };
            } else {
                return {
                    status: 600,
                    error: "요청설정 에러",
                };
            }
        });
    return response;
}
export async function ReqSmartFilterDetail(productId) {
    const response = await instance
        .get(`/api/v1/admin/smartfilter/select/menu/${productId}`, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            // console.log(error);
            if (error.response) {
                // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
                return {
                    status: error.response.status,
                    error: "",
                };
            } else if (error.request) {
                // 요청이 이루어 졌으나 응답을 받지 못했습니다.
                return {
                    status: 600,
                    error: "요청에 대한 응답 없음",
                };
            } else {
                // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
                return {
                    status: 600,
                    error: "요청설정 에러",
                };
            }
        });
    return response;
}
export async function ReqqSmartFilterMenuItem(filterMenuId) {
    const response = await instance
        .get(`/api/v1/admin/smartfilter/select/item/${filterMenuId}`, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            // console.log(error);
            if (error.response) {
                // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
                return {
                    status: error.response.status,
                    error: "",
                };
            } else if (error.request) {
                // 요청이 이루어 졌으나 응답을 받지 못했습니다.
                return {
                    status: 600,
                    error: "요청에 대한 응답 없음",
                };
            } else {
                // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
                return {
                    status: 600,
                    error: "요청설정 에러",
                };
            }
        });
    return response;
}

export async function ReqSmartFilterDelete(id) {
    try {
        const url = `/api/v1/admin/smartfilter/delete/${id}`;
        const response = await instance.delete(url);
        return response;
    } catch (error) {
        console.error(error);
        return { error };
    }
}

export async function ReqSmartFiltermenuUpload(requestData) {
    console.log("req ", requestData);

    const response = await instance
        .post("/api/v1/admin/smartfilter/mng/update/PRODUCT", requestData)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            if (error.response) {
                return {
                    status: error.response.status,
                    error: "",
                };
            } else if (error.request) {
                return {
                    status: 600,
                    error: "요청에 대한 응답 없음",
                };
            } else {
                return {
                    status: 600,
                    error: "요청설정 에러",
                };
            }
        });
    return response;
}

export async function ReqSmartFilterItemUpdate(id, requestData) {
    const response = await instance
        .post(`/api/v1/admin/smartfilter/item/update/PRODUCT/${id}`, requestData)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            if (error.response) {
                return {
                    status: error.response.status,
                    error: "",
                };
            } else if (error.request) {
                return {
                    status: 600,
                    error: "요청에 대한 응답 없음",
                };
            } else {
                return {
                    status: 600,
                    error: "요청설정 에러",
                };
            }
        });
    return response;
}
