import { useContext, useState } from "react";
import classes from "../MngCommon.module.css";
import Button from "../../../components/UI/Button/Button";
import SelectBox from "../../../components/UI/SelectBox/SelectBox";
import Input from "../../../components/UI/Input/Input";
import FileBox, { FileBoxForImage } from "../../../components/UI/Input/FileBox";
import Radio from "../../../components/UI/Input/Radio";

import { useLoaderData, useNavigate } from "react-router-dom";
import QuillEditor from "../../../components/UI/Editer/QuillEditor";
import { ReqInsertKegel } from "../../../api/kegel/KegelAPi";
import SelectBoxKegel from "../../../components/UI/SelectBox/SelectBoxKegel";
import { JwtTokenContext } from "../../../store/JwtTokenProvider";
function MngKegelUpload(props) {
    // viewMode : 0 - 리스트, 1- 작성, 2- 상세
    // 등록일 (오늘 날짜 표시)

    const loginCtx = useContext(JwtTokenContext);
    const today = () => {
        const now = new Date();

        return (
            now.getFullYear() +
            "-" +
            ("0" + (now.getMonth() + 1)).slice(-2) +
            "-" +
            ("0" + now.getDate()).slice(-2)
        );
    };
    const navigate = useNavigate();
    const kegelData = useLoaderData();

    const toList = () => {
        navigate("/admin/kegel");
    };

    const [isModalOpen1, setIsModalOpen1] = useState(false);
    const openModalHandler1 = (e) => {
        e.stopPropagation();
        setIsModalOpen1(true);
    };
    const closeModalHandler1 = (e) => {
        e.stopPropagation();
        setIsModalOpen1(false);
    };
    const sampleData = [
        {
            value: "레인정비기계",
            data: "MACHINE",
            disabled: "false",
            selected: "false",
        },
        {
            value: "레인정비소모품",
            data: "CONSUMABLE",
            disabled: "false",
            selected: "false",
        },
        {
            value: "훈련 도구",
            data: "TOOL",
            disabled: "false",
            selected: "false",
        },
    ];

    //[S] 입력 데이터 --------------------

    const [selectedCategory, setSelectedCategory] = useState("");
    const [selectedCategoryData, setSelectedCategoryData] = useState("");

    // const [category, setCategody] = useState("");
    // const categoryHandler = (value) => {
    //     setCategody(value);
    // };
    const [title, setTitle] = useState("");
    const titleHandler = (value) => {
        setTitle(value);
    };
    const [content, setContent] = useState("");
    const contentHandler = (value) => {
        setContent(value);
    };
    const [exposeYn, setExposeYn] = useState("N");
    const isExposeChangeHandler = (e) => {
        setExposeYn(e.target.value);
    };
    const [firstSignature, setFirstSignature] = useState("");
    const firstSignatureHandler = (value) => {
        setFirstSignature(value);
    };
    const [secondSignature, setSecondSignature] = useState("");
    const secondSignatureHandler = (value) => {
        setSecondSignature(value);
    };
    const [thirdSignature, setThirdSignature] = useState("");
    const thirdSignatureHandler = (value) => {
        setThirdSignature(value);
    };
    const [subDescription, setSubDescription] = useState("");
    const subDescriptionHandler = (value) => {
        setSubDescription(value);
    };
    const [file, setFile] = useState();
    const [imageSrc, setImageSrc] = useState(null);
    const handleFileChangeCallback = (file, image) => {
        setFile(file);
        setImageSrc(image);
    };

    const handleFileDeleteCallback = () => {
        setFile(null);
        setImageSrc(null);
    };

    const submitHandler = async (e) => {
        e.preventDefault();

        if (!file) {
            alert("이미지를 등록해주세요.");
            return;
        }

        const jsonData = JSON.stringify({
            title: title,
            content: content,
            exposeYn: exposeYn,
            firstSignature: firstSignature,
            secondSignature: secondSignature,
            thirdSignature: thirdSignature,
            subDescription: subDescription,
            kegelType: selectedCategoryData,
        });

        const formData = new FormData();
        formData.append("image", file);
        formData.append(
            "body",
            new Blob([jsonData], { type: "application/json" })
        );

        const response = await ReqInsertKegel(formData);
        if (response.status === 200) {
            navigate("/admin/kegel");
        } else {
            alert(
                `[${response.status}] ${
                    response.data ? response.data.error : "등록 실패"
                } `
            );
        }
    };

    //[E] 입력 데이터 --------------------

    return (
        <section>
            <div className={classes.admin_contents}>
                <h3>상품상세 - 등록</h3>
                <div
                    className={`${classes.contents_wrap} ${classes.search_wrap} `}
                >
                    <div className={classes.admin_table_wrap}>
                        <table>
                            <colgroup>
                                <col style={{ width: "159px" }} />
                                <col style={{ width: "620px" }} />
                                <col style={{ width: "159px" }} />
                                <col style={{ width: "620px" }} />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>등록일</th>
                                    <td>{today()}</td>
                                    <th>등록자</th>
                                    <td>{loginCtx.decodedToken.jti}</td>
                                </tr>
                                <tr>
                                    <th>노출여부</th>
                                    <td colSpan={3}>
                                        <div className={classes.radio_wrap}>
                                            <Radio
                                                value="Y"
                                                title="노출"
                                                name="rad_01"
                                                id="rad_01_01"
                                                checked={exposeYn === "Y"}
                                                onChange={isExposeChangeHandler}
                                            />
                                            <Radio
                                                value="N"
                                                title="미노출"
                                                name="rad_01"
                                                id="rad_01_02"
                                                checked={exposeYn === "N"}
                                                onChange={isExposeChangeHandler}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>카테고리</th>
                                    <td colSpan={3}>
                                        <SelectBoxKegel
                                            className="admin_st"
                                            optionData={sampleData}
                                            selectedValue={selectedCategory}
                                            setSelectedValue={
                                                setSelectedCategory
                                            }
                                            selectedData={selectedCategoryData}
                                            setSelectedData={
                                                setSelectedCategoryData
                                            }
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>상품명</th>
                                    <td colSpan={3}>
                                        <Input
                                            className="admin_st w_100"
                                            placeholder="상품명 입력"
                                            value={title}
                                            onChange={titleHandler}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>설명</th>
                                    <td colSpan={3}>
                                        <Input
                                            className="admin_st w_100"
                                            value={subDescription}
                                            onChange={subDescriptionHandler}
                                        />
                                    </td>
                                </tr>

                                <tr>
                                    <th>대표이미지</th>
                                    <td colSpan={3}>
                                        {/* <div className={classes.flex_row}>
                                        
                                            <FileBox />
                                            <p className={classes.desc}>
                                                * 이미지 사이즈 000*000px
                                            </p>
                                        </div> */}
                                        {FileBoxForImage(
                                            imageSrc,
                                            handleFileChangeCallback,
                                            handleFileDeleteCallback
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <th rowSpan={3}>특징</th>
                                    <td colSpan={3}>
                                        <Input
                                            className="admin_st"
                                            value={firstSignature}
                                            onChange={firstSignatureHandler}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={3}>
                                        <Input
                                            className="admin_st"
                                            value={secondSignature}
                                            onChange={secondSignatureHandler}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={3}>
                                        <Input
                                            className="admin_st"
                                            value={thirdSignature}
                                            onChange={thirdSignatureHandler}
                                        />
                                    </td>
                                </tr>
                                {/* <tr>
                                    <th>사용자페이지 URL</th>
                                    <td colSpan={3}>
                                        <Input
                                            className="admin_st"
                                            value="https://bowlingkorea.com/kegel/laneMachinesView.do?ntt_id=43202&currRow=1"
                                        />
                                    </td>
                                </tr> */}
                                <tr>
                                    <th>상세정보</th>
                                    <td colSpan={3}>
                                        <div style={{ height: "500px" }}>
                                            <QuillEditor
                                                height="460"
                                                width="auto"
                                                value={content}
                                                onValueChangeCB={contentHandler}
                                            />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <div className={classes.bottom_btn_wrap}>
                            <Button
                                className="border sm"
                                onClick={submitHandler}
                            >
                                등록
                            </Button>
                            <Button className="secondary sm" onClick={toList}>
                                목록
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default MngKegelUpload;
