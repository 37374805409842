import { useState, useEffect } from "react";
import axios from "axios";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import Moment from "moment";
import Button from "../../../components/UI/Button/Button";
import SelectBoxNew from "../../../components/UI/SelectBox/SelectBoxNew";
import Pagination from "../../../components/UI/Table/Pagination";
import PaginationNew from "../../../components/UI/Table/PaginationNew";
import classes from "../MngCommon.module.css";
import Checkbox from "../../../components/UI/Input/Checkbox";
import DateRangePicker from "../../../components/UI/DatePicker/DateRangePicker";
import MngTournamentReceiptModal from "./components/MngTournamentReceiptModal";
import MngTournamentStadiumModal from "./components/MngTournamentStadiumModal";
import { ReqContestType } from "../../../api/common/ConditionSelectBoxApi";
import {
    DefaultAll,
    progressData,
} from "../../../components/definedefault/DefSelectBoxValues";
import {
    ReqContestMainInfoList,
    ReqContestMainInfoSearchList,
} from "../../../api/tournament/ApiForContestMain";

function MngTournamentDetailApply() {
    const location = useLocation();
    // <<< 대회 타입 가져오기(Select Box) */
    const [contestTypeData, setContestTypeData] = useState([DefaultAll]);
    const [type, setType] = useState("");

    const [text, setText] = useState([]);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [searchStartDate, setSearchStartDate] = useState("");
    const [searchEndDate, setSearchEndDate] = useState("");

    const handleStartDateChange = (date) => {
        setStartDate(date);
        setSearchStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
        setSearchEndDate(date);
    };

    // 대회 유형 select box
    const typeHandler = (key) => {
        setType(key);
    };

    const contestTypeMouseOverHandler = (key) => {
        if (contestTypeData.length === 1) {
            ReqContestType(
                (result) => {
                    const contestTypeDatas = result.map((data) => {
                        return { key: data.type, value: data.name };
                    });
                    setContestTypeData((prev) => [
                        DefaultAll,
                        ...contestTypeDatas,
                    ]);
                },
                (excption_result) => {
                    setContestTypeData((prev) => [DefaultAll]);
                }
            );
        }
    };

    const setProcessTypeCheck = (processType) => {
        const process_type = progressData.find(
            (items) => items.key === processType
        );
        return process_type === undefined ? "" : process_type.value;
    };

    let paymentStatus = ""; // 상태를 저장할 변수 초기화
    const getPaymentStatus = (
        depositStart,
        deposit1stEnd,
        deposit2ndEnd,
        deposit3thEnd
    ) => {
        // 현재 날짜를 Date 형식으로 초기화
        const currentDate = new Date();

        // 결제 시작일시 설정
        const paymentStartDate = Moment(depositStart, "YYYYMMDDHHmm").toDate();
        // console.log(paymentStartDate);
        // 1차 결제 예비마감일과 1차 결제 마감일 설정
        const firstPaymentPreDeadline = Moment(
            deposit1stEnd,
            "YYYYMMDDHHmm"
        ).toDate();
        const firstPaymentDeadline = Moment(
            deposit2ndEnd,
            "YYYYMMDDHHmm"
        ).toDate();

        // 2차 결제 마감일 설정
        const secondPaymentDeadline = Moment(
            deposit3thEnd,
            "YYYYMMDDHHmm"
        ).toDate();

        // 조건에 따라 상태 설정
        if (currentDate < paymentStartDate) {
            paymentStatus = ""; // 공란
        } else if (
            currentDate >= paymentStartDate &&
            currentDate < firstPaymentPreDeadline
        ) {
            paymentStatus = "1차 결제 예비마감";
        } else if (
            currentDate >= firstPaymentPreDeadline &&
            currentDate < firstPaymentDeadline
        ) {
            paymentStatus = "1차 결제 마감";
        } else if (
            currentDate >= firstPaymentDeadline &&
            currentDate < secondPaymentDeadline
        ) {
            paymentStatus = "2차 결제 마감";
        } else if (currentDate >= secondPaymentDeadline) {
            paymentStatus = "2차 결제 마감";
        }

        return paymentStatus;
    };

    // Pagination state
    const [currentPage, setCurrentPage] = useState(location.state?.currentPage ?? 0);
    const [totalPage, setTotalPage] = useState(0); //총 페이지 갯수
    const [totalCount, setTotalCount] = useState(0); //총 페이지 갯수

    const viewAll = async () => {
        setCurrentPage(0);
        setType("");
        setStartDate(new Date());
        setEndDate(new Date());
        setSearchStartDate("");
        setSearchEndDate("");
        const response = await ReqContestMainInfoList(0);
        if (response.status === 200) {
            const resData = await response.data.datas;
            setTotalPage(resData.totalPages);
            setTotalCount(resData.totalElements);
            setText(resData.content);
        } else {
            setText([]);
        }
    };

    const handlePageChange = async (page) => {
        setCurrentPage(page);

        const searchParam = {
            type: type === "" ? null : type,
            sDate:
                searchStartDate === ""
                    ? null
                    : Moment(searchStartDate).format("YYYYMMDD"),
            eDate:
                searchEndDate === ""
                    ? null
                    : Moment(searchEndDate).format("YYYYMMDD"),
            page: page,
            //title: tournament === "" ? null : tournament,
        };

        const response = await ReqContestMainInfoSearchList(searchParam);
        if (response.status === 200) {
            const resData = await response.data.datas;
            setTotalPage(resData.totalPages);
            setTotalCount(resData.totalElements);
            setText(resData.content);
        } else {
            setText([]);
        }
    };
    useEffect(() => {
        handlePageChange(currentPage)
    }, [])
    // 검색 버튼 클릭
    const search = async () => {
        setCurrentPage(0);

        const searchParam = {
            type: type === "" ? null : type,
            sDate:
                searchStartDate === ""
                    ? null
                    : Moment(searchStartDate).format("YYYYMMDD"),
            eDate:
                searchEndDate === ""
                    ? null
                    : Moment(searchEndDate).format("YYYYMMDD"),
            page: 0,
            //title: tournament === "" ? null : tournament,
        };

        const response = await ReqContestMainInfoSearchList(searchParam); // Use loader with pagination parameters
        if (response.status === 200) {
            const resData = await response.data.datas;
            setTotalPage(resData.totalPages);
            setTotalCount(resData.totalElements);
            setText(resData.content);
        } else {
            setText([]);
        }
    };

    const mainList = () => {
        return (
            <>
                {text.length !== 0 &&
                    text.map((data, index) => (
                        <>
                            <tr>
                                <td>
                                    {totalCount - (10 * currentPage + index)}
                                </td>
                                <td>{data.id}</td>
                                <td>
                                    <NavLink to={`${data.id}`} state={{ currentPage }}>
                                        {data.name}
                                    </NavLink>
                                </td>
                                <td>{data.regionNames}</td>
                                <td>
                                    {Moment(data.start_date).format(
                                        "YYYY-MM-DD"
                                    )}
                                    <br />~{" "}
                                    {Moment(data.end_date).format("YYYY-MM-DD")}
                                </td>
                                <td>{data.process_type_value}</td>
                                <td>{data.payment_status_value}</td>
                                <td>
                                    {Moment(data.reg_date).format("YYYY-MM-DD")}
                                </td>
                            </tr>
                        </>
                    ))}
            </>
        );
    };

    return (
        <section>
            <div className={classes.admin_contents}>
                <h3>접수/참가관리 리스트</h3>
                <div
                    className={`${classes.contents_wrap} ${classes.search_wrap}`}
                >
                    <div className={classes.admin_table_wrap}>
                        <table>
                            <colgroup>
                                <col style={{ width: "11%" }} />
                                <col style={{ width: "89%" }} />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>대회기간</th>
                                    <td>
                                        <DateRangePicker
                                            startDate={startDate}
                                            endDate={endDate}
                                            onStartDateChange={
                                                handleStartDateChange
                                            }
                                            onEndDateChange={
                                                handleEndDateChange
                                            }
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>대회조회</th>
                                    <td>
                                        <div className={classes.flex_row}>
                                            <SelectBoxNew
                                                className="admin_st"
                                                setSelectItemCB={typeHandler}
                                                optionDatas={contestTypeData}
                                                showDefaultKey={type}
                                                mouseOverCB={
                                                    contestTypeMouseOverHandler
                                                }
                                            />
                                            {/* <SelectBox
                          className="admin_st"
                          optionData={tournamentName}
                        /> */}
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className={classes.bottom_btn_wrap}>
                            <Button className="border sm" onClick={search}>
                                검색
                            </Button>
                            <Button className="secondary sm" onClick={viewAll}>
                                전체보기
                            </Button>
                        </div>
                    </div>
                </div>
                <div className={classes.contents_wrap}>
                    <div
                        className={`${classes.admin_table_wrap} ${classes.align_center}  ${classes.row_select} ${classes.left_col_3} ${classes.left_col_4}`}
                    >
                        <p className={classes.counter}>
                            총 <b>{totalCount}</b>건
                        </p>
                        <table>
                            <colgroup>
                                <col style={{ width: "5%" }} />
                                <col style={{ width: "10%" }} />
                                <col style={{ width: "20%" }} />
                                <col style={{ width: "20%" }} />
                                <col style={{ width: "12%" }} />
                                <col style={{ width: "10%" }} />
                                <col style={{ width: "13%" }} />
                                <col style={{ width: "10%" }} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th>대회코드</th>
                                    <th>대회명</th>
                                    <th>지역</th>
                                    <th>대회기간</th>
                                    <th>진행상태</th>
                                    <th>결제 마감단계</th>
                                    <th>등록일</th>
                                </tr>
                            </thead>
                            <tbody>{mainList()}</tbody>
                        </table>
                        <PaginationNew
                            className="admin_st"
                            totalPage={totalPage}
                            currentPage={currentPage}
                            defaultShowPage={5}
                            changePageCB={handlePageChange}
                        />
                    </div>
                </div>
            </div>
        </section>
    );
}
export default MngTournamentDetailApply;

//>>>대회등록 리스트 조회 loader
export const loader = async () => {
    const response = await ReqContestMainInfoList(0);

    if (response.status === 200) {
        const resData = await response.data.datas;
        const curData = resData.content.map((data) => {
            return {
                id: data.id,
                name: data.name,
                number: data.number,
                type: data.type,
                start_date: data.start_date,
                end_date: data.end_date,
                process_type: data.process_type,
                process_type_value: data.process_type_value,
                payment_status_value: data.payment_status_value,
                apply_type: data.apply_type,
                apply_start_time: data.apply_start_time,
                apply_end_time: data.apply_end_time,
                entry_fee: data.entry_fee,
                payment_type: data.payment_type,
                deposit_start: data.deposit_start,
                deposit_1st_end: data.deposit_1st_end,
                deposit_2nd_end: data.deposit_2nd_end,
                deposit_3th_end: data.deposit_3th_end,
                auto_succession_yn: data.auto_succession_yn,
                expose_yn: data.expose_yn,
                cancel_apply_date: data.cancel_apply_date,
                modify_apply_info: data.modify_apply_info,
                cancel_apply_yn: data.cancel_apply_yn,
                description: data.description,
                agreement_info: data.agreement_info,
                odd_lane_limit: data.odd_lane_limit,
                even_lane_limit: data.even_lane_limit,
                wait_limit: data.wait_limit,
                handicap_id: data.handicap_id,
                handicap_title: data.handicap_title,
                group_start: data.group_start,
                group_end: data.group_end,
                group_names: data.group_names,
                bg_img_uri: data.bg_img_uri,
                bg_img_name: data.bg_img_name,
                prevent_duplicates: data.prevent_duplicates,
                contest_researchs: data.contest_researchs,
                reg_date: data.reg_date,
                regionNames: data.regionNames,
            };
        });
        // console.log(curData);
        return {
            data: curData,
            totalPage: resData.totalPages,
            totalCount: resData.totalElements,
        };
    } else {
        //console.log(resData);
        return { data: [], totalPage: 0, totalCount: 0 };
    }
};
//<<대회등록 리스트 조회
