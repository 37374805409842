import { useLoaderData, useLocation, useNavigate } from "react-router-dom";
import {
    ReqKegelBoardDelete,
    ReqKegelBoardDetail,
} from "../../../../api/site/ApiForKegel";
import sample_img_1 from "../../../../assets/images/event_sample_img_1.jpg";
import Button from "../../../../components/UI/Button/Button";
import classes from "../../MngCommon.module.css";
import Moment from "moment";

function MngKegelContentsDetail() {
    const kegel = useLoaderData();

    const location = useLocation();
    const navigate = useNavigate();

    const backHandler = () => {
        navigate("/admin/site/kegel", { state: location.state });
    };

    const deleteHandler = async (e) => {
        e.preventDefault();

        const isTrue = window.confirm("내용를 삭제하시겠습니까?");
        if (isTrue) {
            try {
                const response = await ReqKegelBoardDelete(kegel.id);
                if (response.status === 200) {
                    alert("내용이 삭제되었습니다.");
                    backHandler();
                } else {
                    alert("내용 삭제 실패");
                }
            } catch (error) {
                console.error("Error:", error);
            }
        } else {
            return;
        }
    };

    const updateHandler = () => {
        navigate(`../update/${kegel.id}`, { state: location.state });
    };

    return (
        <section>
            <div className={classes.admin_contents}>
                <h3>케겔 - 상세</h3>
                <div
                    className={`${classes.contents_wrap} ${classes.search_wrap} `}
                >
                    <div className={classes.admin_table_wrap}>
                        <table>
                            <colgroup>
                                <col style={{ width: "10%" }} />
                                <col style={{ width: "40%" }} />
                                <col style={{ width: "10%" }} />
                                <col style={{ width: "40%" }} />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>등록일</th>
                                    <td>
                                        {Moment(kegel.regDate).format(
                                            "YYYY-MM-DD"
                                        )}
                                    </td>
                                    <th>등록자</th>
                                    <td>{kegel.regID}</td>
                                </tr>
                                <tr>
                                    <th>노출여부</th>
                                    <td colSpan={3}>
                                        {kegel.exposeYn === "Y"
                                            ? "노출"
                                            : "미노출"}
                                    </td>
                                </tr>
                                <tr>
                                    <th>메인카피</th>
                                    <td colSpan={3}>{kegel.title}</td>
                                </tr>
                                <tr>
                                    <th>서브카피</th>
                                    <td colSpan={3}>{kegel.content}</td>
                                </tr>
                                <tr>
                                    <th>썸네일 이미지</th>
                                    <td colSpan={3}>
                                        <div className={classes.img_box}>
                                            <img
                                                src={kegel.attach_uri}
                                                alt="썸네일 이미지"
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>랜딩 URL</th>
                                    <td colSpan={3}>{kegel.landingUrl}</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className={classes.bottom_btn_wrap}>
                            <Button
                                className="secondary sm"
                                onClick={updateHandler}
                            >
                                수정
                            </Button>
                            <Button className="sm" onClick={deleteHandler}>
                                삭제
                            </Button>
                            <Button className="border sm" onClick={backHandler}>
                                목록
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default MngKegelContentsDetail;

export const loader = async ({ params }) => {
    const response = await ReqKegelBoardDetail(params.kegelID);
    if (response.status === 200) {
        const resData = await response.data;
        return resData;
    } else {
        return [];
    }
};
