import classes from "./Terms.module.css";
function TermsOfUseDetail(props) {
    return (
        <div className={classes.terms_box}>
            <div dangerouslySetInnerHTML={{
                __html: props.contentCB,
                }} >
            </div>
            {/* <article>
                <h3>제 1 장 총칙</h3>
                <div className={classes.box}>
                    <dl>
                        <dt>
                            <span>제1조</span>목적
                        </dt>
                        <dd>
                            본 약관은 ㈜진승이 제공하는 모든 서비스의 이용조건
                            및 절차, 기타 필요한 사항을 규정함을 목적으로 한다.
                        </dd>
                    </dl>
                    <dl>
                        <dt>
                            <span>제2조</span>약관의 효력
                        </dt>
                        <dd>
                            본 약관은 전기통신사업법 제31조, 동법 시행규칙
                            제21조의 2에 따라 공시절차를 거친 후 서비스를 통하여
                            이를 공지하거나 전자우편 기타의 방법으로 회원에게
                            통지함으로써 효력을 발생합니다.
                        </dd>
                    </dl>
                    <dl>
                        <dt>
                            <span>제3조</span>약관의 변경
                        </dt>
                        <dd>
                            회사는 회사 사정상 혹은 서비스상 중요 사유가 있을 때
                            약관을 변경할 수있으며, 변경된 약관은 제2조와 같은
                            방법으로 효력을 발생합니다.
                        </dd>
                    </dl>
                    <dl>
                        <dt>
                            <span>제4조</span>약관외 준칙
                        </dt>
                        <dd>
                            본 약관에 명시되지 않은 사항은 전기통신기본법,
                            전기통신사업법 등 기타 대한민국의 관련 법령과
                            상관습에 따릅니다.
                        </dd>
                    </dl>
                    <dl>
                        <dt>
                            <span></span>
                        </dt>
                        <dd></dd>
                    </dl>
                </div>
            </article>
            <article>
                <h3>제 2 장 회원 가입 및 탈퇴</h3>
                <div className={classes.box}>
                    <dl>
                        <dt>
                            <span>제1조</span>회원 가입
                        </dt>
                        <dd>
                            <ol>
                                <li>
                                    가입신청자 온라인으로 등록절차를 거쳐서
                                    '동의함' 버튼을 누르면 이 약관에 합법적으로
                                    동의하는 것으로 간주합니다.
                                </li>
                                <li>
                                    회사는 다음 각 호에 해당하지 않는 한
                                    신청자를 회원으로 등록합니다.
                                    <ul>
                                        <li>
                                            등록내용에 허위, 기재누락, 오기가
                                            있는 경우
                                        </li>
                                        <li>
                                            회원으로 등록하는 것이 회사의 기술상
                                            또는 업무 수행상 현저히 지장이 있는
                                            경우
                                        </li>
                                        <li>
                                            사회의 안녕과 질서 그리고 미풍양속을
                                            저해할 목적으로 신청한 경우.
                                        </li>
                                    </ul>
                                </li>
                            </ol>
                        </dd>
                    </dl>
                    <dl>
                        <dt>
                            <span>제2조</span>회원 탈퇴
                        </dt>
                        <dd>
                            회원은 회사에 언제든지 자신의 회원 등록을 말소해 줄
                            것(이용자 탈퇴)을 요청 할 수 있으며 회사는 위 요청을
                            받은 즉시 해당 이용자의 회원 등록 말소를 위한 절차를
                            밟습니다
                            <ol>
                                <li>
                                    회원이 사망한 때는 회원자격을 상실합니다.
                                </li>
                                <li>
                                    회원이 다음 각 호의 사유에 해당하는 경우,
                                    회사는 사전 통지없이 회원 자격을
                                    상실시키거나 또는 기간을 정하여 서비스
                                    이용을 중지할 수 있습니다.
                                    <ul>
                                        <li>
                                            등록 신청 시에 허위 내용을 등록한
                                            경우
                                        </li>
                                        <li>본 약관을 위반한 경우</li>
                                        <li>
                                            공공질서 및 미풍양속에 반하는 경우
                                        </li>
                                        <li>범죄적 행위에 관련되는 경우</li>
                                        <li>
                                            서비스에 위해를 가하는 등 서비스의
                                            건전한 이용을 저해한 경우
                                        </li>
                                        <li>
                                            기타 회원으로서의 자격을 지속시키는
                                            것이 부적절하다고 판단되는 경우
                                        </li>
                                    </ul>
                                </li>
                            </ol>
                        </dd>
                    </dl>
                </div>
            </article>
            <article>
                <h3>제 3 장 서비스 제공 및 이용</h3>
                <div className={classes.box}>
                    <dl>
                        <dt>
                            <span>제1조</span>서비스의 내용
                        </dt>
                        <dd>
                            회사는 필요한 경우 서비스의 내용을 추가 또는 변경할
                            수 있으며, 회사는 추가 또는 변경내용을 회원에게
                            통지하여야 합니다.
                        </dd>
                    </dl>
                    <dl>
                        <dt>
                            <span>제2조</span>서비스 이용시간
                        </dt>
                        <dd>
                            <ol>
                                <li>
                                    서비스는 회사의 업무상 또는 기술상 장야,
                                    기타 특별한 사유가 없는 한 연중 무휴, 1일
                                    24시간 이용할 수 있습니다.
                                </li>
                                <li>
                                    회사는 제공하는 서비스 중 일부에 대한
                                    이용시간을 별도로 정할 수 있으며, 이 경우
                                    관련 사항을 사전에 회원에게 공지 또는
                                    통지합니다.
                                </li>
                            </ol>
                        </dd>
                    </dl>
                    <dl>
                        <dt>
                            <span>제3조</span>정보의 제공 및 광고의 게재
                        </dt>
                        <dd>
                            <ol>
                                <li>
                                    회사는 서비스를 운용함에 있어서 각종 정보를
                                    서비스에 게재하는 방법 등으로 회원에게
                                    제공할 수 있습니다.
                                </li>
                                <li>
                                    회사는 광고 수익을 기반으로 본 서비스를
                                    제공하고 있습니다. 서비스를 이용하고자 하는
                                    이용자는 이 광고 게재에 대해 동의하는 것으로
                                    간주됩니다.
                                </li>
                            </ol>
                        </dd>
                    </dl>
                    <dl>
                        <dt>
                            <span>제4조</span>서비스 제공의 중지
                        </dt>
                        <dd>
                            회사는 언제든지 본 서비스 (전체 혹은 그 일부)를 일시
                            또는 영구적으로 수정하거나 중단할 수 있습니다.
                        </dd>
                        <dd>
                            귀하는 서비스의 수정, 중단 또는 중지에 대해 회원
                            또는 제3자에 대하여 어떠한 책임도 지지 않습니다.
                        </dd>
                    </dl>
                </div>
            </article>
            <article>
                <h3>제 4 장 의무사항</h3>
                <div className={classes.box}>
                    <dl>
                        <dt>
                            <span>제1조</span>회사의 의무
                        </dt>
                        <dd>
                            <ol>
                                <li>
                                    회사는 본 약관이 정하는 바에 따라
                                    지속적이고, 안정적인 서비스를 제공하 기
                                    위해서 노력합니다.
                                </li>
                                <li>
                                    회사는 서비스 제공과 관련해서 알고 있는
                                    회원의 신상정보를 본인의 승낙 없이 제
                                    3자에게 누설, 배포하지 않습니다.
                                </li>
                                <li>
                                    회사는 회원 전체 또는 일부의 개인정보에 관한
                                    통계 자료를 작성하여 회사의 내부 마케팅 자료
                                    또는 광고 영업을 위한 통계 자료로 활용할 수
                                    있습니다.
                                </li>
                                <li>
                                    회사는 회원으로부터 제출되는 의견 및
                                    불만사항이 정당하다고 판단하는 경우
                                    우선적으로 그 사항을 처리하여야 합니다.
                                </li>
                                <li>
                                    종이카드 서비스는 우편으로 제공되기 때문에
                                    배달과정에서의 우편배달 사고에 대해서는
                                    회사가 책임을 지지 않습니다.
                                </li>
                            </ol>
                        </dd>
                    </dl>
                    <dl>
                        <dt>
                            <span>제2조</span>회원의 의무
                        </dt>
                        <dd>
                            <ol>
                                <li>회원은 본 약관을 준수 하여야 합니다.</li>
                                <li>
                                    사용상의 과실 혹은 사용자명 및 비밀번호의
                                    부주의한 관리로 3자의 부정사용 등 피해가
                                    발생한 경우 모든 책임은 이를 야기한 회원에게
                                    있습니다
                                </li>
                                <li>
                                    전기통신법 제53조와 전기통신사업법 시행령
                                    16조(불온통신), 통신사업 제53조3항에 의거,
                                    본 서비스를 이용하여 음란물이나 불온한
                                    내용을 전송함으로써 발생하는 모든 법적인
                                    책임은 '아이디 이용자'에게 있습니다
                                </li>
                                <li>
                                    자신의 ID가 부정하게 사용된 경우 회원은
                                    반드시 회사에 그 사실을 신고하여야 합니다.
                                </li>
                            </ol>
                        </dd>
                    </dl>
                </div>
            </article>
            <article>
                <h3>[부칙]</h3>
                <p>본 약관은 2000년 7월 1일부로 시행합니다.</p>
            </article> */}
        </div>
    );
}

export default TermsOfUseDetail;
