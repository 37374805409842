import { Link } from "react-router-dom";
import classes from "./FooterNavigationApp.module.css";

function FooterNavigationApp(props) {
    // ↓ ↓ 화면 구현을 위한 임시 장치 입니다.
    // isMain official home 상태의 footer 입니다.
    const isMain = true;

    const defaultMenu = () => {
        return (
            <ul>
                <li>
                    <Link to="/privatePolicy">개인정보처리방침</Link>
                </li>
                <li>
                    <Link to="/termsOfUse">이용약관</Link>
                </li>
                <li>
                    <Link to="/intro/location">찾아오시는 길</Link>
                </li>
                <li>
                    <Link to="/intro/introduce">회사소개</Link>
                </li>
            </ul>
        );
    };

    return (
        <>
            <footer className={classes.footer}>
                <div className={classes.footer_wrap}>
                    <h2 className={classes.footer_logo}>(주)진승</h2>
                    <div>
                        <nav className={classes.nav}>{defaultMenu()}</nav>
                        <div className={classes.info_company}>
                            <ul>
                                <li>사업자 등록번호 : 229-81-18231</li>
                                <li>통신판매업 신고번호 : 제 2004-130호</li>
                                <li>주소 : 경기도 용인시 기흥구 석성로 521번길 41(동백동)</li>
                            </ul>
                            <ul>
                                <li>대표자 : 전진표</li>
                                <li>대표전화 : 031-282-6011</li>
                                <li>개인정보 관리책임자 : 최일수</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className={classes.copy_wrap}>
                    <p>Copyright JINSEUNG. All Rights Reserved.</p>
                </div>
            </footer>
        </>
    );
}

export default FooterNavigationApp;
