import instance from "../../store/AxiosInterceptor";

export async function ReqResultList() {
    const response = await instance
        .get(`/api/v1/admin/contest/result?page=0&size=10`, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            if (error.response) {
                // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
                return {
                    status: error.response.status,
                    error: "",
                };
            } else if (error.request) {
                // 요청이 이루어 졌으나 응답을 받지 못했습니다.
                return {
                    status: 600,
                    error: "요청에 대한 응답 없음",
                };
            } else {
                // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
                return {
                    status: 600,
                    error: "요청설정 에러",
                };
            }
        });
    return response;
}

export async function ReqResultUploadList() {
    const response = await instance
        .get(`/api/v1/admin/contest/resultUpload`, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            if (error.response) {
                // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
                return {
                    status: error.response.status,
                    error: "",
                };
            } else if (error.request) {
                // 요청이 이루어 졌으나 응답을 받지 못했습니다.
                return {
                    status: 600,
                    error: "요청에 대한 응답 없음",
                };
            } else {
                // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
                return {
                    status: 600,
                    error: "요청설정 에러",
                };
            }
        });
    return response;
}
