import instance from "../../store/AxiosInterceptor";

/**
 * 유저 마이페이지, 결제 요청 시
 * 서버에서 트랜잭션 아이디, 상점아이디, 상점API키를 얻기 위함
 * 트랜잭션 아이디를 정상적으로 얻으면 이니시스 결제 요청 동작
 * @param resolveCB 응답이 성공일 경우 데이터를 넣어 호출하는 callback method
 * @returns
 */
export function ReqTransactionId(object ,resolveCB, rejectCB) {
    instance({
        url: `/api/v1/home/inicis/transaction`,
        method: "POST",
        data : object
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/**
 * 유저 마이페이지, 결제 취소 API 요청
 * @param resolveCB 응답이 성공일 경우 데이터를 넣어 호출하는 callback method
 * @returns
 */
export function ReqCancelApplyOrPayment(contestID, competitorID, cancelPayment, paymentType, resolveCB, rejectCB) {
    //@Param : payStatus (cancelPayment)
    //결제를 안한 상태면 참가취소, 접수취소
    //결제를 한상태면 참가취소 및 결제 취소 로직
    instance({
        url: `/api/v1/home/contest/apply/cancel`,
        method: "POST",
        data : {
            contest_id : contestID,
            competitor_id : competitorID,
            cancel_payment : cancelPayment,
            payment_type : paymentType
        }
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}