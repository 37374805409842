export const DefaultAll = { value: "전체", key: null, disabled: false };
export const DefaultSelect = { value: "선택", key: null, disabled: false };

export const ContestNoticeSearchTypes = [DefaultAll, { value: "제목", key: "title" }, { value: "작성자", key: "writer" }];

/**
 * 대회 공지에서 사용하는 사용여부 필터 기본 값
 */
export const ContestNoticeFilterIsUse = [DefaultAll, { value: "사용", key: "Y" }, { value: "미사용", key: "N" }];

/**
 * 대회 공지에서 사용하는 사용여부 필터 기본 값
 */
export const ContestExposeMngProcessType = [DefaultAll, { value: "사용", key: "true" }, { value: "미사용", key: "false" }];

/**
 * 대회사용구 조회에서 사용하는 조건
 */
export const TournamentBall_Cus_Search = [DefaultAll, { value: "이름", key: "name" }, { value: "ID", key: "id" }];

/**
 * 접수/참가 관리 조회에서 사용하는 조건
 */
export const TournamentApply_Cus_Search = [DefaultAll, { value: "이름", key: "name" }, { value: "ID", key: "id" }, { value: "조", key: "group" }];

/**
 * 접수/참가 관리 조회에서 사용하는 조건(문자발송내역)
 */
export const TournamentApplySend_Cus_Search = [DefaultAll, { value: "이름", key: "name" }, { value: "ID", key: "id" }, { value: "발송자", key: "send" }];

/**
 * 조 편성 및 정원
 */
export const numData = [
    DefaultSelect,
    { value: "1", key: "1" },
    { value: "2", key: "2" },
    { value: "3", key: "3" },
    { value: "4", key: "4" },
    { value: "5", key: "5" },
    { value: "6", key: "6" },
    { value: "7", key: "7" },
    { value: "8", key: "8" },
    { value: "9", key: "9" },
];

/**
 * 조 편성 및 정원
 */
export const numsData = [
    { value: "1", key: "1" },
    { value: "2", key: "2" },
    { value: "3", key: "3" },
    { value: "4", key: "4" },
    { value: "5", key: "5" },
    { value: "6", key: "6" },
    { value: "7", key: "7" },
    { value: "8", key: "8" },
    { value: "9", key: "9" },
];

/**
 * 고객 상태
 */
export const stateData = [
    DefaultAll,
    { value: "접수", key: "applied" },
    { value: "접수취소", key: "cancel_apply" },
    { value: "선정", key: "chosen" },
    { value: "대기", key: "waiting" },
    { value: "비선정", key: "not_chosen" },
    { value: "선정1", key: "chosen_1" },
    { value: "선정9", key: "chosen_9" },
    { value: "참가취소", key: "cancel" },
    { value: "참가취소(미결제)", key: "cancel_not_pay" },
    { value: "대기취소", key: "cancel_waiting" },
    { value: "참가등록", key: "complete" },
    { value: "불참(적)", key: "not_attend_a" },
    { value: "불참(부)", key: "not_attend_n" },
];

/**
 * 진행 상태
 */
export const progressData = [
    DefaultAll,
    { value: "접수전", key: "GENERATED" },
    { value: "접수중", key: "APPLY" },
    { value: "접수마감", key: "APPLY_END" },
    { value: "선정", key: "DRAW" },
    { value: "자리배치", key: "PLACEMENT" },
    { value: "참가신청", key: "PARTICIPATION" },
    { value: "참가마감", key: "COMPLETE_APPLY" },
    { value: "대회종료", key: "CONTEST_END" },
];

/**
 * 진행 상태
 */
export const progressDataDefaultAll = [
    { value: "접수전", key: "GENERATED" },
    // { value: "접수중", key: "APPLY" },
    // { value: "접수마감", key: "APPLY_END" },
    { value: "선정", key: "DRAW" },
    // { value: "선정 실패", key: "DRAW_FAIL" },
    { value: "자리 배치 완료", key: "PLACEMENT" },
    // { value: "참가신청", key: "START_FREE_PAYMENT" },
    // { value: "결제 시작", key: "START_PAYMENT" },
    // { value: "1차 결제 예비 마감", key: "FIRST_PAYMENT_END" },
    // { value: "1차 결제 마감", key: "SECOND_PAYMENT_END" },
    // { value: "2차 결제 마감", key: "THIRD_PAYMENT_END" },
    // { value: "참가마감", key: "COMPLETE_APPLY" },
    { value: "대회종료", key: "CONTEST_END" },
];

/**
 * 고객 상태
 */
export const isPaymentData = [DefaultAll, { value: "결제완료", key: "Y" }, { value: "미결제", key: "N" }, { value: "결제취소", key: "R" }];

/**
 * 발송 상태
 */
export const sendData = [DefaultAll, { value: "수동발송", key: "OFF" }, { value: "자동발송", key: "AUTO" }];

/**
 * 카드 정보
 */
export const cardData = [
    DefaultAll,
    { value: "우리", key: "우리" },
    { value: "비씨", key: "비씨" },
    { value: "현대", key: "현대" },
    { value: "신한", key: "신한" },
    { value: "국민", key: "국민" },
];

/**
 * 결제 방식(전체가 없는 selectBox)
 */
export const applyPaymentTypeData = [
    { value: "PG결제", key: "CARD" },
    { value: "무통장입금", key: "DEPOSIT" },
];

/**
 * 고객 상태(전체가 없는 selectBox)
 */
export const applyStateData = [
    { value: "접수", key: "applied", disabled: false },
    { value: "접수취소", key: "cancel_apply", disabled: false },
    { value: "선정", key: "chosen", disabled: false },
    { value: "대기", key: "waiting", disabled: false },
    { value: "비선정", key: "not_chosen", disabled: false },
    { value: "선정1", key: "chosen_1", disabled: false },
    { value: "선정9", key: "chosen_9", disabled: false },
    { value: "참가취소", key: "cancel", disabled: false },
    { value: "참가취소(미결제)", key: "cancel_not_pay", disabled: false },
    { value: "대기취소", key: "cancel_waiting", disabled: false },
    { value: "참가등록", key: "complete", disabled: false },
    { value: "불참(적)", key: "not_attend_a", disabled: false },
    { value: "불참(부)", key: "not_attend_n", disabled: false },
];

/**
 * 고객 상태(전체가 없는 selectBox)
 */
export const applyIsPaymentData = [
    { value: "결제완료", key: "Y" },
    { value: "미결제", key: "N" },
    { value: "결제취소", key: "R" },
];

/**
 * 대회 타입 -> 대회명 조회
 */
export const DefaultContestNameSelectBox = {
    value: "대회명",
    key: null,
    disabled: false,
};

/**
 * 지역명 -> 경기장(센터명) 조회
 */
export const DefaultCenterNameSelectBox = {
    value: "경기장",
    key: null,
    disabled: false,
};

/**
 * 대회 진행상태 디폴트
 */
export const DefaultProcessTypeNameSelectBox = {
    value: "진행 상태",
    key: null,
    disabled: false,
};

/**
 * 일반 사용자 : 대회 접수 시 조, 장애여부, 항목명 선택 디폴트
 */
export const DefaultGroupNameSelectBox = {
    value: "선택",
    key: "",
    disabled: false,
};

/**
 * 일반 사용자 : 대회 접수 시 조, 항목명 디폴트
 */
export const DefaultRegionSelectBox = {
    value: "전체 지역",
    key: "",
    disabled: false,
};

/**
 * 일반 사용자 : 대회 접수 시 장애여부 디폴트
 */

export const DefaultDisableSelectBox = [
    { value: "선택", key: "", disabled: false },
    { value: "해당없음", key: "N", disabled: false },
    { value: "장애중증(3급)이상", key: "Y", disabled: false },
];
export const DefaultDisableSelectBox2 = [
    { value: "해당없음", key: "N", disabled: false },
    { value: "장애중증(3급)이상", key: "Y", disabled: false },
];
/**
 * 경기명 Default 박스
 */
export const DefaultRoundNameSelectBox = {
    value: "경기 전체",
    key: null,
    disabled: false,
};

export const listData = [
    { value: "선택형", key: "선택형" },
    { value: "입력형", key: "입력형" },
];

/**
 * 대회결과 등록/조회 페이지 검색구분 Default
 */
export const DefaultSearchConditionSelectBox = [
    { value: "전체", key: null, disabled: false },
    { value: "이름", key: "memberName", disabled: false },
    { value: "ID", key: "memberId", disabled: false },
];

/**
 * ADMIN - BO - 회원관리 - 서면 회원가입 회원처리상태
 */
export const DefaultDocMemberProcessSelectBox = [
    { value: "전체", key: null, disabled: false },
    { value: "접수", key: "REQUEST", disabled: false },
    { value: "승인완료", key: "ACCEPT", disabled: false },
    { value: "승인불가", key: "REJECT", disabled: false },
];

/**
 * ADMIN - BO - 회원관리 - 회원검색조건
 */
export const DefaultMemberProcessSelectBox = [
    { value: "전체", key: null, disabled: false },
    { value: "아이디", key: "member_id", disabled: false },
    { value: "이름", key: "member_name", disabled: false },
    { value: "휴대폰", key: "member_phone", disabled: false },
];

export const termsType = [
    { value: "개인정보처리방침", key: "Terms" },
    { value: "이용약관", key: "Privacy" },
];

export const termsAgreementType = [
    { value: "회원약관동의", key: "ConsentTerms" },
    { value: "개인정보 수집에 대한 동의", key: "ConsentCollectPrivacy" },
    { value: "개인정보 제공 및 이용 동의", key: "ConsentUsePrivacy" },
    { value: "회원탈퇴 동의", key: "ConsentUserWithdraw" },
];
/**
 * 게재관리 페이지 진행 상태
 */
export const processStatusDefaultAllPublish = [
    { value: "전체", key: "", disabled: false },
    { value: "접수 전", key: "GENERATED", disabled: false },
    { value: "접수 중", key: "APPLY", disabled: false },
    { value: "접수 마감", key: "APPLY_END", disabled: false },
    { value: "선정", key: "DRAW", disabled: false },
    { value: "자리 배치", key: "PLACEMENT", disabled: false },
    {
        value: "참가 신청",
        key: "START_FREE_PAYMENT,START_PAYMENT,FIRST_PAYMENT_END,SECOND_PAYMENT_END,THIRD_PAYMENT_END",
        disabled: false,
    },
    { value: "참가 마감", key: "COMPLETE_APPLY", disabled: false },
    { value: "대회 종료", key: "CONTEST_END", disabled: false },
];

/**
 * 핸드폰 앞자리 셀렉트 박스
 */
export const phoneNumberDefaultBox = [
    { key: "010", value: "010", disabled: false },
    { key: "011", value: "011", disabled: false },
    { key: "016", value: "016", disabled: false },
    { key: "018", value: "018", disabled: false },
    { key: "019", value: "019", disabled: false },
];

/**
 * 마이페이지 1:1문의, AS 신청
 */
export const mypageCsDefaultBox = [
    { value: "1:1 문의", key: "cs", disabled: false },
    { value: "AS 신청", key: "as", disabled: false },
];

/**
 * BO - 커뮤니티 - AS 분류 디폴트박스
 */
export const DefaultASTypeSelectBox = [
    { value: "전체", key: null, disabled: false },
    { value: "볼링 공", key: "Ball", disabled: false },
    { value: "볼링 신발", key: "Shoes", disabled: false },
    { value: "볼링 가방", key: "Bag", disabled: false },
    { value: "의류", key: "Cloth", disabled: false },
    { value: "기타 용품", key: "Other", disabled: false },
];

/**
 * BO - 커뮤니티 - 1:1문의 ,AS 진행타입 디폴트박스
 */
export const DefaultProcessSelectBox = [
    { value: "전체", key: null, disabled: false },
    { value: "접수", key: "Request", disabled: false },
    { value: "답변 완료", key: "Close", disabled: false },
];

/**
 * BO - 커뮤니티 - AS 검색구분 디폴트박스
 */
export const DefaultASQueryTypeSelectBox = [
    { value: "전체", key: "All", disabled: false },
    { value: "작성자", key: "Name", disabled: false },
    { value: "질문", key: "title", disabled: false },
];

/**
 * BO - 커뮤니티 - 1:1문의 문의항목 디폴트박스
 */
export const DefaultHelpSupporteSelectBox = [
    { value: "전체", key: null, disabled: false },
    { value: "회원정보 문의", key: "Member", disabled: false },
    { value: "대회 관련", key: "Contest", disabled: false },
    { value: "상품 관련", key: "Product", disabled: false },
    { value: "기타 문의", key: "Etc", disabled: false },
];

/**
 * ADMIN - BO - 회원관리 - 회원검색조건
 */
export const DefaultMemberInfoQueryType = [
    { value: "전체", key: null, disabled: false },
    { value: "아이디", key: "id", disabled: false },
    { value: "이름", key: "name", disabled: false },
    { value: "휴대폰", key: "phone", disabled: false },
];

/**
 * ADMIN - BO - 회원관리 - 회원정보 변경 접수 - 상태 디폴트 리스트
 */
export const DefaultMemberInfoChagneSelectBox = [
    { value: "전체", key: null, disabled: false },
    { value: "접수", key: "Request", disabled: false },
    { value: "처리 완료", key: "Close", disabled: false },
    { value: "승인 불가", key: "Reject", disabled: false },
];

/**
 * ADMIN - BO - 회원관리 - 회원활동상태 수정용
 * 셀렉트 박스
 */
export const DefaultMemberStatusSelectBox = [
    { value: "활동", key: "N", disabled: false },
    { value: "휴면", key: "D", disabled: false },
    { value: "탈퇴", key: "Y", disabled: false },
];

/**
 * ADMIN - BO - 회원관리 - 회원활동상태 검색용
 * 셀렉트박스
 */
export const DefaultMemberStatusSearchSelectBox = [
    { value: "전체", key: null, disabled: false },
    { value: "활동", key: "normal", disabled: false },
    { value: "휴면", key: "dormant", disabled: false },
    { value: "탈퇴", key: "withdraw", disabled: false },
];

export const faqType = [
    { value: "선택", key: null },
    { value: "회원가입/정보", key: "faqMember" },
    { value: "상품 A/S", key: "faqAs" },
    { value: "상품 문의", key: "faqProduct" },
    { value: "대회 문의", key: "faqContest" },
];

export const exposeYnType = [DefaultAll, { value: "사용", key: "Y" }, { value: "미사용", key: "N" }];

export const ContestFaqSearchTypes = [DefaultAll, { value: "질문", key: "question" }, { value: "작성자", key: "regId" }];

export const defaultEmailHostList = [
    { key: "", value: "직접입력", disabled: false },
    { key: "naver.com", value: "naver.com", disabled: false },
    { key: "gmail.com", value: "gmail.com", disabled: false },
    { key: "hanmail.net", value: "hanmail.net", disabled: false },
    { key: "daum.net", value: "daum.net", disabled: false },
    { key: "hotmail.com", value: "hotmail.com", disabled: false },
];

export const adminStatusType = [
    { key: "N", value: "사용", disabled: false },
    { key: "Y", value: "사용중지", disabled: false },
];

export const adminStatusTypes = [
    DefaultAll,
    { key: "N", value: "사용", disabled: false },
    { key: "Y", value: "사용중지", disabled: false },
];

export const adminAccountSearchTypes = [
    DefaultAll,
    { key: "member_id", value: "아이디", disabled: false },
    { key: "member_name", value: "관리자명", disabled: false },
    { key: "member_phone", value: "연락처", disabled: false },
];

export const regionType = [
    DefaultAll,
    { value: "서울", key: "서울" },
    { value: "경기", key: "경기" },
    { value: "인천", key: "인천" },
    { value: "충북", key: "충북" },
    { value: "충남", key: "충남" },
    { value: "대전", key: "대전" },
    { value: "경북", key: "경북" },
    { value: "경남", key: "경남" },
    { value: "대구", key: "대구" },
    { value: "울산", key: "울산" },
    { value: "부산", key: "부산" },
    { value: "전북", key: "전북" },
    { value: "전남", key: "전남" },
    { value: "광주", key: "광주" },
    { value: "강원", key: "강원" },
    { value: "제주", key: "제주" },
];

export const staffDataType = [DefaultAll, { value: "프로스탭", key: "professional" }, { value: "아마추어스탭", key: "amateur" }];

export const noneAllstaffDataType = [
    { value: "프로스탭", key: "professional" },
    { value: "아마추어스탭", key: "amateur" },
];

export const productCategoryType = [
    { value: "선택", key: null },
    { value: "볼링볼", key: "100" },
    { value: "볼링백", key: "200" },
    { value: "볼링의류", key: "300" },
    { value: "볼링화", key: "400" },
    { value: "볼링용품", key: "500" },
];

export const productCategoryTypes = [
    { value: "볼링볼", key: "100" },
    { value: "볼링백", key: "200" },
    { value: "볼링의류", key: "300" },
    { value: "볼링화", key: "400" },
    { value: "볼링용품", key: "500" },
];

export const itemFilterType = [
    { value: "선택", key: null },
    { value: "스마트필터", key: "smartFilter" },
    { value: "상품정보", key: "extInformation" },
];

export const dropDownType = [
    { value: "선택", key: "0" },
    { value: "1항목", key: "1" },
    { value: "2항목", key: "2" },
    { value: "3항목", key: "3" },
];

export const DefaultNonePrintType = [
    { value: "이름순", key: "name" },
    { value: "레인순", key: "lane" },
];

/**
 * FO - 마이페이지 - 사용구 등록/수정 볼링볼 브랜드
 */
export const DefaultBallBrandList = [
    { value: "선택", key: null, disabled: false },
    { value: "STORM", key: "STORM", disabled: false },
    { value: "Roto Grip", key: "Roto Grip", disabled: false },
    { value: "900 Global", key: "900 Global", disabled: false },
];

export const DefaultIsRegisterBallBox = [
    { value: "전체", key: "" },
    { value: "등록", key: "Y" },
    { value: "미등록", key: "N" },
];

export const DefaultSerialQueryType = [
    { value: "전체", key: "all" },
    { value: "모델명", key: "ball_name" },
    { value: "시리얼넘버", key: "serial_number" },
];

export const tabType = [
    { value: "1", key: "1" },
    { value: "2", key: "2" },
    { value: "3", key: "3" },
];

export const DefaultExposeSelectBox = [
    { value: "전체", key: "", disabled: false },
    { value: "노출", key: "Y", disabled: false },
    { value: "미노출", key: "N", disabled: false },
];

export const DefaultEventQueryTypeSelectBox = [
    { value: "전체", key: "", disabled: false },
    { value: "아이디", key: "queryID", disabled: false },
    { value: "이름", key: "queryName", disabled: false },
];

export const DefaultEventChosenSelectBox = [
    { value: "전체", key: "", disabled: false },
    { value: "당첨", key: "Y", disabled: false },
    { value: "미당첨", key: "N", disabled: false },
];

export const DefaultRoundName = [
    { value: "경기명 선택", key: "", disabled: false },
];
