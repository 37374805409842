import { useEffect, useState } from "react";
import { ProductCategory } from "../../../api/product/CategoryApi";
import Button from "../../../components/UI/Button/Button";
import SelectBox from "../../../components/UI/SelectBox/SelectBox";
import classes from "../MngCommon.module.css";
import { DefaultAll, DefaultSelect } from "../../../components/definedefault/DefSelectBoxValues";
import SelectBoxNew from "../../../components/UI/SelectBox/SelectBoxNew";
import instance from "../../../store/AxiosInterceptor";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Alert from "../../../components/UI/Modal/Alert";
function MngProductDisplay(props) {

  const [categoryMap, setCategoryMap] = useState([DefaultSelect]);
  const [type, setType] = useState(null);
  const [typeName, setTypeName] = useState(null);

  const [productDisplay, setProductDisplay] = useState([]);
  const [productDisplayFlag, setProductDisplayFlag] = useState(false);

  // 카테고리 select box
  const typeHandler = (key) => {
      setType(key);
      const types = categoryMap.find((items) => items.key === key);
      setTypeName(types === undefined ? "" : types.value);
      setProductDisplayFlag(false)
      setProductDisplay([])
  };

  // 카테고리
  useEffect(() => {
    ProductCategory().then((res) => {
        if (res.status === 200) {
            const resData = res.data.datas;
            const contestTypeDatas = resData.map((data) => {
              return { key: String(data.id), value: data.name };
            });
            setCategoryMap((prev) => [DefaultSelect, ...contestTypeDatas]);
        }
    });
  }, []);

  const displayHandler = async () => {
    if(type != null) {
      const response = await instance({
          method: "GET",
          url: `/api/v1/admin/product/display/list?category_id=${type}`,
          headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
          },
      })

      if (response.status === 200) {
        const resData = await response.data.datas;
        setProductDisplay(resData);
        setProductDisplayFlag(true);
      } else {
        setProductDisplay([]);
        setProductDisplayFlag(true);
      }
    }
  };

    //>>> 드래그 &  드랍 저장 메소드
    const handleOnDragEnd = (result) => {
      if (!result.destination) return;

      const items = Array.from(productDisplay);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);

      items.forEach((data, index) => (data.priority = index + 1));
      setProductDisplay(items);
  };
  //<<< 드래그 &  드랍 저장 메소드

  const [showConfirmation, setShowConfirmation] = useState(false); //모달 Alert 상태관리
    const confirmChangeHandler = () => {
        setShowConfirmation(false);
        changeHandler();
    };

    const changeHandler = async () => {
      const datas = productDisplay.map((item, index) => ({
        id: item.id,
        priority: index + 1,
      }));

      const response = await instance({
        method: "PUT",
        url: "/api/v1/admin/product/display/move",
        data: {
            datas: datas,
        },
      })

      if (response.status === 200) {
        alert("완료되었습니다.")
      } else {
        alert("서버 통신 에러");
      }
  };

  return (
    <section>
      <div className={classes.admin_contents}>
        <h3>상품전시관리</h3>
        <div className={`${classes.contents_wrap} ${classes.search_wrap}`}>
          <div className={classes.admin_table_wrap}>
            <table>
              <colgroup>
                <col style={{ width: "11%" }} />
                <col style={{ width: "59%" }} />
                <col style={{ width: "11%" }} />
                <col style={{ width: "19%" }} />
              </colgroup>
              <tbody>
                <tr>
                  <th>카테고리</th>
                  <td colSpan={3}>
                    <div className={`${classes.flex_row} ${classes.multi}`}>
                      <SelectBoxNew
                          className="admin_st"
                          setSelectItemCB={typeHandler}
                          optionDatas={categoryMap}
                          showDefaultKey={type}
                      />
                      <Button className="border sm" onClick={displayHandler} >선택</Button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <p className={classes.desc_gray}>
            * 상품 카테고리 선택 후 드래그앤 드롭으로 상품 노출 순서 변경
            가능합니다.
          </p>
        </div>
        {productDisplayFlag && (
          
          <div className={`${classes.contents_wrap} `}>
              <p className={classes.counter}>
                {typeName} (<b>{productDisplay.length}</b>)
              </p>
              <div className={`${classes.dnd_table_wrap} ${classes.product_st}`}>
                <ul className={classes.header}>
                  <li>순서변경</li>
                  <li>노출순서</li>
                  <li>중 카테고리</li>
                  <li>상품명</li>
                  <li>등록일</li>
                </ul>
                {productDisplay.length != 0 ? (
                  <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId={classes.list_wrap}>
                        {(provided) => (
                            <div
                                className={classes.list_wrap}
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                {productDisplay.length !== 0 && (productDisplay.map((data, index) => {
                                        return (
                                            <Draggable
                                                key={`display${data.id}`}
                                                draggableId={`display${data.id}`}
                                                index={index}
                                            >
                                                {(provided, snapshot) => (
                                                    <ul
                                                        className={`${classes.list} ${
                                                            snapshot.isDragging ? classes.on : ""
                                                        }`}
                                                        key={data.id}
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                    >
                                                      <li className={classes.btn_move}></li>
                                                      <li>{index+1}</li>
                                                      <li>{data.hierarchyCategoryStr}</li>
                                                      <li>{data.name}</li>
                                                      <li>{data.regDate}</li>
                                                    </ul>
                                                )}
                                            </Draggable>
                                        );
                                    }
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
                ) : (
                  <ul>
                    <li>등록된 상품이 없습니다.</li>
                  </ul>
                )}
              </div>
              {productDisplay.length != 0 && (
                <div className={classes.bottom_btn_wrap}>
                  <Button className="border sm" onClick={displayHandler}>초기화</Button>
                  <Button className="secondary sm" onClick={() => setShowConfirmation(true)}>순서 변경 적용</Button>
                </div>
              )}
            </div>          
        )}
      </div>
      {showConfirmation && (
                <Alert
                    type="confirm"
                    cancel="취소"
                    confirm="확인"
                    close={() => setShowConfirmation(false)} // 모달 닫기
                    confirmHandler={confirmChangeHandler} // 확인 버튼 눌렀을 때 실행되는 Handler
                >
                    {"순서를 변경 하시겠습니까?"}
                </Alert>
            )}
    </section>
  );
}

export default MngProductDisplay;
