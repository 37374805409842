import { useEffect, useState } from "react";
import { ReqRecommendProductList } from "../../../api/site/ApiForRecommendProduct";
import Button from "../../../components/UI/Button/Button";
import Pagination from "../../../components/UI/Table/Pagination";
import classes from "../MngCommon.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import PaginationNew from "../../../components/UI/Table/PaginationNew";
import Moment from 'moment';

function MngRecommendProduct(props) {

    const location = useLocation();
    const navigate = useNavigate();
    
    const [recommendProducts, setRecommendProducts] = useState([]);
    
    const [currentPage, setCurrentPage] = useState(location.state?.currentPage ?? 0);
    const [totalPage, setTotalPage] = useState(0); //총 페이지 갯수
    const [totalCount, setTotalCount] = useState(0); //총 페이지 갯수
    
    const createHandler = () => {
        navigate("./create");
    };

    const detailHandler = (e, id) => {
        e.preventDefault();

        navigate(`./${id}`, { state: { currentPage } });
    };

    const handlePageChange = async (page) => {
        setCurrentPage(page);
    
        const response = await ReqRecommendProductList(page);
        if (response.status === 200) {
            const resData = await response.data.data;
            setTotalPage(resData.totalPages);
            setTotalCount(resData.totalElements);
            setRecommendProducts(resData.content);
        } else {
            setRecommendProducts([]);
        }
    };
    useEffect(() => {
        handlePageChange(currentPage);
    }, [])
    return (
        <section>
                <div className={classes.admin_contents}>
                    <h3>추천상품</h3>
                    <div className={classes.contents_wrap}>
                        <div
                            className={`${classes.admin_table_wrap} ${classes.align_center}  ${classes.row_select} ${classes.left_col_3}`}
                        >
                            <table>
                                <colgroup>
                                    <col style={{ width: "5%" }} />
                                    <col style={{ width: "10%" }} />
                                    <col style={{ width: "40%" }} />
                                    <col style={{ width: "15%" }} />
                                    <col style={{ width: "15%" }} />
                                    <col style={{ width: "15%" }} />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th>No</th>
                                        <th>노출 탭</th>
                                        <th>탭명</th>
                                        <th>등록일</th>
                                        <th>등록자</th>
                                        <th>노출여부</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {recommendProducts.length !== 0 && recommendProducts.map((data, index) => (
                                        <tr onClick={(e) => detailHandler(e, data.id)}>
                                            <td>{data.seq}</td>
                                            <td>
                                                {data.tabSeq}
                                            </td>
                                            <td>{data.title}</td>
                                            <td>{Moment(data.regDate).format('YYYY-MM-DD')}</td>
                                            <td>{data.regID}</td>
                                            <td>{data.exposeYn === "Y" ? "노출" : "미노출"}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <PaginationNew className="admin_st" totalPage={totalPage} currentPage={currentPage} defaultShowPage={5} changePageCB={handlePageChange} />
                            <div
                                className={`${classes.bottom_btn_wrap} ${classes.position_st}`}
                            >
                                <Button
                                className="secondary sm" onClick={createHandler}
                                >
                                    등록
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
        </section>
    );
}

export default MngRecommendProduct;

export const loader = async () => {
    const response = await ReqRecommendProductList(0);
    
    if (response.status === 200) {
        const resData = await response.data.data;
        return { data: resData.content, totalPage: resData.totalPages, totalCount : resData.totalElements };
    } else {
        return { data: [], totalPage: 0, totalCount : 0 };
    }
};