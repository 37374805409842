import React from "react";
import { Link } from "react-router-dom";
import classes from "../../TournamentContainerApp.module.css";
import Button from "../../../../components/UI/Button/Button";

const TournamentResultAppComponent = (props) => {
    let newCtType = "";
    switch (props.ctType) {
        case "K":
            newCtType = classes.type_a;
            break;
        case "L":
            newCtType = classes.type_b;
            break;
        case "A":
            newCtType = classes.type_c;
            break;
        case "B":
            newCtType = classes.type_d;
            break;
        case "S":
            newCtType = classes.type_d;
            break;
    }
    return (
        <div className={classes.card}>
        <div className={classes.title}>
            <b className={newCtType}>{props.ctTypeTitle}</b>
            <h3>{props.ctTitle}</h3>
        </div>
        <ul className={classes.info}>
            <li>
                <span>대회기간</span>{props.ctStartDate} ~ {props.ctEndDate}
            </li>
            <li>
                <span>대회지역</span>
                {props.regionList.join("/")}
            </li>
        </ul>
        <Button className="secondary">
                <Link to={`${props.ctId}`}>대회 결과 보기</Link>
        </Button>
    </div>

    );
};

export default TournamentResultAppComponent;
