/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-key */
import React, { useEffect, useMemo } from "react";
import Button from "../../../components/UI/Button/Button";
import Input from "../../../components/UI/Input/Input";
import classes from "../MngCommon.module.css";
import Radio from "../../../components/UI/Input/Radio";
import DatePickerBox from "../../../components/UI/DatePicker/DatePickerBox";

import _ from "lodash";
import QuillEditor from "../../../components/UI/Editer/QuillEditor";
import MyDropzoneMj from "../../../components/UI/DropZone/DropZone copy";
import { ProductSelectedCategoryInsert } from "../../../api/product/CategorySelectApi";
import SelectBoxNew from "../../../components/UI/SelectBox/SelectBoxNew";
import { useState } from "react";
import Modal from "../../../components/UI/Modal/Modal";
import { ProductCategory, ProductSmartFilter } from "../../../api/product/CategoryApi";

const initInsertSelectedData = {
  name: "",
  subName: "",
  categoryId: -1,
  seq: 1,
  exposeYn: "Y",
  catalogue_ball: "N",
  releaseDate: new Date(Date.now()),
  purchaseUrl: "",
  pageUri: "",
  description: "",
  readCnt: 0,
  // "reqAddProductDetailSFilterList" : [],
  // "reqAddProductDetailExtInformationList" : []
};

function MngProductCRUDPage({
   isEdit = false, insertData = {}, insertDataHandler = () => {}, getCategoryPath = () => {}, images = [], imageUpload=() => {},
   detailData = {}, thumbnailImage = null, handleModeChange = () => {}, smartFilterMenus = [], extInformationMenus = []
  }) {

  const [smartFilterMenuss, setSmartFilterMenus] = useState(smartFilterMenus);
  const [extInformationMenuss, setExtInformationMenus] = useState(extInformationMenus);

  const [smartFilterSeletedInfo, setSmartFilterSeletedInfo] = useState({});
  const [deleteFilter, setDeleteFilter] = useState([]);
  const setSmartFilterSeletedInfoHandler = (pId, id) => {
    setSmartFilterSeletedInfo({...smartFilterSeletedInfo, [pId]: id})
  };
  useEffect(() => {
    //console.log({ isEdit, insertData, detailData });
    try {
      const infoMapBasic = {};
      smartFilterMenuss.forEach((item) => {
        if(isEdit){
          detailData?.smartFilterMenuSelecteds?.forEach((item) => { 
            (item?.smartFilterItemSelecteds ?? []).forEach((sItem, idx) => {
              infoMapBasic[item?.tabSize === 1 || idx === 0 ? item?.id : `${item?.id}_${idx}`] = sItem?.id
            });
          });
        }else{
           Array(item?.tabSize).fill(0)
           .forEach((_v, idx) => { 
            infoMapBasic[idx === 0 ? item?.id : `${item?.id}_${idx}`] = 
            (item.tabSize === 1 ? item?.smartFilterItems : item?.smartFilterItems?.filter((v) => v.tab === (1+idx)))?.at(0)?.id });
        }

      });
      setSmartFilterSeletedInfo(infoMapBasic);
    } catch (error) {
      //console.log(error);
      alert(error.message)      
    }
  }, [smartFilterMenuss]);
  const [extInformationMenuInserted, setExtInformationMenuInserted] = useState({});
  const setExtInfoDescriptionHandler = (id, des) => {
    setExtInformationMenuInserted({...extInformationMenuInserted, [id]: des});
  }
  useEffect(() => {
    try {
      const infoMapBasic = {};
      if(isEdit){
        (detailData?.extInformationMenuSelecteds ?? []).forEach(({ id, description }) => { infoMapBasic[id] = description ?? '' });
      }else{
        (detailData?.extInformationMenuSelecteds ?? []).forEach(({ id }) => { infoMapBasic[id] = '' });
      }
      setExtInformationMenuInserted(infoMapBasic);
    } catch (error) {
      //console.log(error);
      alert(error.message)
    }
  }, [detailData?.extInformationMenuSelecteds]);
  const SmartFilterItems = ({ drop= true, tabSize = 1, smartFilterItems = [],  id: parentId }) => {
    if(smartFilterItems.length === 0) return <></>;
    let view = <></>;
    if(drop){
      if(tabSize === 1){
      view =  <SelectBoxNew 
                className='admin_st w100' 
                optionDatas={smartFilterItems.map(({ id, name }) => ({key: id, value: name}))}
                setSelectItemCB={(key) => { 
                  setSmartFilterSeletedInfoHandler(parentId, key);
                  if(isEdit && !deleteFilter.includes(Number(key))){
                    setDeleteFilter([...deleteFilter, Number(key)])
                  } 
                }}
                showDefaultKey={Number(smartFilterSeletedInfo[parentId])}
              />; 
         
      }else if(tabSize > 1) {
       const splitData = [];
        Array(tabSize).fill(0).forEach(() => {splitData.push([])});
       
       smartFilterItems.forEach((item) => {
        splitData[item.tab - 1].push(item);
      });
       view = <div className={`${classes.flex_row} ${classes.multi}`}> 
            {splitData.map((unit, idx) => { 
            return <SelectBoxNew 
              className='admin_st' 
              key={`${JSON.stringify(unit)}`} 
              optionDatas={unit.map(({id,name}) => ({key: id, value: name}))}
              setSelectItemCB={(key) => setSmartFilterSeletedInfoHandler(idx === 0 ? parentId : `${parentId}_${idx}`, key)}
              showDefaultKey={Number(smartFilterSeletedInfo[idx === 0 ? parentId : `${parentId}_${idx}`])}
            />})}
            
         </div>;
      }
    }else{
      view = smartFilterItems.map(({id, name}) => (
      <Radio 
        id={`${id}`}
        key={`${id}`} 
        value={name} 
        title={name} 
        disabled={false} 
        checked={id.toString() === smartFilterSeletedInfo[parentId]?.toString()} 
        onChange={({target}) => setSmartFilterSeletedInfoHandler(parentId, target.id)}
        />));
      view = <div className={classes.radio_wrap}> {view} </div>;
    }
    return view;
}
  useEffect(() => {
    //console.log({ insertData })
  }, [insertData]);

  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const openModalHandler1 = (e) => {
      e.stopPropagation();
      setIsModalOpen1(true);
  };
  const closeModalHandler1 = (e) => {
      e.stopPropagation();
      setIsModalOpen1(false);
      setAdderCategory([-1, -1, -1]);
  };

  const closeModalHandler2 = (e, id) => {
    e.stopPropagation();
    setAdderCategory(id);
    getCategoryPaths(id);
    setIsModalOpen1(false);

    const cId = id.filter((val) => !_.isNaN(Number(val)) && val > 0).at(-1);
    ProductSmartFilter(cId).then((res) => {
        if (
            res.status === 200 &&
            _.isObject(res.data?.data)
        ) {
            setSmartFilterMenus(
                res.data?.data?.smartFilterMenus ?? []
            );
            setExtInformationMenus(
                res.data?.data?.extInformationMenus ?? []
            );
        }
        return res;
      })
};

  const [categoryMap, setCategoryMap] = useState({});
  const [loadCategory, setLoadCategory] = useState(true);
  const [adderCategoryPath, setAdderCategory] = useState([]);

  const toMap = (data = []) => {
    let rs = {};
    data.forEach((item) => {
        if (item?.id) {
            rs[item.id] = item;
            if (_.isArray(item?.children) && item.children.length > 0) {
                rs = { ...rs, ...toMap(item.children) };
            }
        }
    });
    return rs;
  };

  const adderCategoryPathHandler = (id, depth = 0) => {
    if (_.isNaN(Number(id))) return;
    const _id = Number(id);
    const pathTemp = [...adderCategoryPath];
    const othersTemp = Array(2 - depth).fill(-1);
    othersTemp.unshift(_id);
    pathTemp.splice(depth, 3 - depth, ...othersTemp);
    setAdderCategory(pathTemp);
  };

  useEffect(() => {
    if (!loadCategory) return;
    ProductCategory().then((res) => {
        if (_.isArray(res.data?.datas) && res.status === 200) {
            //console.log(res.data?.datas)
            const map = toMap(res.data.datas);
            setCategoryMap(map);
            setLoadCategory(false);
        }
    });
  }, [loadCategory]);

  // const selectedAdderCategoryHasChildren = useMemo(() => {
  //   const nowPath = adderCategoryPath.filter((path) => path >= 0);
  //   console.log(nowPath)
  //   if (nowPath.length === 0 || Object.keys(categoryMap).length === 0)
  //       return false;
  //   const lastPath = nowPath.at(-1);
  //   return ((categoryMap[lastPath] ?? {}).children ?? []).length > 0;
  // }, [adderCategoryPath, categoryMap]);

  const getCategoryPaths = (categoryId) => {
    let nowData = categoryMap[Number(categoryId)];
    const rs = [nowData];
    do {
        try {
            nowData = categoryMap[nowData["parent_id"]];
            rs.unshift(nowData);
        } catch (error) {
            break;
        }
    } while (
        nowData &&
        _.isNumber(nowData["parent_id"]) &&
        nowData["parent_id"] >= 0
    );

    return rs;
};

  return (
    <div className={classes.admin_contents}>
          <h3>상품상세 - {isEdit ? "수정" : "등록"}</h3>
          <div className={`${classes.contents_wrap} ${classes.search_wrap} `}>
            <div className={classes.admin_table_wrap}>
              <table>
                <colgroup>
                  <col style={{ width: "159px" }} />
                  <col style={{ width: "620px" }} />
                  <col style={{ width: "159px" }} />
                  <col style={{ width: "620px" }} />
                </colgroup>
                <tbody>
                  {isEdit && (<tr>
                    <th>등록일</th>
                    <td>{insertData?.regDate}</td>
                    <th>등록자</th>
                    <td>{insertData?.regID}</td>
                  </tr>)}
                  <tr>
                    <th>노출여부</th>
                    <td colSpan={3}>
                      <div className={classes.radio_wrap}>
                        <Radio
                          value="노출"
                          title="노출"
                          name="rad_01"
                          id="exposeYes"
                          onChange={() => insertDataHandler('exposeYn', 'Y')}
                          checked={insertData?.exposeYn === 'Y' || insertData?.exposeYn === true}
                          />
                        <Radio
                          value="미노출"
                          title="미노출"
                          name="rad_01"
                          id="exposeNo"
                          onChange={() => insertDataHandler('exposeYn', 'N')}
                          checked={insertData?.exposeYn === 'N'  || insertData?.exposeYn === false}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>카테고리</th>
                    <td colSpan={3}>
                      {getCategoryPath(adderCategoryPath.length != 0 ? (adderCategoryPath[2] != -1 ? adderCategoryPath[2] : adderCategoryPath[1]) : insertData?.categoryId).filter((item) => item && item?.name).map(({name}) => name).join('>')}
                      &nbsp;
                      {isEdit && (
                        <Button
                          className="admin_st_sm sm"
                          onClick={openModalHandler1}
                        >
                          변경하기
                        </Button>
                      )}
                      
                    </td>
                  </tr>
                  <tr>
                    <th>상품명</th>
                    <td colSpan={3}>
                      <div className={`${classes.flex_row} ${classes.multi}`}>
                        <Input
                          className="admin_st_sm"
                          placeholder="상품명 입력"
                          onChange={(name) => insertDataHandler('name', name)}
                          value={insertData?.name}
                        />
                        <Input
                          className="admin_st_sm"
                          placeholder="Sub 상품명 입력"
                          onChange={(subName) => insertDataHandler('subName', subName)}
                          value={insertData?.subName}
                          />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>출시일</th>
                    <td colSpan={3}>
                      <DatePickerBox
                        value = {insertData?.releaseDate}
                        onChange={(date) => {
                          insertDataHandler('releaseDate', date);
                          }
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>카탈로그 Ball</th>
                    <td colSpan={3}>
                      <div className={classes.radio_wrap}>
                        <Radio
                          value="해당"
                          title="해당"
                          name="rad_02"
                          id="rad_02_01"
                          checked={insertData['catalogue_ball'] === 'Y'}
                          onChange={() => insertDataHandler('catalogue_ball', 'Y')}
                          />
                        <Radio
                          value="미해당"
                          title="미해당"
                          name="rad_02"
                          id="rad_02_0"
                          checked={insertData['catalogue_ball'] === 'N' || !insertData['catalogue_ball']}
                          onChange={() => insertDataHandler('catalogue_ball', 'N')}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>상품이미지</th>
                    <td colSpan={3}>
                      {/* <div className={classes.file_list_wrap}>
                        <FileBox 
                          onFileInputChange={setThumbnailImage}
                          multiple 
                        />
                      </div> */}
                      <div>
                      <MyDropzoneMj
                         images={images}
                         onImageUpload={imageUpload}
                         onImageUploadL={(acceptedFiles) => imageUpload([...images, ...acceptedFiles])}
                      />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>구매하기 URL</th>
                    <td colSpan={3}>
                      <Input
                        className="admin_st"
                        value={insertData?.purchaseUrl}
                        boxName='purchaseUrl'
                        onChange={(value, keyName) => insertDataHandler(keyName,value)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>사용자페이지 URL</th>
                    <td colSpan={3}>
                      <Input
                        className="admin_st"
                        value={insertData?.pageUri}
                        boxName='pageUri'
                        onChange={(value, keyName) => insertDataHandler(keyName,value)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>상세정보</th>
                    <td colSpan={3} style={{height: '500px'}}>
                      <QuillEditor
                        width='100%'
                        height='400'
                        value={insertData?.description}
                        onValueChangeCB={(description) => insertDataHandler('description', description)}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              {( (_.isArray(smartFilterMenuss) && smartFilterMenuss.length > 0) || 
              (_.isArray(extInformationMenuss) && extInformationMenuss.length > 0)) &&
              (
              <>
              <h4>상품정보/스마트필터</h4>
              <table>
              <colgroup>
                  <col style={{ width: "159px" }} />
                  <col style={{ width: "300px" }} />
                  <col style={{ width: "1000px" }} />
                </colgroup>
                <tbody>
                    {
                      (smartFilterMenuss ?? []).map((sItem, idx) => (
                        <>
                        <tr key={`data-${sItem?.id}`}>
                        {idx === 0 && <th rowSpan={smartFilterMenuss.length}>스마트필터</th>}  
                         <th key={`title-${sItem?.id}`}>{sItem?.title}</th>
                         <td>
                          <SmartFilterItems {...sItem} />
                          </td>
                        </tr>
                        </>
                      ))
                    }
                    {
                      (extInformationMenuss ?? []).map((exItem, idx) => (
                        <tr>
                          {idx === 0 && <th rowSpan={extInformationMenuss?.length}> 상품정보 </th>}
                          <th> {exItem?.title} </th>
                          <td> 
                            <Input 
                              value={extInformationMenuInserted[exItem?.id]} 
                              onChange={(insertDes) => setExtInfoDescriptionHandler(exItem?.id, insertDes)}
                            /> 
                          </td>
                        </tr>
                      )) 
                    }
                </tbody>
              </table>
              </>)
              }
{/* 
              <h4>상품정보/스마트필터</h4>
              <table>
                <colgroup>
                  <col style={{ width: "159px" }} />
                  <col style={{ width: "300px" }} />
                  <col style={{ width: "1000px" }} />
                </colgroup>
                <tbody>
                  <tr>
                    <th rowSpan={5}>스마트필터</th>
                    <th>Coverstock</th>
                    <td>
                      <div className={`${classes.flex_row} ${classes.multi}`}>
                        <SelectBoxNew
                          className="admin_st"
                          optionData={[]}
                        />
                        <SelectBoxNew
                          className="admin_st"
                          optionData={[]}
                        />
                        <SelectBoxNew
                          className="admin_st"
                          optionData={[]}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>Weight Block(Core)</th>
                    <td>
                      <SelectBoxNew
                        className="admin_st w100"
                        optionData={[]}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>Weight Block(Core) Shape</th>
                    <td>
                      <div className={classes.radio_wrap}>
                        <Radio
                          value="Asymmetrical(비대칭)"
                          title="Asymmetrical(비대칭)"
                          name="rad_03"
                          id="rad_03_01"
                        />
                        <Radio
                          value="Symmetrical(대칭)"
                          title="Symmetrical(대칭)"
                          name="rad_03"
                          id="rad_03_02"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>Factory Finish</th>
                    <td>
                      <SelectBoxNew
                        className="admin_st w100"
                        optionData={[]}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>Flare Potential</th>
                    <td>
                      <SelectBoxNew
                        className="admin_st w100"
                        optionData={[]}
                      />
                    </td>
                  </tr>

                  <tr>
                    <th rowSpan={2}>상품정보</th>
                    <th>Ball Color</th>
                    <td>
                      <Input
                        className="admin_st"
                        value="Red / Black / Violet"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>Fragrance</th>
                    <td>
                      <Input
                        className="admin_st"
                        value="Cherry Berry Smoothie"
                      />
                    </td>
                  </tr>
                </tbody>
              </table> */}
              <div className={classes.bottom_btn_wrap}>
                <Button className="secondary sm"
                  onClick={(e) => {
                    e.preventDefault();
                    const reqProductDetailSFilterList = `req${isEdit ? 'Update':'Add'}ProductDetailSFilterList`;
                    const reqProductDetailExtInformationList = `req${isEdit ? 'Update':'Add'}ProductDetailExtInformationList`;
                    const releaseDate = new Date(insertData?.releaseDate ?? Date.now()).toISOString().substring(0,10).split('-').join('.');
                    const body = 
                    { ...insertData, releaseDate, [reqProductDetailSFilterList]: [], [reqProductDetailExtInformationList]:[] };
                    if(body.smartFilterMenuSelecteds) delete body.smartFilterMenuSelecteds;
                    if(isEdit) body.id = detailData.id;
                    if(_.isBoolean(body.exposeYn))body.exposeYn = body.exposeYn ? 'Y': 'N';
                    if(isEdit){
                      body[reqProductDetailSFilterList] = []
                      Object.keys(smartFilterSeletedInfo).forEach((sId) => {
                        if(body[reqProductDetailSFilterList].filter(({ id, psfi_id}) => id !== Number(sId.toString().split('_').shift() && psfi_id !== Number(smartFilterSeletedInfo[sId]))))
                        body[reqProductDetailSFilterList].push({ id: Number(sId.toString().split('_').shift()), psfi_id: Number(smartFilterSeletedInfo[sId])  })});
                    }else{
                      body[reqProductDetailSFilterList] = 
                      Object.values(smartFilterSeletedInfo).map((num) => ( {psfi_id:Number(num)}));
                    }
                    
                    body[reqProductDetailExtInformationList]= 
                    Object.keys(extInformationMenuInserted).map((peim_id) => {
                      const rs = { peim_id: Number(peim_id), description: extInformationMenuInserted[Number(peim_id)] };
                      return rs;
                    });
                    if(isEdit) { 
                      body.reqUpdateProductDetailImageList = images.filter((f) => !(f instanceof File));
                      [
                        'category_img_uris', 'extInformationMenuSelecteds','productDetailImagesSelecteds', 'seq',
                        'pageUri', 'readCnt', 'regID', 'regDate', 'hierarchyCategoryStr', 'categoryId'
                    
                    ].forEach((key) => {
                        delete body[key];
                      }) 
                    }
                    if(_.isBoolean(body?.catalogue_ball)){
                      body.catalogue_ball = body.catalogue_ball ? "Y": "N";
                    }
                    const _images = images.filter((f) => f instanceof File);
                    setDeleteFilter([])
                    const params = { isEdit, body, images: _images }
                    if(isEdit) params.body.categoryId = (adderCategoryPath[2] != -1 ? adderCategoryPath[2] : adderCategoryPath[1]);
                    ProductSelectedCategoryInsert(params).then((res) =>{
                      if(res.status === 200){
                        handleModeChange(isEdit ? 2 : 0)
                      }else{
                        alert(`데이터 ${isEdit ? '수정':'추가'}에 실패했습니다.`)
                      }
                      return res;
                    })
                  }}
                
                >{isEdit ? '수정' : '등록'}</Button>
                <Button
                  className="border sm"
                  onClick={() => handleModeChange(0)}
                >
                  목록
                </Button>
              </div>
            </div>
          </div>
            {isModalOpen1 && (
                <Modal
                    className="admin_st"
                    close={closeModalHandler1}
                    header="카테고리 수정"
                >
                    <main>
                        <div className={classes.category_select_wrap}>
                            <h5>상품등록 카테고리를 선택해 주세요.</h5>
                            <div className={classes.category_wrap}>
                                <div className={classes.category_box}>
                                    <h6>대 카테고리</h6>
                                    <div className={classes.scroll_box}>
                                        <ul>
                                            {Object.values(categoryMap).filter(({ depth }) => depth === 1).map((item) => (
                                                <li
                                                    id={item.id}
                                                    className={adderCategoryPath[0] === item.id ? classes.on : undefined }
                                                    onClick={({target: { id },}) => { adderCategoryPathHandler(id, 0); }}
                                                >
                                                    {" "}
                                                    {item.name}{" "}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                                <div className={classes.category_box}>
                                    <h6>중 카테고리</h6>
                                    <div className={classes.scroll_box}>
                                        <ul>
                                            <ul>
                                                {Object.values(categoryMap).filter( ({ parent_id }) => parent_id >= 0 && parent_id === adderCategoryPath[0])
                                                    .map((item) => (
                                                        <li
                                                            id={item.id}
                                                            className={adderCategoryPath[1] === item.id ? classes.on : undefined}
                                                            onClick={({target: { id },}) => {adderCategoryPathHandler(id, 1); }}
                                                        >
                                                            {" "}
                                                            {item.name}{" "}
                                                        </li>
                                                    ))}
                                            </ul>
                                        </ul>
                                    </div>
                                </div>
                                <div
                                    className={`${classes.category_box} ${classes.dep3}`}
                                >
                                    <h6>소 카테고리</h6>
                                    <div className={classes.scroll_box}>
                                        <ul>
                                            {Object.values(categoryMap).filter(({ parent_id }) => parent_id >= 0 && parent_id === adderCategoryPath[1] )
                                                .map((item) => (
                                                    <li
                                                        id={item.id}
                                                        className={adderCategoryPath[2] === item.id ? classes.on : undefined}
                                                        onClick={({target: { id },}) => {adderCategoryPathHandler(id,2);}}
                                                    >
                                                        {" "}
                                                        {item.name}{" "}
                                                    </li>
                                                ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                    <footer>
                        <Button className=" sm" onClick={closeModalHandler1}>
                            취소
                        </Button>
                        <Button
                            className={`secondary sm`}
                            onClick={(e) => {closeModalHandler2(e, adderCategoryPath)
                                // setInsertData((prev) => {
                                //     return {
                                //         ...prev,
                                //         categoryId: adderCategoryPath
                                //             .filter((val) => val >= 0)
                                //             .at(-1),
                                //     };
                                
                                // });
                            }}
                            //disabled={adderCategoryPath.filter((item) => item >= 0).length === 0 || selectedAdderCategoryHasChildren}
                        >
                            수정
                        </Button>
                    </footer>
                </Modal>
            )}
        </div>
  );
}

export default MngProductCRUDPage;
