import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/UI/Button/Button";
import Input from "../../components/UI/Input/Input";
import classes from "./MyPageApp.module.css";
import instance from "../../store/AxiosInterceptor";
import { ReqApplyListWithSearchApp } from "../../api/mypage/UserMyPageApi";
import UserApplyContestListApp from "./components/UserApplyContestListApp";
import { useMemStore } from "../../store/memstore/MemStore";
import { JwtTokenContext } from "../../store/JwtTokenProvider";
import { useContext } from "react";

function MyTournamentHistoryApp() {
    const setMemStore = useMemStore(false)[1];
    const loginCtx = useContext(JwtTokenContext);
    const token = loginCtx.getToken();
    const navigate = useNavigate();

    const [totalElements, setTotalElements] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageNumber, setPageNumber] = useState(0);
    const [applyContestList, setApplyContestList] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [isValidResult, setIsValidResult] = useState(true);
    const onChangeSearchTextHandler = (key) => {
        setSearchText(key);
    };

    const searchStatusHandler = (state) => {
        setIsValidResult(true);
    };

    const applyListWithSearchHandler = () => {
        setCurrentPage(0);
        ReqApplyListWithSearchApp(
            searchText,
            0,
            (result) => {
                if (result.content.length === 0) {
                    setIsValidResult(false);
                    return;
                } else {
                    setIsValidResult(true);
                }
                const curData = result.content.map((data) => {
                    return {
                        competitorId: data.competitor_id,
                        contestId: data.contest_id,
                        contestTitle: data.contest_title,
                        placeId: data.place_id,
                        centerName: data.center_name,
                        region: data.region,
                        ctStartDate: data.ct_start_date,
                        ctEndDate: data.ct_end_date,
                        ctApplyDate: data.ct_apply_date,
                        drawExposeYn: data.draw_expose_yn,
                        applyStatus: data.apply_status,
                        entryFee: data.entry_fee,
                        seq: data.seq,
                        ballSize: data.register_ball_size,
                        ballUseYn: data.ball_use_yn,
                    };
                });
                setApplyContestList(curData);
                setTotalElements(result.totalElements);
                setPageNumber(result.pageable.pageNumber);
            },
            (error_result) => {
                setApplyContestList((prev) => prev);
                setTotalElements((prev) => prev);
                setPageNumber((prev) => prev);
            }
        );
    };

    const applyListWithPagingHandler = () => {
        setCurrentPage((prev) => prev + 1);
        ReqApplyListWithSearchApp(
            searchText,
            currentPage + 1,
            (result) => {
                const curData = result.content.map((data) => {
                    return {
                        competitorId: data.competitor_id,
                        contestId: data.contest_id,
                        contestTitle: data.contest_title,
                        placeId: data.place_id,
                        centerName: data.center_name,
                        region: data.region,
                        ctStartDate: data.ct_start_date,
                        ctEndDate: data.ct_end_date,
                        ctApplyDate: data.ct_apply_date,
                        drawExposeYn: data.draw_expose_yn,
                        applyStatus: data.apply_status,
                        entryFee: data.entry_fee,
                        seq: data.seq,
                        ballSize: data.register_ball_size,
                        ballUseYn: data.ball_use_yn,
                    };
                });
                setApplyContestList((prev) => [...prev, ...curData]);
                setTotalElements(result.totalElements);
                setPageNumber(result.pageable.pageNumber);
            },
            (error_result) => {
                setApplyContestList((prev) => prev);
                setTotalElements((prev) => prev);
                setPageNumber((prev) => prev);
            }
        );
    };

    const noDataLoader = async () => {
        const response = await instance({
            method: "GET",
            url: `/api/v1/home/mypage/contest?searchText=&page=0&size=5`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        }).catch(function (error) {
            if (error.response) {
                return error.response;
            } else if (error.request) {
                return null;
            } else {
                return null;
            }
        });
        return response;
    };

    useEffect(() => {
        const fetchData = async () => {
            const response = await noDataLoader();
            if (response === null || response.status != 200) {
                // console.log("response.status -- ", response.status);
            } else {
                // console.log(response?.data?.datas !== null);
                if (response?.data?.datas !== null) {
                    const resData = response?.data?.datas;
                    const curData = resData.content.map((data) => {
                        return {
                            competitorId: data.competitor_id,
                            contestId: data.contest_id,
                            contestTitle: data.contest_title,
                            placeId: data.place_id,
                            centerName: data.center_name,
                            region: data.region,
                            ctStartDate: data.ct_start_date,
                            ctEndDate: data.ct_end_date,
                            ctApplyDate: data.ct_apply_date,
                            drawExposeYn: data.draw_expose_yn,
                            applyStatus: data.apply_status,
                            payStatus: data.pay_status,
                            entryFee: data.entry_fee,
                            seq: data.seq,
                            ballSize: data.register_ball_size,
                            ballUseYn: data.ball_use_yn,
                        };
                    });
                    setApplyContestList(curData);
                    setTotalElements(resData.totalElements);
                    setPageNumber(resData.pageable.pageNumber);
                }
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (token == null) {
            setMemStore("LOGIN_MOVE_PAGE", { uri: "/mypage/tournamentHistory" });
            navigate("/login");
        }
    }, []);

    return (
        <div className={classes.contents_wrap}>
            <div className={classes.contents_title}>
                <div className={classes.search_wrap}>
                    <Input onChange={onChangeSearchTextHandler} placeholder="검색어를 입력해주세요." />
                    <Button className="search_sm" onClick={applyListWithSearchHandler} />
                </div>
            </div>
            {isValidResult && (
                <div className={`${classes.mobile_table_wrap} ${classes.main_type}`}>
                    <table>
                        <thead>
                            <tr>
                                <th>번호</th>
                                <th>대회</th>
                                <th>경기장</th>
                                <th>대회기간</th>
                                <th>접수일자</th>
                                <th>상태</th>
                            </tr>
                        </thead>
                        <tbody>
                            {applyContestList.map((data, index) => (
                                <UserApplyContestListApp
                                    key={"ctList" + index}
                                    index={index}
                                    competitorId={data.competitorId}
                                    contestId={data.contestId}
                                    contestTitle={data.contestTitle}
                                    placeId={data.placeId}
                                    centerName={data.centerName}
                                    region={data.region}
                                    ctStartDate={data.ctStartDate}
                                    ctEndDate={data.ctEndDate}
                                    ctApplyDate={data.ctApplyDate}
                                    drawExposeYn={data.drawExposeYn}
                                    applyStatus={data.applyStatus}
                                    payStatus={data.payStatus}
                                    entryFee={data.entryFee}
                                    seq={data.seq}
                                    totalElements={totalElements}
                                    pageNumber={pageNumber}
                                    ballSize={data.ballSize}
                                    ballUseYn={data.ballUseYn}
                                />
                            ))}
                        </tbody>
                    </table>
                    {/* 모바일버전 - 페이징 삭제 / 더보기 추가 */}
                    <div className={classes.more_btn}>
                        {/* <p className="primary_c">[안내] 회원분들의 기존 대회 접수내역은 데이터 이관 작업중입니다.</p> */}
                        <br /> <br />
                        <Button className="text_icon" onClick={applyListWithPagingHandler}>
                            더보기
                        </Button>
                    </div>
                </div>
            )}
            {!isValidResult && (
                <div className={classes.empty_data}>
                    <p>검색결과가 없습니다.</p>
                    <Button className="secondary" onClick={() => searchStatusHandler(false)}>
                        대회 접수내역 보기
                    </Button>
                </div>
            )}
        </div>
    );
}

export default MyTournamentHistoryApp;

// [D] 20240503 YiSuHwan 필요없는 코드 - 로더를 사용하지 않는 방식으로 처리함
export const loader = async () => {
    return {};
};
