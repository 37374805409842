import { useState } from "react";
import classes from "./SelectBox.module.css";
import _ from "lodash";

/**
 * select box 가이드 코드
 *
 * 부모에서 선택된 데이터를 관리하고 다시 넣어주어 호출하도록 함 (부모 컴포넌트에 useState 가 존재)
 * 2023-07-14 handleOnSelectItem bind부분을 제거 변경
 *
 * @param props.optionDatas {key, value, disable} ex) { key: 'id_0', value: "전체", disabled: false}
 * @param props.className (admin_st or admin_st.w_50 or admin_st.w_sm or w_50 or right ... 1)
 * @param props.setSelectItemCB (selectbox 에서 선택된 데이터에 대한 callback 함수)
 * @param props.showDefaultKey (optionDatas 중 default 로 선택되어야 하는 값의 key)
 * @param props.mouseOverCB (mouseOver Event에 대한 처리)
 * @param props.boxName Callback함수를 한개 사용시 어떠한 SelectBox에서 호출했는지 확인하기 위함
 *
 */
function SelectBoxNew(props) {
    const {
        optionDatas,
        className = "",
        setSelectItemCB = (key, boxName) => {
            console.log({ key, boxName });
        },
        showDefaultKey,
        mouseOverCB,
        boxName,
        ref,
    } = props;
    const [showOptions, setShowOptions] = useState(false);
    if (!_.isArray(optionDatas) || optionDatas.length === 0) {
        return <></>;
    }

    let currentOption = optionDatas.find((v) => v.key === showDefaultKey);

    if (!currentOption) currentOption = optionDatas[0];

    const handleSelectBoxHideAndShowToggle = () => {
        setShowOptions((current) => !current);
    };

    const onMouseOutHandler = () => {
        setShowOptions((current) => false);
    };

    const handleOnSelectItem = (e) => {
        const key = e.target.dataset.key;
        const selectedOptionData = optionDatas.find((v) => v.key === key);
        if (!selectedOptionData?.disabled) {
            setSelectItemCB(key, boxName);
        } else {
            e.stopPropagation();
        }
    };

    //클래스명 조합
    const classNames = Array.isArray(className)
        ? className
        : className.split(" ");
    const combinedClasses = classNames
        .map((cn) => classes[cn])
        .filter((cn) => cn)
        .join(" ");
    return (
        <div
            ref={ref}
            className={`${classes.selectBox} ${combinedClasses} ${
                showOptions ? classes.on : ""
            }`}
            onClick={handleSelectBoxHideAndShowToggle}
            onMouseOver={mouseOverCB}
            onMouseLeave={onMouseOutHandler}
        >
            <p>{currentOption.value}</p>
            <ul>
                {optionDatas.map((data) => {
                    // [D] 선택된 데이터도 다시 선택이 가능하도록 처리
                    // if (currentOption === data) {
                    //     return <></>;
                    // }
                    // else {
                    return (
                        <li
                            id={data.key}
                            key={data.key}
                            value={data.value}
                            data-key={data.key}
                            onClick={handleOnSelectItem}
                            className={data.disabled ? classes.readonly : ""}
                        >
                            {data.value}
                            {data.subValue && (
                                <span
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        e.currentTarget.parentNode.click();
                                    }}
                                >
                                    - {data.subValue}
                                </span>
                            )}
                        </li>
                    );
                    // }
                })}
            </ul>
        </div>
    );
}
export default SelectBoxNew;
