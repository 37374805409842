import { Outlet } from "react-router-dom";
import classes from "./IntroduceApp.module.css";
import IntroduceAppNavigation from "./IntroduceAppNavigation";

function IntroduceAppRootLayout() {
    return (
        <div className={`container ${classes.introduce_container}`}>
            <IntroduceAppNavigation />
            <section>
                <div className={classes.introduce_contents}>
                    <Outlet />
                </div>
            </section>
        </div>
    );
}

export default IntroduceAppRootLayout;
