/* eslint-disable react/prop-types */
import Button from "../../../components/UI/Button/Button";
import Input from "../../../components/UI/Input/Input";
import classes from "./../ProductsApp.module.css";
import catalogue from "../../../assets/images/product_icon_catalogue.png";
import { useEffect, useMemo, useRef, useState } from "react";
import htmlReactParser from "html-react-parser";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
    getHomeProduct,
    getHomeProductDetail,
    getHomeProductDetailInfo,
    getHomeSmartFilter,
} from "../../../api/home/ProductCategoryApi";
import React, { useLocation, useNavigate, useParams } from "react-router-dom";
import { ProductAppItem } from "./ProductAppItem";
import _ from "lodash";
import { FacebookShareButton } from "react-share";
import CopyToClipboard from "react-copy-to-clipboard";
import SelectBoxScroll from "../../../components/UI/SelectBox/SelectBoxScroll";
import Checkbox from "../../../components/UI/Input/Checkbox";
import { SmartFilterApp } from "./SmartFilterApp";

const productPageMap = {
    balls: 100,
    bags: 200,
    clothes: 300,
    shoes: 400,
    products: 500,
    guard: 600,
};
const productNameMap = {
    balls: "볼링볼",
    bags: "볼링가방",
    clothes: "볼링의류",
    shoes: "볼링화",
    products: "볼링용품",
    guard: "볼링아대",
};

function ProductAppPage({ isDetail = false }) {
    const resetPage = () => {
        setShowPage(0);
    };

    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const shareBoxRef = useRef();
    const [categoryId, setCategoryId] = useState(-1);
    const [categoryId2, setCategoryId2] = useState(-1);
    const [categoryId3, setCategoryId3] = useState(-1);

    useEffect(() => {
        const lastName = location.pathname.split("/").at(-1);
        setCategoryId(isDetail ? Number(lastName) : productPageMap[lastName]);
        // [A] 20230829 YiSuHwan
        setCategoryMap({});
    }, [location?.pathname]);
    const productName = useMemo(() => {
        const at = location.pathname.split("/").at(isDetail ? -2 : -1);
        return productNameMap[at];
    }, [isDetail, location.pathname]);
    const urlSearchParams = useMemo(() => new URLSearchParams(location.search), [location]);
    const searchParamsMap = useMemo(() => {
        const rs = {};
        urlSearchParams.forEach((v, key) => {
            rs[key] = v;
        });
        return rs;
    }, [urlSearchParams]);

    const [productDatas, setProductDatas] = useState({
        content: [],
        pageable: {},
    });
    const [detailData, setDetailData] = useState({});

    const [showPage, setShowPage] = useState(0);
    const [searchKeyword, setSearchKeyword] = useState("");

    const [categoryMap, setCategoryMap] = useState({});

    const [showSearchInput, setShowSearchInput] = useState(false);

    // ----------------------------------------------------------
    // 스마트필터
    // ----------------------------------------------------------
    const [smartFilterData, setSmartFilterData] = useState([]);

    // 스마트필터에 넘기는 searchParams 값 새로 정의
    const [searchParams, setSearchParams] = useState({
        page: 0,
        category: 100,
        depth: 1,
    });

    // 스마트필터 검색 결과 값
    const [searchResults, setSearchResults] = useState([]);

    // 검색된 스마트필터 값
    const [selectedValues, setSelectedValues] = useState({});

    useEffect(() => {
        if (searchResults) {
            // 검색 결과 데이터를 이용하여 화면에 표시
            setProductDatas(searchResults);
        }
    }, [searchResults]);

    const toMap = (_data = {}, map = {}) => {
        map[_data.id] = _data;
        if (_.isArray(_data?.children) && _data.children.length > 0) {
            _data.children.forEach((item) => {
                toMap(item, map);
            });
        }
    };

    const getProductDatas = (toNext = false, idx = 1, useSearch = true) => {
        if (Object.keys(categoryMap).length === 0) {
            getProductDetailList();
            getHomeSmartFilterList();
            return;
        }

        const page = toNext ? showPage + 1 : 0;
        const category = [categoryId, categoryId2, categoryId3][idx - 1];
        const depth = categoryMap[category]?.depth;
        const queryParams = { ...searchParamsMap };
        if (searchKeyword.length > 0) queryParams.searchKeyword = searchKeyword;
        else if (searchKeyword === "" && queryParams.searchKeyword) delete queryParams.searchKeyword;

        // selectedValues가 객체인 경우(O), 객체를 배열로 변환
        const selectedValuesArray = Object.values(selectedValues);

        // selectedValuesArray에서 객체를 필터링
        const selectedValuesWithoutObjects = selectedValuesArray.filter((value) => typeof value !== "object");

        getHomeProduct(
            { page, category, depth },
            useSearch ? queryParams : {},
            // queryParams,
            selectedValuesWithoutObjects
        ).then((res) => {
            if (res.status === 200) {
                setProductDatas({
                    ...(res.data?.data ?? {}),
                    content: [...(toNext ? productDatas.content : []), ...(res.data?.data?.content ?? [])],
                });
                setDetailData({});
                setShowPage(page);
            }
        });
    };

    const getProductDetailList = () => {
        getHomeProductDetail(categoryId === -1 ? productPageMap[params.category] : categoryId).then(
            ({ data, status }) => {
                if (status === 200 && _.isArray(data?.datas) && data?.datas?.length > 0) {
                    const map = {};
                    toMap(data.datas[0], map);
                    setCategoryMap(map);
                }
            }
        );
    };
    const getHomeSmartFilterList = () => {
        getHomeSmartFilter(
            categoryId === 0 ? productPageMap[params.category] : categoryId,
            searchParams.category,
            searchParams.depth
        ).then(({ data, status }) => {
            if (status === 200 && _.isArray(data?.data.smartFilterMenus) && data?.data.smartFilterMenus?.length > 0) {
                setSmartFilterData(data.data.smartFilterMenus);
            }
        });
    };

    useEffect(() => {
        // searchParams.category가 변경될 때마다 getHomeSmartFilterList 호출
        getHomeSmartFilterList();
    }, [searchParams.category, searchParams.depth]);

    const getDetailDatas = () => {
        const detailId = Number(location.pathname.split("/").at(-1));
        if (_.isNaN(detailId)) return;
        getHomeProductDetailInfo(detailId).then((res) => {
            if (res.status === 200) {
                setDetailData(res.data?.data ?? {});
            }
        });
    };

    useEffect(() => {
        // [A] 20230829 YiSuHwan 검색값 유지되는 부분 수정
        setSearchKeyword("");
        if (_.isString(location.search) && location.search.length > 0 && !isDetail) {
            setSearchKeyword(urlSearchParams.get("searchKeyword"));
        }
        if (isDetail) {
            getDetailDatas();
        } else if (location.state?.toList) {
            setProductDatas(location.state?.productDatas);
        } else if (categoryMap[categoryId] && !_.isNaN(Number(categoryMap[categoryId]?.depth))) {
            getProductDatas();
        } else {
            getProductDatas();
        }
    }, [categoryId, params?.id, categoryMap]);

    const showAllList = () => {
        navigate("");
        setSearchKeyword("");
        getProductDatas(false, 1, false);
    };

    // filter box 여닫기
    const [filterBoxToggle, setFilterBoxToggle] = useState(false);
    const filterBoxHandler = () => {
        setFilterBoxToggle((prev) => !prev);
    };

    // 공유 팝업 여닫기
    const [sharePopToggle, setSharePopToggle] = useState(false);
    const sharePopHandler = () => {
        setSharePopToggle((prev) => !prev);
    };
    // 상품 상세 슬라이드
    const [slideIndex, setSlideIndex] = useState(0);
    const [updateCount, setUpdateCount] = useState(0);
    const sliderRef = useRef(null);
    const sliderSettings = {
        dots: true,
        lazyLoad: true,
        infinite: true,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        afterChange: () => setUpdateCount(updateCount + 1),
        beforeChange: (current, next) => setSlideIndex(next),
    };

    const [copyed, setCopyed] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setCopyed(false);
        }, 2500);
        return () => {
            clearTimeout(timer);
        };
    }, [copyed]);

    const kakaoShare = () => {
        const pathUri = detailData.pageUri;
        let path = "";
        if (pathUri.indexOf("https://www.bowlingkorea.com/") !== -1) {
            path = pathUri.replace("https://www.bowlingkorea.com/", "");
        } else {
            path = pathUri.replace("https://bowlingkorea.com/", "");
        }
        if (!Kakao.isInitialized()) Kakao.init("676b3a47cf0a23d76e9e9af1f250d0c7");
        Kakao.Share.sendCustom({
            templateId: 98020,
            templateArgs: {
                title: "진승의 상품을 공유합니다.",
                desc: `${detailData.name} ${detailData.subName ? `(${detailData.subName})` : ""}`,
                url: detailData.pageUri,
                path: path,
                THU: `https://bowlingkorea.com${detailData.productDetailImagesSelecteds[0].uri}`,
            },
            installTalk: true,
        });
    };
    useEffect(() => {
        setCategoryId2(-1);
        getProductDatas(false, 1);
    }, [categoryId]);
    useEffect(() => {
        setCategoryId3(-1);
        if (categoryId2 === -1) {
            showAllList();
        } else {
            getProductDatas(false, 2);
        }
    }, [categoryId2]);
    useEffect(() => {
        if (categoryId3 === -1) {
            getProductDatas(false, 2);
        } else {
            getProductDatas(false, 3);
        }
    }, [categoryId3]);

    useEffect(() => {
        const clickOut = (e) => {
            if (shareBoxRef.current && !shareBoxRef.current.contains(e.target)) {
                setSharePopToggle(false);
            }
        };
        document.addEventListener("mousedown", clickOut);
        return () => {
            document.removeEventListener("mousedown", clickOut);
        };
    }, []);
    const searchBtnRef = useRef();
    return (
        <div className={`container ${classes.products_container}`}>
            <div className={classes.products_sub_nav}>
                {/* 모바일에서 pc와 구조 다름, 버튼 다름 */}
                <div className={classes.products_sub_header}>
                    <div className={classes.title}>
                        <h3>
                            {productName}{" "}
                            {!isDetail && <b style={{ color: "red" }}> ({productDatas?.totalElements ?? 0}) </b>}{" "}
                        </h3>
                        <div className={`${classes.btn_wrap} ${classes.btn_search}`}>
                            <Button className="search_sm" onClick={() => setShowSearchInput(!showSearchInput)}>
                                검색
                            </Button>
                            <Button className="filter" onClick={filterBoxHandler}>
                                필터
                            </Button>
                        </div>
                        <div className={classes.search_filter}>
                            {!isDetail && !showSearchInput && (
                                <div className={classes.select_wrap}>
                                    <SelectBoxScroll
                                        className="default w_50"
                                        optionDatas={[
                                            {
                                                key: -1,
                                                value: "전체",
                                            },
                                            ...Object.values(categoryMap)
                                                .filter(({ parent_id }) => parent_id === categoryId && parent_id !== -1)
                                                .map(({ id, name }) => ({
                                                    key: id,
                                                    value: name,
                                                })),
                                        ]}
                                        setSelectItemCB={(key) => setCategoryId2(Number(key))}
                                        showDefaultKey={Number(categoryId2)}
                                    />
                                    <SelectBoxScroll
                                        className="default w_50"
                                        optionDatas={[
                                            {
                                                key: -1,
                                                value: "전체",
                                            },
                                            ...Object.values(categoryMap)
                                                .filter(
                                                    ({ parent_id }) => parent_id === categoryId2 && parent_id !== -1
                                                )
                                                .map(({ id, name }) => ({
                                                    key: id,
                                                    value: name,
                                                })),
                                        ]}
                                        setSelectItemCB={(key) => setCategoryId3(Number(key))}
                                        showDefaultKey={categoryId3}
                                    />
                                </div>
                            )}

                            {!isDetail && showSearchInput && (
                                <div className={classes.search_wrap}>
                                    <Input
                                        onChange={setSearchKeyword}
                                        onKeyPress={(e) => {
                                            if (e.key === "Enter" && searchBtnRef.current)
                                                searchBtnRef.current?.click();
                                        }}
                                        value={searchKeyword}
                                    />
                                    <Button
                                        className="search_sm"
                                        forwardedRef={searchBtnRef}
                                        onClick={() => {
                                            navigate(searchKeyword.length > 0 ? `?searchKeyword=${searchKeyword}` : "");
                                            getProductDatas();
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                    {filterBoxToggle && (
                        <SmartFilterApp
                            smartFilterDatas={smartFilterData}
                            setSearchResults={setSearchResults}
                            searchParams={searchParams}
                            searchKeyword={searchKeyword}
                            setSearchKeyword={setSearchKeyword}
                            setSelectedValues={setSelectedValues} // 검색된 스마트필터 선택값(자식->부모)
                            selectedValues={selectedValues} // 부모 -> 자식(선택된 체크박스를 유지하기 위해서)
                            resetPage={resetPage}
                            filterBoxToggle={filterBoxToggle}
                            setFilterBoxToggle={setFilterBoxToggle}
                        />
                    )}
                </div>
            </div>
            <section>
                <div className={classes.products_contents}>
                    <div className={classes.contents_wrap}>
                        <div></div>
                        {!isDetail && (
                            <>
                                {/* 검색결과 */}
                                {urlSearchParams?.get("searchKeyword") && (
                                    <div className={classes.search_result}>
                                        <p>
                                            <b>{urlSearchParams?.get("searchKeyword")}</b>
                                            에대한 검색결과 <b>{productDatas?.totalElements ?? 0}</b>건
                                        </p>
                                        {/* <Button className="secondary">
                                            볼링볼 전체보기
                                        </Button> */}
                                    </div>
                                )}
                                {(productDatas?.totalElements ?? 0) === 0 && (
                                    <div className={classes.empty_data}>
                                        {/* 데이터가 없는 경우 */}
                                        <p>상품이 존재하지 않습니다.</p>
                                        {/* <Button className="secondary">
                                            볼링볼 전체보기
                                        </Button> */}
                                    </div>
                                )}
                                <div className={classes.display_wrap}>
                                    {productDatas?.content?.map((pd) => (
                                        <ProductAppItem
                                            key={pd?.id}
                                            onClick={() =>
                                                navigate(pd?.id.toString(), {
                                                    state: {
                                                        productDatas,
                                                        search: location.search,
                                                    },
                                                })
                                            }
                                            imgSrc={pd["thumbnail_url"]}
                                            alt={pd?.originalFileName}
                                            title={`${pd?.name}`}
                                        />
                                    ))}
                                </div>
                                {!productDatas?.last && (
                                    <div className={classes.bottom_center_btn}>
                                        <Button
                                            className="text_icon"
                                            onClick={() =>
                                                getProductDatas(
                                                    true,
                                                    [categoryId, categoryId2, categoryId3].filter((key) => key > -1)
                                                        .length
                                                )
                                            }
                                        >
                                            더보기
                                        </Button>
                                    </div>
                                )}
                                {/* 검색결과 */}
                                {(productDatas?.totalElements ?? 0) > 0 ? (
                                    <div className={classes.search_result}>
                                        <Button className="secondary" onClick={showAllList}>
                                            {productNameMap[location.pathname.split("/").at(-1)]} 전체보기
                                        </Button>
                                    </div>
                                ) : (
                                    <div className={classes.empty_data}>
                                        <p>상품이 존재하지 않습니다.</p>
                                        <Button className="secondary" onClick={showAllList}>
                                            {productNameMap[location.pathname.split("/").at(-1)]} 전체보기
                                        </Button>
                                    </div>
                                )}
                            </>
                        )}
                        {isDetail && (
                            <>
                                <div className={classes.contents_detail}>
                                    <div className={classes.contents_title}>
                                        <div className={classes.img_box}>
                                            <Slider ref={sliderRef} {...sliderSettings}>
                                                {(detailData?.productDetailImagesSelecteds ?? []).map((imgItem) => (
                                                    <div
                                                        key={`img-${imgItem.id}-${imgItem.seq}`}
                                                        className={classes.main_img}
                                                    >
                                                        <img src={imgItem.uri} alt={imgItem["origin_name"]} />
                                                    </div>
                                                ))}
                                            </Slider>
                                        </div>
                                        <div className={classes.contents_info}>
                                            <div className={classes.logo_wrap}>
                                                <ul>
                                                    {(detailData?.category_img_uris ?? [])
                                                        .filter((imgSrc) => _.isString(imgSrc) && imgSrc !== "")
                                                        .map((imgSrc, idx) => (
                                                            <li key={`${idx}-${imgSrc}`}>
                                                                <img src={imgSrc} />
                                                            </li>
                                                        ))}
                                                    {detailData?.catalogue_ball && (
                                                        <li>
                                                            <img src={catalogue} alt="catalogue" />
                                                        </li>
                                                    )}
                                                </ul>
                                            </div>
                                            <div className={classes.title}>
                                                <span className={classes.en}>{detailData.subName}</span>
                                                <h3>{detailData.name}</h3>
                                                <div className={classes.share_wrap}>
                                                    <Button className="share" onClick={sharePopHandler} />
                                                    <div
                                                        className={`${classes.share_pop} ${
                                                            sharePopToggle ? classes.on : null
                                                        }`}
                                                    >
                                                        <div className={classes.box} ref={shareBoxRef}>
                                                            <div className={classes.header}>
                                                                <h5>공유</h5>
                                                                <Button className="btn_x" onClick={sharePopHandler} />
                                                            </div>
                                                            <div className={classes.list_wrap}>
                                                                <ul>
                                                                    <li
                                                                        className={classes.kakao}
                                                                        onClick={() => {
                                                                            try {
                                                                                kakaoShare();
                                                                            } catch (error) {
                                                                                console.log({
                                                                                    error,
                                                                                });
                                                                                Kakao.cleanup();
                                                                                kakaoShare();
                                                                            }
                                                                        }}
                                                                    >
                                                                        카카오톡
                                                                    </li>
                                                                    <FacebookShareButton url={detailData.pageUri}>
                                                                        <li className={classes.facebook}>페이스북</li>
                                                                    </FacebookShareButton>
                                                                    <CopyToClipboard
                                                                        text={detailData.pageUri}
                                                                        onCopy={() => {
                                                                            setCopyed(true);
                                                                        }}
                                                                    >
                                                                        <li className={classes.link}>URL 복사</li>
                                                                    </CopyToClipboard>
                                                                </ul>
                                                            </div>
                                                            {copyed && (
                                                                <div className={classes.toast}>
                                                                    URL이 복사되었습니다.
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={classes.contents}>
                                                <ul>
                                                    {(detailData?.smartFilterMenuSelecteds ?? []).map((sItem) => (
                                                        <li key={sItem?.id}>
                                                            <b>{sItem?.title}</b> :
                                                            {(sItem?.smartFilterItemSelecteds ?? [])
                                                                .map((sfItem) => sfItem?.name)
                                                                ?.join(" ")}
                                                        </li>
                                                    ))}
                                                    {(detailData?.extInformationMenuSelecteds ?? []).map((item) => (
                                                        <li key={`ext-info-${item?.id}`}>
                                                            {item?.title} :{item?.description}
                                                        </li>
                                                    ))}
                                                    {params?.category === "balls" && (
                                                        <li>
                                                            <b>출시일</b> : {detailData?.releaseDate}
                                                        </li>
                                                    )}
                                                </ul>
                                                {(detailData?.purchaseUrl !== "" && detailData?.purchaseUrl != null) && (
                                                    <Button
                                                        className="official"
                                                        onClick={() => window.open(detailData?.purchaseUrl, "_blank")}
                                                    >
                                                        진승몰에서 구매하기
                                                    </Button>
                                                )}
                                                {(detailData?.purchaseUrl === "" || detailData?.purchaseUrl == null) && (
                                                    <Button className="official" disabled={true}>
                                                        출시 예정
                                                    </Button>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={classes.detail_box}>
                                        {htmlReactParser(detailData?.description ?? "")}
                                    </div>
                                    <div className={classes.bottom_btn_wrap} onClick={() => {}}>
                                        <Button
                                            onClick={() => {
                                                const pathArr = location.pathname.split("/");
                                                console.log("pathArr : ", pathArr);
                                                pathArr.pop();
                                                let listUrl = pathArr.join("/");
                                                if (location.state) {
                                                    listUrl += location.state?.search ?? "";
                                                }
                                                navigate(listUrl, {
                                                    state: {
                                                        item: detailData?.categoryId,
                                                        toList: location.state?.productData?.content.length > 0,
                                                        ...(location.state ?? {}),
                                                    },
                                                });
                                            }}
                                        >
                                            목록
                                        </Button>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </section>
        </div>
    );
}

export default ProductAppPage;
