import { useEffect, useState } from "react";
import Button from "../../components/UI/Button/Button";
import TermsOfUseDetail from "../other/TermsOfUseDetail";
import TermsPersonalInformationDetail from "../other/TermsPersonalInformationDetail";
import classes from "./SignTermsModal.module.css";
import instance from "../../store/AxiosInterceptor";

function SignTermsModal({ className, close, ctAgreementInfo }) {

    const [terms, setTerms] = useState("");

    useEffect(() => {
        const fetchData = async () => {
            const response = await instance.get(`/api/v1/home/terms/${ctAgreementInfo}`)
            if (response.status === 200) {
                const resData = await response.data;
                setTerms(resData);
            } else {
                setTerms([])
            }
        };
        fetchData();
    }, [ctAgreementInfo]);

    return (
        <>
            <main className={classes.terms_wrap}>
                    <TermsPersonalInformationDetail contentCB={terms.content}/>
            </main>
            <footer>
                <Button className="secondary sm" onClick={close}>
                    확인
                </Button>
            </footer>
        </>
    );
}
export default SignTermsModal;
