import Button from "../../../components/UI/Button/Button";
import classes from "./../TournamentContainerApp.module.css";
import { useNavigate } from "react-router-dom";

function ApplyTournamentCompleteApp(props) {
    const { researchList, groupOptionDatas, selectedGroup } = props;
    const viewGroup = groupOptionDatas.filter(
        (data) => data.key === selectedGroup
    );
    const viewGroupName = viewGroup[0].value;

    const navigate = useNavigate();
    const myPageHandler = () => {
        navigate(`/myPage/tournamentHistory`);
    };

    return (
        <>
            <main className={classes.complete_wrap}>
                <h3>
                    <span>{props.memberName}</span>님
                </h3>
                <div className={classes.mobile_modal_table}>
                    <p>아래와 같이 대회접수가 완료되었습니다.</p>
                    <table>
                        <colgroup>
                            <col style={{ width: "160px" }} />
                            <col style={{ width: "460px" }} />
                        </colgroup>
                        <tbody>
                            <tr>
                                <th>대회</th>
                                <td>{props.ctTitle}</td>
                            </tr>
                            <tr>
                                <th>경기장</th>
                                <td>
                                    {props.region}({props.centerName})
                                </td>
                            </tr>
                            <tr>
                                <th>조</th>
                                <td>{viewGroupName}</td>
                            </tr>
                            <tr>
                                <th>이름</th>
                                <td>{props.memberName}</td>
                            </tr>
                            <tr>
                                <th>연락처</th>
                                <td>{props.viewPhoneNumber}</td>
                            </tr>
                            <tr>
                                <th>성별</th>
                                <td>
                                    {props.gender === "M" ? "남자" : "여자"}
                                </td>
                            </tr>
                            <tr>
                                <th>생년월일</th>
                                <td>{props.viewBirthDate}</td>
                            </tr>
                            <tr>
                                <th>장애여부</th>
                                <td>
                                    {props.disabled === "N" ||
                                    props.disabled === undefined
                                        ? "해당 없음"
                                        : "장애중증(3급)이상"}
                                </td>
                            </tr>
                            {researchList.map((data, index) => (
                                <tr key={index}>
                                    <th>{data.title}</th>
                                    <td>{data.default_value}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </main>
            <footer>
                <Button className="sm" onClick={props.close}>
                    확인
                </Button>
                <Button className="secondary sm" onClick={myPageHandler}>
                    접수정보 수정하기
                </Button>
            </footer>
        </>
    );
}
export default ApplyTournamentCompleteApp;
