import { useState } from "react";
import classes from "../../MngCommon.module.css";
import Button from "../../../../components/UI/Button/Button";
import Input, { MultiInput } from "../../../../components/UI/Input/Input";
import Radio from "../../../../components/UI/Input/Radio";
import Checkbox from "../../../../components/UI/Input/Checkbox";
import { useNavigate } from "react-router-dom";
import SelectBoxNew from "../../../../components/UI/SelectBox/SelectBoxNew";
import { ReqCheckDuplicateMemId } from "../../../../api/auth/AuthApi";
import Modal from "../../../../components/UI/Modal/Modal";
import DaumPostcode from "react-daum-postcode";
import { defaultEmailHostList } from "../../../../components/definedefault/DefSelectBoxValues";
import { ReqRegisterMember } from "../../../../api/member/MemberInfoApi";
import Alert from "../../../../components/UI/Modal/Alert";

function MngUserRegisterForm() {
    const navigate = useNavigate();
    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: 81 }, (_, index) => ({
        key: String(currentYear - index),
        value: String(currentYear - index),
        disabled: false,
    }));

    const months = Array.from({ length: 12 }, (_, index) => ({
        key: (index + 1).toString().padStart(2, "0"),
        value: (index + 1).toString().padStart(2, "0"),
        disabled: false,
    }));

    const days = Array.from({ length: 31 }, (_, index) => ({
        key: (index + 1).toString().padStart(2, "0"),
        value: (index + 1).toString().padStart(2, "0"),
        disabled: false,
    }));

    const [selectedYear, setSelectYear] = useState(currentYear);
    const [selectedMonth, setSelectedMonth] = useState("01");
    const [selectedDate, setSelectedDate] = useState("01");

    const selectedYearHandler = (key) => {
        setSelectYear(key);
    };
    const selectedMonthHandler = (key) => {
        setSelectedMonth(key);
    };
    const selectedDateHandler = (key) => {
        setSelectedDate(key);
    };

    const [smsYn, setSmsYn] = useState(false);
    const [emailYn, setEmailYn] = useState(false);
    const [gender, setGender] = useState("");

    const genderHandler = (e) => {
        setGender(e.target.value);
    };
    const smsYnHandler = () => {
        setSmsYn((prev) => !prev);
    };
    const emailYnHandler = () => {
        setEmailYn((prev) => !prev);
    };

    const emailHostList = defaultEmailHostList;
    const [registerEmailHost, setRegisterEmailHost] = useState("");
    const emailHostHandler = (key) => {
        setRegisterEmailHost(key);
    };

    const [input, setInput] = useState({
        registerName: "",
        registerMemberId: "",
        registerMemberPassword: "",
        registerMemberPasswordCheck: "",
        registerMemberPhoneFirst: "",
        registerMemberPhoneMid: "",
        registerMemberPhoneLast: "",
        registerEmailId: "",
        registerReason: "",
    });

    const {
        registerName,
        registerMemberId,
        registerMemberPassword,
        registerMemberPasswordCheck,
        registerMemberPhoneFirst,
        registerMemberPhoneMid,
        registerMemberPhoneLast,
        registerEmailId,
        registerReason,
    } = input;

    const onChangeInput = (e) => {
        const { name, value } = e.target;
        setInput({
            ...input,
            [name]: value,
        });
    };

    const memberIdRegex = /^[a-zA-Z0-9]{6,20}$/;
    const memberIdRegexCheck = memberIdRegex.test(registerMemberId);
    const passwordRegex = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{6,20}$/;
    const passwordRegexCheck = passwordRegex.test(registerMemberPassword);

    const navigateHandler = () => {
        navigate("/admin/users/list");
    };

    // [START] 다음 주소 API =================================================================
    const [isDaumPostcodeOpen, setIsDaumPostcodeOpen] = useState(false);
    const [zipCode, setZipCode] = useState(""); //우편번호
    const [selectedAddress, setSelectedAddress] = useState(""); // 선택한 주소를 저장할 상태
    const [enteredAddress, setEnteredAddress] = useState(""); // 입력한 주소를 저장할 상태

    const openDaumPostcodeHandler = (e) => {
        e.stopPropagation();
        setIsDaumPostcodeOpen(true);
    };

    const handleAddressSelect = (data) => {
        const fullAddress = data.address; // 전체 주소
        const extraAddress = data.addressType === "R" ? "" : data.bname; // 도로명일 경우 법정동 추가
        const address = `${fullAddress} ${extraAddress}`; // 최종 주소
        setZipCode(data.zonecode);
        setSelectedAddress(address); // 주소를 선택하면 상태 업데이트
        setIsDaumPostcodeOpen(false); // 주소를 선택하면 모달 창 닫기
    };

    const closeDaumPostcodeHandler = () => {
        setIsDaumPostcodeOpen(false);
    };

    //우편번호 검색을 했는데, 나머지 주소를 입력하지 않은경우
    //주소입력을 아에 안하고 가입 가능 but, 하나라도 입력하면 나머지 주소를 입력해야한다.
    // [END] 다음 주소 API =================================================================

    const [memberIdIsValid, setMemberIdIsValid] = useState("");
    const idDuplicationHandler = () => {
        if (registerMemberId.trim() === "") {
            alert("회원 아이디를 입력해주세요.");
            return;
        }
        if (!memberIdRegexCheck) {
            alert("회원 아이디 양식에 적합하지 않습니다.");
            return;
        }
        ReqCheckDuplicateMemId(
            registerMemberId,
            (status) => {
                if (status === 208) {
                    //신청내역존재시
                    //Axios Interceptor 때문에 200번대로 내려줌.
                    setMemberIdIsValid("D");
                } else if (status === 204) {
                    setMemberIdIsValid("Y");
                } else if (status === 200) {
                    setMemberIdIsValid("N");
                } else {
                    alert("서버 에러");
                    return;
                }
            },
            (error_result) => {
                alert("서버 에러");
                setMemberIdIsValid("");
            }
        );
    };

    const [registerAlert, setRegisterAlert] = useState(false);
    const [registerValid, setRegisterVlid] = useState(false);

    const memberInfoObject = {
        member_id: registerMemberId,
        name: registerName,
        email: registerEmailId + "@" + registerEmailHost,
        accept_mail: emailYn ? "Y" : "N",
        accept_sms: smsYn ? "Y" : "N",
        password: registerMemberPassword,
        phone: `${registerMemberPhoneFirst}-${registerMemberPhoneMid}-${registerMemberPhoneLast}`,
        cell: `${registerMemberPhoneFirst}-${registerMemberPhoneMid}-${registerMemberPhoneLast}`,
        birth: `${selectedYear}-${selectedMonth}-${selectedDate}`,
        address: selectedAddress,
        address_sub: enteredAddress,
        zip: zipCode, //업데이트할때만 zip_code인듯.
        gender: gender,
        reason: registerReason,
        auth_type: "0",
    };

    const postHandler = () => {
        if (memberIdIsValid === "N" || memberIdIsValid === "" || memberIdIsValid === "D") {
            alert("아이디 중복확인을 해주세요.");
            return;
        }
        if (!passwordRegexCheck) {
            alert("비밀번호 양식에 맞게 입력해주세요.");
            return;
        }
        if (registerName.trim() === "") {
            return;
        }
        if (registerName.trim() === "") {
            return;
        }
        if (registerEmailId.trim() === "") {
            return;
        }
        if (registerEmailHost.trim() === "") {
            return;
        }
        if (registerMemberPassword.trim() === "") {
            return;
        }
        if (registerMemberPhoneFirst.trim() === "") {
            return;
        }
        if (registerMemberPhoneMid.trim() === "") {
            return;
        }
        if (registerMemberPhoneLast.trim() === "") {
            return;
        }
        if (selectedYear.trim() === "") {
            return;
        }
        if (selectedMonth.trim() === "") {
            return;
        }
        if (selectedDate.trim() === "") {
            return;
        }
        if (selectedAddress.trim() === "") {
            return;
        }
        if (enteredAddress.trim() === "") {
            return;
        }
        if (zipCode.trim() === "") {
            return;
        }
        if (gender.trim() === "") {
            return;
        }
        if (registerReason.trim() === "") {
            return;
        }

        ReqRegisterMember(
            memberInfoObject,
            (result) => {
                if (result.status === 200) {
                    setRegisterAlert(true);
                } else {
                    alert("서버 에러");
                }
            },
            (error_result) => {
                alert("서버 에러");
            }
        );
    };

    return (
        <section>
            <div className={classes.admin_contents}>
                <h3>회원목록 - 등록</h3>
                <div className={`${classes.contents_wrap} ${classes.search_wrap} `}>
                    <div className={classes.admin_table_wrap}>
                        <table>
                            <colgroup>
                                <col style={{ width: "159px" }} />
                                <col style={{ width: "*" }} />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>
                                        이름<span className="require_red">*</span>
                                    </th>
                                    <td>
                                        <MultiInput
                                            value={registerName}
                                            onChange={onChangeInput}
                                            name="registerName"
                                            className="admin_st_sm"
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        생년월일<span className="require_red">*</span>
                                    </th>
                                    <td>
                                        <div className={`${classes.flex_row} ${classes.multi}`}>
                                            <SelectBoxNew
                                                className="admin_st w_sm"
                                                optionDatas={years}
                                                showDefaultKey={selectedYear}
                                                setSelectItemCB={selectedYearHandler}
                                                name="registerBirthYear"
                                            />
                                            <span>년</span>
                                            <SelectBoxNew
                                                className="admin_st w_sm"
                                                optionDatas={months}
                                                showDefaultKey={selectedMonth}
                                                setSelectItemCB={selectedMonthHandler}
                                                name="registerBirthMonth"
                                            />
                                            <span>월</span>
                                            <SelectBoxNew
                                                className="admin_st w_sm"
                                                optionDatas={days}
                                                showDefaultKey={selectedDate}
                                                setSelectItemCB={selectedDateHandler}
                                                name="registerBirthDate"
                                            />
                                            <span>일</span>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        성별<span className="require_red">*</span>
                                    </th>
                                    <td>
                                        <div className={classes.radio_wrap}>
                                            <Radio
                                                value="M"
                                                title="남"
                                                name="rad_01"
                                                id="rad_01_01"
                                                checked={gender === "M"}
                                                onChange={genderHandler}
                                            />
                                            <Radio
                                                value="F"
                                                title="여"
                                                name="rad_01"
                                                id="rad_01_02"
                                                checked={gender === "F"}
                                                onChange={genderHandler}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        아이디<span className="require_red">*</span>
                                    </th>
                                    <td>
                                        <div className={classes.flex_row}>
                                            <MultiInput
                                                className="admin_st_sm"
                                                value={registerMemberId}
                                                onChange={onChangeInput}
                                                name="registerMemberId"
                                            />
                                            <Button className="border sm" onClick={idDuplicationHandler}>
                                                아이디 중복확인
                                            </Button>
                                            {/**중복됐을경우 N으로 변경 */}
                                            {memberIdIsValid === "Y" && <p>사용가능한 아이디입니다.</p>}
                                            {memberIdIsValid === "N" && (
                                                <p className="red_c">이미 사용중인 아이디입니다. 다시 확인해주세요.</p>
                                            )}
                                            {memberIdIsValid === "D" && (
                                                <p className={classes.required}>
                                                    서면 회원 가입 신청 이력이 있는 ID입니다.
                                                </p>
                                            )}
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        비밀번호<span className="require_red">*</span>
                                    </th>
                                    <td>
                                        <div className={classes.flex_row}>
                                            <MultiInput
                                                className="admin_st_sm"
                                                value={registerMemberPassword}
                                                onChange={onChangeInput}
                                                name="registerMemberPassword"
                                                type="password"
                                            />
                                            <p className={classes.desc_gray}>
                                                영 대소문자.숫자,특수문자를 포함하여 6~20자 이내로 입력하세요.
                                            </p>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        비밀번호 확인<span className="require_red">*</span>
                                    </th>
                                    <td>
                                        <div className={classes.flex_row}>
                                            <MultiInput
                                                className="admin_st_sm"
                                                value={registerMemberPasswordCheck}
                                                onChange={onChangeInput}
                                                name="registerMemberPasswordCheck"
                                                type="password"
                                            />
                                            <p className={classes.desc_gray}>비밀번호를 다시 한번 입력해 주세요</p>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>주소</th>
                                    <td>
                                        <div className={classes.flex_row}>
                                            <Input className="admin_st_sm" value={zipCode} onChange={setZipCode} />
                                            <Button className="border sm" onClick={openDaumPostcodeHandler}>
                                                우편번호 검색
                                            </Button>
                                        </div>
                                        <div className={`${classes.flex_row} ${classes.multi}`}>
                                            <Input
                                                className="admin_st w_50 "
                                                value={selectedAddress}
                                                onChange={setSelectedAddress}
                                            />
                                            <Input
                                                className="admin_st w_50 "
                                                value={enteredAddress}
                                                onChange={setEnteredAddress}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        휴대폰<span className="require_red">*</span>
                                    </th>
                                    <td>
                                        <div className={`${classes.flex_row} ${classes.multi}`}>
                                            <MultiInput
                                                className="admin_st sm"
                                                value={registerMemberPhoneFirst}
                                                onChange={onChangeInput}
                                                name="registerMemberPhoneFirst"
                                            />
                                            <span>-</span>
                                            <MultiInput
                                                className="admin_st sm"
                                                value={registerMemberPhoneMid}
                                                onChange={onChangeInput}
                                                name="registerMemberPhoneMid"
                                            />
                                            <span>-</span>
                                            <MultiInput
                                                className="admin_st sm"
                                                value={registerMemberPhoneLast}
                                                onChange={onChangeInput}
                                                name="registerMemberPhoneLast"
                                            />
                                            <Checkbox
                                                className="admin_label"
                                                id="chk_01"
                                                title="SMS 수신설정"
                                                onChange={smsYnHandler}
                                                checked={smsYn === true}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        이메일<span className="require_red">*</span>
                                    </th>
                                    <td>
                                        <div className={`${classes.flex_row} ${classes.multi}`}>
                                            <MultiInput
                                                className="admin_st_sm"
                                                value={registerEmailId}
                                                onChange={onChangeInput}
                                                name="registerEmailId"
                                            />
                                            <span>@</span>
                                            <Input
                                                className="admin_st_sm"
                                                value={registerEmailHost}
                                                onChange={emailHostHandler}
                                                name="registerEmailHost"
                                            />
                                            <SelectBoxNew
                                                className="admin_st "
                                                optionDatas={emailHostList}
                                                setSelectItemCB={emailHostHandler}
                                                showDefaultKey={registerEmailHost}
                                            />
                                            <Checkbox
                                                className="admin_label"
                                                id="chk_02"
                                                title="뉴스레터 및 안내메일 수신 설정"
                                                onChange={emailYnHandler}
                                                checked={emailYn === true}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        상태<span className="require_red">*</span>
                                    </th>
                                    <td>활동</td>
                                </tr>
                                <tr>
                                    <th>
                                        등록 사유<span className="require_red">*</span>
                                    </th>
                                    <td>
                                        <MultiInput
                                            className="admin_st"
                                            placeHolder="예) 테스트 계정 등록"
                                            value={registerReason}
                                            onChange={onChangeInput}
                                            name="registerReason"
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className={classes.bottom_btn_wrap}>
                            <Button className="secondary sm" onClick={postHandler}>
                                등록
                            </Button>
                            <Button className="border sm" onClick={navigateHandler}>
                                목록
                            </Button>
                        </div>
                        {isDaumPostcodeOpen && (
                            <Modal className="admin_st" close={closeDaumPostcodeHandler} header="우편번호 검색">
                                <DaumPostcode onComplete={handleAddressSelect} />
                            </Modal>
                        )}
                        {registerValid && (
                            <Alert type="alert" confirm="확인" close={() => setRegisterVlid(false)}>
                                {"필수항목을 입력해주세요."}
                            </Alert>
                        )}
                        {registerAlert && (
                            <Alert type="alert" confirm="확인" close={navigateHandler}>
                                {"등록되었습니다."}
                            </Alert>
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
}

export default MngUserRegisterForm;
