import React, { useEffect } from "react";
import classes from "./ViewLocation.module.css";

function ViewLocation(props) {
    const { kakao } = window;
    useEffect(() => {
        if (kakao) {
            //지도를 담을 영역의 DOM 레퍼런스
            const container = document.getElementById("map");

            //지도를 생성할 때 필요한 기본 옵션
            const options = {
                center: new kakao.maps.LatLng(37.29432708770308, 126.99658273529194), //지도의 중심좌표.
                level: 2, //지도의 레벨(확대, 축소 정도) 낮을수록 확대
            };

            //지도 생성 및 객체 리턴
            const map = new kakao.maps.Map(container, options);

            // 주소-좌표 변환 객체를 생성합니다
            var geocoder = new kakao.maps.services.Geocoder();

            // 주소로 좌표를 검색합니다
            geocoder.addressSearch(`${props.centerDefaultAddress}`, function (result, status) {
                // 정상적으로 검색이 완료됐으면
                if (status === kakao.maps.services.Status.OK) {
                    var coords = new kakao.maps.LatLng(result[0].y, result[0].x);

                    // 결과값으로 받은 위치를 마커로 표시합니다
                    var marker = new kakao.maps.Marker({
                        map: map,
                        position: coords,
                    });

                    // 지도의 중심을 결과값으로 받은 위치로 이동시킵니다
                    map.setCenter(coords);
                }
            });
        }
    }, []);

    return (
        <main className={classes.view_location_wrap}>
            <div className={classes.map_wrap} id="map"></div>
            <div className={classes.info_wrap}>
                <h3>
                    {props.region} {props.name}
                </h3>
                <table>
                    <colgroup>
                        <col style={{ width: "38%" }} />
                        <col style={{ width: "62%" }} />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>주소</th>
                            <td>
                                도로명 - {props.centerDefaultAddress}
                                <br />
                                {props.centerDetailedAddress}
                            </td>
                        </tr>
                        <tr>
                            <th>전화번호</th>
                            <td>{props.centerPhoneNumber}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </main>
    );
}
export default ViewLocation;
