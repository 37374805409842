import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import classes from "../TournamentContainerApp.module.css";
import { useRef, useState } from "react";

function ResultGalleryApp({ fileUris,arr }) {
    
    const [slideIndex, setSlideIndex] = useState(0);
    const [updateCount, setUpdateCount] = useState(0);
    const sliderRef = useRef(null);
    const sliderSettings = {
        className: "gallery_slide",
        lazyLoad: true,
        infinite: arr>3,
        speed: 300,
        slidesToShow: 2,
        // slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        afterChange: () => setUpdateCount(updateCount + 1),
        beforeChange: (current, next) => setSlideIndex(next),
        responsive: [
            {
                breakpoint: 1360,
                settings: {
                    arrows: false,
                },
            },
        ],
    };

    return (
        <div className={classes.result_gallery_wrap}>
            <h4>대회 갤러리</h4>
            <div className={classes.result_gallery}>
                <Slider ref={sliderRef} {...sliderSettings}>
                    {fileUris.map((data, index) => (
                        <div className={classes.content} key={index+1}>
                            <img src={`${data.uri}`} alt={`대회 결과 이미지${index + 1}`} />
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    );

}

export default ResultGalleryApp;
