import React, { useRef, useMemo } from "react";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import UploadAttachImage from "../../../api/common/QuillEditorUploadApi";

/**
 * https://codesandbox.io/s/react-quill-with-functional-component-68zcnt?file=/src/index.js
 */
const QuillEditor = React.forwardRef(({value, width, height, minHeight, onValueChangeCB}, ref) => {
    const quillRef = useRef();
    // const [value, setValue] = useState("");

    // Editor 에서 첨부하는 이미지를 업로드 하고 이를 다시 화면에 뿌려주는 Handler
    const imageHandler = async () => {
        const input = document.createElement("input");
        input.setAttribute("type", "file");
        input.setAttribute("accept", "image/*");
        input.click();
        input.onchange = async () => {
            const file = input && input.files ? input.files[0] : null;
            UploadAttachImage(file, (imgUrl) => {
                // 에디터 객체 가져오기
                const quillObj = quillRef.current.getEditor();
                // 2. 현재 에디터 커서 위치값을 가져온다
                const range = quillObj.getSelection();
                // 가져온 위치에 이미지를 삽입한다
                quillObj.insertEmbed(range.index, "image", imgUrl);
            }, (error) => {
                alert("이미지 업로드에 실패하셨습니다.");
            });
        };
    };

    const modules = useMemo(() => {
        return {
            toolbar: {
                container: [
                    [{ header: [1, 2, 3, 4, 5, 6, false] }],
                    ["bold", "italic", "underline", "strike", "blockquote"],
                    [{ size: ["small", false, "large", "huge"] }],
                    // [{ font: [] }],
                    [{ align: ["right", "center", "justify"] }],
                    [{ list: "ordered" }, { list: "bullet" }],
                    ["link", "image"],
                    //", "code-block"],
                    [{ color: ["black", "red", "blue", "green", "yellow", "orange"] }],
                    // [{ background: ["black", "red", "white"] }],
                ],
                handlers: {
                    image: imageHandler,
                },
            },
        };
    }, []);

    const formats = [
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "link",
        "color",
        "image",
        "background",
        "align",
        "size",
        "font",
    ];

    const handleProcedureContentChange = (content, delta, source, editor) => {
        // setValue(content); 상위 컴포넌트에서 처리하도록 한다.
        if (onValueChangeCB)
            onValueChangeCB(content);
    };

    return (
        <>
            <ReactQuill
                style={{ height: `${height}px`, width: `${width}px`}}
                ref={quillRef}
                theme="snow"
                modules={modules}
                formats={formats}
                value={value}
                onChange={handleProcedureContentChange}
            />
        </>
    );
});

export default QuillEditor;
