import { useState } from "react";
import Button from "../../../../components/UI/Button/Button";
import classes from "../../MngCommon.module.css";
import { useLoaderData, useLocation, useNavigate } from "react-router-dom";
import { ReqSmartFilterDelete, ReqSmartFilterDetail } from "../../../../api/product/SmartFilterApi";

function MngProductSmartFilterDetail(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const smartFilterData = useLoaderData();

    console.log(smartFilterData);

    const toList = () => {
        navigate("/admin/product/smartFilter", { state: location.state });
    };

    const updateHandler = () => {
        navigate(`/admin/product/smartFilter/update/${smartFilterData.id}`, { state: location.state });
    };

    const deleteHandler = async () => {
        if (window.confirm("삭제 하시겠습니까?")) {
            const response = await ReqSmartFilterDelete(smartFilterData.id);
            if (response.status === 200) {
                navigate("/admin/product/smartFilter", { state: location.state });
            } else {
                alert(`[${response.status}] ${response.data ? response.data.error : "삭제 실패"} `);
            }
        }
    };

    return (
        <section>
            <div className={classes.admin_contents}>
                <h3>상품정보/스마트필터관리 - 상세</h3>
                <div className={`${classes.contents_wrap} ${classes.search_wrap} `}>
                    <div className={classes.admin_table_wrap}>
                        <table>
                            <colgroup>
                                <col style={{ width: "159px" }} />
                                <col style={{ width: "620px" }} />
                                <col style={{ width: "159px" }} />
                                <col style={{ width: "482px" }} />
                                {/* <col style={{ width: "138px" }} /> */}
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>등록일</th>
                                    <td>{smartFilterData.regDate}</td>
                                    <th>등록자</th>
                                    <td colSpan={1}>{smartFilterData.regID}</td>
                                </tr>
                                <tr>
                                    <th>사용여부</th>
                                    <td colSpan={4}>{smartFilterData.exposeYn ? "사용" : "미사용"}</td>
                                </tr>
                                <tr>
                                    <th>적용 카테고리</th>
                                    <td colSpan={4}>{smartFilterData.categoryName}</td>
                                </tr>
                                <tr>
                                    <th>제목</th>
                                    <td colSpan={4}>{smartFilterData.title}</td>
                                </tr>
                                <tr className={classes.th_st}>
                                    <th>구분</th>
                                    <th>항목</th>
                                    <th colSpan={3}>유형</th>
                                    {/* <th>삭제</th> */}
                                </tr>
                                {/* Display smartFilterMenus */}
                                {smartFilterData.smartFilterMenus.map((menu, index) => (
                                    <tr key={menu.id}>
                                        {index === 0 && <th rowSpan={smartFilterData.smartFilterMenus.length}>스마트필터</th>}
                                        <td>{menu.title}</td>
                                        <td colSpan={3} className={classes.align_center}>
                                            {/* {menu.drop ? "드롭다운" : "필터 값 설정"} */}
                                            필터 값 설정
                                        </td>
                                        {/* <td className={classes.align_center}>
                                             <Button className="sm" onClick={() => deleteSmartFilter(menu.id)}>
                                                삭제
                                            </Button> 
                                        </td>*/}
                                    </tr>
                                ))}

                                {/* Display extInformationMenus */}
                                {smartFilterData.extInformationMenus.map((menu, index) => (
                                    <tr key={menu.id}>
                                        {index === 0 && <th rowSpan={smartFilterData.extInformationMenus.length}>상품정보</th>}
                                        <td>{menu.title}</td>
                                        <td colSpan={3} className={classes.align_center}>
                                            입력형
                                        </td>
                                        {/* <td className={classes.align_center}>
                                             <Button className="sm" onClick={() => deleteSmartFilter(menu.id)}>
                                                삭제
                                            </Button> 
                                        </td>*/}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div className={classes.bottom_btn_wrap}>
                            <Button className="border sm" onClick={updateHandler}>
                                수정
                            </Button>
                            <Button className="sm" onClick={deleteHandler}>
                                삭제
                            </Button>
                            <Button className="secondary sm" onClick={toList}>
                                목록
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default MngProductSmartFilterDetail;

export const loader = async ({ params }) => {
    const response = await ReqSmartFilterDetail(params.productId);
    if (response.status === 200) {
        return response.data;
    }
    return null;
};
