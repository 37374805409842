import { NavLink, useNavigate } from "react-router-dom";
import classes from "../MngCommon.module.css";
import { useContext } from "react";
import { JwtTokenContext } from "../../../store/JwtTokenProvider";

function MngTournamentNavigation(props) {
    const navigate = useNavigate();
    const loginCtx = useContext(JwtTokenContext);

    // console.log('MngTournamentNavigation :: ', loginCtx.token, loginCtx.isAdmin(), loginCtx.isLogin());
    // 여기를 주석 처리하면 Default adminlogin 으로 이동하지 않는다.
    if (!loginCtx.isAdmin()) {
        navigate("/");
    }

    let view;
    // UserType 이 볼링센터이면
    if (loginCtx.userType === 'C') {
        view = (
            <div className={classes.admin_sub_nav}>
                <nav>
                    <ul className={classes.depth_1}>
                        <li>
                            <NavLink to="detail">대회관리</NavLink>
                            <ul className={classes.depth_2}>
                                <li>
                                    <NavLink to="print">대회진행 인쇄물</NavLink>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <NavLink to="result">대회결과</NavLink>
                            <ul className={classes.depth_2}>
                                <li>
                                    <NavLink to="resultUpload">
                                        대회 진행결과 업로드
                                    </NavLink>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </nav>
            </div>            
        );
    } //일반 회원 || NO_TOKEN view = <div></div>
    else {
        view = (
            <div className={classes.admin_sub_nav}>
                <nav>
                    <ul className={classes.depth_1}>
                        <li>
                            <NavLink to="banner">대회메인</NavLink>
                            <ul className={classes.depth_2}>
                                <li>
                                    <NavLink to="banner">
                                        대회메인 배너 관리
                                    </NavLink>
                                </li>
                            </ul>
                        </li>
                        <li className={classes.depth_1_link}>
                            <NavLink to="notice">대회공지</NavLink>
                        </li>
                        <li>
                            <NavLink to="detail">대회관리</NavLink>
                            <ul className={classes.depth_2}>
                                <li>
                                    <NavLink to="detail">대회등록</NavLink>
                                </li>
                                <li>
                                    <NavLink to="stadium">경기장등록</NavLink>
                                </li>
                                <li>
                                    <NavLink to="bowlingAlley">볼링장등록</NavLink>
                                </li>
                                <li>
                                    <NavLink to="publish">게재관리</NavLink>
                                </li>
                                <li>
                                    <NavLink to="apply">접수/참가관리</NavLink>
                                </li>
                                <li>
                                    <NavLink to="autoSend">
                                        문자 자동발송 관리
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="print">대회진행 인쇄물</NavLink>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <NavLink to="result">대회결과</NavLink>
                            <ul className={classes.depth_2}>
                                <li>
                                    <NavLink to="result">대회결과 목록</NavLink>
                                </li>
                                <li>
                                    <NavLink to="resultUpload">
                                        대회 진행결과 업로드
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="resultRecord">
                                        대회성적 등록/조회
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="ball">대회 사용구 조회</NavLink>
                                </li>
                                <li>
                                    <NavLink to="registerSerial">
                                        대회 사용구
                                        <br />
                                        시리얼넘버 등록
                                    </NavLink>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </nav>
            </div>
        );
    }

    return view;
}

export default MngTournamentNavigation;
