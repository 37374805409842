import { useState } from "react";
import Button from "../../../../components/UI/Button/Button";
import Checkbox, { CheckboxA } from "../../../../components/UI/Input/Checkbox";
import Input, { MultiInput } from "../../../../components/UI/Input/Input";
import SelectBoxNew from "../../../../components/UI/SelectBox/SelectBoxNew";
import classes from "../../MngCommon.module.css";
import { useLoaderData, useNavigate, useParams } from "react-router-dom";
import instance from "../../../../store/AxiosInterceptor";
import { adminStatusTypes, defaultEmailHostList } from "../../../../components/definedefault/DefSelectBoxValues";
import Alert from "../../../../components/UI/Modal/Alert";
import { ReqUpdateAdmin } from "../../../../api/member/AdminMemberApi";
function MngAdminAccountUpdate() {
    const navigate = useNavigate();

    const resData = useLoaderData();
    const params = useParams();
    const adminId = params.adminId;

    const toList = () => {
        navigate("/admin/users/adminAccount");
    };

    const emailHostList = defaultEmailHostList;
    const [registerEmailHost, setRegisterEmailHost] = useState(resData.admin_email?.split("@").at(1));
    const emailHostHandler = (key) => {
        setRegisterEmailHost(key);
    };

    const adminStatusType = adminStatusTypes;
    const [adminStutus, setAdminStatus] = useState("");
    const adminStatusHandler = (value) => {
        setAdminStatus(value);
    };

    const [inputs, setInputs] = useState({
        password: "",
        passwordCheck: "",
        adminName: resData?.admin_name,
        phoneNum1: resData.admin_phone?.slice(0,3),
        phoneNum2: resData.admin_phone?.slice(3,7),
        phoneNum3: resData.admin_phone?.slice(7,11),
        firstEmail: resData.admin_email?.split("@").at(0),
    });

    const { password, passwordCheck, adminName, phoneNum1, phoneNum2, phoneNum3, firstEmail } = inputs; // 비구조화 할당

    function onChange(e) {
        const { value, name } = e.target;
        setInputs({
            ...inputs,
            [name]: value,
        });
    }

    // 대회 운영 / 대회등록
    const [ctMain, setCtMain] = useState(resData.ct_main === 2);
    const [ctMain_1, setCtMain_1] = useState(resData.ct_main === 1);

    // 대회 운영 / 경기장 등록
    const [ctPlace, setCtPlace] = useState(resData.ct_place === 2);
    const [ctPlace_1, setCtPlace_1] = useState(resData.ct_place === 1);

    // 대회 운영 / 볼링장 등록
    const [ctCenter, setCtCenter] = useState(resData.ct_center === 2);
    const [ctCenter_1, setCtCenter_1] = useState(resData.ct_center === 1);

    // 대회 운영 / 게재 관리
    const [ctMngExpose, setCtMngExpose] = useState(resData.ct_mng_expose === 2);
    const [ctMngExpose_1, setCtMngExpose_1] = useState(resData.ct_mng_expose === 1);

    // 대회 운영 / 접수/참가관리
    const [ctMngApplier, setCtMngApplier] = useState(resData.ct_mng_applier === 2);
    const [ctMngApplier_1, setCtMngApplier_1] = useState(resData.ct_mng_applier === 1);

    // 대회 운영 / 문자발송 관리
    const [ctConfigSms, setCtConfigSms] = useState(resData.ct_config_sms === 2);
    const [ctConfigSms_1, setCtConfigSms_1] = useState(resData.ct_config_sms === 1);

    // 상품관리
    const [product, setProduct] = useState(resData.product === 2);

    // 케겔관리
    const [kegel, setKegel] = useState(resData.kegel === 2);

    // 커뮤니티
    const [community, setCommunity] = useState(resData.community === 2);

    // 회사소개
    const [intro, setIntro] = useState(resData.intro === 2);

    // 이벤트
    const [event, setEvent] = useState(resData.event === 2);

    // 회원관리
    const [member, setMember] = useState(resData.member === 2);

    // 웹사이트관리
    const [website, setWebsite] = useState(resData.website === 2);

    // 통계
    const [statistics, setStatistics] = useState(resData.statistics === 2);

    // 볼링장 (볼링장 1일 경우 해당 볼링장 화면만 접근 가능해야 함)
    const [bowlingCenter, setBowlingCenter] = useState(resData.bowling_center === 1);
    const [bowlingCenterModal, setBowlingCenterModal] = useState(false);
    const [successModal, setSuccessModal] = useState(false);

    const updateObject = {
        ct_main: !ctMain && !ctMain_1 ? 0 : ctMain ? 2 : 1,
        ct_place: !ctPlace && !ctPlace_1 ? 0 : ctPlace ? 2 : 1,
        ct_center: !ctCenter && !ctCenter_1 ? 0 : ctCenter ? 2 : 1,
        ct_mng_expose: !ctMngExpose && !ctMngExpose_1 ? 0 : ctMngExpose ? 2 : 1,
        ct_mng_applier: !ctMngApplier && !ctMngApplier_1 ? 0 : ctMngApplier ? 2 : 1,
        ct_config_sms: !ctConfigSms && !ctConfigSms_1 ? 0 : ctConfigSms ? 2 : 1,
        product: product ? 2 : 0,
        kegel: kegel ? 2 : 0,
        community: community ? 2 : 0,
        intro: intro ? 2 : 0,
        event: event ? 2 : 0,
        member: member ? 2 : 0,
        website: website ? 2 : 0,
        statistics: statistics ? 2 : 0,
        bowling_center: bowlingCenter ? 1 : 0,
        password: password,
        admin_name: adminName,
        email: `${firstEmail}@${registerEmailHost}`,
        phone: `${String(phoneNum1)}${String(phoneNum2)}${String(phoneNum3)}`,
        expired : adminStutus
    };

    const confirmHandler = () => {
        if (password.trim() !== passwordCheck.trim()) {
            alert("패스워드가 서로 다릅니다.");
            return;
        }
        if (phoneNum1.trim() === "") {
            alert("연락처를 확인해주세요");
            return;
        }
        if (phoneNum2.trim() === "") {
            alert("연락처를 확인해주세요");
            return;
        }
        if (phoneNum3.trim() === "") {
            alert("연락처를 확인해주세요");
            return;
        }

        if (bowlingCenter) {
            setBowlingCenterModal(true);
            return;
        } else {
            updateHandler();
        }
    };

    const updateHandler = () => {
        setBowlingCenterModal(false);
        ReqUpdateAdmin(
            adminId,
            updateObject,
            (result) => {
                if (result.status === 200) {
                    setSuccessModal(true);
                    return;
                }
            },
            (error_result) => {
                alert("에러");
            }
        );
    };

    return (
        <section>
            <div className={classes.admin_contents}>
                <h3>관리자 계정관리 - 수정</h3>
                <div className={`${classes.contents_wrap} ${classes.search_wrap} `}>
                    <div className={classes.admin_table_wrap}>
                        <table>
                            <colgroup>
                                <col style={{ width: "159px" }} />
                                <col style={{ width: "*" }} />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>아이디</th>
                                    <td>{resData.admin_member_id}</td>
                                </tr>
                                <tr>
                                    <th>
                                        비밀번호
                                        <span className="require_red">*</span>
                                    </th>
                                    <td>
                                        <div className={classes.flex_row}>
                                            <MultiInput
                                                className="admin_st"
                                                name="password"
                                                type="password"
                                                value={password}
                                                onChange={onChange}
                                            />
                                            <p className={classes.desc_gray}>
                                                6~20자 영문, 숫자로만 공백 없이 입력하세요.
                                            </p>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        비밀번호 확인
                                        <span className="require_red">*</span>
                                    </th>
                                    <td>
                                        <div className={classes.flex_row}>
                                            <MultiInput
                                                className="admin_st"
                                                name="passwordCheck"
                                                type="password"
                                                value={passwordCheck}
                                                onChange={onChange}
                                            />
                                            <p className={classes.desc_gray}>비밀번호를 다시 한번 입력해 주세요.</p>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        관리자명
                                        <span className="require_red">*</span>
                                    </th>
                                    <td>
                                        <div className={classes.flex_row}>
                                            <MultiInput
                                                className="admin_st"
                                                name="adminName"
                                                value={adminName}
                                                onChange={onChange}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        담당업무
                                        <span className="require_red">*</span>
                                    </th>
                                    <td>
                                        <div className={classes.flex_row}>{resData.assigned_task}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        연락처
                                        <span className="require_red">*</span>
                                    </th>
                                    <td>
                                        <div className={`${classes.flex_row} ${classes.multi}`}>
                                            <MultiInput
                                                className="admin_st sm"
                                                name="phoneNum1"
                                                value={phoneNum1}
                                                onChange={onChange}
                                            />
                                            <span>-</span>
                                            <MultiInput
                                                className="admin_st sm"
                                                name="phoneNum2"
                                                value={phoneNum2}
                                                onChange={onChange}
                                            />
                                            <span>-</span>
                                            <MultiInput
                                                className="admin_st sm"
                                                name="phoneNum3"
                                                value={phoneNum3}
                                                onChange={onChange}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        이메일
                                        <span className="require_red">*</span>
                                    </th>
                                    <td>
                                        <div className={`${classes.flex_row} ${classes.multi}`}>
                                            <MultiInput
                                                className="admin_st"
                                                name="firstEmail"
                                                value={firstEmail}
                                                onChange={onChange}
                                            />
                                            <span>@</span>
                                            <Input
                                                className="admin_st"
                                                value={registerEmailHost}
                                                name="registerEmailHost"
                                                onChange={setRegisterEmailHost}
                                            />
                                            <SelectBoxNew
                                                className="admin_st"
                                                optionDatas={emailHostList}
                                                showDefaultKey={registerEmailHost}
                                                setSelectItemCB={emailHostHandler}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>상태</th>
                                    <td>
                                        <SelectBoxNew
                                            className="admin_st"
                                            optionDatas={adminStatusType}
                                            showDefaultKey={adminStutus}
                                            setSelectItemCB={adminStatusHandler}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className={`${classes.contents_wrap} ${classes.search_wrap}`}>
                    <div className={`${classes.admin_table_wrap} ${classes.align_center} `}>
                        <h5>업무권한</h5>
                        <table>
                            <tbody>
                                <tr>
                                    <th rowSpan={2}></th>
                                    <th colSpan={6}>대회운영</th>
                                    <th rowSpan={2}>
                                        상품
                                        <br />
                                        관리
                                    </th>
                                    <th rowSpan={2}>케겔</th>
                                    <th rowSpan={2}>
                                        커뮤
                                        <br />
                                        니티
                                    </th>
                                    <th rowSpan={2}>
                                        회사
                                        <br />
                                        소개
                                    </th>
                                    <th rowSpan={2}>이벤트</th>
                                    <th rowSpan={2}>
                                        회원
                                        <br />
                                        관리
                                    </th>
                                    <th rowSpan={2}>
                                        웹사이트
                                        <br />
                                        관리
                                    </th>
                                    <th rowSpan={2}>통계</th>
                                    <th rowSpan={2}>
                                        <span className="require_red">*</span>
                                        <br />
                                        볼링장
                                    </th>
                                </tr>
                                <tr>
                                    <th>
                                        대회
                                        <br />
                                        등록
                                    </th>
                                    <th>
                                        경기장
                                        <br />
                                        등록
                                    </th>
                                    <th>
                                        볼링장
                                        <br />
                                        등록
                                    </th>
                                    <th>
                                        게재
                                        <br />
                                        관리
                                    </th>
                                    <th>
                                        접수/
                                        <br />
                                        참가관리
                                    </th>
                                    <th>
                                        문자발송
                                        <br />
                                        관리
                                    </th>
                                </tr>
                                <tr>
                                    <th>
                                        등록
                                        <br />
                                        /수정
                                    </th>
                                    <td>
                                        <CheckboxA id="ct_main_1" checked={ctMain} onChange={setCtMain} />
                                    </td>
                                    <td>
                                        <CheckboxA id="ct_place_1" checked={ctPlace} onChange={setCtPlace} />
                                    </td>
                                    <td>
                                        <CheckboxA id="ct_center_1" checked={ctCenter} onChange={setCtCenter} />
                                    </td>
                                    <td>
                                        <CheckboxA
                                            id="ct_mng_expose_1"
                                            checked={ctMngExpose}
                                            onChange={setCtMngExpose}
                                        />
                                    </td>
                                    <td>
                                        <CheckboxA
                                            id="ct_mng_applier_1"
                                            checked={ctMngApplier}
                                            onChange={setCtMngApplier}
                                        />
                                    </td>
                                    <td>
                                        <CheckboxA
                                            id="ct_config_sms_1"
                                            checked={ctConfigSms}
                                            onChange={setCtConfigSms}
                                        />
                                    </td>
                                    <td rowSpan={2}>
                                        <CheckboxA id="product" checked={product} onChange={setProduct} />
                                    </td>
                                    <td rowSpan={2}>
                                        <CheckboxA id="kegel" checked={kegel} onChange={setKegel} />
                                    </td>
                                    <td rowSpan={2}>
                                        <CheckboxA id="community" checked={community} onChange={setCommunity} />
                                    </td>
                                    <td rowSpan={2}>
                                        <CheckboxA id="intro" checked={intro} onChange={setIntro} />
                                    </td>
                                    <td rowSpan={2}>
                                        <CheckboxA id="event" checked={event} onChange={setEvent} />
                                    </td>
                                    <td rowSpan={2}>
                                        <CheckboxA id="member" checked={member} onChange={setMember} />
                                    </td>
                                    <td rowSpan={2}>
                                        <CheckboxA id="website" checked={website} onChange={setWebsite} />
                                    </td>
                                    <td rowSpan={2}>
                                        <CheckboxA id="statistics" checked={statistics} onChange={setStatistics} />
                                    </td>
                                    <td rowSpan={2}>
                                        <CheckboxA
                                            id="bowling_center"
                                            checked={bowlingCenter}
                                            onChange={setBowlingCenter}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>조회</th>
                                    <td>
                                        <CheckboxA id="ct_main_2" checked={ctMain_1} onChange={setCtMain_1} />
                                    </td>
                                    <td>
                                        <CheckboxA id="ct_place_2" checked={ctPlace_1} onChange={setCtPlace_1} />
                                    </td>
                                    <td>
                                        <CheckboxA id="ct_center_2" checked={ctCenter_1} onChange={setCtCenter_1} />
                                    </td>
                                    <td>
                                        <CheckboxA
                                            id="ct_mng_expose_2"
                                            checked={ctMngExpose_1}
                                            onChange={setCtMngExpose_1}
                                        />
                                    </td>
                                    <td>
                                        <CheckboxA
                                            id="ct_mng_applier_2"
                                            checked={ctMngApplier_1}
                                            onChange={setCtMngApplier_1}
                                        />
                                    </td>
                                    <td>
                                        <CheckboxA
                                            id="ct_config_sms_2"
                                            checked={ctConfigSms_1}
                                            onChange={setCtConfigSms_1}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className={classes.bottom_btn_wrap}>
                            <Button className="secondary sm" onClick={confirmHandler}>
                                등록
                            </Button>
                            <Button className="border sm" onClick={toList}>
                                목록
                            </Button>
                        </div>
                        {bowlingCenterModal && (
                            <Alert
                                type="confirm"
                                cancel="취소"
                                confirm="확인"
                                close={() => setBowlingCenterModal(false)}
                                confirmHandler={updateHandler}
                            >
                                {"모든 권한은 사용이 불가능하고, 볼링장만 적용됩니다."}
                            </Alert>
                        )}
                        {successModal && (
                            <Alert type="alert" confirm="확인" close={toList}>
                                {"수정되었습니다."}
                            </Alert>
                        )}
                    </div>
                </div>
                <div className={classes.info_box}>
                    <p>* 볼링장 관리자의 경우 아래메뉴에서 자신의 볼링장에 대한 데이터만 조회/등록 가능</p>
                    <ul>
                        <li>
                            - 대회운영 {">"} 대회관리 {">"} 대회진행 인쇄물
                        </li>
                        <li>
                            - 대회운영 {">"} 대회결과 {">"} 대회 진행결과 업로드
                        </li>
                    </ul>
                    <p>
                        * 관리자 계정관리에서 볼링장관리자로 아이디 생성 후 ‘대회운영
                        {">"} 대회관리 {">"} 볼링장 등록‘에 해당 아이디 등록 시 자신의 볼링장에 대한 권한만 부여
                    </p>
                </div>
            </div>
        </section>
    );
}
export default MngAdminAccountUpdate;

//관리자 계정 상세 가져오기
export const loader = async ({ params }) => {
    const response = await instance({
        method: "GET",
        url: `/api/v1/admin/member/admin/${params.adminId}`,
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    }).catch(function (error) {
        if (error.response) {
            // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            return error.response;
        } else if (error.request) {
            // 요청이 이루어 졌으나 응답을 받지 못했습니다.
            console.log(error.request);
            return null;
        } else {
            // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
            console.log("Error", error.message);
            return null;
        }
    });
    if (response == null || response.status !== 200) {
        return [];
    }
    if (response.data.status === 200) {
        console.log(response.data)
        return response.data;
    } else {
        return [];
    }
};
//관리자 계정 목록 리스트 가져오기
