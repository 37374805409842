import Button from "../../../components/UI/Button/Button";
import TermsOfUseDetail from "../../other/TermsOfUseDetail";
import TermsPersonalInformationDetail from "../../other/TermsPersonalInformationDetail";
import classes from "./../TournamentContainerApp.module.css";

function TermsModalContentsApp({ className, close, ctAgreementInfo }) {

    let view = (<></>);
    if (ctAgreementInfo) {
        view = (        <>
            <main className={classes.terms_wrap}>
                <div
                    dangerouslySetInnerHTML={{
                        __html: ctAgreementInfo
                    }}
                ></div>

            </main>
            <footer>
                <Button className="secondary sm" onClick={close}>
                    확인
                </Button>
            </footer>
        </>)
    }
    else {
        view = (
        <>
            <main className={classes.terms_wrap}>
                {className === "terms_personal_info" ? (
                    <TermsPersonalInformationDetail />
                ) : (
                    <TermsOfUseDetail />
                )}

            </main>
            <footer>
                <Button className="secondary sm" onClick={close}>
                    확인
                </Button>
            </footer>
        </>)
    }

    return view;
}
export default TermsModalContentsApp;
