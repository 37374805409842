import React, { useEffect } from "react";
import classes from "./Modal.module.css";
import Button from "../Button/Button";
function Modal(props) {
  const { close, header, children, desc } = props;
  // ↓ 퍼블단에서 화면 확인만을 위해 작성한 코드 입니다.
  //클래스명 조합
  const { className = "" } = props;
  const classNames = Array.isArray(className)
    ? className
    : className.split(" ");

  const combinedClasses = classNames
    .map((cn) => classes[cn])
    .filter((cn) => cn)
    .join(" ");

  const body = document.getElementById("bodyWrap");

  const disableScroll = () => {
    body.style.overflow = "hidden";
    body.style.height = "100vh";
  };
  const enableScroll = () => {
    body.style.overflow = "auto";
    body.style.height = "auto";
  };
  useEffect(() => {
    // modal이 떠 있을 땐 스크롤 막음
    disableScroll();
    // modal 닫히면 다시 스크롤 가능하도록 함
    return () => enableScroll();
  }, []);
  return (
    <div className={`${classes.modal} ${combinedClasses}`}>
      <section>
        <header>
          <h2>
            {header}
            <span>{desc}</span>
          </h2>
          <Button className="close_x" onClick={close}>
            close
          </Button>
        </header>
        <div className={classes.modal_body}>{children}</div>
      </section>
    </div>
  );
}

export default Modal;
