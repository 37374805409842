import { useEffect, useState } from "react";
import classes from "../MngCommon.module.css";
import Pagination from "../../../components/UI/Table/Pagination";
import Button from "../../../components/UI/Button/Button";
import Input from "../../../components/UI/Input/Input";

import { ReqKegelList } from "../../../api/kegel/KegelAPi";
import { useLocation, useNavigate } from "react-router-dom";
import PaginationNew from "../../../components/UI/Table/PaginationNew";
import instance from "../../../store/AxiosInterceptor";
import SelectBoxKegel from "../../../components/UI/SelectBox/SelectBoxKegel";

function MngKegel(props) {
    const location = useLocation();
    const navigate = useNavigate();

    // const [kegelList, setKegelList] = useState(kegel);
    const [kegelList, setKegelList] = useState([]);

    // >>> 페이징을 위한 값 세팅
    const [totalPage, setTotalPage] = useState(); //총 페이지 갯수
    const [pageNumber, setPageNumber] = useState(location.state?.pageNumber ?? 0); //글목록 No. 값을세팅하기 위한 서버에서 들어오는값
    const [totalCount, setTotalCount] = useState(0);
    // <<< 페이징을 위한 값 세팅



    const sampleData = [
        {
            value: "레인정비기계",
            data: "MACHINE",
            disabled: "false",
            selected: "false",
        },
        {
            value: "레인정비소모품",
            data: "CONSUMABLE",
            disabled: "false",
            selected: "false",
        },
        {
            value: "훈련 도구",
            data: "TOOL",
            disabled: "false",
            selected: "false",
        },
    ];
    const exposeData = [
        {
            value: "노출",
            data: "Y",
            disabled: "false",
            selected: "false",
        },
        {
            value: "미노출",
            data: "N",
            disabled: "false",
            selected: "false",
        },
    ];
    const uploadHandler = () => {
        navigate("/admin/kegel/upload", {state: location.state});
    };
    const [selectedCategory, setSelectedCategory] = useState("");
    const [selectedCategoryData, setSelectedCategoryData] = useState("");

    const [selectedExpose, setSelectedExpose] = useState("");
    const [selectedExposeData, setSelectedExposeData] = useState("");

    // 조회수 증가
    // const increaseViews = (id) => {
    //     // c(id);
    //     navigate(`/admin/kegel/${id}`);
    //     instance.put(`/api/v1/admin/kegel/${id}/views`).catch((error) => {
    //         console.error("Error increasing views:", error);
    //     });
    // };

    const toDetail = (id) => {
        navigate(`/admin/kegel/${id}`, { state: { pageNumber } });
    };

    // 검색어
    const [keyword, setKeyword] = useState("");

    const keywordHandler = (keyword) => {
        setKeyword(keyword);
    };

    const kegelTypeMapping = {
        MACHINE: "레인정비기계",
        CONSUMABLE: "레인정비소모품",
        TOOL: "훈련 도구",
    };

    const viewAll = async () => {
        setPageNumber(0);
        setKeyword("");
        setSelectedCategoryData("");
        setSelectedExposeData("");

        instance
            .get("/api/v1/admin/kegel", {
                params: {
                    page: 0,
                    size: 10,
                },
                withCredentials: true,
            })
            .then((res) => {
                const curData = res.data.datas.content.map((data) => ({
                    kegelId: data.id,
                    content: data.content,
                    exposeYn: data.exposeYn,
                    kegelType: data.kegelType,
                    readCnt: data.readCnt,
                    regDate: data.regDate,
                    regID: data.regID,
                    title: data.title,
                }));
                setKegelList(curData);
                setTotalPage(res.data.datas.totalPages);
                setTotalCount(res.data.datas.totalElements);
                setPageNumber(res.data.datas.pageable.pageNumber);
            })
            .catch((error) => {
                console.error(error);
                setKegelList((prev) => prev);
                setTotalPage((prev) => prev);
                setTotalCount((prev) => prev);
                setPageNumber((prev) => prev);
            });
    };

    const bodyValues = kegelList.map(
        (
            { kegelId, kegelType, exposeYn, title, regID, regDate, readCnt },
            index
        ) => {
            const number = totalCount - (10 * pageNumber + index);
            const mappedKegelType = kegelTypeMapping[kegelType] || kegelType;
            return (
                <tr key={kegelId}>
                    <td>{number}</td>
                    <td>{mappedKegelType}</td>
                    <td onClick={() => toDetail(kegelId)}>{title}</td>
                    <td>{regDate}</td>
                    <td>{regID}</td>
                    <td>{exposeYn === "Y" ? "노출" : "미노출"}</td>
                    <td>{readCnt === null ? 0 : readCnt}</td>
                </tr>
            );
        }
    );
    const pageHandler = (value) => {
        setPageNumber(value);
        instance
            .get("/api/v1/admin/kegel", {
                params: {
                    category: selectedCategoryData,
                    exposeYn: selectedExposeData,
                    title: keyword,
                    page: value,
                    size: 10,
                },
                withCredentials: true,
            })
            .then((res) => {
                const curData = res.data.datas.content.map((data) => ({
                    kegelId: data.id,
                    content: data.content,
                    exposeYn: data.exposeYn,
                    kegelType: data.kegelType,
                    readCnt: data.readCnt,
                    regDate: data.regDate,
                    regID: data.regID,
                    title: data.title,
                }));
                console.log(curData);
                setKegelList(curData);
                setTotalPage(res.data.datas.totalPages);
                setTotalCount(res.data.datas.totalElements);
                setPageNumber(res.data.datas.pageable.pageNumber);
            })
            .catch((error) => {
                console.error(error);
                setKegelList((prev) => prev);
                setTotalPage((prev) => prev);
                setTotalCount((prev) => prev);
                setPageNumber((prev) => prev);
            });
    };
    useEffect(() => {
        pageHandler(pageNumber);
    }, [])
    const search = async () => {
        instance
            .get("/api/v1/admin/kegel", {
                params: {
                    category: selectedCategoryData,
                    exposeYn: selectedExposeData,
                    title: keyword,
                    page: 0,
                    size: 10,
                },
                withCredentials: true,
            })
            .then((res) => {
                const curData = res.data.datas.content.map((data) => ({
                    kegelId: data.id,
                    content: data.content,
                    exposeYn: data.exposeYn,
                    kegelType: data.kegelType,
                    readCnt: data.readCnt,
                    regDate: data.regDate,
                    regID: data.regID,
                    title: data.title,
                }));
                setKegelList(curData);
                setTotalPage(res.data.datas.totalPages);
                setTotalCount(res.data.datas.totalElements);
                setPageNumber(res.data.datas.pageable.pageNumber);
            })
            .catch((error) => {
                console.error(error);
                setKegelList((prev) => prev);
                setTotalPage((prev) => prev);
                setTotalCount((prev) => prev);
                setPageNumber((prev) => prev);
            });
    };

    return (
        <section>
            <div className={classes.admin_contents}>
                <h3>상품목록</h3>
                <div
                    className={`${classes.contents_wrap} ${classes.search_wrap}`}
                >
                    <div className={classes.admin_table_wrap}>
                        <table>
                            <colgroup>
                                <col style={{ width: "11%" }} />
                                <col style={{ width: "39%" }} />
                                <col style={{ width: "11%" }} />
                                <col style={{ width: "39%" }} />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>카테고리</th>
                                    <td>
                                        <SelectBoxKegel
                                            className="admin_st"
                                            optionData={sampleData}
                                            selectedValue={selectedCategory}
                                            setSelectedValue={
                                                setSelectedCategory
                                            }
                                            selectedData={selectedCategoryData}
                                            setSelectedData={
                                                setSelectedCategoryData
                                            }
                                        />
                                    </td>
                                    <th>노출여부</th>
                                    <td>
                                        <SelectBoxKegel
                                            className="admin_st"
                                            optionData={exposeData}
                                            selectedValue={selectedExpose}
                                            setSelectedValue={setSelectedExpose}
                                            selectedData={selectedExposeData}
                                            setSelectedData={
                                                setSelectedExposeData
                                            }
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>검색어</th>
                                    <td colSpan={3}>
                                        <Input
                                            className="admin_st"
                                            placeholder="검색어 입력"
                                            value={keyword}
                                            onChange={keywordHandler}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className={classes.bottom_btn_wrap}>
                            <Button className="border sm" onClick={search}>
                                검색
                            </Button>
                            <Button className="secondary sm" onClick={viewAll}>
                                전체보기
                            </Button>
                        </div>
                    </div>
                </div>
                <div className={classes.contents_wrap}>
                    <div
                        className={`${classes.admin_table_wrap} ${classes.align_center}  ${classes.row_select}`}
                    >
                        <p className={classes.counter}>
                            총 <b>{totalCount}</b>건
                        </p>
                        <table className={classes.left_col_3}>
                            <colgroup>
                                <col style={{ width: "5%" }} />
                                <col style={{ width: "20%" }} />
                                <col style={{ width: "25%" }} />
                                <col style={{ width: "15%" }} />
                                <col style={{ width: "15%" }} />
                                <col style={{ width: "10%" }} />
                                <col style={{ width: "10%" }} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th>카테고리</th>
                                    <th>상품명</th>
                                    <th>등록일</th>
                                    <th>등록자</th>
                                    <th>노출여부</th>
                                    <th>조회수</th>
                                </tr>
                            </thead>
                            <tbody>{bodyValues}</tbody>
                        </table>
                        <PaginationNew
                            className="admin_st"
                            totalPage={totalPage}
                            currentPage={pageNumber}
                            changePageCB={pageHandler}
                            defaultShowPage={5}
                        />
                        <div
                            className={`${classes.bottom_btn_wrap} ${classes.position_st}`}
                        >
                            <Button
                                className="secondary sm"
                                onClick={uploadHandler}
                            >
                                상품등록
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default MngKegel;

export const loader = async () => {
    const response = await ReqKegelList();

    if (response.status === 200) {
        const resData = response.data.datas;

        return resData;
    } else {
        return [];
    }
};
