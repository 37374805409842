import { NavLink } from "react-router-dom";
import classes from "./TournamentAppNav.module.css";

function TournamentAppNav(props) {
    return (
        <div className={classes.tournament_sub_nav}>
            <div className={classes.none_slide}>
                <div className={classes.depth_1_link}>
                    <NavLink to="/tournament/notice">대회공지</NavLink>
                </div>
                <div className={classes.depth_1_link}>
                    <NavLink to="/tournament/apply">대회접수</NavLink>
                </div>
                <div className={classes.depth_1_link}>
                    <NavLink to="/tournament/list">대회목록</NavLink>
                </div>
                <div className={classes.depth_1_link}>
                    <NavLink to="/tournament/result">대회결과</NavLink>
                </div>
            </div>
        </div>
    );
}

export default TournamentAppNav;
