import { useEffect, useRef, useState } from "react";
import Button from "../../../../components/UI/Button/Button";
import classes from "../../MngCommon.module.css";
import {
    ReqResultList,
    ReqResultUploadList,
} from "../../../../api/tournament/ResultApi";
import { useLocation, useNavigate } from "react-router-dom";
import SelectBoxNew from "../../../../components/UI/SelectBox/SelectBoxNew";
import {
    ReqContestNameWithCtType2,
    ReqContestType,
    ReqRegionData,
    ReqStadiumListWithRegion,
} from "../../../../api/common/ConditionSelectBoxApi";
import {
    DefaultAll,
    DefaultCenterNameSelectBox,
    DefaultContestNameSelectBox,
} from "../../../../components/definedefault/DefSelectBoxValues";
import instance from "../../../../store/AxiosInterceptor";
import PaginationNew from "../../../../components/UI/Table/PaginationNew";
function MngTournamentResultPage(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const [resultList, setResultList] = useState([]);
    // >>> 페이징을 위한 값 세팅
    const [totalPage, setTotalPage] = useState(); //총 페이지 갯수
    const [pageNumber, setPageNumber] = useState(location.state?.pageNumber ?? 0); //글목록 No. 값을세팅하기 위한 서버에서 들어오는값
    const [totalCount, setTotalCount] = useState(0);
    

 

    const toDetailHandler = (id) => {
        // 대회 상세 페이지로 이동하는 코드 추가 (예: useHistory를 사용하여 페이지 이동)
        navigate(`/admin/tournament/result/${id}`, {state: {pageNumber}});
    };

    // 대회 이름 클릭 시 조회수 증가를 위한 API 요청
    const increaseViews = (id) => {
        instance
            .put(`/api/v1/admin/contest/result/${id}/views`)
            .then((res) => {
                // 성공적으로 조회수가 증가된 후에, 해당 대회의 상세 페이지로 이동
                toDetailHandler(id);
            })
            .catch((error) => {
                console.error("Error increasing views:", error);
            });
    };


    const pageHandler = (value) => {
        instance
            .get("/api/v1/admin/contest/result", {
                params: {
                    contest_type: ctType,
                    contest_id: ctId,
                    region_name: selectedRegion,
                    center_id: centerId,
                    page: value,
                    size: 10,
                },
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
                withCredentials: true,
            })
            .then((res) => {
                const curData = res.data.datas.content.map((data) => ({
                    ct_type: data.ct_type,
                    contest_name: data.contest_name,
                    region_name: data.region_name,
                    center_name: data.center_name,
                    views: data.views,
                    expose_yn: data.expose_yn,
                    bowling_center_id: data.bowling_center_id,
                    reg_id: data.reg_id,
                    id: data.id,
                }));
                console.log(curData);
                setResultList(curData);
                setTotalPage(res.data.datas.totalPages);
                setTotalCount(res.data.datas.totalElements);
                setPageNumber(res.data.datas.pageable.pageNumber);
            })
            .catch((error) => {
                console.error(error);
                setResultList((prev) => prev);
                setTotalPage((prev) => prev);
                setTotalCount((prev) => prev);
                setPageNumber((prev) => prev);
            });
    };
    useEffect(() => {
        pageHandler(pageNumber);
    }, [])
    // <<< 페이징을 위한 값 세팅

    // >>> SelectBox 값 채우기 위한 상태값들
    const [contestTypeData, setContestTypeData] = useState([DefaultAll]); //대회 타입 리스트
    const [contestNameList, setContestNameList] = useState([
        DefaultContestNameSelectBox,
    ]); //대회명 리스트
    const [regionData, setRegionData] = useState([DefaultAll]); //지역명 리스트
    const [centerNameList, setCenterNameList] = useState([
        DefaultCenterNameSelectBox,
    ]); //경기장 리스트

    const contestTypeMouseOverHandler = (key) => {
        if (contestTypeData.length === 1) {
            ReqContestType(
                (result) => {
                    const success = result.map((data) => {
                        return { key: data.type, value: data.name };
                    });
                    setContestTypeData((prev) => [DefaultAll, ...success]);
                },
                (excption_result) => {
                    setContestTypeData((prev) => [DefaultAll]);
                }
            );
        }
    };

    const contestNameMouseOverHandler = (key) => {
        setCtId("");
        if (ctType !== "") {
            ReqContestNameWithCtType2(
                ctType,
                (result) => {
                    const success = result.map((data) => {
                        return {
                            key: String(data.id),
                            value: data.name,
                            disabled: false,
                        };
                    });
                    setContestNameList((prev) => [
                        DefaultContestNameSelectBox,
                        ...success,
                    ]);
                },
                (exception_result) => {
                    setContestNameList((prev) => [DefaultContestNameSelectBox]);
                }
            );
        } else {
            setContestNameList((prev) => [DefaultContestNameSelectBox]);
        }
    };

    const regionNameMouseOverHandler = (key) => {
        if (regionData.length === 1) {
            ReqRegionData(
                (result) => {
                    const success = result.map((data) => {
                        return { key: data.name, value: data.name };
                    });
                    setRegionData((prev) => [DefaultAll, ...success]);
                },
                (excption_result) => {
                    setRegionData((prev) => [DefaultAll]);
                }
            );
        }
    };

    // >>> SelectBox에서 선택된 값들을 다루기 위한 상태값들
    const [ctType, setCtType] = useState(""); //SelectBox에서 선택된 대회 타입
    const ctTypeHandler = (key) => {
        setCtType(key);
    };

    const [ctId, setCtId] = useState(""); //SelectBox에서 선택된 대회 명
    const ctIdHandler = (key) => {
        setCtId(key);
    };

    const [selectedRegion, setSelectedRegion] = useState(""); //SelectBox에서 선택된 지역명
    const selectedRegionHandler = (key) => {
        setSelectedRegion(key);
    };

    useEffect(() => {
        setCenterId("");
        if (selectedRegion) {
            ReqStadiumListWithRegion(
                selectedRegion,
                (result) => {
                    const success = result.map((data) => {
                        return {
                            key: data.bowling_center_id,
                            value: data.center_name,
                            disabled: false,
                        };
                    });
                    setCenterNameList((prev) => [
                        DefaultCenterNameSelectBox,
                        ...success,
                    ]);
                },
                (exception_result) => {
                    setCenterNameList((prev) => [DefaultCenterNameSelectBox]);
                }
            );
        } else {
            setCenterNameList((prev) => [DefaultCenterNameSelectBox]);
        }
    }, [selectedRegion]);

    const [centerId, setCenterId] = useState(""); //SelectBox에서 선택된 경기장
    const centerIdHandler = (key) => {
        setCenterId(key);
    };
    // <<< SelectBox에서 선택된 값들을 다루기 위한 상태값들

    const bodyValues = resultList.map(
        (
            {
                ct_type,
                contest_name,
                region_name,
                center_name,
                views,
                expose_yn,
                bowling_center_id,
                reg_id,
                id,
            },
            index
        ) => {
            const number = totalCount - (10 * pageNumber + index);
            return (
                <tr key={id}>
                    <td>{number}</td>
                    {/* <td onClick={toDetailHandler(id)}>{contest_name}</td> */}
                    <td onClick={() => increaseViews(id)}>
                        {contest_name}
                        {/* <NavLink to={`${id}`}>{contest_name}</NavLink> */}
                    </td>
                    <td>{region_name}</td>
                    <td>{center_name}</td>
                    <td>{reg_id}</td>
                    <td>{views !== null ? views : 0}</td>
                    <td>{expose_yn === "Y" ? "사용" : "미사용"}</td>
                </tr>
            );
        }
    );

    // [START] 검색
    const search = () => {
        instance
            .get("/api/v1/admin/contest/result", {
                params: {
                    contest_type: ctType,
                    contest_id: ctId,
                    region_name: selectedRegion,
                    center_id: centerId,
                    page: 0,
                    size: 10,
                },
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
                withCredentials: true,
            })
            .then((res) => {
                const curData = res.data.datas.content.map((data) => ({
                    ct_type: data.ct_type,
                    contest_name: data.contest_name,
                    region_name: data.region_name,
                    center_name: data.center_name,
                    views: data.views,
                    expose_yn: data.expose_yn,
                    bowling_center_id: data.bowling_center_id,
                    reg_id: data.reg_id,
                    id: data.id,
                }));
                setResultList(curData);
                setTotalPage(res.data.datas.totalPages);
                setTotalCount(res.data.datas.totalElements);
                setPageNumber(res.data.datas.pageable.pageNumber);
            })
            .catch((error) => {
                console.error(error);
                setResultList((prev) => prev);
                setTotalPage((prev) => prev);
                setTotalCount((prev) => prev);
                setPageNumber((prev) => prev);
            });
    };

    // 전체보기
    const viewAll = () => {
        setPageNumber(0);
        setCenterId("");
        setCtType("");
        setCtId("");
        setSelectedRegion("");
        setContestNameList((prev) => [DefaultContestNameSelectBox]);
        instance
            .get("/api/v1/admin/contest/result", {
                params: {
                    page: 0,
                    size: 10,
                },
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
                withCredentials: true,
            })
            .then((res) => {
                const curData = res.data.datas.content.map((data) => ({
                    ct_type: data.ct_type,
                    contest_name: data.contest_name,
                    region_name: data.region_name,
                    center_name: data.center_name,
                    views: data.views,
                    expose_yn: data.expose_yn,
                    bowling_center_id: data.bowling_center_id,
                    reg_id: data.reg_id,
                    id: data.id,
                }));
                setResultList(curData);
                setTotalPage(res.data.datas.totalPages);
                setTotalCount(res.data.datas.totalElements);
                setPageNumber(res.data.datas.pageable.pageNumber);
            })
            .catch((error) => {
                console.error(error);
                setResultList((prev) => prev);
                setTotalPage((prev) => prev);
                setTotalCount((prev) => prev);
                setPageNumber((prev) => prev);
            });
    };

    // [END]

    return (
        <section>
            <div className={classes.admin_contents}>
                <h3>대회결과</h3>
                <div
                    className={`${classes.contents_wrap} ${classes.search_wrap}`}
                >
                    <div className={classes.admin_table_wrap}>
                        <table>
                            <colgroup>
                                <col style={{ width: "11%" }} />
                                <col style={{ width: "20%" }} />
                                <col style={{ width: "11%" }} />
                                <col style={{ width: "58%" }} />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>대회유형</th>
                                    <td>
                                        <SelectBoxNew
                                            className="admin_st"
                                            optionDatas={contestTypeData}
                                            setSelectItemCB={ctTypeHandler}
                                            showDefaultKey={ctType}
                                            mouseOverCB={
                                                contestTypeMouseOverHandler
                                            }
                                        />
                                    </td>
                                    <th>대회명</th>
                                    <td>
                                        <SelectBoxNew
                                            className="admin_st w100"
                                            optionDatas={contestNameList}
                                            setSelectItemCB={ctIdHandler}
                                            showDefaultKey={ctId}
                                            mouseOverCB={
                                                contestNameMouseOverHandler
                                            }
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>지역</th>
                                    <td>
                                        <SelectBoxNew
                                            className="admin_st"
                                            optionDatas={regionData}
                                            setSelectItemCB={
                                                selectedRegionHandler
                                            }
                                            showDefaultKey={selectedRegion}
                                            mouseOverCB={
                                                regionNameMouseOverHandler
                                            }
                                        />
                                    </td>
                                    <th>경기장</th>
                                    <td>
                                        <SelectBoxNew
                                            className="admin_st w100"
                                            optionDatas={centerNameList}
                                            setSelectItemCB={centerIdHandler}
                                            showDefaultKey={centerId}
                                            mouseOverCB={null}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className={classes.bottom_btn_wrap}>
                            <Button className="border sm" onClick={search}>
                                검색
                            </Button>
                            <Button className="secondary sm" onClick={viewAll}>
                                전체보기
                            </Button>
                        </div>
                    </div>
                </div>
                <div className={classes.contents_wrap}>
                    <div
                        className={`${classes.admin_table_wrap} ${classes.align_center} ${classes.row_select}`}
                    >
                        <p className={classes.counter}>
                            총 <b>{totalCount}</b>건
                        </p>
                        <table className={classes.left_col_2}>
                            <colgroup>
                                <col style={{ width: "10%" }} />
                                <col style={{ width: "30%" }} />
                                <col style={{ width: "12%" }} />
                                <col style={{ width: "12%" }} />
                                <col style={{ width: "10%" }} />
                                <col style={{ width: "13%" }} />
                                <col style={{ width: "13%" }} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th>대회명</th>
                                    <th>지역</th>
                                    <th>경기장</th>
                                    <th>등록자</th>
                                    <th>조회수</th>
                                    <th>사용여부 레인배정표</th>
                                </tr>
                            </thead>
                            <tbody>{bodyValues}</tbody>
                        </table>
                        <PaginationNew
                            className="admin_st"
                            totalPage={totalPage}
                            currentPage={pageNumber}
                            changePageCB={pageHandler}
                            defaultShowPage={5}
                        />
                        <div
                            className={`${classes.bottom_btn_wrap} ${classes.position_st}`}
                        ></div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default MngTournamentResultPage;

export const loader = async () => {
    const response = await ReqResultList();
    if (response.status === 200) {
        const resData = response.data.datas;
        // console.log(resData);
        return resData;
    } else {
        return [];
    }
};
