import BreadCrumb from "../../../components/UI/BreadCrumb/BreadCrumb";
import classes from "../Event.module.css";
import Button from "../../../components/UI/Button/Button";
import { useNavigate, useLoaderData, useLocation } from "react-router-dom";
import instance from "../../../store/AxiosInterceptor";

function EventDetail() {
    const breadCrumbList = ["이벤트", "진행중인 이벤트"];
    const resData = useLoaderData();

    const location = useLocation();
    const navigate = useNavigate();
    const backHandler = () => {
        navigate("/event/ongoing", { state: location.state });
    };

    const navigateHandler = (key) => {
        if(key == null || key === "null") return;
        navigate(`/event/ongoing/${key}`, { state: location.state });
    }

    return (
        <>
            <BreadCrumb breadCrumbList={breadCrumbList} />
            <div className={classes.contents_wrap}>
                <div className={classes.contents_title}>
                    <h3>진행중인 이벤트</h3>
                </div>

                <div className={classes.view_content}>
                    <div className={classes.title}>
                        <h3>{resData.cur_event_title}</h3>
                        <p className={classes.date}>
                            {resData.cur_event_start_date} ~ {resData.cur_event_end_date}
                        </p>
                    </div>
                    <div className={classes.content}>
                        <div className={classes.img_box}>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: resData.cur_description,
                                }}
                            />
                        </div>
                    </div>
                    <ul className={classes.list_wrap}>
                        <li className={classes.prev} onClick={()=>navigateHandler(`${resData.prev_event_id}`)}>
                            <i>이전글</i>
                            <span>{resData.prev_event_title ? resData.prev_event_title : ""}</span>
                            {resData.prev_event_start_date && resData.prev_event_end_date ? (
                                <em>
                                    {resData.prev_event_start_date} ~ {resData.prev_event_end_date}
                                </em>
                            ) : (
                                <em></em>
                            )}
                        </li>
                        <li className={classes.next} onClick={()=>navigateHandler(`${resData.next_event_id}`)}>
                            <i>다음글</i>
                            <span>{resData.next_event_title ? resData.next_event_title : ""}</span>
                            {resData.next_event_start_date && resData.next_event_end_date ? (
                                <em>
                                    {resData.next_event_start_date} ~ {resData.next_event_end_date}
                                </em>
                            ) : (
                                <em></em>
                            )}
                        </li>
                    </ul>
                    <div className={classes.btn_wrap}>
                        <Button onClick={backHandler}>목록으로</Button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default EventDetail;

//이벤트 상세내용 가져오기
export const loader = async ({ params }) => {
    const response = await instance({
        method: "GET",
        url: `/api/v1/home/event/${params.eventId}`,
    }).catch(function (error) {
        if (error.response) {
            return error.response;
        } else if (error.request) {
            return null;
        } else {
            return null;
        }
    });
    if (response == null || response.status !== 200) {
        return [];
    }
    if (response.data.status === 200) {
        return response.data;
    } else {
        return [];
    }
};

//이벤트 상세내용 가져오기
