import classes from "../MngCommon.module.css";
import Button from "../../../components/UI/Button/Button";
import { NavLink, useLoaderData, useNavigate } from "react-router-dom";
import { useState } from "react";
import instance from "../../../store/AxiosInterceptor";
import Alert from "../../../components/UI/Modal/Alert";
import { ReqUpdateBannerSeq } from "../../../api/tournament/TournamentBannerApi";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useEffect } from "react";

function MngBannerPage() {
    const banners = useLoaderData();
    const navigate = useNavigate();
    const [characters, updateCharacters] = useState(banners); //기본 배너의 length가 1이다.

    //>>>배너 순서 변경 저장 메소드
    const [showConfirmation, setShowConfirmation] = useState(false); //모달 Alert 상태관리
    const confirmChangeHandler = () => {
        setShowConfirmation(false);
        changeHandler();
    };

    const changeHandler = async () => {
        ReqUpdateBannerSeq(
            characters,
            (result) => {
                if (result.data.status === 200) {
                    navigate("/admin/site/banner");
                }
            },
            (error_result) => {
                alert("서버 통신 에러");
            }
        );
    };
    //<<<배너 순서 변경 저장 메소드

    //>>> 드래그 &  드랍 저장 메소드
    const handleOnDragEnd = (result) => {
        if (!result.destination) return;

        const items = Array.from(characters);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        items.forEach((data, index) => (data.priority = index + 1));
        updateCharacters(items);
    };
    //<<< 드래그 &  드랍 저장 메소드

    const noDataLoader = async () => {
        const response = await instance({
            method: "GET",
            url: `/api/v1/admin/contest/banner?bannerType=home`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        }).catch(function (error) {
            if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
                return error.response;
            } else if (error.request) {
                console.log(error.request);
                return null;
            } else {
                console.log("Error", error.message);
                return null;
            }
        });
        if (response == null || response.status !== 200) {
            return [];
        }
        if (response.data.status === 200) {
            return response.data.datas;
        } else {
            return [];
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            if (!banners) {
                const response = await noDataLoader();
                const curData = response.content.map((data) => {
                    return {
                        id: data.id,
                        title: data.title,
                        priority: data.priority, // 배너 노출 순서, 클수록 상단
                        exposeYn: data.expose_yn, // 노출 여부
                        launchNewPage: data.launch_new_page, //새창 여부
                        linkUrl: data.link_url, // 배너 선택 시 이동 링크
                        fileUri: data.file_uri, // PC배너 URI
                        mobileFileUri: data.mobile_file_uri, // Mobile 배너 URI
                        updId: data.upd_id,
                        regId: data.reg_id,
                        updDate: data.upd_date,
                        regDate: data.reg_date,
                    };
                });
                updateCharacters(curData);
            }
        };
        fetchData();
    }, []);

    return (
        <section>
            <div className={classes.admin_contents}>
                <h3>메인 배너 관리 - 목록</h3>
                <p className={classes.desc}>* 배너는 최소 1개 ~ 최대 5개 까지 노출 가능합니다.</p>
                <div className={classes.contents_wrap}>
                    <div className={classes.dnd_table_wrap}>
                        <ul className={classes.header}>
                            <li>순서변경</li>
                            <li>노출순서</li>
                            <li>배너이미지</li>
                            <li>배너명</li>
                            <li>최종등록일</li>
                            <li>최종등록자</li>
                            <li>노출여부</li>
                        </ul>
                        {/**여기서 부터 배너 리스트 출력 및 드래그 앤 드랍*/}
                        <DragDropContext onDragEnd={handleOnDragEnd}>
                            <Droppable droppableId={classes.list_wrap}>
                                {(provided) => (
                                    <div
                                        className={classes.list_wrap}
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                    >
                                        {characters.map(
                                            (
                                                {
                                                    id,
                                                    title,
                                                    priority,
                                                    exposeYn,
                                                    fileUri,
                                                    updId,
                                                    regId,
                                                    updDate,
                                                    regDate,
                                                },
                                                index
                                            ) => {
                                                return (
                                                    <Draggable
                                                        key={`banner${id}`}
                                                        draggableId={`banner${id}`}
                                                        index={index}
                                                    >
                                                        {(provided, snapshot) => (
                                                            <ul
                                                                className={`${classes.list} ${
                                                                    snapshot.isDragging ? classes.on : ""
                                                                }`}
                                                                key={id}
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                            >
                                                                <li className={classes.btn_move}></li>
                                                                <li>{index + 1}</li>
                                                                <li>
                                                                    <img src={fileUri} alt="배너이미지" />
                                                                </li>
                                                                <li>
                                                                    <NavLink to={`${id}`}>{title}</NavLink>
                                                                </li>
                                                                <li>{updDate ? updDate : regDate}</li>
                                                                <li>{updId ? updId : regId}</li>
                                                                {/**업데이트한사람이 존재하는 경우 업데이트한사람, 없는경우 최초등록자 */}
                                                                <li>{exposeYn === "Y" ? "노출" : "미노출"}</li>
                                                            </ul>
                                                        )}
                                                    </Draggable>
                                                );
                                            }
                                        )}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </div>
                    {/**여기까지 배너 리스트 출력 */}
                    <div className={classes.bottom_btn_wrap}>
                        <Button type="submit" className="secondary sm" onClick={() => setShowConfirmation(true)}>
                            순서 변경 적용
                        </Button>
                    </div>
                </div>
            </div>
            {showConfirmation && (
                <Alert
                    type="confirm"
                    cancel="취소"
                    confirm="확인"
                    close={() => setShowConfirmation(false)} // 모달 닫기
                    confirmHandler={confirmChangeHandler} // 확인 버튼 눌렀을 때 실행되는 Handler
                >
                    {"순서를 변경 하시겠습니까?"}
                </Alert>
            )}
        </section>
    );
}

export default MngBannerPage;

//>>>배너 리스트 뿌려주기 loader, API 분리 불가. 배너가 먼저 존재해야 Drag&Drop 함수가 진행가능
export const loader = async () => {
    const response = await instance({
        method: "GET",
        url: "/api/v1/admin/contest/banner?bannerType=home",
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    }).catch(function (error) {
        if (error.response) {
            // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            return error.response;
        } else if (error.request) {
            // 요청이 이루어 졌으나 응답을 받지 못했습니다.
            console.log(error.request);
            return null;
        } else {
            // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
            console.log("Error", error.message);
            return null;
        }
    });
    if (response == null || response.status !== 200) {
        return [];
    }
    if (response.data.status === 200) {
        const curData = response.data.datas.map((data) => {
            return {
                id: data.id,
                title: data.title,
                priority: data.priority, // 배너 노출 순서, 클수록 상단
                exposeYn: data.expose_yn, // 노출 여부
                launchNewPage: data.launch_new_page, //새창 여부
                linkUrl: data.link_url, // 배너 선택 시 이동 링크
                fileUri: data.file_uri, // PC배너 URI
                mobileFileUri: data.mobile_file_uri, // Mobile 배너 URI
                updId: data.upd_id,
                regId: data.reg_id,
                updDate: data.upd_date,
                regDate: data.reg_date,
            };
        });
        return curData;
    } else {
        return [];
    }
};
//<<<배너 리스트 뿌려주기
