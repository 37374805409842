import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import Button from "../../../components/UI/Button/Button";
import classes from "../MngCommon.module.css";

import _ from "lodash";
import { ProductSelectedCategoryDelete } from "../../../api/product/CategorySelectApi";
import htmlReactParse from 'html-react-parser';
import Slider from 'react-slick';
import { FileBoxForImage } from "../../../components/UI/Input/FileBox";


const sliderSettings = (arr) => ({
  lazyLoad: true,
  arrows: true,
  infinite: arr >= 5,
  slidesToShow: 5,
  slidesToScroll: 1,
});
function MngProductDetailPage({ detailData = {}, setIsEdit=() => {} , setViewMode= () =>{}, tokenContext, smartFilterMenus=[], extInformationMenus=[], handleModeChange = () => {}, ...props }) {
  // viewMode : 0 - 리스트, 1- 작성, 2- 상세


  /* const [isModalOpen1, setIsModalOpen1] = useState(false);
  const openModalHandler1 = (e) => {
    e.stopPropagation();
    setIsModalOpen1(true);
  };
  const closeModalHandler1 = (e) => {
    e.stopPropagation();
    setIsModalOpen1(false);
  }; */


  return (
    <div className={classes.admin_contents}>
      <h3>상품상세</h3>
      <div className={`${classes.contents_wrap} ${classes.search_wrap} `}>
        <div className={classes.admin_table_wrap}>
          <table>
            <colgroup>
              <col style={{ width: "159px" }} />
              <col style={{ width: "620px" }} />
              <col style={{ width: "159px" }} />
              <col style={{ width: "620px" }} />
            </colgroup>
            <tbody>
              <tr>
                <th>등록일</th>
                <td>{detailData.regDate}</td>
                <th>등록자</th>
                <td>{detailData.regID}</td>
              </tr>
              <tr>
                <th>선정방식</th>
                <td colSpan={3}>{!detailData.exposeYn && '미' }노출</td>
              </tr>
              <tr>
                <th>카테고리</th>
                <td colSpan={3}>
                  {detailData.hierarchyCategoryStr}
                </td>
              </tr>
              <tr>
                <th>상품명</th>
                <td colSpan={3}>{detailData.name} {_.isString(detailData.subName) && detailData.subName.length > 0 &&  '|'}  {detailData.subName}</td>
              </tr>
              <tr>
                <th>출시일</th>
                <td colSpan={3}>{detailData.releaseDate}</td>
              </tr>
              <tr>
                <th>카탈로그 Ball</th>
                <td colSpan={3}> {!detailData['catalogue_ball'] && '미'}해당</td>
              </tr>
              <tr>
                <th>상품이미지</th>
                <td colSpan={3}>
                  <Slider {...sliderSettings(detailData.productDetailImagesSelecteds)}>
                    {
                      (detailData.productDetailImagesSelecteds ?? []).map((item) => 
                      // eslint-disable-next-line react/jsx-key
                      <div className={classes.main_img} key={item['origin_name']}> 
                    <img
                      style={{width: '200px'}}
                      src={item.uri}
                      alt={item['origin_name']}
                      />
                    </div>
                      )
                    }
                    </Slider>
                </td>
              </tr>
              <tr>
                <th>구매하기 URL</th>
                <td colSpan={3}>
                 {detailData?.purchaseUrl}
                </td>
              </tr>
              <tr>
                <th>사용자페이지 URL</th>
                <td colSpan={3}>
                  {detailData?.pageUri}
                </td>
              </tr>
              <tr>
                <th>상세정보</th>
                <td colSpan={3}>{htmlReactParse(detailData?.description ?? '')}</td>
              </tr>
            </tbody> 
          </table>
                  
          {
            ((_.isArray(smartFilterMenus) && smartFilterMenus.length > 0) 
          ||(_.isArray(extInformationMenus) && extInformationMenus.length > 0)) 
          && (
              <>
              <h4>상품정보/스마트필터</h4>
              <table>
              <colgroup>
                  <col style={{ width: "159px" }} />
                  <col style={{ width: "300px" }} />
                  <col style={{ width: "1000px" }} />
                </colgroup>
                <tbody>
                    {
                      (smartFilterMenus ?? []).map((sItem, idx) => (
                        <tr key={`title-${sItem?.id}`}>
                          {idx === 0 && <th rowSpan={smartFilterMenus?.length}>스마트필터</th>}  
                         <th key={`title-${sItem?.id}`}>{sItem?.title}</th>
                         <td>
                          {detailData?.smartFilterMenuSelecteds.find((item) => item?.id === sItem?.id)?.smartFilterItemSelecteds?.map(({name}) => name)?.join(' / ')}
                         </td>
                        </tr>
                      ))
                    }
                    {
                      (detailData?.extInformationMenuSelecteds ?? []).map((exItem, idx) => (
                        <tr key={`ex-info-${exItem.id}`}>
                          {idx === 0 && <th rowSpan={extInformationMenus?.length}> 상품정보 </th>}
                          <th> {exItem?.title} </th>
                          <td> {exItem?.description} </td>
                        </tr>
                      )) 
                    }
                </tbody>
              </table>
              </>)
              }

          {/* <h4>상품정보/스마트필터</h4>
              <table>
                <colgroup>
                  <col style={{ width: "159px" }} />
                  <col style={{ width: "300px" }} />
                  <col style={{ width: "1000px" }} />
                </colgroup>
                <tbody>
                  <tr>
                    <th rowSpan={5}>스마트필터</th>
                    <th>Coverstock</th>
                    <td>NeX™ Solid Reactive</td>
                  </tr>
                  <tr>
                    <th>Weight Block(Core)</th>
                    <td>Centripetal™ Core</td>
                  </tr>
                  <tr>
                    <th>Weight Block(Core) Shape</th>
                    <td>Asymmetrical(비대칭)</td>
                  </tr>
                  <tr>
                    <th>Factory Finish</th>
                    <td>1500-grit Polished</td>
                  </tr>
                  <tr>
                    <th>Flare Potential</th>
                    <td>High</td>
                  </tr>

                  <tr>
                    <th rowSpan={2}>상품정보</th>
                    <th>Ball Color</th>
                    <td>Red / Black / Violet</td>
                  </tr>
                  <tr>
                    <th>Fragrance</th>
                    <td>Cherry Berry Smoothie</td>
                  </tr>
                </tbody>
              </table>
 */}

          <div className={classes.bottom_btn_wrap}>
            <>
        
            <Button
              className="secondary sm"
              onClick={() => {
                setIsEdit(true); handleModeChange(1)}
              }
            >
              수정
            </Button>
            <Button 
              className="sm"
              onClick={() => {
                const reallyDelete = window.confirm('정말 삭제하시겠습니까?');
                if(reallyDelete){
                  ProductSelectedCategoryDelete(detailData?.id).then((res) => {
                    window.alert('삭제되었습니다');
                    setViewMode(0);
                    return res
                  })
                  .catch((reason) => {
                    //console.log({ errorReason: reason });
                    window.alert('삭제에 실패했습니다')
                  });
                }
              }}
            >삭제</Button>
            </>
            <Button
              className="border sm"
              onClick={() => handleModeChange(0)}
            >
              목록
            </Button>
            
          </div>
        </div>
      </div>
    </div>
  )
    
}

export default MngProductDetailPage;
