import { Outlet, useLocation } from "react-router-dom";
import MainNavigation from "../components/MainNavigation";
// import SubNavigation from "../components/SubNavigation";
import FooterNavigation from "../components/FooterNavigation";
import ScrollToTop from "./ScrollToTop";
import { useContext, useEffect } from "react";
import { JwtTokenContext } from "../store/JwtTokenProvider";
import MainNavigationApp from "../components/MainNavigationApp";
import FooterNavigationApp from "../components/FooterNavigationApp";
import { isMobile } from "react-device-detect";
import { sendPageView } from "../utils/GAEvent";

function RootLayout() {
    const tokenCtx = useContext(JwtTokenContext);
    // ↓ ↓ admin 화면 확인을 위하여 상태를 수정하였습니다.
    const location = useLocation();

    const isAdminUri = location.pathname.startsWith("/admin");
    const isAdmin = false;
    // 모바일 화면 퍼블리싱을 위해 isMobile 변수를 지정하였습니다. 추후 분기처리 부탁드립니다.
    // const isMobile = false;
    // title 변경
    // console.log("root : ", location.pathname);
    useEffect(() => {
        if (location.pathname === "/adminlogin" || isAdminUri) {
            document.title = "㈜진승 관리자 페이지";
        } else {
            document.title = "㈜진승";
        }
        
        // [A] 20240424 해당 처리를 하면 Google Analyistic에 페이지 뷰 통계가 올라간다
        sendPageView(location.pathname);

    }, [location.pathname]);
    return (
        <>
            <div id="bodyWrap">
                {/* ↓ 페이지 이동 시 스크롤 상단으로 이동 */}
                <ScrollToTop />
                {isMobile ? <MainNavigationApp /> : <MainNavigation />}

                {/* <SubNavigation /> */}
                <Outlet />
                {/* // ↓ ↓ admin 일때는 footer 없음 */}
                {isAdminUri ? null : isMobile ? (
                    <FooterNavigationApp />
                ) : (
                    <FooterNavigation />
                )}
            </div>
        </>
    );
}

export default RootLayout;
