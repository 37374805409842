import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import "react-datepicker/dist/react-datepicker.css";
import classes from "./DatePicker.module.css";

// 사용 한 라이브러리
// https://reactdatepicker.com/#example-year-picker
// https://www.npmjs.com/package/react-datepicker

function DatePickerBox(props) {
  const [startDate, setStartDate] = useState(props.value);
  return (
    <div className={`${classes.date_picker_box} ${classes[props.className]}`}>
      <DatePicker
        selected={props.value === undefined ? new Date() : props.value}
        onChange={props.onChange}
        locale={ko}
        dateFormat="yyyy-MM-dd"
        dropdownMode="select"
        showMonthDropdown={props.showMonthDropdown}
        showYearDropdown={props.showYearDropdown}
        selectsStart
      />
    </div>
  );
}
export default DatePickerBox;
