import React, { useEffect } from "react";
import classes from "./Button.module.css";

const Button = ({forwardedRef, ...props}) => {
  // ↓ 퍼블단에서 화면 확인만을 위해 작성한 코드 입니다.
  //클래스명 조합
  const { className = "" } = props;
  const classNames = Array.isArray(className)
    ? className
    : className.split(" ");

  const combinedClasses = classNames
    .map((cn) => classes[cn])
    .filter((cn) => cn)
    .join(" ");
  const finalClassName = `${classes.button} ${combinedClasses}`;
  return (
    <button
      {...props}
      ref={forwardedRef}
      type={props.type || "button"}
      className={finalClassName}
      onClick={props.onClick}
      disabled={props.disabled}
      style={props?.style}
    >
      {props.children}
    </button>
  );
};

export default Button;
