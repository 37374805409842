import { useState, useEffect, useContext } from "react";
import classes from "../../MngCommon.module.css";
import Button from "../../../../components/UI/Button/Button";
import Input from "../../../../components/UI/Input/Input";
import Radio from "../../../../components/UI/Input/Radio";
import DateTimePicker from "../../../../components/UI/DatePicker/DateTimePicker";
import { useLoaderData, useLocation, useNavigate, useParams } from "react-router-dom";
import instance from "../../../../store/AxiosInterceptor";
import moment from "moment/moment";
import Alert from "../../../../components/UI/Modal/Alert";
import { ReqDeleteEvent, ReqUpdateEvent } from "../../../../api/event/MngEventApi";
import QuillEditor from "../../../../components/UI/Editer/QuillEditor";

function MngEventDetail() {
    const resData = useLoaderData();
    const params = useParams();
    const eventId = params.eventId;
    const [isEditMode, setIsEditMode] = useState(false);

    const editModeHandler = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
        setIsEditMode(true);
    };
    const location = useLocation();
    const navigate = useNavigate();
    const backHandler = () => {
        navigate("/admin/event/list", {state: location.state});
    };
    useEffect(() => {
        window.addEventListener('popstate', backHandler);
        return () => { window.removeEventListener('popstate', backHandler) };
    }, [])
    const [deleteConfirm, setDeleteConfirm] = useState(false);
    const [deleteAlert, setDeleteAlert] = useState(false);

    const deleteHandler = () => {
        setDeleteConfirm(false);
        ReqDeleteEvent(
            eventId,
            (result) => {
                if (result.status === 200) {
                    setDeleteAlert(true);
                }
            },
            (error_result) => {
                alert("신청자가 존재하여 삭제가 불가능합니다.");
            }
        );
    };

    //>>> 이벤트 상세 보기 및 이벤트 삭제

    const [startDate, setStartDate] = useState(
        resData.event_start_date ? moment(resData.event_start_date, "YYYYMMDDHHmm").toDate() : null
    );
    const [endDate, setEndDate] = useState(
        resData.event_end_date ? moment(resData.event_end_date, "YYYYMMDDHHmm").toDate() : null
    );

    const [eventTitle, setEventTitle] = useState(resData.event_title);
    const [exposeYn, setExposeYn] = useState(resData.expose_yn);
    const [chosenExistYn, setChosenExistYn] = useState(resData.chosen_exist_yn);

    const [content, setContent] = useState(resData.content);
    const contentChangeHandler = (value) => {
        setContent(value);
    };

    const onExposeHandler = (e) => {
        setExposeYn(e.target.value);
    };

    const onChosenExistHandler = (e) => {
        setChosenExistYn(e.target.value);
    };

    const onEventTitleHandler = (value) => {
        setEventTitle(value);
    };

    const startDateHandler = (value) => {
        setStartDate(value);
    };

    const endDateHandler = (value) => {
        setEndDate(value);
    };

    // >> 이미지 미리 보기 및 업로드
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [selectedFile, setSelectedFile] = useState();
    const [newFileUri, setNewFileUri] = useState();
    const [oldFileUri, setOldFileUri] = useState(resData.file_uri);
    const [isDeleteImage, setIsDeleteImage] = useState("N");

    const confirmChangeHandler = () => {
        setSelectedFile(null);
        setNewFileUri(null);
        setShowConfirmation(false);
        setOldFileUri(null);
        setIsDeleteImage("Y");
    };

    useEffect(() => {
        if (!selectedFile) {
            setNewFileUri(null);
            return;
        }

        const objectUrl = URL.createObjectURL(selectedFile);
        setNewFileUri(objectUrl);

        return () => URL.revokeObjectURL(objectUrl);
    }, [selectedFile]);

    const onSelectFile = (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            setSelectedFile(undefined);
            return;
        }

        setSelectedFile(e.target.files[0]);
    };
    //<<< 이미지 미리보기

    const [successModal, setSuccessModal] = useState(false);
    const modalHandler = () => {
        setSuccessModal(false);
        navigate("/admin/event/list", { state: location.state });
    };

    const editHandler = () => {
        const jsonData = JSON.stringify({
            title: eventTitle,
            expose_yn: exposeYn,
            chosen_exist_yn: chosenExistYn,
            start_date: moment(startDate).format("YYYYMMDDHHmm"),
            end_date: moment(endDate).format("YYYYMMDDHHmm"),
            content: content,
            old_image_delete: isDeleteImage,
        });

        const formData = new FormData();

        if (selectedFile) {
            formData.append("image", selectedFile);
        }

        formData.append("body", new Blob([jsonData], { type: "application/json" }));

        ReqUpdateEvent(
            eventId,
            formData,
            (result) => {
                if (result.status === 200) {
                    setSuccessModal(true);
                    return;
                }
            },
            (error_result) => {
                alert("등록 에러");
            }
        );
    };
    return (
        <>
            {!isEditMode && (
                <section>
                    <div className={classes.admin_contents}>
                        <h3>이벤트관리 - 상세</h3>
                        <div className={`${classes.contents_wrap} ${classes.search_wrap} `}>
                            <div className={classes.admin_table_wrap}>
                                <table>
                                    <colgroup>
                                        <col style={{ width: "159px" }} />
                                        <col style={{ width: "620px" }} />
                                        <col style={{ width: "159px" }} />
                                        <col style={{ width: "620px" }} />
                                    </colgroup>
                                    <tbody>
                                        <tr>
                                            <th>등록일</th>
                                            <td>{resData.upd_date ? resData.upd_date : resData.reg_date}</td>
                                            <th>등록자</th>
                                            <td>{resData.upd_id ? resData.upd_id : resData.reg_id}</td>
                                        </tr>
                                        <tr>
                                            <th>노출여부</th>
                                            <td>{resData.expose_yn === "Y" ? "노출" : "미노출"}</td>
                                            <th>당첨자 발표 여부</th>
                                            <td>{resData.chosen_exist_yn === "Y" ? "있음" : "없음"}</td>
                                        </tr>
                                        <tr>
                                            <th>이벤트 기간</th>
                                            <td colSpan={3}>
                                                {moment(resData.event_start_date, "YYYYMMDDHHmm").format(
                                                    "YYYY-MM-DD [HH[시] mm[분]]"
                                                )}{" "}
                                                ~{" "}
                                                {moment(resData.event_end_date, "YYYYMMDDHHmm").format(
                                                    "YYYY-MM-DD [HH[시] mm[분]]"
                                                )}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>제목</th>
                                            <td colSpan={3}>{resData.event_title}</td>
                                        </tr>
                                        <tr>
                                            <th>사용자페이지 URL</th>
                                            <td
                                                colSpan={3}
                                            >{`https://bowlingkorea.com/event/ongoing/${resData.event_id}`}</td>
                                        </tr>
                                        <tr>
                                            <th>내용</th>
                                            <td colSpan={3}>
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: resData.content,
                                                    }}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>이벤트 배너</th>
                                            <td colSpan={3}>
                                                <div className={classes.event_banner_img}>
                                                    {resData.file_uri && (
                                                        <img
                                                            src={resData.file_uri}
                                                            alt="이벤트 배너 이미지"
                                                            width="500px"
                                                            height="300px"
                                                        />
                                                    )}
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className={classes.bottom_btn_wrap}>
                                    <Button className="border sm" onClick={editModeHandler}>
                                        수정
                                    </Button>
                                    <Button className="sm" onClick={() => setDeleteConfirm(true)}>
                                        삭제
                                    </Button>
                                    <Button className="secondary sm" onClick={backHandler}>
                                        목록
                                    </Button>
                                </div>
                                {deleteConfirm && (
                                    <Alert
                                        type="confirm"
                                        cancel="취소"
                                        confirm="확인"
                                        close={() => setDeleteConfirm(false)}
                                        confirmHandler={deleteHandler}
                                    >
                                        {"삭제 하시겠습니까?"}
                                    </Alert>
                                )}
                                {deleteAlert && (
                                    <Alert type="alert" confirm="확인" close={backHandler}>
                                        {"삭제 되었습니다."}
                                    </Alert>
                                )}
                            </div>
                        </div>
                    </div>
                </section>
            )}
            {isEditMode && (
                <section>
                    <div className={classes.admin_contents}>
                        <h3>이벤트관리 - 수정</h3>
                        <div className={`${classes.contents_wrap} ${classes.search_wrap} `}>
                            <div className={classes.admin_table_wrap}>
                                <table>
                                    <colgroup>
                                        <col style={{ width: "159px" }} />
                                        <col style={{ width: "620px" }} />
                                        <col style={{ width: "159px" }} />
                                        <col style={{ width: "620px" }} />
                                    </colgroup>
                                    <tbody>
                                        <tr>
                                            <th>등록일</th>
                                            <td>{resData.upd_date ? resData.upd_date : resData.reg_date}</td>
                                            <th>등록자</th>
                                            <td>{resData.upd_id ? resData.upd_id : resData.reg_id}</td>
                                        </tr>
                                        <tr>
                                            <th>노출여부</th>
                                            <td>
                                                <div className={classes.radio_wrap}>
                                                    <Radio
                                                        value="Y"
                                                        title="노출"
                                                        name="rad_01"
                                                        id="rad_01_01"
                                                        checked={exposeYn === "Y"}
                                                        onClick={onExposeHandler}
                                                    />
                                                    <Radio
                                                        value="N"
                                                        title="미노출"
                                                        name="rad_01"
                                                        id="rad_01_02"
                                                        checked={exposeYn === "N"}
                                                        onClick={onExposeHandler}
                                                    />
                                                </div>
                                            </td>
                                            <th>당첨자 발표 여부</th>
                                            <td>
                                                <div className={classes.radio_wrap}>
                                                    <Radio
                                                        value="Y"
                                                        title="있음"
                                                        name="rad_02"
                                                        id="rad_02_01"
                                                        checked={chosenExistYn === "Y"}
                                                        onClick={onChosenExistHandler}
                                                    />
                                                    <Radio
                                                        value="N"
                                                        title="없음"
                                                        name="rad_02"
                                                        id="rad_02_02"
                                                        checked={chosenExistYn === "N"}
                                                        onClick={onChosenExistHandler}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>이벤트 기간</th>
                                            <td colSpan={3}>
                                                <div className={classes.flex_row}>
                                                    <DateTimePicker
                                                        id="start_date"
                                                        onChange={startDateHandler}
                                                        value={startDate}
                                                    />
                                                    ~
                                                    <DateTimePicker
                                                        id="end_date"
                                                        onChange={endDateHandler}
                                                        value={endDate}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>제목</th>
                                            <td colSpan={3}>
                                                <Input value={eventTitle} onChange={onEventTitleHandler} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>내용</th>
                                            <td colSpan={3}>
                                                <div style={{ height: "300px" }}>
                                                    <QuillEditor
                                                        height="260"
                                                        width="auto"
                                                        onValueChangeCB={contentChangeHandler}
                                                        value={content}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>이벤트 배너</th>
                                            <td colSpan={3}>
                                                <div className={classes.flex_row}>
                                                    {oldFileUri && (
                                                        <img
                                                            src={oldFileUri}
                                                            alt="구 배너"
                                                            style={{ width: "500px", height: "300px" }}
                                                        />
                                                    )}
                                                    {newFileUri && (
                                                        <img
                                                            src={newFileUri}
                                                            alt="선택한 배너 미리보기"
                                                            style={{ width: "500px", height: "300px" }}
                                                        />
                                                    )}
                                                </div>
                                                <div className={classes.img_file_box}>
                                                    {!oldFileUri && (
                                                        <input
                                                            type="file"
                                                            id="file"
                                                            accept="image/*"
                                                            onChange={onSelectFile}
                                                        />
                                                    )}
                                                    {!oldFileUri && !newFileUri && (
                                                        <label htmlFor="file">파일선택</label>
                                                    )}
                                                    {oldFileUri || newFileUri ? (
                                                        <Button
                                                            className="border sm"
                                                            onClick={() => setShowConfirmation(true)}
                                                        >
                                                            파일삭제
                                                        </Button>
                                                    ) : null}
                                                </div>
                                                <p className={classes.desc}>* 이미지 사이즈 000*000px</p>
                                            </td>
                                        </tr>
                                        {showConfirmation && (
                                            <Alert
                                                type="confirm"
                                                cancel="취소"
                                                confirm="확인"
                                                close={() => setShowConfirmation(false)}
                                                confirmHandler={confirmChangeHandler}
                                            >
                                                {"파일을 삭제 하시겠습니까?"}
                                            </Alert>
                                        )}
                                        {successModal && (
                                            <Alert type="alert" confirm="확인" close={modalHandler}>
                                                {"이벤트가 수정되었습니다."}
                                            </Alert>
                                        )}
                                    </tbody>
                                </table>
                                <div className={classes.bottom_btn_wrap}>
                                    <Button className="border sm" onClick={editHandler}>
                                        수정
                                    </Button>
                                    <Button className="secondary sm" onClick={backHandler}>
                                        목록
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )}
        </>
    );
}

export default MngEventDetail;

//이벤트 상세내용 가져오기
export const loader = async ({ params }) => {
    const response = await instance({
        method: "GET",
        url: `/api/v1/admin/event/${params.eventId}`,
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    }).catch(function (error) {
        if (error.response) {
            return error.response;
        } else if (error.request) {
            return null;
        } else {
            return null;
        }
    });
    if (response == null || response.status !== 200) {
        return [];
    }
    if (response.data.status === 200) {
        console.log(response.data);
        return response.data;
    } else {
        return [];
    }
};

//이벤트 상세내용 가져오기
