import React, { useState } from "react";
import classes from "./Accordion.module.css";

function Accordion({ contents }) {
    // ↓ 퍼블단에서 화면 확인만을 위해 작성한 코드 입니다.
    const [accordionItems, setAccordionItems] = useState([null]);

    const toggleAccordion = (index) => {
        setAccordionItems((prevState) => {
            const newAccordionItems = [...prevState];
            newAccordionItems[index] = !newAccordionItems[index];
            return newAccordionItems;
        });
    };

    return (
        <div className={classes.accordion_warp}>
            {contents.map((item, index) => (
                <div className={`${classes.accordion} ${accordionItems[index] ? classes.expanded : ""}`} key={index}>
                    <h3 onClick={() => toggleAccordion(index)}>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: item.question,
                            }}
                        />
                    </h3>
                    <p className={`${classes.answer} ${accordionItems[index] ? classes.show : ""}`}>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: item.answer,
                            }}
                        />
                    </p>
                </div>
            ))}
        </div>
    );
}
export default Accordion;
