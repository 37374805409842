import { NavLink } from "react-router-dom";
import classes from "../MngCommon.module.css";

function MngSiteNavigation(props) {
    return (
        <div className={classes.admin_sub_nav}>
            <nav>
                <ul className={classes.depth_1}>
                    <li className={classes.depth_1}>
                        <NavLink to="main">진승홈</NavLink>
                        <ul className={classes.depth_2}>
                            <li>
                                <NavLink to="main">메인 띠배너 관리</NavLink>
                            </li>
                            <li>
                                <NavLink to="banner">메인 배너 관리</NavLink>
                            </li>
                            <li>
                                <NavLink to="recommend">추천상품</NavLink>
                            </li>
                            <li>
                                <NavLink to="kegel">케겔</NavLink>
                            </li>
                            <li>
                                <NavLink to="catalog">카탈로그</NavLink>
                            </li>
                        </ul>
                    </li>
                    <li className={classes.depth_1}>
                        <NavLink to="popup">시스템관리</NavLink>
                        <ul className={classes.depth_2}>
                            <li>
                                <NavLink to="popup">팝업관리</NavLink>
                            </li>
                            <li>
                                <NavLink to="terms">
                                    이용약관/개인정보취급방침
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="system">시스템 점검 안내</NavLink>
                            </li>
                        </ul>
                    </li>
                </ul>
            </nav>
        </div>
    );
}

export default MngSiteNavigation;
