import { Outlet } from "react-router-dom";
import MngSiteNavigation from "./MngSiteNavigation";
import classes from "../MngCommon.module.css";

function MngSiteRootLayout() {
    return (
        <>
            <div className={classes.admin_container}>
                <MngSiteNavigation />
                <Outlet />
            </div>
        </>
    );
}

export default MngSiteRootLayout;
