import { Outlet } from "react-router-dom";
import classes from "./TournamentContainerApp.module.css";
import TournamentAppNav from "./TournamentAppNav";

function TournamentAppRootLayout() {
    return (
        <div className={`container ${classes.tournament_container}`}>
            <TournamentAppNav />
            <section>
                <div className={classes.tournament_contents}>
                    <Outlet />
                </div>
            </section>
        </div>
    );
}

export default TournamentAppRootLayout;
