import React from "react";
import classes from "./Pagination.module.css";


/**
 * Table paging을 위한 Page component
 * 
 * @param {props.className} 
 * @param {props.totalPage} page 개수
 * @param {props.currentPage} 현재 page의 Index 값 (0 시작)
 * @param {props.changePageCB} 아이템 클릭시 이동해야 하는 페이지의 Index(0 부터 시작)
 * @param {props.defaultShowPage} 하단에 보여지는 페이지의 개수 (기본 10)
 * < 누를시 이전 10개의 페이지 첫번째로 이동
 * > 누를시 다음 페이지 첫번째로 이동
 * >> 누를시 마지막으로 이동
 
 * 
 * @returns 
 */
// eslint-disable-next-line react/prop-types
function PaginationNew ({ className, totalPage, currentPage, changePageCB, defaultShowPage = 10 ,...props }) {
    // 부모 컴포넌트에서 줘야하는값
    // totalPage : 총 페이지 갯수, currentPage : 현재 페이지(스프링기준0이 1페이지) changeCurrentPage : 페이지 변경함수
    const DefaultShowPageCount = defaultShowPage > 0 ? defaultShowPage : 10;
    const startPageNumber = Math.floor(currentPage / DefaultShowPageCount) * DefaultShowPageCount; // 0 부터 시작
    const pageNumberList = [];
    let showPageCount = 1;
    // 앞 페이지로 이동
    let isGoBackwardClickable = true;
    let isGoTheFirstClickable = true;
    // 뒤 페이지로 이동
    let isGoForwardClickable = true;
    let isGoTheLastClickable = true;


    const tempRemainPageCnt = totalPage - startPageNumber;

    if (tempRemainPageCnt > DefaultShowPageCount) {
        showPageCount = DefaultShowPageCount;
        if (currentPage < DefaultShowPageCount) {
            isGoBackwardClickable = false;
        }
        if (currentPage === 0) {
            isGoTheFirstClickable = false;
        }
    }
    else {
        if (tempRemainPageCnt < DefaultShowPageCount) {
            isGoForwardClickable = false;
            if (currentPage < DefaultShowPageCount)
                isGoBackwardClickable = false;
        }
        if (totalPage === DefaultShowPageCount) {
            isGoForwardClickable = false;
            isGoBackwardClickable = false;
        }
        if (currentPage + 1 === totalPage) {
            isGoTheLastClickable = false;
        }
        showPageCount = totalPage - startPageNumber;
    }

    //console.log ('PaginationNew :: ', totalPage, currentPage, showPageCount);

    for (let i = 0; i < showPageCount; i++) {
        pageNumberList.push(i + startPageNumber);
    }

    const onClickPage = (index) => {
        changePageCB(index);
    };

    const goBackward = () => {
        if (!isGoBackwardClickable)
            return;
        if (currentPage - DefaultShowPageCount < 0) {
            changePageCB(0);
        }
        else {
            changePageCB(Math.floor((currentPage - DefaultShowPageCount) / DefaultShowPageCount) * DefaultShowPageCount);
        }
    };

    const goForward = () => {
        if (!isGoForwardClickable)
            return;

        const nextStartPage = Math.floor((currentPage + DefaultShowPageCount) / DefaultShowPageCount) * DefaultShowPageCount;
        changePageCB(nextStartPage);
    };

    const moveTheFirstPage = () => {
        changePageCB(0);
    };

    const moveTheLastPage = () => {
        changePageCB(totalPage - 1);
    };

    return (
        <nav className={`${classes.pagination} ${classes[className]}`} {...props}>
            <span
                className={`${classes.first} ${isGoTheFirstClickable ? "" : classes.disable}`}
                onClick={moveTheFirstPage}
            ></span>
            <span
                className={`${classes.prev} ${isGoBackwardClickable ? "" : classes.disable}`}
                onClick={goBackward}
            ></span>
            <ul>
                {pageNumberList.map((item, index) => (
                    <li key={index}  className={currentPage === item && classes.on} onClick={() => onClickPage(item)}>
                        {item + 1}
                    </li>
                ))}
            </ul>
            <span
                className={`${classes.next} ${isGoForwardClickable ? "" : classes.disable}`}
                onClick={goForward}
            ></span>
            <span
                className={`${classes.last} ${isGoTheLastClickable ? "" : classes.disable}`}
                onClick={moveTheLastPage}
            ></span>
        </nav>
    );
}

export default PaginationNew;
