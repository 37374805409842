import { useEffect, useState } from "react";
import classes from "../MngCommon.module.css";
import Button from "../../../components/UI/Button/Button";
import Input from "../../../components/UI/Input/Input";
import DateRangePicker from "../../../components/UI/DatePicker/DateRangePicker";
import SelectBoxNew from "../../../components/UI/SelectBox/SelectBoxNew";
import instance from "../../../store/AxiosInterceptor";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Moment from "moment";
import PaginationNew from "../../../components/UI/Table/PaginationNew";
import {
    ContestNoticeFilterIsUse,
    ContestNoticeSearchTypes,
} from "../../../components/definedefault/DefSelectBoxValues";
function MngCommunity() {
    

    // 등록 페이지
    const [text, setText] = useState([]);
    const [keyword, setKeyword] = useState("");

    const location = useLocation();
    const navigate = useNavigate();
    ///>>> select Box
    
    const [isUse, setIsUse] = useState("");
    const [filter, setFilter] = useState("");

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [searchStartDate, setSearchStartDate] = useState("");
    const [searchEndDate, setSearchEndDate] = useState("");

    const handleStartDateChange = (date) => {
        setStartDate(date);
        setSearchStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
        setSearchEndDate(date);
    };

    // 검색어
    const keywordHandler = (keyword) => {
        setKeyword(keyword);
    };

    const useHandler = (key) => {
        setIsUse(key);
    };

    const filterHandler = (key) => {
        setFilter(key);
    };

    const [currentPage, setCurrentPage] = useState(location.state?.currentPage ?? 0);
    const [totalPage, setTotalPage] = useState(0); //총 페이지 갯수
    const [totalCount, setTotalCount] = useState(0); //총 페이지 갯수
    const uploadHandler = () => {
        navigate("/admin/community/notice/upload", {state: {currentPage}});
    };
    const viewAll = async () => {
        setCurrentPage(0);
        setIsUse("");
        setFilter("");
        setStartDate(new Date());
        setEndDate(new Date());
        setSearchStartDate("");
        setSearchEndDate("");
        const response = await instance.get(
            "/api/v1/admin/board/notice?page=0",
            {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            }
        );
        if (response.status === 200) {
            const resData = await response.data.datas;
            setTotalPage(resData.totalPages);
            setTotalCount(resData.totalElements);
            setText(resData.content);
        } else {
            setText([]);
        }
    };

    const handlePageChange = async (page) => {
        setCurrentPage(page);

        const searchParam = {
            title: filter === "title" ? keyword : "",
            regId: filter === "writer" ? keyword : "",
            // openOtherYn: isUse,
            exposeYn: isUse,
            sDate:
                searchStartDate === ""
                    ? null
                    : Moment(searchStartDate).format("YYYYMMDD"),
            eDate:
                searchEndDate === ""
                    ? null
                    : Moment(searchEndDate).format("YYYYMMDD"),
            page: page,
        };

        const response = await instance.get("/api/v1/admin/board/notice", {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
            params: searchParam,
        });
        if (response.status === 200) {
            const resData = await response.data.datas;
            setTotalPage(resData.totalPages);
            setTotalCount(resData.totalElements);
            setText(resData.content);
        } else {
            setText([]);
        }
    };
    useEffect(() => {
        handlePageChange(currentPage);
    }, [])
    // 검색 버튼 클릭
    const search = async () => {
        const searchParam = {
            title: filter === "title" ? keyword : "",
            regId: filter === "writer" ? keyword : "",
            // openOtherYn: isUse,
            exposeYn: isUse,
            sDate:
                searchStartDate === ""
                    ? null
                    : Moment(searchStartDate).format("YYYYMMDD"),
            eDate:
                searchEndDate === ""
                    ? null
                    : Moment(searchEndDate).format("YYYYMMDD"),
            page: 0,
        };

        const response = await instance.get("/api/v1/admin/board/notice", {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
            params: searchParam,
        });

        if (response.status === 200) {
            const resData = await response.data.datas;
            setTotalPage(resData.totalPages);
            setTotalCount(resData.totalElements);
            setText(resData.content);
        } else {
            setText([]);
        }
    };

    const bodyValues = text.map(
        ({ id, title, exposeYn, readCnt, regID, regDate, seq }) => {
            return (
                <tr>
                    <td>{seq}</td>
                    <td>
                        <NavLink to={`${id}`} state={{currentPage}}>{title}</NavLink>
                    </td>
                    {/* <td onClick={() => increaseViews(id)}>{title}</td> */}
                    <td>{regDate}</td>
                    <td>{regID}</td>
                    <td>{exposeYn ? "사용" : "미사용"}</td>
                    <td>{readCnt}</td>
                </tr>
            );
        }
    );

    return (
        <section>
            <div className={classes.admin_contents}>
                <h3>공지사항 - 목록</h3>
                <div
                    className={`${classes.contents_wrap} ${classes.search_wrap}`}
                >
                    <div className={classes.admin_table_wrap}>
                        <table>
                            <colgroup>
                                <col style={{ width: "11%" }} />
                                <col style={{ width: "59%" }} />
                                <col style={{ width: "11%" }} />
                                <col style={{ width: "19%" }} />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>등록일</th>
                                    <td colSpan={3}>
                                        <DateRangePicker
                                            startDate={startDate}
                                            endDate={endDate}
                                            onStartDateChange={
                                                handleStartDateChange
                                            }
                                            onEndDateChange={
                                                handleEndDateChange
                                            }
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>검색구분</th>
                                    <td>
                                        <div className={classes.flex_row}>
                                            <SelectBoxNew
                                                className="admin_st"
                                                setSelectItemCB={filterHandler}
                                                optionDatas={
                                                    ContestNoticeSearchTypes
                                                }
                                                showDefaultKey={filter}
                                            />

                                            <Input
                                                className="admin_st"
                                                placeholder="검색어 입력"
                                                value={keyword}
                                                onChange={keywordHandler}
                                            />
                                        </div>
                                    </td>
                                    <th>사용여부</th>
                                    <td>
                                        <SelectBoxNew
                                            className="admin_st"
                                            setSelectItemCB={useHandler}
                                            optionDatas={
                                                ContestNoticeFilterIsUse
                                            }
                                            showDefaultKey={isUse}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className={classes.bottom_btn_wrap}>
                            <Button onClick={search} className="border sm">
                                검색
                            </Button>
                            <Button onClick={viewAll} className="secondary sm">
                                전체보기
                            </Button>
                        </div>
                    </div>
                </div>
                <div className={classes.contents_wrap}>
                    <div
                        className={`${classes.admin_table_wrap} ${classes.align_center}  ${classes.row_select}`}
                    >
                        <p className={classes.counter}>총 {totalCount}건</p>
                        <table className={classes.left_col_2}>
                            <colgroup>
                                <col style={{ width: "5%" }} />
                                <col style={{ width: "40%" }} />
                                <col style={{ width: "15%" }} />
                                <col style={{ width: "15%" }} />
                                <col style={{ width: "15%" }} />
                                <col style={{ width: "10%" }} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th>제목</th>
                                    <th>등록일</th>
                                    <th>작성자</th>
                                    <th>사용여부</th>
                                    <th>조회수</th>
                                </tr>
                            </thead>
                            <tbody>{bodyValues}</tbody>
                        </table>
                        <PaginationNew
                            className="admin_st"
                            totalPage={totalPage}
                            currentPage={currentPage}
                            defaultShowPage={5}
                            changePageCB={handlePageChange}
                        />
                        <div
                            className={`${classes.bottom_btn_wrap} ${classes.position_st}`}
                        >
                            <Button
                                className="secondary sm"
                                onClick={uploadHandler}
                            >
                                등록
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default MngCommunity;

export const loader = async () => {
    try {
        const response = await instance.get(
            "/api/v1/admin/board/notice?page=0",
            {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            }
        );
        const resData = response.data.datas;
        console.log("resData ", resData);

        const curData = resData.content.map((data) => {
            return {
                id: data.id,
                title: data.title,
                exposeYn: data.exposeYn,
                readCnt: data.readCnt,
                regID: data.regID,
                regDate: data.regDate,
                seq: data.seq, // [M] YiSuHwan 순번 관련 처리
            };
        });

        return {
            data: curData,
            totalPage: resData.totalPages,
            totalCount: resData.totalElements,
        };
    } catch (error) {
        console.error("Error fetching data:", error);
        return [];
    }
};
