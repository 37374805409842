import { Outlet } from "react-router-dom";
import classes from "./MediaApp.module.css";
import MediaAppNavigation from "./MediaAppNavigation";

function MediaAppRootLayout() {
    return (
        <div className={`container ${classes.media_container}`}>
            <MediaAppNavigation />
            <section>
                <div className={classes.media_contents}>
                    <Outlet />
                </div>
            </section>
        </div>
    );
}

export default MediaAppRootLayout;
