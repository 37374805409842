import { useEffect, useState } from "react";
import Moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import { ReqPopupList } from "../../../api/site/ApiForPopup";
import Button from "../../../components/UI/Button/Button";
import PaginationNew from "../../../components/UI/Table/PaginationNew";
import classes from "../MngCommon.module.css";

function MngPopupPage(props) {
    const location = useLocation();
    const navigate = useNavigate();

    const createHandler = () => {
        navigate("./create");
    };

    
    const [popups, setPopups] = useState([]);
    
    const [currentPage, setCurrentPage] = useState(location.state?.currentPage ?? 0);
    const [totalPage, setTotalPage] = useState(0); //총 페이지 갯수
    const [totalCount, setTotalCount] = useState(0); //총 페이지 갯수
    
    const detailHandler = (e, id) => {
        e.preventDefault();

        navigate(`./${id}`, { state: { currentPage } });
    };
    const handlePageChange = async (page) => {
        setCurrentPage(page);
    
        const response = await ReqPopupList(page);
        if (response.status === 200) {
            const resData = await response.data.data;
            setTotalPage(resData.totalPages);
            setTotalCount(resData.totalElements);
            setPopups(resData.content);
        } else {
            setPopups([]);
        }
      };
    useEffect(() => {
        handlePageChange(currentPage)
    }, [])
    return (
        <section>
            <div className={classes.admin_contents}>
                <h3>팝업관리 - 목록</h3>
                <div className={classes.contents_wrap}>
                    <div
                        className={`${classes.admin_table_wrap} ${classes.align_center}  ${classes.row_select} ${classes.left_col_2}`}
                    >
                        <table>
                            <colgroup>
                                <col style={{ width: "10%" }} />
                                <col style={{ width: "40%" }} />
                                <col style={{ width: "15%" }} />
                                <col style={{ width: "13%" }} />
                                <col style={{ width: "12%" }} />
                                <col style={{ width: "10%" }} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th>팝업타이틀</th>
                                    <th>게시기간</th>
                                    <th>등록일</th>
                                    <th>등록자</th>
                                    <th>노출여부</th>
                                </tr>
                            </thead>
                            <tbody>
                                {popups.length !== 0 && popups.map((data, index) => (
                                    <tr onClick={(e) => detailHandler(e, data.id)}>
                                        <td>{data.seq}</td>
                                        <td>
                                            {data.title}
                                        </td>
                                        <td>{Moment(data.expose_s_date).format('YYYY-MM-DD')} ~ {Moment(data.expose_e_date).format('YYYY-MM-DD')}</td>
                                        <td>{Moment(data.reg_date).format('YYYY-MM-DD')}</td>
                                        <td>{data.reg_id}</td>
                                        <td>{data.exposeYn ? "노출" : "미노출"}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <PaginationNew className="admin_st" totalPage={totalPage} currentPage={currentPage} defaultShowPage={5} changePageCB={handlePageChange} />
                        <div
                            className={`${classes.bottom_btn_wrap} ${classes.position_st}`}
                        >
                            <Button
                                className="secondary sm" onClick={createHandler}
                            >
                                등록
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default MngPopupPage;

export const loader = async () => {
    const response = await ReqPopupList(0);
    
    if (response.status === 200) {
        const resData = await response.data.data;
        return { data: resData.content, totalPage: resData.totalPages, totalCount : resData.totalElements };
    } else {
        return { data: [], totalPage: 0, totalCount : 0 };
    }
};
