import instance from "../../store/AxiosInterceptor";

/**
 * 대회 타입 정보 전체 요청
 * @param successHandlerCB 응답이 성공일 경우 데이터를 넣어 호출하는 callback method
 * @returns
 */
export function ReqContestType(resolveCB, rejectCB) {
    instance({
        url: "/api/v1/admin/contest/type",
        method: "GET",
    })
        .then((response) => {
            // List [ { id, name } ...]
            if (resolveCB) {
                resolveCB(response.data.datas);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/**
 * 지역정보 전체 요청
 *
 * @param successHandlerCB 응답이 성공일 경우 데이터를 넣어 호출하는 callback method
 * @returns
 */
export function ReqRegionData(resolveCB, rejectCB) {
    instance({
        url: "/api/v1/admin/contest/region",
        method: "GET",
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data.datas);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
            // if (error.response) { // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
            // }
            // else if (error.request) { // 요청이 이루어 졌으나 응답을 받지 못했습니다.
            // }
            // else { // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
            // }
        });
}

/**
 * 대회 전체 요청
 *
 * @param successHandlerCB 응답이 성공일 경우 데이터를 넣어 호출하는 callback method
 * @returns
 */
export function ReqTournamentNameByCtType(contestType, resolveCB, rejectCB) {
    instance({
        url: `/api/v1/admin/contest/main?type=${contestType}`,
        method: "GET",
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data.datas);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

export function ReqStadiumListWithRegion(regionName, resolveCB, rejectCB) {
    instance({
        url: `/api/v1/admin/contest/center/region?regionName=${regionName}`,
        method: "GET",
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data.datas);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
            // if (error.response) { // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
            // }
            // else if (error.request) { // 요청이 이루어 졌으나 응답을 받지 못했습니다.
            // }
            // else { // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
            // }
        });
}

export function ReqStadiumListWithRegionContestId(regionName, contestID, resolveCB, rejectCB) {
    instance({
        url: `/api/v1/admin/contest/center/region/${contestID}?regionName=${regionName}`,
        method: "GET",
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data.datas);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
            // if (error.response) { // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
            // }
            // else if (error.request) { // 요청이 이루어 졌으나 응답을 받지 못했습니다.
            // }
            // else { // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
            // }
        });
}

export function ReqContestNameWithCtType(contestType, resolveCB, rejectCB) {
    instance({
        url: `/api/v1/admin/contest/main?type=${contestType}`,
        method: "GET",
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data.datas);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
            // if (error.response) { // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
            // }
            // else if (error.request) { // 요청이 이루어 졌으나 응답을 받지 못했습니다.
            // }
            // else { // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
            // }
        });
}

/**
 * 저장되어있는 대회 진행 타입 전부를 가져온다.
 * @param successHandlerCB 응답이 성공일 경우 데이터를 넣어 호출하는 callback method
 * @returns
 */
export function ReqContestProcessType(resolveCB, rejectCB) {
    instance({
        url: `/api/v1/admin/contest/processType`,
        method: "GET",
    })
        .then((response) => {
            // List [ { id, name } ...]
            if (resolveCB) {
                resolveCB(response.data.datas);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

export function ReqContestNameWithCtType2(contestType, resolveCB, rejectCB) {
    instance({
        url: `/api/v1/admin/contest/main/type/${contestType}`,
        method: "GET",
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data.datas);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/**
 * 지역정보 전체 요청
 * 일반 사용자를 위함, 일반사용자는 admin접근 불가
 * @param successHandlerCB 응답이 성공일 경우 데이터를 넣어 호출하는 callback method
 * @returns
 */
export function ReqRegionDataUser(resolveCB, rejectCB) {
    instance({
        url: "/api/v1/home/contest/region",
        method: "GET",
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data.datas);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/**
 * 대회별 그룹정보
 *
 * @param successHandlerCB 응답이 성공일 경우 데이터를 넣어 호출하는 callback method
 * @returns
 */
export function ReqGroupData(contestId, resolveCB, rejectCB) {
    instance({
        url: `/api/v1/admin/contest/group/${contestId}`,
        method: "GET",
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data.datas);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}
// if (error.response) { // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
// }
// else if (error.request) { // 요청이 이루어 졌으나 응답을 받지 못했습니다.
// }
// else { // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
// }
