import { useEffect, useState } from "react";
import { useLoaderData } from "react-router";
import { ReqRegionDataUser } from "../../../api/common/ConditionSelectBoxApi";
import { ReqApplyTournamentList } from "../../../api/home/TournamentHomeApplyApi";
import Button from "../../../components/UI/Button/Button";
import SelectBoxNew from "../../../components/UI/SelectBox/SelectBoxNew";
import { DefaultRegionSelectBox } from "../../../components/definedefault/DefSelectBoxValues";
import instance from "../../../store/AxiosInterceptor";
import classes from "../TournamentContainerApp.module.css";
import ApplyTournamentListApp from "./components/ApplyTournamentListApp";

/**
 * https://bowlingkorea.com/tournament/apply 
 * 대회 접수 화면의 목록 관리
 * ApplyTournamentListApp 가 개별 데이터
 * 
 * @param {} props 
 * @returns 
 */
function ApplyTournamentAppPage(props) {
    /**
     * 대회 접수 관련 모달 상태관리는
     * 전부 ApplyTournamentList 컴포넌트에서 관리힌다.
     * contestId, placeId의 상태관리를 하기위함
     * 해당 컴포넌트에서는 addted_notice를 사용하지 않기 때문에 더미데이터가 들어옵니다.
     */
    const [contestList, setContestList] = useState([]);
    const [page, setPage] = useState(0);
    const size = 5;
    const [totalPage, setTotalPage] = useState(0);

    // 지역선택 Select box
    const [regionDatas, setRegionDatas] = useState([DefaultRegionSelectBox]);
    const [selectedRegion, setSelectedRegion] = useState("");
    const resData = useLoaderData();

    const regionNameMouseOverHandler = (key) => {
        if (regionDatas.length === 1) {
            ReqRegionDataUser(
                (result) => {
                    const success = result.map((data) => {
                        return { key: data.name, value: data.name };
                    });
                    setRegionDatas((prev) => [
                        DefaultRegionSelectBox,
                        ...success,
                    ]);
                },
                (excption_result) => {
                    setRegionDatas((prev) => [DefaultRegionSelectBox]);
                }
            );
        }
    };

    const changePageHandler = (key) => {
        setPage(key);
    };

    //대회 데이터 얻기
    const onContestListHandler = (selectedRegion) => {
        setSelectedRegion(selectedRegion);
        changePageHandler(0);
        ReqApplyTournamentList(
            0,
            size,
            selectedRegion,
            (result) => {
                const curData = result.content.map((data) => {
                    return {
                        ctId: data.ct_id,
                        ctTitle: data.ct_title,
                        ctType: data.ct_type,
                        ctTypeName: data.ct_type_name,
                        placeId: data.place_id,
                        centerName: data.center_name,
                        centerRegion: data.center_region,
                        ctStartDate: data.ct_start_date,
                        ctEndDate: data.ct_end_date,
                        appStartTime: data.ct_app_start_time,
                        appEndTime: data.ct_app_end_time,
                        regDate: data.reg_date,
                        ctAgreementInfo: data.ct_agreement_info,
                        completePosition: data.complete_position,
                        centerDefaultAddress: data.center_default_address,
                        centerDetailedAddress: data.center_detailed_address,
                        ctAppType: data.ct_app_type,
                        exposeDraw: data.expose_draw,
                        exposePosition: data.expose_position,
                        ctProcessType: data?.ct_process_type,
                        placeCloseApply: data?.close_apply,
                    };
                });
                setContestList(curData);
                setTotalPage(result.totalPages);
                setPage(result.pageable.pageNumber);
            },
            (error_result) => {
                setContestList((prev) => prev);
            }
        );
    };

    //대회 데이터 얻기
    const onContestPagingHandler = () => {
        const pages = page + 1;
        ReqApplyTournamentList(
            pages,
            size,
            selectedRegion,
            (result) => {
                const curData = result.content.map((data) => {
                    return {
                        ctId: data.ct_id,
                        ctTitle: data.ct_title,
                        ctType: data.ct_type,
                        ctTypeName: data.ct_type_name,
                        placeId: data.place_id,
                        centerName: data.center_name,
                        centerRegion: data.center_region,
                        ctStartDate: data.ct_start_date,
                        ctEndDate: data.ct_end_date,
                        appStartTime: data.ct_app_start_time,
                        appEndTime: data.ct_app_end_time,
                        regDate: data.reg_date,
                        ctAgreementInfo: data.ct_agreement_info,
                        completePosition: data.complete_position,
                        centerDefaultAddress: data.center_default_address,
                        centerDetailedAddress: data.center_detailed_address,
                        ctAppType: data.ct_app_type,
                        exposeDraw: data.expose_draw,
                        exposePosition: data.expose_position,
                        ctProcessType: data?.ct_process_type,
                        placeCloseApply: data?.close_apply,                        
                    };
                });
                changePageHandler(pages);
                setContestList((prev) => [...prev, ...curData]);
                setTotalPage(result.totalPages);
            },
            (error_result) => {
                setContestList((prev) => prev);
            }
        );
    };

    useEffect(() => {
        if (resData.content) {
            const curData = resData.content.map((data) => {
                return {
                    ctId: data.ct_id,
                    ctTitle: data.ct_title,
                    ctType: data.ct_type,
                    ctTypeName: data.ct_type_name,
                    placeId: data.place_id,
                    centerName: data.center_name,
                    centerRegion: data.center_region,
                    ctStartDate: data.ct_start_date,
                    ctEndDate: data.ct_end_date,
                    appStartTime: data.ct_app_start_time,
                    appEndTime: data.ct_app_end_time,
                    regDate: data.reg_date,
                    ctAgreementInfo: data.ct_agreement_info,
                    completePosition: data.complete_position,
                    centerDefaultAddress: data.center_default_address,
                    centerDetailedAddress: data.center_detailed_address,
                    ctAppType: data.ct_app_type,
                    exposeDraw: data.expose_draw,
                    exposePosition: data.expose_position,
                    ctProcessType: data?.ct_process_type,
                    placeCloseApply: data?.close_apply,
                };
            });
            setContestList(curData);
            setTotalPage(resData.totalPages);
        }
    }, [resData]);

    return (
        <>
            <div className={classes.search_wrap}>
                <SelectBoxNew
                    optionDatas={regionDatas}
                    setSelectItemCB={onContestListHandler}
                    mouseOverCB={regionNameMouseOverHandler}
                    showDefaultKey={selectedRegion}
                />
            </div>
            <div
                className={`${classes.mobile_board_table} ${classes.apply_type}`}
            >
                <table>
                    <colgroup>
                        <col style={{ width: "13.33%" }} />
                        <col style={{ width: "25%" }} />
                        <col style={{ width: "10.83%" }} />
                        <col style={{ width: "7.5%" }} />
                        <col style={{ width: "15.83%" }} />
                        <col style={{ width: "7.5%" }} />
                        <col style={{ width: "7.5%" }} />
                        <col style={{ width: "12.5%" }} />
                    </colgroup>
                    <thead>
                        <tr>
                            <th>유형</th>
                            <th>대회</th>
                            <th>대회기간</th>
                            <th>지역</th>
                            <th>경기장</th>
                            <th>상태</th>
                            <th>접수</th>
                            <th>접수결과 및 레인</th>
                        </tr>
                    </thead>
                    <tbody>
                        {contestList.map((data, index) => (
                            <ApplyTournamentListApp
                                key={index}
                                ctId={data.ctId}
                                ctTitle={data.ctTitle}
                                ctType={data.ctType}
                                ctTypeName={data.ctTypeName}
                                placeId={data.placeId}
                                centerName={data.centerName}
                                centerRegion={data.centerRegion}
                                ctStartDate={data.ctStartDate}
                                ctEndDate={data.ctEndDate}
                                appStartTime={data.appStartTime}
                                appEndTime={data.appEndTime}
                                regDate={data.regDate}
                                ctAgreementInfo={data.ctAgreementInfo}
                                completePosition={data.completePosition}
                                centerDefaultAddress={data.centerDefaultAddress}
                                centerDetailedAddress={
                                    data.centerDetailedAddress
                                }
                                ctAppType={data.ctAppType}
                                exposeDraw={data.exposeDraw}
                                exposePosition={data.exposePosition}
                                ctProcessType={data.ctProcessType}
                                placeCloseApply={data.placeCloseApply}                                
                            />
                        ))}
                    </tbody>
                </table>
                {/* 모바일버전 - 페이징 삭제 / 더보기 추가 */}
                <div className={classes.more_btn}>
                    <Button
                        className="text_icon"
                        onClick={onContestPagingHandler}
                    >
                        더보기
                    </Button>
                </div>
            </div>
        </>
    );
}

export default ApplyTournamentAppPage;

//>>>대회 목록 뿌려주기 Loader
export const loader = async () => {
    const response = await instance({
        method: "GET",
        url: `/api/v1/home/contest/apply?page=0&size=5`,
    }).catch(function (error) {
        if (error.response) {
            // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
            // console.log(error.response.data);
            // console.log(error.response.status);
            // console.log(error.response.headers);
            return error.response;
        } else if (error.request) {
            // 요청이 이루어 졌으나 응답을 받지 못했습니다.
            console.log(error.request);
            return null;
        } else {
            // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
            console.log("Error", error.message);
            return null;
        }
    });
    if (response == null || response.status !== 200) {
        return [];
    }
    if (response.data.status === 200) {
        return response.data.datas;
    } else {
        return [];
    }
};
//<<<대회 목록 뿌려주기 Loader
