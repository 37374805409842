import React from "react";
import classes from "../UserHome.module.css";

const HomeBanner = (props) => {
    // 주어진 linkUrl 값
    const linkUrl = props.linkUrl;

    // "https://"로 시작하지 않는 경우 "http://"를 추가
    const modifiedLinkUrl = linkUrl?.startsWith("https://") ? linkUrl : "http://" + linkUrl;

    return (
        <a
            href={`${modifiedLinkUrl}`}
            target={props.launchNewPage === "Y" ? "_blank" : null}
            rel={props.launchNewPage === "Y" ? "noopener noreferrer" : null}
        >
            <div className={classes.main_img}>
                <img src={props.fileUri} alt="" />
            </div>
        </a>
    );
};

export default HomeBanner;
