import { NavLink } from "react-router-dom";
import classes from "./IntroduceApp.module.css";
import { useRef } from "react";
import Slider from "react-slick";

function IntroduceAppNavigation(props) {
    // sub nav 슬라이더
    const sliderRef = useRef(null);
    const sliderSettings = {
        className: "header_sub_nav",
        dots: false,
        arrows: false,
        infinite: false,
        speed: 300,
        slidesToShow: 5,
        slidesToScroll: 1,
        initialSlide: 0,
        variableWidth: true,
        responsive: [
            {
                breakpoint: 360,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    return (
        <div className={classes.introduce_sub_nav}>
            <h3>회사소개</h3>
            <Slider ref={sliderRef} {...sliderSettings}>
                <li className={classes.depth_1_link}>
                    <NavLink to="introduce">인사말</NavLink>
                </li>
                <li className={classes.depth_1_link}>
                    <NavLink to="history">연혁</NavLink>
                </li>
                <li className={classes.depth_1_link}>
                    <NavLink to="staff">STAFF</NavLink>
                </li>
                <li className={classes.depth_1_link}>
                    <NavLink to="center">전국 볼링장</NavLink>
                </li>
                <li className={classes.depth_1_link}>
                    <NavLink to="location">찾아오시는 길</NavLink>
                </li>
            </Slider>
        </div>
    );
}

export default IntroduceAppNavigation;
