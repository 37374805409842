import _ from "lodash";
import instance from "../../store/AxiosInterceptor";

const toBackendParamUrl = (url = "", searchParams = {}) => {
    let querys = [];
    const keys = { searchKeyword: "name" };
    for (const sKey in searchParams) {
        const key = keys[sKey] ?? sKey;
        if (_.isString(key) && key !== "") {
            querys.push(`${key}=${searchParams[sKey]}`);
        }
    }
    return `${url}${url.includes("?") ? "&" : "?"}${querys.join("&")}`;
};

export const getHomeProduct = async (
    { page = 0, category = 100, depth = 1 },
    searchParams = {},
    selectedValues = []
) => {
    try {
        const url = toBackendParamUrl("/api/v1/home/product", {
            page,
            category,
            depth,
            ...searchParams,
            selectedValues,
        });
        // console.log("getHomeProduc URL::", url);
        const response = await instance.get(url);
        // console.log("getHomeProduct ::: ", response);
        // console.log("여기 !!! ", selectedValues);
        // console.log(
        //     "getHomeProduct searchParams :: ",
        //     page,
        //     category,
        //     depth,
        //     searchParams,
        //     selectedValues
        // );

        return response;
    } catch (error) {
        return { error };
    }
};

// 여기 해봐 여기서 스마트필터 데이터도 불러와야 할 듯???
export const getHomeProductS = async (
    { page = 0, category = 100, depth = 1 },
    searchParams = {}
) => {
    try {
        const url = toBackendParamUrl("/api/v1/home/product", {
            page,
            category,
            depth,
            ...searchParams,
        });
        console.log("getHomeProduc URLt::", url);
        const response = await instance.get(url);
        // console.log("getHomeProduct ::: ", response, searchParams);
        return response;
    } catch (error) {
        return { error };
    }
};
// 카테고리는 datas.children에 있네

export const getHomeProductDetail = async (id) => {
    try {
        const response = await instance.get(
            `/api/v1/home/product/category/PRODUCT/${id}`
        );
        // console.log("getHomeProductDetail ::: ", response, id);
        // console.log("getHomeProductDetail id ", id);
        return response;
    } catch (error) {
        return { error };
    }
};

export const getHomeProductDetailInfo = async (id) => {
    try {
        const url = `/api/v1/home/product/${id}`;
        const response = await instance.get(url);
        // console.log("getHomeProductDetailInfo ::: ", response, id);
        // console.log("getHomeProductDetailInfo id ", id);
        return response;
    } catch (error) {
        return { error };
    }
};

export const getHomeSmartFilter = async (id, categoryId, depth) => {
    try {
        // console.log("categoryId :::", categoryId, "depth:::", depth);

        const response = await instance.get(
            `/api/v1/home/product/smartfilter/menu/${id}/${categoryId}/${depth}`
        );
        // console.log("getHomeSmartFilter ::: ", response, id);
        // console.log("getHomeSmartFilter ::: ", response, id);
        // console.log("getHomeSmartFilter id ", id);
        return response;
    } catch (error) {
        return { error };
    }
};

export default this;
