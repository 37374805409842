import React, { useContext, useState } from "react";
import Button from "../../../components/UI/Button/Button";
import Input from "../../../components/UI/Input/Input";
import Radio from "../../../components/UI/Input/Radio";
import classes from "../MngCommon.module.css";
import { ReqInsertKegelInfo } from "../../../api/kegel/KegelAPi";
import { useLoaderData, useNavigate } from "react-router-dom";
import SelectBoxKegel from "../../../components/UI/SelectBox/SelectBoxKegel";
import QuillEditor from "../../../components/UI/Editer/QuillEditor";
import FileBoxMj from "../../../components/UI/Input/FileBoxMj";
import { JwtTokenContext } from "../../../store/JwtTokenProvider";

function MngKegelInfoUpload() {
    const loginCtx = useContext(JwtTokenContext);
    // 등록일 (오늘 날짜 표시)
    const today = () => {
        const now = new Date();

        return (
            now.getFullYear() +
            "-" +
            ("0" + (now.getMonth() + 1)).slice(-2) +
            "-" +
            ("0" + now.getDate()).slice(-2)
        );
    };
    const navigate = useNavigate();
    const kegelData = useLoaderData();

    const toList = () => {
        navigate("/admin/kegel/info");
    };

    const sampleData = [
        {
            value: "유용한 정보",
            data: "USEFUL_INFO",
            disabled: "false",
            selected: "false",
        },
        {
            value: "문제해결 도우미",
            data: "HELP",
            disabled: "false",
            selected: "false",
        },
    ];

    const kegelGuideTypeMapping = {
        USEFUL_INFO: "유용한 정보",
        HELP: "문제해결 도우미",
    };

    //[S] 입력 데이터 --------------------

    const [selectedCategory, setSelectedCategory] = useState("");
    const [selectedCategoryData, setSelectedCategoryData] = useState("");

    const [title, setTitle] = useState("");
    const titleHandler = (value) => {
        setTitle(value);
    };
    const [content, setContent] = useState("");
    const contentHandler = (value) => {
        setContent(value);
    };
    const [exposeYn, setExposeYn] = useState("N");
    const isExposeChangeHandler = (e) => {
        setExposeYn(e.target.value);
    };
    const [subDescription, setSubDescription] = useState("");
    const subDescriptionHandler = (value) => {
        setSubDescription(value);
    };
    const [imageSrc, setImageSrc] = useState(null);
    const handleFileChangeCallback = (file, image) => {
        setFile(file);
        setImageSrc(image);
    };

    const handleFileDeleteCallback = () => {
        setFile(null);
        setImageSrc(null);
    };
    const [file, setFile] = useState();
    const [fileName, setFileName] = useState("");
    const handleFileChange = (file) => {
        setFile(file);
        setFileName(file ? file.name : "");
    };

    const submitHandler = async (e) => {
        e.preventDefault();

        const jsonData = JSON.stringify({
            title: title,
            content: content,
            exposeYn: exposeYn,
            subDescription: subDescription,
            kegelGuideType: selectedCategoryData,
        });

        const formData = new FormData();
        formData.append("image", file);
        formData.append(
            "body",
            new Blob([jsonData], { type: "application/json" })
        );

        const response = await ReqInsertKegelInfo(formData);
        if (response.status === 200) {
            navigate("/admin/kegel/info");
        } else {
            alert(
                `[${response.status}] ${
                    response.data ? response.data.error : "등록 실패"
                } `
            );
        }
    };

    //[E] 입력 데이터 --------------------

    return (
        <section>
            <div className={classes.admin_contents}>
                <h3>유용한 정보 / 문제해결 도우미 - 등록</h3>
                <div
                    className={`${classes.contents_wrap} ${classes.search_wrap} `}
                >
                    <div className={classes.admin_table_wrap}>
                        <table>
                            <colgroup>
                                <col style={{ width: "159px" }} />
                                <col style={{ width: "620px" }} />
                                <col style={{ width: "159px" }} />
                                <col style={{ width: "620px" }} />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>등록일</th>
                                    <td>{today()}</td>
                                    <th>등록자</th>
                                    <td>{loginCtx.decodedToken.jti}</td>
                                </tr>
                                <tr>
                                    <th>노출여부</th>
                                    <td colSpan={3}>
                                        <div className={classes.radio_wrap}>
                                            <Radio
                                                value="Y"
                                                title="노출"
                                                name="rad_01"
                                                id="rad_01_01"
                                                checked={exposeYn === "Y"}
                                                onChange={isExposeChangeHandler}
                                            />
                                            <Radio
                                                value="N"
                                                title="미노출"
                                                name="rad_01"
                                                id="rad_01_02"
                                                checked={exposeYn === "N"}
                                                onChange={isExposeChangeHandler}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>구분</th>
                                    <td colSpan={3}>
                                        <SelectBoxKegel
                                            className="admin_st"
                                            optionData={sampleData}
                                            selectedValue={selectedCategory}
                                            setSelectedValue={
                                                setSelectedCategory
                                            }
                                            selectedData={selectedCategoryData}
                                            setSelectedData={
                                                setSelectedCategoryData
                                            }
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>제목</th>
                                    <td colSpan={3}>
                                        <Input
                                            className="admin_st w_100"
                                            value={title}
                                            onChange={titleHandler}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>파일첨부</th>
                                    <td colSpan={3}>
                                        {/* {FileBoxForImage(
                                            imageSrc,
                                            handleFileChangeCallback,
                                            handleFileDeleteCallback
                                        )} */}
                                        <FileBoxMj
                                            onFileChange={handleFileChange}
                                            selectedFileName={fileName}
                                        />
                                    </td>
                                </tr>

                                {/* <tr>
                                    <th>사용자페이지 URL</th>
                                    <td colSpan={3}>
                                        https://bowlingkorea.com/kegel/laneMachinesView.do?ntt_id=43202&currRow=1
                                    </td>
                                </tr> */}
                                <tr>
                                    <th>상세정보</th>
                                    <td colSpan={3}>
                                        <div style={{ height: "500px" }}>
                                            <QuillEditor
                                                height="460"
                                                width="auto"
                                                value={content}
                                                onValueChangeCB={contentHandler}
                                            />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <div className={classes.bottom_btn_wrap}>
                            <Button
                                className="border sm"
                                onClick={submitHandler}
                            >
                                등록
                            </Button>
                            <Button className="secondary sm" onClick={toList}>
                                목록
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default MngKegelInfoUpload;
