import React, { useEffect, useMemo, useState } from "react";
import Button from "../../../../components/UI/Button/Button";
import classes from "../../MngCommon.module.css";
import Radio from "../../../../components/UI/Input/Radio";
import Input from "../../../../components/UI/Input/Input";
import { FileBoxForImage } from "../../../../components/UI/Input/FileBox";
import _ from "lodash";

// eslint-disable-next-line react/prop-types
const ProductCategoryInfo = ({
    title: categoryTitle = "대 카테고리",
    isNew = false,
    depth = 0,
    datas = null,
    nextSeq = 0,
    addHandler = (addParam) => {},
    editHandler = () => {},
    deleteHandler = () => {},
}) => {
    const [d1exposeYN, setD1exposeYN] = useState(datas?.length >= 1 ? datas.at(0).exposeYn : false);
    const [d2exposeYN, setD2exposeYN] = useState(datas?.length >= 2 ? datas.at(1).exposeYn : false);
    const [d3exposeYN, setD3exposeYN] = useState(datas?.length >= 3 ? datas.at(2).exposeYn : false);

    const [title, setTitle] = useState(isNew && datas?.length > 0 ? "" : datas.at(-1)?.name);
    const [imageFile, setImageFile] = useState(null);
    const [imageSize, setImageSize] = useState([0, 0]);
    const [imageSrc, setImageSrc] = useState(null);
    const imageInfo = useMemo(() => {
        const image = new Image();
        if (imageFile) {
            const _URL = window.URL || window.webkitURL;
            image.src = _URL.createObjectURL(imageFile);
            image.onload = () => {
                console.log("Image Width:", image.width ?? 0);
                console.log("Image Height:", image.height ?? 0);
                console.log("Image src:::", image.src);
                setImageSize([image.width ?? 0, image.height ?? 0]);
            };
        }
        return image;
    }, [imageFile]);
    /* const isValid = (file) => {
        if(!!file && file instanceof File){
            const allowedExtensions = ["jpg", "jpeg", "png", "gif"]
            const fileExtension = file.name.split(".").at(-1).toLowerCase();
            if(allowedExtensions.includes(fileExtension)){
                return true;
            }
            alert('이미지만 첨부 가능합니다');
            return false;
        }
        return false
    } */
    const onFileInputChange = (file, dataUrl) => {
        setImageFile(file);
        setImageSrc(dataUrl);
        console.log({ file, dataUrl });
    };
    const onFileDelete = () => {
        setImageFile(null);
        setImageSrc(null);
        setImageSize([0, 0]);
    };
    useEffect(() => {
        setImageFile(null);
        setImageSize([0, 0]);
        setImageSrc(null);
        try {
            setTitle(isNew || depth === 0 ? "" : datas.at(-1)?.name);
        } catch (error) {
            setTitle("");
        }
    }, [datas, isNew, depth]);

    useEffect(() => {
        setD1exposeYN(datas.length >= 1 ? datas?.at(0)?.exposeYn : false);
        setD2exposeYN(datas.length >= 2 ? datas?.at(1)?.exposeYn : false);
        setD3exposeYN(datas.length >= 3 ? datas?.at(2)?.exposeYn : false);
    }, [datas?.at(-1)?.id]);
    // @params {at }
    const ImageViewer = ({ at = -1 }) =>
        _.isArray(datas) &&
        datas.length > 0 &&
        datas?.at(at)["attach_uri"] && (
            <>
                <img src={imageInfo.src} alt={imageInfo.alt} />
                {imageFile && (
                    <Button
                        className="border sm"
                        onClick={() => {
                            setImageFile(null);
                            setImageSize([0, 0]);
                            setImageSrc(null);
                        }}
                    >
                        파일삭제
                    </Button>
                )}
            </>
        );
    return (
        <>
            <h4>
                {categoryTitle} {isNew ? "추가" : "정보"}{" "}
            </h4>
            <div className={classes.admin_table_wrap}>
                <table>
                    <colgroup>
                        <col style={{ width: "159px" }} />
                        <col style={{ width: "459px" }} />
                        <col style={{ width: "159px" }} />
                        <col style={{ width: "459px" }} />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>등록일</th>
                            <td>{datas?.at(-1)?.regDate}</td>
                            <th>등록자</th>
                            <td>
                                {datas?.at(-1)?.regID} {/* {JSON.stringify(datas.at(-1))} */}
                            </td>
                        </tr>
                        <tr>
                            <th>사용여부</th>
                            <td colSpan={3}>
                                <div className={classes.radio_wrap}>
                                    <Radio
                                        id="rad_02_01"
                                        name="rad_02"
                                        value="사용"
                                        title="사용"
                                        checked={d1exposeYN}
                                        onChange={() => {
                                            setD1exposeYN(true);
                                        }}
                                    />
                                    <Radio
                                        id="rad_02_02"
                                        name="2"
                                        value="미사용"
                                        title="미사용"
                                        checked={!d1exposeYN}
                                        onChange={() => {
                                            setD1exposeYN(false);
                                        }}
                                    />
                                </div>
                            </td>
                        </tr>
                        {depth > 0 && (
                            <tr>
                                <th>대 카테고리코드</th>
                                <td colSpan={3}>{datas?.at(0)?.id}</td>
                            </tr>
                        )}
                        <tr>
                            <th>대 카테고리명</th>
                            <td colSpan={3}>
                                {depth === 1 ? (
                                    <Input className="admin_st" value={title} onChange={setTitle} />
                                ) : (
                                    datas?.at(0)?.name
                                )}
                            </td>
                        </tr>
                        {depth > 1 && (
                            <tr>
                                <th>중 카테고리코드</th>
                                <td colSpan={3}>{datas?.at(1)?.id}</td>
                            </tr>
                        )}
                        {depth >= 2 && (
                            <>
                                <tr>
                                    <th>중 카테고리명</th>
                                    <td colSpan={3}>
                                        {depth === 2 ? (
                                            <Input className="admin_st" value={title} onChange={setTitle} />
                                        ) : (
                                            datas?.at(1)?.name
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <th rowSpan={2}>중 카테고리 이미지</th>
                                    <td colSpan={3}>
                                        <div className={classes.radio_wrap}>
                                            <Radio
                                                id="rad_03_01"
                                                name="rad_03"
                                                value="사용"
                                                title="사용"
                                                checked={d2exposeYN}
                                                onChange={() => {
                                                    setD2exposeYN(true);
                                                }}
                                            />
                                            <Radio
                                                id="rad_03_02"
                                                name="rad_03"
                                                value="미사용"
                                                title="미사용"
                                                checked={!d2exposeYN}
                                                onChange={() => {
                                                    setD2exposeYN(false);
                                                }}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                {depth === 2 ? (
                                    <tr>
                                        <td colSpan={3}>
                                            <div className={classes.flex_row}>
                                                {FileBoxForImage(
                                                    imageSrc,
                                                    onFileInputChange,
                                                    onFileDelete,
                                                    <p className={classes.desc}>
                                                        * 이미지 사이즈 &nbsp;
                                                        {imageSize[0].toString().padStart(3, "0")}*
                                                        {imageSize[1].toString().padStart(3, "0")}px
                                                    </p>
                                                )}
                                            </div>
                                        </td>
                                    </tr>
                                ) : (
                                    <tr>
                                        <td colSpan={3}>
                                            <div className={classes.radio_wrap}>
                                                <tr>
                                                    <td colSpan={3}>
                                                        <div className={classes.thumbnail_box}>
                                                            <ImageViewer at={1} />
                                                        </div>
                                                    </td>
                                                </tr>
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </>
                        )}
                        {!isNew && depth > 2 && (
                            <tr>
                                <th>소 카테고리코드</th>
                                <td colSpan={3}>{datas?.at(2)?.id}</td>
                            </tr>
                        )}
                        {depth >= 3 && (
                            <>
                                <tr>
                                    <th>소 카테고리명</th>
                                    <td colSpan={3}>
                                        <Input className="admin_st" value={title} onChange={setTitle} />
                                    </td>
                                </tr>
                                <tr>
                                    <th rowSpan={2}>소 카테고리 이미지</th>
                                    <td colSpan={3}>
                                        <div className={classes.radio_wrap}>
                                            <Radio
                                                id="rad_04_01"
                                                name="rad_04"
                                                value="사용"
                                                title="사용"
                                                checked={d3exposeYN}
                                                onChange={() => {
                                                    setD3exposeYN(true);
                                                }}
                                            />
                                            <Radio
                                                id="rad_04_02"
                                                name="rad_04"
                                                value="미사용"
                                                title="미사용"
                                                checked={!d3exposeYN}
                                                onChange={() => {
                                                    setD3exposeYN(false);
                                                }}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                {depth >= 3 && (
                                    <tr>
                                        <td colSpan={3}>
                                            <div className={classes.flex_row}>
                                                {FileBoxForImage(
                                                    imageSrc,
                                                    onFileInputChange,
                                                    onFileDelete,
                                                    <p className={classes.desc}>
                                                        * 이미지 사이즈 &nbsp;
                                                        {imageSize[0].toString().padStart(3, "0")}*
                                                        {imageSize[1].toString().padStart(3, "0")}px
                                                    </p>
                                                )}
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </>
                        )}
                    </tbody>
                </table>

                <div className={classes.bottom_btn_wrap}>
                    {!isNew ? (
                        <>
                            <Button
                                className="border sm"
                                onClick={() => {
                                    if (depth > 3) return;
                                    const body = {
                                        type: "PRODUCT",
                                        title,
                                        depth,
                                        firstCategory: depth > 1 ? datas[0].id : null,
                                        secondCategory: depth > 2 ? datas[1].id : null,
                                        seq:
                                            !_.isArray(datas) || datas.length === 0
                                                ? nextSeq
                                                : datas.at(-1).children.length + 1,
                                        exposeYn: [d1exposeYN, d2exposeYN, d3exposeYN][depth - 1] ? "Y" : "N",
                                    };
                                    const reqParams = {
                                        id: !_.isArray(datas) || datas.length === 0 ? 0 : datas.at(-1).id,
                                        body,
                                        image: imageFile,
                                    };
                                    console.log({ reqParams, editHandelr: editHandler });
                                    editHandler(reqParams);
                                }}
                            >
                                수정
                            </Button>
                            <Button className="secondary sm" onClick={deleteHandler}>
                                삭제
                            </Button>
                        </>
                    ) : (
                        <Button
                            className="border sm"
                            onClick={() => {
                                if (depth > 3) return;
                                const body = {
                                    type: "PRODUCT",
                                    title,
                                    depth,
                                    firstCategory: depth > 1 ? datas[0].id : null,
                                    secondCategory: depth > 2 ? datas[1].id : null,
                                    seq:
                                        !_.isArray(datas) || datas.length === 0
                                            ? nextSeq
                                            : datas.at(-1).children.length + 1,
                                    exposeYn: [d1exposeYN, d2exposeYN, d3exposeYN][depth - 1] ? "Y" : "N",
                                };
                                const reqParams = { body, image: imageFile };
                                console.log({ reqParams });
                                addHandler(reqParams);
                            }}
                        >
                            등록
                        </Button>
                    )}
                </div>
            </div>
        </>
    );
};

export default ProductCategoryInfo;
