import { useState } from "react";
import Button from "../../../components/UI/Button/Button";
import FileBox from "../../../components/UI/Input/FileBox";
import Input from "../../../components/UI/Input/Input";
import Radio from "../../../components/UI/Input/Radio";
import SelectBox from "../../../components/UI/SelectBox/SelectBox";
import Pagination from "../../../components/UI/Table/Pagination";
import classes from "../MngCommon.module.css";

import sample from "../../../assets/images/sample_img.jpg";
import instance from "../../../store/AxiosInterceptor";
import { useLoaderData, useLocation, useNavigate } from "react-router-dom";
import { ReqDeleteKegelVideo } from "../../../api/kegel/KegelAPi";

function MngKegelVideoDetail() {
    const location = useLocation();
    const navigate = useNavigate();
    const kegelData = useLoaderData();

    const toList = () => {
        navigate("/admin/kegel/video", { state: location.state });
    };

    const updateHandler = () => {
        navigate(`/admin/kegel/video/update/${kegelData.id}`, { state: location.state });
    };

    const deleteKegel = async () => {
        const response = await ReqDeleteKegelVideo(kegelData.id);
        if (response.status === 200) {
            navigate("/admin/kegel/video", { state: location.state });
        } else {
            alert(
                `[${response.status}] ${
                    response.data ? response.data.error : "삭제 실패"
                } `
            );
        }
    };

    // 매핑시켜주기
    const kegelTypeMapping = {
        USEFUL_INFO: "유용한 정보",
        HELP: "문제해결 도우미",
    };

    const bodyData = (
        <>
            <tr>
                <th>등록일</th>
                <td>{kegelData.regDate}</td>
                <th>등록자</th>
                <td>{kegelData.regID}</td>
            </tr>
            <tr>
                <th>노출여부</th>
                <td colSpan={3}>
                    {kegelData.exposeYn === "Y" ? "노출" : "미노출"}
                </td>
            </tr>
            <tr>
                <th>영상 제목</th>
                <td colSpan={3}>{kegelData.title}</td>
            </tr>
            <tr>
                <th>썸네일 이미지</th>
                <td colSpan={3}>
                    {kegelData.attach_uri && (
                        <div className={classes.thumbnail_box}>
                            <img
                                src={kegelData.attach_uri}
                                alt="썸네일이미지"
                            />
                        </div>
                    )}
                </td>
            </tr>
            <tr>
                <th>영상</th>
                <td colSpan={3}>
                    {/* <iframe
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/lKgvmZ634Qg"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen
                    ></iframe> */}
                    <div>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: kegelData.content,
                            }}
                        />
                    </div>
                </td>
            </tr>
        </>
    );
    return (
        <section>
            <div className={classes.admin_contents}>
                <h3>유용한 동영상 - 상세</h3>
                <div
                    className={`${classes.contents_wrap} ${classes.search_wrap} `}
                >
                    <div className={classes.admin_table_wrap}>
                        <table>
                            <colgroup>
                                <col style={{ width: "159px" }} />
                                <col style={{ width: "620px" }} />
                                <col style={{ width: "159px" }} />
                                <col style={{ width: "620px" }} />
                            </colgroup>
                            <tbody>{bodyData}</tbody>
                        </table>
                        <div className={classes.bottom_btn_wrap}>
                            <Button
                                className="border sm"
                                onClick={updateHandler}
                            >
                                수정
                            </Button>
                            <Button className="sm" onClick={deleteKegel}>
                                삭제
                            </Button>
                            <Button className="secondary sm" onClick={toList}>
                                목록
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default MngKegelVideoDetail;
export const loader = async ({ params }) => {
    try {
        const response = await instance.get(
            `/api/v1/admin/kegel/videoGuide/${params.kegelId}`,
            {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            }
        );

        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error("Failed to fetch data");
        }
    } catch (error) {
        console.error("Error fetching data:", error);
        return null;
    }
};
