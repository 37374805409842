import { useEffect, useState } from "react";
import {
    ReqFindGroupNameWithOutTicket,
    ReqReceptionStatus,
    ReqReceptionStatusWithSearch,
} from "../../../api/home/TournamentHomeApplyApi";
import SearchBox from "../../../components/UI/Input/SearchBox";
import Alert from "../../../components/UI/Modal/Alert";
import Modal from "../../../components/UI/Modal/Modal";
import TabMenu from "../../../components/UI/Tab/TabMenu";
import { DefaultGroupNameSelectBox } from "../../../components/definedefault/DefSelectBoxValues";
import classes from "./../TournamentContainerApp.module.css";
import ApplyTournamentReceptionStatus from "./components/ApplyTournamentReceptionStatus";
import ApplyTournamentReceptionStatusSearchApp from "./components/ApplyTournamentReceptionStatusSearchApp";
import LaneSearchResult from "./components/LaneSearchResult";
import PlacementResultsApp from "./PlacementResultsApp";
import LaneSearchResultApp from "./components/LaneSearchResultApp";

/**
 * 대회 > 대회접수 > 접수결과 및 레인 : 모달창
 * @param } props
 * @returns
 */

function ReceptionStatusApp(props) {
    const { contestId, placeId } = props;
    const [searchValue, setSearchValue] = useState("");
    const [isValidText, setIsValidText] = useState(true);
    const [receptionResultList, setReceptionResultList] = useState([]); //선정인 사람들의 리스트
    const [waitingList, setWaitingList] = useState([]);  //대기인 사람들의 리스트
    const [selectedGroupID, setSelectedGroupID] = useState("");
    const [groupOptionDatas, setGroupOptionDatas] = useState([DefaultGroupNameSelectBox]);
    const [receptionSearchResult, setReceptionSearchResult] = useState([]);
    const [searchResultLength, setSearchResultLength] = useState();
    const [isSearch, setIsSearch] = useState(false);
    const navArr = [
        { id: 0, title: "접수결과" },
        { id: 1, title: "레인" },
    ];

    const [showCompletePosition, setShowCompletePosition] = useState(false);
    const onShowCompletePositionHandler = (state) => {
        setShowCompletePosition(state);
    };

    const onSearchValueHandler = (value) => {
        setSearchValue(value);
    };

    const onChangeGroupSeqHandler = (groupID) => {
        setSelectedGroupID(groupID);
    };

    // 접수 결과 모달 시 그룹 정보 가져오기
    const findGroupNameHandler = (contestId, placeId) => {
        ReqFindGroupNameWithOutTicket(
            contestId,
            placeId,
            (result) => {
                const curData = result.map((data) => {
                    return {
                        id: String(data.id),
                        title: data.name ? data.name + " 조" : data.seq + " 조",
                        disabled: false,
                    };
                });
                setGroupOptionDatas((prev) => curData);
                result.sort(function (a, b) {
                    return a.seq - b.seq;
                });
                setSelectedGroupID(String(result[0].id));
            },
            (error_result) => {
                setGroupOptionDatas((prev) => prev);
            }
        );
    };

    //전체 > 조별 신청결과, 검색 X
    const findReceptionStatusHandler = (contestId, placeId, selectedGroupID) => {
        if (!selectedGroupID) return;
        ReqReceptionStatus(
            contestId,
            placeId,
            selectedGroupID,
            (result) => {
                const curData = result.map((data) => {
                    return {
                        memberName: data.member_name,
                        memberId: data.member_id,
                        gender: data.gender,
                        handicap: data.handicap,
                        groupSeq: data.group_seq,
                        applyStatus: data.apply_status,
                        lane: data.lane,
                        laneSeq: data.lane_seq,
                        competitorSeq: data.competitor_seq,
                    };
                });

                const chosenList = curData.filter((data) => {
                    return (
                        data.applyStatus === "chosen" ||
                        data.applyStatus === "chosen_1" ||
                        data.applyStatus === "chosen_9" ||
                        data.applyStatus === "complete"
                    );
                });

                const waiting = curData.filter((data) => {
                    return data.applyStatus === "waiting";
                });

                waiting.sort(function (a, b) {
                    return a.competitorSeq - b.competitorSeq;
                });

                setReceptionResultList(chosenList);
                setWaitingList(waiting);
                setIsSearch(false);
                setSelectedGroupID((prev) => selectedGroupID);
            },
            (error_result) => {
                setReceptionResultList((prev) => prev);
            }
        );
    };

    //신청 결과 검색
    const findReceptionStatusSearchHandler = () => {
        if (searchValue.trim() === "") {
            setIsValidText(false);
            return;
        } else {
            setIsValidText(true);
        }
        ReqReceptionStatusWithSearch(
            contestId,
            placeId,
            searchValue,
            (result) => {
                const curData = result.map((data) => {
                    return {
                        memberName: data.member_name,
                        memberId: data.member_id,
                        gender: data.gender,
                        handicap: data.handicap,
                        groupSeq: data.group_seq,
                        applyStatus: data.apply_status,
                        lane: data.lane,
                        laneSeq: data.lane_seq,
                        competitorSeq: data.competitor_seq,
                    };
                });
                setReceptionSearchResult(curData);
                setSearchResultLength(curData.length);
                setIsSearch(true);
            },
            (error_result) => {
                setReceptionSearchResult((prev) => prev);
            }
        );
    };

    useEffect(() => {
        findGroupNameHandler(contestId, placeId);
    }, [contestId, placeId]);

    useEffect(() => {
        if (selectedGroupID) {
            findReceptionStatusHandler(contestId, placeId, selectedGroupID);
        }
    }, [contestId, placeId, selectedGroupID]);

    //접수결과, 레인 네비게이션
    const [navIndex, setNavIndex] = useState(0);
    const navToggle = (index) => {
        if (props.completePosition === "N" || props.exposePosition === "N") {
            onShowCompletePositionHandler(true);
            return;
        }
        setSelectedGroupID(String(groupOptionDatas[0].id));
        setIsSearch(false);
        setSearchResultLength(1);
        setNavIndex(index);
    };

    return (
        <main className={classes.reception_status_wrap}>
            <div className={classes.mobile_modal_table}>
                <table>
                    <colgroup>
                        <col style={{ width: "160px" }} />
                        <col style={{ width: "460px" }} />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>대회</th>
                            <td>{props.ctTitle}</td>
                        </tr>
                        <tr>
                            <th>경기장</th>
                            <td>
                                {props.region} ({props.centerName})
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <ul className={classes.nav_tab}>
                {navArr.map((item) => (
                    <li key={item.id} className={navIndex === item.id && classes.on} onClick={() => navToggle(item.id)}>
                        {item.title}
                    </li>
                ))}
            </ul>
            <div className={classes.search_box}>
                <SearchBox
                    searchHandler={findReceptionStatusSearchHandler}
                    isValid={isValidText}
                    placeholder="아이디 또는 이름 입력"
                    onChange={onSearchValueHandler}
                />
            </div>
            <TabMenu
                tabMenuArr={groupOptionDatas}
                onChange={onChangeGroupSeqHandler}
                showDefaultKey={selectedGroupID}
            />
            {/* 조별 검색 결과 테이블*/}
            {isSearch &&
                navIndex === 0 &&
                searchResultLength !== 0 &&
                receptionSearchResult.map((data, index) => (
                    <ApplyTournamentReceptionStatusSearchApp
                        key={index}
                        index={index}
                        memberName={data.memberName}
                        memberId={data.memberId}
                        gender={data.gender}
                        handicap={data.handicap}
                        applyStatus={data.applyStatus}
                        groupSeq={data.groupSeq}
                        competitorSeq={data.competitorSeq}
                    />
                ))}
            {isSearch && searchResultLength === 0 && (
                <div className="no_search_result">
                    <p>일치하는 결과가 없습니다.</p>
                </div>
            )}
            {/* 레인 별 검색 결과 테이블*/}
            {isSearch &&
                navIndex === 1 &&
                searchResultLength !== 0 &&
                receptionSearchResult.map((data, index) => (
                    <LaneSearchResultApp
                        key={index}
                        index={index}
                        memberName={data.memberName}
                        memberId={data.memberId}
                        gender={data.gender}
                        handicap={data.handicap}
                        applyStatus={data.applyStatus}
                        groupSeq={data.groupSeq}
                        lane={data.lane}
                        laneSeq={data.laneSeq}
                        competitorSeq={data.competitorSeq}
                    />
                ))}
            {navIndex === 0 && !isSearch && (
                // 모바일에서는 전체 스크롤
                <div className={classes.mobile_table_2}>
                    <div className="table_wrap ">
                        <table className="align_center">
                            <colgroup>
                                <col style={{ width: "15%" }} />
                                <col style={{ width: "40%" }} />
                                <col style={{ width: "15%" }} />
                                <col style={{ width: "15%" }} />
                                <col style={{ width: "15%" }} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>번호</th>
                                    <th>이름(아이디)</th>
                                    <th>성별</th>
                                    <th>핸디</th>
                                    <th>상태</th>
                                </tr>
                            </thead>
                            <tbody>
                                {receptionResultList.map((data, index) => (
                                    <ApplyTournamentReceptionStatus
                                        key={index}
                                        index={index}
                                        memberName={data.memberName}
                                        memberId={data.memberId}
                                        gender={data.gender}
                                        handicap={data.handicap}
                                        applyStatus={data.applyStatus}
                                        competitorSeq={data.competitorSeq}
                                    />
                                ))}
                                {waitingList.map((data, index) => (
                                    <ApplyTournamentReceptionStatus
                                        key={index}
                                        index={index}
                                        memberName={data.memberName}
                                        memberId={data.memberId}
                                        gender={data.gender}
                                        handicap={data.handicap}
                                        applyStatus={data.applyStatus}
                                        competitorSeq={data.competitorSeq}
                                    />
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            )}
            {/**레인 테이블 */}
            {false && (
                <Modal close={() => onShowCompletePositionHandler(false)} header="배치결과">
                    <LaneSearchResult close={() => onShowCompletePositionHandler(false)} />
                </Modal>
            )}
            {navIndex === 1 && !isSearch && searchResultLength !== 0 && (
                <div>
                    <PlacementResultsApp datas={receptionResultList} />
                </div>
            )}
            {showCompletePosition && (
                <Alert type="alert" confirm="확인" close={() => onShowCompletePositionHandler(false)}>
                    {"레인배치 전 또는 게재하기전입니다."}
                </Alert>
            )}
        </main>
    );
}
export default ReceptionStatusApp;
