import React from "react";
import classes from "../../TournamentContainerApp.module.css";

const ApplyTournamentReceptionStatusSearchApp = (props) => {
    let newStatus = "";

    switch (props.applyStatus) {
        case "applied":
            newStatus = "접수완료";
            break;
        case "waiting":
            newStatus = "대기";
            break;
        case "chosen":
        case "chosen_1":
        case "chosen_9":
        case "complete":
            newStatus = "선정";
            break;
    }

    return (
        <div className={classes.mobile_table_2}>
            <h4>{props.groupSeq}조</h4>
            <table className="align_center">
                <colgroup>
                    <col style={{ width: "15%" }} />
                    <col style={{ width: "40%" }} />
                    <col style={{ width: "15%" }} />
                    <col style={{ width: "15%" }} />
                    <col style={{ width: "15%" }} />
                </colgroup>
                <thead>
                    <tr>
                        <th>번호</th>
                        <th>이름(아이디)</th>
                        <th>성별</th>
                        <th>핸디</th>
                        <th>상태</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{props.index + 1}</td>
                        <td>
                            {props.memberName}({props.memberId})
                        </td>
                        <td>{props.gender === "M" ? "남" : "여"}</td>
                        <td>{props.handicap}</td>
                        <td>{newStatus}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default ApplyTournamentReceptionStatusSearchApp;
