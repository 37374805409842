/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import classes from "./../Products.module.css";
import htmlReactParser from "html-react-parser";
import Button from "../../../components/UI/Button/Button";
import _ from "lodash";
import catalogue from "../../../assets/images/product_icon_catalogue.png";
import { FacebookShareButton } from "react-share";
import CopyToClipboard from "react-copy-to-clipboard";

export const ProductItemDetail = ({
    categoryId = 0,
    id = 0,
    name,
    subName,
    seq,
    exposeYn,
    releaseDate,
    purchaseUrl,
    pageUri,
    description,
    readCnt,
    regID,
    regDate,
    productDetailImagesSelecteds = [],
    smartFilterMenuSelecteds = [],
    extInformationMenuSelecteds = [],
    sharePopHandler,
    sharePopToggle,
    catalogue_ball: catalogueBall = false,
    category_img_uris: categoryImgUris = [],
    hierarchyCategoryStr = "",
    ...props
}) => {
    const [mainImg, setMainImg] = useState({});
    const [copyed, setCopyed] = useState(false);
    useEffect(() => {
        const timer = setTimeout(() => {
            setCopyed(false);
        }, 2500);
        return () => {
            clearTimeout(timer);
        };
    }, [copyed]);
    useEffect(() => {
        if (productDetailImagesSelecteds.length > 0) {
            setMainImg(productDetailImagesSelecteds[0] ?? {});
        }
    }, [productDetailImagesSelecteds]);

    const kakaoShare = () => {
        const pathUri = pageUri;
        let path = '';
        if(pathUri.indexOf("https://www.bowlingkorea.com/") !== -1){
            path = pathUri.replace("https://www.bowlingkorea.com/","");
        } else {
            path = pathUri.replace("https://bowlingkorea.com/","");
        }
        if (!Kakao.isInitialized()) Kakao.init("676b3a47cf0a23d76e9e9af1f250d0c7");
        Kakao.Share.sendCustom({
            templateId: 98020,
            templateArgs: {
                title: "진승의 상품을 공유합니다.",
                desc: `${name} ${subName ? `(${subName})` : ""}`,
                url: pageUri,
                path : path,
                THU: `https://bowlingkorea.com${mainImg?.uri}`,
            },
            installTalk: true,
        });
    };
    return (
        <div className={classes.contents_detail}>
            <div className={classes.contents_title}>
                <div className={classes.img_box}>
                    <div className={classes.main_img}>
                        <img src={mainImg?.uri ?? ""} alt={mainImg["origin_name"]} />
                    </div>
                    <div className={classes.thumbnail_box}>
                        {productDetailImagesSelecteds.map((item) => (
                            <div key={item?.id} className={classes.thumb_img}>
                                <img
                                    src={item?.uri}
                                    alt={item["origin_name"]}
                                    onClick={() => {
                                        setMainImg(item);
                                    }}
                                />
                            </div>
                        ))}
                    </div>
                </div>
                <div className={classes.contents_info}>
                    <div className={classes.logo_wrap}>
                        <ul>
                            {categoryImgUris.map(
                                (src, idx) =>
                                    _.isString(src) &&
                                    src.length > 0 && <img style={{ width: "125px" }} key={`${src}_${idx}`} src={src} />
                            )}
                            <li>{catalogueBall && <img src={catalogue} alt="catalogue" />}</li>
                        </ul>
                    </div>
                    <div className={classes.title}>
                        <span className={classes.en}>{subName}</span>
                        <h3>{name}</h3>
                        <div className={classes.share_wrap}>
                            <Button className="share" onClick={sharePopHandler} />
                            <div className={`${classes.share_pop} ${sharePopToggle ? classes.on : null}`}>
                                <div className={classes.header}>
                                    <h5>공유</h5>
                                    <Button className="btn_x" onClick={sharePopHandler} />
                                </div>
                                <div className={classes.list_wrap}>
                                    <ul>
                                        <li
                                            className={classes.kakao}
                                            onClick={() => {
                                                try {
                                                    kakaoShare();
                                                } catch (error) {
                                                    console.log({ error });
                                                    Kakao.cleanup();
                                                    kakaoShare();
                                                }
                                            }}
                                        >
                                            카카오톡
                                        </li>
                                        <FacebookShareButton url={pageUri}>
                                            <li className={classes.facebook}>페이스북</li>
                                        </FacebookShareButton>

                                        <CopyToClipboard
                                            text={pageUri}
                                            onCopy={() => {
                                                setCopyed(true);
                                            }}
                                        >
                                            <li className={classes.link}>URL 복사</li>
                                        </CopyToClipboard>
                                    </ul>
                                </div>
                                {copyed && <div className={classes.toast}>URL이 복사되었습니다.</div>}
                            </div>
                        </div>
                    </div>
                    <div className={classes.contents}>
                        <ul>
                            {(smartFilterMenuSelecteds ?? []).map((item) => (
                                <li key={`smart-filter-${item?.id}`}>
                                    {item?.title} : &nbsp;
                                    {item?.smartFilterItemSelecteds?.map(({ name }) => name)?.join(" ")}
                                </li>
                            ))}
                            {(extInformationMenuSelecteds ?? []).map((item) => (
                                <li key={`ext-info-${item?.id}`}>
                                    {item?.title} :{item?.description}
                                </li>
                            ))}
                            {pageUri?.split("/")?.at(-2) === "balls" && (
                                <li key="release-date">출시일 : {releaseDate}</li>
                            )}
                        </ul>
                        {(purchaseUrl !== "" && purchaseUrl != null)&& (
                            <Button
                                className="official xl"
                                onClick={() => {
                                    const openShopping = window.open(purchaseUrl, "_blank");
                                }}
                            >
                                진승몰에서 구매하기
                            </Button>
                        )}
                        {(purchaseUrl === "" || purchaseUrl == null) && (
                            <Button
                                className="official xl"
                                disabled={true}
                            >
                                출시 예정
                            </Button>
                        )}
                    </div>
                </div>
            </div>
            <div className={classes.detail_box}>{htmlReactParser(description ?? "")}</div>
        </div>
    );
};
