import { useState } from "react";
import Button from "../../components/UI/Button/Button";
import classes from "./EventApp.module.css";
import Input from "../../components/UI/Input/Input";
import instance from "../../store/AxiosInterceptor";
import { useLoaderData } from "react-router-dom";
import EventWinnerListComponentApp from "./components/EventWinnerListComponentApp";
import { useEffect } from "react";
import { ReqPageingEventChosenList, ReqSearchEventChosenList } from "../../api/event/EventHomeApi";

function WinnersListApp() {
    const resData = useLoaderData();
    
    //  breadCrumb sample data
    const [queryText, setQueryText] = useState(null);
    const changeQueryTextHandler = (key) => {
        setQueryText(key);
    };
    const [eventList, setEventList] = useState([]);

    const [totalPages, setTotalPages] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [pageNumber, setPageNumber] = useState(0);

    const navigateHandler = (key) => {
        navigate(key);
    };

    const pagingHandler = () => {
        setPageNumber((prev) => prev + 1);
        ReqPageingEventChosenList(
            queryText,
            pageNumber + 1,
            (result) => {
                if (result.content) {
                    const curData = result.content.map((data) => {
                        return {
                            id: data.event_id,
                            title: data.event_title,
                            regDate: data.event_reg_date,
                            startDate: data.event_start_date,
                            endDate: data.event_end_date,
                            exposeChosenYn: data.expose_chosen_yn,
                            description: data.chosen_description,
                            seq: data.seq,
                        };
                    });

                    setEventList((prev) => [...prev, ...curData]);
                    setTotalCount(result.totalElements);
                    setPageNumber(result.pageable.pageNumber);
                    setTotalPages(result.totalPages);
                }
            },
            (error_result) => {
                setEventList((prev) => prev);
                setTotalCount((prev) => prev);
                setPageNumber((prev) => prev);
                setTotalPages((prev) => prev);
            }
        );
    };

    const searchHandler = () => {
        setPageNumber(0);
        ReqSearchEventChosenList(
            queryText,
            (result) => {
                if (result.content) {
                    const curData = result.content.map((data) => {
                        return {
                            id: data.event_id,
                            title: data.event_title,
                            regDate: data.event_reg_date,
                            startDate: data.event_start_date,
                            endDate: data.event_end_date,
                            exposeChosenYn: data.expose_chosen_yn,
                            description: data.chosen_description,
                            seq: data.seq,
                        };
                    });

                    setEventList(curData);
                    setTotalCount(result.totalElements);
                    setPageNumber(result.pageable.pageNumber);
                    setTotalPages(result.totalPages);
                }
            },
            (error_result) => {
                setEventList((prev) => prev);
                setTotalCount((prev) => prev);
                setPageNumber((prev) => prev);
                setTotalPages((prev) => prev);
            }
        );
    };

    useEffect(() => {
        if (resData.content) {
            const curData = resData.content.map((data) => {
                return {
                    id: data.event_id,
                    title: data.event_title,
                    regDate: data.event_reg_date,
                    startDate: data.event_start_date,
                    endDate: data.event_end_date,
                    exposeChosenYn: data.expose_chosen_yn,
                    description: data.chosen_description,
                    seq: data.seq,
                };
            });

            setEventList(curData);
            setTotalCount(resData.totalElements);
            setPageNumber(resData.pageable.pageNumber);
            setTotalPages(resData.totalPages);
        }
    }, [resData]);

    return (
        <>
            <div className={classes.contents_wrap}>
                {eventList.length === 0 && (
                    <div className={classes.empty_data}>
                        <p>게시글이 없습니다.</p>
                    </div>
                )}
                <div className={classes.contents_title}>
                    <div className={classes.search_wrap}>
                        <Input onChange={changeQueryTextHandler} value={queryText} />
                        <Button className="search_sm" onClick={searchHandler} />
                    </div>
                </div>
                <div className={classes.mobile_table_wrap}>
                    <table>
                        <colgroup>
                            <col style={{ width: "10%" }} />
                            <col style={{ width: "51%" }} />
                            <col style={{ width: "22%" }} />
                            <col style={{ width: "17%" }} />
                        </colgroup>
                        <thead>
                            <tr>
                                <th>번호</th>
                                <th>이벤트 제목</th>
                                <th>이벤트 기간</th>
                                <th>당첨자 발표</th>
                            </tr>
                        </thead>
                        <tbody>
                            {eventList.length > 0 &&
                                eventList.map((data, index) => (
                                    <EventWinnerListComponentApp
                                        index={index}
                                        id={data.id}
                                        title={data.title}
                                        seq={data.seq}
                                        startDate={data.startDate}
                                        endDate={data.endDate}
                                        exposeChosenYn={data.exposeChosenYn}
                                        description={data.description}
                                    />
                                ))}
                        </tbody>
                    </table>
                    <div className={classes.bottom_btn_wrap}>
                        {totalPages > pageNumber + 1 && (
                            <Button className="text_icon" onClick={pagingHandler}>
                                더보기
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default WinnersListApp;

//이벤트 목록 리스트 가져오기
export const loader = async () => {
    const response = await instance({
        method: "GET",
        url: `/api/v1/home/event/chosen?page=0&size=10`,
    }).catch(function (error) {
        if (error.response) {
            return error.response;
        } else if (error.request) {
            return null;
        } else {
            return null;
        }
    });
    if (response == null || response.status !== 200) {
        return [];
    }
    if (response.data.status === 200) {
        return response.data.datas;
    } else {
        return [];
    }
};
//이벤트 목록 리스트 가져오기
