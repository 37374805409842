import React from "react";
import "./App.css";
import { isMobile } from "react-device-detect";
import { CloseInicis } from "./components/inicis";
import { Navigate, RouterProvider, createBrowserRouter } from "react-router-dom";
import JwtTokenProvider from "./store/JwtTokenProvider";
import HomePage from "./pages/Home";
import RootLayout from "./pages/Root";
import { lazy, Suspense } from "react";
import IntroducePage from "./pages/introduce/Introduce";
import FaqPage from "./pages/media/Faq";
import SignInPage from "./pages/auth/SignIn";
import WarrantyServicePage from "./pages/afterservice/WarrantyService";
import PrivacyPolicyPage from "./pages/other/PrivacyPolicy";
import TermsOfUsePage from "./pages/other/TermsOfUse";
import TournamentNotice from "./pages/tournament/tournamentNotice/TournamentNoticeView";
import MngProductListPage from "./pages/manage/product/MngProductList";
import MngStaffPage from "./pages/manage/staff/MngStaff";
import MngUserListPage from "./pages/manage/user/MngUserList";
import MngSiteTopLine from "./pages/manage/site/MngSiteTopLine";
import MngSiteTopLineCreate from "./pages/manage/site/topLineComponents/MngSiteTopLineCreate";
import MngSiteTopLineDetail from "./pages/manage/site/topLineComponents/MngSiteTopLineDetail";
import MngSiteTopLineModify from "./pages/manage/site/topLineComponents/MngSiteTopLineModify";
import UIComponents from "./components/UI/UIComponent";
import AdminSignIn from "./pages/manage/adminAuth/AdminSignIn";
import MngTournamentRootLayout from "./pages/manage/tournament/MngTournamentRoot";
import MngTournamentNoticePage from "./pages/manage/tournament/contestNotice/MngTournamentNotice";
import MngTournamentDetailPage from "./pages/manage/tournament/MngTournamentDetail";
import { AxiosInterceptor } from "./store/AxiosInterceptor";
import MngTournamentBowlingAlley from "./pages/manage/tournament/BowlingAlley/MngTournamentBowlingAlley";
import MngTournamentDetailApply from "./pages/manage/tournament/MngTournamentDetailApply";
import MngTournamentDetailAutoSend from "./pages/manage/tournament/MngTournamentDetailAutoSend";
import MngTournamentDetailPrint from "./pages/manage/tournament/MngTournamentDetailPrint";
import MngTournamentResultPage from "./pages/manage/tournament/ContestResult/MngTournamentResult";
import MngTournamentBall from "./pages/manage/tournament/MngTournamentBall";
import MngTournamentNoticeUpload from "./pages/manage/tournament/contestNotice/MngTournamentNoticeUpload";
import MngTournamentNoticeDetail from "./pages/manage/tournament/contestNotice/MngTournamentNoticeDetail";
import MngTournamentNoticeUpdate from "./pages/manage/tournament/contestNotice/MngTournamentNoticeUpdate";
import TournamentNoticeDetail from "./pages/tournament/tournamentNotice/TournamentNoticeDetail";
import TournamentNoticeViewDetailApp from "./pages/tournament/tournamentNotice/TournamentNoticeViewDetailApp";
import MngProductRootLayout from "./pages/manage/product/MngProductRoot";
import MngProductCategory from "./pages/manage/product/MngProductCategory";
import MngProductDisplay from "./pages/manage/product/MngProductDisplay";
import MngKegelRootLayout from "./pages/manage/kegel/MngKegelRoot";
import MngKegel from "./pages/manage/kegel/MngKegel";
import MngKegelInfo from "./pages/manage/kegel/MngKegelInfo";
import MngKegelVideo from "./pages/manage/kegel/MngKegelVideo";
import MngCommunity from "./pages/manage/community/MngCommunity";
import MngCompany from "./pages/manage/company/MngCompany";
import MngUserRootLayout from "./pages/manage/user/MngUserRoot";
import MngUserInfoPage from "./pages/manage/user/MngUserInfo";
import MngUserTerms from "./pages/manage/user/MngUserTerms";
import MngUserTermsCreate from "./pages/manage/user/userTermsComponents/MngUserTermsCreate";
import MngUserTermsDetail from "./pages/manage/user/userTermsComponents/MngUserTermsDetail";
import MngUserTermsModify from "./pages/manage/user/userTermsComponents/MngUserTermsModify";
import MngUserWithTournamentPage from "./pages/manage/user/MngUserWithTournament";
import MyPageRootLayout from "./pages/mypage/MyPageRoot";
import MyEventHistory from "./pages/mypage/MyEventHistory";
import MyCSHistory from "./pages/mypage/MyCSHistory";
import MyInformationPage from "./pages/mypage/MyInformation";
import SingUpPage from "./pages/auth/SingUp";
import PageError from "./pages/other/PageError";
import KegelRootLayout from "./pages/kegel/KegelRoot";
import LaneMachinesPage from "./pages/kegel/LaneMachines";
import LaneUsefulVideo from "./pages/kegel/LaneUsefulVideo";
import EventPage from "./pages/event/Event";
import MediaRootLayout from "./pages/media/MediaRoot";
import MainNoticePage from "./pages/media/MainNotice";
import EventRootLayout from "./pages/event/EventRoot";
import WinnersList from "./pages/event/WinnersList";
import SingUpAppPage from "./pages/auth/SingUpApp";
import SignInAppPage from "./pages/auth/SignInApp";
import MyPageAppRootLayout from "./pages/mypage/MyPageAppRoot";
import MyTournamentHistoryApp from "./pages/mypage/MyTournamentHistoryApp";
import MyEventHistoryApp from "./pages/mypage/MyEventHistoryApp";
import MyCSHistoryApp from "./pages/mypage/MyCSHistoryApp";
import MyContactHelpApp from "./pages/mypage/MyContactHelpApp";
import MyInformationAppPage from "./pages/mypage/MyInformationApp";
import TournamentAppPage from "./pages/tournament/TournamentApp";
import TournamentNoticeViewApp from "./pages/tournament/tournamentNotice/TournamentNoticeViewApp";
import TournamentAppRootLayout from "./pages/tournament/TournamentAppRoot";
import ApplyTournamentAppPage from "./pages/tournament/applyTournament/ApplyTournamentApp";
import TournamentListApp from "./pages/tournament/tournamentList/TournamentListApp";
import TournamentResultApp from "./pages/tournament/tournamentresult/TournamentresultApp";
import TournamentResultDetailApp from "./pages/tournament/tournamentresult/ResultDetailApp";
import MngTournamentBowlingAlleyUpload from "./pages/manage/tournament/BowlingAlley/MngTournamentBowlingAlleyUpload";
import MngTournamentBowlingAlleyDetail from "./pages/manage/tournament/BowlingAlley/MngTournamentBowlingAlleyDetail";
import MngTournamentBowlingAlleyUpdate from "./pages/manage/tournament/BowlingAlley/MngTournamentBowlingAlleyUpdate";
import MngTournamentResultDetail from "./pages/manage/tournament/ContestResult/MngTournamentResultDetail";
import MngTournamentResultUpdate from "./pages/manage/tournament/ContestResult/MngTournamentResultUpdate";
import IntroduceRootLayout from "./pages/introduce/IntroduceRoot";
import IntroHistoryPage from "./pages/introduce/IntroHistory";
import IntroStaffsPage from "./pages/introduce/IntroStaffs";
import IntroCentersPage from "./pages/introduce/showCenters";
import IntroLocationPage from "./pages/introduce/IntroLocation";
import KegelAppRootLayout from "./pages/kegel/KegelAppRoot";
import LaneMachinesAppPage from "./pages/kegel/LaneMachinesApp";
import LaneUsefulVideoApp from "./pages/kegel/LaneUsefulVideoApp";
import IntroduceAppRootLayout from "./pages/introduce/IntroduceAppRoot";
import IntroduceAppPage from "./pages/introduce/IntroduceApp";
import IntroHistoryAppPage from "./pages/introduce/IntroHistoryApp";
import IntroStaffsAppPage from "./pages/introduce/IntroStaffsApp";
import IntroCentersAppPage from "./pages/introduce/showCentersApp";
import IntroLocationAppPage from "./pages/introduce/IntroLocationApp";
import MediaAppRootLayout from "./pages/media/MediaAppRoot";
import MainNoticeAppPage from "./pages/media/MainNoticeApp";
import FaqAppPage from "./pages/media/FaqApp";
import EventAppRootLayout from "./pages/event/EventAppRoot";
import EventAppPage from "./pages/event/EventApp";
import WinnersListApp from "./pages/event/WinnersListApp";
import MngSiteRootLayout from "./pages/manage/site/MngSiteRoot";
import MngBannerPage from "./pages/manage/site/MngBanner";
import MngKegelContents from "./pages/manage/site/MngKegelContents";
import MngKegelContentsCreate from "./pages/manage/site/kegelComponents/MngKegelContentsCreate";
import MngKegelContentsDetail from "./pages/manage/site/kegelComponents/MngKegelContentsDetail";
import MngKegelContentsModify from "./pages/manage/site/kegelComponents/MngKegelContentsModify";
import MngCatalogue from "./pages/manage/site/MngCatalogue";
import MngCatalogueCreate from "./pages/manage/site/catalogueComponents/MngCatalogueCreate";
import MngCatalogueDetail from "./pages/manage/site/catalogueComponents/MngCatalogueDetail";
import MngCatalogueModify from "./pages/manage/site/catalogueComponents/MngCatalogueModify";
import MngPopupPage from "./pages/manage/site/MngPopup";
import MngPopupCreate from "./pages/manage/site/popupComponents/MngPopupCreate";
import MngPopupDetail from "./pages/manage/site/popupComponents/MngPopupDetail";
import MngPopupModify from "./pages/manage/site/popupComponents/MngPopupModify";
import MngTermsPage from "./pages/manage/site/MngTerms";
import MngTermsCreate from "./pages/manage/site/termsComponents/MngTermsCreate";
import MngTermsDetail from "./pages/manage/site/termsComponents/MngTermsDetail";
import MngTermsModify from "./pages/manage/site/termsComponents/MngTermsModify";
import MngSystemPage from "./pages/manage/site/MngSystem";
import MngSystemCreate from "./pages/manage/site/systemComponents/MngSystemCreate";
import MngSystemDetail from "./pages/manage/site/systemComponents/MngSystemDetail";
import MngSystemModify from "./pages/manage/site/systemComponents/MngSystemModify";
import MngRecommendProduct from "./pages/manage/site/MngRecommendProduct";
import MngRecommendProductCreate from "./pages/manage/site/recommendProductComponents/MngRecommendProductCreate";
import MngRecommendProductDetail from "./pages/manage/site/recommendProductComponents/MngRecommendProductDetail";
import MngRecommendProductModify from "./pages/manage/site/recommendProductComponents/MngRecommendProductModify";
import MngCommunityRootLayout from "./pages/manage/community/MngCommunityRoot";
import MngFaq from "./pages/manage/community/MngFaq";
import MngFaqCreate from "./pages/manage/community/FaqComponets/MngFaqCreate";
import MngFaqDetail from "./pages/manage/community/FaqComponets/MngFaqDetail";
import MngFaqModify from "./pages/manage/community/FaqComponets/MngFaqModify";
import MngCs from "./pages/manage/community/MngCs";
import MngAs from "./pages/manage/community/MngAs";
import MngCompanyRootLayout from "./pages/manage/company/MngCompanyRoot";
import MngStaff from "./pages/manage/company/MngStaff";
import MngStaffCreate from "./pages/manage/company/staffComponents/MngStaffCreate";
import MngStaffDetail from "./pages/manage/company/staffComponents/MngStaffDetail";
import MngStaffModify from "./pages/manage/company/staffComponents/MngStaffModify";
import MngEvent from "./pages/manage/event/MngEvent";
import MngCenter from "./pages/manage/company/MngCenter";
import MngCenterCreate from "./pages/manage/company/centerComponents/MngCenterCreate";
import MngCenterDetail from "./pages/manage/company/centerComponents/MngCenterDetail";
import MngCenterModify from "./pages/manage/company/centerComponents/MngCenterModify";
import MngEventRootLayout from "./pages/manage/event/MngEventRoot";
import MngWinner from "./pages/manage/event/MngWinner";
import MngEventResult from "./pages/manage/event/MngEventResult";
import MngStatisticsRootLayout from "./pages/manage/statistics/MngStatisticsRoot";
import MngTournamentStatisticsPage from "./pages/manage/statistics/MngTournament";
import ErrorInicis from "./components/inicis/errorInicis";
import OkInicis from "./components/inicis/Oknicis";
import MngKegelUpload from "./pages/manage/kegel/MngKegelUpload";
import MngKegelDetail from "./pages/manage/kegel/MngKegelDetail";
import MngKegelUpdate from "./pages/manage/kegel/MngKegelUpdate";
import SignUpPaper from "./pages/auth/components/SignUpPaper";
import SignUpPhone from "./pages/auth/components/SignUpPhone";
import TournamentListDetailApp from "./pages/tournament/tournamentList/TournamentListDetailApp";
import ApplyTournamentDetailApp from "./pages/tournament/applyTournament/components/ApplyTournamentDetailApp";
import MngKegelInfoUpload from "./pages/manage/kegel/MngKegelInfoUpload";
import MngKegelInfoDetail from "./pages/manage/kegel/MngKegelInfoDetail";
import MngKegelInfoUpdate from "./pages/manage/kegel/MngKegelInfoUpdate";
import MngKegelVideoUpload from "./pages/manage/kegel/MngKegelVideoUpload";
import MngKegelVideoDetail from "./pages/manage/kegel/MngKegelVideoDetail";
import MngKegelVideoUpdate from "./pages/manage/kegel/MngKegelVideoUpdate";
import MyASHistory from "./pages/mypage/MyASHistory";
import MyASHistoryApp from "./pages/mypage/MyASHistoryApp";
import MyContactAsApp from "./pages/mypage/MyContactAsApp";
import MyASHistoryDetail from "./pages/mypage/components/MyASHistoryDetail";
import MyContactAs from "./pages/mypage/MyContactAs";
import MngAsDetail from "./pages/manage/community/components/MngAsDetail";
import MyContactHelp from "./pages/mypage/MyContactHelp";
import MngCsDetail from "./pages/manage/community/components/MngCsDetail";
import MngBannerDetail from "./pages/manage/site/bannerComponents/MngBannerDetail";
import MyCSHistoryDetail from "./pages/mypage/components/MyCSHistoryDetail";
import MngUserInfoDetail from "./pages/manage/user/components/MngUserInfoDetail";
import MngUserRegisterForm from "./pages/manage/user/components/MngUserRegisterForm";
import MngUserDetail from "./pages/manage/user/components/MngUserDetail";
import LaneMachinesDetail from "./pages/kegel/LaneMachinesDetail";
import LaneServiceHelp from "./pages/kegel/LaneServiceHelp";
import LaneServiceUsefulInfoDetail from "./pages/kegel/LaneServiceUsefulInfoDetail";
import LaneServiceUsefulInfo from "./pages/kegel/LaneServiceUsefulInfo";
import LaneServiceHelpDetail from "./pages/kegel/LaneServiceHelpDetail";
import MngCommunityDetail from "./pages/manage/community/MngCommunityDetail";
import MngCommunityUpdate from "./pages/manage/community/MngCommunityUpdate";
import MngCommunityUpload from "./pages/manage/community/MngCommunityUpload";
import MainNoticeDetail from "./pages/media/MainNoticeDetail";
import MainNoticeDetailApp from "./pages/media/MainNoticeDetailApp";
import ProductPage from "./pages/products/components/ProductPage";
import NiceError from "./components/nice/NiceError";
import IdentifyFunc from "./pages/auth/components/IdentifySuccess";
import LaneMachinesAppDetail from "./pages/kegel/LaneMachinesAppDetail";
import LaneServiceUsefulInfoApp from "./pages/kegel/LaneServiceUsefulInfoApp";
import LaneServiceHelpAppPage from "./pages/kegel/LaneServiceHelpApp";
import LaneServiceUsefulInfoAppDetail from "./pages/kegel/LaneServiceUsefulInfoAppDetail";
import LaneServiceHelpAppDetail from "./pages/kegel/LaneServiceHelpAppDetail";
import NiceDuplicate from "./components/nice/NiceDuplicate";
import { SystemModalPortal } from "./SystemModalPortal";
import { SystemPopupModal } from "./SystemPopupModal";
import NiceNoExist from "./components/nice/NiceNoExist";
import FindIdAndPwd from "./pages/auth/FindIdAndPwd";
import MyCSHistoryDetailApp from "./pages/mypage/components/MyCSHistoryDetailApp";
import FindIdAndPwdApp from "./pages/auth/FindIdAndPwdApp";
import SignUpPhoneApp from "./pages/auth/components/SignUpPhoneApp";
import SignUpPaperApp from "./pages/auth/components/SignUpPaperApp";
import MyASHistoryDetailApp from "./pages/mypage/components/MyASHistoryDetailApp";
import MyTournamentDetailApp from "./pages/mypage/MyTournamentDetailApp";
import TournamentRootLayout from "./pages/tournament/TournamentRootLayout";
import MngUserWithTournamentDetail from "./pages/manage/user/components/MngUserWithTournamentDetail";
import MngAdminAccountView from "./pages/manage/user/MngAdminAccount/MngAdminAccountView";
import MngAdminAccountCreate from "./pages/manage/user/MngAdminAccount/MngAdminAccountCreate";
import MngAdminAccountDetail from "./pages/manage/user/MngAdminAccount/MngAdminAccountDetail";
import MngAdminAccountUpdate from "./pages/manage/user/MngAdminAccount/MngAdminAccountUpdate";
import MngTournamentRegisterSerial from "./pages/manage/tournament/MngTournamentRegisterSerial";
import MngProductSmartFilter from "./pages/manage/product/smartFilter/MngProductSmartFilter";
import MngProductSmartFilterDetail from "./pages/manage/product/smartFilter/MngProductSmartFilterDetail";
import MngProductSmartFilterUpdate from "./pages/manage/product/smartFilter/MngProductSmartFilterUpdate";
import MngProductSmartFilterUpload from "./pages/manage/product/smartFilter/MngProductSmartFilterUpload";
import MngEventRegister from "./pages/manage/event/components/MngEventRegister";
import MngEventDetail from "./pages/manage/event/components/MngEventDetail";
import MngWinnerDetail from "./pages/manage/event/components/MngWinnerDetail";
import EventAppDetail from "./pages/event/components/EventAppDetail";
import EventDetail from "./pages/event/components/EventDetail";
import { Provider } from "react-redux";
import store from "./_redux/store";

const MngTournamentPage = lazy(() => import("./pages/manage/tournament/MngTournament"));
const MngTournamentBannerDetail = lazy(() => import("./pages/manage/tournament/components/MngTournamentBannerDetail"));
const MngTournamentDetailDetail = lazy(() => import("./pages/manage/tournament/components/MngTournamentDetailDetail"));
const MngTournamentDetailCreatePage = lazy(() => import("./pages/manage/tournament/components/MngTournamentDetailCreate"));
const MngTournamentDetailModify = lazy(() => import("./pages/manage/tournament/components/MngTournamentDetailModify"));
const MngTournamentHandiModal = lazy(() => import("./pages/manage/tournament/components/MngTournamentHandiModal"));
const MngTournamentHandiModifyModal = lazy(() => import("./pages/manage/tournament/components/MngTournamentHandiModifyModal"));
const MngTournamentReceiptModal = lazy(() => import("./pages/manage/tournament/components/MngTournamentReceiptModal"));
const MngTournamentDetailApplyDetail = lazy(() => import("./pages/manage/tournament/registerApplyComponets/MngTournamentDetailApplyDetail"));
const TournamentPage = lazy(() => import("./pages/tournament/Tournament"));
const MngTournamentDetailStadium = lazy(() => import("./pages/manage/tournament/MngTournamentDetailStadium"));
const MngTournamentDetailPublish = lazy(() => import("./pages/manage/tournament/MngTournamentDetailPublish"));
const MngTournamentStadiumDetail = lazy(() => import("./pages/manage/tournament/components/MngTournamentStadiumDetail"));
const TournamentResultPage = lazy(() => import("./pages/tournament/tournamentresult/Tournamentresult"));
const TournamentResultDetail = lazy(() => import("./pages/tournament/tournamentresult/ResultDetail"));
const MyTournamentHistory = lazy(() => import("./pages/mypage/MyTournamentHistory"));
const MyTournamentDetail = lazy(() => import("./pages/mypage/MyTournamentDetail"));
const MngTournamentResultUpload = lazy(() => import("./pages/manage/tournament/ContestResult/MngTournamentResultUpload"));
const TournamentList = lazy(() => import("./pages/tournament/tournamentList/TournamentList"));
const TournamentListDetail = lazy(() => import("./pages/tournament/tournamentList/TournamentListDetail"));
const ApplyTournamentPage = lazy(() => import("./pages/tournament/applyTournament/ApplyTournament"));
const ApplyTournamentDetail = lazy(() => import("./pages/tournament/applyTournament/components/ApplyTournamentDetail"));
const MngTournamentResultRecord = lazy(() => import("./pages/manage/tournament/MngTournamentResultRecord"));
const MngUserWrittenAccept = lazy(() => import("./pages/manage/user/MngUserWrittenAccept"));
const MngUserWrittenAcceptDetail = lazy(() => import("./pages/manage/user/components/MngUserWrittenAcceptDetail"));

const router = createBrowserRouter([
    {
        path: "/",
        element: <RootLayout />,
        children: [
            { index: true, element: <HomePage /> },
            {
                path: ":category",
                element: <ProductPage isMobile={isMobile} />,
            },
            {
                path: ":category/:id",
                element: <ProductPage isDetail={true} isMobile={isMobile} />,
            },
            {
                path: "kegel",
                element: isMobile ? <KegelAppRootLayout /> : <KegelRootLayout />,
                children: [
                    {
                        index: true,
                        element: (
                            <Suspense fallback={<div>Loading...</div>}>
                                <Navigate to="machine" replace />
                            </Suspense>
                        ),
                    },
                    {
                        path: ":machine",
                        children: [
                            {
                                index: true,
                                element: <Suspense fallback={<div>Loading...</div>}>{isMobile ? <LaneMachinesAppPage /> : <LaneMachinesPage />}</Suspense>,
                                loader: (machine) => import("./pages/kegel/LaneMachines").then((module) => module.loader(machine)),
                            },
                            {
                                path: ":kegelId",
                                element: <Suspense fallback={<p>Loading...</p>}>{isMobile ? <LaneMachinesAppDetail /> : <LaneMachinesDetail />}</Suspense>,
                                loader: (kegelId) => import("./pages/kegel/LaneMachinesDetail").then((module) => module.loader(kegelId)),
                            },
                        ],
                    },
                    {
                        path: ":consumable",
                        children: [
                            {
                                index: true,
                                element: <Suspense fallback={<div>Loading...</div>}>{isMobile ? <LaneMachinesAppPage /> : <LaneMachinesPage />}</Suspense>,
                                loader: (consumable) => import("./pages/kegel/LaneMachines").then((module) => module.loader(consumable)),
                            },
                        ],
                    },
                    {
                        path: ":training_materials",
                        children: [
                            {
                                index: true,
                                element: <Suspense fallback={<div>Loading...</div>}>{isMobile ? <LaneMachinesAppPage /> : <LaneMachinesPage />}</Suspense>,
                                loader: (tool) => import("./pages/kegel/LaneMachines").then((module) => module.loader(tool)),
                            },
                        ],
                    },
                    {
                        path: "usefulInfo",
                        children: [
                            {
                                index: true,
                                element: (
                                    <Suspense fallback={<div>Loading...</div>}>{isMobile ? <LaneServiceUsefulInfoApp /> : <LaneServiceUsefulInfo />}</Suspense>
                                ),
                                loader: (machine) => import("./pages/kegel/LaneServiceUsefulInfo").then((module) => module.loader(machine)),
                            },
                            {
                                path: ":kegelId",
                                element: (
                                    <Suspense fallback={<p>Loading...</p>}>
                                        {isMobile ? <LaneServiceUsefulInfoAppDetail /> : <LaneServiceUsefulInfoDetail />}
                                    </Suspense>
                                ),
                                loader: (kegelId) => import("./pages/kegel/LaneServiceUsefulInfoDetail").then((module) => module.loader(kegelId)),
                            },
                        ],
                    },
                    {
                        path: "help",
                        children: [
                            {
                                index: true,
                                element: <Suspense fallback={<div>Loading...</div>}>{isMobile ? <LaneServiceHelpAppPage /> : <LaneServiceHelp />}</Suspense>,
                                loader: (machine) => import("./pages/kegel/LaneServiceHelp").then((module) => module.loader(machine)),
                            },
                            {
                                path: ":kegelId",
                                element: (
                                    <Suspense fallback={<p>Loading...</p>}>{isMobile ? <LaneServiceHelpAppDetail /> : <LaneServiceHelpDetail />}</Suspense>
                                ),
                                loader: (kegelId) => import("./pages/kegel/LaneServiceHelpDetail").then((module) => module.loader(kegelId)),
                            },
                        ],
                    },
                    {
                        path: "video",
                        children: [
                            {
                                index: true,
                                element: <Suspense fallback={<div>Loading...</div>}>{isMobile ? <LaneUsefulVideoApp /> : <LaneUsefulVideo />}</Suspense>,
                                loader: (machine) => import("./pages/kegel/LaneUsefulVideo").then((module) => module.loader(machine)),
                            },
                        ],
                    },
                ],
            },
            {
                path: "media",
                element: isMobile ? <MediaAppRootLayout /> : <MediaRootLayout />,
                children: [
                    {
                        index: true,
                        element: <Navigate to="notice" replace />,
                    },
                    {
                        path: "notice",
                        children: [
                            {
                                index: true,
                                element: <Suspense fallback={<div>Loading...</div>}>{isMobile ? <MainNoticeAppPage /> : <MainNoticePage />}</Suspense>,
                                loader: isMobile
                                    ? () => import("./pages/media/MainNoticeApp").then((module) => module.loader())
                                    : () => import("./pages/media/MainNotice").then((module) => module.loader()),
                            },
                            {
                                path: ":noticeId",
                                element: <Suspense fallback={<p>Loading...</p>}>{isMobile ? <MainNoticeDetailApp /> : <MainNoticeDetail />}</Suspense>,
                                loader: isMobile
                                    ? (noticeId) => import("./pages/media/MainNoticeDetailApp").then((module) => module.loader(noticeId))
                                    : (noticeId) => import("./pages/media/MainNoticeDetail").then((module) => module.loader(noticeId)),
                            },
                        ],
                    },
                    {
                        path: "faq",
                        element: isMobile ? <FaqAppPage /> : <FaqPage />,
                        loader: isMobile
                            ? () => import("./pages/media/FaqApp").then((module) => module.loader())
                            : () => import("./pages/media/Faq").then((module) => module.loader()),
                    },
                ],
            },
            {
                path: "login",
                element: isMobile ? <SignInAppPage /> : <SignInPage />,
            },
            {
                path: "find",
                element: isMobile ? <FindIdAndPwdApp /> : <FindIdAndPwd />,
            },
            {
                path: "signUp",
                children: [
                    {
                        index: true,
                        element: isMobile ? <SingUpAppPage /> : <SingUpPage />,
                    },
                    {
                        path: "paper",
                        element: isMobile ? <SignUpPaperApp /> : <SignUpPaper />,
                    },
                    {
                        path: "phone",
                        element: isMobile ? <SignUpPhoneApp /> : <SignUpPhone />,
                    },
                    { path: "error", element: <NiceError /> },
                    { path: "duplicate", element: <NiceDuplicate /> },
                    { path: "noExist", element: <NiceNoExist /> },
                    { path: "success", element: <IdentifyFunc /> },
                ],
            },
            {
                path: "intro",
                element: isMobile ? <IntroduceAppRootLayout /> : <IntroduceRootLayout />,
                children: [
                    {
                        index: true,
                        element: <Navigate to="introduce" replace />,
                    },
                    {
                        path: "introduce",
                        element: isMobile ? <IntroduceAppPage /> : <IntroducePage />,
                    },
                    {
                        path: "history",
                        element: isMobile ? <IntroHistoryAppPage /> : <IntroHistoryPage />,
                    },
                    {
                        path: "staff",
                        element: isMobile ? <IntroStaffsAppPage /> : <IntroStaffsPage />,
                        loader: isMobile
                            ? () => import("./pages/introduce/IntroStaffsApp").then((module) => module.loader())
                            : () => import("./pages/introduce/IntroStaffs").then((module) => module.loader()),
                    },
                    {
                        path: "center",
                        element: isMobile ? <IntroCentersAppPage /> : <IntroCentersPage />,
                        loader: isMobile
                            ? () => import("./pages/introduce/showCentersApp").then((module) => module.loader())
                            : () => import("./pages/introduce/showCenters").then((module) => module.loader()),
                    },
                    {
                        path: "location",
                        element: isMobile ? <IntroLocationAppPage /> : <IntroLocationPage />,
                    },
                ],
            },
            {
                path: "event",
                element: isMobile ? <EventAppRootLayout /> : <EventRootLayout />,
                children: [
                    {
                        index: true,
                        element: <Navigate to="ongoing" replace />,
                    },
                    {
                        path: "ongoing",
                        element: isMobile ? <EventAppPage /> : <EventPage />,
                        loader: isMobile
                        ? () =>
                              import(
                                  "./pages/event/EventApp"
                              ).then((module) => module.loader())
                        : () =>
                              import("./pages/event/Event")
                              .then(
                                  (module) => module.loader()
                              ),
                    },
                    {
                        path : "ongoing/:eventId",
                        element: isMobile ? <EventAppDetail /> : <EventDetail />,
                        loader: isMobile ? (eventId) =>import("./pages/event/components/EventAppDetail").then((module) => module.loader(eventId))
                                : (eventId) =>import("./pages/event/components/EventDetail").then((module) => module.loader(eventId)),
                    },
                    {
                        path: "winners",
                        element: isMobile ? (<WinnersListApp />) : (<WinnersList />),
                        loader: isMobile 
                            ? () => import("./pages/event/WinnersListApp").then((module) => module.loader()) 
                            : () => import("./pages/event/WinnersList").then((module) => module.loader()),
                    },
                ],
            },
            {
                path: "myPage",
                element: isMobile ? <MyPageAppRootLayout /> : <MyPageRootLayout />,
                children: [
                    {
                        index: true,
                        element: <Navigate to="tournamentHistory" replace />,
                    },
                    {
                        path: "tournamentHistory",
                        children: [
                            {
                                index: true,
                                element: isMobile ? (
                                    <Suspense fallback={<p>Loading...</p>}>
                                        <MyTournamentHistoryApp />
                                    </Suspense>
                                ) : (
                                    <Suspense fallback={<p>Loading...</p>}>
                                        <MyTournamentHistory />
                                    </Suspense>
                                ),
                                loader: isMobile
                                    ? () => import("./pages/mypage/MyTournamentHistoryApp").then((module) => module.loader())
                                    : () => import("./pages/mypage/MyTournamentHistory").then((module) => module.loader()),
                            },
                            {
                                path: ":competitorId",
                                element: isMobile ? (
                                    <Suspense fallback={<p>Loading...</p>}>
                                        <MyTournamentDetailApp />
                                    </Suspense>
                                ) : (
                                    <Suspense fallback={<p>Loading...</p>}>
                                        <MyTournamentDetail />
                                    </Suspense>
                                ),
                                loader: isMobile
                                    ? (competitorId) => import("./pages/mypage/MyTournamentDetailApp").then((module) => module.loader(competitorId))
                                    : (competitorId) => import("./pages/mypage/MyTournamentDetail").then((module) => module.loader(competitorId)),
                            },
                        ],
                    },
                    {
                        path: "event",
                        element: isMobile ? (<MyEventHistoryApp />) : (<MyEventHistory />),
                        loader: isMobile
                                ? () =>import("./pages/mypage/MyEventHistoryApp").then((module) => module.loader())
                                : () =>import("./pages/mypage/MyEventHistory").then((module) => module.loader()),
                    },
                    {
                        path: "cs",
                        children: [
                            {
                                index: true,
                                element: isMobile ? (
                                    <Suspense fallback={<p>Loading...</p>}>
                                        <MyCSHistoryApp />
                                    </Suspense>
                                ) : (
                                    <Suspense fallback={<p>Loading...</p>}>
                                        <MyCSHistory />
                                    </Suspense>
                                ),
                                loader: isMobile
                                    ? () => import("./pages/mypage/MyCSHistoryApp").then((module) => module.loader())
                                    : () => import("./pages/mypage/MyCSHistory").then((module) => module.loader()),
                            },
                            {
                                path: ":helpId",
                                element: isMobile ? (
                                    <Suspense fallback={<p>Loading...</p>}>
                                        <MyCSHistoryDetailApp />
                                    </Suspense>
                                ) : (
                                    <Suspense fallback={<p>Loading...</p>}>
                                        <MyCSHistoryDetail />
                                    </Suspense>
                                ),
                                loader: isMobile
                                    ? (helpId) => import("./pages/mypage/components/MyCSHistoryDetailApp").then((module) => module.loader(helpId))
                                    : (helpId) => import("./pages/mypage/components/MyCSHistoryDetail").then((module) => module.loader(helpId)),
                            },
                            {
                                path: "as",
                                element: isMobile ? (
                                    <Suspense fallback={<p>Loading...</p>}>
                                        <MyASHistoryApp />
                                    </Suspense>
                                ) : (
                                    <Suspense fallback={<p>Loading...</p>}>
                                        <MyASHistory />
                                    </Suspense>
                                ),
                                loader: isMobile
                                    ? () => import("./pages/mypage/MyASHistoryApp").then((module) => module.loader())
                                    : () => import("./pages/mypage/MyASHistory").then((module) => module.loader()),
                            },
                            {
                                path: "as/:asId",
                                element: isMobile ? (
                                    <Suspense fallback={<p>Loading...</p>}>
                                        <MyASHistoryDetailApp />
                                    </Suspense>
                                ) : (
                                    <Suspense fallback={<p>Loading...</p>}>
                                        <MyASHistoryDetail />
                                    </Suspense>
                                ),
                                loader: isMobile
                                    ? (asId) => import("./pages/mypage/components/MyASHistoryDetailApp").then((module) => module.loader(asId))
                                    : (asId) => import("./pages/mypage/components/MyASHistoryDetail").then((module) => module.loader(asId)),
                            },
                        ],
                    },
                    {
                        path: "contact/as",
                        element: isMobile ? (
                            <Suspense fallback={<p>Loading...</p>}>
                                <MyContactAsApp />
                            </Suspense>
                        ) : (
                            <Suspense fallback={<p>Loading...</p>}>
                                <MyContactAs />
                            </Suspense>
                        ),
                    },
                    {
                        path: "contact/help",
                        element: isMobile ? (
                            <Suspense fallback={<p>Loading...</p>}>
                                <MyContactHelpApp />
                            </Suspense>
                        ) : (
                            <Suspense fallback={<p>Loading...</p>}>
                                <MyContactHelp />
                            </Suspense>
                        ),
                    },
                    {
                        path: "modify",
                        element: isMobile ? (
                            <Suspense fallback={<p>Loading...</p>}>
                                <MyInformationAppPage />
                            </Suspense>
                        ) : (
                            <Suspense fallback={<p>Loading...</p>}>
                                <MyInformationPage />
                            </Suspense>
                        ),
                        loader: isMobile
                            ? () => import("./pages/mypage/MyInformationApp").then((module) => module.loader())
                            : () => import("./pages/mypage/MyInformation").then((module) => module.loader()),
                    },
                ],
            },
            { path: "warrantyService", element: <WarrantyServicePage /> },
            {
                path: "privatePolicy",
                element: <PrivacyPolicyPage />,
                loader: () => import("./pages/other/PrivacyPolicy").then((module) => module.loader()),
            },
            { path: "pageError", element: <PageError /> },
            {
                path: "termsOfUse",
                element: <TermsOfUsePage />,
                loader: () => import("./pages/other/TermsOfUse").then((module) => module.loader()),
            },

            {
                path: "/tournamentM",

                element: isMobile ? <TournamentAppRootLayout /> : <TournamentRootLayout />,
                children: [
                    { index: true, element: <Navigate to="notice" replace /> },
                    {
                        path: "notice",
                        children: [
                            {
                                index: true,
                                element: <Suspense fallback={<p>Loading...</p>}>{isMobile ? <TournamentNoticeViewApp /> : <TournamentNotice />}</Suspense>,
                                loader: () => {
                                    if (isMobile) {
                                        return import("./pages/tournament/tournamentNotice/TournamentNoticeViewApp").then((module) => module.loader());
                                    } else {
                                        return import("./pages/tournament/tournamentNotice/TournamentNoticeView").then((module) => module.loader());
                                    }
                                },
                            },
                            {
                                path: ":ctNoticeId",
                                element: (
                                    <Suspense fallback={<p>Loading...</p>}>
                                        {isMobile ? <TournamentNoticeViewDetailApp /> : <TournamentNoticeDetail />}
                                    </Suspense>
                                ),
                                loader: (ctNoticeId) => {
                                    if (isMobile) {
                                        return import("./pages/tournament/tournamentNotice/TournamentNoticeViewDetailApp").then((module) =>
                                            module.loader(ctNoticeId)
                                        );
                                    } else {
                                        return import("./pages/tournament/tournamentNotice/TournamentNoticeDetail").then((module) => module.loader(ctNoticeId));
                                    }
                                },
                            },
                        ],
                    },
                    {
                        path: "apply",
                        children: [
                            {
                                index: true,
                                element: isMobile ? (
                                    <Suspense fallback={<p>Loading...</p>}>
                                        <ApplyTournamentAppPage />
                                    </Suspense>
                                ) : (
                                    <Suspense fallback={<p>Loading...</p>}>
                                        <ApplyTournamentPage />
                                    </Suspense>
                                ),
                                loader: isMobile
                                    ? () => import("./pages/tournament/applyTournament/ApplyTournamentApp").then((module) => module.loader())
                                    : () => import("./pages/tournament/applyTournament/ApplyTournament").then((module) => module.loader()),
                            },
                            {
                                path: ":contestId/:placeId",
                                element: isMobile ? (
                                    <Suspense fallback={<p>Loading...</p>}>
                                        <ApplyTournamentDetailApp />
                                    </Suspense>
                                ) : (
                                    <ApplyTournamentDetail />
                                ),
                            },
                        ],
                    },
                    {
                        path: "list",
                        children: [
                            {
                                index: true,
                                element: <Suspense fallback={<p>Loading...</p>}>{isMobile ? <TournamentListApp /> : <TournamentList />}</Suspense>,
                                loader: isMobile
                                    ? () => import("./pages/tournament/tournamentList/TournamentListApp").then((module) => module.loader())
                                    : () => import("./pages/tournament/tournamentList/TournamentList").then((module) => module.loader()),
                            },
                            {
                                path: "info/:contestId",
                                element: <Suspense fallback={<p>Loading...</p>}>{isMobile ? <TournamentListDetailApp /> : <TournamentListDetail />}</Suspense>,
                            },
                        ],
                    },
                    {
                        path: "result",
                        children: [
                            {
                                index: true,
                                element: <Suspense fallback={<p>Loading...</p>}>{isMobile ? <TournamentResultApp /> : <TournamentResultPage />}</Suspense>,
                                loader: isMobile
                                    ? () => import("./pages/tournament/tournamentresult/TournamentresultApp").then((module) => module.loader())
                                    : () => import("./pages/tournament/tournamentresult/Tournamentresult").then((module) => module.loader()),
                            },
                            {
                                path: ":ctId",
                                element: (
                                    <Suspense fallback={<p>Loading...</p>}>{isMobile ? <TournamentResultDetailApp /> : <TournamentResultDetail />}</Suspense>
                                ),
                                loader: isMobile
                                    ? (ctId) => import("./pages/tournament/tournamentresult/ResultDetailApp").then((module) => module.loader(ctId))
                                    : (ctId) => import("./pages/tournament/tournamentresult/ResultDetail").then((module) => module.loader(ctId)),
                            },
                        ],
                    },
                ],
            },
            {
                path: "/tournament",
                children: [
                    {
                        index: true,
                        element: <Suspense fallback={<p>Loading...</p>}> {isMobile ? <TournamentAppPage /> : <TournamentPage />} </Suspense>,
                    },
                    {
                        path: "notice",
                        element: isMobile ? <TournamentAppRootLayout /> : <TournamentRootLayout />,
                        children: [
                            {
                                index: true,
                                element: <Suspense fallback={<p>Loading...</p>}>{isMobile ? <TournamentNoticeViewApp /> : <TournamentNotice />}</Suspense>,
                                loader: () => {
                                    if (isMobile) {
                                        return import("./pages/tournament/tournamentNotice/TournamentNoticeViewApp").then((module) => module.loader());
                                    } else {
                                        return import("./pages/tournament/tournamentNotice/TournamentNoticeView").then((module) => module.loader());
                                    }
                                },
                            },
                            {
                                path: ":ctNoticeId",
                                element: (
                                    <Suspense fallback={<p>Loading...</p>}>
                                        {isMobile ? <TournamentNoticeViewDetailApp /> : <TournamentNoticeDetail />}
                                    </Suspense>
                                ),

                                loader: (ctNoticeId) => {
                                    if (isMobile) {
                                        return import("./pages/tournament/tournamentNotice/TournamentNoticeViewDetailApp").then((module) =>
                                            module.loader(ctNoticeId)
                                        );
                                    } else {
                                        return import("./pages/tournament/tournamentNotice/TournamentNoticeDetail").then((module) => module.loader(ctNoticeId));
                                    }
                                },
                            },
                        ],
                    },
                    {
                        path: "apply",
                        element: isMobile ? <TournamentAppRootLayout /> : <TournamentRootLayout />,
                        children: [
                            {
                                index: true,
                                element: <Suspense fallback={<p>Loading...</p>}>{isMobile ? <ApplyTournamentAppPage /> : <ApplyTournamentPage />}</Suspense>,
                                loader: isMobile
                                    ? () => import("./pages/tournament/applyTournament/ApplyTournamentApp").then((module) => module.loader()) : null
                            },
                            {
                                path: ":contestId/:placeId",
                                element: (
                                    <Suspense fallback={<p>Loading...</p>}>{isMobile ? <ApplyTournamentDetailApp /> : <ApplyTournamentDetail />}</Suspense>
                                ),
                            },
                        ],
                    },
                    {
                        path: "list",
                        element: isMobile ? <TournamentAppRootLayout /> : <TournamentRootLayout />,
                        children: [
                            {
                                index: true,
                                element: <Suspense fallback={<p>Loading...</p>}>{isMobile ? <TournamentListApp /> : <TournamentList />}</Suspense>,
                                loader: isMobile
                                    ? () => import("./pages/tournament/tournamentList/TournamentListApp").then((module) => module.loader())
                                    : () => import("./pages/tournament/tournamentList/TournamentList").then((module) => module.loader()),
                            },
                            {
                                path: "info/:contestId",
                                element: <Suspense fallback={<p>Loading...</p>}>{isMobile ? <TournamentListDetailApp /> : <TournamentListDetail />}</Suspense>,
                            },
                        ],
                    },
                    {
                        path: "result",
                        element: isMobile ? <TournamentAppRootLayout /> : <TournamentRootLayout />,
                        children: [
                            {
                                index: true,
                                element: <Suspense fallback={<p>Loading...</p>}>{isMobile ? <TournamentResultApp /> : <TournamentResultPage />}</Suspense>,
                                loader: isMobile
                                    ? () => import("./pages/tournament/tournamentresult/TournamentresultApp").then((module) => module.loader())
                                    : () => import("./pages/tournament/tournamentresult/Tournamentresult").then((module) => module.loader()),
                            },
                            {
                                path: ":ctId",
                                element: (
                                    <Suspense fallback={<p>Loading...</p>}>{isMobile ? <TournamentResultDetailApp /> : <TournamentResultDetail />}</Suspense>
                                ),
                                loader: isMobile
                                    ? (ctId) => import("./pages/tournament/tournamentresult/ResultDetailApp").then((module) => module.loader(ctId))
                                    : (ctId) => import("./pages/tournament/tournamentresult/ResultDetail").then((module) => module.loader(ctId)),
                            },
                        ],
                    },
                    { path: "uiComponents", element: <UIComponents /> },
                ],
            },
            {
                path: "admin",
                children: [
                    {
                        index: true,
                        element: <Navigate to="tournament" replace />,
                    },
                    {
                        path: "tournament",
                        element: <MngTournamentRootLayout />,
                        children: [
                            {
                                index: true,
                                element: <Navigate to="banner" replace />,
                            },
                            {
                                path: "banner",
                                children: [
                                    {
                                        index: true,
                                        element: (
                                            <Suspense fallback={<p>Loading...</p>}>
                                                <MngTournamentPage />
                                            </Suspense>
                                        ),
                                        loader: () => import("./pages/manage/tournament/MngTournament").then((module) => module.loader()),
                                    },
                                    {
                                        path: ":bannerId",
                                        element: (
                                            <Suspense fallback={<p>Loading...</p>}>
                                                <MngTournamentBannerDetail />
                                            </Suspense>
                                        ),
                                        loader: (bannerId) =>
                                            import("./pages/manage/tournament/components/MngTournamentBannerDetail").then((module) => module.loader(bannerId)),
                                    },
                                ],
                            },
                            {
                                path: "notice",
                                children: [
                                    {
                                        index: true,
                                        element: <MngTournamentNoticePage />,
                                        loader: () => import("./pages/manage/tournament/contestNotice/MngTournamentNotice").then((module) => module.loader()),
                                    },
                                    {
                                        path: "upload",
                                        element: <MngTournamentNoticeUpload />,
                                    },
                                    {
                                        path: ":ctNoticeId",
                                        element: (
                                            <Suspense fallback={<p>Loading...</p>}>
                                                <MngTournamentNoticeDetail />
                                            </Suspense>
                                        ),
                                        loader: (ctNoticeId) =>
                                            import("./pages/manage/tournament/contestNotice/MngTournamentNoticeDetail").then((module) =>
                                                module.loader(ctNoticeId)
                                            ),
                                    },
                                    {
                                        path: "update/:ctNoticeId",
                                        element: (
                                            <Suspense fallback={<p>Loading...</p>}>
                                                <MngTournamentNoticeUpdate />
                                            </Suspense>
                                        ),
                                        loader: (ctNoticeId) =>
                                            import("./pages/manage/tournament/contestNotice/MngTournamentNoticeUpdate").then((module) =>
                                                module.loader(ctNoticeId)
                                            ),
                                    },
                                ],
                            },
                            {
                                path: "detail",
                                children: [
                                    {
                                        index: true,
                                        element: (
                                            <Suspense fallback={<p>Loading...</p>}>
                                                <MngTournamentDetailPage />
                                            </Suspense>
                                        ),
                                        loader: () => import("./pages/manage/tournament/MngTournamentDetail").then((module) => module.loader()),
                                    },
                                    {
                                        path: ":mainId",
                                        element: (
                                            <Suspense fallback={<p>Loading...</p>}>
                                                <MngTournamentDetailDetail />
                                            </Suspense>
                                        ),
                                        loader: (mainId) =>
                                            import("./pages/manage/tournament/components/MngTournamentDetailDetail").then((module) => module.loader(mainId)),
                                    },
                                    {
                                        path: "update/:mainId",
                                        element: (
                                            <Suspense fallback={<p>Loading...</p>}>
                                                <MngTournamentDetailModify />
                                            </Suspense>
                                        ),
                                        loader: (mainId) =>
                                            import("./pages/manage/tournament/components/MngTournamentDetailModify").then((module) => module.loader(mainId)),
                                    },
                                    {
                                        path: "create",
                                        element: (
                                            <Suspense fallback={<p>Loading...</p>}>
                                                <MngTournamentDetailCreatePage />
                                            </Suspense>
                                        ),
                                    },
                                    {
                                        path: "handi",
                                        element: (
                                            <Suspense fallback={<p>Loading...</p>}>
                                                <MngTournamentHandiModal />
                                            </Suspense>
                                        ),
                                        loader: () => import("./pages/manage/tournament/components/MngTournamentHandiModal").then((module) => module.loader()),
                                    },
                                    {
                                        path: ":handiId",
                                        element: (
                                            <Suspense fallback={<p>Loading...</p>}>
                                                <MngTournamentHandiModifyModal />
                                            </Suspense>
                                        ),
                                        loader: (handiId) =>
                                            import("./pages/manage/tournament/components/MngTournamentHandiModifyModal").then((module) =>
                                                module.loader(handiId)
                                            ),
                                    },
                                    {
                                        path: "receipt",
                                        element: (
                                            <Suspense fallback={<p>Loading...</p>}>
                                                <MngTournamentReceiptModal />
                                            </Suspense>
                                        ),
                                        loader: () =>
                                            import("./pages/manage/tournament/components/MngTournamentReceiptModal").then((module) => module.loader()),
                                    },
                                ],
                            },
                            {
                                path: "stadium",
                                children: [
                                    {
                                        index: true,
                                        element: (
                                            <Suspense fallback={<p>Loading...</p>}>
                                                <MngTournamentDetailStadium />
                                            </Suspense>
                                        ),
                                        loader: () => import("./pages/manage/tournament/MngTournamentDetailStadium").then((module) => module.loader()),
                                    },
                                    {
                                        path: ":placeId",
                                        children: [
                                            {
                                                index: true,
                                                element: (
                                                    <Suspense fallback={<p>Loading...</p>}>
                                                        <MngTournamentStadiumDetail />
                                                    </Suspense>
                                                ),
                                                loader: (placeId) =>
                                                    import("./pages/manage/tournament/components/MngTournamentStadiumDetail").then((module) =>
                                                        module.loader(placeId)
                                                    ),
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                path: "bowlingAlley",
                                children: [
                                    {
                                        index: true,
                                        element: <MngTournamentBowlingAlley />,
                                        loader: () =>
                                            import("./pages/manage/tournament/BowlingAlley/MngTournamentBowlingAlley").then((module) => module.loader()),
                                    },
                                    {
                                        path: "upload",
                                        element: <MngTournamentBowlingAlleyUpload />,
                                    },
                                    {
                                        path: ":center_id",
                                        element: (
                                            <Suspense fallback={<p>Loading...</p>}>
                                                <MngTournamentBowlingAlleyDetail />
                                            </Suspense>
                                        ),
                                        loader: (center_id) =>
                                            import("./pages/manage/tournament/BowlingAlley/MngTournamentBowlingAlleyDetail").then((module) =>
                                                module.loader(center_id)
                                            ),
                                    },
                                    {
                                        path: "update/:center_id",
                                        element: (
                                            <Suspense fallback={<p>Loading...</p>}>
                                                <MngTournamentBowlingAlleyUpdate />
                                            </Suspense>
                                        ),
                                        loader: (center_id) =>
                                            import("./pages/manage/tournament/BowlingAlley/MngTournamentBowlingAlleyUpdate").then((module) =>
                                                module.loader(center_id)
                                            ),
                                    },
                                ],
                            },
                            {
                                path: "publish",
                                element: (
                                    <Suspense fallback={<p>Loading...</p>}>
                                        <MngTournamentDetailPublish />
                                    </Suspense>
                                ),
                                loader: () => import("./pages/manage/tournament/MngTournamentDetailPublish").then((module) => module.loader()),
                            },
                            {
                                path: "apply",
                                children: [
                                    {
                                        index: true,
                                        element: (
                                            <Suspense fallback={<p>Loading...</p>}>
                                                <MngTournamentDetailApply />
                                            </Suspense>
                                        ),
                                        loader: () => import("./pages/manage/tournament/MngTournamentDetailApply").then((module) => module.loader()),
                                    },
                                    {
                                        path: ":mainId",
                                        element: (
                                            <Suspense fallback={<p>Loading...</p>}>
                                                <MngTournamentDetailApplyDetail />
                                            </Suspense>
                                        ),
                                        loader: (mainId) =>
                                            import("./pages/manage/tournament/registerApplyComponets/MngTournamentDetailApplyDetail").then((module) =>
                                                module.loader(mainId)
                                            ),
                                    },
                                ],
                            },
                            {
                                path: "autoSend",
                                children: [
                                    {
                                        index: true,
                                        element: <MngTournamentDetailAutoSend />,
                                        loader: () => import("./pages/manage/tournament/MngTournamentDetailAutoSend").then((module) => module.loader()),
                                    },
                                ],
                            },
                            {
                                path: "print",
                                children: [
                                    {
                                        index: true,
                                        element: <MngTournamentDetailPrint />,
                                        loader: () => import("./pages/manage/tournament/MngTournamentDetailPrint").then((module) => module.loader()),
                                    },
                                ],
                            },
                            {
                                path: "result",
                                children: [
                                    {
                                        index: true,
                                        element: <MngTournamentResultPage />,
                                        loader: () => import("./pages/manage/tournament/ContestResult/MngTournamentResult").then((module) => module.loader()),
                                    },
                                    {
                                        path: "upload",
                                        element: <MngTournamentResultUpload />,
                                    },
                                    {
                                        path: ":result_id",
                                        element: (
                                            <Suspense fallback={<p>Loading...</p>}>
                                                <MngTournamentResultDetail />
                                            </Suspense>
                                        ),
                                        loader: (result_id) =>
                                            import("./pages/manage/tournament/ContestResult/MngTournamentResultDetail").then((module) =>
                                                module.loader(result_id)
                                            ),
                                    },
                                    {
                                        path: "update/:result_id",
                                        element: (
                                            <Suspense fallback={<p>Loading...</p>}>
                                                <MngTournamentResultUpdate />
                                            </Suspense>
                                        ),
                                        loader: (result_id) =>
                                            import("./pages/manage/tournament/ContestResult/MngTournamentResultUpdate").then((module) =>
                                                module.loader(result_id)
                                            ),
                                    },
                                ],
                            },
                            {
                                path: "resultUpload",
                                children: [
                                    {
                                        index: true,
                                        element: (
                                            <Suspense fallback={<p>Loading...</p>}>
                                                <MngTournamentResultUpload />
                                            </Suspense>
                                        ),
                                    },
                                ],
                            },
                            {
                                path: "resultRecord",
                                children: [
                                    {
                                        index: true,
                                        element: (
                                            <Suspense fallback={<p>Loading...</p>}>
                                                <MngTournamentResultRecord />
                                            </Suspense>
                                        ),
                                        loader: () => import("./pages/manage/tournament/MngTournamentResultRecord").then((module) => module.loader()),
                                    },
                                ],
                            },
                            {
                                path: "ball",
                                element: <MngTournamentBall />,
                                loader: () => import("./pages/manage/tournament/MngTournamentBall").then((module) => module.loader()),
                            },
                            {
                                path: "registerSerial",
                                element: <MngTournamentRegisterSerial />,
                                loader: () => import("./pages/manage/tournament/MngTournamentRegisterSerial").then((module) => module.loader()),
                            },
                            { path: "site", element: <MngSiteTopLine /> },
                            {
                                path: "staff",
                                element: <MngStaffPage />,
                            }
                        ],
                    },                
                    {
                        path: "product",
                        element: <MngProductRootLayout />,
                        children: [
                            {
                                index: true,
                                element: <Navigate to="category" replace />,
                            },
                            {
                                path: "category",
                                element: <MngProductCategory />,
                            },
                            { path: 'list', element: <MngProductListPage nowMode={0} /> }, 
                                           { path: 'add', element: <MngProductListPage />},
                                           { path: ':productId', element: <MngProductListPage nowMode={2} />},

                            {
                                path: "smartFilter",
                                children: [
                                    {
                                        index: true,
                                        element: <MngProductSmartFilter />,
                                        loader: () => import("./pages/manage/product/smartFilter/MngProductSmartFilter").then((module) => module.loader()),
                                    },
                                    {
                                        path: "upload",
                                        element: <MngProductSmartFilterUpload />,
                                    },
                                    {
                                        path: ":productId",
                                        element: (
                                            <Suspense fallback={<p>Loading...</p>}>
                                                <MngProductSmartFilterDetail />
                                            </Suspense>
                                        ),
                                        loader: (productId) =>
                                            import("./pages/manage/product/smartFilter/MngProductSmartFilterDetail").then((module) => module.loader(productId)),
                                    },
                                    {
                                        path: "update/:productId",
                                        element: (
                                            <Suspense fallback={<p>Loading...</p>}>
                                                <MngProductSmartFilterUpdate />
                                            </Suspense>
                                        ),
                                        loader: (productId) =>
                                            import("./pages/manage/product/smartFilter/MngProductSmartFilterUpdate").then((module) => module.loader(productId)),
                                    },
                                ],
                            },
                            { path: "display", element: <MngProductDisplay /> },
                        ],
                    },
                    {
                        path: "kegel",
                        element: <MngKegelRootLayout />,
                        children: [
                            {
                                index: true,
                                element: <MngKegel />,
                                loader: () => import("./pages/manage/kegel/MngKegel").then((module) => module.loader()),
                            },
                            {
                                path: "upload",
                                element: <MngKegelUpload />,
                            },
                            {
                                path: ":kegelId",
                                element: (
                                    <Suspense fallback={<p>Loading...</p>}>
                                        <MngKegelDetail />
                                    </Suspense>
                                ),
                                loader: (kegelId) => import("./pages/manage/kegel/MngKegelDetail").then((module) => module.loader(kegelId)),
                            },
                            {
                                path: "update/:kegelId",
                                element: (
                                    <Suspense fallback={<p>Loading...</p>}>
                                        <MngKegelUpdate />
                                    </Suspense>
                                ),
                                loader: (kegelId) => import("./pages/manage/kegel/MngKegelUpdate").then((module) => module.loader(kegelId)),
                            },
                            {
                                path: "info",
                                children: [
                                    {
                                        index: true,
                                        element: <MngKegelInfo />,
                                        loader: () => import("./pages/manage/kegel/MngKegelInfo").then((module) => module.loader()),
                                    },
                                    {
                                        path: "upload",
                                        element: <MngKegelInfoUpload />,
                                    },
                                    {
                                        path: ":kegelId",
                                        element: (
                                            <Suspense fallback={<p>Loading...</p>}>
                                                <MngKegelInfoDetail />
                                            </Suspense>
                                        ),
                                        loader: (kegelId) => import("./pages/manage/kegel/MngKegelInfoDetail").then((module) => module.loader(kegelId)),
                                    },
                                    {
                                        path: "update/:kegelId",
                                        element: (
                                            <Suspense fallback={<p>Loading...</p>}>
                                                <MngKegelInfoUpdate />
                                            </Suspense>
                                        ),
                                        loader: (kegelId) => import("./pages/manage/kegel/MngKegelInfoUpdate").then((module) => module.loader(kegelId)),
                                    },
                                ],
                            },
                            {
                                path: "video",
                                children: [
                                    {
                                        index: true,
                                        element: <MngKegelVideo />,
                                        loader: () => import("./pages/manage/kegel/MngKegelVideo").then((module) => module.loader()),
                                    },
                                    {
                                        path: "upload",
                                        element: <MngKegelVideoUpload />,
                                    },
                                    {
                                        path: ":kegelId",
                                        element: (
                                            <Suspense fallback={<p>Loading...</p>}>
                                                <MngKegelVideoDetail />
                                            </Suspense>
                                        ),
                                        loader: (kegelId) => import("./pages/manage/kegel/MngKegelVideoDetail").then((module) => module.loader(kegelId)),
                                    },
                                    {
                                        path: "update/:kegelId",
                                        element: (
                                            <Suspense fallback={<p>Loading...</p>}>
                                                <MngKegelVideoUpdate />
                                            </Suspense>
                                        ),
                                        loader: (kegelId) => import("./pages/manage/kegel/MngKegelVideoUpdate").then((module) => module.loader(kegelId)),
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: "community",
                        element: <MngCommunityRootLayout />,
                        children: [
                            {
                                index: true,
                                element: <Navigate to="notice" replace />,
                            },
                            {
                                path: "notice",
                                children: [
                                    {
                                        index: true,
                                        element: (
                                            <Suspense fallback={<p>Loading...</p>}>
                                                <MngCommunity />
                                            </Suspense>
                                        ),
                                    },
                                    {
                                        path: "upload",
                                        element: <MngCommunityUpload />,
                                    },
                                    {
                                        path: ":noticeId",
                                        element: (
                                            <Suspense fallback={<p>Loading...</p>}>
                                                <MngCommunityDetail />
                                            </Suspense>
                                        ),
                                        loader: (noticeId) => import("./pages/manage/community/MngCommunityDetail").then((module) => module.loader(noticeId)),
                                    },
                                    {
                                        path: "update/:noticeId",
                                        element: (
                                            <Suspense fallback={<p>Loading...</p>}>
                                                <MngCommunityUpdate />
                                            </Suspense>
                                        ),
                                        loader: (noticeId) => import("./pages/manage/community/MngCommunityUpdate").then((module) => module.loader(noticeId)),
                                    },
                                ],
                            },
                            {
                                path: "faq",
                                children: [
                                    {
                                        index: true,
                                        element: (
                                            <Suspense fallback={<p>Loading...</p>}>
                                                <MngFaq />
                                            </Suspense>
                                        ),
                                        loader: () => import("./pages/manage/community/MngFaq").then((module) => module.loader()),
                                    },
                                    {
                                        path: "create",
                                        element: <MngFaqCreate />,
                                    },
                                    {
                                        path: ":faqID",
                                        element: (
                                            <Suspense fallback={<p>Loading...</p>}>
                                                <MngFaqDetail />
                                            </Suspense>
                                        ),
                                        loader: (faqID) => import("./pages/manage/community/FaqComponets/MngFaqDetail").then((module) => module.loader(faqID)),
                                    },
                                    {
                                        path: "update/:faqID",
                                        element: (
                                            <Suspense fallback={<p>Loading...</p>}>
                                                <MngFaqModify />
                                            </Suspense>
                                        ),
                                        loader: (faqID) => import("./pages/manage/community/FaqComponets/MngFaqModify").then((module) => module.loader(faqID)),
                                    },
                                ],
                            },
                            {
                                path: "cs",
                                children: [
                                    {
                                        index: true,
                                        element: <MngCs />,
                                        loader: () => import("./pages/manage/community/MngCs").then((module) => module.loader()),
                                    },
                                    {
                                        path: ":helpId",
                                        element: <MngCsDetail />,
                                        loader: (helpId) => import("./pages/manage/community/components/MngCsDetail").then((module) => module.loader(helpId)),
                                    },
                                ],
                            },
                            {
                                path: "as",
                                children: [
                                    {
                                        index: true,
                                        element: <MngAs />,
                                        loader: () => import("./pages/manage/community/MngAs").then((module) => module.loader()),
                                    },
                                    {
                                        path: ":asId",
                                        element: <MngAsDetail />,
                                        loader: (asId) => import("./pages/manage/community/components/MngAsDetail").then((module) => module.loader(asId)),
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: "company",
                        element: <MngCompanyRootLayout />,
                        children: [
                            {
                                index: true,
                                element: <Navigate to="info" replace />,
                            },
                            { path: "info", element: <MngCompany /> },
                            {
                                path: "staff",
                                children: [
                                    {
                                        index: true,
                                        element: (
                                            <Suspense fallback={<p>Loading...</p>}>
                                                <MngStaff />
                                            </Suspense>
                                        ),
                                        loader: () => import("./pages/manage/company/MngStaff").then((module) => module.loader()),
                                    },
                                    {
                                        path: "create",
                                        element: <MngStaffCreate />,
                                    },
                                    {
                                        path: ":staffID",
                                        element: (
                                            <Suspense fallback={<p>Loading...</p>}>
                                                <MngStaffDetail />
                                            </Suspense>
                                        ),
                                        loader: (staffID) =>
                                            import("./pages/manage/company/staffComponents/MngStaffDetail").then((module) => module.loader(staffID)),
                                    },
                                    {
                                        path: "update/:staffID",
                                        element: (
                                            <Suspense fallback={<p>Loading...</p>}>
                                                <MngStaffModify />
                                            </Suspense>
                                        ),
                                        loader: (staffID) =>
                                            import("./pages/manage/company/staffComponents/MngStaffModify").then((module) => module.loader(staffID)),
                                    },
                                ],
                            },
                            {
                                path: "center",
                                children: [
                                    {
                                        index: true,
                                        element: (
                                            <Suspense fallback={<p>Loading...</p>}>
                                                <MngCenter />
                                            </Suspense>
                                        ),
                                        loader: () => import("./pages/manage/company/MngCenter").then((module) => module.loader()),
                                    },
                                    {
                                        path: "create",
                                        element: <MngCenterCreate />,
                                    },
                                    {
                                        path: ":centerID",
                                        element: (
                                            <Suspense fallback={<p>Loading...</p>}>
                                                <MngCenterDetail />
                                            </Suspense>
                                        ),
                                        loader: (centerID) =>
                                            import("./pages/manage/company/centerComponents/MngCenterDetail").then((module) => module.loader(centerID)),
                                    },
                                    {
                                        path: "update/:centerID",
                                        element: (
                                            <Suspense fallback={<p>Loading...</p>}>
                                                <MngCenterModify />
                                            </Suspense>
                                        ),
                                        loader: (centerID) =>
                                            import("./pages/manage/company/centerComponents/MngCenterModify").then((module) => module.loader(centerID)),
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: "event",
                        element: <MngEventRootLayout />,
                        children: [
                            {
                                index: true,
                                element: <Navigate to="list" replace />,
                            },
                            { path: "list", children : [
                                {   
                                    index: true,
                                    element: <MngEvent />,
                                    loader: () => import("./pages/manage/event/MngEvent")
                                        .then((module) =>module.loader()),
                                },
                                {   
                                    path: ":eventId",
                                    element: <MngEventDetail />,
                                    loader: (eventId) => import("./pages/manage/event/components/MngEventDetail")
                                        .then((module) =>module.loader(eventId)),
                                },
                                {   
                                    path: "register",
                                    element: <MngEventRegister />,
                                }
                            ]},
                            { path: "winner", children: [
                                {
                                    index : true,
                                    element : <MngWinner />,
                                    loader: () => import("./pages/manage/event/MngWinner")
                                        .then((module) =>module.loader()),
                                },
                                {   
                                    path: ":eventId",
                                    element: <MngWinnerDetail />,
                                    loader: (eventId) => import("./pages/manage/event/components/MngWinnerDetail")
                                        .then((module) =>module.loader(eventId)),
                                },
                            ]
                            },
                            { path: "result", element: <MngEventResult />, 
                                loader: () => import("./pages/manage/event/MngEventResult").then((module) =>module.loader())
                            },
                        ],
                    },
                    {
                        path: "site",
                        element: <MngSiteRootLayout />,
                        children: [
                            {
                                index: true,
                                element: <Navigate to="main" replace />,
                            },
                            {
                                path: "main",
                                children: [
                                    {
                                        index: true,
                                        element: (
                                            <Suspense fallback={<p>Loading...</p>}>
                                                <MngSiteTopLine />
                                            </Suspense>
                                        ),
                                        loader: () => import("./pages/manage/site/MngSiteTopLine").then((module) => module.loader()),
                                    },
                                    {
                                        path: "create",
                                        element: <MngSiteTopLineCreate />,
                                    },
                                    {
                                        path: ":topLineID",
                                        element: (
                                            <Suspense fallback={<p>Loading...</p>}>
                                                <MngSiteTopLineDetail />
                                            </Suspense>
                                        ),
                                        loader: (termsId) =>
                                            import("./pages/manage/site/topLineComponents/MngSiteTopLineDetail").then((module) => module.loader(termsId)),
                                    },
                                    {
                                        path: "update/:topLineID",
                                        element: (
                                            <Suspense fallback={<p>Loading...</p>}>
                                                <MngSiteTopLineModify />
                                            </Suspense>
                                        ),
                                        loader: (termsId) =>
                                            import("./pages/manage/site/topLineComponents/MngSiteTopLineModify").then((module) => module.loader(termsId)),
                                    },
                                ],
                            },
                            {
                                path: "banner",
                                children: [
                                    {
                                        index: true,
                                        element: <MngBannerPage />,
                                        loader: () => import("./pages/manage/site/MngBanner").then((module) => module.loader()),
                                    },
                                    {
                                        path: ":bannerId",
                                        element: <MngBannerDetail />,
                                        loader: (bannerId) =>
                                            import("./pages/manage/site/bannerComponents/MngBannerDetail").then((module) => module.loader(bannerId)),
                                    },
                                ],
                            },
                            {
                                path: "recommend",
                                children: [
                                    {
                                        index: true,
                                        element: (
                                            <Suspense fallback={<p>Loading...</p>}>
                                                <MngRecommendProduct />
                                            </Suspense>
                                        ),
                                        loader: () => import("./pages/manage/site/MngRecommendProduct").then((module) => module.loader()),
                                    },
                                    {
                                        path: "create",
                                        element: <MngRecommendProductCreate />,
                                    },
                                    {
                                        path: ":recommendID",
                                        element: (
                                            <Suspense fallback={<p>Loading...</p>}>
                                                <MngRecommendProductDetail />
                                            </Suspense>
                                        ),
                                        loader: (recommendID) =>
                                            import("./pages/manage/site/recommendProductComponents/MngRecommendProductDetail").then((module) =>
                                                module.loader(recommendID)
                                            ),
                                    },
                                    {
                                        path: "update/:recommendID",
                                        element: (
                                            <Suspense fallback={<p>Loading...</p>}>
                                                <MngRecommendProductModify />
                                            </Suspense>
                                        ),
                                        loader: (recommendID) =>
                                            import("./pages/manage/site/recommendProductComponents/MngRecommendProductModify").then((module) =>
                                                module.loader(recommendID)
                                            ),
                                    },
                                ],
                            },
                            {
                                path: "kegel",
                                children: [
                                    {
                                        index: true,
                                        element: (
                                            <Suspense fallback={<p>Loading...</p>}>
                                                <MngKegelContents />
                                            </Suspense>
                                        ),
                                        loader: () => import("./pages/manage/site/MngKegelContents").then((module) => module.loader()),
                                    },
                                    {
                                        path: "create",
                                        element: <MngKegelContentsCreate />,
                                    },
                                    {
                                        path: ":kegelID",
                                        element: (
                                            <Suspense fallback={<p>Loading...</p>}>
                                                <MngKegelContentsDetail />
                                            </Suspense>
                                        ),
                                        loader: (kegelID) =>
                                            import("./pages/manage/site/kegelComponents/MngKegelContentsDetail").then((module) => module.loader(kegelID)),
                                    },
                                    {
                                        path: "update/:kegelID",
                                        element: (
                                            <Suspense fallback={<p>Loading...</p>}>
                                                <MngKegelContentsModify />
                                            </Suspense>
                                        ),
                                        loader: (kegelID) =>
                                            import("./pages/manage/site/kegelComponents/MngKegelContentsModify").then((module) => module.loader(kegelID)),
                                    },
                                ],
                            },
                            {
                                path: "catalog",
                                children: [
                                    {
                                        index: true,
                                        element: (
                                            <Suspense fallback={<p>Loading...</p>}>
                                                <MngCatalogue />
                                            </Suspense>
                                        ),
                                        loader: () => import("./pages/manage/site/MngCatalogue").then((module) => module.loader()),
                                    },
                                    {
                                        path: "create",
                                        element: <MngCatalogueCreate />,
                                    },
                                    {
                                        path: ":catalogId",
                                        element: (
                                            <Suspense fallback={<p>Loading...</p>}>
                                                <MngCatalogueDetail />
                                            </Suspense>
                                        ),
                                        loader: (catalogId) =>
                                            import("./pages/manage/site/catalogueComponents/MngCatalogueDetail").then((module) => module.loader(catalogId)),
                                    },
                                    {
                                        path: "update/:catalogId",
                                        element: (
                                            <Suspense fallback={<p>Loading...</p>}>
                                                <MngCatalogueModify />
                                            </Suspense>
                                        ),
                                        loader: (catalogId) =>
                                            import("./pages/manage/site/catalogueComponents/MngCatalogueModify").then((module) => module.loader(catalogId)),
                                    },
                                ],
                            },
                            {
                                path: "popup",
                                children: [
                                    {
                                        index: true,
                                        element: (
                                            <Suspense fallback={<p>Loading...</p>}>
                                                <MngPopupPage />
                                            </Suspense>
                                        ),
                                        loader: () => import("./pages/manage/site/MngPopup").then((module) => module.loader()),
                                    },
                                    {
                                        path: "create",
                                        element: <MngPopupCreate />,
                                    },
                                    {
                                        path: ":popupId",
                                        element: (
                                            <Suspense fallback={<p>Loading...</p>}>
                                                <MngPopupDetail />
                                            </Suspense>
                                        ),
                                        loader: (termsId) =>
                                            import("./pages/manage/site/popupComponents/MngPopupDetail").then((module) => module.loader(termsId)),
                                    },
                                    {
                                        path: "update/:popupId",
                                        element: (
                                            <Suspense fallback={<p>Loading...</p>}>
                                                <MngPopupModify />
                                            </Suspense>
                                        ),
                                        loader: (termsId) =>
                                            import("./pages/manage/site/popupComponents/MngPopupModify").then((module) => module.loader(termsId)),
                                    },
                                ],
                            },
                            {
                                path: "terms",
                                children: [
                                    {
                                        index: true,
                                        element: (
                                            <Suspense fallback={<p>Loading...</p>}>
                                                <MngTermsPage />
                                            </Suspense>
                                        ),
                                        loader: () => import("./pages/manage/site/MngTerms").then((module) => module.loader()),
                                    },
                                    {
                                        path: "create",
                                        element: <MngTermsCreate />,
                                    },
                                    {
                                        path: ":termsId",
                                        element: (
                                            <Suspense fallback={<p>Loading...</p>}>
                                                <MngTermsDetail />
                                            </Suspense>
                                        ),
                                        loader: (termsId) =>
                                            import("./pages/manage/site/termsComponents/MngTermsDetail").then((module) => module.loader(termsId)),
                                    },
                                    {
                                        path: "update/:termsId",
                                        element: (
                                            <Suspense fallback={<p>Loading...</p>}>
                                                <MngTermsModify />
                                            </Suspense>
                                        ),
                                        loader: (termsId) =>
                                            import("./pages/manage/site/termsComponents/MngTermsModify").then((module) => module.loader(termsId)),
                                    },
                                ],
                            },
                            {
                                path: "system",
                                children: [
                                    {
                                        index: true,
                                        element: (
                                            <Suspense fallback={<p>Loading...</p>}>
                                                <MngSystemPage />
                                            </Suspense>
                                        ),
                                        loader: () => import("./pages/manage/site/MngSystem").then((module) => module.loader()),
                                    },
                                    {
                                        path: "create",
                                        element: <MngSystemCreate />,
                                    },
                                    {
                                        path: ":systemId",
                                        element: (
                                            <Suspense fallback={<p>Loading...</p>}>
                                                <MngSystemDetail />
                                            </Suspense>
                                        ),
                                        loader: (systemId) =>
                                            import("./pages/manage/site/systemComponents/MngSystemDetail").then((module) => module.loader(systemId)),
                                    },
                                    {
                                        path: "update/:systemId",
                                        element: (
                                            <Suspense fallback={<p>Loading...</p>}>
                                                <MngSystemModify />
                                            </Suspense>
                                        ),
                                        loader: (systemId) =>
                                            import("./pages/manage/site/systemComponents/MngSystemModify").then((module) => module.loader(systemId)),
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: "statistics",
                        element: <MngStatisticsRootLayout />,
                        children: [
                            {
                                index: true,
                                element: <Navigate to="list" replace />,
                            },
                            {
                                path: "list",
                                element: <MngTournamentStatisticsPage />,
                            },
                        ],
                    },
                    {
                        path: "users",
                        element: <MngUserRootLayout />,
                        children: [
                            {
                                index: true,
                                element: <Navigate to="list" replace />,
                            },
                            {
                                path: "list",
                                children: [
                                    {
                                        index: true,
                                        element: (
                                            <Suspense fallback={<p>Loading...</p>}>
                                                <MngUserListPage />
                                            </Suspense>
                                        ),
                                        loader: () => import("./pages/manage/user/MngUserList").then((module) => module.loader()),
                                    },
                                    {
                                        path: "register",
                                        element: (
                                            <Suspense fallback={<p>Loading...</p>}>
                                                <MngUserRegisterForm />
                                            </Suspense>
                                        ),
                                    },
                                    {
                                        path: ":memberID/:memberState",
                                        element: (
                                            <Suspense fallback={<p>Loading...</p>}>
                                                <MngUserDetail />
                                            </Suspense>
                                        ),
                                        loader: (memberID, memberState) =>
                                            import("./pages/manage/user/components/MngUserDetail").then((module) => module.loader(memberID, memberState)),
                                    },
                                ],
                            },
                            {
                                path: "info",
                                children: [
                                    {
                                        index: true,
                                        element: <MngUserInfoPage />,
                                        loader: () => import("./pages/manage/user/MngUserInfo").then((module) => module.loader()),
                                    },
                                    {
                                        path: ":infoId",
                                        element: <MngUserInfoDetail />,
                                        loader: (infoId) => import("./pages/manage/user/components/MngUserInfoDetail").then((module) => module.loader(infoId)),
                                    },
                                ],
                            },
                            {
                                path: "writtenAccept",
                                children: [
                                    {
                                        index: true,
                                        element: (
                                            <Suspense fallback={<p>Loading...</p>}>
                                                <MngUserWrittenAccept />
                                            </Suspense>
                                        ),
                                        loader: () => import("./pages/manage/user/MngUserWrittenAccept").then((module) => module.loader()),
                                    },
                                    {
                                        path: ":docMemberId",
                                        element: (
                                            <Suspense fallback={<p>Loading...</p>}>
                                                <MngUserWrittenAcceptDetail />
                                            </Suspense>
                                        ),
                                        loader: (docMemberId) =>
                                            import("./pages/manage/user/components/MngUserWrittenAcceptDetail").then((module) => module.loader(docMemberId)),
                                    },
                                ],
                            },
                            {
                                path: "terms",
                                children: [
                                    {
                                        index: true,
                                        element: (
                                            <Suspense fallback={<p>Loading...</p>}>
                                                <MngUserTerms />
                                            </Suspense>
                                        ),
                                        loader: () => import("./pages/manage/user/MngUserTerms").then((module) => module.loader()),
                                    },
                                    {
                                        path: "create",
                                        element: <MngUserTermsCreate />,
                                    },
                                    {
                                        path: ":termsId",
                                        element: (
                                            <Suspense fallback={<p>Loading...</p>}>
                                                <MngUserTermsDetail />
                                            </Suspense>
                                        ),
                                        loader: (termsId) =>
                                            import("./pages/manage/user/userTermsComponents/MngUserTermsDetail").then((module) => module.loader(termsId)),
                                    },
                                    {
                                        path: "update/:termsId",
                                        element: (
                                            <Suspense fallback={<p>Loading...</p>}>
                                                <MngUserTermsModify />
                                            </Suspense>
                                        ),
                                        loader: (termsId) =>
                                            import("./pages/manage/user/userTermsComponents/MngUserTermsModify").then((module) => module.loader(termsId)),
                                    },
                                ],
                            },
                            {
                                path: "adminAccount",
                                children: [
                                    {
                                        index: true,
                                        element: (
                                            <Suspense
                                                fallback={<p>Loading...</p>}
                                            >
                                                <MngAdminAccountView />
                                            </Suspense>
                                        ),
                                        loader: () =>
                                            import(
                                                "./pages/manage/user/MngAdminAccount/MngAdminAccountView"
                                            ).then((module) => module.loader()),
                                    },
                                    {
                                        path: "upload",
                                        element: <MngAdminAccountCreate />,
                                    },
                                    {
                                        path: ":adminId",
                                        element: (
                                            <Suspense
                                                fallback={<p>Loading...</p>}
                                            >
                                                <MngAdminAccountDetail />
                                            </Suspense>
                                        ),
                                        loader: (adminId) =>
                                            import(
                                                "./pages/manage/user/MngAdminAccount/MngAdminAccountDetail"
                                            ).then((module) =>
                                                module.loader(adminId)
                                            ),
                                    },
                                    {
                                        path: "update/:adminId",
                                        element: (
                                            <Suspense
                                                fallback={<p>Loading...</p>}
                                            >
                                                <MngAdminAccountUpdate />
                                            </Suspense>
                                        ),
                                        loader: (adminId) =>
                                            import(
                                                "./pages/manage/user/MngAdminAccount/MngAdminAccountUpdate"
                                            ).then((module) =>
                                                module.loader(adminId)
                                            ),
                                    },
                                ],
                            },
                            {
                                path: "withTournament",
                                children: [
                                    {
                                        index: true,
                                        element: (
                                            <Suspense fallback={<p>Loading...</p>}>
                                                <MngUserWithTournamentPage />
                                            </Suspense>
                                        ),
                                        loader: () => import("./pages/manage/user/MngUserWithTournament").then((module) => module.loader()),
                                    },
                                    {
                                        path: ":memberId",
                                        element: (
                                            <Suspense fallback={<p>Loading...</p>}>
                                                <MngUserWithTournamentDetail />
                                            </Suspense>
                                        ),
                                        loader: (memberId) =>
                                            import("./pages/manage/user/components/MngUserWithTournamentDetail").then((module) => module.loader(memberId)),
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        ],
    },
    
    // ↓ ↓ adminLogin 화면 확인을 위해 퍼블단에서 작성
    {
        path: "/adminLogin",
        children: [{ index: true, element: <AdminSignIn /> }],
    },
    {
        path: "/inicisOk",
        children: [{ index: true, element: <OkInicis /> }],
    },
    {
        path: "/inicisError",
        children: [{ index: true, element: <ErrorInicis /> }],
    },
    {
        path: "/inicisClose",
        children: [{ index: true, element: <CloseInicis /> }],
    },
]);

function App() {
    return (
        <JwtTokenProvider>
            <AxiosInterceptor>
            <Provider store={store}>
                <RouterProvider router={router}></RouterProvider>
                {window.location.pathname === "/" && !isMobile && (
                    <SystemModalPortal>
                        <SystemPopupModal />
                    </SystemModalPortal>
                )}
        </Provider>
            </AxiosInterceptor>
        </JwtTokenProvider>
    );
}

export default App;
