import classes from "../../MngCommon.module.css";
import Button from "../../../../components/UI/Button/Button";
import {
    Link,
    useLoaderData,
    useLocation,
    useNavigate,
} from "react-router-dom";
import { ReqDeleteBowlingAlley } from "../../../../api/tournament/BowlingAlleyApi";
import { useEffect, useState } from "react";
import Alert from "../../../../components/UI/Modal/Alert";
import instance from "../../../../store/AxiosInterceptor";

function MngTournamentBowlingAlleyDetail() {
    const location = useLocation();
    const navigate = useNavigate();
    const bowlingAlleyData = useLoaderData();
    const [showConfirm, setShowConfirm] = useState(false);

    const handleConfirm = async () => {
        setShowConfirm(false);

        const response = await ReqDeleteBowlingAlley(
            bowlingAlleyData.center_id
        );
        if (response.status === 200) {
            navigate("/admin/tournament/bowlingAlley", {
                state: location.state,
            });
        } else {
            alert(`볼링장 삭제 요청 실패 (${response.status})`);
        }
    };

    const deleteBowlingAlley = () => {
        setShowConfirm(true);
    };
    useEffect(() => {
        const backHandler = () => {
            navigate("/admin/tournament/bowlingAlley", {
                state: location.state,
            });
        };
        window.addEventListener("popstate", backHandler);
        return () => {
            window.removeEventListener("popstate", backHandler);
        };
    }, []);
    const bodyData = (
        <>
            <tr>
                <th>등록일</th>
                <td colSpan={2}>{bowlingAlleyData.regDate}</td>
                <th>등록자</th>
                <td>{bowlingAlleyData.regID}</td>
            </tr>
            <tr>
                <th>지역</th>
                <td colSpan={4}>{bowlingAlleyData.region}</td>
            </tr>
            <tr>
                <th>볼링장명</th>
                <td colSpan={2}>{bowlingAlleyData.name}</td>
                <th>볼링장ID</th>
                <td>{bowlingAlleyData.center_id}</td>
            </tr>
            <tr>
                <th rowSpan={3}>레인</th>
                <th>레인 수</th>
                <td colSpan={3}>{bowlingAlleyData.use_lane_count}</td>
            </tr>
            <tr>
                <th>사용레인</th>
                <td colSpan={3}>
                    {bowlingAlleyData.start_lane_number} 레인 ~{" "}
                    {bowlingAlleyData.end_lane_number} 레인
                </td>
            </tr>
            <tr>
                <th>예비레인 사용유무</th>
                <td colSpan={3}>
                    {bowlingAlleyData.use_reserv_lane === "Y"
                        ? "사용"
                        : "미사용"}
                </td>
            </tr>
            <tr>
                <th rowSpan={2}>주소</th>
                <td colSpan={4}>{bowlingAlleyData.default_address}</td>
            </tr>
            <tr>
                <td colSpan={4}>{bowlingAlleyData.detailed_address}</td>
            </tr>
            <tr>
                <th>전화번호</th>
                <td colSpan={4}>{bowlingAlleyData.phoneNo}</td>
            </tr>
        </>
    );

    return (
        <section>
            <div className={classes.admin_contents}>
                <h3>볼링장 상세</h3>
                <div
                    className={`${classes.contents_wrap} ${classes.search_wrap}`}
                >
                    <div className={classes.admin_table_wrap}>
                        <table>
                            <colgroup>
                                <col style={{ width: "159px" }} />
                                <col style={{ width: "159px" }} />
                                <col style={{ width: "460px" }} />
                                <col style={{ width: "159px" }} />
                                <col style={{ width: "620px" }} />
                            </colgroup>
                            <tbody>{bodyData}</tbody>
                        </table>
                        <div className={classes.bottom_btn_wrap}>
                            <Link
                                to={`/admin/tournament/bowlingAlley/update/${bowlingAlleyData.center_id}`}
                                state={location.state}
                            >
                                <Button className="border sm">수정</Button>
                            </Link>
                            <Button className="sm" onClick={deleteBowlingAlley}>
                                삭제
                            </Button>
                            <Link
                                to="/admin/tournament/bowlingAlley"
                                state={location.state}
                            >
                                <Button className="secondary sm">목록</Button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            {showConfirm && (
                <Alert
                    type="confirm"
                    confirm="확인"
                    cancel="취소"
                    close={() => setShowConfirm(false)}
                    confirmHandler={handleConfirm}
                >
                    볼링장을 삭제하시겠습니까?
                </Alert>
            )}
        </section>
    );
}
export default MngTournamentBowlingAlleyDetail;

export const loader = async ({ params }) => {
    try {
        const response = await instance.get(
            `/api/v1/admin/contest/center/${params.center_id}`,
            {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            }
        );

        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error("Failed to fetch data");
        }
    } catch (error) {
        console.error("Error fetching data:", error);
        return null;
    }
};
