import React from "react";
import classes from "./UserHome.module.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useContext, useRef, useState } from "react";
import FormSignIn from "../components/auth/FormSignIn";
import Button from "../components/UI/Button/Button";
import { JwtTokenContext } from "../store/JwtTokenProvider";
import { ReqSignOut } from "../api/auth/AuthApi";
import { Link, useNavigate } from "react-router-dom";
import {
    ReqBannerList,
    ReqKegelHomeList,
    ReqNoticeHomeList,
    ReqOngoingTournamentList,
    ReqProductCatalogueHomeList,
    ReqRecommendProductHomeList,
    ReqResultList,
} from "../api/home/TournamentHomepageApi";
import { useEffect } from "react";
import HomeBanner from "./components/HomeBanner";
import OngoingTournamentUser from "./tournament/components/OngoingTournamentUser";
import { PopupModal } from "../PopupModal";
import Checkbox from "../components/UI/Input/Checkbox";
import { ReqTopBanner } from "../api/home/TopBannerApi";

function UserHomePage(props) {
    const loginCtx = useContext(JwtTokenContext);
    const navigate = useNavigate();
    const isUserLogin = loginCtx.isLogin()
        ? loginCtx.isAdmin()
            ? false
            : true
        : false;
    const userName = loginCtx.decodedToken
        ? loginCtx.decodedToken.mname
            ? loginCtx.decodedToken.mname
            : loginCtx.decodedToken.jti
        : "";
    // console.log("UserHomePage", userName, loginCtx.decodedToken);
    /* <TestEditor/> */
    /*<PostCreate height={500} width={800}/>*/
    /*PostCreate 컴포넌트 로드 시 파라미터 height 및 width에 숫자 입력*/
    // 메인 슬라이드
    const [slideIndex, setSlideIndex] = useState(0);
    const [updateCount, setUpdateCount] = useState(0);
    const sliderRef = useRef(null);
    const sliderSettings = {
        dots: true,
        lazyLoad: true,
        infinite: true,
        speed: 300,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        afterChange: () => setUpdateCount(updateCount + 1),
        beforeChange: (current, next) => setSlideIndex(next),
    };
    // 추천상품
    const [slideIndex_3, setSlideIndex_3] = useState(0);
    const [updateCount_3, setUpdateCount_3] = useState(0);
    const sliderRef_3 = useRef(null);
    const sliderSettings_3 = {
        lazyLoad: true,
        infinite: true,
        speed: 300,
        slidesToShow: 3,
        // slidesToScroll: 1,
        arrows: true,
        autoplay: false,
        afterChange: () => setUpdateCount_2(updateCount_2 + 1),
        beforeChange: (current, next) => setSlideIndex_2(next),
    };

    // 대회결과 슬라이드
    const [arr, setArr] = useState();
    const [slideIndex_2, setSlideIndex_2] = useState(0);
    const [updateCount_2, setUpdateCount_2] = useState(0);
    const sliderRef_2 = useRef(null);
    const sliderSettings_2 = {
        lazyLoad: true,
        infinite: arr > 4,
        speed: 300,
        slidesToShow: 4,
        // slidesToScroll: 1,
        arrows: true,
        autoplay: false,
        afterChange: () => setUpdateCount_2(updateCount_2 + 1),
        beforeChange: (current, next) => setSlideIndex_2(next),
        responsive: [
            {
                breakpoint: 1360,
                settings: {
                    arrows: false,
                },
            },
        ],
    };

    const signOutHandler = async () => {
        await ReqSignOut(
            loginCtx,
            () => {
                loginCtx.removeToken();
                navigate("/");
            },
            () => {
                loginCtx.removeToken();
                navigate("/");
            }
        );
    };

    const btnMyPageHandler = () => {
        navigate("/myPage");
    };

    const csHandler = () => {
        navigate("/myPage/contact/help");
    };

    const asHandler = () => {
        navigate("/myPage/contact/as");
    };

    const contestApplyHandler = () => {
        navigate("/tournament/apply");
    };

    const contestResultHandler = () => {
        navigate("/tournament/result");
    };

    /// >>> 노출로 설정된 대회 메인 배너만 출력
    const [banners, setBanners] = useState([]);
    const [kegels, setKegels] = useState([]);
    const [notices, setNotices] = useState([]);
    const [resultList, setResultList] = useState([]);
    const [ongoingTournament, setOngoingTournament] = useState([]);
    const [recommendProduct, setrecommendProduct] = useState([]);
    const [productCatalogue, setProductCatalogue] = useState([]);

 // 띠 배너[START] ********************************

const [topBanner, setTopBanner] = useState(""); // 띠 배너
const [hideTopBannerToday, setHideTopBannerToday] = useState(false); // 오늘 하루 보지 않기 상태

const topBannerHandler = () => {
    ReqTopBanner(
        (bannerData) => {
            setTopBanner(bannerData);
            checkAndHideTopBanner(bannerData.id);
        },
        (error) => {
            console.error("데이터를 불러오는 중 오류 발생:", error);
        }
    );
};

const checkAndHideTopBanner = (id) => {
    const storedHideBanner = localStorage.getItem(`hideTopBannerToday_${id}`);
    if (storedHideBanner) {
        const currentDate = new Date();
        const hideUntilDate = new Date(storedHideBanner);

        // 저장된 날짜(localStorage의 expireDate)가 오늘 이전이면 띠 배너를 다시 표시하고 localStorage 초기화
        if (currentDate > hideUntilDate) {
            setHideTopBannerToday(false);
            localStorage.removeItem(`hideTopBannerToday_${id}`);
        } else {
            setHideTopBannerToday(true);
        }
    }
};

// 띠 배너 체크박스 클릭
const hideTopBannerForToday = (id) => {
    // "오늘 하루 보지 않기" 선택 시, 현재 날짜의 23시 59분 59초로 설정
    const expireDate = new Date();
    expireDate.setHours(23, 59, 59, 999);
    // expireDate.setHours(8, 30, 59, 999);
    localStorage.setItem(`hideTopBannerToday_${id}`, expireDate);
    setHideTopBannerToday(true);
};

// 띠 배너 링크 핸들러
const topBannerLinkHandler = () => {
    if (topBanner && topBanner.newTap) { 
        // 새 창으로 열기
        const endPoint = ".com";
        const lastIndex = topBanner.url.indexOf(endPoint);
        const uri = topBanner.url.substring(lastIndex+4);
        const newUrl = window.location.origin + uri;

        window.open(newUrl, "_blank");
    } else if (topBanner) { 
        // 현재 창에서 열기
        const endPoint = ".com";
        const lastIndex = topBanner.url.indexOf(endPoint);
        const uri = topBanner.url.substring(lastIndex+4);
        const newUrl = window.location.origin + uri;

        navigate(newUrl);
    }
};

// 띠 배너 렌더링 로직
const renderTopBanner = () => {
    if (!hideTopBannerToday && topBanner) {
        return (
            <div className={classes.band_banner}>
                <h4 onClick={topBannerLinkHandler}>{topBanner.title}</h4>
                <p onClick={topBannerLinkHandler}>{topBanner.subTitle}</p>
                <div className={classes.today_close_wrap}>
                    <Checkbox
                        className="white"
                        id="bandBanner"
                        title="오늘하루 보지않기"
                        value="오늘하루 보지않기"
                        onChange={() => hideTopBannerForToday(topBanner.id)} // ID 값 전달
                    />
                    <Button
                        className="close_x"
                        onClick={() => setTopBanner(false)}
                    />
                </div>
            </div>
        );
    }
    return null;
};

// 띠 배너[END] ********************************

    const bannerHandler = () => {
        ReqBannerList(
            "home",
            (result) => {
                const curData = result.map((data) => {
                    return {
                        id: data.id,
                        title: data.title,
                        priority: data.priority,
                        exposeYn: data.expose_yn,
                        launchNewPage: data.launch_new_page,
                        linkUrl: data.link_url,
                        mobileLinkUrl: data.link_url_mobile,
                        fileUri: data.file_uri,
                        mobileFileUri: data.mobile_file_uri,
                    };
                });
                setBanners(curData);
            },
            (error_result) => {
                setBanners((prev) => prev);
            }
        );
    };
    /// <<< 노출로 설정된 대회 메인 배너만 출력

    // >>> 진행중인 대회
    const ongoingTournamentHandler = () => {
        ReqOngoingTournamentList(
            (result) => {
                const curData = result.map((data) => {
                    return {
                        contestId: data.contest_id,
                        placeId: data.place_id,
                        name: data.name,
                        type: data.type,
                        centerName: data.center_name,
                        regionName: data.region_name,
                        title: data.type_title,
                    };
                });
                setOngoingTournament(curData);
            },
            (eroor_result) => {
                setOngoingTournament((prev) => prev);
            }
        );
    };
    // <<< 진행중인 대회

    /// >>> 노출로 설정된 케겔 출력
    const kegelHandler = () => {
        ReqKegelHomeList(
            (result) => {
                const curData = result.datas.map((data) => {
                    return {
                        id: data.id,
                        title: data.title,
                        content: data.content,
                        imgUrl: data.imgUrl,
                        landingUrl: data.landingUrl,
                    };
                });
                setKegels(curData);
            },
            (error_result) => {
                setKegels((prev) => prev);
            }
        );
    };
    /// <<< 노출로 설정된 케겔 출력

    /// >>> 노출로 설정된 공지사항 출력
    const noticeHandler = () => {
        ReqNoticeHomeList(
            (result) => {
                const curData = result.datas.map((data) => {
                    return {
                        id: data.id,
                        title: data.title,
                        regDate: data.regDate,
                    };
                });
                setNotices(curData);
            },
            (error_result) => {
                setNotices((prev) => prev);
            }
        );
    };
    /// <<< 노출로 설정된 공지사항 출력

    const resultListHandler = () => {
        ReqResultList(
            (result) => {
                const curData = result.datas?.map((data) => {
                    return {
                        contestId: data.contest_id,
                        contestName: data.contest_name,
                        centerName: data.center_name,
                        region: data.region,
                        resultId: data.result_id,
                        fileUri: data.file_uri,
                    };
                });
                setResultList(curData);
                setArr(curData.length);
            },
            (error_result) => {
                setResultList((prev) => prev);
            }
        );
    };

    /// >>> 노출로 설정된 추천상품 출력
    const recommendProductHandler = () => {
        ReqRecommendProductHomeList(
            (result) => {
                const curData = result.data?.map((data) => {
                    return {
                        title: data.title,
                        tabSeq: data.tabSeq,
                        pageUrl: data.pageUrl,
                        itemList: data.itemList,
                    };
                });
                setrecommendProduct(curData);
                setProductStatus(curData[0].tabSeq)
                setArr(curData.length);
            },
            (error_result) => {
                setrecommendProduct((prev) => prev);
            }
        );
    };
    /// <<< 노출로 설정된 추천상품 출력


    /// >>> 노출로 설정된 카탈로그 출력
    const productCatalogueHandler = () => {
        ReqProductCatalogueHomeList(
            (result) => {
                const curData = result.data?.map((data) => {
                    return {
                        title: data.title,
                        attach_uri : data.attach_uri,
                        originalFileName : data.attachFile != null ? data.attachFile.originalFileName : null,
                    };
                });
                setProductCatalogue(curData);
                setArr(curData.length);
            },
            (error_result) => {
                setProductCatalogue((prev) => prev);
            }
        );
    };
    /// <<< 노출로 설정된 카탈로그 출력

    useEffect(() => {
        // 띠 배너 체크 및 숨김 로직 호출
        checkAndHideTopBanner();
        // 띠 배너를 가져오는 함수 호출
        topBannerHandler();
        bannerHandler();
        ongoingTournamentHandler();
        kegelHandler();
        noticeHandler();
        resultListHandler();
        recommendProductHandler();
        productCatalogueHandler();
    }, []);

    const handleStormButtonClick = (pdf) => {
        const link = document.createElement("a");
        link.href = pdf;
        link.target = "_blank"; // Open in a new tab
        link.rel = "noopener noreferrer";
        link.download = "Catalog.pdf"; // Name of the downloaded file

        link.click();
    };

    // 20230914 [A] 카탈로그 BO적재후 주석 해제
    // const handleStormButtonClick = async (pdf, originalFileName) => {
    //     try {
    //         const response = await axios.get(`${pdf}`, {
    //             responseType: "blob", // 파일을 Blob 형식으로 받기 위해 설정
    //         });

    //         const blob = new Blob([response.data], {
    //             type: response.headers["content-type"],
    //         });
    //         const url = window.URL.createObjectURL(blob);
    //         const link = document.createElement("a");
    //         link.href = url;
    //         link.download = originalFileName;
    //         link.click();
    //         window.URL.revokeObjectURL(url);
    //     } catch (error) {
    //         console.error("파일 다운로드 에러:", error);
    //     }
    // };

    const [productStatus, setProductStatus] = useState(0);
    const productStatusHandler = (status) => {
        setProductStatus(status);
    };
    const productNavigateHanler = (link) => {
        navigate(link);
    };

    const userId = localStorage.getItem("userId") || "";
    const userIsExist = !!userId;
    const [saveId, setSaveId] = useState(userIsExist);
    const saveIdHandler = () => {
        if (saveId) {
            localStorage.setItem("userId", userId);
        } else {
            localStorage.removeItem("userId");
        }
        setSaveId((prev) => !prev);
    };

    const moveToContestDetail = (link) => {
        navigate(link);
    };

    const contestResultPageHandler = (contestId) => {
        navigate(`/tournament/result/${contestId}`);
    };
    
    return (
        <section className={`container ${classes.official_main_wrap}`}>
            {/* 띠 배너 영역 */}
            <div>{renderTopBanner()}</div>
            {/* 띠 배너 영역 */}
            <div className={classes.official_main}>
                <article className={classes.visual_wrap}>
                    <div className={classes.official_main_slider}>
                        <Slider ref={sliderRef} {...sliderSettings}>
                            {banners.map((banner) => (
                                <HomeBanner
                                    key={banner.id}
                                    fileUri={banner.fileUri}
                                    mobileFileUri={banner.mobileFileUri}
                                    title={banner.title}
                                    launchNewPage={banner.launchNewPage}
                                    linkUrl={banner.linkUrl}
                                />
                            ))}
                        </Slider>
                    </div>
                </article>
                <article className={classes.login_wrap}>
                    <div>
                        <div className={classes.login}>
                            {/* 로그아웃 상태에서 ↓ 아래 컴포넌트  */}
                            {!isUserLogin && (
                                <>
                                    <FormSignIn saveId={saveId} />
                                    {/* <div className={classes.save_id}>
                                        <Checkbox
                                            className="official_st"
                                            id="chk_11"
                                            title="아이디 저장"
                                            checked={saveId === true}
                                            onChange={saveIdHandler}
                                        />
                                    </div> */}
                                </>
                            )}
                            {/* 로그인 상태에서 ↓ 아래 컴포넌트 */}
                            {isUserLogin && (
                                <div className={classes.welcome_wrap}>
                                    <h3>{userName}님 환영합니다.</h3>
                                    {/* <p className={classes.j_id}>
                                    JID : 025648001
                                    <span className={classes.tooltip}>
                                        툴팁 열기
                                    </span>
                                    <span className={classes.tooltip_content}>
                                        <b>JID란?</b>
                                        <span>
                                            진승 라이선스 번호로 진승 회원님들께
                                            제공되는 특별한 번호입니다.
                                        </span>
                                    </span>
                                </p> */}
                                    <div className={classes.btn_wrap}>
                                        <Button
                                            className="btn_text white"
                                            onClick={btnMyPageHandler}
                                        >
                                            마이페이지
                                        </Button>
                                        <Button
                                            className="btn_text white"
                                            onClick={signOutHandler}
                                        >
                                            로그아웃
                                        </Button>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className={classes.my_page_wrap}>
                            <p className={classes.cs} onClick={csHandler}>
                                1:1 문의
                            </p>
                            <p className={classes.as} onClick={asHandler}>
                                AS 신청
                            </p>
                        </div>
                    </div>
                </article>
            </div>
            
            <article className={classes.recommend_product_wrap}>
                <div className={classes.slider_wrap}>
                    <div className={classes.contents_wrap}>
                        <h2>
                            진승의 추천상품을
                            <br />
                            만나보세요
                        </h2>
                        <div className={classes.product_slider}>
                            {recommendProduct.length !== 0 && recommendProduct.map((recommend, index) => (
                                productStatus === recommend.tabSeq && (
                                    <Slider ref={sliderRef_3} {...sliderSettings_3} key={index}>
                                        {recommend.itemList.length !== 0 && recommend.itemList.map((data, itemIndex) => (
                                            <div className={classes.item} onClick={() => (window.location.href = data.pageUrl)} key={itemIndex}>
                                                <p>
                                                    <span>{recommend.title}</span>
                                                    {data.name}
                                                </p>
                                                <img src={data.imgUrl} alt={data.name} />
                                            </div>
                                        ))}
                                    </Slider>
                                )
                            ))} 
                            {/* {productStatus === "STORM" && (
                                <Slider ref={sliderRef_3} {...sliderSettings_3}>
                                    <div className={classes.item} onClick={() => productNavigateHanler("/balls/155")}>
                                        <p>
                                            <span>STORM</span>
                                            PHYSIX TOUR
                                        </p>
                                        <img src={"/api/v1/attach/user/20230829b36901206c4f45bd9ea9f58b66dd7066.jpg"} alt="PHYSIX TOUR" />
                                    </div>
                                    <div className={classes.item} onClick={() => productNavigateHanler("/balls/152")}>
                                        <p>
                                            <span>STORM</span>
                                            DOMINATION BURGUNDY
                                        </p>
                                        <img src={"/api/v1/attach/user/20230829a9261763a57b4c99abacbff2825e167f.jpg"} alt="DOMINATION BURGUNDY" />
                                    </div>
                                    <div className={classes.item} onClick={() => productNavigateHanler("/balls/314")}>
                                        <p>
                                            <span>STORM</span>
                                            REVENANT WAR
                                        </p>
                                        <img src={"/api/v1/attach/user/2023082914d9b7b0533a4ee2922125012805daa2.jpg"} alt="REVENANT WAR" />
                                    </div>
                                    <div className={classes.item} onClick={() => productNavigateHanler("/balls/244")}>
                                        <p>
                                            <span>STORM</span>
                                            SUMMIT
                                        </p>
                                        <img src={"/api/v1/attach/user/20230829b8ee0afb9add4f9ba071f1d18d277702.jpg"} alt="SUMMIT" />
                                    </div>
                                    <div className={classes.item} onClick={() => productNavigateHanler("/balls/150")}>
                                        <p>
                                            <span>STORM</span>
                                            DARK PHYSIX
                                        </p>
                                        <img src={"/api/v1/attach/user/202308293f35b07e647d43d2b8ba2dd08a1ba6a0.jpg"} alt="DARK PHYSIX" />
                                    </div>
                                </Slider>
                            )}
                            {productStatus === "ROTOGRIP" && (
                                <Slider ref={sliderRef_3} {...sliderSettings_3}>
                                    <div className={classes.item} onClick={() => productNavigateHanler("/balls/614")}>
                                        <p>
                                            <span>ROTO GRIP</span>
                                            TOUR DYNAM-X
                                        </p>
                                        <img src={"/api/v1/attach/user/20230829099b78b39fbd482ca71bdf1d79842a28.jpg"} alt="TOUR DYNAM-X" />
                                    </div>
                                    <div className={classes.item} onClick={() => productNavigateHanler("/balls/682")}>
                                        <p>
                                            <span>ROTO GRIP</span>
                                            HUSTLE RIP
                                        </p>
                                        <img src={"/api/v1/attach/user/20230829a67bfbe370c741db834caa5d7380eb81.jpg"} alt="HUSTLE RIP" />
                                    </div>
                                    <div className={classes.item} onClick={() => productNavigateHanler("/balls/568")}>
                                        <p>
                                            <span>ROTO GRIP</span>
                                            CLONE
                                        </p>
                                        <img src={"/api/v1/attach/user/20230829467cbf322003492aba7dd1db7be67e85.jpg"} alt="CLONE" />
                                    </div>
                                    <div className={classes.item} onClick={() => productNavigateHanler("/balls/613")}>
                                        <p>
                                            <span>ROTO GRIP</span>
                                            RUBICON ATTACK
                                        </p>
                                        <img src={"/api/v1/attach/user/20230829c2b9b190aa954c01b94610297d1942c0.jpg"} alt="RUBICON ATTACK" />
                                    </div>
                                    <div className={classes.item} onClick={() => productNavigateHanler("/balls/565")}>
                                        <p>
                                            <span>ROTO GRIP</span>
                                            ATTENTION SCARLET
                                        </p>
                                        <img src={"/api/v1/attach/user/20230829f9dcaa371c5b4e1a9c69271511cf3c3c.jpg"} alt="ATTENTION SCARLET" />
                                    </div>
                                </Slider>
                            )}
                            {productStatus === "NEW" && (
                                <Slider ref={sliderRef_3} {...sliderSettings_3}>
                                    <div className={classes.item} onClick={() => productNavigateHanler("/bags/842")}>
                                        <p>
                                            <span>NEW</span>
                                            ROTO GRIP ALL-STAR EDITION 3-BALL
                                            ROLLER
                                        </p>
                                        <img src={"/api/v1/attach/user/20230829c6a61f7708ef4cf19341612cf509480b.jpg"} alt="ROTO GRIP ALL-STAR EDITION 3-BALL ROLLER" />
                                    </div>
                                    <div className={classes.item} onClick={() => productNavigateHanler("/bags/841")}>
                                        <p>
                                            <span>NEW</span>
                                            ROTO GRIP ALL-STAR EDITION 2-BALL
                                            ROLLER
                                        </p>
                                        <img src={"/api/v1/attach/user/20230829206d687d239a45e1bb759b60067c63ec.jpg"} alt="ROTO GRIP ALL-STAR EDITION 2-BALL ROLLER" />
                                    </div>
                                    <div className={classes.item} onClick={() => productNavigateHanler("/bags/840")}>
                                        <p>
                                            <span>NEW</span>
                                            ROTO GRIP ALL-STAR EDITION 1-BALL
                                            SPARE KIT
                                        </p>
                                        <img src={"/api/v1/attach/user/20230829d2b660e27bf5484fb71ddfdf2b8067a4.jpg"} alt="ROTO GRIP ALL-STAR EDITION 1-BALL SPARE KIT" />
                                    </div>
                                    <div className={classes.item} onClick={() => productNavigateHanler("/products/1094")}>
                                        <p>
                                            <span>NEW</span>
                                            MASTER SKIN PATCH
                                        </p>
                                        <img src={"/api/v1/attach/user/20230829125e0adddd3f4e1a8258ba1a4a448be6.jpg"} alt="MASTER SKIN PATCH" />
                                    </div>
                                    <div className={classes.item} onClick={() => productNavigateHanler("/products/1124")}>
                                        <p>
                                            <span>NEW</span>
                                            스톰 썬더 클리너(티슈형)
                                        </p>
                                        <img src={"/api/v1/attach/user/20230829ea0d9b30da684c6981bdd046e024cb6b.png"} alt="스톰 썬더 클리너(티슈형)" />
                                    </div>
                                </Slider>
                            )} */}
                        </div>
                    </div>
                </div>
                <div className={classes.category_wrap}>
                    <div className={classes.contents_wrap}>
                        <ul>
                            {recommendProduct.length !== 0 && recommendProduct.map((data, index) => (
                                <li key={index} className={`${productStatus === data.tabSeq ? classes.on : ""}`} onClick={() => productStatusHandler(data.tabSeq)}>{data.title}</li>
                            ))} 
                            {/* <li
                                className={`${
                                    productStatus === "STORM" ? classes.on : ""
                                }`}
                                onClick={() => productStatusHandler("STORM")}
                            >
                                Storm
                            </li>
                            <li
                                className={`${
                                    productStatus === "ROTOGRIP"
                                        ? classes.on
                                        : ""
                                }`}
                                onClick={() => productStatusHandler("ROTOGRIP")}
                            >
                                Roto Grip
                            </li>
                            <li
                                className={`${
                                    productStatus === "NEW" ? classes.on : ""
                                }`}
                                onClick={() => productStatusHandler("NEW")}
                            >
                                New
                            </li> */}
                        </ul>
                    </div>
                </div>
            </article>
            <article className={classes.blue}>
                <div className={classes.contents_wrap}>
                    <h2>KEGEL</h2>
                    <div className={classes.kegel_wrap}>
                        {kegels.length !== 0 &&
                            kegels.map((data, index) => (
                                <div
                                    key={index}
                                    onClick={() =>
                                        (window.location.href = data.landingUrl)
                                    }
                                >
                                    <img src={data.imgUrl} alt="kegel 이미지" />
                                    <h3>{data.title}</h3>
                                    <p>{data.content}</p>
                                </div>
                            ))}
                    </div>
                </div>
            </article>
            {ongoingTournament.length !== 0 && (
                <article>
                    <div className={classes.contents_wrap}>
                        <h2>
                            진행중 대회
                            <Button
                                className="btn_text"
                                onClick={contestApplyHandler}
                            >
                                대회 바로가기
                            </Button>
                        </h2>
                        <ul className={classes.tournament_list}>
                            {ongoingTournament.map((data, index) => (
                                <OngoingTournamentUser
                                    onClick={() =>
                                        moveToContestDetail(
                                            `/tournament/apply/${data.contestId}/${data.placeId}`
                                        )
                                    }
                                    key={index}
                                    contestId={data.contestId}
                                    placeId={data.placeId}
                                    name={data.name}
                                    type={data.type}
                                    title={data.title}
                                    centerName={data.centerName}
                                    regionName={data.regionName}
                                />
                            ))}
                        </ul>
                    </div>
                </article>
            )}
            {/**대회 결과 주석 */}
            <article className={classes.blue}>
                <div className={classes.contents_wrap}>
                    <h2>
                        대회결과
                        <Button className="btn_text" onClick={contestResultHandler}>
                            대회결과 더보기
                        </Button>
                    </h2>
                    <div className={classes.contents_slider}>
                        <Slider ref={sliderRef_2} {...sliderSettings_2}>
                            {resultList.map((data, index) => (
                                <div className={classes.content} key={data.resultId}>
                                    <img src={`${data.fileUri}`} alt="대회 대표 이미지" onClick={() => contestResultPageHandler(data.contestId)}/>
                                    <span>
                                        {data.region}({data.centerName})
                                    </span>
                                    <p>{data.contestName}</p>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </article>
            <article>
                <div className={classes.contents_wrap}>
                    <div className={classes.catalog_wrap}>
                        <div className={classes.catalog_box}>
                            <h2>Catalog</h2>
                            <ul>
                                {/* 20230914 [A] 카탈로그 BO적재후 주석 해제
                                {productCatalogue.length !== 0 && productCatalogue.map((data, index) => (
                                    <li>
                                        <Button
                                            className="btn_text white lg"
                                            onClick={() => handleStormButtonClick(data.attach_uri, data.originalFileName)}
                                        >
                                            {data.title}
                                        </Button>
                                    </li>
                                ))} */}
                            
                                <li>
                                    <Button
                                        className="btn_text white lg"
                                        onClick={() =>
                                            handleStormButtonClick(
                                                "http://publicftp.stormbowling.com/2023_SPI_Catalog.pdf"
                                            )
                                        }
                                    >
                                        STORM
                                    </Button>
                                </li>
                                {/* <li>
                                    <Button className="btn_text white lg">ROTO GRIP</Button>
                                </li> */}
                                <li>
                                    <Button
                                        className="btn_text white lg"
                                        onClick={() =>
                                            handleStormButtonClick(
                                                "https://kegel.box.com/shared/static/ko5x3jqsbm1eausr128eckgl1bom84bd.pdf"
                                            )
                                        }
                                    >
                                        KEGEL
                                    </Button>
                                </li>
                            </ul>
                        </div>
                        <div className={classes.faq_box}>
                            <h2>FAQ</h2>
                            <div>
                                <Link to={`/media/faq`}>
                                    <Button className="btn_text white lg">
                                        자세히보기
                                    </Button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </article>
            <article className={classes.notice}>
                <div className={classes.contents_wrap}>
                    <div className={classes.notice_wrap}>
                        <h2>공지사항</h2>
                        <ul className={classes.list}>
                            {notices.map((data) => (
                                <Link to={`/media/notice/${data.id}`} key={data.id}>
                                    <li>
                                        <b>{data.title}</b>
                                        <span className={classes.date}>
                                            {data.regDate}
                                        </span>
                                    </li>
                                </Link>
                            ))}
                        </ul>
                    </div>
                </div>
            </article>
            <article className={classes.sns_article}>
                <div className={classes.contents_wrap}>
                    <div className={classes.sns_wrap}>
                        <h3>진승 SNS 채널 바로가기</h3>
                        <ul>
                            {/* <li className={classes.kakao} onClick={() => window.location.href = 'http://pf.kakao.com/_SbxkxcC'}>kakao</li>
                            <li className={classes.facebook} onClick={() => window.location.href = 'https://www.facebook.com/jinseung.co.ltd'}>facebook</li>
                            <li className={classes.youtube} onClick={() => window.location.href = 'https://www.youtube.com/@stormrotokorea'}>youtube</li> */}
                            <li className={classes.official_insta}>
                                <a
                                    href="https://www.instagram.com/stormrotokorea/"
                                    target="_blank"
                                >
                                    진승 인스타그램
                                </a>
                            </li>
                            <li className={classes.mall_insta}>
                                <a
                                    href="https://www.instagram.com/stormrotokorea_mall/"
                                    target="_blank"
                                >
                                    진승몰 인스타그램
                                </a>
                            </li>
                            <li className={classes.youtube}>
                                <a
                                    href="https://www.youtube.com/@stormrotokorea"
                                    target="_blank"
                                >
                                    진승 유튜브
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </article>
            <PopupModal isMobileCB={false} />
        </section>
    );
}

export default UserHomePage;
