import classes from "./TabMenu.module.css";

/**
 * 
 * @param {props.tabMenuArr} tabMenuArr 노출 목록 정보
 * @param {props.className} 
 * @param {props.onChange} Tab 선택시 변경되는 값
 * @param {props.showDefaultKey} 노출 목록에서 선택되어야 하는 값
 * 
 * @returns 
 */
function TabMenu({ tabMenuArr, className = "", onChange, showDefaultKey}) {

    const selectMenuHandler = (itemId) => {
        if (onChange) onChange(itemId);
    };

    const classNames = Array.isArray(className) ? className : className.split(" ");

    const combinedClasses = classNames
        .map((cn) => classes[cn])
        .filter((cn) => cn)
        .join(" ");

    return (
        <nav className={classes.tab_menu}>
            <ul className={combinedClasses}>
                {tabMenuArr.map((item) => (
                    <li
                        key={item.id}
                        className={item.id === showDefaultKey && classes.on}
                        onClick={() => selectMenuHandler(item.id)}
                    >
                        {item.title}
                    </li>
                ))}
            </ul>
        </nav>
    );
}

export default TabMenu;
