import { useEffect, useState } from "react";
import Radio from "../../../../components/UI/Input/Radio";
import classes from "../../MngCommon.module.css";
import { ReqTermsHistList } from "../../../../api/site/ApiForTerms";
import moment from "moment";
import MngUserTermsHistory from "./MngUserTermsHistory";

function MngUserTermsHistoryList(props) {

    const { idCB, typeCB } = props

    const [termsHist, setTermsHist] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const response = await ReqTermsHistList(typeCB, idCB);
            if (response.status === 200) {
                const resData = await response.data.datas;
                setTermsHist(resData);
            } else {
                setTermsHist([])
            }
        };
        fetchData();
    }, []);

    const [isModalOpen, setIsModalOpen] = useState(false);

    const [id, setId] = useState();

    const openModalHandler = (e, id) => {
      e.stopPropagation();
      setIsModalOpen(true);
      setId(id);  
    };

    const [exposeYnStatus, setExposeYnStatus ] = useState()

    const exposeYnRadioButton = (e, value, id) => {
        const exposeYnTermsHist = termsHist.map((data) => {
            if (data.id === id) {
              return { ...data, exposeYn: value };
            }
            return data;
        });
        setTermsHist(exposeYnTermsHist)

        setExposeYnStatus(e.target.value)
    }


    return (
        <section>
            <div className={`${classes.contents_wrap} `}>
                <h4>개정이력</h4>
                <div
                    className={`${classes.admin_table_wrap} ${classes.row_select}`}
                >
                    <table className="align_center align_left_col2">
                        <colgroup>
                            <col style={{ width: "10%" }} />
                            <col style={{ width: "50%" }} />
                            <col style={{ width: "15%" }} />
                            <col style={{ width: "13%" }} />
                            <col style={{ width: "12%" }} />
                        </colgroup>
                        <thead>
                            <tr>
                                <th>No</th>
                                <th>제목</th>
                                <th>등록자</th>
                                <th>개정일</th>
                                <th>노출여부</th>
                            </tr>
                        </thead>
                        <tbody>
                            {termsHist.length !== 0 && termsHist.map((data, index) => (
                                <tr>
                                    <td>{index+1}</td>
                                    <td onClick={(e) => openModalHandler(e, data.id)}>{data.title}</td>
                                    <td>{data.regID}</td>
                                    <td>{moment(data.regDate).format('YYYY-MM-DD')}</td>
                                    <td>{data.exposeYn ? "사용" : "미사용"}</td>
                                    {/* <td>
                                        <div className={classes.radio_wrap}>
                                        <Radio
                                            value="Y"
                                            title="사용"
                                            name={`rad_${data.id}`} // 고유한 이름으로 변경
                                            id={`rad_${data.id}_01`} // 고유한 ID로 변경
                                            checked={data.exposeYn === "Y"} 
                                            onChange={(e) => exposeYnRadioButton(e, "Y", data.id)} // 데이터의 ID를 매개변수로 전달
                                        />
                                        <Radio
                                            value="N"
                                            title="미사용"
                                            name={`rad_${data.id}`} // 고유한 이름으로 변경
                                            id={`rad_${data.id}_02`} // 고유한 ID로 변경
                                            checked={data.exposeYn === "N"} 
                                            onChange={(e) => exposeYnRadioButton(e, "N", data.id)} // 데이터의 ID를 매개변수로 전달
                                        />
                                        </div>
                                    </td> */}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                {isModalOpen && <MngUserTermsHistory isModalOpenCB={setIsModalOpen} idCB={id}/>}
            </div>
        </section>
    );
}

export default MngUserTermsHistoryList;
