import { useRef, useState } from "react";
import Button from "../../../../components/UI/Button/Button";
import classes from "../../MngCommon.module.css";
import Radio from "../../../../components/UI/Input/Radio";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { useLoaderData, useLocation, useNavigate } from "react-router-dom";
import { FileBoxForExcel } from "../../../../components/UI/Input/FileBox";
import MyDropzoneMj from "../../../../components/UI/DropZone/DropZone copy";
import * as XLSX from "xlsx";
import { CSVLink } from "react-csv";
import instance from "../../../../store/AxiosInterceptor";
import QuillEditor from "../../../../components/UI/Editer/QuillEditor";

function MngTournamentResultUpdate(props) {
    const result = useLoaderData();
    const navigate = useNavigate();

    const data = [];
    const headers = [
        "경기일자",
        "경기명",
        "이름",
        "ID",
        "총점",
        "핸디",
        "1G",
        "2G",
        "3G",
        "4G",
        "5G",
        "랭킹",
    ];

    const [resultList, setResultList] = useState(result);

    // const [file, setFile] = useState();
    // 노출 여부
    const [isExpose, setIsExpose] = useState(result.exposeYn);
    const isExposeChangeHandler = (e) => {
        setIsExpose(e.target.value);
    };
    const [excelRankingYn, setExcelRankingYn] = useState(
        result.excelRankingYn ?? "N"
    );
    const isExcelRankingChangeHandler = (e) => {
        setExcelRankingYn(e.target.value);
    };

    const [resultExcelYn, setResultExcelYn] = useState(result.resultExcelYn);

    const resultExcelYnChangeHandler = (e) => {
        setResultExcelYn(e.target.value);
    };

    const location = useLocation();
    const [excelFile, setExcelFile] = useState(result.uploadFile);

    const [points, setPoints] = useState();

    // 드랍드랍
    const [Images, setImages] = useState(result.images);
    const [ImagesDropDown, setImagesDropDown] = useState([]);

    // 내용
    const [content, setContent] = useState(result.content);

    const contentChangeHandler = (content) => {
        setContent(content);
    };

    // 목록 버튼
    const toList = () => {
        navigate("/admin/tournament/result", { state: location.state });
    };

    const handleExcelUpload = (file) => {
        setExcelFile(file);
        console.log(excelFile);

        const reader = new FileReader();

        reader.onload = function () {
            const fileData = reader.result;
            const wb = XLSX.read(fileData, { type: "binary" });
            wb.SheetNames.forEach(function (sheetName) {
                const rowObj = XLSX.utils.sheet_to_json(wb.Sheets[sheetName]);
                // console.log(JSON.stringify(rowObj));
                setPoints(rowObj);
            });
        };

        reader.readAsBinaryString(file);
    };

    const editHandler = async (e) => {
        e.preventDefault();

        const requestData = JSON.stringify({
            expose_yn: isExpose,
            images: Images,
            points: points,
            content: content,
            excel_ranking_yn: excelRankingYn,
            result_excel_yn: resultExcelYn,
        });

        const body = new Blob([requestData], { type: "application/json" });

        const formData = new FormData();
        formData.append("body", body);

        // if (file) {
        formData.append("result", excelFile);
        // }

        ImagesDropDown.forEach((image, index) => {
            formData.append("image", image);
        });

        try {
            const response = await instance.post(
                `/api/v1/admin/contest/result/${resultList.id}/report`,
                formData
            );

            if (response.status === 200) {
                navigate("/admin/tournament/result", { state: location.state });
            } else {
                alert("Update failed:", response.data);
            }
        } catch (error) {
            alert("Update error");
        }
    };

    const onHandleImageUpload = (acceptedFiles) => {
        setImages([...acceptedFiles]);
    };

    const onHandleImageUploadL = (acceptedFiles) => {
        setImagesDropDown(acceptedFiles);
    };

    return (
        <section>
            <div className={classes.admin_contents}>
                <h3>대회결과 - 수정</h3>
                <div
                    className={`${classes.contents_wrap} ${classes.search_wrap}`}
                >
                    <div className={classes.admin_table_wrap}>
                        <table>
                            <colgroup>
                                <col style={{ width: "159px" }} />
                                <col style={{ width: "620px" }} />
                                <col style={{ width: "159px" }} />
                                <col style={{ width: "620px" }} />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>등록일</th>
                                    <td>{result.regDate}</td>
                                    <th>등록자</th>
                                    <td>{result.regID}</td>
                                </tr>
                                <tr>
                                    <th>대회명</th>
                                    <td colSpan={3}>{result.title}</td>
                                </tr>
                                <tr>
                                    <th>지역</th>
                                    <td>{result.regionName}</td>
                                    <th>경기장</th>
                                    <td>{result.centerName}</td>
                                </tr>
                                <tr>
                                    <th>노출여부</th>
                                    <td colSpan={3}>
                                        <div className={classes.radio_wrap}>
                                            <Radio
                                                value="Y"
                                                title="노출"
                                                name="rad_01"
                                                id="rad_01_01"
                                                checked={isExpose === "Y"}
                                                onChange={isExposeChangeHandler}
                                            />
                                            <Radio
                                                value="N"
                                                title="미노출"
                                                name="rad_01"
                                                id="rad_01_02"
                                                checked={isExpose === "N"}
                                                onChange={isExposeChangeHandler}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>랭킹 적용</th>
                                    <td>
                                        <div className={classes.radio_wrap}>
                                            <Radio
                                                value="N"
                                                title="서버 적용(총점 내림차순으로 랭킹 적용)"
                                                name="rad_02"
                                                id="rad_02_01"
                                                checked={excelRankingYn === "N"}
                                                onChange={
                                                    isExcelRankingChangeHandler
                                                }
                                            />
                                            <Radio
                                                value="Y"
                                                title="엑셀 적용(엑셀에서 정의한 랭킹 적용)"
                                                name="rad_02"
                                                id="rad_02_02"
                                                checked={excelRankingYn === "Y"}
                                                onChange={
                                                    isExcelRankingChangeHandler
                                                }
                                            />
                                        </div>
                                    </td>
                                    <th>경기별 결과 적용</th>
                                    <td>
                                        <div className={classes.radio_wrap}>
                                            <Radio
                                                value="Y"
                                                title="적용"
                                                name="rad_03"
                                                id="rad_03_01"
                                                checked={resultExcelYn === "Y"}
                                                onChange={
                                                    resultExcelYnChangeHandler
                                                }
                                            />
                                            <Radio
                                                value="N"
                                                title="미적용"
                                                name="rad_03"
                                                id="rad_03_03"
                                                checked={resultExcelYn === "N"}
                                                onChange={
                                                    resultExcelYnChangeHandler
                                                }
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>대회 갤러리 이미지</th>
                                    <td colSpan={3}>
                                        <MyDropzoneMj
                                            images={Images}
                                            onImageUpload={onHandleImageUpload}
                                            onImageUploadL={
                                                onHandleImageUploadL
                                            }
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>내용</th>
                                    <td colSpan={3}>
                                        <div style={{ height: "500px" }}>
                                            <QuillEditor
                                                height="460"
                                                width="auto"
                                                value={content}
                                                onValueChangeCB={
                                                    contentChangeHandler
                                                }
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>경기별 결과</th>
                                    <td colSpan={3}>
                                        <div className={classes.flex_row}>
                                            <FileBoxForExcel
                                                onInputChangeCB={
                                                    handleExcelUpload
                                                }
                                                onInputDeleteCB={() => {}}
                                            />
                                            <CSVLink
                                                data={data}
                                                headers={headers}
                                                filename={"결과 업로드 양식"}
                                                forceText={true}
                                            >
                                                <Button className="primary download sm">
                                                    엑셀업로드 양식 다운받기
                                                </Button>
                                            </CSVLink>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className={classes.bottom_btn_wrap}>
                            <Button className="border sm" onClick={editHandler}>
                                수정
                            </Button>
                            <Button className="secondary sm" onClick={toList}>
                                목록
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default MngTournamentResultUpdate;

export const loader = async ({ params }) => {
    try {
        const response = await instance.get(
            `/api/v1/admin/contest/result/${params.result_id}`,
            {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            }
        );

        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error("Failed to fetch data");
        }
    } catch (error) {
        console.error("Error fetching data:", error);
        return null;
    }
};
