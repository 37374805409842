import classes from "./MyPageApp.module.css";
import Button from "../../components/UI/Button/Button";
import Input, { MultiInput } from "../../components/UI/Input/Input";
import { useEffect, useState } from "react";
import Radio from "../../components/UI/Input/Radio";
import { useNavigate } from "react-router-dom";
import { mypageCsDefaultBox, phoneNumberDefaultBox } from "../../components/definedefault/DefSelectBoxValues";
import { ReqAddBoardAs, ReqMemberInfo } from "../../api/mypage/UserMyPageApi";
import SelectBoxNew from "../../components/UI/SelectBox/SelectBoxNew";
import Modal from "../../components/UI/Modal/Modal";
import DaumPostcode from "react-daum-postcode";
import Alert from "../../components/UI/Modal/Alert";
import { JwtTokenContext } from "../../store/JwtTokenProvider";
import { useContext } from "react";
import { useMemStore } from "../../store/memstore/MemStore";
import QuillEditorForText from "../../components/UI/Editer/QuillEditorForText";
import DropZoneForAS from "../../components/UI/DropZone/DropZoneForAS";

function MyContactAsApp() {
    const setMemStore = useMemStore(false)[1];
    const [asSupportType, setAsSupportType] = useState("Ball");
    const [showCancelModal, setShowCancelModal] = useState(false);
    const loginCtx = useContext(JwtTokenContext);
    const token = loginCtx.getToken();
    const navigate = useNavigate();

    const supportTypeHandler = (e) => {
        setAsSupportType(e.target.value);
    };

    const topHandler = () => {
        window.scrollTo({ top: 0, behavior: "smooth" }); //상단 smooth 이동
    };

    const [input, setInput] = useState({
        title: "",
        as_detail_1: "",
        as_detail_2: "",
        as_detail_3: "",
        as_detail_4: "",
        as_detail_5: "",
    });

    const { title, as_detail_1, as_detail_2, as_detail_3, as_detail_4, as_detail_5 } = input;

    const [memberPhone, setMemberPhone] = useState("");

    const onChangeInput = (e) => {
        const { name, value } = e.target;
        setInput({
            ...input,
            [name]: value,
        });
    };

    const [content, setContent] = useState("");
    const contentHandler = (value) => {
        setContent(value);
    };

    const csHandler = () => {
        navigate("/myPage/contact/help");
    };

    //1:1문의 or A/S신청
    const csTypes = mypageCsDefaultBox;
    const [changeMode, setChangeMode] = useState(false);
    const [csType, setCsType] = useState("as");

    const changeModeHandler = (state) => {
        setChangeMode(state);
        setCsType("as");
    };

    const csTypeHandler = (value) => {
        setCsType(value);
        if (value === "cs") {
            changeModeHandler(true);
        }
    };

    const moveHandler = () => {
        csHandler();
    };

    // [START] 다음 주소 API =================================================================
    const [isDaumPostcodeOpen, setIsDaumPostcodeOpen] = useState(false);
    const [zipCode, setZipCode] = useState(""); //우편번호
    const [selectedAddress, setSelectedAddress] = useState(""); // 선택한 주소를 저장할 상태
    const [enteredAddress, setEnteredAddress] = useState(""); // 입력한 주소를 저장할 상태

    const openDaumPostcodeHandler = (e) => {
        e.stopPropagation();
        setIsDaumPostcodeOpen(true);
    };

    const handleAddressSelect = (data) => {
        const fullAddress = data.address; // 전체 주소
        const extraAddress = data.addressType === "R" ? "" : data.bname; // 도로명일 경우 법정동 추가
        const address = `${fullAddress} ${extraAddress}`; // 최종 주소
        setZipCode(data.zonecode);
        setSelectedAddress(address); // 주소를 선택하면 상태 업데이트
        setIsDaumPostcodeOpen(false); // 주소를 선택하면 모달 창 닫기
    };

    const closeDaumPostcodeHandler = () => {
        setIsDaumPostcodeOpen(false);
    };

    //우편번호 검색을 했는데, 나머지 주소를 입력하지 않은경우
    //주소입력을 아에 안하고 가입 가능 but, 하나라도 입력하면 나머지 주소를 입력해야한다.
    const addressIsNotValid = zipCode.trim() === "" || selectedAddress.trim() === "" || enteredAddress.trim() === "";
    // [END] 다음 주소 API =================================================================

    const boardObject = {
        process: "Request",
        support_type: asSupportType,
        phone: memberPhone.replace(/-/g, ""),
        zip: zipCode,
        address: selectedAddress,
        address_sub: enteredAddress,
        title: title,
        content: content,
        as_detail_1: as_detail_1,
        as_detail_2: as_detail_2,
        as_detail_3: as_detail_3,
        as_detail_4: as_detail_4,
        as_detail_5: as_detail_5,
    };

    ///>>> AS신청

    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [validCheck, setValidCheck] = useState(false);

    const validHandler = () => {
        topHandler();
        setValidCheck(true);

        if (addressIsNotValid) {
            return;
        }

        if (title.trim() === "") {
            return;
        }

        if (content.trim() === "") {
            return;
        }

        if (as_detail_1.trim() === "") {
            return;
        }

        if (as_detail_2.trim() === "") {
            return;
        }

        if (as_detail_3.trim() === "") {
            return;
        }

        if (as_detail_4.trim() === "") {
            return;
        }

        setShowConfirmModal(true);
    };

    // 드랍드랍
    const [images, setImages] = useState([]);
    const [ImagesDropDown, setImagesDropDown] = useState([]);

    const onHandleImageUpload = (acceptedFiles) => {
        setImages([...acceptedFiles]);
    };

    const onHandleImageUploadL = (acceptedFiles) => {
        setImagesDropDown(acceptedFiles);
    };

    const postHandler = () => {
        const jsonData = JSON.stringify(boardObject);

        const formData = new FormData();

        ImagesDropDown.forEach((image, index) => {
            formData.append("image", image);
        });

        formData.append("body", new Blob([jsonData], { type: "application/json" }));

        ReqAddBoardAs(
            formData,
            (result) => {
                if (result.status === 200) {
                    setShowSuccessModal(true);
                }
            },
            (error_result) => {
                alert("A/S신청 등록에 실패하셨습니다.");
            }
        );
    };
    ///<<< AS신청

    const navigateHandler = (link) => {
        navigate(link);
        setShowSuccessModal(false);
    };

    const myInfoHandler = () => {
        ReqMemberInfo(
            (result) => {
                setMemberPhone(result.cell);
            },
            (error_result) => {
                setMemberPhone("");
            }
        );
    };

    useEffect(() => {
        if (token == null) {
            setMemStore("LOGIN_MOVE_PAGE", { uri: "/mypage/contact/as" });
            navigate("/login");
        }
        myInfoHandler();
    }, []);

    return (
        <div className={classes.contents_wrap}>
            <div className={classes.contents_title}>
                <p className={classes.info}>A/S 신청 사항이나 문의하실 내용을 알려주세요.</p>
            </div>
            <div className={`${classes.detail_table_wrap} ${classes.mobile_type} ${classes.edit_st}`}>
                <table>
                    <colgroup>
                        <col style={{ width: "15%" }} />
                        <col style={{ width: "85%" }} />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>문의유형</th>
                            <td>
                                <SelectBoxNew
                                    className="default w_100"
                                    optionDatas={csTypes}
                                    setSelectItemCB={csTypeHandler}
                                    showDefaultKey={csType}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>
                                휴대폰<span className="red_c">*</span>
                            </th>
                            <td>
                                <div className={classes.phone_wrap}>
                                    <span>{memberPhone?.replace(/(\d{2,3})(\d{3,4})(\d{4})/, "$1-$2-$3")}</span>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>
                                주소<span className="red_c">*</span>
                            </th>
                            <td>
                                <div className={classes.address_wrap}>
                                    <div className={classes.input_btn_wrap}>
                                        <Input
                                            className="admin_st_sm"
                                            value={zipCode}
                                            id="zipCode"
                                            onChange={setZipCode}
                                        />
                                        <Button className="border sm" onClick={openDaumPostcodeHandler}>
                                            우편번호 검색
                                        </Button>
                                    </div>
                                    {/* mobile 구조변경 */}
                                    <Input
                                        className="h45 w_100"
                                        value={selectedAddress}
                                        id="address"
                                        onChange={setSelectedAddress}
                                    />
                                    <Input
                                        className="h45 w_100"
                                        value={enteredAddress}
                                        id="detailAddress"
                                        onChange={setEnteredAddress}
                                    />
                                    {validCheck && addressIsNotValid && (
                                        <p className="require_red f_14">주소를 입력해주세요.</p>
                                    )}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>
                                신청제목
                                <span className="red_c">*</span>
                            </th>
                            <td>
                                <MultiInput className="h45 w_100" onChange={onChangeInput} name="title" value={title} />
                                {validCheck && title.trim() === "" && (
                                    <p className="require_red f_14">제목 입력해주세요.</p>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <th>분류</th>
                            <td>
                                <div className={classes.radio_wrap}>
                                    <Radio
                                        className="official_st"
                                        name="rad_02"
                                        id="rad_02_1"
                                        value="Ball"
                                        title="볼링공"
                                        checked={asSupportType === "Ball"}
                                        onChange={supportTypeHandler}
                                    />
                                    <Radio
                                        className="official_st"
                                        name="rad_02"
                                        id="rad_02_2"
                                        value="Shoes"
                                        title="볼링신발"
                                        checked={asSupportType === "Shoes"}
                                        onChange={supportTypeHandler}
                                    />
                                    <Radio
                                        className="official_st"
                                        name="rad_02"
                                        id="rad_02_3"
                                        value="Bag"
                                        title="볼링가방"
                                        checked={asSupportType === "Bag"}
                                        onChange={supportTypeHandler}
                                    />
                                    <Radio
                                        className="official_st"
                                        name="rad_02"
                                        id="rad_02_4"
                                        value="Cloth"
                                        title="의류"
                                        checked={asSupportType === "Cloth"}
                                        onChange={supportTypeHandler}
                                    />
                                    <Radio
                                        className="official_st"
                                        name="rad_02"
                                        id="rad_02_5"
                                        value="Other"
                                        title="기타 용품"
                                        checked={asSupportType === "Other"}
                                        onChange={supportTypeHandler}
                                    />
                                </div>
                            </td>
                        </tr>
                        {asSupportType === "Ball" && (
                            <tr>
                                <th>
                                    볼명
                                    <span className="red_c">*</span>
                                </th>
                                <td>
                                    <MultiInput
                                        onChange={onChangeInput}
                                        name="as_detail_1"
                                        className="h45 w_100"
                                        value={as_detail_1}
                                    />
                                    {validCheck && as_detail_1.trim() === "" && (
                                        <p className="require_red f_14">볼명을 입력해주세요.</p>
                                    )}
                                </td>
                            </tr>
                        )}
                        {asSupportType !== "Ball" && (
                            <tr>
                                <th>
                                    상품명
                                    <span className="red_c">*</span>
                                </th>
                                <td>
                                    <MultiInput
                                        onChange={onChangeInput}
                                        name="as_detail_1"
                                        className="h45 w_100"
                                        value={as_detail_1}
                                    />
                                    {validCheck && as_detail_1.trim() === "" && (
                                        <p className="require_red f_14">상품명을 입력해주세요.</p>
                                    )}
                                </td>
                            </tr>
                        )}
                        {asSupportType === "Ball" && (
                            <tr>
                                <th>
                                    파운드
                                    <span className="red_c">*</span>
                                </th>
                                <td>
                                    <MultiInput
                                        onChange={onChangeInput}
                                        name="as_detail_2"
                                        className="h45 w_100"
                                        value={as_detail_2}
                                    />
                                    {validCheck && as_detail_2.trim() === "" && (
                                        <p className="require_red f_14">파운드를 입력해주세요.</p>
                                    )}
                                </td>
                            </tr>
                        )}
                        {(asSupportType === "Shoes" || asSupportType === "Bag" || asSupportType === "Cloth") && (
                            <tr>
                                <th>
                                    색상
                                    <span className="red_c">*</span>
                                </th>
                                <td>
                                    <MultiInput
                                        onChange={onChangeInput}
                                        name="as_detail_2"
                                        className="h45 w_100"
                                        value={as_detail_2}
                                    />
                                    {validCheck && as_detail_2.trim() === "" && (
                                        <p className="require_red f_14">색상을 입력해주세요.</p>
                                    )}
                                </td>
                            </tr>
                        )}
                        {asSupportType === "Other" && (
                            <tr>
                                <th>
                                    옵션
                                    <span className="red_c">*</span>
                                </th>
                                <td>
                                    <MultiInput
                                        onChange={onChangeInput}
                                        name="as_detail_2"
                                        className="h45 w_100"
                                        value={as_detail_2}
                                    />
                                    {validCheck && as_detail_2.trim() === "" && (
                                        <p className="require_red f_14">옵션 입력해주세요.</p>
                                    )}
                                </td>
                            </tr>
                        )}
                        {asSupportType === "Ball" && (
                            <tr>
                                <th>
                                    시리얼넘버
                                    <span className="red_c">*</span>
                                </th>
                                <td>
                                    <MultiInput
                                        onChange={onChangeInput}
                                        name="as_detail_3"
                                        value={as_detail_3}
                                        className="h45 w_100"
                                    />
                                    {validCheck && as_detail_3.trim() === "" && (
                                        <p className="require_red f_14">시리얼 넘버를 입력해주세요.</p>
                                    )}
                                </td>
                            </tr>
                        )}
                        {(asSupportType === "Shoes" || asSupportType === "Cloth") && (
                            <tr>
                                <th>
                                    사이즈
                                    <span className="red_c">*</span>
                                </th>
                                <td>
                                    <MultiInput
                                        onChange={onChangeInput}
                                        name="as_detail_3"
                                        value={as_detail_3}
                                        className="h45 w_100"
                                    />
                                    {validCheck && as_detail_3.trim() === "" && (
                                        <p className="require_red f_14">사이즈를 입력해주세요.</p>
                                    )}
                                </td>
                            </tr>
                        )}
                        {(asSupportType === "Bag" || asSupportType === "Other") && (
                            <>
                                <tr>
                                    <th>
                                        구매처
                                        <span className="red_c">*</span>
                                    </th>
                                    <td>
                                        <MultiInput
                                            onChange={onChangeInput}
                                            name="as_detail_3"
                                            value={as_detail_3}
                                            className="h45 w_100"
                                        />
                                        {validCheck && as_detail_3.trim() === "" && (
                                            <p className="require_red f_14">구매처를 입력해주세요.</p>
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        구매일자
                                        <span className="red_c">*</span>
                                    </th>
                                    <td>
                                        <MultiInput
                                            onChange={onChangeInput}
                                            name="as_detail_4"
                                            value={as_detail_4}
                                            className="h45 w_100"
                                        />
                                        {validCheck && as_detail_4.trim() === "" && (
                                            <p className="require_red f_14">구매일자를 입력해주세요.</p>
                                        )}
                                    </td>
                                </tr>
                            </>
                        )}
                        {(asSupportType === "Ball" || asSupportType === "Shoes" || asSupportType === "Cloth") && (
                            <>
                                <tr>
                                    <th>
                                        구매처
                                        <span className="red_c">*</span>
                                    </th>
                                    <td>
                                        <MultiInput
                                            onChange={onChangeInput}
                                            name="as_detail_4"
                                            className="h45 w_100"
                                            value={as_detail_4}
                                        />
                                        {validCheck && as_detail_4.trim() === "" && (
                                            <p className="require_red f_14">구매처를 입력해주세요.</p>
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        구매일자
                                        <span className="red_c">*</span>
                                    </th>
                                    <td>
                                        <MultiInput
                                            onChange={onChangeInput}
                                            name="as_detail_5"
                                            className="h45 w_100"
                                            value={as_detail_5}
                                        />
                                        {validCheck && as_detail_5.trim() === "" && (
                                            <p className="require_red f_14">구매일자를 입력해주세요.</p>
                                        )}
                                    </td>
                                </tr>
                            </>
                        )}
                        <tr>
                            <th>
                                A/S 신청내용
                                <span className="red_c">*</span>
                            </th>
                            <td>
                                <div style={{ height: "200px" }}>
                                    <QuillEditorForText
                                        height="160"
                                        width="auto"
                                        onValueChangeCB={contentHandler}
                                        value={content}
                                    />
                                </div>
                                {validCheck && content.trim() === "" && (
                                    <p className="require_red f_14">A/S 신청내용을 입력해주세요.</p>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <th>첨부 파일</th>
                            <td>
                                <DropZoneForAS
                                    images={images}
                                    onImageUpload={onHandleImageUpload}
                                    onImageUploadL={onHandleImageUploadL}
                                />
                                <div className={classes.desc_s}>
                                    * 이미지 파일 첨부 가능 갯수는 최대 5개 입니다.
                                    <br />
                                    사진은 5MB 미만의 JPG, PNG, GIF 형식으로 업로드 가능합니다.
                                    <br />
                                    파일명에 ‘_’이외의 특수문자는 사용이 불가능합니다.
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                {isDaumPostcodeOpen && (
                    <Modal close={closeDaumPostcodeHandler} header="우편번호 검색">
                        <DaumPostcode onComplete={handleAddressSelect} />
                    </Modal>
                )}
                {changeMode && (
                    <Alert
                        type="confirm"
                        cancel="취소"
                        confirm="확인"
                        close={() => changeModeHandler(false)}
                        confirmHandler={moveHandler}
                    >
                        {"문의 유형을 변경 하시겠습니까?"}
                        {" 작성중인 내용은 삭제됩니다."}
                    </Alert>
                )}
                {showCancelModal && (
                    <Alert
                        type="confirm"
                        cancel="취소"
                        confirm="확인"
                        close={() => setShowCancelModal(false)}
                        confirmHandler={() => navigateHandler("/myPage/cs/as")}
                    >
                        {"A/S 신청을 취소하시겠습니까?"}
                    </Alert>
                )}
                {showConfirmModal && (
                    <Alert
                        type="confirm"
                        cancel="취소"
                        confirm="확인"
                        close={() => setShowConfirmModal(false)}
                        confirmHandler={postHandler}
                    >
                        {"A/S를 신청하시겠습니까?"}
                    </Alert>
                )}
                {showSuccessModal && (
                    <Alert type="alert" confirm="확인" close={() => navigateHandler("/myPage/cs/as")}>
                        {"A/S신청이 완료되었습니다."}
                    </Alert>
                )}
                {/* 모바일에서 구조 변경 */}
                <div className={classes.btn_bottom_wrap}>
                    <Button onClick={() => setShowCancelModal(true)}>취소</Button>
                    <Button className="secondary" onClick={validHandler}>
                        등록
                    </Button>
                </div>
            </div>
        </div>
    );
}
export default MyContactAsApp;
