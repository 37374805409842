import { useEffect, useState } from "react";
import classes from "../../MngCommon.module.css";
import Button from "../../../../components/UI/Button/Button";
import { ReqBowlingAlleyList } from "../../../../api/tournament/BowlingAlleyApi";
import { Link, NavLink, useLocation } from "react-router-dom";
import { ReqRegionData } from "../../../../api/common/ConditionSelectBoxApi";
import { DefaultAll } from "../../../../components/definedefault/DefSelectBoxValues";
import SelectBoxNew from "../../../../components/UI/SelectBox/SelectBoxNew";
import instance from "../../../../store/AxiosInterceptor";
import PaginationNew from "../../../../components/UI/Table/PaginationNew";

function MngTournamentBowlingAlley() {

    const [bowlingAlleyList, setBowlingAlleyList] = useState([]);

    const location = useLocation();

    // >>> 페이징을 위한 값 세팅
    const [totalPage, setTotalPage] = useState(); //총 페이지 갯수
    const [pageNumber, setPageNumber] = useState(location.state?.pageNumber ?? 0); //글목록 No. 값을세팅하기 위한 서버에서 들어오는값
    const [totalCount, setTotalCount] = useState(0);

    const pageHandler = (value) => {
        setPageNumber(value);
        instance
            .get("/api/v1/admin/contest/center/page", {
                params: {
                    region: regionValue,
                    page: value,
                    size: 10,
                },
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
                withCredentials: true,
            })
            .then((res) => {
                const curData = res.data.datas.content.map((data) => ({
                    center_id: data.center_id,
                    region: data.region,
                    name: data.name,
                    use_lane_count: data.use_lane_count,
                    start_lane_number: data.start_lane_number,
                    end_lane_number: data.end_lane_number,
                    use_reserv_lane: data.use_reserv_lane,
                    regDate: data.regDate,
                    phoneNo: data.phonNo,
                    regID: data.regID,
                }));
                // console.log(curData);
                setBowlingAlleyList(curData);
                setTotalPage(res.data.datas.totalPages);
                setTotalCount(res.data.datas.totalElements);
                setPageNumber(res.data.datas.pageable.pageNumber);
            })
            .catch((error) => {
                console.error(error);
                setBowlingAlleyList((prev) => prev);
                setTotalPage((prev) => prev);
                setTotalCount((prev) => prev);
                setPageNumber((prev) => prev);
            });
    };
    useEffect(() => {
        pageHandler(pageNumber);
    }, [])
    // <<< 페이징을 위한 값 세팅

    // [Start] 지역 선택

    const [regionData, setRegionData] = useState([DefaultAll]);
    const [region, setRegionType] = useState("");
    const [regionValue, setRegionValue] = useState("");

    const regionDataHandler = (key) => {
        // setRegionType(key);
        const selectedOption = regionData.find((data) => data.key === key);
        if (selectedOption) {
            setRegionType(key);
            setRegionValue(selectedOption.value);
        }
    };

    const regionNameMouseOverHandler = (key) => {
        if (regionData.length === 1) {
            ReqRegionData(
                (result) => {
                    const success = result.map((data) => {
                        return { key: String(data.id), value: data.name };
                    });
                    setRegionData((prev) => [DefaultAll, ...success]);
                },
                (excption_result) => {
                    setRegionData((prev) => [DefaultAll]);
                }
            );
        }
    };

    // [End]

    // [START] 검색

    const search = () => {
        let searchRegionValue = regionValue;

        if (regionValue === "전체") {
            searchRegionValue = "";
        }
        instance
            .get("/api/v1/admin/contest/center/page", {
                params: {
                    region: searchRegionValue,
                    page: 0,
                    size: 10,
                },
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
                withCredentials: true,
            })
            .then((res) => {
                const curData = res.data.datas.content.map((data) => ({
                    center_id: data.center_id,
                    region: data.region,
                    name: data.name,
                    use_lane_count: data.use_lane_count,
                    start_lane_number: data.start_lane_number,
                    end_lane_number: data.end_lane_number,
                    use_reserv_lane: data.use_reserv_lane,
                    regDate: data.regDate,
                    phoneNo: data.phonNo,
                    regID: data.regID,
                }));
                setBowlingAlleyList(curData);
                setTotalPage(res.data.datas.totalPages);
                setTotalCount(res.data.datas.totalElements);
                setPageNumber(res.data.datas.pageable.pageNumber);
            })
            .catch((error) => {
                console.error(error);
                setBowlingAlleyList((prev) => prev);
                setTotalPage((prev) => prev);
                setTotalCount((prev) => prev);
                setPageNumber((prev) => prev);
            });
    };

    const viewAll = () => {
        setPageNumber(0);
        setRegionType("");

        instance
            .get("/api/v1/admin/contest/center/page", {
                params: {
                    page: 0,
                    size: 10,
                },
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
                withCredentials: true,
            })
            .then((res) => {
                const curData = res.data.datas.content.map((data) => ({
                    center_id: data.center_id,
                    region: data.region,
                    name: data.name,
                    use_lane_count: data.use_lane_count,
                    start_lane_number: data.start_lane_number,
                    end_lane_number: data.end_lane_number,
                    use_reserv_lane: data.use_reserv_lane,
                    regDate: data.regDate,
                    phoneNo: data.phonNo,
                    regID: data.regID,
                }));
                setBowlingAlleyList(curData);
                setTotalPage(res.data.datas.totalPages);
                setTotalCount(res.data.datas.totalElements);
                setPageNumber(res.data.datas.pageable.pageNumber);
            })
            .catch((error) => {
                console.error(error);
                setBowlingAlleyList((prev) => prev);
                setTotalPage((prev) => prev);
                setTotalCount((prev) => prev);
                setPageNumber((prev) => prev);
            });
    };

    // [END]

    const bodyValues = bowlingAlleyList.map(
        (
            {
                center_id,
                region,
                name,
                use_lane_count,
                start_lane_number,
                end_lane_number,
                use_reserv_lane,
                regDate,
            },
            index
        ) => {
            const number = totalCount - (10 * pageNumber + index);
            return (
                <tr key={center_id}>
                    <td>{number}</td>
                    <td>{region}</td>
                    <td>
                        <NavLink to={`${center_id}`} state={{ pageNumber }}>{name}</NavLink>
                    </td>
                    <td>{use_lane_count}</td>
                    <td>
                        {start_lane_number} ~ {end_lane_number}
                    </td>
                    <td>{use_reserv_lane === "Y" ? "사용" : "미사용"}</td>
                    <td>{center_id}</td>
                    <td>{regDate}</td>
                </tr>
            );
        }
    );
    return (
        <section>
            <div className={classes.admin_contents}>
                <h3>볼링장 등록</h3>
                <div
                    className={`${classes.contents_wrap} ${classes.search_wrap}`}
                >
                    <div className={classes.admin_table_wrap}>
                        <table>
                            <colgroup>
                                <col style={{ width: "11%" }} />
                                <col style={{ width: "89%" }} />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>지역</th>
                                    <td>
                                        <div className={classes.flex_row}>
                                            <SelectBoxNew
                                                className="admin_st"
                                                setSelectItemCB={
                                                    regionDataHandler
                                                }
                                                optionDatas={regionData}
                                                showDefaultKey={region}
                                                mouseOverCB={
                                                    regionNameMouseOverHandler
                                                }
                                            />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className={classes.bottom_btn_wrap}>
                            <Button className="border sm" onClick={search}>
                                검색
                            </Button>
                            <Button className="secondary sm" onClick={viewAll}>
                                전체보기
                            </Button>
                        </div>
                    </div>
                </div>
                <div className={classes.contents_wrap}>
                    <div
                        className={`${classes.admin_table_wrap} ${classes.align_center}  ${classes.row_select}`}
                    >
                        <p className={classes.counter}>
                            총 <b>{totalCount}</b>건
                        </p>
                        <table>
                            <colgroup>
                                <col style={{ width: "5%" }} />
                                <col style={{ width: "10%" }} />
                                <col style={{ width: "30%" }} />
                                <col style={{ width: "10%" }} />
                                <col style={{ width: "10%" }} />
                                <col style={{ width: "10%" }} />
                                <col style={{ width: "10%" }} />
                                <col style={{ width: "15%" }} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th>지역</th>
                                    <th>볼링장명</th>
                                    <th>레인수</th>
                                    <th>사용레인</th>
                                    <th>예비레인사용유무</th>
                                    <th>볼링장ID</th>
                                    <th>등록일</th>
                                </tr>
                            </thead>
                            <tbody>{bodyValues}</tbody>
                        </table>
                        <PaginationNew
                            className="admin_st"
                            totalPage={totalPage}
                            currentPage={pageNumber}
                            changePageCB={pageHandler}
                            defaultShowPage={5}
                        />
                        <div
                            className={`${classes.bottom_btn_wrap} ${classes.position_st}`}
                        >
                            <Link to="/admin/tournament/bowlingAlley/upload">
                                <Button className="secondary sm">등록</Button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default MngTournamentBowlingAlley;

export const loader = async () => {
    const response = await ReqBowlingAlleyList();
    // console.log(response);
    if (response.status === 200) {
        const resData = response.data.datas;

        return resData;
    } else {
        return [];
    }
};
