import React, { useState } from "react";
import classes from "./SelectBox.module.css";

function SelectBoxKegel(props) {
    const {
        optionData,
        className = "",
        readonly,
        selectedValue,
        setSelectedValue,
        selectedData,
        setSelectedData,
    } = props;

    const selectedOption = optionData.find(
        (data) => data.value === selectedValue
    );

    const [showOptions, setShowOptions] = useState(false);

    const HandleShowOption = () => {
        if (!readonly) {
            setShowOptions((current) => !current);
        }
    };

    const handleOptionClick = (value, data, e) => {
        setSelectedValue(value);
        setSelectedData(data);
        setShowOptions(false);

        e.stopPropagation();
    };

    const classNames = Array.isArray(className)
        ? className
        : className.split(" ");

    const combinedClasses = classNames
        .map((cn) => classes[cn])
        .filter((cn) => cn)
        .join(" ");

    return (
        <div
            className={`${classes.selectBox} ${combinedClasses} ${
                showOptions ? classes.on : ""
            }`}
            onClick={HandleShowOption}
        >
            <p>{selectedOption ? selectedOption.value : "전체"}</p>
            <ul>
                {optionData.map((data) => (
                    <li
                        key={data.value}
                        onClick={(e) =>
                            handleOptionClick(data.value, data.data, e)
                        }
                        className={
                            data.value === selectedValue ? classes.selected : ""
                        }
                    >
                        {data.value}
                        {data.state && (
                            <span
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.currentTarget.parentNode.click();
                                }}
                            >
                                - {data.state}
                            </span>
                        )}
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default SelectBoxKegel;
