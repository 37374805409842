import instance from "../../store/AxiosInterceptor";
//대회 메인 홈페이지

/**
 * 대회 메인 홈페이지, 진행중인 대회 가져오기
 * @param resolveCB 응답이 성공일 경우 데이터를 넣어 호출하는 callback method
 * @returns
 */
export function ReqOngoingTournamentList(resolveCB, rejectCB) {
    instance({
        url: `/api/v1/home/contest`,
        method: "GET",
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data.datas);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/**
 * 대회 메인 홈페이지, 대회 공지사항 가져오기
 * @param resolveCB 응답이 성공일 경우 데이터를 넣어 호출하는 callback method
 * @returns
 */
export function ReqTournamentNoticeList(resolveCB, rejectCB) {
    instance({
        url: `/api/v1/home/contest/board/contestNotice`,
        method: "GET",
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data.datas);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/**
 * 대회 메인 홈페이지, 대회 안내사항 가져오기
 * @param resolveCB 응답이 성공일 경우 데이터를 넣어 호출하는 callback method
 * @returns
 */
export function ReqTournamentInfoList(resolveCB, rejectCB) {
    instance({
        url: `/api/v1/home/contest/board/contestInfo`,
        method: "GET",
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data.datas);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/**
 * 대회 메인 홈페이지, FAQ 가져오기
 * @param resolveCB 응답이 성공일 경우 데이터를 넣어 호출하는 callback method
 * @returns
 */
export function ReqFAQList(resolveCB, rejectCB) {
    instance({
        url: `/api/v1/home/board/faq/ct?boardType=faqContest`,
        method: "GET",
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data.datas);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/**
 * 대회 메인 홈페이지, 배너 가져오기
 * @param resolveCB 응답이 성공일 경우 데이터를 넣어 호출하는 callback method
 * 
 * @returns
 */
export function ReqBannerList(bannerType, resolveCB, rejectCB) {
    instance({
        url: `/api/v1/home/contest/banner?bannerType=${bannerType}`,
        method: "GET",
    })
    .then((response) => {
        if (resolveCB) {
            resolveCB(response.data.datas);
        }
    })
    .catch((error) => {
        if (rejectCB) {
            rejectCB([]);
        }
    });
}

/**
 * 대회 메인 홈페이지, 대회 안내사항 가져오기
 * @param resolveCB 응답이 성공일 경우 데이터를 넣어 호출하는 callback method
 * @returns
 */
export function ReqTournamentList(resolveCB, rejectCB) {
    instance({
        url: `/api/v1/home/contest/latest`,
        method: "GET",
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data.datas);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/**
 * 대회 메인 홈페이지, 대회 공지사항 가져오기
 * @param resolveCB 응답이 성공일 경우 데이터를 넣어 호출하는 callback method
 * @returns
 */
export function ReqResultList(resolveCB, rejectCB) {
    instance({
        url: `/api/v1/home/contest/result/list`,
        method: "GET",
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/**
 * 대회 메인 홈페이지, 케겔 가져오기
 * @param resolveCB 응답이 성공일 경우 데이터를 넣어 호출하는 callback method
 * @returns
 */
export function ReqKegelHomeList(resolveCB, rejectCB) {
    instance({
        url: `/api/v1/home/board/kegelSite`,
        method: "GET",
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

export function ReqNoticeHomeList(resolveCB, rejectCB) {
    instance({
        url: `/api/v1/home/contest/board/notice`,
        method: "GET",
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

export function ReqRecommendProductHomeList(resolveCB, rejectCB) {
    instance({
        url: `/api/v1/home/product/recommend`,
        method: "GET",
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data ?? []);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

export function ReqProductCatalogueHomeList(resolveCB, rejectCB) {
    instance({
        url: `/api/v1/home/product/catalogue`,
        method: "GET",
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data ?? []);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}