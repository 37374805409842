import { Outlet } from "react-router-dom";
import classes from "./EventApp.module.css";
import EventAppNavigation from "./EventAppNavigation";

function EventAppRootLayout() {
    return (
        <div className={`container ${classes.event_container}`}>
            <EventAppNavigation />
            <section>
                <div className={classes.event_contents}>
                    <Outlet />
                </div>
            </section>
        </div>
    );
}

export default EventAppRootLayout;
