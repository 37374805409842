import { useState, useEffect } from "react";
import classes from "./Radio.module.css";

function Radio({ title, id, value, name, onChange, checked, className, disabled, onClick }) {
    // MngTournamentBannerDetail.js 166,174번줄 참고.
    // onChange에는 state를 관리하는 함수를 넣어주고, checked에는 state 현재값과 input의 value를 체크한다.

    return (
        <form className={`${classes.radio_box} ${classes[className]}`}>
            <input
                type="radio"
                value={value}
                name={name}
                id={id}
                checked={checked}
                onChange={onChange}
                disabled={disabled}
                onClick={onClick}
            />
            <label htmlFor={id}>{title}</label>
        </form>
    );
}
export default Radio;
