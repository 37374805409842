import { useLoaderData, useLocation, useNavigate } from "react-router-dom";
import { ReqPopupDelete, ReqPopupDetail } from "../../../../api/site/ApiForPopup";
import Button from "../../../../components/UI/Button/Button";
import classes from "../../MngCommon.module.css";
import Moment from 'moment';
import { useEffect } from "react";

function MngPopupDetail(props) {
    
    const popup = useLoaderData();

    const location = useLocation();
    const navigate = useNavigate();

    const backHandler = () => {
        navigate("/admin/site/popup", { state: location.state });
    };
    useEffect(() => {
        window.addEventListener('popstate', backHandler);
        return () => { window.removeEventListener('popstate', backHandler) };
    }, [])
    const deleteHandler = async (e) => {
        e.preventDefault();
        
        const isTrue = window.confirm('팝업을 삭제하시겠습니까?');
        if(isTrue) {
            try {
                const response = await ReqPopupDelete(popup.id);
                if (response.status === 200) {
                    alert("팝업이 삭제되었습니다.");
                    backHandler();
                } else {
                    alert("팝업 삭제 실패");
                }
            } catch (error) {
                console.error("Error:", error);
            }
        } else {
          return
        }
    };

    const updateHandler = () => {
        navigate(`../update/${popup.id}`, { state: location.state });
    };


    return (
        <section>
            <div className={classes.admin_contents}>
                <h3>팝업관리 - 상세</h3>
                <div
                    className={`${classes.contents_wrap} ${classes.search_wrap} `}
                >
                    <div className={classes.admin_table_wrap}>
                        <table>
                            <colgroup>
                                <col style={{ width: "10%" }} />
                                <col style={{ width: "40%" }} />
                                <col style={{ width: "10%" }} />
                                <col style={{ width: "40%" }} />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>등록일</th>
                                    <td>{Moment(popup.reg_date).format('YYYY-MM-DD')}</td>
                                    <th>등록자</th>
                                    <td>{popup.reg_id}</td>
                                </tr>
                                <tr>
                                    <th>노출여부</th>
                                    <td colSpan={3}>{popup.expose_yn ? "노출" : "미노출"}</td>
                                </tr>
                                <tr>
                                    <th>제목</th>
                                    <td colSpan={3}>
                                        {popup.title}
                                    </td>
                                </tr>
                                <tr>
                                    <th>팝업창 URL</th>
                                    <td colSpan={3}>
                                        {popup.url}
                                    </td>
                                </tr>
                                <tr>
                                    <th>팝업창 사이즈</th>
                                    <td colSpan={3}>{popup.width} px * {popup.height} px</td>
                                </tr>
                                <tr>
                                    <th>팝업창 위치</th>
                                    <td colSpan={3}>{popup.x} px * {popup.y} px</td>
                                </tr>
                                <tr>
                                    <th>게시기간</th>
                                    <td colSpan={3}>
                                        {Moment(popup.expose_s_date, "YYYYMMDDHHmm").format("YYYY-MM-DD HH:mm")} ~ {Moment(popup.expose_e_date, "YYYYMMDDHHmm").format("YYYY-MM-DD HH:mm")}
                                    </td>
                                </tr>
                                <tr>
                                    <th>쿠키설정여부</th>
                                    <td colSpan={3}>{popup.cookie_yn ? "Y" : "N"}</td>
                                </tr>
                                <tr>
                                    <th>내용</th>
                                    <td colSpan={3}>
                                        <div dangerouslySetInnerHTML={{
                                        __html: popup.content,
                                        }} >
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className={classes.bottom_btn_wrap}>
                            <Button className="secondary sm" onClick={updateHandler}>수정</Button>
                            <Button className="sm" onClick={deleteHandler}>삭제</Button>
                            <Button className="border sm" onClick={backHandler} >목록</Button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default MngPopupDetail;

export const loader = async ({ params }) => {
    const response = await ReqPopupDetail(params.popupId);
    
    if (response.status === 200) {
        const resData = await response.data.data;
        return resData;
    } else {
        return [];
    }
};