import { NavLink } from "react-router-dom";
import classes from "../MngCommon.module.css";

function MngCompanyNavigation(props) {
    return (
        <div className={classes.admin_sub_nav}>
            <nav>
                <ul className={classes.depth_1}>
                    <li className={classes.depth_1_link}>
                        <NavLink to="info">회사안내</NavLink>
                    </li>
                    <li className={classes.depth_1_link}>
                        <NavLink to="staff">STAFF</NavLink>
                    </li>
                    <li className={classes.depth_1_link}>
                        <NavLink to="center">전국볼링장</NavLink>
                    </li>
                </ul>
            </nav>
        </div>
    );
}

export default MngCompanyNavigation;
