import { useLoaderData, useNavigate } from "react-router-dom";
import Button from "../../../components/UI/Button/Button";
import classes from "../MngCommon.module.css";

import { useState } from "react";
import { ReqStaffList, ReqStaffSearchList, ReqUpdateStaffSeq } from "../../../api/company/ApiForStaff";
import Input from "../../../components/UI/Input/Input";
import SelectBoxNew from "../../../components/UI/SelectBox/SelectBoxNew";
import { staffDataType } from "../../../components/definedefault/DefSelectBoxValues";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Alert from "../../../components/UI/Modal/Alert";
import { CSVLink } from "react-csv";
import ReactFileReader from "react-file-reader";
import instance from "../../../store/AxiosInterceptor";

function MngStaff(props) {
    const staff = useLoaderData();

    const navigate = useNavigate();

    const createHandler = () => {
        navigate("./create");
    };

    const detailHandler = (e, id) => {
        e.preventDefault();

        navigate(`./${id}`);
    };

    const [staffs, setStaffs] = useState(staff.data);

    const [currentPage, setCurrentPage] = useState(0);
    const [totalPage, setTotalPage] = useState(staff.totalPage); //총 페이지 갯수
    const [totalCount, setTotalCount] = useState(staff.totalCount); //총 페이지 갯수

    const viewAll = async () => {
        setCurrentPage(0)
        setSearch("")
        setStaffType("")

        const response = await ReqStaffList(0);
        if (response.status === 200) {
            const resData = await response.data.data;
            setTotalPage(resData.totalPages);
            setTotalCount(resData.totalElements);
            setStaffs(resData.content);
        } else {
            setStaffs([]);
        }
    };

    const handlePageChange = async (page) => {
        const pages = currentPage === "0" ? currentPage : currentPage + 1
        const searchParam = {
            query_type : staffType === "" ? null : staffType,
            query_text: search === "" ? null : search,
            page : pages,
        }

        setCurrentPage(pages);

        const response = await ReqStaffSearchList(searchParam);
        if (response.status === 200) {
            const resData = await response.data.data;
            setTotalPage(resData.totalPages);
            setTotalCount(resData.totalElements);
            const combinedStaffs = [...staffs, ...resData.content];
            setStaffs(combinedStaffs);
        } else {
            setStaffs([]);
        }
    };

    // 검색 버튼 클릭
    const searchClick = async () => {
        setCurrentPage(0)
        
        const searchParam = {
            query_type : staffType === "" ? null : staffType,
            query_text: search === "" ? null : search,
            page : 0,
        }

        console.log(searchParam);

        const response = await ReqStaffSearchList(searchParam);
        if (response.status === 200) {
            const resData = await response.data.data;
            setTotalPage(resData.totalPages);
            setTotalCount(resData.totalElements);
            setStaffs(resData.content);
        } else {
            setStaffs([]);
        }
    };

    const [search, setSearch] = useState();
    const [staffType, setStaffType] = useState ("");

    const searchHandler = (e) => {
        setSearch(e)
    };

    const staffDataHandler = (key) => {
        setStaffType(key);
    };
    
    //>>>배너 순서 변경 저장 메소드
    const [showConfirmation, setShowConfirmation] = useState(false); //모달 Alert 상태관리
    const confirmChangeHandler = () => {
        setShowConfirmation(false);
        changeHandler();
    };

    const changeHandler = async () => {
        ReqUpdateStaffSeq(
            staffs,
            (result) => {
                if (result.data.status === 200) {
                    alert("완료되었습니다.")
                    viewAll();
                }
            },
            (error_result) => {
                alert("서버 통신 에러");
            }
        );
    };
    //<<<배너 순서 변경 저장 메소드

    //>>> 드래그 &  드랍 저장 메소드
    const handleOnDragEnd = (result) => {
        if (!result.destination) return;

        const items = Array.from(staffs);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        items.forEach((data, index) => (data.priority = index + 1));
        setStaffs(items);
    };
    //<<< 드래그 &  드랍 저장 메소드

    const setStaffCheck = (type) => {
        const types = staffDataType.find((items) => items.key === type);
        return types === undefined ? "" : types.value;
    }

    /// 엑셀 업로드 양식때 필요한 데이터
    const headers = [
        { label: "이름" }
        , { label: "팀" }
        , { label: "프로번호" }
        , { label: "구력" }
        , { label: "손(오른손/왼손)" }
        , { label: "소개(intro)" }
        , { label: "노출여부(Y/N)" }
        , { label: "스태프타입(professional/amateur)" }
        , { label: "노출순서" }
    ];
    const data = [];

    /// 엑셀파일 업로드
    const uploadFile = async (files) => {
        const isTrue = window.confirm('STAFF을 등록하시겠습니까?');
        if (isTrue) {
            const formData = new FormData();
            formData.append("file", files[0]); // Assuming files is an array and we take the first file

            try {
                const response = await instance.post("/api/v1/admin/member/staff/excel", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        "Authorization": "Bearer " + localStorage.getItem("token"),
                    },
                });

                if (response.status === 200) {
                    alert("STAFF 등록이 완료되었습니다.");
                    viewAll();
                } else {
                    alert("STAFF 등록 실패");
                }
            } catch (error) {
                console.error("Error uploading file: ", error);
                alert("STAFF 등록 실패");
            }
        } else {
            return;
        }
    };

    return (
        <section>
            <div className={classes.admin_contents}>
                <h3>STAFF - 목록</h3>
                <div
                    className={`${classes.contents_wrap} ${classes.search_wrap}`}
                >
                    <div className={classes.admin_table_wrap}>
                        <table>
                            <colgroup>
                                <col style={{ width: "11%" }} />
                                <col style={{ width: "19%" }} />
                                <col style={{ width: "11%" }} />
                                <col style={{ width: "59%" }} />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>구분</th>
                                    <td>
                                        <SelectBoxNew
                                            className="admin_st"
                                            setSelectItemCB={staffDataHandler}
                                            optionDatas={staffDataType}
                                            showDefaultKey={staffType}
                                        />        
                                    </td>
                                    <th>검색어</th>
                                    <td>
                                        <Input
                                            className="admin_st" placeholder="검색어 입력" onChange={searchHandler} value={search}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className={classes.bottom_btn_wrap}>
                            <Button className="border sm" onClick={searchClick}>검색</Button>
                            <Button className="secondary sm" onClick={viewAll}>
                                전체보기
                            </Button>
                        </div>
                    </div>
                </div>
                <div className={classes.contents_wrap}>
                    <p className={classes.counter}>
                        총 <b>{totalCount}</b> 건
                    </p>
                    <div
                        className={`${classes.dnd_table_wrap} ${classes.staff_st}`}
                    >
                        <ul className={classes.header}>
                            <li>순서변경</li>
                            <li>No</li>
                            <li>구분</li>
                            <li>이미지</li>
                            <li>이름</li>
                            <li>소속팀명</li>
                            <li>등록일</li>
                            <li>등록자</li>
                            <li>노출여부</li>
                            <li>조회수</li>
                        </ul>
                        <DragDropContext onDragEnd={handleOnDragEnd}>
                            <Droppable droppableId={classes.list_wrap}>
                                {(provided) => (
                                    <div
                                        className={classes.list_wrap}
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                    >
                                        {staffs.length !== 0 && (staffs.map((data, index) => {
                                                return (
                                                    <Draggable
                                                        key={`staff${data.id}`}
                                                        draggableId={`staff${data.id}`}
                                                        index={index}
                                                    >
                                                        {(provided, snapshot) => (
                                                            <ul
                                                                className={`${classes.list} ${
                                                                    snapshot.isDragging ? classes.on : ""
                                                                }`}
                                                                key={data.id}
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                onClick={(e) => detailHandler(e, data.id)}
                                                            >
                                                                <li className={classes.btn_move}></li>
                                                                    <li>{index + 1}</li>
                                                                    <li>{setStaffCheck(data.type)}</li>
                                                                    <li>
                                                                        <img
                                                                            src={data.thumbnail_url}
                                                                            alt={data.original_name}
                                                                        />
                                                                    </li>
                                                                    <li>{data.staff_name}</li>
                                                                    <li>{data.team}</li>
                                                                    <li>{data.reg_date}</li>
                                                                    <li>{data.reg_id}</li>
                                                                    <li>{data.expose_yn === "Y" ? "노출" : "미노출"}</li>
                                                                    <li>{data.read_cnt}</li>
                                                                </ul>
                                                        )}
                                                    </Draggable>
                                                );
                                            }
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </div>
                    {totalCount !== staffs.length && (
                    <div className={classes.bottom_center_btn}>
                        <Button className="text_icon" onClick={handlePageChange}>더보기</Button>
                    </div>
                    )}
                    <div
                        className={`${classes.bottom_btn_wrap} ${classes.multi}`}
                    >
                        <div className={classes.left}>
                            <Button type="submit" className="secondary sm" onClick={() => setShowConfirmation(true)}>
                                순서 변경 적용
                            </Button>
                        </div>

                        <div className={classes.right}>
                            <CSVLink
                                data={data}
                                headers={headers}
                                filename={"STAFF"}
                            >
                                <Button className="primary upload sm">
                                    엑셀 업로드 양식 다운로드
                                </Button>
                            </CSVLink>
                            <ReactFileReader
                                handleFiles={uploadFile}
                                fileTypes={".csv"}
                            >
                            <Button className="sm">
                                엑셀 업로드
                            </Button>
                            </ReactFileReader>
                            <Button
                                className="secondary sm" onClick={createHandler}
                            >
                                등록
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            {showConfirmation && (
                <Alert
                    type="confirm"
                    cancel="취소"
                    confirm="확인"
                    close={() => setShowConfirmation(false)} // 모달 닫기
                    confirmHandler={confirmChangeHandler} // 확인 버튼 눌렀을 때 실행되는 Handler
                >
                    {"순서를 변경 하시겠습니까?"}
                </Alert>
            )}
        </section>
    );
}

export default MngStaff;

export const loader = async () => {
    const response = await ReqStaffList(0);
    
    if (response.status === 200) {
        const resData = await response.data.data;
        return { data: resData.content, totalPage: resData.totalPages, totalCount : resData.totalElements };
    } else {
        return { data: [], totalPage: 0, totalCount : 0 };
    }
};