import ReactGA from "react-ga4"

/**
 * YiSuHwan Enum 값을 강제해주기 위한 방법
 * event 및 category 추가 필요시 여기에 넣어서 사용 
 */
export function EnumGAEvent () {};

/**
 * Event param 중 event에 들어가야 할 사항
 * Google 실시간 모니터링 "이벤트 이름 별 이벤트"에 해당 값이 노출됨
 */
EnumGAEvent.event_login = "login";
EnumGAEvent.event_login_admin = "login_admin";
EnumGAEvent.event_logout = "logout";

/**
 * Event param 중 category에 들어가야 할 사항
 */
EnumGAEvent.cat_user_accout = "user_accout";

/**
 * Google Analystics에 보내는 event
 * 참고
 *  - https://github.com/codler/react-ga4?tab=readme-ov-file
 * eventName 
 *  - 참고
 *    - https://support.google.com/analytics/answer/9267735?hl=en
 *  ex) login(로그인), join_group (대회참가 신청)
 */
export const sendGAEvent = (eventName, paramCategory, label) => {
    const options = { category: paramCategory, action : eventName, label};
    // console.log(options);
    ReactGA.event(options);
}

export const sendPageView = (path) => {
    ReactGA.send({ hitType: "pageview", page: path, title: path});
}