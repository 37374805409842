import { NavLink } from "react-router-dom";
import classes from "./MyPageApp.module.css";
import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";

function MyPageAppNavigation(props) {
    const sliderRef = useRef(null);
    const sliderSettings = {
        className: "header_sub_nav",
        dots: false,
        arrows: false,
        infinite: false,
        speed: 300,
        slidesToShow: 5,
        slidesToScroll: 1,
        initialSlide: 0,
        variableWidth: true,
        responsive: [
            {
                breakpoint: 550,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    return (
        <div className={classes.mypage_sub_nav}>
            <Slider ref={sliderRef} {...sliderSettings}>
                <div className={classes.depth_1_link}>
                    <NavLink to="tournamentHistory">대회 접수내역</NavLink>
                </div>
                <div className={classes.depth_1_link}>
                    <NavLink to="event">이벤트 접수내역</NavLink>
                </div>
                <div className={classes.depth_1_link}>
                    <NavLink to="cs">나의 문의내역</NavLink>
                </div>
                <div className={classes.depth_1_link}>
                    <NavLink to="contact/help">1:1 문의</NavLink>
                </div>
                <div className={classes.depth_1_link}>
                    <NavLink to="modify">회원정보수정</NavLink>
                </div>
            </Slider>
        </div>
    );
}

export default MyPageAppNavigation;
