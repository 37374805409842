import { useEffect, useState } from "react";
import classes from "../MngCommon.module.css";
import Button from "../../../components/UI/Button/Button";
import Input from "../../../components/UI/Input/Input";
import SelectBoxNew from "../../../components/UI/SelectBox/SelectBoxNew";
import {
    DefaultASQueryTypeSelectBox,
    DefaultASTypeSelectBox,
    DefaultProcessSelectBox,
} from "../../../components/definedefault/DefSelectBoxValues";
import instance from "../../../store/AxiosInterceptor";
import { useLocation, useNavigate } from "react-router-dom";
import PaginationNew from "../../../components/UI/Table/PaginationNew";
import { ReqUserAsAllList, ReqUserAsPageList, ReqUserAsSearchList } from "../../../api/community/ASAPi";

function MngAs() {
    const location = useLocation();
    const navigate = useNavigate();
    const supportTypeList = DefaultASTypeSelectBox;
    const processTypeList = DefaultProcessSelectBox;
    const queryTypeList = DefaultASQueryTypeSelectBox;
    const [asList, setAsList] = useState([]);
    const [totalPage, setTotalPage] = useState();
    const [page, setPage] = useState(location.state?.page ?? 0);
    const [totalElements, setTotalElements] = useState();
    const [supportType, setSupportType] = useState(location.state?.supportType ?? "");
    const [processType, setProcessType] = useState(location.state?.processType ?? "");
    const [queryType, setQueryType] = useState("All");
    const [queryText, setQueryText] = useState(location.state?.queryText ?? "");

    const supportTypeHandler = (value) => {
        setSupportType(value);
    };

    const processTypeHandler = (value) => {
        setProcessType(value);
    };

    const queryTypeHandler = (value) => {
        setQueryType(value);
    };

    const queryTextHandler = (value) => {
        setQueryText(value);
    };

    const detailPageHandler = (link) => {
        navigate(link, { state: { page, processType, supportType, queryType, queryText } });
    };

    const findAllHandler = () => {
        setSupportType("");
        setProcessType("");
        setQueryType("All");
        setQueryText("");

        ReqUserAsAllList(
            (result) => {
                const curData = result.content.map((data) => {
                    return {
                        asId: data.id,
                        process: data.process,
                        asSupportTypeName: data.asSupportTypeName,
                        reqDate: data.reqDate,
                        seq: data.seq,
                        title: data.title,
                        requestName: data.requestName,
                    };
                });
                setAsList(curData);
                setTotalElements(result.totalElements);
                setTotalPage(result.totalPages);
                setPage(result.pageable.pageNumber);
            },
            (error_result) => {
                setAsList((prev) => prev);
                setTotalElements((prev) => prev);
                setTotalPage((prev) => prev);
                setPage((prev) => prev);
            }
        );
    };

    const searchCondition = {
        process: processType,
        supportType: supportType,
        queryType: queryType,
        queryText: queryText,
    };

    const searchHandler = () => {
        ReqUserAsSearchList(
            searchCondition,
            (result) => {
                const curData = result.content.map((data) => {
                    return {
                        asId: data.id,
                        process: data.process,
                        asSupportTypeName: data.asSupportTypeName,
                        reqDate: data.reqDate,
                        seq: data.seq,
                        title: data.title,
                        requestName: data.requestName,
                    };
                });
                setAsList(curData);
                setTotalElements(result.totalElements);
                setTotalPage(result.totalPages);
                setPage(result.pageable.pageNumber);
            },
            (error_result) => {
                setAsList((prev) => prev);
                setTotalElements((prev) => prev);
                setTotalPage((prev) => prev);
                setPage((prev) => prev);
            }
        );
    };

    const pageHandler = (value) => {
        setPage(value);
        ReqUserAsPageList(
            searchCondition,
            value,
            (result) => {
                const curData = result.content.map((data) => {
                    return {
                        asId: data.id,
                        process: data.process,
                        asSupportTypeName: data.asSupportTypeName,
                        reqDate: data.reqDate,
                        seq: data.seq,
                        title: data.title,
                        requestName: data.requestName,
                    };
                });
                setAsList(curData);
                setTotalElements(result.totalElements);
                setTotalPage(result.totalPages);
                setPage(result.pageable.pageNumber);
            },
            (error_result) => {
                setAsList((prev) => prev);
                setTotalElements((prev) => prev);
                setTotalPage((prev) => prev);
                setPage((prev) => prev);
            }
        );
    };

    const dataLoader = async () => {
        const response = await instance({
            method: "GET",
            url: `/api/v1/admin/board/as`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
            params: {
                page,
                support_type: supportType,
                process: processType,
                query_text: queryText,
                query_type: queryType,
            },
        }).catch(function (error) {
            if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
                return error.response;
            } else if (error.request) {
                console.log(error.request);
                return null;
            } else {
                console.log("Error", error.message);
                return null;
            }
        });
        if (response == null || response.status !== 200) {
            return [];
        }
        if (response.data.status === 200) {
            return response.data.data;
        } else {
            return [];
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            const response = await dataLoader();
            if (!response?.content?.length) return;
            const curData = response.content.map((data) => {
                return {
                    asId: data.id,
                    process: data.process,
                    asSupportTypeName: data.asSupportTypeName,
                    reqDate: data.reqDate,
                    seq: data.seq,
                    title: data.title,
                    requestName: data.requestName,
                };
            });
            setAsList(curData);
            setTotalElements(response.totalElements);
            setTotalPage(response.totalPages);
        };
        fetchData();
        navigate(location.pathname, { replace: true });
    }, []);

    return (
        <section>
            <div className={classes.admin_contents}>
                <h3>A/S 신청 - 목록</h3>
                <div className={`${classes.contents_wrap} ${classes.search_wrap}`}>
                    <div className={classes.admin_table_wrap}>
                        <table>
                            <colgroup>
                                <col style={{ width: "11%" }} />
                                <col style={{ width: "39%" }} />
                                <col style={{ width: "11%" }} />
                                <col style={{ width: "39%" }} />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>분류</th>
                                    <td>
                                        <SelectBoxNew
                                            className="admin_st"
                                            optionDatas={supportTypeList}
                                            setSelectItemCB={supportTypeHandler}
                                            showDefaultKey={supportType}
                                        />
                                    </td>
                                    <th>처리상태</th>
                                    <td>
                                        <SelectBoxNew
                                            className="admin_st"
                                            optionDatas={processTypeList}
                                            setSelectItemCB={processTypeHandler}
                                            showDefaultKey={processType}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>검색구분</th>
                                    <td colSpan={3}>
                                        <div className={classes.flex_row}>
                                            <SelectBoxNew
                                                className="admin_st"
                                                optionDatas={queryTypeList}
                                                setSelectItemCB={queryTypeHandler}
                                                showDefaultKey={queryType}
                                            />
                                            <Input
                                                className="admin_st"
                                                value={queryText}
                                                onChange={queryTextHandler}
                                                placeholder="검색어 입력"
                                            />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className={classes.bottom_btn_wrap}>
                            <Button className="border sm" onClick={searchHandler}>
                                검색
                            </Button>
                            <Button className="secondary sm" onClick={findAllHandler}>
                                전체보기
                            </Button>
                        </div>
                    </div>
                </div>
                <div className={classes.contents_wrap}>
                    <div className={`${classes.admin_table_wrap} ${classes.align_center}  ${classes.row_select}`}>
                        <p className={classes.counter}>
                            총 <b>{totalElements}</b>건
                        </p>
                        <table className={classes.left_col_3}>
                            <colgroup>
                                <col style={{ width: "5%" }} />
                                <col style={{ width: "15%" }} />
                                <col style={{ width: "55%" }} />
                                <col style={{ width: "10%" }} />
                                <col style={{ width: "10%" }} />
                                <col style={{ width: "10%" }} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th>분류</th>
                                    <th>신청내용</th>
                                    <th>신청일자</th>
                                    <th>작성자</th>
                                    <th>처리상태</th>
                                </tr>
                            </thead>
                            <tbody>
                                {asList.map((data, index) => (
                                    <tr key={index} onClick={() => detailPageHandler(`${data.asId}`)}>
                                        <td>{data.seq}</td>
                                        <td>{data.asSupportTypeName}</td>
                                        <td>{data.title}</td>
                                        <td>{data.reqDate}</td>
                                        <td>{data.requestName}</td>
                                        {data.process === "Request" && <td className="gray_c">접수</td>}
                                        {data.process === "Close" && <td>답변 완료</td>}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <PaginationNew
                            className="admin_st"
                            totalPage={totalPage}
                            currentPage={page}
                            defaultShowPage={5}
                            changePageCB={pageHandler}
                        />
                    </div>
                </div>
            </div>
        </section>
    );
}
export default MngAs;

//>>> Loader
export const loader = async () => {
    const response = await instance({
        url: `/api/v1/admin/board/as?page=0&size=10`,
        method: "GET",
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    }).catch(function (error) {
        if (error.response) {
            // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
            // console.log(error.response.data);
            // console.log(error.response.status);
            // console.log(error.response.headers);
            return error.response;
        } else if (error.request) {
            // 요청이 이루어 졌으나 응답을 받지 못했습니다.
            // console.log(error.request);
            return null;
        } else {
            // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
            // console.log("Error", error.message);
            return null;
        }
    });

    if (response == null || response.status !== 200) {
        return [];
    }
    if (response.data.status === 200) {
        return response.data.data;
    } else {
        return [];
    }
};
//<<< Loader
