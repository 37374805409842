import React, { useEffect, useState } from "react";
import Button from "../../../components/UI/Button/Button";
import classes from "../TournamentContainerApp.module.css";
import { useLoaderData, useNavigate, useParams } from "react-router";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import {
    ReqFindResultList,
    ReqFindRoundName,
    ReqFindSearchResultList,
} from "../../../api/home/TournamentHomeResultApi";
import SearchBox from "../../../components/UI/Input/SearchBox";
import SelectBoxNew from "../../../components/UI/SelectBox/SelectBoxNew";
import {
    DefaultCenterNameSelectBox,
    DefaultRoundName,
} from "../../../components/definedefault/DefSelectBoxValues";
import instance from "../../../store/AxiosInterceptor";
import ResultGalleryApp from "./ResultGalleryApp";
import Alert from "../../../components/UI/Modal/Alert";
import ResultContent from "./ResultContent";

function ResultDetailApp() {
    const resData = useLoaderData();
    const navigate = useNavigate();
    const params = useParams();
    const contestId = params.ctId;
    const [arr, setArr] = useState(0);
    const [placeList, setPlaceList] = useState([DefaultCenterNameSelectBox]);
    const [selectedPlaceId, setSelectedPlaceId] = useState("");
    const [searchText, setSearchText] = useState("");

    const [resultList, setResultList] = useState([]);
    const [fileUris, setFileUris] = useState([]);
    const [isSearch, setIsSearch] = useState(false);
    const [isValidText, setIsValidText] = useState(true);
    const [searchResultList, setSearchResultList] = useState([]);

    const [roundName, setRoundName] = useState("");
    const [roundNameList, setRoundNameList] = useState([DefaultRoundName]);
    const [resultContent, setResultContent] = useState([]);
    const [resultExcelYn, setResultExcelYn] = useState(false);

    const onValidTextHandler = (state) => {
        setIsValidText(state);
    };

    //검색 관리
    //참가자의 아이디 또는 이름으로 검색
    const onSearchHandler = () => {
        if (searchText.trim() === "") {
            onValidTextHandler(false);
            return;
        }
        ReqFindSearchResultList(
            contestId,
            searchText,
            (result) => {
                if (result.length <= 0) {
                    onChangeShowConfirmation(true);
                    return;
                }
                const curData = result.map((data) => {
                    return {
                        memberName: data.member_name,
                        memberId:
                            data.member_id.slice(0, 3) +
                            "*".repeat(data.member_id.length - 3),
                        roundName: data.round_name,
                        firstGame: data.first_game,
                        secondGame: data.second_game,
                        thirdGame: data.third_game,
                        fourthGame: data.fourth_game,
                        fifthGame: data.fifth_game,
                        handicap: data.handicap,
                        total: data.total,
                        centerName: data.center_name,
                        regionName: data.region_name,
                        contestTitle: data.contest_title,
                        roundRank: data.round_rank,
                    };
                });
                setSearchResultList(curData);
                setIsSearch(true);
            },
            (error_result) => {
                setSearchResultList((prev) => prev);
                onChangeShowConfirmation(true);
            }
        );
    };

    //모달 상태 관리
    const [showConfirmation, setShowConfirmation] = useState(false);
    const onChangeShowConfirmation = (state) => {
        setShowConfirmation(state);
    };

    const navigateHandler = () => {
        navigate("/tournament/result");
    };

    const onChangePlaceIdHandler = (key) => {
        if (!key) return;
        setIsSearch(false);
        setSelectedPlaceId(key);
    };

    const onChangeRoundNameHandler = (key) => {
        if (!selectedPlaceId) {
            alert("경기장을 선택 해주세요.");
            return;
        }
        setRoundName(key);
        findCompetitorResultListHandler(selectedPlaceId, key);
    };

    const searchTextChangeHandler = (value) => {
        setSearchText(value);
    };

    // 경기장별 경기명 가져오기
    const findRoundNameHandler = (placeId) => {
        if (!placeId) return;

        ReqFindRoundName(
            placeId,
            (result) => {
                const curData = result.map((data) => {
                    return {
                        key: data.round_name,
                        value: data.round_name,
                        disabled: false,
                    };
                });

                setRoundNameList((prev) => [...DefaultRoundName, ...curData]);

                if (result.length > 0) {
                    setRoundName(result[0]["round_name"]);
                }
            },
            (error_result) => {
                setRoundNameList((prev) => [...DefaultRoundName]);
                setRoundName((prev) => prev);
            }
        );
    };

    // 해당 대회, 라운드명(경기명) 별로  대회 결과 가져오기
    // SelectBox에서 경기장 1군데 디폴트, 1개의 경기명 디폴트로 선택되어있다.
    // 그후 셀렉트 박스들 변경 시
    const findCompetitorResultListHandler = (placeId, roundName) => {
        if (!roundName) return;

        ReqFindResultList(
            placeId,
            roundName,
            contestId,
            (result) => {
                const curData = result.data.datas.map((data) => {
                    return {
                        memberName: data.member_name,
                        memberId:
                            data.member_id.slice(0, 3) +
                            "*".repeat(data.member_id.length - 3),
                        roundName: data.round_name,
                        firstGame: data.first_game,
                        secondGame: data.second_game,
                        thirdGame: data.third_game,
                        fourthGame: data.fourth_game,
                        fifthGame: data.fifth_game,
                        handicap: data.handicap,
                        total: data.total,
                        roundRank: data.round_rank,
                    };
                });
                setResultList(curData);
                const imageUris = result.data.file_uris.map((data) => {
                    return {
                        uri: data.uri,
                    };
                });
                setFileUris(imageUris);
                setArr(imageUris.length);
                setResultContent(result.data.reusultContent);
                setResultExcelYn(result.data.resultExcelYn === "Y");
            },
            (error_result) => {
                setResultList((prev) => prev);
            }
        );
    };

    useEffect(() => {
        findCompetitorResultListHandler(selectedPlaceId, roundName);
    }, [selectedPlaceId, roundName]);

    useEffect(() => {
        const curData = resData.datas.map((data) => {
            return {
                contestId: data.contest_id,
                bowlingCenterId: data.bowling_center_id,
                key: String(data.place_id),
                value: `${data.region_name} (${data.center_name})`,
                resultId: String(data.result_id),
                disabled: data.result_expose_yn === "N", //노출이 N이면 노출X > disabled:true >> 사용X
            };
        });

        setPlaceList([DefaultCenterNameSelectBox, ...curData]);

        if (resData.datas.length > 0) {
            onChangePlaceIdHandler(String(resData.datas[0]["place_id"]));
        }

        findRoundNameHandler(resData.datas[0]["place_id"]);
    }, [resData]);

    useEffect(() => {
        findRoundNameHandler(selectedPlaceId);
    }, [selectedPlaceId]);

    return (
        <>
            <section
                className={`${classes.img_type} ${classes.result_detail_st}`}
            >
                <div className={classes.view_content}>
                    <div className={classes.search_wrap}>
                        <SelectBoxNew
                            className="default w_100"
                            optionDatas={placeList}
                            setSelectItemCB={onChangePlaceIdHandler}
                            showDefaultKey={selectedPlaceId}
                        />
                        <p className={classes.thanks}>
                            참가해주신 볼러 여러분 진심으로 감사합니다.
                        </p>
                        <div className={classes.search_box}>
                            <SearchBox
                                placeholder="아이디 또는 이름 입력"
                                onChange={searchTextChangeHandler}
                                searchHandler={onSearchHandler}
                                isValid={true}
                            />
                        </div>
                    </div>
                    <div className={classes.inner}>
                        <div>
                            <ResultContent
                                content={resultContent}
                            ></ResultContent>
                        </div>
                        {fileUris.length > 0 && (
                            <div className={classes.result_gallery_wrap}>
                                <div className={classes.result_gallery}>
                                    <ResultGalleryApp
                                        fileUris={fileUris}
                                        arr={arr}
                                    />
                                </div>
                            </div>
                        )}
                        {!isSearch && resultExcelYn && (
                            <div
                                className={`${classes.content_wrap} ${classes.table_st}`}
                            >
                                <div className={classes.inner_header}>
                                    <h4>경기별 결과</h4>
                                    <SelectBoxNew
                                        className="align_left border_st"
                                        optionDatas={roundNameList}
                                        setSelectItemCB={
                                            onChangeRoundNameHandler
                                        }
                                        showDefaultKey={roundName}
                                    />
                                </div>
                                <div className={classes.x_scroll}>
                                    <div
                                        className={`${classes.mobile_result_table} `}
                                    >
                                        <table>
                                            <colgroup>
                                                <col style={{ width: "5%" }} />
                                                <col style={{ width: "25%" }} />
                                                <col style={{ width: "10%" }} />
                                                <col style={{ width: "10%" }} />
                                                <col style={{ width: "10%" }} />
                                                <col style={{ width: "10%" }} />
                                                <col style={{ width: "10%" }} />
                                                <col style={{ width: "10%" }} />
                                                <col style={{ width: "10%" }} />
                                            </colgroup>
                                            <thead>
                                                <tr>
                                                    <th>순위</th>
                                                    <th>이름(아이디)</th>
                                                    <th>총점</th>
                                                    <th>핸디</th>
                                                    <th>1G</th>
                                                    <th>2G</th>
                                                    <th>3G</th>
                                                    <th>4G</th>
                                                    <th>5G</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {resultList.map(
                                                    (data, index) => (
                                                        <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td>
                                                                {
                                                                    data.memberName
                                                                }
                                                                ({data.memberId}
                                                                )
                                                            </td>
                                                            <td>
                                                                {data.total}
                                                            </td>
                                                            <td>
                                                                {data.handicap
                                                                    ? data.handicap
                                                                    : "-"}
                                                            </td>
                                                            <td>
                                                                {data.firstGame}
                                                            </td>
                                                            <td>
                                                                {
                                                                    data.secondGame
                                                                }
                                                            </td>
                                                            <td>
                                                                {data.thirdGame}
                                                            </td>
                                                            <td>
                                                                {
                                                                    data.fourthGame
                                                                }
                                                            </td>
                                                            <td>
                                                                {data.fifthGame}
                                                            </td>
                                                        </tr>
                                                    )
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        )}
                        {/* 검색 결과 테이블*/}
                        {isSearch && (
                            <div
                                style={{ display: "block" }}
                                className={`${classes.content_wrap} ${classes.search_result_table}`}
                            >
                                <h4>검색결과</h4>
                                {searchResultList.map((data) => (
                                    <>
                                        <h5>
                                            {data.contestTitle} (
                                            {data.regionName})
                                            <span className="primary_c">
                                                {data.roundName}
                                            </span>
                                        </h5>
                                        <div
                                            className={
                                                classes.mobile_result_table
                                            }
                                        >
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            {data.roundRank}위
                                                        </th>
                                                        <th colSpan={6}>
                                                            <span className="primary_c">
                                                                {
                                                                    data.memberName
                                                                }
                                                            </span>
                                                            ({data.memberId})
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th>총점</th>
                                                        <th>핸디</th>
                                                        <th>1G</th>
                                                        <th>2G</th>
                                                        <th>3G</th>
                                                        <th>4G</th>
                                                        <th>5G</th>
                                                    </tr>
                                                    <tr>
                                                        <td>{data.total}</td>
                                                        <td>
                                                            {data.handicap
                                                                ? data.handicap
                                                                : "-"}
                                                        </td>
                                                        <td>
                                                            {data.firstGame}
                                                        </td>
                                                        <td>
                                                            {data.secondGame}
                                                        </td>
                                                        <td>
                                                            {data.thirdGame}
                                                        </td>
                                                        <td>
                                                            {data.fourthGame}
                                                        </td>
                                                        <td>
                                                            {data.fifthGame}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </section>
            <div className={classes.bottom_btn_wrap}>
                <Button onClick={navigateHandler}>목록으로</Button>
            </div>
            {showConfirmation && (
                <Alert
                    type="alert"
                    confirm="확인"
                    close={() => onChangeShowConfirmation(false)}
                >
                    {"일치하는 결과가 없습니다."}
                </Alert>
            )}
            {!isValidText && (
                <Alert
                    type="alert"
                    confirm="확인"
                    close={() => onValidTextHandler(true)}
                >
                    {"검색하실 내용을 입력해주세요."}
                </Alert>
            )}
        </>
    );
}

export default ResultDetailApp;

//>>>대회 목록 뿌려주기 Loader
export const loader = async ({ params }) => {
    const response = await instance({
        method: "GET",
        url: `/api/v1/home/contest/result/${params.ctId}`,
    }).catch(function (error) {
        if (error.response) {
            // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            return error.response;
        } else if (error.request) {
            // 요청이 이루어 졌으나 응답을 받지 못했습니다.
            console.log(error.request);
            return null;
        } else {
            // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
            console.log("Error", error.message);
            return null;
        }
    });
    if (response == null || response.status !== 200) {
        return [];
    }
    if (response.data.status === 200) {
        return response.data;
    } else {
        return [];
    }
};
//<<<대회 목록 뿌려주기 Loader
