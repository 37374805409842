import { useState, useEffect } from "react";
import axios from "axios";
import { NavLink, useLoaderData, useNavigate } from "react-router-dom";

import classes from "../../MngCommon.module.css";
import Button from "../../../../components/UI/Button/Button";
import Checkbox from "../../../../components/UI/Input/Checkbox";
import Modal from "../../../../components/UI/Modal/Modal";
import {
    ReqCenterNameList,
    ReqCreateContestPlace,
    ReqExistedContestPlace,
    ReqRegionInfoList,
} from "../../../../api/tournament/ApiForContestMain";

const MngTournamentStadiumModal = (props) => {
    const { id: contestID } = props;

    const [region, setRegion] = useState([]);

    //지역 핸들러
    useEffect(() => {
        const fetchData = async () => {
            const response = await ReqRegionInfoList();
            if (response.status === 200) {
                const resData = await response.data;
                setRegion(resData.datas);
            }
        };
        fetchData();
    }, []);

    const [selectedRegion, setSelectedRegion] = useState(null);

    const [stadium, setStadium] = useState([]);

    // 지역 선택 후 경기장 핸들러
    const handleRegionCheckboxChange = async (e, id, region) => {
        setSelectedRegion({ id, region });
        const response = await ReqCenterNameList(region); // axios.get(`/api/v1/admin/contest/center?region=${region}`);
        if (response.status === 200) {
            const resData = await response.data;
            return setStadium((prev) => (resData.datas ? resData.datas : []));
        } else {
            alert(`요청 실패 (${response.status})`);
        }
    };

    // 대회에서 선택한 경기장
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await ReqExistedContestPlace(contestID);
                if (response.status === 200) {
                    return setRegisteredStadium(response.data.datas ? response.data.datas : []);
                }
            } catch (error) {
                console.error("Error:", error);
            }
        };

        fetchData();
    }, []);

    const [registeredStadium, setRegisteredStadium] = useState([]);

    const [selectedStadium, setSelectedStadium] = useState([]);

    // 경기장 선택 핸들러
    const handleStadiumCheckboxChange = async (e, id, name, region, useLaneCount, sLaneNumber, useReservLane) => {
        const isStadiumRegistered = registeredStadium.some((stadium) => stadium.center_id === id);

        if (isStadiumRegistered) {
            alert("경기장은 중복 선택 불가합니다");
            return;
        }

        if (e.target.checked) {
            // 선택된 체크박스 항목을 추가
            setSelectedStadium((prevSelectedStadium) => [
                ...prevSelectedStadium,
                { id, name, region, useLaneCount, sLaneNumber, useReservLane },
            ]);
        } else {
            // 선택 해제된 체크박스 항목을 제거
            setSelectedStadium((prevSelectedStadium) =>
                prevSelectedStadium.filter((item) => item.id !== id)
            );
        }
    };

    const closeModalHandler5 = (e) => {
        e.stopPropagation();
        props.isModalOpen5(false);
    };

    const stadiumAddHandler = async (e) => {
        e.preventDefault();
        if (selectedStadium.length === 0)
            return;
        const selectedStadiumIds = selectedStadium.map((stadium) => String(stadium.id));
        const jsonData = {
            contest_id: String(contestID),
            bowling_ids: selectedStadiumIds,
        };

        const response = await ReqCreateContestPlace(jsonData);
        if (response.status === 200) {
            alert("경기장이 등록되었습니다.");
            props.isModalOpen5(false);
        } else {
            alert("경기장 등록 실패");
        }
    };

    const stadiumCheckDelHandler = (e, id) => {
        setSelectedStadium((prevSelectedStadium) =>
            prevSelectedStadium.filter((item) => item.id !== id)
        );
    };

    const tilte = `[${props.id}] ${props.title}`;

    return (
        <section>
            (
                <Modal
                    className="admin_st"
                    close={closeModalHandler5}
                    header="경기장 등록"
                    desc={tilte}
                >
                    <main>
                        <h5>대회 등록할 지역과 경기장을 선택해주세요.</h5>
                        <div className={classes.table_st_wrap}>
                            <div className={classes.header}>
                                <div>지역</div>
                                <div>경기장</div>
                            </div>
                            <div className={classes.body}>
                                <div className={classes.scroll_box}>
                                    <div className={`${classes.col} ${classes.col_2} `}>
                                        <div className={classes.scroll_box}>
                                            {region.length !== 0 &&
                                                region.map(
                                                    (data, index) =>
                                                        index % 2 === 0 && (
                                                            <Checkbox
                                                                key={data.id}
                                                                className="admin_label"
                                                                value={data.name}
                                                                title={data.name}
                                                                id={`chk_1_${index}`}
                                                                checked={
                                                                    selectedRegion &&
                                                                    selectedRegion.id === data.id
                                                                }
                                                                onChange={(e) =>
                                                                    handleRegionCheckboxChange(
                                                                        e,
                                                                        data.id,
                                                                        data.name
                                                                    )
                                                                }
                                                            />
                                                        )
                                                )}
                                        </div>
                                        <div className={classes.scroll_box}>
                                            {region.length !== 0 &&
                                                region.map(
                                                    (data, index) =>
                                                        index % 2 !== 0 && (
                                                            <Checkbox
                                                                key={data.id}
                                                                className="admin_label"
                                                                value={data.name}
                                                                title={data.name}
                                                                id={`chk_1_${index}`}
                                                                checked={
                                                                    selectedRegion &&
                                                                    selectedRegion.id === data.id
                                                                }
                                                                onChange={(e) =>
                                                                    handleRegionCheckboxChange(
                                                                        e,
                                                                        data.id,
                                                                        data.name
                                                                    )
                                                                }
                                                            />
                                                        )
                                                )}
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.scroll_box}>
                                    <div className={classes.col}>
                                        {stadium.length !== 0 &&
                                            stadium.map((data, index) => (
                                                <Checkbox
                                                    key={data.center_id}
                                                    className="admin_label"
                                                    value={data.name}
                                                    title={data.name}
                                                    id={`chk_2_${index}`}
                                                    checked={selectedStadium.some(
                                                        (item) => item.id === data.center_id
                                                    )}
                                                    onChange={(e) =>
                                                        handleStadiumCheckboxChange(
                                                            e,
                                                            data.center_id,
                                                            data.name,
                                                            data.region,
                                                            data.use_lane_count,
                                                            data.lane_s_number,
                                                            data.use_reserv_lane
                                                        )
                                                    }
                                                />
                                            ))}
                                    </div>
                                </div>
                            </div>
                            <h4>
                                선택한 경기장 ({registeredStadium.length + selectedStadium.length})
                            </h4>
                            <div className={`${classes.table_st_wrap} ${classes.st_3}`}>
                                <div className={classes.scroll_box}>
                                    <div className={`${classes.body} `}>
                                        <div className={classes.col}>
                                            {registeredStadium.length !== 0 &&
                                                registeredStadium.map((data, index) => (
                                                    <div className={classes.list}>
                                                        <b>{data.region}</b>
                                                        <p>{data.name}</p>
                                                    </div>
                                                ))}
                                            {selectedStadium.length !== 0 &&
                                                selectedStadium.map((data, index) => (
                                                    <div className={classes.list}>
                                                        <b>{data.region}</b>
                                                        <p>{data.name}</p>
                                                        <i
                                                            className={classes.del_sm}
                                                            onClick={(e) =>
                                                                stadiumCheckDelHandler(e, data.id)
                                                            }
                                                        >
                                                            ×
                                                        </i>
                                                    </div>
                                                ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                    <footer>
                        <Button className="secondary sm" onClick={stadiumAddHandler}>
                            등록
                        </Button>
                    </footer>
                </Modal>
            )
        </section>
    );
};

export default MngTournamentStadiumModal;
