import { useEffect, useState } from "react";
import Button from "../../../../components/UI/Button/Button";
import Modal from "../../../../components/UI/Modal/Modal";
import classes from "../../MngCommon.module.css";
import Moment from "moment";
import { ReqTermsDetail } from "../../../../api/site/ApiForTerms";

function MngUserTermsHistory(props) {
    const { isModalOpenCB, idCB } = props;

    const closeModalHandler = (e) => {
        e.stopPropagation();
        isModalOpenCB(false);
    };

    const [termsHist, setTermsHist] = useState();

    const fetchData = async () => {
        const response = await ReqTermsDetail(idCB);
        if (response.status === 200) {
            const resData = await response.data;
            setTermsHist(resData);
            // console.log(resData);
        } else {
            setTermsHist([]);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <section>
            <Modal
                className="admin_st"
                close={closeModalHandler}
                header="개정이력"
            >
                {termsHist !== undefined && (
                    <main>
                        <>
                            <div className={classes.admin_table_wrap}>
                                <table>
                                    <colgroup>
                                        <col style={{ width: "10%" }} />
                                        <col style={{ width: "40%" }} />
                                        <col style={{ width: "10%" }} />
                                        <col style={{ width: "40%" }} />
                                    </colgroup>
                                    <tbody>
                                        <tr>
                                            <th>제목</th>
                                            <td>{termsHist.title}</td>
                                            <th>개정일</th>
                                            <td>
                                                {Moment(
                                                    termsHist.regDate
                                                ).format("YYYY-MM-DD")}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>개정내용</th>
                                            <td colSpan={3}>
                                                {termsHist.modify}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className={classes.terms_box}>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: termsHist.content,
                                    }}
                                ></div>
                            </div>
                        </>
                    </main>
                )}
                <footer>
                    <Button
                        className="secondary sm"
                        onClick={closeModalHandler}
                    >
                        확인
                    </Button>
                </footer>
            </Modal>
        </section>
    );
}

export default MngUserTermsHistory;
