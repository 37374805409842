import { NavLink } from "react-router-dom";
import classes from "../MngCommon.module.css";

function MngEventNavigation(props) {
    return (
        <div className={classes.admin_sub_nav}>
            <nav>
                <ul className={classes.depth_1}>
                    <li className={classes.depth_1_link}>
                        <NavLink to="list">이벤트관리</NavLink>
                    </li>
                    <li className={classes.depth_1_link}>
                        <NavLink to="winner">당첨자발표</NavLink>
                    </li>
                    <li className={classes.depth_1_link}>
                        <NavLink to="result">
                            이벤트결과 등록 및<br />
                            조회
                        </NavLink>
                    </li>
                </ul>
            </nav>
        </div>
    );
}

export default MngEventNavigation;
