import Input from "./Input";
import classes from "./SearchBox.module.css";
function SearchBox({ className, placeholder, onChange, isValid, searchHandler }) {
    return (
        <div className={`${classes.search_box} ${classes[className]}`}>
            <Input className="search_input" placeholder={placeholder} onChange={onChange} />
            <button className={classes.btn_search} onClick={searchHandler}>
                검색
            </button>
            {isValid ? "" : <p className={classes.error_txt}>검색하실 내용을 입력해주세요.</p>}
        </div>
    );
}

export default SearchBox;
