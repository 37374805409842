import { useContext, useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Button from "../components/UI/Button/Button";
import classes from "./UserHomeApp.module.css";
import {
    ReqBannerList,
    ReqKegelHomeList,
    ReqNoticeHomeList,
    ReqOngoingTournamentList,
    ReqProductCatalogueHomeList,
    ReqRecommendProductHomeList,
    ReqResultList,
} from "../api/home/TournamentHomepageApi";
import { Link, useNavigate } from "react-router-dom";
import { JwtTokenContext } from "../store/JwtTokenProvider";
import HomeBannerApp from "./components/HomeBannerApp";
import OngoingTournamentUserApp from "./tournament/components/OngoingTournamentUserApp";
import { PopupModal } from "../PopupModal";
import { ReqSignOut } from "../api/auth/AuthApi";
import { ReqTopBanner } from "../api/home/TopBannerApi";
import axios from "axios";

function UserHomePage(props) {
    const loginCtx = useContext(JwtTokenContext);
    const navigate = useNavigate();
    const isUserLogin = loginCtx.isLogin()
        ? loginCtx.isAdmin()
            ? false
            : true
        : false;

    /* <TestEditor/> */
    /*<PostCreate height={500} width={800}/>*/
    /*PostCreate 컴포넌트 로드 시 파라미터 height 및 width에 숫자 입력*/
    // 메인 슬라이드
    const [slideIndex, setSlideIndex] = useState(0);
    const [updateCount, setUpdateCount] = useState(0);
    const sliderRef = useRef(null);
    const sliderSettings = {
        dots: true,
        lazyLoad: true,
        infinite: true,
        speed: 300,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        afterChange: () => setUpdateCount(updateCount + 1),
        beforeChange: (current, next) => setSlideIndex(next),
    };
    // 추천상품
    const [slideIndex_3, setSlideIndex_3] = useState(0);
    const [updateCount_3, setUpdateCount_3] = useState(0);
    const sliderRef_3 = useRef(null);
    const sliderSettings_3 = {
        lazyLoad: true,
        infinite: true,
        speed: 300,
        slidesToShow: 3,
        // slidesToScroll: 1,
        arrows: true,
        autoplay: false,
        afterChange: () => setUpdateCount_2(updateCount_2 + 1),
        beforeChange: (current, next) => setSlideIndex_2(next),
    };

    // KEGEL
    const [slideIndex_4, setSlideIndex_4] = useState(0);
    const [slideIndex_5, setSlideIndex_5] = useState(0);
    const [updateCount_4, setUpdateCount_4] = useState(0);
    const [updateCount_5, setUpdateCount_5] = useState(0);
    const sliderRef_4 = useRef(null);
    const sliderSettings_4 = {
        lazyLoad: true,
        infinite: true,
        speed: 300,
        slidesToShow: 2,
        initialSlide: 0,
        arrows: false,
        autoplay: false,
        afterChange: () => setUpdateCount_4(updateCount_4 + 1),
        beforeChange: (current, next) => setSlideIndex_4(next),
        // responsive: [
        //     {
        //         breakpoint: 790,
        //         settings: {
        //             slidesToShow: 2,
        //             initialSlide: 0,
        //             afterChange: () => setUpdateCount_5(updateCount_5 + 1),
        //             beforeChange: (current, next) => setSlideIndex_5(next),
        //         },
        //     },
        // ],
    };

    // 대회결과 슬라이드
    const [arr, setArr] = useState();
    const [slideIndex_2, setSlideIndex_2] = useState(0);
    const [updateCount_2, setUpdateCount_2] = useState(0);
    const sliderRef_2 = useRef(null);
    const sliderSettings_2 = {
        lazyLoad: true,
        infinite: arr > 4,
        speed: 300,
        slidesToShow: 4,
        // slidesToScroll: 1,
        arrows: true,
        autoplay: false,
        afterChange: () => setUpdateCount_2(updateCount_2 + 1),
        beforeChange: (current, next) => setSlideIndex_2(next),
        responsive: [
            {
                breakpoint: 1360,
                settings: {
                    arrows: false,
                },
            },
        ],
    };

    const signOutHandler = async () => {
        await ReqSignOut(
            loginCtx,
            () => {
                loginCtx.removeToken();
                navigate("/");
            },
            () => {
                alert("로그아웃 실패");
            }
        );
    };

    const contestApplyHandler = () => {
        navigate("/tournament/apply");
    };

    const contestResultHandler = () => {
        navigate("/tournament/result");
    };

    const noticeMoveHandler = () => {
        navigate("/media/notice");
    };

    /// >>> 노출로 설정된 대회 메인 배너만 출력
    const [banners, setBanners] = useState([]);
    const [kegels, setKegels] = useState([]);
    const [notices, setNotices] = useState([]);
    const [resultList, setResultList] = useState([]);
    const [ongoingTournament, setOngoingTournament] = useState([]);
    const [recommendProduct, setrecommendProduct] = useState([]);
    const [productCatalogue, setProductCatalogue] = useState([]);

    const bannerHandler = () => {
        ReqBannerList(
            "home",
            (result) => {
                const curData = result.map((data) => {
                    return {
                        id: data.id,
                        title: data.title,
                        priority: data.priority,
                        exposeYn: data.expose_yn,
                        launchNewPage: data.launch_new_page,
                        linkUrl: data.link_url,
                        mobileLinkUrl: data.link_url_mobile,
                        fileUri: data.file_uri,
                        mobileFileUri: data.mobile_file_uri,
                    };
                });
                setBanners(curData);
            },
            (error_result) => {
                setBanners((prev) => prev);
            }
        );
    };
    /// <<< 노출로 설정된 대회 메인 배너만 출력

    // >>> 진행중인 대회
    const ongoingTournamentHandler = () => {
        ReqOngoingTournamentList(
            (result) => {
                const curData = result.map((data) => {
                    return {
                        contestId: data.contest_id,
                        placeId: data.place_id,
                        name: data.name,
                        type: data.type,
                        centerName: data.center_name,
                        regionName: data.region_name,
                        title: data.type_title,
                    };
                });
                setOngoingTournament(curData);
            },
            (eroor_result) => {
                setOngoingTournament((prev) => prev);
            }
        );
    };
    // <<< 진행중인 대회

    /// >>> 노출로 설정된 케겔 출력
    const kegelHandler = () => {
        ReqKegelHomeList(
            (result) => {
                const curData = result.datas.map((data) => {
                    return {
                        id: data.id,
                        title: data.title,
                        content: data.content,
                        imgUrl: data.imgUrl,
                        landingUrl: data.landingUrl,
                    };
                });
                setKegels(curData);
            },
            (error_result) => {
                setKegels((prev) => prev);
            }
        );
    };
    /// <<< 노출로 설정된 케겔 출력

    /// >>> 노출로 설정된 공지사항 출력
    const noticeHandler = () => {
        ReqNoticeHomeList(
            (result) => {
                const curData = result.datas.map((data) => {
                    return {
                        id: data.id,
                        title: data.title,
                        regDate: data.regDate,
                    };
                });
                setNotices(curData);
            },
            (error_result) => {
                setNotices((prev) => prev);
            }
        );
    };
    /// <<< 노출로 설정된 케겔 출력

    const resultListHandler = () => {
        ReqResultList(
            (result) => {
                const curData = result.datas?.map((data) => {
                    return {
                        contestId: data.contest_id,
                        contestName: data.contest_name,
                        centerName: data.center_name,
                        region: data.region,
                        resultId: data.result_id,
                        fileUri: data.file_uri,
                    };
                });
                setResultList(curData);
                setArr(curData.length);
            },
            (error_result) => {
                setResultList((prev) => prev);
            }
        );
    };

    /// >>> 노출로 설정된 추천상품 출력
    const recommendProductHandler = () => {
        ReqRecommendProductHomeList(
            (result) => {
                const curData = result.data?.map((data) => {
                    return {
                        title: data.title,
                        tabSeq: data.tabSeq,
                        pageUrl: data.pageUrl,
                        itemList: data.itemList,
                    };
                });
                setrecommendProduct(curData);
                setProductStatus(curData[0].tabSeq)
                setArr(curData.length);
            },
            (error_result) => {
                setrecommendProduct((prev) => prev);
            }
        );
    };
    /// <<< 노출로 설정된 추천상품 출력


    /// >>> 노출로 설정된 카탈로그 출력
    const productCatalogueHandler = () => {
        ReqProductCatalogueHomeList(
            (result) => {
                const curData = result.data?.map((data) => {
                    return {
                        title: data.title,
                        attach_uri : data.attach_uri,
                        originalFileName : data.attachFile != null ? data.attachFile.originalFileName : null,
                    };
                });
                setProductCatalogue(curData);
                setArr(curData.length);
            },
            (error_result) => {
                setProductCatalogue((prev) => prev);
            }
        );
    };
    /// <<< 노출로 설정된 카탈로그 출력

    const [topBanner, setTopBanner] = useState(""); // 띠 배너

    const topBannerHandler = () => {
        ReqTopBanner(
            (bannerData) => {
                setTopBanner(bannerData);
            },
            (error) => {
                console.error("데이터를 불러오는 중 오류 발생:", error);
            }
        );
    };

    // 띠 배너 링크 핸들러
    const topBannerLinkHandler = () => {
        if (topBanner && topBanner.newTap) { 
            // 새 창으로 열기
            const endPoint = ".com";
            const lastIndex = topBanner.url.indexOf(endPoint);
            const uri = topBanner.url.substring(lastIndex+4);
            const newUrl = window.location.origin + uri;
    
            window.open(newUrl, "_blank");
        } else if (topBanner) { 
            // 현재 창에서 열기
            const endPoint = ".com";
            const lastIndex = topBanner.url.indexOf(endPoint);
            const uri = topBanner.url.substring(lastIndex+4);
            const newUrl = window.location.origin + uri;
    
            navigate(newUrl);
        }
    };

    useEffect(() => {
        topBannerHandler();
        bannerHandler();
        ongoingTournamentHandler();
        kegelHandler();
        noticeHandler();
        resultListHandler();
        recommendProductHandler();
        productCatalogueHandler();
    }, []);

    const handleStormButtonClick = (pdf) => {
        const link = document.createElement("a");
        link.href = pdf;
        link.target = "_blank"; // Open in a new tab
        link.rel = "noopener noreferrer";
        link.download = "Catalog.pdf"; // Name of the downloaded file

        link.click();
    };

    // 20230914 [A] 카탈로그 BO적재후 주석 해제
    // const handleStormButtonClick = async (pdf, originalFileName) => {
    //     try {
    //         const response = await axios.get(`${pdf}`, {
    //             responseType: "blob", // 파일을 Blob 형식으로 받기 위해 설정
    //         });

    //         const blob = new Blob([response.data], {
    //             type: response.headers["content-type"],
    //         });
    //         const url = window.URL.createObjectURL(blob);
    //         const link = document.createElement("a");
    //         link.href = url;
    //         link.download = originalFileName;
    //         link.click();
    //         window.URL.revokeObjectURL(url);
    //     } catch (error) {
    //         console.error("파일 다운로드 에러:", error);
    //     }
    // };

    const [productStatus, setProductStatus] = useState(0);
    const productStatusHandler = (status) => {
        console.log(productStatus)
        setProductStatus(status);
    };
    const productNavigateHanler = (link) => {
        navigate(link);
    };

    const moveToContestDetail = (link) => {
        navigate(link);
    };

    return (
        <section className={`container ${classes.official_main_wrap}`}>
            <div className={classes.official_main}>
                <article className={classes.visual_wrap}>
                    <div className={classes.official_main_slider}>
                        <Slider ref={sliderRef} {...sliderSettings}>
                            {banners.map((banner) => (
                                <HomeBannerApp
                                    key={banner.id}
                                    mobileFileUri={banner.mobileFileUri}
                                    title={banner.title}
                                    launchNewPage={banner.launchNewPage}
                                    // linkUrl={banner.mobileLinkUrl}
                                    linkUrl={banner.linkUrl}
                                />
                            ))}
                        </Slider>
                    </div>
                </article>
            </div>
            {/* 230904 띠배너 추가 */}
            {topBanner && (
                <div
                    className={classes.band_banner}
                    onClick={topBannerLinkHandler}
                >
                    <h4>{topBanner.title}</h4>
                    <p>{topBanner.subTitle}</p>
                </div>
            )}
            {/* //230904 띠배너 추가 */}
            <article className={classes.recommend_product_wrap}>
                <div className={classes.slider_wrap}>
                    <div className={classes.contents_wrap}>
                        <h2>
                            진승의 추천상품을
                            <br />
                            만나보세요
                        </h2>
                        <div className={classes.category_wrap}>
                            <ul>
                                {recommendProduct.length !== 0 && recommendProduct.map((data, index) => (
                                    <li className={`${productStatus === data.tabSeq ? classes.on : ""}`} onClick={() => productStatusHandler(data.tabSeq)}>{data.title}</li>
                                ))} 
                                {/* <li
                                    className={`${
                                        productStatus === "STORM"
                                            ? classes.on
                                            : ""
                                    }`}
                                    onClick={() =>
                                        productStatusHandler("STORM")
                                    }
                                >
                                    Storm
                                </li>
                                <li
                                    className={`${
                                        productStatus === "ROTOGRIP"
                                            ? classes.on
                                            : ""
                                    }`}
                                    onClick={() =>
                                        productStatusHandler("ROTOGRIP")
                                    }
                                >
                                    Roto Grip
                                </li>
                                <li
                                    className={`${
                                        productStatus === "NEW"
                                            ? classes.on
                                            : ""
                                    }`}
                                    onClick={() => productStatusHandler("NEW")}
                                >
                                    New
                                </li> */}
                            </ul>
                        </div>
                        <div className={classes.product_slider}>
                            {recommendProduct.length !== 0 && recommendProduct.map((recommend, index) => (
                                productStatus === recommend.tabSeq && (
                                    <Slider ref={sliderRef_3} {...sliderSettings_3}>
                                        {recommend.itemList.length !== 0 && recommend.itemList.map((data, itemIndex) => (
                                            <div className={classes.item} onClick={() => (window.location.href = data.pageUrl)} key={itemIndex}>
                                                <p>
                                                    <span>{recommend.title}</span>
                                                    {data.name}
                                                </p>
                                                <img src={data.imgUrl} alt={data.name} />
                                            </div>
                                        ))}
                                    </Slider>
                                )
                            ))} 
                            {/* {productStatus === "STORM" && (
                                <Slider ref={sliderRef_3} {...sliderSettings_3}>
                                    <div
                                        className={classes.item}
                                        onClick={() =>
                                            productNavigateHanler("/balls/155")
                                        }
                                    >
                                        <p>
                                            <span>STORM</span>
                                            PHYSIX TOUR
                                        </p>
                                        <img src={"/api/v1/attach/user/20230829b36901206c4f45bd9ea9f58b66dd7066.jpg"} alt="PHYSIX TOUR" />
                                    </div>
                                    <div
                                        className={classes.item}
                                        onClick={() =>
                                            productNavigateHanler("/balls/152")
                                        }
                                    >
                                        <p>
                                            <span>STORM</span>
                                            DOMINATION BURGUNDY
                                        </p>
                                        <img src={"/api/v1/attach/user/20230829a9261763a57b4c99abacbff2825e167f.jpg"} alt="DOMINATION BURGUNDY" />
                                    </div>
                                    <div
                                        className={classes.item}
                                        onClick={() =>
                                            productNavigateHanler("/balls/314")
                                        }
                                    >
                                        <p>
                                            <span>STORM</span>
                                            REVENANT WAR
                                        </p>
                                        <img src={"/api/v1/attach/user/2023082914d9b7b0533a4ee2922125012805daa2.jpg"} alt="REVENANT WAR" />
                                    </div>
                                    <div
                                        className={classes.item}
                                        onClick={() =>
                                            productNavigateHanler("/balls/244")
                                        }
                                    >
                                        <p>
                                            <span>STORM</span>
                                            SUMMIT
                                        </p>
                                        <img src={"/api/v1/attach/user/20230829b8ee0afb9add4f9ba071f1d18d277702.jpg"} alt="SUMMIT" />
                                    </div>
                                    <div
                                        className={classes.item}
                                        onClick={() =>
                                            productNavigateHanler("/balls/150")
                                        }
                                    >
                                        <p>
                                            <span>STORM</span>
                                            DARK PHYSIX
                                        </p>
                                        <img src={"/api/v1/attach/user/202308293f35b07e647d43d2b8ba2dd08a1ba6a0.jpg"} alt="DARK PHYSIX" />
                                    </div>
                                </Slider>
                            )}
                            {productStatus === "ROTOGRIP" && (
                                <Slider ref={sliderRef_3} {...sliderSettings_3}>
                                    <div
                                        className={classes.item}
                                        onClick={() =>
                                            productNavigateHanler("/balls/614")
                                        }
                                    >
                                        <p>
                                            <span>ROTO GRIP</span>
                                            TOUR DYNAM-X
                                        </p>
                                        <img src={"/api/v1/attach/user/20230829099b78b39fbd482ca71bdf1d79842a28.jpg"} alt="TOUR DYNAM-X" />
                                    </div>
                                    <div
                                        className={classes.item}
                                        onClick={() =>
                                            productNavigateHanler("/balls/682")
                                        }
                                    >
                                        <p>
                                            <span>ROTO GRIP</span>
                                            HUSTLE RIP
                                        </p>
                                        <img src={"/api/v1/attach/user/20230829a67bfbe370c741db834caa5d7380eb81.jpg"} alt="HUSTLE RIP" />
                                    </div>
                                    <div
                                        className={classes.item}
                                        onClick={() =>
                                            productNavigateHanler("/balls/568")
                                        }
                                    >
                                        <p>
                                            <span>ROTO GRIP</span>
                                            CLONE
                                        </p>
                                        <img src={"/api/v1/attach/user/20230829467cbf322003492aba7dd1db7be67e85.jpg"} alt="CLONE" />
                                    </div>
                                    <div
                                        className={classes.item}
                                        onClick={() =>
                                            productNavigateHanler("/balls/613")
                                        }
                                    >
                                        <p>
                                            <span>ROTO GRIP</span>
                                            RUBICON ATTACK
                                        </p>
                                        <img src={"/api/v1/attach/user/20230829c2b9b190aa954c01b94610297d1942c0.jpg"} alt="RUBICON ATTACK" />
                                    </div>
                                    <div
                                        className={classes.item}
                                        onClick={() =>
                                            productNavigateHanler("/balls/565")
                                        }
                                    >
                                        <p>
                                            <span>ROTO GRIP</span>
                                            ATTENTION SCARLET
                                        </p>
                                        <img src={"/api/v1/attach/user/20230829f9dcaa371c5b4e1a9c69271511cf3c3c.jpg"} alt="ATTENTION SCARLET" />
                                    </div>
                                </Slider>
                            )}
                            {productStatus === "NEW" && (
                                <Slider ref={sliderRef_3} {...sliderSettings_3}>
                                    <div
                                        className={classes.item}
                                        onClick={() =>
                                            productNavigateHanler("/bags/842")
                                        }
                                    >
                                        <p>
                                            <span>NEW</span>
                                            ROTO GRIP ALL-STAR EDITION 3-BALL
                                            ROLLER
                                        </p>
                                        <img src={"/api/v1/attach/user/20230829c6a61f7708ef4cf19341612cf509480b.jpg"} alt="ROTO GRIP ALL-STAR EDITION 3-BALL ROLLER" />
                                    </div>
                                    <div
                                        className={classes.item}
                                        onClick={() =>
                                            productNavigateHanler("/bags/841")
                                        }
                                    >
                                        <p>
                                            <span>NEW</span>
                                            ROTO GRIP ALL-STAR EDITION 2-BALL
                                            ROLLER
                                        </p>
                                        <img src={"/api/v1/attach/user/20230829206d687d239a45e1bb759b60067c63ec.jpg"} alt="ROTO GRIP ALL-STAR EDITION 2-BALL ROLLER" />
                                    </div>
                                    <div
                                        className={classes.item}
                                        onClick={() =>
                                            productNavigateHanler("/bags/840")
                                        }
                                    >
                                        <p>
                                            <span>NEW</span>
                                            ROTO GRIP ALL-STAR EDITION 1-BALL
                                            SPARE KIT
                                        </p>
                                        <img src={"/api/v1/attach/user/20230829d2b660e27bf5484fb71ddfdf2b8067a4.jpg"} alt="ROTO GRIP ALL-STAR EDITION 1-BALL SPARE KIT" />
                                    </div>
                                    <div
                                        className={classes.item}
                                        onClick={() =>
                                            productNavigateHanler(
                                                "/products/1094"
                                            )
                                        }
                                    >
                                        <p>
                                            <span>NEW</span>
                                            MASTER SKIN PATCH
                                        </p>
                                        <img src={"/api/v1/attach/user/20230829125e0adddd3f4e1a8258ba1a4a448be6.jpg"} alt="MASTER SKIN PATCH" />
                                    </div>
                                    <div
                                        className={classes.item}
                                        onClick={() =>
                                            productNavigateHanler(
                                                "/products/1124"
                                            )
                                        }
                                    >
                                        <p>
                                            <span>NEW</span>
                                            스톰 썬더 클리너(티슈형)
                                        </p>
                                        <img src={"/api/v1/attach/user/20230829ea0d9b30da684c6981bdd046e024cb6b.png"} alt="스톰 썬더 클리너(티슈형)" />
                                    </div>
                                </Slider>
                            )} */}
                        </div>
                    </div>
                </div>
            </article>
            <article className={`${classes.blue} ${classes.kegel_slide_wrap}`}>
                <div className={classes.contents_wrap}>
                    <h2>KEGEL</h2>
                    {/* 모바일에서 슬라이드로 변경 */}
                    <div className={classes.kegel_wrap}>
                        <Slider ref={sliderRef_4} {...sliderSettings_4}>
                            {kegels.length !== 0 &&
                                kegels.map((data, index) => (
                                    <div
                                        className={classes.item}
                                        key={index}
                                        onClick={() =>
                                            (window.location.href =
                                                data.landingUrl)
                                        }
                                    >
                                        <img
                                            src={data.imgUrl}
                                            alt="kegel 이미지"
                                        />
                                        <h3>{data.title}</h3>
                                        <p>{data.content}</p>
                                    </div>
                                ))}
                        </Slider>
                    </div>
                </div>
            </article>

            <article className={classes.tournament_list_wrap}>
                <div className={classes.contents_wrap}>
                    <h2>진행중 대회</h2>
                    <ul className={classes.tournament_list}>
                        {ongoingTournament.map((data, index) => (
                            <OngoingTournamentUserApp
                                onClick={() =>
                                    moveToContestDetail(
                                        `/tournament/apply/${data.contestId}/${data.placeId}`
                                    )
                                }
                                key={index}
                                contestId={data.contestId}
                                placeId={data.placeId}
                                name={data.name}
                                type={data.type}
                                title={data.title}
                                centerName={data.centerName}
                                regionName={data.regionName}
                            />
                        ))}
                    </ul>
                    {/* 모바일에서 대회 바로가기 버튼 위치 / 클래스명 변경 */}
                    <div className={classes.btn_center}>
                        <Button
                            className="secondary"
                            onClick={contestApplyHandler}
                        >
                            대회 바로가기
                        </Button>
                    </div>
                </div>
            </article>
            {/* 진승측 요청으로 인해 대회 결과 마이그레이션 전까지*/}
            <article
                className={`${classes.blue} ${classes.result_slider_wrap}`}
            >
                <div className={classes.contents_wrap}>
                    <h2>
                        대회결과
                        <Button
                            className="btn_text"
                            onClick={contestResultHandler}
                        >
                            대회결과 더보기
                        </Button>
                    </h2>
                    <div className={classes.contents_slider}>
                        <Slider ref={sliderRef_2} {...sliderSettings_2}>
                            {resultList.map((data, index) => (
                                <div className={classes.content}>
                                    <img
                                        src={`${data.fileUri}`}
                                        alt="대회 대표 이미지"
                                    />
                                    <span>
                                        {data.region}({data.centerName})
                                    </span>
                                    <p>{data.contestName}</p>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </article>
            <article>
                <div className={classes.contents_wrap}>
                    <div className={classes.catalog_wrap}>
                        <div className={classes.catalog_box}>
                            <h2>Catalog</h2>
                            <ul>
                                {/* 20230914 [A] 카탈로그 BO적재후 주석 해제
                                {productCatalogue.length !== 0 && productCatalogue.map((data, index) => (
                                    <li>
                                        <Button
                                            className="btn_text white lg"
                                            onClick={() => handleStormButtonClick(data.attach_uri, data.originalFileName)}
                                        >
                                            {data.title}
                                        </Button>
                                    </li>
                                ))} */}
                                <li>
                                    <Button
                                        className="btn_text white lg"
                                        onClick={() =>
                                            handleStormButtonClick(
                                                "http://publicftp.stormbowling.com/2023_SPI_Catalog.pdf"
                                            )
                                        }
                                    >
                                        STORM
                                    </Button>
                                </li>
                                {/* <li>
                                    <Button className="btn_text white lg">
                                        ROTO GRIP
                                    </Button>
                                </li> */}
                                <li>
                                    <Button
                                        className="btn_text white lg"
                                        onClick={() =>
                                            handleStormButtonClick(
                                                "https://kegel.box.com/shared/static/ko5x3jqsbm1eausr128eckgl1bom84bd.pdf"
                                            )
                                        }
                                    >
                                        KEGEL
                                    </Button>
                                </li>
                            </ul>
                        </div>
                        <div className={classes.faq_box}>
                            <h2>FAQ</h2>
                            <div>
                                <Link to={`/media/faq`}>
                                    <Button className="btn_text white lg">
                                        자세히보기
                                    </Button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </article>
            <article className={classes.notice}>
                <div className={classes.contents_wrap}>
                    <div className={classes.notice_wrap}>
                        <h2>
                            공지사항
                            {/* 모바일에서 버튼 추가 */}
                            <Button onClick={noticeMoveHandler}>더보기</Button>
                        </h2>
                        <ul className={classes.list}>
                            {notices.map((data) => (
                                <Link to={`/media/notice/${data.id}`}>
                                    <li>
                                        <b>{data.title}</b>
                                        <span className={classes.date}>
                                            {data.regDate}
                                        </span>
                                    </li>
                                </Link>
                            ))}
                        </ul>
                    </div>
                </div>
            </article>
            <article className={classes.sns_article}>
                <div className={classes.contents_wrap}>
                    <div className={classes.sns_wrap}>
                        <h3>진승 SNS 채널 바로가기</h3>
                        <ul>
                            <li className={classes.official_insta}>
                                <a
                                    href="https://www.instagram.com/stormrotokorea/"
                                    target="_blank"
                                >
                                    진승 인스타그램
                                </a>
                            </li>
                            <li className={classes.mall_insta}>
                                <a
                                    href="https://www.instagram.com/stormrotokorea_mall/"
                                    target="_blank"
                                >
                                    진승몰 인스타그램
                                </a>
                            </li>
                            <li className={classes.youtube}>
                                <a
                                    href="https://www.youtube.com/@stormrotokorea"
                                    target="_blank"
                                >
                                    진승 유튜브
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </article>
            <PopupModal isMobileCB={true} />
        </section>
    );
}

export default UserHomePage;
