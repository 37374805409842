import React from "react"
import { NavLink, useLocation } from "react-router-dom";
import classes from "./Kegel.module.css";
import { useState } from "react";

function KegelNavigation(props) {
    const location = useLocation();
    const isDepth1Active =
        location.pathname.includes("/service") ||
        location.pathname.includes("/video");
    const [openDepth, setOpenDepth] = useState(false);
    const toggleDepth = (prev) => {
        setOpenDepth((prev) => !prev);
    };
    const closeDepth = (prev) => {
        setOpenDepth(false);
    };
    return (
        <div className={classes.kegel_sub_nav}>
            <h3>케겔</h3>
            <nav>
                <ul className={classes.depth_1}>
                    <li className={classes.depth_1_link} onClick={closeDepth}>
                        <NavLink to="machine">레인정비기계</NavLink>
                    </li>
                    <li className={classes.depth_1_link} onClick={closeDepth}>
                        <NavLink to="consumable">레인정비소모품</NavLink>
                    </li>
                    <li className={classes.depth_1_link} onClick={closeDepth}>
                        <NavLink to="training_materials">훈련도구</NavLink>
                    </li>
                    <li className={`${classes.depth_1}`}>
                        <NavLink
                            to="usefulInfo"
                            className={`${isDepth1Active ? classes.on : null} ${
                                openDepth ? classes.on : null
                            }`}
                            onClick={toggleDepth}
                        >
                            레인정비기계 서비스
                        </NavLink>
                        <ul className={classes.depth_2}>
                            <li>
                                <NavLink to="usefulInfo">유용한 정보</NavLink>
                            </li>
                            <li>
                                <NavLink to="help">문제해결 도우미</NavLink>
                            </li>
                            <li>
                                <NavLink to="video">유용한 동영상</NavLink>
                            </li>
                        </ul>
                    </li>
                </ul>
            </nav>
        </div>
    );
}

export default KegelNavigation;
