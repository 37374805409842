/* eslint-disable react/prop-types */
import React from 'react'
import classes from "./../Products.module.css";

export const ProductItem = ({ onClick = () => {},imgSrc = '', alt, title}) => 
(<div
    className={classes.box}
    onClick={onClick}
>
    <div className={classes.img_box}>
        <img src={imgSrc} alt={alt} />
    </div>
    <p>{title}</p>
</div>
)