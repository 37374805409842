import { useState } from "react";
import Button from "../../../components/UI/Button/Button";
import FileBox from "../../../components/UI/Input/FileBox";
import Input from "../../../components/UI/Input/Input";
import Radio from "../../../components/UI/Input/Radio";
import SelectBox from "../../../components/UI/SelectBox/SelectBox";
import Pagination from "../../../components/UI/Table/Pagination";
import classes from "../MngCommon.module.css";
import instance from "../../../store/AxiosInterceptor";
import { ReqUpdateKegelVideo } from "../../../api/kegel/KegelAPi";
import { useLoaderData, useLocation, useNavigate } from "react-router-dom";
import FileBoxMj from "../../../components/UI/Input/FileBoxMj";
import QuillEditor from "../../../components/UI/Editer/QuillEditor";

function MngKegelVideoUpdate() {
    const location = useLocation();
    const navigate = useNavigate();
    const kegelData = useLoaderData();

    const toList = () => {
        navigate("/admin/kegel/video", {state: location.state});
    };

    //[S] 수정 데이터 --------------------
    const [title, setTitle] = useState(kegelData.title);
    const [content, setContent] = useState(kegelData.content);
    const [exposeYn, setExposeYn] = useState(kegelData.exposeYn);
    const isExposeChangeHandler = (e) => {
        setExposeYn(e.target.value);
    };
    const [file, setFile] = useState();
    const [fileName, setFileName] = useState(kegelData.attach_uri || "");

    const titleHandler = (value) => {
        setTitle(value);
    };
    const contentHandler = (value) => {
        setContent(value);
    };

    const handleFileChange = (file) => {
        setFile(file);
        setFileName(file ? file.name : "");
    };

    //[E] 수정 데이터 --------------------

    const editHandler = async (e) => {
        e.preventDefault();

        const jsonData = JSON.stringify({
            title: title,
            content: content,
            exposeYn: exposeYn,
            subDescription: "",
        });

        const formData = new FormData();
        formData.append("image", file);
        formData.append(
            "body",
            new Blob([jsonData], { type: "application/json" })
        );

        const response = await ReqUpdateKegelVideo(kegelData.id, formData);
        if (response.status === 200) {
            navigate("/admin/kegel/video", {state: location.state});
        } else {
            alert(
                `[${response.status}] ${
                    response.data ? response.data.error : "수정 실패"
                } `
            );
        }
    };
    return (
        <section>
            <div className={classes.admin_contents}>
                <h3>유용한 동영상 - 등록/수정</h3>
                <div
                    className={`${classes.contents_wrap} ${classes.search_wrap} `}
                >
                    <div className={classes.admin_table_wrap}>
                        <table>
                            <colgroup>
                                <col style={{ width: "159px" }} />
                                <col style={{ width: "620px" }} />
                                <col style={{ width: "159px" }} />
                                <col style={{ width: "620px" }} />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>등록일</th>
                                    <td>{kegelData.regDate}</td>
                                    <th>등록자</th>
                                    <td>{kegelData.regID}</td>
                                </tr>
                                <tr>
                                    <th>노출여부</th>
                                    <td colSpan={3}>
                                        <div className={classes.radio_wrap}>
                                            <Radio
                                                value="Y"
                                                title="노출"
                                                name="rad_01"
                                                id="rad_01_01"
                                                checked={exposeYn === "Y"}
                                                onChange={isExposeChangeHandler}
                                            />
                                            <Radio
                                                value="N"
                                                title="미노출"
                                                name="rad_01"
                                                id="rad_01_02"
                                                checked={exposeYn === "N"}
                                                onChange={isExposeChangeHandler}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>영상 제목</th>
                                    <td colSpan={3}>
                                        <Input
                                            className="admin_st w_100"
                                            value={title}
                                            onChange={titleHandler}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>썸네일 이미지</th>
                                    <td colSpan={3}>
                                        <FileBoxMj
                                            onFileChange={handleFileChange}
                                            selectedFileName={fileName}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>영상</th>
                                    <td colSpan={3}>
                                        {/* <div style={{ height: "500px" }}>
                                            <QuillEditor
                                                height="460"
                                                width="auto"
                                                onValueChangeCB={contentHandler}
                                                value={content}
                                            />
                                        </div> */}
                                        <Input
                                            className="admin_st w_100"
                                            value={content}
                                            onChange={contentHandler}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className={classes.bottom_btn_wrap}>
                            <Button className="border sm" onClick={editHandler}>
                                수정
                            </Button>
                            <Button className="secondary sm" onClick={toList}>
                                목록
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default MngKegelVideoUpdate;
export const loader = async ({ params }) => {
    try {
        const response = await instance.get(
            `/api/v1/admin/kegel/videoGuide/${params.kegelId}`,
            {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            }
        );

        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error("Failed to fetch data");
        }
    } catch (error) {
        console.error("Error fetching data:", error);
        return null;
    }
};
