import { useContext, useEffect, useState } from "react";
import classes from "../MngCommon.module.css";
import Button from "../../../components/UI/Button/Button";
import Input from "../../../components/UI/Input/Input";
import FileBox, { FileBoxForImage } from "../../../components/UI/Input/FileBox";
import Radio from "../../../components/UI/Input/Radio";
import Pagination from "../../../components/UI/Table/Pagination";
import DateRangePicker from "../../../components/UI/DatePicker/DateRangePicker";
import SelectBoxNew from "../../../components/UI/SelectBox/SelectBoxNew";
import { useLoaderData, useNavigate } from "react-router-dom";
import QuillEditor from "../../../components/UI/Editer/QuillEditor";
import { ReqCreateForAdmin, ReqCreateNoticesForAdmin } from "../../../api/board/BoardApi";
import { JwtTokenContext } from "../../../store/JwtTokenProvider";
import FileBoxMj from "../../../components/UI/Input/FileBoxMj";

function MngCommunityUpload() {
    const navigate = useNavigate();
    const loginCtx = useContext(JwtTokenContext);
    const [enteredTitle, setEnteredTitle] = useState("");
    const [content, setContent] = useState("");
    const [topNotice, setTopNotice] = useState("N");
    const [useNotice, setUseNotice] = useState("N");
    const [fileBoxes, setFileBoxes] = useState([]);
    const [files, setFiles] = useState([]); // 배열로 파일 관리
    const [fileNames, setFileNames] = useState([]); // 파일 이름을 관리하는 배열

    const toList = () => {
        navigate("/admin/community/notice");
    };

    const titleChangeHandler = (e) => {
        setEnteredTitle(e);
    };
    const contentChangeHandler = (content) => {
        setContent(content);
    };

    // 상단공지여부 라디오버튼
    const topNoticesHandler = (e) => {
        setTopNotice(e.target.value);
    };
    // 사용여부 라디오버튼
    const useNoticesHandler = (e) => {
        setUseNotice(e.target.value);
    };
        
    // 새 항목을 추가
    const handleAddFileBox = () => {
        setFileBoxes([...fileBoxes, null]);
        setFiles([...files, null]);
        setFileNames([...fileNames, ""]);
    };

    // 맨 마지막 항목을 삭제
    const handleDelFileBox = (index) => {
        if (fileBoxes.length === 0) {
            return; // 항목이 없을 경우 종료
        }
        
        const newFileBoxes = [...fileBoxes];
        newFileBoxes.pop(); // 맨 마지막 항목 삭제
        setFileBoxes(newFileBoxes);

        const newFiles = [...files];
        newFiles.pop();
        setFiles(newFiles);

        const newFileNames = [...fileNames];
        newFileNames.pop();
        setFileNames(newFileNames);
    };

    // 파일 변경 이벤트 핸들러
    const handleFileChange = (file, index) => {
        const newFiles = [...files];
        newFiles[index] = file;
        setFiles(newFiles);

        const newFileNames = [...fileNames];
        newFileNames[index] = file ? file.name : "";
        setFileNames(newFileNames);
    };

    // 등록일 (오늘 날짜 표시)
    const today = () => {
        const now = new Date();

        return (
            now.getFullYear() +
            "-" +
            ("0" + (now.getMonth() + 1)).slice(-2) +
            "-" +
            now.getDate()
        );
    };

    const submitHandler = async (e) => {
        e.preventDefault();

        if (enteredTitle.length === 0) {
            alert("제목을 입력해주세요.");
            return;
        }
        const jsonData = JSON.stringify({
            title: enteredTitle,
            content: content,
            exposeYn: useNotice,
            openOtherYn: useNotice,
            noticeYn: topNotice,
            boardWriteType: "NOMAL",
        });

        console.log(files);

        const formData = new FormData();
        files.forEach((image, index) => {
            formData.append("image", image);
        });
        formData.append(
            "body",
            new Blob([jsonData], { type: "application/json" })
        );

        const response = await ReqCreateNoticesForAdmin("notice", formData);
        if (response.status === 200) {
            navigate("/admin/community/notice");
        } else {
            alert(
                `[${response.status}] ${
                    response.data ? response.data.error : "등록 실패"
                } `
            );
        }
    };

    return (
        <section>
            <div className={classes.admin_contents}>
                <h3>공지사항 - 등록</h3>
                <div
                    className={`${classes.contents_wrap} ${classes.search_wrap} `}
                >
                    <div className={classes.admin_table_wrap}>
                        <table>
                            <colgroup>
                                <col style={{ width: "159px" }} />
                                <col style={{ width: "620px" }} />
                                <col style={{ width: "159px" }} />
                                <col style={{ width: "620px" }} />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>등록일</th>
                                    <td>{today()}</td>
                                    <th>등록자</th>
                                    <td>{loginCtx.decodedToken.jti}</td>
                                </tr>
                                <tr>
                                    <th>상단공지여부</th>
                                    <td>
                                        <div className={classes.radio_wrap}>
                                            <Radio
                                                value="Y"
                                                title="적용"
                                                name="rad_01"
                                                id="rad_01_01"
                                                checked={topNotice === "Y"}
                                                onChange={topNoticesHandler}
                                            />
                                            <Radio
                                                value="N"
                                                title="미적용"
                                                name="rad_01"
                                                id="rad_01_02"
                                                checked={topNotice === "N"}
                                                onChange={topNoticesHandler}
                                            />
                                        </div>
                                    </td>
                                    <th>사용여부</th>
                                    <td>
                                        <div className={classes.radio_wrap}>
                                            <Radio
                                                value="Y"
                                                title="사용"
                                                name="rad_02"
                                                id="rad_02_01"
                                                checked={useNotice === "Y"}
                                                onChange={useNoticesHandler}
                                            />
                                            <Radio
                                                value="N"
                                                title="미사용"
                                                name="rad_02"
                                                id="rad_02_02"
                                                checked={useNotice === "N"}
                                                onChange={useNoticesHandler}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>제목</th>
                                    <td colSpan={3}>
                                        <Input
                                            type="text"
                                            name="title"
                                            id="title"
                                            className="admin_st w_100"
                                            placeholder="제목 입력"
                                            value={enteredTitle}
                                            onChange={titleChangeHandler}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>내용</th>
                                    <td colSpan={3}>
                                        {" "}
                                        <div style={{ height: "500px" }}>
                                            <QuillEditor
                                                height="460"
                                                width="auto"
                                                value={content}
                                                onValueChangeCB={
                                                    contentChangeHandler
                                                }
                                            />
                                        </div>
                                    </td>
                                </tr>

                                <tr>
                                    <th>첨부파일</th>
                                    <td colSpan={3}>
                                        {/* {FileBoxForImage(
                                            imageSrc,
                                            handleFileChangeCallback,
                                            handleFileDeleteCallback
                                        )} */}
                                        {/* <FileBoxMj
                                            onFileChange={handleFileChange}
                                            selectedFileName={fileName}
                                        /> */}
                                        <Button className="border sm" onClick={handleAddFileBox}>항목추가하기</Button>
                                        <Button className="secondary sm" onClick={handleDelFileBox}>항목삭제하기</Button>
                                        <div>
                                            {fileBoxes.map((item, index) => (
                                                <div key={index}>
                                                    <FileBoxMj
                                                        onFileChange={(file) => handleFileChange(file, index)}
                                                        selectedFileName={fileNames[index]}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <div className={classes.bottom_btn_wrap}>
                            <Button
                                className="border sm"
                                onClick={submitHandler}
                            >
                                등록
                            </Button>
                            <Button className="secondary sm" onClick={toList}>
                                목록
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default MngCommunityUpload;
