import { useState } from "react";
import Button from "../../../../components/UI/Button/Button";
import Checkbox from "../../../../components/UI/Input/Checkbox";
import Input, { MultiInput } from "../../../../components/UI/Input/Input";
import Radio from "../../../../components/UI/Input/Radio";
import classes from "../../MngCommon.module.css";
import Modal from "../../../../components/UI/Modal/Modal";
import instance from "../../../../store/AxiosInterceptor";
import { useLoaderData, useLocation, useNavigate, useParams } from "react-router-dom";
import {
    DefaultMemberStatusSelectBox,
    defaultEmailHostList,
} from "../../../../components/definedefault/DefSelectBoxValues";
import SelectBoxNew from "../../../../components/UI/SelectBox/SelectBoxNew";
import DaumPostcode from "react-daum-postcode";
import {
    ReqFindAlimTalkHistoryForMemberChaneInfo,
    ReqMemberInfo,
    ReqPostComment,
    ReqSendAlimTalkForMemberChaneInfo,
    ReqUpdateMemberInfo,
} from "../../../../api/member/MemberInfoApi";
import Alert from "../../../../components/UI/Modal/Alert";
import { useEffect } from "react";

/**
 * 회원 정보 관리 페이지
 * BO - 회원관리 - 회원정보 등록/변경 접수
 * 상세보기 페이지 및 회원 정보 수정 모달창
 */
function MngUserInfoDetail() {
    let resData = useLoaderData();
    const params = useParams();
    const infoId = params.infoId;
    const [comment, setComment] = useState("");
    const location = useLocation();
    const navigate = useNavigate();

    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: 81 }, (_, index) => ({
        key: String(currentYear - index),
        value: String(currentYear - index),
        disabled: false,
    }));

    const months = Array.from({ length: 12 }, (_, index) => ({
        key: (index + 1).toString().padStart(2, "0"),
        value: (index + 1).toString().padStart(2, "0"),
        disabled: false,
    }));

    const days = Array.from({ length: 31 }, (_, index) => ({
        key: (index + 1).toString().padStart(2, "0"),
        value: (index + 1).toString().padStart(2, "0"),
        disabled: false,
    }));

    // 고객 정보 변경 신청 테이블이 아닌
    // 현재 고객의 정보를 가져온다.
    // Loader에는 변경 신청 정보를 가져옴.
    // 변경정보를 계속 활용하면 변경 후에도 변경 신청정보가 보임.
    const [infoEmailId, setInfoEmailId] = useState("");
    const [infoEmailHost, setInfoEmailHost] = useState("");
    const [infoGender, setInfoGender] = useState("");
    const [infoPhoneFirst, setInfoPhoneFirst] = useState("");
    const [infoPhoneMid, setInfoPhoneMid] = useState("");
    const [infoPhoneLast, setInfoPhoneLast] = useState("");
    const [infoYear, setInfoYear] = useState("");
    const [infoMonth, setInfoMonth] = useState("");
    const [infoDate, setInfoDate] = useState("");
    const [infoName, setInfoName] = useState("");

    const genderHandler = (e) => {
        setInfoGender(e.target.value);
    };

    const reqMemberInfoHandler = () => {
        ReqMemberInfo(
            resData.request_id,
            (result) => {
                if (result) {
                    setInfoEmailId(result.email?.split("@")[0]);
                    setInfoEmailHost(result.email?.split("@")[1]);
                    setInfoGender(result?.gender);
                    setInfoPhoneFirst(result.phone?.split("-")[0]);
                    setInfoPhoneMid(result.phone?.split("-")[1]);
                    setInfoPhoneLast(result.phone?.split("-")[2]);
                    setInfoYear(result.birth?.split("-")[0]);
                    setInfoMonth(result.birth?.split("-")[1]);
                    setInfoDate(result.birth?.split("-")[2]);
                    setEnteredAddress(result?.address_sub);
                    setSelectedAddress(result?.address);
                    setZipCode(result?.zip);
                    setSmsYn(result?.accept_sms === "Y");
                    setEmailYn(result?.accept_mail === "Y");
                    setInfoName(result?.name);
                }
            },
            (error_result) => {
                alert("회원 정보 수정 페이지 로딩 오류");
            }
        );
    };

    const [modifyValid, setModifyValid] = useState(false);
    const [modalText, setModalText] = useState("");

    const modalHandler = (state, text) => {
        setModifyValid(state);
        setModalText(text);
    };

    const [input, setInput] = useState({
        password: "",
        passwordCheck: "",
    });

    const { password, passwordCheck } = input;
    const passwordRegex = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{6,20}$/;
    const passwordRegexCheck = passwordRegex.test(password);

    const onChangeInput = (e) => {
        const { name, value } = e.target;
        setInput({
            ...input,
            [name]: value,
        });
    };

    const [smsYn, setSmsYn] = useState(false);
    const [emailYn, setEmailYn] = useState(false);

    const smsYnHandler = () => {
        setSmsYn((prev) => !prev);
    };
    const emailYnHandler = () => {
        setEmailYn((prev) => !prev);
    };

    const [gender, setGender] = useState("");

    //ADMIN 직접 변경 사유
    const [modifyReason, setModifyReason] = useState("");
    const modifyReasonHandler = (value) => {
        setModifyReason(value);
    };

    const [memberStatus, setMemberStatus] = useState("N");
    const memberStatusList = DefaultMemberStatusSelectBox;
    const memberStatusHandler = (key) => {
        setMemberStatus(key);
    };

    const emailHostList = defaultEmailHostList;

    const commentHandler = (value) => {
        setComment(value);
    };

    const [modifyConfirm, setModifyConfirm] = useState(false);
    const [modifyAlert, setModifyAlert] = useState(false);

    const [isModalOpen1, setIsModalOpen1] = useState(false);
    const openModalHandler1 = (e) => {
        e.stopPropagation();
        setIsModalOpen1(true);
    };

    const closeModalHandler1 = (e) => {
        e.stopPropagation();
        setIsModalOpen1(false);
    };

    const [isModalOpen2, setIsModalOpen2] = useState(false);
    const openModalHandler2 = (e) => {
        e.stopPropagation();
        setIsModalOpen2(true);
    };

    const closeModalHandler2 = (e) => {
        setIsModalOpen2(false);
        setModifyConfirm(false);
    };

    const navigateHandler = () => {
        navigate("/admin/users/info", { state: location.state });
    };
    useEffect(() => {
        window.addEventListener('popstate', navigateHandler);
        return () => { window.removeEventListener('popstate', navigateHandler); }
    }, [])

    const [process, setProcess] = useState(resData.process);
    const processHandler = (e) => {
        setProcess(e.target.value);
    };

    function formatDate(dateString) {
        const numbersOnly = dateString ? dateString.replace(/\D/g, "") : "";
        const formattedDate = dateString ? numbersOnly.replace(/(\d{4})(\d{2})(\d{2})/, "$1-$2-$3") : "";
        return formattedDate;
    }

    // [START] 다음 주소 API =================================================================
    const [isDaumPostcodeOpen, setIsDaumPostcodeOpen] = useState(false);
    const [zipCode, setZipCode] = useState(""); //우편번호
    const [selectedAddress, setSelectedAddress] = useState(""); // 선택한 주소를 저장할 상태
    const [enteredAddress, setEnteredAddress] = useState(""); // 입력한 주소를 저장할 상태

    const openDaumPostcodeHandler = (e) => {
        e.stopPropagation();
        setIsDaumPostcodeOpen(true);
    };

    const handleAddressSelect = (data) => {
        const fullAddress = data.address; // 전체 주소
        const extraAddress = data.addressType === "R" ? "" : data.bname; // 도로명일 경우 법정동 추가
        const address = `${fullAddress} ${extraAddress}`; // 최종 주소
        setZipCode(data.zonecode);
        setSelectedAddress(address); // 주소를 선택하면 상태 업데이트
        setIsDaumPostcodeOpen(false); // 주소를 선택하면 모달 창 닫기
    };

    const closeDaumPostcodeHandler = () => {
        setIsDaumPostcodeOpen(false);
    };

    //우편번호 검색을 했는데, 나머지 주소를 입력하지 않은경우
    //주소입력을 아에 안하고 가입 가능 but, 하나라도 입력하면 나머지 주소를 입력해야한다.

    const [successModal, setSuccessModal] = useState(false);

    const postObject = {
        process: process,
        comment: comment,
    };

    const postHandler = () => {
        ReqPostComment(
            infoId,
            postObject,
            (result) => {
                if (result.status === 200) {
                    setSuccessModal(true);
                }
            },
            (error_result) => {
                alert("서버 에러");
            }
        );
    };

    //서버전송값
    const memberInfoObject = {
        member_id: resData.request_id,
        phone: `${infoPhoneFirst}-${infoPhoneMid}-${infoPhoneLast}`,
        cell: `${infoPhoneFirst}-${infoPhoneMid}-${infoPhoneLast}`,
        email: infoEmailId + "@" + infoEmailHost,
        accept_sms: smsYn ? "Y" : "N",
        accept_mail: emailYn ? "Y" : "N",
        address: selectedAddress,
        address_sub: enteredAddress,
        zip_code: zipCode,
        password: password,
        memo: modifyReason,
        name: infoName,
        gender: infoGender,
        birth: `${infoYear}-${infoMonth}-${infoDate}`,
        status: memberStatus,
    };

    const reqModifyMemberInfoHandler = () => {
        if (password?.trim() !== "" && !passwordRegexCheck) {
            modalHandler(true, "비밀번호 양식이 맞지 않습니다.");
            return;
        }

        if (password?.trim() !== passwordCheck?.trim()) {
            modalHandler(true, "입력하신 비밀번호가 서로 다릅니다.");
            return;
        }

        if (modifyReason.trim() === "") {
            modalHandler(true, "필수항목을 입력해주세요.");
            return;
        }

        ReqUpdateMemberInfo(
            memberInfoObject,
            (result) => {
                if (result === 200) {
                    setModifyAlert(true);
                    return;
                }
            },
            (error_result) => {
                alert("회원 정보 수정 실패");
                return;
            }
        );
    };

    useEffect(() => {
        reqMemberInfoHandler();
    }, [resData]);

    const noDataLoader = async () => {
        const response = await instance({
            method: "GET",
            url: `/api/v1/admin/member/change/${params.infoId}`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        }).catch(function (error) {
            if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
                return error.response;
            } else if (error.request) {
                console.log(error.request);
                return null;
            } else {
                console.log("Error", error.message);
                return null;
            }
        });
        if (response == null || response.status !== 200) {
            return [];
        }
        if (response.data.status === 200) {
            return response.data.data;
        } else {
            return [];
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            if (!resData.length) {
                resData = await noDataLoader();
            }
        };
        fetchData();
    }, []);

    const [isModalOpen3, setIsModalOpen3] = useState(false);
    const openModalHandler3 = () => {
        setAlimTalkSuccess(false);
        setIsModalOpen3(true);
    };

    const closeModalHandler3 = () => {
        setIsModalOpen3(false);
    };

    const [isModalOpen4, setIsModalOpen4] = useState(false);
    const openModalHandler4 = () => {
        setIsModalOpen4(true);
    };

    const closeModalHandler4 = () => {
        setIsModalOpen4(false);
    };

    const [alimTalkConfirm, setAlimTalkConfirm] = useState(false);
    const alimTalkConfirmHandler = (state) => {
        closeModalHandler4();
        setAlimTalkConfirm(state);
    };

    const [alimTalkSuccess, setAlimTalkSuccess] = useState(false);
    const [alimTalkFail, setAlimTalkFail] = useState(false);

    const alimTalkHandler = () => {
        setAlimTalkConfirm(false);
        ReqSendAlimTalkForMemberChaneInfo(
            resData.request_id,
            (result) => {
                if (result.status === 200) {
                    setAlimTalkSuccess(true);
                } else {
                    setAlimTalkFail(true);
                }
            },
            (error_result) => {
                setAlimTalkFail(true);
            }
        );
    };

    const [alimTalkHistory, setAlimTalkHistory] = useState([]);
    const alimtalkHistoryHandler = () => {
        ReqFindAlimTalkHistoryForMemberChaneInfo(
            resData.request_id,
            (result) => {
                if (result.status === 200) {
                    setAlimTalkHistory(result.datas);
                } else {
                    setAlimTalkHistory([]);
                }
            },
            (error_result) => {
                setAlimTalkHistory([]);
            }
        );
    };

    useEffect(() => {
        alimtalkHistoryHandler();
    }, [isModalOpen3]);

    return (
        <section>
            <div className={classes.admin_contents}>
                <h3>회원정보 변경 접수 - 상세</h3>
                <div className={`${classes.contents_wrap} ${classes.search_wrap} `}>
                    <div className={classes.admin_table_wrap}>
                        <table>
                            <colgroup>
                                <col style={{ width: "159px" }} />
                                <col style={{ width: "610px" }} />
                                <col style={{ width: "159px" }} />
                                <col style={{ width: "610px" }} />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>처리일</th>
                                    <td>{resData.adm_upd_date}</td>
                                    <th>처리자</th>
                                    <td>{resData.handling_member}</td>
                                </tr>
                                <tr>
                                    <th>처리상태</th>
                                    <td>
                                        <div className={classes.radio_wrap}>
                                            <Radio
                                                value="Request"
                                                title="접수"
                                                name="rad_01"
                                                id="rad_01_01"
                                                checked={process === "Request"}
                                                onChange={processHandler}
                                            />
                                            <Radio
                                                value="Close"
                                                title="처리 완료"
                                                name="rad_01"
                                                id="rad_01_02"
                                                checked={process === "Close"}
                                                onChange={processHandler}
                                            />
                                            <Radio
                                                value="Reject"
                                                title="처리 불가"
                                                name="rad_01"
                                                id="rad_01_03"
                                                checked={process === "Reject"}
                                                onChange={processHandler}
                                            />
                                        </div>
                                    </td>
                                    <th>변경신청일</th>
                                    <td>{resData.req_date}</td>
                                </tr>
                                <tr>
                                    <th>아이디</th>
                                    <td>{resData.request_id}</td>
                                    <th>이름</th>
                                    <td>{resData.request_name}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className={`${classes.contents_wrap} ${classes.search_wrap} `}>
                    <div className={classes.admin_table_wrap}>
                        <h5>변경신청 항목</h5>
                        <table>
                            <colgroup>
                                <col style={{ width: "159px" }} />
                                <col style={{ width: "159px" }} />
                                <col style={{ width: "459px" }} />
                                <col style={{ width: "159px" }} />
                                <col style={{ width: "159px" }} />
                                <col style={{ width: "461px" }} />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th rowSpan={2}>이름</th>
                                    <th>변경전</th>
                                    <td>{resData.previous_name}</td>
                                    <th rowSpan={2}>성별</th>
                                    <th>변경전</th>
                                    <td>{resData.previous_gender === "M" ? "남" : "여"}</td>
                                </tr>
                                <tr>
                                    <th>변경후</th>
                                    <td className="primary_c">{resData.name}</td>
                                    <th>변경후</th>
                                    {resData.gender && <td>{resData.gender === "M" ? "남" : "여"}</td>}
                                    {!resData.gender && <td></td>}
                                </tr>
                                <tr>
                                    <th rowSpan={2}>생년월일</th>
                                    <th>변경전</th>
                                    <td colSpan={4}>{formatDate(resData.previous_birth)}</td>
                                </tr>
                                <tr>
                                    <th>변경후</th>
                                    <td colSpan={4} className="primary_c">
                                        {formatDate(resData.birth)}
                                    </td>
                                </tr>
                                <tr>
                                    <th>증빙자료</th>
                                    <td colSpan={5}>
                                        <Button className="text_underline" onClick={openModalHandler1}>
                                            {resData.origin_name}
                                        </Button>
                                    </td>
                                </tr>
                                <tr>
                                    <th>변경사유</th>
                                    <td colSpan={5}>{resData.reason}</td>
                                </tr>
                                <tr>
                                    <th>처리내역</th>
                                    <td colSpan={5}>
                                        <Input className="admin_st" value={comment} onChange={commentHandler} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className={classes.position_left_btn}>
                            <Button className="secondary sm" onClick={openModalHandler4}>
                                문자발송
                            </Button>
                            <Button className=" border sm" onClick={openModalHandler3}>
                                문자발송 내역
                            </Button>
                        </div>
                        <div className={classes.bottom_btn_wrap}>
                            <Button className="border sm" onClick={openModalHandler2}>
                                회원정보 변경
                            </Button>
                            <Button className="secondary sm" onClick={postHandler}>
                                저장
                            </Button>
                            <Button className="border sm" onClick={navigateHandler}>
                                목록
                            </Button>
                        </div>
                    </div>
                </div>
                <div className={`${classes.contents_wrap}`}>
                    <div className={`${classes.admin_table_wrap} ${classes.align_center} `}>
                        <h5>회원정보 변경 처리이력</h5>
                        <table className={classes.left_col_2}>
                            <colgroup>
                                <col style={{ width: "10%" }} />
                                <col style={{ width: "45%" }} />
                                <col style={{ width: "15%" }} />
                                <col style={{ width: "15%" }} />
                                <col style={{ width: "15%" }} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th>처리내역</th>
                                    <th>처리상태</th>
                                    <th>처리일</th>
                                    <th>처리자</th>
                                </tr>
                            </thead>
                            <tbody>
                                {resData.comment != null && (
                                    <tr>
                                        <td>1</td>
                                        <td>{resData.comment}</td>
                                        <td>{resData.process_name}</td>
                                        <td>{resData.adm_upd_date}</td>
                                        <td>{resData.handling_member}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {isModalOpen1 && (
                <Modal className="admin_st w_360" close={closeModalHandler1} header="증빙자료">
                    <main>
                        <div className={classes.pop_img_preview}>
                            <img alt={resData.origin_name} src={resData.attach_uri} />
                        </div>
                    </main>
                </Modal>
            )}
            {successModal && (
                <Alert type="alert" confirm="확인" close={navigateHandler}>
                    {"처리내역이 저장되었습니다."}
                </Alert>
            )}
            {isModalOpen2 && (
                <Modal className="admin_st" close={closeModalHandler2} header="회원정보 수정">
                    <main>
                        <div className={classes.admin_table_wrap}>
                            <table>
                                <colgroup>
                                    <col style={{ width: "139px" }} />
                                    <col style={{ width: "*" }} />
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <th>이름</th>
                                        <td>
                                            <Input
                                                className="admin_st_sm"
                                                name="infoName"
                                                value={infoName}
                                                onChange={setInfoName}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>생년월일</th>
                                        <td>
                                            <div className={`${classes.flex_row} ${classes.multi}`}>
                                                <SelectBoxNew
                                                    className="admin_st w_sm"
                                                    optionDatas={years}
                                                    showDefaultKey={infoYear}
                                                    setSelectItemCB={setInfoYear}
                                                />
                                                <span>년</span>
                                                <SelectBoxNew
                                                    className="admin_st w_sm"
                                                    optionDatas={months}
                                                    showDefaultKey={infoMonth}
                                                    setSelectItemCB={setInfoMonth}
                                                />
                                                <span>월</span>
                                                <SelectBoxNew
                                                    className="admin_st w_sm"
                                                    optionDatas={days}
                                                    showDefaultKey={infoDate}
                                                    setSelectItemCB={setInfoDate}
                                                />
                                                <span>일</span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>성별</th>
                                        <td>
                                            <div className={classes.radio_wrap}>
                                                <Radio
                                                    value="M"
                                                    title="남"
                                                    name="rad_02"
                                                    id="rad_02_01"
                                                    onChange={genderHandler}
                                                    checked={infoGender === "M"}
                                                />
                                                <Radio
                                                    value="F"
                                                    title="여"
                                                    name="rad_02"
                                                    id="rad_02_02"
                                                    onChange={genderHandler}
                                                    checked={infoGender === "F"}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>아이디</th>
                                        <td>{resData.request_id}</td>
                                    </tr>
                                    <tr>
                                        <th>비밀번호</th>
                                        <td>
                                            <MultiInput
                                                value={password}
                                                name="password"
                                                onChange={onChangeInput}
                                                className="admin_st_sm"
                                                type="password"
                                            />
                                            <p className={classes.desc_gray}>
                                                영 대소문자.숫자,특수문자를 포함하여 6~20자 이내로 입력하세요.(패스워드
                                                변경을 원치 않으시면 빈칸으로 남겨 주세요.)
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>비밀번호 확인</th>
                                        <td>
                                            <div className={classes.flex_row}>
                                                <MultiInput
                                                    value={passwordCheck}
                                                    name="passwordCheck"
                                                    onChange={onChangeInput}
                                                    className="admin_st_sm"
                                                    type="password"
                                                />
                                                <p className={classes.desc_gray}>비밀번호를 다시 한번 입력해 주세요</p>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>주소</th>
                                        <td>
                                            <div className={classes.flex_row}>
                                                <Input className="admin_st_sm" value={zipCode} onChange={setZipCode} />
                                                <Button className="border xxxs" onClick={openDaumPostcodeHandler}>
                                                    우편번호 검색
                                                </Button>
                                            </div>
                                            <div className={`${classes.flex_row} ${classes.multi}`}>
                                                <Input
                                                    className="admin_st w_50 "
                                                    value={selectedAddress}
                                                    onChange={setSelectedAddress}
                                                />
                                                <Input
                                                    className="admin_st w_50 "
                                                    value={enteredAddress}
                                                    onChange={setEnteredAddress}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>휴대폰</th>
                                        <td>
                                            <div className={`${classes.flex_row} ${classes.multi}`}>
                                                <Input
                                                    className="admin_st sm"
                                                    value={infoPhoneFirst}
                                                    onChange={setInfoPhoneFirst}
                                                />
                                                <span>-</span>
                                                <Input
                                                    className="admin_st sm"
                                                    value={infoPhoneMid}
                                                    onChange={setInfoPhoneMid}
                                                />
                                                <span>-</span>
                                                <Input
                                                    className="admin_st sm"
                                                    value={infoPhoneLast}
                                                    onChange={setInfoPhoneLast}
                                                />
                                                <Checkbox
                                                    className="admin_label"
                                                    title="SMS 수신설정"
                                                    value={smsYn}
                                                    id="smsYn"
                                                    onChange={smsYnHandler}
                                                    checked={smsYn}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>이메일</th>
                                        <td>
                                            <div className={`${classes.flex_row} ${classes.multi}`}>
                                                <Input
                                                    className="admin_st_sm"
                                                    value={infoEmailId}
                                                    onChange={setInfoEmailId}
                                                />
                                                <span>@</span>
                                                <Input
                                                    className="admin_st_sm"
                                                    value={infoEmailHost}
                                                    onChange={setInfoEmailHost}
                                                />
                                                <SelectBoxNew
                                                    className="admin_st"
                                                    showDefaultKey={infoEmailHost}
                                                    setSelectItemCB={setInfoEmailHost}
                                                    optionDatas={emailHostList}
                                                />
                                                <Checkbox
                                                    className="admin_label"
                                                    title={`뉴스레터 및 내 메일 수신 설정`}
                                                    value={emailYn}
                                                    id="emailYn"
                                                    onChange={emailYnHandler}
                                                    checked={emailYn}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>상태</th>
                                        <td>
                                            <SelectBoxNew
                                                optionDatas={memberStatusList}
                                                className="admin_st "
                                                setSelectItemCB={memberStatusHandler}
                                                showDefaultKey={memberStatus}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            변경 사유<span className="require_red">*</span>
                                        </th>
                                        <td>
                                            <Input
                                                className="admin_st"
                                                value={modifyReason}
                                                onChange={modifyReasonHandler}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </main>
                    <footer>
                        <Button className="secondary sm" onClick={() => setModifyConfirm(true)}>
                            수정
                        </Button>
                    </footer>
                </Modal>
            )}
            {modifyValid && (
                <Alert type="alert" confirm="확인" close={() => modalHandler(false, "")}>
                    {modalText}
                </Alert>
            )}
            {isDaumPostcodeOpen && (
                <Modal className="admin_st" close={closeDaumPostcodeHandler} header="우편번호 검색">
                    <DaumPostcode onComplete={handleAddressSelect} />
                </Modal>
            )}
            {modifyConfirm && (
                <Alert
                    type="confirm"
                    cancel="취소"
                    confirm="확인"
                    close={() => closeModalHandler2(false)} // 모달 닫기
                    confirmHandler={reqModifyMemberInfoHandler} // 확인 버튼 눌렀을 때 실행되는 Handler
                >
                    {"회원정보를 변경하시겠습니까?"}
                </Alert>
            )}
            {modifyAlert && (
                <Alert type="alert" confirm="확인" close={navigateHandler}>
                    {"회원정보가 변경되었습니다."}
                </Alert>
            )}
            {isModalOpen3 && (
                <Modal className="admin_st w_1200" close={closeModalHandler3} header="문자발송 내역">
                    <main>
                        <div>
                            <table className="align_center align_left_col3">
                                <colgroup>
                                    <col style={{ width: "10%" }} />
                                    <col style={{ width: "13%" }} />
                                    <col style={{ width: "32%" }} />
                                    <col style={{ width: "10%" }} />
                                    <col style={{ width: "10%" }} />
                                    <col style={{ width: "8%" }} />
                                    <col style={{ width: "13%" }} />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th>아이디</th>
                                        <th>수신번호</th>
                                        <th>내용</th>
                                        <th>발송상태</th>
                                        <th>발송결과</th>
                                        <th>발송일자</th>
                                        <th>발송자</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {alimTalkHistory.map((data, index) => (
                                        <tr key={index}>
                                            <td>{data.member_id}</td>
                                            <td>{data.tel?.replace(/(\d{2,3})(\d{3,4})(\d{4})/, "$1-$2-$3")}</td>
                                            <td>
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: data.message.replaceAll("\n", "<br/>"),
                                                    }}
                                                />
                                            </td>
                                            <td>{data.sent_type === "KAKAO" ? "알림톡" : "SMS"}</td>
                                            <td>
                                                {data.success === true ? "발송 성공" : "발송 실패"}
                                                <p className="red_c">{data.fail_state ? data.fail_state : ""}</p>
                                            </td>
                                            <td>{data.reg_date}</td>
                                            <td>{data.reg_id}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            {alimTalkHistory.length === 0 && (
                                <div className={`${classes.empty_data} ${classes.sm}`}>
                                    <p>문자발송 내역이 없습니다.</p>
                                </div>
                            )}
                        </div>
                    </main>
                    <footer>
                        <Button className="secondary sm" onClick={closeModalHandler3}>
                            확인
                        </Button>
                    </footer>
                </Modal>
            )}
            {isModalOpen4 && (
                <Modal className="admin_st w_360" close={closeModalHandler4} header="미리보기">
                    <main>
                        <div className={classes.text_bx}>
                            {/* {messageCB.message} */}
                            <pre
                                style={{
                                    "text-wrap": "wrap",
                                    "font-family": "pretendard",
                                }}
                            >
                                [회원정보 변경완료]
                                <br />
                                {infoName}님, 요청하신 회원정보 등록/변경이 완료되었습니다.
                            </pre>
                        </div>
                        <div className={classes.send_test_num}>
                            <b>수신번호</b>
                            {infoPhoneFirst}-{infoPhoneMid}-{infoPhoneLast}
                        </div>
                    </main>
                    <footer>
                        <Button className="secondary sm" onClick={() => alimTalkConfirmHandler(true)}>
                            문자발송
                        </Button>
                        <Button className="sm" onClick={closeModalHandler4}>
                            취소
                        </Button>
                    </footer>
                </Modal>
            )}
            {alimTalkConfirm && (
                <Alert
                    type="confirm"
                    cancel="취소"
                    confirm="확인"
                    close={() => alimTalkConfirmHandler(false)}
                    confirmHandler={alimTalkHandler}
                >
                    {"문자를 발송하시겠습니까?"}
                </Alert>
            )}
            {alimTalkSuccess && (
                <Alert type="alert" confirm="발송결과 확인" close={openModalHandler3}>
                    문자 발송이 완료되었습니다.
                </Alert>
            )}
            {alimTalkFail && (
                <Alert type="alert" confirm="확인" close={() => setAlimTalkFail(false)}>
                    문자 발송 실패하였습니다.
                </Alert>
            )}
        </section>
    );
}

export default MngUserInfoDetail;

//회원 정보 변경 신청 리스트 가져오기
export const loader = async ({ params }) => {
    const response = await instance({
        method: "GET",
        url: `/api/v1/admin/member/change/${params.infoId}`,
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    }).catch(function (error) {
        if (error.response) {
            // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            return error.response;
        } else if (error.request) {
            // 요청이 이루어 졌으나 응답을 받지 못했습니다.
            console.log(error.request);
            return null;
        } else {
            // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
            console.log("Error", error.message);
            return null;
        }
    });
    if (response == null || response.status !== 200) {
        return [];
    }
    if (response.data.status === 200) {
        return response.data.data;
    } else {
        return [];
    }
};
//회원 정보 변경 신청 리스트 가져오기
