import { NavLink,Link } from "react-router-dom";
import classes from "./MediaApp.module.css";
import { useMemStore } from "../../store/memstore/MemStore";
import { useContext } from "react";
import { JwtTokenContext } from "../../store/JwtTokenProvider";

function MediaAppNavigation(props) {
    const setMemStore = useMemStore(false)[1];

    const onOneToOneClickHandler = () => {
        setMemStore("LOGIN_MOVE_PAGE", { uri: "/mypage/cs" });
    };

    const loginCtx = useContext(JwtTokenContext);
    const token = loginCtx.getToken();
    let csLink = null;
    if (token) {
        csLink = "/mypage/cs";
    } else {
        csLink = "/login";
    }

    return (
        <div className={classes.media_sub_nav}>
            <h3>커뮤니티</h3>
            <nav>
                <ul className={classes.depth_1}>
                    <li className={classes.depth_1_link}>
                        <NavLink to="notice">공지사항</NavLink>
                    </li>
                    <li className={classes.depth_1_link}>
                        <NavLink to="faq">FAQ</NavLink>
                    </li>
                    <li className={classes.depth_1_link}>
                        <NavLink to={csLink}>
                            1:1 문의
                        </NavLink>
                    </li>
                </ul>
            </nav>
        </div>
    );
}

export default MediaAppNavigation;
