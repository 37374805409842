import { useRef, useState } from "react";
import Button from "../Button/Button";
import classes from "./FileBox.module.css";

function FileBoxUpdateMj({
    onFileChange,
    selectedFileName,
    file,
    setFile,
    setFileName,
}) {
    const fileInputRef = useRef(null);
    const [hasFile, setHasFile] = useState(Boolean(selectedFileName));

    const handleFileInputChange = (event) => {
        const file = event.target.files[0];
        onFileChange(file);
        setHasFile(true);
    };

    const handleFileDelete = () => {
        onFileChange(null);
        setFile(null);
        setFileName("");
        setHasFile(false);
        fileInputRef.current.value = null;
    };
    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    return (
        <div className={classes.file_box}>
            <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileInputChange}
            />
            {!hasFile && (
                <Button className="border sm" onClick={handleButtonClick}>
                    파일 선택
                </Button>
            )}
            {hasFile && (
                <>
                <Button className="border sm" onClick={handleFileDelete}>
                    파일 삭제
                </Button>
                <input 
                    className={classes.file_name}
                    value={selectedFileName}
                    placeholder="선택된 파일 없음"
                    readOnly
                />
                </>
            )}
        </div>
    );
}

export default FileBoxUpdateMj;
