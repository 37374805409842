import { Outlet } from "react-router-dom";
import classes from "./Media.module.css";
import MediaNavigation from "./MediaNavigation";

function MediaRootLayout() {
    return (
        <div className={`container ${classes.media_container}`}>
            <MediaNavigation />
            <section>
                <div className={classes.media_contents}>
                    <Outlet />
                </div>
            </section>
        </div>
    );
}

export default MediaRootLayout;
