import React, { useState } from "react";
import Alert from "../UI/Modal/Alert";
import { useNavigate } from "react-router-dom";

const NiceNoExist = () => {
    const navigate = useNavigate();
    const [showCancelModal, setShowCancelModal] = useState(true);

    const modalHandler = (state) => {
        setShowCancelModal(state);
        navigate("/");
        window.close();
    };

    return (
        <>
            {showCancelModal && (
                <Alert type="alert" confirm="확인" close={() => modalHandler(false)}>
                    {"입력정보와 일치하는 회원 정보가 없습니다."}
                </Alert>
            )}
        </>
    );
};

export default NiceNoExist;
