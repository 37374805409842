import { useEffect, useState } from "react";
import Button from "../../components/UI/Button/Button";
import Input from "../../components/UI/Input/Input";
import classes from "./MediaApp.module.css";
import AccordionFaq from "../../components/UI/Accordion/AccordionFaq";
import instance from "../../store/AxiosInterceptor";
import { useLoaderData } from "react-router";
import { faqType } from "../../components/definedefault/DefSelectBoxValues";

function FaqAppPage(props) {

    const faq = useLoaderData();

    // tab menu sample
    const [tabIndex, setTabIndex] = useState(0);
    const [tabKey, setTabKey] = useState(0);
    const tabToggle = (index, key) => {
        setTabIndex(index);
        setTabKey(key);
        setFaqSearch([]);
    };
    const tabArr = [
        { id: 0, title: "전체", key : null},
        { id: 1, title: "회원가입/정보", key : "faqMember"},
        { id: 2, title: "상품 A/S", key : "faqAs"},
        { id: 3, title: "상품 문의", key : "faqProduct"},
        { id: 4, title: "대회 문의", key : "faqContest"},
    ];

    const [currentPage, setCurrentPage] = useState(0);
    const [totalPage, setTotalPage] = useState(faq.totalPage); //총 페이지 갯수
    const [totalCount, setTotalCount] = useState(faq.totalCount); //총 페이지 갯수

    const [faqs, setFaqs] = useState(faq.data);
    const [faqSearch, setFaqSearch] = useState([]);

    const setTypeCheck = (type) => {
        const types = faqType.find((items) => items.key === type);
        return types === undefined ? "" : types.value;
    }

    const transformedFaq = () => {
        let transformedFaqs = faqs.map((faq) => {
            return {
                id: faq.id,
                title: setTypeCheck(faq.boardTypeValue),
                question: faq.title, // 현재 faq.date.content 값이 question에 해당하는 것으로 가정합니다.
                answer: faq.content, // 현재 faq.date.content 값이 answer에 해당하는 것으로 가정합니다.
            };
        });
        
        return (
        <>
            <div className={classes.faq_wrap}>
                <AccordionFaq
                    className="main_faq"
                    contents={transformedFaqs}
                />
            </div>
            {totalCount !== faqs.length && (
                <div className={classes.bottom_center_btn}>
                    <Button className="text_icon" onClick={handlePageChange}>더보기</Button>
                </div>
            )}
        </>
        )
    }

    const handleTapChange = async () => {
        const searchParam = {
          page : 0,
          boardType : tabKey
        }
        setCurrentPage(0)
        const response = await instance.get("/api/v1/home/board/faq", {
            params : searchParam
        })
        if (response.status === 200) {
            const resData = await response.data.datas;
            setTotalPage(resData.totalPages);
            setTotalCount(resData.totalElements);
            setFaqs(resData.content);
        } else {
            setFaqs([]);
        }
    };

    const handlePageChange = async () => {
        const page = currentPage + 1
        const searchParam = {
          page : page,
          boardType : tabKey
        }
        setCurrentPage(page)
        const response = await instance.get("/api/v1/home/board/faq", {
            params : searchParam
        })
        if (response.status === 200) {
            const resData = await response.data.datas;
            setTotalPage(resData.totalPages);
            setTotalCount(resData.totalElements);
            setFaqs((prev) => [...prev, ...resData.content]);
        } else {
            setFaqs((prev) => prev);
        }
    };

    const handleSearchPageChange = async () => {
        const page = currentPage + 1
        const searchParam = {
          page : page,
          search : searchText
        }
        setCurrentPage(page)
        const response = await instance.get("/api/v1/home/board/faq", {
            params : searchParam
        })
        if (response.status === 200) {
            const resData = await response.data.datas;
            setTotalPage(resData.totalPages);
            setTotalCount(resData.totalElements);
            let transformedFaqs = resData.content.map((faq) => {
                return {
                    id: faq.id,
                    title: setTypeCheck(faq.boardTypeValue),
                    question: faq.title, // 현재 faq.date.content 값이 question에 해당하는 것으로 가정합니다.
                    answer: faq.content, // 현재 faq.date.content 값이 answer에 해당하는 것으로 가정합니다.
                };
            });

            setFaqSearch((prev) => [...prev, ...transformedFaqs]);
        } else {
            setFaqSearch((prev) => prev);
        }
    };

    const [searchText, setSearchText] = useState();

    const searchHandler = (key) => {
        setSearchText(key)
    };

    const findSearchHandler = async () => {
        setCurrentPage(0);
  
        const searchParam = {
          page : currentPage,
          search : searchText
        }
  
        const response = await instance.get("/api/v1/home/board/faq", {
            params : searchParam
        })
        if (response.status === 200) {
            const resData = await response.data.datas;
            setTotalPage(resData.totalPages);
            setTotalCount(resData.totalElements);

            setFaqSearch(resData.content.map((faq) => ({
                id: faq.id,
                title: setTypeCheck(faq.boardTypeValue),
                question: faq.title, // 현재 faq.date.content 값이 question에 해당하는 것으로 가정합니다.
                answer: faq.content, // 현재 faq.date.content 값이 answer에 해당하는 것으로 가정합니다.
            })));
            setTabIndex(-1);
        } else {
            setFaqSearch([]);
        }
    };

    useEffect(() => {
        handleTapChange();
    }, [tabKey]);

    return (
        <>
            <div className={classes.contents_wrap}>
                <div className={classes.contents_title}>
                    {/* 모바일에서 위치, 클래스명 변경 */}
                    <div className={classes.search_wrap}>
                        <Input placeholder="자주 묻는 질문을 검색하세요." onChange={searchHandler}/>
                        <Button className="search_sm" onClick={findSearchHandler}/>
                    </div>
                </div>

                <ul className={classes.tab_menu}>
                    {tabArr.map((item) => (
                        <li
                            key={item.id}
                            className={tabIndex === item.id && classes.on}
                            onClick={() => tabToggle(item.id, item.key)}
                        >
                            {item.title}
                        </li>
                    ))}
                </ul>
                <div className={classes.tab_contents_wrap}>
                    {faqSearch.length === 0 ? (
                        <>
                            {tabIndex === 0 && transformedFaq()}
                            {tabIndex === 1 && transformedFaq()}
                            {tabIndex === 2 && transformedFaq()}
                            {tabIndex === 3 && transformedFaq()}
                            {tabIndex === 4 && transformedFaq()}
                        </>
                    ) : (
                        <>
                            {/* 검색결과 화면 */}
                            <div className={classes.result_wrap}>
                                <h4>검색결과</h4>
                                <AccordionFaq className="main_faq" contents={faqSearch} />
                            </div>
                            {totalCount !== faqSearch.length && (
                                <div className={classes.bottom_center_btn}>
                                    <Button className="text_icon" onClick={handleSearchPageChange}>더보기</Button>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
        </>
    );
}

export default FaqAppPage;

export const loader = async () => {
    const response = await instance.get(`/api/v1/home/board/faq?page=0`)

    if (response.data.status === 200) {
        const resData = await response.data.datas;
        return { data: resData.content, totalPage: resData.totalPages, totalCount : resData.totalElements };
    } else {
        return [];
    }
};