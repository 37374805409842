import { useEffect, useState } from "react";
import Button from "../../../../components/UI/Button/Button";
import classes from "../../MngCommon.module.css";
import {
    ReqTermsDetail,
    ReqTermsHistDelete,
    ReqTermsHistList,
} from "../../../../api/site/ApiForTerms";
import { useLoaderData, useLocation, useNavigate } from "react-router-dom";
import Moment from "moment";
import MngTermsHistory from "./MngTermsHistory";

function MngTermsDetail(props) {
    const term = useLoaderData();

    const location = useLocation();
    const navigate = useNavigate();

    const backHandler = () => {
        navigate("/admin/site/terms", { state: location.state });
    };

    const [termsHist, setTermsHist] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const response = await ReqTermsHistList(term.type, term.id);
            if (response.status === 200) {
                const resData = await response.data.datas;
                setTermsHist(resData);
            } else {
                setTermsHist([]);
            }
        };
        fetchData();
    }, []);

    const deleteHandler = async (e) => {
        e.preventDefault();

        const isTrue = window.confirm("내용을 삭제하시겠습니까?");
        if (isTrue) {
            try {
                const response = await ReqTermsHistDelete(term.id);
                if (response.status === 200) {
                    alert("내용이 삭제되었습니다.");
                    backHandler();
                } else {
                    alert("내용 삭제 실패");
                }
            } catch (error) {
                console.error("Error:", error);
            }
        } else {
            return;
        }
    };

    const [isModalOpen, setIsModalOpen] = useState(false);

    const [id, setId] = useState();

    const openModalHandler = (e, id) => {
        e.stopPropagation();
        setIsModalOpen(true);
        setId(id);
    };

    const updateHandler = () => {
        navigate(`../update/${term.id}`, { state: location.state });
    };

    return (
        <section>
            <div className={classes.admin_contents}>
                <h3>이용약관/개인정보취급방침 - 상세</h3>
                <div
                    className={`${classes.contents_wrap} ${classes.search_wrap} `}
                >
                    <div className={classes.admin_table_wrap}>
                        <table>
                            <colgroup>
                                <col style={{ width: "10%" }} />
                                <col style={{ width: "40%" }} />
                                <col style={{ width: "10%" }} />
                                <col style={{ width: "40%" }} />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>등록일</th>
                                    <td>
                                        {Moment(term.regDate).format(
                                            "YYYY-MM-DD"
                                        )}
                                    </td>
                                    <th>등록자</th>
                                    <td>{term.regID}</td>
                                </tr>
                                <tr>
                                    <th>사용여부</th>
                                    <td colSpan={3}>
                                        {term.exposeYn ? "사용" : "미사용"}
                                    </td>
                                </tr>
                                <tr>
                                    <th>제목</th>
                                    <td colSpan={3}>{term.title}</td>
                                </tr>

                                <tr>
                                    <th>전문</th>
                                    <td colSpan={3}>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: term.content,
                                            }}
                                        ></div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>개정내용</th>
                                    <td colSpan={3}>{term.modify}</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className={classes.bottom_btn_wrap}>
                            <Button
                                className="secondary sm"
                                onClick={updateHandler}
                            >
                                수정
                            </Button>
                            <Button className="sm" onClick={deleteHandler}>
                                삭제
                            </Button>
                            <Button className="border sm" onClick={backHandler}>
                                목록
                            </Button>
                        </div>
                    </div>
                </div>
                <div className={`${classes.contents_wrap} `}>
                    <h4>개정이력</h4>
                    <div className={`${classes.admin_table_wrap}`}>
                        <table className="align_center align_left_col2">
                            <colgroup>
                                <col style={{ width: "10%" }} />
                                <col style={{ width: "50%" }} />
                                <col style={{ width: "15%" }} />
                                <col style={{ width: "13%" }} />
                                <col style={{ width: "12%" }} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th>제목</th>
                                    <th>등록자</th>
                                    <th>개정일</th>
                                    <th>노출여부</th>
                                </tr>
                            </thead>
                            <tbody>
                                {termsHist.length !== 0 &&
                                    termsHist.map((data, index) => (
                                        <tr>
                                            <td>{index + 1}</td>
                                            <td
                                                onClick={(e) =>
                                                    openModalHandler(e, data.id)
                                                }
                                            >
                                                {data.title}
                                            </td>
                                            <td>{data.regID}</td>
                                            <td>
                                                {Moment(data.regDate).format(
                                                    "YYYY-MM-DD"
                                                )}
                                            </td>
                                            <td>
                                                {data.exposeYn
                                                    ? "사용"
                                                    : "미사용"}
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {isModalOpen && (
                <MngTermsHistory isModalOpenCB={setIsModalOpen} idCB={id} />
            )}
        </section>
    );
}

export default MngTermsDetail;

export const loader = async ({ params }) => {
    // console.log(params);
    const response = await ReqTermsDetail(params.termsId);

    if (response.status === 200) {
        const resData = await response.data;
        return resData;
    } else {
        return [];
    }
};
