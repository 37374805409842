import React, { useEffect, useState } from "react";
import Modal from "./components/UI/Modal/Modal";
import SystemCheckInfo from "./pages/other/SystemCheckInfo";
import instance from "./store/AxiosInterceptor";
import classes from "./components/UI/Modal/Modal.module.css";
import Button from "./components/UI/Button/Button";

export const SystemPopupModal = (props) => {
    const [systemPopup, setSystemPopup] = useState([]);
    const [type, setType] = useState();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await instance.get(
                    `/api/v1/home/homeview/systempopup/top`
                );
                if (response.status === 200 && response.data.status === 200) {
                    const resData = await response.data.data;
                    const type = await response.data.type;
                    setSystemPopup(resData);
                    setType(type);
                } else {
                    return [];
                }
            } catch (e) {
                return [];
            }
        };
        fetchData();
    }, []);

    // const closeModalHandler3 = () => {
    //     setIsModalOpen3(false);
    // };

    const { className = "" } = props;
    const classNames = Array.isArray(className)
        ? className
        : className.split(" ");

    const combinedClasses = classNames
        .map((cn) => classes[cn])
        .filter((cn) => cn)
        .join(" ");

    return (
        <>
            {systemPopup.length !== 0 && (
                <div
                    className={`${classes.modal} ${combinedClasses} ${classes.none_title}`}
                >
                    <section>
                        <header>
                            <h2>
                                <span>시스템 점검안내</span>
                            </h2>
                            {!type && (
                                <Button className="close_x">
                                    close
                                </Button>
                            )}
                        </header>
                        <SystemCheckInfo
                            systemPopupCB={systemPopup}
                            typeCB={type}
                        />
                    </section>
                </div>
            )}
        </>
    );
};
