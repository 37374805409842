import { useLoaderData, useLocation, useNavigate } from "react-router-dom";
import { ReqCenterDelete, ReqCenterDetail } from "../../../../api/company/ApiForCenter";
import Button from "../../../../components/UI/Button/Button";
import classes from "../../MngCommon.module.css";
import Moment from 'moment';
import { useEffect } from "react";

function MngCenterDetail() {

    const center = useLoaderData();
    const location = useLocation();
    const navigate = useNavigate();

    const backHandler = () => {
        navigate("/admin/company/center", { state: location.state });
    };
    useEffect(() => {
        window.addEventListener('popstate', backHandler);
        return () => {window.removeEventListener('popstate', backHandler)}
    }, [])
    const deleteHandler = async (e) => {
        e.preventDefault();
        
        const isTrue = window.confirm('볼링장를 삭제하시겠습니까?');
        if(isTrue) {
            try {
                const response = await ReqCenterDelete(center.id);
                if (response.status === 200) {
                    alert("볼링장이 삭제되었습니다.");
                    backHandler();
                } else {
                    alert("볼링장 삭제 실패");
                }
            } catch (error) {
                console.error("Error:", error);
            }
        } else {
          return
        }
    };

    const updateHandler = () => {
        navigate(`../update/${center.id}`, {state: location.state});
    };

    return (
        <section>
            <div className={classes.admin_contents}>
                <h3>전국볼링장 - 상세</h3>
                <div
                    className={`${classes.contents_wrap} ${classes.search_wrap} `}
                >
                    <div className={classes.admin_table_wrap}>
                        <table>
                            <colgroup>
                                <col style={{ width: "159px" }} />
                                <col style={{ width: "620px" }} />
                                <col style={{ width: "159px" }} />
                                <col style={{ width: "620px" }} />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>등록일</th>
                                    <td>{Moment(center.reg_date).format('YYYY-MM-DD')}</td>
                                    <th>등록자</th>
                                    <td>{center.reg_id}</td>
                                </tr>
                                <tr>
                                    <th>노출여부</th>
                                    <td colSpan={3}>{center.expose_yn ? "노출" : "미노출"}</td>
                                </tr>
                                <tr>
                                    <th>지역</th>
                                    <td colSpan={3}>{center.region}</td>
                                </tr>
                                <tr>
                                    <th>볼링장명</th>
                                    <td colSpan={3}>{center.name}</td>
                                </tr>
                                <tr>
                                    <th>연락처</th>
                                    <td colSpan={3}>{center.phone}</td>
                                </tr>
                                <tr>
                                    <th rowSpan={2}>주소</th>
                                    <td colSpan={3}>
                                    {center.address}
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={3}>{center.sub_address}</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className={classes.bottom_btn_wrap}>
                            <Button className="secondary sm" onClick={updateHandler}>수정</Button>
                            <Button className="sm" onClick={deleteHandler}>삭제</Button>
                            <Button className="border sm" onClick={backHandler} >목록</Button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default MngCenterDetail;

export const loader = async ({ params }) => {
    const response = await ReqCenterDetail(params.centerID);
    
    if (response.status === 200) {
        const resData = await response.data.data;
        return resData;
    } else {
        return [];
    }
};