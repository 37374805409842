import classes from "../../MngCommon.module.css";
import Button from "../../../../components/UI/Button/Button";
import Input from "../../../../components/UI/Input/Input";
import Radio from "../../../../components/UI/Input/Radio";
import instance from "../../../../store/AxiosInterceptor";
import { useEffect } from "react";
import { useState } from "react";
import { useLoaderData, useNavigate } from "react-router-dom";
import { ReqUpdateBannerInfo } from "../../../../api/tournament/TournamentBannerApi";
import Alert from "../../../../components/UI/Modal/Alert";

function MngBannerDetail(props) {
    const resData = useLoaderData(); //loader 데이터

    ///>>> 뒤로가기
    const navigate = useNavigate();

    const backHandler = () => {
        navigate(-1);
    };
    ///<<< 뒤로가기

    ///>>> Radio 버튼 관리
    const [isExpose, setIsExpose] = useState(resData.expose_yn);
    const [isLaunchNewPage, setIsLaunchNewPage] = useState(resData.launch_new_page);

    const exposeHandler = (e) => {
        setIsExpose(e.target.value);
    };

    const newPageHandler = (e) => {
        setIsLaunchNewPage(e.target.value);
    };
    ///<<< Radio 버튼 관리

    // >>> PC 배너 이미지 미리보기 및 업로드 세팅
    // (파일선택 버튼 클릭 후 이미지 업로드 시, 수정 버튼 클릭X -> 수정 안됨)
    const [selectedFile, setSelectedFile] = useState();
    const [newFileUri, setNewFileUri] = useState();
    const [oldFileUri, setOldFileUri] = useState(resData.file_uri);

    useEffect(() => {
        if (!selectedFile) {
            setNewFileUri(null);
            return;
        }

        const objectUrl = URL.createObjectURL(selectedFile);
        setNewFileUri(objectUrl);

        return () => URL.revokeObjectURL(objectUrl);
    }, [selectedFile]);

    const onSelectFile = (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            setSelectedFile(undefined);
            return;
        }

        setSelectedFile(e.target.files[0]);
    };
    //<<< PC 배너 이미지 미리보기

    // >>> Mobile 배너 업로드
    const [selectedMobileFile, setSelectedMobileFile] = useState();
    const [newMobileFileUri, setNewMobileFileUri] = useState();
    const [oldMobileFileUri, setOldMobileFileUri] = useState(resData.mobile_file_uri);

    useEffect(() => {
        if (!selectedMobileFile) {
            setNewMobileFileUri(null);
            return;
        }

        const objectUrl = URL.createObjectURL(selectedMobileFile);
        setNewMobileFileUri(objectUrl);

        return () => URL.revokeObjectURL(objectUrl);
    }, [selectedMobileFile]);

    const onSelectMobileFile = (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            setSelectedMobileFile(null);
            return;
        }

        setSelectedMobileFile(e.target.files[0]);
    };
    // <<< Mobile 배너 업로드

    // >>> PC 배너 삭제
    const [isDeleteImg, setIsDeleteImg] = useState(false);
    const [isDeleteMobileImg, setIsDeleteMobileImg] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [showMobileConfirmation, setShowMobileConfirmation] = useState(false);
    
    const confirmChangeHandler = () => {
        setNewFileUri(null);
        setNewMobileFileUri(null);
        setOldFileUri(null);
        setShowConfirmation(false);
        setIsDeleteImg(true);
    };

    const confirmMobileChangeHandler = () => {
        setSelectedMobileFile(null);
        setNewMobileFileUri(null);
        setOldMobileFileUri(null);
        setShowMobileConfirmation(false);
        setIsDeleteMobileImg(true);
    };

    // <<< PC 배너 삭제

    ///>>> 배너 정보 수정 (수정 버튼 클릭시 업데이트 발생, 배너 정보 및 이미지 파일)
    const [newTitle, setNewTitle] = useState(resData.title);
    const [newLinkUrl, setNewLinkUrl] = useState(resData.link_url);

    const changeTitleHandler = (value) => {
        setNewTitle(value);
    };

    const changeLinkUrlHandler = (value) => {
        setNewLinkUrl(value);
    };

    const editHandler = async () => {
        const jsonData = JSON.stringify({
            title: newTitle,
            expose_yn: isExpose,
            launch_new_page: isLaunchNewPage,
            link_url: newLinkUrl,
            is_delete_pc_file: isDeleteImg ? "Y" : "N",
            is_delete_mobile_file: isDeleteMobileImg ? "Y" : "N",
        });

        const formData = new FormData();

        if (selectedFile) {
            formData.append("image", selectedFile);
        }

        if (selectedMobileFile) {
            formData.append("mobileImage", selectedMobileFile);
        }

        formData.append("body", new Blob([jsonData], { type: "application/json" }));

        ReqUpdateBannerInfo(resData.id, formData, (result) => {
            if (result.status === 200) {
                navigate("/admin/site/banner");
            }
        });
    };
    ///<<< 배너 수정

    const [showEditConfirmation, setShowEditConfirmation] = useState(false); //변경 모달 상태관리
    const confirmEditChangeHandler = () => {
        setShowEditConfirmation(false);
        editHandler();
    };

    return (
        <section>
            <div className={classes.admin_contents}>
                <h3>메인 배너 관리 - 등록/수정</h3>
                <div className={`${classes.contents_wrap} ${classes.search_wrap} `}>
                    <div className={classes.admin_table_wrap}>
                        <table>
                            <colgroup>
                                <col style={{ width: "10%" }} />
                                <col style={{ width: "10%" }} />
                                <col style={{ width: "30%" }} />
                                <col style={{ width: "10%" }} />
                                <col style={{ width: "40%" }} />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>최종등록일</th>
                                    <td colSpan={2}>{resData.upd_date ? resData.upd_date : resData.reg_date}</td>
                                    <th>최종등록자</th>
                                    <td>{resData.upd_id ? resData.upd_id : resData.reg_id}</td>
                                </tr>
                                <tr>
                                    <th>노출여부</th>
                                    <td colSpan={4}>
                                        <div className={classes.radio_wrap}>
                                            <Radio
                                                title="노출"
                                                value="Y"
                                                name="rad01"
                                                id="rad01_1"
                                                checked={isExpose === "Y"}
                                                onChange={exposeHandler}
                                            />
                                            <Radio
                                                title="미노출"
                                                value="N"
                                                name="rad01"
                                                id="rad01_2"
                                                checked={isExpose === "N"}
                                                onChange={exposeHandler}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>배너명</th>
                                    <td colSpan={4}>
                                        <Input name="newTitle" value={newTitle} onChange={changeTitleHandler} />
                                    </td>
                                </tr>
                                <tr>
                                    <th rowSpan={2}>링크</th>
                                    <th>타겟</th>
                                    <td colSpan={3}>
                                        <div className={classes.radio_wrap}>
                                            <Radio
                                                title="자창"
                                                value="N"
                                                name="rad02"
                                                id="rad02_1"
                                                checked={isLaunchNewPage === "N"}
                                                onChange={newPageHandler}
                                            />
                                            <Radio
                                                title="새창"
                                                value="Y"
                                                name="rad02"
                                                id="rad02_2"
                                                checked={isLaunchNewPage === "Y"}
                                                onChange={newPageHandler}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>URL</th>
                                    <td colSpan={3}>
                                        <Input
                                            className="admin_st"
                                            name="newLinkUrl"
                                            value={newLinkUrl}
                                            onChange={changeLinkUrlHandler}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th rowSpan={2}>배너이미지</th>
                                    <th>PC</th>
                                    <td colSpan={3}>
                                        <div className={`${(oldFileUri || newFileUri) && classes.img_sm_box}`}>
                                            {oldFileUri && (
                                                <img
                                                    src={oldFileUri}
                                                    alt="배너 미리보기"
                                                    style={{ width: "300px", height: "260px" }}
                                                />
                                            )}
                                            {newFileUri && (
                                                <img
                                                    src={newFileUri}
                                                    alt="선택한 배너 미리보기"
                                                    style={{ width: "300px", height: "260px" }}
                                                />
                                            )}
                                            <div className={classes.img_file_box}>
                                                {!oldFileUri && (
                                                    <input
                                                        type="file"
                                                        id="file"
                                                        accept="image/*"
                                                        onChange={onSelectFile}
                                                    />
                                                )}
                                                {!oldFileUri && !newFileUri && <label htmlFor="file">파일선택</label>}
                                                {oldFileUri || newFileUri ? (
                                                    <Button
                                                        className="border sm"
                                                        onClick={() => setShowConfirmation(true)}
                                                    >
                                                        파일삭제
                                                    </Button>
                                                ) : null}
                                            </div>
                                            <p className={classes.desc}>* 이미지 사이즈 1920*650px</p>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Mobile</th>
                                    <td colSpan={3}>
                                        <div
                                            className={`${
                                                (oldMobileFileUri || newMobileFileUri) && classes.img_sm_box
                                            }`}
                                        >
                                            {oldMobileFileUri && (
                                                <img
                                                    src={oldMobileFileUri}
                                                    alt="구 배너"
                                                    style={{ width: "300px", height: "260px" }}
                                                />
                                            )}
                                            {newMobileFileUri && (
                                                <img
                                                    src={newMobileFileUri}
                                                    alt="선택한 배너 미리보기"
                                                    style={{ width: "300px", height: "260px" }}
                                                />
                                            )}
                                            <div className={classes.img_file_box}>
                                                {!oldMobileFileUri && (
                                                    <input
                                                        type="file"
                                                        id="mobileFile"
                                                        accept="image/*"
                                                        onChange={onSelectMobileFile}
                                                    />
                                                )}
                                                {!oldMobileFileUri && !newMobileFileUri && (
                                                    <label htmlFor="mobileFile">파일선택</label>
                                                )}
                                                {oldMobileFileUri || newMobileFileUri ? (
                                                    <Button
                                                        className="border sm"
                                                        onClick={() => setShowMobileConfirmation(true)}
                                                    >
                                                        파일삭제
                                                    </Button>
                                                ) : null}
                                            </div>
                                            <p className={classes.desc}>* 이미지 사이즈 720*1060px</p>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className={classes.bottom_btn_wrap}>
                            <Button
                                className="secondary sm"
                                type="submit"
                                onClick={() => setShowEditConfirmation(true)}
                            >
                                수정
                            </Button>
                            <Button className="border sm" onClick={backHandler}>
                                목록
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            {showMobileConfirmation && (
                <Alert
                    type="confirm"
                    cancel="취소"
                    confirm="확인"
                    close={() => setShowMobileConfirmation(false)}
                    confirmHandler={confirmMobileChangeHandler}
                >
                    {"파일을 삭제 하시겠습니까?"}
                </Alert>
            )}
            {showConfirmation && (
                <Alert
                    type="confirm"
                    cancel="취소"
                    confirm="확인"
                    close={() => setShowConfirmation(false)}
                    confirmHandler={confirmChangeHandler}
                >
                    {"파일을 삭제 하시겠습니까?"}
                </Alert>
            )}
            {showEditConfirmation && (
                <Alert
                    type="confirm"
                    cancel="취소"
                    confirm="확인"
                    close={() => setShowEditConfirmation(false)} // 모달 닫기
                    confirmHandler={confirmEditChangeHandler} // 확인 버튼 눌렀을 때 실행되는 Handler
                >
                    {"배너 정보를 수정 하시겠습니까?"}
                </Alert>
            )}
        </section>
    );
}

export default MngBannerDetail;

//>>>배너 리스트 뿌려주기 loader, API 분리 불가. 배너가 먼저 존재해야 Drag&Drop 함수가 진행가능
export const loader = async ({ params }) => {
    const response = await instance({
        method: "GET",
        url: `/api/v1/admin/contest/banner/${params.bannerId}`,
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    }).catch(function (error) {
        if (error.response) {
            // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            return error.response;
        } else if (error.request) {
            // 요청이 이루어 졌으나 응답을 받지 못했습니다.
            console.log(error.request);
            return null;
        } else {
            // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
            console.log("Error", error.message);
            return null;
        }
    });
    if (response == null || response.status !== 200) {
        return [];
    }
    if (response.data.status === 200) {
        return response.data;
    } else {
        return [];
    }
};
//<<<배너 리스트 뿌려주기
