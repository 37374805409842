import { NavLink, useNavigate } from "react-router-dom";
import classes from "./MyPage.module.css";
import { JwtTokenContext } from "../../store/JwtTokenProvider";
import { useContext, useEffect } from "react";
import { useMemStore } from "../../store/memstore/MemStore";

function MyPageNavigation(props) {
    const loginCtx = useContext(JwtTokenContext);
    const navigate = useNavigate();
    const setMemStore = useMemStore(false)[1];


    if (loginCtx.isAdmin()) {
        navigate("/");
        return (<></>)
    }

    if (!loginCtx.isLogin()) {
        navigate("/login");
        // configureMemStore에 정의된 ACTION
        setMemStore('LOGIN_MOVE_PAGE', { 
            uri: '/myPage'
        });
    }

    return (
        <div className={classes.mypage_sub_nav}>
            <h3>마이페이지</h3>
            <nav>
                <ul className={classes.depth_1}>
                    <li className={classes.depth_1_link}>
                        <NavLink to="tournamentHistory">대회 접수내역</NavLink>
                    </li>
                    <li className={classes.depth_1_link}>
                        <NavLink to="event">이벤트 접수내역</NavLink>
                    </li>
                    <li className={classes.depth_1_link}>
                        <NavLink to="cs">나의 문의내역</NavLink>
                    </li>
                    <li className={classes.depth_1_link}>
                        <NavLink to="modify">회원정보수정</NavLink>
                    </li>
                </ul>
            </nav>
        </div>
    );
}

export default MyPageNavigation;
