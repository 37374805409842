import Moment from 'moment';
import { useContext, useState } from "react";
import { useNavigate } from "react-router";
import Button from "../../../../components/UI/Button/Button";
import FileBoxMj from "../../../../components/UI/Input/FileBoxMj";
import Input from "../../../../components/UI/Input/Input";
import Radio from "../../../../components/UI/Input/Radio";
import { JwtTokenContext } from "../../../../store/JwtTokenProvider";
import classes from "../../MngCommon.module.css";
import { ReqCatalogueCreate } from '../../../../api/site/ApiForCatalogue';

function MngCatalogueCreate() {

    const loginCtx = useContext(JwtTokenContext);
    const navigate = useNavigate();

    const backHandler = () => {
        navigate("/admin/site/catalog");
    };

    const [exposeYn, setExposeYn] = useState("Y");
    const [title, setTitle] = useState();
    const [file, setFile] = useState();
    const [fileName, setFileName] = useState("");

    const changeTitleHandler = (value) => {
        setTitle(value);
    };

    const exposeYnRadioButton = (e) => {
        setExposeYn(e.target.value)
    }

    const handleFileChange = (file) => {
        setFile(file);
        setFileName(file ? file.name : "");
    };

    const createHandler = async (e) => {
        e.preventDefault();
        
        if(!title) {
            alert("카달로그명을 입력해주세요.");
            return
        }

        if(!file) {
            alert("첨부파일을 등록해주세요.");
            return
        }

        const isTrue = window.confirm('내용을 등록하시겠습니까?');
        if(isTrue) {
            try {
                const jsonData = JSON.stringify({
                    exposeYn: exposeYn,
                    title: title,
                });

                const formData = new FormData();
      
                if (file) {
                    formData.append("file", file);
                }
            
                formData.append("body", new Blob([jsonData], { type: "application/json" }));

                const response = await ReqCatalogueCreate(formData);
                if (response.status === 200) {
                    alert("내용이 등록되었습니다.");
                    backHandler();
                } else {
                    alert("내용 등록 실패");
                }
            } catch (error) {
                console.error("Error:", error);
            }
        } else {
          return
        }

        
    };

    return (
        <section>
            <div className={classes.admin_contents}>
                <h3>카탈로그 - 등록</h3>
                <div
                    className={`${classes.contents_wrap} ${classes.search_wrap} `}
                >
                    <div className={classes.admin_table_wrap}>
                        <table>
                            <colgroup>
                                <col style={{ width: "10%" }} />
                                <col style={{ width: "40%" }} />
                                <col style={{ width: "10%" }} />
                                <col style={{ width: "40%" }} />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>등록일</th>
                                    <td>{Moment(new Date()).format('YYYY-MM-DD')}</td>
                                    <th>등록자</th>
                                    <td>{loginCtx.decodedToken.jti}</td>
                                </tr>
                                <tr>
                                    <th>노출여부</th>
                                    <td colSpan={3}>
                                        <div className={classes.radio_wrap}>
                                            <Radio
                                                value="Y"
                                                title="노출"
                                                name="rad_01"
                                                id="rad_01_01"
                                                checked={exposeYn === "Y"} 
                                                onChange={exposeYnRadioButton}
                                            />
                                            <Radio
                                                value="N"
                                                title="미노출"
                                                name="rad_01"
                                                id="rad_01_02"
                                                checked={exposeYn === "N"} 
                                                onChange={exposeYnRadioButton}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>카탈로그명</th>
                                    <td colSpan={3}>
                                        <Input
                                            className="admin_st"
                                            onChange={changeTitleHandler}
                                            value={title}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>첨부파일</th>
                                    <td colSpan={3}>
                                        <FileBoxMj
                                            onFileChange={handleFileChange}
                                            selectedFileName={fileName}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className={classes.bottom_btn_wrap}>
                            <Button className="secondary sm" onClick={createHandler}>등록</Button>
                            <Button
                                className="border sm" onClick={backHandler}
                            >
                                목록
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default MngCatalogueCreate;
