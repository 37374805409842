import classes from "../MngCommon.module.css";
function MngTournamentStatisticsPage(props) {
    return (
        <section>
            <div className={classes.admin_contents}>
                <h3>통계</h3>
                <div className={classes.contents_wrap}>
                    <div
                        className={`${classes.empty_data} ${classes.coming_soon}`}
                    >
                        <p>서비스 준비중 입니다.</p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default MngTournamentStatisticsPage;
