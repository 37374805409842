import { useEffect, useState } from "react";
import {  useNavigate, useLocation } from "react-router-dom";
import Button from "../../../components/UI/Button/Button";
import DateRangePicker from "../../../components/UI/DatePicker/DateRangePicker";
import Input from "../../../components/UI/Input/Input";
import SelectBoxNew from "../../../components/UI/SelectBox/SelectBoxNew";
import PaginationNew from "../../../components/UI/Table/PaginationNew";
import classes from "../MngCommon.module.css";
import instance from "../../../store/AxiosInterceptor";
import moment from "moment";
import {
    DefaultMemberInfoChagneSelectBox,
    DefaultMemberInfoQueryType,
} from "../../../components/definedefault/DefSelectBoxValues";
import {
    ReqFindMemberInfoAll,
    ReqFindMemberInfoPaging,
    ReqFindMemberInfoSearch,
} from "../../../api/member/MemberInfoApi";
import _ from "lodash";

/**
 * BO - 회원관리 - 회원정보 등록/변경 접수
 * 리스트 페이지
 */
function MngUserInfoPage() {
    const navigate = useNavigate();
    const navigateHandler = (link) => {
        navigate(link, { state: { pageNumber } });
    };
    const location = useLocation();
    const queryTypeList = DefaultMemberInfoQueryType;
    const processList = DefaultMemberInfoChagneSelectBox;

    const [memberInfoList, setMemberInfoList] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [pageNumber, setPageNumber] = useState(location.state?.pageNumber ?? 0);
    const [totalPages, setTotalPages] = useState(0);
    const [process, setProcess] = useState(null);
    const [queryType, setQueryType] = useState("all");
    const [queryText, setQueryText] = useState("");
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const searchStartDate = startDate ? moment(startDate).format("YYYYMMDD") : null;
    const searchEndDate = endDate ? moment(endDate).format("YYYYMMDD") : null;

    const startDateHandler = (value) => {
        setStartDate(value);
    };

    const endDateHandler = (value) => {
        setEndDate(value);
    };

    const processHandler = (key) => {
        setProcess(key);
    };

    const queryTypeHandler = (key) => {
        setQueryType(key);
    };

    const queryTextHandler = (key) => {
        setQueryText(key);
    };

    function formatPhoneNumber(phoneNumber) {
        const numbersOnly = phoneNumber ? phoneNumber.replace(/\D/g, "") : "";
        const formattedNumber = phoneNumber ? numbersOnly.replace(/(\d{2,3})(\d{3,4})(\d{4})/, "$1-$2-$3") : "";
        return formattedNumber;
    }

    const searchObject = {
        sDate: searchStartDate,
        eDate: searchEndDate,
        process: process,
        query_type: queryType,
        query_text: queryText !== "" && queryType === "phone" ? queryText.replace(/-/g, "") : queryText,
    };

    const searchHandler = () => {
        ReqFindMemberInfoSearch(
            searchObject,
            (result) => {
                const curData = result.content.map((data) => {
                    return {
                        infoId: data.id,
                        memberName: data.memberName,
                        phone: data.phone,
                        process: data.process,
                        processName: data.process_name,
                        reqDate: data.reqDate,
                        requestID: data.requestID,
                        seq: data.seq,
                        changeTypes: data.change_type,
                    };
                });
                setMemberInfoList(curData);
                setTotalCount(result.totalElements);
                setPageNumber(result.pageable.pageNumber);
                setTotalPages(result.totalPages);
            },
            (error_result) => {
                setMemberInfoList((prev) => prev);
                setTotalCount((prev) => prev);
                setPageNumber((prev) => prev);
                setTotalPages((prev) => prev);
            }
        );
    };

    const pagingHandler = (value) => {
        setPageNumber(value);
        ReqFindMemberInfoPaging(
            searchObject,
            value,
            (result) => {
                const curData = result.content.map((data) => {
                    return {
                        infoId: data.id,
                        memberName: data.memberName,
                        phone: data.phone,
                        process: data.process,
                        processName: data.process_name,
                        reqDate: data.reqDate,
                        requestID: data.requestID,
                        seq: data.seq,
                        changeTypes: data.change_type,
                    };
                });
                setMemberInfoList(curData);
                setTotalCount(result.totalElements);
                setPageNumber(result.pageable.pageNumber);
                setTotalPages(result.totalPages);
            },
            (error_result) => {
                setMemberInfoList((prev) => prev);
                setTotalCount((prev) => prev);
                setPageNumber((prev) => prev);
                setTotalPages((prev) => prev);
            }
        );
    };

    const findAllHandler = () => {
        setProcess("");
        setQueryType("");
        setQueryText("");
        setStartDate(null);
        setEndDate(null);
        ReqFindMemberInfoAll(
            (result) => {
                const curData = result.content.map((data) => {
                    return {
                        infoId: data.id,
                        memberName: data.memberName,
                        phone: data.phone,
                        process: data.process,
                        processName: data.process_name,
                        reqDate: data.reqDate,
                        requestID: data.requestID,
                        seq: data.seq,
                        changeTypes: data.change_type,
                    };
                });
                setMemberInfoList(curData);
                setTotalCount(result.totalElements);
                setPageNumber(result.pageable.pageNumber);
                setTotalPages(result.totalPages);
            },
            (error_result) => {
                setMemberInfoList((prev) => prev);
                setTotalCount((prev) => prev);
                setPageNumber((prev) => prev);
                setTotalPages((prev) => prev);
            }
        );
    };


    const dataLoader = async () => {
        const response = await instance({
            method: "GET",
            url: `/api/v1/admin/member/change?page=${pageNumber}&size=10`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        }).catch(function (error) {
            if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
                return error.response;
            } else if (error.request) {
                console.log(error.request);
                return null;
            } else {
                console.log("Error", error.message);
                return null;
            }
        });
        if (response == null || response.status !== 200) {
            return [];
        }
        if (response.data.status === 200) {
            return response.data.data;
        } else {
            return [];
        }
    };

    useEffect(() => {
        if(_.isNumber(location.state?.pageNumber) && location.state.pageNumber !== pageNumber){
            pagingHandler(location.state.pageNumber);
            return;
        }
        const fetchData = async () => {
           const response = await dataLoader();
           if (response.length === 0) return;
           const curData = response.content.map((data) => {
               return {
                   infoId: data.id,
                   memberName: data.memberName,
                   phone: data.phone,
                   process: data.process,
                   processName: data.process_name,
                   reqDate: data.reqDate,
                   requestID: data.requestID,
                   seq: data.seq,
                   changeTypes: data.change_type,
               };
           });
           setMemberInfoList(curData);
           setTotalCount(response.totalElements);
           setPageNumber(response.pageable.pageNumber);
           setTotalPages(response.totalPages);
        };
        fetchData();
    }, [location.state]);
    /* useEffect(() => {
        if(_.isNumber(location.state?.pageNumber) && location.state.pageNumber !== pageNumber){
            pagingHandler(location.state.pageNumber);
        }
    }, [location.state]) */
    return (
        <section>
            <div className={classes.admin_contents}>
                <h3>회원정보 변경 접수</h3>
                <div className={`${classes.contents_wrap} ${classes.search_wrap}`}>
                    <div className={classes.admin_table_wrap}>
                        <table>
                            <colgroup>
                                <col style={{ width: "11%" }} />
                                <col style={{ width: "39%" }} />
                                <col style={{ width: "11%" }} />
                                <col style={{ width: "39%" }} />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>변경신청일자</th>
                                    <td>
                                        <DateRangePicker
                                            startDate={startDate}
                                            endDate={endDate}
                                            onStartDateChange={startDateHandler}
                                            onEndDateChange={endDateHandler}
                                        />
                                    </td>
                                    <th>상태</th>
                                    <td>
                                        <SelectBoxNew
                                            optionDatas={processList}
                                            className="admin_st"
                                            setSelectItemCB={processHandler}
                                            showDefaultKey={process}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>검색구분</th>
                                    <td colSpan={3}>
                                        <div className={classes.flex_row}>
                                            <SelectBoxNew
                                                optionDatas={queryTypeList}
                                                className="admin_st"
                                                setSelectItemCB={queryTypeHandler}
                                                showDefaultKey={queryType}
                                            />
                                            <Input
                                                className="admin_st"
                                                value={queryText}
                                                onChange={queryTextHandler}
                                                placeholder="검색어 입력"
                                            />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className={classes.bottom_btn_wrap}>
                            <Button className="border sm" onClick={searchHandler}>
                                검색
                            </Button>
                            <Button className="secondary sm" onClick={findAllHandler}>
                                전체보기
                            </Button>
                        </div>
                    </div>
                </div>
                <div className={classes.contents_wrap}>
                    <div className={`${classes.admin_table_wrap} ${classes.align_center}  ${classes.row_select}`}>
                        <p className={classes.counter}>
                            총 <b>{totalCount}</b>건
                        </p>
                        <table>
                            <colgroup>
                                <col style={{ width: "5%" }} />
                                <col style={{ width: "15%" }} />
                                <col style={{ width: "15%" }} />
                                <col style={{ width: "15%" }} />
                                <col style={{ width: "15%" }} />
                                <col style={{ width: "15%" }} />
                                <col style={{ width: "15%" }} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th>변경신청일</th>
                                    <th>아이디</th>
                                    <th>이름</th>
                                    <th>휴대폰</th>
                                    <th>변경신청 항목</th>
                                    <th>처리상태</th>
                                </tr>
                            </thead>
                            <tbody>
                                {memberInfoList.map((data) => (
                                    <tr onClick={() => navigateHandler(`${data.infoId}`)}>
                                        <td>{data.seq}</td>
                                        <td>{data.reqDate}</td>
                                        <td>{data.requestID}</td>
                                        <td>{data.memberName}</td>
                                        <td>{formatPhoneNumber(data.phone)}</td>
                                        <td>{data.changeTypes}</td>
                                        {data.process === "Request" && <td>접수</td>}
                                        {data.process === "Reject" && <td className="red_c">승인 불가</td>}
                                        {data.process === "Close" && <td className="primary_c">처리 완료</td>}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <PaginationNew
                            totalPage={totalPages}
                            currentPage={pageNumber}
                            defaultShowPage={5}
                            changePageCB={pagingHandler}
                            className="admin_st"
                        />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default MngUserInfoPage;

//회원 정보 변경 신청 리스트 가져오기
export const loader = async () => {
    const response = await instance({
        method: "GET",
        url: "/api/v1/admin/member/change?page=0&size=10",
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    }).catch(function (error) {
        if (error.response) {
            // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            return error.response;
        } else if (error.request) {
            // 요청이 이루어 졌으나 응답을 받지 못했습니다.
            console.log(error.request);
            return null;
        } else {
            // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
            console.log("Error", error.message);
            return null;
        }
    });
    if (response == null || response.status !== 200) {
        return [];
    }
    if (response.data.status === 200) {
        return response.data.data;
    } else {
        return [];
    }
};
//회원 정보 변경 신청 리스트 가져오기
