import { useState } from "react";
import classes from "../MngCommon.module.css";
import Button from "../../../components/UI/Button/Button";
import Input from "../../../components/UI/Input/Input";
import PaginationNew from "../../../components/UI/Table/PaginationNew";
import DateRangePicker from "../../../components/UI/DatePicker/DateRangePicker";
import SelectBoxNew from "../../../components/UI/SelectBox/SelectBoxNew";
import { DefaultExposeSelectBox } from "../../../components/definedefault/DefSelectBoxValues";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import instance from "../../../store/AxiosInterceptor";
import { ReqFindAllEventList, ReqPagingEvent, ReqSearchEventList } from "../../../api/event/MngEventApi";
import moment from "moment/moment";

function MngEvent() {
    const navigate = useNavigate();
    const location = useLocation();

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const searchStartDate = startDate ? moment(startDate).format("YYYYMMDD") : "";
    const searchEndDate = endDate ? moment(endDate).format("YYYYMMDD") : "";

    const exposeStateList = DefaultExposeSelectBox;
    const [eventList, setEventList] = useState([]);
    const [exposeYn, setExposeYn] = useState("");
    const [eventTitle, setEventTitle] = useState("");
    const [totalPages, setTotalPages] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [pageNumber, setPageNumber] = useState(location.state?.pageNumber ?? 0);

    const startDateHandler = (value) => {
        setStartDate(value);
    };

    const endDateHandler = (value) => {
        setEndDate(value);
    };

    const onExposeHandler = (value) => {
        setExposeYn(value);
    };

    const onEventTitleHandler = (value) => {
        setEventTitle(value);
    };

    const registerPageHandler = () => {
        navigate("register");
    };

    const detailPageHandler = (value) => {
        navigate(value, {state: {pageNumber}});
    };

    const searchObject = {
        title: eventTitle,
        expose: exposeYn,
        sDate: searchStartDate,
        eDate: searchEndDate,
    };

    const findAllHandler = () => {
        setPageNumber(0);
        setEventTitle("");
        setExposeYn("");
        setStartDate(null);
        setEndDate(null);

        ReqFindAllEventList(
            (result) => {
                if (result.status === 200) {
                    const curData = result.data.content.map((data) => {
                        return {
                            id: data.id,
                            title: data.title,
                            exposeYn: data.expose_yn,
                            notifyDate: data.notify_date,
                            readCnt: data.read_cnt,
                            regId: data.reg_id,
                            seq: data.seq,
                            startDate: data.start_date,
                            endDate: data.end_date,
                            chosenExposeYn: data.chosen_expose_yn,
                            status: data.status,
                        };
                    });
                    setEventList(curData);
                    setTotalCount(result.data.totalElements);
                    setPageNumber(result.data.pageable.pageNumber);
                    setTotalPages(result.data.totalPages);
                } else {
                    setEventList((prev) => prev);
                    setTotalCount((prev) => prev);
                    setPageNumber((prev) => prev);
                    setTotalPages((prev) => prev);
                }
            },
            (error_result) => {
                setEventList((prev) => prev);
                setTotalCount((prev) => prev);
                setPageNumber((prev) => prev);
                setTotalPages((prev) => prev);
            }
        );
    };

    const searchHandler = () => {
        ReqSearchEventList(
            searchObject,
            (result) => {
                if (result.status === 200) {
                    const curData = result.data.content.map((data) => {
                        return {
                            id: data.id,
                            title: data.title,
                            exposeYn: data.expose_yn,
                            notifyDate: data.notify_date,
                            readCnt: data.read_cnt,
                            regId: data.reg_id,
                            seq: data.seq,
                            startDate: data.start_date,
                            endDate: data.end_date,
                            chosenExposeYn: data.chosen_expose_yn,
                            status: data.status,
                        };
                    });
                    setEventList(curData);
                    setTotalCount(result.data.totalElements);
                    setPageNumber(result.data.pageable.pageNumber);
                    setTotalPages(result.data.totalPages);
                } else {
                    setEventList((prev) => prev);
                    setTotalCount((prev) => prev);
                    setPageNumber((prev) => prev);
                    setTotalPages((prev) => prev);
                }
            },
            (error_result) => {
                setEventList((prev) => prev);
                setTotalCount((prev) => prev);
                setPageNumber((prev) => prev);
                setTotalPages((prev) => prev);
            }
        );
    };

    const pagingHandler = (value) => {
        setPageNumber(value);
        ReqPagingEvent(
            searchObject,
            value,
            (result) => {
                if (result.status === 200) {
                    const curData = result.data.content.map((data) => {
                        return {
                            id: data.id,
                            title: data.title,
                            exposeYn: data.expose_yn,
                            notifyDate: data.notify_date,
                            readCnt: data.read_cnt,
                            regId: data.reg_id,
                            seq: data.seq,
                            startDate: data.start_date,
                            endDate: data.end_date,
                            chosenExposeYn: data.chosen_expose_yn,
                            status: data.status,
                        };
                    });
                    setEventList(curData);
                    setTotalCount(result.data.totalElements);
                    setPageNumber(result.data.pageable.pageNumber);
                    setTotalPages(result.data.totalPages);
                } else {
                    setEventList((prev) => prev);
                    setTotalCount((prev) => prev);
                    setPageNumber((prev) => prev);
                    setTotalPages((prev) => prev);
                }
            },
            (error_result) => {
                setEventList((prev) => prev);
                setTotalCount((prev) => prev);
                setPageNumber((prev) => prev);
                setTotalPages((prev) => prev);
            }
        );
    };

    useEffect(() => {
        pagingHandler(pageNumber);
    }, [])

    return (
        <section>
            <div className={classes.admin_contents}>
                <h3>이벤트관리 - 목록</h3>
                <div className={`${classes.contents_wrap} ${classes.search_wrap}`}>
                    <div className={classes.admin_table_wrap}>
                        <table>
                            <colgroup>
                                <col style={{ width: "11%" }} />
                                <col style={{ width: "39%" }} />
                                <col style={{ width: "11%" }} />
                                <col style={{ width: "39%" }} />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>이벤트기간</th>
                                    <td>
                                        <DateRangePicker
                                            startDate={startDate}
                                            endDate={endDate}
                                            onStartDateChange={startDateHandler}
                                            onEndDateChange={endDateHandler}
                                        />
                                    </td>
                                    <th>노출여부</th>
                                    <td>
                                        <SelectBoxNew
                                            className="admin_st"
                                            optionDatas={exposeStateList}
                                            setSelectItemCB={onExposeHandler}
                                            showDefaultKey={exposeYn}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>검색어</th>
                                    <td colSpan={3}>
                                        <Input className="admin_st" value={eventTitle} onChange={onEventTitleHandler} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className={classes.bottom_btn_wrap}>
                            <Button className="border sm" onClick={searchHandler}>
                                검색
                            </Button>
                            <Button className="secondary sm" onClick={findAllHandler}>
                                전체보기
                            </Button>
                        </div>
                    </div>
                </div>
                <div className={classes.contents_wrap}>
                    <div
                        className={`${classes.admin_table_wrap} ${classes.align_center}  ${classes.row_select} ${classes.left_col_2}`}
                    >
                        <p className={classes.counter}>
                            총 <b>{totalCount}</b> 건
                        </p>
                        <table>
                            <colgroup>
                                <col style={{ width: "8%" }} />
                                <col style={{ width: "30%" }} />
                                <col style={{ width: "10%" }} />
                                <col style={{ width: "15%" }} />
                                <col style={{ width: "10%" }} />
                                <col style={{ width: "10%" }} />
                                <col style={{ width: "10%" }} />
                                <col style={{ width: "8%" }} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th>이벤트명</th>
                                    <th>이벤트코드</th>
                                    <th>이벤트기간</th>
                                    <th>상태</th>
                                    <th>노출여부</th>
                                    <th>등록자</th>
                                    <th>조회수</th>
                                </tr>
                            </thead>
                            <tbody>
                                {eventList?.length > 0 &&
                                    eventList.map((data, index) => (
                                        <tr key={index} onClick={() => detailPageHandler(`${data.id}`)}>
                                            <td>{data.seq}</td>
                                            <td>{data.title}</td>
                                            <td>{data.id}</td>
                                            <td>
                                                {data.startDate} ~ {data.endDate}
                                            </td>
                                            {data.status === "N" && <td>진행예정</td>}
                                            {data.status === "Y" && <td>진행중</td>}
                                            {data.status === "F" && <td>종료</td>}
                                            <td>{data.exposeYn === "Y" ? "노출" : "미노출"}</td>
                                            <td>{data.regId}</td>
                                            <td>{data.readCnt}</td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                        <PaginationNew
                            className="admin_st"
                            totalPage={totalPages}
                            currentPage={pageNumber}
                            defaultShowPage={5}
                            changePageCB={pagingHandler}
                        />
                        <div className={`${classes.bottom_btn_wrap} ${classes.position_st}`}>
                            <Button className="secondary sm" onClick={registerPageHandler}>
                                등록
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default MngEvent;

//이벤트 목록 리스트 가져오기
export const loader = async () => {
    const response = await instance({
        method: "GET",
        url: `/api/v1/admin/event?title=&expose=&sDate=&eDate=&page=0&size=10`,
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    }).catch(function (error) {
        if (error.response) {
            return error.response;
        } else if (error.request) {
            return null;
        } else {
            return null;
        }
    });
    if (response == null || response.status !== 200) {
        return [];
    }
    if (response.data.status === 200) {
        console.log(response.data.data);
        return response.data.data;
    } else {
        return [];
    }
};
//이벤트 목록 리스트 가져오기
