import { useEffect, useState } from "react";
import Button from "../../../../components/UI/Button/Button";
import classes from "../../MngCommon.module.css";
import { ReqSmartFilterList } from "../../../../api/product/SmartFilterApi";
import PaginationNew from "../../../../components/UI/Table/PaginationNew";
import { useLocation, useNavigate } from "react-router-dom";

function MngProductSmartFilter(props) {
    const location = useLocation();
    const navigate = useNavigate();

    const [smartFilterList, setSmartFilterList] = useState([]);
    const [currentPage, setCurrentPage] = useState(location.state?.currentPage ?? 0);
    const [totalPage, setTotalPage] = useState(0);
    const [totalCount, setTotalCount] = useState(0);

    const handlePageChange = async (page) => {
        setCurrentPage(page);
        setSmartFilterList([]); // 페이지 변경 전에 기존 데이터 초기화

        const response = await ReqSmartFilterList(page);

        if (response.status === 200) {
            const resData = response.data.datas;

            setTotalPage(resData.totalPages);
            setTotalCount(resData.totalElements);
            setSmartFilterList(resData.content);
        } else {
            setSmartFilterList([]);
        }
    };

    const toDetail = (id) => {
        navigate(`/admin/product/smartFilter/${id}`, { state: { currentPage } });
    };
    const uploadHandler = () => {
        navigate("/admin/product/smartFilter/upload", { state: { currentPage } });
    };
    useEffect(() => {
        handlePageChange(currentPage);
    }, [])
    return (
        <section>
            <div className={classes.admin_contents}>
                <h3>상품정보/스마트필터관리</h3>
                <div className={classes.contents_wrap}>
                    <div className={`${classes.admin_table_wrap} ${classes.align_center}  ${classes.row_select}`}>
                        <p className={classes.counter}>
                            총 <b>{totalCount}</b>건
                        </p>
                        <table>
                            <colgroup>
                                <col style={{ width: "5%" }} />
                                <col style={{ width: "25%" }} />
                                <col style={{ width: "25%" }} />
                                <col style={{ width: "15%" }} />
                                <col style={{ width: "15%" }} />
                                <col style={{ width: "15%" }} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th>적용 카테고리</th>
                                    <th>제목</th>
                                    <th>등록일</th>
                                    <th>등록자</th>
                                    <th>사용여부</th>
                                </tr>
                            </thead>
                            <tbody>
                                {smartFilterList.map(({ id, hierarchyCategoryStr, categoryId, title, seq, regID, regDate, exposeYn }, index) => {
                                    // const number = totalCount - (10 * currentPage + index);
                                    return (
                                        <tr key={categoryId} onClick={() => toDetail(id)}>
                                            {/* <td>{number}</td> */}
                                            <td>{seq}</td>
                                            <td>{hierarchyCategoryStr}</td>
                                            <td>{title}</td>
                                            <td>{regDate}</td>
                                            <td>{regID}</td>
                                            <td>{exposeYn ? "사용" : "미사용"}</td>
                                        </tr>
                                    );
                                })}

                                {/* {bodyValues} */}
                                {/* <tr onClick={() => handleModeChange(2)}>
                                    <td>3</td>
                                    <td>
                                        볼링볼 {">"} 전체 {">"} 전체
                                    </td>
                                    <td>볼링볼_상품정보 및 스마트필터</td>
                                    <td>2023-05-27</td>
                                    <td>admin</td>
                                    <td>사용</td>
                                </tr>
                                <tr onClick={() => handleModeChange(2)}>
                                    <td>2</td>
                                    <td>
                                        볼링화 {">"} 전체 {">"}
                                        전체
                                    </td>
                                    <td>볼링화_상품정보 및 스마트필터</td>
                                    <td>2023-05-27</td>
                                    <td>admin</td>
                                    <td>사용</td>
                                </tr>
                                <tr onClick={() => handleModeChange(2)}>
                                    <td>1</td>
                                    <td>
                                        볼링의류 {">"} 전체 {">"}
                                        전체
                                    </td>
                                    <td>볼링의류_상품정보 및 스마트필터</td>
                                    <td>2023-05-27</td>
                                    <td>admin</td>
                                    <td>미사용</td>
                                </tr> */}
                            </tbody>
                        </table>
                        <PaginationNew
                            className="admin_st"
                            totalPage={totalPage}
                            currentPage={currentPage}
                            defaultShowPage={5}
                            changePageCB={handlePageChange}
                        />
                        <div className={`${classes.bottom_btn_wrap} ${classes.position_st}`}>
                            <Button className="secondary sm" onClick={uploadHandler}>
                                등록
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default MngProductSmartFilter;
export const loader = async () => {
    const response = await ReqSmartFilterList(0);

    if (response.status === 200) {
        const resData = await response.data.datas;
        return {
            data: resData.content,
            totalPage: resData.totalPages,
            totalCount: resData.totalElements,
        };
    } else {
        return { data: [], totalPage: 0, totalCount: 0 };
    }
};
