import { useState, useContext } from "react";
import { ReqAdminSignIn } from "../../../api/auth/AuthApi";
import Button from "../../../components/UI/Button/Button";
import Checkbox from "../../../components/UI/Input/Checkbox";
import Input from "../../../components/UI/Input/Input";
import classes from "./AdminSign.module.css";
import { JwtTokenContext } from "../../../store/JwtTokenProvider";
import { useNavigate } from "react-router-dom";

function AdminSignIn() {
    const navigate = useNavigate();
    const tokenCtx = useContext(JwtTokenContext); // tokenCtx
    const [userId, setUserId] = useState();
    const [password, setPassword] = useState();

    const useIdHandler = (value) => {
        setUserId(value);
    };

    const passwordHandler = (value) => {
        setPassword(value);
    };

    const onSubmitHandler = async (e) => {
        e.preventDefault();
        
        const response = await ReqAdminSignIn(userId, password);
        // console.log(response);
        if (response.status !== 200) {
            alert(`[${response.status}] ${response.error}`);
            return;
        }
        if (response.data.status !== 200 && response.data.status !== 0) {
            alert(`[${response.status}] ${response.data.error}`);
            return;            
        }
        if (response.data.is_dormant === "Y") {
            alert("휴면 계정 해지 페이지로 이동 기능 생성필요.");
            return;
        }

        const decodedToken = tokenCtx.addToken({
            token: response.data.access_token,
            refreshToken: response.data.refresh_token,
        });

        // 볼링 센터일 경우
        if (decodedToken?.role === 'C') {
            navigate('/admin/tournament/resultUpload')
        }
        else {
            navigate('/admin')
        }
    };

    return (
        <div className={classes.admin_sign_wrap}>
            <div className={classes.sign_in_wrap}>
                <div className={classes.sign_in_visual}>
                    <h1>
                        <span className={classes.logo}>logo</span>관리자 페이지
                    </h1>
                </div>
                <div className={classes.sign_in_bx}>
                    <form>
                        <Input
                            className="admin_login"
                            id="userId"
                            type="userId"
                            placeholder="아이디"
                            label="아이디"
                            onChange={useIdHandler}
                        />
                        <Input
                            className="admin_login"
                            id="password"
                            type="password"
                            placeholder="비밀번호"
                            label="비밀번호"
                            onChange={passwordHandler}
                        />
                        <Checkbox className="admin_label" id="chk01" value="아이디 저장" />
                        <Button type="submit" className="secondary sm" onClick={onSubmitHandler}>
                            로그인
                        </Button>
                    </form>
                </div>
            </div>
            <footer>
                <p>Copyright 2023 JINSEUNG. All Rights Reserved.</p>
            </footer>
        </div>
    );
}
export default AdminSignIn;
