import { useEffect, useRef, useState } from "react";
import Button from "../../../../components/UI/Button/Button";
import classes from "../../MngCommon.module.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate, useLoaderData, useLocation } from "react-router-dom";
import axios from "axios";
import SelectBoxNew from "../../../../components/UI/SelectBox/SelectBoxNew";
import { DefaultRoundNameSelectBox } from "../../../../components/definedefault/DefSelectBoxValues";
import instance from "../../../../store/AxiosInterceptor";

function MngTournamentResultDetail(props) {
    const bowlingAlleyData = useLoaderData().resultData; // 등록일 등 경기장 상세
    const pointData = useLoaderData().pointData.datas; // 등록일 등 경기장 상세

    const [pointList, setPointList] = useState(pointData);

    // console.log(bowlingAlleyData, pointData);
    const location = useLocation();
    const navigate = useNavigate();

    const update = () => {
        navigate(`/admin/tournament/result/update/${bowlingAlleyData.id}`, {
            state: location.state,
        });
    };

    // 목록 버튼
    const toList = () => {
        navigate("/admin/tournament/result", { state: location.state });
    };
    useEffect(() => {
        window.addEventListener("popstate", toList);
        return () => {
            window.removeEventListener("popstate", toList);
        };
    }, []);
    // 대회 갤러리 이미지 슬라이드
    const sliderRef = useRef(null);
    const arr = bowlingAlleyData.images.length; // 1
    const sliderSettings = {
        lazyLoad: true,
        arrows: true,
        infinite: arr > 3,
        slidesToShow: 3,
        slidesToScroll: 1,
    };

    // console.log(bowlingAlleyData.images);
    // 이미지 데이터가 있을때만 보여지도록
    const sliderItems = bowlingAlleyData.images?.map((image, index) => (
        <div className={classes.main_img} key={index}>
            <img src={image.uri} alt={`대회 이미지${index + 1}`} />
        </div>
    ));

    const [bodyValues, setBodyValues] = useState(
        pointList.map(
            (
                {
                    member_name,
                    member_id,
                    round_name,
                    first_game,
                    second_game,
                    third_game,
                    fourth_game,
                    fifth_game,
                    total,
                    handicap,
                    rank,
                },
                index
            ) => {
                return (
                    <tr>
                        <td>{rank}</td>
                        <td>
                            {member_name}({member_id})
                        </td>
                        <td>{total}</td>
                        <td>{handicap}</td>
                        <td>{first_game}</td>
                        <td>{second_game}</td>
                        <td>{third_game !== 0 ? third_game : ""}</td>
                        <td>{fourth_game !== 0 ? fourth_game : ""}</td>
                        <td>{fifth_game !== 0 ? fifth_game : ""}</td>
                    </tr>
                );
            }
        )
    );

    const [roundNameList, setRoundNameList] = useState([
        DefaultRoundNameSelectBox,
    ]);

    const [selectedRound, setSelectedRound] = useState("");
    const onChangeRoundHandler = (key) => {
        setSelectedRound(key);
        const filteredBodyValues = pointList
            .filter((data) => {
                if (key === null) {
                    return true;
                }
                return data.round_name === key;
            })
            .map(
                (
                    {
                        member_name,
                        member_id,
                        round_name,
                        first_game,
                        second_game,
                        third_game,
                        fourth_game,
                        fifth_game,
                        total,
                        handicap,
                    },
                    index
                ) => {
                    return (
                        <tr>
                            <td>{index + 1}</td>
                            <td>
                                {member_name}({member_id})
                            </td>
                            <td>{total}</td>
                            <td>{handicap}</td>
                            <td>{first_game}</td>
                            <td>{second_game}</td>
                            <td>{third_game}</td>
                            <td>{fourth_game !== 0 ? fourth_game : ""}</td>
                            <td>{fifth_game !== 0 ? fifth_game : ""}</td>
                        </tr>
                    );
                }
            );
        setBodyValues(filteredBodyValues);
    };

    useEffect(() => {
        const uniqueRoundNames = [
            ...new Set(pointList.map((item) => item.round_name)),
        ];
        const serverRoundNameList = uniqueRoundNames.map((round_name) => {
            return {
                key: round_name,
                value: round_name,
                disabled: false,
            };
        });
        setRoundNameList((prev) => [
            DefaultRoundNameSelectBox,
            ...serverRoundNameList,
        ]);
    }, [pointList]);
    return (
        <section>
            <div className={classes.admin_contents}>
                <h3>대회결과 - 상세</h3>
                <div
                    className={`${classes.contents_wrap} ${classes.search_wrap}`}
                >
                    <div className={classes.admin_table_wrap}>
                        <table>
                            <colgroup>
                                <col style={{ width: "159px" }} />
                                <col style={{ width: "620px" }} />
                                <col style={{ width: "159px" }} />
                                <col style={{ width: "620px" }} />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>등록일</th>
                                    <td>{bowlingAlleyData.regDate}</td>
                                    <th>등록자</th>
                                    <td>{bowlingAlleyData.regID}</td>
                                </tr>
                                <tr>
                                    <th>대회명</th>
                                    <td colSpan={3}>
                                        {bowlingAlleyData.title}
                                    </td>
                                </tr>
                                <tr>
                                    <th>지역</th>
                                    <td>{bowlingAlleyData.regionName}</td>
                                    <th>경기장</th>
                                    <td>{bowlingAlleyData.centerName}</td>
                                </tr>
                                <tr>
                                    <th>노출여부</th>
                                    <td colSpan={3}>
                                        {bowlingAlleyData.exposeYn === "Y"
                                            ? "노출"
                                            : "미노출"}
                                    </td>
                                </tr>
                                <tr>
                                    <th>랭킹 적용</th>
                                    <td>
                                        {bowlingAlleyData.excelRankingYn === "Y"
                                            ? "엑셀 적용"
                                            : "서버 적용"}
                                    </td>
                                    <th>경기별 결과 적용</th>
                                    <td>
                                        {bowlingAlleyData.resultExcelYn === "Y"
                                            ? "적용"
                                            : "미적용"}
                                    </td>
                                </tr>
                                <tr>
                                    <th>대회 갤러리 이미지</th>
                                    <td colSpan={3}>
                                        <div
                                            className={
                                                classes.gallery_img_slider
                                            }
                                        >
                                            <Slider
                                                ref={sliderRef}
                                                {...sliderSettings}
                                            >
                                                {sliderItems}
                                            </Slider>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>내용</th>
                                    <td colSpan={3}>
                                        {" "}
                                        <div>
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: bowlingAlleyData.content,
                                                }}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>경기별 결과</th>
                                    <td colSpan={3}>
                                        <div
                                            className={`${classes.admin_table_wrap} ${classes.align_center}`}
                                        >
                                            <SelectBoxNew
                                                className="admin_st w_50 right"
                                                optionDatas={roundNameList}
                                                setSelectItemCB={
                                                    onChangeRoundHandler
                                                }
                                                showDefaultKey={selectedRound}
                                            />
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>순위</th>
                                                        <th>이름(아이디)</th>
                                                        <th>총점</th>
                                                        <th>핸디</th>
                                                        <th>1G</th>
                                                        <th>2G</th>
                                                        <th>3G</th>
                                                        <th>4G</th>
                                                        <th>5G</th>
                                                    </tr>
                                                </thead>
                                                <tbody>{bodyValues}</tbody>
                                            </table>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className={classes.bottom_btn_wrap}>
                            <Button className="border sm" onClick={update}>
                                수정
                            </Button>
                            <Button className="secondary sm" onClick={toList}>
                                목록
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default MngTournamentResultDetail;

export const loader = async ({ params }) => {
    try {
        const [resultDataResponse, pointDataResponse] = await Promise.all([
            instance.get(`/api/v1/admin/contest/result/${params.result_id}`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            }),
            instance.get(
                `/api/v1/admin/contest/result/${params.result_id}/report`,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            ),
        ]);

        if (
            resultDataResponse.status === 200 ||
            pointDataResponse.status === 200
        ) {
            const resultData = resultDataResponse.data;
            const pointData = pointDataResponse.data;

            return { resultData, pointData };
        } else {
            throw new Error("Failed to fetch data");
        }
    } catch (error) {
        console.error("Error fetching data:", error);
        return null;
    }
};
