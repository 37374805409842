import { NavLink } from "react-router-dom";
import classes from "./Introduce.module.css";

function IntroduceNavigation(props) {
    return (
        <div className={classes.introduce_sub_nav}>
            <h3>회사소개</h3>
            <nav>
                <ul className={classes.depth_1}>
                    <li className={classes.depth_1_link}>
                        <NavLink to="introduce">인사말</NavLink>
                    </li>
                    <li className={classes.depth_1_link}>
                        <NavLink to="history">연혁</NavLink>
                    </li>
                    <li className={classes.depth_1_link}>
                        <NavLink to="staff">STAFF</NavLink>
                    </li>
                    <li className={classes.depth_1_link}>
                        <NavLink to="center">전국 볼링장</NavLink>
                    </li>
                    <li className={classes.depth_1_link}>
                        <NavLink to="location">찾아오시는 길</NavLink>
                    </li>
                </ul>
            </nav>
        </div>
    );
}

export default IntroduceNavigation;
