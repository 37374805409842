import Input from "../../../components/UI/Input/Input";
import classes from "../TournamentContainer.module.css";
import Button from "../../../components/UI/Button/Button";
import { useEffect, useState } from "react";
import { NavLink, useLoaderData, useLocation } from "react-router-dom";
import instance from "../../../store/AxiosInterceptor";
import PaginationNew from "../../../components/UI/Table/PaginationNew";
function TournamentNotice() {
    const ctNotice = useLoaderData();
    const location = useLocation();

    const [text, setText] = useState([]);
    const topData = ctNotice.topData; // 상단 공지 데이터
    const [currentPage, setCurrentPage] = useState(location.state?.currentPage ?? 0);
    const [totalPage, setTotalPage] = useState(ctNotice.totalPage); //총 페이지 갯수
    const [totalCount, setTotalCount] = useState(ctNotice.totalCount); //총 페이지 갯수

    const handlePageChange = async (page) => {
        setCurrentPage(page);

        const searchParam = {
            page: page,
            searchText: searchText,
        };

        const response = await instance.get(
            "/api/v1/home/contest/contestNotice",
            {
                params: searchParam,
            }
        );
        if (response.status === 200) {
            const resData = await response.data.datas;
            setTotalPage(resData.totalPages);
            setTotalCount(resData.totalElements);
            setText(resData.content);
        } else {
            setText([]);
        }
    };
    useEffect(() => {
        handlePageChange(currentPage);
    }, [])
    function resCtTypeClass(ctType) {
        switch (ctType) {
            case "K":
                return classes.type_a;
            case "L":
                return classes.type_b;
            case "A":
                return classes.type_c;
            case "B":
                return classes.type_d;
            case "S":
                return classes.type_d;
            default:
                return "";
        }
    }

    const [searchText, setSearchText] = useState("");

    const onSearchTextHandler = (value) => {
        setSearchText(value);
    };

    const findSearchHandler = async () => {
        setCurrentPage(0);

        const searchParam = {
            page: currentPage,
            searchText: searchText,
        };

        const response = await instance.get(
            "/api/v1/home/contest/contestNotice",
            {
                params: searchParam,
            }
        );
        if (response.status === 200) {
            const resData = await response.data.datas;
            setTotalPage(resData.totalPages);
            setTotalCount(resData.totalElements);
            setText(resData.content);
        } else {
            setText([]);
        }
    };
    const increaseViews = (id) => {
        instance.put(`/api/v1/home/contest/${id}/views`).catch((error) => {
            console.error("Error increasing views:", error);
        });
    };

    return (
        <div className={`container ${classes.tournament_container}`}>
            <div className={classes.tournament_sub_header}>
                <h2>대회공지</h2>
            </div>
            <section>
                <div className={classes.search_wrap}>
                    <Input onChange={onSearchTextHandler} />
                    <Button className="search_sm" onClick={findSearchHandler} />
                </div>
                <div className="board_table">
                    <table className="align_center align_left_col3">
                        <colgroup>
                            <col style={{ width: "8%" }} />
                            <col style={{ width: "18%" }} />
                            <col style={{ width: "55%" }} />
                            <col style={{ width: "19%" }} />
                        </colgroup>
                        <thead>
                            <tr>
                                <th>번호</th>
                                <th>유형</th>
                                <th>제목</th>
                                <th>등록일자</th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* 상단 공지 데이터 표시 */}
                            {topData.map((data) => (
                                <tr key={data.id}>
                                    <td>
                                        <b>공지</b>
                                    </td>
                                    <td>
                                        <b
                                            className={resCtTypeClass(
                                                data.ct_type
                                            )}
                                        >
                                            {data.type_title}
                                        </b>
                                    </td>
                                    <td onClick={() => increaseViews(data.id)}>
                                        <NavLink to={`${data.id}`}>
                                            <b>{data.title}</b>
                                        </NavLink>
                                    </td>
                                    <td>
                                        <b>{data.regDate}</b>
                                    </td>
                                </tr>
                            ))}
                            {text.map((data, index) => {
                                return (
                                    <tr>
                                        <td>
                                            {totalCount -
                                                (10 * currentPage + index)}
                                        </td>
                                        <td>
                                            <b
                                                className={resCtTypeClass(
                                                    data.ct_type
                                                )}
                                            >
                                                {data.type_title}
                                            </b>
                                        </td>
                                        <td
                                            onClick={() =>
                                                increaseViews(data.id)
                                            }
                                        >
                                            <NavLink to={`${data.id}`} state={{ currentPage }}>
                                                {data.title}
                                            </NavLink>
                                        </td>
                                        <td>{data.regDate}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    <PaginationNew
                        className="admin_st"
                        totalPage={totalPage}
                        currentPage={currentPage}
                        defaultShowPage={5}
                        changePageCB={handlePageChange}
                    />
                </div>
            </section>
        </div>
    );
}
export default TournamentNotice;

export const loader = async () => {
    return instance
        .get("/api/v1/home/contest/contestNotice?page=0")
        .then((response) => {
            const resData = response.data.datas;
            const curData = resData.content.map((data) => {
                return {
                    id: data.id,
                    title: data.title,
                    exposeYn: data.exposeYn,
                    readCnt: data.readCnt,
                    regID: data.regID,
                    regDate: data.regDate,
                    ct_type: data.ct_type,
                    type_title: data.type_title,
                };
            });
            return {
                data: curData,
                topData: response.data.topNoticeList,
                totalPage: resData.totalPages,
                totalCount: resData.totalElements,
            };
        })
        .catch((error) => {
            return [];
        });
};
