import { Outlet } from "react-router-dom";
import classes from "../MngCommon.module.css";
import MngUserNavigation from "./MngUserNavigation";

function MngUserRootLayout() {
  return (
    <>
      <div className={classes.admin_container}>
        <MngUserNavigation />
        <Outlet />
      </div>
    </>
  );
}

export default MngUserRootLayout;
