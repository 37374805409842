import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ReqApplyTournamentInfo } from "../../../../api/home/TournamentHomeApplyApi";
import Button from "../../../../components/UI/Button/Button";
import Alert from "../../../../components/UI/Modal/Alert";
import { JwtTokenContext } from "../../../../store/JwtTokenProvider";
import classes from "../../TournamentContainerApp.module.css";
import ApplyTournamentFormApp from "../ApplyTournamentFormApp";
import ReceptionStatusApp from "../ReceptionStatusApp";
import ViewLocation from "../ViewLocation";
import Modal from "../../../../components/UI/Modal/Modal";
import { useMemStore } from "../../../../store/memstore/MemStore";
import { ReqCheckContestCondition } from "../../../../api/tournament/ApiForContestApply";

/**
 * 대회 접수 상세 페이지
 *
 * @returns
 */
const ApplyTournamentDetailApp = () => {
    const params = useParams();
    const contestId = params.contestId;
    const placeId = params.placeId;

    const loginCtx = useContext(JwtTokenContext);
    const isLogin = loginCtx.isLogin();

    const [contestDetail, setContestDetail] = useState({});
    // login 하고 돌아오기 위함
    const setMemStore = useMemStore(false)[1];

    //접수결과 레인 배치
    const [showApplyResultAndLane, setShowApplyResultAndLane] = useState(false);
    const onShowApplyResultLaneModalHandler = (state) => {
        setShowApplyResultAndLane(state);
    };

    const navigate = useNavigate();
    const moveListHandler = () => {
        navigate("/tournament/apply");
    };

    const findContestInfoHandler = (contestId, placeId) => {
        ReqApplyTournamentInfo(
            contestId,
            placeId,
            (result) => {
                const curData = {
                    ctTitle: result.ct_title,
                    ctType: result.ct_type,
                    ctTypeName: result.ct_type_name,
                    centerName: result.center_name,
                    centerRegion: result.center_region,
                    ctStartDate: result.ct_start_date,
                    ctEndDate: result.ct_end_date,
                    appStartTime: result.ct_app_start_time,
                    appEndTime: result.ct_app_end_time,
                    centerDefaultAddress: result.center_default_address,
                    centerDetailedAddress: result.center_detailed_address,
                    centerPhoneNumber: result.center_phone_number,
                    ctAgreementInfo: result.ct_agreement_info,
                    completePosition: result.complete_position,
                    ctAppType: result.ct_app_type,
                    exposeDraw: result.expose_draw,
                    exposePosition: result.expose_position,
                    addedNotice: result.added_notice,
                    ctProcessType: result?.ct_process_type,
                    placeCloseApply: result?.close_apply,
                };
                setContestDetail(curData);
            },
            (error_result) => {
                setContestDetail((prev) => prev);
            }
        );
    };

    let newCtTypeCss = "";
    switch (contestDetail.ctType) {
        case "K":
            newCtTypeCss = classes.type_a;
            break;
        case "L":
            newCtTypeCss = classes.type_b;
            break;
        case "A":
            newCtTypeCss = classes.type_c;
            break;
        case "B":
            newCtTypeCss = classes.type_d;
            break;
        case "S":
            newCtTypeCss = classes.type_e;
            break;
    }

    //경기장 위치보기 모달 상태 관리
    const [showCenterInfo, setShowCenterInfo] = useState(false);
    const onShowCenterInfoHandler = (state) => {
        setShowCenterInfo(state);
    };

    //접수하기 모달 상태 관리
    const [showApplyModal, setShowApplyModal] = useState(false);
    const [loginAlert, setLoginAlert] = useState(false);
    const [retryLogin, setRetryLogin] = useState(false);
    const [conditionAlert, setConditionAlert] = useState(false);
    const [conditionText, setConditionText] = useState("");

    const reLoginModalHandler = (state) => {
        setRetryLogin(state);
    }

    const onShowApplyModalHandler = (state) => {
        if (!isLogin) {
            onShowLoginModalHandler(true);
            return;
        }

        ReqCheckContestCondition(
            contestId,
            (result) => {
                if (result.status === 200) {
                    setShowApplyModal(state);
                }
            },
            (error_result) => {
                if (error_result.status === 403) {
                    loginCtx.removeToken();
                    reLoginModalHandler(true);
                    return;
                }

                if (error_result.status === 400) {
                    setConditionAlert(true);
                    setConditionText(`아래사유로 접수 하실 수 없습니다 : ${error_result.error}`);
                    return;
                }
            }
        );
    };

    const onShowLoginModalHandler = (state) => {
        setLoginAlert(state);
    };

    const navigateHandler = () => {
        if (loginAlert) onShowLoginModalHandler(false);
        if (retryLogin) reLoginModalHandler(false);
        // configureMemStore에 정의된 ACTION
        setMemStore("LOGIN_MOVE_PAGE", {
            uri: `/tournament/apply/${contestId}/${placeId}`,
        });
        navigate("/login");
    };

    //그룹(조) 선택
    const [selectedGroup, setSelectedGroup] = useState();
    const onSelectedGroupHandler = (key) => {
        setSelectedGroup(key);
    };

    //장애여부 선택
    const [disableStatus, setDisableStatus] = useState();
    const onDisableStatusHandler = (key) => {
        setDisableStatus(key);
    };

    useEffect(() => {
        findContestInfoHandler(contestId, placeId);
    }, []);

    const now = new Date();
    const contestAppStartTime = new Date(contestDetail.appStartTime);
    const contestAppEndTime = new Date(contestDetail.appEndTime);

    // [M] 20230905 YiSuHwan 대회상태관련
    const ctProcessType = contestDetail?.ctProcessType;
    const placeCloseApply = contestDetail?.placeCloseApply;
    let isApply = false;
    if (ctProcessType) {
        if (ctProcessType === "APPLY") {
            if (placeCloseApply && placeCloseApply === "Y") {
                isApply = false;
            } else {
                isApply = true;
            }
        }
    } else {
        // 절대 타지 않아야 하지만 혹시나 예외가 있을 수 있어 추가됨 backend 적용 후 삭제 무방
        isApply = now > contestAppStartTime && now < contestAppEndTime;
    }

    return (
        <section>
            <div className={classes.view_content}>
                <div className={classes.title}>
                    <b className={newCtTypeCss}>{contestDetail.ctTypeName}</b>
                    <h3>{contestDetail.ctTitle}</h3>
                </div>
                <div className={`${classes.content_wrap} ${classes.mobile_detail_table}`}>
                    <table>
                        <colgroup>
                            <col style={{ width: "14%" }} />
                            <col style={{ width: "86%" }} />
                        </colgroup>
                        <tbody>
                            <tr>
                                <th>대회기간</th>
                                <td>
                                    {contestDetail.ctStartDate} ~ {contestDetail.ctEndDate}
                                </td>
                            </tr>
                            <tr>
                                <th>접수기간</th>
                                <td>
                                    {contestDetail.appStartTime} ~ {contestDetail.appEndTime}
                                </td>
                            </tr>
                            <tr>
                                <th>경기장</th>
                                <td>
                                    {contestDetail.centerRegion} {contestDetail.centerName}
                                    <Button className="poi" onClick={() => onShowCenterInfoHandler(true)} />
                                </td>
                            </tr>
                            <tr>
                                <th>접수결과 및 레인</th>
                                <td>
                                    <Button
                                        className="border xs"
                                        onClick={() => onShowApplyResultLaneModalHandler(true)}
                                    >
                                        접수결과 및 레인
                                    </Button>
                                </td>
                            </tr>
                            <tr>
                                <th>유의사항</th>
                                <td className={classes.inner_table}>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: contestDetail.addedNotice,
                                        }}
                                    ></div>
                                    {/* <Button className="border sm">
                                    대회 안내보기
                                </Button> */}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className={classes.btn_wrap}>
                    <Button onClick={moveListHandler}>목록으로</Button>
                    {isApply && (
                        <Button className="secondary" onClick={() => onShowApplyModalHandler(true)}>
                            접수하기
                        </Button>
                    )}
                    {!isApply && (
                        <Button className="secondary" disabled>
                            접수하기
                        </Button>
                    )}
                </div>
            </div>
            {showCenterInfo && (
                <Modal close={() => onShowCenterInfoHandler(false)} header="경기장 위치보기">
                    <ViewLocation
                        centerDefaultAddress={contestDetail.centerDefaultAddress}
                        centerDetailedAddress={contestDetail.centerDetailedAddress}
                        centerPhoneNumber={contestDetail.centerPhoneNumber}
                        region={contestDetail.centerRegion}
                        name={contestDetail.centerName}
                        close={() => onShowCenterInfoHandler(false)}
                    />
                </Modal>
            )}
            {showApplyModal && (
                <Modal close={() => onShowApplyModalHandler(false)} header="대회 신청">
                    <ApplyTournamentFormApp
                        close={() => onShowApplyModalHandler(false)}
                        contestId={contestId}
                        placeId={placeId}
                        selectedGroup={selectedGroup}
                        onGroupNameHandler={onSelectedGroupHandler}
                        selectedDisable={disableStatus}
                        onDisableHandler={onDisableStatusHandler}
                        onApplyModalHandler={onShowApplyModalHandler}
                        region={contestDetail.centerRegion}
                        centerName={contestDetail.centerName}
                        ctTitle={contestDetail.ctTitle}
                        ctAgreementInfo={contestDetail.ctAgreementInfo}
                        ctAppType={contestDetail.ctAppType}
                    />
                </Modal>
            )}
            {showApplyResultAndLane && contestDetail.exposeDraw === "N" && (
                <Alert type="alert" confirm="확인" close={() => onShowApplyResultLaneModalHandler(false)}>
                    {"선정여부를 게재하기 전입니다."}
                </Alert>
            )}
            {showApplyResultAndLane && contestDetail.exposeDraw === "Y" && (
                <Modal close={() => onShowApplyResultLaneModalHandler(false)} header="접수결과 및 레인">
                    <ReceptionStatusApp
                        contestId={contestId}
                        placeId={placeId}
                        ctTitle={contestDetail.ctTitle}
                        region={contestDetail.centerRegion}
                        centerName={contestDetail.centerName}
                        completePosition={contestDetail.completePosition}
                        exposePosition={contestDetail.exposePosition}
                    />
                </Modal>
            )}
            {loginAlert && (
                <Alert type="alert" confirm="확인" close={() => navigateHandler()}>
                    {"로그인이 필요한 서비스입니다."}
                </Alert>
            )}
            {retryLogin && (
                <Alert type="alert" confirm="확인" close={() => navigateHandler()}>
                    {"다시 로그인 해주세요."}
                </Alert>
            )}
            {conditionAlert && (
                <Alert type="alert" confirm="확인" close={() => setConditionAlert(false)}>
                    {conditionText}
                </Alert>
            )}
        </section>
    );
};

export default ApplyTournamentDetailApp;
