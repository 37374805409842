import { NavLink } from "react-router-dom";
import classes from "../MngCommon.module.css";

function MngUserNavigation(props) {
  return (
    <div className={classes.admin_sub_nav}>
      <nav>
        <ul className={classes.depth_1}>
          <li className={classes.depth_1}>
            <NavLink to="list">회원정보관리</NavLink>
            <ul className={classes.depth_2}>
              <li>
                <NavLink to="list">회원목록</NavLink>
              </li>
              <li>
                <NavLink to="info">회원정보 등록/변경 접수</NavLink>
              </li>
              <li>
                <NavLink to="writtenAccept">서면회원가입</NavLink>
              </li>
              <li>
                <NavLink to="terms">약관/동의문</NavLink>
              </li>
            </ul>
          </li>
          <li className={classes.depth_1_link}>
            <NavLink to="adminAccount">관리자계정관리</NavLink>
          </li>
          <li className={classes.depth_1_link}>
            <NavLink to="withTournament">대회접수이력</NavLink>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default MngUserNavigation;
