import instance from "../../store/AxiosInterceptor";

/**
 * 유저 대회 목록 페이지, 대회 리스트 리턴
 * @param resolveCB 응답이 성공일 경우 데이터를 넣어 호출하는 callback method
 * @returns
 */
export function ReqTournamentList(page, resolveCB, rejectCB) {
    instance({
        url: `/api/v1/home/contest/list?page=${page}&size=5`,
        method: "GET",
    })
        .then((response) => {
            // List [ { id, name } ...]
            if (resolveCB) {
                resolveCB(response.data.datas.content);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/**
 * 유저 대회 목록 페이지, 대회 리스트 리턴
 * 검색 기능 및 페이징
 * @param resolveCB 응답이 성공일 경우 데이터를 넣어 호출하는 callback method
 * @returns
 */
export function ReqTournamentSearchList(page, searchText, resolveCB, rejectCB) {
    instance({
        url: `/api/v1/home/contest/list/search?searchText=${searchText}&page=${page}&size=5`,
        method: "GET",
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data.datas.content);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/**
 * 유저 대회 목록 페이지, 대회 클릭 후 대회 상세 정보
 * @param resolveCB 응답이 성공일 경우 데이터를 넣어 호출하는 callback method
 * @returns
 */
export function ReqTournamentInfo(contestId,resolveCB, rejectCB) {
    instance({
        url: `/api/v1/home/contest/list/info/${contestId}`,
        method: "GET",
    })
        .then((response) => {
            // List [ { id, name } ...]
            if (resolveCB) {
                resolveCB(response.data);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}