import { useState } from "react";
import classes from "../MngCommon.module.css";
import Button from "../../../components/UI/Button/Button";
import Input from "../../../components/UI/Input/Input";
import FileBox from "../../../components/UI/Input/FileBox";
import Radio from "../../../components/UI/Input/Radio";
import Pagination from "../../../components/UI/Table/Pagination";
import DateRangePicker from "../../../components/UI/DatePicker/DateRangePicker";
import SelectBoxNew from "../../../components/UI/SelectBox/SelectBoxNew";
function MngCompany() {
    const [viewMode, setViewMode] = useState(0);
    // viewMode : 0 - 리스트, 1- 작성, 2- 상세
    const handleModeChange = (mode) => {
        setViewMode(mode);
        window.scrollTo(0, 0);
    };
    return (
        <section>
            {viewMode === 0 && (
                <div className={classes.admin_contents}>
                    <h3>회사안내 - 목록</h3>
                    <div className={classes.contents_wrap}>
                        <div
                            className={`${classes.admin_table_wrap} ${classes.align_center}  ${classes.row_select}`}
                        >
                            <table>
                                <colgroup>
                                    <col style={{ width: "5%" }} />
                                    <col style={{ width: "50%" }} />
                                    <col style={{ width: "15%" }} />
                                    <col style={{ width: "15%" }} />
                                    <col style={{ width: "15%" }} />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th>No</th>
                                        <th>제목</th>
                                        <th>등록일</th>
                                        <th>작성자</th>
                                        <th>사용여부</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr onClick={() => handleModeChange(2)}>
                                        <td>1</td>
                                        <td>인사말</td>
                                        <td>2023-05-27</td>
                                        <td>admin</td>
                                        <td>사용</td>
                                    </tr>
                                    <tr onClick={() => handleModeChange(2)}>
                                        <td>2</td>
                                        <td>연혁</td>
                                        <td>2023-05-27</td>
                                        <td>admin</td>
                                        <td>미사용</td>
                                    </tr>
                                    <tr onClick={() => handleModeChange(2)}>
                                        <td>3</td>
                                        <td>찾아오시는길</td>
                                        <td>2023-05-27</td>
                                        <td>admin</td>
                                        <td>미사용</td>
                                    </tr>
                                </tbody>
                            </table>
                            <Pagination className="admin_st" />
                            <div
                                className={`${classes.bottom_btn_wrap} ${classes.position_st}`}
                            >
                                <Button
                                    className="secondary sm"
                                    onClick={() => {
                                        handleModeChange(1);
                                    }}
                                >
                                    등록
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {viewMode === 1 && (
                <div className={classes.admin_contents}>
                    <h3>회사안내 - 등록/수정</h3>
                    <div
                        className={`${classes.contents_wrap} ${classes.search_wrap} `}
                    >
                        <div className={classes.admin_table_wrap}>
                            <table>
                                <colgroup>
                                    <col style={{ width: "159px" }} />
                                    <col style={{ width: "620px" }} />
                                    <col style={{ width: "159px" }} />
                                    <col style={{ width: "620px" }} />
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <th>등록일</th>
                                        <td>2023-04-15</td>
                                        <th>등록자</th>
                                        <td>admin</td>
                                    </tr>
                                    <tr>
                                        <th>사용여부</th>
                                        <td colSpan={3}>
                                            <div className={classes.radio_wrap}>
                                                <Radio
                                                    value="사용"
                                                    title="사용"
                                                    name="rad_01"
                                                    id="rad_01_01"
                                                />
                                                <Radio
                                                    value="미사용"
                                                    title="미사용"
                                                    name="rad_01"
                                                    id="rad_01_02"
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>제목</th>
                                        <td colSpan={3}>
                                            <Input value="인사말" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>내용</th>
                                        <td colSpan={3}>text editor</td>
                                    </tr>
                                </tbody>
                            </table>

                            <div className={classes.bottom_btn_wrap}>
                                <Button className="border sm">등록</Button>
                                <Button
                                    className="secondary sm"
                                    onClick={() => handleModeChange(0)}
                                >
                                    목록
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {viewMode === 2 && (
                <div className={classes.admin_contents}>
                    <h3>회사안내 - 상세</h3>
                    <div
                        className={`${classes.contents_wrap} ${classes.search_wrap} `}
                    >
                        <div className={classes.admin_table_wrap}>
                            <table>
                                <colgroup>
                                    <col style={{ width: "159px" }} />
                                    <col style={{ width: "620px" }} />
                                    <col style={{ width: "159px" }} />
                                    <col style={{ width: "620px" }} />
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <th>등록일</th>
                                        <td>2023-04-15</td>
                                        <th>등록자</th>
                                        <td>admin</td>
                                    </tr>
                                    <tr>
                                        <th>사용여부</th>
                                        <td colSpan={2}>미사용</td>
                                    </tr>
                                    <tr>
                                        <th>제목</th>
                                        <td colSpan={3}>인사말</td>
                                    </tr>
                                    <tr>
                                        <th>내용</th>
                                        <td colSpan={3}>text editor</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className={classes.bottom_btn_wrap}>
                                <Button
                                    className="border sm"
                                    onClick={() => handleModeChange(1)}
                                >
                                    수정
                                </Button>
                                <Button className="sm">삭제</Button>
                                <Button
                                    className="secondary sm"
                                    onClick={() => handleModeChange(0)}
                                >
                                    목록
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </section>
    );
}
export default MngCompany;
