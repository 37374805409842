import instance from "../../store/AxiosInterceptor";

/**
 * BO - 이벤트 관리
 * 전체보기
 */
export function ReqFindAllEventList(resolveCB, rejectCB) {
    instance({
        url: `/api/v1/admin/event?title=&expose=&sDate=&eDate=&page=0&size=10`,
        method: "GET",
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/**
 * BO - 이벤트 관리
 * 검색
 */
export function ReqSearchEventList(object, resolveCB, rejectCB) {
    instance({
        url: `/api/v1/admin/event?title=${object.title}&expose=${object.expose}&sDate=${object.sDate}&eDate=${object.eDate}&page=0&size=10`,
        method: "GET",
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/**
 * BO - 이벤트 관리
 * 페이징 기능
 */
export function ReqPagingEvent(object, page, resolveCB, rejectCB) {
    instance({
        url: `/api/v1/admin/event?title=${object.title}&expose=${object.expose}&sDate=${object.sDate}&eDate=${object.eDate}&page=${page}&size=10`,
        method: "GET",
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/**
 * BO - 이벤트 관리
 * 이벤트 생성
 */
export function ReqSaveEvent(formData, resolveCB, rejectCB) {
    instance({
        url: `/api/v1/admin/event`,
        method: "POST",
        data: formData,
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/**
 * BO - 이벤트 관리
 * 이벤트 삭제
 */
export function ReqDeleteEvent(eventId, resolveCB, rejectCB) {
    instance({
        url: `/api/v1/admin/event/${eventId}`,
        method: "DELETE",
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/**
 * BO - 이벤트 관리
 * 이벤트 삭제
 */
export function ReqUpdateEvent(eventId, formData, resolveCB, rejectCB) {
    instance({
        url: `/api/v1/admin/event/${eventId}`,
        method: "PUT",
        data : formData
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/**
 * BO - 당첨자발표
 * 당첨자 날짜 설정, 노출여부 설정, 내용 변경
 */
export function ReqUpdateNotifyDate(eventId, bodyData, resolveCB, rejectCB) {
    instance({
        url: `/api/v1/admin/event/chosen/${eventId}`,
        method: "PUT",
        data : bodyData
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/////

/**
 * BO - 이벤트 관리
 * 전체보기
 */
export function ReqFindChosenEventList(resolveCB, rejectCB) {
    instance({
        url: `/api/v1/admin/event/chosen?title=&expose=&sDate=&eDate=&page=0&size=10`,
        method: "GET",
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/**
 * BO - 이벤트 관리
 * 검색
 */
export function ReqSearchChosenEventList(object, resolveCB, rejectCB) {
    instance({
        url: `/api/v1/admin/event/chosen?title=${object.title}&expose=${object.expose}&sDate=${object.sDate}&eDate=${object.eDate}&page=0&size=10`,
        method: "GET",
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/**
 * BO - 이벤트 관리
 * 페이징 기능
 */
export function ReqPagingChosenEvent(object, page, resolveCB, rejectCB) {
    instance({
        url: `/api/v1/admin/event/chosen?title=${object.title}&expose=${object.expose}&sDate=${object.sDate}&eDate=${object.eDate}&page=${page}&size=10`,
        method: "GET",
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}