import { useEffect, useState } from "react";
import BreadCrumb from "../../components/UI/BreadCrumb/BreadCrumb";
import classes from "./Introduce.module.css";
import Input from "../../components/UI/Input/Input";
import Button from "../../components/UI/Button/Button";
import Pagination from "../../components/UI/Table/Pagination";
import instance from "../../store/AxiosInterceptor";
import { useLocation } from "react-router-dom";
import PaginationNew from "../../components/UI/Table/PaginationNew";

function IntroCentersPage(props) {

    const breadCrumbList = ["회사소개", "전국 볼링장"];

    const [tabIndex, setTabIndex] = useState(0);
    const [region, setRegion] = useState("서울");
    const [filter, setFilter] = useState();
    const tabToggle = (index, key) => {
        setTabIndex(index);
        setRegion(key);
    };
    const tabArr = [
        //{ id: 0, title: "전체", filter: "in"},
        { id: 0, title: "서울", key: "서울", disabled: false, filter: false },
        { id: 1, title: "인천", key: "인천", disabled: false, filter: false },
        { id: 2, title: "경기", key: "경기", disabled: false, filter: false },
        { id: 3, title: "충남", key: "충남", disabled: false, filter: false },
        { id: 4, title: "충북", key: "충북", disabled: false, filter: false },
        { id: 5, title: "대전", key: "대전", disabled: false, filter: false },
        { id: 6, title: "전북", key: "전북", disabled: false, filter: false },
        { id: 7, title: "전남", key: "전남", disabled: false, filter: false },
        { id: 8, title: "광주", key: "광주", disabled: false, filter: false },
        { id: 9, title: "경북", key: "경북", disabled: false, filter: false },
        { id: 10, title: "경남", key: "경남", disabled: false, filter: false },
        { id: 11, title: "대구", key: "대구", disabled: false, filter: false },
        { id: 12, title: "울산", key: "울산", disabled: false, filter: false },
        { id: 13, title: "부산", key: "부산", disabled: false, filter: false },
        { id: 14, title: "제주", key: "제주", disabled: false, filter: false },
    ];

    const [tabArray, setTabArray] = useState(tabArr);
    const location = useLocation();

    const [currentPage, setCurrentPage] = useState(location.state?.currentPage ?? 0);
    const [totalPage, setTotalPage] = useState(0); //총 페이지 갯수
    const [totalCount, setTotalCount] = useState(0); //총 페이지 갯수

    const [centers, setCenters] = useState([]);

    const handlePageChange = async (page) => {
        const searchParam = {
            page: page,
            region: region,
            query_text: searchText,
        };
        setCurrentPage(page);

        const response = await instance.get("/api/v1/home/center/address", {
            params: searchParam,
        });
        if (response.status === 200) {
            const resData = await response.data.data;
            setTotalPage(resData.totalPages);
            setTotalCount(resData.totalElements);
            setCenters(resData.content);
        } else {
            setCenters([]);
        }
    };
    useEffect(() => {
        handlePageChange(location.state?.currentPage ?? 0);
    }, [])
    const handleTapChange = async () => {
        setCurrentPage(0);
        const searchParam = {
            page: 0,
            region: region,
            query_text: searchText,
        };

        const response = await instance.get("/api/v1/home/center/address", {
            params: searchParam,
        });
        if (response.status === 200) {
            const resData = await response.data.data;
            // console.log(resData);
            setTotalPage(resData.totalPages);
            setTotalCount(resData.totalElements);
            setCenters(resData.content);
        } else {
            setCenters([]);
        }
    };

    const [searchText, setSearchText] = useState();

    const searchHandler = (key) => {
        setSearchText(key);
    };

    const findSearchHandler = async () => {
        setCurrentPage(0);
        const searchParam = {
            page: currentPage,
            query_text: searchText,
        };
        const response = await instance.get("/api/v1/home/center/address", {
            params: searchParam,
        });
        if (response.status === 200) {
            const resData = await response.data.data;
            setTotalPage(resData.totalPages);
            setTotalCount(resData.totalElements);
            setCenters(resData.content);
            const updatedTabArr = tabArr.map((item) => {
                if (response.data.regionList.includes(item.key)) {
                    return { ...item, disabled: false, filter: true };
                } else {
                    return { ...item, disabled: true, filter: false };
                }
            });
            setRegion(response.data.regionList[0]);
            setTabArray(updatedTabArr);
            setTabIndex(-1);
        } else {
            setCenters([]);
        }
    };

    useEffect(() => {
        handleTapChange();
    }, [region]);

    return (
        <>
            <BreadCrumb breadCrumbList={breadCrumbList} />
            <div className={classes.contents_wrap}>
                <div className={classes.contents_title}>
                    <h3>전국 볼링장</h3>
                </div>
                <div className={classes.center_search_wrap}>
                    <Input
                        placeholder="볼링장을 검색해주세요."
                        onChange={searchHandler}
                    />
                    <Button className="search_sm" onClick={findSearchHandler} />
                </div>
                <ul className={classes.tab_menu}>
                    {tabArray.map((item) => (
                        <li
                            key={item.id}
                            className={` ${
                                tabIndex === item.id && classes.on
                            } ${item.filter ? classes.in : null} `}
                            onClick={() =>
                                !item.disabled && tabToggle(item.id, item.key)
                            } // 클릭 가능한 경우에만 onClick 실행
                        >
                            {item.title}
                        </li>
                    ))}
                </ul>
                <div className={classes.center_card_board}>
                    {centers.length !== 0 ? (
                        centers.map((data, index) => (
                            <div className={classes.box}>
                                <h4>{data.name}</h4>
                                <p>{data.address}</p>
                                <p className={classes.tel}>TEL {data.phone}</p>
                            </div>
                        ))
                    ) : (
                        <div className={classes.empty_data}>
                            <p>검색결과가 없습니다.</p>
                        </div>
                    )}
                </div>
                {totalCount !== centers.length && (
                    <PaginationNew
                        className="admin_st"
                        totalPage={totalPage}
                        currentPage={currentPage}
                        defaultShowPage={5}
                        changePageCB={handlePageChange}
                    />
                )}
            </div>
        </>
    );
}

export default IntroCentersPage;

export const loader = async () => {
    const response = await instance.get(
        `/api/v1/home/center/address?page=0&region=서울`
    );

    if (response.data.status === 200) {
        const resData = await response.data.data;
        return {
            data: resData.content,
            totalPage: resData.totalPages,
            totalCount: resData.totalElements,
        };
    } else {
        return [];
    }
};
