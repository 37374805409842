import { useEffect, useState } from "react";
import Button from "../../../components/UI/Button/Button";
import classes from "../MngCommon.module.css";

import Moment from "moment";
import { CSVLink } from "react-csv";
import { useLocation } from "react-router-dom";
import { ReqContestNameWithCtType2, ReqContestType, ReqRegionData, ReqStadiumListWithRegion } from "../../../api/common/ConditionSelectBoxApi";
import SelectBoxNew from "../../../components/UI/SelectBox/SelectBoxNew";
import PaginationNew from "../../../components/UI/Table/PaginationNew";
import { DefaultAll, DefaultCenterNameSelectBox, DefaultContestNameSelectBox } from "../../../components/definedefault/DefSelectBoxValues";
import instance from "../../../store/AxiosInterceptor";
import MngTournamentDetailPrintAttendModal from "./printComponents/MngTournamentDetailPrintAttendModal";
import MngTournamentDetailPrintLaneModal from "./printComponents/MngTournamentDetailPrintLaneModal";
//import Papa from "papaparse";

function MngTournamentDetailPrint() {

  const location = useLocation();
  const [print, setPrint] = useState([]);

  const [currentPage, setCurrentPage] = useState(location.state?.currentPage ?? 0);
  const [totalPage, setTotalPage] = useState(0); //총 페이지 갯수
  const [totalCount, setTotalCount] = useState(0); //총 페이지 갯수

  // 전체보기
  const viewAll = async () => {
    setCurrentPage(0);
    setContestType("");
    setRegionName("");
    setContestId("");
    setCenterId("");

    const response = await instance({
      method: "GET",
      url: `/api/v1/admin/contest/printing?page=0`,
      headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })

    if (response.status === 200) {
        const resData = await response.data.datas;
        setTotalPage(resData.totalPages);
        setTotalCount(resData.totalElements);
        setPrint(resData.content);
    } else {
        setPrint([]);
    }
  };

  // 페이징
  const handlePageChange = async (page) => {
    setCurrentPage(page);

    const searchParam = {
        contest_type : contestType === "" ? null : contestType,
        contest_id : contestId === "" ? null : contestId,
        region_name : regionName === "" ? null : regionName,
        center_id : centerId === "" ? null : centerId,
        page: page,
    };

    const response = await instance({
      method: "GET",
      url: `/api/v1/admin/contest/printing?page=0`,
      params: searchParam,
      headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })

    if (response.status === 200) {
        const resData = await response.data.datas;
        setTotalPage(resData.totalPages);
        setTotalCount(resData.totalElements);
        setPrint(resData.content);
    } else {
        setPrint([]);
    }
  };
  useEffect(() => {
      handlePageChange(currentPage)
  }, [])
    // 검색 버튼 클릭
  const search = async () => {
    setCurrentPage(0);

    const searchParam = {
        contest_type : contestType === "" ? null : contestType,
        contest_id : contestId === "" ? null : contestId,
        region_name : regionName === "" ? null : regionName,
        center_id : centerId === "" ? null : centerId,
        page: 0,
    };

    const response = await instance({
      method: "GET",
      url: `/api/v1/admin/contest/printing?page=0`,
      params: searchParam,
      headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })

    if (response.status === 200) {
        const resData = await response.data.datas;
        setTotalPage(resData.totalPages);
        setTotalCount(resData.totalElements);
        setPrint(resData.content);
    } else {
        setPrint([]);
    }
  };

  // 대회 타입 -> 대회명
  const [contestTypeData, setContestTypeData] = useState([DefaultAll]);
  const [contestType, setContestType] = useState("");

  const contestTypeHandler = (key) => {
      setContestType(key);
  };

  const contestTypeMouseOverHandler = (key) => {
      if (contestTypeData.length === 1) {
          ReqContestType(
              (result) => {
                  const success = result.map((data) => {
                      return { key: data.type, value: data.name };
                  });
                  setContestTypeData((prev) => [DefaultAll, ...success]);
              },
              (excption_result) => {
                  setContestTypeData((prev) => [DefaultAll]);
              }
          );
      }
  };
  // 대회 타입 -> 대회명

  // >>> 지역 목록
  const [regionData, setRegionData] = useState([DefaultAll]);
  const [regionName, setRegionName] = useState("");

  const regionDataHandler = (key) => {
      setRegionName(key);
  };

  const regionNameMouseOverHandler = (key) => {
      if (regionData.length === 1) {
          ReqRegionData(
              (result) => {
                  const success = result.map((data) => {
                      return { key: data.name, value: data.name };
                  });
                  setRegionData((prev) => [DefaultAll, ...success]);
              },
              (excption_result) => {
                  setRegionData((prev) => [DefaultAll]);
              }
          );
      }
  };
  // <<< 지역 목록

  // >>> 대회명 Default 값 및 대회 타입에 따른 select Box
  const [contestNameList, setContestNameList] = useState([DefaultContestNameSelectBox]);
  const [contestId, setContestId] = useState("");
  const [centerId, setCenterId] = useState("");

  useEffect(() => {
      //contestId선택 후 contestType이 변경되면 contestId를 초기화시킨다.
      setContestId("");
      //대회 조회에서 대회 타입을 변경될때마다 대회목록을 다시 불러온다.
      if (contestType) {
          ReqContestNameWithCtType2(
              contestType,
              (resData) => {
                  const viewDatas = resData.map((data) => {
                      return { key: String(data.id), value: data.name, disabled: false };
                  });
                  setContestNameList((prev) => [DefaultContestNameSelectBox, ...viewDatas]);
              },
              (excption_result) => setContestNameList((prev) => [DefaultContestNameSelectBox])
          );
      } else {
          setContestNameList((prev) => [DefaultContestNameSelectBox]);
      }
  }, [contestType]);

  // >>> 경기장 목록 Default 값 및 지역 명에 따른 경기장 목록 select box
  const [stadiumName, setStadiumName] = useState([DefaultCenterNameSelectBox]);

  useEffect(() => {
      //지역명 선택이 변경될 때 마다 경기장 새로 조회
      if (regionName) {
          ReqStadiumListWithRegion(
              regionName,
              (resData) => {
                  const viewDatas = resData.map((data) => {
                      return { key: data.bowling_center_id, value: data.center_name };
                  });
                  setStadiumName((prev) => [DefaultCenterNameSelectBox, ...viewDatas]);
              },
              (errorDatas) => setStadiumName((prev) => [DefaultCenterNameSelectBox])
          );
      } else {
          setStadiumName((prev) => [DefaultCenterNameSelectBox]);
      }
  }, [regionName]);

  // >>> 경기장 목록 가져오기(전체, 검색)

  
  const handleContestIdCB = (key) => {
    setContestId(key);
  };

  const handleCenterIdCB = (key) => {
      setCenterId(key);
  };
  
  // 출석확인표, 레인배정표 modal
  const [isLaneModalOpen, setIsLaneModalOpen] = useState(false);
  const [isAttendModalOpen, setIsAttendModalOpen] = useState(false);
  const [modalContest, setModalContest] = useState("");
  const [printType, setPrintType] = useState("");

  const openLaneModalHandler = (e, data, printType) => {
      e.stopPropagation();
      setModalContest(data);
      setPrintType(printType)
      setIsLaneModalOpen(true);
  };

  const openAttendModalHandler = (e, data) => {
    e.stopPropagation();
    setModalContest(data);
    setIsAttendModalOpen(true);
  };

  // 정규 표현식을 사용하여 전화번호 형식 변경
  const formatPhoneNumber = (phoneNumber) => {
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{4})(\d{4})$/);
  
    if (match) {
      return match[1] + '-' + match[2] + '-' + match[3];
    }
  
    return phoneNumber;
  };

  /// 출석확인표 엑셀
  const [attendData, setAttendData] = useState([]);

  const attendHeaders = [
    { label: "번호", key: "num" },
    { label: "이름", key: "memberName" },
    { label: "레인", key: "lane" },
    { label: "핸디", key: "handicap" },
    { label: "비고", key: "birthDate" },
    { label: "성별", key: "gender" },
    { label: "연락처", key: "phoneNumber" },
  ];

  const downloadAttendModalHandler = async (e, contest) => {
      try {
          const searchParam = {
              contestID: contest.contest_id,
              placeId: contest.place_id,
              regionName: contest.region,
          };

          const response = await instance({
              method: "GET",
              url: `/api/v1/admin/contest/printing/attendtGroupAll`,
              params: searchParam,
              headers: {
                  Authorization: "Bearer " + localStorage.getItem("token"),
              },
          });

          if (response.status === 200) {
              const resData = await response.data.datas;
              const modifiedData = Object.keys(resData).map((groupKey) => {
                // map 함수를 사용하여 배열을 변환하고 반환
                return resData[groupKey].map((item, index) => {
                  const genderLabel = item.gender === "M" ? '남자' + (item.disabled === "Y" ? "+" : "") : '여자' + (item.disabled === "Y" ? "+" : "");
                  const laneLabel = String(item.lane === null ? "" : item.lane) + "-" + String(item.seqOfLane === 0 ? "" : item.seqOfLane);
              
                  return {
                    num: index+1,
                    memberName: item.memberName,
                    lane: String(laneLabel)+".",
                    handicap: item.handicap,
                    birthDate: Moment(item.birthDate).format("YYYY-MM-DD"),
                    gender: genderLabel,
                    phoneNumber: formatPhoneNumber(item.phoneNumber),
                  };
                });
              });
              setAttendData(modifiedData);

              if (attendData.length > 0) {
                // CSV 데이터 생성
                let csvContent = "\uFEFF"; // BOM 문자로 UTF-8 인코딩 설정 (한글 깨짐 방지)
              
                // 그룹 헤더 추가
                attendData.forEach((groupData, index) => {
                  if(index !== 0) {
                    csvContent += `\n`;
                  }

                  // 그룹명 가져오기
                  const groupKey = `${index + 1}조`; // 0부터 시작하는 인덱스에 1을 더하여 그룹명으로 사용
              
                  // 헤더 추가
                  csvContent += attendHeaders.map((header) => header.label).join(",") + "\n";
                  csvContent += `"${groupKey}"\n`;
              
                  // 데이터 추가
                  groupData.forEach((item) => {
                    csvContent += `"${item.num}","${item.memberName}","${item.lane}","${item.handicap}","${item.birthDate}","${item.gender}","${item.phoneNumber}"\n`;
                  });
                });
              
                // Blob 생성
                const blob = new Blob([csvContent], {
                  type: "text/csv;charset=utf-8;",
                });
              
                // Blob을 URL로 변환하여 다운로드 링크 생성
                const url = URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.style.display = "none";
                a.href = url;
                a.download = `출석확인표.csv`;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
              }
          }
      } catch (error) {
          console.error("Error:", error);
      }
    };
    /// 출석확인표 엑셀


  const [laneData, setLaneData] = useState([]);

  const laneHeaders = [
    { label: "레인", key: "lane" },
    { label: "이름", key: "memberName" },
    { label: "핸디", key: "handicap" },
  ];

  const downloadLaneModalHandler = async (e, contest, printTypes) => {
    try {
        const searchParam = {
            contestID: contest.contest_id,
            placeId: contest.place_id,
            regionName: contest.region,
            type : printTypes,
        };

        const response = await instance({
            method: "GET",
            url: `/api/v1/admin/contest/printing/lane`,
            params: searchParam,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        });

        if (response.status === 200) {
            const resData = await response.data.datas;
            const modifiedData = Object.keys(resData).map((groupKey) => {
              // map 함수를 사용하여 배열을 변환하고 반환
              return resData[groupKey].map((item, index) => {
                const laneLabel = String(item.lane === null ? "" : item.lane) + "-" + String(item.seqOfLane === 0 ? "" : item.seqOfLane);
            
                return {
                  memberName: item.memberName,
                  lane: String(laneLabel)+".",
                  handicap: item.handicap,
                };
              });
            });
            setLaneData((prev) => modifiedData);

            if (modifiedData.length > 0) {
              // CSV 데이터 생성
              let csvContent = "\uFEFF"; // BOM 문자로 UTF-8 인코딩 설정 (한글 깨짐 방지)
            
              // 그룹 헤더 추가
              modifiedData.forEach((groupData, index) => {
                if(index !== 0) {
                  csvContent += `\n`;
                }

                // 그룹명 가져오기
                const groupKey = `${index + 1}조`; // 0부터 시작하는 인덱스에 1을 더하여 그룹명으로 사용
            
                // 헤더 추가
                csvContent += laneHeaders.map((header) => header.label).join(",") + "\n";
                csvContent += `"${groupKey}"\n`;
            
                // 데이터 추가
                groupData.forEach((item) => {
                  csvContent += `"${item.lane}","${item.memberName}","${item.handicap}"\n`;
                });
              });
            
              // Blob 생성
              const blob = new Blob([csvContent], {
                type: "text/csv;charset=utf-8;",
              });
            
              // Blob을 URL로 변환하여 다운로드 링크 생성
              const url = URL.createObjectURL(blob);
              const a = document.createElement("a");
              a.style.display = "none";
              a.href = url;
              a.download = `레인배정표${printTypes === "name" ? "(이름순)" : "(레인순)"}.csv`;
              document.body.appendChild(a);
              a.click();
              window.URL.revokeObjectURL(url);
            }
        }
    } catch (error) {
        console.error("Error:", error);
    }
  };
  /// 출석확인표 엑셀

  return (
    <section>
      <div className={classes.admin_contents}>
        <h3>대회진행 인쇄물</h3>
        <div className={`${classes.contents_wrap} ${classes.search_wrap}`}>
          <div className={classes.admin_table_wrap}>
            <table>
              <colgroup>
                <col style={{ width: "11%" }} />
                <col style={{ width: "89%" }} />
              </colgroup>
                <tbody>
                  <tr>
                      <th>대회조회</th>
                      <td>
                          <div className={classes.flex_row}>
                              <SelectBoxNew
                                  className="admin_st"
                                  setSelectItemCB={contestTypeHandler}
                                  optionDatas={contestTypeData}
                                  showDefaultKey={contestType}
                                  mouseOverCB={contestTypeMouseOverHandler}
                              />
                              <SelectBoxNew
                                  className="admin_st"
                                  optionDatas={contestNameList}
                                  setSelectItemCB={handleContestIdCB}
                                  showDefaultKey={contestId}
                              />
                          </div>
                      </td>
                  </tr>
                  <tr>
                      <th>경기장조회</th>
                      <td>
                          <div className={classes.flex_row}>
                              <SelectBoxNew
                                  className="admin_st"
                                  optionDatas={regionData}
                                  setSelectItemCB={regionDataHandler}
                                  showDefaultKey={regionName}
                                  mouseOverCB={regionNameMouseOverHandler}
                              />
                              <SelectBoxNew
                                  className="admin_st"
                                  optionDatas={stadiumName}
                                  setSelectItemCB={handleCenterIdCB}
                                  showDefaultKey={centerId}
                              />
                          </div>
                      </td>
                  </tr>
              </tbody>
            </table>
            <div className={classes.bottom_btn_wrap}>
              <Button className="border sm" onClick={search}>
                  검색
              </Button>
              <Button className="secondary sm" onClick={viewAll}>
                  전체보기
              </Button>
            </div>
          </div>
        </div>
        <div className={classes.contents_wrap}>
          <div
            className={`${classes.admin_table_wrap} ${classes.align_center} `}
          >
            <p className={classes.counter}>
              총 <b>{totalCount}</b>건
            </p>
            <table className={classes.left_col_2}>
              <colgroup>
                <col style={{ width: "7%" }} />
                <col style={{ width: "20%" }} />
                <col style={{ width: "12%" }} />
                <col style={{ width: "12%" }} />
                <col style={{ width: "10%" }} />
                <col style={{ width: "13%" }} />
                <col style={{ width: "13%" }} />
                <col style={{ width: "13%" }} />
              </colgroup>
              <thead>
                <tr>
                  <th>No</th>
                  <th>대회명</th>
                  <th>지역</th>
                  <th>경기장</th>
                  <th>진행상태</th>
                  <th>출석확인표</th>
                  <th>
                    레인배정표
                    <br />
                    (이름순)
                  </th>
                  <th>
                    레인배정표
                    <br />
                    (레인순)
                  </th>
                </tr>
              </thead>
              <tbody>
                {print.length !== 0 && print.map((data, index) => (
                  <tr>
                    <td>{data.seq}</td>
                    <td>{data.contest_name}</td>
                    <td>{data.region}</td>
                    <td>{data.center_name}</td>
                    <td>{data.process_status}</td>
                    <td>
                      <div className={`${classes.flex_row} ${classes.center}`}>
                        <Button className="xxxs primary" onClick={(e) => {downloadAttendModalHandler(e, data);}}>
                          다운
                        </Button>
                        <Button
                          className="xxxs border"
                          onClick={(e) => openAttendModalHandler(e, data)}
                        >
                          인쇄
                        </Button>
                      </div>
                    </td>
                    <td>
                      <div className={`${classes.flex_row} ${classes.center}`}>
                        <Button className="xxxs primary " onClick={(e) => {downloadLaneModalHandler(e, data, "name");}}>다운</Button>
                        <Button className="xxxs border" onClick={(e) => openLaneModalHandler(e, data, "name")}>인쇄</Button>
                      </div>
                    </td>
                    <td>
                      <div className={`${classes.flex_row} ${classes.center}`}>
                        <Button className="xxxs primary " onClick={(e) => {downloadLaneModalHandler(e, data, "lane");}}>다운</Button>
                        <Button className="xxxs border" onClick={(e) => openLaneModalHandler(e, data, "lane")}>인쇄</Button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <PaginationNew
                className="admin_st"
                totalPage={totalPage}
                currentPage={currentPage}
                defaultShowPage={5}
                changePageCB={handlePageChange}
            />
          </div>
        </div>
      </div>
      {isLaneModalOpen && <MngTournamentDetailPrintLaneModal isLaneModalOpenCB={setIsLaneModalOpen} contestCB={modalContest} printTypeCB={printType}/>}
      {isAttendModalOpen && <MngTournamentDetailPrintAttendModal isAttendModalOpenCB={setIsAttendModalOpen} contestCB={modalContest}/>}
    </section>
  );
}
export default MngTournamentDetailPrint;

export const loader = async () => {
  const response = await instance({
      method: "GET",
      url: `/api/v1/admin/contest/printing?page=0`,
      headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
      },
  }).catch(function (error) {
      if (error.response) {
          // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          return error.response;
      } else if (error.request) {
          // 요청이 이루어 졌으나 응답을 받지 못했습니다.
          console.log(error.request);
          return null;
      } else {
          // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
          console.log("Error", error.message);
          return null;
      }
  });
  if (response == null || response.status !== 200) {
      return [];
  }
  if (response.data.status === 200) {
      const resData = await response.data.datas;
      return {
        data: resData.content,
        totalPage: resData.totalPages,
        totalCount: resData.totalElements,
    };
  } else {
    return { data: [], totalPage: 0, totalCount: 0 };
  }
};