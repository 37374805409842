// @ts-check
import React, { useRef, useState } from "react";
import Tree, { TreeNode } from "rc-tree";
import PropTypes from "prop-types";
import _ from "lodash";
import "./assets/index.css";
const CustomTree = ({
    data = [], // 초기 데이터. Object구조는 key, title, children, disabled, depth가 기본적으로 들어감. title은 중복되어도 되나 key는 고유한 값, children은 하위 트리 배열
    keyName = "key", //카테고리에서 주는 JSON형식 변경을 위해
    titleName = "title", //카테고리에서 주는 JSON형식 변경을 위해
    checkable = true, // 체크 가능하게 할지 여부
    defaultExpanedKeys = [], // 최초에 펼쳐져 있을 node의 key값들
    expandAction = undefined, // 아무값도 넣지 않을시 스위쳐아이콘으로만 expand가능, 'click'과 'doubleClick'옵션이 있음
    selectable = true, // 선택 가능하게 할지말지여부. 선택시 css의 .rc-tree-node-selected의 css가 적용됨
    multiple = false, // 선택 가능하게 할지말지여부. 선택시 css의 .rc-tree-node-selected의 css가 적용됨
    onSelect = (/** @type {Array} */ sKey, { node }) => {
        console.log(sKey, node);
    }, // 선택시 이벤트. 선택된 키값과 선택한 node의 정보를 가져옴.
    onExpand = (/** @type {Array} */ eKey, { node }) => {
        console.log(eKey, node);
    }, // 펼칠시 이벤트. 펼쳐진 키값과 선택한 node의 정보를 가져옴.
    draggable = false,
    onDragStart,
    onDragOver,
    onDragEnter,
    onDragEnd,
    onDragLeave,
    onDrop, // draggable과 드래그시 이벤트들
    onClick = () => {},
    selectedDataKey = "",
    ...props
}) => {
    const [expandedKeys, setExpandedKeys] = useState(defaultExpanedKeys); // expand 관리를 위한 state
    const treeRef = useRef(null);

    const convertDataToTree = (nodeData) => {
        return nodeData.map((item) => (
            <TreeNode
                key={item[keyName]}
                title={item[titleName]}
                disabled={!!item?.disabled}
                className={`${
                    _.isString(item?.depth) && item?.depth.startsWith("depth") ? item.depth : `depth${item?.depth}`
                } rc-tree-node-${item[keyName]}`}
                expanded={defaultExpanedKeys.includes(item[keyName].toString())}
            >
                {_.isArray(item?.children) && item.children.length > 0 && convertDataToTree(item.children)}
            </TreeNode>
        ));
    };

    const ElIcon = ({ isClosed, disabled = false, hasChildren = true }) => {
        //커스텀 아이콘
        if (!hasChildren) return null;
        const opacity = disabled ? 0.3 : 1;
        if (isClosed)
            return (
                <svg
                    data-name="Component 13 – 7"
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                >
                    <rect width="15" height="15" rx="4" style={{ fill: "#3e21d9", opacity }} />
                    <path
                        data-name="Path 3325"
                        d="M-14599.5 599.735v8"
                        transform="translate(14607 -596.235)"
                        style={{ fill: "none", stroke: "#fff", strokeLinecap: "round", strokeWidth: "1.5px" }}
                    />
                    <path
                        data-name="Path 3326"
                        d="M-14599.5 599.735v8"
                        transform="rotate(90 -6997.883 7609.118)"
                        style={{ fill: "none", stroke: "#fff", strokeLinecap: "round", strokeWidth: "1.5px" }}
                    />
                </svg>
            );
        else
            return (
                <svg
                    data-name="Component 14 – 5"
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                >
                    <rect width="15" height="15" rx="4" style={{ fill: "#dedede", opacity }} />
                    <path
                        data-name="Path 3326"
                        d="M-14599.5 599.735v8"
                        transform="rotate(90 -6997.883 7609.118)"
                        style={{ fill: "none", stroke: "#fff", strokeLinecap: "round", strokeWidth: "1.tpx" }}
                    />
                </svg>
            );
    };
    //
    return (
        <Tree
            onExpand={(eKeys, info) => {
                // console.log(info.node);
                if (info.node.props.disabled) return;
                setExpandedKeys(eKeys);
                onExpand(eKeys, info);
            }}
            ref={treeRef}
            direction="rtl"
            expandedKeys={expandedKeys}
            onClick={onClick}
            checkable={checkable}
            expandAction={expandAction}
            showLine
            switcherIcon={(e) => <ElIcon isClosed={!e.expanded} disabled={e.disabled} hasChildren={!e.isLeaf} />}
            multiple={multiple}
            selectable={selectable}
            selectedKeys={[selectedDataKey.toString()]}
            onSelect={onSelect}
            draggable={draggable}
            onDragStart={onDragStart}
            onDragOver={onDragOver}
            onDragEnd={onDragEnd}
            onDragEnter={onDragEnter}
            onDragLeave={onDragLeave}
            onDrop={onDrop}
        >
            {convertDataToTree(data)}
            {props.children}
        </Tree>
    );
};

CustomTree.prototypes = {
    data: PropTypes.arrayOf(PropTypes.object),
    checkable: PropTypes.bool,
    defaultExpanedKeys: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    expandAction: PropTypes.oneOf([undefined, "click", "doubleClick"]),
    selectable: PropTypes.bool,
    multiple: PropTypes.bool,
    keyName: PropTypes.string,
    titleName: PropTypes.string,
    onSelect: PropTypes.func,
    onExpand: PropTypes.func,
    onDragStart: PropTypes.func,
    onDragOver: PropTypes.func,
    onDragEnd: PropTypes.func,
    onDragEnter: PropTypes.func,
    onDragLeave: PropTypes.func,
    onDrop: PropTypes.func,
};

export default CustomTree;
