import classes from "./WaitingService.module.css";
function WaitingService({ className }) {
    return (
        <main className={classes.waiting_service_wrap}>
            <div className={classes.loading_box}>
                <div
                    className={`${classes.loading} ${classes[className]}`}
                ></div>
                <h2>서비스 접속 대기중입니다.</h2>
                <p>이용에 불편을 드려 죄송합니다.</p>
            </div>
            <ul className={classes.info}>
                <li>
                    현재 접속자가 많아 대기중이며 잠시만 기다리시면
                    자동접속됩니다.
                </li>
                <li>
                    재접속하시거나 새로고침하실 경우 대기 시간이 더 길어질 수
                    있습니다.
                </li>
            </ul>
        </main>
    );
}
export default WaitingService;
