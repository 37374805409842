import { useLoaderData, useLocation, useNavigate } from "react-router-dom";
import Button from "../../../../components/UI/Button/Button";
import DateRangePicker from "../../../../components/UI/DatePicker/DateRangePicker";
import Input from "../../../../components/UI/Input/Input";
import Radio from "../../../../components/UI/Input/Radio";
import classes from "../../MngCommon.module.css";
import {
    ReqTopLineDetail,
    ReqTopLineUpdate,
} from "../../../../api/site/ApiForTopLine";
import DateTimePicker from "../../../../components/UI/DatePicker/DateTimePicker";
import { useState } from "react";
import Moment from "moment";
import { sub } from "date-fns";

function MngSiteTopLineModify(props) {
    const topLine = useLoaderData();

    const location = useLocation();
    const navigate = useNavigate();

    const backHandler = () => {
        navigate("/admin/site/main", { state: location.state });
    };

    const [title, setTitle] = useState(topLine.title);
    const [subTitle, setSubTitle] = useState(topLine.sub_title);
    const [startDate, setStartDate] = useState(
        Moment(topLine.expose_s_date, "YYYY-MM-DD HH:mm").toDate()
    );
    const [endDate, setEndDate] = useState(
        Moment(topLine.expose_e_date, "YYYY-MM-DD HH:mm").toDate()
    );
    const [exposeYnStatus, setExposeYnStatus] = useState(
        topLine.expose_yn ? "Y" : "N"
    );
    const [newTap, setNewTap] = useState(topLine.new_tap ? "Y" : "N");
    const [url, setUrl] = useState(topLine.url);

    const changeTitleHandler = (value) => {
        setTitle(value);
    };

    const changeSubTitleHandler = (value) => {
        setSubTitle(value);
    };

    const startDateHandler = (date) => {
        setStartDate(date);
    };

    const endDateHandler = (date) => {
        setEndDate(date);
    };

    const exposeYnRadioButton = (e) => {
        setExposeYnStatus(e.target.value);
    };

    const newTapRadioButton = (e) => {
        setNewTap(e.target.value);
    };

    const changeUrlHandler = (value) => {
        setUrl(value);
    };

    const updateHandler = async (e) => {
        e.preventDefault();

        if (endDate < startDate) {
            alert("종료일보다 시작일이 클 수 없습니다.");
            return;
        }

        if (!title) {
            alert("배너타이틀을 입력해주세요.");
            return;
        }

        if (!subTitle) {
            alert("서브타이틀을 입력해주세요.");
            return;
        }

        if (!url) {
            alert("URL을 입력해주세요.");
            return;
        }

        const isTrue = window.confirm("메인 띠배너를 수정하시겠습니까?");
        if (isTrue) {
            try {
                const jsonData = {
                    expose_yn: exposeYnStatus === "Y" ? true : false,
                    title: title,
                    sub_title: subTitle,
                    url: url,
                    expose_s_date: Moment(startDate).format("YYYYMMDDHHmm"),
                    expose_e_date: Moment(endDate).format("YYYYMMDDHHmm"),
                    new_tap: newTap === "Y" ? true : false,
                };

                const response = await ReqTopLineUpdate(topLine.id, jsonData);
                if (response.status === 200) {
                    alert("메인 띠배너가 수정되었습니다.");
                    backHandler();
                } else if (response.status === 409) {
                    alert("* 메인 띠배너는 1개만 노출 가능합니다.");
                } else {
                    alert("메인 띠배너 수정 실패");
                }
            } catch (error) {
                console.error("Error:", error);
            }
        } else {
            return;
        }
    };

    return (
        <section>
            <div className={classes.admin_contents}>
                <h3>메인 띠배너 관리 - 수정</h3>
                <div
                    className={`${classes.contents_wrap} ${classes.search_wrap} `}
                >
                    <div className={classes.admin_table_wrap}>
                        <table>
                            <colgroup>
                                <col style={{ width: "10%" }} />
                                <col style={{ width: "10%" }} />
                                <col style={{ width: "30%" }} />
                                <col style={{ width: "10%" }} />
                                <col style={{ width: "40%" }} />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>등록일</th>
                                    <td colSpan={2}>
                                        {Moment(topLine.reg_date).format(
                                            "YYYY-MM-DD"
                                        )}
                                    </td>
                                    <th>등록자</th>
                                    <td>{topLine.reg_id}</td>
                                </tr>
                                <tr>
                                    <th>노출여부</th>
                                    <td colSpan={4}>
                                        <div className={classes.radio_wrap}>
                                            <Radio
                                                value="Y"
                                                title="노출"
                                                name="rad_01"
                                                id="rad_01_01"
                                                checked={exposeYnStatus === "Y"}
                                                onChange={exposeYnRadioButton}
                                            />
                                            <Radio
                                                value="N"
                                                title="미노출"
                                                name="rad_01"
                                                id="rad_01_02"
                                                checked={exposeYnStatus === "N"}
                                                onChange={exposeYnRadioButton}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>노출기간</th>
                                    <td colSpan={4}>
                                        <div className={classes.date_pick_row}>
                                            <DateTimePicker
                                                id="start_date"
                                                onChange={startDateHandler}
                                                value={startDate}
                                            />
                                            <em>~</em>
                                            <DateTimePicker
                                                id="end_date"
                                                onChange={endDateHandler}
                                                value={endDate}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>배너타이틀</th>
                                    <td colSpan={4}>
                                        <Input
                                            className="admin_st"
                                            onChange={changeTitleHandler}
                                            value={title}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>서브타이틀</th>
                                    <td colSpan={4}>
                                        <Input
                                            className="admin_st"
                                            onChange={changeSubTitleHandler}
                                            value={subTitle}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th rowSpan={2}>링크</th>
                                    <th>타겟</th>
                                    <td colSpan={3}>
                                        <div className={classes.radio_wrap}>
                                            <Radio
                                                value="N"
                                                title="자창"
                                                name="rad_02"
                                                id="rad_02_01"
                                                checked={newTap === "N"}
                                                onChange={newTapRadioButton}
                                            />
                                            <Radio
                                                value="Y"
                                                title="새창"
                                                name="rad_02"
                                                id="rad_02_02"
                                                checked={newTap === "Y"}
                                                onChange={newTapRadioButton}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>URL</th>
                                    <td colSpan={3}>
                                        <Input
                                            className="admin_st"
                                            onChange={changeUrlHandler}
                                            value={url}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className={classes.bottom_btn_wrap}>
                            <Button
                                className="secondary sm"
                                onClick={updateHandler}
                            >
                                수정
                            </Button>
                            <Button className="border sm" onClick={backHandler}>
                                목록
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default MngSiteTopLineModify;

export const loader = async ({ params }) => {
    const response = await ReqTopLineDetail(params.topLineID);

    if (response.status === 200) {
        const resData = await response.data.data;
        return resData;
    } else {
        return [];
    }
};
