import classes from "../../MngCommon.module.css";
import { useLoaderData, useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Button from "../../../../components/UI/Button/Button";
import Input, { MultiInput } from "../../../../components/UI/Input/Input";
import Radio from "../../../../components/UI/Input/Radio";
import Checkbox from "../../../../components/UI/Input/Checkbox";
import SelectBoxNew from "../../../../components/UI/SelectBox/SelectBoxNew";
import Modal from "../../../../components/UI/Modal/Modal";
import instance from "../../../../store/AxiosInterceptor";
import {
    DefaultMemberStatusSelectBox,
    defaultEmailHostList,
} from "../../../../components/definedefault/DefSelectBoxValues";
import Alert from "../../../../components/UI/Modal/Alert";
import DaumPostcode from "react-daum-postcode";
import {
    ReqActivateDormantMember,
    ReqUpdateMemberInfo,
} from "../../../../api/member/MemberInfoApi";

/**
 * BO - 회원관리 - 회원목록(회원관리 페이지)
 * isEdit === false : 해당 회원 정보 상세 보기 페이지
 * isEdit === true : 해당 회원 정보 수정 페이지
 */
function MngUserDetail() {
    const resData = useLoaderData();
    const params = useParams();
    const location = useLocation();
    const memberID = params.memberID;
    const memberState = params.memberState;

    const [smsYn, setSmsYn] = useState(
        resData?.accept_sms && resData?.accept_sms === "Y"
    );
    const [emailYn, setEmailYn] = useState(
        resData?.accept_mail && resData?.accept_mail === "Y"
    );
    const [emailHost, setEmailHost] = useState(
        resData?.email && resData?.email.split("@")[1]
    );
    const emailHostList = defaultEmailHostList;
    const stateList = DefaultMemberStatusSelectBox;
    const [state, setState] = useState("N");
    const [modifyAlert, setModifyAlert] = useState(false);
    const [modifyValid, setModifyValid] = useState(false);
    const [modalText, setModalText] = useState("");

    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: 81 }, (_, index) => ({
        key: String(currentYear - index),
        value: String(currentYear - index),
        disabled: false,
    }));

    const months = Array.from({ length: 12 }, (_, index) => ({
        key: (index + 1).toString().padStart(2, "0"),
        value: (index + 1).toString().padStart(2, "0"),
        disabled: false,
    }));

    const days = Array.from({ length: 31 }, (_, index) => ({
        key: (index + 1).toString().padStart(2, "0"),
        value: (index + 1).toString().padStart(2, "0"),
        disabled: false,
    }));

    const [selectedYear, setSelectYear] = useState(
        resData?.birth && resData?.birth.split("-")[0]
    );
    const [selectedMonth, setSelectedMonth] = useState(
        resData?.birth && resData?.birth.split("-")[1]
    );
    const [selectedDate, setSelectedDate] = useState(
        resData?.birth && resData?.birth.split("-")[2]
    );

    const selectedYearHandler = (key) => {
        setSelectYear(key);
    };
    const selectedMonthHandler = (key) => {
        setSelectedMonth(key);
    };
    const selectedDateHandler = (key) => {
        setSelectedDate(key);
    };

    const modalHandler = (state, text) => {
        setModifyValid(state);
        setModalText(text);
    };

    const stateHandler = (key) => {
        setState(key);
    };

    const emailHostHandler = (key) => {
        setEmailHost(key);
    };
    const smsYnHandler = () => {
        setSmsYn((prev) => !prev);
    };
    const emailYnHandler = () => {
        setEmailYn((prev) => !prev);
    };

    const [input, setInput] = useState({
        name: resData?.name,
        memberId: resData?.memberId,
        birth: resData?.birth,
        cellFirst: resData?.cell ? resData?.cell.split("-")[0] : "",
        cellMid: resData?.cell ? resData?.cell.split("-")[1] : "",
        cellLast: resData?.cell ? resData?.cell.split("-")[2] : "",
        phone: resData?.phone,
        last_login: resData?.last_login,
        email: resData?.email,
        emailId: resData?.email ? resData?.email.split("@")[0] : "",
        password: "",
        passwordCheck: "",
        modifyReason: "",
    });

    const {
        name,
        memberId,
        birth,
        cellFirst,
        cellMid,
        cellLast,
        phone,
        last_login,
        email,
        emailId,
        password,
        passwordCheck,
        modifyReason,
    } = input;

    const onChangeInput = (e) => {
        const { name, value } = e.target;
        setInput({
            ...input,
            [name]: value,
        });
    };
    const passwordRegex =
        /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{6,20}$/;
    const passwordRegexCheck = passwordRegex.test(password);

    const [gender, setGender] = useState(resData?.gender);
    const genderHandler = (e) => {
        setGender(e.target.value);
    };

    const [isEdit, setIsEdit] = useState(false);
    const editModeHandler = () => {
        setIsEdit(true);
    };

    const navigate = useNavigate();
    const navigateHandler = () => {
        navigate("/admin/users/list", { state: location.state }); // 받은 페이징 정보 그대로 토스
    };
    useEffect(() => {
        const backHandler = navigateHandler;
        window.addEventListener('popstate', backHandler);
        return () => { window.removeEventListener('popstate', backHandler); };
    }, []);
    // [START] 다음 주소 API =================================================================
    const [isDaumPostcodeOpen, setIsDaumPostcodeOpen] = useState(false);
    const [zipCode, setZipCode] = useState(resData?.zip); //우편번호
    const [selectedAddress, setSelectedAddress] = useState(resData?.address); // 선택한 주소를 저장할 상태
    const [enteredAddress, setEnteredAddress] = useState(resData?.address_sub); // 입력한 주소를 저장할 상태

    const openDaumPostcodeHandler = (e) => {
        e.stopPropagation();
        setIsDaumPostcodeOpen(true);
    };

    const handleAddressSelect = (data) => {
        const fullAddress = data.address; // 전체 주소
        const extraAddress = data.addressType === "R" ? "" : data.bname; // 도로명일 경우 법정동 추가
        const address = `${fullAddress} ${extraAddress}`; // 최종 주소
        setZipCode(data.zonecode);
        setSelectedAddress(address); // 주소를 선택하면 상태 업데이트
        setIsDaumPostcodeOpen(false); // 주소를 선택하면 모달 창 닫기
    };

    const closeDaumPostcodeHandler = () => {
        setIsDaumPostcodeOpen(false);
    };

    //우편번호 검색을 했는데, 나머지 주소를 입력하지 않은경우
    //주소입력을 아에 안하고 가입 가능 but, 하나라도 입력하면 나머지 주소를 입력해야한다.
    // [END] 다음 주소 API =================================================================

    //서버전송값
    const memberInfoObject = {
        member_id: memberID,
        cell: `${cellFirst}-${cellMid}-${cellLast}`,
        email: emailId + "@" + emailHost,
        accept_sms: smsYn ? "Y" : "N",
        accept_mail: emailYn ? "Y" : "N",
        address: selectedAddress,
        address_sub: enteredAddress,
        zip_code: zipCode,
        password: password,
        memo: modifyReason,
        name: name,
        gender: gender,
        birth: `${selectedYear}-${selectedMonth}-${selectedDate}`,
        status: state,
    };

    const reqUpdateMemberInfoHandler = () => {
        if (password?.trim() !== "" && !passwordRegexCheck) {
            modalHandler(true, "비밀번호 양식이 맞지 않습니다.");
            return;
        }

        if (password?.trim() !== passwordCheck?.trim()) {
            modalHandler(true, "입력하신 비밀번호가 서로 다릅니다.");
            return;
        }

        if (modifyReason.trim() === "") {
            modalHandler(true, "필수항목을 입력해주세요.");
            return;
        }

        ReqUpdateMemberInfo(
            memberInfoObject,
            (result) => {
                if (result === 200) {
                    setModifyAlert(true);
                    return;
                }
            },
            (error_result) => {
                alert("회원 정보 수정 실패");
                return;
            }
        );
    };

    const [dormantActiveModal, setDormantActiveModal] = useState(false);
    const [dormantSuccessModal, setDormantSuccessModal] = useState(false);
    const dormantMemberActiveHandler = () => {
        ReqActivateDormantMember(
            memberID,
            (result) => {
                // console.log(result);
                if (result.status_error === 1005) {
                    alert("휴면회원이 아닙니다.");
                    return;
                } else if (result.status_error === 200) {
                    setDormantSuccessModal(true);
                    return;
                } else {
                    alert("휴면해제 실패");
                }
            },
            (error_result) => {
                alert("서버 에러");
            }
        );
    };

    return (
        <>
            {!isEdit && (
                <section>
                    <div className={classes.admin_contents}>
                        <h3>회원목록 – 상세</h3>
                        <div
                            className={`${classes.contents_wrap} ${classes.search_wrap} `}
                        >
                            <div className={classes.admin_table_wrap}>
                                <table>
                                    <colgroup>
                                        <col style={{ width: "159px" }} />
                                        <col style={{ width: "*" }} />
                                        <col style={{ width: "159px" }} />
                                        <col style={{ width: "*" }} />
                                    </colgroup>
                                    <tbody>
                                        <tr>
                                            <th>이름</th>
                                            <td>{name}</td>
                                            <th>생년월일</th>
                                            <td>{birth}</td>
                                        </tr>
                                        <tr>
                                            <th>성별</th>
                                            {gender !== undefined &&
                                                gender === "M" && <td>남</td>}
                                            {gender !== undefined &&
                                                gender === "F" && <td>여</td>}
                                            {!gender && <td></td>}
                                            <th>아이디</th>
                                            <td>{memberID}</td>
                                        </tr>
                                        <tr>
                                            <th>주소</th>
                                            <td>
                                                {resData?.address}{" "}
                                                {resData?.address_sub}
                                            </td>
                                            <th>휴대폰</th>
                                            <td>
                                                {resData?.cell
                                                    ? resData.cell
                                                    : resData?.phone}{" "}
                                                {resData?.accept_sms &&
                                                    resData?.accept_sms ===
                                                        "Y" && (
                                                        <span className="primary_c">
                                                            (마케팅 정보 수신
                                                            동의)
                                                        </span>
                                                    )}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>이메일</th>
                                            <td className="primary_c underline">
                                                {email}
                                            </td>
                                            <th>회원구분</th>
                                            {resData &&
                                                resData.auth_type == null && (
                                                    <td></td>
                                                )}
                                            {resData &&
                                                resData?.auth_type === "0" && (
                                                    <td>미인증 회원</td>
                                                )}
                                            {resData &&
                                                resData?.auth_type === "1" && (
                                                    <td>인증 회원</td>
                                                )}
                                            {resData &&
                                                resData?.auth_type === "2" && (
                                                    <td>서면 회원</td>
                                                )}
                                            {!resData && <td>탈퇴</td>}
                                        </tr>
                                        <tr>
                                            <th>상태</th>
                                            {resData && memberState === "N" && (
                                                <td>활동</td>
                                            )}
                                            {resData && memberState === "D" && (
                                                <td>휴면</td>
                                            )}
                                            {!resData && <td>탈퇴</td>}
                                            <th>등록 사유</th>
                                            <td>{resData?.memo}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className={classes.bottom_btn_wrap}>
                                    {resData && memberState === "N" && (
                                        <>
                                            <Button
                                                className="border sm"
                                                onClick={editModeHandler}
                                            >
                                                수정
                                            </Button>
                                            <Button
                                                className="secondary sm"
                                                onClick={navigateHandler}
                                            >
                                                목록
                                            </Button>
                                        </>
                                    )}
                                    {resData && memberState === "D" && (
                                        <>
                                            <Button
                                                className="sm"
                                                onClick={() =>
                                                    setDormantActiveModal(true)
                                                }
                                            >
                                                휴면해제
                                            </Button>
                                            <Button
                                                className="secondary sm"
                                                onClick={navigateHandler}
                                            >
                                                목록
                                            </Button>
                                        </>
                                    )}
                                    {!resData && (
                                        <Button
                                            className="secondary sm"
                                            onClick={navigateHandler}
                                        >
                                            목록
                                        </Button>
                                    )}
                                </div>
                                {dormantActiveModal && (
                                    <Alert
                                        type="confirm"
                                        cancel="취소"
                                        confirm="확인"
                                        close={() =>
                                            setDormantActiveModal(false)
                                        }
                                        confirmHandler={
                                            dormantMemberActiveHandler
                                        }
                                    >
                                        {"휴면해제 하시겠습니까?"}
                                    </Alert>
                                )}
                                {dormantSuccessModal && (
                                    <Alert
                                        type="alert"
                                        confirm="확인"
                                        close={navigateHandler}
                                    >
                                        {"휴면해제 되었습니다."}
                                    </Alert>
                                )}
                            </div>
                        </div>
                        <div className={`${classes.contents_wrap} `}>
                            <div
                                className={`${classes.admin_table_wrap} ${classes.align_center}`}
                            >
                                <h5>회원정보 변경 이력</h5>
                                <table>
                                    <colgroup>
                                        <col style={{ width: "10%" }} />
                                        <col style={{ width: "15%" }} />
                                        <col style={{ width: "15%" }} />
                                        <col style={{ width: "15%" }} />
                                        <col style={{ width: "15%" }} />
                                        <col style={{ width: "15%" }} />
                                        <col style={{ width: "15%" }} />
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th>No</th>
                                            <th>변경항목</th>
                                            <th>변경전</th>
                                            <th>변경후</th>
                                            <th>변경사유</th>
                                            <th>변경일</th>
                                            <th>변경자</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>
            )}
            {isEdit && (
                <section>
                    <div className={classes.admin_contents}>
                        <div
                            className={`${classes.contents_wrap} ${classes.search_wrap} `}
                        >
                            <div className={classes.admin_table_wrap}>
                                <h3>회원목록 – 수정</h3>
                                <main>
                                    <table>
                                        <colgroup>
                                            <col style={{ width: "139px" }} />
                                            <col style={{ width: "*" }} />
                                        </colgroup>
                                        <tbody>
                                            <tr>
                                                <th>이름</th>
                                                <td>
                                                    <MultiInput
                                                        className="admin_st_sm"
                                                        name="name"
                                                        value={name}
                                                        onChange={onChangeInput}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>생년월일</th>
                                                <td>
                                                    <div
                                                        className={`${classes.flex_row} ${classes.multi}`}
                                                    >
                                                        <SelectBoxNew
                                                            className="admin_st w_sm"
                                                            optionDatas={years}
                                                            showDefaultKey={
                                                                selectedYear
                                                            }
                                                            setSelectItemCB={
                                                                selectedYearHandler
                                                            }
                                                            name="registerBirthYear"
                                                        />
                                                        <span>년</span>
                                                        <SelectBoxNew
                                                            className="admin_st w_sm"
                                                            optionDatas={months}
                                                            showDefaultKey={
                                                                selectedMonth
                                                            }
                                                            setSelectItemCB={
                                                                selectedMonthHandler
                                                            }
                                                            name="registerBirthMonth"
                                                        />
                                                        <span>월</span>
                                                        <SelectBoxNew
                                                            className="admin_st w_sm"
                                                            optionDatas={days}
                                                            showDefaultKey={
                                                                selectedDate
                                                            }
                                                            setSelectItemCB={
                                                                selectedDateHandler
                                                            }
                                                            name="registerBirthDate"
                                                        />
                                                        <span>일</span>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>성별</th>
                                                <td>
                                                    <div
                                                        className={
                                                            classes.radio_wrap
                                                        }
                                                    >
                                                        <Radio
                                                            value="M"
                                                            title="남"
                                                            name="rad_02"
                                                            id="rad_02_01"
                                                            onChange={
                                                                genderHandler
                                                            }
                                                            checked={
                                                                gender === "M"
                                                            }
                                                        />
                                                        <Radio
                                                            value="F"
                                                            title="여"
                                                            name="rad_02"
                                                            id="rad_02_02"
                                                            onChange={
                                                                genderHandler
                                                            }
                                                            checked={
                                                                gender === "F"
                                                            }
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>아이디</th>
                                                <td>{memberId}</td>
                                            </tr>
                                            <tr>
                                                <th>비밀번호</th>
                                                <td>
                                                    <div
                                                        className={
                                                            classes.flex_row
                                                        }
                                                    >
                                                        <MultiInput
                                                            className="admin_st_sm"
                                                            name="password"
                                                            value={password}
                                                            onChange={
                                                                onChangeInput
                                                            }
                                                            type="password"
                                                        />
                                                        <p
                                                            className={
                                                                classes.desc_gray
                                                            }
                                                        >
                                                            영
                                                            대소문자.숫자,특수문자를
                                                            포함하여 6~20자
                                                            이내로 입력하세요.
                                                            (패스워드 변경을
                                                            원치 않으시면
                                                            빈칸으로 남겨
                                                            주세요.)
                                                        </p>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>비밀번호 확인</th>
                                                <td>
                                                    <div
                                                        className={
                                                            classes.flex_row
                                                        }
                                                    >
                                                        <MultiInput
                                                            className="admin_st_sm"
                                                            name="passwordCheck"
                                                            value={
                                                                passwordCheck
                                                            }
                                                            onChange={
                                                                onChangeInput
                                                            }
                                                            type="password"
                                                        />
                                                        <p
                                                            className={
                                                                classes.desc_gray
                                                            }
                                                        >
                                                            비밀번호를 다시 한번
                                                            입력해 주세요
                                                        </p>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>주소</th>
                                                <td>
                                                    <div
                                                        className={
                                                            classes.flex_row
                                                        }
                                                    >
                                                        <Input
                                                            className="admin_st_sm"
                                                            value={zipCode}
                                                            onChange={
                                                                setZipCode
                                                            }
                                                        />
                                                        <Button
                                                            className="border xxxs"
                                                            onClick={
                                                                openDaumPostcodeHandler
                                                            }
                                                        >
                                                            우편번호 검색
                                                        </Button>
                                                    </div>
                                                    <div
                                                        className={`${classes.flex_row} ${classes.multi}`}
                                                    >
                                                        <Input
                                                            className="admin_st w_50"
                                                            value={
                                                                selectedAddress
                                                            }
                                                            onChange={
                                                                setSelectedAddress
                                                            }
                                                        />
                                                        <Input
                                                            className="admin_st w_50"
                                                            value={
                                                                enteredAddress
                                                            }
                                                            onChange={
                                                                setEnteredAddress
                                                            }
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>휴대폰</th>
                                                <td>
                                                    <div
                                                        className={`${classes.flex_row} ${classes.multi}`}
                                                    >
                                                        <MultiInput
                                                            className="admin_st sm"
                                                            name="cellFirst"
                                                            value={cellFirst}
                                                            onChange={
                                                                onChangeInput
                                                            }
                                                        />
                                                        <span>-</span>
                                                        <MultiInput
                                                            className="admin_st sm"
                                                            name="cellMid"
                                                            value={cellMid}
                                                            onChange={
                                                                onChangeInput
                                                            }
                                                        />
                                                        <span>-</span>
                                                        <MultiInput
                                                            className="admin_st sm"
                                                            name="cellLast"
                                                            value={cellLast}
                                                            onChange={
                                                                onChangeInput
                                                            }
                                                        />
                                                        <Checkbox
                                                            className="admin_label"
                                                            id="chk_01"
                                                            title="SMS 수신설정"
                                                            onChange={
                                                                smsYnHandler
                                                            }
                                                            checked={
                                                                smsYn === true
                                                            }
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>이메일</th>
                                                <td>
                                                    <div
                                                        className={`${classes.flex_row} ${classes.multi}`}
                                                    >
                                                        <MultiInput
                                                            className="admin_st_sm"
                                                            name="emailId"
                                                            value={emailId}
                                                            onChange={
                                                                onChangeInput
                                                            }
                                                        />
                                                        <span>@</span>
                                                        <Input
                                                            className="admin_st_sm"
                                                            name="emailHost"
                                                            value={emailHost}
                                                            onChange={
                                                                emailHostHandler
                                                            }
                                                        />
                                                        <SelectBoxNew
                                                            className="admin_st "
                                                            optionDatas={
                                                                emailHostList
                                                            }
                                                            setSelectItemCB={
                                                                emailHostHandler
                                                            }
                                                            showDefaultKey={
                                                                emailHost
                                                            }
                                                        />
                                                        <Checkbox
                                                            className="admin_label"
                                                            id="chk_02"
                                                            title="뉴스레터 및 안내메일 수신 설정"
                                                            onChange={
                                                                emailYnHandler
                                                            }
                                                            checked={
                                                                emailYn === true
                                                            }
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>상태</th>
                                                <td>
                                                    <SelectBoxNew
                                                        className="admin_st"
                                                        optionDatas={stateList}
                                                        setSelectItemCB={
                                                            stateHandler
                                                        }
                                                        showDefaultKey={state}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>
                                                    변경 사유
                                                    <span className="require_red">
                                                        *
                                                    </span>
                                                </th>
                                                <td>
                                                    <MultiInput
                                                        className="admin_st"
                                                        value={modifyReason}
                                                        name="modifyReason"
                                                        onChange={onChangeInput}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div className={classes.bottom_btn_wrap}>
                                        <Button
                                            className="secondary sm"
                                            onClick={reqUpdateMemberInfoHandler}
                                        >
                                            수정
                                        </Button>
                                        <Button
                                            className="secondary sm"
                                            onClick={navigateHandler}
                                        >
                                            목록
                                        </Button>
                                    </div>
                                </main>
                                {isDaumPostcodeOpen && (
                                    <Modal
                                        className="admin_st"
                                        close={closeDaumPostcodeHandler}
                                        header="우편번호 검색"
                                    >
                                        <DaumPostcode
                                            onComplete={handleAddressSelect}
                                        />
                                    </Modal>
                                )}
                                {modifyValid && (
                                    <Alert
                                        type="alert"
                                        confirm="확인"
                                        close={() => modalHandler(false, "")}
                                    >
                                        {modalText}
                                    </Alert>
                                )}
                                {modifyAlert && (
                                    <Alert
                                        type="alert"
                                        confirm="확인"
                                        close={navigateHandler}
                                    >
                                        {"수정되었습니다."}
                                    </Alert>
                                )}
                            </div>
                        </div>
                    </div>
                </section>
            )}
        </>
    );
}

export default MngUserDetail;

//회원 정보 변경 신청 리스트 가져오기
export const loader = async ({ params }) => {
    const response = await instance({
        method: "GET",
        url: `/api/v1/admin/member/list/${params.memberID}/${params.memberState}`,
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    }).catch(function (error) {
        if (error.response) {
            // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            return error.response;
        } else if (error.request) {
            // 요청이 이루어 졌으나 응답을 받지 못했습니다.
            console.log(error.request);
            return null;
        } else {
            // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
            console.log("Error", error.message);
            return null;
        }
    });
    if (response == null || response.status !== 200) {
        return [];
    }
    if (response.data.status === 200) {
        return response.data.data;
    } else {
        return [];
    }
};
//회원 정보 변경 신청 리스트 가져오기
