/* eslint-disable react/prop-types */
import React, { useState } from "react";
import classes from "./../Products.module.css";
import _ from "lodash";
import Checkbox from "../../../components/UI/Input/Checkbox";
import Button from "../../../components/UI/Button/Button";
import { getHomeProduct } from "../../../api/home/ProductCategoryApi";

export const ProductNavigation = ({
    datas = {},
    isDetail = false,
    selectedItem,
    subSelectedItem,
    handleItemClick = () => {},
    subHandleItemClick = () => {},
    smartFilterDatas = {},
    searchParams, // searchParams를 프롭으로 전달
    searchKeyword,
    setSearchResults, // 부모 컴포넌트에서 검색 결과 데이터를 설정할 콜백 함수
    setSelectedValues, // 부모단에서 받을 선택된 스마트필터 값
    resetPage, // resetPage 함수를 프롭으로 받음
}) => {
    // console.log("나와라 스필: ", smartFilterDatas);

    // console.log("검색 값 : ", searchKeyword);

    const [filterToggle, setFilterToggle] = useState("");
    const filterToggleHandler = (id) => {
        if (id === filterToggle) {
            setFilterToggle("");
        } else {
            setFilterToggle(id);
        }
    };

    const [selectedCheckboxes, setSelectedCheckboxes] = useState({});

    const handleSearchClick = () => {
        const selectedValues = Object.keys(selectedCheckboxes).filter(
            (checkboxId) => selectedCheckboxes[checkboxId]
        );

        setSelectedValues(selectedValues);

        resetPage(); // 페이지 초기화

        getHomeProduct(searchParams, searchKeyword, selectedValues)
            .then(({ data, status }) => {
                if (status === 200 && _.isObject(data.data)) {
                    const pData = data.data;

                    console.log(
                        "ProductNavigation getHomeProduct :::",
                        searchParams,
                        searchKeyword,
                        selectedValues,
                        pData
                    );
                    setSearchResults(pData);
                } else {
                    console.error("검색에 실패했습니다.");
                    setSearchResults([]); // 검색에 실패한 경우 결과를 초기화합니다.
                }
            })
            .catch((error) => {
                console.error("API 요청 중 오류가 발생했습니다:", error);
                setSearchResults([]); // API 요청 오류 시도 결과를 초기화합니다.
            });
    };

    // 초기화 버튼 클릭
    const handleResetClick = () => {
        // 모든 선택된 체크박스를 해제하는 로직 추가
        const updatedCheckboxes = {};

        // 모든 선택된 체크박스의 ID를 반복하고 value 값을 false로 설정하여 해제
        Object.keys(selectedCheckboxes).forEach((checkboxId) => {
            updatedCheckboxes[checkboxId] = false;
        });

        // setSelectedCheckboxes를 사용하여 선택된 체크박스를 갱신
        setSelectedCheckboxes(updatedCheckboxes);

        // 초기화 후 검색 결과를 리셋
        // setSearchResults([]);
    };

    return (
        <>
            <nav>
                <ul
                    className={
                        (datas?.children ?? []).length > 0
                            ? classes.depth_1
                            : "last"
                    }
                >
                    {(datas?.children ?? []).map((parentItem, parentIndex) => (
                        <li
                            key={parentIndex}
                            className={
                                Number(selectedItem) === Number(parentItem.id)
                                    ? classes.on
                                    : ""
                            }
                        >
                            <span
                                onClick={() => handleItemClick(parentItem.id)}
                                className={
                                    _.isArray(parentItem?.children) &&
                                    parentItem.children.length > 0
                                        ? classes.hasNext
                                        : classes.last
                                }
                            >
                                {parentItem.name}
                            </span>
                            <ul
                                className={
                                    (datas?.children ?? []).length > 0
                                        ? classes.depth_2
                                        : "last"
                                }
                            >
                                {parentItem.children.map(
                                    (childItem, childIndex) => (
                                        <li
                                            key={childIndex}
                                            className={
                                                Number(subSelectedItem) ===
                                                Number(childItem.id)
                                                    ? classes.on
                                                    : ""
                                            }
                                        >
                                            <span
                                                className={classes.last}
                                                onClick={() =>
                                                    subHandleItemClick(
                                                        childItem.id
                                                    )
                                                }
                                            >
                                                {childItem.name}
                                                {/* {childItem.value && (
                                                        <i>{childItem.value}</i>
                                                    )} */}
                                            </span>
                                        </li>
                                    )
                                )}
                            </ul>
                        </li>
                    ))}
                </ul>
            </nav>

            {/* 스마트필터 데이터가 있을때만 보여주도록 하자 */}
            {smartFilterDatas.length > 0 && (
                <>
                    <div className={classes.smart_filter_wrap}>
                        <h4>Smart Filter</h4>
                        <ul className={classes.depth_1}>
                            {smartFilterDatas.map((filter, index) => (
                                <li
                                    key={index}
                                    id={`filter_${index + 1}`}
                                    className={
                                        filterToggle === `filter_${index + 1}`
                                            ? classes.on
                                            : ""
                                    }
                                    onClick={() =>
                                        filterToggleHandler(
                                            `filter_${index + 1}`
                                        )
                                    }
                                >
                                    <span>{filter.title}</span>
                                    <div className={classes.depth_2_wrap}>
                                        {filter.smartFilterItems.map(
                                            (item, itemIndex) => (
                                                <div
                                                    className={classes.depth_2}
                                                    key={itemIndex}
                                                >
                                                    <p>
                                                        <Checkbox
                                                            className="official_st"
                                                            id={`chk_${
                                                                index + 1
                                                            }_${itemIndex + 1}`}
                                                            value={item.id}
                                                            // title={item.name}
                                                            title={
                                                                item.name +
                                                                " (" +
                                                                item.itemCount +
                                                                ")"
                                                            }
                                                            checked={
                                                                selectedCheckboxes[
                                                                    item.id
                                                                ] || false
                                                            } // 체크박스의 초기 상태 설정
                                                            onChange={(
                                                                event
                                                            ) => {
                                                                const checked =
                                                                    event.target
                                                                        .checked;
                                                                if (
                                                                    item.itemCount >
                                                                    0
                                                                ) {
                                                                    console.log(
                                                                        "체크여부 : ",
                                                                        checked,
                                                                        "item.id : ",
                                                                        item.id
                                                                    );
                                                                    setSelectedCheckboxes(
                                                                        (
                                                                            prevCheckboxes
                                                                        ) => ({
                                                                            ...prevCheckboxes,
                                                                            [item.id]:
                                                                                checked,
                                                                        })
                                                                    );
                                                                }
                                                            }}
                                                            disabled={
                                                                item.itemCount ===
                                                                0
                                                            } // itemCount가 0이면 체크박스 비활성화
                                                        />
                                                    </p>
                                                    {itemIndex + 1 <
                                                        filter.smartFilterItems
                                                            .length &&
                                                        item.tab !==
                                                            filter
                                                                .smartFilterItems[
                                                                itemIndex + 1
                                                            ].tab && <hr />}
                                                </div>
                                            )
                                        )}
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className={classes.btn_wrap}>
                        <Button onClick={handleResetClick}>초기화</Button>
                        <Button
                            className="secondary"
                            onClick={handleSearchClick}
                        >
                            검색
                        </Button>
                    </div>
                </>
            )}
        </>
    );
};
