import { useRef, useState } from "react";
import Button from "../Button/Button";
import classes from "./FileBox.module.css";

const buttonStyle = {
  display: 'inline-block',
  padding: '0.375rem 0.75rem',
  lineHeight: 1.5,
  color: '#6c757d',
  backgroundColor: 'transparent',
  border: '1px solid #6c757d',
  borderRadius: '2rem',
  cursor: 'pointer',
};


function FileBox({ onFileInputChange = (file) => {}, onFileDelete = () => {}, showMessage = <></>, accept=null, isValid = (file) => true, ...props }) {
    const fileInputRef = useRef(null);
    const [fileName, setFileName] = useState("");
  
    const handleFileInputChange = (event) => {
      const file = event.target.files[0];
      if(!isValid(file)) return;
      setFileName(file.name);
      onFileInputChange(file);
    };
  
    const handleFileDelete = () => {
      setFileName(""); // 파일 이름 초기화
      onFileDelete(); // 파일 삭제 콜백 호출
    };
  
    const handleButtonClick = () => {
      fileInputRef.current.click();
    };
  
    return (
      <div className={classes.file_box}>
        <input
          accept={accept}
          type="file"
          ref={fileInputRef}
          onChange={handleFileInputChange}
          {...props}
        />
        <Button className="border sm" onClick={handleButtonClick}>
          파일 선택
        </Button>
        <input
          className={classes.file_name}
          value={fileName || "선택된 파일 없음"}
          readOnly
        />
        {showMessage}
        {fileName && (
          <Button className="secondary sm" onClick={handleFileDelete}>
            파일 삭제
          </Button>
        )}
      </div>
    );
  }
/**
 *
 * @param {*} imageSrc
 * @param {*} onInputChangeCB 파일 선택시 첫번째 파일을 응답값으로 전달 (선택한 이미지 정보, 로딩한 이미지 URL(화면에 표시를 위함))
 * @param {*} onInputDeleteCB 파일삭제에 대한 사항 전달
 * @returns
 */
export function FileBoxForImage(imageSrc, onInputChangeCB, onInputDeleteCB, showMSG = <></>) {
    const inputChangeHandler = async function (event) {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.readAsDataURL(file);
        await new Promise((resolve) => (reader.onload = () => resolve()));
        const dataUrl = reader.result;

        onInputChangeCB(file, dataUrl);
    };

    return (
        <div className={classes.img_file_box}>
            {!imageSrc && (
                <>
                    <input
                        type="file"
                        id="imgFile"
                        accept="image/*"
                        onChange={inputChangeHandler}
                        hidden
                    />
                    <label 
                    style={buttonStyle}
                    className="button secondary sm" htmlFor="imgFile">파일선택</label>
                </>
            )}
            {imageSrc && (
                <>
                    <img key={imageSrc} src={imageSrc} alt="preview-img" />
                    <Button className="secondary sm" onClick={onInputDeleteCB}>
                        {" "}
                        파일삭제
                    </Button>
                    {showMSG}
                </>
            )}
        </div>
    );
}

// 엑셀 파일을 취급하는 FileBoxForExcel 함수
export function FileBoxForExcel({ onInputChangeCB, onInputDeleteCB }) {
    const fileInputRef = useRef(null);
    const [fileName, setFileName] = useState("");

    const handleFileInputChange = (event) => {
        const file = event.target.files[0];
        setFileName(file.name);
        onInputChangeCB(file); // 엑셀 파일에 대한 콜백 함수 호출
    };

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    return (
        <div className={classes.file_box}>
            <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileInputChange}
                accept=".csv" //csv만 가능하도록 변경(배한성)
            />
            <Button className="primary upload sm" onClick={handleButtonClick}>
                엑셀파일 업로드
            </Button>
            <input
                className={classes.file_name}
                value={fileName}
                placeholder="선택된 파일 없음"
                readOnly
            />
        </div>
    );
}

export default FileBox;
