import classes from "./IntroduceApp.module.css";
function IntroHistoryAppPage(props) {
    return (
        <>
            <div className={classes.contents_wrap}>
                <div className={classes.history_box_wrap}>
                    <div className={classes.intro_txt}>
                        당사는 1995년 5월 설립되었으며, 1996년 3월부터 오늘날에
                        이르기까지 미국의 볼링 볼 제조 업체인 스톰(STORM
                        PRODUCT.INC)의 독점 판매점으로 한국 볼링 시장에 우수한
                        기술력으로 생산된 뛰어난 품질의 스톰(STORM)과 로또
                        그립(ROTO GRIP)의 볼을 소개해 왔으며, 꾸준한 성장으로
                        한국 볼링 시장의 선두 업체 중 하나로 거듭나게
                        되었습니다.
                        <br />
                        국위선양과 볼링의 저변 확대를 위해 국가 대표팀과 프로 및
                        아마추어 볼러들에게 스톰과 로또 그립 볼과 장비, 가방
                        등을 스폰하고 있으며, 정기적인 대회 주최 등의 프로모션을
                        통해 제품의 우수성을 알리는 것은 물론 더 많은 볼러
                        분들과 만날 수 있도록 노력하고 있습니다.
                    </div>
                    <div className={classes.history_box}>
                        <div className={classes.box}>
                            <span className={classes.year}>2024</span>
                            <div className={classes.list_box}>
                                <div className={classes.history}>
                                    <span className={classes.month}>~</span>
                                    <p>
                                        본사 사옥 신축이전 계획 (경기도 용인시
                                        동백동)
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className={classes.box}>
                            <span className={classes.year}>2022</span>
                            <div className={classes.list_box}>
                                <div className={classes.history}>
                                    <span className={classes.month}>11</span>
                                    <p>SBS 비즈 트렌드 스페셜 방영</p>
                                </div>
                            </div>
                        </div>
                        <div className={classes.box}>
                            <span className={classes.year}>2020</span>
                            <div className={classes.list_box}>
                                <div className={classes.history}>
                                    <span className={classes.month}>12</span>
                                    <p>
                                        어린이재단, 사랑마루 정기후원 금액
                                        일억원 돌파
                                        <br />
                                        가방 제조 수출 삼천만불달성
                                    </p>
                                </div>
                                <div className={classes.history}>
                                    <span className={classes.month}>06</span>
                                    <p>볼링화 디자인등록 인증</p>
                                </div>
                            </div>
                        </div>
                        <div className={classes.box}>
                            <span className={classes.year}>2019</span>
                            <div className={classes.list_box}>
                                <div className={classes.history}>
                                    <span className={classes.month}>12</span>
                                    <p>
                                        2019년 KPBA 준메이저, 메이저 3개대회
                                        공식후원
                                        <br />
                                        ㈜진승 상호 및 CI 변경
                                    </p>
                                </div>
                                <div className={classes.history}>
                                    <span className={classes.month}>11</span>
                                    <p>디자인 연구전담부서 설립</p>
                                </div>
                                <div className={classes.history}>
                                    <span className={classes.month}>05</span>
                                    <p>
                                        자회사 ㈜제이티에스 QUBICA AMF 한국內
                                        대리점권 계약
                                    </p>
                                </div>
                                <div className={classes.history}>
                                    <span className={classes.month}>04</span>
                                    <p>자회사 ㈜제이티에스 설립</p>
                                </div>
                            </div>
                        </div>
                        <div className={classes.box}>
                            <span className={classes.year}>2018</span>
                            <div className={classes.list_box}>
                                <div className={classes.history}>
                                    <span className={classes.month}>03</span>
                                    <p>모범납세자 지방국세청장 표창수상</p>
                                </div>
                            </div>
                        </div>
                        <div className={classes.box}>
                            <span className={classes.year}>2017</span>
                            <div className={classes.list_box}>
                                <div className={classes.history}>
                                    <span className={classes.month}>12</span>
                                    <p>사랑마루 사회적협동조합 공식후원</p>
                                </div>
                            </div>
                        </div>
                        <div className={classes.box}>
                            <span className={classes.year}>2016</span>
                            <div className={classes.list_box}>
                                <div className={classes.history}>
                                    <span className={classes.month}>09</span>
                                    <p>
                                        STORM 볼링화 제조/판매 계약
                                        <br />
                                        KEGEL LLC 레인정비기기 한국內 대리점권
                                        계약
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className={classes.box}>
                            <span className={classes.year}>2015</span>
                            <div className={classes.list_box}>
                                <div className={classes.history}>
                                    <span className={classes.month}>11</span>
                                    <p>초록우산 어린이재단 공식후원</p>
                                </div>
                                <div className={classes.history}>
                                    <span className={classes.month}>07</span>
                                    <p>
                                        본사 사옥 이전 (경기도 광주시 신현동)
                                        <br />
                                        썬더레인즈볼링센터 오픈
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className={classes.box}>
                            <span className={classes.year}>2009</span>
                            <div className={classes.list_box}>
                                <div className={classes.history}>
                                    <span className={classes.month}>06</span>
                                    <p>
                                        본사 사옥 신축 이전 (경기도 광주시
                                        오포읍 능평리)
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className={classes.box}>
                            <span className={classes.year}>2005</span>
                            <div className={classes.list_box}>
                                <div className={classes.history}>
                                    <span className={classes.month}>03</span>
                                    <p>
                                        ROTO GRIP.INC 한국內 독점 대리점권 계약
                                        <br />
                                        ROTO GRIP BALL 수입판매
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className={classes.box}>
                            <span className={classes.year}>1999</span>
                            <div className={classes.list_box}>
                                <div className={classes.history}>
                                    <span className={classes.month}>12</span>
                                    <p>가방 제조 수출 백만불 달성</p>
                                </div>
                                <div className={classes.history}>
                                    <span className={classes.month}>02</span>
                                    <p>제조업 등록</p>
                                </div>
                            </div>
                        </div>
                        <div className={classes.box}>
                            <span className={classes.year}>1996</span>
                            <div className={classes.list_box}>
                                <div className={classes.history}>
                                    <span className={classes.month}>03</span>
                                    <p>
                                        STORM PRODUCTS.INC 한국內 독점 대리점권
                                        계약
                                        <br />
                                        STORM BALL 수입판매
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className={classes.box}>
                            <span className={classes.year}>1995</span>
                            <div className={classes.list_box}>
                                <div className={classes.history}>
                                    <span className={classes.month}>05</span>
                                    <p>
                                        다이노테인(DYNO-THANE) 볼링볼 수입판매
                                        <br />
                                        대표이사 전진표
                                        <br />
                                        ㈜진승무역 설립
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default IntroHistoryAppPage;
