import classes from "./MyPage.module.css";
import Button from "../../components/UI/Button/Button";
import { MultiInput } from "../../components/UI/Input/Input";
import { useEffect, useState } from "react";
import Radio from "../../components/UI/Input/Radio";
import { useNavigate } from "react-router-dom";
import { mypageCsDefaultBox, phoneNumberDefaultBox } from "../../components/definedefault/DefSelectBoxValues";
import { ReqAddBoardHelp, ReqMemberInfo } from "../../api/mypage/UserMyPageApi";
import SelectBoxNew from "../../components/UI/SelectBox/SelectBoxNew";
import Alert from "../../components/UI/Modal/Alert";
import { JwtTokenContext } from "../../store/JwtTokenProvider";
import { useContext } from "react";
import { useMemStore } from "../../store/memstore/MemStore";
import QuillEditorForText from "../../components/UI/Editer/QuillEditorForText";
import DropZoneForAS from "../../components/UI/DropZone/DropZoneForAS";

const MyContactHelp = () => {
    const setMemStore = useMemStore(false)[1];
    const [helpSupportType, setHelpSupportType] = useState("Member");
    const [showCancelModal, setShowCancelModal] = useState(false);
    const loginCtx = useContext(JwtTokenContext);
    const token = loginCtx.getToken();
    const navigate = useNavigate();

    const supportTypeHandler = (e) => {
        setHelpSupportType(e.target.value);
    };

    const topHandler = () => {
        window.scrollTo({ top: 0, behavior: "smooth" }); //상단 smooth 이동
    };

    const [input, setInput] = useState({
        title: "",
    });

    const { title } = input;

    const onChangeInput = (e) => {
        const { name, value } = e.target;
        setInput({
            ...input,
            [name]: value,
        });
    };

    const [content, setContent] = useState("");
    const contentHandler = (value) => {
        setContent(value);
    };

    const numberRegex = /^\d+$/;
    const [memberPhone, setMemberPhone] = useState("");
    const myInfoHandler = () => {
        ReqMemberInfo(
            (result) => {
                setMemberPhone(result.cell);
            },
            (error_result) => {
                console.log(error_result);
                setMemberPhone("");
            }
        );
    };

    const asHandler = () => {
        navigate("/myPage/contact/as");
    };

    //1:1문의 or A/S신청
    const csTypes = mypageCsDefaultBox;
    const [changeMode, setChangeMode] = useState(false);
    const [csType, setCsType] = useState("cs");

    const changeModeHandler = (state) => {
        setChangeMode(state);
        setCsType("cs");
    };

    const csTypeHandler = (value) => {
        setCsType(value);
        if (value === "as") {
            changeModeHandler(true);
        }
    };

    const moveHandler = () => {
        asHandler();
    };

    const boardObject = {
        process: "Request",
        support_type: helpSupportType,
        phone: memberPhone.replace(/-/g, ""),
        title: title,
        content: content,
    };

    ///>>> AS신청

    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [validCheck, setValidCheck] = useState(false);

    // 드랍드랍
    const [images, setImages] = useState([]);
    const [ImagesDropDown, setImagesDropDown] = useState([]);

    const onHandleImageUpload = (acceptedFiles) => {
        setImages([...acceptedFiles]);
    };

    const onHandleImageUploadL = (acceptedFiles) => {
        setImagesDropDown(acceptedFiles);
    };

    const navigateHandler = (link) => {
        navigate(link);
        setShowSuccessModal(false);
    };

    const postHandler = () => {
        setValidCheck(true);
        topHandler();

        if (title.trim() === "") {
            return;
        }

        if (content.trim() === "") {
            return;
        }

        if (ImagesDropDown.length > 5) {
            alert("첨부 파일의 최대 갯수는 5개 입니다.");
            return;
        }

        const jsonData = JSON.stringify(boardObject);

        const formData = new FormData();

        ImagesDropDown.forEach((image, index) => {
            formData.append("image", image);
        });

        formData.append("body", new Blob([jsonData], { type: "application/json" }));

        ReqAddBoardHelp(
            formData,
            (result) => {
                if (result.status === 200) {
                    setShowSuccessModal(true);
                }
            },
            (error_result) => {
                alert("A/S신청 등록에 실패하셨습니다.");
            }
        );
    };
    ///<<< AS신청

    useEffect(() => {
        if (token == null) {
            setMemStore("LOGIN_MOVE_PAGE", { uri: "/mypage/contact/help" });
            navigate("/login");
        }
        myInfoHandler();
    }, []);

    return (
        <div className={classes.contents_wrap}>
            <div className={classes.contents_title}>
                <h3>1:1 문의</h3>
                <p className={classes.desc}>이용 중 불편하신 사항이나 문의하실 내용을 알려주세요.</p>
            </div>
            <div className={classes.detail_table_wrap}>
                <table>
                    <colgroup>
                        <col style={{ width: "15%" }} />
                        <col style={{ width: "85%" }} />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>문의유형</th>
                            <td>
                                <SelectBoxNew
                                    className="default"
                                    optionDatas={csTypes}
                                    setSelectItemCB={csTypeHandler}
                                    showDefaultKey={csType}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>문의항목</th>
                            <td>
                                <div className={classes.radio_wrap}>
                                    <Radio
                                        className="official_st"
                                        name="rad_01"
                                        id="rad_01_1"
                                        value="Member"
                                        title="회원정보 문의"
                                        checked={helpSupportType === "Member"}
                                        onChange={supportTypeHandler}
                                    />
                                    <Radio
                                        className="official_st"
                                        name="rad_01"
                                        id="rad_01_2"
                                        value="Contest"
                                        title="대회관련"
                                        checked={helpSupportType === "Contest"}
                                        onChange={supportTypeHandler}
                                    />
                                    <Radio
                                        className="official_st"
                                        name="rad_01"
                                        id="rad_01_3"
                                        value="Product"
                                        title="상품관련"
                                        checked={helpSupportType === "Product"}
                                        onChange={supportTypeHandler}
                                    />
                                    <Radio
                                        className="official_st"
                                        name="rad_01"
                                        id="rad_01_4"
                                        value="Etc"
                                        title="기타문의"
                                        checked={helpSupportType === "Etc"}
                                        onChange={supportTypeHandler}
                                    />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>
                                휴대폰<span className="red_c">*</span>
                            </th>
                            <td>
                                <div className={classes.phone_wrap}>
                                    <span>{memberPhone?.replace(/(\d{2,3})(\d{3,4})(\d{4})/, "$1-$2-$3")}</span>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>
                                문의제목
                                <span className="red_c">*</span>
                            </th>
                            <td>
                                <MultiInput className="h45 w_100" onChange={onChangeInput} name="title" value={title} />
                                {validCheck && title.trim() === "" && (
                                    <p className="require_red f_14">제목 입력해주세요.</p>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <th>
                                문의내용<span className="red_c">*</span>
                            </th>
                            <td>
                                <div style={{ height: "200px" }}>
                                    <QuillEditorForText
                                        height="160"
                                        width="auto"
                                        onValueChangeCB={contentHandler}
                                        value={content}
                                    />
                                </div>
                                {validCheck && content.trim() === "" && (
                                    <p className="require_red f_14">문의 내용을 입력해주세요.</p>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <th>첨부 파일</th>
                            <td>
                                <DropZoneForAS
                                    images={images}
                                    onImageUpload={onHandleImageUpload}
                                    onImageUploadL={onHandleImageUploadL}
                                />
                                <div className={classes.desc_s}>
                                    * 이미지 파일 첨부 가능갯수는 최대 5개 입니다.
                                    <br />
                                    사진은 5MB 미만의 JPG, PNG, GIF 형식으로 업로드 가능합니다.
                                    <br />
                                    파일명에 ‘_’이외의 특수문자는 사용이 불가능합니다.
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                {changeMode && (
                    <Alert
                        type="confirm"
                        cancel="취소"
                        confirm="확인"
                        close={() => changeModeHandler(false)}
                        confirmHandler={moveHandler}
                    >
                        {"문의 유형을 변경 하시겠습니까?"}
                        {" 작성중인 내용은 삭제됩니다."}
                    </Alert>
                )}
                {showCancelModal && (
                    <Alert
                        type="confirm"
                        cancel="취소"
                        confirm="확인"
                        close={() => setShowCancelModal(false)}
                        confirmHandler={() => navigateHandler("/myPage/cs")}
                    >
                        {"문의를 취소하시겠습니까?"}
                    </Alert>
                )}
                {showConfirmModal && (
                    <Alert
                        type="confirm"
                        cancel="취소"
                        confirm="확인"
                        close={() => setShowConfirmModal(false)}
                        confirmHandler={postHandler}
                    >
                        {"문의를 등록하시겠습니까?"}
                    </Alert>
                )}
                {showSuccessModal && (
                    <Alert type="alert" confirm="확인" close={() => navigateHandler("/myPage/cs")}>
                        {"등록이 완료되었습니다."}
                    </Alert>
                )}
                <div className={classes.btn_bottom_wrap}>
                    <div className={classes.right}>
                        <Button onClick={() => setShowCancelModal(true)}>취소</Button>
                        <Button className="secondary" onClick={() => setShowConfirmModal(true)}>
                            등록
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MyContactHelp;
