import { useState } from "react";
import Button from "../../../../components/UI/Button/Button";
import classes from "../../MngCommon.module.css";
import {
    ReqTopLineDelete,
    ReqTopLineDetail,
} from "../../../../api/site/ApiForTopLine";
import { useLoaderData, useLocation, useNavigate } from "react-router-dom";
import Moment from "moment";

function MngSiteTopLineDetail(props) {
    const topLine = useLoaderData();

    const location = useLocation();
    const navigate = useNavigate();

    const backHandler = () => {
        navigate("/admin/site/main", {state: location.state});
    };

    const deleteHandler = async (e) => {
        e.preventDefault();

        const isTrue = window.confirm("메인 띠배너를 삭제하시겠습니까?");
        if (isTrue) {
            try {
                const response = await ReqTopLineDelete(topLine.id);
                if (response.status === 200) {
                    alert("메인 띠배너가 삭제되었습니다.");
                    backHandler();
                } else {
                    alert("메인 띠배너 삭제 실패");
                }
            } catch (error) {
                console.error("Error:", error);
            }
        } else {
            return;
        }
    };

    const updateHandler = () => {
        navigate(`../update/${topLine.id}`, { state: location.state });
    };

    return (
        <section>
            <div className={classes.admin_contents}>
                <h3>메인 띠배너 관리 - 상세</h3>
                <div
                    className={`${classes.contents_wrap} ${classes.search_wrap} `}
                >
                    <div className={classes.admin_table_wrap}>
                        <table>
                            <colgroup>
                                <col style={{ width: "10%" }} />
                                <col style={{ width: "10%" }} />
                                <col style={{ width: "30%" }} />
                                <col style={{ width: "10%" }} />
                                <col style={{ width: "40%" }} />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>등록일</th>
                                    <td colSpan={2}>
                                        {Moment(topLine.reg_date).format(
                                            "YYYY-MM-DD"
                                        )}
                                    </td>
                                    <th>등록자</th>
                                    <td>{topLine.reg_id}</td>
                                </tr>
                                <tr>
                                    <th>노출여부</th>
                                    <td colSpan={4}>
                                        {topLine.expose_yn ? "노출" : "미노출"}
                                    </td>
                                </tr>
                                <tr>
                                    <th>노출기간</th>
                                    <td colSpan={4}>
                                        {Moment(
                                            topLine.expose_s_date,
                                            "YYYYMMDDHHmm"
                                        ).format("YYYY-MM-DD HH:mm")}{" "}
                                        ~{" "}
                                        {Moment(
                                            topLine.expose_e_date,
                                            "YYYYMMDDHHmm"
                                        ).format("YYYY-MM-DD HH:mm")}
                                    </td>
                                </tr>
                                <tr>
                                    <th>배너타이틀</th>
                                    <td colSpan={4}>{topLine.title}</td>
                                </tr>
                                <tr>
                                    <th>서브타이틀</th>
                                    <td colSpan={4}>{topLine.sub_title}</td>
                                </tr>
                                <tr>
                                    <th rowSpan={2}>링크</th>
                                    <th>타겟</th>
                                    <td colSpan={3}>
                                        {topLine.new_tap ? "새창" : "자창"}
                                    </td>
                                </tr>
                                <tr>
                                    <th>URL</th>
                                    <td colSpan={3}>{topLine.url}</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className={classes.bottom_btn_wrap}>
                            <Button
                                className="secondary sm"
                                onClick={updateHandler}
                            >
                                수정
                            </Button>
                            <Button className="sm" onClick={deleteHandler}>
                                삭제
                            </Button>
                            <Button className="border sm" onClick={backHandler}>
                                목록
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default MngSiteTopLineDetail;

export const loader = async ({ params }) => {
    const response = await ReqTopLineDetail(params.topLineID);

    if (response.status === 200) {
        const resData = await response.data.data;
        return resData;
    } else {
        return [];
    }
};
