import { Outlet } from "react-router-dom";
import classes from "./Event.module.css";
import EventNavigation from "./EventNavigation";

function EventRootLayout() {
    return (
        <div className={`container ${classes.event_container}`}>
            <EventNavigation />
            <section>
                <div className={classes.event_contents}>
                    <Outlet />
                </div>
            </section>
        </div>
    );
}

export default EventRootLayout;
