import classes from "./MyPage.module.css";
import PaginationNew from "../../components/UI/Table/PaginationNew";
import { useEffect, useState } from "react";
import instance from "../../store/AxiosInterceptor";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { ReqBoardAsListPage } from "../../api/mypage/UserMyPageApi";
import { JwtTokenContext } from "../../store/JwtTokenProvider";
import { useContext } from "react";
import { useMemStore } from "../../store/memstore/MemStore";

function MyASHistory() {
    const setMemStore = useMemStore(false)[1];

    const location = useLocation();
    const navigate = useNavigate();

    const loginCtx = useContext(JwtTokenContext);
    const token = loginCtx.getToken();
    
    const [asHistoryList, setAsHistoryList] = useState([]);

    const [pageNumber, setPageNumber] = useState(location.state?.pageNumber ?? 0);
    const [totalPage, setTotalPage] = useState(1);

    const [tabIndex, setTabIndex] = useState(1);
    const pageHandler = (value) => {
        setPageNumber(value);
        ReqBoardAsListPage(
            value,
            (result) => {
                const curData = result.content.map((data) => {
                    return {
                        asId: data.id,
                        process: data.process,
                        asSupportTypeName: data.asSupportTypeName,
                        reqDate: data.reqDate,
                        seq: data.seq,
                        title: data.title,
                    };
                });
                setAsHistoryList(curData);
                setPageNumber(result.pageable.pageNumber);
                setTotalPage(result.totalPages);
            },
            (error_result) => {
                setAsHistoryList((prev) => prev);
                setPageNumber((prev) => prev);
                setTotalPage((prev) => prev);
            }
        );
    };


    const tabArr = [
        { id: 0, title: "1:1 문의내역", link: "/myPage/cs" },
        { id: 1, title: "A/S 신청내역", link: "/myPage/cs/as" },
    ];

    const navigateHandler = (link) => {
        navigate(link, { pageNumber });
    };

    const dataLoader = async () => {
        const response = await instance({
            method: "GET",
            url: `/api/v1/home/board/as?page=${pageNumber}&size=10`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        }).catch(function (error) {
            if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
                return error.response;
            } else if (error.request) {
                console.log(error.request);
                return null;
            } else {
                console.log("Error", error.message);
                return null;
            }
        });
        if (response == null || response.status !== 200) {
            return [];
        }
        if (response.data.status === 200) {
            return response.data.data;
        } else {
            return [];
        }
    };


    useEffect(() => {
        const fetchData = async () => {
                const response = await dataLoader();
                const curData = response.content.map((data) => {
                    return {
                        asId: data.id,
                        process: data.process,
                        asSupportTypeName: data.asSupportTypeName,
                        reqDate: data.reqDate,
                        seq: data.seq,
                        title: data.title,
                    };
                });
                setAsHistoryList(curData);
                setPageNumber(response.pageable.pageNumber);
                setTotalPage(response.totalPages);
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (token == null) {
            setMemStore("LOGIN_MOVE_PAGE", { uri: "/myPage/cs/as" });
            navigate("/login");
        }
    }, []);

    return (
        <div className={classes.contents_wrap}>
            <div className={classes.contents_title}>
                <h3>나의 A/S 신청 내역</h3>
            </div>
            <ul className={classes.tab_menu}>
                {tabArr.map((item) => (
                    <li
                        key={item.id}
                        className={tabIndex === item.id && classes.on}
                        onClick={() => navigateHandler(item.link)}
                    >
                        {item.title}
                    </li>
                ))}
            </ul>
            {asHistoryList.length > 0 && (
                <div>
                    <table className="align_center align_left_col3">
                        <colgroup>
                            <col style={{ width: "7%" }} />
                            <col style={{ width: "19%" }} />
                            <col style={{ width: "50%" }} />
                            <col style={{ width: "12%" }} />
                            <col style={{ width: "12%" }} />
                        </colgroup>
                        <thead>
                            <tr>
                                <th>번호</th>
                                <th>분류</th>
                                <th>문의제목</th>
                                <th>신청일자</th>
                                <th>상태</th>
                            </tr>
                        </thead>
                        <tbody>
                            {asHistoryList.map((data, index) => (
                                <tr key={index}>
                                    <td>{data.seq}</td>
                                    <td>{data.asSupportTypeName}</td>
                                    <td>
                                        <Link to={`${data.asId}`}>{data.title}</Link>
                                    </td>
                                    <td>{data.reqDate}</td>
                                    {data.process === "Request" && <td className="gray_c">처리 중</td>}
                                    {data.process === "Close" && <td>처리 완료</td>}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <PaginationNew
                        totalPage={totalPage}
                        currentPage={pageNumber}
                        defaultShowPage={5}
                        changePageCB={pageHandler}
                    />
                </div>
            )}
            {asHistoryList.length === 0 && (
                <div className={`${classes.empty_data} ${classes.as_st}`}>
                    <p>신청 내역이 없습니다.</p>
                </div>
            )}
        </div>
    );
}

export default MyASHistory;

//>>> Loader
export const loader = async () => {
    const response = await instance({
        url: `/api/v1/home/board/as?page=0&size=10`,
        method: "GET",
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    }).catch(function (error) {
        if (error.response) {
            // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
            // console.log(error.response.data);
            // console.log(error.response.status);
            // console.log(error.response.headers);
            return error.response;
        } else if (error.request) {
            // 요청이 이루어 졌으나 응답을 받지 못했습니다.
            // console.log(error.request);
            return null;
        } else {
            // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
            // console.log("Error", error.message);
            return null;
        }
    });

    if (response == null || response.status !== 200) {
        return [];
    }
    if (response.data.status === 200) {
        return response.data.data;
    } else {
        return [];
    }
};
