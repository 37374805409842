import instance from "../../store/AxiosInterceptor";

export function ReqTopBanner(successCallback, errorCallback) {
    instance
        .get(`/api/v1/home/topBanner`)
        .then((response) => {
            if (response.data === "") {
                return;
            }
            const bannerData = {
                id: response.data.data.id,
                title: response.data.data.title,
                subTitle: response.data.data.sub_title,
                exposeYn: response.data.data.expose_yn,
                topBannerSDate: response.data.data.expose_s_date,
                topBannerEDate: response.data.data.expose_e_date,
                url: response.data.data.url,
                newTap: response.data.data.new_tap,
            };
            if (successCallback) {
                successCallback(bannerData);
            }
        })
        .catch((error) => {
            if (errorCallback) {
                errorCallback(error);
            }
        });
}
