import { NavLink } from "react-router-dom";
import classes from "./Event.module.css";

function EventNavigation(props) {
    return (
        <div className={classes.event_sub_nav}>
            <h3>이벤트</h3>
            <nav>
                <ul className={classes.depth_1}>
                    <li className={classes.depth_1_link}>
                        <NavLink to="ongoing">진행중인 이벤트</NavLink>
                    </li>
                    <li className={classes.depth_1_link}>
                        <NavLink to="winners">당첨자 발표</NavLink>
                    </li>
                </ul>
            </nav>
        </div>
    );
}

export default EventNavigation;
