import React, { useState } from "react";
import Alert from "../UI/Modal/Alert";
import { useNavigate } from "react-router-dom";

const OkInicis = () => {
    const navigate = useNavigate();
    const [showCancelModal, setShowCancelModal] = useState(true);

    const modalHandler = (state) => {
        setShowCancelModal(state);
        navigate("/myPage/tournamentHistory");
    };

    return (
        <>
            {showCancelModal && (
                <Alert type="alert" confirm="확인" close={() => modalHandler(false)}>
                    {"결제가 완료되었습니다."}
                </Alert>
            )}
        </>
    );
};

export default OkInicis;
