import BreadCrumb from "../../components/UI/BreadCrumb/BreadCrumb";
import classes from "./Introduce.module.css";
import office_img_1 from "../../assets/images/office_img_1.jpg";
import office_img_2 from "../../assets/images/office_img_2.jpg";
import office_img_3 from "../../assets/images/office_img_3.jpg";
import { useEffect } from "react";

function IntroLocationPage(props) {
    //  breadCrumb sample data
    const breadCrumbList = ["회사소개", "찾아오시는 길"];

    const { kakao } = window;

    useEffect(() => {
        if (kakao) {
            //지도를 담을 영역의 DOM 레퍼런스
            const container = document.getElementById("map");

            //지도를 생성할 때 필요한 기본 옵션
            const options = {
                center: new kakao.maps.LatLng(37.29432708770308, 126.99658273529194), //지도의 중심좌표.
                level: 2, //지도의 레벨(확대, 축소 정도) 낮을수록 확대
            };

            //지도 생성 및 객체 리턴
            const map = new kakao.maps.Map(container, options);

            // 주소-좌표 변환 객체를 생성합니다
            var geocoder = new kakao.maps.services.Geocoder();

            // 주소로 좌표를 검색합니다
            geocoder.addressSearch(`경기도 용인시 기흥구 석성로 521번길 41`, function (result, status) {
                // 정상적으로 검색이 완료됐으면
                if (status === kakao.maps.services.Status.OK) {
                    var coords = new kakao.maps.LatLng(result[0].y, result[0].x);

                    // 결과값으로 받은 위치를 마커로 표시합니다
                    var marker = new kakao.maps.Marker({
                        map: map,
                        position: coords,
                    });

                    // 지도의 중심을 결과값으로 받은 위치로 이동시킵니다
                    map.setCenter(coords);
                }
            });
        }
    }, []);

    return (
        <>
            <BreadCrumb breadCrumbList={breadCrumbList} />
            <div className={`${classes.contents_wrap} ${classes.location_contents_wrap}`}>
                <div className={classes.contents_title}>
                    <h3>찾아오시는 길</h3>
                </div>
                <div className={classes.location_info_wrap}>
                    <div className={classes.map_wrap} id="map"></div>

                    <div className={classes.contact}>
                        <ul>
                            <li className={classes.logo}>진승</li>
                            <li className={classes.address}>
                                <span>주소</span>
                                경기도 용인시 기흥구 석성로 521번길 41(동백동) (주)진승
                            </li>
                            <li className={classes.tel}>
                                <span>대표전화</span>031-282-6011
                            </li>
                            <li className={classes.fax}>
                                <span>팩스</span>031-282-6010
                            </li>
                        </ul>
                    </div>
                </div>
                <div className={classes.gallery_wrap}>
                    <div className={classes.inner}>
                        <div className={classes.img_box}>
                            <img src={office_img_1} alt="진승 회사 이미지" />
                        </div>
                        <div className={classes.img_box}>
                            <img src={office_img_2} alt="진승 회사 이미지" />
                        </div>
                        <div className={classes.img_box}>
                            <img src={office_img_3} alt="진승 회사 이미지" />
                        </div>
                    </div>
                </div>
                {/* <div className={classes.location_info}>
                    <div className={classes.box}>
                        <h4>자가 이용시</h4>
                        <ol>
                            <li>
                                강변북로 또는 올림픽대로 구리방향 청담대교에서 분당방향 판교 IC로 나오셔서 광주,
                                오포방향으로 좌회전 (57번 국도)
                            </li>
                            <li>
                                서현역 사거리, 시범단지, 중앙교회, 율동공원, 분당요한성당, 지역난방공사를 지나
                                태재고개를 넘습니다.
                            </li>
                            <li>태재고개 사거리에서 좌회전 후 약 400m정도 직진합니다.</li>
                            <li>왼쪽으로 현대모닝1차 아파트 버스정류장이 보이면 정류장 오른편 사잇길로 진입합니다.</li>
                            <li>현대모닝1차아파트와 성진전자통신 사이 골목길을 지나면 썬더레인즈 건물이 있습니다.</li>
                        </ol>
                    </div>
                    <div className={classes.box}>
                        <h4>대중교통 이용시</h4>
                        <ul>
                            <li>광역버스 : 1500-2, 1151, 1150 </li>
                            <li>시내버스 : 522, 521, 520, 17, 17-1, 119</li>
                            <li>논현역 또는 강남역에서 양재방향으로 1150번 광역버스</li>
                            <li>사당역, 남부버스터미널역에서 양재방향으로 1500-2번 광역버스</li>
                            <li>강남역, 신논현역에서 양재방향으로 1151번 광역버스</li>
                            <li>서현역 1번출구 또는 5번출구로 나와서 로데오거리를 지나 큰길로 직진</li>
                            <li>
                                서현역 2번출구 또는 3번출구(한신아파트방향)에서 17번 버스 현대모닝사이드1차 정류장 하차
                            </li>
                        </ul>
                    </div>
                </div> */}
            </div>
        </>
    );
}

export default IntroLocationPage;
