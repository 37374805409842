/**
 * 로그인 유지를 위해 JwtToken을 전역으로 관리하는 코드
 * [A] YiSuHwan
 */
import React, { useEffect } from "react";
import { useReducer } from "react";

export const JwtTokenContext = React.createContext({
    token: "",
    decodedToken: {}, // (object type)parsed Token
    refreshToken: "",
    userType: "", //U: User , C: Center, A: Admin
    name: "",
    getToken: () => {},
    addToken: () => {},
    removeToken: () => {},
    isAdmin: () => {},
    isAccessExpired: () => {},
    isRefreshExpired: () => {},
    isLogin: () => {},
});

const defaultState = {
    token: "",
    refreshToken: "",
    userType: "", //A : admin , U : user
    name: "",
    decodedToken: {},
};

const jwtTokenReducer = (state, action) => {
    if (action.type === "ADD") {
        // console.log("ADD JWT Token", state, action);
        localStorage.setItem("token", action.token.token);
        localStorage.setItem("refreshToken", action.token.refreshToken);
        return {
            token: action.token.token,
            refreshToken: action.token.refreshToken,
            userType: action.userType,
            decodedToken: action.decodedToken,
            name: action.name,
        };
    }
    if (action.type === "REMOVE") {
        // console.log("REMOVE JWT Token");
        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");
    }
    return defaultState;
};

const JwtTokenProvider = (props) => {
    const [tokenState, dispatchTokenAction] = useReducer(
        jwtTokenReducer,
        defaultState
    );

    const addTokenHandler = (newToken) => {
        const decodedToken = parseToken(newToken.token);
        dispatchTokenAction({
            type: "ADD",
            token: newToken,
            userType: decodedToken.role,
            decodedToken: decodedToken,
            name: decodedToken.mname,
        });
        return decodedToken;
    };

    const removeTokenHandler = () => {
        dispatchTokenAction({
            type: "REMOVE",
        });
    };

    const bindCheckIsAdmin = function () {
        if (this.token && this.refreshToken && this.userType) {
            if (this.userType === "A" || this.userType === "C") return true;
        }
        return false;
    }.bind(tokenState);

    const isAccessExpired = function (decodedToken) {
        if (decodedToken) {
            return !isValidTokenWithDecodedToken(decodedToken);
        } else {
            if (!this.token) {
                const savedToken = localStorage.getItem("token");
                const savedRToken = localStorage.getItem("refreshToken");
                if (savedToken) {
                    const decodedToken = parseToken(savedToken);
                    dispatchTokenAction({
                        type: "ADD",
                        token: {
                            token: savedToken,
                            refreshToken: savedRToken,
                        },
                        userType: decodedToken.role,
                        name: decodedToken.mname,
                        decodedToken: decodedToken,
                    });
                    return !isValidToken(this.token);
                }
                else {
                    return true;
                }
            } else {
                return !isValidTokenWithDecodedToken(this.decodedToken);
            }
        }
    }.bind(tokenState);

    const isRefreshExpired = function (checkToken) {
        if (checkToken) {
            return !isValidTokenWithDecodedToken(checkToken);
        } else {
            if (!this.refreshToken) {
                const savedRToken = localStorage.getItem("refreshToken");
                return !isValidToken(savedRToken);
            } else return !isValidTokenWithDecodedToken(this.refreshToken);
        }
    }.bind(tokenState);

    // 초기에 로딩을 해주기 위함
    const getTokenHandler = function () {
        if (!this.token || !this.refreshToken) {
            const loadedData = loadTokenData();
            return loadedData;
        }
        return {
            token: this.token,
            refreshToken: this.refreshToken,
        };
    }.bind(tokenState);

    const checkLogin = () => !!(tokenState && tokenState.token && tokenState.refreshToken && tokenState.userType);
    ;

    const jwtTokenContext = {
        ...tokenState,
        getToken: getTokenHandler,
        addToken: addTokenHandler,
        removeToken: removeTokenHandler,
        isAdmin: bindCheckIsAdmin,
        isAccessExpired,
        isRefreshExpired,
        isLogin: () => !!(tokenState && tokenState.token && tokenState.refreshToken && tokenState.userType),
    };

    const loadTokenData = function () {
        const savedToken = localStorage.getItem("token");
        if (savedToken) {
            const savedRToken = localStorage.getItem("refreshToken");
            const decodedToken = parseToken(savedToken);
            if (decodedToken) {
                // console.log("loadTokenData  : " , decodedToken.mname);
                dispatchTokenAction({
                    type: "ADD",
                    token: {
                        token: savedToken,
                        refreshToken: savedRToken,
                    },
                    userType: decodedToken.role,
                    decodedToken: decodedToken,
                    name: decodedToken.mname,
                });
                return {
                    token: savedToken,
                    refreshToken: savedRToken,
                    userType: decodedToken.role,
                    decodedToken: decodedToken,
                    name: decodedToken.mname,
                };
            } else {
                return null;
            }
        }
        return null;
    };

    useEffect(() => {
        loadTokenData();
    }, []);

    return (
        <JwtTokenContext.Provider value={jwtTokenContext}>
            {props.children}
        </JwtTokenContext.Provider>
    );
};

// 토큰 유효시간 확인. 토큰 유효 : true, 유효X : false
const isValidToken = (token) => {
    const decodedData = parseToken(token);
    return isValidTokenWithDecodedToken(decodedData);
};

// 토큰 유효시간 확인. 토큰 유효 : true, 유효X : false
const isValidTokenWithDecodedToken = (decodedToken) => {
    if (!decodedToken) {
        return true;
    }
    if (decodedToken.exp) {
        const expirationTime = decodedToken.exp * 1000; // 초 단위로 표현된 만료 시간을 밀리초 단위로 변환
        const now = new Date().getTime(); // 현재 시간을 밀리 세컨즈로 반환

        // console.log('isValidTokenWithDecodedToken', now, expirationTime, now < expirationTime);

        return now < expirationTime; // 현재 시간이 만료 시간보다 작은 경우 토큰은 유효하다.
    } else {
        return false; // 만료 시간이 지정되어 있지 않은 경우 false 반환, 이어지는 로직 없도록 하기
    }
};

// Role 추출
const extractRoleFromToken = (token) => {
    const decodedData = parseToken(token);
    if (decodedData) return decodedData.role;
    return null;
};

// 토큰 유효시간 확인. 토큰 유효 : true, 유효X : false
const parseToken = (token) => {
    if (token === null || !token || token === undefined) {
        return null;
    }
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const decodedData = JSON.parse(window.atob(base64));

    decodedData.mname = decodeURI(decodedData.mname);
    return decodedData;
};

export default JwtTokenProvider;
