import { useState } from "react";
import classes from "./Sign.module.css";
import Button from "../../components/UI/Button/Button";
import Input from "../../components/UI/Input/Input";
import {
  defaultEmailHostList,
  phoneNumberDefaultBox,
} from "../../components/definedefault/DefSelectBoxValues";
import SelectBoxNew from "../../components/UI/SelectBox/SelectBoxNew";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ReqChangePassword, ReqFindId, ReqFindPassword } from "../../api/auth/AuthApi";
import Alert from "../../components/UI/Modal/Alert";

function FindIdAndPwd() {
  const [viewMode, setViewMode] = useState(1);
  // viewMode : 1- 아이디/비밀번호 찾기
  // 2- 아이디/비밀번호 찾기 결과 , 3- 비밀번호 변경
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [informationFormIsOpen, setInformationFormIsOpen] = useState(false);
  const [informationFormIsOpen2, setInformationFormIsOpen2] = useState(false);

  const protocol = document.location.protocol;

  const handleModeChange = (mode) => {
    setIdName("");
    setIdPhoneFirst("010");
    setIdPhoneMid("");
    setIdPhoneLast("");
    setIdEmailId("");
    setIdEmailHost("");
    setPwdName("");
    setPwdPhoneFirst("010");
    setPwdPhoneMid("");
    setPwdPhoneLast("");
    setPwdEmailId("");
    setPwdEmailHost("");
    setPwdId("");
    setValidCheck(false);
    setpwdFormValidCheck(false);
    setViewMode(mode);
    setTabIndex(0);
    setInformationFormIsOpen(false);
    setInformationFormIsOpen2(false);
    window.scrollTo(0, 0);
  };

  const findPwdUI = () => {
    setIdName("");
    setIdPhoneFirst("010");
    setIdPhoneMid("");
    setIdPhoneLast("");
    setIdEmailId("");
    setIdEmailHost("");
    setPwdName("");
    setPwdPhoneFirst("010");
    setPwdPhoneMid("");
    setPwdPhoneLast("");
    setPwdEmailId("");
    setPwdEmailHost("");
    setPwdId("");
    setValidCheck(false);
    setpwdFormValidCheck(false);
    setViewMode(1);
    setTabIndex(1);
    setInformationFormIsOpen(false);
    setInformationFormIsOpen2(false);
    window.scrollTo(0, 0);
  };

  // tab menu
  const [tabIndex, setTabIndex] = useState(0);
  const navigate = useNavigate();
  const navigateHandler = (link) => {
    navigate(link);
  };

  const tabToggle = (index) => {
    setIdName("");
    setIdPhoneFirst("010");
    setIdPhoneMid("");
    setIdPhoneLast("");
    setIdEmailId("");
    setIdEmailHost("");
    setPwdName("");
    setPwdPhoneFirst("010");
    setPwdPhoneMid("");
    setPwdPhoneLast("");
    setPwdEmailId("");
    setPwdEmailHost("");
    setPwdId("");
    setValidCheck(false);
    setpwdFormValidCheck(false);
    setTabIndex(index);
    setInformationFormIsOpen(false);
    setInformationFormIsOpen2(false);
  };

  const tabArr = [
    { id: 0, title: "아이디 찾기" },
    { id: 1, title: "비밀번호 찾기" },
  ];

  const [sEncData, setSEncData] = useState("");
  const [validCheck, setValidCheck] = useState(false);
  const [pwdFormvalidCheck, setpwdFormValidCheck] = useState(false);

  const [idName, setIdName] = useState("");
  const [idPhoneFirst, setIdPhoneFirst] = useState("010");
  const [idPhoneMid, setIdPhoneMid] = useState("");
  const [idPhoneLast, setIdPhoneLast] = useState("");
  const [idEmailId, setIdEmailId] = useState("");
  const [idEmailHost, setIdEmailHost] = useState("");

  const [pwdName, setPwdName] = useState("");
  const [pwdId, setPwdId] = useState("");
  const [pwdPhoneFirst, setPwdPhoneFirst] = useState("010");
  const [pwdPhoneMid, setPwdPhoneMid] = useState("");
  const [pwdPhoneLast, setPwdPhoneLast] = useState("");
  const [pwdEmailId, setPwdEmailId] = useState("");
  const [pwdEmailHost, setPwdEmailHost] = useState("");

  const emailHostList = defaultEmailHostList;
  const phoneFirstList = phoneNumberDefaultBox;

  const findIdObject = {
    name: idName,
    phone: `${idPhoneFirst}-${idPhoneMid}-${idPhoneLast}`,
    email: `${idEmailId}@${idEmailHost}`,
  };

  const findPwdObject = {
    name: pwdName,
    phone: `${pwdPhoneFirst}-${pwdPhoneMid}-${pwdPhoneLast}`,
    memberId: pwdId,
    email: `${pwdEmailId}@${pwdEmailHost}`,
  };

  const [foundMemberId, setFoundMemberId] = useState("");

  const findIdHandler = () => {
    setValidCheck(true);
    if (idPhoneFirst.trim() === "") {
      return;
    }
    if (idName.trim() === "") {
      return;
    }
    if (idPhoneMid.trim() === "") {
      return;
    }
    if (idPhoneLast.trim() === "") {
      return;
    }
    if (idEmailId.trim() === "") {
      return;
    }
    if (idEmailHost.trim() === "") {
      return;
    }
    ReqFindId(
      findIdObject,
      (result) => {
        if (result.status_error === 200) {
          setFoundMemberId(result.memberId);
          handleModeChange(2);
          return;
        } else {
          navigate("/signUp/noExist");
          return;
        }
      },
      (error_result) => {
        alert("서버 에러");
        return;
      }
    );
  };

  const findPwdHandler = () => {
    setpwdFormValidCheck(true);

    if (pwdName.trim() === "") {
      return;
    }
    if (pwdId.trim() === "") {
      return;
    }
    if (pwdPhoneFirst.trim() === "") {
      return;
    }
    if (pwdPhoneMid.trim() === "") {
      return;
    }
    if (pwdPhoneLast.trim() === "") {
      return;
    }
    if (pwdEmailId.trim() === "") {
      return;
    }
    if (pwdEmailHost.trim() === "") {
      return;
    }

    ReqFindPassword(
      findPwdObject,
      (result) => {
        if (result.status_error === 200) {
          setFoundMemberId(result.memberId);
          handleModeChange(3);
          return;
        } else {
          navigate("/signUp/noExist");
          return;
        }
      },
      (error_result) => {
        alert("서버 에러");
        return;
      }
    );
  };

  const [newPwd, setNewPwd] = useState("");
  const [newPwdCheck, setNewPwdCheck] = useState("");
  const passwordRegex =
    /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{6,20}$/;
  const passwordRegexCheck = passwordRegex.test(newPwd);
  const [newPwdValidCheck, setNewPwdValidCheck] = useState(false);

  const passwordChangeObject = {
    member_id: foundMemberId,
    new_password: newPwd,
  };

  const changePasswordHandler = () => {
    setNewPwdValidCheck(true);

    if (foundMemberId.trim() === "") {
      alert("비정상적인 접근입니다.");
      return;
    }
    if (!passwordRegexCheck) {
      return;
    }

    if (newPwd.trim() === "" || newPwdCheck.trim() === "") {
      return;
    }

    if (newPwd.trim() !== newPwdCheck.trim()) {
      return;
    }

    ReqChangePassword(
      passwordChangeObject,
      (result) => {
        if (result.status_error === 200) {
          setShowPasswordModal(true);
          return;
        } else {
          setErrorModal(true);
          return;
        }
      },
      (error_result) => {
        setErrorModal(true);
        return;
      }
    );
  };

  const fnPopupId = async () => {
    window.open(
      "",
      "popupChk",
      "width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no"
    );

    window.parentCallback = (gender, name, year, month, day, cell, memberId) => {
      // 휴대폰 인증 후 성공하면
      //memberID가 존재하고, 화면에 memberID를 보여준다.
      setFoundMemberId(memberId);
      handleModeChange(2);
    };

    const response = await axios({
      url: `/api/v1/home/nice/find?protocol=${protocol}`,
      method: "GET",
    });

    const resData = await response.data;
    const sEncData = await resData.encData;
    setSEncData(sEncData);

    const form = document.createElement("form");
    form.method = "post";
    form.action = "https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb";
    form.target = "popupChk";

    const inputM = document.createElement("input");
    inputM.type = "hidden";
    inputM.name = "m";
    inputM.value = "checkplusService";
    form.appendChild(inputM);

    const inputEncodeData = document.createElement("input");
    inputEncodeData.type = "hidden";
    inputEncodeData.name = "EncodeData";
    inputEncodeData.value = sEncData;
    form.appendChild(inputEncodeData);

    document.body.appendChild(form);
    form.submit();
  };

  const fnPopupPwd = async () => {
    window.open(
      "",
      "popupChk",
      "width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no"
    );

    window.parentCallback = (gender, name, year, month, day, cell, memberId) => {
      // 휴대폰 인증 후 성공하면
      // memberID가 존재하면 해당 회원은 비밀번호 변경 권한을 가진다.
      // memberID는 해당 유저에게 보여주지 않고, 메모리에 가지고 있다가
      // 입력한 비밀번호랑 함께 서버로 보내 비밀번호를 변경한다.
      setFoundMemberId(memberId);
      handleModeChange(3);
    };

    const response = await axios({
      url: `/api/v1/home/nice/find?protocol=${protocol}`,
      method: "GET",
    });

    const resData = await response.data;
    const sEncData = await resData.encData;
    setSEncData(sEncData);

    const form = document.createElement("form");
    form.method = "post";
    form.action = "https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb";
    form.target = "popupChk";

    const inputM = document.createElement("input");
    inputM.type = "hidden";
    inputM.name = "m";
    inputM.value = "checkplusService";
    form.appendChild(inputM);

    const inputEncodeData = document.createElement("input");
    inputEncodeData.type = "hidden";
    inputEncodeData.name = "EncodeData";
    inputEncodeData.value = sEncData;
    form.appendChild(inputEncodeData);

    document.body.appendChild(form);
    form.submit();
  };

  const handleInformaionFormOpen = () => {
    setInformationFormIsOpen((prev) => !prev);
  };
  const handleInformaionFormOpen2 = () => {
    setInformationFormIsOpen2((prev) => !prev);
  };

  return (
    <>
      {viewMode === 1 && (
        <div className={`container ${classes.sign_container}`}>
          <div className={classes.official_sub_header}>
            <h2>아이디/비밀번호 찾기</h2>
          </div>
          <div className={classes.find_id_pw_wrap}>
            <ul className={classes.tab_menu}>
              {tabArr.map((item) => (
                <li
                  key={item.id}
                  className={tabIndex === item.id && classes.on}
                  onClick={() => tabToggle(item.id)}
                >
                  {item.title}
                </li>
              ))}
            </ul>
            {tabIndex === 0 && (
              <div className={classes.find_box}>
                <p className={classes.desc}>
                  원하시는 방법을 선택해 아이디를 찾을 수 있습니다.
                </p>
                <div className={classes.box}>
                  <div className={classes.flex_row}>
                    <h4>본인확인으로 찾기</h4>
                    <Button className="secondary sm" onClick={fnPopupId}>
                      휴대폰 인증하기
                    </Button>
                  </div>
                </div>
                {!informationFormIsOpen && (
                  <div className={classes.box}>
                    <div className={classes.flex_row}>
                      <h4>가입한 정보로 찾기</h4>
                      <Button className="secondary sm" onClick={handleInformaionFormOpen}>
                        정보 입력하기
                      </Button>
                    </div>
                  </div>
                )}
                {informationFormIsOpen && (
                  <div className={classes.box}>
                    <h4>가입한 정보로 찾기</h4>
                    <div className={classes.input_wrap}>
                      <p>이름</p>
                      <Input className="admin_st" value={idName} onChange={setIdName} />
                      {validCheck && idName.trim() === "" && (
                        <p className={classes.required}>이름을 입력해주세요.</p>
                      )}
                      <p>휴대폰</p>
                      <div className={classes.contact_wrap}>
                        <SelectBoxNew
                          className="default sm"
                          optionDatas={phoneFirstList}
                          setSelectItemCB={setIdPhoneFirst}
                          showDefaultKey={idPhoneFirst}
                        />
                        <span>-</span>
                        <Input
                          className="h45 sm"
                          value={idPhoneMid}
                          onChange={setIdPhoneMid}
                        />
                        <span>-</span>
                        <Input
                          className="h45 sm"
                          value={idPhoneLast}
                          onChange={setIdPhoneLast}
                        />
                      </div>
                      {validCheck &&
                        (idPhoneMid.trim() === "" || idPhoneLast.trim() === "") && (
                          <p className={classes.required}>휴대폰을 입력해주세요.</p>
                        )}
                      <p>이메일</p>
                      <div className={classes.contact_wrap}>
                        <Input
                          className="h45 sm"
                          value={idEmailId}
                          onChange={setIdEmailId}
                        />
                        <span>@</span>
                        <Input
                          className="h45 sm"
                          value={idEmailHost}
                          onChange={setIdEmailHost}
                        />
                        <SelectBoxNew
                          className="default sm"
                          optionDatas={emailHostList}
                          setSelectItemCB={setIdEmailHost}
                          showDefaultKey={idEmailHost}
                        />
                      </div>
                      {validCheck &&
                        (idEmailId.trim() === "" || idEmailHost.trim() === "") && (
                          <p className={classes.required}>이메일을 입력해주세요.</p>
                        )}
                      <Button className="secondary sm" onClick={findIdHandler}>
                        확인
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            )}
            {tabIndex === 1 && (
              <div className={classes.find_box}>
                <p className={classes.desc}>
                  원하시는 방법을 선택해 비밀번호를 재설정 할 수 있습니다.
                </p>
                <div className={classes.box}>
                  <div className={classes.flex_row}>
                    <h4>본인확인으로 찾기</h4>
                    <Button className="secondary sm" onClick={fnPopupPwd}>
                      휴대폰 인증하기
                    </Button>
                  </div>
                </div>
                {!informationFormIsOpen2 && (
                  <div className={classes.box}>
                    <div className={classes.flex_row}>
                      <h4>가입한 정보로 찾기</h4>
                      <Button className="secondary sm" onClick={handleInformaionFormOpen2}>
                        정보 입력하기
                      </Button>
                    </div>
                  </div>
                )}
                {informationFormIsOpen2 && (
                  <div className={classes.box}>
                    <h4>가입한 정보로 찾기</h4>
                    <div className={classes.input_wrap}>
                      <p>이름</p>
                      <Input className="admin_st" value={pwdName} onChange={setPwdName} />
                      {pwdFormvalidCheck && pwdName.trim() === "" && (
                        <p className={classes.required}>이름을 입력해주세요.</p>
                      )}
                      <p>아이디</p>
                      <Input className="admin_st" value={pwdId} onChange={setPwdId} />
                      {pwdFormvalidCheck && pwdId.trim() === "" && (
                        <p className={classes.required}>아이디를 입력해주세요.</p>
                      )}
                      <p>휴대폰</p>
                      <div className={classes.contact_wrap}>
                        <SelectBoxNew
                          className="default sm"
                          optionDatas={phoneFirstList}
                          setSelectItemCB={setPwdPhoneFirst}
                          showDefaultKey={pwdPhoneFirst}
                        />
                        <span>-</span>
                        <Input
                          className="h45 sm"
                          value={pwdPhoneMid}
                          onChange={setPwdPhoneMid}
                        />
                        <span>-</span>
                        <Input
                          className="h45 sm"
                          value={pwdPhoneLast}
                          onChange={setPwdPhoneLast}
                        />
                      </div>
                      {pwdFormvalidCheck &&
                        (pwdPhoneMid.trim() === "" || pwdPhoneLast.trim() === "") && (
                          <p className={classes.required}>휴대폰을 입력해주세요.</p>
                        )}
                      <p>이메일</p>
                      <div className={classes.contact_wrap}>
                        <Input
                          className="h45 sm"
                          value={pwdEmailId}
                          onChange={setPwdEmailId}
                        />
                        <span>@</span>
                        <Input
                          className="h45 sm"
                          value={pwdEmailHost}
                          onChange={setPwdEmailHost}
                        />
                        <SelectBoxNew
                          className="default sm"
                          optionDatas={emailHostList}
                          setSelectItemCB={setPwdEmailHost}
                          showDefaultKey={pwdEmailHost}
                        />
                      </div>
                      {pwdFormvalidCheck &&
                        (pwdEmailId.trim() === "" || pwdEmailHost.trim() === "") && (
                          <p className={classes.required}>이메일를 입력해주세요.</p>
                        )}
                      <Button className="secondary sm" onClick={findPwdHandler}>
                        확인
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      )}
      {viewMode === 2 && (
        <div className={`container ${classes.sign_container}`}>
          <div className={classes.official_sub_header}>
            <h2>아이디/비밀번호 찾기</h2>
          </div>
          <div className={classes.result_wrap}>
            <div className={classes.box}>
              <p>고객님의 정보와 일치하는 아이디입니다.</p>
              <p className={classes.result}>{foundMemberId}</p>
            </div>
            <div className={classes.flex_row}>
              <Button className="sm" onClick={findPwdUI}>
                비밀번호 찾기
              </Button>
              <Button className="secondary sm" onClick={() => navigateHandler("/login")}>
                로그인
              </Button>
            </div>
          </div>
        </div>
      )}
      {viewMode === 3 && (
        <div className={`container ${classes.sign_container}`}>
          <div className={classes.official_sub_header}>
            <h2>비밀번호 변경</h2>
          </div>
          <div className={classes.result_wrap}>
            <p>비밀번호를 재설정해주세요.</p>
            <div className={classes.box}>
              <div className={classes.input_wrap}>
                <p>새 비밀번호</p>
                <Input
                  type="password"
                  className="admin_st"
                  value={newPwd}
                  onChange={setNewPwd}
                />
                {!newPwdValidCheck && !passwordRegexCheck && (
                  <span className="require_red f_14">
                    영 대소문자, 숫자, 특수문자를 포함하여 6~20자 이내
                  </span>
                )}
                {newPwdValidCheck && !passwordRegexCheck && newPwd.trim() !== "" && (
                  <span className="require_red f_14">
                    영 대소문자, 숫자, 특수문자를 포함하여 6~20자 이내로 입력해주세요.
                  </span>
                )}
                {newPwdValidCheck && newPwd.trim() === "" && (
                  <span className="require_red f_14">비밀번호를 입력해주세요.</span>
                )}
                <p>새 비밀번호 확인</p>
                <Input
                  type="password"
                  className="admin_st"
                  value={newPwdCheck}
                  onChange={setNewPwdCheck}
                />
                {newPwdValidCheck && newPwdCheck.trim() === "" && (
                  <p className="require_red f_14">비밀번호를 다시 한번 입력해주세요.</p>
                )}
                {passwordRegexCheck &&
                  newPwd.trim() !== newPwdCheck.trim() &&
                  newPwd.trim() !== "" &&
                  newPwdCheck.trim() !== "" && (
                    <p className="require_red f_14">
                      입력하신 비밀번호와 동일하지 않습니다.
                    </p>
                  )}
                <Button className="secondary sm" onClick={changePasswordHandler}>
                  확인
                </Button>
              </div>
            </div>
          </div>
          {showPasswordModal && (
            <Alert type="alert" confirm="확인" close={() => navigateHandler("/login")}>
              {"비밀번호가 재설정 되었습니다."}
              {"다시 로그인해주세요."}
            </Alert>
          )}
          {errorModal && (
            <Alert type="alert" confirm="확인" close={() => navigateHandler("/login")}>
              {"휴면회원 또는 탈퇴한 회원입니다."}
            </Alert>
          )}
        </div>
      )}
    </>
  );
}

export default FindIdAndPwd;
