import { useEffect, useState } from "react";
import Button from "../../../components/UI/Button/Button";
import Input from "../../../components/UI/Input/Input";
import SelectBoxNew from "../../../components/UI/SelectBox/SelectBoxNew";
import Moment from "moment";
import classes from "../MngCommon.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import { ReqFaqList, ReqFaqSearchList } from "../../../api/community/ApiForFaq";
import PaginationNew from "../../../components/UI/Table/PaginationNew";
import {
    ContestFaqSearchTypes,
    faqType,
    exposeYnType,
} from "../../../components/definedefault/DefSelectBoxValues";



function MngFaq() {
    const location = useLocation();
    const navigate = useNavigate();

    const createHandler = () => {
        navigate("./create", { state: { currentPage } });
    };

    const detailHandler = (e, id) => {
        e.preventDefault();

        navigate(`./${id}`, {state: {currentPage}});
    };

    const [faqs, setFaqs] = useState([""]);

    const [currentPage, setCurrentPage] = useState(location.state?.currentPage ?? 0);
    const [totalPage, setTotalPage] = useState(0); //총 페이지 갯수
    const [totalCount, setTotalCount] = useState(0); //총 페이지 갯수

    const [faqDataType, setFaqDataType] = useState();
    const [exposeYnDataType, setExposeYnDataType] = useState();
    const [faqSearchType, setFaqSearchType] = useState();
    const [search, setSearch] = useState();
    
    const viewAll = async () => {
        setCurrentPage(0);
        setFaqDataType("");
        setExposeYnDataType("");
        setFaqSearchType("");
        setSearch("");

        const response = await ReqFaqList(0);
        if (response.status === 200) {
            const resData = await response.data.datas;
            setTotalPage(resData.totalPages);
            setTotalCount(resData.totalElements);
            setFaqs(resData.content);
        } else {
            setFaqs([]);
        }
    };

    const handlePageChange = async (page) => {
        setCurrentPage(page);

        const searchParam = {
            boardType: faqDataType,
            exposeYn: exposeYnDataType,
            searchType: faqSearchType,
            search: search,
            page: page,
        };

        const response = await ReqFaqSearchList(searchParam);
        if (response.status === 200) {
            const resData = await response.data.datas;
            setTotalPage(resData.totalPages);
            setTotalCount(resData.totalElements);
            setFaqs(resData.content);
        } else {
            setFaqs([]);
        }
    };
    useEffect(() => {
        handlePageChange(currentPage)
    }, [])
    // 검색 버튼 클릭
    const searchClick = async () => {
        setCurrentPage(0);

        const searchParam = {
            boardType: faqDataType,
            exposeYn: exposeYnDataType,
            searchType: faqSearchType,
            search: search,
            page: 0,
        };

        const response = await ReqFaqSearchList(searchParam);
        if (response.status === 200) {
            const resData = await response.data.datas;
            setTotalPage(resData.totalPages);
            setTotalCount(resData.totalElements);
            setFaqs(resData.content);
        } else {
            setFaqs([]);
        }
    };

    const setTypeCheck = (type) => {
        const types = faqType.find((items) => items.key === type);
        return types === undefined ? "" : types.value;
    };



    const faqTypeHandler = (key) => {
        setFaqDataType(key);
    };

    const exposeYnTypeHandler = (key) => {
        setExposeYnDataType(key);
    };

    const faqSearchTypeHandler = (key) => {
        setFaqSearchType(key);
    };

    const searchHandler = (e) => {
        setSearch(e);
    };

    return (
        <section>
            <div className={classes.admin_contents}>
                <h3>FAQ - 목록</h3>
                <div
                    className={`${classes.contents_wrap} ${classes.search_wrap}`}
                >
                    <div className={classes.admin_table_wrap}>
                        <table>
                            <colgroup>
                                <col style={{ width: "11%" }} />
                                <col style={{ width: "39%" }} />
                                <col style={{ width: "11%" }} />
                                <col style={{ width: "39%" }} />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>유형</th>
                                    <td>
                                        <SelectBoxNew
                                            className="admin_st"
                                            setSelectItemCB={faqTypeHandler}
                                            optionDatas={faqType}
                                            showDefaultKey={faqDataType}
                                        />
                                    </td>
                                    <th>사용여부</th>
                                    <td>
                                        <SelectBoxNew
                                            className="admin_st"
                                            setSelectItemCB={
                                                exposeYnTypeHandler
                                            }
                                            optionDatas={exposeYnType}
                                            showDefaultKey={exposeYnDataType}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>검색구분</th>
                                    <td colSpan={3}>
                                        <div className={classes.flex_row}>
                                            <SelectBoxNew
                                                className="admin_st"
                                                setSelectItemCB={
                                                    faqSearchTypeHandler
                                                }
                                                optionDatas={
                                                    ContestFaqSearchTypes
                                                }
                                                showDefaultKey={faqSearchType}
                                            />
                                            <Input
                                                className="admin_st"
                                                placeholder="검색어 입력"
                                                onChange={searchHandler}
                                                value={search}
                                            />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className={classes.bottom_btn_wrap}>
                            <Button className="border sm" onClick={searchClick}>
                                검색
                            </Button>
                            <Button className="secondary sm" onClick={viewAll}>
                                전체보기
                            </Button>
                        </div>
                    </div>
                </div>
                <div className={classes.contents_wrap}>
                    <div
                        className={`${classes.admin_table_wrap} ${classes.align_center}  ${classes.row_select}`}
                    >
                        <p className={classes.counter}>
                            총 <b>{totalCount}</b>건
                        </p>
                        <table className={classes.left_col_3}>
                            <colgroup>
                                <col style={{ width: "5%" }} />
                                <col style={{ width: "15%" }} />
                                <col style={{ width: "40%" }} />
                                <col style={{ width: "10%" }} />
                                <col style={{ width: "10%" }} />
                                <col style={{ width: "10%" }} />
                                <col style={{ width: "10%" }} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th>유형</th>
                                    <th>질문</th>
                                    <th>등록일</th>
                                    <th>작성자</th>
                                    <th>사용여부</th>
                                    <th>조회수</th>
                                </tr>
                            </thead>
                            <tbody>
                                {faqs?.length && faqs?.length !== 0 &&
                                    faqs.map((data, index) => (
                                        <tr
                                            onClick={(e) =>
                                                detailHandler(e, data.id)
                                            }
                                        >
                                            <td>{data.seq}</td>
                                            <td>
                                                {setTypeCheck(
                                                    data.boardTypeValue
                                                )}
                                            </td>
                                            <td>{data.title}</td>
                                            <td>
                                                {Moment(data.regDate).format(
                                                    "YYYY-MM-DD"
                                                )}
                                            </td>
                                            <td>{data.regID}</td>
                                            <td>
                                                {data.exposeYn
                                                    ? "사용"
                                                    : "미사용"}
                                            </td>
                                            <td>{data.readCnt}</td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                        <PaginationNew
                            className="admin_st"
                            totalPage={totalPage}
                            currentPage={currentPage}
                            defaultShowPage={5}
                            changePageCB={handlePageChange}
                        />
                        <div
                            className={`${classes.bottom_btn_wrap} ${classes.position_st}`}
                        >
                            <Button
                                className="secondary sm"
                                onClick={createHandler}
                            >
                                등록
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default MngFaq;

export const loader = async () => {
    const response = await ReqFaqList(0);
    if (response.status === 200) {
        const resData = await response.data.datas;
        return {
            data: resData.content,
            totalPage: resData.totalPages,
            totalCount: resData.totalElements,
        };
    } else {
        return [];
    }
};
