import instance from "../../store/AxiosInterceptor";

/**
 * 저장되어있는 배너 리스트 순서 변경
 * Admin 배너 페이지
 * @param resolveCB 응답이 성공일 경우 데이터를 넣어 호출하는 callback method
 * @returns
 */
export function ReqUpdateBannerSeq(characters, resolveCB, rejectCB) {
    instance({
        method: "PUT",
        url: "/api/v1/admin/contest/banner/move",
        data: {
            datas: [
                { banner_id: characters[0].id, priority: 5 },
                { banner_id: characters[1].id, priority: 4 },
                { banner_id: characters[2].id, priority: 3 },
                { banner_id: characters[3].id, priority: 2 },
                { banner_id: characters[4].id, priority: 1 },
            ],
        },
    })
        .then((response) => {
            // List [ { id, name } ...]
            if (resolveCB) {
                resolveCB(response);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/** TODO :
 * 저장되어있는 배너 상세 정보 변경
 * Admin 배너 상세 정보 페이지
 * @param resolveCB 응답이 성공일 경우 데이터를 넣어 호출하는 callback method
 * @returns
 */
export async function ReqUpdateBannerInfo(bannerId, formData, resolveCB) {
    await instance({
        method: "PUT",
        url: `/api/v1/admin/contest/banner/${bannerId}`,
        data: formData,
    }).then((res) => {
        if (resolveCB) {
            resolveCB(res);
        }
    });
}

/**
 * 저장되어있는 배너 파일 삭제
 * Admin 배너 상세 정보 페이지
 * @param resolveCB 응답이 성공일 경우 데이터를 넣어 호출하는 callback method
 * @returns
 */
export async function ReqDeleteBannerImg(bannerId) {
    const response = await instance({
        method: "DELETE",
        url: `/api/v1/admin/contest/banner/${bannerId}`,
    });

    if (response.ok) {
        return response.data;
    }
}
