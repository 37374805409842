import classes from "../MngCommon.module.css";
import { CheckboxA } from "../../../components/UI/Input/Checkbox";
import { useLoaderData } from "react-router-dom";
import { useState } from "react";
import Alert from "../../../components/UI/Modal/Alert";
import { ReqAutoSendList, ReqUpdateCheckbox } from "../../../api/tournament/AutoSendApi";

function MngTournamentDetailAutoSend() {
    const smsConfig = useLoaderData();
    const [smsTemplateList, setSmsTemplateList] = useState(smsConfig); // 본문
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [confirmationData, setConfirmationData] = useState({
        id: "",
        checked: false,
    });

    /**
     * 자동발송 여부 체크
     * @param {*} id
     * @param {*} checked
     */
    const checkboxChangeHandler = (id, checked) => {

        if (checked && smsTemplateList.find((item) => item.id === id)?.auto === "OFF") {
            setConfirmationData({ id, checked });
            setShowConfirmation(true);
        } else if (!checked && smsTemplateList.find((item) => item.id === id)?.auto === "AUTO") {
            setConfirmationData({ id, checked });
            setShowConfirmation(true);
        } else {
            updateCheckbox(id, checked);
        }
    };

    //확인 버튼 클릭
    const confirmChangeHandler = () => {
        const { id, checked } = confirmationData;
        setShowConfirmation(false);
        updateCheckbox(id, checked);
    };

    const updateCheckbox = async (id, checked) => {
        const response = await ReqUpdateCheckbox(id, checked);
        if (response.status === 200) {
            const updatedTemplates = smsTemplateList.map((item) => item.id === id ? { ...item, auto: checked ? "AUTO" : "OFF" } : item);
            setSmsTemplateList(updatedTemplates);
        } else {
            //console.error("Failed to update contest SMS config");
            alert(`업데이트 요청 실패 (${response.status})`)
        }
    };

    const tableHeadValue = (
        <>
            <colgroup>
                <col style={{ width: "10%" }} />
                <col style={{ width: "15%" }} />
                <col style={{ width: "15%" }} />
                <col style={{ width: "55%" }} />
                <col style={{ width: "15%" }} />
            </colgroup>
            <thead>
                <tr>
                    <th>자동발송 여부</th>
                    <th>템플릿명 (고객상태)</th>
                    <th>템플릿 유형</th>
                    <th>템플릿 문구 (최대 1000자)</th>
                    <th>버튼 적용</th>
                </tr>
            </thead>
        </>);

    const bodyValues = 
        smsTemplateList.map(
        ({
            id,
            auto,
            title,
            message,
            use_button,
            template_type,
            link,
        }) => {
            return (
                <tr key ={id}>
                    <td>
                        {auto === "OFF_ALWAYS" ? (
                            "항상 수동발송"
                        ) : (
                            <CheckboxA
                                className = "toggle_st"
                                id = {`chk_${id}`}
                                checked = {
                                    auto === "AUTO"
                                }
                                onChange = { (checked) =>
                                    checkboxChangeHandler(
                                        id,
                                        checked
                                    )
                                }
                            />
                        )}
                    </td>
                    <td>{title}</td>
                    <td>
                        {template_type === "BASIC"
                            ? "기본형"
                            : "안기본형"}
                    </td>
                    <td>{message}</td>
                    <td>
                        {use_button ? (
                            <a
                                href={link}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                참가비 결제하러 가기
                            </a>
                        ) : (
                            ""
                        )}
                    </td>
                </tr>
            );
        }
    );

    return (
        <section>
            <div className={classes.admin_contents}>
                <h3>문자 자동발송 관리</h3>

                <div className={classes.contents_wrap}>
                    <p className={classes.txt_right_primary}>
                        * 템플릿 유형 및 문구 변경은 카카오 사전승인 완료 후
                        가능합니다.
                    </p>
                    <div
                        className={`${classes.admin_table_wrap} ${classes.align_center} `}
                    >
                        <table className={classes.left_col_4}>
                            {tableHeadValue}
                            <tbody>
                                {bodyValues}
                            </tbody>
                        </table>
                        <ul className={classes.toggle_footnote}>
                            <li>자동문자발송 ON</li>
                            <li>자동문자발송 OFF</li>
                        </ul>
                    </div>
                </div>
            </div>
            {showConfirmation && (
                <Alert
                    type="confirm"
                    cancel="취소"
                    confirm="확인"
                    close={() => setShowConfirmation(false)} // 모달 닫기
                    confirmHandler={confirmChangeHandler} // 확인 버튼 눌렀을 때 실행되는 Handler
                >
                    {confirmationData.checked
                        ? "자동발송 기능을 ON 상태로 변경하시겠습니까?"
                        : "자동발송 기능을 OFF 상태로 변경하시겠습니까?"}
                </Alert>
            )}
        </section>
    );
}

export default MngTournamentDetailAutoSend;

export const loader = async () => {
    const response = await ReqAutoSendList();
    if (response.status === 200) {
        const resData = response.data;
        const curData = resData.datas.map((data) => ({
            id: data.id,
            auto: data.auto,
            title: data.title,
            message: data.message,
            use_button: data.use_button,
            template_code: data.template_code,
            template_type: data.template_type,
            link: data.link,
            seq: data.seq,
        }));
        return curData;
    } else {
        return [];
    }

};
