import { useEffect, useState } from "react";
import classes from "./IntroduceApp.module.css";
import Input from "../../components/UI/Input/Input";
import Button from "../../components/UI/Button/Button";
import Pagination from "../../components/UI/Table/Pagination";
import SelectBoxNew from "../../components/UI/SelectBox/SelectBoxNew";
import instance from "../../store/AxiosInterceptor";
import { useLoaderData } from "react-router";
function IntroCentersAppPage(props) {

    const center = useLoaderData();

    const [currentPage, setCurrentPage] = useState(0);
    const [totalPage, setTotalPage] = useState(center.totalPage); //총 페이지 갯수
    const [totalCount, setTotalCount] = useState(center.totalCount); //총 페이지 갯수

    const [centers, setCenters] = useState(center.data);

    const [region, setRegion] = useState("서울");

    // select box sample data
    const regionData = [
        { value: "서울", key: "서울" },
        { value: "인천", key: "인천" },
        { value: "경기", key: "경기" },
        { value: "충남", key: "충남" },
        { value: "충북", key: "충북" },
        { value: "대전", key: "대전" },
        { value: "전북", key: "전북" },
        { value: "전남", key: "전남" },
        { value: "광주", key: "광주" },
        { value: "경북", key: "경북" },
        { value: "경남", key: "경남" },
        { value: "대구", key: "대구" },
        { value: "울산", key: "울산" },
        { value: "부산", key: "부산" },
        { value: "제주", key: "제주" },
    ];

    const setSelectItemCB = (optionItemKey) => {
        setRegion(optionItemKey);
    };

    const [searchText, setSearchText] = useState();

    const searchHandler = (key) => {
        setSearchText(key)
    };

    const findSearchHandler = async () => {
        setCurrentPage(0)
        const searchParam = {
            page : currentPage,
            region : region,
            query_text : searchText,
        }
        const response = await instance.get("/api/v1/home/center/address", {
            params : searchParam
        })
        if (response.status === 200) {
            const resData = await response.data.data;
            setTotalPage(resData.totalPages);
            setTotalCount(resData.totalElements);
            setCenters(resData.content);
        } else {
            setCenters([]);
        }
    };

    const viewAll = async () => {
        setCurrentPage(0)
        setRegion("서울")
        setSearchText("")

        const response = await instance.get(`/api/v1/home/center/address?page=0`)
        if (response.status === 200) {
            const resData = await response.data.data;
            setTotalPage(resData.totalPages);
            setTotalCount(resData.totalElements);
            setCenters(resData.content);
        } else {
            setCenters([]);
        }
    };

    const handlePageChange = async () => {
        const page = currentPage + 1
        const searchParam = {
            page : page,
            region : region,
            query_text : searchText,
        }
        setCurrentPage(page)
        const response = await instance.get("/api/v1/home/center/address", {
            params : searchParam
        })
        if (response.status === 200) {
            const resData = await response.data.data;
            setTotalPage(resData.totalPages);
            setTotalCount(resData.totalElements);
            setCenters((prev) => [...prev, ...resData.content]);
        } else {
            setCenters((prev) => prev);
        }
    };

    useEffect(() => {
        findSearchHandler();
    }, [region]);


    return (
        <>
            <div className={classes.contents_wrap}>
                {/* 모바일에서 구조변경 탭메뉴-> selectbox */}
                <div className={classes.select_wrap}>
                    <SelectBoxNew
                        className="default w_100"
                        optionDatas={regionData}
                        setSelectItemCB={setSelectItemCB}
                        showDefaultKey={region}
                    />
                </div>
                <div className={classes.center_search_wrap}>
                    <Input placeholder="볼링장을 검색해주세요." onChange={searchHandler} value={searchText}/>
                    <Button className="search_sm" onClick={findSearchHandler}/>
                </div>
                <div className={classes.center_card_board}>
                    {centers.length !== 0 ? centers.map((data, index) => (
                    <>
                        <div className={classes.box}>
                            <h4>{data.name}</h4>
                            <p>{data.address}</p>
                            <p className={classes.tel}>TEL {data.phone}</p>
                        </div>
                    </>
                    )) : (
                        <div className={classes.empty_data}>
                            <p>검색결과가 없습니다.</p>
                            <Button className="secondary" onClick={viewAll}>전체 볼링장 보기</Button>
                        </div>
                    )}
                    {totalCount !== centers.length && (
                        <div className={classes.bottom_center_btn}>
                                <Button className="text_icon" onClick={handlePageChange}>더보기</Button>
                        </div>
                    )}
                </div>
                
            </div>
        </>
    );
}

export default IntroCentersAppPage;

export const loader = async () => {
    const response = await instance.get(`/api/v1/home/center/address?page=0&region=서울`)

    if (response.data.status === 200) {
        const resData = await response.data.data;
        return { data: resData.content, totalPage: resData.totalPages, totalCount : resData.totalElements };
    } else {
        return [];
    }
};