import { useState } from "react";
import classes from "./Sign.module.css";
import Button from "../../components/UI/Button/Button";
import { useNavigate } from "react-router-dom";
import axios from "axios";

function SingUpPage() {
    const protocol = document.location.protocol;

    const navigate = useNavigate();
    const [sEncData, setSEncData] = useState("");

    const paperNavigateHandler = () => {
        navigate("paper");
    }

    const cancelHandler = () => {
        navigate("/signUp");
    }

    const fnPopup = async () => {

        window.open(
            "",
            "popupChk",
            "width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no"
        );

        window.parentCallback = (gender,name,year,month,day,cell) => {
            navigate(`/signUp/phone?gender=${gender}&name=${name}&year=${year}&month=${month}&day=${day}&cell=${cell}`);
        }

        const response = await axios({
            url: `/api/v1/home/nice?protocol=${protocol}`,
            method: "GET",
        });

        const resData = await response.data;
        const sEncData = await resData.encData;
        setSEncData(sEncData);

        const form = document.createElement("form");
        form.method = "post";
        form.action = "https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb";
        form.target = "popupChk";

        const inputM = document.createElement("input");
        inputM.type = "hidden";
        inputM.name = "m";
        inputM.value = "checkplusService";
        form.appendChild(inputM);

        const inputEncodeData = document.createElement("input");
        inputEncodeData.type = "hidden";
        inputEncodeData.name = "EncodeData";
        inputEncodeData.value = sEncData;
        form.appendChild(inputEncodeData);

        document.body.appendChild(form);
        form.submit();
    }

    return (
        <div className={`container ${classes.sign_container}`}>
            <div className={classes.official_sub_header}>
                <h2>회원가입</h2>
            </div>
            <div className={classes.sign_up_wrap}>
                <div className={classes.identity_check_wrap}>
                    <div className={classes.desc}>
                        <p>
                            휴대폰 본인인증을 통해 회원가입을 하실 수
                            있으며, 입력하신 정보는 실명확인 용도 외에는
                            별도 저장되지 않습니다.
                        </p>
                        <p>
                            진승 회원으로 가입하시면 동일한 아이디와
                            비밀번호로 진승의 모든 회원제 사이트의 서비스를
                            안전하게 이용하실 수 있습니다.
                        </p>
                    </div>
                    <Button
                        className="secondary"
                        onClick={fnPopup}
                    >
                        휴대폰 본인인증 가입하기
                    </Button>
                    <div className={classes.info}>
                        <p className={classes.desc}>
                            본인인증이 불가능하신가요? 서면 회원가입으로
                            가입을 도와드립니다.
                        </p>
                        <Button onClick={paperNavigateHandler}>
                            서면 회원 가입하기
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SingUpPage;