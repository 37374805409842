import { useEffect, useState } from "react";
import classes from "../MngCommon.module.css";
import Button from "../../../components/UI/Button/Button";
import Input from "../../../components/UI/Input/Input";
import Pagination from "../../../components/UI/Table/Pagination";
import SelectBoxNew from "../../../components/UI/SelectBox/SelectBoxNew";
import {
    DefaultAll,
    DefaultEventChosenSelectBox,
    DefaultEventQueryTypeSelectBox,
} from "../../../components/definedefault/DefSelectBoxValues";
import { useNavigate } from "react-router-dom";
import {
    ReqEventName,
    ReqEventResultFindAll,
    ReqEventResultPaging,
    ReqEventResultSearch,
    ReqEventResultUploadFile,
} from "../../../api/event/MngEventResultApi";
import instance from "../../../store/AxiosInterceptor";
import { CSVLink } from "react-csv";
import PaginationNew from "../../../components/UI/Table/PaginationNew";

function MngEventResult() {
    const navigate = useNavigate();

    const [eventList, setEventList] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [pageNumber, setPageNumber] = useState(0);

    const [eventNameDefaultList, setEventNameDefaultList] = useState([DefaultAll]);
    const [eventId, setEventId] = useState("");
    const onEventIdHandler = (key) => {
        setEventId(key);
    };

    const queryTypeList = DefaultEventQueryTypeSelectBox;
    const [queryType, setQueryType] = useState("");
    const onQueryTypeHandler = (key) => {
        setQueryType(key);
    };

    const chosenTypeList = DefaultEventChosenSelectBox;
    const [chosenType, setChosenType] = useState("");
    const onChosenTypeHandler = (key) => {
        setChosenType(key);
    };

    const [queryText, setQueryText] = useState("");
    const onQueryTextHandler = (key) => {
        setQueryText(key);
    };

    const eventNameMouseOverHandler = () => {
        if (eventNameDefaultList.length === 1) {
            ReqEventName(
                (result) => {
                    const success = result.map((data) => {
                        return { key: String(data.event_id), value: data.event_title, disable: false };
                    });
                    setEventNameDefaultList((prev) => [DefaultAll, ...success]);
                },
                (excption_result) => {
                    setEventNameDefaultList((prev) => [DefaultAll]);
                }
            );
        }
    };

    useEffect(() => {
        eventNameMouseOverHandler();
    }, []);

    const data = [];
    const headers = ["NO", "이벤트 명", "이벤트 코드", "아이디", "이름", "당첨유무(당첨/미당첨)", "비고"];

    const [selectedFile, setSelectedFile] = useState();
    const [uploadSuccessModal, setUploadSuccessModal] = useState(false);
    const modalHandler = (state) => {
        setUploadSuccessModal(state);
        navigate("/admin/event/result");
    };

    const uploadCsvHandler = (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            setSelectedFile(undefined);
            return;
        }

        // 파일업로드를 하면 바로 서버로 보내기 위함
        const formData = new FormData();
        setSelectedFile(e.target.files[0]);

        if (e.target.files[0]) {
            formData.append("file", e.target.files[0]);
        }

        ReqEventResultUploadFile(
            formData,
            (result) => {
                if (result.status === 200) {
                    setUploadSuccessModal(true);
                    return;
                } else {
                    alert("업로드 에러");
                }
            },
            (error_result) => {
                alert("업로드 에러");
            }
        );
    };
    ///<<< 파일 업로드

    const pagingHanlder = (value) => {
        setPageNumber(value);

        const searchObejct = {
            queryType: queryType,
            queryText: queryText,
            chosen: chosenType,
            eventID: String(eventId),
        };

        ReqEventResultPaging(
            searchObejct,
            value,
            (result) => {
                const curData = result.content.map((data) => {
                    return {
                        id: data.id,
                        eventId: data.event_id,
                        eventTitle: data.event_title,
                        memberId: data.member_id,
                        memberName: data.member_name,
                        description: data.description,
                        seq: data.seq,
                        chosenYn: data.chosen_yn,
                    };
                });
                setEventList(curData);
                setTotalCount(result.totalElements);
                setPageNumber(result.pageable.pageNumber);
                setTotalPages(result.totalPages);
            },
            (error_result) => {
                setEventList((prev) => prev);
                setTotalCount((prev) => prev);
                setPageNumber((prev) => prev);
                setTotalPages((prev) => prev);
            }
        );
    };
    useEffect(() => {
        pagingHanlder(pageNumber);
    }, [])
    const searchHandler = () => {
        const searchObejct = {
            queryType: queryType,
            queryText: queryText,
            chosen: chosenType,
            eventID: String(eventId),
        };

        ReqEventResultSearch(
            searchObejct,
            (result) => {
                const curData = result.content.map((data) => {
                    return {
                        id: data.id,
                        eventId: data.event_id,
                        eventTitle: data.event_title,
                        memberId: data.member_id,
                        memberName: data.member_name,
                        description: data.description,
                        seq: data.seq,
                        chosenYn: data.chosen_yn,
                    };
                });
                setEventList(curData);
                setTotalCount(result.totalElements);
                setPageNumber(result.pageable.pageNumber);
                setTotalPages(result.totalPages);
            },
            (error_result) => {
                setEventList((prev) => prev);
                setTotalCount((prev) => prev);
                setPageNumber((prev) => prev);
                setTotalPages((prev) => prev);
            }
        );
    };

    const findAllHandler = () => {
        setEventId("");
        setQueryType("all");
        setQueryText("");
        setPageNumber(0);
        setChosenType("");

        ReqEventResultFindAll(
            (result) => {
                const curData = result.content.map((data) => {
                    return {
                        id: data.id,
                        eventId: data.event_id,
                        eventTitle: data.event_title,
                        memberId: data.member_id,
                        memberName: data.member_name,
                        description: data.description,
                        seq: data.seq,
                        chosenYn: data.chosen_yn,
                    };
                });
                setEventList(curData);
                setTotalCount(result.totalElements);
                setPageNumber(result.pageable.pageNumber);
                setTotalPages(result.totalPages);
            },
            (error_result) => {
                setEventList((prev) => prev);
                setTotalCount((prev) => prev);
                setPageNumber((prev) => prev);
                setTotalPages((prev) => prev);
            }
        );
    };


    return (
        <section>
            <div className={classes.admin_contents}>
                <h3>이벤트결과 등록 및 조회</h3>
                <div className={`${classes.contents_wrap} ${classes.search_wrap}`}>
                    <div className={classes.admin_table_wrap}>
                        <table>
                            <colgroup>
                                <col style={{ width: "11%" }} />
                                <col style={{ width: "59%" }} />
                                <col style={{ width: "11%" }} />
                                <col style={{ width: "19%" }} />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>이벤트명</th>
                                    <td>
                                        <SelectBoxNew
                                            className="admin_st w_100"
                                            optionDatas={eventNameDefaultList}
                                            setSelectItemCB={onEventIdHandler}
                                            showDefaultKey={eventId}
                                        />
                                    </td>
                                    <th>당첨유무</th>
                                    <td>
                                        <SelectBoxNew
                                            className="admin_st"
                                            optionDatas={chosenTypeList}
                                            setSelectItemCB={onChosenTypeHandler}
                                            showDefaultKey={chosenType}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>검색어</th>
                                    <td colSpan={3}>
                                        <div className={classes.flex_row}>
                                            <SelectBoxNew
                                                className="admin_st"
                                                optionDatas={queryTypeList}
                                                setSelectItemCB={onQueryTypeHandler}
                                                showDefaultKey={queryType}
                                            />
                                            <Input
                                                className="admin_st"
                                                onChange={onQueryTextHandler}
                                                value={queryText}
                                            />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className={classes.bottom_btn_wrap}>
                            <Button className="border sm" onClick={searchHandler}>
                                검색
                            </Button>
                            <Button className="secondary sm" onClick={findAllHandler}>
                                전체보기
                            </Button>
                        </div>
                    </div>
                </div>
                <div className={classes.contents_wrap}>
                    <div className={`${classes.admin_table_wrap} ${classes.align_center} `}>
                        <p className={classes.counter}>
                            총 <b>{totalCount}</b> 건
                        </p>
                        <table className="align_left_col2">
                            <colgroup>
                                <col style={{ width: "8%" }} />
                                <col style={{ width: "33%" }} />
                                <col style={{ width: "10%" }} />
                                <col style={{ width: "15%" }} />
                                <col style={{ width: "10%" }} />
                                <col style={{ width: "10%" }} />
                                <col style={{ width: "15%" }} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th>이벤트명</th>
                                    <th>이벤트코드</th>
                                    <th>아이디</th>
                                    <th>이름</th>
                                    <th>당첨유무</th>
                                    <th>비고</th>
                                </tr>
                            </thead>
                            <tbody>
                                {eventList.map((data) => (
                                    <tr key={data.id}>
                                        <td>{data.seq}</td>
                                        <td>{data.eventTitle}</td>
                                        <td>{data.eventId}</td>
                                        <td>{data.memberId}</td>
                                        <td>{data.memberName}</td>
                                        <td>{data.chosenYn === "Y" ? "당첨" : "미당첨"}</td>
                                        <td>{data.description}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <PaginationNew
                            className="admin_st"
                            totalPage={totalPages}
                            currentPage={pageNumber}
                            defaultShowPage={5}
                            changePageCB={pagingHanlder}
                        />
                        <div className={classes.position_left_btn}>
                            <CSVLink
                                data={data}
                                headers={headers}
                                filename={"이벤트 결과 엑셀 업로드 양식"}
                                forceText={true}
                            >
                                <Button className="primary download sm">엑셀 업로드 양식 다운로드</Button>
                            </CSVLink>
                        </div>
                        <div className={`${classes.bottom_btn_wrap} ${classes.position_st}`}>
                            <Button className="primary upload sm">
                                <input id="file" type="file" accept=".csv" onChange={uploadCsvHandler} />
                                <label htmlFor="file">이벤트 결과 엑셀 업로드</label>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            {uploadSuccessModal && (
                <Alert type="alert" confirm="확인" close={() => modalHandler(false)}>
                    {"결과가 저장되었습니다."}
                </Alert>
            )}
        </section>
    );
}
export default MngEventResult;

//이벤트 목록 리스트 가져오기
export const loader = async () => {
    const response = await instance({
        method: "GET",
        url: `/api/v1/admin/eventApplier?page=0&size=10`,
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    }).catch(function (error) {
        if (error.response) {
            return error.response;
        } else if (error.request) {
            return null;
        } else {
            return null;
        }
    });
    if (response == null || response.status !== 200) {
        return [];
    }
    if (response.data.status === 200) {
        return response.data.data;
    } else {
        return [];
    }
};
//이벤트 목록 리스트 가져오기
