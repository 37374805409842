import instance from "../../store/AxiosInterceptor";

/**
 * BO - 회원관리 - 회원정보 등록/변경 접수
 * 모든 신청 리스트를 가져온다.
 * @returns
 */
export function ReqFindMemberInfoAll(resolveCB, rejectCB) {
    instance({
        url: `/api/v1/admin/member/change?page=0&size=10`,
        method: "GET",
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data.data);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/**
 * BO - 회원관리 - 회원정보 등록/변경 접수
 * 검색 조건에 해당하는 목록 리스트를 가져온다.
 * @returns
 */
export function ReqFindMemberInfoSearch(searchObject, resolveCB, rejectCB) {
    instance({
        url: `/api/v1/admin/member/change?sDate=${searchObject.sDate}&eDate=${searchObject.eDate}&process=${searchObject.process}&query_type=${searchObject.query_type}&query_text=${searchObject.query_text}&page=0&size=10`,
        method: "GET",
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data.data);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/**
 * BO - 회원관리 - 회원정보 등록/변경 접수
 * 검색 조건에 해당하는 목록 리스트를 가져온다. 페이징
 * @returns
 */
export function ReqFindMemberInfoPaging(searchObject, page, resolveCB, rejectCB) {
    instance({
        url: `/api/v1/admin/member/change?sDate=${searchObject.sDate}&eDate=${searchObject.eDate}&process=${searchObject.process}&query_type=${searchObject.query_type}&query_text=${searchObject.query_text}&page=${page}&size=10`,
        method: "GET",
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data.data);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/**
 * BO - 회원관리 - 회원정보 등록/변경 접수
 * 상세보기/ 해당 회원의 정보 상세보기
 * @returns
 */
export function ReqPostComment(infoId, postObject, resolveCB, rejectCB) {
    instance({
        url: `/api/v1/admin/member/change/${infoId}`,
        method: "PUT",
        data: postObject,
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/**
 * BO - 회원관리 - 회원정보 등록/변경 접수
 * 등록/ 접수 페이지 진입시 해당 사용자의 정보를 가져온다.
 * @returns
 */
export function ReqMemberInfo(memberId, resolveCB, rejectCB) {
    instance({
        url: `/api/v1/admin/member/change/memberInfo/${memberId}`,
        method: "GET",
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data.data);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/**
 * BO - 회원관리 - 회원정보 등록/변경 접수
 * 회원 정보 변경
 * @returns
 */
export function ReqUpdateMemberInfo(object, resolveCB, rejectCB) {
    instance({
        url: `/api/v1/admin/member/change/memberInfo/edit`,
        method: "PUT",
        data : object
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data.status_error);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/**
 * BO - 회원관리 - 회원목록 - 회원등록
 * ADMIN이 직접 회원을 등록하는 API
 * @returns
 */
export function ReqRegisterMember(object, resolveCB, rejectCB) {
    instance({
        url: `/api/v1/admin/member/list`,
        method: "POST",
        data : object
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/**
 * BO - 회원관리 - 회원목록 - 회원등록
 * ADMIN이 직접 회원을 등록하는 API
 * @returns
 */
export function ReqActivateDormantMember(memberId, resolveCB, rejectCB) {
    instance({
        url: `/api/v1/admin/member/list/active`,
        method: "POST",
        data : {
            memberId : memberId
        }
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/**
 * BO - 회원관리 - 회원정보 변경 - 알림톡 발송
 * @returns
 */
export function ReqSendAlimTalkForMemberChaneInfo(memberID, resolveCB, rejectCB) {
    instance({
        url: `/api/v1/admin/member/change/memberInfo/alimtalk/${memberID}`,
        method: "POST",
        data : {}
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB(error);
            }
        });
}

/**
 * BO - 회원관리 - 회원정보 변경 - 알림톡 히스토리
 * @returns
 */
export function ReqFindAlimTalkHistoryForMemberChaneInfo(memberID, resolveCB, rejectCB) {
    instance({
        url: `/api/v1/admin/member/change/memberInfo/alimtalk/${memberID}`,
        method: "GET",
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB(error);
            }
        });
}