import { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";

import classes from "./FormSignIn.module.css";
import Input from "../UI/Input/Input";
import Button from "../UI/Button/Button";
import { JwtTokenContext } from "../../store/JwtTokenProvider";
import Checkbox from "../UI/Input/Checkbox";
import { ReqSignIn } from "../../api/auth/AuthApi";
import { useMemStore } from "../../store/memstore/MemStore";

const FormSignIn = (props) => {
    const [memStore, setMemStore] = useMemStore(false);
    const movePageSaved = memStore.lastPage ? (memStore["lastPage"].uri ? memStore["lastPage"].uri : null) : null;
    const movePage = useState(movePageSaved)[0];
    // configureMemStore에 정의된 ACTION 페이지 클리어

    const tokenCtx = useContext(JwtTokenContext); // tokenCtx
    const [password, setPassword] = useState("");
    const [validCheck, setValidCheck] = useState(false);

    const [userId, setUserId] = useState(localStorage.getItem("userId") || "");
    const userIsExist = !!userId;
    const [saveId, setSaveId] = useState(userIsExist);
    const saveIdHandler = () => {
        setSaveId((prev) => !prev);
    };

    const navigate = useNavigate();

    const useIdHandler = (value) => {
        setUserId(value);
    };

    const passwordHandler = (value) => {
        setPassword(value);
    };

    const submitHandler = async (e) => {
        e.preventDefault();

        setValidCheck(true);
        if (userId.trim() === "") {
            alert("아이디를 입력해주세요.");
            return;
        }
        if (password.trim() === "") {
            alert("비밀번호를 입력해주세요.");
            return;
        }

        const response = await ReqSignIn(userId, password);
        if (response.status !== 200) {
            alert("error");
            return;
        }

        if (response.status !== 200) {
            alert(`[${response.status}] ${response.error}`);
            return;
        }
        if (response.data.status !== 200 && response.data.status !== 0) {
            alert(`[${response.status}] ${response.data.error}`);
            return;
        }
        if (response.data.is_dormant === "Y") {
            alert("휴면 계정 해지 페이지로 이동 기능 생성필요.");
            return;
        }

        if (saveId) {
            localStorage.setItem("userId", userId);
        } else {
            localStorage.removeItem("userId");
        }
        tokenCtx.addToken({
            token: response.data.access_token,
            refreshToken: response.data.refresh_token,
        });

        if (movePage) navigate(movePage);
        else navigate("/");
        setMemStore("LOGIN_MOVE_PAGE", { uri: null });
    };
    const findNavigateHandler = () => {
        navigate("/find");
    };
    return (
        <>
            {
                //   className 에 따라 스타일이 변경 됩니다.
                // 1. login_wrap : 오피셜, 대회 홈 화면의 퀵 로그인
                // 2. login_wrap + props.className 으로 받은 official_st : 메인 > 로그인 메뉴 선택 시 전체 페이지의 로그인 화면
                <div className={`${classes.login_wrap} ${classes[props.className]}`}>
                    <h3>Login</h3>
                    <form>
                        <Input
                            id="userId"
                            type="userId"
                            placeholder="아이디"
                            label="아이디"
                            value={userId}
                            onChange={useIdHandler}
                        />
                        <Input
                            id="password"
                            type="password"
                            placeholder="비밀번호"
                            label="비밀번호"
                            onChange={passwordHandler}
                        />
                        <div className={classes.save_id}>
                            <Checkbox
                                className="official_st"
                                id="chk_11"
                                title="아이디 저장"
                                checked={saveId === true}
                                onChange={saveIdHandler}
                            />
                        </div>
                        <Button type="submit" className="square" onClick={submitHandler}>
                            로그인
                        </Button>
                    </form>
                    <div className={classes.find_btn_wrap}>
                        <Button className="btn_text white" onClick={findNavigateHandler}>
                            아이디/비밀번호 찾기
                        </Button>
                        <Link to="/signUp">
                            <Button className="btn_text white">회원가입</Button>
                        </Link>
                    </div>
                </div>
            }
        </>
    );
};

export default FormSignIn;
