import Button from "../../../../components/UI/Button/Button";
import classes from "../../MngCommon.module.css";

import FileBox, { FileBoxForImage } from "../../../../components/UI/Input/FileBox";
import Input from "../../../../components/UI/Input/Input";
import Radio from "../../../../components/UI/Input/Radio";
import SelectBoxNew from "../../../../components/UI/SelectBox/SelectBoxNew";
import { useLoaderData, useNavigate } from "react-router";
import { noneAllstaffDataType, staffDataType } from "../../../../components/definedefault/DefSelectBoxValues";
import { useContext, useState } from "react";
import Moment from 'moment';
import QuillEditor from "../../../../components/UI/Editer/QuillEditor";
import { ReqStaffCreate, ReqStaffDetail, ReqStaffUpdate } from "../../../../api/company/ApiForStaff";
import { JwtTokenContext } from "../../../../store/JwtTokenProvider";

function MngStaffModify(props) {

    const loginCtx = useContext(JwtTokenContext);

    const staff = useLoaderData();

    const navigate = useNavigate();

    const backHandler = () => {
        navigate("/admin/company/staff");
    };

    const [exposeYn, setExposeYn ] = useState(staff.expose_yn)
    const [staffType, setStaffType] = useState(staff.staff_type);
    const [staffName, setStaffName] = useState(staff.staff_name);
    const [team, setTeam] = useState(staff.team);
    const [proNo, setProNo] = useState(staff.pro_no);
    const [career, setCareer] = useState(staff.career);
    const [useHand, setUseHand] = useState(staff.use_hand);
    const [pointRank, setPointRank] = useState(staff.point_rank);
    const [staffInfo, setStaffInfo] = useState(staff.staff_info);

    const exposeYnRadioButton = (e) => {
        setExposeYn(e.target.value)
    }

    const staffDataHandler = (key) => {
        setStaffType(key);
    };

    const changeStaffNameHandler = (value) => {
        setStaffName(value);
    };

    const changeTeamHandler = (value) => {
        setTeam(value);
    };

    const changeProNoHandler = (value) => {
        setProNo(value);
    };

    const changeCareerHandler = (value) => {
        setCareer(value);
    };

    const changeUseHandHandler = (value) => {
        setUseHand(value);
    };

    const changePointRankHandler = (value) => {
        setPointRank(value);
    };

    const changeStaffInfoHandler = (value) => {
        setStaffInfo(value);
    };

    const [imageSrc, setImageSrc] = useState(staff.thumbnail_url);
    const [selectedFile, setSelectedFile] = useState();

    const encodeFileToBase64New = (fileBlob, fileUrl) => {
      setImageSrc(fileUrl);
      setSelectedFile(fileBlob);
    };

    const encodeFileRemove = () => {
      setImageSrc('');
      setSelectedFile(null)
    };

    const updateHandler = async (e) => {
        e.preventDefault();
        
        if(!staffName) {
            alert("이름을 입력해주세요.");
            return
        }

        if(!team) {
            alert("소속팀명을 입력해주세요.");
            return
        }

        if(!proNo) {
            alert("프로기수을 입력해주세요.");
            return
        }

        if(!career) {
            alert("구력을 입력해주세요.");
            return
        }

        if(!useHand) {
            alert("사용손을 입력해주세요.");
            return
        }

        if(!staffInfo) {
            alert("경력을 입력해주세요.");
            return
        }

        const isTrue = window.confirm('STAFF를 수정하시겠습니까?');
        if(isTrue) {
            try {
                const jsonData = JSON.stringify({
                    expose_yn: exposeYn,
                    staff_type: staffType,
                    staff_name: staffName,
                    team: team,
                    pro_no: proNo,
                    career: career,
                    use_hand: useHand,
                    point_rank: pointRank,
                    staff_info: staffInfo,
                    read_cnt : "0",
                    thumbnail_url : selectedFile ? null : imageSrc
                });
                console.log(jsonData);
                const formData = new FormData();
      
                if (selectedFile) {
                    formData.append("image", selectedFile);
                }
            
                formData.append("body", new Blob([jsonData], { type: "application/json" }));
                
                const response = await ReqStaffUpdate(staff.id, formData);
                if (response.status === 200) {
                    alert("STAFF이 수정되었습니다.");
                    backHandler();
                } else {
                    alert("STAFF 수정 실패");
                }
            } catch (error) {
                console.error("Error:", error);
            }
        } else {
          return
        }

        
    };

    return (
        <section>
                <div className={classes.admin_contents}>
                    <h3>STAFF - 수정</h3>
                    <div
                        className={`${classes.contents_wrap} ${classes.search_wrap} `}
                    >
                        <div className={classes.admin_table_wrap}>
                            <table>
                                <colgroup>
                                    <col style={{ width: "10%" }} />
                                    <col style={{ width: "40%" }} />
                                    <col style={{ width: "10%" }} />
                                    <col style={{ width: "40%" }} />
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <th>등록일</th>
                                        <td>{Moment(new Date()).format('YYYY-MM-DD')}</td>
                                        <th>등록자</th>
                                        <td>{loginCtx.decodedToken.jti}</td>
                                    </tr>
                                    <tr>
                                        <th>노출여부</th>
                                        <td>
                                            <div className={classes.radio_wrap}>
                                                <Radio
                                                    value="Y"
                                                    title="노출"
                                                    name="rad_01"
                                                    id="rad_01_01"
                                                    checked={exposeYn === "Y"} 
                                                    onChange={exposeYnRadioButton}
                                                />
                                                <Radio
                                                    value="N"
                                                    title="미노출"
                                                    name="rad_01"
                                                    id="rad_01_02"
                                                    checked={exposeYn === "N"} 
                                                    onChange={exposeYnRadioButton}
                                                />
                                            </div>
                                        </td>
                                        <th>구분</th>
                                        <td>
                                        <SelectBoxNew
                                            className="admin_st"
                                            setSelectItemCB={staffDataHandler}
                                            optionDatas={noneAllstaffDataType}
                                            showDefaultKey={staffType}
                                        />       
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>이름</th>
                                        <td>
                                            <Input
                                                className="admin_st"
                                                value={staffName}
                                                onChange={changeStaffNameHandler}
                                            />
                                        </td>
                                        <th>소속팀명</th>
                                        <td>
                                            <Input
                                                className="admin_st"
                                                value={team}
                                                onChange={changeTeamHandler}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>프로기수</th>
                                        <td>
                                            <Input
                                                className="admin_st"
                                                value={proNo}
                                                onChange={changeProNoHandler}
                                            />
                                        </td>
                                        <th>구력</th>
                                        <td>
                                            <Input
                                                className="admin_st"
                                                value={career}
                                                onChange={changeCareerHandler}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>사용손</th>
                                        <td>
                                            <Input
                                                className="admin_st"
                                                value={useHand}
                                                onChange={changeUseHandHandler}
                                            />
                                        </td>
                                        <th>포인트랭킹</th>
                                        <td>
                                            <Input 
                                                className="admin_st" 
                                                value={pointRank}
                                                onChange={changePointRankHandler}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>이미지</th>
                                        <td colSpan={3}>
                                            {FileBoxForImage (imageSrc, encodeFileToBase64New, encodeFileRemove)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>경력</th>
                                        <td colSpan={3}><div
                                        style={{ border: "1px solid #dedede", height: "292px" }}
                                    >
                                        <QuillEditor
                                        height='248' width='auto'
                                        onValueChangeCB={changeStaffInfoHandler}
                                        value={staffInfo}
                                        />
                                    </div></td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className={classes.bottom_btn_wrap}>
                                <Button className="secondary sm" onClick={updateHandler}>수정</Button>
                                <Button
                                    className="border sm" onClick={backHandler}
                                >
                                    목록
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
        </section>
    );
}

export default MngStaffModify;

export const loader = async ({ params }) => {
    const response = await ReqStaffDetail(params.staffID);
    
    if (response.status === 200) {
        const resData = await response.data.data;
        return resData;
    } else {
        return [];
    }
};