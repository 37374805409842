import { useState } from "react";
import Modal from "../../../../components/UI/Modal/Modal";
import Button from "../../../../components/UI/Button/Button";
import moment from "moment/moment";

const MngUserWithTournamentDetailList = ({ info }) => {
    //>>>참가자 신청 상태
    const applied = info.competitor_apply_status === "applied";
    const waiting = info.competitor_apply_status === "waiting";
    const chosen = info.competitor_apply_status === "chosen";
    const chosen_1 = info.competitor_apply_status === "chosen_1";
    const chosen_9 = info.competitor_apply_status === "chosen_9";
    const not_chosen = info.competitor_apply_status === "not_chosen";
    const cancel = info.competitor_apply_status === "cancel";
    const cancel_not_pay = info.competitor_apply_status === "cancel_not_pay";
    const cancel_apply = info.competitor_apply_status === "cancel_apply";
    const cancel_waiting = info.competitor_apply_status === "cancel_waiting";
    const complete = info.competitor_apply_status === "complete";
    const not_attend_a = info.competitor_apply_status === "not_attend_a";
    const not_attend_n = info.competitor_apply_status === "not_attend_n";

    const entryFee = info.entry_fee !== "0"; //0이아니면 참가비 존재

    const [modalOpen, setModalOpen] = useState(false);

    const researchView = (
        <>
            {info.research_result_list &&
                info.research_result_list.map((data, index) => (
                    <tr key={index}>
                        <th>{data.title}</th>
                        <td colSpan={3}>{data.value}</td>
                    </tr>
                ))}
        </>
    );

    function formatDate(inputDate) {
        if (!inputDate) return;
        if(inputDate.indexOf("-") !== -1) return;
        const year = inputDate.substr(0, 4);
        const month = inputDate.substr(4, 2);
        const day = inputDate.substr(6, 2);
        const formattedDate = `${year}-${month}-${day}`;

        return formattedDate;
    }
    function formatPhoneNumber(phoneNumber) {
        if(!phoneNumber) return;
        if(phoneNumber.indexOf("-") !== -1) return;
        phoneNumber = phoneNumber.replace(/\D/g, '');
        phoneNumber = phoneNumber.replace(/(\d{2,3})(\d{3,4})(\d{4})/, '$1-$2-$3');
        
        return phoneNumber;
      }

    return (
        <>
            {!modalOpen && (
                <tr onClick={() => setModalOpen(true)}>
                    <td>{info.contest_title}</td>
                    <td>{info.region}</td>
                    <td>{info.center_name}</td>
                    <td>{info.group_name ? info.group_name : info.group_seq}조</td>
                    <td>
                        {moment(info.contest_start_date).format("YYYY-MM-DD")} ~{" "}
                        {moment(info.contest_end_date).format("YYYY-MM-DD")}
                    </td>
                    <td>
                        {applied && "접수"}
                        {waiting && "대기"}
                        {chosen && "선정"}
                        {chosen_1 && "선정1"}
                        {chosen_9 && "선정9"}
                        {not_chosen && "비선정"}
                        {(cancel || cancel_not_pay) && "참가취소"}
                        {cancel_apply && "접수취소"}
                        {cancel_waiting && "대기취소"}
                        {complete && "참가등록"}
                        {not_attend_a && "불참(적)"}
                        {not_attend_n && "불참(부)"}
                        <br />
                        {info.pay_status === "Y" && "결제완료"}
                        {entryFee && info.pay_status === "N" && "미결제"}
                        {!entryFee && info.pay_status === "N" && "무결제"}
                        {info.pay_status === "R" && "결제취소"}
                    </td>
                </tr>
            )}
            {modalOpen && (
                <section>
                    <Modal className="admin_st" close={() => setModalOpen(false)} header="대회접수 상세 내역">
                        <main>
                            <h4>{info.contest_title}</h4>
                            <table>
                                <colgroup>
                                    <col style={{ width: "15%" }} />
                                    <col style={{ width: "35%" }} />
                                    <col style={{ width: "15%" }} />
                                    <col style={{ width: "35%" }} />
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <th>대회접수</th>
                                        <td>{moment(info.competitor_apply_date).format("YYYY-MM-DD HH:mm:ss")}</td>
                                        <th>대회명</th>
                                        <td>{info.contest_title}</td>
                                    </tr>
                                    <tr>
                                        <th>지역</th>
                                        <td>{info.region}</td>
                                        <th>경기장</th>
                                        <td>{info.center_name}</td>
                                    </tr>
                                    <tr>
                                        <th>조</th>
                                        <td>{info.group_name ? info.group_name : info.group_seq}조</td>
                                        <th>레인</th>
                                        <td>
                                            {info.lane}-{info.seq_of_lane !== 0 ? info.seq_of_lane : ""}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>이름</th>
                                        <td colSpan={3}>{info.member_name}</td>
                                    </tr>
                                    <tr>
                                        <th>연락처</th>
                                        <td colSpan={3}>{formatPhoneNumber(info.member_phone)}</td>
                                    </tr>
                                    <tr>
                                        <th>성별</th>
                                        <td colSpan={3}>{info.member_gender === "M" ? "남" : "여"}</td>
                                    </tr>
                                    <tr>
                                        <th>생년월일</th>
                                        <td colSpan={3}>{formatDate(info.member_birth)}</td>
                                    </tr>
                                    <tr>
                                        <th>장애여부</th>
                                        <td colSpan={3}>{info.disabled === "Y" ? "장애중증(3급)이상" : "해당없음"}</td>
                                    </tr>
                                    <tr>
                                        <th>핸디</th>
                                        <td colSpan={3}>{info.handicap}점</td>
                                    </tr>
                                    {researchView}
                                    <tr>
                                        <th>고객상태</th>
                                        <td colSpan={3}>
                                            {applied && "접수"}
                                            {waiting && "대기"}
                                            {chosen && "선정"}
                                            {chosen_1 && "선정1"}
                                            {chosen_9 && "선정9"}
                                            {not_chosen && "비선정"}
                                            {(cancel || cancel_not_pay) && "참가취소"}
                                            {cancel_apply && "접수취소"}
                                            {cancel_waiting && "대기취소"}
                                            {complete && "참가등록"}
                                            {not_attend_a && "불참(적)"}
                                            {not_attend_n && "불참(부)"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>결제유무</th>
                                        <td colSpan={3}>
                                            {info.pay_status === "Y" && "결제완료"}
                                            {entryFee && info.pay_status === "N" && "미결제"}
                                            {!entryFee && info.pay_status === "N" && "무결제"}
                                            {info.pay_status === "R" && "결제취소"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>결제(취소)일</th>
                                        <td colSpan={3}>
                                            {info.competitor_refund_date
                                                ? moment(info.competitor_refund_date).format("YYYY-MM-DD HH:mm:ss")
                                                : info.competitor_app_date ? moment(info.competitor_app_date).format("YYYY-MM-DD HH:mm:ss") : ""}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </main>
                        <footer>
                            <Button className="secondary sm" onClick={() => setModalOpen(false)}>
                                확인
                            </Button>
                        </footer>
                    </Modal>
                </section>
            )}
        </>
    );
};

export default MngUserWithTournamentDetailList;
