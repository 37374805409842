import { useEffect } from "react";
import classes from "./Alert.module.css";
import Button from "../Button/Button";

function Alert({ close= ()=> {}, type = 'alert'|'alert2'|'confirm', children = '', cancel = '취소', confirm = '확인', confirmHandler, ...props }) {
    // ↓ 퍼블단에서 화면 확인만을 위해 작성한 코드 입니다.
    //클래스명 조합
    const { className = "" } = props;
    const classNames = Array.isArray(className)
        ? className
        : className.split(" ");

    const combinedClasses = classNames
        .map((cn) => classes[cn])
        .filter((cn) => cn)
        .join(" ");

    const disableScroll = () => {
        document.body.style.overflow = "hidden";
    };
    const enableScroll = () => {
        document.body.style.overflow = "auto";
    };

    // 문자
    const handleConfirm = () => {
        // console.log("confirmHandler 실행");
        if (confirmHandler) {
            confirmHandler();
        }
        close();
    };
    useEffect(() => {
        // modal이 떠 있을 땐 스크롤 막음
        disableScroll();
        // modal 닫히면 다시 스크롤 가능하도록 함
        return () => enableScroll();
    }, []);
    return (
        <div className={`${classes.alert} ${combinedClasses}`}>
            <section>
                <span className={classes.close_x} onClick={close}>
                    close
                </span>
                <div className={classes.modal_body}>{children}</div>
                {type === "confirm" && (
                    <footer>
                        <Button className="sm" onClick={close}>
                            {cancel}
                        </Button>
                        <Button
                            className="secondary sm"
                            onClick={handleConfirm}
                        >
                            {confirm}
                        </Button>
                    </footer>
                )}
                {type === "alert" && (
                    <footer>
                        <Button className="secondary sm" onClick={close}>
                            {confirm}
                        </Button>
                    </footer>
                )}
            </section>
        </div>
    );
}
export default Alert;
