import { useContext, useState } from "react";
import classes from "../../MngCommon.module.css";
import Button from "../../../../components/UI/Button/Button";
import Radio from "../../../../components/UI/Input/Radio";
import Input from "../../../../components/UI/Input/Input";
import Modal from "../../../../components/UI/Modal/Modal";
import { Link, useNavigate } from "react-router-dom";
import DaumPostcode from "react-daum-postcode";
import { ReqRegionData } from "../../../../api/common/ConditionSelectBoxApi";
import SelectBoxNew from "../../../../components/UI/SelectBox/SelectBoxNew";
import { DefaultAll } from "../../../../components/definedefault/DefSelectBoxValues";
import Alert from "../../../../components/UI/Modal/Alert";
import classes2 from "../../../../components/UI/Input/SearchBox.module.css";
import {
    ReqAddRegion,
    ReqInsertBowlingAlley,
    ReqRegionList,
    ReqRegionSearch,
} from "../../../../api/tournament/BowlingAlleyApi";
import { JwtTokenContext } from "../../../../store/JwtTokenProvider";
function MngTournamentBowlingAlleyUpload() {
    // 등록자 정보 불러오기
    const loginCtx = useContext(JwtTokenContext);
    const navigate = useNavigate();
    // 등록일 (오늘 날짜 표시)
    const today = () => {
        const now = new Date();

        return (
            now.getFullYear() +
            "-" +
            ("0" + (now.getMonth() + 1)).slice(-2) +
            "-" +
            now.getDate()
        );
    };

    // 지역
    const [regionList, setRegionList] = useState([]);
    const [regionName, setRegionName] = useState("");

    const regionNameChangeHandler = (e) => {
        setRegionName(e);
    };

    const [showConfirm, setShowConfirm] = useState(false);

    // 지역 추가
    const addRegion = async () => {
        setShowConfirm(true);
    };
    const handleConfirm = async () => {
        setShowConfirm(false);

        const requestData = {
            name: regionName,
        };
        const response = await ReqAddRegion(requestData);
        if (response.status === 200) {
            setRegionList((prevRegionList) => [
                ...prevRegionList,
                response.data,
            ]);
            setRegionName(""); // 지역 추가 후 입력 필드 초기화
        } else {
            alert(`지역 추가 요청 실패 (${response.status})`);
        }
    };

    const [keyword, setKeyword] = useState("");

    const keywordHandler = (keyword) => {
        setKeyword(keyword);
    };

    // 지역 검색 버튼
    const regionSearch = async () => {
        let requestData = null;
        if (keyword === "") {
            requestData = null;
        } else {
            requestData = keyword;
        }
        const response = await ReqRegionSearch(requestData);

        if (response.status === 200) {
            setRegionList(response.data.datas);
        } else {
            alert(`지역 검색 요청 실패 (${response.status})`);
        }
    };

    // [END]

    // [START] 다음 주소 API =================================================================

    const [isDaumPostcodeOpen, setIsDaumPostcodeOpen] = useState(false);
    const [selectedAddress, setSelectedAddress] = useState(""); // 선택한 주소를 저장할 상태
    const [enteredAddress, setEnteredAddress] = useState(""); // 선택한 주소를 저장할 상태

    const openDaumPostcodeHandler = (e) => {
        e.stopPropagation();
        setIsDaumPostcodeOpen(true);
    };

    const handleAddressSelect = (data) => {
        const fullAddress = data.address; // 전체 주소
        const extraAddress = data.addressType === "R" ? "" : data.bname; // 도로명일 경우 법정동 추가
        const address = `${fullAddress} ${extraAddress}`; // 최종 주소
        setSelectedAddress(address); // 주소를 선택하면 상태 업데이트
        setIsDaumPostcodeOpen(false); // 주소를 선택하면 모달 창 닫기
    };

    const closeDaumPostcodeHandler = () => {
        setIsDaumPostcodeOpen(false);
    };

    // [END] 다음 주소 API =================================================================

    // [Start] 지역 선택 =================================

    const [isModalOpen1, setIsModalOpen1] = useState(false);
    const openModalHandler1 = async (e) => {
        e.stopPropagation();
        setIsModalOpen1(true);
        const response = await ReqRegionList();

        if (response.status === 200) {
            setRegionList(response.data.datas);
        } else {
            alert(`지역 리스트 요청 실패 (${response.status})`);
        }
    };
    const closeModalHandler1 = (e) => {
        e.stopPropagation();
        setIsModalOpen1(false);
    };

    const addressHandler = (address) => {
        setEnteredAddress(address);
    };

    const [regionData, setRegionData] = useState([DefaultAll]);
    const [region, setRegionType] = useState("");
    const [regionValue, setRegionValue] = useState("");

    const regionDataHandler = (key) => {
        // setRegionType(key);
        const selectedOption = regionData.find((data) => data.key === key);
        if (selectedOption) {
            setRegionType(key);
            setRegionValue(selectedOption.value);
        }
    };

    const regionNameMouseOverHandler = (key) => {
        if (regionData.length === 1) {
            ReqRegionData(
                (result) => {
                    const success = result.map((data) => {
                        return { key: String(data.id), value: data.name };
                    });
                    setRegionData((prev) => [DefaultAll, ...success]);
                },
                (excption_result) => {
                    setRegionData((prev) => [DefaultAll]);
                }
            );
        }
    };

    // [End]

    // [START] INPUT
    const [name, setName] = useState("");
    const nameChangeHandler = (value) => {
        setName(value);
    };

    const [id, setId] = useState("");
    const idChangeHandler = (value) => {
        setId(value);
    };

    const [laneCnt, setLaneCnt] = useState("");
    const laneCntChangeHandler = (value) => {
        setLaneCnt(value);
    };

    const [startLane, setStartLane] = useState("");
    const startLaneChangeHandler = (value) => {
        setStartLane(value);
    };

    const [endLane, setEndLane] = useState("");
    const endLaneChangeHandler = (value) => {
        setEndLane(value);
    };
    const [phoneNum1, setPhoneNum1] = useState("");
    const phoneNum1ChangeHandler = (value) => {
        setPhoneNum1(value);
    };
    const [phoneNum2, setPhoneNum2] = useState("");
    const phoneNum2ChangeHandler = (value) => {
        setPhoneNum2(value);
    };
    const [phoneNum3, setPphoneNum3] = useState("");
    const phoneNum3ChangeHandler = (value) => {
        setPphoneNum3(value);
    };

    const [isUse, setIsUse] = useState("N");
    const isUseChangeHandler = (e) => {
        setIsUse(e.target.value);
    };

    // [END] =================================================================

    const addCenter = async () => {
        // 필수 항목 체크
        if (
            name === "" ||
            id === "" ||
            laneCnt === "" ||
            startLane === "" ||
            endLane === "" ||
            phoneNum1 === "" ||
            phoneNum2 === "" ||
            phoneNum3 === "" ||
            selectedAddress === "" ||
            region === ""
        ) {
            setShowConfirmation(true); // 필수 항목이 비어 있을 경우 알림창 띄우기
            return;
        }

        // 끝 레인이 시작 레인보다 작을 때
        if (Number(startLane) > Number(endLane)) {
            setShowConfirmation2(true);
            return;
        }

        // 사용레인 개수가 맞지 않을 때

        if (endLane - startLane + 1 != laneCnt) {
            setShowConfirmation4(true);
            return;
        }

        // 전화번호 형식
        if (
            /^(\d{2,4})-(\d{3,4})-(\d{4})$/.test(
                `${phoneNum1}-${phoneNum2}-${phoneNum3}`
            ) === false
        ) {
            setShowConfirmation3(true);
            return;
        }

        const requestData = {
            center_id: id,
            region: regionValue,
            name: name,
            use_lane_count: parseInt(laneCnt),
            start_lane_number: parseInt(startLane),
            end_lane_number: parseInt(endLane),
            use_reserv_lane: isUse,
            // phoneNo: `${phoneNum1}-${phoneNum2}-${phoneNum3}`,
            phoneNo: `${phoneNum1}${phoneNum2}${phoneNum3}`,
            defaultAddress: selectedAddress,
            detailedAddress: enteredAddress,
            regDate: new Date().toISOString(),
            regID: "admin",
        };

        const response = await ReqInsertBowlingAlley(requestData);

        if (response.status === 200) {
            navigate("/admin/tournament/bowlingAlley");
        } else if (response.status === 409) {
            // CONFLICT 발생
            alert("동일한 볼링장ID가 존재합니다.");
        } else {
            alert("등록 요청에 실패했습니다.");
            console.log(response.status + " " + response.message);
        }
    };

    // [END] =================================================================

    // --- 필수항목 넣었는지? Alert 띄우기  --------------------------------

    const [showConfirmation, setShowConfirmation] = useState(false);
    const [showConfirmation2, setShowConfirmation2] = useState(false);
    const [showConfirmation3, setShowConfirmation3] = useState(false);
    const [showConfirmation4, setShowConfirmation4] = useState(false);

    const bodyData = (
        <>
            <tr>
                <th>등록일</th>
                <td colSpan={2}>{today()}</td>
                <th>등록자</th>
                <td>{loginCtx.decodedToken.jti}</td>
            </tr>
            <tr>
                <th>지역</th>
                <td colSpan={4}>
                    <div className={classes.flex_row}>
                        <SelectBoxNew
                            className="admin_st"
                            setSelectItemCB={regionDataHandler}
                            optionDatas={regionData}
                            showDefaultKey={region}
                            mouseOverCB={regionNameMouseOverHandler}
                        />
                        <Button
                            className="sm border"
                            onClick={openModalHandler1}
                        >
                            지역 추가
                        </Button>
                    </div>
                </td>
            </tr>
            <tr>
                <th>볼링장명</th>
                <td colSpan={2}>
                    <Input
                        className="admin_st_sm"
                        value={name}
                        onChange={nameChangeHandler}
                    />
                </td>
                <th>볼링장ID</th>
                <td>
                    <Input
                        className="admin_st_sm"
                        value={id}
                        onChange={idChangeHandler}
                    />
                </td>
            </tr>
            <tr>
                <th rowSpan={3}>레인</th>
                <th>레인 수</th>
                <td colSpan={3}>
                    <Input
                        type="number"
                        className="admin_st_sm"
                        value={laneCnt}
                        onChange={laneCntChangeHandler}
                    />
                </td>
            </tr>
            <tr>
                <th>사용레인</th>
                <td colSpan={3}>
                    <div className={classes.flex_row}>
                        <Input
                            type="number"
                            min="1"
                            className="admin_st_sm"
                            label="레인"
                            value={startLane}
                            onChange={startLaneChangeHandler}
                        />
                        <span>~</span>
                        <Input
                            type="number"
                            className="admin_st_sm"
                            label="레인"
                            value={endLane}
                            onChange={endLaneChangeHandler}
                        />
                    </div>
                </td>
            </tr>
            <tr>
                <th>예비레인 사용유무</th>
                <td colSpan={3}>
                    <div className={classes.radio_wrap}>
                        <Radio
                            value="Y"
                            title="사용"
                            name="rad_01"
                            id="rad_01_01"
                            checked={isUse === "Y"}
                            onChange={isUseChangeHandler}
                        />
                        <Radio
                            value="N"
                            title="미사용"
                            name="rad_01"
                            id="rad_01_02"
                            checked={isUse === "N"}
                            onChange={isUseChangeHandler}
                        />
                    </div>
                </td>
            </tr>
            <tr>
                <th rowSpan={2}>주소</th>
                <td colSpan={4}>
                    <div className={classes.flex_row}>
                        <Input
                            className="admin_st_mid"
                            value={selectedAddress}
                            readOnly
                        />
                        <Button
                            className="border sm"
                            onClick={openDaumPostcodeHandler}
                        >
                            주소찾기
                        </Button>
                    </div>
                </td>
            </tr>
            {isDaumPostcodeOpen && (
                <Modal
                    className="admin_st"
                    close={closeDaumPostcodeHandler}
                    header="주소 검색"
                >
                    <DaumPostcode onComplete={handleAddressSelect} />
                </Modal>
            )}
            <tr>
                <td colSpan={4}>
                    <Input
                        className="admin_st_mid"
                        placeholder="나머지 주소 입력"
                        onChange={addressHandler}
                        value={enteredAddress}
                    />
                </td>
            </tr>
            <tr>
                <th>전화번호</th>
                <td colSpan={4}>
                    <div className={classes.flex_row}>
                        <Input
                            type="number"
                            className="sm"
                            value={phoneNum1}
                            onChange={phoneNum1ChangeHandler}
                        />
                        <span>-</span>
                        <Input
                            type="number"
                            className="sm"
                            value={phoneNum2}
                            onChange={phoneNum2ChangeHandler}
                        />
                        <span>-</span>
                        <Input
                            type="number"
                            className="sm"
                            value={phoneNum3}
                            onChange={phoneNum3ChangeHandler}
                        />
                    </div>
                </td>
            </tr>
        </>
    );

    // --------------------------------

    return (
        <section>
            <div className={classes.admin_contents}>
                <h3>볼링장 등록</h3>
                <div
                    className={`${classes.contents_wrap} ${classes.search_wrap}`}
                >
                    <div className={classes.admin_table_wrap}>
                        <table>
                            <colgroup>
                                <col style={{ width: "159px" }} />
                                <col style={{ width: "159px" }} />
                                <col style={{ width: "460px" }} />
                                <col style={{ width: "159px" }} />
                                <col style={{ width: "620px" }} />
                            </colgroup>
                            <tbody>{bodyData}</tbody>
                        </table>
                        <div className={classes.bottom_btn_wrap}>
                            <Button className="border sm" onClick={addCenter}>
                                등록
                            </Button>
                            <Link to="/admin/tournament/bowlingAlley">
                                <Button className="secondary sm">목록</Button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            {showConfirmation && (
                <Alert
                    type="alert"
                    confirm="확인"
                    close={() => setShowConfirmation(false)}
                >
                    필수항목을 입력해주세요.
                </Alert>
            )}
            {showConfirmation2 && (
                <Alert
                    type="alert"
                    confirm="확인"
                    close={() => setShowConfirmation2(false)}
                >
                    시작레인보다 작을 수 없습니다.
                </Alert>
            )}
            {showConfirmation3 && (
                <Alert
                    type="alert"
                    confirm="확인"
                    close={() => setShowConfirmation3(false)}
                >
                    전화번호 형식에 맞지 않습니다.
                </Alert>
            )}
            {showConfirmation4 && (
                <Alert
                    type="alert"
                    confirm="확인"
                    close={() => setShowConfirmation4(false)}
                >
                    레인 수는 사용레인의 개수입니다.
                </Alert>
            )}

            {isModalOpen1 && (
                <Modal
                    className="admin_st"
                    close={closeModalHandler1}
                    header="지역 추가"
                >
                    <main>
                        <div className={classes.search_bx_wrap}>
                            <div className={classes2.search_box}>
                                <Input
                                    className="search_input"
                                    placeholder="지역검색"
                                    value={keyword}
                                    onChange={keywordHandler}
                                />
                                <button
                                    className={classes2.btn_search}
                                    onClick={regionSearch}
                                >
                                    검색
                                </button>
                                <p className={classes2.error_txt}>
                                    검색하실 내용을 입력해주세요.
                                </p>
                            </div>
                        </div>
                        <table>
                            <colgroup>
                                <col style={{ width: "110px" }} />
                                <col style={{ width: "739px" }} />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>지역 추가</th>
                                    <td>
                                        <div className={classes.flex_row}>
                                            <Input
                                                value={regionName}
                                                className="h35"
                                                onChange={
                                                    regionNameChangeHandler
                                                }
                                            />
                                            <Button
                                                className="border xxxs"
                                                onClick={addRegion}
                                            >
                                                등록
                                            </Button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div
                            className={`${classes.table_st_wrap} ${classes.st_3}`}
                        >
                            <div className={classes.header}>
                                <div>No</div>
                                <div>지역명</div>
                            </div>
                            <div className={classes.scroll_box}>
                                <div className={`${classes.body} `}>
                                    <div className={classes.col}>
                                        {regionList.map((item, index) => {
                                            return (
                                                <div className={classes.list}>
                                                    <b>{index + 1}</b>
                                                    <p>{item.name}</p>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </Modal>
            )}
            {showConfirm && (
                <Alert
                    type="confirm"
                    confirm="확인"
                    cancel="취소"
                    close={() => setShowConfirm(false)}
                    confirmHandler={handleConfirm}
                >
                    지역을 추가하시겠습니까?
                </Alert>
            )}
        </section>
    );
}
export default MngTournamentBowlingAlleyUpload;
