import React, { useEffect, useState } from "react";
import classes from "./KegelApp.module.css";
import BreadCrumb from "../../components/UI/BreadCrumb/BreadCrumb";
import Pagination from "../../components/UI/Table/Pagination";
import Button from "../../components/UI/Button/Button";
import SelectBoxSearch from "../../components/UI/SelectBox/SelectBoxNew";
import { NavLink, useLoaderData, useNavigate } from "react-router-dom";
import { ReqKegelHomeServiceList } from "../../api/kegel/KegelAPi";
import instance from "../../store/AxiosInterceptor";
import axios from "axios";

function LaneServiceUsefulInfoAppDetail(props) {
    const navigate = useNavigate();
    const resData = useLoaderData();

    const [kegelData, setKegelData] = useState(resData.data);
    const [prevKegel, setPrevKegel] = useState(resData.prev_data); // 이전글 정보
    const [nextKegel, setNextKegel] = useState(resData.next_data); // 다음글 정보

    const downloadFile = async () => {
        try {
            const response = await axios.get(`${kegelData.attach_uri}`, {
                responseType: "blob", // 파일을 Blob 형식으로 받기 위해 설정
            });

            const blob = new Blob([response.data], {
                type: response.headers["content-type"],
            });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.download = kegelData.originalFileName;
            link.click();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error("파일 다운로드 에러:", error);
        }
    };

    const toList = () => {
        navigate("/kegel/usefulInfo");
    };

    const handlePreviousClick = async (e, prevId) => {
        const response = await instance.get(
            `/api/v1/home/kegel/guide/usefulInfo/${prevId}`
        );
        if (response.status === 200) {
            setKegelData(response.data.data);
            setPrevKegel(response.data.prev_data);
            setNextKegel(response.data.next_data);
        } else {
            return [];
        }
    };

    const handleNextClick = async (e, nextId) => {
        const response = await instance.get(
            `/api/v1/home/kegel/guide/usefulInfo/${nextId}`
        );
        if (response.status === 200) {
            setKegelData(response.data.data);
            setPrevKegel(response.data.prev_data);
            setNextKegel(response.data.next_data);
        } else {
            return [];
        }
    };

    return (
        <>
            <div className={`${classes.contents_wrap} ${classes.depth_2_st}`}>
                <div className={classes.view_content}>
                    <div className={classes.title}>
                        <h4>{kegelData.title}</h4>
                        <p className={classes.date}>{kegelData.regDate}</p>
                    </div>
                    <div className={classes.content}>
                        <Button
                            className="btn_text official_st download"
                            onClick={downloadFile}
                        >
                            {kegelData.originalFileName}
                        </Button>
                        <div className={classes.text_box}>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: kegelData.content,
                                }}
                            />
                        </div>
                    </div>
                    <ul className={classes.list_wrap}>
                        {prevKegel != null && (
                            <li
                                className={classes.prev}
                                onClick={(e) =>
                                    handlePreviousClick(e, prevKegel.id)
                                }
                            >
                                <i>이전글</i>
                                <span>{prevKegel.title}</span>
                                <em>{prevKegel.regDate}</em>
                            </li>
                        )}
                        {nextKegel != null && (
                            <li
                                className={classes.next}
                                onClick={(e) =>
                                    handleNextClick(e, nextKegel.id)
                                }
                            >
                                <i>다음글</i>

                                <span>{nextKegel.title}</span>
                                <em>{nextKegel.regDate}</em>
                            </li>
                        )}
                    </ul>
                    <div className={classes.btn_wrap}>
                        <Button onClick={toList}>목록으로</Button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default LaneServiceUsefulInfoAppDetail;

export const loader = async ({ params }) => {
    try {
        const kegelGuideType = "usefulInfo"; // Use uppercase value here

        const response = await instance.get(
            `/api/v1/home/kegel/guide/${kegelGuideType}/${params.kegelId}`
        );

        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error("Failed to fetch data");
        }
    } catch (error) {
        console.error("Error fetching data:", error);
        return null;
    }
};
