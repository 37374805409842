import { useState, useEffect } from "react";
import classes from ".././SignApp.module.css";
import Button from "../../../components/UI/Button/Button";
import Input, { MultiInput } from "../../../components/UI/Input/Input";
import Radio from "../../../components/UI/Input/Radio";
import Checkbox from "../../../components/UI/Input/Checkbox";
import Modal from "../../../components/UI/Modal/Modal";
import SignTermsModal from "../SignTermsModal";
import SelectBoxNew from "../../../components/UI/SelectBox/SelectBoxNew";
import { useNavigate } from "react-router";
import DaumPostcode from "react-daum-postcode";
import Alert from "../../../components/UI/Modal/Alert";
import { Link } from "react-router-dom";
import { defaultEmailHostList } from "../../../components/definedefault/DefSelectBoxValues";
import {
    ReqCheckDuplicateMemId,
    ReqSMSAuthCode,
    ReqSMSAuthCodeValid,
    ReqSignUpDocMember,
} from "../../../api/auth/AuthApi";

function SignUpPaperApp() {
    const navigate = useNavigate();
    const [successSignUp, setSuccessSignUp] = useState(false);
    const [validCheck, setValidCheck] = useState(false);
    const [isAuthPassed, setIsAuthPassed] = useState("");
    const [serverAuthCode, setServerAuthCode] = useState("");
    const [confirmTid, setConfirmTid] = useState("");
    const topHandler = () => {
        window.scrollTo({ top: 0, behavior: "smooth" }); //상단 smooth 이동
    };

    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: 81 }, (_, index) => ({
        key: String(currentYear - index),
        value: String(currentYear - index),
        disabled: false,
    }));

    const months = Array.from({ length: 12 }, (_, index) => ({
        key: (index + 1).toString().padStart(2, "0"),
        value: (index + 1).toString().padStart(2, "0"),
        disabled: false,
    }));

    const days = Array.from({ length: 31 }, (_, index) => ({
        key: (index + 1).toString().padStart(2, "0"),
        value: (index + 1).toString().padStart(2, "0"),
        disabled: false,
    }));

    const [selectedYear, setSelectYear] = useState(currentYear);
    const [selectedMonth, setSelectedMonth] = useState("01");
    const [selectedDate, setSelectedDate] = useState("01");

    const selectedYearHandler = (key) => {
        setSelectYear(key);
    };
    const selectedMonthHandler = (key) => {
        setSelectedMonth(key);
    };
    const selectedDateHandler = (key) => {
        setSelectedDate(key);
    };

    const [input, setInput] = useState({
        memberName: "",
        gender: "",
        memberPhoneMid: "",
        memberPhoneLast: "",
        inputAuthCode: "",
        phoneOwner: "",
        phoneOwnerNmuber: "",
        emailId: "",
    });

    const {
        memberName,
        gender,
        memberPhoneMid,
        memberPhoneLast,
        inputAuthCode,
        phoneOwner,
        phoneOwnerNmuber,
        emailId,
    } = input;

    const [memberId, setMemberId] = useState("");
    const memberIdHandler = (value) => {
        setMemberIdIsValid("");
        setMemberId(value);
    };

    const onChangeInput = (e) => {
        const { name, value } = e.target;
        setInput({
            ...input,
            [name]: value,
        });
    };

    const [checkBox, setCheckBox] = useState({
        ownerAgree: "N",
        memberAgree: "N",
        useAgree: "N",
        temsAgree: "N",
        signOutAgree: "N",
        signUpAgree: "N",
    });

    const { ownerAgree, memberAgree, signOutAgree, useAgree, temsAgree, signUpAgree } = checkBox;

    const onChangeCheckBox = (e) => {
        const { id, value } = e.target;
        let newValue = null;
        if (value === "N") {
            newValue = "Y";
        }
        if (value === "Y") {
            newValue = "N";
        }
        setCheckBox({
            ...checkBox,
            [id]: newValue,
        });
    };

    const [smsYn, setSmsYn] = useState(true);
    const [emailYn, setEmailYn] = useState(true);

    const smsYnHandler = () => {
        setSmsYn((prev) => !prev);
    };
    const emailYnHandler = () => {
        setEmailYn((prev) => !prev);
    };

    const [emailHost, setEmailHost] = useState("");
    const emailHostHandler = (value) => {
        setEmailHost(value);
    };

    const memberNameRegex = /^[a-zA-Zㄱ-ㅎ가-힣]*$/;
    const memberIdRegex = /^(?=.*[a-zA-Z\d])(?=.*)[A-Za-z\d!@#$%^&*]{6,20}$/;
    const emailDomainRegex = /^[a-zA-Z0-9.!@#$%^&*()_+{}\[\]:;<>,.?~=-]*$/;
    const numberRegex = /^\d+$/;

    const memberNameRegexCheck = memberNameRegex.test(memberName);
    const memberIdRegexCheck = memberIdRegex.test(memberId);
    const emailDomainRegexCheck = emailDomainRegex.test(emailHost);
    const memberPhoneRegexCheck = numberRegex.test(memberPhoneMid) && numberRegex.test(memberPhoneLast);
    const phoneOwnerNameRegexCheck = memberNameRegex.test(phoneOwner);

    // modal
    const [showAlertModal, setShowAlertModal] = useState(false);
    const [modalText, setModalText] = useState("");
    const [isModalOpen1, setIsModalOpen1] = useState(false);
    const [isModalOpen2, setIsModalOpen2] = useState(false);
    const [isModalOpen3, setIsModalOpen3] = useState(false);
    const [isModalOpen4, setIsModalOpen4] = useState(false);

    const modalHandler = (state, text) => {
        setShowAlertModal(state);
        setModalText(text);
    };
    const openModalHandler1 = (e) => {
        e.stopPropagation();
        setIsModalOpen1(true);
    };
    const closeModalHandler1 = (e) => {
        e.stopPropagation();
        setIsModalOpen1(false);
    };
    const openModalHandler2 = (e) => {
        e.stopPropagation();
        setIsModalOpen2(true);
    };
    const closeModalHandler2 = (e) => {
        e.stopPropagation();
        setIsModalOpen2(false);
    };
    const openModalHandler3 = (e) => {
        e.stopPropagation();
        setIsModalOpen3(true);
    };
    const closeModalHandler3 = (e) => {
        e.stopPropagation();
        setIsModalOpen3(false);
    };
    const openModalHandler4 = (e) => {
        e.stopPropagation();
        setIsModalOpen4(true);
    };
    const closeModalHandler4 = (e) => {
        e.stopPropagation();
        setIsModalOpen4(false);
    };

    const phoneNumberDatas = [
        { key: "010", value: "010", disabled: isAuthPassed === "Y" },
        { key: "011", value: "011", disabled: isAuthPassed === "Y" },
        { key: "016", value: "016", disabled: isAuthPassed === "Y" },
        { key: "018", value: "018", disabled: isAuthPassed === "Y" },
        { key: "019", value: "019", disabled: isAuthPassed === "Y" },
    ];

    const [selectedPhoneNumber, setSelectedPhoneNumber] = useState("010");
    const selectedPhoneNumberHandler = (key) => {
        setSelectedPhoneNumber(key);
    };

    const [memberIdIsValid, setMemberIdIsValid] = useState("");
    const memberIdDuplicateHandler = () => {
        if (memberId.trim() === "") {
            alert("아이디를 입력해주세요.");
            return;
        }
        if (!memberIdRegexCheck) {
            alert("회원 아이디 양식에 적합하지 않습니다.");
            return;
        }
        ReqCheckDuplicateMemId(
            memberId,
            (status) => {
                if (status === 208) {
                    //신청내역존재시
                    //Axios Interceptor 때문에 200번대로 내려줌.
                    setMemberIdIsValid("D");
                } else if (status === 204) {
                    setMemberIdIsValid("Y");
                } else if (status === 200) {
                    setMemberIdIsValid("N");
                } else {
                    alert("서버 에러");
                    return;
                }
            },
            (error_result) => {
                alert("서버 에러");
                setMemberIdIsValid("");
            }
        );
    };

    const emailHostList = defaultEmailHostList;

    const navigateHandler = () => {
        navigate("/");
    };

    // >>> PC 배너 이미지 미리보기 및 업로드 세팅
    // (파일선택 버튼 클릭 후 이미지 업로드 시, 수정 버튼 클릭X -> 수정 안됨)
    const [selectedFile, setSelectedFile] = useState();
    const [newFileUri, setNewFileUri] = useState();

    useEffect(() => {
        if (!selectedFile) {
            setNewFileUri(null);
            return;
        }

        const objectUrl = URL.createObjectURL(selectedFile);
        setNewFileUri(objectUrl);

        return () => URL.revokeObjectURL(objectUrl);
    }, [selectedFile]);

    const onSelectFile = (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            setSelectedFile(undefined);
            return;
        }

        setSelectedFile(e.target.files[0]);
    };
    //<<< PC 배너 이미지 미리보기

    // [START] 다음 주소 API =================================================================
    const [isDaumPostcodeOpen, setIsDaumPostcodeOpen] = useState(false);
    const [zipCode, setZipCode] = useState(""); //우편번호
    const [selectedAddress, setSelectedAddress] = useState(""); // 선택한 주소를 저장할 상태
    const [enteredAddress, setEnteredAddress] = useState(""); // 입력한 주소를 저장할 상태

    const openDaumPostcodeHandler = (e) => {
        e.stopPropagation();
        setIsDaumPostcodeOpen(true);
    };

    const handleAddressSelect = (data) => {
        const fullAddress = data.address; // 전체 주소
        const extraAddress = data.addressType === "R" ? "" : data.bname; // 도로명일 경우 법정동 추가
        const address = `${fullAddress} ${extraAddress}`; // 최종 주소
        setZipCode(data.zonecode);
        setSelectedAddress(address); // 주소를 선택하면 상태 업데이트
        setIsDaumPostcodeOpen(false); // 주소를 선택하면 모달 창 닫기
    };

    const closeDaumPostcodeHandler = () => {
        setIsDaumPostcodeOpen(false);
    };

    //우편번호 검색을 했는데, 나머지 주소를 입력하지 않은경우
    //주소입력을 아에 안하고 가입 가능 but, 하나라도 입력하면 나머지 주소를 입력해야한다.
    const addressIsNotValid = zipCode.trim() !== "" && (selectedAddress.trim() === "" || enteredAddress.trim() === "");
    // [END] 다음 주소 API =================================================================

    //SMS 인증번호 전송
    const smsHandler = () => {
        setMinutes(3);
        setSeconds(0);
        const memberPhone = selectedPhoneNumber + memberPhoneMid + memberPhoneLast;
        ReqSMSAuthCode(
            memberPhone,
            (result) => {
                setServerAuthCode(result.auth_tid);
            },
            (error_result) => {
                alert("인증번호 발송 실패, 잠시만 기다려주세요.");
                setServerAuthCode();
            }
        );
    };

    const authCodeCheckHandler = () => {
        if (inputAuthCode.trim() === "") {
            setIsAuthPassed("N");
            return;
        }
        ReqSMSAuthCodeValid(
            serverAuthCode,
            inputAuthCode,
            (result) => {
                setIsAuthPassed(result.confirm);
                setConfirmTid(result.confirm_tid);
            },
            (error_result) => {
                alert("인증번호 인증 실패, 잠시만 기다려주세요.");
                setConfirmTid();
            }
        );
    };

    // 인증번호 3분 타이머
    const [minutes, setMinutes] = useState(3);
    const [seconds, setSeconds] = useState(0);

    useEffect(() => {
        let countdown; // countdown 변수를 선언

        if (serverAuthCode !== "" && isAuthPassed === "Y") {
            clearInterval(countdown);
            return;
        }

        if (serverAuthCode !== "") {
            countdown = setInterval(() => {
                if (parseInt(seconds) > 0) {
                    setSeconds(parseInt(seconds) - 1);
                }
                if (parseInt(seconds) === 0) {
                    if (parseInt(minutes) === 0) {
                        clearInterval(countdown);
                    } else {
                        setMinutes(parseInt(minutes) - 1);
                        setSeconds(59);
                    }
                }
            }, 1000);
            return () => clearInterval(countdown);
        }
    }, [minutes, seconds, serverAuthCode, isAuthPassed]);

    //서버전송값
    const memberInfoObject = {
        member_name: memberName,
        birth_date: String(selectedYear) + String(selectedMonth) + String(selectedDate),
        gender: gender,
        member_id: memberId,
        member_phone: (selectedPhoneNumber + memberPhoneMid + memberPhoneLast).replace(/-/g, ""),
        phone_owner: phoneOwner,
        phone_owner_number: phoneOwnerNmuber.replace(/-/g, ""),
        email_id: emailId + "@" + emailHost,
        owner_agree: ownerAgree,
        member_agree: memberAgree,
        sign_out_agree: signOutAgree,
        sign_use_agree: useAgree,
        sign_terms_agree: temsAgree,
        sign_up_agree: signUpAgree,
        sms_yn: smsYn ? "Y" : "N",
        email_yn: emailYn ? "Y" : "N",
        address: selectedAddress,
        address_sub: enteredAddress,
        confirm_tid: confirmTid,
        zip_code: zipCode,
        auth_type: "2",
    };

    //>>>회원가입 요청
    const signUpHandler = () => {
        setValidCheck(true);
        topHandler();

        if (memberIdIsValid === "N" || memberIdIsValid === "" || memberIdIsValid === "D") {
            return;
        }

        if (isAuthPassed === "" || isAuthPassed === "N") {
            return;
        }

        if (phoneOwnerNmuber.trim() === "") {
            return;
        }

        if (phoneOwner.trim() === "") {
            return;
        }

        if (!memberNameRegexCheck) {
            return;
        }

        if (!memberIdRegexCheck) {
            return;
        }

        if (!emailDomainRegexCheck) {
            return;
        }

        if (!memberPhoneRegexCheck) {
            return;
        }

        if (!phoneOwnerNameRegexCheck) {
            return;
        }

        if (addressIsNotValid) {
            return;
        }

        if (gender === "") {
            return;
        }

        if (ownerAgree === "N") {
            return;
        }

        if (memberAgree === "N") {
            return;
        }

        if (useAgree === "N") {
            return;
        }

        if (temsAgree === "N") {
            return;
        }

        if (signOutAgree === "N") {
            return;
        }

        if (signUpAgree === "N") {
            return;
        }

        if (!selectedFile || !newFileUri) {
            return;
        }

        if (memberName.trim() === "") {
            return;
        }

        if (memberId.trim() === "") {
            return;
        }

        if (memberPhoneMid.trim() === "") {
            return;
        }

        if (memberPhoneLast.trim() === "") {
            return;
        }

        if (emailId.trim() === "") {
            return;
        }

        const jsonData = JSON.stringify(memberInfoObject);

        const formData = new FormData();

        if (selectedFile) {
            formData.append("image", selectedFile);
        }

        formData.append("body", new Blob([jsonData], { type: "application/json" }));

        ReqSignUpDocMember(
            formData,
            (result) => {
                if (result.status === 200) {
                    setSuccessSignUp(true);
                    setValidCheck(false);
                }
            },
            (error_result) => {
                alert("회원가입 중 오류로 회원가입실패");
                setSuccessSignUp(false);
            }
        );
    };

    return (
        <div className={`container ${classes.sign_container}`}>
            <div className={classes.sign_up_wrap}>
                {!successSignUp && (
                    <div className={classes.sign_up_box}>
                        <div className={classes.title}>
                            <h2>서면 회원가입</h2>
                            <p className={classes.desc}>
                                필수항목<span className="red_c">*</span>
                            </p>
                        </div>
                        <div className={classes.detail_table}>
                            <table>
                                <tbody>
                                    <tr>
                                        <th>
                                            이름<span className="red_c">*</span>
                                        </th>
                                        <td>
                                            <MultiInput
                                                className="admin_st w_100"
                                                value={memberName}
                                                name="memberName"
                                                onChange={onChangeInput}
                                            />
                                            {validCheck && memberName.trim() === "" && (
                                                <p className={classes.required}>이름을 입력해주세요.</p>
                                            )}
                                            {memberName.trim() !== "" && !memberNameRegexCheck && (
                                                <p className={classes.required}>한글 및 영어만 입력가능합니다.</p>
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            생년월일
                                            <span className="red_c">*</span>
                                            (주민번호 기준)
                                        </th>
                                        <td>
                                            <div className={classes.input_col3}>
                                                <SelectBoxNew
                                                    className="sm "
                                                    optionDatas={years}
                                                    showDefaultKey={selectedYear}
                                                    setSelectItemCB={selectedYearHandler}
                                                    name="registerBirthYear"
                                                />
                                                <span>년</span>
                                                <SelectBoxNew
                                                    className="sm "
                                                    optionDatas={months}
                                                    showDefaultKey={selectedMonth}
                                                    setSelectItemCB={selectedMonthHandler}
                                                    name="registerBirthMonth"
                                                />
                                                <span>월</span>
                                                <SelectBoxNew
                                                    className="sm "
                                                    optionDatas={days}
                                                    showDefaultKey={selectedDate}
                                                    setSelectItemCB={selectedDateHandler}
                                                    name="registerBirthDate"
                                                />
                                                <span>일</span>
                                                {validCheck && selectedYear === "" && (
                                                    <p className={classes.required}>생년월일을 입력해주세요.</p>
                                                )}
                                                {validCheck && selectedMonth === "" && (
                                                    <p className={classes.required}>생년월일을 입력해주세요.</p>
                                                )}
                                                {validCheck && selectedDate === "" && (
                                                    <p className={classes.required}>생년월일을 입력해주세요.</p>
                                                )}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            성별<span className="red_c">*</span>
                                        </th>
                                        <td>
                                            <div className={classes.radio_wrap}>
                                                <Radio
                                                    className="official_st"
                                                    name="gender"
                                                    id="rad_02_1"
                                                    value="M"
                                                    title="남"
                                                    checked={gender === "M"}
                                                    onChange={onChangeInput}
                                                />
                                                <Radio
                                                    className="official_st"
                                                    name="gender"
                                                    id="rad_02_2"
                                                    value="F"
                                                    title="여"
                                                    checked={gender === "F"}
                                                    onChange={onChangeInput}
                                                />
                                                {validCheck && gender === "" && (
                                                    <p className={classes.required}>성별을 선택해주세요.</p>
                                                )}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            희망 아이디
                                            <span className="red_c">*</span>
                                        </th>
                                        <td>
                                            <div className={classes.input_btn_wrap}>
                                                <Input
                                                    className="admin_st_sm"
                                                    value={memberId}
                                                    name="memberId"
                                                    onChange={memberIdHandler}
                                                />
                                                <Button className="border sm" onClick={memberIdDuplicateHandler}>
                                                    아이디 중복 확인
                                                </Button>
                                            </div>
                                            {/**중복됐을경우 N으로 변경 */}
                                            {memberIdIsValid === "Y" &&
                                                memberIdRegexCheck &&
                                                memberId.trim() !== "" && <p>사용가능한 아이디입니다.</p>}
                                            {!validCheck && !memberIdRegexCheck && (
                                                <p className={classes.required}>영 대소문자를 포함하여 6~20자 이내</p>
                                            )}
                                            {memberId.trim() !== "" && memberIdIsValid === "N" && (
                                                <p className={classes.required}>
                                                    이미 사용중인 아이디입니다. 다시 확인해주세요.
                                                </p>
                                            )}
                                            {memberId.trim() !== "" && memberIdIsValid === "D" && (
                                                <p className={classes.required}>
                                                    서면 회원 가입 신청 이력이 있는 ID입니다.
                                                </p>
                                            )}
                                            {validCheck &&
                                                memberIdIsValid === "" &&
                                                memberId.trim() !== "" &&
                                                memberIdRegexCheck && (
                                                    <p className={classes.required}>아이디 중복확인을 해주세요.</p>
                                                )}
                                            {memberId.trim() === "" && validCheck && (
                                                <p className={classes.required}>아이디를 입력해주세요.</p>
                                            )}
                                            {validCheck &&
                                                memberId.trim() !== "" &&
                                                !memberIdRegexCheck &&
                                                memberIdIsValid === "" && (
                                                    <p className={classes.required}>
                                                        영 대소문자를 포함하여 6~20자 이내로 입력해주세요.
                                                    </p>
                                                )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            휴대폰 명의자 동의 여부
                                            <span className="red_c">*</span>
                                        </th>
                                        <td>
                                            <div className={classes.chk_agree}>
                                                <Checkbox
                                                    className="official_st"
                                                    id="ownerAgree"
                                                    value={ownerAgree}
                                                    onChange={onChangeCheckBox}
                                                    title="휴대폰 명의자의 사전동의를 받았습니다."
                                                />
                                                {validCheck && ownerAgree === "N" && (
                                                    <p className={classes.required}>
                                                        휴대폰 명의자 동의 여부에 동의해주세요
                                                    </p>
                                                )}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            휴대폰
                                            <span className="red_c">*</span>
                                        </th>
                                        <td>
                                            {/* 모바일에서 구조 변경 */}
                                            <div className={classes.phone_agree_wrap}>
                                                <div className={classes.input_col3}>
                                                    <SelectBoxNew
                                                        className="default sm"
                                                        optionDatas={phoneNumberDatas}
                                                        setSelectItemCB={selectedPhoneNumberHandler}
                                                        showDefaultKey={selectedPhoneNumber}
                                                        disabled={isAuthPassed === "Y"}
                                                    />
                                                    <span>-</span>
                                                    <MultiInput
                                                        className="h45 sm"
                                                        value={memberPhoneMid}
                                                        name="memberPhoneMid"
                                                        onChange={onChangeInput}
                                                        disabled={isAuthPassed === "Y"}
                                                    />
                                                    <span>-</span>
                                                    <MultiInput
                                                        className="h45 sm"
                                                        value={memberPhoneLast}
                                                        name="memberPhoneLast"
                                                        onChange={onChangeInput}
                                                        disabled={isAuthPassed === "Y"}
                                                    />
                                                </div>
                                                <div className={classes.input_btn_wrap}>
                                                    <MultiInput
                                                        className="admin_st_sm sm"
                                                        placeholder="인증번호를 입력해주세요."
                                                        value={inputAuthCode}
                                                        name="inputAuthCode"
                                                        onChange={onChangeInput}
                                                        disabled={isAuthPassed === "Y"}
                                                    />
                                                    <span>
                                                        {serverAuthCode !== "" &&
                                                            isAuthPassed !== "Y" &&
                                                            `${minutes}:${seconds}`}
                                                    </span>
                                                    {serverAuthCode && isAuthPassed !== "Y" && (
                                                        <Button className="border sm" onClick={authCodeCheckHandler}>
                                                            인증확인
                                                        </Button>
                                                    )}
                                                    {isAuthPassed !== "Y" && (
                                                        <Button className="border sm" onClick={smsHandler}>
                                                            인증요청
                                                        </Button>
                                                    )}
                                                </div>
                                                {serverAuthCode !== "" &&
                                                    isAuthPassed === "" &&
                                                    (minutes !== 0 || seconds !== 0) && (
                                                        <p className={classes.required}>인증번호가 전송되었습니다.</p>
                                                    )}
                                                {isAuthPassed === "N" && inputAuthCode.trim() !== "" && (
                                                    <p className={classes.required}>
                                                        인증번호가 올바르지않습니다. 다시확인해주세요.
                                                    </p>
                                                )}
                                                {isAuthPassed === "N" && inputAuthCode.trim() === "" && (
                                                    <p className={classes.required}>인증번호를 입력해주세요.</p>
                                                )}
                                                {isAuthPassed === "Y" && inputAuthCode.trim() !== "" && (
                                                    <p>인증이 완료되었습니다.</p>
                                                )}
                                                {validCheck &&
                                                    serverAuthCode === "" &&
                                                    memberPhoneMid.trim() !== "" &&
                                                    memberPhoneLast.trim() !== "" &&
                                                    isAuthPassed === "" && (
                                                        <p className={classes.required}>휴대폰 인증을 진행해주세요.</p>
                                                    )}
                                                {validCheck &&
                                                    memberPhoneMid.trim() === "" &&
                                                    memberPhoneLast.trim() === "" && (
                                                        <p className={classes.required}>휴대폰을 입력해주세요.</p>
                                                    )}
                                                {validCheck &&
                                                    memberPhoneMid.trim() !== "" &&
                                                    memberPhoneLast.trim() !== "" &&
                                                    !memberPhoneRegexCheck && (
                                                        <p className={classes.required}>
                                                            휴대폰번호 양식이 올바르지않습니다.
                                                        </p>
                                                    )}
                                                {isAuthPassed === "" && minutes === 0 && seconds === 0 && (
                                                    <p className={classes.required}>
                                                        인증시간이 지났습니다. 다시 휴대폰 인증을 진행해주세요.
                                                    </p>
                                                )}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            휴대폰 명의자 이름
                                            <span className="red_c">*</span>
                                        </th>
                                        <td>
                                            <MultiInput
                                                className="admin_st w_100"
                                                value={phoneOwner}
                                                name="phoneOwner"
                                                onChange={onChangeInput}
                                            />
                                            {validCheck && phoneOwner.trim() === "" && (
                                                <p className={classes.required}>휴대폰 명의자 이름을 입력해주세요.</p>
                                            )}
                                            {!phoneOwnerNameRegexCheck && (
                                                <p className={classes.required}>한글 및 영어만 입력가능합니다.</p>
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            휴대폰 명의자 연락처
                                            <span className="red_c">*</span>
                                        </th>
                                        <td>
                                            <MultiInput
                                                className="admin_st w_100"
                                                value={phoneOwnerNmuber}
                                                name="phoneOwnerNmuber"
                                                onChange={onChangeInput}
                                            />
                                            {validCheck && phoneOwnerNmuber.trim() === "" && (
                                                <p className={classes.required}>휴대폰 명의자 연락처를 입력해주세요.</p>
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>주소</th>
                                        <td>
                                            <div className={classes.address_wrap}>
                                                <div className={classes.input_btn_wrap}>
                                                    <Input
                                                        className="h45 "
                                                        value={zipCode}
                                                        id="zipCode"
                                                        onChange={setZipCode}
                                                    />
                                                    <Button className="border sm" onClick={openDaumPostcodeHandler}>
                                                        우편번호 검색
                                                    </Button>
                                                </div>
                                                <Input
                                                    className="h45 w_100"
                                                    value={selectedAddress}
                                                    id="address"
                                                    onChange={setSelectedAddress}
                                                />
                                                <Input
                                                    className="h45 w_100"
                                                    value={enteredAddress}
                                                    id="detailAddress"
                                                    onChange={setEnteredAddress}
                                                />
                                                {validCheck && addressIsNotValid && (
                                                    <p className={classes.required}>주소를 입력해주세요.</p>
                                                )}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            이메일
                                            <span className="red_c">*</span>
                                        </th>
                                        <td>
                                            <div className={classes.email_wrap}>
                                                <MultiInput
                                                    className="admin_st_sm"
                                                    value={emailId}
                                                    name="emailId"
                                                    onChange={onChangeInput}
                                                />
                                                <span>@</span>
                                                <Input
                                                    className="admin_st_sm"
                                                    value={emailHost}
                                                    name="emailHost"
                                                    onChange={emailHostHandler}
                                                />
                                                <SelectBoxNew
                                                    className="default "
                                                    optionDatas={emailHostList}
                                                    setSelectItemCB={emailHostHandler}
                                                    showDefaultKey={emailHost}
                                                />
                                            </div>
                                            {validCheck && (emailId.trim() === "" || emailHost.trim() === "") && (
                                                <p className={classes.required}>이메일을 입력해주세요.</p>
                                            )}
                                            {emailId.trim() !== "" && !emailDomainRegexCheck && (
                                                <p className={classes.required}>올바른 이메일 주소를 입력해주세요.</p>
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            신분증
                                            <span className="red_c">*</span>
                                        </th>
                                        <td>
                                            <div className={classes.id_card_wrap}>
                                                <p>
                                                    본인확인(이름/생년월일/성별)을 위해 주민등록증 또는 운전면허증을
                                                    첨부해주세요.
                                                    <br />
                                                    확인이 필요한 개인정보 외의 정보는 가려서 찍어주세요.
                                                </p>
                                                <ul>
                                                    <li>사진은 5MB 미만의 JPG, PNG, GIF 형식으로 업로드 가능합니다.</li>
                                                    <li>파일명에 ‘_’이외의 특수문자는 사용이 불가능합니다.</li>
                                                </ul>
                                                {validCheck && !newFileUri && (
                                                    <p className={classes.required}>신분증 이미지를 첨부해주세요.</p>
                                                )}
                                                {!newFileUri && (
                                                    <div className="input_file">
                                                        <input
                                                            type="file"
                                                            id="file"
                                                            accept="image/png, image/jpeg, image/gif"
                                                            onChange={onSelectFile}
                                                        />
                                                        <label htmlFor="file">파일선택</label>
                                                    </div>
                                                )}
                                                {/* <FileBox /> */}
                                                <div className={classes.flex_row}>
                                                    {newFileUri && (
                                                        <img
                                                            src={newFileUri}
                                                            alt="업로드 이미지 미리보기"
                                                            style={{
                                                                width: "400px",
                                                                height: "280px",
                                                            }}
                                                        />
                                                    )}
                                                    {newFileUri && (
                                                        <Button
                                                            className="border sm"
                                                            onClick={() => setSelectedFile(null)}
                                                        >
                                                            파일삭제
                                                        </Button>
                                                    )}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            약관동의
                                            <span className="red_c">*</span>
                                        </th>
                                        <td>
                                            <div className={classes.checkbox_wrap}>
                                                <Checkbox
                                                    className="official_st"
                                                    value={memberAgree}
                                                    title="개인정보 수집에 대한 동의"
                                                    id="memberAgree"
                                                    onChange={onChangeCheckBox}
                                                />
                                                <span className="red_c">(필수)</span>
                                                <Button className="text_underline" onClick={openModalHandler1}>
                                                    전문보기
                                                </Button>
                                            </div>
                                            {validCheck && memberAgree === "N" && (
                                                <p className={classes.required}>
                                                    개인정보 수집 및 이용에 동의 해주세요.
                                                </p>
                                            )}
                                            <div className={classes.checkbox_wrap}>
                                                <Checkbox
                                                    className="official_st"
                                                    value={useAgree}
                                                    title="개인정보 제공에 대한 동의"
                                                    id="useAgree"
                                                    onChange={onChangeCheckBox}
                                                />
                                                <span className="red_c">(필수)</span>
                                                <Button className="text_underline" onClick={openModalHandler2}>
                                                    전문보기
                                                </Button>
                                            </div>
                                            {validCheck && useAgree === "N" && (
                                                <p className={classes.required}>개인정보 제공에 동의를 해주세요.</p>
                                            )}
                                            <div className={classes.checkbox_wrap}>
                                                <Checkbox
                                                    className="official_st"
                                                    value={temsAgree}
                                                    title="회원약관 동의"
                                                    id="temsAgree"
                                                    onChange={onChangeCheckBox}
                                                />
                                                <span className="red_c">(필수)</span>
                                                <Button className="text_underline" onClick={openModalHandler3}>
                                                    전문보기
                                                </Button>
                                            </div>
                                            <div className={classes.checkbox_wrap}>
                                                {validCheck && temsAgree === "N" && (
                                                    <p className={classes.required}>회원약관에 동의를 해주세요.</p>
                                                )}
                                            </div>
                                            <div className={classes.checkbox_wrap}>
                                                <Checkbox
                                                    className="official_st"
                                                    value={signOutAgree}
                                                    title="회원탈퇴 동의"
                                                    id="signOutAgree"
                                                    onChange={onChangeCheckBox}
                                                />
                                                <span className="red_c">(필수)</span>
                                                <Button className="text_underline" onClick={openModalHandler4}>
                                                    전문보기
                                                </Button>
                                            </div>
                                            {validCheck && signOutAgree === "N" && (
                                                <p className={classes.required}>회원탈퇴에 동의를 해주세요.</p>
                                            )}
                                            <div className={classes.checkbox_wrap}>
                                                <Checkbox
                                                    className="official_st"
                                                    value={signUpAgree}
                                                    title="서면회원가입으로 위탁가입신청에 동의합니다."
                                                    id="signUpAgree"
                                                    onChange={onChangeCheckBox}
                                                />
                                            </div>
                                            {validCheck && signUpAgree === "N" && (
                                                <p className={classes.required}>위탁가입신청에 동의해주세요.</p>
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>마케팅 정보수신 동의</th>
                                        <td>
                                            <div className={classes.radio_wrap}>
                                                <Checkbox
                                                    className="official_st"
                                                    title="SMS"
                                                    id="smsYn"
                                                    onChange={smsYnHandler}
                                                    checked={smsYn === true}
                                                />
                                                <Checkbox
                                                    className="official_st"
                                                    value={emailYn}
                                                    title="이메일"
                                                    id="emailYn"
                                                    onChange={emailYnHandler}
                                                    checked={emailYn === true}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className={classes.btn_bottom_wrap}>
                                <Button onClick={navigateHandler}>취소</Button>
                                <Button className="secondary" onClick={signUpHandler}>
                                    서면가입 신청하기
                                </Button>
                            </div>
                        </div>
                    </div>
                )}
                {/* 서면회원가입 완료 */}
                {successSignUp && (
                    <div className={classes.welcome_wrap}>
                        <h2 className={classes.written_st}>
                            {memberName}님 서면회원가입 신청이
                            <br />
                            <span className="red_c">완료되었습니다.</span>
                        </h2>
                        <p className={classes.desc}>
                            서면회원가입 심사 후 개별 연락 예정입니다.
                            <br />
                            (영업일 기준 약 2일 소요)
                            <br />
                            서면회원가입 완료 후 아이디/비밀번호 찾기 시 ‘본인확인으로 찾기’가 아닌 ‘등록한 휴대폰
                            번호로 찾기’를 이용해주세요.
                        </p>
                        <Link to="/">
                            <Button className="secondary">홈으로</Button>
                        </Link>
                    </div>
                )}
            </div>
            {showAlertModal && (
                <Alert type="alert" confirm="확인" close={() => modalHandler(false, "")}>
                    {modalText}
                </Alert>
            )}
            {isModalOpen1 && (
                <Modal close={closeModalHandler1} header="개인정보 수집에 대한 동의">
                    <SignTermsModal
                        className="terms_personal_info"
                        close={closeModalHandler1}
                        ctAgreementInfo="ConsentCollectPrivacy"
                    />
                </Modal>
            )}
            {isModalOpen2 && (
                <Modal close={closeModalHandler2} header="개인정보 제공에 대한 동의">
                    <SignTermsModal
                        className="terms_personal_info"
                        close={closeModalHandler2}
                        ctAgreementInfo="ConsentUsePrivacy"
                    />
                </Modal>
            )}
            {isModalOpen3 && (
                <Modal close={closeModalHandler3} header="회원 약관 동의">
                    <SignTermsModal
                        className="terms_personal_info"
                        close={closeModalHandler3}
                        ctAgreementInfo="ConsentTerms"
                    />
                </Modal>
            )}
            {isModalOpen4 && (
                <Modal close={closeModalHandler4} header="회원 탈퇴 동의">
                    <SignTermsModal
                        className="terms_personal_info"
                        close={closeModalHandler4}
                        ctAgreementInfo="ConsentUserWithdraw"
                    />
                </Modal>
            )}
            {isDaumPostcodeOpen && (
                <Modal close={closeDaumPostcodeHandler} header="우편번호 검색">
                    <DaumPostcode onComplete={handleAddressSelect} />
                </Modal>
            )}
        </div>
    );
}

export default SignUpPaperApp;
