import classes from "../TournamentContainerApp.module.css";
import Button from "../../../components/UI/Button/Button";
import { useNavigate, useParams } from "react-router";
import { ReqTournamentInfo } from "../../../api/home/TournamentHomeApi";
import { useEffect, useState } from "react";

function TournamentListDetailApp() {
    const params = useParams();
    const contestId = params.contestId;

    const navigate = useNavigate();
    const listNavigateHandler = () => {
        navigate(`/tournament/list`);
    };

    const resultNavigateHandler = () => {
        navigate(`/tournament/result/${contestId}`);
    };

    const [contestInfo, setContestInfo] = useState({});

    const findContestInfoHandler = () => {
        ReqTournamentInfo(
            contestId,
            (result) => {
                const curData = {
                    ctId: result.ct_id,
                    ctTitle: result.ct_title,
                    ctStartDate: result.ct_start_date,
                    ctEndDate: result.ct_end_date,
                    ctAppStartTime: result.ct_app_start_time,
                    ctAppEndTime: result.ct_app_end_time,
                    resultId: result.result_id,
                    handiId: result.handi_id,
                    sexualPoint: result.sexual_point,
                    disabledPoint: result.disabled_point,
                    agesPoint: result.ages_point,
                    resultExposeYn: result.result_expose_yn,
                    description: result.description,
                    fileUri: result.file_uri,
                };
                setContestInfo(curData);
            },
            (error_result) => {
                setContestInfo((prev) => prev);
            }
        );
    };

    useEffect(() => {
        findContestInfoHandler();
    }, []);

    return (
        <>
            <div className={classes.tournament_img_header}>
                <img src={`${contestInfo.fileUri}`} alt="대회배너이미지" />
                <div className={classes.title}>
                    <h2>{contestInfo.ctTitle}</h2>
                    <p>대회 관련 정보를 꼼꼼히 확인해주세요.</p>
                </div>
            </div>
            <section className={classes.img_type}>
                <div className={classes.view_content}>
                    {/* 모바일에서 제목 삭제 */}
                    <div className={classes.mobile_detail_table}>
                        <div
                            className={classes.tournamanet_info_img}
                            dangerouslySetInnerHTML={{
                                __html: contestInfo.description,
                            }}
                        ></div>
                    </div>

                    <div className={classes.btn_wrap}>
                        <Button onClick={listNavigateHandler}>목록으로</Button>
                        {contestInfo.resultExposeYn === "Y" && (
                            <Button className="secondary" onClick={resultNavigateHandler}>
                                대회 결과
                            </Button>
                        )}
                    </div>
                </div>
            </section>
        </>
    );
}
export default TournamentListDetailApp;
