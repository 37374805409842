import { useLoaderData, useLocation, useNavigate } from "react-router-dom";
import { ReqRecommendProductDelete, ReqRecommendProductDetail } from "../../../../api/site/ApiForRecommendProduct";
import Button from "../../../../components/UI/Button/Button";
import classes from "../../MngCommon.module.css";
import Moment from 'moment';

function MngRecommendProduct(props) {

    const location = useLocation();
    const recommendProduct = useLoaderData();

    const navigate = useNavigate();

    const backHandler = () => {
        navigate("/admin/site/recommend", { state: location.state });
    };
    
    const deleteHandler = async (e) => {
        e.preventDefault();
        
        const isTrue = window.confirm('내용을 삭제하시겠습니까?');
        if(isTrue) {
            try {
                const response = await ReqRecommendProductDelete(recommendProduct.id);
                if (response.status === 200) {
                    alert("내용이 삭제되었습니다.");
                    backHandler();
                } else {
                    alert("내용 삭제 실패");
                }
            } catch (error) {
                console.error("Error:", error);
            }
        } else {
          return
        }
    };

    const updateHandler = () => {
        navigate(`../update/${recommendProduct.id}`, { state: location.state });
    };


    return (
        <section>
            <div className={classes.admin_contents}>
                <h3>추천상품 - 상세</h3>
                <div
                    className={`${classes.contents_wrap} ${classes.search_wrap} `}
                >
                    <div className={classes.admin_table_wrap}>
                        <table>
                            <colgroup>
                                <col style={{ width: "10%" }} />
                                <col style={{ width: "40%" }} />
                                <col style={{ width: "10%" }} />
                                <col style={{ width: "40%" }} />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>등록일</th>
                                    <td>{Moment(recommendProduct.regDate).format('YYYY-MM-DD')}</td>
                                    <th>등록자</th>
                                    <td>{recommendProduct.regID}</td>
                                </tr>
                                <tr>
                                    <th>노출여부</th>
                                    <td colSpan={3}>{recommendProduct.exposeYn === "Y" ? "노출" : "미노출"}</td>
                                </tr>
                                <tr>
                                    <th>노출탭</th>
                                    <td colSpan={3}>{recommendProduct.tabSeq}</td>
                                </tr>
                                <tr>
                                    <th>탭명</th>
                                    <td colSpan={3}>{recommendProduct.title}</td>
                                </tr>
                                <tr>
                                    <th>상품매핑</th>
                                    <td colSpan={3}>
                                        <ul
                                            className={
                                                classes.product_select_box
                                            }
                                        >
                                            {recommendProduct.itemList.length !== 0 && recommendProduct.itemList.map((data, index) => (
                                                <li>{data.name}</li>
                                            ))}
                                        </ul>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className={classes.bottom_btn_wrap}>
                            <Button className="secondary sm" onClick={updateHandler}>수정</Button>
                            <Button className="sm" onClick={deleteHandler}>삭제</Button>
                            <Button className="border sm" onClick={backHandler} >목록</Button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default MngRecommendProduct;

export const loader = async ({ params }) => {
    // console.log(params);
    const response = await ReqRecommendProductDetail(params.recommendID);

    if (response.status === 200) {
        const resData = await response.data.data;
        return resData;
    } else {
        return [];
    }
};