import {
    ReqContestType,
    ReqRegionData,
    ReqStadiumListWithRegion,
    ReqContestNameWithCtType2,
} from "../../../api/common/ConditionSelectBoxApi";
import {
    ReqFindAllUserBallList,
    ReqPagingUserBallList,
    ReqSearchUserBallList,
} from "../../../api/tournament/UserBallApi";
import Button from "../../../components/UI/Button/Button";
import Input from "../../../components/UI/Input/Input";
import SelectBoxNew from "../../../components/UI/SelectBox/SelectBoxNew";
import {
    DefaultAll,
    DefaultCenterNameSelectBox,
    DefaultContestNameSelectBox,
    DefaultIsRegisterBallBox,
    TournamentBall_Cus_Search,
} from "../../../components/definedefault/DefSelectBoxValues";
import instance from "../../../store/AxiosInterceptor";
import classes from "../MngCommon.module.css";
import { useState, useEffect } from "react";
import PaginationNew from "../../../components/UI/Table/PaginationNew";
import { CSVLink } from "react-csv";

function MngTournamentBall() {
    const [userBallList, setUserBallList] = useState([]);
    const [contestTypeData, setContestTypeData] = useState([DefaultAll]);
    const [contestType, setContestType] = useState("");
    const isReisterBall = DefaultIsRegisterBallBox;
    const [isRegister, setIsRegister] = useState("");
    const [regionData, setRegionData] = useState([DefaultAll]);
    const [centerNameList, setCenterNameList] = useState([DefaultCenterNameSelectBox]);

    const [selectedRegion, setSelectedRegion] = useState("");
    const selectedRegionHandler = (key) => {
        setSelectedRegion(key);
    };
    const isRegisterHandler = (value) => {
        setIsRegister(value);
    };

    const [keywordType, setKeywordType] = useState("");
    const [keyword, setKeyword] = useState("");
    const keywordHandler = (value) => {
        setKeyword(value);
    };

    // >>> 페이징을 위한 값 세팅
    const [totalCount, setTotalCount] = useState(0);
    const [totalPage, setTotalPage] = useState();
    const [pageNumber, setPageNumber] = useState(0);
    // <<< 페이징을 위한 값 세팅

    // 대회 유형 select box
    const contestTypeHandler = (key) => {
        setContestType(key);
    };

    // 검색어
    const keywordTypeHandler = (key) => {
        setKeywordType(key);
    };

    const contestTypeMouseOverHandler = (key) => {
        if (contestTypeData.length === 1) {
            ReqContestType(
                (result) => {
                    const success = result.map((data) => {
                        return { key: data.type, value: data.name };
                    });
                    setContestTypeData((prev) => [DefaultAll, ...success]);
                },
                (excption_result) => {
                    setContestTypeData((prev) => [DefaultAll]);
                }
            );
        }
    };

    const regionNameMouseOverHandler = (key) => {
        if (regionData.length === 1) {
            ReqRegionData(
                (result) => {
                    const success = result.map((data) => {
                        return { key: data.name, value: data.name };
                    });
                    setRegionData((prev) => [DefaultAll, ...success]);
                },
                (excption_result) => {
                    setRegionData((prev) => [DefaultAll]);
                }
            );
        }
    };

    // 검색하기
    const [contestId, setContestId] = useState("");
    const [centerId, setCenterId] = useState("");

    // >>> 대회명 Default 값 및 대회 타입에 따른 select Box
    const [contestNameList, setContestNameList] = useState([DefaultContestNameSelectBox]);

    useEffect(() => {
        setContestId("");
        if (contestType) {
            ReqContestNameWithCtType2(
                contestType,
                (resData) => {
                    const viewDatas = resData.map((data) => {
                        return { key: String(data.id), value: data.name, disabled: false };
                    });
                    setContestNameList((prev) => [DefaultContestNameSelectBox, ...viewDatas]);
                },
                (excption_result) => setContestNameList((prev) => [DefaultContestNameSelectBox])
            );
        } else {
            setContestNameList((prev) => [DefaultContestNameSelectBox]);
        }
    }, [contestType]);

    useEffect(() => {
        setCenterId("");
        if (selectedRegion) {
            ReqStadiumListWithRegion(
                selectedRegion,
                (result) => {
                    const success = result.map((data) => {
                        return {
                            key: data.bowling_center_id,
                            value: data.center_name,
                            disabled: false,
                        };
                    });
                    setCenterNameList((prev) => [DefaultCenterNameSelectBox, ...success]);
                },
                (exception_result) => {
                    setCenterNameList((prev) => [DefaultCenterNameSelectBox]);
                }
            );
        } else {
            setCenterNameList((prev) => [DefaultCenterNameSelectBox]);
        }
    }, [selectedRegion]);

    const handleContestIdCB = (key) => {
        setContestId(key);
    };

    const handleCenterIdCB = (key) => {
        setCenterId(key);
    };

    const searchCondition = {
        contestType: contestType !== null ? contestType : "",
        contestId: contestId !== null ? contestId : "",
        regionName: selectedRegion !== null ? selectedRegion : "",
        centerId: centerId !== null ? centerId : "",
        isRegister: isRegister !== null ? isRegister : "",
        queryType: keywordType,
        queryText: keyword,
    };

    const downLoadheaders = [
        { label: "No", key: "index" },
        { label: "대회명", key: "contestName" },
        { label: "지역", key: "regionName" },
        { label: "경기장", key: "centerName" },
        { label: "조", key: "groupName" },
        { label: "레인번호", key: "lane" },
        { label: "고객상태", key: "competitorStatus" },
        { label: "ID", key: "memberId" },
        { label: "이름", key: "memberName" },
        { label: "휴대폰", key: "memberPhone" },
        { label: "모델명", key: "modelName" },
        { label: "시리얼넘버", key: "serialNo" },
        { label: "등록확인", key: "confirm" },
    ];

    // 엑셀 다운로드
    const dataHandler = async (e, props) => {
        try {
            const response = await instance({
                url: `/api/v1/admin/contest/userBall/excel?contestId=${props.contestId}&contestType=${props.contestType}&regionName=${props.regionName}&centerId=${props.centerId}&isRegister=${props.isRegister}&${props.queryType}=${props.queryText}`,
                method: "GET",
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            });

            if (response.status === 200) {
                const resData = await response.data.datas;
                const curData = resData.map((data, index) => {
                    return {
                        index: index + 1,
                        centerName: data.center_name,
                        competitorStatus: data.competitor_status,
                        confirm: data.confirm === "Y" ? "확인" : "미확인",
                        contestName: data.contest_name,
                        groupName: data.group_name ? `${data.group_name}조` : `${data.group_seq}조`,
                        lane: `${data.lane ? data.lane : ""}-${data.lane_seq ? data.lane_seq : ""}`,
                        memberId: data.member_id,
                        memberName: data.member_name,
                        memberPhone: data.member_phone?.replace(/(\d{2,3})(\d{3,4})(\d{4})/, "$1-$2-$3"),
                        modelName: data.model_name,
                        serialNo: data.serial_no,
                        regionName: data.region_name,
                    };
                });

                if (curData.length > 0) {
                    // CSV 데이터 생성
                    let csvContent = "\uFEFF"; // BOM 문자로 UTF-8 인코딩 설정 (한글 깨짐 방지)

                    // 그룹 헤더 추가
                    curData.forEach((data, index) => {
                        if (index === 0) {
                            // 헤더 추가
                            csvContent += downLoadheaders.map((header) => header.label).join(",") + "\n";
                        }

                        if (index !== 0) {
                            csvContent += `\n`;
                        }

                        // 데이터 추가
                        csvContent += `"${data.index}","${data.contestName}","${data.regionName}","${
                            data.centerName
                        }","${data.groupName}","${data.lane}","${data.competitorStatus}","${data.memberId}","${
                            data.memberName
                        }","${String(data.memberPhone)}","${data.modelName}","${data.serialNo}","${data.confirm}"`;
                    });

                    // Blob 생성
                    const blob = new Blob([csvContent], {
                        type: "text/csv;charset=utf-8;",
                    });

                    // Blob을 URL로 변환하여 다운로드 링크 생성
                    const url = URL.createObjectURL(blob);
                    const a = document.createElement("a");
                    a.style.display = "none";
                    a.href = url;
                    a.download = `대회 사용구 조회.csv`;
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                }
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    const searchHandler = () => {
        ReqSearchUserBallList(
            searchCondition,
            (result) => {
                if (result?.content) {
                    const curData = result.content.map((data) => {
                        return {
                            centerName: data.center_name,
                            competitorStatus: data.competitor_status,
                            confirm: data.confirm,
                            contestId: data.contest_id,
                            contestName: data.contest_name,
                            groupName: data.group_name ? data.group_name : data.group_seq,
                            lane: data.lane,
                            laneSeq: data.lane_seq,
                            memberId: data.member_id,
                            memberName: data.member_name,
                            memberPhone: data.member_phone,
                            modelName: data.model_name,
                            serialNo: data.serial_no,
                            seq: data.seq,
                            regionName: data.region_name,
                        };
                    });
                    setUserBallList(curData);
                    setTotalCount(result.totalElements);
                    setTotalPage(result.totalPages);
                    setPageNumber(result.pageable.pageNumber);
                }
            },
            (error_result) => {
                setUserBallList((prev) => prev);
                setTotalCount((prev) => prev);
                setTotalPage((prev) => prev);
                setPageNumber((prev) => prev);
            }
        );
    };

    const pagingHandler = (key) => {
        setPageNumber(key);
        ReqPagingUserBallList(
            searchCondition,
            key,
            (result) => {
                if (result?.content) {
                    const curData = result.content.map((data) => {
                        return {
                            centerName: data.center_name,
                            competitorStatus: data.competitor_status,
                            confirm: data.confirm,
                            contestId: data.contest_id,
                            contestName: data.contest_name,
                            groupName: data.group_name ? data.group_name : data.group_seq,
                            lane: data.lane,
                            laneSeq: data.lane_seq,
                            memberId: data.member_id,
                            memberName: data.member_name,
                            memberPhone: data.member_phone,
                            modelName: data.model_name,
                            serialNo: data.serial_no,
                            seq: data.seq,
                            regionName: data.region_name,
                        };
                    });
                    setUserBallList(curData);
                    setTotalCount(result.totalElements);
                    setTotalPage(result.totalPages);
                    setPageNumber(result.pageable.pageNumber);
                }
            },
            (error_result) => {
                setUserBallList((prev) => prev);
                setTotalCount((prev) => prev);
                setTotalPage((prev) => prev);
                setPageNumber((prev) => prev);
            }
        );
    };
    useEffect(() => {
        pagingHandler(pageNumber);
    }, [])
    const findAllHandler = () => {
        setPageNumber(0);
        setContestType("");
        setContestId(null);
        setSelectedRegion("");
        setCenterId("");
        setIsRegister("");
        setKeyword("");
        setKeywordType("");

        ReqFindAllUserBallList(
            (result) => {
                const curData = result.content.map((data) => {
                    return {
                        centerName: data.center_name,
                        competitorStatus: data.competitor_status,
                        confirm: data.confirm,
                        contestId: data.contest_id,
                        contestName: data.contest_name,
                        groupName: data.group_name ? data.group_name : data.group_seq,
                        lane: data.lane,
                        laneSeq: data.lane_seq,
                        memberId: data.member_id,
                        memberName: data.member_name,
                        memberPhone: data.member_phone,
                        modelName: data.model_name,
                        serialNo: data.serial_no,
                        seq: data.seq,
                        regionName: data.region_name,
                    };
                });
                setUserBallList(curData);
                setTotalCount(result.totalElements);
                setTotalPage(result.totalPages);
                setPageNumber(result.pageable.pageNumber);
            },
            (error_result) => {
                setUserBallList((prev) => prev);
                setTotalCount((prev) => prev);
                setTotalPage((prev) => prev);
                setPageNumber((prev) => prev);
            }
        );
    };



    return (
        <section>
            <div className={classes.admin_contents}>
                <h3>대회 사용구 조회</h3>
                <div className={`${classes.contents_wrap} ${classes.search_wrap}`}>
                    <div className={classes.admin_table_wrap}>
                        <table>
                            <colgroup>
                                <col style={{ width: "11%" }} />
                                <col style={{ width: "20%" }} />
                                <col style={{ width: "11%" }} />
                                <col style={{ width: "23.5%" }} />
                                <col style={{ width: "11%" }} />
                                <col style={{ width: "23%" }} />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>대회유형</th>
                                    <td>
                                        <SelectBoxNew
                                            className="admin_st"
                                            setSelectItemCB={contestTypeHandler}
                                            optionDatas={contestTypeData}
                                            showDefaultKey={contestType}
                                            mouseOverCB={contestTypeMouseOverHandler}
                                        />
                                    </td>
                                    <th>대회명</th>
                                    <td colSpan={3}>
                                        <SelectBoxNew
                                            className="admin_st w100"
                                            optionDatas={contestNameList}
                                            setSelectItemCB={handleContestIdCB}
                                            showDefaultKey={contestId}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>지역</th>
                                    <td>
                                        <SelectBoxNew
                                            className="admin_st"
                                            optionDatas={regionData}
                                            setSelectItemCB={selectedRegionHandler}
                                            showDefaultKey={selectedRegion}
                                            mouseOverCB={regionNameMouseOverHandler}
                                        />
                                    </td>
                                    <th>경기장</th>
                                    <td>
                                        <SelectBoxNew
                                            className="admin_st w100"
                                            optionDatas={centerNameList}
                                            setSelectItemCB={handleCenterIdCB}
                                            showDefaultKey={centerId}
                                        />
                                    </td>
                                    <th>사용구 등록여부</th>
                                    <td>
                                        <SelectBoxNew
                                            className="admin_st w100"
                                            optionDatas={isReisterBall}
                                            setSelectItemCB={isRegisterHandler}
                                            showDefaultKey={isRegister}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>검색구분</th>
                                    <td colSpan={5}>
                                        <div className={classes.flex_row}>
                                            <SelectBoxNew
                                                className="admin_st"
                                                setSelectItemCB={keywordTypeHandler}
                                                optionDatas={TournamentBall_Cus_Search}
                                                showDefaultKey={keywordType}
                                            />
                                            <Input placeholder="검색어입력" onChange={keywordHandler} value={keyword} />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className={classes.bottom_btn_wrap}>
                            <Button className="border sm" onClick={searchHandler}>
                                검색
                            </Button>
                            <Button className="secondary sm" onClick={findAllHandler}>
                                전체보기
                            </Button>
                        </div>
                    </div>
                </div>
                <div className={classes.contents_wrap}>
                    <div className={`${classes.admin_table_wrap} ${classes.align_center}`}>
                        <p className={classes.counter}>
                            총 <b>{totalCount}</b>건
                        </p>
                        <table className={classes.left_col_2}>
                            <colgroup>
                                <col style={{ width: "8%" }} />
                                <col style={{ width: "12%" }} />
                                <col style={{ width: "8%" }} />
                                <col style={{ width: "8%" }} />
                                <col style={{ width: "5%" }} />
                                <col style={{ width: "5%" }} />
                                <col style={{ width: "8%" }} />
                                <col style={{ width: "8%" }} />
                                <col style={{ width: "10%" }} />
                                <col style={{ width: "10%" }} />
                                <col style={{ width: "10%" }} />
                                <col style={{ width: "8%" }} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th>대회명</th>
                                    <th>지역</th>
                                    <th>경기장</th>
                                    <th>조</th>
                                    <th>
                                        레인
                                        <br />
                                        번호
                                    </th>
                                    <th>
                                        고객
                                        <br />
                                        상태
                                    </th>
                                    <th>이름</th>
                                    <th>ID</th>
                                    <th>모델명</th>
                                    <th>시리얼넘버</th>
                                    <th>
                                        등록
                                        <br />
                                        확인
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {userBallList.map((data, index) => (
                                    <tr key={index}>
                                        <td>{data.seq}</td>
                                        <td>{data.contestName}</td>
                                        <td>{data.regionName}</td>
                                        <td>{data.centerName}</td>
                                        <td>{data.groupName}조</td>
                                        <td>
                                            {data.lane}-{data.laneSeq}
                                        </td>
                                        <td>{data.competitorStatus}</td>
                                        <td>{data.memberName}</td>
                                        <td>{data.memberId}</td>
                                        <td>{data.modelName}</td>
                                        <td>{data.serialNo}</td>
                                        <td>{data.confirm === "Y" ? "확인" : "미확인"}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <PaginationNew
                            className="admin_st"
                            totalPage={totalPage}
                            currentPage={pageNumber}
                            defaultShowPage={5}
                            changePageCB={pagingHandler}
                        />
                        <div className={`${classes.bottom_btn_wrap} ${classes.position_st}`}>
                            <Button className="primary download sm" onClick={(e) => dataHandler(e, searchCondition)}>
                                엑셀 다운로드
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default MngTournamentBall;

//>>> 유저가 등록한 대회 사용구 리스트 가져오기
export const loader = async () => {
    const response = await instance({
        method: "GET",
        url: `/api/v1/admin/contest/userBall?page=0&size=10&contestId=&contestType=&regionName=&centerId=&isRegister=`,
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    }).catch(function (error) {
        if (error.response) {
            return error.response;
        } else if (error.request) {
            return null;
        } else {
            return null;
        }
    });
    if (response == null || response.status !== 200) {
        return [];
    }
    if (response.data.status === 200) {
        return response.data.datas;
    } else {
        return [];
    }
};
//>>> 유저가 등록한 대회 사용구 리스트 가져오기
