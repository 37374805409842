import { useState, useEffect } from "react";


let globalState = {};
let listeners = [];
let actions = {};

/**
 * Redux 와 동일한 기능을 제공하기 위한 Global Memory Manager
 * @param {*} shouldListener useMemStore를 변경시 화면갱신을 하지 않기 위함 (false 설정)
 * @param {*} listen_target_action shouldListener = true 일 때 특정 action에 대해서만 반응을 하기 위함
 */
export const useMemStore = (shouldListener = true, listen_target_action = null ) => {
    const setState = useState (globalState)[1];

    /**
     * useState 의 두번째 값 설정하는 함수와 동일한 기능
     * 
     * @param {*} actionIdentifier 미리 정의한 ACTION 의 이름
     * @param {*} payload 정의된 ACTION 에서 사용될 데이터
     */
    const dispatch = (actionIdentifier, payload) => {
        const newState = actions[actionIdentifier](globalState, payload);
        globalState = { ...globalState, ...newState };

        for (const listener of listeners) {
            if (!listener.target_action) {
                listener (globalState);
            }
            else if (listener.target_action === actionIdentifier) {
                listener (globalState);
            }
        }
    };

    useEffect (() => {
        // console.log("useMemStore : useEffect ", shouldListener, listen_target_action);
        if (shouldListener) {
            listeners.push ( {
                savedSetState : setState,
                target_action: listen_target_action
            });
        }

        return () => {
            if (shouldListener) {
                listeners = listeners.filter(li => li.savedSetState !== setState);
            }
        };
    }, [setState, dispatch]);

    return [globalState, dispatch];
};

export const initMemStore = (userActions, initialState) => {
    if (initialState) {
        globalState = { ...globalState, ...initialState };
    }
    actions = { ...actions, ...userActions };
};