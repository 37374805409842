import classes from "./MyPageApp.module.css";
import Button from "../../components/UI/Button/Button";
import Input, { MultiInput } from "../../components/UI/Input/Input";
import { useEffect, useState, useContext } from "react";
import Checkbox from "../../components/UI/Input/Checkbox";
import Modal from "../../components/UI/Modal/Modal";
import Radio from "../../components/UI/Input/Radio";
import { ReqCheckPassword } from "../../api/auth/AuthApi";
import { defaultEmailHostList, phoneNumberDefaultBox } from "../../components/definedefault/DefSelectBoxValues";
import SelectBoxNew from "../../components/UI/SelectBox/SelectBoxNew";
import DaumPostcode from "react-daum-postcode";
import instance from "../../store/AxiosInterceptor";
import { useLoaderData, useNavigate } from "react-router";
import {
    ReqChangeInfoDetail,
    ReqDeleteMember,
    ReqModifyMemberInfo,
    ReqModifyPersonalInfo,
} from "../../api/mypage/UserMyPageApi";
import Alert from "../../components/UI/Modal/Alert";
import { JwtTokenContext } from "../../store/JwtTokenProvider";
import axios from "axios";
import { useMemStore } from "../../store/memstore/MemStore";

function MyInformationAppPage() {
    const setMemStore = useMemStore(false)[1];
    const protocol = document.location.protocol;

    const resData = useLoaderData();
    const loginCtx = useContext(JwtTokenContext);
    const token = loginCtx.getToken();
    const jti = loginCtx.decodedToken ? loginCtx.decodedToken.jti : "";

    const [viewMode, setViewMode] = useState(0);
    const [memberId, setMemberId] = useState(resData.memberId ? resData.memberId : jti);
    const [gender, setGender] = useState(resData.gender);
    const [memberName, setMemberName] = useState(resData.name);
    const [birth, setBirth] = useState(resData.birth);

    const navigate = useNavigate();
    const navigateHandler = () => {
        navigate("/myPage/modify");
    };

    const [isEditBirth, setIsEditBirth] = useState(false);
    const [isEditName, setIsEditName] = useState(false);
    const [isEditGender, setIsEditGender] = useState(false);

    const isEditBirthHandler = () => {
        setIsEditBirth((prev) => !prev);
    };

    const isEditNameHandler = () => {
        setIsEditName((prev) => !prev);
    };

    const isEditGenderHandler = () => {
        setIsEditGender((prev) => !prev);
    };

    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: 81 }, (_, index) => ({
        key: String(currentYear - index),
        value: String(currentYear - index),
        disabled: isEditBirth === false,
    }));

    const months = Array.from({ length: 12 }, (_, index) => ({
        key: (index + 1).toString().padStart(2, "0"),
        value: (index + 1).toString().padStart(2, "0"),
        disabled: isEditBirth === false,
    }));

    const days = Array.from({ length: 31 }, (_, index) => ({
        key: (index + 1).toString().padStart(2, "0"),
        value: (index + 1).toString().padStart(2, "0"),
        disabled: isEditBirth === false,
    }));

    const [selectedYear, setSelectYear] = useState(resData.birth ? resData?.birth.split("-")[0] : "");
    const [selectedMonth, setSelectedMonth] = useState(resData.birth ? resData?.birth.split("-")[1] : "");
    const [selectedDate, setSelectedDate] = useState(resData.birth ? resData?.birth.split("-")[2] : "");

    const selectedYearHandler = (key) => {
        setSelectYear(key);
    };
    const selectedMonthHandler = (key) => {
        setSelectedMonth(key);
    };
    const selectedDateHandler = (key) => {
        setSelectedDate(key);
    };

    //회원 정보 수정 시 벨리드 체크용
    const [validCheck, setValidCheck] = useState(false);

    // viewMode : 0 - 리스트, 1- 상세
    const handleModeChange = (mode) => {
        setViewMode(mode);
        window.scrollTo(0, 0);
    };

    const atIndex = resData.email ? resData.email.indexOf("@") : -1;

    const [emailId, setEmailId] = useState(resData.email ? resData.email.slice(0, atIndex) : "");
    const [emailHost, setEmailHost] = useState(resData.email ? resData.email.slice(atIndex + 1) : "");
    const emailIdHandler = (value) => {
        setEmailId(value);
    }
    const emailHostHandler = (value) => {
        setEmailHost(value);
    };

    const [memberPhone, setMemberPhone] = useState(resData.cell);

    const [input, setInput] = useState({
        password: "",
        passwordCheck: "",
        quitPassword: "",
        quitReason: "",
        modifyReason: "",
    });

    const { password, passwordCheck, quitPassword, quitReason, modifyReason } = input;

    const onChangeInput = (e) => {
        const { name, value } = e.target;
        setInput({
            ...input,
            [name]: value,
        });
    };

    // >>> PC 배너 이미지 미리보기 및 업로드 세팅
    // (파일선택 버튼 클릭 후 이미지 업로드 시, 수정 버튼 클릭X -> 수정 안됨)
    const [selectedFile, setSelectedFile] = useState();
    const [newFileUri, setNewFileUri] = useState();

    useEffect(() => {
        if (!selectedFile) {
            setNewFileUri(null);
            return;
        }

        const objectUrl = URL.createObjectURL(selectedFile);
        setNewFileUri(objectUrl);

        return () => URL.revokeObjectURL(objectUrl);
    }, [selectedFile]);

    const onSelectFile = (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            setSelectedFile(undefined);
            return;
        }

        setSelectedFile(e.target.files[0]);
    };
    //<<< PC 배너 이미지 미리보기

    const [checkBox, setCheckBox] = useState({
        smsYn: resData.accept_sms,
        emailYn: resData.accept_mail,
    });

    const { smsYn, emailYn } = checkBox;

    const onChangeCheckBox = (e) => {
        const { id, value } = e.target;
        let newValue = null;
        if (value === "N") {
            newValue = "Y";
        }
        if (value === "Y") {
            newValue = "N";
        }
        setCheckBox({
            ...checkBox,
            [id]: newValue,
        });
    };

    const [newName, setNewName] = useState(resData.name);
    const newNameHandler = (value) => {
        setNewName(value);
    };

    const [newGender, setNewGender] = useState(resData.gender);
    const newGenderHandler = (e) => {
        setNewGender(e.target.value);
    };

    const [isPasswordCheck, setIsPasswordCheck] = useState(false);

    const emailDomainRegex = /^[a-zA-Z0-9.!@#$%^&*()_+{}\[\]:;<>,.?~=-]*$/;
    const numberRegex = /^\d+$/;
    const passwordRegex = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{6,20}$/;

    const passwordRegexCheck = passwordRegex.test(password);
    const emailDomainRegexCheck = emailDomainRegex.test(emailHost);

    // modal
    const [isModalOpen1, setIsModalOpen1] = useState(false);
    const [isModalOpen2, setIsModalOpen2] = useState(false);

    const openModalHandler1 = (e) => {
        e.stopPropagation();
        setIsModalOpen1(true);
    };

    const [closeModal, setCloseModal] = useState(false);

    const closeModalHandler1 = () => {
        setNewGender(resData.gender);
        setNewName(resData.name);

        setIsEditGender(false);
        setIsEditName(false);
        setIsEditBirth(false);

        setSelectYear(resData?.birth.split("-")[0]);
        setSelectedMonth(resData?.birth.split("-")[1]);
        setSelectedDate(resData?.birth.split("-")[2]);

        setIsModalOpen1(false);
    };

    const openModalHandler2 = (e) => {
        const dummy1 = {
            target: {
                name: "quitReason",
                value: "",
            },
        };
        onChangeInput(dummy1);
        e.stopPropagation();
        setIsModalOpen2(true);
    };

    const closeModalHandler2 = (e) => {
        const dummy2 = {
            target: {
                name: "quitPassword",
                value: "",
            },
        };
        onChangeInput(dummy2);
        e.stopPropagation();
        setIsModalOpen2(false);
    };

    const [loginPassword, setLoginPassword] = useState("");
    const loginPasswordHandler = (value) => {
        setLoginPassword(value);
    };

    const [passwordFail, setPasswordFail] = useState(false);
    const passwordCheckHandler = () => {
        ReqCheckPassword(
            loginPassword,
            (result) => {
                //비밀번호 인증을 성공했을경우 200
                if (result.status === 200) {
                    handleModeChange(1);
                } else {
                    handleModeChange(0);
                    setPasswordFail(true);
                }
            },
            (error_result) => {
                //비밀번호를 틀리면 서버에서 BAD_REQUEST가 내려와서
                //에러로 처리된다.
                handleModeChange(0);
                setPasswordFail(true);
            }
        );
    };

    // [START] 다음 주소 API =================================================================
    const [isDaumPostcodeOpen, setIsDaumPostcodeOpen] = useState(false);
    const [zipCode, setZipCode] = useState(resData.zip); //우편번호
    const [selectedAddress, setSelectedAddress] = useState(resData.address); // 선택한 주소를 저장할 상태
    const [enteredAddress, setEnteredAddress] = useState(resData.address_sub); // 입력한 주소를 저장할 상태

    const openDaumPostcodeHandler = (e) => {
        e.stopPropagation();
        setIsDaumPostcodeOpen(true);
    };

    const handleAddressSelect = (data) => {
        const fullAddress = data.address; // 전체 주소
        const extraAddress = data.addressType === "R" ? "" : data.bname; // 도로명일 경우 법정동 추가
        const address = `${fullAddress} ${extraAddress}`; // 최종 주소
        setZipCode(data.zonecode);
        setSelectedAddress(address); // 주소를 선택하면 상태 업데이트
        setIsDaumPostcodeOpen(false); // 주소를 선택하면 모달 창 닫기
    };

    const closeDaumPostcodeHandler = () => {
        setIsDaumPostcodeOpen(false);
    };

    const emailHostList = defaultEmailHostList;

    const phoneNumberDatas = phoneNumberDefaultBox;

    const [selectedPhoneNumber, setSelectedPhoneNumber] = useState(resData.phone?.split("-")[0]);
    const selectedPhoneNumberHandler = (key) => {
        setSelectedPhoneNumber(key);
    };
    const addressIsNotValid = zipCode?.trim() === "" || selectedAddress?.trim() === "" || enteredAddress?.trim() === "";

    const topHandler = () => {
        window.scrollTo({ top: 0, behavior: "smooth" }); //상단 smooth 이동
    };

    //회원 정보 변경 서버전송값
    const memberInfoObject = {
        member_id: memberId,
        phone: memberPhone,
        cell: memberPhone,
        email: emailId + "@" + emailHost,
        accept_sms: smsYn,
        accept_mail: emailYn,
        address: selectedAddress,
        address_sub: enteredAddress,
        zip_code: zipCode,
        password: password,
    };

    //>>>기본 회원 정보(비밀번호, 핸드폰번호, 주소, 이메일) 수정 핸들러
    const modifyHandler = () => {
        setValidCheck(true);
        topHandler();

        if (password?.trim() !== passwordCheck?.trim()) {
            alert("입력하신 비밀번호와 동일하지 않습니다.");
            return;
        }

        if (zipCode.trim() === "") {
            return;
        }

        if (selectedAddress.trim() === "") {
            return;
        }

        if (enteredAddress.trim() === "") {
            return;
        }

        if (!emailDomainRegexCheck) {
            return;
        }

        if (addressIsNotValid) {
            return;
        }

        if (emailId?.trim() === "") {
            return;
        }

        ReqModifyMemberInfo(
            memberInfoObject,
            (result) => {
                if (result.status === 200) {
                    setValidCheck(false);
                    alert("수정이 완료되었습니다.");
                    navigate("/myPage/modify");
                }
            },
            (error_result) => {
                setValidCheck(false);
                alert("서버 통신중 에러가 발생했습니다.");
            }
        );
    };

    //이름, 생년월일, 성별을 담기 위한 Object
    const personalInfoObject = {
        name: isEditName ? newName : "",
        birth: isEditBirth ? `${String(selectedYear)}${String(selectedMonth)}${String(selectedDate)}` : "",
        gender: isEditGender ? newGender : "",
        reason: modifyReason,
        request_id: memberId,
        request_name: resData.name,
        phone: memberPhone,
        cell: memberPhone,
    };

    //회원 정보 변경 시 벨리드 체크용(이름, 생년월일, 성별)
    const [modifyValidCheck, setModifyValidCheck] = useState(false);
    const [beforePersonalModal, setBeforePersonalModal] = useState(false);
    const [personalModal, setPersonalModal] = useState(false);
    // 이름, 성별, 생년월일 변경 접수 핸들러
    // ADMIN에게 허가를 받아야한다.

    const applyModifyHandler = () => {
        setModifyValidCheck(false);

        const jsonData = JSON.stringify(personalInfoObject);

        const formData = new FormData();

        if (selectedFile) {
            formData.append("image", selectedFile);
        }

        formData.append("body", new Blob([jsonData], { type: "application/json" }));

        ReqModifyPersonalInfo(
            formData,
            (result) => {
                if (result.status === 200) {
                    setModifyValidCheck(false);
                    setPersonalModal(true);
                }
            },
            (error_result) => {
                alert("회원 변경 신청 실패");
                setModifyValidCheck(false);
            }
        );
    };

    const [errorMoal, setErrorModal] = useState(false);
    const [errorText, setErrorText] = useState("");
    const errorModalHandler = (state, text) => {
        setErrorModal(state);
        setErrorText(text);
    };

    const modifyValidationHandler = () => {
        if (!isEditName && !isEditBirth && !isEditGender) {
            errorModalHandler(true, "변경 사항이 없습니다.");
            return;
        }

        setModifyValidCheck(true);

        if (modifyReason?.trim() === "") {
            return;
        }

        if (isEditName && resData.name === newName) {
            errorModalHandler(true, "이름의 변경 사항이 없습니다.");
            return;
        }

        if (isEditName && newName?.trim() === "") {
            errorModalHandler(true, "이름을 확인해주세요.");
            return;
        }

        if (isEditGender && resData.gender === newGender) {
            errorModalHandler(true, "성별의 변경 사항이 없습니다.");
            return;
        }

        if (isEditGender && newGender?.trim() === "") {
            return;
        }

        if (
            isEditBirth &&
            (selectedYear?.trim() === "" || selectedMonth?.trim() === "" || selectedDate?.trim() === "")
        ) {
            return;
        }

        if (
            isEditBirth &&
            resData.birth === `${String(selectedYear)}-${String(selectedMonth)}-${String(selectedDate)}`
        ) {
            errorModalHandler(true, "생년월일의 변경 사항이 없습니다.");
            return;
        }

        if (!selectedFile) {
            errorModalHandler(true, "신분증 이미지를 첨부해주세요.");
            return;
        }

        setBeforePersonalModal(true);
    };

    const locationHandler = () => {
        window.location.href = "/myPage/modify";
    };

    const [quiteSuccess, setQuitSuccess] = useState(false);
    const quiteSuccessHandler = () => {
        navigate("/");
        loginCtx.removeToken();
    };

    const quitObject = {
        member_id: memberId,
        password: quitPassword,
        reason: quitReason,
    };

    const quitHandler = () => {
        if (quitPassword?.trim() === "") {
            alert("비밀번호를 입력해주세요.");
            return;
        }

        if (quitReason?.trim() === "") {
            alert("탈퇴 사유를 입력해주세요.");
            return;
        }
        ReqDeleteMember(
            quitObject,
            (result) => {
                if (result.status_error === 200) {
                    setQuitSuccess(true);
                    return;
                } else if (result.status_error === 1010) {
                    alert("비밀번호를 다시 확인해주세요.");
                    return;
                } else {
                    alert("서버에러. 회원탈퇴에 실패하셨습니다. 잠시후 다시 시도해주세요.");
                    return;
                }
            },
            (error_result) => {
                alert("서버에러. 회원탈퇴에 실패하셨습니다. 잠시후 다시 시도해주세요.");
            }
        );
    };

    const noDataLoader = async () => {
        const response = await instance({
            method: "GET",
            url: `/api/v1/home/mypage/memberInfo`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        }).catch(function (error) {
            if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
                return error.response;
            } else if (error.request) {
                console.log(error.request);
                return null;
            } else {
                console.log("Error", error.message);
                return null;
            }
        });
        if (response == null || response.status !== 200) {
            return [];
        }
        if (response.data.status === 200) {
            return response.data.data;
        } else {
            return [];
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            if (!resData.length) {
                const response = await noDataLoader();
                const atIndex = response.email ? response.email.indexOf("@") : -1;

                setGender(response.gender);
                setMemberName(response.name);
                setBirth(response.birth);
                setZipCode(response.zip);
                setSelectedAddress(response.address);
                setEnteredAddress(response.address_sub);
                setSelectYear(response?.birth.split("-")[0]);
                setSelectedMonth(response?.birth.split("-")[1]);
                setSelectedDate(response?.birth.split("-")[2]);
                setEmailId(response?.email.slice(0, atIndex));
                setEmailHost(response?.email.slice(atIndex + 1));
            }
        };

        fetchData();
        findChangeInfoDetail();
    }, []);

    const [changeInfoDetail, setChangeInfoDetail] = useState(null);
    const findChangeInfoDetail = () => {
        ReqChangeInfoDetail(
            (result) => {
                if (result.status === 200) {
                    const resData = {
                        changeInfoId: result.change_info_id,
                        memberId: result.member_id,
                        processStatus: result.process_status,
                        reqDate: result.req_date,
                    };
                    setChangeInfoDetail(resData);
                }
            },
            (error_result) => {}
        );
    };

    const [sEncData, setSEncData] = useState("");
    const fnPopup = async () => {
        window.open(
            "",
            "popupChk",
            "width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no"
        );

        window.parentCallback = (gender, name, year, month, day, cell) => {
            setMemberPhone(cell);
        };

        const response = await axios({
            url: `/api/v1/home/nice/myPage?protocol=${protocol}`,
            method: "GET",
        });

        const resData = await response.data;
        const sEncData = await resData.encData;
        setSEncData(sEncData);

        const form = document.createElement("form");
        form.method = "post";
        form.action = "https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb";
        form.target = "popupChk";

        const inputM = document.createElement("input");
        inputM.type = "hidden";
        inputM.name = "m";
        inputM.value = "checkplusService";
        form.appendChild(inputM);

        const inputEncodeData = document.createElement("input");
        inputEncodeData.type = "hidden";
        inputEncodeData.name = "EncodeData";
        inputEncodeData.value = sEncData;
        form.appendChild(inputEncodeData);

        document.body.appendChild(form);
        form.submit();
    };

    useEffect(() => {
        if (token == null) {
            setMemStore("LOGIN_MOVE_PAGE", { uri: "/myPage/modify" });
            navigate("/login");
        }
    }, []);

    return (
        <div className={classes.contents_wrap}>
            {viewMode === 0 && (
                <>
                    <div className={classes.contents_title}>
                        <h3>비밀번호 재확인</h3>
                        {/* 모바일에서 info로 변경 */}
                        <p className={classes.info}>개인정보 보호와 본인확인을 위해 한번 더 비밀번호를 입력해주세요.</p>
                    </div>
                    <div className={classes.password_wrap}>
                        <p>아이디</p>
                        {/* 모바일에서 admin_st 클래스 추가 */}
                        <Input className="admin_st" value={memberId} disabled />
                        <p>비밀번호</p>
                        <Input
                            className="admin_st"
                            type="password"
                            onChange={loginPasswordHandler}
                            value={loginPassword}
                        />
                        {loginPassword?.trim() === "" && <p className="require_red f_14">비밀번호를 입력해주세요.</p>}
                        {loginPassword?.trim() !== "" && passwordFail && (
                            <p className="require_red f_14">비밀번호를 다시 확인해주세요.</p>
                        )}
                        <Button className="secondary" onClick={passwordCheckHandler}>
                            확인
                        </Button>
                    </div>
                </>
            )}
            {viewMode === 1 && (
                <>
                    <div className={classes.contents_title}>
                        <h3>회원정보수정</h3>
                    </div>
                    <div className={`${classes.detail_table_wrap} ${classes.mobile_type} ${classes.edit_st}`}>
                        <table>
                            <colgroup>
                                <col style={{ width: "15%" }} />
                                <col style={{ width: "85%" }} />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>이름</th>
                                    <td>
                                        <div className={`${classes.input_btn_wrap} ${classes.readOnly_st} `}>
                                            {memberName}
                                            {changeInfoDetail?.changeInfoId == null && (
                                                <Button className="border sm" onClick={openModalHandler1}>
                                                    이름/생년월일/성별 변경신청
                                                </Button>
                                            )}
                                            {changeInfoDetail?.changeInfoId != null && (
                                                <span className="primary_c">
                                                    ({changeInfoDetail.reqDate} 변경신청완료)
                                                </span>
                                            )}
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>생년월일</th>
                                    <td>
                                        <div className={`${classes.input_btn_wrap} ${classes.readOnly_st} `}>
                                            {birth}
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>성별</th>
                                    <td>
                                        <div className={classes.radio_wrap}>
                                            <Radio
                                                className="official_st"
                                                name="gender"
                                                id="rad_01_1"
                                                value="M"
                                                title="남"
                                                checked={gender === "M"}
                                                disabled={true}
                                            />
                                            <Radio
                                                className="official_st"
                                                name="gender"
                                                id="rad_01_2"
                                                value="F"
                                                title="여"
                                                checked={gender === "F"}
                                                disabled={true}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>비밀번호</th>
                                    <td>
                                        {/* 모바일에서 구조 변경 및 클래스 변경 */}
                                        <MultiInput
                                            className=" admin_st"
                                            value={password}
                                            name="password"
                                            onChange={onChangeInput}
                                            type="password"
                                        />
                                        {
                                            <p className="require_red f_14">
                                                영 대소문자, 숫자, 특수문자를 포함하여 6~20자 이내 (패스워드 변경을 원치
                                                않으시면 빈칸으로 남겨 주세요)
                                            </p>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <th>비밀번호 확인</th>
                                    <td>
                                        <MultiInput
                                            className=" admin_st"
                                            value={passwordCheck}
                                            name="passwordCheck"
                                            onChange={onChangeInput}
                                            type="password"
                                        />
                                        {validCheck && password !== "" && passwordCheck?.trim() === "" && (
                                            <p className="require_red f_14">비밀번호를 다시 한번 입력해주세요.</p>
                                        )}
                                        {passwordRegexCheck &&
                                            isPasswordCheck &&
                                            password?.trim() !== passwordCheck?.trim() &&
                                            passwordCheck?.trim() !== "" && (
                                                <p className="require_red f_14">
                                                    입력하신 비밀번호와 동일하지 않습니다.
                                                </p>
                                            )}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        주소<span className="red_c">*</span>
                                    </th>
                                    <td>
                                        <div className={classes.address_wrap}>
                                            <div className={classes.input_btn_wrap}>
                                                <Input
                                                    className="admin_st_sm"
                                                    value={zipCode}
                                                    id="zipCode"
                                                    onChange={setZipCode}
                                                />
                                                <Button className="border sm" onClick={openDaumPostcodeHandler}>
                                                    우편번호 검색
                                                </Button>
                                            </div>
                                            {validCheck && addressIsNotValid && (
                                                <p className="require_red f_14">주소를 입력해주세요.</p>
                                            )}
                                            {/* mobile 구조변경 */}
                                            <Input
                                                className="h45 w_100"
                                                value={selectedAddress}
                                                id="address"
                                                onChange={setSelectedAddress}
                                            />
                                            <Input
                                                className="h45 w_100"
                                                value={enteredAddress}
                                                id="detailAddress"
                                                onChange={setEnteredAddress}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        휴대폰
                                        <span className="red_c">*</span>
                                    </th>
                                    <td>
                                        {/* 모바일에서 구조 변경 */}
                                        <div className={classes.phone_agree_wrap}>
                                            <div className={classes.input_btn_wrap}>
                                                <Input className="admin_st_sm sm" value={memberPhone} />
                                                <Button className="border sm" onClick={fnPopup}>
                                                    휴대폰 본인인증
                                                </Button>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>이메일</th>
                                    <td>
                                        <div className={classes.email_wrap}>
                                            <Input
                                                className="admin_st_sm"
                                                value={emailId}
                                                name="emailId"
                                                onChange={emailIdHandler}
                                            />
                                            <span>@</span>
                                            <Input
                                                className="admin_st_sm"
                                                value={emailHost}
                                                name="emailHost"
                                                onChange={emailHostHandler}
                                            />
                                            <SelectBoxNew
                                                className="default "
                                                optionDatas={emailHostList}
                                                setSelectItemCB={emailHostHandler}
                                                showDefaultKey={emailHost}
                                            />
                                        </div>
                                        {validCheck && (emailId?.trim() === "" || emailHost?.trim() === "") && (
                                            <p className={"require_red f_14"}>이메일을 입력해주세요.</p>
                                        )}
                                        {emailId?.trim() !== "" && !emailDomainRegexCheck && (
                                            <p className={"require_red f_14"}>올바른 이메일 주소를 입력해주세요.</p>
                                        )}
                                        <div className={classes.checkbox_wrap}>
                                            <Checkbox
                                                className="official_st"
                                                value={smsYn}
                                                title="SMS"
                                                id="smsYn"
                                                onChange={onChangeCheckBox}
                                                checked={smsYn === "Y"}
                                            />
                                            <Checkbox
                                                className="official_st"
                                                value={emailYn}
                                                title="이메일"
                                                id="emailYn"
                                                onChange={onChangeCheckBox}
                                                checked={emailYn === "Y"}
                                            />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className={classes.btn_bottom_wrap}>
                            {/* 모바일에서 div.right 삭제 */}
                            <Button className="border" onClick={openModalHandler2}>
                                회원탈퇴
                            </Button>
                            <Button className="secondary" onClick={modifyHandler}>
                                정보수정
                            </Button>
                        </div>
                    </div>
                    {isDaumPostcodeOpen && (
                        <Modal close={closeDaumPostcodeHandler} header="우편번호 검색">
                            <DaumPostcode onComplete={handleAddressSelect} />
                        </Modal>
                    )}
                </>
            )}
            {isModalOpen1 && (
                <Modal close={closeModalHandler1} header="회원정보 변경신청">
                    <main>
                        <div className={`${classes.pop_mobile_table_wrap}`}>
                            <table>
                                <colgroup>
                                    <col style={{ width: "28%" }} />
                                    <col style={{ width: "72%" }} />
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <th>
                                            변경항목
                                            <span className="red_c">*</span> (중복가능)
                                        </th>
                                        <td>
                                            <div className={classes.radio_wrap}>
                                                <Radio
                                                    className="official_st"
                                                    id="isEditName"
                                                    checked={isEditName === true}
                                                    title="이름"
                                                    onClick={isEditNameHandler}
                                                />
                                                <Radio
                                                    className="official_st"
                                                    id="isEditGender"
                                                    checked={isEditGender === true}
                                                    title="성별"
                                                    onClick={isEditGenderHandler}
                                                />
                                                <Radio
                                                    className="official_st"
                                                    id="isEditBirth"
                                                    checked={isEditBirth === true}
                                                    title="생년월일"
                                                    onClick={isEditBirthHandler}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>이름</th>
                                        <td>
                                            <Input
                                                className="w_100"
                                                value={newName}
                                                name="newName"
                                                onChange={newNameHandler}
                                                readOnly={isEditName === false}
                                            />
                                            {modifyValidCheck && isEditName === "Y" && newName?.trim() === "" && (
                                                <p className={"require_red f_14"}>이름을 입력해주세요.</p>
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>성별</th>
                                        <td>
                                            <div className={classes.radio_wrap}>
                                                <Radio
                                                    className="official_st"
                                                    name="newGender"
                                                    id="rad_02_1"
                                                    value="M"
                                                    title="남"
                                                    onChange={newGenderHandler}
                                                    checked={newGender === "M"}
                                                    disabled={isEditGender === false}
                                                />
                                                <Radio
                                                    className="official_st"
                                                    name="newGender"
                                                    id="rad_02_2"
                                                    value="F"
                                                    title="여"
                                                    onChange={newGenderHandler}
                                                    checked={newGender === "F"}
                                                    disabled={isEditGender === false}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>생년월일</th>
                                        <td>
                                            <div className={classes.birth_wrap}>
                                                <SelectBoxNew
                                                    className="sm"
                                                    optionDatas={years}
                                                    showDefaultKey={selectedYear}
                                                    setSelectItemCB={selectedYearHandler}
                                                    name="registerBirthYear"
                                                />
                                                <span>년</span>
                                                <SelectBoxNew
                                                    className="sm"
                                                    optionDatas={months}
                                                    showDefaultKey={selectedMonth}
                                                    setSelectItemCB={selectedMonthHandler}
                                                    name="registerBirthMonth"
                                                />
                                                <span>월</span>
                                                <SelectBoxNew
                                                    className="sm"
                                                    optionDatas={days}
                                                    showDefaultKey={selectedDate}
                                                    setSelectItemCB={selectedDateHandler}
                                                    name="registerBirthDate"
                                                />
                                                <span>일</span>
                                            </div>
                                            {modifyValidCheck &&
                                                isEditBirth === "Y" &&
                                                (selectedYear?.trim() === "" ||
                                                    selectedMonth?.trim() === "" ||
                                                    selectedDate?.trim() === "") && (
                                                    <p className={"require_red f_14"}>생년 월일을 입력해주세요.</p>
                                                )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            변경사유
                                            <span className="red_c">*</span>
                                        </th>
                                        <td>
                                            <MultiInput
                                                value={modifyReason}
                                                name="modifyReason"
                                                onChange={onChangeInput}
                                                placeholder="예) 개명"
                                            />
                                            {modifyValidCheck && modifyReason?.trim() === "" && (
                                                <p className={"require_red f_14"}>변경 사유를 입력해주세요.</p>
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            증빙자료
                                            <span className="red_c">*</span>
                                        </th>
                                        <td>
                                            <ul className={classes.desc_list}>
                                                <li>이름을 변경하실 경우 증빙자료 첨부는 필수입니다.</li>
                                                <li>
                                                    본인확인(이름/생년월일/성별)을 위해 주민등록증 또는 운전면허증을
                                                    첨부해주세요.
                                                </li>
                                                <li>확인이 필요한 개인정보 외의 정보는 가려서 찍어주세요.</li>
                                            </ul>
                                            <div className={classes.flex_row}>
                                                <div className={classes.img_file_box}>
                                                    {!newFileUri && (
                                                        <div className="input_file">
                                                            <input
                                                                type="file"
                                                                id="file"
                                                                accept="image/png, image/jpeg, image/gif"
                                                                onChange={onSelectFile}
                                                            />
                                                            <label htmlFor="file">파일선택</label>
                                                        </div>
                                                    )}
                                                </div>
                                                {modifyValidCheck && !newFileUri && (
                                                    <p className="require_red f_14">신분증 이미지를 첨부해주세요.</p>
                                                )}
                                            </div>

                                            <div className={classes.thumbnail_box}>
                                                {newFileUri && (
                                                    <img
                                                        src={newFileUri}
                                                        alt="선택한 배너 미리보기"
                                                        style={{
                                                            width: "370px",
                                                            height: "230px",
                                                        }}
                                                    />
                                                )}
                                                {newFileUri && (
                                                    <Button className="border sm" onClick={() => setSelectedFile(null)}>
                                                        파일삭제
                                                    </Button>
                                                )}
                                            </div>
                                            <ul className={`${classes.desc_list} ${classes.gray}`}>
                                                <li>사진은 5MB 미만의 JPG, PNG, GIF 형식으로 업로드 가능합니다.</li>
                                                <li>파일명에 ‘_’이외의 특수문자는 사용이 불가능합니다.</li>
                                            </ul>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </main>
                    <footer>
                        <div className={classes.btn_bottom_wrap}>
                            <Button onClick={() => setCloseModal(true)}>취소</Button>
                            <Button className=" secondary" onClick={modifyValidationHandler}>
                                변경 신청
                            </Button>
                        </div>
                    </footer>
                    {errorMoal && (
                        <Alert type="alert" confirm="확인" close={() => errorModalHandler(false, "")}>
                            {errorText}
                        </Alert>
                    )}
                    {beforePersonalModal && (
                        <Alert
                            type="confirm"
                            cancel="취소"
                            confirm="확인"
                            close={() => setBeforePersonalModal(false)}
                            confirmHandler={applyModifyHandler}
                        >
                            {isEditName === "Y" ? "이름 " : ""}
                            {isEditGender === "Y" ? "성별 " : ""}
                            {isEditBirth === "Y" ? "생년월일 " : ""}
                            {"변경 신청을 하시겠습니까?"}
                        </Alert>
                    )}
                    {personalModal && (
                        <Alert type="alert" confirm="확인" close={locationHandler}>
                            {"변경신청이 완료되었습니다."}
                            {"변경은 영업일 기준 최대 5일 이내"}
                            {"처리됩니다."}
                        </Alert>
                    )}
                    {closeModal && (
                        <Alert
                            type="confirm"
                            cancel="취소"
                            confirm="확인"
                            close={() => setCloseModal(false)}
                            confirmHandler={closeModalHandler1}
                        >
                            {"취소하시겠습니까?"}
                        </Alert>
                    )}
                </Modal>
            )}
            {isModalOpen2 && (
                <Modal close={closeModalHandler2} header="회원탈퇴" className="modal_body_scroll">
                    <main>
                        <p className={classes.info_title}>
                            그동안 서비스를 이용해 주셔서 감사합니다.
                            <br />
                            탈퇴신청에 앞서 아래 안내 사항을 반드시 확인하시고 신중하게 결정해주세요.
                        </p>
                        <div className={`${classes.pop_mobile_table_wrap}`}>
                            <table>
                                <colgroup>
                                    <col style={{ width: "28%" }} />
                                    <col style={{ width: "72%" }} />
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <th>이름</th>
                                        <td>{resData.name}</td>
                                    </tr>
                                    <tr>
                                        <th>아이디</th>
                                        <td>{memberId}</td>
                                    </tr>

                                    <tr>
                                        <th>
                                            비밀번호
                                            <span className="red_c">*</span>
                                        </th>
                                        <td>
                                            <MultiInput
                                                value={quitPassword}
                                                name="quitPassword"
                                                onChange={onChangeInput}
                                                type="password"
                                            />
                                            {quitPassword?.trim() === "" && (
                                                <p className="require_red f_14">비밀번호를 입력해주세요.</p>
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            탈퇴사유
                                            <span className="red_c">*</span>
                                        </th>
                                        <td>
                                            <textarea
                                                value={quitReason}
                                                name="quitReason"
                                                onChange={onChangeInput}
                                                cols="30"
                                                rows="3"
                                            ></textarea>
                                            {quitReason?.trim() === "" && (
                                                <p className="require_red f_14">탈퇴 사유를 입력해주세요.</p>
                                            )}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </main>
                    <footer>
                        <div className={classes.btn_bottom_wrap}>
                            <Button onClick={closeModalHandler2}>취소</Button>
                            <Button className="secondary" onClick={quitHandler}>
                                확인
                            </Button>
                        </div>
                    </footer>
                    {quiteSuccess && (
                        <Alert type="alert" confirm="확인" close={quiteSuccessHandler}>
                            {"탈퇴처리 되었습니다."}
                            {"그동안 이용해 주셔서 감사합니다."}
                        </Alert>
                    )}
                    <ul className={`${classes.desc_list} ${classes.footer_st}`}>
                        <b>[회원 탈퇴 안내 사항]</b>
                        <li>
                            회원 탈퇴하시면 대회 접수 및 참여 이력, 쇼핑몰의 적립금과 구매내역 및 기타 이력은 영구
                            삭제되며 복구되지 않습니다.
                        </li>
                        <li>회원 탈퇴한 아이디로는 재가입이 불가능합니다.</li>
                    </ul>
                </Modal>
            )}
        </div>
    );
}

export default MyInformationAppPage;

//해당 정보 가져오기
export const loader = async () => {
    const response = await instance({
        method: "GET",
        url: `/api/v1/home/mypage/memberInfo`,
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    }).catch(function (error) {
        if (error.response) {
            // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            return error.response;
        } else if (error.request) {
            // 요청이 이루어 졌으나 응답을 받지 못했습니다.
            console.log(error.request);
            return null;
        } else {
            // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
            console.log("Error", error.message);
            return null;
        }
    });
    if (response == null || response.status !== 200) {
        return [];
    }
    if (response.data.status === 200) {
        return response.data.data;
    } else {
        return [];
    }
};
//해당 정보 가져오기
