import { Outlet } from "react-router-dom";
import classes from "./MyPage.module.css";
import MyPageNavigation from "./MyPageNavigation";
import MyPageSubHeader from "./MyPageSubHeader";

function MyPageRootLayout() {
    return (
        <div className={`container ${classes.my_page_container}`}>
            <MyPageNavigation />
            <section>
                <div className={classes.mypage_contents}>
                    <MyPageSubHeader />
                    <Outlet />
                </div>
            </section>
        </div>
    );
}

export default MyPageRootLayout;
