import React, { useState } from "react";
import Alert from "../UI/Modal/Alert";
import { useNavigate } from "react-router-dom";

const NiceError = () => {
    const navigate = useNavigate();
    const [showCancelModal, setShowCancelModal] = useState(true);

    const modalHandler = (state) => {
        setShowCancelModal(state);
        navigate("/signUp");
        window.close();
    };

    return (
        <>
            {showCancelModal && (
                <Alert type="alert" confirm="확인" close={() => modalHandler(false)}>
                    {"휴대폰 본인 인증 중 오류 또는 인증에 실패하셨습니다."}
                </Alert>
            )}
        </>
    );
};

export default NiceError;
