import Button from "../../../../components/UI/Button/Button";
import classes from "../../MngCommon.module.css";

import sample_img_3 from "../../../../assets/images/staff_4.png";
import Radio from "../../../../components/UI/Input/Radio";
import { ReqStaffDelete, ReqStaffDetail } from "../../../../api/company/ApiForStaff";
import { useLoaderData, useNavigate } from "react-router";
import Moment from 'moment';
import { staffDataType } from "../../../../components/definedefault/DefSelectBoxValues";
import { JwtTokenContext } from "../../../../store/JwtTokenProvider";
import { useContext } from "react";

function MngStaffDetail(props) {

    const loginCtx = useContext(JwtTokenContext);

    const staff = useLoaderData();

    const navigate = useNavigate();

    const backHandler = () => {
        navigate("/admin/company/staff");
    };
    
    const deleteHandler = async (e) => {
        e.preventDefault();
        
        const isTrue = window.confirm('STAFF를 삭제하시겠습니까?');
        if(isTrue) {
            try {
                const response = await ReqStaffDelete(staff.id);
                if (response.status === 200) {
                    alert("STAFF가 삭제되었습니다.");
                    backHandler();
                } else {
                    alert("STAFF 삭제 실패");
                }
            } catch (error) {
                console.error("Error:", error);
            }
        } else {
          return
        }
    };

    const updateHandler = () => {
        navigate(`../update/${staff.id}`);
    };

    const setStaffCheck = (type) => {
        const types = staffDataType.find((items) => items.key === type);
        return types === undefined ? "" : types.value;
    }

    return (
        <section>
            <div className={classes.admin_contents}>
                <h3>STAFF - 상세</h3>
                <div
                    className={`${classes.contents_wrap} ${classes.search_wrap} `}
                >
                    <div className={classes.admin_table_wrap}>
                        <table>
                            <colgroup>
                                <col style={{ width: "10%" }} />
                                <col style={{ width: "40%" }} />
                                <col style={{ width: "10%" }} />
                                <col style={{ width: "40%" }} />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>등록일</th>
                                    <td>{Moment(staff.reg_date).format('YYYY-MM-DD')}</td>
                                    <th>등록자</th>
                                    <td>{loginCtx.decodedToken.jti}</td>
                                </tr>
                                <tr>
                                    <th>노출여부</th>
                                    <td>{staff.expose_yn ? "노출" : "미노출"}</td>
                                    <th>구분</th>
                                    <td>{setStaffCheck(staff.staff_type)}</td>
                                </tr>
                                <tr>
                                    <th>이름</th>
                                    <td>{staff.staff_name}</td>
                                    <th>소속팀명</th>
                                    <td>{staff.team}</td>
                                </tr>
                                <tr>
                                    <th>프로기수</th>
                                    <td>{staff.pro_no}</td>
                                    <th>구력</th>
                                    <td>{staff.career}</td>
                                </tr>
                                <tr>
                                    <th>사용손</th>
                                    <td>{staff.use_hand}</td>
                                    <th>포인트랭킹</th>
                                    <td>{staff.point_rank}</td>
                                </tr>
                                <tr>
                                    <th>이미지</th>
                                    <td colSpan={3}>
                                        <div
                                            className={
                                                classes.profile_img_box
                                            }
                                        >
                                            <img
                                                src={staff.thumbnail_url}
                                                alt={staff.original_name}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>경력</th>
                                    <td colSpan={3}>
                                        <div dangerouslySetInnerHTML={{
                                            __html: staff.staff_info,
                                        }} >
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className={classes.bottom_btn_wrap}>
                            <Button className="secondary sm" onClick={updateHandler}>수정</Button>
                            <Button className="sm" onClick={deleteHandler}>삭제</Button>
                            <Button className="border sm" onClick={backHandler} >목록</Button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default MngStaffDetail;

export const loader = async ({ params }) => {
    const response = await ReqStaffDetail(params.staffID);
    
    if (response.status === 200) {
        const resData = await response.data.data;
        return resData;
    } else {
        return [];
    }
};