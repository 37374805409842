// 퍼블단에서 임의로 선택한 라이브러리 입니다.
// 추후 개발 환경에 맞게 변경 부탁드립니다.
// https://react-dropzone.js.org/
// 다른 라이브러리를 이용 하실 경우 그에 맞게 style custom 이 필요 할 수 있으니 말씀해주세요
import React, { useCallback, useState, useRef, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import Button from "../Button/Button";
import classes from "./DropZone.module.css";

function MyDropzoneMj({ onImageUpload, onImageUploadL, images }) {
    const [files, setFiles] = useState([]);

    const fileInputRef = useRef(null);

    const onDrop = useCallback(
        (acceptedFiles) => {
            setFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
            onImageUploadL(acceptedFiles); // Call the callback function with acceptedFiles
        },
        [onImageUploadL]
    );

    const selectFiles = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const onFileInputChange = (event) => {
        const selectedFiles = event.target.files;
        setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
    };

    const removeFile = (e, fileToRemove) => {
        e.stopPropagation();
        setFiles((prevFiles) =>
            prevFiles.filter((file) => file !== fileToRemove)
        );
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        multiple: true, // Enable multiple file selection
        accept: "image/*", // Accept only image files
    });

    const fileItems = files.map((file) => (
        <li key={file.path}>
            <div className={classes.file_item}>
                {file.type.startsWith("image/") && (
                    <img
                        className={classes.preview_image}
                        src={URL.createObjectURL(file)}
                        alt={file.name}
                    />
                )}
                <div className={classes.file_info}>
                    <span>{file.name}</span>
                    <button
                        className={classes.remove_button}
                        onClick={(e) => removeFile(e, file)}
                    >
                        remove
                    </button>
                </div>
            </div>
        </li>
    ));

    const imageItems = images.map((image, index) => (
        <li key={index}>
            <div className={classes.file_item}>
                <img
                    className={classes.preview_image}
                    src={image.uri}
                    alt={image.origin_name}
                />
                <div className={classes.file_info}>
                    <span>{image.origin_name}</span>
                    <button
                        className={classes.remove_button}
                        onClick={(e) => removeFileFromImages(e, index)}
                    >
                        remove
                    </button>
                </div>
            </div>
        </li>
    ));

    const removeFileFromImages = (e, index) => {
        e.stopPropagation();
        // onImageUpload((prevImages) => {
        //     const updatedImages = [...prevImages];
        //     updatedImages.splice(index, 1);
        //     return updatedImages;
        // });

        images.splice(index, 1);
        onImageUpload(images);
        // setFiles((prevFiles) => [...prevFiles]);
    };

    return (
        <div className={classes.drop_zone_wrap}>
            <div {...getRootProps({ className: "dropzone" })}>
                <input {...getInputProps()} accept="image/*" />
                <div className={classes.zone_box}>
                    <p className={classes.text}>파일을 마우스로 끌어오세요</p>
                    <div className={classes.preview_wrap}>
                        <ul>
                            {/* Show the images from the `images` prop */}
                            {imageItems}
                            {/* Show the uploaded files */}
                            {fileItems}
                        </ul>
                    </div>
                    <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        multiple
                        accept="image/*"
                        onChange={onFileInputChange}
                    />
                </div>
            </div>
        </div>
    );
}

export default MyDropzoneMj;
