import React, { useCallback, useState, useRef } from "react";
import { useDropzone } from "react-dropzone";
import classes from "./DropZone.module.css";

function DropZoneForAS({ onImageUpload, onImageUploadL, images }) {
    const [files, setFiles] = useState([]);
    const [disabled, setDisabled] = useState(false);

    const fileInputRef = useRef(null);

    const onDrop = useCallback(
        (acceptedFiles) => {
            const newFiles = [...files, ...acceptedFiles];
            setFiles(newFiles);
            if (newFiles.length >= 5) {
                setDisabled(true);
            } else {
                setDisabled(false);
            }
            onImageUploadL(acceptedFiles);
        },
        [files, onImageUploadL]
    );

    const selectFiles = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const onFileInputChange = (event) => {
        const selectedFiles = event.target.files;
        setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
    };

    const removeFile = (e, fileToRemove) => {
        e.stopPropagation();
        setFiles((prevFiles) => {
            const newFiles = prevFiles.filter((file) => file !== fileToRemove);
            if (newFiles.length < 5) setDisabled(false);
            return newFiles;
        });
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        multiple: true,
        accept: {
            "image/*": [".png", ".gif", ".jpeg", ".jpg"],
        },
        maxFiles: 5 - files?.length,
        maxSize: 5242880, //5MB,
        noDragEventsBubbling: true,
        autoFocus: false,
        disabled: disabled,
    });

    const fileItems = files.map((file) => (
        <li key={file.path}>
            <div className={classes.file_item}>
                {file.type.startsWith("image/") && (
                    <img className={classes.preview_image} src={URL.createObjectURL(file)} alt={file.name} />
                )}
                <div className={classes.file_info}>
                    <span>{file.name}</span>
                    <button className={classes.remove_button} onClick={(e) => removeFile(e, file)}>
                        remove
                    </button>
                </div>
            </div>
        </li>
    ));

    const imageItems = images.map((image, index) => (
        <li key={index}>
            <div className={classes.file_item}>
                <img className={classes.preview_image} src={image.uri} alt={image.origin_name} />
                <div className={classes.file_info}>
                    <span>{image.origin_name}</span>
                    <button className={classes.remove_button} onClick={(e) => removeFileFromImages(e, index)}>
                        remove
                    </button>
                </div>
            </div>
        </li>
    ));

    const removeFileFromImages = (e, index) => {
        e.stopPropagation();

        images.splice(index, 1);
        onImageUpload(images);
    };

    return (
        <div className={classes.drop_zone_wrap}>
            <div {...getRootProps({ className: "dropzone" })}>
                <input {...getInputProps()} accept="image/*" />
                <div className={classes.zone_box}>
                    <p className={classes.text}>파일을 마우스로 끌어오세요</p>
                    <div className={classes.preview_wrap}>
                        <ul>
                            {/* Show the images from the `images` prop */}
                            {imageItems}
                            {/* Show the uploaded files */}
                            {fileItems}
                        </ul>
                    </div>
                    <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        multiple
                        accept="image/*"
                        onChange={onFileInputChange}
                    />
                </div>
            </div>
        </div>
    );
}

export default DropZoneForAS;
