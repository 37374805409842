import { useContext } from "react";
// import { Link } from "react-router-dom";
import { JwtTokenContext } from "../store/JwtTokenProvider";
import AdminHomePage from "./AdminHome";
import UserHomePage from "./UserHome";
import { isMobile } from "react-device-detect";
import UserHomeAppPage from "./UserHomeApp";

function HomePage(props) {
    const tokenContext = useContext(JwtTokenContext);
    // ↓ ↓ admin 화면 퍼브작업 확인을 위하여 주석처리
    // const isAdmin = tokenContext.isAdmin();

    // ↓ ↓ admin 화면 확인을 위하여 상태를 수정하였습니다.
    const isAdmin = false;

    return (
        <>
            {isAdmin ? (
                <AdminHomePage />
            ) : isMobile ? (
                <UserHomeAppPage />
            ) : (
                <UserHomePage />
            )}
        </>
    );
}
export default HomePage;
