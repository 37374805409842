import instance from "../../store/AxiosInterceptor";

/**
 * 유저 결과 목록 페이지, 대회 결과 리스트 리턴
 * @param resolveCB 응답이 성공일 경우 데이터를 넣어 호출하는 callback method
 * @returns
 */
export function ReqResultList(page, searchText, resolveCB, rejectCB) {
    instance({
        url: `/api/v1/home/contest/result?page=${page}&size=5`,
        method: "GET",
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data.datas.content);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/**
 * 유저 결과 목록 페이지, 대회 결과 리스트 리턴
 * @param resolveCB 응답이 성공일 경우 데이터를 넣어 호출하는 callback method
 * @returns
 */
export function ReqResultListWithSearch(page, searchText, resolveCB, rejectCB) {
    instance({
        url: `/api/v1/home/contest/result/search?page=${page}&size=5&searchText=${searchText}`,
        method: "GET",
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data.datas.content);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/**
 * 해당 대회, 해당 경기장의 몇조까지 존재하는지 가져오기 위한 API
 */
export function ReqFindGroupName(contestId, resolveCB, rejectCB) {
    instance({
        url: `/api/v1/home/contest/result/groupName?contestId=${contestId}`,
        method: "GET",
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data.datas);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/**
 * SelectBox에서 경기장 선택 후
 * 해당 경기장 및 해당 그룹에의 대회 결과 리턴
 */
export function ReqFindResultList(
    placeId,
    roundName,
    contestId,
    resolveCB,
    rejectCB
) {
    instance({
        url: `/api/v1/home/contest/result/competitor/list?placeId=${placeId}&roundName=${roundName}&contestID=${contestId}`,
        method: "GET",
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/**
 * InputBox에서 검색어 입력 후 검색 실행
 * 해당 대회의 참가자 이름 또는 아이디로 검색한다.
 * 아이디 또는 이름이 일치해야함(===)
 */
export function ReqFindSearchResultList(
    contestId,
    searchText,
    resolveCB,
    rejectCB
) {
    instance({
        url: `/api/v1/home/contest/result/competitor/search?contestId=${contestId}&text=${searchText}`,
        method: "GET",
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data.datas);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}

/**
 * 해당 대회, 해당 경기장의 라운드 명, 경기명을 가져온다.
 */
export function ReqFindRoundName(placeId, resolveCB, rejectCB) {
    instance({
        url: `/api/v1/home/contest/result/roundName?placeId=${placeId}`,
        method: "GET",
    })
        .then((response) => {
            if (resolveCB) {
                resolveCB(response.data.datas);
            }
        })
        .catch((error) => {
            if (rejectCB) {
                rejectCB([]);
            }
        });
}
