import Moment from "moment";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ReqTermsAgreementList } from "../../../api/site/ApiForTerms";
import Button from "../../../components/UI/Button/Button";
import classes from "../MngCommon.module.css";
import PaginationNew from "../../../components/UI/Table/PaginationNew";
function MngUserTerms() {

    const location = useLocation();
    const navigate = useNavigate();


    const [terms, setTerms] = useState([]);

    const [currentPage, setCurrentPage] = useState(location.state?.currentPage ?? 0);
    const [totalPage, setTotalPage] = useState(0); //총 페이지 갯수
    const [totalCount, setTotalCount] = useState(0);

    const createHandler = () => {
        navigate("./create");
    };

    const detailHandler = (e, id) => {
        e.preventDefault();

        navigate(`./${id}`, { state: location.state });
    };
    const handlePageChange = async (page) => {
        setCurrentPage(page);

        const response = await ReqTermsAgreementList(page);
        if (response.status === 200) {
            const resData = await response.data.datas;
            setTotalPage(resData.totalPages);
            setTotalCount(resData.totalElements);
            setTerms(resData.content);
            // console.log(resData);
        } else {
            setTerms([]);
        }
    };
    useEffect(() => {
        handlePageChange(currentPage);
    }, [])
    return (
        <section>
            <div className={classes.admin_contents}>
                <h3>약관/동의문</h3>
                <div className={classes.contents_wrap}>
                    <div
                        className={`${classes.admin_table_wrap} ${classes.align_center}  ${classes.row_select}`}
                    >
                        <p className={classes.counter}>
                            총 <b>{totalCount}</b>건
                        </p>
                        <table>
                            <colgroup>
                                <col style={{ width: "5%" }} />
                                <col style={{ width: "50%" }} />
                                <col style={{ width: "15%" }} />
                                <col style={{ width: "15%" }} />
                                <col style={{ width: "15%" }} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th>제목</th>
                                    <th>등록일</th>
                                    <th>등록자</th>
                                    <th>사용여부</th>
                                </tr>
                            </thead>
                            <tbody>
                                {terms.length !== 0 &&
                                    terms.map((data, index) => (
                                        <tr
                                            onClick={(e) =>
                                                detailHandler(e, data.id)
                                            }
                                        >
                                            <td>
                                                {totalCount -
                                                    (10 * currentPage + index)}
                                            </td>
                                            <td>{data.title}</td>
                                            <td>
                                                {Moment(data.regDate).format(
                                                    "YYYY-MM-DD"
                                                )}
                                            </td>
                                            <td>{data.regID}</td>
                                            <td>
                                                {data.exposeYn
                                                    ? "사용"
                                                    : "미사용"}
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                        <PaginationNew
                            className="admin_st"
                            totalPage={totalPage}
                            currentPage={currentPage}
                            defaultShowPage={5}
                            changePageCB={handlePageChange}
                        />
                        <div
                            className={`${classes.bottom_btn_wrap} ${classes.position_st}`}
                        >
                            <Button
                                className="secondary sm"
                                onClick={createHandler}
                            >
                                등록
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default MngUserTerms;

export const loader = async () => {
    const response = await ReqTermsAgreementList(0);
    // console.log(response);
    if (response.status === 200) {
        const resData = await response.data.datas;
        return {
            data: resData.content,
            totalPage: resData.totalPages,
            totalCount: resData.totalElements,
        };
    } else {
        return { data: [], totalPage: 0, totalCount: 0 };
    }
};
