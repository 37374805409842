import classes from "./MyPageApp.module.css";
import { useEffect, useState } from "react";
import instance from "../../store/AxiosInterceptor";
import { useLoaderData, useNavigate, Link } from "react-router-dom";
import { ReqBoardAsListPage, ReqBoardAsListPageApp } from "../../api/mypage/UserMyPageApi";
import Button from "../../components/UI/Button/Button";
import { JwtTokenContext } from "../../store/JwtTokenProvider";
import { useContext } from "react";
import { useMemStore } from "../../store/memstore/MemStore";

function MyASHistoryApp() {
    const setMemStore = useMemStore(false)[1];
    const loginCtx = useContext(JwtTokenContext);
    const token = loginCtx.getToken();
    
    const resData = useLoaderData();
    const [asHistoryList, setAsHistoryList] = useState([]);
    
    const [page, setPage] = useState(0);
    const pageHandler = () => {
        setPage((prev) => prev + 1);
        ReqBoardAsListPageApp(
            page + 1,
            (result) => {
                const curData = result.content.map((data) => {
                    return {
                        asId: data.id,
                        process: data.process,
                        asSupportTypeName: data.asSupportTypeName,
                        reqDate: data.reqDate,
                        seq: data.seq,
                        title: data.title,
                    };
                });
                setAsHistoryList(prev=>[...prev, curData]);
                setPageNumber(result.pageable.pageNumber);
                setTotalPage(result.totalPages);
            },
            (error_result) => {
                setAsHistoryList((prev) => prev);
                setPageNumber((prev) => prev);
                setTotalPage((prev) => prev);
            }
        );
    };

    const [pageNumber, setPageNumber] = useState(0);
    const [totalPage, setTotalPage] = useState(1);

    const [tabIndex, setTabIndex] = useState(1);
    const navigate = useNavigate();

    const tabArr = [
        { id: 0, title: "1:1 문의내역", link: "/myPage/cs" },
        { id: 1, title: "A/S 신청내역", link: "/myPage/cs/as" },
    ];

    const navigateHandler = (link) => {
        navigate(link);
    };

    const noDataLoader = async () => {
        const response = await instance({
            method: "GET",
            url: `/api/v1/home/board/as?page=0&size=5`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        }).catch(function (error) {
            if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
                return error.response;
            } else if (error.request) {
                console.log(error.request);
                return null;
            } else {
                console.log("Error", error.message);
                return null;
            }
        });
        if (response == null || response.status !== 200) {
            return [];
        }
        if (response.data.status === 200) {
            return response.data.data;
        } else {
            return [];
        }
    };

    useEffect(() => {
        if (resData.content) {
            const curData = resData.content.map((data) => {
                return {
                    asId: data.id,
                    process: data.process,
                    asSupportTypeName: data.asSupportTypeName,
                    reqDate: data.reqDate,
                    seq: data.seq,
                    title: data.title,
                };
            });
            setAsHistoryList(curData);
            setPageNumber(resData.pageable.pageNumber);
            setTotalPage(resData.totalPages);
        }
    }, [resData, page]);

    useEffect(() => {
        const fetchData = async () => {
            if (!resData.length) {
                const response = await noDataLoader();
                const curData = response.content.map((data) => {
                    return {
                        asId: data.id,
                        process: data.process,
                        asSupportTypeName: data.asSupportTypeName,
                        reqDate: data.reqDate,
                        seq: data.seq,
                        title: data.title,
                    };
                });
                setAsHistoryList(curData);
                setPageNumber(response.pageable.pageNumber);
                setTotalPage(response.totalPages);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (token == null) {
            setMemStore("LOGIN_MOVE_PAGE", { uri: "/myPage/cs/as" });
            navigate("/login");
        }
    }, []);

    return (
        <div className={classes.contents_wrap}>
            {/* 모바일에서 title 없음 */}
            <ul className={classes.tab_menu}>
                {tabArr.map((item) => (
                    <li
                        key={item.id}
                        className={tabIndex === item.id && classes.on}
                        onClick={() => navigateHandler(item.link)}
                    >
                        {item.title}
                    </li>
                ))}
            </ul>

            {/* 모바일에서 클래스 명 추가 및 변경  */}
            <div className={`${classes.mobile_table_wrap} ${classes.cs_type}`}>
                <table>
                    <tbody>
                        {asHistoryList.map((data, index) => (
                            <tr className={classes.waiting_answer} key={index}>
                                <td>{data.seq}</td>
                                <td>
                                    <Link to={`${data.asId}`}>
                                        [ {data.asSupportTypeName} ] {data.title}
                                    </Link>
                                </td>
                                {data.process === "Request" && <td className="gray_c">처리 중</td>}
                                {data.process === "Close" && <td>처리 완료</td>}
                                <td>{data.reqDate}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                {/* 모바일버전 - 페이징 삭제 / 더보기 추가 */}
                <div className={classes.more_btn}>
                    <Button className="text_icon" onClick={pageHandler}>
                        더보기
                    </Button>
                </div>
            </div>
            {asHistoryList.length === 0 && (
                <div className={`${classes.empty_data} ${classes.cs_st}`}>
                    <p>신청 내역이 없습니다.</p>
                </div>
            )}
        </div>
    );
}
export default MyASHistoryApp;

//>>> Loader
export const loader = async () => {
    const response = await instance({
        url: `/api/v1/home/board/as?page=0&size=5`,
        method: "GET",
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    }).catch(function (error) {
        if (error.response) {
            // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
            // console.log(error.response.data);
            // console.log(error.response.status);
            // console.log(error.response.headers);
            return error.response;
        } else if (error.request) {
            // 요청이 이루어 졌으나 응답을 받지 못했습니다.
            // console.log(error.request);
            return null;
        } else {
            // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
            // console.log("Error", error.message);
            return null;
        }
    });

    if (response == null || response.status !== 200) {
        return [];
    }
    if (response.data.status === 200) {
        return response.data.data;
    } else {
        return [];
    }
};
