import { useContext, useState } from "react";
import Button from "../../../../components/UI/Button/Button";
import Input from "../../../../components/UI/Input/Input";
import Radio from "../../../../components/UI/Input/Radio";
import classes from "../../MngCommon.module.css";
import { useNavigate } from "react-router-dom";
import Moment from "moment";
import QuillEditor from "../../../../components/UI/Editer/QuillEditor";
import {
    termsAgreementType,
    termsType,
} from "../../../../components/definedefault/DefSelectBoxValues";
import SelectBoxNew from "../../../../components/UI/SelectBox/SelectBoxNew";
import instance from "../../../../store/AxiosInterceptor";
import { ReqTermsCreate } from "../../../../api/site/ApiForTerms";
import { JwtTokenContext } from "../../../../store/JwtTokenProvider";

function MngUserTermsCreate(props) {
    const loginCtx = useContext(JwtTokenContext);
    const navigate = useNavigate();

    const backHandler = () => {
        navigate("/admin/users/terms");
    };

    const [title, setTitle] = useState();
    const [revisionHist, setRevisionHist] = useState();
    const [description, setDescription] = useState();
    const [exposeYnStatus, setExposeYnStatus] = useState("Y");
    const [type, setType] = useState(termsAgreementType[0].key);

    const changeTitleHandler = (value) => {
        setTitle(value);
    };

    const changeRevisionHistHandler = (value) => {
        setRevisionHist(value);
    };

    const descriptionHandler = (value) => {
        setDescription(value);
    };

    const exposeYnRadioButton = (e) => {
        setExposeYnStatus(e.target.value);
    };

    const typeHandler = (key) => {
        setType(key);
    };

    const createHandler = async (e) => {
        e.preventDefault();

        if (!title) {
            alert("제목을 입력해주세요.");
            return;
        }

        if (!description) {
            alert("전문을 입력해주세요.");
            return;
        }

        if (!revisionHist) {
            alert("개정내용을 입력해주세요.");
            return;
        }

        const isTrue = window.confirm("내용를 등록하시겠습니까?");
        if (isTrue) {
            try {
                const jsonData = {
                    type: type,
                    title: title,
                    exposeYn: exposeYnStatus,
                    modify: revisionHist,
                    content: description,
                    notiDate: new Date().toISOString(),
                };
                // console.log(jsonData)
                const response = await ReqTermsCreate(jsonData);
                if (response.status === 200) {
                    alert("내용이 등록되었습니다.");
                    backHandler();
                } else {
                    alert("내용 등록 실패");
                }
            } catch (error) {
                console.error("Error:", error);
            }
        } else {
            return;
        }
    };

    return (
        <section>
            <div className={classes.admin_contents}>
                <h3>약관/동의문 - 등록</h3>
                <div
                    className={`${classes.contents_wrap} ${classes.search_wrap} `}
                >
                    <div className={classes.admin_table_wrap}>
                        <table>
                            <colgroup>
                                <col style={{ width: "10%" }} />
                                <col style={{ width: "40%" }} />
                                <col style={{ width: "10%" }} />
                                <col style={{ width: "40%" }} />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>등록일</th>
                                    <td>
                                        {Moment(new Date()).format(
                                            "YYYY-MM-DD"
                                        )}
                                    </td>
                                    <th>등록자</th>
                                    <td>{loginCtx.decodedToken.jti}</td>
                                </tr>
                                <tr>
                                    <th>사용여부</th>
                                    <td>
                                        <div className={classes.radio_wrap}>
                                            <Radio
                                                value="Y"
                                                title="사용"
                                                name="rad_01"
                                                id="rad_01_01"
                                                checked={exposeYnStatus === "Y"}
                                                onChange={exposeYnRadioButton}
                                            />
                                            <Radio
                                                value="N"
                                                title="미사용"
                                                name="rad_01"
                                                id="rad_01_02"
                                                checked={exposeYnStatus === "N"}
                                                onChange={exposeYnRadioButton}
                                            />
                                        </div>
                                    </td>
                                    <th>타입</th>
                                    <td>
                                        <SelectBoxNew
                                            className="admin_st"
                                            setSelectItemCB={typeHandler}
                                            optionDatas={termsAgreementType}
                                            showDefaultKey={type}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>제목</th>
                                    <td colSpan={3}>
                                        <Input
                                            className="admin_st"
                                            onChange={changeTitleHandler}
                                        />
                                    </td>
                                </tr>

                                <tr>
                                    <th>전문</th>
                                    <td colSpan={3}>
                                        <div
                                            style={{
                                                border: "1px solid #dedede",
                                                height: "292px",
                                            }}
                                        >
                                            <QuillEditor
                                                height="248"
                                                width="auto"
                                                onValueChangeCB={
                                                    descriptionHandler
                                                }
                                                value={description}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>개정내용</th>
                                    <td colSpan={3}>
                                        <Input
                                            className="admin_st"
                                            onChange={changeRevisionHistHandler}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className={classes.bottom_btn_wrap}>
                            <Button
                                className="secondary sm"
                                onClick={createHandler}
                            >
                                등록
                            </Button>
                            <Button
                                className="border sm"
                                onClick={() => backHandler(0)}
                            >
                                목록
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default MngUserTermsCreate;
