import { useEffect, useState } from "react";
import Button from "../../../../components/UI/Button/Button";
import classes from "../../MngCommon.module.css";
import { ReqFaqDelete, ReqFaqDetail } from "../../../../api/community/ApiForFaq";
import { useLoaderData, useLocation, useNavigate } from "react-router-dom";
import Moment from 'moment';
import { faqType } from "../../../../components/definedefault/DefSelectBoxValues";

function MngFaq() {
    
    const faq = useLoaderData();

    const location = useLocation();
    const navigate = useNavigate();

    const backHandler = () => {
        navigate("/admin/community/faq", { state: location.state });
    };
    useEffect(() => {
        window.addEventListener('popstate', backHandler);
        return () => {window.removeEventListener('popstate', backHandler);};
    }, []);
    const deleteHandler = async (e) => {
        e.preventDefault();
        
        const isTrue = window.confirm('내용을 삭제하시겠습니까?');
        if(isTrue) {
            try {
                const response = await ReqFaqDelete(faq.id);
                if (response.status === 200) {
                    alert("내용이 삭제되었습니다.");
                    backHandler();
                } else {
                    alert("내용 삭제 실패");
                }
            } catch (error) {
                console.error("Error:", error);
            }
        } else {
          return
        }
    };

    const updateHandler = () => {
        navigate(`../update/${faq.id}`, {state:  location.state });
    };

    const setTypeCheck = (type) => {
        const types = faqType.find((items) => items.key === type);
        return types === undefined ? "" : types.value;
    }

    return (
        <section>
            <div className={classes.admin_contents}>
                <h3>FAQ - 상세</h3>
                <div
                    className={`${classes.contents_wrap} ${classes.search_wrap} `}
                >
                    <div className={classes.admin_table_wrap}>
                        <table>
                            <colgroup>
                                <col style={{ width: "159px" }} />
                                <col style={{ width: "620px" }} />
                                <col style={{ width: "159px" }} />
                                <col style={{ width: "620px" }} />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>등록일</th>
                                    <td>{Moment(faq.regDate).format('YYYY-MM-DD')}</td>
                                    <th>등록자</th>
                                    <td>{faq.regID}</td>
                                </tr>
                                <tr>
                                    <th>사용여부</th>
                                    <td colSpan={3}>{faq.exposeYn === "Y" ? "사용" : "미사용"}</td>
                                </tr>
                                <tr>
                                    <th>유형</th>
                                    <td colSpan={3}>{setTypeCheck(faq.boardTypeValue)}</td>
                                </tr>
                                <tr>
                                    <th>질문</th>
                                    <td colSpan={3}>{faq.title}</td>
                                </tr>
                                <tr>
                                    <th>답변</th>
                                    <td colSpan={3}>
                                        <div dangerouslySetInnerHTML={{
                                            __html: faq.content,
                                        }} >
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className={classes.bottom_btn_wrap}>
                            <Button className="secondary sm" onClick={updateHandler}>수정</Button>
                            <Button className="sm" onClick={deleteHandler}>삭제</Button>
                            <Button className="border sm" onClick={backHandler} >목록</Button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default MngFaq;

export const loader = async ({ params }) => {
    console.log(params);
    const response = await ReqFaqDetail(params.faqID);
    
    if (response.status === 200) {
        const resData = await response.data;
        return resData;
    } else {
        return [];
    }
};