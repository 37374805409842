
import { useSearchParams, useNavigate, useParams, useLocation } from "react-router-dom";
/**
 * 나이스 본인인증 결과를 받아서 처리하는 부분
 * 
 * 1. 본인인증에서 나이스 팝업창 구동
 * 
 * @returns 
 */
export default async function IdentifyFunc() {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const paramGender = searchParams.get('gender');
    const paramName = searchParams.get('name');
    const paramYear = searchParams.get('year');
    const paramMonth = searchParams.get('month');
    const paramDay = searchParams.get('day');
    const paramCell = searchParams.get('cell');
    const paramMemberId = searchParams.get('memberId');

    if (window.opener) {
        window.opener.parentCallback(paramGender, paramName, paramYear, paramMonth, paramDay, paramCell, paramMemberId);
    }
    else {
        console.log("window opener is null.");
    }

    window.close();

    return (<div>인증완료</div>);
}