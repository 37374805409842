import { Outlet } from "react-router-dom";
import classes from "./TournamentContainerApp.module.css";
import TournamentAppNav from "./TournamentAppNav";

function TournamentRootLayout() {
    return (
        <>
            <Outlet />
        </>
    );
}

export default TournamentRootLayout;
